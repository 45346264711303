import { useContext } from 'react'

import { useAsync } from 'react-use'

import { VisitCancelationReason } from '../../../model/dictionary-item'
import { ApiContext } from '../../../providers'
import { VisitWithPos } from '../../_common/hooks/useFetchVisit'

export const UseReasonsCancelled = (visit: VisitWithPos): VisitCancelationReason[] | undefined => {
  const api = useContext(ApiContext)
  const userProfile = useAsync(async () => await api.userProfile.getCurrentProfile())

  const reasonsCancelledItems = userProfile.value?.profile?.availableVisitCancelationReasons
    ?.filter((item) => {
      const availableVisitSources = item.availableVisitSources
      if (!availableVisitSources?.length) {
        return true
      }
      return availableVisitSources?.some((source) => visit.source === source)
    })
    .map((item) => item.reason)

  return reasonsCancelledItems
}
