/* eslint-disable react/no-children-prop */
import React, { useEffect } from 'react'

import { Route, Routes } from 'react-router-dom'

import { Layout } from '../../../layout'
import ReportsPage from '../reports-page/reports-page'
import IsaReportPage from '../reports/isa/isa-report-page'
import IsaResultsPage from '../reports/isa/isa-results-page'
import SalesExpertReportPage from '../reports/sales-expert/sales-expert-report-page'
import SmItemPage from '../reports/sm/sm-item-page'
import SMReportPage from '../reports/sm/sm-report-page'

interface ReportsAppFeatureProps {
  children?: never
}

export const ReportsAppFeature: React.FC<ReportsAppFeatureProps> = (props) => {
  useEffect(() => {
    console.log('ReportsAppFeature mount', props, relativeRoutes)
  }, [])

  return (
    <Routes>
      <Route path={relativeRoutes.root} element={<Layout children={<ReportsPage />} />} />
      <Route path={relativeRoutes.isaReportPage} element={<IsaReportPage />} />
      <Route path={relativeRoutes.isaResultsPage} element={<IsaResultsPage />} />
      <Route path={relativeRoutes.salesExpertReportPage} element={<SalesExpertReportPage />} />
      <Route path={relativeRoutes.smReportPage} element={<SMReportPage />} />
      <Route path={relativeRoutes.smItemPage} element={<SmItemPage />} />
    </Routes>
  )
}

export const reportsRoutes = {
  root: '/:featureRoute',
  isaReportPage: '/:featureRoute/isa',
  isaResultsPage: '/:featureRoute/isa/:id',
  salesExpertReportPage: '/:featureRoute/sales-expert',
  smReportPage: '/:featureRoute/sm',
  smItemPage: '/:featureRoute/sm/:id',
} as const

const relativeRoutes = Object.fromEntries(
  Object.entries(reportsRoutes).map(([key, value]) => [key, value.replace(/^\/:featureRoute/, '')]),
) as typeof reportsRoutes
