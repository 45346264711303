import React, { useContext } from 'react'

import { Box, Checkbox, createStyles, makeStyles, MenuItem, Select } from '@material-ui/core'

import { useUpdateProperty } from '../../../nested/useUpdateProperty'
import { getPropertyAny } from '../../../script-tasks/propertyName'
import { CellWrapperProps, EditorItemProps } from './editorUtils'
import { TableSelect } from './single-select-cell'
import { TableItemContext } from './table-item'

const useStyles = makeStyles((theme) =>
  createStyles({
    boolean: {
      padding: 5,
    },
  }),
)

const BooleanCellControl: React.FC<EditorItemProps> = ({ col, row, i, id }) => {
  if (col.control.$type !== 'PMI.FACE.BDDM.Extensions.Classes.CheckBoxCell') throw new Error('unreachable')
  const classes = useStyles()
  const value = getPropertyAny<boolean>(row, col.propertyName ?? '')
  const updateProperty = useUpdateProperty()
  const tableItem = useContext(TableItemContext)

  const isInvertValue = col.control.invertValue

  if (col.control.nullable) {
    return (
      <Select
        value={value ?? 'null'}
        onChange={(evt) => {
          void updateProperty(`${tableItem.propertyName}.${i}.${col.propertyName}`, evt.target.value)
        }}
        input={<TableSelect color='primary' />}
      >
        <MenuItem value={true as never}>{'Да'}</MenuItem>
        <MenuItem value={false as never}>{'Нет'}</MenuItem>
        <MenuItem value='null'>{'N/A'}</MenuItem>
      </Select>
    )
  }

  return (
    <Checkbox
      className={classes.boolean}
      id={id}
      color='primary'
      onChange={(evt) => {
        void updateProperty(
          `${tableItem.propertyName}.${i}.${col.propertyName}`,
          !isInvertValue ? evt.target.checked : !evt.target.checked,
        )
      }}
      checked={!isInvertValue ? value ?? false : !value ?? true}
    />
  )
}

export const BooleanCell: React.FC<EditorItemProps & CellWrapperProps> = ({ variant, ...props }) => {
  if (props.col.control.$type !== 'PMI.FACE.BDDM.Extensions.Classes.CheckBoxCell') throw new Error('unreachable')
  switch (variant) {
    case 'table':
      return <BooleanCellControl {...props} />
    case 'portrait':
    case 'extended-row':
      if (props.col.control.nullable) {
        return (
          <Box>
            <BooleanCellControl {...props} />
          </Box>
        )
      }
      return (
        <Box>
          <BooleanCellControl {...props} />
        </Box>
      )
  }
}
