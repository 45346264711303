import { useContext } from 'react'

import { useAsync } from 'react-use'
import { AsyncState } from 'react-use/lib/useAsyncFn'

import { DataSource, IDictionaryWithItems, IKeyValuePairsDataSource } from '../../../../../../model/data-source'
import { IDictionaryItem } from '../../../../../../model/dictionary-item'
import { ApiContext } from '../../../../../../providers'
import { getContextProperty } from '../../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../../script-tasks/script-task-context'

export function getKeyValuePairValue(source: IKeyValuePairsDataSource, key: string | undefined): string | undefined {
  return source.items?.find((pair) => pair.key === key)?.value
}

export function useDataSourceValue(
  dataSource: DataSource,
  propertyName: string,
  // eslint-disable-next-line react-hooks/rules-of-hooks
  localContext = useScriptTaskContext(),
): AsyncState<string | undefined> {
  const api = useContext(ApiContext)

  const ops = useAsync(async () => {
    switch (dataSource?.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.DictionaryReferenceDataSource': {
        const ref = dataSource.dictionary
        const value = getContextProperty<IDictionaryItem | undefined>(localContext, propertyName)
        if (!value) return undefined
        const dictionary: IDictionaryWithItems | null = await api.dictionary.getDictionaryByVersionCode(
          ref.version.code,
        )
        return dictionary?.items?.find((item) => isItemTheSame(item, value))?.name
      }
      case 'PMI.FACE.BDDM.Extensions.Classes.ContextPropertyDataSource': {
        const value = getContextProperty<IDictionaryItem | undefined>(localContext, propertyName)
        if (!value) return undefined
        const dictionary: IDictionaryWithItems | null = getContextProperty(localContext, dataSource.propertyName)
        return dictionary?.items?.find((item) => isItemTheSame(item, value))?.name
      }
      case 'PMI.FACE.BDDM.Extensions.Classes.KeyValuePairsDataSource': {
        const key = getContextProperty<string | undefined>(localContext, propertyName)
        return dataSource.items?.find((pair) => pair.key === key)?.value
      }
    }
  }, [propertyName, dataSource?.$type])

  return ops
}

function isItemTheSame(a: IDictionaryItem, b: IDictionaryItem): boolean {
  return a.code === b.code
}
