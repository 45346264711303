import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react'

import { Box, LinearProgress, Paper, Theme, Typography, createStyles, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { useAsyncFn } from 'react-use'

import { checkPredicate } from '../../../model/script-predicate'
import { ITaskTemplate } from '../../../model/task-template'
import { ISelectFieldForceTaskTemplateScreen } from '../../../model/user-step-screen'
import { ApiContext } from '../../../providers'
import StoresSearch from '../../stores/components/stores-search/stores-search'
import { useUpdateProperty } from '../nested/useUpdateProperty'
import { getContextProperty } from '../script-tasks/propertyName'
import { SyncScriptRunner } from '../script-tasks/run-sync-script'
import { useScriptTaskContext } from '../script-tasks/script-task-context'
import { ItemCard } from './composite-screen/item-card'
import { IFieldForceTaskTemplateReference } from './composite-screen/select-task-template-screen-item'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerWrapper: {
      height: `calc(100vh - 316px)`,
      margin: '0px 24px',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },

    container: {
      height: 'inherit',
      overflow: 'auto',
      padding: '8px ',
      border: '1px solid #E6E9EF',
    },

    item: {
      cursor: 'pointer',
      padding: '8px 16px',
      '&:hover': {
        backgroundColor: 'rgba(48,119,184, 0.05)',
      },
    },
    selected: {
      backgroundColor: 'rgba(48,119,184, 0.05)',
    },
    emptyMessage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      color: 'rgba(0, 0, 0, 0.38)',
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: '20px',
    },
  }),
)

interface Props {
  children?: never
  screen: ISelectFieldForceTaskTemplateScreen
  onReadyChange: (isReady: boolean) => void
}
const SelectTaskTemplateScreen: React.FC<Props> = ({ onReadyChange, screen }) => {
  const classes = useStyles()
  const context = useScriptTaskContext()
  const api = useContext(ApiContext)
  const updateProperty = useUpdateProperty()

  const [templatesOps, fetchTemplate] = useAsyncFn(
    async () => {
      try {
        const templates = await api.tasks.getTaskTemplates()
        templates.sort((x, y) => x.name.localeCompare(y.name))
        return templates
      } catch (error) {
        throw new Error('Ошибка при получении списка задач.')
      }
    },
    [],
    { loading: true },
  )

  useEffect(() => {
    void fetchTemplate()
  }, [fetchTemplate])

  const value: IFieldForceTaskTemplateReference | undefined = getContextProperty(context, screen.propertyName)
  const isValid = screen.required ? !value && checkPredicate(screen.required, undefined, context) : false

  const filteredTemplates = useMemo(() => {
    if (screen.condition) {
      const scriptRunner = new SyncScriptRunner(
        screen.condition.body.scriptBody,
        context,
        screen.condition.argumentName,
      )

      return templatesOps.value?.filter((template) => scriptRunner.run(template))
    }
    return templatesOps.value
  }, [templatesOps.value, screen.condition])

  useEffect(() => {
    onReadyChange(!isValid)
  }, [isValid])

  return (
    <Box className={classes.containerWrapper}>
      {templatesOps.loading && <LinearProgress />}

      {!templatesOps.loading && (
        <SelectTaskTemplate
          items={filteredTemplates}
          selectTemplate={async (code) => {
            const findTemplate = filteredTemplates?.find((t) => t.code === code)
            if (findTemplate) {
              const newParam: IFieldForceTaskTemplateReference = {
                $type: 'PMI.BDDM.Transactionaldata.FieldForceTaskTemplateReference',
                code: findTemplate.code,
                codeSpace: findTemplate.codeSpace,
              }
              await updateProperty(screen.propertyName, newParam)
            } else {
              await updateProperty(screen.propertyName, undefined)
            }
          }}
          selectedCode={value?.code}
        ></SelectTaskTemplate>
      )}
    </Box>
  )
}

export default SelectTaskTemplateScreen

interface SelectTaskTemplateProps {
  items: ITaskTemplate[] | undefined
  selectTemplate: (code: string | undefined) => void
  selectedCode: string | undefined
}
const SelectTaskTemplate: React.FC<SelectTaskTemplateProps> = ({ items, selectTemplate, selectedCode }) => {
  const classes = useStyles()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [selectedItem, setSelectedItem] = useState<string | undefined>(undefined)
  const filteredItems = useMemo(
    () => items?.filter((it) => it.name.toLocaleLowerCase().includes(searchQuery.trim().toLocaleLowerCase())),

    [searchQuery, items],
  )

  const onClickItem = useCallback(
    (item: ITaskTemplate) => {
      const code = selectedItem === item.code ? undefined : item.code
      setSelectedItem(code)
      selectTemplate(code)
    },
    [selectedItem],
  )

  useEffect(() => {
    setSelectedItem(selectedCode)
  }, [])

  const renderItem = (item: ITaskTemplate, index: number): JSX.Element => {
    return (
      <Box
        key={index}
        display='flex'
        alignItems='center'
        component='label'
        mb={1}
        onClick={() => onClickItem(item)}
        className={classNames(classes.item, {
          [classes.selected]: selectedItem === item.code,
        })}
      >
        <Typography>{item.name}</Typography>
      </Box>
    )
  }

  return (
    <>
      <Box mb={3}>
        <StoresSearch query={searchQuery} onChange={setSearchQuery} isContrast={false} />
      </Box>
      <Box>
        {!items?.length && (
          <ItemCard>
            <Box>
              <EmptyMessage text='Нет доступных задач' />
            </Box>
          </ItemCard>
        )}
      </Box>
      {!!items?.length && (
        <Paper elevation={0} square className={classes.container}>
          {filteredItems?.length ? filteredItems.map(renderItem) : <EmptyMessage text='Поиск не дал результатов' />}
        </Paper>
      )}
    </>
  )
}

interface EmptyMessageProps {
  text: string
}
const EmptyMessage: React.FC<EmptyMessageProps> = ({ text }) => {
  const classes = useStyles()
  return <div className={classes.emptyMessage}>{text}</div>
}
