import { getClientId } from '../client-id'
import CLIENT_VERSION from '../client-version'
import SPECIAL_HEADERS from '../headers'
import { LogManager } from '../logger'
import AppConfig from './app-config'
import { ConfigError, ConfigErrorCode, IConfigService } from './config-service-api'

export default class ConfigService implements IConfigService {
  private readonly logger = LogManager.getLogger('ConfigService')
  private readonly _clientId = getClientId()

  private _config: AppConfig | undefined

  async getAppConfig(): Promise<AppConfig> {
    return this._config ?? (this._config = await this.loadConfig())
  }

  private async loadConfig(): Promise<AppConfig> {
    let localConfig
    try {
      localConfig = await this.fetchLocalConfig()
    } catch (e) {
      this.logger.error('loadConfig', 'Failed fetching app config', e)
      throw e instanceof ConfigError ? e : (await this.makeConfigError(e, 'Failed to fetch local app config'))
    }

    let remoteConfig
    try {
      remoteConfig = await this.fetchRemoteConfig(localConfig.apiUrl)
    } catch (e) {
      this.logger.error('loadConfig', 'Failed fetching api config', e)
      throw e instanceof ConfigError ? e : (await this.makeConfigError(e, 'Failed to fetch remote app config'))
    }

    // merging configs
    const config = localConfig.__main ? { ...remoteConfig, ...localConfig } : { ...localConfig, ...remoteConfig }

    this.logger.info('loadConfig', 'config', config)
    return config
  }

  /**
   * Система получает файл config.json
   */
  private async fetchLocalConfig(): Promise<AppConfig> {
    this.logger.debug('fetchLocalConfig', 'Fetching app config...')
    const res = await fetch('/assets/config.json', {
      headers: {
        Accept: 'application/json'
      }
    })
    const config: AppConfig = await res.json()
    return config
  }

  /**
   * Система получает конфигурацию с сервера
   */
  private async fetchRemoteConfig(apiUrl: string): Promise<AppConfig> {
    this.logger.debug('fetchRemoteConfig', 'Fetching api config...')
    const res = await fetch(`${apiUrl}/config`, {
      headers: {
        Accept: 'application/json',
        [SPECIAL_HEADERS.clientId]: this._clientId,
        [SPECIAL_HEADERS.clientVersion]: CLIENT_VERSION
      }
    })

    if (res.status === 200) {
      return await res.json()
    } else {
      throw await this.makeConfigError(res, 'Failed to fetch remote app config')
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async makeConfigError(res: any, defaultMessage: string): Promise<ConfigError> {
    let code = ConfigErrorCode.Other
    let message = defaultMessage
    const status = res.status

    if (status) {
      message += ` ${status}`
    }

    const body = await res.json()
    if (body) {
      if (status === 503 && body.type === 'MAINTENANCE_IN_PROGRESS') {
        code = ConfigErrorCode.MaintenanceInProgress
      }
    }

    return new ConfigError(code, message, status, res)
  }
}
