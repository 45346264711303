import React, { useContext } from 'react';

import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { IProblem } from '../../../../../model/problem';
import { ProfileContext } from '../../../../../providers';
import { useIsSmall } from '../../../../_common/hooks/useIsSmall';


interface IProps {
  problem: IProblem
}

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    status: {
      padding: '4px 12px 6px',
      borderRadius: '40px',
      fontSize: 12,
      lineHeight: '14px',
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.common.white,
      marginLeft: theme.spacing(2),
    },
    statusNew: {
      background: theme.palette.info.main,
    },
    statusFinished: {
      background: theme.palette.success.main,
    },
    statusInProgress: {
      background: theme.palette.warning.main,
    },
    statusCanceled: {
      background: theme.palette.grey[600],
    },
    status_mini: {
      borderRadius: '50%',
      padding: 6,
      marginLeft: theme.spacing(2),
    },
  })
)

const ProblemStatusItem: React.FC<IProps> = ({problem}) => {
  const classes = useStyles()
  const currentUser = useContext(ProfileContext)
  const isSmall = useIsSmall()
  const { t } = useTranslation('problems')
  let resultStatus = ''

  if (
    problem!.status === 'New' &&
    (!problem!.assignedTo || currentUser.value?.fieldPositionRole?.code !== problem!.assignedToPositionRole?.code)
  ) {
    resultStatus = 'New'
  }
  if (
    problem!.status === 'New' &&
    currentUser.value?.fieldPositionRole?.code === problem!.assignedToPositionRole?.code
  ) {
    resultStatus = 'Processed'
  }
  if (problem!.status === 'Resolved') {
    resultStatus = 'Resolved'
  }
  if (problem!.status === 'Canceled') {
    resultStatus = 'Canceled'
  }

  return (
    <Typography
      className={classNames(isSmall ? classes.status_mini : classes.status, {
        [classes.statusNew]: resultStatus === 'New',
        [classes.statusInProgress]: resultStatus === 'Processed',
        [classes.statusFinished]: resultStatus === 'Resolved',
        [classes.statusCanceled]: resultStatus === 'Canceled',
      })}
    >
      {problem && !isSmall && t(resultStatus)}
    </Typography>
  );
};

export default ProblemStatusItem