import React from 'react'

import { Box } from '@material-ui/core'

import { EmptyMessage } from '../../../components'
import { Layout } from '../../../layout'
import { PageContent } from '../../../layout/page-content'
import { TitleBar } from '../../../layout/title-bar'
import { BusinessError } from '../../../model/errors'

interface IBusinessErrorBoundary {
  error: Error
  resetErrorBoundary: () => void
}

export const BusinessErrorBoundary: React.FC<IBusinessErrorBoundary> = ({ error, resetErrorBoundary }) => {
  if (!(error instanceof BusinessError)) {
    throw error
  }

  return (
    <Layout>
      <TitleBar onBack={resetErrorBoundary}>{'Задача'}</TitleBar>
      <PageContent>
        <Box pt={3} />
        <EmptyMessage message={error.message} />
      </PageContent>
    </Layout>
  )
}
