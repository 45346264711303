import React, { useContext, useEffect } from 'react'

import { useAsync } from 'react-use'

import { IEntityReference, getEntityKey } from '../../model/base'
import { SyncContext } from '../../providers'
import { AppRoutes } from '../../routes'
import { DteProcess } from './dte-process'
import { DtePageSkeleton } from './skeleton'
import { DTETaskStatus } from './types'

interface DteIqosTasksPageProps {
  children?: never
}

const templateRef: IEntityReference = {
  code: 'TT02464LA9AD3DQ',
  codeSpace: 'FACE',
  version: {
    code: 'TT02464LA9AD3DQ_v1',
    startDate: Date.now(),
  },
}
const templateKey = getEntityKey(templateRef)
const statuses: DTETaskStatus[] = ['Approved', 'Assigned', 'Submitted', 'Rejected']

export const DteIqosTasksPage: React.FC<DteIqosTasksPageProps> = (props) => {
  useEffect(() => {
    console.log('DteIqosTasksPage mount', props)
  }, [])

  const sync = useContext(SyncContext)

  const ops = useAsync(async () => {
    return sync.syncService.sync('Minimal')
  }, [])

  return (
    <>
      {ops.loading ? (
        <DtePageSkeleton />
      ) : (
        <DteProcess
          key={templateKey}
          title={AppRoutes.dteIqosTasks.primaryText}
          templateRef={templateRef}
          statuses={statuses}
        />
      )}
    </>
  )
}
