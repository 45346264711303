import React, { useEffect, useState, useMemo, useRef } from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core'
import classNames from 'classnames'
import { findLastIndex, isError } from 'lodash'

import { EmptyMessage } from '../../../components'
import { ValidationFab } from '../../../components/validation-fab'
import { LogManager } from '../../../infrastructure/logger'
import { Code } from '../../../model/base'
import { IProcessStep, isScriptStep, ISwitchStep, IUserStep } from '../../../model/task-execution-process'
import { appToast } from '../../../utils'
import { useConfirmationModal } from '../../_common/hooks/useConfirmationModal'
import { useInlineActionButton } from '../../dte/useInlineActionButton'
import { DebugTree } from '../debug-tree'
import { useScriptTaskContext } from '../script-tasks/script-task-context'
import { ScriptView } from '../script-tasks/script-view'
import { useTaskMethods } from '../script-tasks/task-methods'
import { TaskStartScreen } from '../script-tasks/task-start-screen'
import { UserStepControl } from '../script-tasks/user-step-control'
import { SwitchStepControl } from '../switch/switch-step-control'
import { ProceedTabs, ProceedTab, TabIcon } from '../template-tasks/proceed-tabs'
import { ScreenRef } from '../template-tasks/types'
import { useExecutionState } from './execution-state'
import { useLocalContextService } from './local-context'

const empty: never[] = []

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      height: '100%',
      marginTop: 84,
      paddingBottom: theme.spacing(12),
      paddingTop: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        marginTop: 77,
        paddingTop: theme.spacing(2),
      },
    },
    hideTabs: {
      marginTop: 0,
    },
  }),
)

interface IProps {
  canTaskStart: () => Promise<string | undefined>
  onBeforeFinish: () => Promise<string | undefined>
  onTaskFinish: () => void
  onBeforeStartTask: () => void
  onAfterStartTask: () => void
}

const logger = LogManager.getLogger('TaskProcessControl')

export const TaskProcessControl: React.FC<IProps> = (props) => {
  useEffect(() => {
    console.log('TaskProcessControl mounted', props)
  }, [])
  const { canTaskStart, onBeforeFinish, onTaskFinish, onBeforeStartTask, onAfterStartTask } = props
  const { startTask, finishTask } = useTaskMethods()
  const finishTaskRequest = useConfirmationModal()
  const classes = useStyles()
  const localContext = useScriptTaskContext()
  const { template, taskState, task } = localContext
  const contextService = useLocalContextService()
  const inlineActionButton = useInlineActionButton()

  const steps = template.process?.steps ?? empty
  const tabs = useMemo(() => steps.filter(isTabStep), [steps])
  const { taskState: { currentStep: stepIndex } = { currentStep: 0 }, setTaskStep } = useExecutionState()

  const [isReady, setIsReady] = useState<boolean>(false)
  const currentScreenRef = useRef<ScreenRef>(null)

  // const stepIndex = steps.findIndex((step) => step.code === currentStage)
  const foundStage: IProcessStep | undefined = steps[stepIndex]
  const currentStage = foundStage?.code
  const setCurrentStage = (code: Code): void => {
    const targetIndex = steps.findIndex((step) => step.code === code)
    setTaskStep(targetIndex)
  }

  const { taskStartStep, taskFinishStep, lastScreenStep } = useMemo(() => {
    const steps = template.process?.steps ?? empty
    const taskStartStep = steps.find((step) => {
      switch (step.$type) {
        case 'PMI.FACE.BDDM.Extensions.Classes.SwitchStep':
        case 'PMI.FACE.BDDM.Extensions.Classes.ScriptStep':
          return true
        case 'PMI.FACE.BDDM.Extensions.Classes.UserStep':
          return !step.availableBeforeTaskStart
        default:
          return false
      }
    })

    const taskFinishStep = steps[steps.length - 1]
    const lastScreenStep = steps[findLastIndex(steps, isTabStep)]
    return { taskStartStep, taskFinishStep, lastScreenStep }
  }, [template.code])

  const goToNext = async (): Promise<void> => {
    const newIndex = steps.indexOf(foundStage!) + 1

    const nextStage = steps[newIndex]
    console.log('goToNext', { nextStage, foundStage, steps })

    if (newIndex) {
      setTaskStep(newIndex)
      await contextService.updateStackSteps()
    }
  }

  async function handleStart(): Promise<void> {
    const res = await canTaskStart()
    if (res) {
      throw new Error(res)
    }
    try {
      onBeforeStartTask()
      console.log('can start task')
      await contextService.onLoad()
      await startTask()
      await contextService.refetch()
    } finally {
      onAfterStartTask()
    }
  }

  async function handleFinish(): Promise<void> {
    // const confirmation = await finishTaskRequest()
    // if (!confirmation) return

    const reportLink = await onBeforeFinish().catch(() => '')
    await finishTask(reportLink ?? '')

    await contextService.refetch()
    onTaskFinish()
  }

  const handleAction = async (): Promise<void> => {
    await contextService.onLoad()
    await contextService.refetch()
    const newIndex = steps.indexOf(foundStage!) + 1
    const nextStage = steps[newIndex]
    console.log('Action', { nextStage, currentStage: foundStage, taskStartStep, taskFinishStep })
    if (task.status === 'Planned' && nextStage?.code === taskStartStep?.code) {
      return void handleStart()
        .then(goToNext)
        .catch((err) => isError(err) && appToast.info(err.message))
    }

    if (foundStage?.code === lastScreenStep.code) {
      const confirmation = await finishTaskRequest()
      if (!confirmation) return
    }

    if (foundStage?.code === taskFinishStep.code) {
      return void handleFinish()
    }
    void goToNext()
  }

  const handleScreenAction = (): void => {
    if (!isReady) {
      return currentScreenRef.current?.validate()
    }

    return void handleAction()
  }

  function findLastScreenIndex(): number {
    const currentIndex = steps.indexOf(foundStage!)
    if (currentIndex > 0) {
      const previousStepIndex = findLastIndex(steps.slice(0, currentIndex), isTabStep)
      return previousStepIndex
    }
    return -1
  }

  async function goToLastScreen(): Promise<void> {
    const previousStepIndex = findLastScreenIndex()
    if (previousStepIndex > -1) {
      setTaskStep(previousStepIndex)
      await contextService.updateStackSteps()
    }
  }

  const handleScriptError = (error: Error): void => {
    appToast.error(error.message)
    void goToLastScreen()
  }

  useEffect(() => {
    if (!steps.length) {
      return
    }
    const currentStep = steps.findIndex((s) => s.code === foundStage?.code)
    const taskStartStepIndex = steps.findIndex((s) => s.code === taskStartStep?.code)
    if (task.status === 'Planned' && currentStep > taskStartStepIndex) {
      logger.error('task status', 'task reached an unreachable step with invalid status', undefined, {
        task,
        currentStep,
        taskStartStep: taskStartStepIndex,
      })
    }
  }, [foundStage])

  if (!steps.length) {
    return <EmptyMessage message='Не заполнен список шагов' />
  }

  const showTabs = tabs.length > 1

  if (
    task.status === 'Planned' &&
    steps.findIndex((s) => s.code === foundStage?.code) >= steps.findIndex((s) => s.code === taskStartStep?.code)
  ) {
    const areStepsAvailable = stepIndex !== 0
    return (
      <div className={classes.content}>
        {showTabs && (
          <ProceedTabs value={areStepsAvailable ? currentStage ?? '' : ''} onChange={setCurrentStage}>
            {tabs.map((stage, i) => {
              return (
                <ProceedTab
                  disabled={!areStepsAvailable || steps.indexOf(stage) > (taskState.currentStep ?? 0)}
                  value={stage.code}
                  key={stage.code}
                  icon={<TabIcon>{`${i + 1}`}</TabIcon>}
                  label={stage.displayName}
                />
              )
            })}
          </ProceedTabs>
        )}
        <TaskStartScreen onStart={handleStart} />
      </div>
    )
  }

  if (foundStage?.$type === 'PMI.FACE.BDDM.Extensions.Classes.ScriptStep') {
    return (
      <ScriptView
        localContext={localContext}
        script={template.scripts[foundStage.script.code]}
        onSuccess={handleAction}
        onError={handleScriptError}
      />
    )
  }

  return (
    <>
      <div className={classNames(classes.content, !showTabs && classes.hideTabs)}>
        {showTabs && (
          <ProceedTabs value={currentStage ?? ''} onChange={setCurrentStage}>
            {tabs.map((stage, i) => {
              return (
                <ProceedTab
                  disabled={steps.indexOf(stage) > (taskState.currentStep ?? 0)}
                  value={stage.code}
                  key={stage.code}
                  icon={<TabIcon>{`${i + 1}`}</TabIcon>}
                  label={stage.displayName}
                />
              )
            })}
          </ProceedTabs>
        )}
        {foundStage?.$type === 'PMI.FACE.BDDM.Extensions.Classes.UserStep' && (
          <UserStepControl
            ref={currentScreenRef}
            step={foundStage}
            onReadyChange={setIsReady}
            nextButton={<ValidationFab useInline={inlineActionButton} onClick={handleScreenAction} isValid={isReady} />}
          />
        )}
        {foundStage?.$type === 'PMI.FACE.BDDM.Extensions.Classes.SwitchStep' && (
          <SwitchStepControl
            ref={currentScreenRef}
            step={foundStage}
            onReadyChange={setIsReady}
            onScriptError={handleScriptError}
            nextButton={<ValidationFab useInline={inlineActionButton} onClick={handleScreenAction} isValid={isReady} />}
          />
        )}
      </div>
      <DebugTree />
    </>
  )
}

function isTabStep(step: IProcessStep): step is IUserStep | ISwitchStep {
  return !isScriptStep(step)
}
