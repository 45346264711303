import React, { useContext } from 'react'

import { createStyles } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { EmptyText } from '../../../../components/empty-text'
import { ApiContext } from '../../../../providers'
import { ErrorPage } from '../../../error-page'
import { IStoreMenuLocationState } from '../store-menu-page'
import ParticipantItem from './participant-item'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 40,
    },
  }),
)

export const ParticipantDte: React.FC = () => {
  const classes = useStyles()
  const api = useContext(ApiContext)
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state as IStoreMenuLocationState
  const { posCode } = useParams<{ posCode: string }>()

  const participantsOps = useAsync(async () => api.dte.searchParticipantProfiles({ posCode: posCode! }))

  const onClickHandler = (code: string): void => {
    navigate(`/stores/store/${posCode}/participant-dte/${code}`, {
      state: { ...state, prevParticipantPath: location.pathname },
    })
  }

  const renderEmpty = (text: string): JSX.Element => (
    <div style={{ height: 'calc(100vh - 200px)' }}>
      <EmptyText>{text}</EmptyText>
    </div>
  )

  if (participantsOps.error) {
    return <ErrorPage errorMessage={participantsOps.error.message} />
  }

  if (participantsOps.loading) {
    return renderEmpty('Загрузка')
  }

  const participants = participantsOps.value
  if (!participants?.length) {
    return renderEmpty('Список пуст')
  }

  return (
    <>
      <div className={classes.root}>
        {participants.map((participant) => (
          <ParticipantItem
            key={participant.code}
            participant={participant}
            onClick={() => onClickHandler(participant.code)}
          />
        ))}
      </div>
    </>
  )
}
