import React, { useContext, useEffect, useState } from 'react'

import { CircularProgress, createStyles, Paper, Theme, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { sortBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useAsync, useAsyncRetry } from 'react-use'

import { EmptyMessage } from '../../../components'
import { EmptyText } from '../../../components/empty-text'
import { LogManager } from '../../../infrastructure/logger'
import { grays } from '../../../layout/theme'
import { Code } from '../../../model/base'
import { DateTime } from '../../../model/common'
import { IPointOfSale } from '../../../model/pos'
import { ITask } from '../../../model/task'
import { IAvailableTaskCancelationReason } from '../../../model/task-template'
import { VisitStatus } from '../../../model/visit'
import { IVisitTask, VisitTaskStatus } from '../../../model/visit-task'
import { ApiContext, ProfileContext } from '../../../providers'
import { useFetchPendingItems } from '../../../providers/menu-data/pending-items-store'
import { appToast } from '../../../utils'
import { useAvailableTemplateList } from '../../_common/hooks/useAvailableTemplateList'
import { useTryUpload } from '../../_common/hooks/useTryUpload'
import { useFeatureRoute } from '../../custom-app-feature/app-feature-context'
import ErrorPage from '../../error-page/error-page'
import { OpenableTypography } from '../../tasks/components/openable-typography'
import { findRepresentativeItem } from '../../tasks/sales-expert/utils'
import { getTaskProperty } from '../../tasks/script-tasks/propertyName'
import { ITaskRouteState } from '../../tasks/template-tasks/types'
import { VisitModal } from '../components'
import { getUserCancellationReason } from '../task-list/utils'
import { useTaskList } from '../visit-view-page/useTaskList'
import { visitsRoutes } from '../visits-app-feature/visits-app-feature'
import { EmptyTaskMessage } from './empty-task-message'
import VisitTaskMenu from './visit-task-menu'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    taskItem: {
      width: '100%',
      display: 'flex',
      paddingLeft: 28,
      paddingRight: 48,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
      minHeight: theme.spacing(7),
      overflow: 'hidden',
      position: 'relative',
      borderRadius: 8,
      cursor: 'pointer',
      backgroundColor: '#ffffff !important',
      boxShadow: '0px 3px 7px rgba(96, 97, 112, 0.13) !important',
      [theme.breakpoints.down('xs')]: {
        paddingRight: 0,
        paddingTop: 11,
        paddingBottom: 11,
        flexWrap: 'wrap',
        minHeight: 64,
        alignItems: 'start',
      },
    },
    disabled: {
      background: '#F2F3F4 !important',
      // textDecoration: 'line-through',
      // color: theme.palette.grey['500'],
      // textDecorationColor: theme.palette.grey['500']
    },
    isProgressStatus: {
      // background: 'rgb(251,242,227)'
    },
    finishedStatus: {
      background: '#F2F3F4 !important',
    },
    nameWrap: {
      paddingRight: 10,
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      flex: '0 0 100%',
      maxWidth: '100%',
      [theme.breakpoints.down('xs')]: {
        paddingRight: 0,
        paddingBottom: 2,
        flexGrow: 1,
        // flex: '0 0 100%',
        // maxWidth: '100%',
      },
    },
    nameWrapIsRepresentative: {
      flex: '0 0 45%',
      maxWidth: '45%',
      [theme.breakpoints.down('xs')]: {
        flex: '0 0 100%',
        maxWidth: '100%',
      },
    },
    name: {
      fontSize: 16,
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 'calc(100% - 35px)',
        fontSize: 14,
      },
    },
    nameRepresentative: {
      // maxWidth: '100%',
      fontSize: 14,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
    cancelationReason: {
      color: grays.gray3,
      fontSize: 14,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
    selectIcon: {
      color: '#7AB2DC',
    },
    selectIconDisabled: {
      color: theme.palette.grey['500'],
    },
    cancelButton: {
      borderRadius: 'unset',
      background: '#B80718',
      color: 'white',
    },
    repairButton: {
      borderRadius: 'unset',
      background: theme.palette.primary.main,
      color: 'white',
    },
    info: {
      position: 'relative',
      textAlign: 'left',
      flex: '0 0 55%',
      maxWidth: '55%',
      fontSize: 16,
      minHeight: 21,
      paddingLeft: 24,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: -4,
        bottom: -4,
        left: 0,
        width: 2,
        height: 'calc(100% + 8px)',
        backgroundColor: '#E6E9EF',
      },
      [theme.breakpoints.down('xs')]: {
        flex: '0 0 100%',
        maxWidth: '100%',
        textAlign: 'left',
        minHeight: 18,
        paddingLeft: 0,
        color: grays.gray2,
        '&::before': {
          content: 'none',
        },
      },
    },
    status: {
      position: 'absolute',
      height: '100%',
      top: 0,
      left: 0,
      width: 8,
    },
    statusPlanned: {
      background: theme.palette.info.main,
    },
    statusFinished: {
      background: theme.palette.success.main,
    },
    statusInProgress: {
      background: theme.palette.warning.main,
    },
    statusCanceled: {
      background: theme.palette.grey[600],
    },
  }),
)

interface IVisitTaskListProps {
  visitPos: IPointOfSale | null
  visitStatus: VisitStatus
  visitPlannedDate: DateTime
  checkCanFinish: () => void
}

const logger = LogManager.getLogger('VisitTasksList')

const VisitTaskList: React.FC<IVisitTaskListProps> = (props) => {
  const fetchPendingItems = useFetchPendingItems()
  const classes = useStyles()
  const navigate = useNavigate()
  const featureRoute = useFeatureRoute()
  const [isCancelTaskModalOpen, setCancelTaskModalOpen] = useState<Code | null>(null)
  const [isRepairModalOpen, setRepairModalOpen] = useState<Code | null>(null)
  const currentRouteParams = useParams<{ id: string }>()
  const visitCode: Code = currentRouteParams.id!
  const api = useContext(ApiContext)
  const profile = useContext(ProfileContext)
  const { getTaskList } = useTaskList()
  const taskListOps = useAsyncRetry(async () => await getTaskList(visitCode), [props.visitStatus])
  const taskTypesList = useAsync(async () => await api.tasks.getTaskTemplates(), [props.visitStatus])
  const tryUploadVisit = useTryUpload('visit')

  const SEV3_VERSION = 'ServiceSE_v3'
  const templatesOps = useAvailableTemplateList([])
  const template = templatesOps.value?.find((t) => t.version.code === SEV3_VERSION)

  const { i18n, t, ready } = useTranslation('visits')
  useEffect(() => {
    !ready && i18n.reloadResources(i18n.language, 'visits')
  }, [ready])

  const getAvailableTaskCancelationReasons = async (
    taskCode: string,
  ): Promise<IAvailableTaskCancelationReason[] | undefined> => {
    const task = await api.tasks.getTask(taskCode)
    const templateRef = task?.template

    if (templateRef) {
      const template = await api.tasks.getTaskTemplate(templateRef)
      return template?.availableTaskCancelationReasons?.filter((item) => {
        const availableProfiles = item.availableProfiles

        if (!availableProfiles?.length) {
          return true
        }
        return availableProfiles?.some((item) => item.code === profile.value?.profile.code)
      })
    }
  }

  const onCancelTaskClickHandler = async (taskCode?: string | null): Promise<void> => {
    if (!taskCode) {
      return
    }

    try {
      const availableTaskCancelationReasons = await getAvailableTaskCancelationReasons(taskCode)
      if (availableTaskCancelationReasons?.length) {
        // Проверяем что есть причины отмены
        const path = generatePath(visitsRoutes.visitTaskRemove, { featureRoute, visitCode, code: taskCode })
        await navigate(`${path}?type=task`)
        return
      }

      await onCancelTaskClick(taskCode!)
      props.checkCanFinish()
    } catch (error) {
      const message = error.message
      logger.error('onCancelTaskClickHandler', message, error)
      throw new Error(error)
    }
  }

  const onRepairClickHandler = async (): Promise<void> => {
    await onRepairClick(isRepairModalOpen!)
    props.checkCanFinish()
  }

  const onCancelTaskClick = async (taskCode: Code): Promise<void> => {
    try {
      await api.tasks.setTaskStatus({
        taskCode,
        taskStatus: 'Canceled',
        visitCode,
        cancellationReason: getUserCancellationReason(),
      })

      await tryUploadVisit(visitCode)
    } catch (e) {
      const message = t('cancelTaskError')
      logger.error('onCancelClick', message, e, { taskCode })
      appToast.error(message)
    }
    await taskListOps.retry()
    setCancelTaskModalOpen(null)
    // appToast.success(t('cancelTaskSuccess'))
    fetchPendingItems()
  }

  const onRepairClick = async (taskCode: Code): Promise<void> => {
    try {
      const res = await api.tasks.restoreCanceledTask(taskCode)
      if (!res) {
        throw new Error('restoreCanceledVisitTask failed')
      }
      await tryUploadVisit(visitCode)
    } catch (e) {
      const message = t('restoreTaskError')
      logger.error('onRepairClick', message, e, { taskCode })
      appToast.error(message)
    }
    await taskListOps.retry()
    setRepairModalOpen(null)
    // appToast.success(t('restoreTaskSuccess'))
    fetchPendingItems()
  }

  const onEditCompletedTask = async (taskCode: Code): Promise<void> => {
    try {
      await api.tasks.setTaskStatus({ taskStatus: 'InProgress', taskCode })
      const taskState = await api.tasks.getTaskExecutionState(taskCode)
      await api.tasks.saveTaskExecutionState({ subProcesses: {}, taskCode, ...taskState, currentStep: 0 })
      await api.tasks.setTaskPropertyPath({ taskCode, propertyName: 'task.endDate', value: undefined })
    } catch (e) {
      const message = t('restoreTaskError')
      logger.error('onEditCompletedTask', message, e, { taskCode })
      appToast.error(message)
    }
    await taskListOps.retry()
    props.checkCanFinish()
    fetchPendingItems()
  }

  if (taskListOps.error) {
    return <ErrorPage errorMessage={t('taskListFetchError')} />
  }
  if (taskTypesList.error) {
    console.error(taskTypesList.error)
    return <ErrorPage errorMessage={t('taskTypesFetchError')} />
  }

  if (taskListOps.loading || taskTypesList.loading) {
    return (
      <EmptyText>
        <CircularProgress />
      </EmptyText>
    )
  }

  if (!taskListOps.value) {
    if (!taskListOps.loading) {
      return <EmptyTaskMessage status={props.visitStatus} />
    }
    return <></>
  }

  if (!taskTypesList.value) {
    if (!taskTypesList.loading) {
      return <EmptyMessage message={t('taskTypesEmpty')} />
    }
    return <></>
  }

  const onTaskClickHandler = (taskCode: string, taskStatus: string, templateCode: string, route = 'task/'): void => {
    if (taskStatus === 'Canceled' || (props.visitStatus === 'Finished' && taskStatus === 'Planned')) {
      return
    }

    const state: ITaskRouteState = {
      taskTypeCode: templateCode,
      fromPath: location.pathname,
      taskCode: taskCode,
      visitCode,
    }
    const path = generatePath(visitsRoutes.visitTaskPage, { featureRoute, visitCode, taskCode })
    navigate(path, { state })
  }

  const representativeItem = template && findRepresentativeItem(template)

  const getTaskRepresentativeName = (task: IVisitTask | ITask): string => {
    const name = representativeItem && getTaskProperty(task, representativeItem.representativeNamePropertyName, '')
    if (!name) {
      return ''
    }
    return name
  }

  const renderTask = ({
    code,
    taskName,
    representativeName,
    status,
    onClick,
    cancellationReason,
  }: ITaskEntry): JSX.Element => {
    return (
      <Paper
        key={code}
        onClick={onClick}
        square
        className={classnames(classes.taskItem, {
          [classes.isProgressStatus]: status === 'InProgress',
          [classes.disabled]: status === 'Canceled',
          [classes.finishedStatus]: status === 'Finished',
        })}
      >
        <Tooltip title={t(status, '') as string}>
          <div
            className={classnames(classes.status, {
              [classes.statusPlanned]: status === 'Planned',
              [classes.statusFinished]: status === 'Finished',
              [classes.statusInProgress]: status === 'InProgress',
              [classes.statusCanceled]: status === 'Canceled',
            })}
          />
        </Tooltip>
        <div
          className={classnames(classes.nameWrap, {
            [classes.nameWrapIsRepresentative]: !!representativeName || !!cancellationReason,
          })}
        >
          <Typography className={classes.name} color='inherit' noWrap>
            {taskName}
          </Typography>
        </div>
        {representativeName && !cancellationReason && (
          <div className={classes.info}>
            <Typography className={classnames(classes.name, classes.nameRepresentative)} noWrap>
              {representativeName}
            </Typography>
          </div>
        )}
        {cancellationReason && (
          <div className={classes.info}>
            <OpenableTypography
              className={classnames(classes.name, classes.cancelationReason)}
              dialogTitle='Причина'
              noWrap
            >
              {cancellationReason}
            </OpenableTypography>
          </div>
        )}
        <VisitTaskMenu
          taskStatus={status as VisitTaskStatus}
          visitStatus={props.visitStatus}
          visitPlannedDate={props.visitPlannedDate}
          onCancel={async () => {
            const availableTaskCancelationReasons = await getAvailableTaskCancelationReasons(code)
            if (!availableTaskCancelationReasons?.length) {
              setCancelTaskModalOpen(code)
            } else {
              void onCancelTaskClickHandler(code)
            }
          }}
          onRepair={() => {
            setRepairModalOpen(code)
          }}
          onEditCompletedTask={() => {
            void onEditCompletedTask(code)
          }}
        />
      </Paper>
    )
  }

  const taskList = [...taskListOps.value.visits, ...taskListOps.value.registers]

  if (!taskList.length) {
    return <EmptyTaskMessage status={props.visitStatus} />
  }

  interface ITaskEntry {
    code: string
    taskName: string
    representativeName?: string
    cancellationReason?: string
    status: string
    onClick: () => void
  }

  const getEntry = (): ITaskEntry[] => {
    const list = [...taskList].map((entry) => {
      return {
        code: entry.code,
        // taskName: getTaskTypeName(entry.template.version!.code),
        taskName: entry.name,
        status: entry.status,
        representativeName: getTaskRepresentativeName(entry),
        // templateVersionCode: entry.template.version!.code,
        cancellationReason: entry.cancelationReason?.name,
        onClick: () => onTaskClickHandler(entry.code, entry.status, entry.template.code, 'task/'),
      }
    })

    switch (props.visitStatus) {
      case 'Planned':
      case 'InProgress':
      case 'Finished':
        return sortBy(list, [(o) => o.taskName]).sort((a, b) => sortEntryByStatus(a.status, b.status))

      default:
        return sortBy(list, [(o) => o.taskName])
    }
  }

  return (
    <>
      {getEntry().map((entry) =>
        renderTask({
          code: entry.code,
          taskName: entry.taskName,
          representativeName: entry.representativeName,
          status: entry.status,
          onClick: entry.onClick,
          cancellationReason: entry.cancellationReason,
        }),
      )}

      <VisitModal
        open={!!isCancelTaskModalOpen}
        onOk={() => {
          void onCancelTaskClickHandler(isCancelTaskModalOpen)
        }}
        onClose={() => setCancelTaskModalOpen(null)}
        title={t('confirmAction')}
        message={t('cancelTaskWarning')}
      />

      <VisitModal
        open={!!isRepairModalOpen}
        onOk={onRepairClickHandler}
        onClose={() => setRepairModalOpen(null)}
        title={t('confirmAction')}
        message={t('restoreTaskWarning')}
      />
    </>
  )
}

export default VisitTaskList

function sortEntryByStatus(a: string, b: string): number {
  if (!a || !b) {
    return -1
  }

  const itemOrder: string[] = ['InProgress', 'Planned', 'Finished', 'Canceled']
  return itemOrder.indexOf(a) - itemOrder.indexOf(b)
}
