import React, { useEffect, useState } from 'react'

import { Placemark } from 'react-yandex-maps'
import ymaps from 'yandex-maps'

import { Code } from '../../model/base'
import { IGeoCoordinates } from '../../model/common'

interface IYandexMapPoint {
  code: Code
  icon: ymaps.layout.templateBased.Base
  coordinates: IGeoCoordinates
  onClick: () => void
  zIndex: number
}

export const YandexMapPoint: React.FC<IYandexMapPoint> = ({ code, icon, coordinates, onClick, zIndex }) => {
  const [instancePoint, setInstancePoint] = useState<ymaps.Placemark>()

  useEffect(() => {
    instancePoint?.events.add('click', onClick)
    return () => {
      instancePoint?.events.remove('click', onClick)
    }
  }, [instancePoint])

  const lat = coordinates.latitude
  const long = coordinates.longitude

  return (
    <Placemark
      geometry={[lat, long]}
      instanceRef={(placemark: ymaps.Placemark) => {
        setInstancePoint(placemark)
      }}
      properties={{
        code: code,
      }}
      options={{
        iconLayout: 'default#imageWithContent',
        iconImageHref: '',
        iconContentLayout: icon,
        iconShape: {
          type: 'Circle',
          coordinates: [0, 0],
          radius: 25,
        },
        zIndex,
      }}
    />
  )
}