import React, { useContext } from 'react'

import { Box, Grid, Typography } from '@material-ui/core'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'

import { MaintenanceContext } from '../../providers'

export const MaintenanceMarker: React.FC = () => {
  const { maintenance } = useContext(MaintenanceContext) ?? {}

  if (!maintenance) return null

  return (
    <Box margin={1}>
      <Grid container alignItems='center' justify='center' spacing={1}>
        <ReportProblemOutlinedIcon color='action' />
        <Grid item>
          <Typography variant='h6'>Ведутся регламентные работы</Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
