import React from 'react'

import {IBusinessParameters} from "../../model/business-parameters";

export interface IBusinessSettingsContext {
  value?: IBusinessParameters
  refetch: () => Promise<void>
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const BusinessSettingsContext = React.createContext<IBusinessSettingsContext>({} as IBusinessSettingsContext)
