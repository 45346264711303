import { useContext } from 'react'

import { BlobKey, BlobMetadata } from '../../../infrastructure/blob-storage/blob-storage-api'
import { IMediaStorageSettings } from '../../../model/screen-item'
import { ApiContext } from '../../../providers'

interface DocumentMethods {
  addBlob: (document: Blob) => Promise<BlobMetadata>
  getBlob: (key: BlobKey) => Promise<Blob | undefined>
  getMetadataOrBlob: (key: BlobKey) => Promise<BlobMetadata | Blob | undefined>
  deleteBlob: (key: BlobKey) => Promise<boolean>
}

export const useDocumentMethods = (storage?: IMediaStorageSettings): DocumentMethods => {
  const api = useContext(ApiContext)
  const blobStorage = api.blobStorage
  const blobWebApi = api.blobWebApi

  const addBlob = async (document: Blob): Promise<BlobMetadata> => {
    let metadata: BlobMetadata
    switch (storage?.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.CloudMediaStorageSettings':
        metadata = await blobWebApi.add(`${getRoutePath(storage)}`, document)
        break
      case 'PMI.FACE.BDDM.Extensions.Classes.LocalMediaStorageSettings':
      default:
        metadata = await blobStorage!.add(document)
        break
    }

    return metadata
  }

  const getMetadataOrBlob = async (key: BlobKey): Promise<BlobMetadata | Blob | undefined> => {
    switch (storage?.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.CloudMediaStorageSettings':
        return await blobWebApi.get(`${getRoutePath(storage)}`, key)
      case 'PMI.FACE.BDDM.Extensions.Classes.LocalMediaStorageSettings':
      default:
        return await blobStorage!.getMetadata(key, true)
    }
  }

  const getBlob = async (key: BlobKey): Promise<Blob | undefined> => {
    switch (storage?.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.CloudMediaStorageSettings':
        return await blobWebApi.get(`${getRoutePath(storage)}`, key)
      case 'PMI.FACE.BDDM.Extensions.Classes.LocalMediaStorageSettings':
      default:
        return await blobStorage!.getBlob(key, true)
    }
  }

  const deleteBlob = async (key: BlobKey): Promise<boolean> => {
    switch (storage?.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.CloudMediaStorageSettings':
        return true // Удаление из облака запрещено (метод отсутсвует)
      case 'PMI.FACE.BDDM.Extensions.Classes.LocalMediaStorageSettings':
      default:
        return await blobStorage!.delete(key)
    }
  }

  return { addBlob, getBlob, getMetadataOrBlob, deleteBlob }
}

export const getRoutePath = (storage: IMediaStorageSettings): string => {
  if ('routePath' in storage) {
    return storage.routePath ?? ''
  }
  return ''
}