import { Code, IEntityReference, ISyncEntity } from './base'
import { IAddress, IGeoCoordinates } from './common'
import { IFieldPositionRole } from './user-profile'

export interface IPointOfSale extends ISyncEntity {
  name: string
  address?: IAddress
  coordinates?: IGeoCoordinates
  positionRoleCoverage?: Code[]
  status?: 'Active' | 'Inactive'
  posSegmentation?: 'Priority' | 'NonPriority' | 'Other' | 'PriorityLow' | 'PriorityMedium' | 'PriorityHigh'
  format?:
    | 'Hypermarket'
    | 'Supermarket'
    | 'Minimarket'
    | 'OverTheCounter'
    | 'ThroughTheWindow'
    | 'Island'
    | 'ShopInShop'
    | 'LAMP'
    | 'LargeBoutique'
    | 'SmallBoutique'
    | 'TemporaryBoutique'
    | 'Corner'
    | 'WarehouseSales'
    | 'MobileUnit'
    | 'ShopInShopIsland'
    | 'ShopInShopIntegrated'
  businessNiche?:
    | 'Food'
    | 'Petrol'
    | 'Tobacconist'
    | 'Vape'
    | 'LAMP'
    | 'RRP'
    | 'PostOffice'
    | 'NewsStand'
    | 'BeerShop'
    | 'Household'
    | 'Electronic'
    | 'Liquor'
  assortmentRange?: IEntityReference[]
  salesChannel?:
    | 'ModernTrade'
    | 'TraditionalTrade'
    | 'Delivery'
    | 'BrandRetail'
    | 'CallCenter'
    | 'DistributionCenter'
    | 'RRPProject'
  operationalEnviroment?: 'ShoppingMall' | 'InShop' | 'PMOffice' | 'Market' | 'Airport' | 'StandAlone'
  associatedLegalEntities?: IProductLocationLegalEntity[],

  // metrics
  checkOutCount?: number
  posModelStoreLevel?: string
}

export interface IPOSReference extends IEntityReference {
  $type: 'PMI.BDDM.Staticdata.POSReference'
}

export interface IProductLocationLegalEntity {
  associationType: 'OwnedBy' | 'OperatedBy' | 'RepresentedBy' | 'ControlledBy'
  legalEntity: IEntityReference
}

export function isPOSInCoverage(pos: IPointOfSale, positionRole: Code | IFieldPositionRole): boolean {
  if (!pos) throw new Error('POS must be specified')
  if (!positionRole) throw new Error('PositionRole code or FieldPositionRole must be specified')

  const positionRoleCode = (positionRole as IFieldPositionRole).code ?? (positionRole as Code)
  return pos.positionRoleCoverage?.includes(positionRoleCode) ?? false
}

export function setPOSIsInCoverage(
  pos: IPointOfSale,
  positionRole: Code | IFieldPositionRole,
  isInCoverage = true,
): void {
  if (!pos) throw new Error('POS must be specified')
  if (!positionRole) throw new Error('PositionRole code or FieldPositionRole must be specified')

  const positionRoleCode = (positionRole as IFieldPositionRole).code ?? (positionRole as Code)
  const coverage = pos.positionRoleCoverage ?? []
  pos.positionRoleCoverage = coverage
  const idx = coverage.indexOf(positionRoleCode)
  if (idx >= 0 && !isInCoverage) {
    coverage.splice(idx, 1)
  } else if (idx < 0 && isInCoverage) {
    coverage.push(positionRoleCode)
  }
}
