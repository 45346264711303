import React, { useEffect, useState } from 'react'

import { Avatar, Box, createStyles, IconButton, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { useLocation, useNavigate } from 'react-router-dom'

import { IPointOfSale } from '../../../model/pos'
import { dateFormat } from '../../../utils'
import { formatDuration } from '../../../utils/duration'
import { getPosAddress } from '../../_common/pos'
import { IStoreMenuLocationState } from '../../stores/store-menu-page/store-menu-page'
import { getContextProperty } from '../script-tasks/propertyName'
import { useScriptTaskContext } from '../script-tasks/script-task-context'
import { VisitAssessment } from './audit-merchandise-service-task'

const useStyles = makeStyles((theme) =>
  createStyles({
    posIcon: {
      width: 36,
      height: 36,
      // marginBottom: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        width: 24,
        height: 24,
      },
    },
    posTitle: {
      ...theme.typography.h1,
      [theme.breakpoints.down('xs')]: {
        ...theme.typography.h2,
      },
    },
    menuWrap: {
      margin: theme.spacing(-1, -3, -1, 'auto'),
    },
  }),
)

interface VisitCardCustomItemProps {
  children?: never
}

const numberFormatter = new Intl.NumberFormat('ru-RU')

export const VisitCardCustomItem: React.FC<VisitCardCustomItemProps> = (props) => {
  const classes = useStyles()
  const location = useLocation()
  useEffect(() => {
    console.log('VisitCardCustomItem mount', props)
  }, [])
  const localContext = useScriptTaskContext()
  const auditedVisit = getContextProperty<VisitAssessment>(localContext, 'auditedVisit')
  const navigate = useNavigate()

  if (!auditedVisit?.posExtension) return <></>

  const { startDate, endDate, distanceVariance, outOfRouteReason, source } = auditedVisit.visitExtension ?? {}
  let formattedDuration
  let formattedInterval
  if (startDate && endDate) {
    formattedDuration = formatDuration({
      start: startDate,
      end: endDate,
    })
    formattedInterval = `${dateFormat(startDate, 'HH:mm')}\u00A0-\u00A0${dateFormat(
      endDate,
      'HH:mm',
    )},\u00A0${dateFormat(startDate, 'dd.MM.yyyy')}`
  }

  const infoRow = [
    auditedVisit.posExtension.code,
    auditedVisit.posExtension.ownedBy,
    `Продолжительность:\u00A0${formattedDuration?.replace(/ /g, '\u00A0') ?? 'Не завершен'}`,
    formattedInterval,
  ]
    .filter((t) => t)
    .join(' | ')

  const formattedDistance = distanceVariance
    ? `${numberFormatter.format(Math.round(distanceVariance.value))} ${distanceVariance.unitOfMeasure}`
    : 'нет данных'

  return (
    <>
      <Box display='flex' mt={-1} mb={1}>
        <Avatar className={classes.posIcon} />
        <Box pl={2}></Box>
        <Typography className={classes.posTitle}>{auditedVisit.posExtension.name}</Typography>
        <div className={classes.menuWrap}>
          <GenericMenu>
            <MenuItem
              onClick={() => {
                const state: IStoreMenuLocationState = {
                  prevPath: location.pathname,
                  rootPrevPath: location.pathname,
                }
                return navigate(`/stores/store/${auditedVisit.posExtension!.code}/menu`, { state })
              }}
            >
              Торговая точка
            </MenuItem>
          </GenericMenu>
        </div>
      </Box>

      <Typography variant='body2'>
        {getPosAddress({ address: auditedVisit.posExtension } as unknown as IPointOfSale)}
      </Typography>
      <Typography variant='body2' color='textSecondary'>
        {infoRow}
      </Typography>
      <Typography variant='body2' color='textSecondary'>
        Отклонение координат: {formattedDistance}
      </Typography>
      <Typography variant='body2' color='textSecondary'>
        {source === 'RoutePlan' ? 'RP' : 'OOR'}
        {outOfRouteReason && ` | ${outOfRouteReason.name}`}
      </Typography>
      <Box pb={3}></Box>
    </>
  )
}

const GenericMenu: React.FC = ({ children }) => {
  const [anchor, setAnchor] = useState<HTMLElement>()
  return (
    <>
      <IconButton onClick={(ev) => setAnchor(ev.currentTarget)}>
        <MoreVertIcon />
      </IconButton>
      <Menu open={!!anchor} anchorEl={anchor} onClose={() => setAnchor(undefined)}>
        {children}
      </Menu>
    </>
  )
}
