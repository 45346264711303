import React, { useCallback, useEffect } from 'react'

import { uniq } from 'lodash'
import { useAsyncFn } from 'react-use'
import { createContext, useContext, useContextSelector } from 'use-context-selector'

import { IPOSTaskRegister } from '../../../model/pos-task-register'
import { IProblem } from '../../../model/problem'
import { ITaskExecutionScope, ITaskExecutionState } from '../../../model/task-execution'
import { ApiContext, ConfigContext, ProfileContext } from '../../../providers'
import { isNonNullable } from '../../../utils/isNonNullable'
import { useAsyncError } from '../../_common/hooks/useAsyncError'
import { useBusinessSettings } from '../../_common/hooks/useBusinessSettings'
import { useWebUrl } from '../../_common/hooks/useWebUrl'
import { fetchAllTaskSurveys, ILocalContext } from '../nested/local-context'
import { applyNesting, NestedContext, SubProcessContext } from '../nested/nested-context'
import { tryFindTaskTemplate } from '../nested/tryFindTaskTemplate'

export interface IScriptTaskContext extends ILocalContext {
  problem?: IProblem
  register?: IPOSTaskRegister
}

const initialContext = {} as IScriptTaskContext

export const ScriptTaskContext = createContext<IScriptTaskContext>(initialContext)

export function useScriptTaskContext(): IScriptTaskContext
export function useScriptTaskContext<T>(selector: (context: IScriptTaskContext) => T): T
export function useScriptTaskContext<T>(selector?: (context: IScriptTaskContext) => T): T | IScriptTaskContext {
  const topContext = useContext(ScriptTaskContext)
  const subProcessContext = React.useContext(SubProcessContext)
  const nestedContext = React.useContext(NestedContext)
  const getMergedContext = useCallback(
    (context: IScriptTaskContext) => applyNesting(context, subProcessContext, nestedContext),
    [subProcessContext, nestedContext],
  )

  if (selector) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useContextSelector(ScriptTaskContext, (context) => selector(getMergedContext(context)))
  }
  return getMergedContext(topContext)
}

interface Props {
  taskCode: string
  visitCode?: string
}

export const ScriptTaskContextProvider: React.FC<Props> = ({ children, taskCode, visitCode }) => {
  const api = React.useContext(ApiContext)
  const profileContext = React.useContext(ProfileContext)
  const webUrl = useWebUrl()
  const config = React.useContext(ConfigContext)
  const businessParameters = useBusinessSettings()

  const [contextOps, fetchContext] = useAsyncFn(
    async () => {
      const task = await api.tasks.getTask(taskCode)
      if (!task) throw new Error(`task ${taskCode} not found`)
      console.log('task.surveys', task.surveys)
      let visit
      let pos
      if (visitCode) {
        visit = await api.visits.getVisit(visitCode)
        if (!visit) throw new Error(`visit ${visitCode} not found`)
        pos = await api.pos.getPos(visit.pointOfSaleCode)
        if (!pos) throw new Error(`pos ${visit.pointOfSaleCode} not found`)
      }
      const template = await tryFindTaskTemplate(api, profileContext.value!.profile, task)

      const surveys = await fetchAllTaskSurveys(task, template, api)
      const questionnaires = await api.questionnaire.getQuestionnaires(
        uniq(
          Object.values(surveys)
            .flat()
            .map((survey) => survey.questionnaire.code),
        ),
      )

      const fullScope: ITaskExecutionScope = (await api.tasks.getTaskExecutionScope(taskCode)) ?? { task: {} }
      const taskState: ITaskExecutionState = (await api.tasks.getTaskExecutionState(taskCode)) ?? {
        currentStep: 0,
        taskCode,
        subProcesses: {},
      }

      const res: IScriptTaskContext = {
        fieldPositionRole: profileContext.value?.fieldPositionRole,
        employee: profileContext.value?.employee,
        profileCode: profileContext.value?.profile?.code,
        participantProfile: profileContext.value.participantProfile,
        task,
        visit: visit ?? undefined,
        template,
        pos: pos ?? undefined,
        surveys,
        questionnaires: questionnaires.filter(isNonNullable),
        taskState,
        scope: fullScope.task,
        fullScope,
        apiUrl: config.config.apiUrl,
        webUrl,
        businessParameters,
        readOnly: task.status === 'Finished',
      }
      return res
    },
    [],
    { loading: true },
  )

  useEffect(() => {
    void fetchContext()
    console.log('ScriptTaskContextProvider mounted', { taskCode, visitCode })
  }, [])

  const context = contextOps.value ?? initialContext

  useAsyncError(contextOps.error)
  if (contextOps.loading && !contextOps.value?.task) return <></>

  return <ScriptTaskContext.Provider value={context}>{children}</ScriptTaskContext.Provider>
}
