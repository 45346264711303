import React, { useEffect } from 'react'

import { createStyles, IconButton, makeStyles, OutlinedInput, Theme } from '@material-ui/core'

import { grays } from '../../../../../layout/theme'

interface IStylesProps {
  isError: boolean
}

const useStylesInput = makeStyles<Theme, IStylesProps>((theme: Theme) =>
  createStyles({
    root: {
      width: 'auto',
      maxWidth: '147px',
      borderRadius: 0,
      height: 40,
      border: 'none',
      [theme.breakpoints.down('xs')]: {
        height: 26,
        marginRight: 11,
        marginTop: 6,
        marginBottom: 6,
      },
    },
    input: {
      margin: '0 8px',
      width: 50,
      height: 40,
      padding: 0,
      border: `1px solid ${grays.gray4}`,
      borderColor: ({ isError }) => (isError ? theme.palette.error.main : grays.gray4),
      borderRadius: 4,
      textAlign: 'center',
      fontSize: 18,
      lineHeight: '21px',
      appearance: 'none',
      boxShadow: 'none',
      color: ({ isError }) => (isError ? theme.palette.error.main : 'inherit'),
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        display: 'none',
      },
      [theme.breakpoints.down('xs')]: {
        width: 45,
        height: 26,
        fontSize: 16,
      },
    },
    adornedStart: {
      padding: 0,
    },
    adornedEnd: {
      padding: 0,
    },
    notchedOutline: {
      border: 0,
    },
  }),
)

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) =>
  createStyles({
    button: {
      padding: 0,
      width: 40,
      height: 40,
      borderRadius: '50%',
      backgroundColor: 'rgba(48, 119, 184, 0.1)',
      '&:hover': {
        backgroundColor: 'rgba(48, 119, 184, 0.1)',
      },
      '&:disabled': {
        backgroundColor: 'rgba(48, 119, 184, 0.1)',
        opacity: 0.5,
      },

      [theme.breakpoints.down('xs')]: {
        width: 26,
        height: 26,
        '& svg': {
          width: 16,
          height: 16,
        },
      },
    },
  }),
)

interface INumberEditorProps {
  value?: number | string
  onChange: (value: number | string) => void
  onBlur?: () => void
  showIncreaseButtons?: boolean
  minValue?: number
  maxValue?: number
  defaultValue?: number
  incrementValue?: number
  isRequired?: boolean
  disabled?: boolean
}

export const MIN_NUMERIC_VALUE = 0
export const MAX_NUMERIC_VALUE = 100

export const NumberEditor: React.FC<INumberEditorProps> = ({
  value,
  onChange,
  onBlur,
  showIncreaseButtons = false,
  minValue = MIN_NUMERIC_VALUE,
  maxValue = MAX_NUMERIC_VALUE,
  defaultValue,
  incrementValue = 1,
  isRequired,
  disabled,
}) => {
  useEffect(() => {
    console.log('NumberEditor mount, value:', value)
    if (value !== undefined) return
    if (typeof defaultValue !== 'number') return

    onChange(defaultValue)
  }, [value])

  // let isError = isRequired ? value === '' : false
  // if (!isError && typeof value === 'number') {
  //   isError = checkNumericRangeError(Number(value), minValue, maxValue)
  // }
  const isError = checkNumericError(value, !!isRequired, minValue, maxValue)

  const classesInput = useStylesInput({ isError })
  const classes = useStyles({ isError })

  const clickButtonHandler = (entry: number): void => {
    if (!checkNumericRangeError(entry, minValue, maxValue)) {
      onChange(entry)
    } else {
      onChange(minValue ?? 0)
    }
  }

  return (
    <OutlinedInput
      disabled={disabled}
      classes={classesInput}
      startAdornment={
        showIncreaseButtons &&
        !disabled && (
          <IconButton
            disabled={!!(typeof value === 'number' && String(minValue) && value <= minValue!)}
            className={classes.button}
            type='button'
            color='primary'
            onClick={() => {
              if (typeof value !== 'number') {
                onChange(minValue ?? 0)
                return
              }

              const entry = value - incrementValue || 0
              clickButtonHandler(entry)
            }}
          >
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M18 12L6 12' stroke='#3077B8' strokeWidth='2' strokeLinecap='round' />
            </svg>
          </IconButton>
        )
      }
      endAdornment={
        showIncreaseButtons &&
        !disabled && (
          <IconButton
            disabled={!!(typeof value === 'number' && maxValue && value >= maxValue)}
            className={classes.button}
            type='button'
            color='primary'
            onClick={() => {
              if (typeof value !== 'number') {
                onChange(minValue ?? 0)
                return
              }

              const entry = value + incrementValue || 0
              clickButtonHandler(entry)
            }}
          >
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M12 6L12 18' stroke='#3077B8' strokeWidth='2' strokeLinecap='round' />
              <path d='M18 12L6 12' stroke='#3077B8' strokeWidth='2' strokeLinecap='round' />
            </svg>
          </IconButton>
        )
      }
      value={value}
      onChange={(evt) => {
        const value = evt.target.value === '' ? '' : Number(evt.target.value)
        onChange(value)
      }}
      onBlur={onBlur}
      type='number'
    />
  )
}

const checkIsInteger = (value: number): boolean => Number.isInteger(value)

export const checkNumericRangeError = (value: number, min = MIN_NUMERIC_VALUE, max = MAX_NUMERIC_VALUE): boolean => {
  const isRangeDownError = value < min
  const isRangeUpError = value > max
  return isRangeDownError || isRangeUpError || !checkIsInteger(value)
}

export const checkNumericError = (
  value: number | string | null | undefined,
  isRequired: boolean,
  min: number | undefined,
  max: number | undefined,
): boolean => {
  let isError = isRequired ? value === '' || value === null : false
  if (!isError && typeof value === 'number') {
    isError = checkNumericRangeError(Number(value), min, max)
  }

  return isError
}
