import { IAnswer } from '../../model/answer'
import { IQuestion } from '../../model/question'
import { findUnansweredQuestion } from '../tasks/sales-expert/tabs-new/trainee-skills/predicate-questions'
import { IValidation } from '../tasks/template-tasks/composite-screen/validate'
import { findRequiredAttachment } from './answer-requirements-utils'
import { useNumericState, validateNumeric } from './numeric-question/numeric-question'
import { validatePhotoQuestion } from './photo-question/photo-question'
import { useTextState } from './text-question/text-question-body'

export const validateAnswer = (answer: IAnswer | undefined, question: IQuestion | undefined): IValidation => {
  // if (isNullAnswer(answer)) {
  //   return {
  //     isError: false,
  //   }
  // }

  // if (question?.required === false) {
  //   return {
  //     isError: false,
  //   }
  // }

  if (!answer) {
    return {
      isError: true,
      message: 'Даны ответы не на все вопросы',
    }
  }

  if (
    answer?.$type === 'PMI.FACE.BDDM.Extensions.Classes.FaceTextAnswer' &&
    question?.$type === 'PMI.FACE.BDDM.Extensions.Classes.FaceTextQuestion'
  ) {
    const value = useTextState.getState().store[question.code] ?? answer.reply
    const trimValue = value?.trim()

    if (question?.required === false && !trimValue) {
      return {
        isError: false,
      }
    }

    return {
      isError: !trimValue,
    }
  }

  if (
    answer?.$type === 'PMI.FACE.BDDM.Extensions.Classes.FacePhotoAnswer' &&
    question?.$type === 'PMI.FACE.BDDM.Extensions.Classes.FacePhotoQuestion'
  ) {
    return {
      isError: !validatePhotoQuestion(
        answer.reply.parts.length,
        question?.minPhotoCountRequired,
        question?.maxPhotoCountAvailable,
      ),
      message:
        question.errorHelperText ??
        `Необходимо сделать от ${question.minPhotoCountRequired} до ${question.maxPhotoCountAvailable} фотографий`,
    }
  }

  if (
    (answer?.$type === 'PMI.FACE.BDDM.Extensions.Classes.FaceNumericAnswer' || answer?.$type === 'PMI.FACE.BDDM.Extensions.Classes.FaceNullAnswer') &&
    question?.$type === 'PMI.FACE.BDDM.Extensions.Classes.FaceNumericQuestion'
  ) {
    const value = useNumericState.getState().store[question.code]

    let isError = false
    switch (answer?.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.FaceNumericAnswer':
        isError = validateNumeric(value === undefined ? answer.reply : value, question?.minValue, question?.maxValue)
        break
      case 'PMI.FACE.BDDM.Extensions.Classes.FaceNullAnswer':
        if (value) {
          isError = validateNumeric(value, question?.minValue, question?.maxValue)
        }
        break
    }
    return {
      isError: isError,
      message:
        question.errorHelperText ??
        `Необходимо ввести целое число в диапазоне от ${question.minValue} до ${question.maxValue}`,
    }
  }

  return {
    isError: false,
  }
}

export function validateEsc(
  availableQuestions: IQuestion[],
  answers: IAnswer[] = [],
): { message?: string; invalidQuestion?: IQuestion } {
  const unansweredQuestion = findUnansweredQuestion(availableQuestions, answers)
  if (unansweredQuestion?.invalidQuestion) {
    return {
      message: unansweredQuestion.message,
      invalidQuestion: unansweredQuestion.invalidQuestion,
    }
  }
  const requiredAttachment = findRequiredAttachment(availableQuestions, answers)
  if (requiredAttachment) {
    return {
      message: 'Добавьте фото к вопросу',
      invalidQuestion: requiredAttachment,
    }
  }

  return {}
}
