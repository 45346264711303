import React from 'react'

import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { useTranslation } from 'react-i18next'

import { VisitsViewMode } from '../../models'

interface IVisitsViewSwitcherProps {
  currentViewMode: VisitsViewMode
  onChangeViewMode: (newValue: VisitsViewMode) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 40,
      backgroundColor: '#F2F3F4',
      borderRadius: 40,
      marginRight: 24,
      '& .Mui-selected': {
        background: theme.palette.primary.main.concat('!important'),
        color: 'white',
        border: 0
      },
      '& > button': {
        textTransform: 'unset',
        color: theme.palette.common.black,
        fontWeight: 500,
        fontSize: 14,
        minWidth: 90,
        border: '0',
        borderRadius: 40,
        padding: '10px 20px',
        [theme.breakpoints.down('xs')]: {
          minWidth: 84,
          padding: '9px 17px',
        }
      },
      '& > button:hover': {
        background: 'unset',
        border: 0
      },
      '& > button:not(:first-child)': {
        borderLeft: 0,
        marginLeft: 0,
        borderTopLeftRadius: 40,
        borderBottomLeftRadius: 40,
      },
      '& > button:not(:last-child)': {
        borderTopRightRadius: 40,
        borderBottomRightRadius: 40,
      },
      [theme.breakpoints.down('xs')]: {
        marginRight: 8,
        height: 36
      }
    }
  })
)

const VisitsViewSwitcher: React.FC<IVisitsViewSwitcherProps> = ({ currentViewMode, onChangeViewMode }) => {
  const classes = useStyles()
  const { t } = useTranslation('visits')

  const handleChangeViewMode = (event: React.MouseEvent<HTMLElement>, newValue: VisitsViewMode | null): void => {
    if (!newValue) {
      return
    }
    onChangeViewMode(newValue)
  }

  return (
    <ToggleButtonGroup
      className={classes.root}
      size='small'
      value={currentViewMode}
      exclusive
      onChange={handleChangeViewMode}
      aria-label='text alignment'
    >
      <ToggleButton value='day' aria-label='day'>
        {t('periodDay')}
      </ToggleButton>
      <ToggleButton value='week' aria-label='week'>
        {t('periodWeek')}
      </ToggleButton>
      <ToggleButton value='month' aria-label='month'>
        {t('periodMonth')}
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export default React.memo(VisitsViewSwitcher)
