import React, { useCallback, useContext, useState } from 'react'

import { Box, createStyles, Theme, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { ViewModeButton } from '../../../components/yandex-map-custom/components/view-mode-button'
import { PageContent } from '../../../layout/page-content'
import { TitleBar } from '../../../layout/title-bar'
import { SHOW_POSES_ON_MAP } from '../../../model/user-profile'
import { ProfileContext } from '../../../providers'
import { AppRoutes } from '../../../routes'
import { StoresSearch } from '../components'
import StoresList from '../stores-list/stores-list-virtual'
import { StoreMapWrapper } from '../stores-yandex-map/store-map-wrapper'
import { useSearchQuery } from './query-store'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageContainer: {
      height: 'calc(100vh - 88px)',
    },
    searchWrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: 'calc(100% - 60px) auto',
      },
    },
    viewModeButton: {
      justifySelf: 'end',
      backgroundColor: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#FFFFFF',
      },
      [theme.breakpoints.down('xs')]: {
        height: '100%',
      },
    },
  }),
)

const StoresPage: React.FC = () => {
  const classes = useStyles()

  const profile = useContext(ProfileContext)
  const permissions = profile?.value?.profile.permissions
  const isMapViewPermissions = permissions?.find((permission) => permission.code === SHOW_POSES_ON_MAP)

  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'))
  const { query, setQuery } = useSearchQuery()

  const location = useLocation()
  const state = location.state as { isViewMap: boolean }

  const [uiState, setUiState] = useState<'map' | 'list'>(state?.isViewMap ? 'map' : 'list')
  const { t } = useTranslation('menu')

  const onSearchHandler = useCallback((queryString: string): void => {
    setQuery(queryString)
  }, [])

  const validQuery = query.length > 2 ? query : ''

  return (
    <>
      <TitleBar>{t(AppRoutes.stores.path)}</TitleBar>
      <PageContent className={classes.pageContainer}>
        <Box paddingX={isSmall ? 1 : 3} paddingY={3} display='flex' flexDirection='column' height='100%'>
          {/*<StoresViewSwitcher />*/}
          <div className={classes.searchWrapper}>
            <StoresSearch query={query} onChange={onSearchHandler} />

            {isMapViewPermissions && (
              <ViewModeButton
                className={classes.viewModeButton}
                uiState={uiState}
                onClick={() => {
                  setUiState(uiState !== 'list' ? 'list' : 'map')
                }}
              />
            )}
          </div>
          {uiState === 'list' ? (
            <StoresList searchQuery={validQuery} menuAccess={true} />
          ) : (
            <StoreMapWrapper searchQuery={validQuery} />
          )}
        </Box>
      </PageContent>
    </>
  )
}

export default StoresPage
