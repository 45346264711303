import React, { useContext, useLayoutEffect, useState } from 'react'

import { AuthContext } from '../../providers'
import LoginPageByCredentials from './login-page-by-credentials'
import LoginPageBySso from './login-page-by-sso'

type AuthMethod = 'by_credential' | 'sso'

const FORCE_AUTH_METHOD: null | AuthMethod = null // 'by_credential'

const LoginSwitcher: React.FC = () => {
  const { authService } = useContext(AuthContext)
  const [authMethod, setAuthMethod] = useState<AuthMethod>('sso')

  useLayoutEffect(() => {
    if (FORCE_AUTH_METHOD) {
      return setAuthMethod(FORCE_AUTH_METHOD)
    }
    const isCredentialsRequired = authService.requireCredentials
    if (isCredentialsRequired) {
      setAuthMethod('by_credential')
    } else {
      setAuthMethod('sso')
    }
  }, [])

  switch (authMethod) {
    case 'by_credential':
      return <LoginPageByCredentials />
    case 'sso':
      return <LoginPageBySso />
  }
}

export default LoginSwitcher
