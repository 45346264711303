import React from 'react'

import { Button, Paper, Typography } from '@material-ui/core'

const PushDebug: React.FC = () => {
  return (
    <>
      <Paper elevation={5} style={{ padding: 8, marginBottom: 24 }}>
        <Typography variant='h6' color='inherit'>
          Push notification:
        </Typography>

        <span>
          Permission status: <b>{getPermissionStatus()}</b>
        </span>
        <br />
        <span>
          Check if push messaging is supported: <b>{isPushSupported().toString()}</b>
        </span>
        <br />
        <Button variant='contained' onClick={async () => await requestPermission()} style={{ margin: 24 }}>
          request permission
        </Button>
        <br />
        <Button variant='contained' onClick={async () => await sendNotification()} style={{ margin: 24 }}>
          send push notification
        </Button>
        <br />
        <Button variant='contained' onClick={async () => await sendNotificationTimeout(5000)} style={{ margin: 24 }}>
          send push notification after 5 sec
        </Button>
      </Paper>
    </>
  )
}

async function sendNotificationTimeout(timeout: number): Promise<void> {
  await new Promise((resolve) => setTimeout(resolve, timeout))
  await sendNotification()
}

async function sendNotification(): Promise<void> {
  const title = 'Yay a message.'
  const body = 'We have received a push message.'
  const icon = '/icon-192x192.png'
  const tag = 'simple-push-demo-notification-tag'

  const registration = await navigator.serviceWorker.getRegistration()
  console.log('registration', registration)
  if (!registration) {
    return
  }
  try {
    await registration.showNotification(title, {
      body,
      icon,
      tag
    })
  } catch (e) {
    console.error('send notification was failed:', e)
  }

  // на мобильных устройствах запрещено использовать конструктор Notification.
  // И для решения этой проблемы нужно использовать ServiceWorkerRegistration.showNotification()
  // const notification = new Notification(title, {
  //   body: body,
  //   icon: icon,
  //   tag: tag
  // })
  // console.log('notification', notification)
}

async function requestPermission(): Promise<void> {
  try {
    const permissionStatus = await Notification.requestPermission()
    console.log('Notification permission status:', permissionStatus)
  } catch (e) {
    console.error('Request notification permission was failed:', e)
  }
}

// Check if push messaging is supported
function isPushSupported(): boolean {
  if (!('PushManager' in window)) {
    console.warn("Push messaging isn't supported.")
    return false
  }
  return true
}

function getPermissionStatus(): string {
  if (!('Notification' in window)) {
    return 'This browser does not support desktop notification'
  }
  return Notification.permission
}

export default PushDebug
