import React from 'react'

import { IBlobStorage } from '../../infrastructure/blob-storage/blob-storage-api'
import { IDumpService } from '../../infrastructure/dump-service/dump-service-api'
import { IEncryptionService } from '../../infrastructure/encryption'
import { IHttpClientFactory } from '../../infrastructure/http-client-factory'
import { IStorageService } from '../../infrastructure/storage-service'
import { IUserProfileService } from '../../infrastructure/user-profile'
import { IBlobWebApi } from '../../services-admin/blob-web-api'
import { IAuditService } from '../../services/audit-service-api'
import { IDataCollectionServiceApi } from '../../services/data-collection-service-api'
import { IDictionaryService } from '../../services/dictionary-service-api'
import { IDocumentService } from '../../services/document-service-api'
import { IDteService } from '../../services/dte-service-api'
import { IEmployeeService } from '../../services/employee-service-api'
import IFprUserProfileService from '../../services/fpr-user-profile-service-api'
import { IPointOfSaleService } from '../../services/pos-service-api'
import IPOSTaskRegisterService from '../../services/pos-task-register-service-api'
import { IProblemService } from '../../services/problem-service-api'
import { IQuestionnaireService } from '../../services/questionnaire-service-api'
import { IRichTextService } from '../../services/rich-text-service-api'
import ISupervisedService from '../../services/supervised-service-api'
import ISurveyService from '../../services/survey-service-api'
import ITaskService from '../../services/task-service-api'
import IVisitService from '../../services/visit-service-api'

export interface IApiContext {
  storageService: IStorageService | null
  visits: IVisitService
  pos: IPointOfSaleService
  tasks: ITaskService
  survey: ISurveyService
  questionnaire: IQuestionnaireService
  employee: IEmployeeService
  audit: IAuditService
  userProfile: IUserProfileService
  dictionary: IDictionaryService
  blobStorage?: IBlobStorage
  blobWebApi: IBlobWebApi
  posTaskRegister?: IPOSTaskRegisterService
  document?: IDocumentService
  problem: IProblemService
  dte: IDteService
  encryption: IEncryptionService
  richtext: IRichTextService
  dumpService: IDumpService
  dataCollections: IDataCollectionServiceApi
  supervised: ISupervisedService
  fprUserProfiles: IFprUserProfileService

  _httpClientFactory: IHttpClientFactory
}

const ApiContext = React.createContext<IApiContext>({} as IApiContext)

export default ApiContext
