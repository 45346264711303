import { isNonNullable } from '../../../utils/isNonNullable'
import { FieldDecl, Type, TypeDecl, TypeRef } from './models'

interface TypeResolutionOptions {
  type?: TypeHandler
}

type TypeHandler = (type: Type) => Type | undefined | null

export class Typespace {
  static unwrapOptional(type: Type) {
    if (type.kind === 'TypeRef' && type.name === 'Optional') {
      return type.typeArgs[0]
    }
  }

  static unwrapList(type: Type) {
    if (type.kind === 'TypeRef' && type.name === 'List') {
      return type.typeArgs[0]
    }
  }

  static composeTypeHandlers(handlers: TypeHandler[]): TypeHandler {
    return function (type) {
      for (const handler of handlers) {
        const changedType = handler(type)
        if (changedType) {
          return changedType
        }
      }
    }
  }

  static defaultTypeNameFormatter(typeName: string): string {
    return typeName.split('.').at(-1)!
  }

  constructor(types: TypeDecl[]) {
    this.typeMap = new Map(types.map((type) => [type.name, type]))
  }

  private readonly typeMap: Map<string, TypeDecl>

  findByName(typeName: string) {
    return this.typeMap.get(typeName)
  }

  getDisplayName(type: Type, options?: { formatTypeName?: (typeName: string) => string }): string | undefined {
    const formatTypeName = options?.formatTypeName ?? Typespace.defaultTypeNameFormatter

    switch (type.kind) {
      case 'StringLiteral':
        return `'${type.value}'`

      case 'TypeRef':
        if (type.name === 'Optional') {
          return this.getDisplayName(type.typeArgs[0], options)
        } else {
          const typeName = formatTypeName(type.name)

          if (type.typeArgs.length > 0) {
            return `${typeName}<${type.typeArgs
              .map((typeArg) => this.getDisplayName(typeArg, options))
              .filter(Boolean)
              .join(', ')}>`
          } else {
            return typeName
          }
        }

      case 'Union':
        return type.types
          .map((x) => this.getDisplayName(x, options))
          .filter(Boolean)
          .join(' | ')

      default:
        return undefined
    }
  }

  getAllFields(type: Type, options?: TypeResolutionOptions): FieldDecl[] {
    if (options?.type) {
      const changedType = options.type(type)
      if (changedType) {
        return this.getAllFields(changedType, options)
      }
    }

    switch (type.kind) {
      case 'StringLiteral':
        return []

      case 'Enum':
        return []

      case 'TypeRef': {
        // TODO probably not needed anymore
        if (type.name === 'Optional') {
          return this.getAllFields(type.typeArgs[0], options)
        }
        const typeDecl = this.typeMap.get(type.name)
        if (!typeDecl) {
          return []
        }
        return this.getAllFields(typeDecl.type, options)
      }

      case 'Union':
        return Array.from(new Set(type.types.flatMap((variant) => this.getAllFields(variant, options))))

      case 'Record': {
        if (type.baseType) {
          const baseTypeFields = this.getAllFields(type.baseType, options)
          return Array.from(new Set([...baseTypeFields, ...type.fields]))
        } else {
          return type.fields
        }
      }
    }
  }

  getFieldType(type: Type, path: Array<string | number | null>, options?: TypeResolutionOptions): Type | null {
    if (options?.type) {
      const changedType = options.type(type)
      if (changedType) {
        return this.getFieldType(changedType, path, options)
      }
    }

    const [seg, ...rest] = path

    if (!isNonNullable(seg)) {
      return type
    }

    if (typeof seg === 'number') {
      const listTypes = this.tryFindListTypes(type)
      if (listTypes.length > 0) {
        return this.getFieldType(this.makeUnion(listTypes.map((listType) => listType.typeArgs[0])), rest, options)
      } else {
        console.warn('trying to index non-list type')
        return null
      }
    }

    const allFields = this.getAllFields(type, options)
    const matchedFields = allFields.filter((x) => x.name === seg)

    return this.getFieldType(this.makeUnion(matchedFields.map((x) => x.type)), rest, options)
  }

  makeUnion(types: Type[]): Type {
    if (types.length === 0) {
      console.warn('trying to make zero-union')
    }
    if (types.length === 1) {
      return types[0]
    }
    return {
      kind: 'Union',
      types: types,
    }
  }

  private tryFindListTypes(type: Type): TypeRef[] {
    if (type.kind === 'TypeRef' && type.name === 'List') {
      return [type]
    }
    if (type.kind === 'TypeRef' && type.name === 'Optional') {
      return this.tryFindListTypes(type.typeArgs[0])
    }
    if (type.kind === 'Union') {
      return type.types.flatMap((x) => this.tryFindListTypes(x)).filter(isNonNullable)
    }
    return []
  }
}
