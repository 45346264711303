import React, { forwardRef, useEffect } from 'react'

import { useAsync, useLatest } from 'react-use'

import { ISwitchStep, ISwitchStepCase } from '../../../model/task-execution-process'
import { useAsyncError } from '../../_common/hooks/useAsyncError'
import { useLocalContextService } from '../nested/local-context'
import { runPureScript } from '../script-tasks/run-script'
import { useScriptTaskContext } from '../script-tasks/script-task-context'
import { ScreenRef } from '../template-tasks/types'
import { SwitchCaseControl } from './switch-case-control'

interface Props {
  step: ISwitchStep
  onReadyChange: (isReady: boolean) => void
  nextButton: JSX.Element | undefined
  onScriptError: (error: Error) => void
}

export const SwitchStepControl = forwardRef<ScreenRef, Props>(function SwitchStepControl(props, ref) {
  useEffect(() => {
    console.log('Switch step mount', props)
  }, [])
  const { step, onReadyChange, nextButton, onScriptError } = props
  const localContext = useScriptTaskContext()
  const contextService = useLocalContextService()
  const template = localContext.template
  const contextRef = useLatest(localContext)

  const stepOps = useAsync(async () => {
    await contextService.onLoad()
    await contextService.refetch()
    const res: ISwitchStepCase[] = []
    for (const switchCase of step.cases ?? []) {
      const predicate = template.scripts[switchCase.condition.script.code]
      // console.log('running predicate', predicate)
      if (predicate) {
        const result = await runPureScript(predicate, contextRef.current, contextService)
        if (result) {
          res.push(switchCase)
        }
      }
    }
    return res
  }, [step.code])

  useAsyncError(stepOps.error)

  if (!step.cases?.length) {
    return <>Не заполнен список вариантов выполнения</>
  }

  // TODO: maybe add loader
  if (stepOps.loading && !stepOps.value) return <></>

  switch (stepOps.value?.length) {
    case 0:
      return <>Не выполнено ни одно из условий активации</>
    case 1:
      return (
        <SwitchCaseControl
          screenRef={ref}
          nextButton={nextButton}
          onReadyChange={onReadyChange}
          switchCase={stepOps.value?.[0]}
          onScriptError={onScriptError}
        />
      )
    default:
      return <>Не выполнено ни одно (более одного) из условий активации</>
  }
})
