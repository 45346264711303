import { IFaceDataCatalog } from '../features/admin/data-manager/models/models'
import { IHttpClient } from '../infrastructure/http-client-factory'
import { IGenericUserReference } from '../model/user'
import { IUserProfileReference } from '../model/user-profile'

export class DataCatalogService {
  constructor(private readonly _http: IHttpClient) {}

  async getCatalog(user: IGenericUserReference, profile: IUserProfileReference): Promise<IFaceDataCatalog> {
    const response = await this._http.request({
      method: 'POST',
      url: 'catalog/by-user',
      data: {
        user,
        profile,
      },
    })
    return response.data
  }

  async updateCatalog(catalog: IFaceDataCatalog): Promise<IFaceDataCatalog> {
    const response = await this._http.request({
      method: 'POST',
      url: 'catalog/update',
      data: {
        DataCatalog: catalog,
      },
    })
    return response.data
  }

  async createDefaultCatalog(user: IGenericUserReference, profile: IUserProfileReference): Promise<IFaceDataCatalog> {
    const response = await this._http.request({
      method: 'POST',
      url: 'catalog/create/by-default',
      data: {
        user,
        profile,
      },
    })
    return response.data
  }
}
