import { CompositeLogger } from './composite-logger'
import { ConsoleLogger } from './console-logger'
import { DelegateLogger } from './delegate-logger'
import { LogContext, LogContextItemKey, LoggerBase, LogLevel } from './logger-api'
import { LoggerService } from './logger-service'

export class LogManager {
  private readonly _context: LogContext = {}
  private readonly _loggerService = new LoggerService(this._context, LogLevel.userMetric)

  public setContextItem(key: LogContextItemKey, value: unknown): void {
    if (value == null) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete this._context[key]
    } else {
      this._context[key] = value
    }
  }

  public getService(): LoggerService {
    return this._loggerService
  }

  public flush(): void {
    void this.getService().flush()
  }

  public getLogger(name: string): LoggerBase {
    return new CompositeLogger([new ConsoleLogger(name, LogLevel.debug), new DelegateLogger(name, this._loggerService)])
  }
}
