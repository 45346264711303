import { useContext } from 'react'

import { IScriptTaskContext } from '../script-tasks/script-task-context'
import { useLocalContextService } from './local-context'
import { SubProcessContext } from './nested-context'
import { resolvePropertyName } from './useUpdateProperty'

export function useBatchUpdate(): (dict: Record<string, unknown>) => Promise<IScriptTaskContext> {
  const subProcessStack = useContext(SubProcessContext)
  const contextService = useLocalContextService()

  return async (dict: Record<string, unknown>) => {
    const changes: Array<[string, unknown]> = []
    for (const [propertyName, value] of Object.entries(dict)) {
      const path = resolvePropertyName(propertyName, subProcessStack)
      await contextService.updateProperty(path, value)
      changes.push([path, value])
    }
    await contextService.updateStackSteps()
    console.log('batch update changes', changes)
    return contextService.refetch(changes)
  }
}
