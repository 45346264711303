import React, { useContext } from 'react'

import { useAsync } from 'react-use'

import { IStringPropertyScreenItem, SELastFurtherFocusScreenItem } from '../../../../../model/screen-item'
import { IVisitTask } from '../../../../../model/visit-task'
import { ApiContext } from '../../../../../providers'
import { getTaskProperty } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { StringItem } from '../string-item'

interface Props {
  item: SELastFurtherFocusScreenItem
}

export const LastFurtherFocus: React.FC<Props> = ({ item }) => {
  const api = useContext(ApiContext)
  const task = useScriptTaskContext((context) => context.task)

  const lastFurtherFocus = useAsync(async () => {
    const resultTask: IVisitTask | undefined = await api.tasks.findLastFurtherFocus(task as IVisitTask)
    return getTaskProperty(resultTask!, item.futherFocusPropertyName)
  }, [task.code, item.futherFocusPropertyName])

  const stringItem = {
    actionKind: 'View',
    displayName: item.displayName
  } as IStringPropertyScreenItem

  const value =
    lastFurtherFocus.value ??
    (lastFurtherFocus.loading
      ? ''
      : 'Если пользователь уже проводил обучение выбранного представителя, здесь отображается информация о том, какой фокус на следующее полевое обучение этого представителя был указан')

  return (
    <>
      <StringItem item={stringItem} value={value} />
    </>
  )
}
