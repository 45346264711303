import format from 'date-fns/format'
import isValid from 'date-fns/isValid'

import { getLocal } from './get-local'

/**
 * Return the formatted date string in the given format
 * @param date
 * @param formatStr
 * https://date-fns.org/v2.0.0-alpha.9/docs/I18n
 * @param locale
 */
export function dateFormat(date: Date | number, formatStr: string, locale?: string): string {
  if (!isValid(date)) {
    console.info('The Date is invalid')
    return ''
  }
  return format(date, formatStr, { locale: getLocal(locale) })
}

/**
 * Returns date as ISO8601 string with current timezone
 * @param date date to format
 * @param precise whether to include milliseconds
 */
export function dateFormatIso(date: Date | number, precise = false): string {
  const d = new Date(date)
  const tz = -d.getTimezoneOffset()
  return (
    `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}` +
    `T${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}:${d
      .getSeconds()
      .toString()
      .padStart(2, '0')}` +
    (precise ? `.${d.getMilliseconds().toString().padStart(3, '0')}` : '') +
    (tz === 0
      ? 'Z'
      : (tz > 0 ? '+' : '-') +
        `${Math.trunc(Math.abs(tz / 60))
          .toString()
          .padStart(2, '0')}:${Math.abs(tz % 60)
          .toString()
          .padStart(2, '0')}`)
  )
}
