import React, { useContext } from 'react'

import {
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  SvgIcon,
  Typography,
  TypographyProps,
} from '@material-ui/core'
import classNames from 'classnames'

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { LayoutContext } from '../../../layout/layout'
import { grays } from '../../../layout/theme'
import { ModalContext } from '../../../providers/modal'
import { useIsSmall } from '../../_common/hooks/useIsSmall'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginLeft: 80,
    },
    comment: {
      cursor: 'pointer',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    dialogTitle: {
      paddingTop: 17,
      // padding: theme.spacing(2, 2, 0),
      minWidth: 240,
      position: 'relative',
    },
    dialogContent: {
      // padding: theme.spacing(1, 2, 3),
      paddingTop: 0,
      overflowWrap: 'break-word',
      // wordBreak: 'break-all',
    },
    closeButton: {
      position: 'absolute',
      top: 13,
      right: theme.spacing(2),
      color: grays.gray4,
    },
  }),
)

interface IOpenableTypography {
  dialogTitle?: string
}

export const OpenableTypography: React.FC<TypographyProps & IOpenableTypography> = ({
  children,
  className,
  dialogTitle = 'Комментарий',
  ...props
}) => {
  const classes = useStyles()
  const modalContext = useContext(ModalContext)
  const layoutContext = useContext(LayoutContext)
  const isSmall = useIsSmall()
  return (
    <Typography
      {...props}
      className={classNames(classes.comment, className)}
      onClick={() =>
        modalContext.open(
          <Dialog
            open
            onClose={modalContext.close}
            PaperProps={{
              style: {
                marginLeft: !isSmall && layoutContext?.isLayout ? 112 : 32,
                marginRight: 32,
                maxWidth: 310,
              },
            }}
          >
            <DialogTitle disableTypography className={classes.dialogTitle}>
              {dialogTitle}
              <IconButton className={classes.closeButton} onClick={modalContext.close} edge='end' size='small'>
                <SvgIcon viewBox='0 0 18 18' component={CloseIcon} />
              </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>{children}</DialogContent>
          </Dialog>,
        )
      }
    >
      {children}
    </Typography>
  )
}
