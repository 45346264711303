import { set } from 'lodash/fp'

import { Code, CodeSpace, IEntityReference } from '../../model/base'
import { IContractTerm } from '../../model/contract-term'
import { FieldForceVisitEscalationReason } from '../../model/dictionary-item'
import { BusinessError } from '../../model/errors'
import { IPOSReference } from '../../model/pos'
import { IProductMatrix } from '../../model/product-matrix'
import { IQuestionnaire } from '../../model/questionnaire'
import { ISupervisedFieldPositionRole } from '../../model/supervised-field-position-role'
import { ITask } from '../../model/task'
import { ITaskExecutionScope, ITaskExecutionState } from '../../model/task-execution'
import { ITaskStage } from '../../model/task-stage'
import { ITaskTemplate } from '../../model/task-template'
import { IUserProfile } from '../../model/user-profile'
import { IPosVisitReference } from '../../model/visit'
import { IApiContext } from '../../providers/api/api-context'
import { ProblemErrorCode } from '../../services/problem-service-api'
import { VisitAssessment } from '../tasks/audit/audit-merchandise-service-task'
import { makePosExtension } from '../tasks/audit/use-backup-extensions'
import { useExecutionState } from '../tasks/nested/execution-state'
import { ILocalContextService } from '../tasks/nested/local-context'
import { IScriptTaskContext } from '../tasks/script-tasks/script-task-context'
import { executeWebMethod, IWebMethodRequest, IWebMethodResult } from './execute-web-method'

export type IProcessContextService = ILocalContextService

export abstract class ApiProcessContextServiceBase implements IProcessContextService {
  posRef: IPOSReference | undefined
  visitRef: IPosVisitReference | undefined
  constructor(
    readonly taskCode: Code,
    readonly api: IApiContext,
    private readonly _refetch: ILocalContextService['refetch'],
    protected readonly defaultCodeSpace: CodeSpace,
  ) {}

  private fetching: Promise<IScriptTaskContext> | undefined

  refetch = async (changes?: Array<[string, unknown]>): Promise<IScriptTaskContext> => {
    this.fetching = this._refetch(changes)
    const res = await this.fetching
    this.fetching = undefined
    return res
  }

  onLoad = async (): Promise<void> => {
    if (this.fetching) {
      console.log('awaiting fetch')
      return this.fetching.then(
        () => void 0,
        () => void 0,
      )
    } else {
      console.log('not fetching')
      return Promise.resolve()
    }
  }

  // needs to use a method syntax to call super
  async updateProperty(propertyName: string, value: unknown): Promise<void> {
    if (propertyName.startsWith('fullScope')) {
      const oldScope = await this.api.tasks.getTaskExecutionScope(this.taskCode)
      const { fullScope: newScope } = set(propertyName, value, { fullScope: oldScope })
      // console.log('updating scope instead', oldScope, newScope)
      return await this.saveScope(newScope!)
    }
    await this.api.tasks.setTaskPropertyPath({ propertyName, taskCode: this.taskCode, value })
  }

  async saveTask(task: ITask): Promise<void> {
    if (task.code !== this.taskCode) {
      throw new Error('wrong task code')
    }
    await this.api.tasks.saveTask(task)
  }

  async saveScope(scope: ITaskExecutionScope): Promise<void> {
    await this.api.tasks.saveTaskExecutionScope(this.taskCode, scope)
  }

  saveTaskState = async (taskState: ITaskExecutionState): Promise<void> => {
    // we have full control over this
    if (taskState.taskCode !== this.taskCode) {
      throw new Error('wrong task code')
    }
    await this.api.tasks.saveTaskExecutionState(taskState)
  }

  updateStackSteps = async (): Promise<void> => {
    // TODO: update upwards only
    const localTaskState = useExecutionState.getState().taskState
    await this.api.tasks.saveTaskExecutionState(localTaskState)
    await this.refetch()
  }

  getProductMatrix = async (): Promise<IProductMatrix[]> => {
    if (!this.posRef?.code) throw new Error('no posCode')
    return this.api.pos.getProductMatrices(this.posRef.code, new Date())
  }

  getFprUserProfiles = async (): Promise<IUserProfile[]> => {
    return this.api.fprUserProfiles.getFprUserProfiles()
  }

  getSupervisedRoleByCode = async (roleCode: Code): Promise<ISupervisedFieldPositionRole | undefined> => {
    return this.api.supervised.getSupervisedRoleByCode(roleCode)
  }

  getContractTerms = async (): Promise<IContractTerm[]> => {
    if (!this.posRef?.code) throw new Error('no posCode')
    return this.api.pos.getContractTerms(this.posRef.code, new Date())
  }

  createVisitProblem = async (
    escalationReason: FieldForceVisitEscalationReason,
    executiveComment: string,
    detail?: string,
  ): Promise<string> => {
    try {
      const problem = await this.api.problem.createNewProblems({
        escalationReason,
        executiveComment,
        problemDetails: detail ? [detail] : undefined,
        visit: this.visitRef,
        location: this.posRef,
      })
      return problem[0].code
    } catch (err: unknown) {
      if (err instanceof BusinessError && err.code === ProblemErrorCode.DuplicateProblem) {
        return ''
      }
      throw err
    }
  }

  getTaskTemplateByKey = async (ref: IEntityReference): Promise<ITaskTemplate | null> => {
    return await this.api.tasks.getTaskTemplate(ref)
  }

  getTaskTemplateByTaskCode = async (
    taskCode: string,
    searchInSupervisedTasks: boolean,
  ): Promise<ITaskTemplate | null> => {
    if (!searchInSupervisedTasks) throw new Error(`not implemented searchInSupervisedTasks: ${searchInSupervisedTasks}`)
    const taskUnit = await this.api.tasks.getSupervisedTaskUnit(taskCode)
    if (!taskUnit) throw new Error(`task ${taskCode} not found`)

    const template = await this.api.tasks.getTaskTemplate(taskUnit.task.template)
    return template
  }

  getTaskTemplateStages = async (taskCode: Code, searchInSupervisedTasks: boolean): Promise<ITaskStage[]> => {
    const template = await this.getTaskTemplateByTaskCode(taskCode, searchInSupervisedTasks)
    return template?.stages ?? []
  }

  loadMediaForSupervisedVisit = async (visitCode: string): Promise<void> => {
    const mediaRefs = await this.api.audit.getMediaRefsForSupervisedVisit(visitCode)
    await this.api.blobStorage?.receive(
      mediaRefs.map((ref) => [ref, {}]),
      1,
    )
  }

  executeWebMethod = async (req: IWebMethodRequest): Promise<IWebMethodResult> => {
    return executeWebMethod(this.api, req)
  }

  getPosExtension = async (posCode: string): Promise<VisitAssessment['posExtension'] | undefined> => {
    const pos = await this.api.pos?.getPos(posCode)
    if (pos) {
      return makePosExtension(pos)
    }
  }

  getQuestionnaireByKey = async (ref: IEntityReference): Promise<IQuestionnaire | null> => {
    return await this.api.questionnaire.getQuestionnaireByKey(ref)
  }

  getDefaultCodeSpace = (): CodeSpace => this.defaultCodeSpace
}
