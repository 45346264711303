import React, { useContext } from 'react'

import { Box } from '@material-ui/core'
import { get } from 'lodash'

import { checkPredicate } from '../../../../../model/script-predicate'
import { useUpdateProperty } from '../../../nested/useUpdateProperty'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { CellWrapperProps, EditorItemProps } from './editorUtils'
import { NumberEditor } from './number-editor'
import { TableItemContext } from './table-item'

const IntegerInputCellControl: React.FC<EditorItemProps> = ({ col, row, i }) => {
  if (col.control.$type !== 'PMI.FACE.BDDM.Extensions.Classes.IntegerInputCell') throw new Error('unreachable')
  const value = get(row, col.propertyName ?? '', undefined) as number
  const updateProperty = useUpdateProperty()
  const tableItem = useContext(TableItemContext)
  const localContext = useScriptTaskContext()

  let isRequired = false
  if (col.control.required) {
    switch (col.control.required.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.ScriptPredicate':
        isRequired = checkPredicate(col.control.required, row, localContext)
        break
      case 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate':
        isRequired = col.control.required.value
    }
  }

  return (
    <>
      <NumberEditor
        value={value}
        onChange={(value) => {
          void updateProperty(
            `${tableItem.propertyName}.${i}.${col.propertyName}`,
            typeof value === 'number' ? value : null,
          )
        }}
        showIncreaseButtons={col.control.showIncreaseButtons ?? false}
        minValue={col.control.minValue}
        maxValue={col.control.maxValue}
        defaultValue={col.control.defaultValue}
        incrementValue={col.control.incrementValue}
        isRequired={isRequired}
      />
    </>
  )
}

export const IntegerInputCell: React.FC<EditorItemProps & CellWrapperProps> = ({ variant, ...props }) => {
  const { col, row } = props
  const localContext = useScriptTaskContext()
  if (col.control.$type !== 'PMI.FACE.BDDM.Extensions.Classes.IntegerInputCell') throw new Error('unreachable')

  if (col.control.visible) {
    switch (col.control.visible.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.ScriptPredicate':
        const isVisible = checkPredicate(col.control.visible, row, localContext)
        if (!isVisible) {
          return <Box></Box>
        }
        break
      case 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate':
        if (!col.control.visible.value) {
          return <Box></Box>
        }
    }
  }

  switch (variant) {
    case 'table':
      return <IntegerInputCellControl {...props} />
    case 'portrait':
    case 'extended-row':
      return (
        <Box>
          <IntegerInputCellControl {...props} />
        </Box>
      )
  }
}
