import React, { useContext, useEffect, useState } from 'react'

import { createStyles, Theme, useMediaQuery } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns'
import addDays from 'date-fns/addDays'
import addMonths from 'date-fns/addMonths'
import addWeeks from 'date-fns/addWeeks'
import endOfDay from 'date-fns/endOfDay'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import startOfDay from 'date-fns/startOfDay'
import subDays from 'date-fns/subDays'
import subMonths from 'date-fns/subMonths'
import subWeeks from 'date-fns/subWeeks'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'

import { capitalizeFirstLetter, dateFormat } from '../../../../utils'
import { getLocal } from '../../../../utils/get-local'
import { VisitPeriod } from '../../models'
import { VisitPageUiContext } from '../../visits-page/visits-page-ui-context'





const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
        zIndex: 10000,
      },
    },
    button: {
      width: 40,
      height: 40,
      borderRadius: '50%',
      backgroundColor: '#F2F3F4',
      color: theme.palette.common.black,
      '&:hover': {
        backgroundColor: '#F2F3F4',
      },
      [theme.breakpoints.down('xs')]: {
        width: 36,
        height: 36,
      },
    },
    dataPicker: {
      position: 'relative',
      zIndex: 9,
      color: theme.palette.common.black,
      '& .react-datepicker__tab-loop': {
        position: 'absolute',
        top: 0,
        left: '50%',
        width: 340,
        transform: 'translateX(-50%)',
      },
      '& .react-datepicker-popper': {
        padding: '0 !important',
        width: 'auto',
        inset: '56px 0% auto auto !important',
        transform: 'translateX(0) !important',
      },
      '& .react-datepicker__triangle': {
        display: 'none',
      },
      '& .react-datepicker__current-month': {
        fontSize: 18,
        lineHeight: '21px',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        paddingBottom: 16,
      },
      '& .react-datepicker__header': {
        padding: '22px 0 8px',
        backgroundColor: 'transparent',
        border: 'none',
      },
      '& .react-datepicker__today-button': {
        padding: '22px 0 31px',
        backgroundColor: 'transparent',
        border: 'none',
        color: theme.palette.primary.main,
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: '0.1px',
      },
      '& .react-datepicker': {
        fontFamily: 'Lato',
        border: 'none',
        borderRadius: 12,
        boxShadow: '0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
      },
      '& .react-datepicker__day-name': {
        width: 41,
        padding: '13px 0',
        margin: '-1px 0 0 -1px',
        fontSize: 12,
        fontWeight: 600,
        lineHeight: '14px',
        textTransform: 'capitalize',
      },
      '& .react-datepicker__day': {
        verticalAlign: 'middle',
        margin: '-1px 0 0 -1px',
        padding: '13px 0 12px',
        width: 41,
        fontSize: 12,
        lineHeight: '14px',
        border: '1px solid #C7CDD7',
        '&:hover': {
          borderRadius: 0,
        },
      },
      '& .react-datepicker__day--selected': {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        fontWeight: '600',
      },
      '& .react-datepicker__day--keyboard-selected': {
        borderRadius: 'inherit',
        backgroundColor: 'inherit',
        color: 'inherit',
        fontWeight: 'inherit',
      },
      '& .react-datepicker__month': {
        margin: '0 30px',
      },
      '& .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before':
        {
          borderWidth: '2px 2px 0 0',
          borderColor: '#546066',
        },
      '& .react-datepicker__navigation': {
        top: 18,
        '&--next': {
          right: 24,
        },
        '&--previous': {
          left: 24,
        },
      },
      '& .react-datepicker__day--outside-month': {
        backgroundColor: 'inherit',
        fontWeight: 'inherit',
        color: '#858F97',
      },
      '& .react-datepicker__day--disabled': {
        background: '#FCFCFC',
        color: '#858F97',
      },
      '& .react-datepicker__portal': {
        background: 'rgba(4, 4, 4, 0.15)',
      },
    },
    text: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      fontSize: 14,
      fontWeight: 500,
      minWidth: 129,
      height: 40,
      borderRadius: 8,
      padding: '8px 15px',
      color: theme.palette.common.black,
    },
    calendarIcon: {
      width: 24,
      marginRight: 7,
      color: theme.palette.common.black,
    },
    keyboardDatePicker: {
      marginTop: 0,
      position: 'absolute',
      top: 0,
      left: '20%',
      height: '100%',
      visibility: 'hidden',
      width: '100%',
    },
  }),
)

type VisitPeriodSwitcherVariant = 'day' | 'week' | 'month'

interface IVisitPeriodSwitcherProps {
  selectedStartDate?: Date
  variant: VisitPeriodSwitcherVariant
  onPeriodChange: (period: VisitPeriod) => void
}

const DISABLED_INTERVAL_IN_MONTH = 2

const VisitPeriodSwitcher: React.FC<IVisitPeriodSwitcherProps> = ({ selectedStartDate, variant, onPeriodChange }) => {
  const { t, i18n } = useTranslation('common')
  const { uiState } = useContext(VisitPageUiContext)
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'))
  const classes = useStyles()
  const [currentPeriod, setPeriod] = useState<VisitPeriod>(buildPeriodInitialByVariant(selectedStartDate, variant))

  const onChange = (period: VisitPeriod): void => onPeriodChange(period)

  useEffect(() => {
    if (uiState.viewMode !== variant) {
      return
    }
    const initPeriodValue: VisitPeriod = buildPeriodInitialByVariant(selectedStartDate, variant)
    setPeriod(initPeriodValue)
    onChange(initPeriodValue)
  }, [variant])

  const handleLeft = (): void => {
    const newValue: VisitPeriod = buildPeriodByVariant(currentPeriod, 'sub', variant)
    setPeriod(newValue)
    onChange(newValue)
  }
  const handleRight = (): void => {
    const newValue: VisitPeriod = buildPeriodByVariant(currentPeriod, 'add', variant)
    setPeriod(newValue)
    onChange(newValue)
  }
  const clickOnPeriodHandler = (date: Date | undefined | null): void => {
    const newValue: VisitPeriod = buildPeriodInitialByVariant(date, variant)
    setPeriod(newValue)
    onChange(newValue)
  }

  const isDisabledLeft = (): boolean => isBefore(currentPeriod.start, subMonths(new Date(), DISABLED_INTERVAL_IN_MONTH))

  const isDisabledRight = (): boolean =>
    isAfter(currentPeriod.end, startOfDay(addMonths(new Date(), DISABLED_INTERVAL_IN_MONTH)))

  const renderCustomInput = (): JSX.Element => (
    <div className={classes.text}>
      <svg
        className={classes.calendarIcon}
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M20 3H19V2C19 1.45 18.55 1 18 1C17.45 1 17 1.45 17 2V3H7V2C7 1.45 6.55 1 6 1C5.45 1 5 1.45 5 2V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM19 21H5C4.45 21 4 20.55 4 20V8H20V20C20 20.55 19.55 21 19 21Z'
          fill='#333333'
        />
      </svg>
      {/*<CalendarTodayIcon className={classes.calendarIcon}  />*/}
      {getFormattedPeriodByVariant(currentPeriod, variant, i18n.language)}
    </div>
  )

  return (
    <div className={classes.root}>
      <IconButton aria-label='left' className={classes.button} onClick={handleLeft} disabled={isDisabledLeft()}>
        <ChevronLeftIcon />
      </IconButton>

      <div className={classes.dataPicker}>
        <DatePicker
          // shouldCloseOnSelect={false}
          withPortal={isSmall}
          customInput={renderCustomInput()}
          locale={getLocal(i18n.language)}
          todayButton={t('today')}
          minDate={subMonths(new Date(), DISABLED_INTERVAL_IN_MONTH)}
          maxDate={startOfDay(addMonths(new Date(), DISABLED_INTERVAL_IN_MONTH))}
          selected={variant === 'day' ? currentPeriod.start : new Date()}
          onChange={(date) => {
            clickOnPeriodHandler(date)
          }}
        />
      </div>
      <IconButton aria-label='right' className={classes.button} onClick={handleRight} disabled={isDisabledRight()}>
        <ChevronRightIcon />
      </IconButton>
    </div>
  )
}

export default React.memo(VisitPeriodSwitcher)

export function buildPeriodInitialByVariant(
  selectedStartDate: Date | undefined | null,
  variant: VisitPeriodSwitcherVariant,
): VisitPeriod {
  const TODAY = startOfDay(new Date())
  const START_DATE = startOfDay(selectedStartDate ?? TODAY)
  switch (variant) {
    case 'day':
      return {
        start: START_DATE,
        end: endOfDay(START_DATE),
      }
    case 'week':
      return {
        start: startOfWeek(START_DATE, { weekStartsOn: 1 }),
        end: endOfWeek(START_DATE, { weekStartsOn: 1 }),
      }
    case 'month':
      return {
        start: startOfMonth(START_DATE),
        end: endOfMonth(START_DATE),
      }
  }
}

function buildPeriodByVariant(
  currentPeriod: VisitPeriod,
  operation: 'add' | 'sub',
  variant: VisitPeriodSwitcherVariant,
): VisitPeriod {
  switch (variant) {
    case 'day':
      if (operation === 'add') {
        return {
          start: addDays(currentPeriod.start, 1),
          end: addDays(currentPeriod.end, 1),
        }
      } else {
        return {
          start: subDays(currentPeriod.start, 1),
          end: subDays(currentPeriod.end, 1),
        }
      }
    case 'week':
      if (operation === 'add') {
        return {
          start: addWeeks(currentPeriod.start, 1),
          end: addWeeks(currentPeriod.end, 1),
        }
      } else {
        return {
          start: subWeeks(currentPeriod.start, 1),
          end: subWeeks(currentPeriod.end, 1),
        }
      }
    case 'month':
      if (operation === 'add') {
        const startMonth = addMonths(currentPeriod.start, 1)
        return {
          start: startMonth,
          end: endOfMonth(startMonth),
        }
      } else {
        const startMonth = subMonths(currentPeriod.start, 1)
        return {
          start: startMonth,
          end: endOfMonth(startMonth),
        }
      }
  }
}

function getFormattedPeriodByVariant(
  period: VisitPeriod,
  variant: VisitPeriodSwitcherVariant,
  locale?: string,
): string {
  switch (variant) {
    case 'day':
      return dateFormat(period.start, 'dd.MM.yyyy')
    case 'week':
      const start = dateFormat(period.start, 'dd.MM')
      const end = dateFormat(period.end, 'dd.MM')
      return `${start} - ${end}`
    case 'month':
      return capitalizeFirstLetter(dateFormat(period.start, 'LLLL yyyy', locale))
  }
}
