import React, { useContext } from 'react'

import { Box, createStyles, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useAsync } from 'react-use'

import { LogManager } from '../../../infrastructure/logger'
import { BackButton } from '../../../layout/back-button'
import { grays } from '../../../layout/theme'
import { ITaskTemplate } from '../../../model/task-template'
import { IVisitTask } from '../../../model/visit-task'
import { ApiContext } from '../../../providers'
import { dateFormat } from '../../../utils'
import { useFetchVisit } from '../../_common/hooks/useFetchVisit'
import { useIsSmall } from '../../_common/hooks/useIsSmall'
import { findRepresentativeItem } from '../sales-expert/utils'
import { getTaskProperty } from '../script-tasks/propertyName'
import { ScriptTaskContextProvider } from '../script-tasks/script-task-context'
import { CompositeResultScreen } from './composite-screen/composite-result-screen'
import { SimpleSurveyTaskResult } from './simple-survey/simple-survey-task-result'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(-3),
    },
    content: {
      marginTop: theme.spacing(1),
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1, 2),
      overflow: 'overlay',
      minHeight: 69,
    },
    backButton: {
      width: 44,
      height: 44,
      color: theme.palette.primary.main,
      padding: theme.spacing(1),
      marginRight: 0,
    },
  }),
)

const logger = LogManager.getLogger('PreviousTaskView')

interface IPreviousTaskViewProps {
  item: IVisitTask
  onBack: () => void
  renderInfo?: () => JSX.Element
}

const PreviousTaskView: React.FC<IPreviousTaskViewProps> = ({ item, onBack, renderInfo }) => {
  const classes = useStyles()
  const api = useContext(ApiContext)
  const { t } = useTranslation('sales-expert-tasks')
  const isSmall = useIsSmall()

  const visitOps = useFetchVisit({ visitCode: item.visitCode }, { withPos: true })

  const taskTemplateListOps = useAsync(async () => {
    try {
      return await api.tasks.getTaskTemplates()
    } catch (error) {
      const message = t('taskTypesFetchError')
      logger.error('getVisitTaskTypes', message, error)
      throw new Error(message)
    }
  }, [item.code])

  const formattedDate = dateFormat(new Date(item.creationTime ?? item.updateTime), 'dd.MM')

  if (taskTemplateListOps.loading && !taskTemplateListOps.value) return <></>
  if (visitOps.loading && !visitOps.value) return <></>

  const templateByVersion =
    item && taskTemplateListOps.value?.find((template) => template.version.code === item!.template.version!.code)
  const templateByCode = item && taskTemplateListOps.value?.find((template) => template.code === item!.template.code)

  const representativeItem = templateByVersion && findRepresentativeItem(templateByVersion)

  const renderResultScreen = (template: ITaskTemplate): JSX.Element => {
    console.log('renderResultScreen', taskTemplateListOps, item, template)
    switch (template.resultScreen.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.SimpleSurveyTaskResultScreen':
        return <SimpleSurveyTaskResult title={item.name} questionnaireCode={template.resultScreen.questionnaire.code} />
      case 'PMI.FACE.BDDM.Extensions.Classes.CompositeTaskResultScreen':
        return <CompositeResultScreen screen={template.resultScreen} />
      default:
        return <h1>Неверный тип: {template.resultScreen.$type}</h1>
    }
  }

  const representativeName: string | undefined = getTaskProperty(
    item,
    representativeItem?.representativeNamePropertyName ?? '',
  )
  const agencyName: string | undefined = getTaskProperty(item, representativeItem?.agencyNamePropertyName ?? '')

  const renderTitle = (): JSX.Element => {
    if (isSmall) {
      const showDividers: boolean = Boolean(representativeName) || Boolean(agencyName)
      return (
        <Box className={classes.wrapper}>
          <BackButton className={classes.backButton} onBack={onBack} />
          {showDividers && divider}
          <Box flexGrow={1} textAlign='center'>
            <Box display='inline-box'>
              <Typography variant='h4' align='left'>
                {formattedDate}
              </Typography>
            </Box>
          </Box>
          {showDividers && divider}
          {showDividers && (
            <Box flexGrow={2}>
              {representativeName && <Typography variant='h4'>{representativeName}</Typography>}
              {agencyName && (
                <Box color={grays.gray2}>
                  <Typography color='inherit' variant='body1'>
                    {agencyName}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          {!showDividers && <Box width={44}></Box>}
        </Box>
      )
    } else {
      return (
        <Box className={classes.wrapper}>
          <BackButton className={classes.backButton} onBack={onBack} />

          <Box marginLeft='auto' />
          <Typography variant='h2'>{formattedDate}</Typography>

          {representativeName && (
            <>
              {divider}
              <Typography variant='h2'>{representativeName}</Typography>
            </>
          )}
          {agencyName && (
            <>
              {divider}
              <Box color={grays.gray2}>
                <Typography color='inherit' variant='h2'>
                  {agencyName}
                </Typography>
              </Box>
            </>
          )}

          <Box marginRight='auto' />
          <Box className={classes.backButton} />
        </Box>
      )
    }
  }

  return (
    <div className={classes.root}>
      {renderInfo?.()}
      {renderTitle()}
      <ScriptTaskContextProvider taskCode={item.code} visitCode={item.visitCode}>
        {renderResultScreen(templateByVersion ?? templateByCode!)}
      </ScriptTaskContextProvider>
    </div>
  )
}

const divider = <Box height={28} marginX={3} borderLeft={`2px solid ${grays.gray4}`}></Box>

export default PreviousTaskView
