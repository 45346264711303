import React, { useCallback, useContext, useMemo } from 'react'

import { PermissionCode } from '../../../model/user-profile'
import { ProfileContext } from '../../../providers'

type NonEmptyArray<T> = [T, ...T[]]

interface Props {
  code: PermissionCode | NonEmptyArray<PermissionCode>
  children: ((hasPermission: boolean) => React.ReactNode) | React.ReactNode
}

export const Permission = React.forwardRef(function Permission(props: Props, _ref) {
  const hasPermission = useHasPermission()

  let render: (hasPermission: boolean) => React.ReactNode

  if (typeof props.children === 'function') {
    render = props.children as (hasPermission: boolean) => React.ReactNode
  } else {
    render = (hasPermission: boolean) => (hasPermission ? props.children : null)
  }

  const codes = (Array.isArray(props.code) ? props.code : [props.code]) as NonEmptyArray<PermissionCode>

  return render(hasPermission(...codes)) as React.ReactElement
})

export function useHasPermission() {
  const { value: profile } = useContext(ProfileContext)

  const permissions = useMemo(
    () => new Set(profile?.profile.permissions.map((x) => x.code)),
    [profile?.profile.permissions],
  )

  return useCallback(
    function hasPermission(...codes: NonEmptyArray<PermissionCode>) {
      return codes.some((code) => permissions.has(code))
    },
    [permissions],
  )
}
