/* eslint-disable @typescript-eslint/dot-notation */
import ExifReader from 'exifreader'

import {BlobMetadata} from './blob-storage-api'

export async function extractExifProperties(blob: Blob, metadata: BlobMetadata): Promise<void> {
  if (blob.type?.startsWith('image/jp')) {
    try {
      const tags = ExifReader.load(await blob.arrayBuffer(), {expanded: true})
      if (tags) {
        if (tags.file) {
          metadata.width = tags.file['Image Width']?.value
          metadata.height = tags.file['Image Height']?.value
        }
        if (tags.exif) {
          const dateTimeString = tags.exif['DateTime']?.description
          if (dateTimeString) {
            const parts = dateTimeString.split(' ')
            if (parts.length === 2) {
              const date = parts[0].replaceAll(':', '-')
              const time = parts[1]
              const dt = `${date}T${time}`
              metadata.dateTime = Date.parse(dt)
            } else {
              console.warn(`Cant parse string '${dateTimeString}' as DateTime`)
            }
          }
        }
        if (tags.gps) {
          metadata.gps = {lat: tags.gps.Latitude, lon: tags.gps.Longitude, alt: tags.gps.Altitude}
        }
      }
    } catch (e) {
      console.warn('Failed reading EXIF', e)
    }
  }
}