import { IHttpClient } from '../infrastructure/http-client-factory'
import { EntityKey, Code } from '../model/base'
import { IQuestionnaire } from '../model/questionnaire'
import { IQuestionnaireService } from './questionnaire-service-api'

export class QuestionnaireService implements IQuestionnaireService {
  constructor(private readonly _http: IHttpClient) {}

  async getQuestionnaires(): Promise<IQuestionnaire[]> {
    const response = await this._http.request({
      method: 'GET',
      url: 'questionnaires',
    })
    return response.data
  }

  async getQuestionnaire(code: Code): Promise<IQuestionnaire> {
    const response = await this._http.request({
      method: 'GET',
      url: `questionnaires/${code}`,
    })
    return response.data
  }

  async saveQuestionnaire(data: IQuestionnaire): Promise<void> {
    const response = await this._http.request({
      method: 'POST',
      url: 'questionnaires',
      data,
    })
    return response.data
  }

  async copyQuestionnaire(key: EntityKey): Promise<EntityKey> {
    const response = await this._http.request({
      method: 'POST',
      url: `questionnaires/${key}/copy`,
    })
    return response.data
  }
}
