import React, { useContext, useEffect } from 'react'

import { Box } from '@material-ui/core'

import { AppVersion } from './app-version'
import { LayoutContext } from './layout'
import Menu from './menu/menu'
import UserInfo from './user-info'

interface MenuPageProps {
  children?: never
}

export const MenuPage: React.FC<MenuPageProps> = (props) => {
  const layoutContext = useContext(LayoutContext)
  useEffect(() => {
    console.log('MenuPage mount', layoutContext)
  }, [])

  return (
    <>
      <Menu isBottomMenu />
      <Box marginTop='auto' paddingX={2} bgcolor='white'>
        <UserInfo />
        <AppVersion />
      </Box>
    </>
  )
}
