import React from 'react'

import { Button } from '@material-ui/core'

import { useUpdateProperty } from '../nested/useUpdateProperty'

interface DeleteReportLinkProps {
  children?: never
}

export const DeleteReportLink: React.FC<DeleteReportLinkProps> = (props) => {
  const updateProperty = useUpdateProperty()
  return (
    <Button
      onClick={() => {
        void updateProperty('task.reportLink', undefined)
      }}
    >
      DeleteReportLink
    </Button>
  )
}
