import React, { useEffect, useMemo, useReducer } from 'react'

import {
  Box,
  createStyles,
  makeStyles,
  Menu,
  Typography,
  MenuItem,
  IconButton,
  Dialog,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import classNames from 'classnames'
import { useTranslation } from "react-i18next";

import { PageContent } from '../../../layout/page-content'
import { TitleBar } from '../../../layout/title-bar'
import { Code } from '../../../model/base'
import { IMimeContentRef } from '../../../model/content'
import { IPointOfSale } from '../../../model/pos'
import { IActionScreenItem } from '../../../model/screen-item-base'
import { isNonNullable } from '../../../utils/isNonNullable'
import { useAsyncError } from '../../_common/hooks/useAsyncError'
import { getPosAddress } from '../../_common/pos'
import { PhotoDialog } from '../../documents'
import { ViewPhotoButton } from '../../execute-survey-control/photo-button'
import { usePropertyName } from '../nested/usePropertyName'
import { AccordionArrow } from '../template-tasks/composite-screen/accordion-card'
import { ItemCard } from '../template-tasks/composite-screen/item-card'
import { VisitAssessment } from './audit-merchandise-service-task'
import { formatTaskAssessmentResult } from './task-assessment-result'
import { TaskResultView } from './task-result-view'
import { useBackupExtensions } from './use-backup-extensions'
import { formatVisitAssessmentResult } from './visit-assessment-result'

const useStyles = makeStyles((theme) =>
  createStyles({
    accordionSummary: {
      padding: theme.spacing(2, 1.25, 2, 0),
      height: 'unset !important',
    },
    visitView: {
      display: 'grid',
      alignItems: 'start',
      gridTemplateColumns: '48px auto auto',
      gridTemplateAreas: '"arrow visitTitle visitResult"',

      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: '48px auto',
        gridTemplateAreas: '"arrow visitTitle" ". visitResult"',
      },
      // marginBottom: theme.spacing(2),
    },
    visitResult: {
      gridArea: 'visitResult',
      textAlign: 'end',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'start',
      },
    },

    tasksContainer: {
      paddingLeft: theme.spacing(6),
      paddingRight: 0,
      display: 'block',
    },
    taskView: {
      display: 'grid',
      columnGap: theme.spacing(2),
      marginBottom: theme.spacing(2),
      alignItems: 'start',
      gridTemplateColumns: '2fr 1fr 30px 30px',
      gridTemplateRows: '28px auto',
      gridTemplateAreas: `
        "name taskResult photo menu"
        "comment taskResult . ."`,
      [theme.breakpoints.down('xs')]: {
        columnGap: theme.spacing(1),
        gridTemplateColumns: 'auto 30px 30px',
        gridTemplateAreas: `
          "name photo menu"
          "taskResult taskResult TaskResult"
          "comment comment comment"`,
        gridTemplateRows: 'unset',
      },
    },
    taskResult: {
      gridArea: 'taskResult',
      fontWeight: 500,
      fontSize: 16,
      [theme.breakpoints.down('xs')]: {
        fontSize: 15,
      },
    },
    font18_600: {
      fontSize: 18,
      fontWeight: 600,
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
      },
    },
    font18_500: {
      fontSize: 18,
      fontWeight: 500,
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
      },
    },
    resultPage: {
      paddingTop: theme.spacing(3),
      backgroundColor: theme.palette.background.default,
    },
    photoButton: {
      height: 'fit-content',
      gridArea: 'photo',
    },
    menuButton: {
      padding: 3,
      gridArea: 'menu',
    },
    visitResultColor: {
      '&.Positive': {
        color: theme.palette.success.main,
      },
      '&.Negative': {
        color: theme.palette.error.main,
      },
    },
  }),
)

interface ServiceAuditResultsScreenItemProps {
  children?: never
  item: ServiceAuditResultsScreenItem
}

export interface ServiceAuditResultsScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.ServiceAuditResultsScreenItem'
}

interface State {
  menuAnchor?: HTMLElement
  menuCode?: Code
  photoCode?: Code
  resultCode?: Code
}

type Action =
  | {
      type: 'OpenMenu'
      menuAnchor: HTMLElement
      menuCode: Code
    }
  | {
      type: 'OpenPhoto'
      photoCode: Code
    }
  | {
      type: 'OpenResult'
    }
  | { type: 'CloseMenu' }
  | { type: 'ClosePhoto' }
  | { type: 'CloseResult' }

const emptyArr: never[] = []

export const ServiceAuditResultsScreenItemControl: React.FC<ServiceAuditResultsScreenItemProps> = (props) => {
  const { item } = props
  const { t } = useTranslation('photo')
  const value = usePropertyName<VisitAssessment[]>(item.propertyName) ?? emptyArr
  useEffect(() => {
    console.log('ServiceAuditResultsScreenItem mount', value, item)
  }, [])

  const extensions = useBackupExtensions()

  useAsyncError(extensions.error)

  const classes = useStyles()

  const [{ menuAnchor, photoCode, resultCode }, dispatch] = useReducer((state: State, action: Action): State => {
    const { type, ...payload } = action
    switch (action.type) {
      case 'OpenMenu':
      case 'OpenPhoto':
      case 'ClosePhoto':
      case 'CloseMenu':
      case 'CloseResult':
        return { ...payload }
      case 'OpenResult':
        return { resultCode: state.menuCode }
      default:
        return state
    }
  }, {})

  const photos = useMemo(() => {
    if (photoCode) {
      for (const va of value) {
        for (const ta of va.taskAssessmentsData) {
          if (ta.code === photoCode) {
            return ta.attachment?.parts?.map((p) => (p as IMimeContentRef).target)?.filter(isNonNullable) ?? []
          }
        }
      }
    }
    return []
  }, [photoCode, value])

  return (
    <ItemCard label={<Typography variant='inherit'>{item.displayName}</Typography>}>
      <Menu open={!!menuAnchor} anchorEl={menuAnchor} onClose={() => dispatch({ type: 'CloseMenu' })}>
        <MenuItem onClick={() => dispatch({ type: 'OpenResult' })}>Открыть задачу</MenuItem>
      </Menu>
      <Dialog open={!!photoCode} fullScreen onClose={() => dispatch({ type: 'ClosePhoto' })}>
        <PhotoDialog
          inboxIds={photos}
          isReadOnly
          isShow={!!photoCode}
          title={t('photoDialogTitle')}
          minOccurs={0}
          maxOccurs={0}
          onClose={(): void => {
            dispatch({ type: 'ClosePhoto' })
          }}
          onSubmit={function (newPhotos: string[]): void {
            throw new Error('Function not implemented.')
          }}
          allowAddPhoto={false}
          permissionsAddPhoto={false}
          allowDeletePhoto={false}
        />
      </Dialog>
      <Dialog open={!!resultCode} fullScreen onClose={() => dispatch({ type: 'CloseResult' })}>
        <TitleBar onBack={() => dispatch({ type: 'CloseResult' })}>Результат</TitleBar>
        <PageContent className={classes.resultPage}>
          {resultCode && <TaskResultView targetTaskCode={resultCode} />}
        </PageContent>
      </Dialog>
      {value.map((visitAssessment) => {
        const {
          visit,
          result,
          posExtension = visit?.code ? extensions.value?.[visit.code]?.pos : visitAssessment.posExtension,
          taskAssessmentsData = [],
        } = visitAssessment

        const filteredTAs = taskAssessmentsData.filter(
          (ta) => !!ta.result || !!ta.comment || !!ta.attachment?.parts?.length,
        )

        if (!filteredTAs.length) return <></>

        return (
          <Box key={visit?.code} margin={-1} marginLeft={-2}>
            <Accordion elevation={0}>
              {posExtension && (
                <AccordionSummary classes={{ content: classes.visitView, root: classes.accordionSummary }}>
                  <Box mt={-1.5} gridArea='arrow'>
                    <AccordionArrow />
                  </Box>
                  <Box gridArea='visitTitle'>
                    <Typography className={classes.font18_600}>
                      {posExtension.name}{' '}
                      <Typography color='textSecondary' className={classes.font18_500} component='span'>
                        ({posExtension.code})
                      </Typography>
                    </Typography>
                    <Typography variant='body2' color='textSecondary'>
                      {getPosAddress({ address: posExtension } as unknown as IPointOfSale)}
                    </Typography>
                  </Box>
                  <div className={classes.visitResult}>
                    <Typography className={classNames(classes.font18_500, classes.visitResultColor, result)}>
                      {formatVisitAssessmentResult(result)}
                    </Typography>
                  </div>
                </AccordionSummary>
              )}
              <AccordionDetails className={classes.tasksContainer}>
                {filteredTAs.map((ta) => {
                  if (!ta.taskStatus && visit && ta.task?.code) {
                    const sourceTask = extensions.value?.[visit.code]?.tasks?.[ta.task.code]
                    ta.taskStatus = sourceTask?.status
                    ta.cancelationReason = sourceTask?.cancelationReason
                  }
                  return (
                    <div key={ta.code} className={classes.taskView}>
                      <Box gridArea='name'>
                        <Typography>{ta.task?.name ?? ta.taskTemplate?.name}</Typography>
                      </Box>
                      <Box gridArea='comment'>
                        <Typography color='textSecondary' variant='body2'>
                          {ta.comment}
                        </Typography>
                      </Box>

                      {ta.taskStatus !== 'Canceled' ? (
                        <Typography className={classes.taskResult}>{formatTaskAssessmentResult(ta.result)}</Typography>
                      ) : (
                        <Typography className={classes.taskResult} color='textSecondary'>
                          {ta.cancelationReason?.name}
                        </Typography>
                      )}
                      {ta.attachment?.parts?.length ? (
                        <ViewPhotoButton
                          className={classes.photoButton}
                          code={ta.code}
                          onClick={(code) => dispatch({ type: 'OpenPhoto', photoCode: code })}
                        />
                      ) : (
                        <div className={classes.photoButton} />
                      )}

                      {ta.taskStatus === 'Finished' ? (
                        <IconButton
                          className={classes.menuButton}
                          onClick={(ev) =>
                            dispatch({
                              type: 'OpenMenu',
                              menuAnchor: ev.currentTarget,
                              menuCode: ta.task?.code ?? ta.taskTemplate?.code ?? '', // TODO: Решить что открывать
                            })
                          }
                        >
                          <MoreVertIcon />
                        </IconButton>
                      ) : (
                        <div className={classes.menuButton} />
                      )}
                    </div>
                  )
                })}
              </AccordionDetails>
            </Accordion>
          </Box>
        )
      })}
    </ItemCard>
  )
}
