import { IKeyValuePair } from '../../model/data-source'
import { EncryptionSettingsReference } from '../../model/encryption-settings'
import { parseRequestError } from '../../utils/error-utils'
import { IHttpClient } from '../http-client-factory'
import { EncryptKeyValuePairsRequest, IEncryptionService } from './encryption-service-api'

export default class EncryptionService implements IEncryptionService {
  constructor(private readonly _http: IHttpClient) {}

  async encryptKeyValuePairs(request: EncryptKeyValuePairsRequest): Promise<IKeyValuePair[]> {
    try {
      const response = await this._http.request({
        method: 'POST',
        url: '/encryption/v1.0/encrypt-key-value-pairs',
        data: request,
      })

      return response.data
    } catch (error) {
      throw parseRequestError(error)
    }
  }

  async getEncryptionSettingsRefs(): Promise<EncryptionSettingsReference[]> {
    const response = await this._http.request({
      method: 'GET',
      url: '/encryption/v1.0/encryption-settings-refs',
    })

    return response.data
  }
}
