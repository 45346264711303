import { isError } from 'lodash'

import { LogManager } from '../../../../infrastructure/logger'
import { IStringValueBase } from '../../../../model/string-value-base'
import { appToast } from '../../../../utils'
import { runSyncScript } from '../../script-tasks/run-sync-script'
import { IScriptTaskContext } from '../../script-tasks/script-task-context'
import { ITableRecord } from './table-item/editorUtils'

const logger = LogManager.getLogger('GetStringValueBase')

export function getStringValueBase(stringValue: IStringValueBase, context: IScriptTaskContext): string | undefined {
  switch (stringValue.$type) {
    case 'PMI.FACE.BDDM.Extensions.Classes.ConstStringValue':
      return stringValue.value
    case 'PMI.FACE.BDDM.Extensions.Classes.ScriptStringValue': {
      const script = context.template.scripts[stringValue.script.code]
      if (!script) throw new Error('no script')

      return runSyncScript(script.body, { ...context })
    }
    case 'PMI.FACE.BDDM.Extensions.Classes.InlineScriptStringValue': {
      if (stringValue.scriptBody == null) throw new Error('no script')
      return runSyncScript(stringValue.scriptBody, { ...context })
    }
    default:
      throw new Error('unimplemented StringValue type ' + stringValue.$type)
  }
}

export function useGetStringValueBase(
  context: IScriptTaskContext,
  record?: ITableRecord,
  stringValue?: IStringValueBase,
  locationError?: string, // type, propertyName, location
): string | undefined {
  if (stringValue) {
    try {
      return getStringValueBase(stringValue, { ...context, record: record ?? context.record })
    } catch (error: unknown) {
      if (isError(error)) {
        logger.critical('handler', error.message, error, locationError)
        appToast.error('Во время выполнения скрипта произошла ошибка, получение строкового значения невозможно')
      }
    }
  }
}
