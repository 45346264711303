import { Theme } from '@material-ui/core/styles'
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles'

export const getFloatActionButtonStyle = (theme: Theme): CreateCSSProperties => ({
  position: 'fixed',
  '-webkit-transform': 'translateZ(0)',
  right: 55,
  bottom: 52,
  [theme.breakpoints.down('xs')]: {
    right: 0,
    left: 0,
    margin: '0 auto',
  },
})
