import { AxiosError } from 'axios'

import { AuthError, BusinessError, NetworkError, ServiceError, ServiceStateError, UnknownError } from '../model/errors'
import { isAxiosError } from './is-axios-error'

interface ProblemDetailsBase {
  type?: string
  title?: string
  detail?: string
  status?: number
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type ProblemDetails<TExtensions extends object = { [key: string]: unknown }> = ProblemDetailsBase & TExtensions

export function parseRequestError(error: unknown): Error {
  if (isAxiosError<ProblemDetails>(error)) {
    // https://github.com/axios/axios/issues/4655
    if (error.response && !Object.is(error.response, error.request)) {
      const res = error.response

      if (res.status === 401) {
        return new AuthError(AuthError.Unauthorized)
      }
      if (res.status === 403) {
        return new AuthError(AuthError.Forbidden)
      }

      if (res.data?.type === 'MAINTENANCE_IN_PROGRESS') {
        return new ServiceStateError(ServiceStateError.Maintenance)
      }
      if (res.data?.type === 'UPGRADE_REQUIRED') {
        return new ServiceStateError(ServiceStateError.UpgradeRequired)
      }

      if (res.status === 400) {
        return new BusinessError(res.data?.type, res.data?.title ?? '', res.data)
      }

      // if (res.status >= 400 && res.status < 500) {
      // }

      if (res.status >= 500) {
        return new ServiceError(res.data?.type ?? '', res.data?.title, res.data)
      }

      return new UnknownError(error.message, { cause: error })
    }

    if (error.request) {
      if (!navigator.onLine) {
        return new NetworkError(NetworkError.Offline)
      }

      if (error.code === AxiosError.ERR_NETWORK) {
        return new NetworkError(NetworkError.Failed)
      }

      if (error.code === AxiosError.ERR_CANCELED) {
        return new NetworkError(NetworkError.Canceled)
      }

      if (error.code === AxiosError.ECONNABORTED) {
        return new NetworkError(NetworkError.Canceled)
      }

      if (error.code === AxiosError.ETIMEDOUT) {
        return new NetworkError(NetworkError.Canceled)
      }

      return new UnknownError(error.message, { cause: error })
    }

    return new UnknownError(error.message, { cause: error })
  }

  if (error instanceof Error) {
    return new UnknownError(error.message, { cause: error })
  }

  return new UnknownError()
}
