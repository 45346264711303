import React from 'react'

import { AppBar as MuiAppBar, Toolbar, makeStyles } from '@material-ui/core'
import classNames, { Argument as ClassNamesArg } from 'classnames'

// import { HideOnScroll } from '../components'
import { XSOnlySidebarButton } from './sidebar-button'

const SIDEBAR_BUTTON_WIDTH = 80
const DEFAULT_HEIGHT = 88

const useStyles = makeStyles(
  (theme) => ({
    appBar: {
      zIndex: 1200,
      background: 'white',
      left: SIDEBAR_BUTTON_WIDTH,
      width: `calc(100% - ${SIDEBAR_BUTTON_WIDTH}px)`,
      height: DEFAULT_HEIGHT,
      [theme.breakpoints.down('xs')]: {
        left: 0,
        width: '100%',
        height: 64,
      },
    },
    text: {
      whiteSpace: 'nowrap',
      // flexGrow: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 28,
      fontWeight: 500,
      [theme.breakpoints.down('xs')]: {
        fontSize: 22,
        fontWeight: 400,
        paddingLeft: theme.spacing(0),
      },
    },
    root: {
      height: '100%',
    },
  }),
  { name: 'PmAppBar' },
)

interface Props {
  className?: ClassNamesArg
}

export const TitleBarText: React.FC = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.text}>{children}</div>
}

const AppBar: React.FC<Props> = ({ children, className }) => {
  const classes = useStyles()

  return (
    // <HideOnScroll>
    <MuiAppBar color='secondary' elevation={0} className={classNames(classes.appBar, className)}>
      <Toolbar disableGutters classes={classes}>
        <XSOnlySidebarButton />
        {children}
      </Toolbar>
    </MuiAppBar>
    // </HideOnScroll>
  )
}

export default AppBar
