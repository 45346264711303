import React from 'react'

import { intersectionWith } from 'lodash'

import { isReferenceToEntity } from '../../../../../../model/base'
import { BusinessError } from '../../../../../../model/errors'
import { IViewSurveyScreenItem } from '../../../../../../model/screen-item'
import { ISurvey } from '../../../../../../model/survey'
import { getSurveysByPropertyName } from '../../../../../_common/hooks/useCreateSurvey'
import { useFetchQuestionnaire } from '../../../../../_common/hooks/useFetchQuestionnaire'
import { useScriptTaskContext } from '../../../../script-tasks/script-task-context'
import { SurveyPreview } from '../../../survey-preview'
import { getQuestionnaireByAssignments } from '../../surveys/utils'

interface IViewSurvey {
  item: IViewSurveyScreenItem
}

export const ViewSurveyHtml: React.FC<IViewSurvey> = ({ item }) => {
  const propertiesContext = useScriptTaskContext()
  const surveys: ISurvey[] = getSurveysByPropertyName(item.propertyName, propertiesContext)

  let questionnaireCode: string
  let survey: ISurvey | undefined
  try {
    const qref = getQuestionnaireByAssignments(item, [])
    questionnaireCode = qref.code
    survey = surveys.find((survey) => survey.questionnaire.code === questionnaireCode)
  } catch (e: unknown) {
    if (!item.questionnaires?.length) {
      return <div>В параметрах настройки на указан опросник</div>
    }
    const fittingQuestionnaires = intersectionWith(
      item.questionnaires,
      propertiesContext.questionnaires,
      isReferenceToEntity,
    )
    if (!fittingQuestionnaires?.length) {
      return <div>В параметрах настройки на указан опросник</div>
    }
    questionnaireCode = fittingQuestionnaires[0].code
    survey = surveys.find((survey) => survey.questionnaire.code === questionnaireCode)
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const questionnaireOps = useFetchQuestionnaire({ questionnaireCode }, [questionnaireCode])

  if (questionnaireOps.error) {
    if (!(questionnaireOps.error instanceof BusinessError)) {
      throw questionnaireOps.error
    }
  }

  if (questionnaireOps.loading && !questionnaireOps.value) {
    return <></>
  }

  const questionnaire = questionnaireOps.value!

  if (item.hideEmpty && !survey) {
    return <></>
  }

  if (survey) {
    return (
      <SurveyPreview key={survey.code} survey={survey} questionnaire={questionnaire} surveyName={item.displayName} />
    )
  } else {
    return <div>{item.nullValueCaption ?? 'Нет данных'}</div>
  }
}