import React from 'react';

import { Box, Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from "react-i18next";

import { useScriptTaskContext } from '../../../../tasks/script-tasks/script-task-context';
import { ItemCard } from '../../../../tasks/template-tasks/composite-screen/item-card';
import StoresListItem from '../../../stores-list-item/stores-list-item';
import { getRegisteredNameFromPos } from '../../utils';

const useStyles = makeStyles((theme: Theme) => 
createStyles({
  item: {
    position: 'relative',
    marginBottom: 18,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10,
    },
    '& .MuiPaper-root': {
      padding: 0,
      margin: '-8px 0px',
      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
    },
  },
  button: {
    position: 'absolute',
    top: 12,
    right: 20,
    textTransform: 'none',
    fontSize: 15,
    lineHeight: '26px',
    fontWeight: 500,
    color: theme.palette.primary.main,
    zIndex: 10,
  },
})
)

interface IProps {
  isChangeOption?: boolean
  handleClick?: () => void
}

const ProblemPosInfoScreenItem: React.FC<IProps> = ({isChangeOption=false, handleClick}) => {
  const { t } = useTranslation('problems')
  const localContext = useScriptTaskContext()
  const pos = localContext.pos!
  const classes = useStyles()
  const registeredName = pos && getRegisteredNameFromPos(pos)

  return (
    <Box className={classes.item}>
      {
        isChangeOption &&
        <Button 
          className={classes.button}
          onClick={handleClick}
        >
          {t('changePOS')}
        </Button>
      }
      <ItemCard label={t('posItemTitle')}>
        <StoresListItem
          name={pos.name}
          address={`${pos.address!.fullAddress}, ${pos.address!.city}`}
          code={pos.code}
          registerName={registeredName}
        />
      </ItemCard>
    </Box>
  );
};

export default ProblemPosInfoScreenItem