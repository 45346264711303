import React, { useContext } from 'react'

import { sortBy } from 'lodash'
import { useAsync } from 'react-use'

import { IEntityReference } from '../../../../../../model/base'
import { ISurveysPropertyScreenItem } from '../../../../../../model/screen-item'
import { ApiContext } from '../../../../../../providers'
import { useAsyncError } from '../../../../../_common/hooks/useAsyncError'
import { useFetchQuestionnaire } from '../../../../../_common/hooks/useFetchQuestionnaire'
import { getContextProperty } from '../../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../../script-tasks/script-task-context'
import { SurveyPreview } from '../../../survey-preview'
import { makeSurveyName } from '../../../utils'

export const SurveyListHtml: React.FC<{ item: ISurveysPropertyScreenItem }> = ({ item }) => {
  const propertiesContext = useScriptTaskContext()
  const api = useContext(ApiContext)
  const questionnaireCode = item.questionnaire.code
  const surveyRefs: IEntityReference[] = getContextProperty(propertiesContext, item.propertyName, [])
  const surveysOps = useAsync(async () => {
    const surveys = await api.survey.getSurveys(surveyRefs.map((ref) => ref.code))
    const fittingSurveys = surveys?.filter((survey) => survey.questionnaire.code === questionnaireCode) ?? []
    return sortBy(fittingSurveys, ['creationTime', 'code']).reverse()
  }, [surveyRefs])

  const questionnaireOps = useFetchQuestionnaire({ questionnaireCode }, [questionnaireCode])

  useAsyncError(surveysOps.error)
  useAsyncError(questionnaireOps.error)

  if (surveysOps.loading && !surveysOps.value) {
    return <></>
  }
  if (questionnaireOps.loading && !questionnaireOps.value) {
    return <></>
  }
  const surveys = surveysOps.value!
  const questionnaire = questionnaireOps.value!

  return (
    <div>
      {surveys.map((survey, i, arr) => {
        const surveyName = makeSurveyName(item, i, arr.length)
        return <SurveyPreview key={survey.code} survey={survey} questionnaire={questionnaire} surveyName={surveyName} />
      })}
    </div>
  )
}