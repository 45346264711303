import React, { useState, useEffect, useRef, useCallback } from 'react'

import {
  makeStyles,
  TextField,
  TextFieldProps,
  Typography,
  Theme,
  createStyles,
  Box,
  OutlinedInputProps,
} from '@material-ui/core'
import classNames from 'classnames'
import { IMask, IMaskInput } from 'react-imask'

import { IScreenItem, IStringInputScreenItem } from '../../../../model/screen-item'
import { checkPredicate } from '../../../../model/script-predicate'
import { useUpdateProperty } from '../../nested/useUpdateProperty'
import { getContextProperty } from '../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../script-tasks/script-task-context'
import { ItemCard } from './item-card'
// import { useThrottledFn } from './useThrottledFn'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    useEllipsis: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    useLineBreak: {
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
    },
    compact: {
      width: '100%',
      marginBottom: 8,
      '& [class*="MuiInputBase-root"]': {
        minHeight: '40px',
        borderRadius: '40px',
        padding: '10px 24px',
        fontSize: 14,
        backgroundColor: '#fff',
      },
    },
    compactError: {
      '& [class*="MuiInputBase-root"]:before': {
        content: `''`,
        height: '40px',
        width: '40px',
        borderRadius: '40px',
        borderLeft: '2px solid #D32F2F',
        borderTop: '2px solid #D32F2F',
        borderBottom: '2px solid transparent',
        borderRight: '2px solid transparent',
        transform: 'rotate(-45deg)',
        position: 'absolute',
        top: 0,
        left: 0,
      },
    },
  }),
)

type Props = {
  item: IStringInputScreenItem
  handleChange?: (item: IScreenItem, value: string, oldValue: string) => Promise<void>
  onBlur?: () => void
  setTaskPropertyPath?: (value: unknown) => Promise<void>
} & TextFieldProps

export const StringInputScreenItem: React.FC<Props> = ({
  item,
  // handleChange,
  // setTaskPropertyPath,
  // onBlur,
  ...props
}) => {
  const classes = useStyles()
  const localContext = useScriptTaskContext()
  // const contextRef = useLatest(localContext)

  const [currentValue, setCurrentValue] = useState<string>(() => getContextProperty(localContext, item.propertyName))
  // const currentValue: string | undefined = getContextProperty(localContext, item.propertyName)
  const updateProperty = useUpdateProperty()
  const onUpdate = (value: string): void => void updateProperty(item.propertyName, value)
  const [isError, setIsError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const renderCounter = useRef(0)

  const { displayName, placeholder } = item

  const isVisible = item.visible ? checkPredicate(item.visible, undefined, localContext) : true
  const isRequired = item.required ? checkPredicate(item.required, undefined, localContext) : false
  const multiline = true

  // const throttleSetTaskPropertyPath = useThrottledFn(setTaskPropertyPath, 50, { trailing: true })

  function hadleErrorState(): void {
    if (isRequired && !currentValue?.trim()) {
      setIsError(true)
      setErrorMessage(item.errorHelperText)
      return
    }
    if (item.maxLength != null && (currentValue?.trim().length ?? 0) > item.maxLength) {
      setIsError(true)
      setErrorMessage(`Количество символов не должно превышать ${item.maxLength}`)
    }
    if (currentValue && item.mask && currentValue.trim().length !== item.mask.length) {
      setIsError(true)
      setErrorMessage(`${item.helperText}`)
    } else {
      setIsError(false)
      setErrorMessage('')
    }
  }

  const getRequiredErrorProps = (): {
    helperText?: React.ReactNode
    FormHelperTextProps?: { error: boolean }
    error?: boolean
  } => {
    if (isError) {
      return {
        helperText: errorMessage,
        FormHelperTextProps: { error: true },
        error: true,
      }
    } else if (item.helperText !== '') {
      return {
        helperText: item.helperText,
        FormHelperTextProps: { error: false },
        error: false,
      }
    }
    return {}
  }

  const InputMask = useCallback(
    (props: InputMaskProps) => {
      const { onChange, ...other } = props
      const customMask = IMask.createMask({
        mask: `${item.mask}`,
        blocks: {
          YYYY: { mask: IMask.MaskedRange, placeholderChar: 'Y', from: 1900, to: 2999, maxLength: 4 },
          MM: { mask: IMask.MaskedRange, placeholderChar: 'M', from: 1, to: 12, maxLength: 2 },
          DD: { mask: IMask.MaskedRange, placeholderChar: 'D', from: 1, to: 31, maxLength: 2 },
          HH: { mask: IMask.MaskedRange, placeholderChar: 'H', from: 0, to: 23, maxLength: 2 },
          mm: { mask: IMask.MaskedRange, placeholderChar: 'm', from: 0, to: 59, maxLength: 2 },
          ss: { mask: IMask.MaskedRange, placeholderChar: 's', from: 0, to: 59, maxLength: 2 },
          tz: {
            mask: IMask.MaskedEnum,
            enum: ['00', '30'],
            placeholderChar: 'm',
          },
        },
        definitions: {
          '#': /[0-9]/,
        },
        placeholder: item.mask,
      })

      return (
        <IMaskInput
          {...other}
          {...customMask}
          onAccept={(value: unknown) => onChange({ target: { name: props.name, value: value as string } })}
          overwrite
        />
      )
    },
    [item.mask],
  )
  const renderInputMask = (): { InputProps: Partial<OutlinedInputProps> } | undefined => {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    return item.mask ? { InputProps: { inputComponent: InputMask as any } } : undefined
  }

  // useEffect(() => {
  //   const savedValue = getContextProperty(contextRef.current, item.propertyName)
  //   if (currentValue && currentValue !== savedValue) {
  //     void setTaskPropertyPath(currentValue)
  //   }
  // }, [isError])

  useEffect(() => {
    if (renderCounter.current !== 0) {
      hadleErrorState()
    }
  }, [currentValue, item.required])

  return (
    <>
      {isVisible && (
        <StringInputScreenItemWrapper
          displayName={displayName}
          isError={isRequired && !currentValue}
          isCompact={!!item.compactMode}
        >
          <Typography
            variant='body1'
            color='textSecondary'
            className={classNames({
              [classes.useEllipsis]: !multiline,
              [classes.useLineBreak]: multiline,
            })}
          ></Typography>

          <Box marginY={inputExtraMargin}>
            <TextField
              // size='small'
              multiline={multiline}
              fullWidth
              variant='outlined'
              placeholder={placeholder}
              {...getRequiredErrorProps()}
              value={currentValue}
              onChange={(evt) => {
                // void handleChange(item, evt.target.value, currentValue)
                // void throttleSetTaskPropertyPath(evt.target.value.trim())
                setCurrentValue(evt.target.value)
                onUpdate(evt.target.value)
                renderCounter.current = renderCounter.current + 1
              }}
              // onBlur={() => {
              //   onBlur?.()
              //   // void throttleSetTaskPropertyPath.flush()
              // }}
              {...renderInputMask()}
              {...props}
            ></TextField>
          </Box>
        </StringInputScreenItemWrapper>
      )}
    </>
  )
}

const inputExtraMargin = { xs: 0, sm: 1 }

interface InputMaskProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

interface WrapperProps {
  displayName: string
  isError: boolean
  isCompact: boolean
}
const StringInputScreenItemWrapper: React.FC<WrapperProps> = ({ children, displayName, isError, isCompact }) => {
  const classes = useStyles()
  if (isCompact) {
    return <div className={classNames(classes.compact, { [classes.compactError]: isError })}>{children}</div>
  }
  return (
    <ItemCard isError={isError} label={<Typography variant='inherit'>{displayName}</Typography>}>
      {children}
    </ItemCard>
  )
}
