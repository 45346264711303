import React from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import { grays } from '../../../../layout/theme'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      border: `1px solid ${grays.gray5}`,
      marginBottom: 8,
      width: '100%',
      // [theme.breakpoints.down('xs')]: { // Вроде у нас на всех разрешения отступ от кирпича снизу 8px??
      //   marginBottom: 4,
      // },
      '&.Mui-expanded, &.Mui-expanded:last-child': {
        marginTop: 0,
        marginBottom: 8,
        // [theme.breakpoints.down('xs')]: { // Вроде у нас на всех разрешения отступ от кирпича снизу 8px??
        //   marginBottom: 4,
        // },
      },
      '&::before': {
        content: 'none',
      },
    },
    summary: {
      alignItems: 'flex-start',
      fontSize: 18,
      fontWeight: 600,
      letterSpacing: '0.15px',
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        padding: '2px 16px 0 16px',
        fontSize: 16,
      },
    },
    chevron: {
      transform: 'rotate(-90deg)',
      transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      marginRight: theme.spacing(0.5),
      '.Mui-expanded &': {
        transform: 'rotate(0deg)',
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: -18,
        marginRight: 0,
      },
    },
    summaryRoot: {
      alignItems: 'flex-start',
      paddingLeft: 8,
      borderBottom: `1px solid ${grays.gray5}`,
      '&:not(.Mui-expanded)': {
        marginBottom: -1,
      },
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
    details: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 8,
        paddingRight: 6,
      },
    },
  }),
)

interface Props {
  label: React.ReactNode
  isOpen?: boolean
  isOpenDefault?: boolean
  onChange?: (isOpen: boolean) => void
}

export const AccordionCard: React.FC<Props> = ({ label, isOpen, onChange, children, isOpenDefault }) => {
  const classes = useStyles()
  return (
    <Accordion //
      defaultExpanded={isOpenDefault}
      expanded={isOpen}
      onChange={(_ev, val) => onChange?.(val)}
      elevation={0}
      square
      className={classes.card}
    >
      <AccordionSummary classes={{ content: classes.summary, root: classes.summaryRoot }}>{label}</AccordionSummary>
      <AccordionDetails className={classes.details}>{children}</AccordionDetails>
    </Accordion>
  )
}

export const AccordionArrow: React.FC = () => {
  const classes = useStyles()

  return (
    <IconButton className={classes.chevron}>
      <ArrowDropDownIcon />
    </IconButton>
  )
}
