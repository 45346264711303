import React from 'react'

import { createStyles } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Palette, PaletteColor } from '@material-ui/core/styles/createPalette'
import classNames from 'classnames'

import { grays } from '../../../../../../layout/theme'

type Color = keyof Palette | keyof typeof grays
type Form = 'line' | 'circle'

interface IStyleProp {
  status?: Color
  form?: Form
}

const useStyles = makeStyles<Theme, IStyleProp>((theme) => {
  const getColor = (color: Color): string => {
    if (color in grays) {
      return grays[color as keyof typeof grays]
    } else {
      return (theme.palette[color as keyof Palette] as PaletteColor)?.main
    }
  }
  return createStyles({
    statusIndicator: {
      display: 'inline-block',
      verticalAlign: 'middle',
      height: 28,
      background: theme.palette.info.main,
      backgroundColor: ({ status }) => getColor(status ?? 'info'),
      width: 3,
      borderRadius: '100px',
      margin: theme.spacing(0, 2, 0, 0),
    },
    circle: {
      width: 10,
      height: 10,
      borderRadius: '50%',
    },
    line: {
      height: 28,
      width: 3,
    },
  })
})

interface ILabelMarker {
  status?: string
  form?: string
}

export const LabelMarker: React.FC<ILabelMarker> = ({ status, form }) => {
  const classes = useStyles({ status: status as Color, form: form as Form })
  return <span className={classNames(classes.statusIndicator, classes[form ?? 'line'])}></span>
}
