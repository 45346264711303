import React, { useContext, useEffect, useState } from 'react'

import { Box, Button, Container, createStyles, LinearProgress, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAsync } from 'react-use'

import { DumpService } from '../../infrastructure/dump-service/dump-service'
import HttpClientFactory from '../../infrastructure/http-client-factory/http-client-factory'
import { LogManager } from '../../infrastructure/logger'
import { LockedPage } from '../../layout/locked-page'
import { AuthContext, ConfigContext, ApiContext } from '../../providers'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentWrap: {
      padding: 24,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      lineHeight: '36px',
      textAlign: 'center',
      fontSize: 28,
      fontStyle: 'normal',
      fontWeight: 500,
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
        lineHeight: '28px',
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    submit: {
      height: 48,
      paddingLeft: 24,
      paddingRight: 24,
      borderRadius: 28,
      marginTop: 24,
    },
  }),
)

enum Error {
  dump = 'get-dump-error',
  send = 'send-error',
  null = '',
}

enum State {
  loading,
  error,
  success,
}

const getTextError = (error: Error): string => {
  switch (error) {
    case Error.dump:
      return 'При формировании снимка данных произошла ошибка'
    case Error.send:
      return 'При отправке снимка данных произошла ошибка'
    default:
      return 'Ошибка'
  }
}

const logger = LogManager.getLogger('DumpLoadPage')

const DumpLoadPage: React.FC = () => {
  const { t } = useTranslation('sync')

  const auth = useContext(AuthContext)
  const cfg = useContext(ConfigContext)
  const api = useContext(ApiContext)
  const authService = auth.authService
  const profileService = api?.userProfile
  const httpClientFactory = new HttpClientFactory(cfg.config.apiUrl, authService)

  let dumpService = api.dumpService
  useEffect(() => {
    if (!dumpService) {
      dumpService = new DumpService(authService, profileService, httpClientFactory)
    }
  }, [dumpService])

  const navigate = useNavigate()
  const classes = useStyles()
  const [state, setState] = useState<State>(State.loading)
  const [error, setError] = useState<Error>(Error.null)

  useAsync(async () => {
    let dumps
    try {
      dumps = await dumpService.dumpAll()
    } catch (error) {
      logger.critical('dumpService.dumpAll', 'Failed making dumps of IndexedDB', error)
      setState(State.error)
      setError(Error.dump)
      return
    }

    try {
      await Promise.all(dumps.map(async (dump) => dumpService.uploadDump(dump)))
      setState(State.success)
    } catch (error) {
      logger.critical('dumpService.uploadAll', 'Failed uploading dumps of IndexedDB', error)
      setState(State.error)
      setError(Error.send)
    }
  }, [])

  return (
    <LockedPage>
      <Container disableGutters fixed className={classes.contentWrap} component='main' maxWidth='sm'>
        <div className={classes.content}>
          {state === State.loading ? (
            <>
              <Box marginBottom={4}>
                <Typography className={classes.title}>{t('waiting')}</Typography>
                <Typography className={classes.title}>{t('sendDump')}</Typography>
              </Box>
              <LinearProgress color='secondary' />
            </>
          ) : (
            <></>
          )}
          {state === State.error ? (
            <>
              <Box>
                <Typography className={classes.title}>{getTextError(error)}</Typography>
              </Box>
              <Box mt={8} textAlign='center'>
                <Button className={classes.submit} color='primary' variant='contained' onClick={() => navigate('/')}>
                  {t('closeDump')}
                </Button>
              </Box>
            </>
          ) : (
            <></>
          )}
          {state === State.success ? (
            <>
              <Box>
                <Typography className={classes.title}>{t('sendDumpSuccess')}</Typography>
              </Box>
              <Box textAlign='center'>
                <Button className={classes.submit} color='primary' variant='contained' onClick={() => navigate('/')}>
                  {t('onMain')}
                </Button>
              </Box>
            </>
          ) : (
            <></>
          )}
        </div>
      </Container>
    </LockedPage>
  )
}

export default DumpLoadPage
