import React, { useContext } from 'react'

import { Container, Theme, Button, Typography, createStyles, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { useNavigate, useLocation } from 'react-router-dom'
import { useMountedState } from 'react-use'

import { AuthError, AuthErrorCode } from '../../infrastructure/auth'
import { LogManager } from '../../infrastructure/logger'
import { LockedPage } from '../../layout/locked-page'
import { AuthContext } from '../../providers'
import { ILoginPageErrorStateFromRoute } from './models'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 'calc(100% + 140px)',
      position: 'relative',
      padding: theme.spacing(2),
      backgroundImage: 'url("/pmi-logo-sync.png")',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },
    topPanel: {
      background: theme.palette.secondary.main,
      height: theme.spacing(4),
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
    },
    bottomPanel: {
      background: theme.palette.secondary.main,
      height: theme.spacing(4),
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
    },
    contentWrap: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      justifyContent: 'space-around',
      paddingTop: theme.spacing(2),
    },
    title: {
      lineHeight: '36px',
      textAlign: 'center',
      fontSize: 34,
      fontStyle: 'normal',
      fontWeight: 300,
      [theme.breakpoints.down('xs')]: {
        fontSize: 24,
        lineHeight: '26px',
      },
    },
    errorMsg: {
      lineHeight: '24px',
      textAlign: 'center',
      fontSize: 24,
      fontStyle: 'normal',
      letterSpacing: '0.25px',
      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
        lineHeight: '18px',
      },
    },
    errorIcon: {
      verticalAlign: 'middle',
      marginRight: 8,
      color: theme.palette.error.dark,
    },
    submit: {
      height: 48,
      width: 260,
      borderRadius: 28,
    },
  }),
)

const logger = LogManager.getLogger('LoginPageError')

const LoginPageError: React.FC = () => {
  const { logout } = useContext(AuthContext)
  const location = useLocation() as { state: ILoginPageErrorStateFromRoute }
  const navigate = useNavigate()
  const isMounted = useMountedState()
  const classes = useStyles()
  const { code, status, message, userName } = location.state ?? {}
  const { title, showLogin, description } = mapAuthError(code, status, message)

  const onLoginHandler = async (): Promise<void> => {
    try {
      if (logout) {
        await logout()
        if (isMounted()) {
          navigate('/')
        }
      }
    } catch (e) {
      logger.error('onLoginHandler', 'Ошибка входа', e)
    }
  }

  return (
    <LockedPage>
      <Container disableGutters fixed className={classes.contentWrap} component='main' maxWidth='md'>
        <div className={classes.content}>
          <Box>
            {showLogin && userName && (
              <Typography className={classes.title}>
                Пользователь: <b>{userName ?? ''}</b>
              </Typography>
            )}
            <Box mt={2}>
              <Typography className={classes.errorMsg}>
                <span className={classes.errorIcon}>
                  <InfoOutlinedIcon />
                </span>
                {title ?? 'Ошибка входа'}
              </Typography>
              <Box mt={2} textAlign='center'>
                <Typography>{description}</Typography>
              </Box>
            </Box>
          </Box>

          <Box display='flex' justifyContent='center'>
            <Button
              size='large'
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              onClick={onLoginHandler}
            >
              Сменить пользователя
            </Button>
          </Box>
        </div>
      </Container>
    </LockedPage>
  )
}

export default LoginPageError

function mapAuthError(
  code: AuthError['code'],
  status: AuthError['status'],
  message: AuthError['message'],
): { title: string; showLogin: boolean; description: string } {
  switch (code) {
    case AuthErrorCode.GetToken401Error:
    case AuthErrorCode.GetToken403Error:
    case AuthErrorCode.GetTokenBusinessError:
    case AuthErrorCode.GetTokenOtherError:
      return {
        title: 'Ошибка аутентификации',
        showLogin: false,
        description: `При получении токена произошла ошибка (${status})`,
      }
    case AuthErrorCode.InvalidClient:
      return {
        title: 'Ошибка аутентификации',
        showLogin: false,
        description: 'Необходимо обновление приложения. В настройках указан некорректный clientId',
      }
    case AuthErrorCode.GetTokenUnavailableError:
      return {
        title: 'Ошибка аутентификации',
        showLogin: false,
        description: 'Сервер аутентификации недоступен',
      }
    default:
      return {
        title: 'Ошибка входа',
        showLogin: true,
        description: message,
      }
  }
}
