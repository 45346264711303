import { isToday } from 'date-fns'

import { IPointOfSale } from '../../model/pos'
import { IVisit, VisitStatus } from '../../model/visit'
import { getRegisteredNameFromPos } from '../stores/store-menu-page/utils'

export function filterCancelledVisitsByToday(visit: IVisit): boolean {
  if (visit.status === 'Canceled') {
    return isToday(visit.plannedStartDate) && isToday(visit.updateTime)
  }
  return true
}

export function filterStoreByQuery(pos: IPointOfSale | null, filterQuery: string): boolean | undefined {
  const registeredName = pos && getRegisteredNameFromPos(pos)

  return `
  ${pos?.name ?? ''}
  ${pos?.code ?? ''}
  ${pos?.address?.city ?? ''}
  ${pos?.address?.fullAddress ?? ''}
  ${registeredName ?? ''}`
    .trim()
    .toLowerCase()
    .includes(filterQuery.trim().toLowerCase())
}

export function filterCancelledVisits(visit: IVisit): boolean {
  return visit.status !== 'Canceled'
}

//#region Sorting
export function sortByStatus(a: IVisit, b: IVisit): number {
  if (!a.status || !b.status) {
    return -1
  }
  // Визиты в списке визитов на день должны сортироваться по статусу ("выполняется", "запланирован", "завершен")
  const itemOrder: VisitStatus[] = ['InProgress', 'Planned', 'Finished', 'Canceled']
  return itemOrder.indexOf(a.status) - itemOrder.indexOf(b.status)
}

export function sortByDate(a: IVisit, b: IVisit): number {
  if (!a.plannedStartDate || !b.plannedStartDate) {
    return -1
  }
  // Визиты в списке визитов на день должны сортироваться по времени начала визита (по возрастанию)
  return new Date(a.plannedStartDate).valueOf() - new Date(b.plannedStartDate).valueOf()
}

export function sortByCreationTime(a: IVisit, b: IVisit): number {
  if (!a.creationTime || !b.creationTime) {
    return -1
  }
  // Визиты в списке визитов на день должны сортироваться по времени начала визита (по возрастанию)
  return new Date(a.creationTime).valueOf() - new Date(b.creationTime).valueOf()
}

export function sortByCode(a: IVisit, b: IVisit): number {
  if (!a.code || !b.code) {
    return -1
  }
  // Визиты в списке визитов на день должны сортироваться по идентификатору визита (по возрастанию)
  return a.code.localeCompare(b.code)
}

//#endregion Sorting
