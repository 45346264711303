import { endOfDay, startOfDay } from 'date-fns'
import haversine from 'haversine'

import {
  POSStore,
  SurveyStore,
  VisitStore,
  TaskStore,
  VisitStore_pos,
  VisitStore_plannedStartDate,
  TaskStore_visit,
  VisitAssessmentStore,
  VisitAssessmentStore_updateTime,
  TaskExecutionStateStore,
  TaskExecutionScopeStore, TaskTemplateStore,
} from '../../data/schema'
import {Code, generateEntityCode, getEntityKey} from '../../model/base'
import { FieldForceTaskCancelationReason } from '../../model/dictionary-item'
import { BusinessError, BusinessErrorCode, ValidationError, ValidationErrorCode } from '../../model/errors'
import { ISurvey } from '../../model/survey'
import { IVisit, VisitStatus } from '../../model/visit'
import { IVisitAssessment } from '../../model/visit-assessment'
import { IVisitTask, VisitTaskStatus } from '../../model/visit-task'
import { dateFormatIso } from '../../utils'
import { trace } from '../../utils/trace'
import IVisitService, {
  CountVisitTasksRequest,
  CreateVisitRequest,
  IVisitTasksCount,
  SearchVisitsRequest,
  SetVisitGpsCoordinatesRequest,
  SetVisitStatusRequest,
  ShiftVisitRequest,
} from '../visit-service-api'
import { LocalStorageBaseService } from './local-storage-base-service'

export default class LocalStorageVisitService extends LocalStorageBaseService implements IVisitService {
  private static readonly __className = 'LocalStorageVisitService'

  @trace()
  async createVisit(createVisitRequest: CreateVisitRequest): Promise<IVisit> {
    if (!createVisitRequest.date || !createVisitRequest.pointOfSaleCode) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing,
        'date and pointOfSaleCode are required in CreateVisitRequest',
        ['date', 'pointOfSaleCode'],
      )
    }

    const userRef = await this._getCurrentUserReference()
    if (!userRef) throw new BusinessError(BusinessErrorCode.Unauthorized, 'No current user')

    const roleRef = await this._getCurrentRoleReference()
    if (!roleRef) throw new BusinessError(BusinessErrorCode.Unauthorized, 'Current user has no role')

    const position = await this._getCurrentProfilePosition()
    if (!position) throw new BusinessError(BusinessErrorCode.Unauthorized, 'Current user has no position')

    return await this._storage.execute([POSStore, VisitStore], async (tx) => {
      const pos = await this._getPosOrThrow(createVisitRequest.pointOfSaleCode, tx)
      const now = Date.now()
      const visit: IVisit = {
        code: generateEntityCode('VIS'),
        codeSpace: this.defaultCodeSpace,
        _changeTime: now,
        creationTime: now,
        updateTime: now,
        startDate: createVisitRequest.date,
        localStartDate: dateFormatIso(createVisitRequest.date),
        plannedStartDate: createVisitRequest.date,
        status: 'Planned',
        source: createVisitRequest.source,
        outOfRouteReason: createVisitRequest.outOfRouteReason,
        pointOfSaleCode: pos.code,
        executedBy: userRef,
        executivePositionRole: roleRef,
        // deprecated
        assignee: {
          userCode: userRef.code,
          positionCode: position.code,
          positionRoleCode: roleRef.code,
        },
      }
      await tx.put(VisitStore, visit)
      return visit
    })
  }

  @trace()
  async searchVisits(request?: SearchVisitsRequest): Promise<IVisit[]> {
    let visitsIterator: AsyncIterableIterator<IVisit>
    let filterByDate = false

    if (request?.pointOfSaleCode) {
      visitsIterator = this._storage.selectByIndexRange<IVisit>(VisitStore, VisitStore_pos, [
        '=',
        request.pointOfSaleCode,
      ])
      filterByDate = !!request.plannedDate
    } else if (request?.plannedDate) {
      visitsIterator = this._storage.selectByIndexRange<IVisit>(VisitStore, VisitStore_plannedStartDate, [
        '<=<=',
        request.plannedDate.from,
        request.plannedDate.to,
      ])
    } else {
      visitsIterator = this._storage.selectAll<IVisit>(VisitStore)
    }

    const visits: IVisit[] = []

    for await (const visit of visitsIterator) {
      if (
        (!request?.executivePositionCode ||
          request.executivePositionCode === (visit.executivePositionRole?.code ?? visit.assignee?.positionRoleCode)) &&
        (!request?.executedByUserCode ||
          request.executedByUserCode === (visit.executedBy?.code ?? visit.assignee?.userCode)) &&
        (!filterByDate ||
          (visit.plannedStartDate >= request!.plannedDate!.from && visit.plannedStartDate <= request!.plannedDate!.to))
      ) {
        visits.push(visit)
      }
    }

    return visits
  }

  async getVisit(visitCode: Code): Promise<IVisit | null> {
    return await this._getVisit(visitCode, this._storage)
  }

  @trace()
  async shiftVisit(shiftVisitRequest: ShiftVisitRequest): Promise<IVisit> {
    if (!shiftVisitRequest.visitCode || !shiftVisitRequest.pointOfSaleCode || !shiftVisitRequest.date) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing,
        'visitCode, pointOfSaleCode and date are required in ShiftVisitRequest',
        ['visitCode', 'pointOfSaleCode', 'date'],
      )
    }

    return await this._storage.execute([VisitStore, POSStore, TaskStore], async (tx) => {
      const visit = await this._getVisitOrThrow(shiftVisitRequest.visitCode, tx)
      const pos = await this._getPosOrThrow(shiftVisitRequest.pointOfSaleCode, tx)

      visit.startDate = shiftVisitRequest.date
      visit.localStartDate = dateFormatIso(shiftVisitRequest.date)
      visit.plannedStartDate = shiftVisitRequest.date
      visit.pointOfSaleCode = shiftVisitRequest.pointOfSaleCode
      visit.outOfRouteReason = shiftVisitRequest.outOfRouteReason
      visit.updateTime = Date.now()

      for (const task of await this._getVisitTasks(visit.code, tx)) {
        this._updateVisitTaskFromVisit(task, visit, pos)
        await tx.put(TaskStore, task)
      }

      visit._changeTime = Date.now()
      await tx.put(VisitStore, visit)

      return visit
    })
  }

  @trace()
  async setVisitStatus(setVisitStatusRequest: SetVisitStatusRequest): Promise<IVisit> {
    if (!setVisitStatusRequest.visitCode || !setVisitStatusRequest.visitStatus) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing,
        'visitCode and visitStatus are required in SetVisitStatusRequest',
        ['visitCode', 'visitStatus'],
      )
    }
    /*
    if (setVisitStatusRequest.visitStatus === 'Canceled' && setVisitStatusRequest.cancelationReason == null) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing,
        'cancelationReason must be specified for Canceled status',
        ['cancelationReason']
      )
    }
    */

    const userRef = await this._getCurrentUserReference()
    const roleRef = await this._getCurrentRoleReference()

    return await this._storage.execute([VisitStore, TaskStore, SurveyStore, TaskTemplateStore], async (tx) => {
      const visit = await this._getVisitOrThrow(setVisitStatusRequest.visitCode, tx)

      // no need to change visit status
      if (visit.status === setVisitStatusRequest.visitStatus) {
        return visit
      }

      if (setVisitStatusRequest.visitStatus === 'Canceled') {
        visit.preCanceledStatus = visit.status
        visit.cancelationReason = setVisitStatusRequest.cancelationReason
        visit.executedBy = userRef
        visit.executivePositionRole = roleRef
        // FACE-141 При отмене визита автоматически должны отменяться все задачи визита (запланированные, выполняющиеся, выполненные).
        // При отмене задачи должны автоматически отменяться опросы задачи
        const taskCancellationReason: FieldForceTaskCancelationReason = {
          $type: 'PMI.BDDM.Transactionaldata.FieldForceTaskCancelationReason',
          code: 'VisitCancelled',
          name: 'В связи с отменой визита',
        }
        // cancelling visit tasks
        for (const task of await this._getVisitTasks(visit.code, tx)) {
          const versionedTemplateRef = await this._getTaskVersionedTemplateRef(task.template, tx)

          if (versionedTemplateRef) {
            task.template = versionedTemplateRef
          }

          if (this._setTaskStatus(task, 'Canceled', taskCancellationReason)) {
            await tx.put(TaskStore, task)
          }
        }
        // cancelling visit surveys
        for (const survey of await this._getVisitSurveys(visit.code, tx)) {
          if (this._setSurveyStatus(survey, 'Canceled')) {
            await tx.put(SurveyStore, survey)
          }
        }
      } else {
        const now = Date.now()
        visit.preCanceledStatus = undefined
        visit.cancelationReason = undefined
        if (visit.status === 'Planned' && setVisitStatusRequest.visitStatus === 'InProgress') {
          visit.executedBy = userRef
          visit.executivePositionRole = roleRef
          visit.startDate = now
          visit.localStartDate = dateFormatIso(now)
          // FACE-2857?focusedCommentId=1456207#comment-1456207
          if (setVisitStatusRequest.tasksToInclude) {
            const visitTaskSet = new Set((await this._getVisitTasks(visit.code, tx)).map(t => t.code))
            const tasksToInclude = (await tx.getByKeys<IVisitTask>(
                TaskStore, setVisitStatusRequest.tasksToInclude.filter(tc => !visitTaskSet.has(tc))
              )).filter(t => t._isVisitTask && t._isTaskRegister)
            if (tasksToInclude) {
              for (const task of tasksToInclude) {
                console.debug(`setVisitStatus: Including TaskRegister '${task.code}' in Visit '${visit.code}'`)
                task.visitCode = visit.code
                task._isTaskRegister = false
              }
              await tx.put(TaskStore, tasksToInclude)
            }
          }
        } else if (visit.status === 'InProgress' && setVisitStatusRequest.visitStatus === 'Finished') {
          visit.endDate = now
          visit.localEndDate = dateFormatIso(now)
        }
      }
      visit.status = setVisitStatusRequest.visitStatus
      visit.updateTime = visit._changeTime = Date.now()

      await tx.put(VisitStore, visit)

      return visit
    })
  }

  @trace()
  async countDailyVisitTasks({
    fromDate,
    toDate,
    visitStatus,
    visitTaskStatus,
    showZero,
  }: CountVisitTasksRequest): Promise<IVisitTasksCount[]> {
    const visitStatusSet = new Set<VisitStatus>(visitStatus ?? ['Planned', 'InProgress', 'Finished'])
    const visitTaskStatusSet = new Set<VisitTaskStatus>(visitTaskStatus ?? ['Planned', 'InProgress', 'Finished'])
    if (fromDate > toDate) {
      throw new ValidationError(ValidationErrorCode.PreconditionFailed, 'fromDate should be earlier than toDate', [
        'fromDate',
        'toDate',
      ])
    }
    fromDate = startOfDay(fromDate).getTime()
    toDate = endOfDay(toDate).getTime()

    const counts = new Map<number, number>()
    await this._storage.execute(
      [VisitStore, TaskStore],
      async (tx) => {
        for await (const visit of tx.selectByIndexRange<IVisit>(VisitStore, VisitStore_plannedStartDate, [
          '<=<=',
          fromDate,
          toDate,
        ])) {
          if (visitStatusSet.has(visit.status)) {
            let taskCount = 0
            for await (const task of tx.selectByIndexRange<IVisitTask>(TaskStore, TaskStore_visit, ['=', visit.code])) {
              if (visitTaskStatusSet.has(task.status)) {
                taskCount++
              }
            }
            if (!showZero && taskCount === 0) {
            } else {
              const visitDate = startOfDay(visit.plannedStartDate).getTime()
              counts.set(visitDate, (counts.get(visitDate) ?? 0) + taskCount)
            }
          }
        }
      },
      'r',
    )
    const result: IVisitTasksCount[] = []
    counts.forEach((count, date) => result.push({ date, count }))
    return result
  }

  @trace()
  async setVisitCoordinates({ visitCode, coordinates }: SetVisitGpsCoordinatesRequest): Promise<IVisit> {
    if (!visitCode || !coordinates) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing,
        'visitCode and coordinates are required in SetVisitGpsCoordinatesRequest',
        ['visitCode', 'coordinates'],
      )
    }

    return await this._storage.execute([VisitStore, POSStore], async (tx) => {
      const visit = await this._getVisitOrThrow(visitCode, tx)
      const pos = await this._getPos(visit.pointOfSaleCode, tx)

      if (pos?.coordinates) {
        const distance = haversine(coordinates, pos.coordinates, { unit: 'meter' })
        visit.distanceVariance = {
          value: distance,
          unitOfMeasure: 'm',
        }
      }

      visit.gpsCoordinates = coordinates
      visit.updateTime = visit._changeTime = Date.now()

      await tx.put(VisitStore, visit)

      return visit
    })
  }

  async restoreCanceledVisit(visitCode: Code): Promise<boolean> {
    if (!visitCode) {
      throw new ValidationError(ValidationErrorCode.RequiredFieldsMissing, 'visitCode is a required parameter', [
        'visitCode',
      ])
    }

    return await this._storage.execute([VisitStore], async (tx) => {
      const visit = await this._getVisitOrThrow(visitCode, tx)
      if (visit.status === 'Canceled') {
        if (visit.preCanceledStatus === 'InProgress') {
          const now = Date.now()
          visit.startDate = now
          visit.localStartDate = dateFormatIso(now)
        }
        visit.status = visit.preCanceledStatus ?? 'Planned'
        visit.preCanceledStatus = undefined
        visit.cancelationReason = undefined

        visit.updateTime = visit._changeTime = Date.now()
        await tx.put(VisitStore, visit)
        return true
      }
      return false
    })
  }

  async getLastNewVisitAssessment(posCode: Code): Promise<IVisitAssessment | null> {
    const currentRoleReference = await this._getCurrentRoleReference()
    if (!currentRoleReference) {
      throw new BusinessError(BusinessErrorCode.Unauthorized, 'Cant determine current user role')
    }
    const roleKey = getEntityKey(currentRoleReference, true)
    return await this._storage.execute(
      [VisitAssessmentStore, VisitStore],
      async tx => {
        const selector = tx.selectByIndexRange<IVisitAssessment>(
          VisitAssessmentStore, VisitAssessmentStore_updateTime, null, 'desc'
        )
        for await (const va of selector) {
          const visitCode = va.visit?.code
          if (!visitCode) continue
          const visit = await this._getVisit(visitCode, tx)
          if (!visit) continue
          if (visit.pointOfSaleCode !== posCode) continue
          if (va._acceptedBy?.includes(roleKey)) continue
          return va
        }
        return null
      },
      'r'
    )
  }

  async acceptVisitAssessment(vaCode: Code): Promise<boolean> {
    const currentRoleReference = await this._getCurrentRoleReference()
    if (!currentRoleReference) {
      throw new BusinessError(BusinessErrorCode.Unauthorized, 'Cant determine current user role')
    }
    const roleKey = getEntityKey(currentRoleReference, true)
    return await this._storage.execute(
      [VisitAssessmentStore],
      async tx => {
        const va = await tx.getByKey<IVisitAssessment>(VisitAssessmentStore, vaCode)
        if (!va) {
          return false
        }
        if (va._acceptedBy) {
          if (va._acceptedBy.includes(roleKey)) {
            return false
          } else {
            va._acceptedBy.push(roleKey)
          }
        } else {
          va._acceptedBy = [roleKey]
        }
        await tx.put(VisitAssessmentStore, va)
        return true
      }
    )
  }

  @trace()
  async purgeVisit(visitCode: Code): Promise<void> {
    await this._storage.execute(
      [VisitStore, TaskStore, SurveyStore, TaskExecutionStateStore, TaskExecutionScopeStore],
      async (tx) => {
        return await this._purgeVisit(visitCode, tx)
      },
      'rw'
    )
  }

  async getVisitSurveys(visitCode: Code): Promise<ISurvey[]> {
    if (!visitCode) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing, 'visitCode is a required parameter', ['visitCode']
      )
    }
    return await this._storage.execute(
      [VisitStore, SurveyStore],
      async (tx) => {
        return await this._getVisitSurveys(visitCode, tx)
      },
      'r'
    )
  }
}
