import React, { useContext } from 'react'

import { Navigate } from 'react-router-dom'
import { useLocation } from 'react-use'

import { MenuContext } from '../../layout/menu/menu-context'
import {
  DEV_FEATURE_CODE,
  IPermission,
  POS_CODE,
  POS_SECTION,
  REPORTS_CODE,
  REPORTS_SECTION,
  VISIT_CODE,
  VISIT_SECTION,
} from '../../model/user-profile'
import { AppRoutes } from '../../routes'
import { ProfileContext } from '../profile'
import { AuthContext } from './index'

const PATH_TO_LOGIN_PAGE = '/'.concat(AppRoutes.login.path)
const PATH_TO_VISITS_PAGE = '/'.concat(AppRoutes.visits.path)
const PATH_TO_STORES_PAGE = '/'.concat(AppRoutes.stores.path)
const PATH_TO_REPORTS_PAGE = '/'.concat(AppRoutes.reports.path)
// const PATH_TO_SYNC_PAGE = '/sync-auto'
const PATH_TO_DEBUG_PAGE = '/'.concat(AppRoutes.debug.path)

const defaultPaths = [
  { code: VISIT_SECTION, permission: VISIT_CODE, path: PATH_TO_VISITS_PAGE },
  { code: POS_SECTION, permission: POS_CODE, path: PATH_TO_STORES_PAGE },
  { code: REPORTS_SECTION, permission: REPORTS_CODE, path: PATH_TO_REPORTS_PAGE },
  { permission: DEV_FEATURE_CODE, path: PATH_TO_DEBUG_PAGE },
]

const AuthRoute: React.FC = ({ children }) => {
  const { pathname } = useLocation()

  const authContext = useContext(AuthContext)
  const user = authContext.currentUserName

  const profile = useContext(ProfileContext)
  const menu = useContext(MenuContext)
  const permissions: IPermission[] | undefined = profile?.value?.profile.permissions

  if (!user) {
    return <Navigate to={PATH_TO_LOGIN_PAGE} />
  }

  // if (!profile.value) {
  //   if (pathname === PATH_TO_SYNC_PAGE) {
  //     console.log('auth route permitted', pathname)
  //     return <>{children}</>
  //   }
  //   return <Navigate to={PATH_TO_SYNC_PAGE} /> // TODO: change profile and sync page initialization ordering
  // }

  for (const item of menu.permittedMenuItems) {
    const feature = item.action.feature
    if (pathname === feature.routePath) {
      if (!permissions?.find((permission) => permission.code === item.permission)) {
        return <Navigate to={'/'} />
      }
    }
  }

  if (pathname === '/') {
    if (profile?.value?.profile.defaultSection) {
      const defaultPath = defaultPaths.find((path) => path.code === profile.value!.profile.defaultSection)

      if (defaultPath && permissions?.find((permission) => permission.code === defaultPath.permission)) {
        return <Navigate to={defaultPath.path} />
      }
    }

    const firstAvailablePath = menu.permittedMenuItems[0]?.action.feature.routePath
    // console.log({ firstAvailablePath })
    if (firstAvailablePath) {
      return <Navigate to={`/${firstAvailablePath}`} />
    }
  }

  console.log('auth route permitted', pathname)
  return <>{children}</>
}

export default AuthRoute
