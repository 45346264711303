import { AnswerReplyPredicate } from './answer-reply-predicate'

/**
 * Дополнительные требования, зависящие от значения ответа на вопрос
 */
export interface IAnswerReplyRequirements {
  /**
   * Условие на значение ответа, при выполнении которого срабатывают требования к ответу.
   * Условие применяется к значению ответа на вопрос (Answer.Reply).
   * Если значение параметра не заполено, то требования применяются для любого значения ответа на вопрос (даже для пустого).
   */
  replyCondition?: AnswerReplyPredicate
  /**
   * Требования на наличие комментария к ответу, если ответ на вопрос равен Answer
   */
  commentRequirements?: AnswerReplyCommentRequirements
  /**
   * Требования к наличию вложений. Может быть не более одного элемента на каждый тип вложения (Photo/Video)
   */
  attachmentRequirements?: IAttachmentRequirements[]
}

/**
 * Требования на наличие комментария к ответу
 */
export enum AnswerReplyCommentRequirements {
  /**
   * К ответу должен быть оставлен комментарий
   */
  Required = 'Required',
  /**
   * К ответу можно оставить комментарий, а можно не оставлять
   */
  Available = 'Available',
  /**
   * К ответу нельзя оставлять комментарий
   */
  NotAvailable = 'NotAvailable'
}

/**
 * Требования к наличию вложений
 */
export interface IAttachmentRequirements {
  /**
   * Тип вложения
   */
  attachmentType: AttachmentType
  /**
   * Минимально необходимое количество вложений указанного типа
   */
  minOccurs: number
  /**
   * Максимально возможное количество вложений указанного типа
   */
  maxOccurs: number
}

/**
 * Тип вложения
 */
export enum AttachmentType {
  /**
   * Фотография
   */
  Photo = 'Photo',
  /**
   * Видеозапись
   */
  Video = 'Video'
}