import { checkPredicate } from '../../../../../model/script-predicate'
import { ISortingOptions, ITableCell, ITableRowsFilter } from '../../../../../model/table-screen-item'
import { runSyncScript } from '../../../script-tasks/run-sync-script'
import { IScriptTaskContext } from '../../../script-tasks/script-task-context'

type Row = unknown

function applyFilter(rows: Row[], item: FilterableItem, localContext: IScriptTaskContext): Row[] {
  const filter = item.rowsFilter
  if (!filter) return rows
  switch (filter.$type) {
    case 'PMI.FACE.BDDM.Extensions.Classes.PredicateTableRowsFilter':
      switch (filter.predicate.$type) {
        case 'PMI.FACE.BDDM.Extensions.Classes.ScriptPredicate':
        case 'PMI.FACE.BDDM.Extensions.Classes.InlineScriptPredicate':
          return rows.filter((row) =>
            checkPredicate(filter.predicate, row, { ...localContext, [item.elementVariable ?? 'record']: row }),
          )
        case 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate':
          const has = filter.predicate.value
          return rows.filter(() => has)
      }
      break
    default:
      throw new Error('Неподдерживаемый тип фильтра')
  }
}

function applySort(rows: Row[], item: FilterableItem, localContext: IScriptTaskContext): Row[] {
  const sortingOptions = item.sortingOptions
  if (!sortingOptions) return rows

  switch (sortingOptions.$type) {
    case 'PMI.FACE.BDDM.Extensions.Classes.InlineScriptSortingOptions':
      return [...rows].sort((first, second) =>
        runSyncScript(sortingOptions.scriptBody, { ...localContext, first, second }),
      )
    default:
      throw new Error('Неподдерживаемый тип сортировки')
  }
}

interface FilterableItem {
  rowsFilter?: ITableRowsFilter
  sortingOptions?: ISortingOptions
  elementVariable?: string
}

export const filterTableRows = <T extends Row[] | null>(
  rows: T,
  item: FilterableItem,
  localContext: IScriptTaskContext,
): T => {
  if (!rows) return rows

  const filtered = applyFilter(rows, item, localContext)
  const sorted = applySort(filtered, item, localContext)
  return sorted as T
}

export type CssClassCell =
  | 'LabelCell'
  | 'CheckBoxCell'
  | 'SingleSelectCell'
  | 'QuantityInputCell'
  | 'IntegerInputCell'
  | 'TextDialogCell'
  | 'PhotosDialogCell'
  | 'BooleanIndicatorCell'
  | ''

export const getCssClassCell = (cell: ITableCell): CssClassCell => {
  switch (cell.control.$type) {
    case 'PMI.FACE.BDDM.Extensions.Classes.LabelCell': {
      return 'LabelCell'
    }
    case 'PMI.FACE.BDDM.Extensions.Classes.CheckBoxCell': {
      return 'CheckBoxCell'
    }

    case 'PMI.FACE.BDDM.Extensions.Classes.SingleSelectCell': {
      return 'SingleSelectCell'
    }

    case 'PMI.FACE.BDDM.Extensions.Classes.QuantityInputCell': {
      return 'QuantityInputCell'
    }

    case 'PMI.FACE.BDDM.Extensions.Classes.IntegerInputCell': {
      return 'IntegerInputCell'
    }

    case 'PMI.FACE.BDDM.Extensions.Classes.TextDialogCell': {
      return 'TextDialogCell'
    }

    case 'PMI.FACE.BDDM.Extensions.Classes.PhotosDialogCell': {
      return 'PhotosDialogCell'
    }

    case 'PMI.FACE.BDDM.Extensions.Classes.BooleanIndicatorCell': {
      return 'BooleanIndicatorCell'
    }

    default: {
      return ''
    }
  }
}
