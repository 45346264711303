import React, { cloneElement, ReactElement, useContext } from 'react'

import { makeStyles } from '@material-ui/core'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import MenuItemMu from '@material-ui/core/MenuItem'
import classnames from 'classnames'
import { NavLink } from 'react-router-dom'

import { AuthContext } from '../../providers'
import { useSidebarOpen } from '../layout'
import { menuBackgroundColor, menuBackgroundColorActive, menuTextColor, menuTextColorActive } from './menu'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      background: `var(${menuBackgroundColor})`,
      color: `var(${menuTextColor})`,
      '$active &': {
        // background: 'white',
        // color: theme.palette.primary.main,
        background: `var(${menuBackgroundColorActive})`,
        color: `var(${menuTextColorActive})`,
      },
      height: ITEM_HEIGHT_ONLY_ICON,
      borderRadius: 100,
      overflow: 'visible', // for sync change badge
      fontWeight: 600,
      fontSize: 14,
    },
    active: {
      // color: `var(${menuTextColorActive})`,
    },
    icon: {
      minWidth: 0,
      color: 'inherit',
      position: 'relative',
    },
    menuTitle: { marginLeft: theme.spacing(2) },
    onlyIcon: {
      borderRadius: '12px',
      justifyContent: 'center',
      height: theme.spacing(5),
      width: theme.spacing(5),
      margin: theme.spacing(1.0, 'auto'),
    },
    isExit: {
      // color: theme.palette.error.dark,
      // '& .activeIcon': {
      //   color: theme.palette.error.dark
      // }
    },
    noInternetIcon: {
      position: 'absolute',
      width: 16,
      height: 16,
      bottom: -8,
      right: -8,
      opacity: 0.9,
      color: theme.palette.warning.main,
    },
    changedVisitsBadge: {
      '& .MuiBadge-badge': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 16,
        height: 16,
        minWidth: 16,
        fontSize: 11,
        lineHeight: 12,
        backgroundColor: '#FFCC00',
        color: theme.palette.common.black,
        pointerEvents: 'none',
      },
    },
  }),
  { name: 'PmMenuItem' },
)

interface IMenuItem {
  to?: string
  primaryText?: string
  leftIcon?: ReactElement
  onlyIcon?: boolean
  isExit?: boolean
}

const ITEM_HEIGHT_ONLY_ICON = 56
// const NO_INTERNET_MESSAGE = 'Отсутсвует подключение к интернету'

const MenuItem: React.FC<IMenuItem> = ({
  to = 'default_url',
  primaryText,
  leftIcon,
  onlyIcon = false,
  isExit = false,
}) => {
  const authContext = useContext(AuthContext)
  const [, setIsOpen] = useSidebarOpen()
  const classes = useStyles()

  const onItemClick = async (): Promise<void> => (isExit ? void authContext.logout() : setIsOpen(false))

  function renderIcon(): JSX.Element {
    return cloneElement(leftIcon!, {
      titleAccess: primaryText,
    })
  }

  return (
    <NavLink to={to} className={({ isActive }) => (isActive ? classes.active : undefined)}>
      <MenuItemMu
        className={classnames(classes.root, {
          [classes.onlyIcon]: onlyIcon,
          [classes.isExit]: isExit,
        })}
        tabIndex={0}
        onClick={onItemClick}
      >
        {leftIcon && <ListItemIcon className={classnames('activeIcon', classes.icon)}>{renderIcon()}</ListItemIcon>}
        {!onlyIcon && <span className={classes.menuTitle}>{primaryText}</span>}
      </MenuItemMu>
    </NavLink>
  )
}

export default React.memo(MenuItem)
