import React, { useContext, useReducer, useState } from 'react'

import { Box, createStyles, Drawer, Theme, Typography, Link, Button } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useAsync } from 'react-use'

import { Code } from '../../../model/base'
import { ITaskTemplate } from '../../../model/task-template'
import { ApiContext } from '../../../providers'
import { pdfInitialState, pdfReducer, PdfDocContext, PdfDocumentActionKind } from '../../viewers/pdf-reducer'
import PdfViewer, { PdfFile } from '../../viewers/pdf-viewer/pdf-viewer'
import DocumentListDrawerMenu from './document-list-drawer-menu'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    drawerPaper: {
      height: '90%',
      borderTopLeftRadius: theme.spacing(2),
      borderTopRightRadius: theme.spacing(2),
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(6),
      zIndex: 'inherit',
      overflow: 'scroll',
    },
    button: {
      [theme.breakpoints.down('xs')]: {
        minWidth: 30,
      },
    },
    closeButton: {
      color: 'white',
      position: 'fixed',
      bottom: '3%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    description: {
      marginTop: theme.spacing(3),
      whiteSpace: 'pre-wrap',
    },
    steps: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    step: {
      whiteSpace: 'pre-wrap',
    },
    instructionLink: {
      margin: '20px 0 20px 5px',
      marginLeft: '5px',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    instructionWrapper: {
      position: 'fixed',
      zIndex: 10000,
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
    },
    instructionClose: {
      position: 'fixed',
      bottom: 20,
      right: 20,
    },
    buttonNav: {
      height: 40,
      minWidth: 40,
      borderRadius: '50%',
      position: 'absolute',
      top: '50%',
      backgroundColor: 'rgba(0,0,0,0.1)',
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.1)',
      },
    },
    buttonPrev: {
      left: ' 5px',
      transform: 'translate(0, -50%) scaleX(-1)',
    },
    buttonNext: {
      right: ' 5px',
      transform: 'translate(0, -50%)',
    },
  }),
)

interface IVisitTaskDescriptionProps {
  posCode: Code
  taskTemplate: ITaskTemplate
  instruction?: PdfFile
  buttonText: JSX.Element
  description?: string
  title: string
}

const TaskDescriptionDrawerMenu: React.FC<IVisitTaskDescriptionProps> = ({
  taskTemplate,
  posCode,
  buttonText,
  description,
  instruction,
  title,
}): JSX.Element => {
  // const instruction = taskTemplate.helpFileLink?.target
  // const description = taskTemplate.description ?? ''
  const taskTemplateVersionCode = taskTemplate.version.code

  const classes = useStyles()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isInstructionOpen, setIsInstructionOpen] = useState(false)
  const { t } = useTranslation('sales-expert-tasks')
  const [state, dispatch] = useReducer(pdfReducer, pdfInitialState)

  const api = useContext(ApiContext)

  const [documentsList, setDocumentsList] = useState<string[]>([])
  useAsync(async () => {
    const documentsList = await api.document?.getTaskTemplateDocuments({ taskTemplateVersionCode, posCode })
    const ids: string[] = []
    if (documentsList) {
      for (const doc of documentsList) {
        if (doc.status === 'Active') {
          ids.push(doc.content.target)
        }
      }
    }
    setDocumentsList(ids)
  }, [])

  const renderSteps = (): JSX.Element => {
    return (
      <div className={classes.steps}>
        {taskTemplate.stages
          ?.filter((stage) => stage.executionDescription)
          .map((stage) => {
            return (
              <Typography key={stage.code} variant='body1' color={'textSecondary'} className={classes.step}>
                {stage.executionDescription}
              </Typography>
            )
          })}
      </div>
    )
  }

  return (
    <>
      <Button className={classes.button} aria-label='info' color='inherit' onClick={() => setIsMenuOpen(true)}>
        {buttonText}
      </Button>
      <Drawer
        anchor='bottom'
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        PaperProps={{
          className: classes.drawerPaper,
        }}
      >
        <Box minHeight='100%' position='relative' mb={25}>
          <Typography variant='h5' component='h5'>
            {title}
          </Typography>

          {description && (
            <Typography variant='body1' color={'textSecondary'} className={classes.description}>
              {description}
            </Typography>
          )}
          {renderSteps()}
          {instruction ? (
            <Link
              className={classes.instructionLink}
              underline='none'
              onClick={() => {
                setIsInstructionOpen((prevIsInstructionOpen) => !prevIsInstructionOpen)
              }}
            >
              Инструкция
            </Link>
          ) : null}
          {documentsList && <DocumentListDrawerMenu inboxIds={documentsList} title='Документы' />}
          <Fab
            color='primary'
            aria-label='add'
            variant='extended'
            className={classes.closeButton}
            onClick={() => setIsMenuOpen(false)}
          >
            {t('taskDescriptionDrawerClose') as string}
          </Fab>
        </Box>

        {isInstructionOpen ? (
          <div className={classes.instructionWrapper}>
            <PdfDocContext.Provider value={{ dispatch, state }}>
              <PdfViewer pdf={instruction} />

              {state.currentPage > 1 && state.numPages ? (
                <Button
                  className={classnames(classes.buttonPrev, classes.buttonNav)}
                  onClick={() => {
                    dispatch({ type: PdfDocumentActionKind.SetCurrentPage, payload: state.currentPage - 1 })
                  }}
                >
                  <ArrowForwardIosIcon style={{ color: '#ffffff' }} />
                </Button>
              ) : (
                <></>
              )}
              {state.currentPage < state.numPages && state.numPages ? (
                <Button
                  className={classnames(classes.buttonNext, classes.buttonNav)}
                  onClick={() => {
                    dispatch({ type: PdfDocumentActionKind.SetCurrentPage, payload: state.currentPage + 1 })
                  }}
                >
                  <ArrowForwardIosIcon style={{ color: '#ffffff' }} />
                </Button>
              ) : (
                <></>
              )}
            </PdfDocContext.Provider>
            <Fab
              className={classes.instructionClose}
              onClick={() => setIsInstructionOpen(false)}
              color='primary'
              aria-label='add'
              variant='extended'
            >
              Закрыть
            </Fab>
          </div>
        ) : null}
      </Drawer>
    </>
  )
}

export default TaskDescriptionDrawerMenu
