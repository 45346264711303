import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'

import { Box, Dialog, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useCounter } from 'react-use'

import { BlobStorageError } from '../../../infrastructure/blob-storage/blob-storage-api'
import { LogManager } from '../../../infrastructure/logger'
import { IAnswer } from '../../../model/answer'
import { FaceMimeContentRef, IContent } from '../../../model/content'
import { IQuestion } from '../../../model/question'
import { ApiContext } from '../../../providers'
import { appToast } from '../../../utils'
import { DocumentsScreenItemLabel } from '../../documents/components/documents-screen-items/documents-screen-item-label'
import { useOptimizeBlobImage } from '../../documents/hooks'
import PhotoLoad from '../../documents/photo-dialog/photo-load'
import { getErrorToast, ITempFileItem, useFetchFilesByKeys } from '../../documents/utils'
import { DocumentViewer } from '../../viewers/document-viewer/document-viewer'
import { IQuestionRef } from '../numeric-question/numeric-question'
import ReadOnlyText from '../read-only-text'
import { isNullAnswer } from '../utils'
import { PhotoQuestionEditor } from './photo-question-editor'

interface ISurveyQuestionProps {
  disabled?: boolean
  title: string
  description?: React.ReactNode
  answerContent?: IContent
  onPhotoAnswer: (answer: string[]) => void
  onNullAnswer: () => void
  minPhotoCountRequired?: number
  maxPhotoCountAvailable?: number
  errorHelperText?: string
  answer?: IAnswer
  question: IQuestion
}

const logger = LogManager.getLogger('PhotoQuestion')

const emptyArray: never[] = []

export const validatePhotoQuestion = (
  length: number,
  minPhotoCountRequired = 1,
  maxPhotoCountAvailable = 15,
): boolean => {
  return length >= minPhotoCountRequired && length <= maxPhotoCountAvailable
}

const PhotoQuestion: ForwardRefRenderFunction<IQuestionRef, ISurveyQuestionProps> = (
  {
    disabled = false,
    title = '',
    description,
    answerContent,
    onPhotoAnswer,
    onNullAnswer,
    minPhotoCountRequired,
    maxPhotoCountAvailable,
    errorHelperText,
    answer,
    question,
  },
  ref,
) => {
  useImperativeHandle(ref, () => ({
    validate: () => {
      return validatePhotoQuestion(ids.length, minPhotoCountRequired, maxPhotoCountAvailable)
    },
  }))
  const { t } = useTranslation('sales-expert-tasks')

  const api = useContext(ApiContext)
  const optimizeBlobImage = useOptimizeBlobImage()
  const blobStorage = api.blobStorage

  const [updatedItemKey, setUpdatedItemKey] = useState<string | undefined>(undefined)
  const [downloadError, setDownloadError] = useState<BlobStorageError>()
  const [isLoadingToDB, setIsLoadingToDB] = useState<boolean>(false)
  const [checkedList, setCheckedList] = useState<string[]>([])
  const [openedDocument, setOpenedDocument] = useState<ITempFileItem | null>(null)
  const [isSelectMode, setIsSelectMode] = useState<boolean>(false)

  const parts = answerContent?.parts as FaceMimeContentRef[]
  const ids = useMemo(() => (parts ? parts.map((item) => item.code) : emptyArray), [parts])

  useEffect(() => {
    if (disabled) return
    if (ids.length) return
    if (question?.required === false) {
      onNullAnswer()
    }
  }, [ids])

  const { value: files = [] } = useFetchFilesByKeys(ids)
  const [retryCount, { inc: retryInc }] = useCounter(0)

  useEffect(() => {
    if (files.length) {
      const item = files.find((f) => f.metadata.key === (updatedItemKey ?? openedDocument?.metadata.key))
      /*
        после обновления нужно автоматически открыть картинку
      */
      if (item && (updatedItemKey ?? (openedDocument && !files.includes(openedDocument)))) {
        setOpenedDocument(item)
        setUpdatedItemKey(undefined)
      }
    }
  }, [files, updatedItemKey, openedDocument])

  const retry = (): void => {
    setDownloadError(undefined)
    retryInc()
  }

  const onClickItem = (item: ITempFileItem, isChecked: boolean): void => {
    if (!isSelectMode) {
      setOpenedDocument(item)
      return
    }
    if (!isChecked) {
      setCheckedList([...checkedList, item.metadata.key])
    } else {
      setCheckedList(checkedList.filter((id) => id !== item.metadata.key))
    }
  }

  const onLongPressItem = (evt: MouseEvent | TouchEvent, item: ITempFileItem): void => {
    if (disabled) return
    if (!isSelectMode) {
      setIsSelectMode(true)
    }
    if (evt.type === 'touchstart') {
      setCheckedList([...checkedList, item.metadata.key])
    }
  }

  const onChangeInput = async (file: Blob | undefined): Promise<void> => {
    if (file) {
      setIsLoadingToDB(true)
      try {
        const optimizedImage = await optimizeBlobImage(file)
        const metadata = await blobStorage!.add(optimizedImage)
        await onPhotoAnswer([metadata.key, ...ids])
        // setIds((oldIds) => [...oldIds, metadata.key])
      } catch (err) {
        logger.error('onChangeInput', 'Error adding photos', err)
        getErrorToast(err as BlobStorageError)
      }
      setIsLoadingToDB(false)
    }
  }

  const onDeleteHandler = async (): Promise<void> => {
    const key = openedDocument?.metadata.key
    if (!key) return
    const result = ids.filter((id) => id !== key)
    setIsLoadingToDB(true)
    try {
      await onPhotoAnswer(result)
      await blobStorage!.delete(key)
      // setIds(result)
    } catch (err) {
      logger.error('onDeleteHandler', 'Error removed photo', err)
      appToast.error('Произошла ошибка при удалении фотографии')
    }
    setIsLoadingToDB(false)
    setOpenedDocument(null)
  }

  const onUpdateHandler = async (file: Blob): Promise<void> => {
    const oldKey = openedDocument?.metadata.key
    if (!oldKey) return
    const removeIndex = ids.findIndex((id) => id === oldKey)
    setIsLoadingToDB(true)

    try {
      // добавить новый
      const optimizedImage = await optimizeBlobImage(file)
      const { key } = await blobStorage!.add(optimizedImage)
      ids.splice(removeIndex, 1, key)
      await onPhotoAnswer([...ids])
      // сохранить id, для автоматического открытия модалки после обновления
      setUpdatedItemKey(key)
      setOpenedDocument(null)

      // удалить старый
      await blobStorage!.delete(oldKey)
    } catch (err) {
      logger.error('onUpdateHandler', 'Error update photo', err)
      appToast.error('Произошла ошибка при обновлении фотографии')
    }
    setIsLoadingToDB(false)
  }

  const onCancel = (): void => {
    setIsSelectMode(false)
    setCheckedList([])
  }

  const onSelectAll = (): void => {
    setIsSelectMode(true)
    setCheckedList(files.map((file) => file.metadata.key))
  }

  const onDeleteSelected = async (): Promise<void> => {
    const difference: string[] = []
    setIsLoadingToDB(true)
    try {
      for (const id of ids) {
        if (!checkedList.includes(id)) {
          difference.push(id)
        } else {
          await blobStorage!.delete(id)
        }
      }
      await onPhotoAnswer(difference)
    } catch (err) {
      logger.error('onDeleteSelected', 'Error removed selected photos', err)
      appToast.error('Произошла ошибка при удалении выбранных фотографий')
    }
    // setIds(difference)
    setIsSelectMode(false)
    setIsLoadingToDB(false)
  }

  const renderPhotoEditor = (): JSX.Element => {
    return (
      <PhotoQuestionEditor
        key={retryCount}
        isReadOnly={disabled}
        photos={files}
        checkedList={checkedList}
        isSelectMode={isSelectMode}
        onClickItem={onClickItem}
        onLongPressItem={onLongPressItem}
        onChangeInput={onChangeInput}
        onDownloadError={setDownloadError}
      />
    )
  }

  return (
    <>
      {description && (
        <Box letterSpacing='0.25px' mb={2} whiteSpace='pre-wrap'>
          <Typography variant='body2'>{description}</Typography>
        </Box>
      )}
      {isNullAnswer(answer) && disabled ? (
        <ReadOnlyText>{t('questionAnswerNotGiven')}</ReadOnlyText>
      ) : (
        <>
          {!(!files.length || disabled) && (
            <DocumentsScreenItemLabel
              isReadOnly={false}
              isSelectMode={isSelectMode}
              onCancel={onCancel}
              onDeleteSelected={onDeleteSelected}
              onSelectAll={onSelectAll}
              downloadImageError={downloadError}
            />
          )}
          {renderPhotoEditor()}
        </>
      )}
      {openedDocument && (
        <Dialog open={!!openedDocument} fullScreen>
          <DocumentViewer
            title={title}
            isReadOnly={disabled}
            blob={openedDocument.blob}
            idKey={openedDocument.metadata.key}
            onClose={() => {
              retry()
              setOpenedDocument(null)
            }}
            isPrev={files.indexOf(openedDocument) !== 0}
            isNext={files.indexOf(openedDocument) !== files.length - 1}
            onPrevClick={() => {
              setOpenedDocument(files[files.indexOf(openedDocument) - 1])
            }}
            onNextClick={() => {
              setOpenedDocument(files[files.indexOf(openedDocument) + 1])
            }}
            onDelete={onDeleteHandler}
            onUpdate={onUpdateHandler}
          />
        </Dialog>
      )}
      {isLoadingToDB ? <PhotoLoad /> : ''}
    </>
  )
}
export default forwardRef(PhotoQuestion)
