import { ASTPredicate } from '../../../../../model/ast-predicate'
import { IPredicateScreenItem } from './types'

export const isAstPredicateValue = (value: IPredicateScreenItem | null | undefined): value is ASTPredicate => {
  if (value?.$type === 'PMI.FACE.BDDM.Extensions.Classes.PredicatesJunction') return true
  if (value?.$type === 'PMI.FACE.BDDM.Extensions.Classes.BinaryPredicate') return true
  if (value === null) return true
  return false
}

export const checkPredicateItemValue = (value: IPredicateScreenItem | null | undefined): boolean => {
  switch (value?.$type) {
    case 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate':
      return true
    case 'PMI.FACE.BDDM.Extensions.Classes.InlineScriptPredicate':
      return Boolean(value.scriptBody)
    case 'PMI.FACE.BDDM.Extensions.Classes.ScriptPredicate':
      return Boolean(value.script)
    case 'PMI.FACE.BDDM.Extensions.Classes.BinaryPredicate':
    case 'PMI.FACE.BDDM.Extensions.Classes.PredicatesJunction':
    default:
      return Boolean(value)
  }
}