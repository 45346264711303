import { useCallback, useContext } from 'react'

import { LogManager } from '../../../infrastructure/logger'
import { IVisit } from '../../../model/visit'
import { ApiContext } from '../../../providers'
import { appToast } from '../../../utils'

const logger = LogManager.getLogger('useSaveVisitCoordinates')

export function useSaveVisitCoordinates(): (visitCode: IVisit['code'], position: GeolocationPosition) => Promise<void> {
  const api = useContext(ApiContext)

  const saveVisitCoordinates = useCallback(
    async (visitCode: IVisit['code'], position: GeolocationPosition) => {
      const coordinates = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      }
      try {
        const visitWithGps = await api.visits.setVisitCoordinates({
          visitCode,
          coordinates
        })
        console.log('visitWithGps', visitWithGps)
      } catch (e) {
        const message = 'Ошибка при сохранении геокоординат'
        logger.error('setVisitCoordinates', message, e, { visitCode, coordinates })
        appToast.error(message)
      }
    },
    [api.visits]
  )

  return saveVisitCoordinates
}
