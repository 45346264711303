import React, { useEffect, useMemo, useRef, useState } from 'react'

import { makeStyles, createStyles, Box } from '@material-ui/core'
import classNames from 'classnames'
import { findLastIndex, isError } from 'lodash'

import { EmptyMessage } from '../../../components'
import { ValidationFab } from '../../../components/validation-fab'
import { Code } from '../../../model/base'
import { IProcessStep, isTabStep, ITaskExecutionProcess } from '../../../model/task-execution-process'
import { appToast } from '../../../utils'
import { useConfirmationModal } from '../../_common/hooks/useConfirmationModal'
import { useInlineActionButton } from '../../dte/useInlineActionButton'
import { DebugTree } from '../debug-tree'
import { useScriptTaskContext } from '../script-tasks/script-task-context'
import { ScriptView } from '../script-tasks/script-view'
import { TaskStartScreen } from '../script-tasks/task-start-screen'
import { UserStepControl } from '../script-tasks/user-step-control'
import { SwitchStepControl } from '../switch/switch-step-control'
import { ProceedTabs, ProceedTab, TabIcon } from '../template-tasks/proceed-tabs'
import { ScreenRef } from '../template-tasks/types'
import { useLocalContextService } from './local-context'

interface ExecutionProcessControlProps {
  children?: never
  canProcessStart?: () => Promise<string | undefined>
  onProcessStart?: () => Promise<void>
  onBeforeFinish?: () => Promise<string | undefined>
  onProcessFinish?: (link: string | undefined) => Promise<void>
  process: ITaskExecutionProcess
  useFinishConfirmation: boolean
}

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      content: {
        height: '100%',
        marginTop: 84,
        paddingBottom: theme.spacing(12),
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
          marginTop: 77,
          paddingTop: theme.spacing(2),
        },
      },
      hideTabs: {
        marginTop: 0,
      },
    }),

  { name: 'execution-process-control' },
)
const empty: never[] = []

export const ExecutionProcessControl: React.FC<ExecutionProcessControlProps> = (props) => {
  useEffect(() => {
    console.log('ExecutionProcessControl mount', props)
  }, [])
  const { process, canProcessStart, onBeforeFinish, onProcessFinish, onProcessStart, useFinishConfirmation } = props
  const classes = useStyles()
  const currentScreenRef = useRef<ScreenRef>(null)
  const steps = process.steps ?? empty
  const tabs = useMemo(() => steps.filter(isTabStep), [steps])
  const [isReady, setIsReady] = useState<boolean>(false)
  const localContext = useScriptTaskContext()
  const contextService = useLocalContextService()
  const finishConfirmation = useConfirmationModal()
  const inlineActionButton = useInlineActionButton()

  const [stepIndex, setTaskStep] = useState(0)

  const foundStage: IProcessStep | undefined = steps[stepIndex]
  const currentStage = foundStage?.code
  const setCurrentStage = (code: Code): void => {
    const targetIndex = steps.findIndex((step) => step.code === code)
    setTaskStep(targetIndex)
  }

  const { processStartStep, processFinishStep, lastScreenStep } = useMemo(() => {
    const steps = process?.steps ?? empty
    const processStartStep = steps.find((step) => {
      switch (step.$type) {
        case 'PMI.FACE.BDDM.Extensions.Classes.SwitchStep':
        case 'PMI.FACE.BDDM.Extensions.Classes.ScriptStep':
          return true
        case 'PMI.FACE.BDDM.Extensions.Classes.UserStep':
          return !step.availableBeforeTaskStart
        default:
          return false
      }
    })

    const processFinishStep = steps[steps.length - 1]
    const lastScreenStep = steps[findLastIndex(steps, isTabStep)]
    return { processStartStep, processFinishStep, lastScreenStep }
  }, [process.code])

  const goToNext = async (): Promise<void> => {
    const newIndex = steps.indexOf(foundStage!) + 1

    const nextStage = steps[newIndex]
    console.log('goToNext', { nextStage, foundStage, steps })

    if (newIndex) {
      setTaskStep(newIndex)
      // await contextService.updateStackSteps()
    }
  }

  async function handleStart(): Promise<void> {
    if (canProcessStart) {
      const res = await canProcessStart?.()
      if (res) {
        throw new Error(res)
      }
      console.log(`process ${process.code} can start`)
    }
    if (onProcessStart) {
      await onProcessStart()
      await contextService.refetch()
    }
  }

  async function handleFinish(): Promise<void> {
    // const confirmation = await finishTaskRequest()
    // if (!confirmation) return

    const reportLink = await onBeforeFinish?.().catch(() => '')
    await onProcessFinish?.(reportLink ?? '')

    await contextService.refetch()
  }

  const handleAction = async (): Promise<void> => {
    const newIndex = steps.indexOf(foundStage!) + 1
    const nextStage = steps[newIndex]
    console.log('Action', { nextStage, currentStage: foundStage, processStartStep, processFinishStep })
    if (onProcessStart) {
      return void handleStart()
        .then(goToNext)
        .catch((err) => isError(err) && appToast.info(err.message))
    }

    if (useFinishConfirmation && foundStage?.code === lastScreenStep.code) {
      const confirmation = await finishConfirmation()
      if (!confirmation) return
    }

    if (foundStage?.code === processFinishStep.code) {
      return void handleFinish()
    }
    void goToNext()
  }

  const handleScreenAction = (): void => {
    if (!isReady) {
      return currentScreenRef.current?.validate()
    }

    return void handleAction()
  }

  function findLastScreenIndex(): number {
    const currentIndex = steps.indexOf(foundStage!)
    if (currentIndex > 0) {
      const previousStepIndex = findLastIndex(steps.slice(0, currentIndex), isTabStep)
      return previousStepIndex
    }
    return -1
  }

  async function goToLastScreen(): Promise<void> {
    const previousStepIndex = findLastScreenIndex()
    if (previousStepIndex > -1) {
      setTaskStep(previousStepIndex)
      await contextService.updateStackSteps()
    }
  }

  const handleScriptError = (error: Error): void => {
    appToast.error(error.message)
    void goToLastScreen()
  }

  if (!steps.length) {
    return <EmptyMessage message='Не заполнен список шагов' />
  }

  const showTabs = tabs.length > 1

  if (onProcessStart && foundStage?.code === processStartStep?.code) {
    const areStepsAvailable = stepIndex !== 0
    return (
      <div className={classes.content}>
        {showTabs && (
          <ProceedTabs value={areStepsAvailable ? currentStage ?? '' : ''} onChange={setCurrentStage}>
            {tabs.map((stage, i) => {
              return (
                <ProceedTab
                  disabled={!areStepsAvailable || steps.indexOf(stage) > /* taskState.currentStep ?? */ 0}
                  value={stage.code}
                  key={stage.code}
                  icon={<TabIcon>{`${i + 1}`}</TabIcon>}
                  label={stage.displayName}
                />
              )
            })}
          </ProceedTabs>
        )}
        <TaskStartScreen onStart={handleStart} />
      </div>
    )
  }

  if (foundStage?.$type === 'PMI.FACE.BDDM.Extensions.Classes.ScriptStep') {
    return (
      <ScriptView
        localContext={localContext}
        script={(localContext.template ?? localContext.processUnit).scripts[foundStage.script.code]}
        onSuccess={handleAction}
        onError={handleScriptError}
      />
    )
  }

  const hideButtonForDte = inlineActionButton
  const showFinishButton = !!onProcessFinish && !hideButtonForDte
  const showButton = foundStage?.code === processFinishStep.code ? showFinishButton : true

  return (
    <>
      <div className={classNames(classes.content, !showTabs && classes.hideTabs)}>
        {showTabs && (
          <ProceedTabs value={currentStage ?? ''} onChange={setCurrentStage}>
            {tabs.map((stage, i) => {
              return (
                <ProceedTab
                  disabled={steps.indexOf(stage) > /* taskState.currentStep ?? */ 0}
                  value={stage.code}
                  key={stage.code}
                  icon={<TabIcon>{`${i + 1}`}</TabIcon>}
                  label={stage.displayName}
                />
              )
            })}
          </ProceedTabs>
        )}
        {foundStage?.$type === 'PMI.FACE.BDDM.Extensions.Classes.UserStep' && (
          <UserStepControl
            ref={currentScreenRef}
            step={foundStage}
            onReadyChange={setIsReady}
            nextButton={
              showButton ? (
                <ValidationFab useInline={inlineActionButton} onClick={handleScreenAction} isValid={isReady} />
              ) : (
                <Box hidden />
              )
            }
          />
        )}
        {foundStage?.$type === 'PMI.FACE.BDDM.Extensions.Classes.SwitchStep' && (
          <SwitchStepControl
            ref={currentScreenRef}
            step={foundStage}
            onReadyChange={setIsReady}
            onScriptError={handleScriptError}
            nextButton={
              showButton ? (
                <ValidationFab useInline={inlineActionButton} onClick={handleScreenAction} isValid={isReady} />
              ) : (
                <Box hidden />
              )
            }
          />
        )}
      </div>
      <DebugTree />
    </>
  )
}
