import React from 'react'

import { createStyles } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import classnames from 'classnames'

interface ISectionTitleProps {
  title: string
  className?: string
  elevation?: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(3),
      background: 'rgba(0, 0, 0, 0.05)',
      height: theme.spacing(7)
    }
  })
)

const SectionTitle: React.FC<ISectionTitleProps> = ({ title, className, elevation = 1 }) => {
  const classes = useStyles()

  return (
    <Paper elevation={elevation} className={classnames([classes.root, className])} square>
      <Typography variant='h6' component='h6'>
        {title}
      </Typography>
    </Paper>
  )
}

export default SectionTitle
