import React from 'react'

import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import { EmptyMessage } from '../../../../../components'
import { IAuditInquiryAnswer } from '../../../../../model/audit'
// import { getIsaAnswerType } from '../utils/get-isa-answer-type'
import { IsaResultItem, IsaSectionTitle } from './index'

interface IIsaAuditAnswersProps {
  groupName: string
  filterByAnswerType: string | null
  answers: IAuditInquiryAnswer[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:not(:first-child)': {
        marginTop: 8
      }
    },
    answers: {
      padding: theme.spacing(0, 3)
    }
  })
)

const IsaAuditAnswers: React.FC<IIsaAuditAnswersProps> = (props) => {
  const classes = useStyles()

  const { t } = useTranslation('reports')

  const filteredAnswers = props.answers.filter((answer) =>
    props.filterByAnswerType != null ? answer.answerText === props.filterByAnswerType : true
  )

  if (!filteredAnswers.length && props.filterByAnswerType != null) {
    return null
  }

  return (
    <div className={classes.root}>
      <IsaSectionTitle title={props.groupName} />
      <div className={classes.answers}>
        {!filteredAnswers.length ? <EmptyMessage message={t('noData')} marginTop={2} marginBottom={2} /> : null}
        {filteredAnswers.map((answer) => (
          <IsaResultItem
            key={answer.code ?? ''}
            title={answer.questionText ?? ''}
            result={answer.answerText ?? "''"}
            comment={answer.comment ?? undefined}
            hasImage={answer.hasPhoto ?? false}
          />
        ))}
      </div>
    </div>
  )
}

export default IsaAuditAnswers
