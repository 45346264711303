import React, { useContext } from 'react'

import { noop } from 'lodash'

import { ProfileContext } from '../profile'
import { BusinessSettingsContext } from './business-settings-context'

export const BusinessSettingsProvider: React.FC = ({ children }) => {
  const profile = useContext(ProfileContext)

  return (
    <BusinessSettingsContext.Provider value={{ value: profile.businessParameters, refetch: async () => noop() }}>
      {children}
    </BusinessSettingsContext.Provider>
  )
}
