import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react'

import { Box, Typography } from '@material-ui/core'
import { noop } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useMap } from 'react-use'

import { IScreenItem, isScreenItemGroup } from '../../../../model/screen-item'
import { ICompositeUserStepScreen } from '../../../../model/user-step-screen'
import { appToast } from '../../../../utils'
import { useScrollToTop } from '../../../_common/hooks/useScrollToTop'
import { DtePageSkeleton } from '../../../dte/skeleton'
import { useUpdateProperty } from '../../nested/useUpdateProperty'
import { getContextProperty } from '../../script-tasks/propertyName'
import { IScriptTaskContext, useScriptTaskContext } from '../../script-tasks/script-task-context'
import { ScreenRef } from '../types'
import { ScreenItem } from './screen-item'
import { findAllItems, sortScreenItems } from './utils'
import { IValidation, validateCompositeScreen, validateStringItem } from './validate'

interface Props {
  screen: ICompositeUserStepScreen
  onReadyChange: (isReady: boolean) => void
}

function init({
  screen,
  propertiesContext,
}: {
  screen: ICompositeUserStepScreen
  propertiesContext: IScriptTaskContext
}): State {
  const initialMap = Object.fromEntries(
    findAllItems(screen.items).map((item) => {
      const key = item.propertyName ?? ''
      const value = getContextProperty(propertiesContext, key, '')
      return [key, { value, touched: false, dirty: false, validate: validateStringItem(item, value) }]
    }),
  )
  console.log('CompositeScreenStage init', initialMap)
  return initialMap
}

interface State {
  [k: string]: { value: string; touched: boolean; dirty: boolean; validate: IValidation }
}

type Action =
  | { type: 'TouchAll' }
  | { type: 'Blur'; item: IScreenItem }
  | { type: 'Change'; item: IScreenItem; value: string }
  | { type: 'AsyncUpdate'; propertiesContext: IScriptTaskContext }

function reducer(state: State, action: Action): State {
  // console.log(action)
  switch (action.type) {
    case 'TouchAll': {
      const arr = Object.entries(state).map(([key, value]) => [key, { ...value, touched: true }])
      const res = Object.fromEntries(arr)
      // console.log('TouchAll', res)
      return res
    }
    case 'Blur': {
      const key = action.item.propertyName
      return {
        ...state,
        [key]: { ...state[key], touched: true, validate: validateStringItem(action.item, state[key].value) },
      }
    }
    case 'Change': {
      const key = action.item.propertyName
      return {
        ...state,
        [key]: {
          ...state[key],
          value: action.value,
          dirty: true,
          validate: validateStringItem(action.item, action.value),
        },
      }
    }
    case 'AsyncUpdate': {
      const res = { ...state }
      for (const key in res) {
        const form = res[key]
        if (!form.dirty) {
          // carefully mutating local shallow copy
          res[key] = { ...form, value: getContextProperty(action.propertiesContext, key, '') }
        }
      }
      return res
    }
  }
}

export const CompositeScreenStage = forwardRef<ScreenRef, Props>(function CompositeScreenStage(props, ref) {
  const { screen, onReadyChange } = props
  const propertiesContext = useScriptTaskContext()
  const updateProperty = useUpdateProperty()
  const { t } = useTranslation('sales-expert-tasks')
  const customItemsRef = useRef<ScreenRef[]>([])
  const addCustomItem = useCallback((item: ScreenRef) => {
    if (item) {
      console.log('adding custom item', item)
      customItemsRef.current.push(item)
    }
  }, [])

  useScrollToTop()

  const [state, dispatch] = useReducer(reducer, { screen, propertiesContext }, init)
  const [isLoading, setLoading] = useState(false)
  const [validationMap, { set: validationMapSet }] = useMap<Record<string, IValidation>>({})
  const setCustomValidation: ICompositeScreenContext['setCustomValidation'] = (key, validation): void => {
    validationMapSet(key, validation)
  }

  useEffect(() => {
    for (const key in state) {
      const form = state[key]
      const taskValue = getContextProperty(propertiesContext, key, '')
      if (taskValue !== form.value && !form.dirty) {
        return dispatch({ type: 'AsyncUpdate', propertiesContext })
      }
    }
  }, [propertiesContext])

  const handleChange = async (item: IScreenItem, value: string, oldValue: string): Promise<void> => {
    dispatch({ type: 'Change', item, value })

    if (value === '' || oldValue === '') {
      const propertyName = item.propertyName
      await updateProperty(propertyName, value)
    }
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        validate: () => {
          const { invalidItem, message } = validateCompositeScreen(screen, propertiesContext)
          console.log({ invalidItem, message })
          if (message) {
            appToast.info(t(message))
          } else {
            const customMessage = Object.values(validationMap).find((v) => v.message)?.message
            if (customMessage) {
              appToast.info(customMessage)
            }
          }
          dispatch({ type: 'TouchAll' })
          customItemsRef.current.forEach((item) => {
            item.validate()
          })
        },
      }
    },
    [propertiesContext, screen],
  )

  useEffect(() => {
    const outerValidation = !validateCompositeScreen(screen, propertiesContext).invalidItem
    const customValidation = Object.values(validationMap).every((v) => !v.isError)
    onReadyChange(outerValidation && customValidation)
  }, [propertiesContext, validationMap])

  const sorted = useMemo(() => sortScreenItems(screen.items), [screen.items])

  function renderItem(item: IScreenItem): JSX.Element {
    const key = `${item.propertyName} ${item.actionKind} ${item.orderNumber}`
    return (
      <Box key={key} marginX={3} letterSpacing='0.15px'>
        <ScreenItem
          ref={addCustomItem}
          key={key}
          state={state[item.propertyName]}
          item={item}
          onChange={handleChange}
          onBlur={() => {
            dispatch({ type: 'Blur', item })
          }}
        />
      </Box>
    )
  }
  if (isLoading) {
    return <DtePageSkeleton />
  }

  return (
    <CompositeScreenContext.Provider value={{ isLoading, setLoading, setCustomValidation }}>
      <Box>
        {sorted.map((item, i) => {
          if (isScreenItemGroup(item)) {
            const group = item
            return (
              <div key={group.displayName}>
                <Box paddingX={3} paddingTop={1} paddingBottom={2} fontSize={22}>
                  <Typography color='primary' variant='inherit'>
                    {group.displayName}
                  </Typography>
                </Box>
                <Box>{group.items.map(renderItem)}</Box>
              </div>
            )
          } else {
            return renderItem(item)
          }
        })}
      </Box>
    </CompositeScreenContext.Provider>
  )
})

export interface ICompositeScreenContext {
  isLoading: boolean
  setLoading: (val: boolean) => void
  setCustomValidation: (key: string, validation: IValidation) => void
}
export const CompositeScreenContext = React.createContext<ICompositeScreenContext>({
  isLoading: false,
  setLoading: noop,
  setCustomValidation: noop,
})
