import React, { useContext } from 'react'

import { Button } from '@material-ui/core'
import { last } from 'lodash'

import { useExecutionState } from '../nested/execution-state'
import { makeStateKey } from '../nested/local-context'
import { SubProcessContext } from '../nested/nested-context'

// import { useScriptTaskContext } from '../script-tasks/script-task-context'

interface DebugStepsProps {
  children?: never
}

export const DebugSteps: React.FC<DebugStepsProps> = (props) => {
  // const localContext = useScriptTaskContext()
  const { taskState, setSubProcessStep, setTaskStep } = useExecutionState()
  const stack = useContext(SubProcessContext)
  const handleClick = (): void => {
    if (stack.length) {
      const stateKey = makeStateKey(last(stack)!)
      console.log('resetting subprocess step', taskState.subProcesses[stateKey])
      setSubProcessStep(stateKey, 0)
    } else {
      console.log('resetting task step', taskState.currentStep)
      setTaskStep(0)
    }
  }

  return <Button onClick={handleClick}>DebugSteps</Button>
}
