import { IIntegerInputScreenItem, IQuantityInputScreenItem } from '../../../../../model/screen-item'
import { getContextProperty } from '../../../script-tasks/propertyName'
import { IScriptTaskContext } from '../../../script-tasks/script-task-context'

export type IIntegerRecord = Record<string, unknown>

export const getMinValue = (
  item: IQuantityInputScreenItem | IIntegerInputScreenItem,
  propertiesContext: IScriptTaskContext,
): number | undefined => {
  if (item.minValuePropertyName) {
    return getContextProperty(propertiesContext, item.minValuePropertyName, item.minValue)
  }

  return item.minValue
}

export const getMaxValue = (
  item: IQuantityInputScreenItem | IIntegerInputScreenItem,
  propertiesContext: IScriptTaskContext,
): number | undefined => {
  if (item.maxValuePropertyName) {
    return getContextProperty(propertiesContext, item.maxValuePropertyName, item.maxValue)
  }

  return item.maxValue
}