/* eslint-disable @typescript-eslint/no-base-to-string */

import { IHttpClient } from '../../infrastructure/http-client-factory'
import { DataCollectionCode, COLLECTIONS } from '../../model/collections'
import {
  GetListRequest,
  GetListResult,
  IDataCollectionServiceApi,
  UploadFileRequest,
  UploadFileResponse,
} from '../data-collection-service-api'

export class DataCollectionService implements IDataCollectionServiceApi {
  constructor(private readonly _http: IHttpClient) {}

  async getList<C extends DataCollectionCode>(collection: C, request: GetListRequest): Promise<GetListResult<C>> {
    const response = await this._http.request({
      method: 'POST',
      url: `collections/1.0/${collection}/get-list`,
      data: {
        filter: request.filter,
        sortingOptions: request.sortingOptions,
        pagingOptions: request.pagingOptions,
      },
    })
    return response.data
  }

  async update<C extends DataCollectionCode>(collection: C, data: COLLECTIONS[C]): Promise<COLLECTIONS[C]> {
    const response = await this._http.request({
      method: 'POST',
      url: `collections/1.0/${collection}/update`,
      data,
    })
    return response.data
  }

  async getListSqlQuery(collection: string, request: GetListRequest): Promise<ListSqlQuery> {
    const response = await this._http.request({
      method: 'POST',
      url: `collections/1.0/${collection}/get-list/sql-query`,
      data: request,
    })
    return response.data
  }

  async export<C extends DataCollectionCode>(
    collection: C,
    request: GetListRequest,
    fileType: 'xlsx' | 'json',
  ): Promise<Blob> {
    const response = await this._http.request({
      method: 'POST',
      url: `collections/1.0/${collection}/export/${fileType}`,
      data: request,
      responseType: 'blob',
    })

    return response.data
  }

  async uploadFile<C extends DataCollectionCode>(
    collection: C,
    file: File,
    request: UploadFileRequest,
  ): Promise<UploadFileResponse> {
    const searchParams = new URLSearchParams({
      profileCode: request.profileCode,
      $type: request.recordType,
      action: request.action,
    })

    const url = `collections/1.0/${collection}/upload-file?${searchParams.toString()}`

    const response = await this._http.request({
      method: 'POST',
      url,
      data: file,
      headers: {
        'Content-Type': file.type,
      },
    })

    return response.data
  }
}

export interface ListSqlQuery {
  command: string
  args: unknown[]
}
