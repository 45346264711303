import { useContext } from 'react'

import { LogManager } from '../../../infrastructure/logger'
import { Code, SyncErrorCode } from '../../../model/base'
import { SyncContext } from '../../../providers'
import { useFetchPendingItems } from '../../../providers/menu-data/pending-items-store'
import { SyncError } from '../../../services/sync-service-api'
import { appToast } from '../../../utils'

type EntityType = 'visit' | 'task' | 'problem'

const logger = LogManager.getLogger('useTryUpload')

export function useTryUpload(entityType: EntityType): (code: Code | Code[]) => Promise<boolean> {
  const { syncService } = useContext(SyncContext)

  const method = async (code: Code): Promise<boolean> => {
    switch (entityType) {
      case 'visit': {
        return syncService.tryUploadPendingVisit(code)
      }
      case 'task': {
        return syncService.tryUploadPendingTask(code)
      }
      case 'problem': {
        return syncService.tryUploadPendingProblem(code)
      }
    }
  }

  return async (code) => {
    try {
      if (Array.isArray(code)) {
        return await Promise.race(code.map(method))
      }
      return await method(code)
    } catch (error: unknown) {
      if (error instanceof SyncError && error.code === SyncErrorCode.Unauthorized) {
        appToast.info('Время сессии истекло. Необходимо повторно войти в приложение.')
      }
      return false
    }
  }
}

export function useTryUploadAll(): (checkPendingItems?: boolean) => Promise<void> {
  const { syncService } = useContext(SyncContext)
  const fetchPendingItems = useFetchPendingItems()
  return async (checkPendingItems) => {
    try {
      await syncService.sync('SaveOnly')
    } catch (e: unknown) {
      if (e instanceof SyncError) {
        switch (e.code) {
          case SyncErrorCode.Unauthorized:
            appToast.info('Не удалось отправить данные, пользователь не аутентифицирован')
            break
          case SyncErrorCode.Forbidden:
            appToast.info('Не удалось отправить данные, пользователь не включен в необходимые группы безопасности')
            break
          case SyncErrorCode.Maintenance:
            appToast.info('Не удалось отправить данные, ведутся регламентные работы')
            break
          default:
            logger.debug('useTryUploadAll', 'ignored error', e)
        }
      } else {
        logger.error('useTryUploadAll', 'unknown error', e)
      }
    }

    if (checkPendingItems) {
      fetchPendingItems()
    }
  }
}