function parseSlots(templateString: string): string[] {
  return (templateString.match(/({.+?})/g) ?? []).reduce(
    (unique, slot) => (!unique.includes(slot) ? [...unique, slot] : unique),
    [] as string[],
  )
}

function getPropertyName(slot: string): string {
  return slot.slice(1, -1).trim()
}

export function formatTemplateString(templateString: string, getValue: (propertyName: string) => string): string {
  const slots = parseSlots(templateString)
  let result = templateString

  for (const slot of slots) {
    const prop = getPropertyName(slot)
    const value = getValue(prop)
    const regex = new RegExp(slot.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'), 'g')
    result = result.replace(regex, value)
  }
  return result
}
