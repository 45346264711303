import React from 'react'

import DateFnsUtils from '@date-io/date-fns'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ThemeProvider } from '@material-ui/styles'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ruLocale from 'date-fns/locale/ru'
import { ErrorBoundary } from 'react-error-boundary'
import { BrowserRouter as Router } from 'react-router-dom'
import { useAsync } from 'react-use'

import { NewVersionModal } from '../../components'
import { PM_LIGHT_THEME } from '../../layout'
import { MenuContextProvider } from '../../layout/menu/menu-context'
import {
  ApiProvider,
  AuthProvider,
  ConfigProvider,
  BusinessSettingsProvider,
  MaintenanceProvider,
  SyncProvider,
  ProfileProvider,
} from '../../providers'
import { PhotoCounter } from '../../providers/menu-data/photo-counter'
import { ModalProvider } from '../../providers/modal'
import { ServiceWorkerProvider } from '../../providers/service-worker'
import { LanguageReader } from '../../providers/translation/i18n'
// import { RouteList } from '../../routes'
import { DynamicRouteList } from '../../routes/dynamic-route-list'
import { PublicRouteList } from '../../routes/public-route-list'
import { AppToastContainer } from '../../utils'
import 'react-toastify/dist/ReactToastify.css'
import { getGeolocation } from '../../utils/get-geolocation'
import { ErrorPageInternal } from '../error-page'
import { VisitPageUiProvider } from '../visits/visits-page/visits-page-ui-context'
import { UserApp } from './user-app'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
})

export const App: React.FC = () => {
  useAsync(getGeolocation, []) // пустой вызов на получение координат, чтобы пользователь мог дать глобальное разрешение при первом запуске

  return (
    <>
      <ServiceWorkerProvider>
        <Router>
          <AppToastContainer />
          <ThemeProvider theme={PM_LIGHT_THEME}>
            <CssBaseline />
            <ErrorBoundary
              FallbackComponent={ErrorPageInternal}
              onReset={() => document.location.replace(document.location.origin)}
            >
              {/*<GeolocationProvider>*/}
              <MaintenanceProvider>
                <ConfigProvider>
                  <AuthProvider>
                    <PublicRouteList>
                      <UserApp>
                        <ApiProvider>
                          <ProfileProvider>
                            <SyncProvider>
                              <BusinessSettingsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                                  <VisitPageUiProvider>
                                    <ModalProvider>
                                      <MenuContextProvider>
                                        <QueryClientProvider client={queryClient}>
                                          {/* <RouteList /> */}
                                          <DynamicRouteList />
                                        </QueryClientProvider>
                                      </MenuContextProvider>
                                    </ModalProvider>
                                    <LanguageReader />
                                  </VisitPageUiProvider>
                                </MuiPickersUtilsProvider>
                                <PhotoCounter />
                              </BusinessSettingsProvider>
                            </SyncProvider>
                          </ProfileProvider>
                        </ApiProvider>
                      </UserApp>
                    </PublicRouteList>
                  </AuthProvider>
                </ConfigProvider>
              </MaintenanceProvider>
              {/*</GeolocationProvider>*/}
            </ErrorBoundary>
          </ThemeProvider>
        </Router>

        <NewVersionModal />
      </ServiceWorkerProvider>
    </>
  )
}
