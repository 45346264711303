import React, { useMemo } from 'react'

import {
  Box,
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import classnames from 'classnames'

import { grays } from '../../../../../layout/theme'
import { checkPredicate } from '../../../../../model/script-predicate'
import { HorizontalAlignmentType, ITableCell, ITableScreenItem } from '../../../../../model/table-screen-item'
import { useUpdateProperty } from '../../../nested/useUpdateProperty'
import { getContextProperty, getPropertyAny } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { BooleanCell } from './boolean-cell'
import { BooleanIndicatorCell } from './boolean-indicator-cell'
import { CellWrapperProps, ITableRecord } from './editorUtils'
import { IntegerInputCell } from './integer-input-cell'
import { LabelCell } from './label-cell/label-cell'
import { PhotosDialogCell } from './photos-dialog-cell'
import { QuantityInputCell } from './quantity-input-cell'
import { RowMenu } from './row-menu'
import { SingleSelectCell } from './single-select-cell'
import { groupByIndex } from './table-item-portrait'
import { TextDialogCell } from './text-dialog-cell'
import { useGotoSubProcess } from './useGotoSubProcess'
import { filterTableRows } from './utils'

const useStyles = makeStyles((theme) =>
  createStyles({
    tableRoot: {
      '& .MuiTable-root': {
        // tableLayout: 'fixed',
      },
    },
    cell: {
      maxWidth: 0,
      padding: '16px 10px',
      paddingBottom: 4,
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '0.15px',
      // verticalAlign: 'top',
      // height: 57,
      // boxSizing: 'border-box',
      fontWeight: 400,
      verticalAlign: 'top',
      borderBottom: 'none',
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 0,
      },
    },
    cellMenu: {
      maxWidth: 'none',
      width: 50,
    },
    endLine: {
      paddingBottom: 16,
      borderBottom: '1px solid #E0E0E0',
    },
    extendedLines: {
      paddingTop: 0,
    },
    headerCell: {
      verticalAlign: 'top',
      padding: '10px 10px',
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.15px',
      color: grays.gray3,
      // boxSizing: 'border-box',
      fontWeight: 400,
      borderBottom: '2px solid #E0E0E0',
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 0,
      },
    },
    reportCell: {
      padding: '16px 10px',
      color: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inherit',
    },
    tableWithLabelItem: {
      marginTop: theme.spacing(-2),
    },
  }),
)

export function renderCell(
  col: ITableCell,
  row: ITableRecord,
  rowI: number,
  i: number,
  variant: CellWrapperProps['variant'],
  isReport?: boolean,
  isReadOnly?: boolean,
): JSX.Element {
  switch (col.control.$type) {
    case 'PMI.FACE.BDDM.Extensions.Classes.CheckBoxCell':
      return <BooleanCell variant={variant} col={col} row={row} i={rowI} />
    case 'PMI.FACE.BDDM.Extensions.Classes.SingleSelectCell':
      return <SingleSelectCell variant={variant} col={col} row={row} i={rowI} />
    case 'PMI.FACE.BDDM.Extensions.Classes.LabelCell':
      return <LabelCell variant={variant} col={col} row={row} i={rowI} />
    case 'PMI.FACE.BDDM.Extensions.Classes.TextDialogCell':
      return <TextDialogCell variant={variant} col={col} row={row} i={rowI} />
    case 'PMI.FACE.BDDM.Extensions.Classes.PhotosDialogCell':
      return <PhotosDialogCell variant={variant} col={col} row={row} i={rowI} isReadOnly={isReadOnly} />
    case 'PMI.FACE.BDDM.Extensions.Classes.BooleanIndicatorCell':
      return <BooleanIndicatorCell variant={variant} col={col} row={row} i={rowI} isReport={isReport} />
    case 'PMI.FACE.BDDM.Extensions.Classes.QuantityInputCell':
      return <QuantityInputCell variant={variant} col={col} row={row} i={rowI} />
    case 'PMI.FACE.BDDM.Extensions.Classes.IntegerInputCell':
      return <IntegerInputCell variant={variant} col={col} row={row} i={rowI} />
    default: {
      return <Typography>Unknown type cell</Typography>
    }
  }
}

interface Props {
  item: ITableScreenItem
  isReport?: boolean
  isReadOnly: boolean
}

export const TableItemLandscape: React.FC<Props> = ({ item, isReport, isReadOnly }) => {
  if (!(item.tableStyle?.$type === 'PMI.FACE.BDDM.Extensions.Classes.DefaultTableStyle' || !item.tableStyle))
    throw new Error('unreachable')
  const classes = useStyles()
  const propContext = useScriptTaskContext()
  const updateProperty = useUpdateProperty()
  const gotoSubProcess = useGotoSubProcess(item)

  const rows: ITableRecord[] | null = getContextProperty(propContext, item.propertyName)

  const filteredRows = filterTableRows(rows, item, propContext)

  if (!item.cells?.length) {
    throw new Error('Table Item cells is empty')
  }

  const cells = item.cells

  const [tableLine, ...extendedLines] = useMemo(() => {
    return groupByIndex(cells, (cell) => cell.landscapeSettings.lineNumber ?? 0).map((line) =>
      line.sort(
        (cell1, cell2) => (cell1.landscapeSettings.columnNumber ?? 0) - (cell2.landscapeSettings.columnNumber ?? 0),
      ),
    )
  }, [item.cells])

  const headers = useMemo(() => {
    if (tableLine.some((cell) => cell.landscapeSettings.displayName)) {
      return tableLine.map((cell) => {
        return {
          displayName: cell.landscapeSettings.displayName,
          align: cell.landscapeSettings.horizontalAlignment.toLowerCase() as Lowercase<HorizontalAlignmentType>,
          size: cell.landscapeSettings.columnSize,
        }
      })
    }
    return []
  }, [tableLine])

  if (!filteredRows?.length) {
    if (isReport) {
      return (
        <Box marginTop={-3} paddingBottom={0.5}>
          <Typography variant='inherit'>{item.nullValueCaption}</Typography>
        </Box>
      )
    }
    return <Typography>{item.nullValueCaption}</Typography>
  }

  const isSeparator =
    item.tableStyle?.rowStyle?.showSeparatorLine === undefined ? true : item.tableStyle?.rowStyle?.showSeparatorLine

  const withRowMenu = !!item.deleteRecordEnabled || !!item.rowSubprocesses?.length

  return (
    <TableContainer className={classnames({ [classes.tableWithLabelItem]: !!item.displayName }, classes.tableRoot)}>
      <Table>
        {!!headers.length && item.tableStyle?.headerStyle?.showHeaders && (
          <TableHead>
            {headers.map((headerCell, i) => (
              <TableCell
                className={classnames(classes.headerCell, { [classes.reportCell]: isReport })}
                key={(headerCell.displayName ?? '') + String(i)}
                align={headerCell.align}
                width={headerCell.size && `${headerCell.size}%`}
              >
                {headerCell.displayName}
              </TableCell>
            ))}
            {withRowMenu && (
              // add header cell if menu is possible
              <TableCell className={classes.headerCell} />
            )}
          </TableHead>
        )}
        <TableBody>
          {filteredRows.map((row, rowI) => {
            const defaultAction = item.rowSubprocesses?.find((settings) => {
              return checkPredicate(
                settings.defaultProcessCondition ?? {
                  $type: 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate',
                  value: false,
                },
                row,
                propContext,
              )
            })
            const recordCodePropertyKeyName = item.recordKeyPropertyName ?? 'checkNumber'
            const recordCode = getPropertyAny(row, recordCodePropertyKeyName, rowI.toString())
            const foundIndex =
              rows?.findIndex((row) => getPropertyAny<unknown>(row, recordCodePropertyKeyName) === recordCode) ?? -1
            const realIndex = foundIndex > -1 ? foundIndex : undefined
            const onRowClick = (): void => {
              if (!defaultAction) return
              gotoSubProcess(defaultAction.process.code, recordCode)
            }
            return (
              <>
                <TableRow key={recordCode} onClick={onRowClick}>
                  {tableLine.map((col, i) => {
                    const align =
                      col.landscapeSettings.horizontalAlignment.toLowerCase() as Lowercase<HorizontalAlignmentType>
                    const width = col.landscapeSettings.columnSize
                    return (
                      <TableCell
                        className={classnames(classes.cell, {
                          [classes.reportCell]: isReport,
                          [classes.endLine]: !extendedLines.length && isSeparator,
                        })}
                        key={(col.propertyName ?? '') + String(i)}
                        align={align}
                        width={width && `${width}%`}
                      >
                        {renderCell(col, row, realIndex ?? rowI, i, 'table', isReport, isReadOnly)}
                      </TableCell>
                    )
                  })}
                  {withRowMenu && (
                    <TableCell className={classnames(classes.cell, classes.cellMenu)} align='right'>
                      <RowMenu
                        item={item}
                        row={row}
                        onDelete={() => {
                          const keyProp = item.recordKeyPropertyName ?? 'checkNumber'
                          const rowKey = getPropertyAny(row, keyProp)
                          const result = rows?.filter((record) => getPropertyAny(record, keyProp) !== rowKey)
                          console.log({ rows, rowKey, keyProp, result })
                          void updateProperty(item.propertyName, result)
                        }}
                      />
                    </TableCell>
                  )}
                </TableRow>
                {!!extendedLines.length && (
                  <TableRow>
                    <TableCell
                      className={classnames(classes.cell, classes.extendedLines, {
                        [classes.endLine]: isSeparator,
                      })}
                      colSpan={cells.length}
                    >
                      {extendedLines.map((line, lineI) => (
                        <Box display='flex' alignItems='center' key={lineI}>
                          {line.map((col, i) =>
                            renderCell(col, row, realIndex ?? rowI, i, 'extended-row', isReport, isReadOnly),
                          )}
                        </Box>
                      ))}
                    </TableCell>
                  </TableRow>
                )}
              </>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
