import { z } from 'zod'

import { Type, TypeDecl } from './models'

export function createSchemas(types: TypeDecl[]) {
  const result = new Map<TypeDecl['name'], z.Schema>()

  function createSchema(type: Type): z.Schema {
    switch (type.kind) {
      case 'Enum':
        return z.enum(type.values as [string, ...string[]])

      case 'StringLiteral':
        return z.literal(type.value)

      case 'TypeRef': {
        if (result.has(type.name)) {
          return result.get(type.name)!
        }
        switch (type.name) {
          case 'Optional':
            return z.optional(createSchema(type.typeArgs[0]))
          case 'List':
            return z.array(createSchema(type.typeArgs[0]))
          case 'String':
            return z.string()
          case 'Boolean':
            return z.boolean()
          case 'Integer':
            return z.number()
          case 'Float':
            return z.number()
          case 'Decimal':
            return z.number()
          case 'DateTime':
            // return z.number()
            return z.string().datetime({ offset: true })
          case 'Buffer':
            return z.array(z.number())
        }
        throw new Error(`Schema for type '${type.name}' not found`)
      }

      case 'Union':
        return z.union(type.types.map((x) => createSchema(x)) as [z.Schema, z.Schema, ...z.Schema[]])

      case 'Record': {
        const fields = Object.fromEntries(
          type.fields.map((fieldDecl) => [fieldDecl.name, createSchema(fieldDecl.type)]),
        )
        if (type.baseType) {
          const baseSchema = createSchema(type.baseType) as z.ZodLazy<z.SomeZodObject>
          return baseSchema.schema.extend(fields)
        }
        return z.object(fields)
      }
    }
  }

  for (const typeDecl of types) {
    result.set(
      typeDecl.name,
      z.lazy(() => createSchema(typeDecl.type)),
    )
  }

  return result
}
