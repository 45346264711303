import React, { useContext, useEffect, useRef } from 'react'

import { makeStyles, createStyles } from '@material-ui/core'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import { getAppFrameStyle } from '../../../assets/pm-shared-styles'
import { ShareButton } from '../../../components/share-button'
import { PageContent } from '../../../layout/page-content'
import { TitleBar } from '../../../layout/title-bar'
import { Code, CodeSpace } from '../../../model/base'
import { ConfigContext } from '../../../providers'
import ApiContext, { IApiContext } from '../../../providers/api/api-context'
import { useShareReport } from '../../_common/hooks/useShareReport'
import { useFeatureRoute } from '../../custom-app-feature/app-feature-context'
import { ApiProcessContextServiceBase } from '../../process/process-context-service'
import { visitsRoutes } from '../../visits/visits-app-feature/visits-app-feature'
import { LocalContextServiceContext } from '../nested/local-context'
import { ScriptTaskContextProvider, useScriptTaskContext } from '../script-tasks/script-task-context'
import { PreviewControl } from './preview-control'

const useStyles = makeStyles((theme) =>
  createStyles({
    appFrame: getAppFrameStyle(theme),
  }),
)

export interface PreviewPdfNonVisitParams {
  taskCode: Code
  visitCode?: Code
}

interface PreviewPageNonVisitProps {
  children?: never
}

interface PreviewPageLayoutProps {
  children?: never
  onBack: VoidFunction
}

class Service extends ApiProcessContextServiceBase {
  constructor(
    //
    readonly taskCode: Code,
    readonly api: IApiContext,
    protected readonly defaultCodeSpace: CodeSpace,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    super(taskCode, api, async () => ({} as any), defaultCodeSpace)
  }

  updateProperty = async (_propertyName: string, _value: unknown): Promise<void> => {
    console.error('calling update on report preview page, mutation is not allowed here')
  }
}

const PreviewPageLayout: React.FC<PreviewPageLayoutProps> = ({ onBack }) => {
  const { template, task } = useScriptTaskContext()
  const onShareHandler = useShareReport(task, async () => {
    // TODO add refetch task here
  })

  return (
    <>
      <TitleBar onBack={onBack} icons={<ShareButton onShare={onShareHandler} />}>
        {template?.name ?? ''}
      </TitleBar>
      <PageContent>
        <PreviewControl />
      </PageContent>
    </>
  )
}

export const PreviewPage: React.FC<PreviewPageNonVisitProps> = (props) => {
  useEffect(() => {
    console.log('PreviewPagemount', props)
  }, [])
  const { taskCode, visitCode } = useParams<keyof PreviewPdfNonVisitParams>() as PreviewPdfNonVisitParams
  const navigate = useNavigate()
  const classes = useStyles()
  const featureRoute = useFeatureRoute()
  const serviceRef = useRef<Service>()
  const api = useContext(ApiContext)
  const config = useContext(ConfigContext)
  if (!serviceRef.current) {
    serviceRef.current = new Service(taskCode, api, config.config.defaultCodeSpace)
  }

  const onBack = (): void => {
    if (visitCode) {
      const path = generatePath(visitsRoutes.visitTaskPage, { featureRoute, visitCode, taskCode })
      return navigate(path)
    }
    const path = generatePath(visitsRoutes.nonVisitTaskPage, { featureRoute, taskCode })
    navigate(path)
  }

  return (
    <div className={classes.appFrame}>
      <LocalContextServiceContext.Provider value={serviceRef.current}>
        <ScriptTaskContextProvider taskCode={taskCode} visitCode={visitCode}>
          <PreviewPageLayout onBack={onBack} />
        </ScriptTaskContextProvider>
      </LocalContextServiceContext.Provider>
    </div>
  )
}
