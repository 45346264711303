import React from 'react'

import { Box, createStyles, Fab, makeStyles, SvgIcon, Theme } from '@material-ui/core'
import classNames, { Argument as ClassName } from 'classnames'

import { ReactComponent as ActionDisabledIcon } from '../assets/icons/action-disabled.svg'
import { ReactComponent as ActionEnabledIcon } from '../assets/icons/action-enabled.svg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      whiteSpace: 'nowrap',
      position: 'fixed',
      zIndex: 9999,
      bottom: 52,
      right: 55,
      '#root &': {
        [theme.breakpoints.down('xs')]: {
          right: '50%',
          transform: 'translateX(50%)',
        },
      },
    },
    icon: {
      '--background-color': theme.palette.primary.main,
      marginRight: theme.spacing(1),
      marginLeft: -2,
    },
    fixedContainer: {
      position: 'fixed',
      zIndex: 1300,
    },
    inlineContainder: {
      position: 'relative',
      zIndex: 1,
    },
    inlineButton: {
      position: 'absolute',
      bottom: 'unset',
      right: 25,
    },
  }),
)

interface Props {
  isValid: boolean
  onClick: (isValid: boolean) => void
  className?: ClassName
  useInline?: boolean
}
export const ValidationFab: React.FC<Props> = ({
  className,
  children,
  onClick,
  isValid,
  useInline = false,
  ...props
}) => {
  const classes = useStyles()
  return (
    <div className={classNames(useInline ? classes.inlineContainder : classes.fixedContainer)}>
      <Fab
        className={classNames(classes.button, useInline && classes.inlineButton, className)}
        color='primary'
        variant='extended'
        onClick={() => onClick(isValid)}
        {...props}
      >
        <>
          <ValidationFabIcon isValid={isValid} />
          {children}
        </>
      </Fab>
    </div>
  )
}

export const ValidationFabIcon: React.FC<{ isValid: boolean }> = ({ isValid }) => {
  const classes = useStyles()
  return <SvgIcon className={classes.icon} component={isValid ? ActionEnabledIcon : ActionDisabledIcon} />
}

export const AlignedFab: React.FC<{ className?: ClassName; onClick: VoidFunction }> = ({
  children,
  className,
  onClick,
}) => {
  const classes = useStyles()
  return (
    <Box position='fixed' zIndex='1300'>
      <Fab //
        className={classNames(classes.button, className)}
        color='primary'
        variant='extended'
        onClick={onClick}
      >
        <>{children}</>
      </Fab>
    </Box>
  )
}
