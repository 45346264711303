import React, { useContext } from 'react'

import { createStyles } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Palette, PaletteColor } from '@material-ui/core/styles/createPalette'

import { grays } from '../../../../../../layout/theme'
import { useScriptTaskContext } from '../../../../script-tasks/script-task-context'
import { useGetStringValueBase } from '../../get-string-value-base'
import { ITableRecord } from '../editorUtils'
import { TableItemContext } from '../table-item'

type Color = keyof Palette | keyof typeof grays

interface IStyleProp {
  status?: Color
}

const useStyles = makeStyles<Theme, IStyleProp>((theme) => {
  const getColor = (color: Color): string => {
    if (color in grays) {
      return grays[color as keyof typeof grays]
    } else {
      return (theme.palette[color as keyof Palette] as PaletteColor)?.main
    }
  }
  return createStyles({
    statusIndicator: {
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'inline-block',
      verticalAlign: 'middle',
      width: 8,
      height: '100%',
      background: theme.palette.info.main,
      backgroundColor: ({ status }) => getColor(status ?? 'info'),
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      margin: theme.spacing(0, 2, 0, 0),
    },
  })
})

interface ICardMarker {
  record?: ITableRecord
}

export const CardMarker: React.FC<ICardMarker> = ({ record }) => {
  const tableItem = useContext(TableItemContext)
  const propContext = useScriptTaskContext()
  if (tableItem.tableStyle?.$type !== 'PMI.FACE.BDDM.Extensions.Classes.CardsTableStyle') throw new Error('unreachable')

  const markerColorString = useGetStringValueBase(
    propContext,
    record,
    tableItem.tableStyle?.rowStyle?.marker?.color,
    `$type: ${tableItem.$type}, itemPropertyName: ${tableItem.propertyName}, location: item.tableStyle.rowStyle.marker.color`,
  )

  const status = markerColorString

  const classes = useStyles({ status: status as Color })

  return <span className={classes.statusIndicator}></span>
}
