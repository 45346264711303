import React from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core'

import { VisitWithPosIndexed } from '../visits-map-by-day'
import { MapVisitItem } from './map-visit-item'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  }),
)

interface IMapVisitListProps {
  visits: VisitWithPosIndexed[]
  selectVisit: string | undefined
  onSelect: (code: string | undefined) => void
  onClick: (code: string | undefined) => void
  retryVisits?: () => void
}

export const MapVisitList: React.FC<IMapVisitListProps> = ({ visits, selectVisit, onSelect, onClick, retryVisits }) => {
  const classes = useStyles()

  return (
    <div className={classes.list}>
      {visits.map((visit) => {
        return (
          <MapVisitItem
            key={visit.code}
            visit={visit}
            isSelect={visit.code === selectVisit}
            onSelect={onSelect}
            onClick={onClick}
            retryVisits={retryVisits}
          />
        )
      })}
    </div>
  )
}
