import React, { useCallback } from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { getAppFrameStyle } from '../../../../assets/pm-shared-styles'
import { PageContent } from '../../../../layout/page-content'
import { TitleBar } from '../../../../layout/title-bar'
import { SEReportList } from './se-report-list'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appFrame: getAppFrameStyle(theme),
    root: {
      // padding: theme.spacing(4, 3)
    },
  }),
)

const SalesExpertReportPage: React.FC = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation('reports')
  const onBackHandler = useCallback((): void => navigate(-1), [])

  return (
    <div className={classes.appFrame}>
      <TitleBar onBack={onBackHandler}>{t('sales-expert')}</TitleBar>

      <PageContent>
        <div className={classes.root}>{<SEReportList />}</div>
      </PageContent>
    </div>
  )
}

export default SalesExpertReportPage
