import React, { useContext } from 'react'

import { createStyles, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { ApiContext } from '../../../../providers'
import { dateFormat } from '../../../../utils'
import { ErrorPage } from '../../../error-page'
import { ItemCard } from '../../../tasks/template-tasks/composite-screen/item-card'
import { InfoPageWrapper } from '../../components/info-page-wrapper'
import { IStoreMenuLocationState } from '../store-menu-page'
import { getProgramStatus } from './utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'grid',
      gridTemplateColumns: '33fr 67fr',
      columnGap: 10,
      marginBottom: 16,
      '& > *': {
        minWidth: 0,
      },
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
  }),
)

export const ProgramDteInfo: React.FC = () => {
  const classes = useStyles()
  const api = useContext(ApiContext)
  const navigate = useNavigate()
  const location = useLocation()
  const { participantProgramCode } = useParams<{ participantProgramCode: string; posCode: string }>()

  const state = location.state as IStoreMenuLocationState

  const { prevProgramsPath, prevPath } = state

  const onBackHandler = (): void => navigate(prevProgramsPath ?? prevPath, { state })

  const participantProgramOps = useAsync(async () => api.dte.getPOSParticipantProgram(participantProgramCode!))

  if (participantProgramOps.error) {
    return <ErrorPage errorMessage={participantProgramOps.error.message} />
  }

  const program = participantProgramOps.value?.assignedProgram

  if (participantProgramOps.loading || !program) return <></>

  return (
    <InfoPageWrapper title={program.name} onBack={onBackHandler}>
      <ItemCard label={'Информация о программе'}>
        <div className={classes.row}>
          <Typography color='textSecondary'>Наименование</Typography>
          <Typography>{program.name}</Typography>
        </div>
        <div className={classes.row}>
          <Typography color='textSecondary'>Код</Typography>
          <Typography>{program.code}</Typography>
        </div>
        <div className={classes.row}>
          <Typography color='textSecondary'>Тип</Typography>
          <Typography>{program.type}</Typography>
        </div>
        <div className={classes.row}>
          <Typography color='textSecondary'>Стоимость</Typography>
          <Typography>{program.cost}</Typography>
        </div>
        <div className={classes.row}>
          <Typography color='textSecondary'>Дата начала</Typography>
          <Typography>{dateFormat(program.validFrom, 'dd.MM.yyyy')}</Typography>
        </div>
        <div className={classes.row}>
          <Typography color='textSecondary'>Дата окончания</Typography>
          <Typography>{dateFormat(program.validTo, 'dd.MM.yyyy')}</Typography>
        </div>
        <div className={classes.row}>
          <Typography color='textSecondary'>Статус</Typography>
          <Typography>{getProgramStatus(program)}</Typography>
        </div>
      </ItemCard>
    </InfoPageWrapper>
  )
}
