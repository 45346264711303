import React, { useContext } from 'react'

import { Box, createStyles } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { EmptyText } from '../../../../components/empty-text'
import { SelectInput } from '../../../../components/select-input/select-input'
import { ApiContext } from '../../../../providers'
import { ErrorPage } from '../../../error-page'
import SearchInput from '../../../tasks/sales-expert/tabs/session/search-input'
import { IStoreMenuLocationState } from '../store-menu-page'
import { ProgramItem } from './program-item'
import { useProgramsFilters } from './store'
import { getProgramStatus, sortProgramsByStatus, STATUS_LIST_WITH_ORDER } from './utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 40,
    },
    filters: {
      display: 'grid',
      gridTemplateColumns: '2fr 1fr',
      gridGap: 24,
      paddingBottom: 24,
      [theme.breakpoints.down('xs')]: {
        gridGap: 12,
        paddingBottom: 12,
        gridTemplateColumns: '1fr',
      },
    },
    listWrapper: {
      height: 'calc(100vh - 275px)',
      paddingBottom: 24,
      overflow: 'auto',
      [theme.breakpoints.down('xs')]: {
        height: 'calc(100vh - 315px)',
      },
    },
  }),
)

export const ProgramsDte: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const api = useContext(ApiContext)
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state as IStoreMenuLocationState
  const { posCode } = useParams<{ posCode: string }>()

  const { query, status, setQuery, setStatus } = useProgramsFilters((state) => state)

  const participantPrograms = useAsync(async () => api.dte.searchPOSParticipantPrograms({ posCode: posCode! }))

  const onClickHandler = (code: string): void => {
    navigate(`/stores/store/${posCode}/programs-dte/${code}`, {
      state: { ...state, prevProgramsPath: location.pathname },
    })
  }

  const renderEmpty = (text: string): JSX.Element => (
    <div style={{ height: '100%' }}>
      <EmptyText>{text}</EmptyText>
    </div>
  )

  if (participantPrograms.error) {
    return <ErrorPage errorMessage={participantPrograms.error.message} />
  }

  if (participantPrograms.loading) {
    return <Box className={classes.listWrapper}>{renderEmpty('Загрузка')} </Box>
  }

  const programs = participantPrograms.value?.map((participantProgram) => participantProgram)

  if (!programs?.length) {
    return <Box className={classes.listWrapper}> {renderEmpty('Список пуст')} </Box>
  }

  const filteredPrograms = programs
    .filter((program) =>
      `${program.assignedProgram.name}${program.assignedProgram.type}`
        .trim()
        .toLowerCase()
        .includes(query.trim().toLowerCase()),
    )
    .filter((program) => (!status || status === 'Все' ? true : getProgramStatus(program.assignedProgram) === status))
    .sort((a, b) => a.assignedProgram.name.localeCompare(b.assignedProgram.name))
    .sort((a, b) => sortProgramsByStatus(getProgramStatus(a.assignedProgram), getProgramStatus(b.assignedProgram)))

  return (
    <>
      <div className={classes.root}>
        <Box className={classes.filters}>
          <Box>
            <SearchInput
              onChange={(value) => {
                setQuery(value)
              }}
              query={query ?? ''}
              placeholder={t('search')}
            />
          </Box>
          <Box>
            <SelectInput
              query={status}
              list={STATUS_LIST_WITH_ORDER}
              placeholder={'Выберите статус'}
              onChange={(value) => {
                setStatus(value)
              }}
            />
          </Box>
        </Box>
        <Box className={classes.listWrapper}>
          {filteredPrograms?.length
            ? filteredPrograms.map((program) => (
                <ProgramItem
                  key={program.code}
                  participantProgram={program}
                  onClick={() => onClickHandler(program.code)}
                />
              ))
            : renderEmpty('Поиск не дал результатов')}
        </Box>
      </div>
    </>
  )
}
