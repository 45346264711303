import React, { useState } from 'react'

import { Button, createStyles, Link, makeStyles, Theme, Typography } from '@material-ui/core'
import classnames from 'classnames'

import { ISignatureImageScreenItem } from '../../../../../model/screen-item'
import { Signature } from '../../../../signature'
import { useBatchUpdate } from '../../../nested/useBatchUpdate'
import { useUpdateProperty } from '../../../nested/useUpdateProperty'
import { getContextProperty } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginBottom: theme.spacing(1),
      minHeight: theme.spacing(7),
    },
    wrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      fontSize: 16,
      minHeight: theme.spacing(7),
      // fontWeight: 700
    },
    name: {
      fontSize: 20,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    noImageText: {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: '0.1px',
    },
    link: {
      paddingLeft: 0,
      marginBottom: -4,
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: '0.1px',
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
    },
    imageSignature: {
      height: 56,
      width: 128,
      objectFit: 'contain',
    },
    signature: {
      display: 'flex',
      marginTop: 5,
    },
    isRejection: {
      alignItems: 'baseline',
    },
  }),
)

// type Value = string | undefined
interface Props {
  item: ISignatureImageScreenItem
  // onChange?: (value: Value) => void
  isReadOnly?: boolean
}

export const SignatureImageReportItem: React.FC<Props> = ({ item, isReadOnly }) => {
  const classes = useStyles()
  const propertiesContext = useScriptTaskContext()
  const updateProperty = useUpdateProperty()
  const batchUpdate = useBatchUpdate()

  const [isOpen, setIsOpen] = useState(false)

  const image = getContextProperty(propertiesContext, item.propertyName, '')

  const isRejection =
    item.signatureRejectionPropertyName !== undefined
      ? (getContextProperty(propertiesContext, item.signatureRejectionPropertyName, false) as boolean)
      : undefined

  console.log('SignatureImageReportItem value', image)
  if (isReadOnly && item.viewSettings?.hideEmpty && !image && !isRejection) {
    return <></>
  }

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.name}>
        <strong>{item.displayName}</strong>
      </Typography>
      <div className={classnames(classes.signature, { [classes.isRejection]: isRejection })}>
        {image && !isRejection ? <img className={classes.imageSignature} src={image} alt='' /> : ''}
        {isRejection ? (
          <span style={{ display: 'inline-block', marginRight: 5 }}>От подписи отказался (лась)</span>
        ) : (
          ''
        )}
        {isReadOnly && !image && !isRejection ? (
          <span className={classes.noImageText}>{item.viewSettings?.nullValueCaption ?? ''}</span>
        ) : (
          ''
        )}
        {!isReadOnly && (
          <Button
            className={classes.link}
            component={Link}
            onClick={() => {
              setIsOpen(true)
            }}
          >
            {image || isRejection ? 'Изменить' : 'Поставить подпись'}
          </Button>
        )}
      </div>
      <Signature
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
        onChange={(value) => {
          if (item.actionKind !== 'Edit') return
          if ('image' in value) {
            if (item.signatureRejectionPropertyName) {
              void batchUpdate({
                [item.propertyName]: value.image,
                [item.signatureRejectionPropertyName]: false,
              })
            } else {
              void updateProperty(item.propertyName, value.image)
            }
          } else if ('rejection' in value) {
            if (item.signatureRejectionPropertyName) {
              void batchUpdate({
                [item.propertyName]: '',
                [item.signatureRejectionPropertyName]: value.rejection,
              })
            }
          }
        }}
        isRejection={isRejection}
      />
    </div>
  )
}
