import React, { useContext } from 'react'

import { createStyles, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { ApiContext } from '../../../../providers'
import { ErrorPage } from '../../../error-page'
import { ItemCard } from '../../../tasks/template-tasks/composite-screen/item-card'
import { getRegisteredNameFromPos } from '../utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 44,
      paddingBottom: theme.spacing(12),
      [theme.breakpoints.down('xs')]: {
        paddingTop: 40,
      },
    },
    row: {
      display: 'grid',
      gridTemplateColumns: '33fr 67fr',
      columnGap: 10,
      marginBottom: 16,
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    titleCol: {},
    infoCol: {},
  }),
)

export const Information: React.FC = () => {
  const classes = useStyles()

  const api = useContext(ApiContext)
  const { posCode } = useParams<{ posCode: string }>()

  const posOps = useAsync(async () => {
    const pos = await api.pos.getPos(posCode!)
    const checkoutCount = await api.pos.getCheckoutCount(posCode!, new Date())

    const modelStoreLevel = await api.pos.getModelStoreLevel(posCode!, new Date())

    return {
      pos,
      checkoutCount,
      modelStoreLevel,
    }
  }, [posCode])

  if (posOps.error) {
    return <ErrorPage errorMessage={posOps.error.message} />
  }

  if (posOps.loading && !posOps.value?.pos) return <></>
  const { pos, modelStoreLevel, checkoutCount } = posOps.value!

  return (
    <div className={classes.root}>
      <ItemCard label={'Общие сведения'}>
        <div className={classes.row}>
          <Typography className={classes.titleCol} color='textSecondary'>
            Название
          </Typography>
          <Typography className={classes.infoCol}>{pos?.name ?? '-'}</Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.titleCol} color='textSecondary'>
            Код торговой точки
          </Typography>
          <Typography className={classes.infoCol}>{pos?.code ?? '-'}</Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.titleCol} color='textSecondary'>
            Адрес
          </Typography>
          <Typography className={classes.infoCol}>
            {pos?.address ? `${pos.address?.city} ${pos.address?.fullAddress}` : '-'}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.titleCol} color='textSecondary'>
            Юридическое лицо
          </Typography>
          <Typography className={classes.infoCol}>{(pos && getRegisteredNameFromPos(pos)) ?? '-'}</Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.titleCol} color='textSecondary'>
            Статус
          </Typography>
          <Typography className={classes.infoCol}>{pos?.status ?? '-'}</Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.titleCol} color='textSecondary'>
            Количество касс
          </Typography>
          <Typography className={classes.infoCol}>{checkoutCount ?? '-'}</Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.titleCol} color='textSecondary'>
            Сегмент
          </Typography>
          <Typography className={classes.infoCol}>{pos?.posSegmentation ?? '-'}</Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.titleCol} color='textSecondary'>
            Уровень Model Store
          </Typography>
          <Typography className={classes.infoCol}>{modelStoreLevel ?? '-'}</Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.titleCol} color='textSecondary'>
            Категория ТТ
          </Typography>
          <Typography className={classes.infoCol}>{'-'}</Typography>
          {/* TODO: Дописать */}
        </div>
        <div className={classes.row}>
          <Typography className={classes.titleCol} color='textSecondary'>
            Ассортимент
          </Typography>
          <Typography className={classes.infoCol}>
            {pos?.assortmentRange?.length ? pos.assortmentRange.map((item) => item.name).join(',') : '-'}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.titleCol} color='textSecondary'>
            Ниша
          </Typography>
          <Typography className={classes.infoCol}>{pos?.businessNiche ?? '-'}</Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.titleCol} color='textSecondary'>
            Окружение
          </Typography>
          <Typography className={classes.infoCol}>{pos?.operationalEnviroment ?? '-'}</Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.titleCol} color='textSecondary'>
            Формат
          </Typography>
          <Typography className={classes.infoCol}>{pos?.format ?? '-'}</Typography>
        </div>
      </ItemCard>
    </div>
  )
}
