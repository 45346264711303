import React from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      width: '100%',
      marginBottom: 8,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 12,
      },
    },
  }),
)

export const ItemCompact: React.FC = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.root}>{children}</div>
}
