import { DependencyList, useContext } from 'react'

import { useAsyncRetry } from 'react-use'
import { AsyncState } from 'react-use/lib/useAsyncFn'
import { AsyncStateRetry } from 'react-use/lib/useAsyncRetry'

import { LogManager } from '../../../infrastructure/logger'
import { IPointOfSale } from '../../../model/pos'
import { IVisit } from '../../../model/visit'
import { ApiContext } from '../../../providers'
import { SearchVisitsRequest } from '../../../services/visit-service-api'

export type VisitWithPos = IVisit & { pointOfSale: IPointOfSale }

interface VisitWithPosFlag {
  withPos: true
}

interface VisitWithoutPosFlag {
  withPos: false
}

interface IVisitSearchParams {
  periodStartDate?: number
  periodEndDate?: number
}

/**
 * Загружать или нет связанную с визитом точку продаж
 */
interface IVisitSearchOptions {
  withPos?: boolean
}

const logger = LogManager.getLogger('useSearchVisits')

export function useSearchVisits(
  searchParams: IVisitSearchParams,
  options: VisitWithPosFlag,
  deps?: DependencyList,
): AsyncStateRetry<VisitWithPos[]>

export function useSearchVisits(
  searchParams: IVisitSearchParams,
  options: VisitWithoutPosFlag,
  deps?: DependencyList,
): AsyncState<IVisit[]>

/**
 * Загружает все визиты которые соответсвуют переданным параметрам фильтрации
 */
export function useSearchVisits(
  searchParams: IVisitSearchParams,
  options: IVisitSearchOptions,
  deps: DependencyList = [],
): AsyncStateRetry<IVisit[] | VisitWithPos[]> {
  const api = useContext(ApiContext)

  const { periodStartDate, periodEndDate } = searchParams

  return useAsyncRetry(async () => {
    const profile = await api.userProfile.getCurrentProfile()
    try {
      const foundVisits = await api.visits.searchVisits({
        plannedDate: buildFilterByPlannedDates(periodStartDate, periodEndDate),
        // executedByUserCode: auth.currentUser.employee.userCode,  // FACE-2085 Change visits load logic - UI
        executivePositionCode: profile?.fieldPositionRole?.code,
      })

      if (options.withPos) {
        const posList = await api.pos.searchPos()
        return foundVisits.map((v) => ({
          ...v,
          pointOfSale: posList.find((pos) => pos.code === v.pointOfSaleCode),
        }))
      }

      return foundVisits
    } catch (error) {
      logger.error('searchVisits', 'Error during fetching visit list', error)
      throw error
    }
  }, [periodStartDate, periodEndDate, ...deps])
}

function buildFilterByPlannedDates(
  periodStartDate?: number,
  periodEndDate?: number,
): SearchVisitsRequest['plannedDate'] {
  if (!periodStartDate || !periodEndDate) {
    return undefined
  }
  return {
    from: periodStartDate,
    to: periodEndDate,
  }
}
