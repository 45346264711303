import React from 'react'

import { Box, createStyles, Grid, Theme, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import isToday from 'date-fns/isToday'
import { useTranslation } from "react-i18next";

import { getPosAddress } from '../features/_common/pos'
import { getRegisteredNameFromPos } from '../features/stores/store-menu-page/utils'
import { IPointOfSale } from '../model/pos'
import { IVisit } from '../model/visit'
import { dateFormat } from '../utils'

interface IVisitViewProps {
  visit?: IVisit
  pos: IPointOfSale | null
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    code: {
      color: '#858F97',
      letterSpacing: '0.25px',
      fontSize: 14,
      lineHeight: '20px',
    },
    date: {
      paddingRight: `${theme.spacing(1)}px !important`,
      textAlign: 'end',
      // [theme.breakpoints.up('md')]: {
      //   textAlign: 'end'
      // }
    },
    address: {
      paddingBottom: 4,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.1px',
    },
  }),
)

const TitleView: React.FC<IVisitViewProps> = ({ visit, pos }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  // const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'))

  const getDate = (): string => {
    if (!visit?.plannedStartDate) {
      return ''
    }
    const visitDate = new Date(visit.plannedStartDate)
    return isToday(visitDate) ? t('today') : dateFormat(visitDate, 'dd.MM.yyyy')
  }

  const registeredName = pos && getRegisteredNameFromPos(pos)

  return (
    <Grid item xs={12}>
      <Box display='flex' flexDirection='column'>
        <Tooltip title={pos?.address?.fullAddress ?? ''}>
          <Typography noWrap className={classes.address}>
            {getPosAddress(pos)}
          </Typography>
        </Tooltip>
        <Box display='flex' alignItems='center' justifyContent={'space-between'}>
          <Typography className={classes.code} noWrap>
            {pos?.code}
            {registeredName && `\u00A0\u00A0|\u00A0\u00A0${registeredName}`}
            {visit && `\u00A0\u00A0|\u00A0\u00A0${getDate()}`}
          </Typography>
        </Box>
      </Box>
    </Grid>
  )
}
export default React.memo(TitleView)
