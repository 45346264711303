import { useRef } from 'react'

import { endOfDay, subMonths, subWeeks } from 'date-fns'

import { IAnswer } from '../../../model/answer'
import { DateTime } from '../../../model/common'
import { IQuestion } from '../../../model/question'
import { IQuestionnaire } from '../../../model/questionnaire'
import { ISurveysPropertyScreenItem } from '../../../model/screen-item'

interface Period {
  from: DateTime
  to: DateTime
}

export type SessionViewMode = 'last-week' | 'last-month' | 'all'
export function viewModeToPeriod(viewMode: SessionViewMode): Period {
  const today = endOfDay(Date.now())
  switch (viewMode) {
    case 'last-week': {
      return {
        from: subWeeks(today, 1).valueOf(),
        to: today.valueOf()
      }
    }
    case 'last-month': {
      return {
        from: subMonths(today, 1).valueOf(),
        to: today.valueOf()
      }
    }
    case 'all': {
      return {
        from: subMonths(today, 2).valueOf(),
        to: today.valueOf()
      }
    }
  }
}

export function useLoadingSetRef<T>(): { get: () => Set<T>; add: (code: T) => void; remove: (code: T) => void } {
  const loadingRef = useRef<Set<T>>()
  function getLoading(): Set<T> {
    if (!loadingRef.current) {
      loadingRef.current = new Set()
    }
    return loadingRef.current
  }
  function addLoading(code: T): void {
    getLoading().add(code)
  }
  function removeLoading(code: T): void {
    setTimeout(() => {
      getLoading().delete(code)
    }, 0)
  }

  return { get: getLoading, add: addLoading, remove: removeLoading }
}

/** sort in place */
export function sortAnswers(questionnaire: IQuestionnaire, answers: IAnswer[]): IAnswer[] {
  function findQuestion(a: IAnswer): IQuestion {
    return questionnaire.questions.find((question) => question.code === a.questionCode)!
  }
  function getGroupOrder(q: IQuestion): number {
    return questionnaire.questionGroups?.find((group) => group.code === q.questionGroupCode)?.orderNumber ?? 0
  }

  return answers.sort((a1, a2) => {
    const q1 = findQuestion(a1)
    const q2 = findQuestion(a2)
    const groupOrder = getGroupOrder(q1) - getGroupOrder(q2)
    return groupOrder || q1.orderNumber - q2.orderNumber
  })
}

export function makeSurveyName(item: ISurveysPropertyScreenItem, i: number, length: number): string {
  if (length > 1) {
    return `${item.surveyDisplayNamePrefix ?? item.displayName + ' '}${i + 1}`
  }
  return item.displayName
}
