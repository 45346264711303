import React, { useEffect, useRef, useState } from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  createStyles,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  SvgIcon,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import classnames from 'classnames'
import { set as setFp } from 'lodash/fp'
import { nanoid } from 'nanoid'
import { useTranslation } from 'react-i18next'
import create from 'zustand'

import { ReactComponent as ExclamationMarkIcon } from '../../../assets/icons/exclamation-mark.svg'
import { ReactComponent as PencilIcon } from '../../../assets/icons/pencil.svg'
import { IAnswer } from '../../../model/answer'
import { Code } from '../../../model/base'
import { IQuestion, TextQuestionShowMode } from '../../../model/question'
import { QuestionModal } from '../../tasks/sales-expert/tabs-new/trainee-skills/question-modal'
import { TableSelect } from '../../tasks/template-tasks/composite-screen/table-item/single-select-cell'
import { useThrottledFn } from '../../tasks/template-tasks/composite-screen/useThrottledFn'
import { findPhotos } from '../answer-requirements-utils'
import ReadOnlyText from '../read-only-text'
import { isNullAnswer } from '../utils'
import TextQuestionDescription from './text-question-description'

const PROFILE_COLOR = '#00A3E0'
const BUTTON_BORDER_RADIUS = 28
const BUTTON_WIDTH = 112
const DELAY_TO_AUTOSAVE = 500

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    description: {
      whiteSpace: 'pre-wrap',
      lineHeight: '1.2em',
    },
    actions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    profileButton: {
      borderColor: PROFILE_COLOR,
      color: PROFILE_COLOR,
      borderRadius: BUTTON_BORDER_RADIUS,
      width: BUTTON_WIDTH,
      '&:first-child': {
        marginRight: theme.spacing(2),
      },
    },
    answerWrapper: {
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',

      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 1,
      wordBreak: 'break-all',
      flex: 1,
    },
    modalContent: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
    },
    modalDescription: {
      paddingBottom: theme.spacing(2),
    },
    modalTitle: {
      fontSize: 22,
      lineHeight: '28px',
    },
    summaryRoot: {
      height: 'unset !important',
      alignItems: 'flex-start',
      '& .MuiAccordionSummary-expandIcon': {
        marginTop: -8,
        alignSelf: 'flex-start',
      },
      '& .MuiAccordionSummary-content': {
        alignSelf: 'flex-start',
      },
    },
    textFieldWrap: {
      minHeight: 200,
      height: '100%',
    },
    textField: {
      flex: '1 0 1px',
      '& .MuiInputBase-root': {
        height: '100%',
      },
      '& .MuiInputBase-input': {
        height: '100% !important',
        overflowY: 'auto !important',
      },
    },
    infoIcon: {
      marginRight: theme.spacing(2),
      objectFit: 'contain',
    },
    select: {
      margin: theme.spacing(0, 0, 1),
      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
      maxWidth: '100%',
    },
    menuItem: {
      paddingTop: 12,
      paddingBottom: 12,
      whiteSpace: 'unset',
    },
    menuItemOther: {
      fontStyle: 'italic',
    },
  }),
)

interface ISurveyQuestionProps {
  questionCode: Code
  disabled: boolean
  title: string
  description?: React.ReactNode
  answerEntryValue: string | undefined
  elevation?: number
  className?: string
  onAnswer: (answer: string) => void
  titleButtons?: React.ReactNode
  titleClassName?: string
  onRemove?: () => void
  showMode?: TextQuestionShowMode
  dataList?: string[]
  allowOtherOption?: boolean
  placeholder?: string
  onNullAnswer: () => void
  answer?: IAnswer
  question: IQuestion
  allowMultipleSelection?: boolean
}

let oldAnswer: string | undefined

interface ITextState {
  store: Record<Code, string | undefined>
  setValue: (code: Code, value: string | undefined) => void
}

export const useTextState = create<ITextState>((setState) => ({
  store: {},
  setValue: (code, value) => setState(setFp(['store', code], value)),
}))

const TextQuestionBody: React.FC<ISurveyQuestionProps> = ({
  questionCode,
  disabled,
  title = '',
  description,
  answerEntryValue,
  onAnswer,
  onRemove,
  showMode,
  dataList,
  allowOtherOption,
  placeholder,
  onNullAnswer,
  answer,
  question,
  allowMultipleSelection,
}) => {
  const fieldRef = useRef<HTMLTextAreaElement>(null)

  const { t } = useTranslation('sales-expert-tasks')

  const hasAnswer = answerEntryValue != null
  const classes = useStyles()

  const [showModal, setShowModal] = useState(false)

  const answerField = useTextState((state) => state.store[questionCode])
  const setAnswerField = useTextState((state) => state.setValue)

  const [isOtherSelected, setIsOtherSelected] = useState(false)

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const isOtherValue = allowOtherOption && answerField != null && (!dataList?.includes(answerField) || isOtherSelected)

  useEffect(() => {
    if (disabled) return
    const photoCount = findPhotos(question.code, answer ? [answer] : undefined)?.length ?? 0
    if (question?.required === false) {
      if (!!answerEntryValue || isNullAnswer(answer)) {
        return
      }

      if (photoCount) {
        return
      }
      onNullAnswer()
    }
  }, [answerEntryValue, answer, disabled])

  useEffect(() => {
    setAnswerField(questionCode, answerEntryValue)
  }, [questionCode])

  const answerValue = answerField?.trim()

  const canBeSavedDialog =
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    (answerValue && answerValue !== answerEntryValue) || (answerValue && oldAnswer && answerValue !== oldAnswer.trim())

  const throttleSetValue = useThrottledFn(
    (value) => {
      if (showMode === 'Dialog') {
        if (canBeSavedDialog) {
          onAnswer(value)
        }
        return
      }

      onAnswer(value)
    },
    DELAY_TO_AUTOSAVE,
    { trailing: true },
  )

  const setDisabled = (): boolean => {
    if (oldAnswer === undefined && answerEntryValue === '') {
      return !(answerValue && answerValue !== answerEntryValue)
    } else {
      return !!(!answerValue || (oldAnswer && answerValue === oldAnswer.trim()))
    }
  }

  const answerHandler = (): void => {
    onAnswer(answerField!)
    oldAnswer = answerField
    setShowModal(false)
  }

  useEffect(() => {
    if (showModal) {
      oldAnswer = answerEntryValue
    }
  }, [showModal])

  const openModal = (): void => {
    setShowModal(true)
    setTimeout(() => {
      const el = fieldRef.current
      if (!el) return
      el.focus()
      el.setSelectionRange(el.value.length, el.value.length)
    }, 200)
  }

  const renderAnswerButtons = (): JSX.Element | null => {
    if (!hasAnswer) {
      return (
        <div className={classes.actions}>
          <Button
            className={classes.profileButton}
            size='large'
            variant='outlined'
            onClick={() => {
              openModal()
              setAnswerField(questionCode, '')
            }}
          >
            Ответить
          </Button>
        </div>
      )
    }
    return null
  }

  const renderDialogMode = (): JSX.Element => {
    return (
      <>
        {!!description && <TextQuestionDescription description={description} />}
        {renderAnswerButtons()}

        {hasAnswer && (
          <Box mt={3}>
            {!disabled && (
              <Grid container alignItems='center' wrap='nowrap'>
                <div className={classes.answerWrapper}>{answerEntryValue}</div>
                <IconButton
                  edge='end'
                  className={classes.button}
                  color='primary'
                  aria-label='change-answer'
                  onClick={openModal}
                >
                  <SvgIcon component={PencilIcon} />
                </IconButton>
              </Grid>
            )}

            {disabled && <ReadOnlyText>{answerEntryValue}</ReadOnlyText>}
          </Box>
        )}
        <QuestionModal
          isOkDisabled={setDisabled()}
          okButtonText='Сохранить'
          closeButtonText={disabled ? undefined : 'Отменить'}
          onOk={disabled ? undefined : answerHandler}
          contentClassName={classes.textFieldWrap}
          title={
            description ? (
              <Accordion elevation={0}>
                <AccordionSummary className={classes.summaryRoot} expandIcon={<ExpandMoreIcon />}>
                  <Box pt={0.5} mr={2}>
                    <SvgIcon className={classes.iconCollapse} color='primary' component={ExclamationMarkIcon} />
                  </Box>
                  <div className={classes.modalTitle}>{title}</div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.description}>{description}</Typography>
                </AccordionDetails>
              </Accordion>
            ) : (
              <div className={classes.modalTitle}>{title}</div>
            )
          }
          open={showModal}
          onClose={() => {
            setShowModal(false)
            if (oldAnswer !== undefined) {
              setAnswerField(questionCode, oldAnswer)
              onAnswer(oldAnswer)
            } else {
              setAnswerField(questionCode, '')
              if (onRemove && answerEntryValue) {
                onRemove()
                oldAnswer = undefined
              }
            }
          }}
        >
          <div className={classes.modalContent}>
            {disabled ? (
              answerEntryValue?.split('\n').map((line) => <div key={line}>{line}</div>)
            ) : (
              <TextField
                inputRef={fieldRef}
                className={classes.textField}
                variant='outlined'
                fullWidth
                multiline
                value={answerField}
                onChange={(ev) => {
                  setAnswerField(questionCode, ev.target.value)
                }}
              />
            )}
          </div>
        </QuestionModal>
      </>
    )
  }

  const renderInlineMode = (): JSX.Element => {
    return (
      <>
        {!!description && <TextQuestionDescription description={description} />}
        {!disabled && !!dataList?.length && !allowMultipleSelection && (
          <Select
            value={isOtherValue ? '%other' : answerField ?? null}
            // value={'%other'}
            className={classes.select}
            displayEmpty
            input={<TableSelect color='primary' />}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            renderValue={(value) => {
              if (isOtherValue) {
                return 'Иное значение'
              }

              if (value) {
                return value as string
              }

              return 'Выбрать'
            }}
            onChange={(evt) => {
              let value = evt.target.value as string
              if (value === '%other') {
                value = ''
                setIsOtherSelected(true)
              } else {
                setIsOtherSelected(false)
              }

              onAnswer(value)
              setAnswerField(questionCode, value)
            }}
          >
            {dataList?.map((value) => (
              <MenuItem className={classes.menuItem} key={nanoid()} value={value}>
                {value}
              </MenuItem>
            ))}
            {allowOtherOption && (
              <MenuItem className={classnames(classes.menuItem, classes.menuItemOther)} value={'%other'}>
                Иное значение
              </MenuItem>
            )}
          </Select>
        )}

        {!disabled && !!dataList?.length && allowMultipleSelection && (
          <Select
            multiple
            value={answerField ? answerField.split(';') : []}
            className={classes.select}
            displayEmpty
            input={<TableSelect color='primary' />}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            renderValue={(value) => {
              const arr = value as string[]
              if (arr.length) {
                return arr.join(';') as string
              }

              return 'Выбрать'
            }}
            onChange={(evt) => {
              const value = (evt.target.value as string[]).join(';')

              onAnswer(value)
              setAnswerField(questionCode, value)
            }}
          >
            {dataList?.map((value) => (
              <MenuItem className={classes.menuItem} key={nanoid()} value={value}>
                <Checkbox color='primary' checked={answerField?.includes(value)} />
                {value}
              </MenuItem>
            ))}
          </Select>
        )}

        {!disabled && (!dataList?.length || isOtherValue) && (
          <OutlinedInput
            value={answerField ?? ''}
            onChange={(evt) => {
              setIsOtherSelected(true)
              throttleSetValue(evt.target.value)
              setAnswerField(questionCode, evt.target.value)
            }}
            onBlur={() => {
              throttleSetValue.flush()
            }}
            fullWidth
            multiline
            id='answer'
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            placeholder={placeholder || 'Ввод текста'}
          />
        )}

        {disabled && <ReadOnlyText>{answerEntryValue}</ReadOnlyText>}
      </>
    )
  }

  if (isNullAnswer(answer) && disabled) {
    return (
      <>
        {!!description && <TextQuestionDescription description={description} />}
        <ReadOnlyText>{t('questionAnswerNotGiven')}</ReadOnlyText>
      </>
    )
  }

  switch (showMode) {
    case 'Dialog': {
      return renderDialogMode()
    }
    case 'Inline':
    default: {
      return renderInlineMode()
    }
  }
}

export default TextQuestionBody
