import { IApiContext } from '../../providers/api/api-context'
import { isAxiosError } from '../../utils/is-axios-error'

export interface IWebMethodRequest {
  path: string
  httpMethod: 'GET' | 'POST' | 'PUT'
  body: unknown
  headers?: Record<string, string | number | boolean>
}

export type IWebMethodResult =
  | {
      httpStatusCode: number
      body: unknown
    }
  | {
      httpStatusCode: number | undefined
      message: string | undefined
    }

export const executeWebMethod = async (
  api: IApiContext,
  { path, httpMethod, body, headers }: IWebMethodRequest,
): Promise<IWebMethodResult> => {
  const client = api._httpClientFactory.getHttpClient()
  try {
    const res = await client({ method: httpMethod, url: path, data: body, headers: headers })
    return {
      httpStatusCode: res.status as 200,
      body: res.data,
    }
  } catch (err) {
    if (isAxiosError(err)) {
      return {
        httpStatusCode: err.response?.status,
        message: err.message,
        body: err.response?.data,
      }
    } else {
      console.log('executeWebMethod unknown error', err)
      return {
        httpStatusCode: undefined,
        message: 'unknown error',
      }
    }
  }
}
