import { DateTime } from "../../model/common";
import { BusinessError } from '../../model/errors'
import { IAwaitable } from '../../utils/awaitable'
import { IEvent } from '../../utils/event'
import { IMediaReceiveSetting } from "../../utils/media";

export type BlobKey = string
export interface BlobReceive {
  key: BlobKey,
  deleteAfter?: DateTime
}

export enum BlobSyncStatus {
  None = 'None',
  Pending = 'Pending',
  Sent = 'Sent',
  Received = 'Received'
}

export interface BlobMetadata extends Record<string, unknown>{
  // Ключ
  key: BlobKey
  // Время добавления в хранилище
  timestamp: number
  // Размер в байтах
  size: number
  // MIME-nbg содержимого
  mimeType: string
  // Тип содержимого
  kind?: string
  // Предпросмотр
  thumbnail?: string
  // Реальное время создания
  dateTime?: number
  // Время успешной синхронизации
  syncTime?: number
  // Статус синхронизации
  syncStatus?: BlobSyncStatus
  // Время когда сборщику необходимо удалить медиа
  deleteAfter?: DateTime
}

export interface BlobStorageSyncStatus {
  receivingCount: number
  sendingCount: number
}

export enum BlobStorageErrorCode  {
  Unknown = 'Unknown',
  OutOfSpace = 'OutOfSpace',
  StorageRestricted = 'StorageRestricted',
  Unauthorized = 'Unauthorized',
  Offline = 'Offline',
  NotFound = 'NotFound',
  BadRequest = 'BadRequest'
}

export class BlobStorageError extends BusinessError<BlobStorageErrorCode> {
  name = 'BlobStorageError'
}

export interface IBlobStorage {
  /**
   * Событие "Блоб загружен (downloaded)"
   */
  readonly onBlobReceived: IEvent<BlobKey>

  /**
   * Событие "Блоб отправлен (uploaded)"
   */
  readonly onBlobSent: IEvent<BlobKey>

  /**
   * Событие "Блобы поставлены в очередь на отправку"
   */
  readonly onSending: IEvent<BlobKey[]>

  /**
   * Добавляет блоб (и метаданные) в хранилище
   */
  add: (blob: Blob, customMetadata?: Record<string, unknown>) => Promise<BlobMetadata>

  /**
   * Возвращает блоб из хранилища по ключу
   * если параметр receiveIfNotFound = true и блоб не найден в хранилище, пытается немедленно скачать блоб
   */
  getBlob: (key: BlobKey, receiveIfNotFound?: boolean) => Promise<Blob | undefined>

  /**
   * Возвращает метаданные блоба из хранилища по ключу
   * если параметр receiveIfNotFound = true и метаданные не найдены в хранилище, пытается немедленно скачать блоб
   */
  getMetadata: (key: BlobKey, receiveIfNotFound?: boolean) => Promise<BlobMetadata | undefined>

  /**
   * Удаляет блоб (и метаданные) из хранилища
   * Возвращает true, если блоб был удален, иначе - false
   */
  delete: (key: BlobKey) => Promise<boolean>

  /**
   * Возвращает количество блобов в хранилище
   */
  count: () => Promise<number>

  /**
   * Возвращает общий размер в байтах, занимаемый всеми блобами
   */
  totalSize: () => Promise<number>

  /**
   * Планирует приоритетную загрузку блобов с указанными ключами
   * @param keyTuples Кортежи или коллекции (ключ + настройки) для загрузки
   * @param priority Приоритет загрузки
   * @param forced Форсировать загрузку
   * @returns Awaitable объект c результатами отдельных загрузок
   */
  receive: (keyTuples: Array<[string, IMediaReceiveSetting]> | Map<string, IMediaReceiveSetting>, priority?: number, forced?: boolean) => Promise<IAwaitable<Array<PromiseSettledResult<unknown>>>>

  /**
   * Планирует отправку блобов с указанными ключами
   * @param keys
   */
  send: (keys: BlobKey[] | Set<BlobKey>) => Promise<void>

  /**
   * Возвращает текущий статус процесса синхронизации
   * @param keys
   */
  readonly syncStatus: BlobStorageSyncStatus
}