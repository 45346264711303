import React from 'react'

import { ISyncService } from '../../services/sync-service-api'

export interface ISyncContext {
  syncService: ISyncService
}

const SyncContext = React.createContext<ISyncContext>({} as ISyncContext)
export default SyncContext
