const CHARS = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890#$%&@*"

export function unscramble(str?: string): string | undefined {
  if (!str) {
    return str
  }
  const k = CHARS.indexOf(str[0]) + 1
  let result = ''
  for (let i = 1; i < str.length; i++) {
    if (i % k !== 0) {
      result += str[i];
    }
  }
  return result;
}