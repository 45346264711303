import React from 'react'

import { createStyles, IconButton, makeStyles, SvgIcon, Theme } from '@material-ui/core'
import classNames, { Argument as ClassName } from 'classnames'

import { ReactComponent as ExpandLeft } from '../assets/icons/Expand_left.svg'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      backButton: {
        marginRight: theme.spacing(3),
        width: 36,
        height: 36,
        padding: 0
      },
      icon: {
        width: '100%',
        height: '100%'
      }
    }),
  { name: 'BackButton' }
)
interface Props {
  onBack: VoidFunction
  className?: ClassName
}

export const BackButton: React.FC<Props> = ({ onBack, className }) => {
  const classes = useStyles()
  return (
    <IconButton className={classNames(classes.backButton, className)} color='inherit' onClick={onBack}>
      <SvgIcon className={classes.icon} component={ExpandLeft} viewBox='0 0 36 36' />
    </IconButton>
  )
}
