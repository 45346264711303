import { useContext, useEffect, useMemo } from 'react'

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Fade,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@material-ui/core'
import { Check, DonutLarge } from '@material-ui/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { LogManager } from '../../../../infrastructure/logger'
import { Layout } from '../../../../layout'
import { PageContent } from '../../../../layout/page-content'
import { TitleBar } from '../../../../layout/title-bar'
import { Code } from '../../../../model/base'
import { BusinessError } from '../../../../model/errors'
import { IDataObjectOperation } from '../../../../model/user-profile'
import { ProfileContext } from '../../../../providers'
import { useService } from '../../../../providers/service'
import { DATA_OBJECTS_SERVICE_API } from '../../../../services-admin/data-objects-service-api'
import {
  DATA_COLLECTIONS_API,
  UploadFileAction,
  UploadFileProblemDetails,
} from '../../../../services/data-collection-service-api'
import { parseRequestError } from '../../../../utils'
import { ItemCard } from '../../../tasks/template-tasks/composite-screen/item-card'
import { actionOptions } from '../../data-manager/modals/import-modal/action-options'
import { useHasPermissions } from '../../data-manager/utils/permissions'

interface DbUploadForm {
  file: File
  dataObjectCode: Code
  recordType: string
  action: UploadFileAction
}

const logger = LogManager.getLogger('DataLoader.DbUploader')

export function DbUploadPage() {
  const navigate = useNavigate()

  const theme = useTheme()

  const dataObjectsApi = useService(DATA_OBJECTS_SERVICE_API)
  const dataCollectionsApi = useService(DATA_COLLECTIONS_API)

  const hasPermission = useHasPermissions()

  const { data: dataObjects } = useQuery({
    queryKey: ['data-objects'],
    queryFn: async () => dataObjectsApi.getDataObjects(),
  })

  const {
    value: { profile },
  } = useContext(ProfileContext)

  const form = useForm<DbUploadForm>({
    defaultValues: {
      file: null!,
      dataObjectCode: '',
      recordType: '',
      action: '' as UploadFileAction,
    },
  })

  useEffect(() => {
    uploadFile.reset()
  }, [form.watch('file')])

  useEffect(() => {
    form.setValue('recordType', supportedTypes.at(0)?.bddmType ?? '')
    form.setValue('action', availableActions.at(0)?.value ?? ('' as UploadFileAction))
  }, [form.watch('dataObjectCode')])

  const availableDataObjects = useMemo(() => {
    return dataObjects?.filter((dataObject) =>
      hasPermission(dataObject, {
        anyOf: [IDataObjectOperation.Insert, IDataObjectOperation.Update, IDataObjectOperation.Delete],
      }),
    )
  }, [dataObjects])

  const selectedDataObjectCode = form.watch('dataObjectCode')
  const selectedDataObject = useMemo(() => {
    return dataObjects?.find((x) => x.code === selectedDataObjectCode)
  }, [selectedDataObjectCode])

  const supportedTypes = selectedDataObject?.supportedTypes ?? []

  const availableActions = useMemo(() => {
    return actionOptions.filter((action) =>
      hasPermission(selectedDataObject, {
        allOf: action.operations,
      }),
    )
  }, [selectedDataObject])

  const uploadFile = useMutation({
    mutationFn: async (data: DbUploadForm) => {
      try {
        return await dataCollectionsApi.uploadFile(data.dataObjectCode, data.file, {
          profileCode: profile.code,
          recordType: data.recordType,
          action: data.action,
        })
      } catch (error) {
        throw parseRequestError(error)
      }
    },
    onError(error) {
      logger.error('uploadFile', 'Произошла ошибка при импорте', error, (error as { details?: unknown }).details)
    },
  })

  return (
    <Layout>
      <TitleBar onBack={() => navigate('/data-loader', { replace: true })}>DB upload</TitleBar>

      <PageContent>
        <Container maxWidth='sm'>
          <Box height={24}></Box>

          <form onSubmit={form.handleSubmit((data) => uploadFile.mutate(data))}>
            <Controller
              control={form.control}
              name='file'
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <ItemCard label='Файл' isError={!!fieldState.error}>
                  <Box display='flex' alignItems='center' gridGap={theme.spacing(1)}>
                    <Box display='none' clone>
                      <input
                        accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        id={field.name}
                        type='file'
                        onChange={(ev) => {
                          field.onChange(ev.target.files?.item(0))
                          field.onBlur()
                        }}
                      />
                    </Box>
                    <label htmlFor={field.name}>
                      <Button variant='contained' color='primary' component='span'>
                        Выбрать
                      </Button>
                    </label>
                    <Typography>{field.value?.name}</Typography>
                  </Box>
                </ItemCard>
              )}
            />

            <Controller
              control={form.control}
              name='dataObjectCode'
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <ItemCard label='Коллекция' isError={!!fieldState.error}>
                  <Select
                    variant='outlined'
                    fullWidth
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                  >
                    {availableDataObjects?.map((dataObject) => (
                      <MenuItem key={dataObject.code} value={dataObject.code}>
                        <div>
                          <Typography>{dataObject.name}</Typography>
                          <Typography variant='body2' color='textSecondary'>
                            {dataObject.code}
                          </Typography>
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </ItemCard>
              )}
            />

            <Controller
              control={form.control}
              name='recordType'
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <ItemCard label='Тип' isError={!!fieldState.error}>
                  {supportedTypes.length > 0 ? (
                    <Select
                      variant='outlined'
                      fullWidth
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      disabled={!selectedDataObject}
                    >
                      {supportedTypes.map((type) => (
                        <MenuItem key={type.bddmType} value={type.bddmType}>
                          <div>
                            <Typography>{type.displayName ?? type.bddmType}</Typography>
                            <Typography variant='body2' color='textSecondary'>
                              {type.bddmType}
                            </Typography>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <Typography>Нет доступных типов</Typography>
                  )}
                </ItemCard>
              )}
            />

            <Controller
              control={form.control}
              name='action'
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <ItemCard label='Действие' isError={!!fieldState.error}>
                  <Select
                    variant='outlined'
                    fullWidth
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    disabled={!selectedDataObject}
                  >
                    {availableActions.map((action) => (
                      <MenuItem key={action.value} value={action.value}>
                        {action.title}
                      </MenuItem>
                    ))}
                  </Select>
                </ItemCard>
              )}
            />

            <Box my={3}>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                disabled={uploadFile.isLoading}
                startIcon={
                  form.formState.isValid ? <Check /> : <DonutLarge style={{ stroke: theme.palette.primary.main }} />
                }
              >
                Импорт
              </Button>
            </Box>

            {uploadFile.isSuccess && (
              <Box>
                <Typography variant='h4'>Импорт выполнен успешно</Typography>
                <Typography>{uploadFile.data.message}</Typography>
              </Box>
            )}

            {uploadFile.isError && <UploadFileErrorDisplay error={uploadFile.error as Error} />}

            <Fade in={uploadFile.isLoading}>
              <Box display='flex' gridGap={theme.spacing(1)}>
                <CircularProgress size='1.5em' />
                <Typography>Импорт файла</Typography>
              </Box>
            </Fade>
          </form>
        </Container>
      </PageContent>
    </Layout>
  )
}

function UploadFileErrorDisplay(props: { error: Error }) {
  if (props.error instanceof BusinessError) {
    const details = props.error.details as UploadFileProblemDetails

    return (
      <Box>
        <Typography variant='h4' color='error'>
          Произошла ошибка при импорте
        </Typography>
        <Typography>{props.error.message}</Typography>
        <Typography>{details.detail}</Typography>
        {details.position && <Typography>pos: {Object.keys(details.position).join(', ')}</Typography>}
      </Box>
    )
  } else {
    return (
      <Box>
        <Typography variant='h4' color='error'>
          Произошла ошибка при импорте
        </Typography>
        <Typography>{props.error.message}</Typography>
      </Box>
    )
  }
}
