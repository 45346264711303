import CryptoJS from 'crypto-js'
import { JSEncrypt } from 'jsencrypt'

// TODO: Уточнить надо ли выносить в конфиг CLIENT_ID и PRIVATE_KEY
const CLIENT_ID = 448
const PRIVATE_KEY =
  '-----BEGIN RSA PRIVATE KEY-----' +
  'MIIBOwIBAAJBAJyDUZIZoZdn5GgR2orm7QXSyNDzlYcbgqnEIjMDoclsbj82kKqO' +
  'LP7Ex9p74NzWj/ixCsOZ7YYUb/ljt20evhsCAwEAAQJBAJm4BDKZR/M7j8KC3oEU' +
  'I3FXv/IxewfShjeUsf4msAzyD9LclFcSx1CvXhvDMyhtncLcaoegwevGtQC4f6Gg' +
  'C2ECIQDNqArDXw5pdfA+N0U6M5pd9rxkr8TGQSNuHLO1+EDZawIhAMLTlUOtbdVE' +
  'VxoNiuJiU5/N2o2FqKR3GnBGc3ak3moRAiBUd1E8Xv1HWVY75216cxlSwHUuEXcL' +
  'SlOn9QfmlDjmUwIhAMLGpUOz70p3uFzjjSYnB1OMiiNSrPc/277DsEu9KF8BAiAK' +
  'Wqo6CY1qPd2oeSJIujv2jx6yjIhw2vsGhjfxnVnuCQ==' +
  '-----END RSA PRIVATE KEY-----'

// https://yandex.ru/dev/yandex-apps-launch/navigator/doc/concepts/navigator-commercial-use-signature.html - инструкция по хешированию
export const getURLWithSign = (url: string): string => {
  const urlWithClientId = `${url}&client=${CLIENT_ID}`
  const encrypt = new JSEncrypt()
  encrypt.setKey(PRIVATE_KEY)
  const encrypted = encrypt.sign(urlWithClientId, CryptoJS.SHA256 as unknown as (str: string) => string, 'sha256')
  const decodedSign = encodeURIComponent(encrypted)
  return `${urlWithClientId}&signature=${decodedSign}`
}
