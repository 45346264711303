import React, { useContext } from 'react'

import { Box, Button, Container, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { useNavigate, useLocation, Navigate, Link } from 'react-router-dom'
import { useMountedState, useNetworkState } from 'react-use'

import { AuthContext } from '.'
import { LogManager } from '../../infrastructure/logger'
import { LockedPage } from '../../layout/locked-page'
import { IUserContextErrorLocationState } from './types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 'calc(100% + 140px)',
      position: 'relative',
      padding: theme.spacing(2),
      backgroundImage: 'url("/pmi-logo-sync.png")',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },
    errorMsg: {
      lineHeight: '24px',
      textAlign: 'center',
      fontSize: 24,
      fontStyle: 'normal',
      letterSpacing: '0.25px',
      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
        lineHeight: '18px',
      },
    },
    errorIcon: {
      verticalAlign: 'middle',
      marginRight: 8,
      color: theme.palette.error.dark,
    },
    title: {
      lineHeight: '36px',
      textAlign: 'center',
      fontSize: 34,
      fontStyle: 'normal',
      fontWeight: 300,
      [theme.breakpoints.down('xs')]: {
        fontSize: 24,
        lineHeight: '26px',
      },
    },
    submit: {
      height: 48,
      width: 260,
      borderRadius: 28,
    },
  }),
)

const logger = LogManager.getLogger('UserContextErrorPage')

export const UserContextErrorPage: React.FC = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { authService } = useContext(AuthContext)
  const location = useLocation() as { state: IUserContextErrorLocationState }

  const isMounted = useMountedState()
  const { online } = useNetworkState()

  const logout = async (): Promise<void> => {
    try {
      await authService.logout()
      if (isMounted()) {
        navigate('/')
      }
    } catch (e) {
      logger.error('logout', 'Ошибка выхода', e)
    }
  }
  if (!location.state) {
    return <Navigate to='/' />
  }
  const { userName, title, message, version, doNotLogoff = false } = location.state
  return (
    <LockedPage>
      <Box display='flex' alignItems='center' justifyContent='center' height='100%'>
        <Container disableGutters fixed component='main' maxWidth='md'>
          {userName && (
            <Box mb={5}>
              <Typography className={classes.title}>
                Пользователь: <b>{userName ?? ''}</b>
              </Typography>
            </Box>
          )}
          <Typography className={classes.errorMsg} align='center'>
            <span className={classes.errorIcon}>
              <InfoOutlinedIcon />
            </span>
            {title}
          </Typography>
          <Box mt={3}></Box>
          <Typography align='center'>{message}</Typography>
          <Box mt={4}>
            <Typography align='center' color='textSecondary'>
              Версия приложения: {version || '0.1.17'}
            </Typography>
          </Box>
          <Box mt={5} textAlign='center'>
            {doNotLogoff ? (
              <Button className={classes.submit} color='primary' variant='contained' component={Link} to='/'>
                На главную
              </Button>
            ) : (
              <>
                <Button
                  disabled={!online}
                  className={classes.submit}
                  color='primary'
                  variant='contained'
                  onClick={logout}
                >
                  Сменить пользователя
                </Button>
                {!online && (
                  <Box mt={2}>
                    <Typography align='center'>
                      Для смены пользователя необходимо подключение к сети Интернет
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Container>
      </Box>
    </LockedPage>
  )
}
