const MimeTypeExtensions: Record<string, string> = {
  'image/jpeg': 'jpg',
  'image/jpg': 'jpg',
  'image/png': 'png',
  'image/gif': 'gif',
  'video/mp4': 'mp4',
  'application/pdf': 'pdf'
}

export function getMimeTypeByExtension(ext: string): string {
  ext = ext.toLowerCase()
  for (const [key, value] of Object.entries(MimeTypeExtensions)) {
    if (value === ext) {
      return key
    }
  }
  return ''
}

export default MimeTypeExtensions

