const TIMEOUT = 10000

const ErrorTextViaCode = new Map([
  [1, 'Разрешите определение координат в настройках'],
  [2, 'При попытке получения координат произошла ошибка'],
  [3, 'Не удалось определить координаты за отведенное время'],
])

export const getGeolocation = async (options?: PositionOptions): Promise<GeolocationPosition> => {
  const defaultOptions = {
    enableHighAccuracy: true,
    timeout: TIMEOUT,
    maximumAge: 0,
  }

  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      const message = ErrorTextViaCode.get(1)
      reject(new Error(message))
    }, TIMEOUT + 1000)

    navigator.geolocation.getCurrentPosition(
      (pos) => {
        clearTimeout(timer)
        resolve(pos)
      },
      (err) => {
        clearTimeout(timer)
        reject(new Error(ErrorTextViaCode.get(err.code)))
      },
      options ?? defaultOptions,
    )
  })
}
