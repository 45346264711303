import React, { useContext } from 'react'

import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useAsync } from 'react-use'

import { IDictionaryBase } from '../../../../../model/dictionary'
import { IDictionaryItem } from '../../../../../model/dictionary-item'
import { IDictionaryItemPropertyScreenItem } from '../../../../../model/screen-item'
import { ApiContext } from '../../../../../providers'
import { FormSelectResult } from '../../../sales-expert/tabs-new/components/form-select-result'
import { ItemCard } from '../item-card'

type Value = IDictionaryItem | IDictionaryItem[] | undefined
interface Props {
  item: IDictionaryItemPropertyScreenItem
  onChange?: (value: Value) => void
  onBlur?: () => void
  entry: Value
  touched?: boolean
}

export const DictionaryItem: React.FC<Props> = ({ item, onChange, onBlur, entry, touched }) => {
  const api = useContext(ApiContext)
  const { t } = useTranslation('sales-expert-tasks')

  const value = entry instanceof Array ? entry[0] : entry

  const dictionaryVersion = item.dictionary.version!.code

  interface IDictionaryWithItems extends IDictionaryBase {
    items: IDictionaryItem[]
  }

  const dictionaryOps = useAsync(async () => {
    if (dictionaryVersion) {
      return await api.dictionary.getDictionaryByVersionCode(dictionaryVersion)
    }
  }, [dictionaryVersion])

  if (dictionaryOps.loading) return <></>

  const dictionaryItems = dictionaryOps.value?.items

  // dictionaryOps.value && console.log(dictionaryOps.value, item, value)
  const readOnly = item.actionKind !== 'Edit'
  const isArrayValue = item.editSettings?.isArrayValue
  const isFilled = !!value?.code || value === null
  const isRequired = item.editSettings?.required
  // console.log(value, readOnly, 'isFilled')

  if (readOnly && item.viewSettings.hideEmpty) {
    return <></>
  }

  return (
    <ItemCard
      isError={!readOnly && !isFilled && isRequired}
      label={<Typography variant='inherit'>{item.displayName}</Typography>}
    >
      <FormSelectResult
        id={item.propertyName}
        onBlur={onBlur}
        disabled={readOnly}
        value={value === null ? null : value?.code ?? ''}
        onChange={(ev) => {
          if (ev.target.value === null) {
            const value = isArrayValue ? [null as unknown as IDictionaryItem] : (null as unknown as IDictionaryItem)
            onChange?.(value)
            return
          }

          const targetDictionary = (dictionaryOps.value as IDictionaryWithItems)?.items?.find(
            (el) => el.code === ev.target.value,
          )

          const value = isArrayValue && targetDictionary ? [targetDictionary] : targetDictionary
          onChange?.(value)
        }}
        variant='outlined'
        placeholder='Ожидаемое поведение относительно поставленной цели'
        dictionaries={dictionaryItems}
        helperText={item.editSettings?.errorHelperText ?? t('requiredFieldError')}
        error={touched && !isFilled && isRequired}
        showListButtonCaption={item.editSettings?.showListButtonCaption}
        isNullable={item.editSettings?.nullable}
        nullValueCaption={readOnly ? item.viewSettings?.nullValueCaption : item.editSettings?.nullValueCaption}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      />
    </ItemCard>
  )
}
