import React from 'react'

import { Box, Typography, useTheme } from '@material-ui/core'

import { ReactComponent as ExclamationWarn } from '../../../../../assets/icons/exclamation-warn.svg'
import {
  AuthError,
  BusinessError,
  NetworkError,
  ServiceError,
  ServiceStateError,
  ValidationError,
} from '../../../../../model/errors'

export function ErrorDetails(props: { error: unknown }): JSX.Element {
  const theme = useTheme()

  const iconColors = {
    warn: theme.palette.warning.main,
    error: theme.palette.error.main,
  }

  const details = getErrorDetails(props.error)

  return (
    <Box display='flex' flexDirection='column' gridGap={theme.spacing(1)}>
      <Box display='flex' gridGap={theme.spacing(1)}>
        <ExclamationWarn color={iconColors[details.severity]} /> <Typography>Отображение невозможно</Typography>
      </Box>

      <Typography style={{ fontWeight: 600 }}>{details.message}</Typography>

      {details.description && <Typography>{details.description}</Typography>}

      {details.operation && <Typography color='textSecondary'>{details.operation}</Typography>}
    </Box>
  )
}

interface IErrorDetails {
  severity: 'warn' | 'error'
  message: string
  description?: string
  operation?: string
}

function getErrorDetails(error: unknown): IErrorDetails {
  if (error instanceof ValidationError) {
    return {
      severity: 'warn',
      message: 'Некорректно настроен элемент экрана',
      description: error.message,
    }
  }
  if (error instanceof ReferenceError) {
    return {
      severity: 'warn',
      message: 'Некорректно настроен элемент экрана',
      description: error.message,
    }
  }

  if (error instanceof NetworkError) {
    if (error.code === NetworkError.Offline) {
      return {
        severity: 'warn',
        message: 'Отсутствует подключение к сети',
      }
    }
    if (error.code === NetworkError.Failed) {
      return {
        severity: 'error',
        message: 'Сервер FACE временно недоступен',
        operation: 'Не удалось вызвать метод шифрования параметров',
      }
    }
    if (error.code === NetworkError.Canceled) {
      return {
        severity: 'error',
        message: 'Сервер FACE временно недоступен',
        operation: 'Не удалось вызвать метод шифрования параметров',
      }
    }
  }

  if (error instanceof AuthError) {
    if (error.code === AuthError.Unauthorized) {
      return {
        severity: 'warn',
        message: 'Пользователь не аутентифицирован в FACE',
        operation: 'Не удалось вызвать метод шифрования параметров',
      }
    }
    if (error.code === AuthError.Forbidden) {
      return {
        severity: 'warn',
        message: 'Пользователь не имеет необходимых ролей в FACE',
        operation: 'Не удалось вызвать метод шифрования параметров',
      }
    }
  }

  if (error instanceof BusinessError) {
    return {
      severity: 'error',
      message: 'При вызове метода получения настроек произошла ошибка (400)',
      description: error.message,
      operation: 'Не удалось вызвать метод шифрования параметров',
    }
  }

  if (error instanceof ServiceError) {
    return {
      severity: 'error',
      message: 'При вызове метода получения настроек произошла ошибка (500)',
      description: error.message,
      operation: 'Не удалось вызвать метод шифрования параметров',
    }
  }

  if (error instanceof ServiceStateError) {
    if (error.code === ServiceStateError.Maintenance) {
      return {
        severity: 'warn',
        message: 'Ведутся регламентные работы',
      }
    }
  }

  if (error instanceof Error) {
    return {
      severity: 'error',
      message: 'Неизвестная ошибка',
      description: error.message,
    }
  }

  return {
    severity: 'error',
    message: 'Неизвестная ошибка',
  }
}
