import React, { useContext, useEffect } from 'react'

import { Navigate } from 'react-router-dom'

import { AuthContext } from '../../providers'
import { AppRoutes } from '../../routes'

export const UserApp: React.FC = ({ children }) => {
  const auth = useContext(AuthContext)

  useEffect(() => {
    console.log('UserApp currentUserName:', auth.currentUserName)
  }, [auth.currentUserName])

  if (!auth?.currentUserName) {
    return <Navigate to={`/${AppRoutes.login.path}`} />
  }

  return <>{children}</>
}
