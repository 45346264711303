import React from 'react'

import { Container, createStyles, Dialog, LinearProgress, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import { LockedPage } from '../layout/locked-page'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      lineHeight: '36px',
      textAlign: 'center',
      fontSize: 28,
      fontStyle: 'normal',
      fontWeight: 500,
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
        lineHeight: '28px',
      },
    },
    progressWrap: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiLinearProgress-barColorSecondary': {
        backgroundColor: theme.palette.primary.main,
      },
      '& .MuiLinearProgress-colorSecondary': {
        backgroundColor: '#ACC9E3',
      },
    },
    line: {
      width: 165,
    },
  }),
)

export type TStageLoad = 'Geolocation' | 'Visit' | 'Task'

interface ILoad {
  stage: TStageLoad
}

export const StageLoad: React.FC<ILoad> = ({ stage }) => {
  const classes = useStyles()
  const { t } = useTranslation(['common', 'task', 'visits'])

  const renderStage = (): string => {
    switch (stage) {
      case 'Geolocation':
        return t('getGeolocation', { ns: 'common' })
      case 'Visit':
        return t('preparationStartVisit', { ns: 'visits' })
      case 'Task':
        return t('preparationStartTask', { ns: 'task' })
    }
  }

  return (
    <Dialog open fullScreen>
      <div>
        <LockedPage>
          <Container disableGutters component='main' maxWidth='sm'>
            <Box marginBottom={4}>
              <Typography className={classes.title}>{t('waiting')}</Typography>
              <Typography className={classes.title}>{renderStage()}</Typography>
            </Box>
            <div className={classes.progressWrap}>
              <LinearProgress className={classes.line} color='secondary' />
            </div>
          </Container>
        </LockedPage>
      </div>
    </Dialog>
  )
}
