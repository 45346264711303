import React, { useContext } from 'react'

import { createStyles, makeStyles, MenuItem, Select, Theme, Typography } from '@material-ui/core'
import { useAsync } from 'react-use'

import { IDictionaryItem } from '../../../../../model/dictionary-item'
import { IKeyValuePair, ISingleSelectScreenItem } from '../../../../../model/screen-item'
import { checkPredicate } from '../../../../../model/script-predicate'
import { ApiContext } from '../../../../../providers'
import { getContextProperty, getPropertyAny } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { ItemCard } from '../item-card'
import { ItemCompact } from '../item-compact'
import { TableSelect } from '../table-item/single-select-cell'
import { CompactSelect } from './compact-select'

interface IStylesProps {
  isCompact?: boolean
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) =>
  createStyles({
    select: {
      margin: ({ isCompact }) => (isCompact ? 0 : theme.spacing(1, 0)),
      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
      maxWidth: '100%',
      width: ({ isCompact }) => (isCompact ? '100%' : 'auto'),
    },
    menuItem: {
      paddingTop: 12,
      paddingBottom: 12,
      whiteSpace: 'unset',
    },
    dropdown: {
      boxShadow: ({ isCompact }) =>
        isCompact
          ? '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)'
          : '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    },
  }),
)

type Value = IDictionaryItem | IDictionaryItem[] | string | string[] | undefined
interface Props {
  item: ISingleSelectScreenItem
  onChange?: (value: Value) => void
}

export const SingleSelectScreenItem: React.FC<Props> = ({ item, onChange }) => {
  const isCompact = item.compactMode
  const isArrayValue = item.isArrayValue
  const isRequired = item.required

  const classes = useStyles({ isCompact })
  const propertiesContext = useScriptTaskContext()
  const api = useContext(ApiContext)

  const data = useAsync(async () => {
    switch (item.dataSource.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.KeyValuePairsDataSource':
        return item.dataSource.items
      case 'PMI.FACE.BDDM.Extensions.Classes.PropertyKeyValuePairsDataSource':
        return getPropertyAny<IKeyValuePair[]>(propertiesContext, item.dataSource.propertyName)
      case 'PMI.FACE.BDDM.Extensions.Classes.ContextPropertyDataSource':
        return getPropertyAny<IDictionaryItem[]>(propertiesContext, item.dataSource.propertyName)
      case 'PMI.FACE.BDDM.Extensions.Classes.DictionaryReferenceDataSource':
        const code = item.dataSource.dictionary.version!.code
        const dictionary = await api.dictionary.getDictionaryByVersionCode(code)
        return dictionary?.items
      default:
        return null
    }
  }, [])

  if (data.loading && !data.value) return <></>

  if (item.visible && !checkPredicate(item.visible, undefined, propertiesContext)) {
    return <></>
  }

  const entry = getContextProperty<Value>(propertiesContext, item.propertyName)
  const unfoldedEntry = entry instanceof Array ? entry[0] : entry
  let currentValue: string | null | undefined

  switch (item.dataSource.$type) {
    case 'PMI.FACE.BDDM.Extensions.Classes.KeyValuePairsDataSource':
    case 'PMI.FACE.BDDM.Extensions.Classes.PropertyKeyValuePairsDataSource':
      currentValue = unfoldedEntry === null ? null : (unfoldedEntry as string) ?? ''
      break
    case 'PMI.FACE.BDDM.Extensions.Classes.ContextPropertyDataSource':
    case 'PMI.FACE.BDDM.Extensions.Classes.DictionaryReferenceDataSource':
      currentValue = unfoldedEntry === null ? null : (unfoldedEntry as IDictionaryItem)?.code ?? ''
      break
  }

  const renderValue = (val: unknown): string => {
    const value = val instanceof Array ? val[0] : val
    if (typeof value === 'string') {
      let items: IKeyValuePair[] | IDictionaryItem[] | undefined
      let name: string | undefined
      switch (item.dataSource.$type) {
        case 'PMI.FACE.BDDM.Extensions.Classes.KeyValuePairsDataSource':
        case 'PMI.FACE.BDDM.Extensions.Classes.PropertyKeyValuePairsDataSource':
          items = data.value as IKeyValuePair[]
          name = items?.find((item) => item.key === val)?.value
          if (name) return name
          break
        case 'PMI.FACE.BDDM.Extensions.Classes.ContextPropertyDataSource':
        case 'PMI.FACE.BDDM.Extensions.Classes.DictionaryReferenceDataSource':
          items = data.value as IDictionaryItem[]
          name = items?.find((item) => item.code === val)?.name
          if (name) return name
          break
      }

      if (value) return value
      if (!items?.length) return 'Список элементов пуст'
    }

    if (val === null) {
      return item.nullValueCaption ?? 'Не выбрано'
    }

    return item.buttonCaption ?? 'Выбрать'
  }

  const renderItems = (): JSX.Element[] => {
    let items: IKeyValuePair[] | IDictionaryItem[] | undefined
    switch (item.dataSource.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.KeyValuePairsDataSource':
      case 'PMI.FACE.BDDM.Extensions.Classes.PropertyKeyValuePairsDataSource':
        items = data.value as IKeyValuePair[]
        return items?.map((item) => (
          <MenuItem className={classes.menuItem} key={item.key} value={item.key}>
            {item.value || item.key}
          </MenuItem>
        ))
      case 'PMI.FACE.BDDM.Extensions.Classes.ContextPropertyDataSource':
      case 'PMI.FACE.BDDM.Extensions.Classes.DictionaryReferenceDataSource':
        items = data.value as IDictionaryItem[]
        return items?.map((item) => (
          <MenuItem className={classes.menuItem} key={item.code} value={item.code}>
            {item.name || item.code}
          </MenuItem>
        ))
    }
    return []
  }
  const isFilled = !!currentValue || currentValue === null

  const renderSelect = (): JSX.Element => {
    return (
      <Select
        value={currentValue}
        className={classes.select}
        MenuProps={{
          classes: { paper: classes.dropdown },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        displayEmpty
        input={isCompact ? <CompactSelect /> : <TableSelect color='primary' />}
        renderValue={renderValue}
        // disabled={isEmpty}
        onChange={(ev) => {
          if (ev.target.value === null) {
            const value = isArrayValue ? [null as unknown as IDictionaryItem] : (null as unknown as IDictionaryItem)
            onChange?.(value)
            return
          }

          let items: IKeyValuePair[] | IDictionaryItem[] | undefined
          let target: string | IDictionaryItem | undefined
          let value: string | string[] | IDictionaryItem | IDictionaryItem[] | undefined
          switch (item.dataSource.$type) {
            case 'PMI.FACE.BDDM.Extensions.Classes.KeyValuePairsDataSource':
            case 'PMI.FACE.BDDM.Extensions.Classes.PropertyKeyValuePairsDataSource':
              items = data.value as IKeyValuePair[]
              target = items?.find((item) => item.key === ev.target.value)?.key
              value = isArrayValue && target ? [target] : target
              onChange?.(value)
              break
            case 'PMI.FACE.BDDM.Extensions.Classes.ContextPropertyDataSource':
            case 'PMI.FACE.BDDM.Extensions.Classes.DictionaryReferenceDataSource':
              items = data.value as IDictionaryItem[]
              target = items?.find((item) => item.code === ev.target.value)
              value = isArrayValue && target ? [target] : target
              onChange?.(value)
              break
          }
        }}
      >
        {item.nullable && (
          <MenuItem value={null as unknown as string}>{item.nullValueCaption ?? 'Не выбрано'}</MenuItem>
        )}

        {renderItems()}
      </Select>
    )
  }

  if (isCompact) {
    return <ItemCompact>{renderSelect()}</ItemCompact>
  }

  return (
    <ItemCard isError={!isFilled && isRequired} label={<Typography variant='inherit'>{item.displayName}</Typography>}>
      {renderSelect()}
    </ItemCard>
  )
}
