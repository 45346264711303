import { generateKey } from '../infrastructure/blob-storage/blob-storage'
import { BlobMetadata, BlobStorageErrorCode } from '../infrastructure/blob-storage/blob-storage-api'
import { getBlobKind } from '../infrastructure/blob-storage/blob-utils'
import MimeTypeExtensions from '../infrastructure/blob-storage/mimetype-extensions'
import { IHttpClient } from '../infrastructure/http-client-factory'
import { LoggerBase, LogManager } from '../infrastructure/logger'
import { BlobApiError, IBlobWebApi, OkStrorageResponse } from './blob-web-api'

export class BlobWebApiService implements IBlobWebApi {
  private readonly logger: LoggerBase

  constructor(private readonly _http: IHttpClient, private readonly _baseUrl: string, private readonly _rootFolder = '') {
    this.logger = LogManager.getLogger('BlobWebApiService')
  }

  getObjectUrl(collection: string, path: string): string {
    // HACK: https://wiki.app.pconnect.biz/pages/viewpage.action?spaceKey=FACE&title=CloudMediaStorageSettings
    if (path.startsWith(collection) || path.startsWith('/' + collection)) {
      collection = ''
    }
    return `${this._baseUrl}/${this._rootFolder}/${collection}/${path}`
  }

  async get(collection: string, path: string): Promise<Blob | undefined> {
    if (!self.navigator.onLine) {
      throw new BlobApiError(BlobStorageErrorCode.Offline, 'Cant receive blob while offline')
    }

    const url = this.getObjectUrl(collection, path)
    try {
      const response = await this._http.request({
        method: 'GET',
        url,
        responseType: 'blob',
      })

      return response.data
    } catch (e) {
      console.warn(`BlobWebApiService: Error downloading blob '${url}'`, e)
      if (e.response) {
        switch (e.response.status) {
          case 400:
            let text = '?'
            try {
              text = await e.response.data.text()
            } catch (e) {
              /* ignore */
            }
            this.logger.warn('get', `Error downloading blob '${url}' (${text})`, e)
            // HACK: Hardcoded server error string!
            if (text.includes('specified key does not exist')) {
              throw new BlobApiError(BlobStorageErrorCode.NotFound, `Blob '${url}' not found on server`, {
                target: path,
              })
            }
            throw new BlobApiError(BlobStorageErrorCode.BadRequest, `Blob download error: ${text}`, {
              target: path,
              error: text,
            })
          case 401:
            throw new BlobApiError(BlobStorageErrorCode.Unauthorized, 'Unauthorized')
          case 404:
            throw new BlobApiError(BlobStorageErrorCode.NotFound, `Blob '${path}' not found on server`)
        }
      }
    }
  }

  async update(collection: string, path: string, blob: Blob): Promise<OkStrorageResponse> {
    const url = this.getObjectUrl(collection, path)
    const response = await this._http.request({
      method: 'PUT',
      url,
      data: blob,
      headers: {
        'Content-Type': blob.type,
      },
    })

    return response.data
  }

  async add(collection: string, blob: Blob): Promise<BlobMetadata> {
    const kind = getBlobKind(blob)
    const key = `${generateKey(kind)}.${MimeTypeExtensions[blob.type] ?? 'bin'}`

    try {
      await this.update(collection, key, blob)
    } catch (e) {
      console.warn(`BlobWebApiService: Error uploading blob '${key}'`, e)
      if (e.response) {
        switch (e.response.status) {
          case 400:
            let text = '?'
            try {
              text = await e.response.data.text()
            } catch (e) {
              /* ignore */
            }
            this.logger.warn('add', `Error uploading blob '${key}' (${text})`, e)
            break
          case 401:
            throw new BlobApiError(BlobStorageErrorCode.Unauthorized, 'Unauthorized')
        }
      }
    }

    const metadata: BlobMetadata = {
      key: `${collection}/${key}`,
      timestamp: Date.now(),
      size: blob.size,
      mimeType: blob.type,
    }

    return metadata
  }
}
