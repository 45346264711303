/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react'

import { uniq, uniqWith } from 'lodash'
import { set } from 'lodash/fp'
import { useAsync, useMountedState } from 'react-use'

import {
  Code,
  CodeSpace,
  createReferenceToEntity,
  IEntityReference,
  isReferenceToEntityIgnoreVersion,
} from '../../../model/base'
import { IBusinessParameters } from '../../../model/business-parameters'
import { IContractTerm } from '../../../model/contract-term'
import { FieldForceVisitEscalationReason } from '../../../model/dictionary-item'
import { IDteParticipantProfile } from '../../../model/dte-participant-profile'
import { IEmployee } from '../../../model/employee'
import { BusinessError } from '../../../model/errors'
import { IPointOfSale, IPOSReference } from '../../../model/pos'
import { IProductMatrix } from '../../../model/product-matrix'
import { IQuestionnaire, IQuestionnaireReference } from '../../../model/questionnaire'
import { IQuestionnairePOSAssignment } from '../../../model/questionnaire-pos-assignment'
import { ISupervisedFieldPositionRole } from '../../../model/supervised-field-position-role'
import { ISurvey } from '../../../model/survey'
import { ITableScreenItem } from '../../../model/table-screen-item'
import { ITask } from '../../../model/task'
import { IProcessState, ITaskExecutionScope, ITaskExecutionState } from '../../../model/task-execution'
import { ITaskExecutionProcess } from '../../../model/task-execution-process'
import { ITaskStage } from '../../../model/task-stage'
import { ITaskTemplate } from '../../../model/task-template'
import { IUserGroup } from '../../../model/user-group'
import { IFieldPositionRole, IUserProfile } from '../../../model/user-profile'
import { IUserStepScreen } from '../../../model/user-step-screen'
import { IPosVisitReference, IVisit } from '../../../model/visit'
import { ConfigContext, ProfileContext } from '../../../providers'
import ApiContext, { IApiContext } from '../../../providers/api/api-context'
import { useDefaultCodeSpace } from '../../../providers/config/useDefaultCodeSpace'
import { ExecutionProcessUnit } from '../../../services-admin/execution-process-service-api'
import { CreateProblemRequest, ProblemErrorCode } from '../../../services/problem-service-api'
import { useAsyncError } from '../../_common/hooks/useAsyncError'
import { useAsyncRetryPromise } from '../../_common/hooks/useAsyncRetry'
import { useBusinessSettings } from '../../_common/hooks/useBusinessSettings'
import { useWebUrl } from '../../_common/hooks/useWebUrl'
import { executeWebMethod, IWebMethodRequest, IWebMethodResult } from '../../process/execute-web-method'
import { VisitAssessment } from '../audit/audit-merchandise-service-task'
import { makePosExtension } from '../audit/use-backup-extensions'
import { getPropertyAny } from '../script-tasks/propertyName'
import { IScriptTaskContext, ScriptTaskContext } from '../script-tasks/script-task-context'
import { IViewRichTextListScreenItem } from '../template-tasks/composite-screen/view-rich-text-list-screen-item'
import { useExecutionState } from './execution-state'
import { iterAllScreens, iterAllItems, iterProcessItems } from './iter-template-utils'
import { tryFindTaskTemplate } from './tryFindTaskTemplate'

export type SubProcessDefiningItem = ITableScreenItem | IViewRichTextListScreenItem

export interface SubProcessStackFrame {
  item: SubProcessDefiningItem
  record: unknown
  recordIndex: number
  process: ITaskExecutionProcess
}

export function makeStateKey({ process, record, item }: SubProcessStackFrame): string {
  const recordCode = getPropertyAny(record, item.recordKeyPropertyName!, '')
  return recordCode + process.code
}

export function getContextScreens(context: IScriptTaskContext): Record<Code, IUserStepScreen> {
  const { template, processUnit } = context
  if (processUnit) {
    return processUnit.screens
  }
  return template.userStepScreens
}

type TaskScope = ITaskExecutionScope['task']

export type ContextProcessUnit = ExecutionProcessUnit & {
  subProcesses: ITaskTemplate['subProcesses']
}

export interface ILocalContext {
  subProcessStack?: SubProcessStackFrame[]
  employee: IEmployee | undefined
  profileCode: string
  fieldPositionRole: IFieldPositionRole | undefined
  participantProfile: IDteParticipantProfile | undefined
  userGroups?: IUserGroup[]
  task: ITask
  visit?: IVisit
  pos?: IPointOfSale
  template: ITaskTemplate
  processUnit?: ContextProcessUnit
  taskState: ITaskExecutionState
  scope: TaskScope
  fullScope: ITaskExecutionScope
  questionnaires: IQuestionnaire[]
  surveys: Record<Code, ISurvey[]>
  // questionnaireAssignment?: ITaskTemplateQuestionnaireAssignment
  questionnairePosAssignments?: IQuestionnairePOSAssignment[]
  recordScope?: unknown
  recordState?: IProcessState
  record?: unknown
  apiUrl: string
  webUrl: string
  businessParameters: IBusinessParameters | undefined
  readOnly: boolean
  [key: string]: unknown
}

export type CreateSingleProblemRequest = Omit<CreateProblemRequest, 'problemDetails'> & {
  problemDetails: string | undefined
}

export interface ILocalContextService {
  inMemory?: boolean

  /** @defaultValue 'task' */
  rootRecordName?: string

  cancelRefetch?: VoidFunction
  updateProperty: (propertyName: string, value: unknown) => Promise<void>
  /** save root record */
  saveTask: (task: ITask) => Promise<void>
  saveScope: (scope: ITaskExecutionScope) => Promise<void>
  saveTaskState: (taskState: ITaskExecutionState) => Promise<void>
  updateStackSteps: () => Promise<void>
  refetch: (changes?: Array<[string, unknown]>) => Promise<IScriptTaskContext>
  onLoad: () => Promise<void>

  getProductMatrix: () => Promise<IProductMatrix[]>
  getFprUserProfiles: () => Promise<IUserProfile[]>
  getSupervisedRoleByCode: (roleCode: Code) => Promise<ISupervisedFieldPositionRole | undefined>
  getContractTerms: () => Promise<IContractTerm[]>
  createVisitProblem: (
    escalationReason: FieldForceVisitEscalationReason,
    executiveComment: string,
    problemDetails?: string,
  ) => Promise<string>
  getTaskTemplateStages: (taskCode: Code, searchInSupervisedTasks: boolean) => Promise<ITaskStage[]>
  getTaskTemplateByKey: (ref: IEntityReference) => Promise<ITaskTemplate | null>
  getTaskTemplateByTaskCode: (taskCode: Code, searchInSupervisedTasks: boolean) => Promise<ITaskTemplate | null>
  loadMediaForSupervisedVisit: (visitCode: string) => Promise<void>
  executeWebMethod?: (req: IWebMethodRequest) => Promise<IWebMethodResult>
  getPosExtension: (posCode: string) => Promise<VisitAssessment['posExtension'] | undefined>
  getQuestionnaireByKey: (ref: IEntityReference) => Promise<IQuestionnaire | null>
  getDefaultCodeSpace: () => CodeSpace
}

class LocalContextService implements ILocalContextService {
  posRef: IPOSReference | undefined
  visitRef: IPosVisitReference | undefined

  constructor(
    readonly taskCode: Code,
    readonly api: IApiContext,
    private readonly _refetch: ILocalContextService['refetch'],
    private readonly defaultCodeSpace: CodeSpace,
  ) {}

  private fetching: Promise<IScriptTaskContext> | undefined

  refetch = async (): Promise<IScriptTaskContext> => {
    this.fetching = this._refetch()
    const res = await this.fetching
    this.fetching = undefined
    return res
  }

  onLoad = async (): Promise<void> => {
    if (this.fetching) {
      console.log('awaiting fetch')
      return this.fetching.then(() => void 0)
    } else {
      console.log('not fetching')
      return Promise.resolve()
    }
  }

  updateProperty = async (propertyName: string, value: unknown): Promise<void> => {
    if (propertyName.startsWith('fullScope')) {
      const oldScope = await this.api.tasks.getTaskExecutionScope(this.taskCode)
      const { fullScope: newScope } = set(propertyName, value, { fullScope: oldScope })
      // console.log('updating scope instead', oldScope, newScope)
      return await this.saveScope(newScope!)
    }
    await this.api.tasks.setTaskPropertyPath({ propertyName, taskCode: this.taskCode, value })
  }

  saveTask = async (task: ITask): Promise<void> => {
    if (task.code !== this.taskCode) {
      throw new Error('wrong task code')
    }
    await this.api.tasks.saveTask(task)
  }

  saveScope = async (scope: ITaskExecutionScope): Promise<void> => {
    await this.api.tasks.saveTaskExecutionScope(this.taskCode, scope)
  }

  saveTaskState = async (taskState: ITaskExecutionState): Promise<void> => {
    // we have full control over this
    if (taskState.taskCode !== this.taskCode) {
      throw new Error('wrong task code')
    }
    await this.api.tasks.saveTaskExecutionState(taskState)
  }

  updateStackSteps = async (): Promise<void> => {
    // TODO: update upwards only
    const localTaskState = useExecutionState.getState().taskState
    await this.api.tasks.saveTaskExecutionState(localTaskState)
    await this.refetch()
  }

  getProductMatrix = async (): Promise<IProductMatrix[]> => {
    if (!this.posRef?.code) throw new Error('no posCode')
    return this.api.pos.getProductMatrices(this.posRef.code, new Date())
  }

  getFprUserProfiles = async (): Promise<IUserProfile[]> => {
    return this.api.fprUserProfiles.getFprUserProfiles()
  }

  getSupervisedRoleByCode = async (roleCode: Code): Promise<ISupervisedFieldPositionRole | undefined> => {
    return this.api.supervised.getSupervisedRoleByCode(roleCode)
  }

  getContractTerms = async (): Promise<IContractTerm[]> => {
    if (!this.posRef?.code) throw new Error('no posCode')
    return this.api.pos.getContractTerms(this.posRef.code, new Date())
  }

  createVisitProblem = async (
    escalationReason: FieldForceVisitEscalationReason,
    executiveComment: string,
    detail?: string,
  ): Promise<string> => {
    try {
      const problem = await this.api.problem.createNewProblems({
        escalationReason,
        executiveComment,
        problemDetails: detail ? [detail] : undefined,
        visit: this.visitRef,
        location: this.posRef,
      })
      return problem[0].code
    } catch (err: unknown) {
      if (err instanceof BusinessError && err.code === ProblemErrorCode.DuplicateProblem) {
        return ''
      }
      throw err
    }
  }

  getTaskTemplateByKey = async (ref: IEntityReference): Promise<ITaskTemplate | null> => {
    return await this.api.tasks.getTaskTemplate(ref)
  }

  getTaskTemplateByTaskCode = async (
    taskCode: string,
    searchInSupervisedTasks: boolean,
  ): Promise<ITaskTemplate | null> => {
    if (!searchInSupervisedTasks) throw new Error(`not implemented searchInSupervisedTasks: ${searchInSupervisedTasks}`)
    const taskUnit = await this.api.tasks.getSupervisedTaskUnit(taskCode)
    if (!taskUnit) throw new Error(`task ${taskCode} not found`)

    const template = await this.api.tasks.getTaskTemplate(taskUnit.task.template)
    return template
  }

  getTaskTemplateStages = async (taskCode: Code, searchInSupervisedTasks: boolean): Promise<ITaskStage[]> => {
    const template = await this.getTaskTemplateByTaskCode(taskCode, searchInSupervisedTasks)
    return template?.stages ?? []
  }

  loadMediaForSupervisedVisit = async (visitCode: string): Promise<void> => {
    const mediaRefs = await this.api.audit.getMediaRefsForSupervisedVisit(visitCode)
    await this.api.blobStorage?.receive(
      mediaRefs.map((ref) => [ref, {}]),
      1,
    )
  }

  executeWebMethod = async (req: IWebMethodRequest): Promise<IWebMethodResult> => {
    return executeWebMethod(this.api, req)
  }

  getPosExtension = async (posCode: string): Promise<VisitAssessment['posExtension'] | undefined> => {
    const pos = await this.api.pos?.getPos(posCode)
    if (pos) {
      return makePosExtension(pos)
    }
  }

  getQuestionnaireByKey = async (ref: IEntityReference): Promise<IQuestionnaire | null> => {
    return await this.api.questionnaire.getQuestionnaireByKey(ref)
  }

  getDefaultCodeSpace = (): CodeSpace => this.defaultCodeSpace
}

export const LocalContextServiceContext = React.createContext({} as ILocalContextService)
export const useLocalContextService = (): ILocalContextService => useContext(LocalContextServiceContext)

interface ProviderProps {
  taskCode: Code
  visitCode?: Code
  initialData?: Record<string, unknown>
}

export const LocalContextProvider: React.FC<ProviderProps> = ({ taskCode, visitCode, initialData, children }) => {
  const serviceRef = useRef<LocalContextService>()
  const api = useContext(ApiContext)
  const profileContext = useContext(ProfileContext)
  const webUrl = useWebUrl()
  const config = useContext(ConfigContext)
  const setTaskStateStore = useExecutionState((store) => store.init)
  const isMounted = useMountedState()
  const businessParameters = useBusinessSettings()
  const defaultCodeSpace = useDefaultCodeSpace()

  useEffect(() => {
    if (!api.tasks) return
    void (async () => {
      const taskState = (await api.tasks.getTaskExecutionState(taskCode)) ?? {
        taskCode,
        cases: {},
        currentStep: 0,
        subProcesses: {},
      }
      if (isMounted()) {
        setTaskStateStore(taskState)
      }
    })()
    return () => setTaskStateStore(undefined!)
  }, [taskCode, api.tasks])

  const readOnlyDataOps = useAsync(async () => {
    if (!profileContext.value) return
    const visit = visitCode ? await api.visits.getVisit(visitCode) : undefined
    const pos = visit && (await api.pos.getPos(visit.pointOfSaleCode))
    if (pos) {
      const date = new Date()
      pos.checkOutCount = await api.pos.getCheckoutCount(pos.code, date)
      pos.posModelStoreLevel = await api.pos.getModelStoreLevel(pos.code, date)
    }

    const task = await api.tasks.getTask(taskCode)
    if (!task) throw new Error('no task')
    const template = await tryFindTaskTemplate(api, profileContext.value.profile, task)
    const assignableQuestionnaires = findAssignableQuestionnaires(template)
    let questionnairePosAssignments: IQuestionnairePOSAssignment[] | undefined
    if (pos) {
      const res = await Promise.all(
        assignableQuestionnaires.map(async (q) => api.questionnaire.getQuestionnairePOSAssignments(q.code, pos.code)),
      )
      questionnairePosAssignments = res.flat()
    }
    return {
      visit: visit || undefined,
      pos: pos || undefined,
      questionnairePosAssignments,
      template,
      fieldPositionRole: profileContext.value.fieldPositionRole,
      employee: profileContext.value.employee,
      profileCode: profileContext.value.profile.code,
      participantProfile: profileContext.value.participantProfile,
      apiUrl: config.config.apiUrl,
      webUrl,
      businessParameters,
    }
  }, [taskCode, visitCode, profileContext.value])

  const mutableDataOps = useAsyncRetryPromise(async () => {
    const task = await api.tasks.getTask(taskCode)
    if (!task) throw new Error('no task')
    const visit = visitCode ? await api.visits.getVisit(visitCode) : undefined
    const taskState = (await api.tasks.getTaskExecutionState(taskCode)) ?? {
      taskCode: task.code,
      cases: {},
      currentStep: 0,
      subProcesses: {},
    }
    const fullScope = (await api.tasks.getTaskExecutionScope(taskCode)) ?? { task: {} }
    const template = await tryFindTaskTemplate(api, profileContext.value!.profile, task)

    const surveys = await fetchAllTaskSurveys(task, template, api)
    const questionnaires = await api.questionnaire.getQuestionnaires(
      uniq(
        Object.values(surveys)
          .flat()
          .map((survey) => survey.questionnaire.code),
      ),
    )

    const context: ILocalContext = {
      ...initialData,
      visit: visit || undefined,
      task,
      template,
      fullScope,
      taskState,
      scope: fullScope.task,
      questionnaires,
      surveys,
    } as unknown as ILocalContext
    return context
  }, [])

  useLayoutEffect(() => {
    if (!api) return
    serviceRef.current = new LocalContextService(
      taskCode,
      api,
      mutableDataOps.retry as () => Promise<IScriptTaskContext>,
      config.config.defaultCodeSpace,
    )
  }, [taskCode, api])

  useAsyncError(mutableDataOps.error)
  useAsyncError(readOnlyDataOps.error)
  if (readOnlyDataOps.loading) return <></>
  if (mutableDataOps.loading && !mutableDataOps.value) return <></>

  if (serviceRef.current && (!serviceRef.current.visitRef || !serviceRef.current.posRef)) {
    if (readOnlyDataOps.value) {
      const { pos, visit } = readOnlyDataOps.value
      if (pos) {
        serviceRef.current.posRef = createReferenceToEntity<IPOSReference>(
          pos,
          defaultCodeSpace,
          'PMI.BDDM.Staticdata.POSReference',
        )
      }
      if (visit) {
        serviceRef.current.visitRef = createReferenceToEntity<IPosVisitReference>(
          visit,
          defaultCodeSpace,
          'PMI.BDDM.Transactionaldata.FieldForcePOSVisitReference',
        )
      }
    }
  }

  const mutableData = mutableDataOps.value as unknown as IScriptTaskContext
  const readOnlyData = readOnlyDataOps.value!
  const context = { ...readOnlyData, ...mutableData }

  return (
    <LocalContextServiceContext.Provider value={serviceRef.current!}>
      <ScriptTaskContext.Provider value={context}>{children}</ScriptTaskContext.Provider>
    </LocalContextServiceContext.Provider>
  )
}

export function findAssignableQuestionnaires(template: ITaskTemplate): IQuestionnaireReference[] {
  const res: IQuestionnaireReference[] = []
  for (const screen of iterAllScreens({ template })) {
    if (screen.$type === 'PMI.FACE.BDDM.Extensions.Classes.CompositeUserStepScreen') {
      for (const item of iterAllItems(screen.items)) {
        switch (item.$type) {
          case 'PMI.FACE.BDDM.Extensions.Classes.FillSurveyScreenItem':
          case 'PMI.FACE.BDDM.Extensions.Classes.ViewSurveyScreenItem':
            if (item.questionnaires?.length) {
              res.push(...item.questionnaires)
            }
        }
      }
    }
    // if (screen.$type === "PMI.FACE.BDDM.Extensions.Classes.SimpleSurveyUserStepScreen") {
    // }
  }
  return uniqWith(res, isReferenceToEntityIgnoreVersion)
}

function findSurveyPaths(template: ITaskTemplate): Array<[unresolvedPath: string, fullPath: string[]]> {
  const unresolvedPaths = ['task.surveys']
  for (const screen of iterAllScreens({ template })) {
    if (screen.$type === 'PMI.FACE.BDDM.Extensions.Classes.CompositeUserStepScreen') {
      for (const item of iterAllItems(screen.items)) {
        switch (item.$type) {
          case 'PMI.FACE.BDDM.Extensions.Classes.FillSurveyScreenItem':
          case 'PMI.FACE.BDDM.Extensions.Classes.ViewSurveyScreenItem':
          case 'PMI.FACE.BDDM.Extensions.Classes.SurveysPropertyScreenItem':
            unresolvedPaths.push(item.propertyName)
          // if (item.propertyName === 'task.visitAssessmentsData[0].surveys') {
          //   console.log('found task.visitAssessmentsData[0].surveys', screen, item)
          // }
        }
      }
    }
  }
  const paths = uniq(unresolvedPaths)
  const variableMapping: Record<string, string> = {}
  for (const item of iterProcessItems({ template })) {
    if (item.elementVariable) {
      variableMapping[item.elementVariable] = item.propertyName
    }
  }
  const res: Array<[unresolvedPath: string, fullPath: string[]]> = []
  for (const unresolvedPath of paths) {
    function resolvePath(path: string[]): string[] {
      const currentPath = path[0]
      const [variable, ...rest] = currentPath.split('.')
      if (variable in variableMapping) {
        return resolvePath([variableMapping[variable], rest.join('.'), ...path.slice(1)])
      }
      return path
    }

    const fullPath = resolvePath([unresolvedPath])
    res.push([unresolvedPath, fullPath])
  }
  return res
}

export async function fetchAllTaskSurveys(
  task: ITask,
  template: ITaskTemplate,
  api: IApiContext,
): Promise<ILocalContext['surveys']> {
  const possibleSurveyPaths = findSurveyPaths(template)
  console.log('possible survey paths', possibleSurveyPaths)
  const surveys: ILocalContext['surveys'] = {}
  const existingSurveyPaths = new Set<string>()
  for (const [, fullPath] of possibleSurveyPaths) {
    const [basePath, ...properties] = fullPath
    const target = getPropertyAny<unknown[]>({ task }, basePath) ?? []

    function rec(target: unknown[], propIndex: number, pathSoFar: string): void {
      if (propIndex >= properties.length) {
        existingSurveyPaths.add(pathSoFar)
        return
      }
      if (!target.length) return

      const prop = properties[propIndex]
      target.forEach((elem, i) => {
        const path = `${pathSoFar}[${i}].${prop}`
        return rec(getPropertyAny(elem, prop, []), propIndex + 1, path)
      })
    }

    rec(target, 0, basePath)
  }
  console.log('existing survey paths', existingSurveyPaths)
  for (const path of existingSurveyPaths) {
    const refs = getPropertyAny<IEntityReference[]>({ task }, path, [])
    const surveysByPath = await api.survey.getSurveys(refs.map((survey) => survey.code) ?? [])
    surveys[path] = surveysByPath
  }

  return surveys
}
