import React, { useEffect, useState } from 'react'

import { Box, createStyles, FormControl, MenuItem, Select, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { grays } from '../../../../../layout/theme'
import { useIsSmall } from '../../../../_common/hooks/useIsSmall'
import SearchInput from '../../../../tasks/sales-expert/tabs/session/search-input'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterBlock: {
      display: 'flex',
      marginBottom: theme.spacing(3),
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        marginBottom: 4,
      },
    },
    searchInput: {
      marginRight: theme.spacing(3),
      flex: '1 1 322px',
    },
    filter: {
      marginRight: theme.spacing(3),
      flex: '1 1 322px',
      backgroundColor: 'white',
      borderRadius: 40,
      overflow: 'hidden',
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
        marginBottom: 12,
        flex: '1 1 auto',
      },
      '& :before': {
        display: 'none',
      },
      '& :after': {
        display: 'none',
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
    filterStatus: {
      flex: '1 1 203px',
      [theme.breakpoints.down('xs')]: {
        flex: '1 1 auto',
      },
    },
    filterCaptionDefault: {
      '& .MuiSelect-select': {
        color: grays.gray3,
      },
    },
    filterCaptionSelected: {
      '& .MuiSelect-select': {
        color: theme.palette.common.black,
      },
    },
    filterInput: {
      padding: '4px 0px 3px 0px',
      backgroundColor: 'white',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.15px',
      [theme.breakpoints.down('xs')]: {
        padding: '2px 0px 1px 0px',
      },
      '& .MuiSelect-select.MuiSelect-select': {
        paddingRight: 32,
        paddingLeft: 16,
        backgroundColor: 'white',
      },
      '&.MuiSelect-selectMenu': {
        whiteSpace: 'nowrap',
      },
      '&:focus': {
        backgroundColor: 'inherit',
      },
      '& .MuiSelect-icon': {
        marginRight: 15,
        paddingBottom: 2,
        [theme.breakpoints.down('xs')]: {
          marginRight: 14,
        },
      },
    },
    menuPopup: {
      marginTop: 6,
    },
  }),
)

interface ILocation {
  pathname: string
}

interface IProps {
  triggerFilter: React.Dispatch<React.SetStateAction<boolean>>
}

const ProblemFilterScreenItem: React.FC<IProps> = ({ triggerFilter }) => {
  const { t } = useTranslation(['problems', 'common'])
  const isSmall = useIsSmall()
  const classes = useStyles()
  const statusDictionary = ['all', 'new', 'resolve', 'assignedToMe', 'appointedByMe', 'irrelevant']
  const filterDateDictionary = ['ascending', 'descending']
  const { pathname } = useLocation() as ILocation

  const [sortStatus, setSortStatus] = useState<string | undefined>('')
  const [sortDate, setSortDate] = useState<string | undefined>('')

  const handleSearch = (value: string): void => {
    triggerFilter((prev) => !prev)
    sessionStorage.setItem(`${pathname}-searchInput`, value)
  }

  const handleChangeStatus = (e: React.ChangeEvent<{ value: unknown }>): void => {
    sessionStorage.setItem(`${pathname}-sortStatus`, e.target.value as string)
    triggerFilter((prev) => !prev)
    setSortStatus(e.target.value as string)
  }

  const handleChangeDate = (e: React.ChangeEvent<{ value: unknown }>): void => {
    sessionStorage.setItem(`${pathname}-sortDate`, e.target.value as string)
    triggerFilter((prev) => !prev)
    setSortDate(e.target.value as string)
  }

  useEffect(() => {
    setSortStatus(sessionStorage.getItem(`${pathname}-sortStatus`) ?? '')
    setSortDate(sessionStorage.getItem(`${pathname}-sortDate`) ?? '')
  }, [])

  return (
    <Box className={classes.filterBlock}>
      {!isSmall && (
        <Box className={classes.searchInput}>
          <SearchInput
            placeholder={t('search', { ns: 'common' })}
            onChange={handleSearch}
            query={sessionStorage.getItem(`${pathname}-searchInput`) ?? ''}
          />
        </Box>
      )}
      <FormControl
        className={classNames(classes.filter, {
          [classes.filterStatus]: true,
          [classes.filterCaptionSelected]: sortStatus,
          [classes.filterCaptionDefault]: !sortStatus,
        })}
      >
        <Select
          displayEmpty
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            classes: { paper: classes.menuPopup },
          }}
          className={classes.filterInput}
          onChange={handleChangeStatus}
          renderValue={(val) => {
            if (typeof val === 'string') {
              return t(`statusDictionary.${val}`)
            }
            if (sortStatus) {
              return t(`statusDictionary.${sortStatus}`)
            }
            return t('chooseStatus')
          }}
        >
          {statusDictionary.map((item) => (
            <MenuItem key={item} value={item}>
              {t(`statusDictionary.${item}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        className={classNames(classes.filter, {
          [classes.filterCaptionSelected]: sortDate,
          [classes.filterCaptionDefault]: !sortDate,
        })}
      >
        <Select
          displayEmpty
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            classes: { paper: classes.menuPopup },
          }}
          className={classes.filterInput}
          onChange={handleChangeDate}
          renderValue={(val) => {
            if (typeof val === 'string') {
              return t(`filterDateDictionary.${val}`)
            }
            if (sortDate) {
              return t(`filterDateDictionary.${sortDate}`)
            }
            return `${t('sortingBy')}:`
          }}
        >
          {filterDateDictionary.map((item) => (
            <MenuItem key={item} value={item}>
              {t(`filterDateDictionary.${item}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default ProblemFilterScreenItem
