import { pdfjs } from 'react-pdf'

export async function makeImageThumbnail(
  imageBlob: Blob,
  width: number,
  height: number,
  quality = 0.4
): Promise<string> {
  const img = new Image()
  const blobLink = URL.createObjectURL(imageBlob)
  try {
    img.src = blobLink
    await img.decode()
  } finally {
    URL.revokeObjectURL(blobLink)
  }

  let canvas: HTMLCanvasElement | null = document.createElement('canvas')
  canvas.width = width
  canvas.height = height

  const kw = img.naturalWidth / width
  const kh = img.naturalHeight / height
  const k = kw * height > img.naturalHeight ? kh : kw
  const wk = width * k
  const hk = height * k
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
  ctx.drawImage(
    img,
    (img.naturalWidth - wk)/2,
    (img.naturalHeight - hk)/2,
    wk,
    hk,
    0, 0, width, height
  )
  const base64 = canvas.toDataURL('image/jpeg', quality)
  canvas = null

  return base64
}

export async function makePdfThumbnail(
  pdfBlob: Blob,
  width: number,
  height: number,
  quality = 0.4
): Promise<string> {
  let canvas: HTMLCanvasElement | null = document.createElement('canvas')

  canvas.width = width
  canvas.height = height

  try {
    const url = URL.createObjectURL(pdfBlob)
    const pdf = await pdfjs.getDocument({url: url}).promise
    URL.revokeObjectURL(url)
    const page = await pdf.getPage(1)
    const viewport = page.getViewport({ scale: 1 })
    const ctx = canvas.getContext('2d')

    const getTransform = (): number[] => {
      const kw = viewport.width / width
      const k = kw * height <= viewport.height ? height / viewport.height : width / viewport.width
      const dx = width / 2 - viewport.width / 2 * k
      const dy = height / 2 - viewport.height / 2 * k
      return [k, 0, 0, k, dx, dy]
    }

    const renderContext = { canvasContext: ctx!, viewport: viewport, transform: getTransform() }

    await page.render(renderContext).promise
  } catch (err) {
    console.warn(`Failed render thumbnail image: ${err}`)
  }

  const base64 = canvas.toDataURL('image/jpeg', quality)
  canvas = null

  return base64
}