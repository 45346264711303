import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { Box, createStyles, Grid, IconButton, Paper, SvgIcon, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import classnames from 'classnames'
import { useAsync } from 'react-use'

import { ReactComponent as ChevronRight } from '../../../assets/icons/ChevronRight.svg'
import { grays } from '../../../layout/theme'
import { ITaskTemplate } from '../../../model/task-template'
import { IPreviousTasksUserStepScreen } from '../../../model/user-step-screen'
import { ApiContext } from '../../../providers'
import { IVisitTaskSummary3 } from '../../../services/task-service-api'
import { dateFormat } from '../../../utils'
import { getPageLayoutStyles } from '../components/shared-styles'
import SessionPeriodSwitcher from '../sales-expert/tabs/session/components/session-period-switcher'
import SearchInput from '../sales-expert/tabs/session/search-input'
import { getPropertyAny } from '../script-tasks/propertyName'
import PreviousTasksInfo from './previous-task-info'
import PreviousTaskView from './previous-task-view'
import { SessionViewMode, viewModeToPeriod } from './utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
      // display: 'flex',
      minWidth: 320,
      alignItems: 'center',
      padding: '7px 24px',
      marginBottom: theme.spacing(1),
      minHeight: theme.spacing(9),
      borderRadius: 12,
      boxShadow: '0px 3px 7px rgba(96, 97, 112, 0.13)',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1.5),
      },
    },
    content: {
      ...getPageLayoutStyles(theme),
      paddingTop: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2),
      },
    },
    selectIcon: {
      width: 36,
      height: 36,
      padding: 0,
      // [theme.breakpoints.down('xs')]: {
      //   display: 'none'
      // }
    },
    title: {
      color: 'rgba(0, 0, 0, 0.87)',
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
        padding: theme.spacing(1),
      },
    },
    wrapper: {
      minHeight: 'calc(100vh - 290px)',
      [theme.breakpoints.down('xs')]: {
        minHeight: 'calc(100vh - 230px)',
        padding: theme.spacing(0),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
    },
    emptyMessage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'rgba(0, 0, 0, 0.38)',
      fontWeight: 'normal',
      minHeight: 'calc(100vh - 290px)',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
        minHeight: 'calc(100vh - 230px)',
      },
    },
    isInfoOpen: {
      minHeight: 'calc(100vh - 390px)',
      [theme.breakpoints.down('xs')]: {
        minHeight: 'calc(100vh - 340px)',
      },
    },
    searchAndFilters: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: theme.spacing(0, 0, 3),
      [theme.breakpoints.down('xs')]: {
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        margin: theme.spacing(2, 0),
        flexDirection: 'column-reverse',
        '& > *:first-child': {
          width: '100% !important',
          margin: theme.spacing(2, 0, 0),
        },
      },
    },
    search: {
      width: '40%',
      minWidth: 150,
      marginRight: theme.spacing(2),
    },
    periodFilter: {
      // minWidth: 325
    },
    caption: {
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 2,
      overflow: 'hidden',
      wordBreak: 'break-all',
      textOverflow: 'ellipsis',
    },
    captionLarge: {
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '0.1px',
      fontWeight: 500,
    },
    captionSmall: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '22px',
      letterSpacing: '0.25px',
      color: grays.gray2,
    },
    captionMedium: {
      fontSize: 15,
      lineHeight: '24px',
      letterSpacing: '0.15px',
      fontWeight: 400,
    },
    arrowIcon: {
      textAlign: 'right',
      marginLeft: 'auto',
    },
    itemWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'nowrap',
    },
    divider: {
      margin: theme.spacing(0, 5),
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(0, 2.5),
      },
      width: 2,
      backgroundColor: grays.gray5,
      height: '48px',
    },
  }),
)

interface PreviousTasksProps {
  screen: IPreviousTasksUserStepScreen
  template: ITaskTemplate
  posCode: string
  onReadyChange: (isReady: boolean) => void
}

export const PreviousTasks: React.FC<PreviousTasksProps> = ({
  screen,
  template: rootTemplate,
  posCode,
  onReadyChange,
}) => {
  const classes = useStyles()
  const api = useContext(ApiContext)

  const [selectedItemCode, setSelectedItemCode] = useState<string | null>(null)
  const [isShowInfo, setIsShowInfo] = useState<boolean>(!!screen.listCaption)
  const [viewMode, setViewMode] = useState<SessionViewMode>('all')
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    onReadyChange(true)
  }, [])

  const listData = useAsync(async () => {
    const isFilterByTemplateVersionCode = screen.taskFilter === 'MatchByTemplateVersionCode'
    const visitTasks = await api.tasks.getTasks3({
      period: viewModeToPeriod(viewMode),
      pointOfSaleCode: posCode,
      templateCode: rootTemplate.code,
      templateVersionCode: isFilterByTemplateVersionCode ? rootTemplate.version.code : undefined,
    })

    return visitTasks
  }, [posCode, screen, rootTemplate, viewMode])

  const list = useMemo(() => {
    if (!listData.value) return []
    if (!screen.search) return listData.value
    const filtered = listData.value.filter((propContext) => {
      const searchData = screen.search!.propertyNames.map<string>((propertyName) =>
        getPropertyAny(propContext, propertyName, ''),
      )
      return searchData.some((searchProperty) => searchProperty.toLowerCase().includes(searchQuery.toLowerCase()))
    })
    return filtered.sort((a, b) => b.date - a.date).slice(0, screen.tasksLimit)
  }, [listData.value, screen.search, searchQuery])

  const onListItemClickHandler = useCallback((itemCode: string) => {
    setSelectedItemCode(itemCode)
  }, [])

  const clearSelectedItemHandler = useCallback(() => {
    setSelectedItemCode(null)
  }, [])

  const handleChangeViewMode = useCallback((newValue: SessionViewMode): void => {
    setSelectedItemCode(null)
    setViewMode(newValue)
  }, [])

  const renderItem = (propContext: IVisitTaskSummary3): JSX.Element => {
    const { task, date } = propContext
    return (
      <Paper
        className={classes.root}
        key={task.code}
        elevation={0}
        onClick={() => {
          onListItemClickHandler(task.code)
        }}
      >
        <div className={classes.itemWrapper}>
          <Typography className={classes.captionLarge}>
            {dateFormat(new Date(date), screen.itemDateFormat ?? 'dd MMMM')}
          </Typography>
          {!!screen.itemCaptions?.length && <div className={classes.divider} />}
          <Grid item xs>
            {screen.itemCaptions?.map((caption) => (
              <div
                className={classnames(
                  classes.caption,
                  {
                    small: classes.captionSmall,
                    medium: classes.captionMedium,
                    large: classes.captionLarge,
                  }[caption.style],
                )}
                key={caption.propertyName}
              >
                {getPropertyAny(propContext, caption.propertyName)}
              </div>
            ))}
          </Grid>
          <div className={classes.arrowIcon}>
            <IconButton edge='end'>
              <SvgIcon
                className={classes.selectIcon}
                component={ChevronRight}
                viewBox='0 0 36 36'
                color='primary'
                fontSize='large'
              />
            </IconButton>
          </div>
        </div>
      </Paper>
    )
  }

  const renderEmptyMessage = (): string => {
    if (listData.error) {
      throw new Error('Ошибка получения предыдущих задач')
    }

    if (listData.loading) return 'Загрузка'

    if (searchQuery) return 'Поиск не дал результатов'

    return 'Предыдущие задачи отсутствуют'
  }

  const renderInfo = (): JSX.Element => {
    return (
      <PreviousTasksInfo
        isShow={isShowInfo}
        onHide={() => {
          setIsShowInfo(false)
        }}
      >
        {screen.listCaption}
      </PreviousTasksInfo>
    )
  }

  if (selectedItemCode && listData.value?.length) {
    return (
      <PreviousTaskView
        renderInfo={renderInfo}
        item={listData.value.find((x) => x.task.code === selectedItemCode)!.task}
        onBack={clearSelectedItemHandler}
      />
    )
  }

  return (
    <Box mt={-3}>
      {renderInfo()}
      <div className={classes.content}>
        {(!!screen.search || !!screen.showDateFilter) && (
          <div className={classes.searchAndFilters}>
            {!!screen.search && (
              <div className={classes.search}>
                <SearchInput //
                  onChange={setSearchQuery}
                  query={searchQuery}
                  placeholder={screen.search.placeholder}
                />
              </div>
            )}
            {!!screen.showDateFilter && (
              <div className={classes.periodFilter}>
                <SessionPeriodSwitcher currentViewMode={viewMode} onChangeViewMode={handleChangeViewMode} />
              </div>
            )}
          </div>
        )}
        <div className={classnames(classes.wrapper, isShowInfo ? classes.isInfoOpen : '')}>
          {list.length ? (
            <div>{list.map((item) => renderItem(item))}</div>
          ) : (
            <Typography
              className={classnames(classes.emptyMessage, isShowInfo ? classes.isInfoOpen : '')}
              variant='h6'
              component='h6'
              align='center'
            >
              {renderEmptyMessage()}
            </Typography>
          )}
        </div>
      </div>
    </Box>
  )
}
