import { IAnswer } from '../../../../../model/answer'
import { checkAnswerPredicate, AnswerPredicate } from '../../../../../model/answer-predicate'
import { IQuestion } from '../../../../../model/question'
import { validateAnswer } from '../../../../execute-survey-control/validate'

interface WithPrecondition {
  precondition?: AnswerPredicate
}

export function getAvailableQuestions<T extends WithPrecondition>(questions: T[], answers: IAnswer[]): T[] {
  const availableQuestions = questions.filter((question) => {
    if (question.precondition && !checkAnswerPredicate(question.precondition, answers)) {
      return false
    }
    return true
  })

  return availableQuestions
}

export function findUnansweredQuestion(
  questions: IQuestion[],
  answers: IAnswer[],
): { message?: string; invalidQuestion?: IQuestion } | undefined {
  for (const question of questions) {
    const answer = answers.find((answer) => answer.questionCode === question.code)
    const validation = validateAnswer(answer, question)
    if (validation.isError) {
      return {
        message: validation.message,
        invalidQuestion: question,
      }
    }
  }
}
