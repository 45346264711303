import React, { useEffect, useState } from 'react';

import { 
  Select,
  makeStyles,
  Theme,
  createStyles,
  MenuItem,
  Typography,
  Checkbox,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";

import { ItemCard } from '../../../../tasks/template-tasks/composite-screen/item-card';
import { TableSelect } from '../../../../tasks/template-tasks/composite-screen/table-item/single-select-cell';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      margin: theme.spacing(1, 0),
      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
      width: '100%'
    }
  }),
)

interface IProps {
  itemsList: string[] | undefined
  displayName: string
  isMultiSelect: boolean
  isRequired: boolean
  onChange: (value: string) => void 
}

const ProblemSelectDetailsScreenItem: React.FC<IProps> = ({itemsList, displayName, isMultiSelect, isRequired, onChange}) => {
  const { t } = useTranslation('screen-items')
  const classes = useStyles()
  const [multiItems, setMultiItems] = useState<string[]>([])
  const [singleItem, setSingleItem] = useState<string>('')

  const handleSingleUpdate = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setSingleItem(event.target.value as string)
    onChange(event.target.value as string);
  }

  const handleMultiUpdate = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const res = event.target.value as string[]
    setMultiItems(res)
    onChange(res.join('$%'));
  }

  useEffect( () => {
    setSingleItem('')
    setMultiItems([])

    return () => {
      onChange('')
    }
  }, [itemsList])

  if (!itemsList || itemsList.length === 0) {
    return (
      <ItemCard
        isError={isRequired && !(multiItems.length > 0)}
        label={<Typography variant='inherit'>{displayName}</Typography>}
      >
        <Typography variant='body1' color='textSecondary'>Список элементов пуст</Typography>
      </ItemCard>
    )
  }

  return (
    <>
      { isMultiSelect ?
        <ItemCard
        isError={isRequired && !(multiItems.length > 0)}
        label={<Typography variant='inherit'>{displayName}</Typography>}
      >
        <Select
          className={classes.select}
          displayEmpty
          multiple={true}
          input={<TableSelect color='primary'/>}
          onChange={handleMultiUpdate}
          value={multiItems}
          renderValue={(val) => {
            const selected = val as string[]
            return selected.length === 0 ? t('choose') : selected.join(', ')
          }}
        >
          {itemsList!.map( item => (
            <MenuItem key={item} value={item}
            >
              <Checkbox checked={multiItems!.includes(item)} color='primary'/>
              {item}
            </MenuItem>
          ))}
        </Select>
      </ItemCard>

      :
      
      <ItemCard
        isError={isRequired && !singleItem}
        label={<Typography variant='inherit'>{displayName}</Typography>}
      >
        <Select
          className={classes.select}
          displayEmpty
          multiple={false}
          input={<TableSelect color='primary'/>}
          onChange={handleSingleUpdate}
          value={singleItem}
          renderValue={(val) => {
            return val ? singleItem : t('choose')
          }}
        >
          {itemsList!.map( item => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </ItemCard>
      } 
    </>
  );
};

export default ProblemSelectDetailsScreenItem;