/* eslint-disable import/no-webpack-loader-syntax */

import * as parser from '!peggy-loader!./fuzzy-ast-predicate.grammar.pegjs'
import type { AnyExpr } from '!peggy-loader!./fuzzy-ast-predicate.grammar.pegjs'

export function getParseTree(text: string): AnyExpr {
  return parser.parse(text)
}

export type {
  AnyExpr,
  LogicExpr,
  RelExpr,
  ValueExpr,
  PropPath,
  Identifier,
  TokenLocation,
} from '!peggy-loader!./fuzzy-ast-predicate.grammar.pegjs'
