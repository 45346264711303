/* eslint-disable */
// https://github.com/jakearchibald/safari-14-idb-fix
/**
 * Work around Safari 14 IndexedDB open bug.
 *
 * Safari has a horrible bug where IDB requests can hang while the browser is starting up. https://bugs.webkit.org/show_bug.cgi?id=226547
 * The only solution is to keep nudging it until it's awake.
 */
export default function whenIdbReady(): Promise<void> {
  const isSafari =
    !(window.navigator as any).userAgentData &&
    /Safari\//.test(window.navigator.userAgent) &&
    !/Chrom(e|ium)\//.test(window.navigator.userAgent)

  // No point putting other browsers or older versions of Safari through this mess.
  if (!isSafari || !(window.indexedDB as any).databases) return Promise.resolve()

  let intervalId: number

  return new Promise<void>((resolve) => {
    let done = false
    let count = 0
    const exit = () => done ? undefined : (done = true, resolve())
    const tryIdb = () => {
      if (count > 0) {
        console.warn(`nudging idb (attempt ${count})`)
      }
      (window.indexedDB as any).databases().finally(exit)
      count++;
    }
    intervalId = window.setInterval(tryIdb, 100)
    tryIdb()
  }).finally(() => window.clearInterval(intervalId))
}
