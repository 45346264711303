/// <reference path="./types.d.ts" />

/* eslint-disable import/no-webpack-loader-syntax */

import * as parser from '!peggy-loader!./ast-predicate.grammar.pegjs'
import type { Expr, ValueExpr, PropPath } from '!peggy-loader!./ast-predicate.grammar.pegjs'
import { ASTPredicate, Expression } from '../../../../../model/ast-predicate'

export function parse(text: string): ASTPredicate | null {
  const result = parser.parse(text)

  if (!result) {
    return null
  }

  function transform(expr: Expr): ASTPredicate {
    switch (expr.kind) {
      case 'logic_expr':
        return {
          $type: 'PMI.FACE.BDDM.Extensions.Classes.PredicatesJunction',
          junctionKind: expr.op,
          useParens: expr.parens,
          left: transform(expr.lhs),
          right: transform(expr.rhs),
        }

      case 'rel_expr':
        return {
          $type: 'PMI.FACE.BDDM.Extensions.Classes.BinaryPredicate',
          operation: expr.op,
          useParens: expr.parens,
          left: transformValue(expr.lhs),
          right: transformValue(expr.rhs),
        }
    }
  }

  function transformValue(expr: ValueExpr | PropPath | Expr): Expression {
    switch (expr.kind) {
      case 'logic_expr':
      case 'rel_expr':
        return {
          $type: 'PMI.FACE.BDDM.Extensions.Classes.PredicateExpression',
          predicate: transform(expr),
        }

      case 'null':
        return {
          $type: 'PMI.FACE.BDDM.Extensions.Classes.NullLiteralExpression',
        }

      case 'bool':
        return {
          $type: 'PMI.FACE.BDDM.Extensions.Classes.BooleanLiteralExpression',
          value: expr.value,
        }

      case 'string':
        return {
          $type: 'PMI.FACE.BDDM.Extensions.Classes.StringLiteralExpression',
          value: expr.value,
        }

      case 'number':
        return {
          $type: 'PMI.FACE.BDDM.Extensions.Classes.NumberLiteralExpression',
          value: expr.value,
        }

      case 'string_array':
        return {
          $type: 'PMI.FACE.BDDM.Extensions.Classes.StringArrayLiteralExpression',
          values: expr.values,
        }

      case 'number_array':
        return {
          $type: 'PMI.FACE.BDDM.Extensions.Classes.NumberArrayLiteralExpression',
          values: expr.values,
        }

      case 'prop_path':
        return {
          $type: 'PMI.FACE.BDDM.Extensions.Classes.PropertyNameExpression',
          propertyName: join(
            '.',
            expr.path.map((x) => x.name),
          ),
        }
    }
  }

  return transform(result)
}

function join(sep: string, values: string[]): string {
  return values.join(sep)
}
