import React, { useRef, useEffect } from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { useVirtualizer } from '@tanstack/react-virtual'

import { IPointOfSale } from '../../../model/pos'
import { StoreItemMap } from './store-item-map'

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    list: {
      position: 'absolute',
      zIndex: 1299,
      top: 0,
      left: 0,
      padding: '24px 24px 30px 24px',
      width: '318px',
      height: '100%',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '0',
      },
    },
  })
})

interface IStoreListMap {
  stores: IPointOfSale[]
  selectStoreCodes: string[]
  onSelect: (code: string[]) => void
  setFocus: (code: string | undefined) => void
}

export const StoreListMap: React.FC<IStoreListMap> = ({ stores, selectStoreCodes, onSelect, setFocus }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const classes = useStyles()

  const rowVirtualizer = useVirtualizer({
    count: stores.length,
    getScrollElement: () => containerRef.current,
    estimateSize: () => 147,
    overscan: 5,
    enableSmoothScroll: true,
  })

  const scrollTo = (): void => {
    const index = stores.findIndex((item) => item.code === selectStoreCodes.at(0))
    rowVirtualizer.scrollToIndex(index, {
      align: 'center',
    })
  }

  useEffect(() => {
    if (selectStoreCodes.at(0)) {
      scrollTo()
    }
  }, [selectStoreCodes])

  return (
    <div id='listWrap' className={classes.list} ref={containerRef}>
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualItem) => {
          const item = stores[virtualItem.index]
          const isSelect = selectStoreCodes.includes(item.code)
          return (
            <div
              key={virtualItem.key}
              data-code={item.code}
              data-index={virtualItem.index}
              ref={virtualItem.measureElement}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${virtualItem.start}px)`,
              }}
            >
              {
                <StoreItemMap
                  store={item}
                  isSelect={isSelect}
                  onClick={() => {
                    if (!isSelect) {
                      setFocus(item.code)
                      onSelect([item.code])
                    }
                  }}
                />
              }
            </div>
          )
        })}
      </div>
    </div>
  )
}
