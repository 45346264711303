import { SupervisedFieldPositionRoleStore } from '../../data/schema'
import { Code } from '../../model/base'
import { ISupervisedFieldPositionRole } from '../../model/supervised-field-position-role'
import ISupervisedService from '../supervised-service-api'
import { LocalStorageBaseService } from './local-storage-base-service'

export default class LocalStorageSupervisedService extends LocalStorageBaseService implements ISupervisedService {
  private static readonly __className = 'LocalStorageSupervisedService'

  async getAllSupervisedFieldPositionRole(): Promise<ISupervisedFieldPositionRole[]> {
    return this._storage.getAll<ISupervisedFieldPositionRole>(SupervisedFieldPositionRoleStore)
  }

  async getSupervisedRoleByCode(roleCode: Code): Promise<ISupervisedFieldPositionRole | undefined> {
    return this._storage.getByKey<ISupervisedFieldPositionRole>(SupervisedFieldPositionRoleStore, roleCode)
  }
}
