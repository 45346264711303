import React, { useContext, useEffect, useRef } from 'react'

import { Layout } from '../../layout'
import { ITaskExecutionScope } from '../../model/task-execution'
import { ProfileContext, ConfigContext, ApiContext } from '../../providers'
import { ExecutionProcessUnit } from '../../services-admin/execution-process-service-api'
import { useAsyncError } from '../_common/hooks/useAsyncError'
import { useAsyncRetryPromise } from '../_common/hooks/useAsyncRetry'
import {
  createExecutionStateStore,
  ExecutionStateStoreApi,
  ExecutionStateStoreContext,
} from '../tasks/nested/execution-state'
import { LocalContextServiceContext } from '../tasks/nested/local-context'
import { IScriptTaskContext, ScriptTaskContext } from '../tasks/script-tasks/script-task-context'
import { InMemoryProcessContextService } from './in-memory-process-context-service'

interface InMemoryProcessContextProps {
  children: React.ReactNode
  initialState?: Record<string, unknown>
  processUnit: ExecutionProcessUnit
  rootRecordName: string
}

export const InMemoryProcessContext: React.FC<InMemoryProcessContextProps> = (props) => {
  const { initialState, processUnit, rootRecordName, children } = props
  useEffect(() => {
    console.log('InMemoryProcessContext mount', props)
  }, [])

  const api = useContext(ApiContext)
  const serviceRef = useRef<InMemoryProcessContextService>()
  const profileContext = useContext(ProfileContext)
  const config = useContext(ConfigContext)
  const taskStateStore = useRef<ExecutionStateStoreApi>()

  if (!taskStateStore.current) {
    taskStateStore.current = createExecutionStateStore({
      taskCode: '',
      currentStep: 0,
      subProcesses: {},
    })
  }

  const ops = useAsyncRetryPromise(async () => {
    const state = serviceRef.current?.state
    const fullScope = (state?.fullScope as ITaskExecutionScope | undefined) ?? { task: { surveys: {} } }
    const context = {
      fieldPositionRole: profileContext.value.fieldPositionRole,
      employee: profileContext.value.employee,
      profileCode: profileContext.value.profile.code,
      participantProfile: profileContext.value.participantProfile,
      businessParameters: profileContext.businessParameters,
      userGroups: profileContext.userGroups,
      apiUrl: config.config.apiUrl,
      processUnit,
      scope: fullScope.task ?? {},
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      surveys: (fullScope.task as any).surveys ?? {},
      ...serviceRef.current?.state,
    }
    return context as IScriptTaskContext
  }, [])

  if (!serviceRef.current) {
    serviceRef.current = new InMemoryProcessContextService(
      ops.retry,
      rootRecordName,
      initialState,
      config.config.defaultCodeSpace,
      api,
    )
  }
  useAsyncError(ops.error)
  if (ops.loading && !ops.value) {
    return <Layout></Layout>
  }
  const context = ops.value!

  return (
    <ExecutionStateStoreContext.Provider value={taskStateStore.current}>
      <LocalContextServiceContext.Provider value={serviceRef.current}>
        <ScriptTaskContext.Provider value={context}>{children}</ScriptTaskContext.Provider>
      </LocalContextServiceContext.Provider>
    </ExecutionStateStoreContext.Provider>
  )
}
