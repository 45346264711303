import type { IEntityReference, IEntityVersion, ISyncEntity } from './base'
import type {
  DepositTransactionReason,
  FaceSalesExpertTaskResult,
  FieldForceTaskCancelationReason,
  FieldForceVisitEscalationReason,
  IDictionaryItem,
  OutOfStockReasonDictionaryItem,
  PPOSMTypeItem,
} from './dictionary-item'

export type IDictionary =
  | IFaceSalesExpertTaskResultDictionary
  | IFaceFieldForceTaskCancelationReasonDictionary
  | IOutOfStockReasonDictionary
  | IPPOSMTypeDictionary

export interface IDictionaryBase<T extends IDictionaryItem = IDictionaryItem> extends ISyncEntity {
  $type: string
  name: string
  description?: string
  version: IEntityVersion
  items: T[]
}

export interface IFaceSalesExpertTaskResultDictionary extends IDictionaryBase {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceSalesExpertTaskResultDictionary'
  items: FaceSalesExpertTaskResult[]
}

export interface IFaceFieldForceTaskCancelationReasonDictionary extends IDictionaryBase {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceFieldForceTaskCancelationReasonDictionary'
  items: FieldForceTaskCancelationReason[]
}

export interface IOutOfStockReasonDictionary extends IDictionaryBase {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.OutOfStockReasonDictionary'
  items: OutOfStockReasonDictionaryItem[]
}

export interface IPPOSMTypeDictionary extends IDictionaryBase {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.PPOSMTypeDictionary'
  items: PPOSMTypeItem[]
}

export interface IFieldForceVisitEscalationReasonDictionary extends IDictionaryBase {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.FieldForceVisitEscalationReasonDictionary'
  items: FieldForceVisitEscalationReason[]
}

export const DepositTransactionReasonDictionaryType =
  'PMI.FACE.BDDM.Extensions.Classes.DepositTransactionReasonDictionary'

export interface DepositTransactionReasonDictionary extends IDictionaryBase {
  $type: typeof DepositTransactionReasonDictionaryType
  items: DepositTransactionReason[]
}

export const DepositTransactionReasonDictionaryReferenceType =
  'PMI.FACE.BDDM.Extensions.Classes.DepositTransactionReasonDictionaryReference'

export interface DepositTransactionReasonDictionaryReference extends IEntityReference {
  $type: typeof DepositTransactionReasonDictionaryReferenceType
}
