import type { IHttpClient } from '../infrastructure/http-client-factory'
import type { EntityKey, IEntity, IEntityReference } from '../model/base'
import type { IDTETaskTemplate, IDTETaskTemplateUnit } from '../model/dte-task-template'
import type { ITaskResultScreen } from '../model/task-result-screen'
import { cleanObjectOfEmptyStrings } from '../utils'
import type {
  DTETaskTemplateUpdateDTO,
  IDTETaskTemplatesService,
  NewVersionParams,
} from './dte-task-templates-service-api'
import type { IExecutionProcessSource } from './task-templates-service-api'

export class DTETaskTemplatesService implements IDTETaskTemplatesService {
  constructor(private readonly _http: IHttpClient) {}

  async getCurrentTaskTemplateVersions(): Promise<IDTETaskTemplate[]> {
    const response = await this._http.request({
      method: 'GET',
      url: `dte-task-templates/current-versions`,
    })

    return response.data
  }

  async getTaskTemplateVersions(templateKey: EntityKey): Promise<IDTETaskTemplate[]> {
    const response = await this._http.request({
      method: 'GET',
      url: `dte-task-templates/${templateKey}/versions`,
    })

    return response.data
  }

  async getTaskTemplate(templateKey: EntityKey): Promise<IDTETaskTemplateUnit> {
    const response = await this._http.request({
      method: 'GET',
      url: `dte-task-templates/${templateKey}`,
    })

    return response.data
  }

  async createTaskTemplate(data: DTETaskTemplateUpdateDTO): Promise<EntityKey> {
    const response = await this._http.request({
      method: 'POST',
      url: `dte-task-templates`,
      data: cleanTaskTemplateFields(data),
    })

    return response.data
  }

  async updateTaskTempate(templateKey: EntityKey, data: DTETaskTemplateUpdateDTO): Promise<void> {
    const response = await this._http.request({
      method: 'PUT',
      url: `dte-task-templates/${templateKey}`,
      data: cleanTaskTemplateFields(data),
    })

    return response.data
  }

  async copyTaskTemplate(templateKey: EntityKey): Promise<EntityKey> {
    const response = await this._http.request({
      method: 'POST',
      url: `dte-task-templates/${templateKey}/copy`,
    })

    return response.data
  }

  async exportTaskTemplate(templateKey: EntityKey): Promise<IEntity[]> {
    const response = await this._http.request({
      method: 'GET',
      url: `dte-task-templates/${templateKey}/export`,
    })

    return response.data
  }

  async importTaskTemplate(unit: IEntity[]): Promise<void> {
    const response = await this._http.request({
      method: 'POST',
      url: `dte-task-templates/import`,
      data: unit,
    })

    return response.data
  }

  async createNewVersion(templateKey: EntityKey, params: NewVersionParams): Promise<EntityKey> {
    const response = await this._http.request({
      method: 'POST',
      url: `dte-task-templates/${templateKey}/new-version`,
      data: params,
    })

    return response.data
  }

  async updateExecutionProcess(templateKey: EntityKey, data: IExecutionProcessSource): Promise<IEntityReference> {
    const response = await this._http.request({
      method: 'POST',
      url: `dte-task-templates/${templateKey}/process`,
      data: data,
    })

    return response.data
  }

  async createExecutionSubprocess(templateKey: EntityKey, data: IExecutionProcessSource): Promise<IEntityReference> {
    const response = await this._http.request({
      method: 'POST',
      url: `dte-task-templates/${templateKey}/subprocesses`,
      data: data,
    })

    return response.data
  }

  async updateExecutionSubprocess(
    templateKey: EntityKey,
    processKey: EntityKey,
    data: IExecutionProcessSource,
  ): Promise<void> {
    const response = await this._http.request({
      method: 'PUT',
      url: `dte-task-templates/${templateKey}/subprocesses/${processKey}`,
      data: data,
    })

    return response.data
  }

  async updateTaskResultScreen(templateKey: EntityKey, data: ITaskResultScreen): Promise<IEntityReference> {
    const response = await this._http.request({
      method: 'POST',
      url: `dte-task-templates/${templateKey}/result-screen`,
      data: data,
    })

    return response.data
  }
}

function cleanTaskTemplateFields(data: DTETaskTemplateUpdateDTO): DTETaskTemplateUpdateDTO {
  const result = { ...data }
  if (result.pointsAccrualRules) {
    if (result.pointsAccrualRules.length === 0) {
      delete result.pointsAccrualRules
    } else {
      result.pointsAccrualRules = result.pointsAccrualRules.map((rule) => {
        return {
          $type: rule.$type,
          amount: rule.amount,
          ...cleanObjectOfEmptyStrings(rule),
        }
      })
    }
  }
  return result
}
