import { DateTime } from '../model/common'

export interface IMediaReceiveSetting {
  deleteAfter?: DateTime
}

export type MediaReceiveMap = Map<string, IMediaReceiveSetting>
export type MediaReceiveTuple = Array<[string, IMediaReceiveSetting]>
export type MediaReceive = MediaReceiveTuple | MediaReceiveMap

export function uniqueMediaReceive(
  array: MediaReceive,
): MediaReceiveTuple {
  if (array instanceof Map) {
    return Array.from(array)
  }
  if (array != null && array.length > 1) {
    return Array.from(new Map(array))
  }
  return array
}

export function getMediaDeleteTime(daysOfLifeMedia: string): number {
  return Date.now() + parseFloat(daysOfLifeMedia) * 24 * 60 * 60 * 1000
}