import { ASTPredicate, Expression, OperationKind } from '../../../../model/ast-predicate'

export function formatAstPredicate(ast: ASTPredicate | null | undefined): string {
  if (!ast) {
    return ''
  }

  function format(expr: ASTPredicate): string {
    switch (expr.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.PredicatesJunction': {
        const left = format(expr.left)
        const right = format(expr.right)
        const op = expr.junctionKind.toLowerCase()
        const enquote = wrapParens(expr.useParens ?? false)
        return enquote(`${left} ${op} ${right}`)
      }

      case 'PMI.FACE.BDDM.Extensions.Classes.BinaryPredicate': {
        const left = formatValue(expr.left)
        const right = formatValue(expr.right)
        const op = formatCompareOp(expr.operation)
        const enquote = wrapParens(expr.useParens ?? false)
        return enquote(`${left} ${op} ${right}`)
      }
    }
  }

  function formatValue(expr: Expression): string {
    switch (expr.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.NullLiteralExpression':
        return 'null'
      case 'PMI.FACE.BDDM.Extensions.Classes.BooleanLiteralExpression':
        return String(expr.value)
      case 'PMI.FACE.BDDM.Extensions.Classes.StringLiteralExpression':
        return `'${expr.value.replaceAll(`\\`, `\\\\`).replaceAll(`'`, `\\'`)}'`
      case 'PMI.FACE.BDDM.Extensions.Classes.NumberLiteralExpression':
        return String(expr.value)
      case 'PMI.FACE.BDDM.Extensions.Classes.StringArrayLiteralExpression':
        return wrapParens(true)(expr.values.map((x) => `'${x}'`).join(', '))
      case 'PMI.FACE.BDDM.Extensions.Classes.NumberArrayLiteralExpression':
        return wrapParens(true)(expr.values.map((x) => String(x)).join(', '))
      case 'PMI.FACE.BDDM.Extensions.Classes.PropertyNameExpression':
        return expr.propertyName
      case 'PMI.FACE.BDDM.Extensions.Classes.PredicateExpression':
        return `(${formatAstPredicate(expr.predicate)})`
    }
  }

  function formatCompareOp(operation: OperationKind): string {
    switch (operation) {
      case 'EQUALS':
        return '='
      case 'NOT_EQUAL':
        return '!='
      case 'LESS':
        return '<'
      case 'LESS_OR_EQUAL':
        return '<='
      case 'MORE':
        return '>'
      case 'MORE_OR_EQUAL':
        return '>='
      case 'LIKE':
        return 'like'
      case 'NOT_LIKE':
        return 'not like'
      case 'IN':
        return 'in'
      case 'NOT_IN':
        return 'not in'
      case 'STARTS_WITH':
        return 'starts with'
      case 'EXISTS':
        return 'exists'
      case 'NOT_EXISTS':
        return 'not exists'
    }
  }

  return format(ast)
}

function wrapParens(cond: boolean) {
  return function enquote(text: string) {
    if (cond) {
      return `(${text})`
    } else {
      return text
    }
  }
}
