import React, { useContext } from 'react'

import { Container, createStyles, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { AuthContext, ProfileContext } from '../providers'
import { formatFio } from '../utils/fio'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userInfo: {
      // height: 100,
      paddingTop: theme.spacing(1.5),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    userAvatar: {
      marginBottom: theme.spacing(3),
    },
    userName: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
      letterSpacing: '0.15px',
    },
    position: {
      letterSpacing: 0.25,
      fontSize: 14,
      color: 'inherit',
    },
  }),
)

const UserInfo: React.FC = () => {
  const classes = useStyles()
  const auth = useContext(AuthContext)
  const profileContextContext = useContext(ProfileContext)
  const profileContext = profileContextContext.value
  const userName = auth.currentUserName

  const contact = profileContext?.employee?.contact ?? profileContext?.participantProfile?.contact

  let fio
  if (contact) {
    fio = formatFio(contact.name, contact.surname, contact.middleName)
  } else {
    fio = userName
  }
  if (!fio) {
    fio = ' - '
  }

  return (
    <Container className={classes.userInfo}>
      {/* <Avatar className={classes.userAvatar}>{employee.firstName[0].concat(employee.surname?.[0] ?? '')}</Avatar> */}
      <Typography className={classes.userName} variant='h5' component='h5'>
        {fio}
      </Typography>
      <span className={classes.position}>{profileContext?.profile.name}</span>
    </Container>
  )
}

export default React.memo(UserInfo)
