import { flatten } from 'lodash'

import {
  ContractTermAssignmentStore, ContractTermAssignmentStore_pos,
  ContractTermStore, MetricStore, MetricStore_type,
  POSStore, PPOSMStore, PPOSMStore_pos,
  ProductMatrixAssignmentStore,
  ProductMatrixAssignmentStore_pos,
  ProductMatrixStore
} from '../../data/schema'
import { Code } from '../../model/base'
import { IContractTerm } from '../../model/contract-term'
import { IContractTermAssignment } from '../../model/contract-term-assignment'
import { IMetric } from '../../model/metric'
import { IPointOfSale } from '../../model/pos'
import { IPPOSM } from '../../model/pposm'
import { IProductMatrix } from '../../model/product-matrix'
import { IProductMatrixAssignment } from '../../model/product-matrix-assignment'
import { IPointOfSaleService, SearchPosRequest } from '../pos-service-api'
import { LocalStorageBaseService } from './local-storage-base-service'

function buildPosAddress(pos: IPointOfSale): string {
  return !pos.address?.fullAddress || !pos.address?.city ? '' : pos.address.fullAddress + pos.address.city
}

export default class LocalStoragePOSService extends LocalStorageBaseService implements IPointOfSaleService
{
  private static readonly __className = 'LocalStoragePosService'

  async getPos(posCode: Code): Promise<IPointOfSale | null> {
    return await this._getPos(posCode, this._storage)
  }

  async searchPos(searchPosRequest?: SearchPosRequest): Promise<IPointOfSale[]> {
    const predicates: Array<(pos: IPointOfSale) => boolean> = []

    if (searchPosRequest?.searchQuery) {
      const queryString = searchPosRequest.searchQuery.toLowerCase()
      predicates.push(
        (pos) =>
          Boolean(pos.associatedLegalEntities && 
            pos.associatedLegalEntities.length > 0 && 
            pos.associatedLegalEntities[0].legalEntity?.name?.toLowerCase().includes(queryString)) ||
          pos.name.toLowerCase().includes(queryString) ||
          pos.code.toLowerCase().includes(queryString) ||
          buildPosAddress(pos).toLowerCase().includes(queryString)
      )
    }

    if (searchPosRequest?.positionRoleCoverage != null) {
      predicates.push(
        (pos) => pos.positionRoleCoverage?.includes(searchPosRequest.positionRoleCoverage!) ?? false
      )
    }

    let result: Promise<IPointOfSale[]>

    if (predicates.length > 0) {
      const wherePredicate = (pos: IPointOfSale): boolean => predicates.every((p) => p(pos))
      result = this._storage.getWhere(POSStore, wherePredicate)
    } else {
      result = this._storage.getByKeyRange<IPointOfSale>(POSStore, null)
    }

    return await result
  }

  async getProductMatrices(posCode: Code, dateTime: Date): Promise<IProductMatrix[]> {
    return this._storage.execute(
      [ProductMatrixStore, ProductMatrixAssignmentStore],
      async tx => {
        let assignments = await tx.getByIndexRange<IProductMatrixAssignment>(
          ProductMatrixAssignmentStore, ProductMatrixAssignmentStore_pos, ['=', posCode]
        )
        assignments = assignments.filter(a => a.isActive && a.startDate < dateTime.getTime() && (a.endDate == null || a.endDate > dateTime.getTime()))
        if (assignments.length > 0) {
          const matrixCodes = new Set(assignments.map(a => a.matrix.code))
          const matrices = await tx.getByKeys<IProductMatrix>(ProductMatrixStore, Array.from(matrixCodes))
          return matrices.filter(m => m.isActive)
        } else {
          return []
        }
      },
      'r'
    )
  }

  async getContractTerms(posCode: Code, dateTime: Date): Promise<IContractTerm[]> {
    return this._storage.execute(
      [ContractTermStore, ContractTermAssignmentStore],
      async tx => {
        const assignments = await tx.getByIndexRange<IContractTermAssignment>(
          ContractTermAssignmentStore, ContractTermAssignmentStore_pos, ['=', posCode]
        )
        const contractTermItems = flatten(
          assignments.filter(a => a.isActive).map(a => a.assignedTerms)
        ).filter(a => a.contractTerm?.code != null && a.isActive)
        if (contractTermItems.length > 0) {
          const contractTermCodes = new Set(contractTermItems.map(a => a.contractTerm.code))
          const contractTerms = await tx.getByKeys<IContractTerm>(ContractTermStore, Array.from(contractTermCodes))
          return contractTerms.filter(m => m.isActive)
        } else {
          return []
        }
      },
      'r'
    )
  }

  async getCheckoutCount(posCode: Code, dateTime: Date): Promise<number | undefined> {
    let metric: IMetric | undefined
    const selector = this._storage.selectByIndexRange<IMetric>(
      MetricStore, MetricStore_type, ['=', 'PMI.BDDM.Transactionaldata.Metrics.POSCheckOutCount']
    )
    for await(const m of selector) {
      if (m.periodStart > dateTime.getTime() || m.periodEnd < dateTime.getTime()) {
        continue
      }
      if (m.subjects.some(s => s.code === posCode)) {
        metric = m;
        break;
      }
    }
    return metric?.value as number
  }

  async getModelStoreLevel (posCode: Code, dateTime: Date): Promise<string | undefined> {
    let metric: IMetric | undefined
    const selector = this._storage.selectByIndexRange<IMetric>(
      MetricStore, MetricStore_type, ['=', 'PMI.BDDM.Transactionaldata.Metrics.POSModelStoreLevel']
    )
    for await(const m of selector) {
      if (m.periodStart > dateTime.getTime() || m.periodEnd < dateTime.getTime()) {
        continue
      }
      if (m.subjects.some(s => s.code === posCode)) {
        metric = m;
        break;
      }
    }
    return metric?.value as string
  }

  async getPPOSM(pposmCode: Code): Promise<IPPOSM | undefined> {
    return this._storage.getByKey<IPPOSM>(PPOSMStore, pposmCode)
  }

  async searchPPOSM(request: { posCode: Code }): Promise<IPPOSM[]> {
    return this._storage.getByIndexRange(PPOSMStore, PPOSMStore_pos, ['=', request.posCode])
  }
}
