import React, { useMemo } from 'react'

import { makeStyles, Typography } from '@material-ui/core'

import { ILabelScreenItem } from '../../../../../model/screen-item'
import { formatTemplateString } from '../../../../../utils/format-template-string'
import { useIsSmall } from '../../../../_common/hooks/useIsSmall'
import { getContextProperty } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 18,
    lineHeight: '23px',
    fontWeight: 600,
    letterSpacing: '0.15px',
    padding: '16px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '14px 16px',
      fontSize: 16,
    },
  },
}))

interface Props {
  item: ILabelScreenItem
  value: string
}

export const LabelItem: React.FC<Props> = ({ item, value }) => {
  const { defaultFont, mobileFont } = item

  const classes = useStyles()
  const isSmall = useIsSmall()
  const mobileStyles = useMemo(() => (isSmall ? mobileFont : undefined), [isSmall, mobileFont])
  return (
    <div className={classes.label} style={{ ...defaultFont, ...mobileStyles }}>
      <Typography variant='inherit'>{value}</Typography>
    </div>
  )
}

interface LabelItemControlProps {
  item: ILabelScreenItem
}
export const LabelItemControl: React.FC<LabelItemControlProps> = ({ item }) => {
  const context = useScriptTaskContext()
  const value = formatTemplateString(item.text, (prop) => getContextProperty(context, prop, ``))
  return <LabelItem item={item} value={value}></LabelItem>
}
