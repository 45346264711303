import { IDisposable } from './disposable'

type JobAction = () => Promise<void>

export class Job implements IDisposable {
  private readonly _name: string

  private _interval: number
  private _action: JobAction | null = null
  private _timerId: number | null = null
  private _running = false

  constructor(
    name: string,
    interval: number,
    action: JobAction,
    start = false
  ) {
    this._name = name
    this._interval = interval
    this._action = action
    if (start) {
      this.resume()
    }
  }

  public get interval(): number {
    return this._interval
  }

  public set interval(value: number) {
    this._interval = value
  }

  public execute(): void {
    void this._do()
  }

  private async _do(): Promise<void> {
    if (this._action == null) {
      return
    }
    if (this._running) {
      return
    }
    console.debug(`Running job '${this._name}'`)
    this._running = true
    try {
      await this._action()
    } catch (e) {
      console.error(`Error executing job '${this._name}'`, e)
    } finally {
      this._running = false
    }

    if (this._interval < 0) {
      this._action = null
    } else {
      this.resume()
    }
  }

  public suspend(): void {
    if (this._timerId != null) {
      window.clearTimeout(this._timerId)
      this._timerId = null
    }
  }

  public resume(): void {
    this.suspend()
    if (this._action != null) {
      this._timerId = window.setTimeout(() => void this._do(), Math.max(this._interval, 0))
    }
  }

  public dispose(): void {
    this._action = null
    this.suspend()
  }
}
