import React, { useContext, useEffect } from 'react'

import { Box } from '@material-ui/core'
import { useParams, useLocation, useNavigate, Routes, Route } from 'react-router-dom'
import { useAsync } from 'react-use'

import { EmptyMessage } from '../../components'
import { Layout } from '../../layout'
import { PageContent } from '../../layout/page-content'
import { TitleBar } from '../../layout/title-bar'
import { Code } from '../../model/base'
import { ITask } from '../../model/task'
import { ApiContext } from '../../providers'
import { LocalContextProvider } from '../tasks/nested/local-context'
import { NestedProcessPage } from '../tasks/nested/nested-process'
import { DteTaskDialog } from './dte-task-dialog'

interface DteTaskPageProps {
  taskCode: Code
}

export const DteTaskPage: React.FC = () => {
  const params = useParams() as unknown as DteTaskPageProps
  const location = useLocation()
  const navigate = useNavigate()
  const api = useContext(ApiContext)

  useEffect(() => {
    console.log('DteTaskPage mount', params)
  }, [])

  const goBack = (): void => {
    if (location.state && typeof location.state === 'object') {
      const state = location.state as { returnUrl: unknown } // TODO: remove this cast in typescript 4.9
      if (typeof state.returnUrl === 'string') {
        return navigate(state.returnUrl)
      }
    }
    return navigate('/visits')
  }
  const toUpperLevel = (): void => navigate('')

  const { taskCode } = params
  const ops = useAsync(async () => {
    const client = api._httpClientFactory.getHttpClient()
    const res = await client.post('/dte/tasks/v1.0/get-task', { code: taskCode, codeSpace: 'FACEDTE' })
    console.log('got dte-task', res)
    const oldTask = await api.tasks.getTask(taskCode)
    if (oldTask) {
      console.log('rewriting old task', oldTask)
      await api.tasks.purgeTask(taskCode)
    }
    const newTask: ITask = res.data
    await api.tasks.saveTask(newTask)
  }, [taskCode])

  // useAsyncError(ops.error)
  if (ops.error) {
    return (
      <Layout>
        <TitleBar onBack={goBack}>Задача</TitleBar>
        <PageContent>
          <Box pt={3} />
          <EmptyMessage message='При получении задачи произошла ошибка' />
        </PageContent>
      </Layout>
    )
  }
  if (ops.loading) return <Layout>loading</Layout>

  return (
    <LocalContextProvider {...params}>
      <Routes>
        <Route path='' element={<DteTaskDialog onBack={goBack} />} />
        <Route path={`process/:processCode/:recordCode/*`} element={<NestedProcessPage onBack={toUpperLevel} />} />
      </Routes>
    </LocalContextProvider>
  )
}
