const PATH_DELIMITER = '/'

export function joinPath(...parts: string[]): string {
  return parts.reduce(
    (path, part, _) => {
      if (part) {
        if (path.endsWith(PATH_DELIMITER)) {
          if (part.startsWith(PATH_DELIMITER)) {
            return path + part.substring(PATH_DELIMITER.length)
          } else {
            return path + part
          }
        } else {
          if (part.startsWith(PATH_DELIMITER)) {
            return path + part
          } else {
            return path + PATH_DELIMITER + part
          }
        }
      }
      return path
    }, '')
}

export function getPathExtension(path: string): string {
  if (!path.endsWith(PATH_DELIMITER)) {
    const i = path.lastIndexOf('.')
    if (i >= 0) {
      return path.substring(i+1)
    }
  }
  return ''
}