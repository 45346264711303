/* eslint-disable @typescript-eslint/no-explicit-any */

export function scanObjectTree<T>(
  root: unknown,
  match: (item: T) => boolean,
  bypass?: (parent: unknown, propName: string | number, propValue: unknown) => boolean,
): T[] {
  if (root == null) {
    return []
  }
  if (match(root as T)) {
    return [root as T]
  }

  const subs: T[] = []

  if (Array.isArray(root)) {
    let i = 0
    for (const item of root) {
      if (bypass == null || !bypass(root, i, item)) {
        subs.push(...scanObjectTree<T>(item, match, bypass))
      }
      i++
    }
  } else if (typeof root === 'object') {
    for (const [key, value] of Object.entries(root as any)) {
      if (bypass == null || !bypass(root, key, value)) {
        if (value != null) {
          subs.push(...scanObjectTree<T>(value, match, bypass))
        }
      }
    }
  }

  return subs
}

interface ScanEntry<T> {
  value: T
  path: Array<string | number>
}

export function scanObjectTreeEntries<T>(
  root: unknown,
  match: (item: unknown) => item is T,
  bypass?: (parent: unknown, propName: string | number, propValue: unknown) => boolean,
  path: Array<string | number> = [],
): Array<ScanEntry<T>> {
  if (root == null) {
    return []
  }
  if (match(root)) {
    return [{ value: root, path: path }]
  }

  return entries(root)
    .filter(([key, value]) => bypass == null || !bypass(root, key, value))
    .flatMap(([key, value]) => scanObjectTreeEntries(value, match, bypass, [...path, key]))
}

function entries(value: unknown): Array<[string | number, unknown]> {
  if (Array.isArray(value)) {
    return value.map((item, index) => [index, item])
  } else if (typeof value === 'object' && value !== null) {
    return Object.entries(value)
  } else {
    return []
  }
}

export function cleanObjectOfEmptyStrings<T extends Record<string, any>>(object: T, toUndefined = false): Partial<T> {
  const entries = Object.entries(object)
  const filtered = entries.flatMap(([key, value]) => {
    const shouldBeCleaned = String(value).trim() === ''
    if (!shouldBeCleaned) {
      return [[key, value]]
    } else {
      return toUndefined ? [[key, undefined]] : []
    }
  })
  return Object.fromEntries(filtered) as Partial<T>
}

type TObjectPredicate<T> = (arg: T) => string | undefined | null
interface ISortOptions {
  /** Default: false */
  emptyValuesFirst?: boolean
}

export function sortObjects<T extends Record<string, any>>(
  first: ISortOptions | TObjectPredicate<T>,
  ...rest: Array<TObjectPredicate<T>>
): (l: T, r: T) => number {
  const predicates = rest
  let params: ISortOptions = {
    emptyValuesFirst: false,
  }
  if (!(first instanceof Function)) {
    params = {
      ...params,
      ...first,
    }
  } else {
    predicates.unshift(first)
  }
  const comparator = (l: T, r: T, predicates: Array<TObjectPredicate<T>>): number => {
    const [predicate, ...rest] = predicates
    if (!predicate) {
      return 0
    }
    const lValue = predicate(l)
    const rValue = predicate(r)
    if (lValue && rValue && lValue !== rValue) {
      return lValue.localeCompare(rValue)
    }
    if (!lValue && rValue) {
      return params.emptyValuesFirst ? -1 : 1
    }
    if (!rValue && lValue) {
      return params.emptyValuesFirst ? 1 : -1
    }
    return comparator(l, r, rest)
  }

  return (l: T, r: T) => {
    return comparator(l, r, predicates)
  }
}
