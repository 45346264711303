import React, { useContext } from 'react'

import { Box, createStyles, Fab, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, Link, useLocation } from 'react-router-dom'
import { useAsync } from 'react-use'

import { getFloatActionButtonStyle } from '../../../../../assets/pm-shared-styles'
import { LogManager } from '../../../../../infrastructure/logger'
import { AppBar } from '../../../../../layout'
import { TitleBarText } from '../../../../../layout/app-bar'
import { BackButton } from '../../../../../layout/back-button'
import { PageContent } from '../../../../../layout/page-content'
import { grays } from '../../../../../layout/theme'
import { TitleBar } from '../../../../../layout/title-bar'
import { IPhotosPropertyScreenItem } from '../../../../../model/screen-item'
import { ApiContext, ProfileContext } from '../../../../../providers'
import { dateFormat } from '../../../../../utils'
import { useIsSmall } from '../../../../_common/hooks/useIsSmall'
import { DocumentsScreenItemContainer } from '../../../../documents/components/documents-screen-items/documents-screen-item-container'
import { ErrorPage } from '../../../../error-page'
import { useScriptTaskContext } from '../../../../tasks/script-tasks/script-task-context'
import { ItemCard } from '../../../../tasks/template-tasks/composite-screen/item-card'
import ProblemPosInfoScreenItem from '../items/problem-pos-info-screen-item'
import ProblemStatusItem from '../items/problem-status-item'
import { ProblemContextProvider } from '../problem-context'

interface IState {
  problemId: string
  posCode: string
}

interface IStoreMenuLocationState {
  prevProblemPath: string
  scrollOffset: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentWrap: {
      padding: '24px 25px 0',
      paddingBottom: theme.spacing(12),
      [theme.breakpoints.down('xs')]: {
        padding: '0 17px 0',
        marginTop: 113,
        paddingBottom: theme.spacing(12),
      },
    },
    appBar: {
      position: 'static',
      width: '100% !important',
      [theme.breakpoints.down('xs')]: {
        alignItems: 'flex-start',
      },
    },
    appBar_small: {
      display: 'flex',
      alignItems: 'center',
    },
    backButton_small: {
      width: 32,
      height: 32,
      padding: 2,
      marginRight: 8,
      marginLeft: -8,
    },
    title_small: {
      fontSize: theme.typography.h3.fontSize,
      lineHeight: theme.typography.h4.lineHeight,
      fontWeight: 600,
    },
    date: {
      marginLeft: theme.spacing(2),
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.h4.fontSize,
      lineHeight: '20px',
      color: grays.gray2,
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
        marginBottom: -10,
      },
    },
    item: {
      marginBottom: 10,
      [theme.breakpoints.down('xs')]: {
        margin: '0 0 10px 0',
      },
    },
    text: {
      color: grays.gray2,
    },
    addButton: {
      ...getFloatActionButtonStyle(theme),
      paddingRight: 16,
      textTransform: 'none',
      [theme.breakpoints.down('xs')]: {
        right: '50%',
        transform: 'translateX(50%)',
        margin: '0 auto',
      },
    },
    btnText: {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.fontSize,
      lineHeight: '20px',
    },
  }),
)

const ToProcessBtn = (): JSX.Element => {
  const classes = useStyles()

  const { t } = useTranslation('problems')
  return (
    <Link to='process'>
      <Fab color='primary' aria-label='add' variant='extended' className={classes.addButton}>
        <Typography className={classes.btnText}>{t('toProcess')}</Typography>
      </Fab>
    </Link>
  )
}

const logger = LogManager.getLogger('problemViewPage')

const ProblemViewPage: React.FC = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const { t } = useTranslation('problems')
  const isSmall = useIsSmall()
  const localContext = useScriptTaskContext()
  const { problem } = localContext
  const updateDateHM = dateFormat(problem!.updateTime!, 'HH:mm')
  const updateDateDMY = dateFormat(problem!.updateTime!, 'dd.MM.yyyy')
  const creationDateHM = dateFormat(problem!.creationTime!, 'HH:mm')
  const creationDateDMY = dateFormat(problem!.creationTime!, 'dd.MM.yyyy')
  const api = useContext(ApiContext)
  const currentUser = useContext(ProfileContext)
  const location = useLocation()
  const state = location.state as IStoreMenuLocationState
  const prevProblemPath = state.prevProblemPath

  const onBackHandler = (): void => navigate(prevProblemPath, { state })

  const executiveScreenItem: IPhotosPropertyScreenItem = {
    $type: 'PMI.FACE.BDDM.Extensions.Classes.PhotosPropertyScreenItem',
    propertyName: 'problem.attachment',
    displayName: 'Фото автора',
    orderNumber: 1,
    actionKind: 'View',
    viewSettings: {
      hideEmpty: true,
    },
  }

  const revisionScreenItem: IPhotosPropertyScreenItem = {
    $type: 'PMI.FACE.BDDM.Extensions.Classes.PhotosPropertyScreenItem',
    propertyName: 'problem.revisionAttachment',
    displayName: 'Фото исполнителя',
    orderNumber: 1,
    actionKind: 'View',
    viewSettings: {
      hideEmpty: true,
    },
  }

  const problemTemplates = useAsync(async () => {
    try {
      return await api.problem.getProblemTemplates()
    } catch (error) {
      const message = 'Ошибка при получении шаблонов проблем'
      logger.error('get', message, error)
      throw new Error(message)
    }
  }, [])
  if (problemTemplates.error) {
    return <ErrorPage errorMessage={problemTemplates.error.message} />
  }
  if (problemTemplates.loading && !problemTemplates.value) return <></>

  const problemTemplate = problemTemplates!.value!.find(
    (item) => item.escalationReason.code === problem!.escalationReason.code,
  )!

  const renderUpdateDate = (): JSX.Element => (
    <Typography className={classes.date}>{`${updateDateHM} | ${updateDateDMY}`}</Typography>
  )

  const renderHeader = (): JSX.Element => (
    <AppBar className={classes.appBar}>
      <BackButton onBack={onBackHandler} />
      <TitleBarText>{t('viewingtheIssue')}</TitleBarText>
      <Box>
        <ProblemStatusItem problem={problem!} />
      </Box>
      <Box>{renderUpdateDate()}</Box>
    </AppBar>
  )

  const renderHeaderSmall = (): JSX.Element => (
    <TitleBar fixed additionalToolbar={renderUpdateDate()}>
      <Box>
        <Box className={classes.appBar_small}>
          <BackButton className={classes.backButton_small} onBack={onBackHandler} />
          <Typography className={classes.title_small} noWrap={true}>
            {t('viewingtheIssue')}
          </Typography>
          <ProblemStatusItem problem={problem!} />
        </Box>
      </Box>
    </TitleBar>
  )

  return (
    <Box>
      {isSmall ? renderHeaderSmall() : renderHeader()}
      <PageContent>
        <div className={classes.contentWrap}>
          <Box className={classes.item}>
            <ProblemPosInfoScreenItem />
          </Box>
          <Box className={classes.item}>
            <ItemCard label={t('typeOfProblem')}>
              <Typography className={classes.text}>
                {problem!.escalationReason.name || problem!.escalationReason.code}
              </Typography>
            </ItemCard>
          </Box>
          {problem!.revisionComment && (
            <Box className={classes.item}>
              <ItemCard label={t('revisionComment')}>
                <Typography className={classes.text}>{problem!.revisionComment}</Typography>
              </ItemCard>
            </Box>
          )}
          {problem!.executiveComment && (
            <Box className={classes.item}>
              <ItemCard label={t('executiveComment')}>
                <Typography className={classes.text}>{problem!.executiveComment}</Typography>
              </ItemCard>
            </Box>
          )}
          <Box className={classes.item}>
            <ItemCard label={t('dateAndTimeOfCreation')}>
              <Typography className={classes.text}>{`${creationDateHM}, ${creationDateDMY}`}</Typography>
            </ItemCard>
          </Box>
          <Box className={classes.item}>
            <ItemCard label={t('createdBy')}>
              {
                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                <Typography className={classes.text}>{problem!.raisedBy?.name || problem!.raisedBy?.code}</Typography>
              }
            </ItemCard>
          </Box>
          {problem!.attachment && problem!.attachment.parts.length > 0 && (
            <Box className={classes.item}>
              <DocumentsScreenItemContainer entity={executiveScreenItem} isReadOnly={true} />
            </Box>
          )}
          {problem!.revisionAttachment && problem!.revisionAttachment.parts.length > 0 && (
            <Box className={classes.item}>
              <DocumentsScreenItemContainer entity={revisionScreenItem} isReadOnly={true} />
            </Box>
          )}
          {problem!.problemDetails && (
            <Box className={classes.item}>
              {
                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                <ItemCard label={problemTemplate?.problemDetailsCaption || 'Детали'}>
                  <Typography className={classes.text}>{problem!.problemDetails}</Typography>
                </ItemCard>
              }
            </Box>
          )}
          <Box className={classes.item}>
            <ItemCard label={t('executor')}>
              <Typography className={classes.text}>
                {
                  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                  problem!.assignedTo?.name || problem!.assignedTo?.code || 'Не назначен'
                }
              </Typography>
            </ItemCard>
          </Box>
        </div>

        {problem!.status === 'New' &&
          currentUser.value?.fieldPositionRole?.code === problem!.assignedToPositionRole?.code && <ToProcessBtn />}
      </PageContent>
    </Box>
  )
}

// eslint-disable-next-line react/display-name
export default (): JSX.Element => {
  const { posCode, problemId } = useParams() as unknown as IState
  const api = useContext(ApiContext)

  const problem = useAsync(async () => {
    try {
      return await api.problem.getProblem(problemId)
    } catch (error) {
      const message = 'Ошибка при поиске проблемы'
      throw new Error(message)
    }
  }, [posCode, problemId])

  if (problem.error) {
    return <ErrorPage errorMessage={problem.error.message} />
  }
  if (problem.loading && !problem.value) return <></>

  return (
    <ProblemContextProvider posCode={posCode} problem={problem.value!}>
      <ProblemViewPage />
    </ProblemContextProvider>
  )
}
