import React from 'react'

interface State {
  numPages: number
  currentPage: number
}

type Action =
  | { type: PdfDocumentActionKind.SetCurrentPage; payload: number }
  | { type: PdfDocumentActionKind.SetNumPages; payload: number }

interface IContextProps {
  state: State
  dispatch: ({type, payload}: Action) => void
}

export enum PdfDocumentActionKind {
  SetCurrentPage = 'SET_CURRENT_PAGE',
  SetNumPages = 'SET_NUM_PAGES'
}

export const pdfInitialState = {
  numPages: 0,
  currentPage: 1
}

export const PdfDocContext = React.createContext({} as IContextProps)

export const pdfReducer = (state: State, action: Action): State => {
  const { type, payload } = action

  switch (type) {
    case PdfDocumentActionKind.SetCurrentPage:
      return {
        ...state,
        currentPage: payload
      }
    case PdfDocumentActionKind.SetNumPages:
      return {
        ...state,
        numPages: payload
      }
    default:
      return state
  }
}