import React, { useCallback, useMemo } from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { CalendarToday } from '@material-ui/icons'
import { addMonths } from 'date-fns'
import { ru } from 'date-fns/locale'
import DatePicker from 'react-datepicker'

import { dateFormat } from '../../../../../utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      fontSize: 14,
      fontWeight: 500,
      minWidth: 129,
      height: 40,
      borderRadius: 8,
      color: theme.palette.common.black,
    },
    calendarIcon: {
      width: 16,
      marginRight: 7,
      color: theme.palette.common.black,
    },
  }),
)

interface IRangeDatePicker {
  onChange: (date: [Date | null, Date | null]) => void
  selectedStartDate?: number
  selectedEndDate?: number
  minDate?: Date
  maxDate?: Date
}

const RangeDateInput: React.FC<IRangeDatePicker> = ({
  onChange,
  maxDate,
  minDate,
  selectedStartDate,
  selectedEndDate,
}) => {
  const classes = useStyles()

  const inputText = useMemo(
    () =>
      selectedStartDate
        ? `${dateFormat(new Date(selectedStartDate), 'dd.MM.yyyy')} - ${
            selectedEndDate ? dateFormat(new Date(selectedEndDate), 'dd.MM.yyyy') : ''
          }`
        : 'Выберите период',
    [selectedStartDate, selectedEndDate],
  )

  const renderCustomInput = useCallback(
    (): JSX.Element => (
      <div className={classes.text}>
        <CalendarToday className={classes.calendarIcon} />
        {inputText}
      </div>
    ),
    [selectedStartDate, selectedEndDate],
  )

  return (
    <DatePicker
      dateFormat='dd.MM.yyyy'
      locale={ru}
      todayButton='Сегодня'
      selectsRange={true}
      startDate={selectedStartDate ? new Date(selectedStartDate) : null}
      endDate={selectedEndDate ? new Date(selectedEndDate) : null}
      onChange={onChange}
      isClearable={true}
      customInput={renderCustomInput()}
      minDate={minDate ?? new Date()}
      maxDate={maxDate ?? addMonths(new Date(), 2)}
    />
  )
}

export default RangeDateInput
