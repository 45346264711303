import React, { useCallback, useContext, useMemo, useState } from 'react'

import { Button, createStyles, Dialog, makeStyles, Paper, Theme, Typography } from '@material-ui/core'
import classnames from 'classnames'
import { useAsync } from 'react-use'
import { AsyncState } from 'react-use/lib/useAsyncFn'

import { ValidationFab } from '../../../../../components/validation-fab'
import { PageContent } from '../../../../../layout/page-content'
import { grays } from '../../../../../layout/theme'
import { TitleBar } from '../../../../../layout/title-bar'
import { createReferenceToEntity, IEntityReference } from '../../../../../model/base'
import { checkConditionLists } from '../../../../../model/condition-lists'
import { ISelectEmployeeForAuditScreenItem } from '../../../../../model/screen-item'
import { checkPredicate } from '../../../../../model/script-predicate'
import { ISupervisedFieldPositionRole } from '../../../../../model/supervised-field-position-role'
import { ApiContext } from '../../../../../providers'
import { useDefaultCodeSpace } from '../../../../../providers/config/useDefaultCodeSpace'
import { appToast } from '../../../../../utils'
import { useAsyncError } from '../../../../_common/hooks/useAsyncError'
import { useBatchUpdate } from '../../../nested/useBatchUpdate'
import { getContextProperty } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { ItemCard } from '../item-card'
import { getEmployeeName } from './utils'

export const SCOPE_EMPLOYEE_KEY = 'scope.employeeKey'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chooseBtn: {
      fontSize: 16,
      fontWeight: 500,
      paddingLeft: 0,
    },
    content: {
      padding: '25px 28px 0',
    },
    employeeList: {
      border: `1px solid ${grays.gray5}`,
      padding: `12px 0 12px`,
    },
    selectedEmployee: {
      cursor: 'pointer',
      padding: `6px 0`,
    },
    name: {
      fontSize: 16,
      lineHeight: '24px',
      padding: '12px 17px',
      cursor: 'pointer',
    },
    selected: {
      background: 'rgba(48,119,184, 0.05)',
    },
  }),
)

interface IEmployee {
  name: string
  supervisedFieldPositionRole: ISupervisedFieldPositionRole
}

interface ISelectEmployeeForAudit {
  item: ISelectEmployeeForAuditScreenItem
}

export const SelectEmployeeForAudit: React.FC<ISelectEmployeeForAudit> = ({ item }) => {
  const classes = useStyles()
  const api = useContext(ApiContext)
  const localContext = useScriptTaskContext()
  const batchUpdate = useBatchUpdate()
  const defaultCodeSpace = useDefaultCodeSpace()

  const [dialogEmployeeKey, setDialogEmployeeKey] = useState<string | null>(null)
  const [employeesOpen, setEmployeesOpen] = useState(false)

  let selectedEmployeeKey = getContextProperty<string | null>(localContext, SCOPE_EMPLOYEE_KEY, null)
  if (!selectedEmployeeKey) {
    selectedEmployeeKey =
      getContextProperty<IEntityReference | null>(localContext, item.selectedPositionRoleReferencePropertyName, null)
        ?.code ?? ''
  }

  const employeesOps: AsyncState<IEmployee[]> = useAsync(async () => {
    const supervisedList = await api.supervised.getAllSupervisedFieldPositionRole()
    let filteredEmployees = []
    if (item.jobFunctions) {
      for (const sfpr of supervisedList) {
        if (checkConditionLists(item.jobFunctions, [sfpr.jobFunction])) {
          filteredEmployees.push(sfpr)
        }
      }
    } else {
      filteredEmployees = supervisedList
    }

    return filteredEmployees.map((employee) => {
      return {
        name: getEmployeeName(employee, item.employeeDisplayName),
        supervisedFieldPositionRole: employee,
      }
    })
  }, [])

  useAsyncError(employeesOps.error)

  const onChange = (value: string): void => {
    const selected = employees?.find((employee) => employee.supervisedFieldPositionRole.code === value)
    if (!selected) {
      return console.log('selected not found', value)
    }

    void batchUpdate({
      [SCOPE_EMPLOYEE_KEY]: value,
      [item.selectedPositionRoleReferencePropertyName]: createReferenceToEntity(
        selected.supervisedFieldPositionRole,
        defaultCodeSpace,
        'PMI.BDDM.Staticdata.FieldPositionRoleReference',
      ),
      [item.selectedUserReferencePropertyName]: {
        ...selected.supervisedFieldPositionRole.currentEmployee?.account,
        name: getEmployeeName(selected.supervisedFieldPositionRole, 'Name'),
      },
    })
  }

  const openEmployees = (): void => {
    setEmployeesOpen(true)
  }
  const closeEmployees = (): void => {
    setEmployeesOpen(false)
  }

  const employees = employeesOps.value

  const selectedEmployee = useMemo(
    () => employees?.find((employee) => employee.supervisedFieldPositionRole.code === selectedEmployeeKey),
    [selectedEmployeeKey, employees],
  )

  const transformName = useCallback(
    (name: string, code: string): string => {
      if (item.employeeDisplayName === 'Name' && employees!.filter((employee) => employee.name === name).length >= 2) {
        return `${name} (${code})`
      }

      return name
    },
    [employees],
  )

  const isVisible = item.visible ? checkPredicate(item.visible, undefined, localContext) : true
  const isRequired = item.required ? checkPredicate(item.required, undefined, localContext) : false

  if ((employeesOps.loading && !employeesOps.value?.length) || !isVisible) {
    return <></>
  }

  return (
    <ItemCard isError={isRequired && !selectedEmployeeKey} label={item.displayName}>
      {selectedEmployee ? (
        <Typography className={classes.selectedEmployee} onClick={openEmployees}>
          {transformName(selectedEmployee.name, selectedEmployee.supervisedFieldPositionRole.code)}
        </Typography>
      ) : (
        <Button className={classes.chooseBtn} onClick={openEmployees}>
          <Typography color='primary'>Выбрать</Typography>
        </Button>
      )}

      <Dialog open={employeesOpen} onClose={closeEmployees} fullScreen>
        <TitleBar
          onBack={() => {
            setDialogEmployeeKey(null)
            closeEmployees()
          }}
        >
          {item.displayName}
        </TitleBar>

        <PageContent className={classes.content}>
          <Paper elevation={0} square className={classes.employeeList}>
            {employees?.length ? (
              employees.map((employee) => {
                const { name, supervisedFieldPositionRole } = employee
                return (
                  <Typography
                    key={supervisedFieldPositionRole.code}
                    className={classnames(classes.name, {
                      [classes.selected]: dialogEmployeeKey
                        ? dialogEmployeeKey === supervisedFieldPositionRole.code
                        : selectedEmployeeKey === supervisedFieldPositionRole.code,
                    })}
                    onClick={() => {
                      setDialogEmployeeKey(supervisedFieldPositionRole.code)
                    }}
                  >
                    {transformName(name, supervisedFieldPositionRole.code)}
                  </Typography>
                )
              })
            ) : (
              <Typography className={classes.name}>Список сотрудников пуст</Typography>
            )}
          </Paper>
        </PageContent>
        <ValidationFab
          isValid={!!dialogEmployeeKey}
          onClick={() => {
            if (dialogEmployeeKey) {
              onChange(dialogEmployeeKey)
              closeEmployees()
            } else {
              appToast.info('Выберите сотрудника из списка')
            }
          }}
        >
          Подтвердить
        </ValidationFab>
      </Dialog>
    </ItemCard>
  )
}
