import React from 'react'

import { Box } from '@material-ui/core'

import { getPropertyAny } from '../../../script-tasks/propertyName'
import AnswerResolution from '../answer-resolution'
import { CellWrapperProps, EditorItemProps } from './editorUtils'

type TIndicatorValue = boolean | null | undefined

export const BooleanIndicatorCell: React.FC<EditorItemProps & CellWrapperProps> = ({ variant, ...props }) => {
  const { col, row, isReport } = props

  if (col.control.$type !== 'PMI.FACE.BDDM.Extensions.Classes.BooleanIndicatorCell') throw new Error('unreachable')

  let value = getPropertyAny<TIndicatorValue>(row, col.propertyName ?? '', undefined)

  const isInvert = col.control.invertValue

  if (isInvert && typeof value === 'boolean') {
    value = !value
  }

  switch (variant) {
    case 'table':
      return (
        <div style={{ display: 'inline-block', verticalAlign: 'baseline' }}>
          <AnswerResolution
            resolution={value}
            onlyText={isReport}
            text={
              value === true
                ? col.control.trueValueCaption ?? 'Да'
                : value === false
                ? col.control.falseValueCaption ?? 'Нет'
                : col.control.nullValueCaption ?? 'N/A'
            }
          />
        </div>
      )
    case 'portrait':
    case 'extended-row':
      return (
        <Box>
          <AnswerResolution
            resolution={value}
            onlyText={isReport}
            text={
              value === true
                ? col.control.trueValueCaption ?? 'Да'
                : value === false
                ? col.control.falseValueCaption ?? 'Нет'
                : col.control.nullValueCaption ?? 'N/A'
            }
          />
        </Box>
      )
  }
}
