import React from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core'
import classnames from 'classnames'

import { grays } from '../../../layout/theme'
import { IPointOfSale } from '../../../model/pos'
import { getRegisteredNameFromPos } from '../store-menu-page/utils'
import { StoreMenuButton } from '../stores-list-item/store-menu-button'

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    item: {
      position: 'relative',
      padding: '16px 8px 16px 8px',
      marginBottom: '14px',
      boxShadow: '0px 3px 7px rgba(96, 97, 112, 0.13), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      cursor: 'pointer',
      filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3))',
      [theme.breakpoints.down('xs')]: {
        marginBottom: 0,
      },
    },
    itemSelect: {
      boxShadow: `0px 0px 0px 2px ${theme.palette.info.main} inset, 0px 3px 7px rgba(96, 97, 112, 0.13), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)`,
    },
    title: {
      margin: 0,
      width: 'calc(100% - 30px) !important',
      marginBottom: '4px',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
    },
    address: {
      margin: 0,
      marginBottom: '8px',
      minHeight: '40px',
      fontSize: '14px',
      lineHeight: '20px',
    },
    ellipsis: {
      display: 'block',
      width: '100%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    code: {
      margin: 0,
      marginBottom: '1px',
      fontSize: '14px',
      lineHeight: '24px',
      color: grays.gray3,
      letterSpacing: '0.25px',
    },
    visitMenu: {
      position: 'absolute',
      top: 6,
      right: 0,
    },
  })
})

interface IStoreItemMap {
  store: IPointOfSale
  isSelect: boolean
  onClick?: () => void
}

export const StoreItemMap: React.FC<IStoreItemMap> = ({ store, isSelect = false, onClick }) => {
  const classes = useStyles()
  const registeredName = getRegisteredNameFromPos(store)
  return (
    <div
      className={classnames(classes.item, isSelect ? classes.itemSelect : '', `STORE_CODE-${store.code}`)}
      onClick={onClick}
    >
      <h3 className={classnames(classes.title, classes.ellipsis)}>{store.name}</h3>
      <p className={classes.address}>
        <span className={classes.ellipsis}>{store.address?.fullAddress}</span>
        <span className={classes.ellipsis}>{store.address?.city}</span>
      </p>
      <p className={classnames(classes.code, classes.ellipsis)}>
        {store.code}
        {registeredName ? `\u00A0|\u00A0${registeredName}` : ''}
      </p>
      <div className={classes.visitMenu} onClick={(evt) => evt.stopPropagation()}>
        <StoreMenuButton posCode={store.code} />
      </div>
    </div>
  )
}
