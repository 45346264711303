import React, { useContext, useEffect } from 'react'

import { Box, Button, createStyles, IconButton, Link, SvgIcon, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import { useTranslation } from 'react-i18next'

import { ReactComponent as PencilIcon } from '../../../assets/icons/pencil.svg'
import { IAnswer } from '../../../model/answer'
import { AnswerReplyCommentRequirements } from '../../../model/answer-reply-requirements'
import { IQuestion } from '../../../model/question'
import { ModalContext } from '../../../providers/modal'
import { formatTemplateString } from '../../../utils/format-template-string'
import { OpenableTypography } from '../../tasks/components/openable-typography'
import { getContextProperty } from '../../tasks/script-tasks/propertyName'
import { useScriptTaskContext } from '../../tasks/script-tasks/script-task-context'
import { isCommentDisabled } from '../answer-requirements-utils'
import CommentModal from '../comment-modal'
import ReadOnlyText from '../read-only-text'
import { isNullAnswer } from '../utils'

const SUCCESS_COLOR = '#388E3C'

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    answerWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    comment: {
      marginLeft: 'auto',
      padding: theme.spacing(0, 1),
    },
    finishedDisplayName: {
      fontSize: 16,
      color: SUCCESS_COLOR,
    },
  }),
)

interface ISurveyQuestionProps {
  disabled?: boolean
  commentReq?: AnswerReplyCommentRequirements
  title: string
  description?: React.ReactNode
  answerValue?: string | null
  comment: string | null
  onHyperlinkAnswer: (answer: string) => void
  onSaveComment: (comment: string) => void
  linkDisplayName?: string
  linkFormat: string
  finishedDisplayName?: string
  onNullAnswer: () => void
  answer?: IAnswer
  question: IQuestion
}

export const HyperlinkQuestion: React.FC<ISurveyQuestionProps> = ({
  disabled = false,
  commentReq,
  title = '',
  description,
  answerValue,
  comment = null,
  onHyperlinkAnswer,
  onSaveComment,
  linkDisplayName,
  linkFormat,
  finishedDisplayName,
  onNullAnswer,
  answer,
  question,
}) => {
  const hasAnswer = answerValue !== undefined && !isNullAnswer(answer)
  const classes = useStyles()
  const modalContext = useContext(ModalContext)
  const localContext = useScriptTaskContext()

  const resultLink = formatTemplateString(linkFormat, (propertyName) =>
    getContextProperty(localContext, propertyName, ''),
  )

  const { t } = useTranslation(['sales-expert-tasks', 'question'])

  useEffect(() => {
    if (disabled) return
    if (answer) return
    if (question?.required === false) {
      onNullAnswer()
    }
  }, [answerValue])

  const renderAnswerLink = (): JSX.Element | null => {
    if (!hasAnswer) {
      return (
        <Typography color='primary'>
          <Link
            href={resultLink}
            target='_blank'
            onClick={() => {
              onHyperlinkAnswer(resultLink)
            }}
            underline='none'
            variant='body1'
          >
            {linkDisplayName ?? 'Перейти'}
          </Link>
        </Typography>
      )
    } else {
      return (
        <div className={classes.answerWrapper}>
          <div className={classes.finishedDisplayName}>
            {finishedDisplayName ?? t('linkFinishedDisplayName', { ns: 'question' })}
          </div>

          <OpenableTypography className={classes.comment}>{comment}</OpenableTypography>
          <Box>{renderCommentButton()}</Box>
        </div>
      )
    }
  }

  const openHandler = (): void => {
    modalContext.open(
      <CommentModal //
        open
        isCommentRequired={commentReq === AnswerReplyCommentRequirements.Required}
        comment={comment}
        titleDescription={title}
        onSave={(comment) => {
          onSaveComment(comment)
          modalContext.close()
        }}
        onClose={modalContext.close}
      />,
    )
  }

  const renderCommentButton = (): JSX.Element => {
    if (disabled || isCommentDisabled(commentReq)) return <div></div>
    if (comment?.trim()) {
      return (
        <IconButton //
          edge='end'
          className={classes.button}
          color='primary'
          aria-label='change-comment'
          onClick={openHandler}
        >
          <SvgIcon component={PencilIcon} />
        </IconButton>
      )
    } else {
      return (
        <Button className={classes.button} variant='text' color='primary' startIcon={<AddIcon />} onClick={openHandler}>
          {t('commentAction')}
        </Button>
      )
    }
  }

  return (
    <>
      {description && (
        <Box letterSpacing='0.25px' mb={2} whiteSpace='pre-wrap'>
          <Typography variant='body2'>{description}</Typography>
        </Box>
      )}

      {isNullAnswer(answer) && disabled ? (
        <ReadOnlyText>{t('questionAnswerNotGiven')}</ReadOnlyText>
      ) : (
        renderAnswerLink()
      )}
    </>
  )
}
