/* eslint-disable @typescript-eslint/indent*/
import React, { useContext, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { Box } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'react-use'

import { ILoginPageErrorStateFromRoute } from '../../features/login-page'
import {AuthError, ICredentials, AuthService, AuthServiceSettings, UserName} from '../../infrastructure/auth'
import { HttpClientFactory } from '../../infrastructure/http-client-factory'
import { LogManager } from '../../infrastructure/logger'
import UserMetricService from '../../infrastructure/user-metric/user-metric-service'
import { ConfigContext } from '../config'
import AuthContext, { IAuthContext } from './auth-context'

const logger = LogManager.getLogger('AuthProvider')

const AuthProvider: React.FC = ({ children }) => {
  const configContext = useContext(ConfigContext)
  const navigate = useNavigate()
  const authServiceRef = useRef<AuthService>()
  const userMetricServiceRef = useRef<UserMetricService>()
  const [currentUserName, setCurrentUserName] = useState<string | undefined | null>(null)
  const [redirecting, setRedirecting] = useState<boolean>(false)

  const authOptions = useMemo(() => {
    // if (Math.random() > 0) {
    // return configContext.config.authOptions!
    // }
    if (configContext.config.authOptions?.length) {
      return configContext.config.authOptions?.filter((option) => {
        if (!option.hosts) {
          // TODO: ignore undefined hosts options instead of defaulting to them
          return true
        }
        return option.hosts.includes(window.location.hostname)
      })
    }
    if (configContext.config.auth) {
      return [configContext.config.auth]
    }
    return []
  }, [configContext.config])

  const [selectedOption, setSelectedOption] = useLocalStorage<string>('authOption', undefined)

  useLayoutEffect(() => {
    let authOption = authOptions[0]
    if (selectedOption) {
      authOption = authOptions.find((option) => option?.key === selectedOption) ?? authOptions[0]
    }
    if (!authOption) throw new Error('auth option not found')
    console.log('using auth option', authOption, authOptions)

    const authServiceSettings: AuthServiceSettings = {
      apiUrl: configContext.config.apiUrl,
      method: authOption.method,
      authorizeUrl: authOption.authorizeEndpoint,
      tokenUrl: authOption.tokenEndpoint,
      logoutUrl: authOption.logoutEndpoint,
      clientId: authOption.clientId,
      clientSecret: authOption.clientSecret,
      scope: authOption.scope,
      loginClaim: authOption.loginClaim,
      enableAutoLogout: configContext.config.enableAutoLogout,
    }
    const authService = new AuthService(
      authServiceSettings,
      (url) => {
        setRedirecting(true)
        window.location.assign(url)
      },
      () => authOption.key ?? ''
    )
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(window as any).logoff = () => authService.logoff()
    }
    authServiceRef.current = authService
    userMetricServiceRef.current = new UserMetricService(authService)

    function onUserLoggedOut(userName: UserName): void {
      logger.debug('onLoggedOut', `User '${userName}' logged out`)
      setCurrentUserName(undefined)
    }
    function onUserLoggedIn(userName: UserName): void {
      logger.debug('onLoggedIn', `User '${userName}' logged in`)
      setCurrentUserName(userName)
    }

    setCurrentUserName(authService.currentUserName)

    authService.onLoggedIn.add(onUserLoggedIn)
    authService.onLoggedOut.add(onUserLoggedOut)
    return () => {
      authService.onLoggedIn.remove(onUserLoggedIn)
      authService.onLoggedOut.remove(onUserLoggedOut)
    }
  }, [configContext.config, selectedOption])

  const authService = authServiceRef.current

  const httpClientFactory = new HttpClientFactory(configContext.config.apiUrl, authService)
  LogManager.getService().setHttpClientFactory(httpClientFactory)
  if (configContext.config.logFlushInterval) {
    LogManager.getService().setFlushInterval(configContext.config.logFlushInterval * 1000)
  }

  // todo: tbd remove. using to avoid useless login() calls
  const [loading, setLoading] = useState<boolean>(false)

  if (!authService) {
    console.info('waiting for auth context')
    return null
  }

  const authContext: IAuthContext = {
    authOptions,
    selectOption: setSelectedOption,
    authenticated: !!currentUserName,
    // обращение к этому провайдеру будет только после логина в приложение,
    // поэтому поле currentUserName не будет null (! - чтобы не делать лишнюю проверку в компонентах)
    currentUserName: currentUserName!,
    login: async (credentials?: ICredentials) => {
      const authService = authServiceRef.current!
      if (loading) {
        return
      }
      setLoading(true)
      let _currentUserName: string | null | undefined = null
      try {
        _currentUserName = (await authService.login(credentials))?.userName
      } catch (e) {
        setLoading(false)
        // setCurrentUser(undefined)
        // setAuthenticated(false)
        if (e instanceof AuthError) {
          const userName = authService.currentUserName ?? ''
          // logger.warn('login', `${e.code}`, e, { login: userName })
          const path = '/login-error'
          const state: ILoginPageErrorStateFromRoute = {
            userName,
            code: e.code,
            status: e.status,
            message: e.message,
          }
          return navigate(path, { state })
        }
      } finally {
        LogManager.flush()
      }

      console.log('currentUserName=', _currentUserName)

      if (_currentUserName) {
        setLoading(false)
        void userMetricServiceRef.current?.userLogIn()
        console.log('logged in and redirecting')
        await navigate('/sync-auto', { state: { cleanProfile: true } })
      }
    },
    logout: async () => {
      return await authService.logout()
    },
    authService,
  }

  if (redirecting) {
    return (
      <Box display='flex' alignItems='center' height='80vh' justifyContent='center'>
        Redirecting...
      </Box>
    )
  }
  return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
}
export default AuthProvider
