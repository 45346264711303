// import { set as setFp } from 'lodash/fp'
import { useCallback, useContext } from 'react'

import create from 'zustand'

import { SyncContext } from '../sync'

interface PendingItemsStore {
  isPending: boolean
  pendingPhotoCount: number | undefined
  setPendingItems: (isPending: boolean) => void
  setPhotoCount: (updater: (pendingPhotoCount: number | undefined) => number) => void
}

export const usePendingItems = create<PendingItemsStore>((setStore, getStore) => ({
  isPending: false,
  pendingPhotoCount: 0,
  setPendingItems: (isPending) => {
    setStore({ isPending })
  },
  setPhotoCount: (cb) => {
    const oldValue = getStore().pendingPhotoCount
    const newValue = cb(oldValue)
    console.log('blobs updating count', { oldValue, newValue })
    setStore({ pendingPhotoCount: newValue })
  },
}))

export function useFetchPendingItems(): () => void {
  const setIsPending = usePendingItems((store) => store.setPendingItems)
  const { syncService } = useContext(SyncContext)

  return useCallback(() => {
    void syncService?.hasPendingItems().then(setIsPending)
  }, [syncService])
}
