import { AxiosInstance, AxiosRequestConfig } from 'axios'

import { IDisposable } from '../../utils/disposable'
import { IEvent } from '../../utils/event'
import { InjectionToken } from '../di'

export type IHttpClient = AxiosInstance

/** Axios request configuration function */
export type RequestConfigurer = (config: AxiosRequestConfig) => void

export interface IHttpClientFactory {
  /** request rejected (401) event */
  readonly onRequestRejected: IEvent<void>

  /** builds new httpclient instance */
  getHttpClient: (baseUrl?: string, finalConfigurer?: RequestConfigurer) => IHttpClient

  /** registers request configuration function */
  registerRequestConfigurer: (configurer: RequestConfigurer) => IDisposable
}

export const HTTP_CLIENT_FACTORY = new InjectionToken<IHttpClientFactory>('http-client-factory')

export const HTTP_CLIENT = new InjectionToken<IHttpClient>('http-client')
