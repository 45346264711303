import React, { useEffect } from 'react'

import { Box, Button, Collapse, createStyles, makeStyles, SvgIcon, Theme } from '@material-ui/core'
import classNames from 'classnames'

import { ReactComponent as CheckIcon } from '../../../assets/icons/action-enabled.svg'
import { ReactComponent as ExpandIcon } from '../../../assets/icons/expand-right.svg'
import { ValidationFab } from '../../../components/validation-fab'
import { grays } from '../../../layout/theme'
import { IUserStep, ISwitchStep, IProcessStep } from '../../../model/task-execution-process'
import { useInlineActionButton } from '../../dte/useInlineActionButton'
import { DebugTree } from '../debug-tree'
import { useLocalContextService } from '../nested/local-context'
import { useScriptTaskContext } from '../script-tasks/script-task-context'
import { UserStepControl } from '../script-tasks/user-step-control'
import { SwitchStepControl } from '../switch/switch-step-control'
import { ScreenRef } from '../template-tasks/types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      '&::before': {
        position: 'absolute',
        content: '""',
        top: 60,
        bottom: -14,
        left: 17,
        width: 3,
        zIndex: -1,
        borderRadius: 40,
        backgroundColor: theme.palette.primary.main,
      },
      '&:first-of-type': {
        marginTop: theme.spacing(-3),
      },
      '&:last-child::before': {
        display: 'none',
      },
      '&.disabled::before': {
        backgroundColor: grays.gray3,
      },
    },
    tab: {
      padding: theme.spacing(2, 0),
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.main,
      fontSize: 18,
      fontWeight: 500,
      '&.disabled': {
        color: grays.gray3,
      },
    },
    indexLabel: {
      userSelect: 'none',
      width: 24,
      height: 24,
      margin: 6,
      border: '0px solid transparent',
      borderRadius: '50%',
      backgroundColor: 'currentColor',
      '& > span': { color: theme.palette.primary.contrastText },
      textAlign: 'center',
      lineHeight: '24px',
      fontSize: 12,
      fontWeight: 500,
      // transition: theme.transitions.create(['border-width', 'width', 'height', 'margin']),
      // marginRight: theme.spacing(2),
    },
    labelActive: {
      width: 36,
      height: 36,
      margin: 0,
      border: '6px solid #D5EAFD ',
    },
    checkIcon: {
      color: 'white',
      width: 16,
      height: 16,
      marginTop: 4,
    },
    expandIcon: {
      transform: 'rotate(-90deg)',
      transition: theme.transitions.create('transform'),
      // marginLeft: theme.spacing(1),
    },
    iconExpanded: {
      transform: 'none',
    },
    nextButton: {
      boxShadow: 'none',
      borderRadius: 100,
      marginLeft: theme.spacing(3.5),
      marginTop: theme.spacing(2.5),
      padding: theme.spacing(1, 3),
      '&:hover': {
        boxShadow: 'none',
      },
    },
    stepWrapper: {
      marginLeft: 19,
    },
  }),
)

interface SubProcessViewProps {
  children?: never
  tabs: Array<IUserStep | ISwitchStep>
  allSteps: IProcessStep[]
  currentTab: string
  onTabChange: (tab: string) => void
  onAction: VoidFunction
  isReady: boolean
  setIsReady: (isReady: boolean) => void
  currentScreenRef: React.Ref<ScreenRef> | undefined
  onScriptError: (error: Error) => void
  finishActionName: string
}

export const SubProcessView: React.FC<SubProcessViewProps> = (props) => {
  useEffect(() => {
    console.log('SubProcessView mount', props)
  }, [])

  const {
    tabs,
    currentTab,
    onTabChange,
    setIsReady,
    onAction,
    allSteps,
    isReady,
    currentScreenRef,
    onScriptError,
    finishActionName,
  } = props
  const classes = useStyles()
  const { recordState } = useScriptTaskContext()
  const { inMemory } = useLocalContextService()
  const inlineActionButton = useInlineActionButton()
  // const currentStep = tabs.find((tab) => tab.code === currentTab)
  // console.log(
  //   'currentTab',
  //   currentStep,
  //   'screen',
  //   template.userStepScreens[
  //     currentStep?.$type === 'PMI.FACE.BDDM.Extensions.Classes.UserStep' ? currentStep.screen.code : ''
  //   ],
  // )

  const isLast = tabs.findIndex((tab) => tab.code === currentTab) === tabs.length - 1

  function renderStep(tab: IUserStep | ISwitchStep, active: boolean): JSX.Element {
    return (
      <>
        {tab.$type === 'PMI.FACE.BDDM.Extensions.Classes.UserStep' && (
          <UserStepControl
            ref={active ? currentScreenRef : undefined}
            step={tab}
            onReadyChange={setIsReady}
            nextButton={
              !isLast ? (
                <Button variant='contained' color='primary' className={classes.nextButton} onClick={onAction} />
              ) : undefined
            }
          />
        )}
        {tab.$type === 'PMI.FACE.BDDM.Extensions.Classes.SwitchStep' && (
          <SwitchStepControl
            ref={active ? currentScreenRef : undefined}
            step={tab}
            onReadyChange={setIsReady}
            onScriptError={onScriptError}
            nextButton={
              !isLast ? (
                <Button variant='contained' color='primary' className={classes.nextButton} onClick={onAction} />
              ) : undefined
            }
          />
        )}
      </>
    )
  }

  return (
    <>
      <DebugTree />
      {tabs.length === 1 && renderStep(tabs[0], true)}
      {tabs.length > 1 &&
        tabs.map((tab, i) => {
          const active = tab.code === currentTab
          const disabled = inMemory
            ? allSteps.indexOf(tab) > allSteps.findIndex((step) => step.code === currentTab)
            : allSteps.indexOf(tab) > (recordState?.currentStep ?? 0)
          const completed = !disabled && !active
          return (
            <div className={classNames(classes.container, { disabled, active })} key={tab.code}>
              <div className={classNames(classes.tab, { disabled })} onClick={() => !disabled && onTabChange(tab.code)}>
                <div className={classNames(classes.indexLabel, active && classes.labelActive)}>
                  {completed ? (
                    <SvgIcon className={classes.checkIcon} color='inherit' component={CheckIcon} />
                  ) : (
                    <span>{i + 1}</span>
                  )}
                </div>
                <Box p={1}>{tab.displayName}</Box>
                <SvgIcon
                  className={classNames(classes.expandIcon, active && classes.iconExpanded)}
                  component={ExpandIcon}
                />
              </div>
              <Collapse in={active} unmountOnExit>
                <div className={classes.stepWrapper}>{renderStep(tab, active)}</div>
              </Collapse>
            </div>
          )
        })}
      <ValidationFab useInline={inlineActionButton} isValid={isLast && isReady} onClick={() => isLast && onAction()}>
        {finishActionName}
      </ValidationFab>
    </>
  )
}
