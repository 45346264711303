import React, { useState } from 'react'

import { useContentDocumentsListQuery } from '../../features/admin/dte-task-templates/utils/api'
import { getEntityKey } from '../../model/base'
import type { MimeContentType } from '../../model/content'
import type { FaceContentDocumentReference } from '../../model/face-content-document'
import { ContentDocumentSelectorModal } from './content-document-selector-modal'
import { DocumentThumbnail } from './document-thumbnail'

type TValue = FaceContentDocumentReference

export function ContentDocumentSelector(props: {
  value: TValue | null | undefined
  readOnly?: boolean
  mimeTypeFilter?: MimeContentType[]
  required?: boolean
  onChange?: (value: TValue | null) => void
}): JSX.Element {
  const { value } = props
  const readOnly = Boolean(props.readOnly)
  const [showModal, setShowModal] = useState(false)
  const previewDocumentKey = value ? getEntityKey(value) : undefined
  const { data: documentsList, isLoading } = useContentDocumentsListQuery({
    isEnabled: !!previewDocumentKey,
    filterCodes: value?.code,
  })
  const previewDocument = previewDocumentKey
    ? documentsList?.find((document) => getEntityKey(document) === previewDocumentKey)
    : undefined

  return (
    <>
      <DocumentThumbnail
        loading={isLoading}
        document={previewDocument}
        showAddButton={!readOnly}
        showEditButton={!readOnly}
        onClick={readOnly ? 'openDocumentViewer' : () => setShowModal(true)}
      />
      {!readOnly && (
        <ContentDocumentSelectorModal
          open={showModal}
          initValue={value}
          mimeTypeFilter={props.mimeTypeFilter}
          required={props.required}
          onCancel={() => setShowModal(false)}
          onSubmit={(value) => {
            setShowModal(false)
            props.onChange?.(value)
          }}
        />
      )}
    </>
  )
}
