import { IDBPDatabase, IDBPTransaction } from 'idb'

import { BusinessError } from '../../model/errors'
import { LoggerBase } from '../logger'

type Ops = '=' | '<' | '>' | '<=' | '>=' | '<=<=' | '<<'

export type RangeDef = [Ops, ...unknown[]]

export type SortOrder = 'asc' | 'desc'

export type EntityKey = IDBValidKey

type IDBKeyPath = string

export interface IStorageSchema {
  version: number
  stores: {
    [name: string]: {
      keyPath?: IDBKeyPath
      autoIncrement?: boolean
      updateTrigger?: <T>(obj: T) => T
      index?: {
        [name: string]: {
          keyPath: IDBKeyPath
          unique?: boolean
          multi?: boolean
        }
      }
    }
  }
  data?: {
    [store: string]: unknown[]
  },
  refactor?: (
    oldVersion: number,
    newVersion: number,
    schema: IStorageSchema,
    db: IDBPDatabase,
    tx: IDBPTransaction<unknown, string[], 'versionchange'>,
    logger: LoggerBase
  ) => Promise<void>,
  migrate?: (
    oldVersion: number,
    newVersion: number,
    schema: IStorageSchema,
    db: IDBPDatabase,
    tx: IDBPTransaction<unknown, string[], 'versionchange'>,
    logger: LoggerBase
  ) => Promise<void>
}

export enum StorageErrorCode {
  Unknown = 'Unknown',
  OutOfSpace = 'OutOfSpace',
  Restriction = 'Restriction'
}

export class StorageError extends BusinessError<StorageErrorCode> {
  name = 'StorageError'
}

export interface IStorageOperations {
  put: <T>(store: string, items: T[] | T, bypassTrigger?: boolean) => Promise<EntityKey[]>

  putIf: <T>(
    store: string,
    items: T[] | T,
    keyFunc: (obj: T) => EntityKey,
    condition: (oldItem: T | undefined, newItem: T) => boolean,
    bypassTrigger?: boolean
  ) => Promise<EntityKey[]>

  putByKey: <T>(store: string, item: T, key: EntityKey) => Promise<EntityKey>

  exists: (store: string, key: EntityKey) => Promise<boolean>

  deleteAll: (store: string) => Promise<void>

  deleteByKey: (store: string, key: EntityKey) => Promise<void>

  deleteByKeys: (store: string, keys: EntityKey[]) => Promise<void>

  deleteByKeyRange: (store: string, range: RangeDef) => Promise<void>

  getWhere: <T>(store: string, predicate: (obj: T) => boolean, count?: number, order?: SortOrder) => Promise<T[]>

  getFirstWhere: <T>(store: string, predicate: (obj: T) => boolean) => Promise<T | undefined>

  existsWhere: <T>(store: string, predicate: (obj: T) => boolean) => Promise<boolean>

  getAll: <T>(store: string, order?: SortOrder) => Promise<T[]>

  getByKey: <T>(store: string, key: EntityKey) => Promise<T | undefined>

  getByKeys: <T>(store: string, keys: EntityKey[]) => Promise<T[]>

  getByKeyRange: <T>(store: string, range: RangeDef | null, count?: number) => Promise<T[]>

  getByIndexRange: <T>(store: string, index: string, range: RangeDef | null, count?: number) => Promise<T[]>

  getKeys: <TKey extends EntityKey>(store: string, range: RangeDef | null, count?: number) => Promise<TKey[]>

  getKeysByIndexRange: <TKey extends EntityKey>(store: string, index: string, range: RangeDef | null, count?: number) => Promise<TKey[]>

  getFirstByKeyRange: <T>(store: string, range: RangeDef | null) => Promise<T | undefined>

  getLastByKeyRange: <T>(store: string, range: RangeDef | null) => Promise<T | undefined>

  getFirstByIndexRange: <T>(store: string, index: string, range: RangeDef | null) => Promise<T | undefined>

  getLastByIndexRange: <T>(store: string, index: string, range: RangeDef | null) => Promise<T | undefined>

  getFirstKeyByKeyRange: <K extends EntityKey>(store: string, range: RangeDef | null) => Promise<K | undefined>

  getLastKeyByKeyRange: <K extends EntityKey>(store: string, range: RangeDef | null) => Promise<K | undefined>

  getFirstKeyByIndexRange: <K extends EntityKey>(
    store: string,
    index: string,
    range: RangeDef | null
  ) => Promise<K | undefined>

  getLastKeyByIndexRange: <K extends EntityKey>(
    store: string,
    index: string,
    range: RangeDef | null
  ) => Promise<K | undefined>

  count: (store: string) => Promise<number>

  countByKeyRange: (store: string, range: RangeDef | null) => Promise<number>

  countByIndexRange: (store: string, index: string, range: RangeDef | null) => Promise<number>

  countWhere: <T>(store: string, predicate: (obj: T) => boolean) => Promise<number>

  selectAll: <T>(store: string, order?: SortOrder) => AsyncIterableIterator<T>

  selectWhere: <T>(store: string, predicate: (obj: T) => boolean, order?: SortOrder) => AsyncIterableIterator<T>

  selectByKeyRange: <T>(store: string, range: RangeDef | null, order?: SortOrder) => AsyncIterableIterator<T>

  selectByIndexRange: <T>(
    store: string,
    index: string,
    range: RangeDef | null,
    order?: SortOrder
  ) => AsyncIterableIterator<T>

  updateWhere: <T>(
    store: string,
    predicate: (obj: T) => boolean,
    updateFunc: (obj: T, key?: EntityKey) => T | null | undefined
  ) => Promise<void>

  updateByIndexRange: <T>(
    store: string,
    index: string,
    range: RangeDef | null,
    updateFunc: (obj: T, key?: EntityKey) => T | null | undefined
  ) => Promise<void>

  updateByKeyRange: <T>(
    store: string,
    range: RangeDef | null,
    updateFunc: (obj: T, key?: EntityKey) => T | null | undefined
  ) => Promise<void>
}

export interface IStorageService extends IStorageOperations {
  drop: () => Promise<void>

  execute: <T>(stores: string[], action: (tx: IStorageOperations) => Promise<T>, mode?: 'r' | 'rw') => Promise<T>

  close: () => void

  dispose: () => void
}
