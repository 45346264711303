import React, { useState, useContext } from 'react'

import { Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { useAsync } from 'react-use'

import { LogManager } from '../../../../../infrastructure/logger'
import { IPointOfSale, IPOSReference } from '../../../../../model/pos'
import { ISelectPOSScreenItem } from '../../../../../model/screen-item'
import { checkPredicate } from '../../../../../model/script-predicate'
import { ApiContext } from '../../../../../providers'
import { appToast } from '../../../../../utils'
import { useUpdateProperty } from '../../../nested/useUpdateProperty'
import { getContextProperty } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { ItemCard } from '../item-card'
import PosListModal from './pos-list-modal'
import SelectedPosItem from './selected-pos-item'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chooseBtn: {
      paddingLeft: 0,
    },
  }),
)

interface IProps {
  item: ISelectPOSScreenItem
}

const logger = LogManager.getLogger('posSelectorScreenItem')

const PosSelectorScreenItem: React.FC<IProps> = ({ item }) => {
  const classes = useStyles()
  const api = useContext(ApiContext)
  const localContext = useScriptTaskContext()
  const updateProperty = useUpdateProperty()
  const record = getContextProperty<IPOSReference | undefined>(localContext, item.propertyName, undefined)
  const isRequired = item.required ? checkPredicate(item.required, {} as Record<string, unknown>, localContext) : false
  const [selectedItem, setSelectedItem] = useState<IPointOfSale | null>()
  const [isPosListModalOpen, setIsPosListModalOpen] = useState(false)
  const [isLoading, setisLoading] = useState(true)

  const reset = (): void => {
    setSelectedItem(undefined)
  }

  const toggleModalVisibility = (val: boolean): void => {
    setIsPosListModalOpen(val)
  }

  const selectPos = (val: IPointOfSale | null): void => {
    if (!val) return
    setSelectedItem(val)
    void updateProperty(item.propertyName, {
      $type: 'PMI.BDDM.Staticdata.POSReference',
      code: val.code,
      codeSpace: val.codeSpace,
    })
  }

  useAsync(async () => {
    if (record?.$type.includes('POSReference')) {
      try {
        const res = await api.pos.getPos(record.code)
        selectPos(res)
        setisLoading(false)
        return
      } catch (err) {
        setisLoading(false)
        const message = `Ошибка ${err.message}`
        appToast.info(message)
        logger.error('get', message, err)
        throw new Error(message)
      }
    } else {
      setisLoading(false)
    }
  }, [])

  return (
    <>
      {!isLoading && (
        <ItemCard isError={isRequired && !selectedItem} label={item.displayName}>
          {selectedItem ? (
            <SelectedPosItem item={selectedItem} toggleModalVisibility={toggleModalVisibility} reset={reset} />
          ) : (
            <Button className={classes.chooseBtn} onClick={() => toggleModalVisibility(true)}>
              <Typography color='primary'>Нажмите для выбора</Typography>
            </Button>
          )}
        </ItemCard>
      )}
      {isPosListModalOpen && (
        <PosListModal
          selectedItem={selectedItem}
          selectPos={selectPos}
          condiditon={item.condition}
          toggleModalVisibility={toggleModalVisibility}
        />
      )}
    </>
  )
}

export default PosSelectorScreenItem
