import React from 'react'

import { createStyles, Divider, Grid, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { grays } from '../../../../layout/theme'
import { IPOSParticipantProgram } from '../../../../model/dte-pos-participant-program'
import { dateFormat } from '../../../../utils'
import { useIsSmall } from '../../../_common/hooks/useIsSmall'
import { CardItemWrapper } from '../../components/card-item-wrapper'
import { getProgramStatus } from './utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalLine: {
      margin: 0,
      backgroundColor: grays.gray5,
      width: 2,
    },
    nameCol: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '50%',
      flex: '0 0 50%',
      paddingRight: '6%',
    },
    datesCol: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '28%',
      flex: '0 0 28%',
      paddingRight: '3%',
      paddingLeft: '3%',
    },
    statusCol: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '21%',
      flex: '0 0 21%',
      paddingLeft: '3%',
    },
    name: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.1px',
    },
    dates: {
      width: '100%',
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: '0.1px',
      [theme.breakpoints.down('xs')]: {
        lineHeight: '20px',
      },
    },
    status: {
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: grays.gray2,
    },
  }),
)

interface IProgramItem {
  participantProgram: IPOSParticipantProgram
  onClick: () => void
}

export const ProgramItem: React.FC<IProgramItem> = ({ participantProgram, onClick }) => {
  const classes = useStyles()
  const isSmall = useIsSmall()

  const program = participantProgram.assignedProgram

  if (isSmall) {
    return (
      <CardItemWrapper onClick={onClick}>
        <Typography noWrap className={classes.name}>
          {program.name}
        </Typography>
        <Typography noWrap className={classes.dates}>
          {dateFormat(program.validFrom, 'dd.MM.yyyy')}-{dateFormat(program.validTo, 'dd.MM.yyyy')}
        </Typography>
        <Typography noWrap className={classes.status}>
          {getProgramStatus(program)}
        </Typography>
      </CardItemWrapper>
    )
  }

  return (
    <CardItemWrapper onClick={onClick}>
      <Grid container>
        <Grid item className={classes.nameCol}>
          <Typography noWrap className={classes.name}>
            {program.name}
          </Typography>
        </Grid>
        {<Divider orientation='vertical' flexItem className={classes.verticalLine} />}
        <Grid item className={classes.datesCol}>
          <Typography noWrap className={classes.dates} align='center'>
            {dateFormat(program.validFrom, 'dd.MM.yyyy')}-{dateFormat(program.validTo, 'dd.MM.yyyy')}
          </Typography>
        </Grid>
        {<Divider orientation='vertical' flexItem className={classes.verticalLine} />}
        <Grid item className={classes.statusCol}>
          <Typography noWrap className={classes.status}>
            {getProgramStatus(program)}
          </Typography>
        </Grid>
      </Grid>
    </CardItemWrapper>
  )
}
