/* eslint-disable  @typescript-eslint/no-explicit-any */

export enum LogLevel {
  trace,
  debug,
  info,
  userMetric,
  warn,
  error,
  critical
}

export type LogContextItemKey = 'userName' | 'appVersion' | string

export interface LogContext {
  [key: string]: any
}

export interface LogEntry {
  level: LogLevel
  logger?: string
  tag?: string
  message?: string
  error?: any
  details?: any
}

export interface ILogger {
  log: (entry: LogEntry) => void
  flush: () => Promise<void>
}

export abstract class LoggerBase implements ILogger {
  public abstract log(entry: LogEntry): void

  public async flush(): Promise<void> {
    // do nothing by default
  }

  public debug(tag: string, message: string, details?: unknown): void {
    this.log({ level: LogLevel.debug, tag, message, details })
  }

  public info(tag: string, message: string, details?: unknown): void {
    this.log({ level: LogLevel.info, tag, message, details })
  }

  public userMetric(tag: string, message: string, details?: unknown): void {
    this.log({ level: LogLevel.userMetric, tag, message, details })
  }

  public warn(tag: string, message: string, error?: unknown, details?: unknown): void {
    this.log({ level: LogLevel.warn, tag, error, message, details })
  }

  public error(tag: string, message: string, error?: unknown, details?: unknown): void {
    this.log({ level: LogLevel.error, tag, error, message, details })
  }

  public critical(tag: string, message: string, error?: unknown, details?: unknown): void {
    this.log({ level: LogLevel.critical, tag, error, message, details })
  }
}
