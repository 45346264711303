import React from 'react'

import { createStyles, Icon, IconProps, makeStyles } from '@material-ui/core'

interface PublicSvgIconProps {
  path: string
}

const useStyles = makeStyles(() =>
  createStyles({
    iconMask: {
      // maskImage: 'url("/assets/icons/Logout.svg")',
      maskSize: 24,
      maskPosition: 'center',
      maskRepeat: 'no-repeat',
      background: 'currentColor',
      height: 24,
      // doesn't always work without -webkit, strips -webkit in firefox properly
      '-webkit-mask-image': (props: PublicSvgIconProps) => {
        const res = `url("${props.path}")`
        // console.log('jss maskImage is called with props', props, res)
        return res
      },
    },
  }),
)

export const PublicSvgIcon: React.FC<PublicSvgIconProps & IconProps> = ({ path, ...props }) => {
  const classes = useStyles({ path: `/assets/icons/${path}` })
  return (
    <Icon {...props}>
      <div className={classes.iconMask}></div>
    </Icon>
  )
}
