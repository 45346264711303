import React, { useContext } from 'react'

import { useTranslation } from 'react-i18next'

import { ModalContext } from '../../../providers/modal'
import VisitModal from '../../visits/components/visit-modal/visit-modal'

/** TODO: parametrize modal */
export function useConfirmationModal(): () => Promise<boolean> {
  const modalContext = useContext(ModalContext)
  const { t } = useTranslation('sales-expert-tasks')
  const handler = async (): Promise<boolean> =>
    new Promise<boolean>((resolve) => {
      console.log('running confirmation dialog')
      modalContext.open(
        <VisitModal
          open
          onOk={async () => {
            modalContext.close()
            resolve(true)
          }}
          onClose={() => {
            modalContext.close()
            resolve(false)
          }}
          title={t('taskFinishModalTitle')}
          message={t('taskFinishModalMessage')}
        />,
      )
    })
  return handler
}
