import { IDataObjectOperation } from '../../../../../model/user-profile'
import { UploadFileAction } from '../../../../../services/data-collection-service-api'

interface ActionOption {
  value: UploadFileAction
  title: string
  operations: [IDataObjectOperation, ...IDataObjectOperation[]]
}

export const actionOptions: ActionOption[] = [
  {
    value: 'Create',
    title: 'Create',
    operations: [IDataObjectOperation.Insert],
  },
  {
    value: 'Update',
    title: 'Update',
    operations: [IDataObjectOperation.Update],
  },
  {
    value: 'Upsert',
    title: 'Create or Update',
    operations: [IDataObjectOperation.Insert, IDataObjectOperation.Update],
  },
  {
    value: 'Merge',
    title: 'Merge',
    operations: [IDataObjectOperation.Update],
  },
  {
    value: 'Cancel',
    title: 'Delete',
    operations: [IDataObjectOperation.Delete],
  },
]
