import React, { useState } from 'react'

import { Box, createStyles, makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ValidationFab } from '../../../../../components/validation-fab'
import { PageContent } from '../../../../../layout/page-content'
import { TitleBar } from '../../../../../layout/title-bar'
import { appToast } from '../../../../../utils'
import { useIsSmall } from '../../../../_common/hooks/useIsSmall'
import { StoresSearch } from '../../../components'
import StoresList from '../../../stores-list/stores-list-virtual'

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    pageContainer: {
      paddingTop: 88,
      height: '100vh',
      [theme.breakpoints.down('xs')]: {
        paddingTop: 64,
      },
    },
  })
)

const ProblemPosSelectorPage: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation('problems')
  const isSmall = useIsSmall()
  const navigate = useNavigate()

  const [searchQuery, setSearchQuery] = useState<string>('')
  const [selectedPosCode, setSelectedPosCode] = useState<string | null>(null)
  const [offset, setOffset] = useState<unknown>()

  const goToCreateProblemPage = (): void => {
    navigate(`/problems/problemPosSelector/addProblem/${selectedPosCode}`, {
      state: {
        scrollOffset: offset,
      },
    })
  }
  const goBack = (): void => {
    navigate(-1)
  }
  const onSearchHandler = (queryString: string): void => setSearchQuery(queryString)

  return (
    <>
      <TitleBar onBack={goBack} fixed={true}>
        {t('selectPos')}
      </TitleBar>
      <PageContent className={classes.pageContainer}>
        <Box paddingX={isSmall ? 1 : 3} paddingY={3} display='flex' flexDirection='column' height='100%'>
          <div>
            <StoresSearch query={searchQuery} onChange={onSearchHandler} />
          </div>
          <StoresList 
            searchQuery={searchQuery} 
            menuAccess={true} 
            selectable
            isButtonNeeded
            selectedId={selectedPosCode}
            onSelect={(selectedPosCode) => {
              setSelectedPosCode(selectedPosCode)
            }}
            getScrollOffset={(offset) => {
              setOffset(offset)
            }}
          />
        </Box>
        <ValidationFab 
          aria-label='add'
          isValid={Boolean(selectedPosCode)}
          onClick={(isValid) => {
            if (isValid) {
              goToCreateProblemPage()
              return
            }
            appToast.info('Не выбрана торговая точка')
          }}
        >
          {t('next')}
        </ValidationFab>
      </PageContent>
    </>
  )
}

export default ProblemPosSelectorPage