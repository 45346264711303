import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  Box,
  CircularProgress,
  createStyles,
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import classnames from 'classnames'

import { ICompositeScreenItemLayout, JustifyFlexLayoutContent } from '../../../../model/composite-screen-item-layout'
import { IActionScreenItem } from '../../../../model/screen-item-base'
import { formatTemplateString } from '../../../../utils/format-template-string'
import { useIsSmall } from '../../../_common/hooks/useIsSmall'
import { useRichTextImg } from '../../../_common/hooks/useRichTextImg'
import { CloseIcon } from '../../../admin/data-manager/icons'
import { getContextProperty } from '../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../script-tasks/script-task-context'
import { ItemCard } from './item-card'
import { RichtextItemWrapper } from './view-rich-text-screen-item'

export interface IPopupRichtextScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.PopUpRichTextScreenItem'
  previewRichTextContent: string
  popUpCaption: string
  popUpRichTextContent: string
  /** @defaultValue false */
  useDefaultPadding: boolean
  /** @defaultValue 0 */
  borderRadius: number
  mobileLayout: ICompositeScreenItemLayout
  desktopLayout: ICompositeScreenItemLayout
}

interface PopupRichtextScreenItemProps {
  value: string
  item: IPopupRichtextScreenItem
  loading: boolean
  children?: never
}

interface IStylesProps {
  itemWidth?: number
  borderRadius?: number
  useDefaultPadding?: boolean
  hasTitle?: boolean
}

const useStyles = makeStyles<Theme, IStylesProps>((theme) =>
  createStyles({
    root: {
      '& .MuiPaper-root': {
        borderRadius: ({ borderRadius }) => `${borderRadius}px`,
        overflow: 'hidden',
        cursor: 'pointer',
      },
      '& .MuiPaper-root > div:nth-child(1)': {
        padding: ({ hasTitle, useDefaultPadding }) => (!hasTitle && !useDefaultPadding ? 0 : null),
      },
      '& .MuiPaper-root > div:nth-child(2)': {
        padding: ({ useDefaultPadding }) => (useDefaultPadding ? null : 0),
      },
    },
    itemBlock: { width: ({ itemWidth }) => `${itemWidth ?? 100}%` },
    loader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    dialogHeader: {
      position: 'fixed',
      top: '0px',
      right: '16px',
      left: '0px',
      display: 'flex',
      alignItems: 'center',
      padding: '16px 8px 16px 24px;',
      zIndex: 100,
      background: '#fff',
      transition: '0.1s top',

      fontSize: 18,
      lineHeight: '23px',
      fontWeight: 600,
      letterSpacing: '0.15px',
    },
    hideHeader: {
      top: '-64px',
    },
    dialogContent: { marginTop: '48px' },
  }),
)

const PopupRichtextScreenItem: React.FC<PopupRichtextScreenItemProps> = (props) => {
  const classes = useStyles({})
  const { value, item, loading } = props
  useEffect(() => {
    console.log('PopupRichtextScreenItem resolved value', value)
  }, [])
  return (
    <ItemCard label={item.displayName && !loading && <Typography variant='inherit'>{item.displayName}</Typography>}>
      {loading ? (
        <Box className={classes.loader}>
          <CircularProgress />
        </Box>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: value }}></div>
      )}
    </ItemCard>
  )
}

interface PopupRichtextScreenItemControlProps {
  item: IPopupRichtextScreenItem
  children?: never
}
export const PopupRichtextScreenItemControl: React.FC<PopupRichtextScreenItemControlProps> = (props) => {
  useEffect(() => {
    console.log('PopupRichtextScreenItemControl mount', props)
  }, [])

  const [openDialog, setOpenDialog] = useState(false)

  const context = useScriptTaskContext()

  const { item } = props
  const val = formatTemplateString(item.previewRichTextContent, (prop) => getContextProperty(context, prop))
  const { isLoading, value } = useRichTextImg(val)

  const toggleDialog = useCallback(() => {
    setOpenDialog((prev) => !prev)
  }, [])

  const { desktopLayout, mobileLayout } = item
  const isSmall = useIsSmall()
  const { width, horizontalAlignment } = useMemo(() => (isSmall ? mobileLayout ?? {} : desktopLayout ?? {}), [isSmall])
  const classes = useStyles({
    borderRadius: props.item.borderRadius,
    useDefaultPadding: props.item.useDefaultPadding,
    hasTitle: !!props.item.displayName,
    itemWidth: width,
  })
  return (
    <>
      <RichtextItemWrapper>
        <div
          style={{
            display: 'flex',
            justifyContent: `${JustifyFlexLayoutContent[horizontalAlignment]}`,
          }}
          className={classes.root}
        >
          <div onClick={toggleDialog} className={classes.itemBlock}>
            <PopupRichtextScreenItem value={value} item={item} loading={isLoading}></PopupRichtextScreenItem>
          </div>
        </div>
      </RichtextItemWrapper>

      <PopupRichtextScreenItemDialogControl
        item={item}
        openDialog={openDialog}
        toggleDialog={toggleDialog}
      ></PopupRichtextScreenItemDialogControl>
    </>
  )
}

interface PopupRichtextScreenItemDialogControlProps {
  item: IPopupRichtextScreenItem
  openDialog: boolean
  toggleDialog: () => void
  children?: never
}
const PopupRichtextScreenItemDialogControl: React.FC<PopupRichtextScreenItemDialogControlProps> = (props) => {
  useEffect(() => {
    console.log('PopupRichtextScreenItemDialogControl mount', props)
  }, [])

  const classes = useStyles({})
  const context = useScriptTaskContext()

  const { item, toggleDialog, openDialog } = props
  const val = formatTemplateString(item.popUpRichTextContent, (prop) => getContextProperty(context, prop))
  const { isLoading, value } = useRichTextImg(val)

  const contentRef = useRef<HTMLDivElement>(null)
  const [scroll, setScroll] = useState<number>(-1)
  const [hideHeader, setHideHeader] = useState(false)

  const scrollAction = (): void => {
    if (contentRef.current) {
      if (contentRef.current.scrollTop <= 0) {
        setHideHeader(false)
      } else if (scroll > contentRef.current.scrollTop) {
        setHideHeader(false)
      } else if (scroll < contentRef.current.scrollTop) {
        setHideHeader(true)
      }

      setScroll(contentRef.current.scrollTop)
    }
  }

  return (
    <Dialog fullScreen open={openDialog} onClose={toggleDialog} disableBackdropClick={true} disableEscapeKeyDown={true}>
      <DialogContent onScroll={scrollAction} ref={contentRef}>
        <div
          className={classnames(classes.dialogHeader, {
            [classes.hideHeader]: hideHeader,
          })}
        >
          <Typography style={{ flex: 1 }} variant='inherit'>
            {item.popUpCaption}
          </Typography>
          <IconButton edge='end' size='small' onClick={toggleDialog} aria-label='close'>
            <CloseIcon />
          </IconButton>
        </div>
        <RichtextItemWrapper>
          <div className={(classes.root, classes.dialogContent)}>
            <PopupRichtextScreenItemDialog value={value} loading={isLoading}></PopupRichtextScreenItemDialog>
          </div>
        </RichtextItemWrapper>
      </DialogContent>
    </Dialog>
  )
}

interface PopupRichtextScreenItemDialogProps {
  value: string
  loading: boolean
  children?: never
}
const PopupRichtextScreenItemDialog: React.FC<PopupRichtextScreenItemDialogProps> = (props) => {
  const classes = useStyles({})
  const { value, loading } = props
  useEffect(() => {
    console.log('PopupRichtextScreenItemDialog resolved value', value)
  }, [])

  return (
    <ItemCard>
      {loading ? (
        <Box className={classes.loader}>
          <CircularProgress />
        </Box>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: value }}></div>
      )}
    </ItemCard>
  )
}
