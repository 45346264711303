import React from 'react'

import { Button, createStyles, Paper, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import { grays } from '../../../layout/theme'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      zIndex: 3,
      width: '100%',
      display: 'flex',
      alignItems: 'flex-start',
      padding: '0 24px',
      boxShadow: '0px 3px 7px rgb(96 97 112 / 13%)',
      backgroundColor: '#EEF5FC',
      [theme.breakpoints.down('xs')]: {
        paddingTop: 5,
      },
    },
    messageWrap: {
      display: 'flex',
    },
    actions: {
      marginLeft: 'auto',
    },
    hideButton: {
      fontSize: 16,
      lineHeight: '23px',
      letterSpacing: '0.1px',
      fontWeight: 500,
      padding: '16px 0',
    },
    message: {
      color: grays.gray2,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '23px',
      letterSpacing: '0.15px',
      padding: '16px 0',
    },
  }),
)

interface IPreviousTasksInfoProps {
  isShow: boolean
  onHide: () => void
}

const PreviousTasksInfo: React.FC<IPreviousTasksInfoProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation('sales-expert-tasks')

  if (!props.isShow) {
    return <></>
  }

  return (
    <Paper elevation={0} square className={classes.root}>
      <div className={classes.messageWrap}>
        <Typography variant='caption' className={classes.message}>
          {props.children}
        </Typography>
      </div>
      <div className={classes.actions}>
        <Button
          variant='text'
          onClick={() => {
            props.onHide()
          }}
          color='primary'
          className={classes.hideButton}
        >
          {t('validationFormInfoClose')}
        </Button>
      </div>
    </Paper>
  )
}

export default PreviousTasksInfo
