import { ITask } from '../../../model/task'
import { IProfile } from '../../../model/user-profile'
import { IApiContext } from '../../../providers/api/api-context'
import { isTemplateAvailable } from '../../_common/hooks/useAvailableTemplateList'

export const getAvailableVisitTaskRegister = async (
  api: IApiContext,
  profile: IProfile,
  visitCode: string,
): Promise<ITask[]> => {
  const visit = await api.visits.getVisit(visitCode)
  const pointOfSaleCode = visit?.pointOfSaleCode
  const visitDate = visit?.plannedStartDate

  if (!(pointOfSaleCode && visitDate)) {
    return []
  }

  if (visit?.status === 'Finished' || visit?.status === 'Canceled') {
    return []
  }

  const taskRegisters = await api.tasks.searchTaskRegisters({ pointOfSaleCode, visitDate })

  const filteredTaskRegisters: ITask[] = []
  for await (const task of taskRegisters) {
    const templateRef = task.template
    if (!templateRef) return []

    const template = await api.tasks.getTaskTemplate(templateRef)

    if (template && isTemplateAvailable(template, profile, ['Proceed'])) {
      filteredTaskRegisters.push(task)
    }
  }

  return filteredTaskRegisters
}
