import { Container, Provider, Token } from './container'

export function provide<T>(token: Token<T>, factory: (container: Container) => T): Provider<T> {
  return {
    provide: token,
    factory,
  }
}

export function fromClass<T, Deps extends unknown[]>(
  Class: new (...args: Deps) => T,
  tokens: { [P in keyof Deps]: Token<Deps[P]> },
): (container: Container) => T {
  return function (container: Container) {
    const deps = tokens.map((dep) => container.get(dep)) as Deps
    return new Class(...deps)
  }
}
