/* eslint-disable @typescript-eslint/promise-function-async */
import {IBusinessParameters} from '../../model/business-parameters'
import {DateTime} from '../../model/common'
import {unique} from '../../utils/array'
import {CompositeAwaitable, IAwaitable} from '../../utils/awaitable'
import {ICancellationToken} from '../../utils/cancellation'
import {delay, whenOnline} from '../../utils/delay'
import {IDisposable, ObjectDisposedError} from '../../utils/disposable'
import {EventEmitter, IEvent} from '../../utils/event'
import {Job} from '../../utils/job'
import {MediaReceive, MediaReceiveTuple, uniqueMediaReceive} from '../../utils/media'
import {getPathExtension, joinPath} from '../../utils/path'
import {Task, TaskScheduler} from '../../utils/task-scheduler'
import globalEventBus from '../global-event-bus'
import {
  EVENT_FETCHED_BUSINESS_PARAMETERS,
  EVENT_NEED_RECEIVE_BLOBS,
  EVENT_NEED_REMOVE_BLOBS,
  EVENT_NEED_SEND_BLOBS, EventFetchedBusinessParametersArgType,
  EventNeedReceiveBlobsArgType,
  EventNeedRemoveBlobsArgType,
  EventNeedSendBlobsArgType,
} from '../global-events'
import {IHttpClientFactory} from '../http-client-factory'
import {LoggerBase, LogManager} from '../logger'
import {StorageErrorCode, StorageService} from '../storage-service'
import {
  BlobKey,
  BlobMetadata, BlobReceive,
  BlobStorageError,
  BlobStorageErrorCode,
  BlobStorageSyncStatus,
  BlobSyncStatus,
  IBlobStorage
} from './blob-storage-api'
import schema, {
  BlobMetaStore,
  BlobMetaStore_syncStatus,
  BlobReceiveQueueStore,
  BlobStore,
} from './blob-storage-schema'
import {cleanupBlobPredicate, getBlobKind} from './blob-utils'
import {BlobMetadataGenerator, MetadataGenerators} from './metadata-generators'
import MimeTypeExtensions, {getMimeTypeByExtension} from './mimetype-extensions'

export function generateKey(prefix: string): string {
  const seed = Math.floor(Math.random() * 1024 * 1024)
  return `${prefix}-${Date.now().toString(36)}-${seed.toString(16).padStart(5, '0')}`
}

interface BlobRecord {
  key: BlobKey
  blob: Blob
}

interface BlobReceiveQueueItem {
  key: BlobKey
  priority?: number
  timestamp: number
  deleteAfter?: DateTime
}

export interface BlobStorageSettings {
  blobStoragePath: string
  blobSecret?: string
  cleanupIntervalInMinutes: number
  purgeBlobsOlderThanDays: number
  purgeRequestsOlderThanDays: number
}

export type GeneratorUnion = 'exif' | 'thumbnail'

const BASIC_USER = '_blob_'

// количество потоков скачки/закачки
const DEFAULT_CONCURRENCY_LEVEL = 3
// на сколько увеличивать (линейно) время задержки (в ms) перед следующей попыткой скачки/закачки
const ATTEMPT_DELAY_FACTOR = 3000
// максимальное время задержки (в ms) перед следующей попыткой скачки/закачки
const MAX_ATTEMPT_DELAY = 5*60*1000

export class BlobStorage implements IBlobStorage, IDisposable {
  private static readonly __className = 'BlobStorage'
  private readonly logger: LoggerBase
  private readonly _storage: StorageService
  private readonly _httpClientFactory: IHttpClientFactory
  private readonly _settings: BlobStorageSettings
  private readonly _sendTaskScheduler: TaskScheduler
  private readonly _receiveTaskScheduler: TaskScheduler
  private readonly _cleanupJob: Job
  private readonly _storageFolder: string
  private readonly _name: string
  private readonly _auth: string | null

  private readonly _metadataGenerators = new MetadataGenerators<GeneratorUnion>(['exif', 'thumbnail'])
  private readonly _onBlobReceived = new EventEmitter<BlobKey>()

  get onBlobReceived(): IEvent<BlobKey> {
    return this._onBlobReceived
  }

  private readonly _onBlobSent = new EventEmitter<BlobKey>()
  get onBlobSent(): IEvent<BlobKey> {
    return this._onBlobSent
  }

  private readonly _onSending = new EventEmitter<BlobKey[]>()
  get onSending(): IEvent<BlobKey[]> {
    return this._onSending
  }

  private readonly _instanceId: string
  private _restoreTimeoutId?: number
  private _subscriptions?: IDisposable[]

  constructor(
    storageName: string,
    storageFolder: string,
    httpClientFactory: IHttpClientFactory,
    settings: BlobStorageSettings
  ) {
    this._name = `${BlobStorage.__className}[${storageName}]`
    this._instanceId = generateKey(BlobStorage.__className)
    console.debug(`${this._name}::ctor`)
    this.logger = LogManager.getLogger(this._name)
    this._httpClientFactory = httpClientFactory
    this._storage = new StorageService(storageName, schema)
    this._storageFolder = storageFolder
    this._settings = settings
    this._sendTaskScheduler = new TaskScheduler('blob-sender', DEFAULT_CONCURRENCY_LEVEL)
    this._receiveTaskScheduler = new TaskScheduler('blob-receiver', DEFAULT_CONCURRENCY_LEVEL)

    this._cleanupJob = new Job(
      'blob-storage-cleanup',
      this._settings.cleanupIntervalInMinutes * 60 * 1000,
      () => this._cleanup(),
      true
    )

    this._auth = settings.blobSecret ? 'Basic ' + btoa(`${BASIC_USER}:${settings.blobSecret}`) : null

    this._subscriptions = [
      globalEventBus.subscribe(EVENT_NEED_RECEIVE_BLOBS, (arg: EventNeedReceiveBlobsArgType) => void this.receive(arg)),
      globalEventBus.subscribe(EVENT_NEED_SEND_BLOBS, (arg: EventNeedSendBlobsArgType) => void this.send(arg)),
      globalEventBus.subscribe(EVENT_NEED_REMOVE_BLOBS, (arg: EventNeedRemoveBlobsArgType) => void this._deleteBlobs(arg)),
      globalEventBus.subscribe(EVENT_FETCHED_BUSINESS_PARAMETERS, (arg: EventFetchedBusinessParametersArgType) => void this._applyBusinessParameters(arg)),
    ]

    // postpone restoring send and receive tasks
    this._restoreTimeoutId = self.setTimeout(() => {
      this._cleanup().finally(() => {
        void this._restoreSendTasks()
        void this._restoreReceiveTasks()
      })
    }, 2 * 60 * 1000)
  }

  public registerMetadataGenerator(name: GeneratorUnion, generator: BlobMetadataGenerator): void {
    this._metadataGenerators.add(name, generator)
  }

  private async _put(blob: Blob, key: BlobKey, customMetadata?: Record<string, unknown>): Promise<BlobMetadata> {
    const metadata: BlobMetadata = {
      key: key,
      timestamp: Date.now(),
      size: blob.size,
      mimeType: blob.type,
      ...customMetadata
    }

    const exifMetadataGenerator = this._metadataGenerators.get('exif')
    try {
      await exifMetadataGenerator?.(blob, metadata)
    } catch (e) {
      this.logger.warn('_put', `Error generating metadata for type '${blob.type}'`, e)
    }

    const record: BlobRecord = { key: metadata.key, blob: blob }

    let attempt = 0
    while (true) {
      attempt++
      try {
        await this._storage.execute([BlobStore, BlobMetaStore], async tx => {
          await tx.put<BlobRecord>(BlobStore, record)
          await tx.put<BlobMetadata>(BlobMetaStore, metadata)
        })
        break
      } catch (e) {
        let errorCode = BlobStorageErrorCode.Unknown
        if (/*e instanceof StorageError && */e.code === StorageErrorCode.OutOfSpace) {
          console.warn(`${this._name}: Storage is out of space`)
          errorCode = BlobStorageErrorCode.OutOfSpace
          if (attempt <= 3) {
            await this._cleanup()
            continue /* retry save after cleanup */
          }
        } else if (e.code === StorageErrorCode.Restriction) {
          console.warn(`${this._name}: Storage is restricted`)
          errorCode = BlobStorageErrorCode.StorageRestricted
        }

        this.logger.error('_put', `Failed writing blob '${metadata.key}' to store (after attempt ${attempt})`, e, metadata)
        throw new BlobStorageError(errorCode, e.message ?? errorCode, e)
      }
    }

    try {
      await this._storage.deleteByKey(BlobReceiveQueueStore, key)
    } catch (e) {
      // rare issue, not critical - do not throw
      console.warn(`${this._name}: Failed deleting entry from blob-receive-queue`, e)
    }

    return metadata
  }

  private async _cleanup(): Promise<void> {
    this.logger.info('_cleanup', 'Cleaning up')
    try {
      await this._cleanupBlobStore(Date.now() - this._settings.purgeBlobsOlderThanDays * 24 * 60 * 60 * 1000)
    } catch (e) {
      this.logger.warn('_cleanup', `Blob-store cleanup failed`, e)
    }
    try {
      await this._cleanupReceiveQueue(Date.now() - this._settings.purgeRequestsOlderThanDays * 24 * 60 * 60 * 1000)
    } catch (e) {
      this.logger.warn('_cleanup', `Blob-receive-queue cleanup failed`, e)
    }
  }

  private async _cleanupBlobStore(syncTimeBefore: number): Promise<BlobKey[]> {
    console.debug(`${this._name}::_cleanupBlobStore(syncTimeBefore=${new Date(syncTimeBefore)})`)
    return this._storage.execute([BlobStore, BlobMetaStore], async tx => {
      const oldKeys = []
      for await (const md of tx.selectWhere<BlobMetadata>(BlobMetaStore, (md) => cleanupBlobPredicate(md, syncTimeBefore))) {
        oldKeys.push(md.key)
      }

      if (oldKeys.length) {
        console.info(`${this._name}: Removing ${oldKeys.length} obsolete blobs`, oldKeys)
        for (const oldKey of oldKeys) {
          await tx.deleteByKey(BlobMetaStore, oldKey);
          await tx.deleteByKey(BlobStore, oldKey);
        }
      }
      return oldKeys
    })
  }

  private async _cleanupReceiveQueue(timestampBefore: number): Promise<void> {
    console.debug(`${this._name}::_cleanupReceiveQueue(timestampBefore=${new Date(timestampBefore)})`)
    return this._storage.execute([BlobReceiveQueueStore], async tx => {
      const oldKeys = []
      for await (const md of tx.selectWhere<BlobMetadata>(BlobReceiveQueueStore, (md) => cleanupBlobPredicate(md, timestampBefore))) {
        oldKeys.push(md.key)
      }

      if (oldKeys.length) {
        console.info(`${this._name}: Removing ${oldKeys.length} old entries from blob-receive-queue`, oldKeys)
        await tx.deleteByKeys(BlobReceiveQueueStore, oldKeys)
      }
    })
  }

  private _deleteBlob(key: BlobKey): Promise<boolean> {
    return this._storage.execute(
      [BlobStore, BlobMetaStore, BlobReceiveQueueStore],
      async (tx) => {
        await tx.deleteByKey(BlobReceiveQueueStore, key)
        if (await tx.exists(BlobMetaStore, key)) {
          await tx.deleteByKey(BlobMetaStore, key)
          await tx.deleteByKey(BlobStore, key)
          return true
        }
        return false
      }
    )
  }

  private async _deleteBlobs(keys: BlobKey[] | Set<BlobKey>): Promise<void> {
    const uniqueKeys = unique(keys)
    if (uniqueKeys?.length) {
      this.logger.debug('_deleteBlobs', 'Deleting blobs', uniqueKeys)
      await this._storage.execute(
        [BlobStore, BlobMetaStore, BlobReceiveQueueStore],
        async (tx) => {
          await tx.deleteByKeys(BlobReceiveQueueStore, uniqueKeys)
          await tx.deleteByKeys(BlobMetaStore, uniqueKeys)
          await tx.deleteByKeys(BlobStore, uniqueKeys)
        }
      )
    }
  }

  private _applyBusinessParameters(bp: IBusinessParameters): void {
    if (bp?.purgeBlobsOlderThanDays) {
      try {
        this._settings.purgeBlobsOlderThanDays = parseFloat(bp.purgeBlobsOlderThanDays)
        this.logger.debug(
          '_applyBusinessParameters',
          `Applied parameter 'purgeBlobsOlderThanDays' = ${this._settings.purgeBlobsOlderThanDays}`
        )
      } catch (e) { /* dont care */ }
    }

    // FACE-3197
    if (bp.blobRequestLifetime) {
      try {
        this._settings.purgeRequestsOlderThanDays = parseFloat(bp.blobRequestLifetime) / 24.0 /* hours to days */
        this.logger.debug(
          '_applyBusinessParameters',
          `Applied parameter 'purgeRequestsOlderThanDays' = ${this._settings.purgeRequestsOlderThanDays}`
        )
      } catch (e) { /* dont care */ }
    }
  }

  public dispose(): void {
    console.debug(`${this._name}::dispose`)
    clearTimeout(this._restoreTimeoutId)
    this._restoreTimeoutId = undefined
    this._subscriptions?.forEach(s => s.dispose())
    this._subscriptions = undefined
    this._sendTaskScheduler.dispose()
    this._receiveTaskScheduler.dispose()
  }

  public async add(blob: Blob, customMetadata?: Record<string, unknown>): Promise<BlobMetadata> {
    console.debug(`${this._name}::add(blobSize=${blob.size}, blobType=${blob.type})`)
    const kind = getBlobKind(blob)
    const key = `${generateKey(kind)}.${MimeTypeExtensions[blob.type] ?? 'bin'}`

    const generateMetadata: BlobMetadata = {
      key: key,
      timestamp: Date.now(),
      size: blob.size,
      mimeType: blob.type,
      ...customMetadata
    }

    const thumbnailMetadataGenerator = this._metadataGenerators.get('thumbnail')
    try {
      await thumbnailMetadataGenerator?.(blob, generateMetadata)
    } catch (e) {
      this.logger.warn('add', `Error generating metadata for type '${blob.type}'`, e)
    }

    return this._put(blob, key, { kind: kind, dateTime: Date.now(), ...generateMetadata })
  }

  public async delete(key: BlobKey): Promise<boolean> {
    console.debug(`${this._name}::delete(key=${key})`)
    try {
      return await this._deleteBlob(key)
    } catch (e) {
      this.logger.error('delete', `Failed deleting blob '${key}' from storage`, e)
      throw e
    }
  }

  public async getBlob(key: BlobKey, receiveIfNotFound = false): Promise<Blob | undefined> {
    const record = await this._storage.getByKey<BlobRecord>(BlobStore, key)
    if (record != null || !receiveIfNotFound) {
      return record?.blob
    }
    if (!self.navigator.onLine) {
      throw new BlobStorageError(BlobStorageErrorCode.Offline, 'Cant receive blob while offline')
    }
    await this._downloadOrJoin({key}, 1)
    return (await this._storage.getByKey<BlobRecord>(BlobStore, key))?.blob
  }

  public async getMetadata(key: BlobKey, receiveIfNotFound = false): Promise<BlobMetadata | undefined> {
    let metadata = await this._storage.getByKey<BlobMetadata>(BlobMetaStore, key)
    if (!metadata) {
      if (!receiveIfNotFound) {
        return metadata
      }

      if (!self.navigator.onLine) {
        throw new BlobStorageError(BlobStorageErrorCode.Offline, 'Cant receive blob while offline')
      }
      await this._downloadOrJoin({key}, 1)
      metadata = await this._storage.getByKey<BlobMetadata>(BlobMetaStore, key)
    }

    if (metadata && !metadata.thumbnail) {
      const blob = await this.getBlob(key, false)

      if (blob) {
        const thumbnailMetadataGenerator = this._metadataGenerators.get('thumbnail')
        try {
          await thumbnailMetadataGenerator?.(blob, metadata)
        } catch (e) {
          this.logger.warn('getMetadata', `Error generating metadata for type '${blob.type}'`, e)
        }
      }
    }

    return metadata
  }

  public async count(): Promise<number> {
    return await this._storage.count(BlobStore)
  }

  public async totalSize(): Promise<number> {
    let totalSize = 0
    for await (const metadata of this._storage.selectAll<BlobMetadata>(BlobMetaStore)) {
      totalSize += metadata.size
    }
    return totalSize
  }

  /* v receiving v */
  private readonly _currentDownloads: Map<BlobKey, Promise<void>> = new Map<BlobKey, Promise<void>>()

  private async _downloadOrJoin(blobReceive: BlobReceive, attempts: number, ct?: ICancellationToken): Promise<void> {
    let currentDownload = this._currentDownloads.get(blobReceive.key)
    if (currentDownload) {
      console.debug(`${this._name}: Blob '${blobReceive.key}' is being currently downloaded`)
    } else {
      currentDownload = this._download(blobReceive, attempts, ct).finally(() => this._currentDownloads.delete(blobReceive.key))
      this._currentDownloads.set(blobReceive.key, currentDownload)
    }
    return currentDownload
  }

  private async _download(blobReceive: BlobReceive, attempts: number, ct?: ICancellationToken): Promise<void> {
    const { key: blobKey, deleteAfter } = blobReceive
    if (!this._settings.blobStoragePath) {
      this.logger.warn('_download', 'Download aborted - "blobStoragePath" setting not specified')
      return
    }

    const path = joinPath(this._settings.blobStoragePath, this._storageFolder, blobKey)
    const httpClient = this._httpClientFactory.getHttpClient()

    let blob: Blob
    let attempt = 0
    while (true) {
      if (ct?.isCancellationRequested) {
        return
      }
      try {
        await whenOnline(2 * 60 * 1000)
      } catch {
        /* timeout */
        continue
      }

      // check if blob is already in storage
      if (await this._storage.exists(BlobStore, blobKey)) {
        this.logger.info('_download', `Download aborted - blob '${blobKey}' is already in storage`)
        return
      }

      attempt++
      this.logger.debug('_download', `Downloading blob '${blobKey}' (attempt ${attempt})`)

      try {
        const response = await httpClient.get(path, {responseType: 'blob', timeout: 90000})
        const mimeType = response.headers['Content-Type'] ?? getMimeTypeByExtension(getPathExtension(blobKey))
        blob = new Blob([response.data], {type: mimeType})
        break
      } catch (e) {
        console.warn(`${this._name}: Error downloading blob '${blobKey}'`, e)
        if (e.response) {
          switch (e.response.status) {
            case 400:
              let text = '?'
              try {
                text = await e.response.data.text()
              } catch (e) { /* ignore */
              }
              this.logger.warn('_download', `Error downloading blob '${blobKey}' (${text})`, e)
              // HACK: Hardcoded server error string!
              if (text.includes('specified key does not exist')) {
                throw new BlobStorageError(
                  BlobStorageErrorCode.NotFound, `Blob '${blobKey}' not found on server`, {blobKey: blobKey}
                )
                // TODO: should retry to download NotFound blobs??
                // if (attempt > attempts) {
                //   throw new BlobStorageError(
                //     BlobStorageErrorCode.NotFound, `Blob '${blobKey}' not found on server`, {blobKey: blobKey}
                //   )
                // } else {
                //   this.logger.info('_download', `Blob '${blobKey}' not found on server - will retry download after while`)
                //   /* retrying 'not found' blobs some more times */
                //   const retryAttempts = Math.min(attempts, 5) - 1
                //   self.setTimeout(() => {
                //     try {
                //       this._receiveTaskScheduler.enqueueTask({
                //         id: `download:${blobKey}`,
                //         action: (ct) => this._download(blobKey, ct, retryAttempts)
                //       })
                //     } catch { /* ignore */ }
                //   }, 30000);
                // }
              }
              throw new BlobStorageError(
                BlobStorageErrorCode.BadRequest, `Blob download error: ${text}`, {blobKey: blobKey, error: text}
              )
            case 401:
              throw new BlobStorageError(BlobStorageErrorCode.Unauthorized, 'Unauthorized')
            case 404:
              throw new BlobStorageError(BlobStorageErrorCode.NotFound, `Blob '${blobKey}' not found on server`)
          }
        }
      }
      if (attempt >= attempts) {
        throw new BlobStorageError(
          BlobStorageErrorCode.Unknown, `Could not download blob '${blobKey}' after ${attempt} attempts`
        )
      }
      // sleep before next attempt
      const sleepTime = Math.min(ATTEMPT_DELAY_FACTOR * attempt, MAX_ATTEMPT_DELAY)
      this.logger.debug('_download', `Blob '${blobKey}' download falling asleep for ${sleepTime}ms before next attempt`)
      await delay(sleepTime)
    }

    await this._put(blob, blobKey, {syncTime: Date.now(), syncStatus: BlobSyncStatus.Received, deleteAfter})

    this._onBlobReceived.emit(blobKey)
  }

  public async receive(
    keys: MediaReceive,
    priority = 0,
    forced = false
  ): Promise<IAwaitable<Array<PromiseSettledResult<unknown>>>> {
    const uniqueReceiveTuples = uniqueMediaReceive(keys)
    if (uniqueReceiveTuples?.length > 0) {
      const newReceiveTuples: MediaReceiveTuple = []
      await this._storage.execute([BlobMetaStore, BlobReceiveQueueStore], async (tx) => {
        for (const key of uniqueReceiveTuples) {
          if (!(await tx.exists(BlobMetaStore, key[0]))) {
            if (forced) {
              newReceiveTuples.push(key)
            } else {
              const queueItem = await tx.getByKey<BlobReceiveQueueItem>(BlobReceiveQueueStore, key[0])
              if (queueItem == null || queueItem.priority !== priority) {
                newReceiveTuples.push(key)
              }
            }
          }
        }
        if (newReceiveTuples.length > 0) {
          const timestamp = Date.now()
          await tx.put(BlobReceiveQueueStore, newReceiveTuples.map((receiveTuple) =>
            <BlobReceiveQueueItem>{key: receiveTuple[0], timestamp, priority, deleteAfter: receiveTuple[1].deleteAfter})
          )
        }
      })
      if (newReceiveTuples.length > 0) {
        return new CompositeAwaitable(
          this._scheduleDownload(newReceiveTuples.map((receiveTuple) => [receiveTuple[0], priority, receiveTuple[1].deleteAfter])),
        )
      }
    }
    this.logger.debug('receive', 'Nothing new to receive')
    return new CompositeAwaitable()
  }

  private async _restoreReceiveTasks(): Promise<void> {
    console.debug(`${this._name}::_restoreReceiveTasks`)
    let items: BlobReceiveQueueItem[]
    try {
      items = await this._storage.execute(
        [BlobReceiveQueueStore],
        tx => tx.getAll<BlobReceiveQueueItem>(BlobReceiveQueueStore),
        'r'
      )
    } catch (e) {
      this.logger.error('_restoreReceiveTasks', 'Failed selecting pending blobs to receive', e)
      return
    }

    if (items.length > 0) {
      this._scheduleDownload(items.map(({key, priority, deleteAfter}) => [key, priority ?? 0, deleteAfter]))
    }
  }

  private _scheduleDownload(items: Array<[BlobKey, number, number | undefined]>): IAwaitable[] {
    this.logger.info('_scheduleDownload', 'Scheduling download', items.map(([key]) => key))
    const tasks = items.map(
      ([key, priority, deleteAfter]) =>
        <Task>{
          id: `download!${priority}:${key}`,
          priority: priority,
          deleteAfter: deleteAfter,
          action: (ct) => this._downloadOrJoin({ key, deleteAfter }, 1000, ct),
        },
    )
    try {
      this._receiveTaskScheduler.enqueueTasks(tasks)
    } catch (e) {
      if (e instanceof ObjectDisposedError) {
        console.warn(`${this._name}: TaskScheduler disposed`)
        return []
      } else {
        throw e
      }
    }
    return tasks.map(t => t.result!)
  }
  /* ^ receiving ^ */

  /* v sending v */
  private async _upload(metadata: BlobMetadata, attempts: number, ct?: ICancellationToken): Promise<void> {
    if (this._settings.blobStoragePath == null) {
      this.logger.warn('_upload', 'Upload aborted - "blobStoragePath" setting not specified')
      return
    }

    const key = metadata.key
    const record = await this._storage.getByKey<BlobRecord>(BlobStore, key)
    if (!record) {
      this.logger.warn('_upload', `Blob '${key}' not found in storage`)
      return
    }

    const path = joinPath(this._settings.blobStoragePath, this._storageFolder, key)
    const httpClient = this._httpClientFactory.getHttpClient(
      undefined,
      (config) => {
      if (this._auth) {
        config.headers = { ...config.headers, 'Authorization': this._auth }
      }
    })

    const blob = record.blob
    let attempt = 0

    while (true) {
      if (ct?.isCancellationRequested) { return }
      try { await whenOnline(2*60*1000) } catch /*timeout*/ { continue }

      attempt++
      this.logger.debug('_upload', `Uploading blob '${key}' (attempt ${attempt})`)

      try {
        await httpClient.put(path, blob, { headers: { 'Content-Type': blob.type }, timeout: 90000 })
        break
      } catch (e) {
        console.warn(`${this._name}: Error uploading blob '${key}'`, e)
        if (e.response) {
          switch (e.response.status) {
            case 400:
              let text = '?'
              try { text = await e.response.data.text() } catch (e) { /* ignore */ }
              this.logger.warn('_upload', `Error uploading blob '${key}' (${text})`, e)
              break
              /*
              throw new BlobStorageError(
                BlobStorageErrorCode.BadRequest, `Blob upload error: ${text}`, { blobKey: key, error: text }
              )
              */
            case 401:
              throw new BlobStorageError(BlobStorageErrorCode.Unauthorized, 'Unauthorized')
          }
        }
      }
      if (attempt >= attempts) {
        throw new BlobStorageError(
          BlobStorageErrorCode.Unknown, `Could not upload blob '${metadata.key}' after ${attempt} attempts`
        )
      }
      // sleep before next attempt
      const sleepTime = Math.min(ATTEMPT_DELAY_FACTOR * attempt, MAX_ATTEMPT_DELAY)
      this.logger.debug('_upload', `Blob '${metadata.key}' upload falling asleep for ${sleepTime}ms before next attempt`)
      await delay(sleepTime)
    }

    // updating blob sync status
    metadata.syncTime = Date.now()
    metadata.syncStatus = BlobSyncStatus.Sent

    // saving updated metadata (status)
    try {
      await this._storage.execute(
        [BlobMetaStore], tx => tx.put<BlobMetadata>(BlobMetaStore, metadata), 'rw', ct
      )
    } catch (e) {
      console.error(`${this._name}: Error updating blob '${metadata.key}' metadata`, e)
      throw e
    }

    this._onBlobSent.emit(metadata.key)
  }

  private _scheduleUpload(items: BlobMetadata[]): void {
    this.logger.info('_scheduleUpload', 'Scheduling upload', items.map(m => m.key))
    try {
      this._sendTaskScheduler.enqueueTasks(
        items.map(metadata => ({
          id: `upload:${metadata.key}`,
          action: (ct) => this._upload(metadata, 1000, ct)
        }))
      )
      this._onSending.emit(items.map(b => b.key))
    }
    catch (e) {
      if (e instanceof ObjectDisposedError) { console.warn(`${this._name}: TaskScheduler disposed`) } else throw e
    }
  }

  private async _restoreSendTasks(): Promise<void> {
    console.debug(`${this._name}::_restoreSendTasks`)
    let items
    try {
      items = await this._storage.execute(
        [BlobMetaStore],
        tx => tx.getByIndexRange<BlobMetadata>(BlobMetaStore, BlobMetaStore_syncStatus, ['=', BlobSyncStatus.Pending]),
        'r'
      )
    } catch (e) {
      this.logger.error('_restoreSendTasks', 'Failed selecting pending blobs to send', e)
      return
    }

    if (items.length > 0) {
      this._scheduleUpload(items)
    }
  }

  async send(keys: BlobKey[] | Set<BlobKey>): Promise<void> {
    const uniqueKeys = unique(keys)
    if (uniqueKeys == null || uniqueKeys.length === 0) {
      return
    }

    const blobsToSend: BlobMetadata[] = []
    await this._storage.execute(
      [BlobMetaStore],
      async tx => {
        for (const metadata of await tx.getByKeys<BlobMetadata>(BlobMetaStore, uniqueKeys)) {
          if (metadata.syncTime == null && (metadata.syncStatus == null || metadata.syncStatus === BlobSyncStatus.None)) {
            metadata.syncStatus = BlobSyncStatus.Pending
            blobsToSend.push(metadata)
          }
        }
        if (blobsToSend.length > 0) {
          await tx.put<BlobMetadata>(BlobMetaStore, blobsToSend)
        }
      }
    )

    if (blobsToSend.length > 0) {
      this._scheduleUpload(blobsToSend)
    }
  }
  /* ^ sending ^ */

  get syncStatus(): BlobStorageSyncStatus {
    return {
      receivingCount: this._receiveTaskScheduler.totalTaskCount,
      sendingCount: this._sendTaskScheduler.totalTaskCount,
    }
  }
}