import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import {IDisposable} from '../../utils/disposable'
import { EventEmitter, IEvent } from '../../utils/event'
import IAuthService from '../auth/auth-service-api'
import { getClientId } from '../client-id'
import CLIENT_VERSION from '../client-version'
import SPECIAL_HEADERS from '../headers'
import {IHttpClientFactory, RequestConfigurer} from './http-client-factory-api'

export default class HttpClientFactory implements IHttpClientFactory {
  private readonly _tz: number
  private readonly _defaultBaseUrl: string
  private readonly _clientId: string
  private readonly _clientVersion: string

  private _authService: IAuthService | null = null

  private readonly _configurers: RequestConfigurer[] = []

  private readonly _onRequestRejected = new EventEmitter<void>()

  public get onRequestRejected(): IEvent<void> {
    return this._onRequestRejected
  }

  constructor(defaultBaseUrl: string, authService?: IAuthService) {
    this._authService = authService ?? null
    this._defaultBaseUrl = defaultBaseUrl
    this._tz = -new Date().getTimezoneOffset()
    this._clientId = getClientId()
    this._clientVersion = CLIENT_VERSION
  }

  setAuthService(authService: IAuthService | null): void {
    this._authService = authService
  }

  registerRequestConfigurer(configurer: RequestConfigurer): IDisposable {
    this._configurers.push(configurer)
    return {
      dispose: () => {
        const idx = this._configurers.indexOf(configurer)
        if (idx >= 0) {
          this._configurers.splice(idx, 1)
        }
      }
    }
  }

  getHttpClient(baseUrl?: string, finalConfigurer?: RequestConfigurer): AxiosInstance {
    const config: AxiosRequestConfig = {
      baseURL: baseUrl ?? this._defaultBaseUrl,
      headers: {
        [SPECIAL_HEADERS.timezone]: this._tz,
        [SPECIAL_HEADERS.clientId]: this._clientId,
        [SPECIAL_HEADERS.clientVersion]: this._clientVersion
      }
    }

    // configuring request with current context
    this._configurers.forEach(c => c(config))
    this._authService?.configureAxiosRequest(config)
    finalConfigurer?.(config)

    const instance = axios.create(config)

    // adding interceptors
    instance.interceptors.response.use(
      undefined,
      // eslint-disable-next-line @typescript-eslint/promise-function-async
      (error) => {
        if (error.response) {
          if (error.response.status === 401) {
            this._onRequestRejected.emit()
          }
        }
        return Promise.reject(error)
      }
    )

    return instance
  }

  dispose(): void {
    this._onRequestRejected.dispose()
  }
}
