import { DependencyList, useCallback, useEffect } from 'react'

import { useAsyncFn } from 'react-use'
import { AsyncState } from 'react-use/lib/useAsyncFn'

// Inlined useAsync and useAsyncRetry to return awaitable retry

export type AsyncStateRetryPromise<T> = AsyncState<T> & {
  retry: () => Promise<T>
}

export const useAsyncRetryPromise = <T>(fn: () => Promise<T>, deps: DependencyList = []): AsyncStateRetryPromise<T> => {
  // const [attempt, setAttempt] = useState<number>(0)
  const [state, callback] = useAsyncFn(fn, deps, {
    loading: true
  })

  useEffect(() => {
    void callback()
  }, [callback])

  const stateLoading = state.loading
  const retry = useCallback(async () => {
    // if (stateLoading) {
    //   if (process.env.NODE_ENV === 'development') {
    //     console.log('You are calling useAsyncRetry hook retry() method while loading in progress, this is a no-op.')
    //   }
    //   return
    // }

    // setAttempt((currentAttempt) => currentAttempt + 1)
    return callback()
  }, [...deps, stateLoading])

  return { ...state, retry }
}
