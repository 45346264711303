import { useEffect, useMemo, useRef } from 'react'

import { DebouncedFunc, throttle, ThrottleSettings } from 'lodash'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFunction = (...args: any[]) => any

export function useThrottledFn<T extends AnyFunction>(
  fn: T,
  time: number,
  options?: ThrottleSettings
): DebouncedFunc<T> {
  const fnRef = useRef(fn)
  fnRef.current = fn

  const res = useMemo(() => {
    const throttled = throttle(
      ((...args) => {
        return fnRef.current(...args)
      }) as T,
      time,
      options
    )
    return throttled
  }, [time, options?.leading, options?.trailing])

  useEffect(() => {
    return () => void res.flush()
  }, [res])

  return res
}
