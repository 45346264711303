import { IBddmContent } from '../../../../../model/content'
import { IQuestionableListScreenItem } from '../../../../../model/questionable-list-screen-item'
import { checkPredicate, IPredicate } from '../../../../../model/script-predicate'
import { getContextProperty, getPropertyAny } from '../../../script-tasks/propertyName'
import { IScriptTaskContext } from '../../../script-tasks/script-task-context'
import { IValidation } from '../validate'
import { IQuestionableRecord } from './utils'

function checkIsAvailable(
  predicate: IPredicate | undefined,
  record: IQuestionableRecord,
  localContext: IScriptTaskContext,
): boolean {
  if (!predicate) {
    return true
  }
  return checkPredicate(predicate, record, localContext)
}

function checkIsRequired(
  predicate: IPredicate | undefined,
  record: IQuestionableRecord,
  localContext: IScriptTaskContext,
): boolean {
  if (!predicate) {
    return false
  }
  return checkPredicate(predicate, record, localContext)
}

function validateValue(value: unknown): boolean {
  if (typeof value === 'string') {
    return !!value.trim().length
  }
  return value != null
}

function validateComment(
  item: IQuestionableListScreenItem,
  record: IQuestionableRecord,
  localContext: IScriptTaskContext,
): boolean {
  const available = item.itemSettings.commentSettings?.available
  const required = item.itemSettings.commentSettings?.required

  const isRequired =
    checkIsAvailable(available, record, localContext) && checkIsRequired(required, record, localContext)

  if (isRequired) {
    const value = getPropertyAny(record, item.itemSettings.commentPropertyName ?? '')
    const res = validateValue(value)
    console.log('validating questionable comment', value, res)
    return res
  }
  return true
}

function validatePhotos(
  item: IQuestionableListScreenItem,
  record: IQuestionableRecord,
  localContext: IScriptTaskContext,
): boolean {
  const available = item.itemSettings.photosSettings?.available
  const required = item.itemSettings.photosSettings?.required

  const isRequired =
    checkIsAvailable(available, record, localContext) && checkIsRequired(required, record, localContext)
  if (isRequired) {
    const content = getPropertyAny(record, item.itemSettings.photosPropertyName ?? '') as IBddmContent
    const photos = content?.parts ? content.parts.map((item) => item.target) : []

    const maxOccurs = item.itemSettings.photosSettings?.maxPhotoCountAvailable ?? 5

    const res = photos.length >= 1 && maxOccurs >= photos.length
    return res
  }
  return true
}

function validateAnswer(
  item: IQuestionableListScreenItem,
  record: IQuestionableRecord,
  localContext: IScriptTaskContext,
): boolean {
  const isComplied = getPropertyAny<boolean | null>(record, item.itemSettings.answerPropertyName ?? '')
  const isAnswer = isComplied === false || isComplied === true

  return isAnswer
}

export function validateQuestionableRecords(
  records: IQuestionableRecord[],
  item: IQuestionableListScreenItem,
  localContext: IScriptTaskContext,
): { isValid: boolean; record?: IQuestionableRecord } {
  for (const record of records) {
    // console.log(record, 'recordssss')
    if (!validateAnswer(item, record, localContext)) {
      return {
        isValid: false,
        record,
      }
    }

    if (!validateComment(item, record, localContext)) {
      return {
        isValid: false,
        record,
      }
    }

    if (!validatePhotos(item, record, localContext)) {
      return {
        isValid: false,
        record,
      }
    }
  }
  return {
    isValid: true,
  }
}

export function validateQuestionableListScreenItem(
  item: IQuestionableListScreenItem,
  context: IScriptTaskContext,
): IValidation {
  const records = getContextProperty<IQuestionableRecord[]>(context, item.propertyName, [])

  if (!records?.length) {
    if (!item.skipWhenEmpty) {
      return {
        isError: true,
        message: item.nullValueCaption,
      }
    }
  }

  const { isValid } = validateQuestionableRecords(records, item, context)
  console.log(isValid)
  return {
    isError: !isValid,
    message: 'Заполнены не все обязательные поля',
  }
}
