import React from 'react'

import { Box, createStyles, Paper, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import SmAnswerResolution from './sm-answer-resolution'

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(1),
      position: 'relative',
      padding: theme.spacing(2, 11, 2, 3),
      borderLeft: ({ resolution }) =>
        `4px solid ${resolution === null ? '#98999B' : resolution ? '#538928' : '#B80718'}`
    },
    title: {
      fontSize: 14,
      lineHeight: '24px'
    },
    info: {
      marginTop: theme.spacing(2),
      display: 'flex'
    },
    commentWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      borderLeft: '1px solid rgba(33, 33, 33, 0.08)'
    },
    comment: {
      fontSize: 14,
      lineHeight: '24px',
      fontWeight: 500
    },
    answerMark: {
      position: 'absolute',
      top: 8,
      right: 8
    }
  })
)

interface IIsaResultItemProps {
  title: string
  // result: boolean | null
  result: string
  comment?: string
  answerMark: number | undefined
}

interface IStylesProps {
  resolution: boolean | null
}

const SMResultItem: React.FC<IIsaResultItemProps> = (props) => {
  const resolutionText = props.result ?? undefined
  const resolution: boolean | null = resolutionText === 'N/A' ? null : resolutionText === 'Да'

  const classes = useStyles({ resolution })
  return (
    <Paper square elevation={3} className={classes.root}>
      {props.answerMark != null ? (
        <Box className={classes.answerMark}>
          <Typography variant='overline' color='textSecondary'>
            Оценка: {props.answerMark}
          </Typography>
        </Box>
      ) : null}
      <Typography>
        <span className={classes.title}>{props.title}</span>
      </Typography>
      <div className={classes.info}>
        <SmAnswerResolution resolution={resolution} />
        {props.comment && (
          <div className={classes.commentWrapper}>
            <Typography className={classes.comment}>{props.comment}</Typography>
          </div>
        )}
      </div>
    </Paper>
  )
}

export default SMResultItem
