import { useCallback, useContext, useEffect, useRef } from 'react'

import { useAsyncFn } from 'react-use'

import { Code } from '../../../model/base'
import { ISurvey } from '../../../model/survey'
import { ApiContext } from '../../../providers'
import { useFetchPendingItems } from '../../../providers/menu-data/pending-items-store'
import { useLocalContextService } from '../../tasks/nested/local-context'
import { resolvePropertyName } from '../../tasks/nested/useUpdateProperty'
import { IScriptTaskContext, useScriptTaskContext } from '../../tasks/script-tasks/script-task-context'
import { AsyncStateRetryPromise } from './useAsyncRetry'

export function getSurveysByPropertyName(propertyName: string, context: IScriptTaskContext): ISurvey[] {
  const { surveys, subProcessStack } = context
  const resolvedPropertyName = resolvePropertyName(propertyName || 'task.surveys', subProcessStack)
  return surveys[resolvedPropertyName] ?? []
}

export function useCreateSurvey(
  questionnaireCode: Code | undefined,
  propertyName = 'task.surveys',
): AsyncStateRetryPromise<ISurvey | null> {
  const api = useContext(ApiContext)
  const context = useScriptTaskContext()
  const { task, subProcessStack } = context
  const surveys = getSurveysByPropertyName(propertyName, context)
  const fetchPendingItems = useFetchPendingItems()
  const createdSurvey = surveys.find((survey) => survey.questionnaire.code === questionnaireCode)
  const contextService = useLocalContextService()
  const startedRef = useRef(false)

  const [{ error }, run] = useAsyncFn(async (): Promise<void> => {
    const resolvedPropertyName = resolvePropertyName(propertyName, subProcessStack)
    await api.tasks.createNewTaskSurvey(task!.code, questionnaireCode!, resolvedPropertyName.replace(/^task./, ''))
    await contextService.refetch()
  }, [task?.code, questionnaireCode, propertyName, subProcessStack])

  useEffect(() => {
    if (startedRef.current || createdSurvey) {
      return // done
    }
    if (!questionnaireCode) {
      return console.log('waiting for questionnaire to create survey')
    }
    startedRef.current = true
    console.log('creating survey for', questionnaireCode, surveys)
    void run()
  }, [createdSurvey, questionnaireCode])

  const retry = useCallback(async () => {
    // TODO: maybe add dedicated refetch method for surveys, clean this up
    const { surveys } = await contextService.refetch()
    const surveysByPropertyName = getSurveysByPropertyName(propertyName, {
      surveys,
      subProcessStack,
    } as IScriptTaskContext)
    await contextService.updateStackSteps()

    fetchPendingItems()
    return surveysByPropertyName.find((survey) => survey.questionnaire.code === questionnaireCode) ?? null
  }, [fetchPendingItems])

  if (error) return { error, loading: false, retry }
  if (createdSurvey) return { value: createdSurvey, loading: false, retry }

  return { loading: true, retry }
}
