import { runSyncScript } from '../features/tasks/script-tasks/run-sync-script'
import { IScriptTaskContext } from '../features/tasks/script-tasks/script-task-context'
import { IEntityReference } from './base'

export type IPredicate = ConstPredicate | ScriptPredicate | InlinePredicate

export type IFaceScriptDialect = 'JavaScript'

export interface ConstPredicate {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate'
  value: boolean
}

export interface ScriptPredicate {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.ScriptPredicate'
  script: IEntityReference
}

export interface InlinePredicate {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.InlineScriptPredicate'
  scriptBody: string
  dialect: IFaceScriptDialect
}

export function checkPredicate(predicate: IPredicate, record: unknown, localContext: IScriptTaskContext): boolean {
  const { template } = localContext

  switch (predicate.$type) {
    case 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate': {
      return predicate.value
    }

    case 'PMI.FACE.BDDM.Extensions.Classes.ScriptPredicate': {
      const script = template.scripts[predicate.script.code]

      if (!script) throw new Error('Predicate script not found: ' + predicate.script.code)
      return runSyncScript(script.body, { ...localContext, record })
    }

    case 'PMI.FACE.BDDM.Extensions.Classes.InlineScriptPredicate': {
      const script = predicate.scriptBody
      return runSyncScript(script, { ...localContext, record })
    }
  }
}
