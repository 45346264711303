import React, { useEffect } from 'react'

import { ICustomScreenItem } from '../../../../model/screen-item'
import {
  IRefreshAuditedVisitsButtonScreenItem,
  RefreshAuditedVisitsButton,
} from '../../audit/refresh-audited-visits-button'
import { VisitCardCustomItem } from '../../audit/visit-card-custom-item'

interface CustomScreenItemProps {
  children?: never
  item: ICustomScreenItem
}

export const CustomScreenItemControl: React.FC<CustomScreenItemProps> = (props) => {
  useEffect(() => {
    console.log('CustomScreenItem mount', props)
  }, [])
  const { item } = props

  switch (item.screenItemCode) {
    case 'VisitCard':
      return <VisitCardCustomItem />
    case 'RefreshAuditedVisitsButton':
      return (
        <RefreshAuditedVisitsButton
          item={
            {
              $type: 'PMI.FACE.BDDM.Extensions.Classes.RefreshAuditedVisitsButtonScreenItem',
            } as IRefreshAuditedVisitsButtonScreenItem
          }
        />
      )
    default:
      return (
        <>
          Unimplemented CustomScreenItem {item.screenItemCode} for {item.propertyName}
        </>
      )
  }
}
