/* eslint-disable  @typescript-eslint/no-explicit-any */
import {AxiosInstance} from 'axios'

import {DateTime} from '../../model/common'
import {gzip} from '../../utils/compression'
import {Job} from '../../utils/job'
import {IHttpClientFactory} from '../http-client-factory'
import {StorageService} from '../storage-service'
import {ILogger, LogContext, LogEntry, LogLevel} from './logger-api'
import schema, {RecordStore, RecordStoreTimestampIndex} from './logger-schema'

interface LogRecord {
  id?: any
  timestamp: DateTime
  level: string
  logger?: string
  tag?: string
  message?: string
  error?: any
  details?: any
  context?: LogContext
}

function getLevelString(level: LogLevel): string {
  if (level === LogLevel.trace) {
    return 'Trace'
  } else if (level === LogLevel.debug) {
    return 'Debug'
  } else if (level === LogLevel.userMetric) {
    return 'UserMetric'
  } else if (level === LogLevel.warn) {
    return 'Warn'
  } else if (level === LogLevel.error) {
    return 'Error'
  } else if (level === LogLevel.critical) {
    return 'Error'
  }
  return 'Info'
}

const DEFAULT_FLUSH_INTERVAL = 3 * 60 * 1000 // flush interval in ms
const DEFAULT_BATCH_SIZE = 100               // upload batch size
const MIN_RECORDS_TO_COMPRESS = 3            // minimum record count to apply compression
const MIN_SIZE_TO_COMPRESS = 4096            // minimum payload size to apply compression
const URL = 'log'
const AUTH = `Basic ${btoa('_log_:923f41bf-ab23-4751-96d8-9f1478c375b0')}`

export class LoggerService implements ILogger {
  private readonly _storage: StorageService
  private readonly _context: LogContext
  private readonly _uploadJob: Job
  private _minLogLevel: LogLevel
  private _httpClientFactory: IHttpClientFactory | null = null
  private _uploading = false

  constructor(context: LogContext, minLogLevel = LogLevel.warn) {
    this._context = context
    this._minLogLevel = minLogLevel
    this._storage = new StorageService('_log', schema)

    // eslint-disable-next-line @typescript-eslint/promise-function-async
    this._uploadJob = new Job('log-flush', DEFAULT_FLUSH_INTERVAL, () => this.flush())
  }

  public setFlushInterval(interval: number): void {
    this._uploadJob.interval = interval
  }

  public setMinimumLogLevel(minLogLevel: LogLevel): void {
    this._minLogLevel = minLogLevel
  }

  public setHttpClientFactory(httpClientFactory: IHttpClientFactory | null): void {
    this._httpClientFactory = httpClientFactory
    if (this._httpClientFactory == null) {
      this._uploadJob.suspend()
    } else {
      this._uploadJob.resume()
    }
  }

  public log(entry: LogEntry): void {
    if (entry.level < this._minLogLevel) {
      return
    }

    const record: LogRecord = {
      timestamp: Date.now(),
      level: getLevelString(entry.level),
      logger: entry.logger,
      tag: entry.tag,
      message: entry.message,
      error:
        entry.error instanceof Error
          ? { code: (entry.error as any).code, name: entry.error.name, message: entry.error.message }
          : entry.error,
      details: entry.details,
      context: this._context
    }

    try {
      void this._storage.put(RecordStore, record)
    } catch (e) {
      console.error('LoggerService: Error saving log record', e)
      return
    }

    if (entry.level === LogLevel.critical || entry.level === LogLevel.userMetric) {
      this._uploadJob.execute()
    }
  }

  public async flush(): Promise<void> {
    while (await this._uploadBatch(DEFAULT_BATCH_SIZE)) {}
  }

  private async _uploadRecords(client: AxiosInstance, records: LogRecord[]): Promise<void> {
    console.log(`LoggerService: Uploading ${records.length} log records`)

    if (records.length < MIN_RECORDS_TO_COMPRESS) {
      await client.post(URL, records)
    } else {
      const json = JSON.stringify(records)
      if (json.length < MIN_SIZE_TO_COMPRESS) {
        await client.post(URL, records)
      } else {
        const data = await gzip(json)
        await client.post(URL, data, { headers: {'Content-Encoding': 'gzip', 'Content-Type': 'application/json'} })
      }
    }
  }

  private async _uploadBatch(count: number): Promise<boolean> {
    if (this._uploading) {
      return false
    }
    if (this._httpClientFactory == null) {
      return false
    }
    if (!window.navigator.onLine) {
      return false
    }
    this._uploading = true
    try {
      let records
      try {
        records = await this._storage.getByIndexRange<LogRecord>(RecordStore, RecordStoreTimestampIndex, null, count)
      } catch (e) {
        console.warn('LoggerService: Error reading log records', e)
        return false
      }
      if (records == null || records.length === 0) {
        return false
      }
      const client = this._httpClientFactory.getHttpClient(undefined, config => {
        config.headers = { ...config.headers, 'Authorization': AUTH }
      })
      await this._uploadRecords(client, records)
      await this._storage.deleteByKeys(RecordStore, records.map((r) => r.id))
    } finally {
      this._uploading = false
    }
    return true
  }

  public dispose(): void {
    this._httpClientFactory = null
    this._uploadJob.dispose()
  }
}
