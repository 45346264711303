import React, { useContext, useEffect } from 'react'

import { uniq } from 'lodash'
import { useAsync } from 'react-use'

import { EmptyMessage } from '../../../components'
import { Code } from '../../../model/base'
import { BusinessError } from '../../../model/errors'
import { ApiContext, ProfileContext } from '../../../providers'
import { ILocalContext } from '../nested/local-context'
import { tryFindTaskTemplate } from '../nested/tryFindTaskTemplate'
import { ScriptTaskContext, useScriptTaskContext } from '../script-tasks/script-task-context'
import { CompositeResultScreen } from '../template-tasks/composite-screen/composite-result-screen'
import { SimpleSurveyTaskResult } from '../template-tasks/simple-survey/simple-survey-task-result'

interface TaskResultViewProps {
  children?: never
  targetTaskCode: Code
}

export const TaskResultView: React.FC<TaskResultViewProps> = (props) => {
  const api = useContext(ApiContext)
  const profile = useContext(ProfileContext)
  const outerContext = useScriptTaskContext()
  const { targetTaskCode } = props
  useEffect(() => {
    console.log('TaskResultView mount', props)
  }, [])

  const contextOps = useAsync(async () => {
    const unit = await api.tasks.getSupervisedTaskUnit(targetTaskCode)
    if (!unit) throw new Error(`unit ${targetTaskCode} not found`)
    const template = await tryFindTaskTemplate(api, profile.value!.profile, unit.task)

    // TODO: handle nested surveys or not
    const surveys: ILocalContext['surveys'] = { 'task.surveys': unit.surveys ?? [] }
    const questionnaires =
      unit.surveys != null
        ? await api.questionnaire.getQuestionnaires(uniq(unit.surveys.map((s) => s.questionnaire.code)))
        : []

    return { ...unit, surveys, template, questionnaires }
  }, [targetTaskCode])

  if (contextOps.error) {
    if (contextOps.error instanceof BusinessError) {
      return <EmptyMessage message='Недостаточно прав для просмотра задачи' />
    } else {
      return <>{contextOps.error.message}</>
    }
  }

  if (contextOps.loading) return <></>
  const resultScreenContext = contextOps.value!

  const renderResultScreen = (): JSX.Element => {
    const { template } = resultScreenContext ?? {}

    console.log('resultScreen', contextOps)

    if (template && !template.resultScreen) {
      return <EmptyMessage message={`Отсутствует экран результата задачи в шаблоне ${template.code}`} />
    }

    switch (template?.resultScreen.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.SimpleSurveyTaskResultScreen':
        return (
          <SimpleSurveyTaskResult title={template.name} questionnaireCode={template.resultScreen.questionnaire.code} />
        )
      case 'PMI.FACE.BDDM.Extensions.Classes.CompositeTaskResultScreen':
        return <CompositeResultScreen screen={template.resultScreen} />
      default:
        return <h1>Неверный тип: {template.resultScreen.$type}</h1>
    }
  }
  return (
    <ScriptTaskContext.Provider
      value={{
        ...outerContext,
        ...resultScreenContext,
      }}
    >
      {renderResultScreen()}
    </ScriptTaskContext.Provider>
  )
}
