import { ILogger, LogEntry, LoggerBase, LogLevel } from './logger-api'

export class CompositeLogger extends LoggerBase {
  private readonly _loggers: ILogger[]
  private readonly _minLogLevel: LogLevel

  constructor(loggers: ILogger[], minLogLevel: LogLevel = LogLevel.debug) {
    super()
    this._loggers = loggers.filter((logger) => logger != null)
    this._minLogLevel = minLogLevel
  }

  log(entry: LogEntry): void {
    if (entry.level >= this._minLogLevel) {
      this._loggers.forEach((logger) => logger.log(entry))
    }
  }

  async flush(): Promise<void> {
    for (const logger of this._loggers) {
      await logger.flush()
    }
  }
}
