import { BlobMetadata } from './blob-storage-api'

export type BlobMetadataGenerator = (blob: Blob, metadata: BlobMetadata) => Promise<void>

export class MetadataGenerators<T extends string> {
  private readonly _metadataGenerators = {} as Record<T, BlobMetadataGenerator | null>

  constructor(registeredNames: T[]) {
    for (const name of registeredNames) {
      this._metadataGenerators[name] = null
    }
  }

  public get(name: T): BlobMetadataGenerator | null {
    return this._metadataGenerators[name]
  }

  public add(name: T, generator: BlobMetadataGenerator): void {
    this._metadataGenerators[name] = generator
  }
}