import React, { useEffect, useState } from 'react'

import { makeStyles, TextField, TextFieldProps, Typography, Theme, createStyles, Box } from '@material-ui/core'
import classNames from 'classnames'

import { IScreenItem, IStringPropertyScreenItem } from '../../../../model/screen-item'
import { useUpdateProperty } from '../../nested/useUpdateProperty'
import { ItemCard } from './item-card'
import { useThrottledFn } from './useThrottledFn'
import { IValidation } from './validate'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    useEllipsis: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    useLineBreak: {
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
    },
  }),
)

type Props = {
  item: IStringPropertyScreenItem
  isError?: boolean
  isFilled?: boolean
  errorMessage?: string
} & TextFieldProps

export const StringItem: React.FC<Props> = ({ item, isError, errorMessage, value, isFilled = false, ...props }) => {
  const classes = useStyles()

  const { actionKind, displayName, editSettings } = item
  const readOnly = actionKind !== 'Edit'
  const multiline = true

  const getRequiredErrorProps = (): {
    helperText?: React.ReactNode
    FormHelperTextProps?: { error: boolean }
    error?: boolean
  } => {
    if (isError) {
      return {
        helperText: errorMessage,
        FormHelperTextProps: { error: true },
        error: true,
      }
    }
    return {}
  }

  if (readOnly && item.viewSettings?.hideEmpty && !value) {
    return <></>
  }

  return (
    <ItemCard
      isError={!readOnly && editSettings?.required && !isFilled}
      label={<Typography variant='inherit'>{displayName}</Typography>}
    >
      {readOnly && (
        <Typography
          variant='body1'
          color='textSecondary'
          className={classNames({
            [classes.useEllipsis]: !multiline,
            [classes.useLineBreak]: multiline,
          })}
        >
          {String(value) || (item.viewSettings?.nullValueCaption ?? '-')}
        </Typography>
      )}
      {!readOnly && (
        <Box marginY={inputExtraMargin}>
          <TextField
            // size='small'
            multiline={multiline}
            fullWidth
            variant='outlined'
            placeholder={editSettings?.placeholder}
            {...getRequiredErrorProps()}
            value={value}
            {...props}
          />
        </Box>
      )}
    </ItemCard>
  )
}

const inputExtraMargin = { xs: 0, sm: 1 }

interface IStringItemContainerProps {
  item: IStringPropertyScreenItem
  onChange: (item: IScreenItem, value: string, oldValue: string) => Promise<void>
  onBlur: () => void
  state: { value: string; touched: boolean; validate: IValidation }
}

export const StringItemContainer: React.FC<IStringItemContainerProps> = (props) => {
  const { item, onChange, onBlur, state } = props
  const key = item.propertyName
  const { value, touched, validate } = state
  const updateProperty = useUpdateProperty()

  const setTaskPropertyPath = async (value: unknown): Promise<void> => {
    await updateProperty(item.propertyName, value)
  }

  const [currentValue, setCurrentValue] = useState<string>('')

  useEffect(() => {
    if (item.actionKind === 'Edit' && currentValue) {
      void setTaskPropertyPath(currentValue)
    }
  }, [validate.isError])

  const throttleSetTaskPropertyPath = useThrottledFn(setTaskPropertyPath, 500, { trailing: true })

  return (
    <StringItem
      key={key}
      item={item}
      isFilled={!validate.isError}
      isError={touched && validate.isError}
      errorMessage={validate.message}
      value={value}
      onChange={(evt) => {
        void onChange(item, evt.target.value, value)
        void throttleSetTaskPropertyPath(evt.target.value.trim())
        setCurrentValue(evt.target.value)
      }}
      onBlur={() => {
        onBlur()
        void throttleSetTaskPropertyPath.flush()
      }}
    />
  )
}
