import { useContext } from 'react'

import { IPermission, SHOW_PROBLEMS_LIST_ON_VISIT_CARD } from '../../../model/user-profile'
import { ProfileContext } from '../../../providers'

export const usePermissionsShowProblemsListOnVisitCard = (): boolean => {
  const profile = useContext(ProfileContext)
  const permissions: IPermission[] | undefined = profile?.value?.profile.permissions

  if (permissions?.find((permission) => permission.code === SHOW_PROBLEMS_LIST_ON_VISIT_CARD)) {
    return true
  }
  return false
}
