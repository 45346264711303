import React from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useTranslation } from 'react-i18next'

interface ICancelVisitModalProps {
  showOkButton?: boolean
  open: boolean
  onClose: () => void
  onOk: () => void
  okButtonText?: string
  closeButtonText?: string
  message: string
  title: string
}

const VisitModal: React.FC<ICancelVisitModalProps> = ({
  showOkButton = true,
  okButtonText,
  closeButtonText,
  open,
  onClose,
  onOk,
  message,
  title
}) => {
  const { t } = useTranslation()
  return (
    <Dialog
      disableScrollLock
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          {closeButtonText ?? t('modalClose')}
        </Button>
        {showOkButton && (
          <Button onClick={onOk} autoFocus color='primary'>
            {okButtonText ?? t('modalOk')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default VisitModal
