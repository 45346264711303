import React, { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState } from 'react'

import {
  Avatar,
  Box,
  Chip,
  createStyles,
  InputBase,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import ToggleButton from '@material-ui/lab/ToggleButton'
import { addDays, endOfDay, startOfDay } from 'date-fns'
import { groupBy } from 'lodash'
import { useAsync } from 'react-use'

import EmptyMessage from '../../../components/empty-message'
import { ToggleButtons } from '../../../components/toggle-buttons'
import { grays } from '../../../layout/theme'
import { IEntityReference } from '../../../model/base'
import { checkConditionLists } from '../../../model/condition-lists'
import { IStringPropertyScreenItem } from '../../../model/screen-item'
import { ISupervisedVisitUnit } from '../../../model/supervised-visit-unit'
import { ITaskTemplate } from '../../../model/task-template'
import { IPositionRoleReference } from '../../../model/user'
import { ISelectEmployeeForAuditUserStepScreen } from '../../../model/user-step-screen'
import { IVisit } from '../../../model/visit'
import { ApiContext } from '../../../providers'
import { SearchSupervisedVisitsRequest } from '../../../services/audit-service-api'
import { appToast, dateFormat } from '../../../utils'
import { useAsyncError } from '../../_common/hooks/useAsyncError'
import { useIsSmall } from '../../_common/hooks/useIsSmall'
import { useTemplatesByRelations } from '../../_common/hooks/useTemplatesByRelations'
import { getPosAddress } from '../../_common/pos'
import { useBatchUpdate } from '../nested/useBatchUpdate'
import { getContextProperty } from '../script-tasks/propertyName'
import { useScriptTaskContext } from '../script-tasks/script-task-context'
import { AccordionArrow, AccordionCard } from '../template-tasks/composite-screen/accordion-card'
import { StringItem } from '../template-tasks/composite-screen/string-item'
import { ScreenRef } from '../template-tasks/types'
import { AuditMerchandiseServiceTask } from './audit-merchandise-service-task'

interface MerchendizerScreenProps {
  children?: never
  onReadyChange: (isReady: boolean) => void
  screen: ISelectEmployeeForAuditUserStepScreen
}

interface Merchendizer {
  code: string
  name: string
  date: Date
  visits: ISupervisedVisitUnit[]
}

type AuditPeriod = 'today' | 'yesterday' | 'both'
const periods: Array<{ name: string; value: AuditPeriod }> = [
  { name: 'В день аудита', value: 'today' },
  { name: 'За день до аудита', value: 'yesterday' },
  { name: 'Оба дня', value: 'both' },
]

const useStyles = makeStyles((theme) =>
  createStyles({
    toolbar: {
      display: 'flex',
      flexWrap: 'wrap',
      columnGap: 50,
      rowGap: 12,
      paddingBottom: theme.spacing(3),
    },
    periodToggle: {
      // marginRight: 50,
      [theme.breakpoints.down('sm')]: {
        margin: 'auto',
        // marginBottom: theme.spacing(3),
        // flexGrow: 1,
      },
    },
    templateSelectWrap: {
      flexGrow: 1,
      minWidth: 370,
      [theme.breakpoints.down('xs')]: {
        minWidth: 'unset',
      },
    },
    templateSelectInput: {
      borderRadius: 40,
      position: 'relative',
    },
    row: {
      padding: theme.spacing(1),
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: '40px 2fr 5fr 1fr',
      columnGap: theme.spacing(2),
      gridTemplateAreas: '"avatar title address code"',
      [theme.breakpoints.down('xs')]: {
        columnGap: 12,
        rowGap: 4,
        gridTemplateColumns: '40px auto',
        gridTemplateAreas: `
            "avatar title"
            "address address"
            "code code"
          `,
      },
    },
    itemLabel: {
      display: 'grid',
      columnGap: 16,
      alignItems: 'center',
      width: '100%',
      gridTemplateColumns: 'auto 60% 42px',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: 'auto 45% 42px',
      },
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'auto 30% 42px',
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: 'auto 42px 42px',
        marginRight: '-8px',
        width: 'calc(100% + 16px)',
      },
    },
  }),
)

function mapPeriod(startDate: number, period: AuditPeriod): SearchSupervisedVisitsRequest {
  const sameDay = startOfDay(startDate)
  const dayBefore = addDays(sameDay, -1)
  const end = addDays(sameDay, 1)
  switch (period) {
    case 'today': {
      return {
        dateFrom: sameDay.valueOf(),
        dateTo: end.valueOf() - 1,
      }
    }
    case 'yesterday': {
      return {
        dateFrom: dayBefore.valueOf(),
        dateTo: sameDay.valueOf() - 1,
      }
    }
    case 'both': {
      return {
        dateFrom: dayBefore.valueOf(),
        dateTo: end.valueOf() - 1,
      }
    }
  }
}

const MerchendizerSelect: React.FC<MerchendizerScreenProps> = (props) => {
  const { screen } = props
  useEffect(() => {
    console.log('MerchendizerScreen mount', props)
  }, [])

  const classes = useStyles()
  const api = useContext(ApiContext)
  const localContext = useScriptTaskContext()
  const batchUpdate = useBatchUpdate()
  const isSmall = useIsSmall()
  const [selectedPeriod, setSelectedPeriod] = useState(periods[0].value)
  const [selectedTemplate, setSelectedTemplate] = useState<ITaskTemplate>()
  const selectedMerchKey = getContextProperty(localContext, 'scope.merchKey', '')

  const merchendizersOps = useAsync(async () => {
    const visits = await api.audit.searchSupervisedVisits({
      ...mapPeriod(localContext.task.startDate, selectedPeriod),
      taskTemplateCode: selectedTemplate?.code,
      status: screen.availableVisitStatuses ?? ['Finished'],
    })
    let filteredVisits: typeof visits = []
    if (screen.jobFunctions) {
      for (const vu of visits) {
        if (checkConditionLists(screen.jobFunctions, [vu.role.jobFunction])) {
          filteredVisits.push(vu)
        }
      }
    } else {
      filteredVisits = visits
    }
    const dict = groupBy(filteredVisits, (vu) => {
      const date = startOfDay(vu.visit.plannedStartDate).valueOf()
      return `${vu.visit.executivePositionRole?.code}${date}`
    })
    const merchendizers = Object.entries(dict).map<Merchendizer>(([code, value]) => ({
      code,
      name: getExecutiveName(value[0], screen.employeeDisplayName),
      date: new Date(value[0].visit.plannedStartDate),
      visits: value,
    }))
    console.log('got merchendizers', merchendizers, selectedPeriod, selectedTemplate)
    return merchendizers
  }, [selectedPeriod, selectedTemplate])

  const onChange = (_ev: unknown, value: string): void => {
    if (!value) {
      void batchUpdate({
        'scope.merchKey': undefined,
        [screen.selectedPositionRoleReferencePropertyName ?? 'task.auditedFieldPositionRole']: undefined,
        [screen.selectedUserReferencePropertyName ?? 'task.auditedUser']: undefined,
        'task.auditDate': undefined,
      })
      return console.log('deselected')
    }
    const selected = merchendizersOps.value?.find((merch) => merch.code === value)
    if (!selected) {
      return console.log('selected not found', value)
    }
    const vu = selected.visits[0]

    void batchUpdate({
      'scope.merchKey': value,
      [screen.selectedPositionRoleReferencePropertyName ?? 'task.auditedFieldPositionRole']: getExecutivePositionRole(
        vu.visit,
      ),
      [screen.selectedUserReferencePropertyName ?? 'task.auditedUser']: getExecutiveRef(vu),
      'task.auditDate': selected.date.valueOf(),
    })
  }

  useAsyncError(merchendizersOps.error)

  const templatesOps = useTemplatesByRelations(useMemo(() => ['View'], []))
  useAsyncError(templatesOps.error)

  if (merchendizersOps.loading && !merchendizersOps.value) {
    return <></>
  }
  const merchendizers = merchendizersOps.value!

  return (
    <Box px={3}>
      {(screen.allowPreviousDay ?? true) && (
        <div className={classes.toolbar}>
          <ToggleButtons
            className={classes.periodToggle}
            value={selectedPeriod}
            onChange={(_ev, value: AuditPeriod | undefined) => value && setSelectedPeriod(value)}
            exclusive
          >
            {periods.map((period) => (
              <ToggleButton key={period.value} value={period.value}>
                {isSmall ? period.name.split(' ').slice(0, 2).join(' ') : period.name}
              </ToggleButton>
            ))}
          </ToggleButtons>
          <Autocomplete
            className={classes.templateSelectWrap}
            options={templatesOps.value ?? []}
            getOptionLabel={(template) => template.name}
            size='small'
            noOptionsText='Не найдено'
            value={selectedTemplate}
            onChange={(_ev, value) => setSelectedTemplate(value ?? undefined)}
            renderInput={(props) => {
              const buttons = props.InputProps.endAdornment
              return (
                <Paper elevation={0} className={classes.templateSelectInput}>
                  <InputBase
                    ref={props.InputProps.ref}
                    inputProps={props.inputProps}
                    fullWidth
                    style={{ paddingLeft: 16, height: 40 }}
                    placeholder='Выберите тип задачи'
                  />
                  {buttons && React.cloneElement(buttons as JSX.Element, { style: { right: 16 } })}
                </Paper>
              )
            }}
          ></Autocomplete>
        </div>
      )}
      <RadioGroup onChange={onChange} value={selectedMerchKey}>
        {!merchendizers.length &&
          (selectedTemplate ? (
            <EmptyMessage marginTop={-6} marginBottom={-12} message='Поиск не дал результатов' />
          ) : (
            <EmptyMessage marginTop={-6} marginBottom={-12} message='Визиты отсутствуют' />
          ))}

        {merchendizers.map(({ code, name, date, visits }) => {
          return (
            <AccordionCard
              key={code}
              label={
                <div className={classes.itemLabel}>
                  <Box display='flex' alignItems='center' overflow='hidden'>
                    <AccordionArrow />
                    <Typography
                      noWrap
                      style={{ paddingTop: 12, paddingBottom: 12 }}
                      variant='inherit' /** TODO: fontSize */
                    >
                      {name}
                    </Typography>

                    <Chip
                      style={{ backgroundColor: grays.gray4, color: 'white', marginLeft: '12px' }}
                      size='small'
                      label={visits.length}
                    />
                  </Box>
                  {selectedPeriod === 'both' ? (
                    <Box textAlign='center'>{dateFormat(date, isSmall ? 'dd.MM' : 'dd.MM.yyyy')}</Box>
                  ) : (
                    <div />
                  )}
                  <Box textAlign='right' component='label' onClick={(ev) => ev.stopPropagation()}>
                    <Radio value={code} color='primary' />
                  </Box>
                </div>
              }
            >
              <Box width='100%'>
                {visits.map(({ visit, pos }) => {
                  if (!pos) return 'no pos'
                  return (
                    <div className={classes.row} key={visit.code}>
                      <Avatar style={{ gridArea: 'avatar' }}>{pos.name.slice(0, 1)}</Avatar>
                      <Typography variant='h4' noWrap style={{ gridArea: 'title' }}>
                        {pos.name}
                      </Typography>
                      <Typography noWrap style={{ gridArea: 'address' }}>
                        {getPosAddress(pos)}
                      </Typography>
                      <Typography
                        style={{ gridArea: 'code' }}
                        color='textSecondary'
                        variant='body2'
                        align={isSmall ? 'left' : 'right'}
                      >
                        {pos.code}
                      </Typography>
                    </div>
                  )
                })}
              </Box>
            </AccordionCard>
          )
        })}
      </RadioGroup>
    </Box>
  )
}

export const SelectEmployeeForAuditUserStepScreen = forwardRef<ScreenRef, MerchendizerScreenProps>((props, ref) => {
  const { screen } = props
  const localContext = useScriptTaskContext()
  const { task } = localContext
  const auditDate = getContextProperty<AuditMerchandiseServiceTask['auditDate']>(
    localContext,
    screen.allowPreviousDay ?? true ? 'task.auditDate' : 'task.startDate',
  )
  const auditedUser = getContextProperty<AuditMerchandiseServiceTask['auditedUser']>(
    localContext,
    screen.selectedUserReferencePropertyName ?? 'task.auditedUser',
  )
  const auditedFieldPositionRole = getContextProperty<AuditMerchandiseServiceTask['auditedFieldPositionRole']>(
    localContext,
    screen.selectedPositionRoleReferencePropertyName ?? 'task.auditedFieldPositionRole',
  )

  console.log('isValid', { auditDate, auditedUser, auditedFieldPositionRole })
  const isValid = !!auditDate && !!auditedUser && !!auditedFieldPositionRole
  useImperativeHandle(
    ref,
    () => ({
      validate: () => {
        if (!isValid) {
          const message = 'Необходимо выбрать сотрудника'
          appToast.info(message)
        }
      },
    }),
    [isValid],
  )
  useEffect(() => {
    props.onReadyChange(isValid)
  }, [isValid])

  if (task.status !== 'Planned' && isValid) {
    return (
      <StartedPlaceholder screen={screen} auditDate={auditDate} auditedFieldPositionRole={auditedFieldPositionRole} />
    )
  }
  return <MerchendizerSelect {...props} />
})
SelectEmployeeForAuditUserStepScreen.displayName = 'SelectEmployeeForAuditUserStepScreen'

/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
function getExecutiveName(
  { visit, employee }: ISupervisedVisitUnit,
  employeeDisplayName: ISelectEmployeeForAuditUserStepScreen['employeeDisplayName'],
): string {
  switch (employeeDisplayName) {
    case 'RoleName':
      return visit.executivePositionRole?.code || visit.assignee?.positionRoleCode || ''
    case 'Name':
    default:
      const employeeName = [employee?.contact.surname, employee?.contact.name].filter((v) => v).join(' ')
      return employeeName || visit.executedBy?.name || visit.assignee?.employeeName || ''
  }
}

function getExecutivePositionRole(visit: IVisit): IEntityReference | undefined {
  return visit.executivePositionRole
}

function getExecutiveRef({ visit, employee }: ISupervisedVisitUnit): IEntityReference | undefined {
  return employee?.account || visit.executedBy
}

const namePreviewItem = { displayName: 'Проверяемый' } as IStringPropertyScreenItem
const datePreviewItem = { displayName: 'Дата проверяемых визитов' } as IStringPropertyScreenItem
interface PlaceholderProps {
  screen: ISelectEmployeeForAuditUserStepScreen
  auditedFieldPositionRole: IPositionRoleReference
  auditDate: number
}
const StartedPlaceholder: React.FC<PlaceholderProps> = ({ screen, auditedFieldPositionRole, auditDate }) => {
  const api = useContext(ApiContext)
  const selectedUserName = useAsync(async () => {
    const vus = await api.audit.searchSupervisedVisits({
      dateFrom: startOfDay(auditDate).valueOf(),
      dateTo: endOfDay(auditDate).valueOf(),
      positionRole: auditedFieldPositionRole,
    })
    if (vus.length) return getExecutiveName(vus[0], screen.employeeDisplayName)
  }, [auditDate, auditedFieldPositionRole.code])
  let displayName = ''
  if (screen.employeeDisplayName === 'RoleName') {
    displayName = auditedFieldPositionRole.code
  } else {
    displayName = `${selectedUserName.value ?? ''} (${auditedFieldPositionRole.code})`
  }
  return (
    <Box px={3}>
      <StringItem item={namePreviewItem} value={displayName} />
      <StringItem item={datePreviewItem} value={dateFormat(auditDate, 'dd.MM.yyyy')} />
    </Box>
  )
}