import React, { useEffect } from 'react'

import { Accordion, AccordionDetails, AccordionSummary, Box, createStyles, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { intersectionWith, noop } from 'lodash'

import { EmptyMessage } from '../../../../../components'
import { isReferenceToEntity } from '../../../../../model/base'
import { BusinessError } from '../../../../../model/errors'
import { IViewSurveyScreenItem } from '../../../../../model/screen-item'
import { ISurvey } from '../../../../../model/survey'
import { getSurveysByPropertyName } from '../../../../_common/hooks/useCreateSurvey'
import { useFetchQuestionnaire } from '../../../../_common/hooks/useFetchQuestionnaire'
import ExecuteSurveyControl from '../../../../execute-survey-control/execute-survey-control'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { AccordionArrow, AccordionCard } from '../accordion-card'
import { SurveyView } from './survey-view'
import { getQuestionnaireByAssignments } from './utils'

interface Props {
  item: IViewSurveyScreenItem
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    compensationMargin: {
      margin: theme.spacing(0, -3),
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(0, -2),
      },
    },
    accordionTitle: {
      fontSize: 16,
      lineHeight: '26px',
      fontWeight: 500,
    },
    accordion: {
      '& .MuiPaper-elevation1': {
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
      '& .MuiAccordion-root:before': {
        display: 'none',
      },
    },

    accordionSummary: {
      justifyContent: 'flex-start',
      padding: 0,
      minHeight: 'unset !important',
      '& > .MuiAccordionSummary-content.Mui-expanded': {
        margin: 0,
      },
      '& > .MuiAccordionSummary-content': {
        margin: 0,
        flexGrow: 0,
      },
      '& > .MuiAccordionSummary-expandIcon.Mui-expanded, & .MuiAccordionSummary-expandIcon': {
        padding: '0 !important',
      },
    },

    accordionDetails: {
      width: '100%',
      padding: '8px 0 20px',
    },
  }),
)

export const ViewSurveyScreenItem: React.FC<Props> = ({ item }) => {
  const classes = useStyles()
  useEffect(() => {
    console.log('SurveyItem', item)
  }, [item])
  const propertiesContext = useScriptTaskContext()

  function renderError(message: string): JSX.Element {
    return (
      <Box pt={0.5}>
        <EmptyMessage message={message} />
      </Box>
    )
  }

  const surveys = getSurveysByPropertyName(item.propertyName || 'task.surveys', propertiesContext)
  let questionnaireCode: string
  let survey: ISurvey | undefined
  try {
    const qref = getQuestionnaireByAssignments(item, [])
    questionnaireCode = qref.code
    survey = surveys.find((survey) => survey.questionnaire.code === questionnaireCode)
  } catch (e: unknown) {
    if (!item.questionnaires?.length) {
      survey = surveys[0]

      if (!survey) {
        return renderError('Нет подходящего опроса')
      }

      questionnaireCode = survey.questionnaire.code
    } else {
      const fittingQuestionnaires = intersectionWith(
        item.questionnaires,
        propertiesContext.questionnaires,
        isReferenceToEntity,
      )
      if (!fittingQuestionnaires?.length) {
        return renderError('В параметрах настройки не указан опросник')
      }
      questionnaireCode = fittingQuestionnaires[0].code
      survey = surveys.find((survey) => survey.questionnaire.code === questionnaireCode)
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const questionnaireOps = useFetchQuestionnaire({ questionnaireCode }, [questionnaireCode])

  if (questionnaireOps.error) {
    if (!(questionnaireOps.error instanceof BusinessError)) {
      throw questionnaireOps.error
    }
  }

  if (questionnaireOps.loading && !questionnaireOps.value) {
    return <></>
  }

  const questionnaire = questionnaireOps.value!

  if (item.hideEmpty && !survey) {
    return <></>
  }

  function renderContent(): JSX.Element {
    if (questionnaireOps.error instanceof BusinessError) {
      return (
        <Box pt={0.5}>
          <EmptyMessage message={questionnaireOps.error.message} />
        </Box>
      )
    }
    if (!survey) {
      return <EmptyMessage message={item.nullValueCaption ?? 'Опрос не найден'} />
    }

    if (item.compactMode === false) {
      return (
        <Box className={classes.compensationMargin}>
          <ExecuteSurveyControl
            readOnly={true}
            ref={null}
            survey={survey}
            questionnaire={questionnaire}
            onRemoveAnswer={noop}
            onBooleanAnswer={noop}
            onHyperlinkAnswer={noop}
            onNumericAnswer={noop}
            onPhotoAnswer={noop}
            onSaveComment={noop}
            onTextAnswer={noop}
            onPhotosSubmit={noop}
            onReadyChange={noop}
            onNullAnswer={noop}
          />
        </Box>
      )
    } else {
      return (
        <SurveyView //
          key={survey.code}
          survey={survey}
          questionnaire={questionnaire}
        />
      )
    }
  }

  if (item.compactMode === false) {
    return (
      <div className={classes.accordion}>
        <Accordion defaultExpanded={true}>
          <AccordionSummary className={classes.accordionSummary} expandIcon={<ArrowDropUpIcon />}>
            <Typography className={classes.accordionTitle}>{item.displayName || 'Опрос'}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Box width='100%'>{renderContent()}</Box>
          </AccordionDetails>
        </Accordion>
      </div>
    )
  }

  return (
    <AccordionCard
      isOpenDefault
      label={
        <>
          <AccordionArrow />
          <Typography style={{ paddingTop: 12, paddingBottom: 12 }} variant='inherit'>
            {item.displayName || 'Опрос'}
          </Typography>
        </>
      }
    >
      <Box width='100%'>{renderContent()}</Box>
    </AccordionCard>
  )
}
