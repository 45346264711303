import React, { useContext, useEffect, useRef } from 'react'

import { AxiosInstance, AxiosResponse } from 'axios'
import { useAsync } from 'react-use'

import { Layout } from '../../layout'
import { TitleBar } from '../../layout/title-bar'
import { IAppPage, ProcessAppFeatureDTO } from '../../model/app-feature'
import { ApiContext, AuthContext } from '../../providers'
import { ExecutionProcessUnit } from '../../services-admin/execution-process-service-api'
import { isAxiosError } from '../../utils'
import { ContextProcessUnit } from '../tasks/nested/local-context'
import { ProcessPage } from './process-page'

interface ProcessAppPageProps {
  children?: never
  feature: ProcessAppFeatureDTO
  page: IAppPage
  onBack?: VoidFunction
}

// const testPage = {}

export const ProcessAppPage: React.FC<ProcessAppPageProps> = (props) => {
  const { page, onBack } = props
  useEffect(() => {
    console.log('ProcessAppPage mount', page)
  }, [])

  const api = useContext(ApiContext)
  const auth = useContext(AuthContext)
  const client = useRef<AxiosInstance>()
  if (!client.current) {
    client.current = api._httpClientFactory.getHttpClient()
  }

  const ops = useAsync(async () => {
    const res: AxiosResponse<ExecutionProcessUnit[]> = await client.current!({
      url: '/execution-process/v1/batch-query',
      method: 'POST',
      data: [page.process, ...(page.subProcesses ?? [])],
    })
    console.log('fetched process', res)

    const [mainUnit, ...subProcessUnits] = res.data
    const contextProcessUnit: ContextProcessUnit = { ...mainUnit, subProcesses: {} }
    for (const { process, screens, scripts } of subProcessUnits) {
      contextProcessUnit.subProcesses[process.code] = process
      Object.assign(contextProcessUnit.screens, screens)
      Object.assign(contextProcessUnit.scripts, scripts)
    }
    console.log('resulting combined unit', contextProcessUnit)
    return contextProcessUnit
  }, [page])

  if (ops.error) {
    if (isAxiosError(ops.error)) {
      const status = ops.error.response?.status
      if (status === 401 || status === 403) {
        auth.authService.logoff()
        return <></>
      }
    }
    throw ops.error
  }

  if (ops.loading && !ops.value) {
    return (
      <Layout>
        <TitleBar>{page.displayName}</TitleBar>
      </Layout>
    )
  }

  const processUnit = ops.value!
  return (
    <ProcessPage
      onBack={onBack}
      mainTitle={page.displayName}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      record={{} as any}
      processUnit={processUnit}
      onSave={
        onBack &&
        ((res) => {
          console.log(`ProcessAppPage ${page.code} result`, res)
          onBack()
        })
      }
      useInlineActionButton
    />
  )
}
