import React, { useContext, useEffect, useRef, useState } from 'react'

import { Box, createStyles, Dialog, makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { EmptyMessage } from '../../../components'
import { YandexMapProvider } from '../../../components/yandex-map-custom/yandex-map-provider'
import { appToast } from '../../../utils'
import { useBusinessSettings } from '../../_common/hooks/useBusinessSettings'
import { VisitWithPos } from '../../_common/hooks/useFetchVisit'
import { useIsSmall } from '../../_common/hooks/useIsSmall'
import { useSearchVisits } from '../../_common/hooks/useSearchVisits'
import { filterCancelledVisits, filterStoreByQuery, sortByCreationTime, sortByStatus } from '../../_common/visits'
import { ErrorPage } from '../../error-page'
import { VisitPageUiContext } from '../visits-page/visits-page-ui-context'
import { EmptyActivitiesMessage } from './components/empty-activities-message'
import { LeafletProvider, VisitsYandexMap } from './map-sources'
import { IVisitsYandexMapRef } from './map-sources/visits-yandex-map'
import { MapVisitList } from './map-visit-list/map-visit-list'
import { MapVisitMobileCard } from './map-visit-list/map-visit-mobile-card'
import { MapVisitSearch } from './map-visit-list/map-visit-search'

export type VisitWithPosIndexed = VisitWithPos & { index: number }

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: -24,
      marginRight: -24,
      flex: '1 0 auto',
      // width: '100%',
      maxWidth: 'calc(100% + 48px)',
      position: 'relative',
      zIndex: 1,
    },
    wrapper: {
      position: 'absolute',
      zIndex: 9999,
      top: 0,
      left: 0,
      padding: '24px 24px 30px 24px',
      width: '318px',
      maxHeight: '100%',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '0',
      },
    },
    inner: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      width: '100%',
    },
    visitCardDialog: {
      '& .MuiDialog-paper': {
        borderRadius: 8,
      },
    },
  }),
)

// const useBreakpoint = createBreakpoint({ tablet: BreakpointSize.tablet, mobile: BreakpointSize.mobile })

interface IVisitListProps {
  periodStartDate: number
  periodEndDate: number
  filterQuery: string
  onChange: (queryString: string) => void
}

const VisitsMapByDay: React.FC<IVisitListProps> = ({ periodStartDate, periodEndDate, filterQuery, onChange }) => {
  const classes = useStyles()
  const visitListData = useSearchVisits({ periodStartDate, periodEndDate }, { withPos: true })
  const isSmall = useIsSmall()
  const { t } = useTranslation('visits')
  const businessSettings = useBusinessSettings()
  const [selectVisit, setSelectVisit] = useState<string>()
  const { uiStateMethods } = useContext(VisitPageUiContext)
  // const [searchQuery, setSearchQuery] = useState<string>(filterQuery)

  const yandexMapRef = useRef<IVisitsYandexMapRef>(null)

  const [isMapInit, setIsMapInit] = useState(false)
  const [isWarning, setIsWarning] = useState(false)

  useEffect(() => {
    if (!listWithoutCoordinates && !filterListByQuery) {
      return
    }

    if (listWithoutCoordinates.length === filterListByQuery.length && filterListByQuery.length && !isWarning) {
      setIsWarning(true)
      console.log(isWarning)
      appToast.info('У визитов отсутствуют координаты')

      setTimeout(() => {
        setIsWarning(false)
      }, 8000)
    }
  }, [filterQuery])

  if (visitListData.error) {
    const message = t('errorFetchingList')
    console.log(message)
    appToast.error(message)
    return <ErrorPage errorMessage={message} />
  }

  if (visitListData.loading) {
    return <EmptyMessage message={t('loading')} marginTop={10} />
  }

  if (!visitListData.value) {
    return <EmptyActivitiesMessage>{t('noVisits')}</EmptyActivitiesMessage>
  }

  const sortedAndFilteredList = visitListData.value
    .filter(filterCancelledVisits)
    .sort(sortByCreationTime)
    // .sort(sortByCode)
    // .sort(sortByDate)
    .sort(sortByStatus)

  if (!sortedAndFilteredList.length) {
    return <EmptyActivitiesMessage>{t('noVisits')}</EmptyActivitiesMessage>
  }

  const onSearchHandler = (queryString: string): void => onChange(queryString)

  const selectHandler = (code: string | undefined): void => {
    if (code === selectVisit) {
      setSelectVisit(undefined)
    } else {
      setSelectVisit(code)
    }
  }

  const clickHandler = (code: string | undefined): void => {
    if (code) {
      yandexMapRef?.current?.focus(code)
    }
  }

  const indexedList: VisitWithPosIndexed[] = sortedAndFilteredList.map((visit, index) => {
    return { ...visit, index: index + 1 }
  })

  const filterListByQuery = indexedList.filter((visit) => filterStoreByQuery(visit.pointOfSale, filterQuery))

  const listWithoutCoordinates = filterListByQuery.filter((visit) => !visit.pointOfSale?.coordinates)

  const renderMapSource = (): JSX.Element => {
    switch (businessSettings?.mapDataSource) {
      case 'yandex':
        return (
          <YandexMapProvider
            onInit={() => {
              setIsMapInit(true)
            }}
            onError={() => {
              uiStateMethods.setViewDayMode('list')
            }}
          >
            <VisitsYandexMap
              ref={yandexMapRef}
              visits={filterListByQuery}
              selectVisitCode={selectVisit}
              onSelect={selectHandler}
            />
          </YandexMapProvider>
        )
      case 'openStreetMaps':
        return (
          <LeafletProvider
            visits={filterListByQuery}
            selectVisit={selectVisit}
            onSelect={selectHandler}
            onInit={() => {
              setIsMapInit(true)
            }}
          />
        )
      default:
        return <></>
    }
  }

  const mobileCardVisit = filterListByQuery.find((visit) => visit.code === selectVisit)

  return (
    <Box className={classes.root}>
      {isSmall ? (
        <></>
      ) : (
        isMapInit && (
          <div className={classes.wrapper}>
            <MapVisitSearch query={filterQuery} onChange={onSearchHandler} />
            <MapVisitList
              visits={filterListByQuery}
              selectVisit={selectVisit}
              onSelect={selectHandler}
              onClick={clickHandler}
              retryVisits={visitListData.retry}
            />
          </div>
        )
      )}
      <div className={classes.inner}>{renderMapSource()}</div>
      {mobileCardVisit && isSmall && (
        <Dialog
          className={classes.visitCardDialog}
          open={true}
          onClose={() => {
            setSelectVisit(undefined)
          }}
        >
          <MapVisitMobileCard visit={mobileCardVisit} retryVisits={visitListData.retry} />
        </Dialog>
      )}
    </Box>
  )
}

export default React.memo(VisitsMapByDay)
