import { AdminPermissionsMap } from '../features/admin/permissions'
import { DtePermissions } from '../features/dte/permissions'
import type { Code, IEntity, IEntityReference, ISyncable } from './base'
import type { VisitCancelationReason, VisitOutOfRouteReason } from './dictionary-item'
import type { IDteParticipantProfile } from './dte-participant-profile'
import type { IEmployee } from './employee'
import type { IFaceDataObjectReference } from './face-data-object'
import type { FieldForceVisitSource } from './visit'

export const PermissionsMap = {
  VISIT_CODE: 'VisitsSectionEnabled',
  POS_CODE: 'PosSectionEnabled',
  REPORTS_CODE: 'ReportsSectionEnabled',
  DEV_FEATURE_CODE: 'DevFeatureSectionEnabled',
  SHOW_VISITS_ON_MAP: 'ShowVisitsOnMapEnabled',
  ADD_VISITS_ENABLED: 'AddVisitEnabled',
  SHOW_PROBLEMS_LIST_ON_VISIT_CARD: 'ShowProblemsListOnVisitCardEnabled',
  PROBLEMS_CODE: 'ProblemsSectionEnabled',
  SHOW_POSES_ON_MAP: 'ShowPosesOnMapEnabled',
  DOWNLOAD_MEDIA_CONTENT: 'DownloadMediaContentEnabled',
} as const

/** @deprecated use PermissionsMap */
export const VISIT_CODE = PermissionsMap.VISIT_CODE
/** @deprecated use PermissionsMap */
export const POS_CODE = PermissionsMap.POS_CODE
/** @deprecated use PermissionsMap */
export const REPORTS_CODE = PermissionsMap.REPORTS_CODE
/** @deprecated use PermissionsMap */
export const DEV_FEATURE_CODE = PermissionsMap.DEV_FEATURE_CODE
/** @deprecated use PermissionsMap */
export const SHOW_VISITS_ON_MAP = PermissionsMap.SHOW_VISITS_ON_MAP
/** @deprecated use PermissionsMap */
export const ADD_VISITS_ENABLED = PermissionsMap.ADD_VISITS_ENABLED
/** @deprecated use PermissionsMap */
export const SHOW_PROBLEMS_LIST_ON_VISIT_CARD = PermissionsMap.SHOW_PROBLEMS_LIST_ON_VISIT_CARD
/** @deprecated use PermissionsMap */
export const PROBLEMS_CODE = PermissionsMap.PROBLEMS_CODE
/** @deprecated use PermissionsMap */
export const SHOW_POSES_ON_MAP = PermissionsMap.SHOW_POSES_ON_MAP
/** @deprecated use PermissionsMap */
export const DOWNLOAD_MEDIA_CONTENT = PermissionsMap.DOWNLOAD_MEDIA_CONTENT

export const AllPermissions = {
  ...PermissionsMap,
  ...AdminPermissionsMap,
  ...DtePermissions,
}

type ValueOf<T> = T[keyof T]
export type PermissionCode = ValueOf<typeof AllPermissions>

export const VISIT_SECTION = 'VisitsSection'
export const POS_SECTION = 'PosSection'
export const REPORTS_SECTION = 'ReportsSection'
export type UserProfileSection = typeof VISIT_SECTION | typeof POS_SECTION | typeof REPORTS_SECTION

export interface IUserProfile {
  profile: IProfile
  contextDisplayName: string
  employee?: IEmployee
  participantProfile?: IDteParticipantProfile
  position?: IPosition
  fieldPositionRole?: IFieldPositionRole
}

export interface IPosition extends IEntity {
  name: string
}

export interface IFieldPositionRole extends IEntity {
  name: string
  jobFunction: IEntityReference
}

export interface IProfile extends ISyncable {
  $type?: 'PMI.FACE.BDDM.Extensions.Classes.FaceUserProfile'
  code: Code
  name: string
  description?: string
  permissions: IPermission[]
  defaultSection?: UserProfileSection
  availableOutOfRouteVisitReasons?: VisitOutOfRouteReason[]
  availableVisitCancelationReasons?: AvailableVisitCancelationReason[]
  mobileMenuStyle?: 'BottomMenuString'
  dataAccessPermissions?: IDataAccessPermission[]

  /** нужно ли загружать TaskRegisters (по-умолчанию - true) */
  loadTaskRegisters?: boolean

  /** нужно ли загружать POSMetrics (по-умолчанию - true) */
  loadPOSMetrics?: boolean

  /** нужно ли загружать NonVisitTasks (по-умолчанию - true) */
  loadNonVisitTasks?: boolean

  /** нужно ли загружать визиты (по-умолчанию - true) */
  loadVisits?: boolean

  /** нужно ли загружать ContractTerms (по-умолчанию - true) */
  loadContractTerms?: boolean

  /** нужно ли загружать ProductMatrices (по-умолчанию - true) */
  loadProductMatrices?: boolean

  /** загружать ли медиа материалы для аудируемых визитов (по-умолчанию - false) */
  loadMediaForSupervisedVisits?: boolean

  /** загружать ли проблемы (по-умолчанию - true) */
  loadVisitProblems?: boolean

  /** загружать ли аудируемые визиты (по-умолчанию - true) */
  loadSupervisedVisits?: boolean

  /** загружать ли содержимое шаблонов задач (по-умолчанию - true) */
  loadTaskTemplateContents?: boolean

  /** загружать ли supervised полевые роли (по-умолчанию - false) */
  loadSupervisedFieldPositionRoles?: boolean

  /** загружать ли профили (по-умолчанию - false) */
  loadUserProfiles?: boolean
}

export interface IPermission {
  code: PermissionCode
  name: string
}

export interface IDataAccessPermission {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceDataAccessPermission'
  dataObject: IFaceDataObjectReference
  availableOperations: IDataObjectOperation[]
}

export enum IDataObjectOperation {
  View = 'View',
  Update = 'Update',
  Insert = 'Insert',
  Delete = 'Delete',
}

export interface IUserProfileReference {
  $type?: 'PMI.FACE.BDDM.Extensions.Classes.FaceUserProfileReference'
  code: string
  /** @deprecated removed */
  name?: string
}

export interface AvailableVisitCancelationReason {
  reason: VisitCancelationReason
  availableVisitSources?: FieldForceVisitSource[]
}
