import { IHttpClient } from '../../../infrastructure/http-client-factory'
import { TypeDecl } from './models'

export class BddmTypesApi {
  constructor(private readonly _http: IHttpClient) {}

  async getBddmTypes() {
    const response = await this._http.request<TypeDecl[]>({
      method: 'GET',
      url: '/bddm-types',
    })

    return response.data
  }
}
