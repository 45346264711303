import React, { useMemo, useState } from 'react'

import { Box, createStyles, Dialog, Grid, IconButton, makeStyles, SvgIcon, Typography } from '@material-ui/core'
import classnames from 'classnames'
import { noop } from 'lodash'
import { useTranslation } from "react-i18next";

import { ReactComponent as ImageBox } from '../../../../../assets/icons/img_box.svg'
import { BooleanAnswer } from '../../../../../model/answer'
import { Code } from '../../../../../model/base'
import { FaceMimeContentRef, IContent } from '../../../../../model/content'
import { IQuestion } from '../../../../../model/question'
import { IQuestionGroup } from '../../../../../model/question-group'
import { IQuestionnaire } from '../../../../../model/questionnaire'
import { ISurvey } from '../../../../../model/survey'
import { PhotoDialog } from '../../../../documents'
import { findPhotos } from '../../../../execute-survey-control/answer-requirements-utils'
import { isNullAnswer } from '../../../../execute-survey-control/utils'
import AnswerResolution from '../../../sales-expert/questionnaire/components/answer-resolution'
import { getAvailableQuestions } from '../../../sales-expert/tabs-new/trainee-skills/predicate-questions'

const SUCCESS_COLOR = '#388E3C'

interface ListProps {
  questionnaire: IQuestionnaire
  survey: ISurvey
}

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      answerWrapper: {
        display: 'flex',
        padding: theme.spacing(1, 1, 0, 1),
      },
      answerIcon: {
        flex: '0 0 34px',
        marginRight: 10,
        marginTop: 1,
      },
      photoIcon: {
        flex: '0 0 48px',
        margin: theme.spacing(-1.25, -1.5, 0, 0),
      },
      descriptionBool: {
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: '0.15px',
      },
      description: {
        fontSize: 16,
        fontWeight: 700,
        letterSpacing: '0.15px',
      },
      comment: {
        marginTop: 8,
        marginBottom: 8,
        fontSize: 14,
      },
      nullAnswerText: {
        marginTop: 8,
        marginBottom: 8,
        fontSize: 16,
      },
      reply: {
        marginTop: 12,
        fontSize: 16,
      },
      green: {
        color: SUCCESS_COLOR,
      },
    }),
  { name: 'AnswerList' },
)
const defaultIds: string[] = []

type QuestionnaireEntry = IQuestionGroup | IQuestion

function isGroup(entry: QuestionnaireEntry): entry is IQuestionGroup {
  if ('name' in entry) return true
  return false
}

export const filterTextDialog = (question: IQuestion): boolean => {
  // FACE-2143
  // Don't show text questions with dialog mode in result screen and reports
  if (question.$type === 'PMI.FACE.BDDM.Extensions.Classes.FaceTextQuestion') {
    if (question.showMode === 'Dialog') {
      return false
    }
  }

  return true
}

export const SurveyView: React.FC<ListProps> = ({ questionnaire, survey }) => {
  const classes = useStyles()
  const { t } = useTranslation(['question', 'photo'])
  const [photoDialogCode, setPhotoDialogCode] = useState<Code>()
  const [answerPhotos, setAnswerPhotos] = useState<string[]>()

  const answers = survey.answers ?? []

  const availableQuestions = getAvailableQuestions(questionnaire.questions, answers).filter(filterTextDialog)

  const questionnaireGroupEntries = availableQuestions.sort((a, b) => {
    function getGroupOrder(q: QuestionnaireEntry): number {
      return (
        questionnaire.questionGroups?.find(
          (group) => group.code === (isGroup(q) ? q.parentGroupCode : q.questionGroupCode),
        )?.orderNumber ?? 0
      )
    }

    const groupOrder = getGroupOrder(a) - getGroupOrder(b)
    return groupOrder || a.orderNumber - b.orderNumber
  })
  const availableGroups =
    questionnaire.questionGroups?.filter((group) =>
      questionnaireGroupEntries.find((entry) => entry.questionGroupCode === group.code),
    ) ?? []

  const questionnaireEntries: QuestionnaireEntry[] = [
    ...availableGroups,
    ...questionnaireGroupEntries.filter((entry) => !entry.questionGroupCode),
  ].sort((a, b) => {
    return a.orderNumber - b.orderNumber
  })

  const renderQuestion = (question: IQuestion): JSX.Element => {
    const answer = answers.find((answer) => answer.questionCode === question.code)
    const photoCount = findPhotos(question.code, answers)?.length ?? 0
    if (!answer) return <></>

    if (isNullAnswer(answer)) {
      return (
        <>
          <Grid item xs zeroMinWidth>
            <Typography className={classes.description}>{question.description}</Typography>
            <Typography variant='body2' color='textSecondary' className={classes.nullAnswerText}>
              Не ответил(а) на вопрос
            </Typography>
          </Grid>
        </>
      )
    }

    switch (question.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.FaceBooleanQuestion':
        return (
          <>
            <div className={classes.answerIcon}>
              <AnswerResolution resolution={(answer as BooleanAnswer).booleanAnswer} />
            </div>
            <Grid item xs zeroMinWidth>
              <Typography className={classes.descriptionBool}>{question.description}</Typography>
              <Typography variant='body2' color='textSecondary' className={classes.comment}>
                {answer.comment}
              </Typography>
              {/*<OpenableTypography variant='body2' color='textSecondary' className={classes.comment}>*/}
              {/*  {answer.comment}*/}
              {/*</OpenableTypography>*/}
            </Grid>
            {!!photoCount && (
              <div className={classes.photoIcon}>
                <IconButton color='primary' onClick={() => setPhotoDialogCode(question.code)}>
                  <SvgIcon component={ImageBox} />
                </IconButton>
              </div>
            )}
          </>
        )
      case 'PMI.FACE.BDDM.Extensions.Classes.FaceTextQuestion':
        return (
          <>
            <Grid item xs zeroMinWidth>
              <Typography className={classes.description}>{question.description}</Typography>
              <Typography className={classes.reply}>{answer.reply}</Typography>
              {/*<OpenableTypography className={classes.reply}></OpenableTypography>*/}
            </Grid>
            {!!photoCount && (
              <div className={classes.photoIcon}>
                <IconButton color='primary' onClick={() => setPhotoDialogCode(question.code)}>
                  <SvgIcon component={ImageBox} />
                </IconButton>
              </div>
            )}
          </>
        )
      case 'PMI.FACE.BDDM.Extensions.Classes.FaceNumericQuestion':
        return (
          <>
            <Grid item xs zeroMinWidth>
              <Typography className={classes.description}>{question.description}</Typography>
              <Typography className={classes.reply}>{answer.reply}</Typography>
              {/*<OpenableTypography className={classes.reply}>{answer.reply}</OpenableTypography>*/}
              <Typography variant='body2' color='textSecondary' className={classes.comment}>
                {answer.comment}
              </Typography>
              {/*<OpenableTypography variant='body2' color='textSecondary' className={classes.comment}>*/}
              {/*  {answer.comment}*/}
              {/*</OpenableTypography>*/}
            </Grid>
            {!!photoCount && (
              <div className={classes.photoIcon}>
                <IconButton color='primary' onClick={() => setPhotoDialogCode(question.code)}>
                  <SvgIcon component={ImageBox} />
                </IconButton>
              </div>
            )}
          </>
        )
      case 'PMI.FACE.BDDM.Extensions.Classes.FaceHyperlinkQuestion':
        return (
          <>
            <Grid item xs zeroMinWidth>
              <Typography className={classes.description}>{question.description}</Typography>
              <Typography className={classnames(classes.reply, classes.green)}>
                {answer.reply ? question.finishedDisplayName ?? t('linkFinishedDisplayName') : t('linkNoFinishedDisplayName')}
              </Typography>
              {/*<OpenableTypography className={classnames(classes.reply, classes.green)}>*/}
              {/*  {answer.reply ? question.finishedDisplayName ?? 'Выполнено' : 'Не выполнено'}*/}
              {/*</OpenableTypography>*/}
              <Typography variant='body2' color='textSecondary' className={classes.comment}>
                {answer.comment}
              </Typography>
              {/*<OpenableTypography variant='body2' color='textSecondary' className={classes.comment}>*/}
              {/*  {answer.comment}*/}
              {/*</OpenableTypography>*/}
            </Grid>
            {!!photoCount && (
              <div className={classes.photoIcon}>
                <IconButton color='primary' onClick={() => setPhotoDialogCode(question.code)}>
                  <SvgIcon component={ImageBox} />
                </IconButton>
              </div>
            )}
          </>
        )
      case 'PMI.FACE.BDDM.Extensions.Classes.FacePhotoQuestion':
        const parts = (answer.reply as IContent).parts as FaceMimeContentRef[]
        return (
          <>
            <Grid item xs zeroMinWidth>
              <Typography className={classes.description}>{question.description}</Typography>
              <Typography className={classes.reply}>{parts?.length ?? '0'} {t('surveyViewPhotoCount')}</Typography>
              {/*<OpenableTypography className={classes.reply}>{parts?.length ?? '0'} фото</OpenableTypography>*/}
            </Grid>
            {!!parts.length && (
              <div className={classes.photoIcon}>
                <IconButton
                  color='primary'
                  onClick={() => setAnswerPhotos(parts ? parts.map((item) => item.code) : [])}
                >
                  <SvgIcon component={ImageBox} />
                </IconButton>
              </div>
            )}
          </>
        )
      default:
        return <div>Unimplemented render question {question.$type}</div>
    }
  }

  // const currentDialogQuestion = questionnaire.questions.find((question) => question.code === photoDialogCode)

  const currentPhotos = useMemo(
    () => findPhotos(photoDialogCode ?? '', answers) ?? defaultIds,
    [photoDialogCode, answers],
  )

  return (
    <>
      <Dialog fullScreen open={!!photoDialogCode}>
        <PhotoDialog
          isShow={!!photoDialogCode}
          // title={currentDialogQuestion?.description ?? ''}
          title={t('photoDialogTitle', { ns: 'photo' })}
          inboxIds={currentPhotos}
          minOccurs={0}
          maxOccurs={0}
          onClose={() => setPhotoDialogCode(undefined)}
          isReadOnly
          allowAddPhoto={true}
          permissionsAddPhoto={false}
          onSubmit={noop}
          allowDeletePhoto={true}
        />
      </Dialog>
      <Dialog fullScreen open={!!answerPhotos?.length}>
        <PhotoDialog
          isShow={!!answerPhotos?.length}
          // title={currentDialogQuestion?.description ?? ''}
          title={t('photoDialogTitle', { ns: 'photo' })}
          inboxIds={answerPhotos}
          minOccurs={0}
          maxOccurs={0}
          onClose={() => setAnswerPhotos(undefined)}
          isReadOnly
          allowAddPhoto={true}
          permissionsAddPhoto={false}
          onSubmit={noop}
          allowDeletePhoto={true}
        />
      </Dialog>
      {questionnaireEntries.map((entry) => {
        if (isGroup(entry)) {
          const group = entry
          return (
            <>
              <Box pt={1} px={1}>
                <Typography variant='h4' color='textSecondary'>
                  {group.name.toUpperCase()}
                </Typography>
              </Box>
              {questionnaireGroupEntries
                .filter((entry) => entry.questionGroupCode === group.code)
                .map((question) => {
                  return (
                    <div key={question.code} className={classes.answerWrapper}>
                      {renderQuestion(question)}
                    </div>
                  )
                })}
            </>
          )
        } else {
          return (
            <div key={entry.code} className={classes.answerWrapper}>
              {renderQuestion(entry)}
            </div>
          )
        }
      })}
    </>
  )
}
