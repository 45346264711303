import { useContext } from 'react'

import { ITask } from '../../../model/task'
import { ApiContext } from '../../../providers'
import { AsyncStateRetryPromise, useAsyncRetryPromise } from '../../_common/hooks/useAsyncRetry'

interface ICalendarSearchParams {
  periodStartDate?: number
  periodEndDate?: number
}

export function useSearchNonVisitTasks({
  periodStartDate = 0,
  periodEndDate = Infinity,
}: ICalendarSearchParams): AsyncStateRetryPromise<ITask[]> {
  const api = useContext(ApiContext)
  const taskListOps = useAsyncRetryPromise(async () => {
    const allTasks = await api.tasks.searchNonVisitTasks()
    return allTasks.filter((task) => task.startDate >= periodStartDate && task.startDate <= periodEndDate)
  }, [periodStartDate, periodEndDate])

  return taskListOps
}
