export function validateEmail(email: string | undefined): boolean {
  if (!email?.trim()) {
    return true
  }
  // FACE-115 https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
  const testingValue = String(email).toLowerCase()
  const re =
    /^(([^<>()[\]\\.,;:\s@"А-я]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(testingValue) && !testingValue.includes('xn--')
}
