import { Code, getEntityKey, IEntityReference, isVersionActive } from '../../../model/base'
import { BusinessError } from '../../../model/errors'
import type { TaskStatus } from '../../../model/task'
import type { ITaskTemplate } from '../../../model/task-template'
import type { IProfile } from '../../../model/user-profile'
import type { IApiContext } from '../../../providers/api/api-context'
import { isTemplateAvailable } from '../../_common/hooks/useTemplatesByRelations'

interface TaskParams {
  code: Code
  template: IEntityReference
  status: TaskStatus
}

export async function tryFindTaskTemplate(
  api: IApiContext,
  profile: IProfile,
  task: TaskParams,
): Promise<ITaskTemplate> {
  if (task.status === 'Finished') {
    if (!task.template.version) {
      throw new BusinessError(
        0,
        `Не указана версия шаблона задачи. \n Ключ шаблона: ${getEntityKey(task.template)}. \n Код задачи: ${
          task.code
        }`,
      )
    }
    const template = await api.tasks.getTaskTemplate(task.template)
    if (!template) {
      throw new BusinessError(
        0,
        `Не найден шаблон задачи. \n Ключ шаблона: ${getEntityKey(task.template)}. \n Код задачи: ${task.code}`,
      )
    }
    if (!isTemplateAvailable(template, profile, ['View'])) {
      throw new BusinessError(
        0,
        `Недостаточно прав для просмотра задачи '${template.name}' (${getEntityKey(template)})`,
      )
    }
    if (!template.resultScreen) {
      throw new BusinessError(
        0,
        `Не указан результирующий экран для шаблона '${template.name}' (${getEntityKey(template)})`,
      )
    }
    return template
  }

  const template = await api.tasks.getTaskTemplate(task.template)
  if (!template) {
    if (!task.template.version) {
      throw new BusinessError(
        0,
        `"Выполнение задачи невозможно. \n Не удалось определить действующую версию шаблона задачи (ключ шаблона: ${getEntityKey(
          task.template,
        )})"`,
      )
    }
    throw new BusinessError(
      0,
      `Не найден шаблон задачи. \n Ключ шаблона: ${getEntityKey(task.template)}. \n Код задачи: ${task.code}`,
    )
  }
  if (template.status !== 'Active') {
    throw new BusinessError(
      0,
      `Выполнение задачи невозможно. \n В задаче указана ссылка на архивную версию шаблона (ключ шаблона: ${getEntityKey(
        template,
      )})`,
    )
  }
  if (!isVersionActive(template.version, new Date())) {
    if (!task.template.version) {
      throw new BusinessError(
        0,
        `Выполнение задачи невозможно. \n В задаче указана ссылка на архивный шаблон (ключ шаблона: ${getEntityKey(
          template,
        )})`,
      )
    }
    throw new BusinessError(
      0,
      `Выполнение задачи невозможно. \n В задаче указана недействующая версия шаблона (ключ шаблона: ${getEntityKey(
        template,
      )})`,
    )
  }

  if (!isTemplateAvailable(template, profile, ['Proceed'])) {
    throw new BusinessError(
      0,
      `Недостаточно прав для выполнения задачи '${template.name}' (ключ шаблона: ${getEntityKey(template)})`,
    )
  }
  return template
}
