import React, { useEffect } from 'react'

import { Box, Typography } from '@material-ui/core'

import { IActionScreenItem } from '../../../../../model/screen-item-base'
import { usePropertyName } from '../../../nested/usePropertyName'
import AnswerResolution from '../answer-resolution'
import { ItemCard } from '../item-card'
import { useBooleanStyles } from './styles'
import { BooleanItemValue, mapDisplayValue } from './utils'

export interface IBooleanViewScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.BooleanViewScreenItem'
  nullValueCaption?: string
  hideEmpty?: boolean
}

interface BooleanViewScreenItemControlProps {
  item: IBooleanViewScreenItem
  children?: never
}

export const BooleanViewScreenItemControl: React.FC<BooleanViewScreenItemControlProps> = ({ item }) => {
  useEffect(() => {
    console.log('BooleanViewScreenItemControl mount', item)
  }, [])

  const value: boolean = usePropertyName(item.propertyName)

  return <BooleanViewScreenItem item={item} value={value} />
}

interface BooleanViewScreenItemProps {
  item: IBooleanViewScreenItem
  value: BooleanItemValue
  children?: never
}

const BooleanViewScreenItem: React.FC<BooleanViewScreenItemProps> = ({ value, item }) => {
  useEffect(() => {
    console.log('BooleanViewScreenItem mount, resolved value:', value, item)
  }, [])
  const classes = useBooleanStyles()

  const isCompact = false

  const displayValue = mapDisplayValue(value)
  const isAnswer = !!displayValue

  if (!isAnswer && item.hideEmpty) {
    return <></>
  }

  const renderLabel = (): JSX.Element => {
    return (
      <>
        <Box className={classes.label}>
          <Typography variant='inherit'>{item.displayName}</Typography>
          <Box ml='auto' pl={2}>
            {isAnswer && isCompact && <AnswerResolution resolution={value} onlyText={false} text={displayValue} />}
            {!isAnswer && <Typography>{item.nullValueCaption}</Typography>}
          </Box>
        </Box>
      </>
    )
  }

  return (
    <ItemCard label={renderLabel()}>
      {isAnswer && !isCompact && (
        <div className={classes.answerWrap}>
          <AnswerResolution resolution={value} onlyText={false} text={displayValue} />
        </div>
      )}
    </ItemCard>
  )
}
