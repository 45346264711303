import { useState } from 'react'

export function useMenu<HandlerMap extends Record<string, () => void>>(handlers: HandlerMap) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  function open(event: { currentTarget: HTMLElement }) {
    setAnchorEl(event.currentTarget)
  }

  function close() {
    setAnchorEl(null)
  }

  function action(type: keyof HandlerMap) {
    return function () {
      handlers[type]()
      close()
    }
  }

  return {
    anchor: {
      onClick: open,
    },
    menu: {
      anchorEl: anchorEl,
      open: Boolean(anchorEl),
      onClose: close,
    },
    action(type: keyof HandlerMap, options?: { enabled?: boolean }) {
      return {
        onClick: action(type),
        disabled: !(options?.enabled ?? true),
      }
    },
  }
}
