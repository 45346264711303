import { BusinessError } from '../../model/errors'
import AppConfig from './app-config'

export enum ConfigErrorCode {
  MaintenanceInProgress,
  Other
}

export class ConfigError extends BusinessError<ConfigErrorCode> {
  readonly httpStatus?: number

  constructor(
    code: ConfigErrorCode,
    message: string,
    httpStatus?: number,
    details?: unknown
  ) {
    super(code, message, details)
    this.httpStatus = httpStatus
  }
}

export interface IConfigService {
  getAppConfig: () => Promise<AppConfig>
}
