import { ILogger, LogEntry, LoggerBase } from './logger-api'

export class DelegateLogger extends LoggerBase {
  private readonly _name: string
  private readonly _logger: ILogger | null

  constructor(name: string, logger: ILogger | null) {
    super()
    this._name = name
    this._logger = logger
  }

  log(entry: LogEntry): void {
    this._logger?.log({ ...entry, logger: this._name })
  }

  async flush(): Promise<void> {
    await this._logger?.flush()
  }
}
