import React from 'react'

import { Box, Dialog, useMediaQuery, Theme } from '@material-ui/core'

import { MENU_WIDTH } from '../utils/constants'

interface Props {
  children: React.ReactNode
  open: boolean
  fullscreen?: boolean
}

export function ModalPage(props: Props): JSX.Element {
  const menuHidden = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'))
  const left = menuHidden || props.fullscreen ? 0 : MENU_WIDTH

  return (
    <Dialog
      fullScreen
      hideBackdrop
      open={props.open}
      style={{ left }}
      PaperProps={{ elevation: 0 }}
      transitionDuration={0}
    >
      <Box bgcolor='background.default'>{props.open && props.children}</Box>
    </Dialog>
  )
}
