export const DtePermissions = {
  DteMainSectionEnabled: 'DteMainSectionEnabled',
  DteTasksSectionEnabled: 'DteTasksSectionEnabled',
  DteAcademySectionEnabled: 'DteAcademySectionEnabled',
  DteIqosTasksSectionEnabled: 'DteIqosTasksSectionEnabled',
  DteTasksCheckingSectionEnabled: 'DteTasksCheckingSectionEnabled',
  DteRewardsSectionEnabled: 'DteRewardsSectionEnabled',
  DepositBalanceSectionEnabled: 'DepositBalanceSectionEnabled',
  RewardListSectionEnabled: 'RewardListSectionEnabled',
} as const
