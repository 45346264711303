/* eslint-disable @typescript-eslint/indent */
import React from 'react'

import {Box, createStyles, Divider, Grid, Paper, Theme, Tooltip, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import classnames from 'classnames'
import {useTranslation} from 'react-i18next'
import {generatePath, Link} from 'react-router-dom'

import {grays} from '../../../layout/theme'
import {VisitWithPos} from '../../_common/hooks/useFetchVisit'
import {useFeatureRoute} from '../../custom-app-feature/app-feature-context'
import {getRegisteredNameFromPos} from '../../stores/store-menu-page/utils'
import { getVisitSourceString } from '../utils'
import VisitMenu from '../visit-view-page/visit-menu'
import { visitsRoutes } from '../visits-app-feature/visits-app-feature'
import { UseReasonsCancelled } from './use-reasons-cancelled'

interface IVisitsListItemProps {
  visit: VisitWithPos
  size?: 'small'
  retryVisits?: () => void
}

const useStyles = makeStyles<Theme, IVisitsListItemProps>((theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
      position: 'relative',
      fontSize: 16,
      minHeight: 90,
      boxShadow: '0 3px 7px rgba(96, 97, 112, 0.13)',
      borderRadius: 12,
      overflow: 'hidden',
      padding: '21px 26px 21px 22px',
      [theme.breakpoints.down('xs')]: {
        padding: '18px 16px 13px 28px',
      },
    },
    name: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: 0.1,
      [theme.breakpoints.down('xs')]: {
        fontSize: '16px',
        lineHeight: '20px',
        marginBottom: 9,
      },
    },
    cancelled: {
      background: '#F2F3F4',
    },
    finished: {
      background: '#F2F3F4',
    },
    gridContainer: {
      flexWrap: 'nowrap',
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
      },
    },
    verticalLine: {
      margin: 0,
      backgroundColor: grays.gray5,
      width: 2,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    nameWrap: {
      maxWidth: '34.7%',
      flex: '0 0 34.7%',
      paddingRight: '7%',
      marginLeft: 8,
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
        maxWidth: '100%',
        flexBasis: '100%',
      },
    },
    addressWrap: {
      maxWidth: 'calc(44% - 50px)',
      flex: '0 0 calc(44% - 50px)',
      paddingLeft: '7%',
      paddingRight: '7%',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        flexBasis: '100%',
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: 2,
      },
    },
    menuWrap: {
      maxWidth: '50px',
      flex: '0 0 50px',
      marginRight: -20,
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
        position: 'absolute',
        top: 0,
        right: -3,
      },
    },
    thirdColumn: {
      display: 'flex',
      alignItems: 'center',
      // justifyContent: 'flex-end',
      paddingLeft: '6%',
      textAlign: 'end',
      maxWidth: '21.3%',
      flex: '0 0 21.3%',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        flexBasis: '100%',
        paddingLeft: 0,
      },
    },
    statusWrap: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
        minWidth: 126,
        justifyContent: 'flex-end',
      },
    },
    code: {
      fontSize: 14,
      lineHeight: '24px',
      color: grays.gray2,
      letterSpacing: '0.25px',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    codeMobile: {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
        textAlign: 'left',
        flexGrow: 1,
        display: 'block',
        letterSpacing: '0.1px',
        color: grays.gray3,
      },
    },
    address: {
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
    },
    source: {
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      marginLeft: 10,
      textAlign: 'start',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginLeft: 0,
      },
    },
    statusText: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 500,
      letterSpacing: '0.1px',
      color: grays.gray2,
    },
    status: {
      position: 'absolute',
      height: '100%',
      marginTop: -21,
      marginLeft: -22,
      width: 8,
      [theme.breakpoints.down('xs')]: {
        marginTop: -18,
        marginLeft: -28,
      },
    },
    statusPlanned: {
      background: theme.palette.info.main,
    },
    statusFinished: {
      background: theme.palette.success.main,
    },
    statusInProgress: {
      background: theme.palette.warning.main,
    },
    statusCanceled: {
      background: theme.palette.grey[600],
    },
    dotStatus: {
      display: 'block',
      borderRadius: '50%',
      flexShrink: 0,
      width: 10,
      height: 10,
      margin: 10,
    },
    codeWrap: {
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        alignItems: 'baseline',
      },
    },
  }),
)

const VisitsListItem: React.FC<IVisitsListItemProps> = (props) => {
  const { t } = useTranslation('visits')
  const classes = useStyles(props)
  const featureRoute = useFeatureRoute()

  const { visit, size, retryVisits } = props
  const reasonsCancelledItems = UseReasonsCancelled(visit)

  // const gridSize = (size: GridSize): GridSize => (props?.size === 'small' ? 12 : size)

  const registeredName = visit.pointOfSale && getRegisteredNameFromPos(visit.pointOfSale)
  const pathname = generatePath(visitsRoutes.visitView, { id: visit.code, featureRoute })

  return (
    <Link to={pathname}>
      <Paper
        elevation={3}
        square
        className={classnames(classes.root, {
          [classes.cancelled]: visit.status === 'Canceled',
          [classes.finished]: visit.status === 'Finished',
        })}
      >
        <Tooltip title={t(visit.status, '') as string}>
          <div
            className={classnames(classes.status, {
              [classes.statusPlanned]: visit.status === 'Planned',
              [classes.statusFinished]: visit.status === 'Finished',
              [classes.statusInProgress]: visit.status === 'InProgress',
              [classes.statusCanceled]: visit.status === 'Canceled',
            })}
          />
        </Tooltip>
        <Grid container className={classes.gridContainer} alignItems='center' justify='space-between'>
          <Grid item className={classes.nameWrap}>
            <Box display='flex' alignItems='center'>
              <Box style={{ maxWidth: '100%' }}>
                <Typography className={classes.name} component='h6' noWrap>
                  {visit.pointOfSale?.name ?? ''}
                </Typography>
                <Typography className={classes.code} noWrap>
                  {visit.pointOfSale?.code ?? ''}
                  {registeredName ? `\u00A0|\u00A0${registeredName}` : ''}
                </Typography>
              </Box>
            </Box>
          </Grid>
          {size !== 'small' && (
            <Divider orientation='vertical' variant='middle' flexItem className={classes.verticalLine} />
          )}
          <Grid item className={classes.addressWrap}>
            <Tooltip
              title={`${visit.pointOfSale?.address?.fullAddress ?? ''} ${visit.pointOfSale?.address?.city ?? ''}`}
            >
              <Typography noWrap className={classes.address}>
                {visit.pointOfSale?.address?.fullAddress ?? ''}
                <br />
                {visit.pointOfSale?.address?.city ?? ''}
              </Typography>
            </Tooltip>
          </Grid>
          {props?.size !== 'small' && (
            <Divider orientation='vertical' variant='middle' flexItem className={classes.verticalLine} />
          )}
          <Grid item wrap='wrap' container className={classes.thirdColumn}>
            <Grid item className={classes.codeWrap}>
              <Typography className={classnames(classes.code, classes.codeMobile)} noWrap>
                {visit.pointOfSale?.code ?? ''}
                {registeredName ? `\u00A0|\u00A0${registeredName}` : ''}
              </Typography>
              <div className={classes.statusWrap}>
                <span
                  className={classnames(classes.dotStatus, {
                    [classes.statusPlanned]: visit.status === 'Planned',
                    [classes.statusFinished]: visit.status === 'Finished',
                    [classes.statusInProgress]: visit.status === 'InProgress',
                    [classes.statusCanceled]: visit.status === 'Canceled',
                  })}
                />
                <Typography noWrap className={classes.statusText}>
                  {t(visit.status)}
                </Typography>
              </div>
            </Grid>
            <Grid item style={{ width: '100%' }}>
              <Typography noWrap className={classes.source}>
                {getVisitSourceString(visit.source)}
              </Typography>
            </Grid>
          </Grid>
          <Grid item className={classes.menuWrap} onClick={(evt) => evt.stopPropagation()}>
            <VisitMenu visit={visit} reasonsCancelledItems={reasonsCancelledItems} retry={retryVisits} />
          </Grid>
        </Grid>
      </Paper>
    </Link>
  )
}

export default React.memo(VisitsListItem)
