import { useContext } from 'react'

import { useQuery } from '@tanstack/react-query'

import type { ASTPredicate } from '../../../../model/ast-predicate'
import { Code, findActiveVersion } from '../../../../model/base'
import type { COLLECTIONS, DataCollectionCode } from '../../../../model/collections'
import type { MimeContentType } from '../../../../model/content'
import type { DepositTransactionReasonDictionary, IDictionaryBase } from '../../../../model/dictionary'
import { DepositTransactionReasonDictionaryType } from '../../../../model/dictionary'
import type { DICTIONARY_ITEMS, DictionaryCode } from '../../../../model/dictionary-item'
import { ApiContext } from '../../../../providers'
import { useService } from '../../../../providers/service'
import { DATA_COLLECTIONS_API, IDataCollectionServiceApi } from '../../../../services/data-collection-service-api'
import { appToast, sortObjects } from '../../../../utils'

const PAGE_SIZE = 100

export function useTradeProgramsListQuery(props?: { isEnabled?: boolean; filterCodes?: Code | Code[] }) {
  const { filterCodes } = props ?? {}
  const api = useService(DATA_COLLECTIONS_API)
  const isEnabled = props?.isEnabled ?? true
  const query = useQuery({
    queryKey: ['collections', 'dtetradeprogram', JSON.stringify(props?.filterCodes)],
    enabled: isEnabled,
    async queryFn() {
      const allPages = await loadAllPages(api, 'dtetradeprogram', createFilterPredicate({ filterCodes }))
      return allPages.sort(
        sortObjects(
          (obj) => obj.name,
          (obj) => obj.code,
        ),
      )
    },
    onError(err) {
      console.error(err)
      appToast.error('Произошла ошибка при получении типов торговых программ')
    },
  })
  return {
    ...query,
    isLoading: isEnabled && query.isLoading,
  } as typeof query
}

export function useContentDocumentsListQuery(props?: {
  isEnabled?: boolean
  filterCodes?: Code | Code[]
  filterMimeTypes?: MimeContentType[]
}) {
  const { filterCodes, filterMimeTypes } = props ?? {}
  const api = useService(DATA_COLLECTIONS_API)
  const isEnabled = props?.isEnabled ?? true
  const query = useQuery({
    queryKey: [
      'collections',
      'contentdocuments',
      JSON.stringify(props?.filterCodes),
      JSON.stringify(props?.filterMimeTypes),
    ],
    enabled: isEnabled,
    async queryFn() {
      const allPages = await loadAllPages(
        api,
        'contentdocuments',
        createFilterPredicate({ filterCodes, filterMimeTypes }),
      )
      return allPages.sort(
        sortObjects(
          (obj) => obj.name?.toUpperCase(),
          (obj) => obj.code,
        ),
      )
    },
    onError(err) {
      console.error(err)
      appToast.error('Произошла ошибка при получении списка документов')
    },
  })
  return {
    ...query,
    isLoading: isEnabled && query.isLoading,
  } as typeof query
}

export function useBrandVariantListQuery(props?: { isEnabled?: boolean }) {
  const api = useService(DATA_COLLECTIONS_API)
  const isEnabled = props?.isEnabled ?? true
  const query = useQuery({
    queryKey: ['collections', 'brandvariants'],
    enabled: isEnabled,
    async queryFn() {
      const allPages = await loadAllPages(api, 'brandvariants')
      return allPages.sort(
        sortObjects(
          (obj) => obj.name?.toUpperCase(),
          (obj) => obj.code,
        ),
      )
    },
    onError(err) {
      console.error(err)
      appToast.error('Произошла ошибка при получении коллекции brandvariants')
    },
  })
  return {
    ...query,
    isLoading: isEnabled && query.isLoading,
  } as typeof query
}

export function useBrandFamiliesListQuery(props?: { isEnabled?: boolean }) {
  const api = useService(DATA_COLLECTIONS_API)
  const isEnabled = props?.isEnabled ?? true
  const query = useQuery({
    queryKey: ['collections', 'brandfamilies'],
    enabled: isEnabled,
    async queryFn() {
      const allPages = await loadAllPages(api, 'brandfamilies')
      return allPages.sort(
        sortObjects(
          (obj) => obj.name?.toUpperCase(),
          (obj) => obj.code,
        ),
      )
    },
    onError(err) {
      console.error(err)
      appToast.error('Произошла ошибка при получении коллекции brandfamilies')
    },
  })
  return {
    ...query,
    isLoading: isEnabled && query.isLoading,
  } as typeof query
}

export function useDictionariesQuery<C extends DictionaryCode>(props?: {
  isEnabled?: boolean
  code?: C
  onlyActiveVersion?: boolean
}) {
  const api = useService(DATA_COLLECTIONS_API)
  const onlyActiveVersion = props?.onlyActiveVersion ?? !!props?.code
  const isEnabled = props?.isEnabled ?? true
  const query = useQuery({
    queryKey: ['collections', 'dictionaries', props?.code, onlyActiveVersion],
    enabled: isEnabled,
    async queryFn() {
      const _allDictionaries = await loadAllPages(api, 'dictionaries', createFilterCodesPredicate(props?.code))
      const allDictionaries = patchDictionariesVersionDates(_allDictionaries) as Array<
        IDictionaryBase<DICTIONARY_ITEMS[C]>
      >
      if (onlyActiveVersion) {
        const activeVersion = findActiveVersion(allDictionaries)
        return activeVersion ? [activeVersion] : []
      } else {
        return allDictionaries
      }
    },
    onError(err) {
      console.error(err)
      appToast.error(`Произошла ошибка при получении списка словарей${props?.code ? ` (${props.code})` : ''}`)
    },
  })
  return {
    ...query,
    isLoading: isEnabled && query.isLoading,
  } as typeof query
}

export function useDepositTransactionReasonsDictionariesQuery<C extends DictionaryCode>(props?: {
  isEnabled?: boolean
  code?: C
  onlyActiveVersion?: boolean
}) {
  const api = useService(DATA_COLLECTIONS_API)
  const onlyActiveVersion = props?.onlyActiveVersion ?? !!props?.code
  const isEnabled = props?.isEnabled ?? true
  const query = useQuery({
    queryKey: ['collections', 'dictionaries', 'DepositTransactionReasonsDictionaries', props?.code, onlyActiveVersion],
    enabled: isEnabled,
    async queryFn() {
      const _allDictionaries = await loadAllPages(
        api,
        'dictionaries',
        createFilterPredicate({
          filterCodes: props?.code,
          filterTypes: DepositTransactionReasonDictionaryType,
        }),
      )
      const allDictionaries = patchDictionariesVersionDates(_allDictionaries) as DepositTransactionReasonDictionary[]
      if (onlyActiveVersion) {
        const activeVersion = findActiveVersion(allDictionaries)
        return activeVersion ? [activeVersion] : []
      } else {
        return allDictionaries
      }
    },
    onError(err) {
      console.error(err)
      appToast.error(`Произошла ошибка при получении списка словарей${props?.code ? ` (${props.code})` : ''}`)
    },
  })
  return {
    ...query,
    isLoading: isEnabled && query.isLoading,
  } as typeof query
}

export function useCollectionImage(imageCode: string | undefined) {
  const { richtext: api } = useContext(ApiContext)
  const enabled = imageCode !== undefined
  const imgUrl = `content-documents/v1.0/${imageCode}/get-target`

  const query = useQuery({
    queryKey: ['content-document-view', imageCode],
    enabled,
    queryFn: async () => api.getImage(imgUrl),
    retry: false,
    suspense: false,
    refetchOnMount: false,
    staleTime: 5 * 60 * 1000,
    onError: (error) => {
      console.error(error)
    },
  })

  return {
    ...query,
    isLoading: enabled && query.isLoading,
  } as typeof query
}

async function loadAllPages<C extends DataCollectionCode>(
  api: IDataCollectionServiceApi,
  collectionCode: C,
  filter?: ASTPredicate,
): Promise<Array<COLLECTIONS[C]>> {
  const firstRequest = await api.getList(collectionCode, {
    filter,
    pagingOptions: { pageSize: PAGE_SIZE, pageNumber: 0 },
  })

  const pages = []
  let pageNumber = 0
  do {
    pageNumber++
    if (PAGE_SIZE * pageNumber >= firstRequest.totalItemsCount) {
      break
    }
    pages.push(pageNumber)
  } while (true)

  const requests = pages.map(async (pageNumber) =>
    api.getList(collectionCode, {
      filter,
      pagingOptions: { pageSize: PAGE_SIZE, pageNumber },
    }),
  )

  const restRequests = await Promise.all(requests)
  return [firstRequest, ...restRequests].flatMap((res) => res.items)
}

function createFilterPredicate(props: {
  filterCodes?: Code | Code[]
  filterMimeTypes?: MimeContentType[]
  filterTypes?: string | string[]
}): ASTPredicate | undefined {
  const { filterCodes: filterKeys, filterTypes, filterMimeTypes } = props
  const predicates = [
    createFilterCodesPredicate(filterKeys),
    createFilterMimePredicate(filterMimeTypes),
    createFilterTypesPredicate(filterTypes),
  ].filter((e: ASTPredicate | undefined): e is ASTPredicate => e !== undefined)

  const result = predicates.reduce(
    (previousValue: ASTPredicate | undefined, currentValue: ASTPredicate): ASTPredicate => {
      if (!previousValue) {
        return currentValue
      }
      return {
        $type: 'PMI.FACE.BDDM.Extensions.Classes.PredicatesJunction',
        junctionKind: 'AND',
        left: previousValue,
        right: currentValue,
      }
    },
    undefined,
  )

  return result
}

function createFilterCodesPredicate(filterCodes?: Code | Code[]): ASTPredicate | undefined {
  if (!filterCodes) {
    return undefined
  }
  if (typeof filterCodes === 'string') {
    filterCodes = [filterCodes]
  }
  if (!filterCodes.length) {
    return undefined
  }
  return {
    $type: 'PMI.FACE.BDDM.Extensions.Classes.BinaryPredicate',
    left: {
      $type: 'PMI.FACE.BDDM.Extensions.Classes.PropertyNameExpression',
      propertyName: 'code',
    },
    operation: 'IN',
    right: {
      $type: 'PMI.FACE.BDDM.Extensions.Classes.StringArrayLiteralExpression',
      values: filterCodes,
    },
  }
}

function createFilterTypesPredicate(filterTypes?: string | string[]): ASTPredicate | undefined {
  if (!filterTypes) {
    return undefined
  }
  if (typeof filterTypes === 'string') {
    filterTypes = [filterTypes]
  }
  if (!filterTypes.length) {
    return undefined
  }
  return {
    $type: 'PMI.FACE.BDDM.Extensions.Classes.BinaryPredicate',
    left: {
      $type: 'PMI.FACE.BDDM.Extensions.Classes.PropertyNameExpression',
      propertyName: '$type',
    },
    operation: 'IN',
    right: {
      $type: 'PMI.FACE.BDDM.Extensions.Classes.StringArrayLiteralExpression',
      values: filterTypes,
    },
  }
}

function createFilterMimePredicate(filterTypes?: MimeContentType[]): ASTPredicate | undefined {
  if (!filterTypes || !filterTypes.length) {
    return undefined
  }
  return {
    $type: 'PMI.FACE.BDDM.Extensions.Classes.BinaryPredicate',
    left: {
      $type: 'PMI.FACE.BDDM.Extensions.Classes.PropertyNameExpression',
      propertyName: 'content.type',
    },
    operation: 'IN',
    right: {
      $type: 'PMI.FACE.BDDM.Extensions.Classes.StringArrayLiteralExpression',
      values: filterTypes,
    },
  }
}

function convertDate(date: number | string | undefined): number | undefined {
  return typeof date === 'string' ? new Date(date).getTime() : date
}

function patchDictionariesVersionDates<T extends IDictionaryBase[]>(dictionaries: T): T {
  const mappedDate = dictionaries.map((dict) => ({
    ...dict,
    version: {
      ...dict.version,
      startDate: convertDate(dict.version.startDate)!,
      endDate: convertDate(dict.version.endDate),
    },
  })) as typeof dictionaries

  return mappedDate.sort(
    sortObjects(
      (obj) => obj.name?.toUpperCase(),
      (obj) => obj.code,
    ),
  )
}
