import { useRef } from 'react'

import { IAnswer } from '../../../model/answer'
import { IQuestion } from '../../../model/question'
import { IQuestionnaire } from '../../../model/questionnaire'
import { SETaskRepresentativeScreenItem } from '../../../model/screen-item'
import { ITaskTemplate } from '../../../model/task-template'
import { iterAllScreens, iterAllItems } from '../nested/iter-template-utils'

export function useLoadingSetRef<T>(): { get: () => Set<T>; add: (code: T) => void; remove: (code: T) => void } {
  const loadingRef = useRef<Set<T>>()
  function getLoading(): Set<T> {
    if (!loadingRef.current) {
      loadingRef.current = new Set()
    }
    return loadingRef.current
  }
  function addLoading(code: T): void {
    getLoading().add(code)
  }
  function removeLoading(code: T): void {
    setTimeout(() => {
      getLoading().delete(code)
    }, 0)
  }

  return { get: getLoading, add: addLoading, remove: removeLoading }
}

/** sort in place */
export function sortAnswers(questionnaire: IQuestionnaire, answers: IAnswer[]): IAnswer[] {
  function findQuestion(a: IAnswer): IQuestion {
    return questionnaire.questions.find((question) => question.code === a.questionCode)!
  }
  function getGroupOrder(q: IQuestion): number {
    return questionnaire.questionGroups?.find((group) => group.code === q.questionGroupCode)?.orderNumber ?? 0
  }

  return answers.sort((a1, a2) => {
    const q1 = findQuestion(a1)
    const q2 = findQuestion(a2)
    const groupOrder = getGroupOrder(q1) - getGroupOrder(q2)
    return groupOrder || q1.orderNumber - q2.orderNumber
  })
}

export function findRepresentativeItem(template: ITaskTemplate): SETaskRepresentativeScreenItem | undefined {
  for (const screen of iterAllScreens({ template })) {
    if (screen.$type === 'PMI.FACE.BDDM.Extensions.Classes.CompositeUserStepScreen') {
      for (const item of iterAllItems(screen.items)) {
        if (item.$type === 'PMI.FACE.BDDM.Extensions.Classes.SalesExpertEducationTaskRepresentativeScreenItem') {
          return item
        }
      }
    }
  }
}
