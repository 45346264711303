import { IKeyValuePairsDataSource } from '../../../model/data-source'
import { getKeyValuePairValue } from '../template-tasks/composite-screen/table-item/label-cell/data-source'
import { TaskAssessment } from './audit-merchandise-service-task'

const source: IKeyValuePairsDataSource = {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.KeyValuePairsDataSource',
  items: [
    {
      $type: 'PMI.FACE.BDDM.Extensions.Classes.KeyValuePair',
      key: 'Complied',
      value: 'Оказана',
    },
    {
      $type: 'PMI.FACE.BDDM.Extensions.Classes.KeyValuePair',
      key: 'Completed',
      value: 'Оказана',
    },
    {
      $type: 'PMI.FACE.BDDM.Extensions.Classes.KeyValuePair',
      key: 'NotComplied',
      value: 'Не оказана',
    },
    {
      $type: 'PMI.FACE.BDDM.Extensions.Classes.KeyValuePair',
      key: 'NotCompleted',
      value: 'Не оказана',
    },
    {
      $type: 'PMI.FACE.BDDM.Extensions.Classes.KeyValuePair',
      key: 'CouldNotAssess',
      value: 'Невозможно оценить',
    },
  ],
}

export function formatTaskAssessmentResult(result: TaskAssessment['result']): string | undefined {
  // console.log('formatting task assessment result', result)
  return getKeyValuePairValue(source, result)
}
