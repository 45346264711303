import React, { useContext, useState } from 'react'

import { Accordion, AccordionDetails, AccordionSummary, Button, createStyles, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles, Theme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import classnames from 'classnames'
import endOfDay from 'date-fns/endOfDay'
import isPast from 'date-fns/isPast'
import { useTranslation } from 'react-i18next'
import { generatePath, Link, useNavigate, useParams } from 'react-router-dom'
import { useAsync, useNetworkState } from 'react-use'

import { TStageLoad, StageLoad } from '../../../components/stage-load'
import { LogManager } from '../../../infrastructure/logger'
import { BackButton } from '../../../layout/back-button'
import { PageContent } from '../../../layout/page-content'
import { TitleBar } from '../../../layout/title-bar'
import TitleView from '../../../layout/title-view'
import { ApiContext } from '../../../providers'
import { useFetchPendingItems } from '../../../providers/menu-data/pending-items-store'
import { appToast } from '../../../utils'
import { getGeolocation } from '../../../utils/get-geolocation'
import { useFetchVisit } from '../../_common/hooks/useFetchVisit'
import { useSaveVisitCoordinates } from '../../_common/hooks/useSaveVisitCoordinates'
import { useStartVisit } from '../../_common/hooks/useStartVisit'
import { useTryUploadAll } from '../../_common/hooks/useTryUpload'
import { useFeatureRoute } from '../../custom-app-feature/app-feature-context'
import { ErrorPage } from '../../error-page'
import { ProblemsPosList } from '../../stores/store-menu-page/problems/problems-pos-list'
import { VisitModal } from '../components'
import { VisitTaskList } from '../index'
import { visitsRoutes } from '../visits-app-feature/visits-app-feature'
import { usePermissionsShowProblemsListOnVisitCard } from './use-permission-show-problems'
import { useTaskList } from './useTaskList'
import { setVisitStatusFn } from './utils'
import VisitActionButton from './visit-action-button'
import VisitMenu from './visit-menu'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 147,
      paddingBottom: theme.spacing(12),
      [theme.breakpoints.down('xs')]: {
        paddingTop: 131,
      },
    },
    currentItem: {
      display: 'flex',
      alignItems: 'center',
    },
    surveyTitle: {
      marginBottom: theme.spacing(4),
      height: theme.spacing(9),
      background: 'rgba(0, 61, 165, 0.03)',
    },
    addButton: {
      position: 'absolute',
      top: 48,
      color: 'white',
      '-webkit-transform': 'translateZ(0)',
      right: 24,
      zIndex: 1550,
      [theme.breakpoints.down('xs')]: {
        top: 44,
      },
    },
    addIcon: {
      marginRight: theme.spacing(1),
    },
    statusPlanned: {
      borderLeft: '4px solid',
      borderColor: 'rgba(112, 113, 115, 0.5)',
    },
    statusFinished: {
      borderLeft: '4px solid',
      borderColor: '#538928',
    },
    statusInProgress: {
      borderLeft: '4px solid',
      borderColor: '#EB690B',
    },
    statusCanceled: {
      borderLeft: '4px solid',
      borderColor: '#B80718',
    },
    actionButtonWrap: {
      position: 'fixed',
      right: 55,
      bottom: 52,
      zIndex: 10,
      [theme.breakpoints.down('xs')]: {
        right: 0,
        left: 0,
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
      },
    },
    accordion: {
      paddingTop: 15,
      '& .MuiPaper-elevation1': {
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
      '& .MuiAccordion-root:before': {
        display: 'none',
      },
      '& .MuiAccordionSummary-root': {
        justifyContent: 'flex-start',
        minHeight: '40px !important',
        padding: '0px 20px',
      },
      '& .MuiAccordionSummary-content.Mui-expanded, & .MuiAccordion-root.Mui-expanded': {
        margin: 0,
      },
      '& .MuiAccordionSummary-content': {
        margin: 0,
        flexGrow: 0,
      },
      '& .MuiAccordionDetails-root': {
        width: '100%',
        padding: '8px 0 20px',
      },
      '& .MuiAccordionSummary-expandIcon.Mui-expanded, & .MuiAccordionSummary-expandIcon': {
        padding: '0 !important',
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: 11,
      },
    },
    accordionButton: {
      position: 'absolute',
      top: 3,
      right: 15,
      textTransform: 'none',
      fontSize: 15,
      lineHeight: '26px',
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
    accordionButtonIcon: {
      marginRight: 4,
    },
    accordionTitle: {
      marginLeft: 4,
      marginRight: 6,
      fontSize: 16,
      lineHeight: '26px',
      fontWeight: 500,
    },
    taskListWrap: {
      width: '100%',
      padding: '0 20px',
      flexGrow: 1,
    },
    nameWrap: {
      display: 'flex',
      paddingRight: 32,
      [theme.breakpoints.down('xs')]: {
        paddingRight: 16,
      },
    },
    problemListWrap: {
      width: '100%',
      padding: '0 20px',
      '& .MuiPaper-root': {
        backgroundColor: 'white',
      },
      '& #listWrap': {
        paddingBottom: 0,
      },
    },
    status: {
      flexShrink: 0,
      fontSize: 13,
      fontWeight: 500,
      lineHeight: '20px',
      height: 24,
      padding: '6px 12px',
      color: 'white',
      borderRadius: 40,
      marginTop: 7,
      marginLeft: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        width: 12,
        height: 12,
        padding: 0,
        marginLeft: 18,
        marginTop: 13,
        borderRadius: '50%',
      },
    },
    statusText: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    statusDotPlanned: {
      backgroundColor: theme.palette.info.main,
    },
    statusDotFinished: {
      backgroundColor: theme.palette.success.main,
    },
    statusDotInProgress: {
      backgroundColor: theme.palette.warning.main,
    },
    statusDotCanceled: {
      backgroundColor: theme.palette.grey[600],
    },
    name: {
      fontSize: 28,
      lineHeight: '36px',
      fontWeight: 500,
      letterSpacing: '0.1px',
      [theme.breakpoints.down('xs')]: {
        fontSize: 22,
        fontWeight: 400,
      },
    },
    backButton: {
      marginLeft: -8,
      marginRight: 8,
      [theme.breakpoints.down('xs')]: {
        marginLeft: -4, // looks worse but easier to click
        width: 32,
        padding: 2,
      },
    },
  }),
)

const logger = LogManager.getLogger('VisitViewPage')

const VisitViewPage: React.FC = () => {
  const tryUploadPendingVisit = useTryUploadAll()
  const fetchPendingItems = useFetchPendingItems()
  const isPermissionShowProblems = usePermissionsShowProblemsListOnVisitCard()

  const { t } = useTranslation('visits')
  const classes = useStyles()
  // const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'))
  const networkState = useNetworkState()
  const isOnline = networkState.online

  const [stageLoading, setStageLoading] = useState<TStageLoad | null>(null)
  const [finishVisitModalOpen, setFinishVisitModalOpen] = useState(false)
  const [finishVisitLoading, setFinishVisitLoading] = useState(false)
  const currentRouteParams = useParams<'id'>() as { id: string }
  const visitCode = currentRouteParams.id
  const navigate = useNavigate()
  const featureRoute = useFeatureRoute()
  const api = useContext(ApiContext)
  const visitOps = useFetchVisit({ visitCode }, { withPos: true }, [visitCode])
  const saveVisitCoordinates = useSaveVisitCoordinates()
  const { startVisit } = useStartVisit()
  const { getTaskList } = useTaskList()

  const [finishStatus, setFinishStatus] = useState<{ isCanFinish: boolean; message?: string }>()

  const userProfile = useAsync(async () => await api.userProfile.getCurrentProfile())

  const reasonsCancelledItems = userProfile.value?.profile?.availableVisitCancelationReasons
    ?.filter((item) => {
      const availableVisitSources = item.availableVisitSources
      if (!availableVisitSources?.length) {
        return true
      }
      return availableVisitSources?.some((source) => visitOps.value?.source === source)
    })
    .map((item) => item.reason)

  const checkCanFinish = async (): Promise<void> => {
    const listTasksOps = await getTaskList(currentRouteParams.id)
    const listTasks = [...listTasksOps.visits, ...listTasksOps.registers]

    const hasFinishTask = !!listTasks.find((x) => x.status === 'Finished')

    if (!hasFinishTask) {
      setFinishStatus({ isCanFinish: false, message: 'Необходимо завершить хотя бы одну задачу' })
      return
    }

    const canFinishViaTasks = listTasks.every((x) => x.status !== 'Planned' && x.status !== 'InProgress')

    if (!canFinishViaTasks) {
      setFinishStatus({
        isCanFinish: false,
        message: 'В визите есть задачи, которые необходимо завершить или отменить',
      })
      return
    }

    setFinishStatus({ isCanFinish: canFinishViaTasks && hasFinishTask })
  }

  useAsync(checkCanFinish, [])

  if (visitOps.error) {
    return <ErrorPage errorMessage={visitOps.error.message} />
  }

  if (visitOps.loading && !visitOps.value) return <></>
  const visit = visitOps.value!

  const onVisitStart = async (): Promise<void> => {
    const visitCode = visit.code
    const started = await startVisit(visitCode)
    if (!started) return

    let geolocation: GeolocationPosition | undefined
    setStageLoading('Geolocation')
    try {
      geolocation = await getGeolocation()
      console.log('Current coordinates: ', geolocation)
    } catch (error) {
      appToast.error(`${error.message}`)
      logger.error('onVisitStart', 'error get geolocation', error, { visitCode })
      LogManager.flush()
    }
    setStageLoading('Visit')

    if (geolocation) await saveVisitCoordinates(visitCode, geolocation)
    await visitOps.retry()
    // appToast.success('Визит начат')
    setStageLoading(null)

    void tryUploadPendingVisit(true)
  }

  const onVisitEdit = async (): Promise<void> => {
    await navigate(generatePath(visitsRoutes.visitEdit, { featureRoute, id: visitCode }))
  }

  const onFinishClick = (): void => {
    if (finishStatus?.isCanFinish) {
      setFinishVisitModalOpen(true)
    } else {
      const message = finishStatus?.message
      if (message) {
        appToast.info(message)
      }
    }
  }

  const onBack = (): void => {
    navigate(generatePath(visitsRoutes.root, { featureRoute }))
  }

  const onVisitFinish = async (): Promise<void> => {
    const visitCode = visit.code

    const visitResult = await setVisitStatusFn({
      apiContext: api,
      visitCode,
      visitStatus: 'Finished',
    })
    if (!visitResult) {
      setFinishVisitModalOpen(false)
      return
    }

    if (isOnline) {
      try {
        setFinishVisitModalOpen(false)
        setFinishVisitLoading(true)
        await tryUploadPendingVisit()
      } catch (e) {
        setFinishVisitLoading(false)
        logger.error('onVisitFinish', 'uploadPendingVisit was failed', e, { visitCode })
      }
    }
    setFinishVisitLoading(false)

    fetchPendingItems()
    await onBack()
    // appToast.success('Визит закончен')

    // await visit.retry()
  }

  const onTaskAddClick = async (evt: React.MouseEvent<HTMLElement>): Promise<void> => {
    evt.stopPropagation()
    const path = generatePath(visitsRoutes.visitTaskAdd, { featureRoute, id: visitCode })
    await navigate(path)
  }

  const renderMenu = (): JSX.Element => (
    <VisitMenu
      visit={visit}
      isOpenVisitMenuItem={false}
      reasonsCancelledItems={reasonsCancelledItems}
      retry={async () => {
        await visitOps.retry()
      }}
    />
  )

  if (stageLoading) {
    return <StageLoad stage={stageLoading} />
  }

  return (
    <div className={classes.root}>
      <TitleBar fixed icons={renderMenu()} additionalToolbar={<TitleView visit={visit} pos={visit.pointOfSale} />}>
        <Box className={classes.nameWrap}>
          <BackButton className={classes.backButton} onBack={onBack} />
          <Typography className={classes.name} component='h6' noWrap={true}>
            {visit.pointOfSale?.name}
          </Typography>
          <Typography
            className={classnames(classes.status, {
              [classes.statusDotPlanned]: visit.status === 'Planned',
              [classes.statusDotFinished]: visit.status === 'Finished',
              [classes.statusDotInProgress]: visit.status === 'InProgress',
              [classes.statusDotCanceled]: visit.status === 'Canceled',
            })}
            component='h6'
          >
            <span className={classes.statusText}>{t(visit.status)}</span>
          </Typography>
        </Box>
      </TitleBar>
      <PageContent>
        <div className={classes.accordion}>
          {visit.status === 'Canceled' && (
            <Box px={3}>
              <Typography>{visit.cancelationReason?.name ?? 'Нет причины отмены'}</Typography>
            </Box>
          )}
          <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<ArrowDropUpIcon />} aria-controls='tasks-content' id='tasks-header'>
              <Typography className={classes.accordionTitle}>{t('tasks')}</Typography>
              {(visit.status === 'InProgress' || visit.status === 'Planned') && (
                <Button
                  className={classes.accordionButton}
                  onClick={onTaskAddClick}
                  disabled={isPast(endOfDay(visit.plannedStartDate))}
                >
                  <AddIcon className={classes.accordionButtonIcon} /> {t('addTask')}
                </Button>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Box className={classes.taskListWrap}>
                <VisitTaskList
                  visitPos={visit.pointOfSale}
                  visitStatus={visit.status}
                  visitPlannedDate={visit.plannedStartDate}
                  checkCanFinish={checkCanFinish}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
          {isPermissionShowProblems && (visit.status === 'Planned' || visit.status === 'InProgress') && (
            <Accordion defaultExpanded={true}>
              <AccordionSummary expandIcon={<ArrowDropUpIcon />} aria-controls='tasks-content' id='tasks-header'>
                <Typography className={classes.accordionTitle}>{t('problems')}</Typography>
                <Link to={`/stores/${visit.pointOfSale?.code}/addProblem`}>
                  <Button className={classes.accordionButton}>
                    <AddIcon className={classes.accordionButtonIcon} /> {t('addProblem')}
                  </Button>
                </Link>
              </AccordionSummary>
              <AccordionDetails>
                <Box className={classes.problemListWrap}>
                  <ProblemsPosList posCode={visit.pointOfSale?.code} />
                </Box>
              </AccordionDetails>
            </Accordion>
          )}
        </div>

        {!(visit.status === 'Finished' || visit.status === 'Canceled') && (
          // <>
          <Box className={classes.actionButtonWrap}>
            <VisitActionButton
              visit={visit}
              loading={finishVisitLoading}
              onEditClick={onVisitEdit}
              onStartClick={async () => onVisitStart()}
              onFinishClick={onFinishClick}
              isCanFinish={!!finishStatus?.isCanFinish}
            />
          </Box>
        )}
      </PageContent>

      <VisitModal
        open={finishVisitModalOpen}
        onOk={onVisitFinish}
        onClose={() => setFinishVisitModalOpen(false)}
        title={t('confirmAction')}
        message={t('finishVisitWarning')}
      />
    </div>
  )
}

export default React.memo(VisitViewPage)
