import React from 'react'

import ToggleButton from '@material-ui/lab/ToggleButton'
import { useTranslation } from "react-i18next";

import { ToggleButtons } from '../../../../../../components/toggle-buttons'
import { SessionViewMode } from '../../../../template-tasks/utils'

interface ISectionPeriodSwitcherProps {
  currentViewMode: SessionViewMode
  onChangeViewMode: (newValue: SessionViewMode) => void
}

const SessionPeriodSwitcher: React.FC<ISectionPeriodSwitcherProps> = ({ currentViewMode, onChangeViewMode }) => {
  const isSmall = true
  const { t } = useTranslation('reports')

  const handleChangeViewMode = (event: React.MouseEvent<HTMLElement>, newValue: SessionViewMode | null): void => {
    if (!newValue) {
      return
    }
    onChangeViewMode(newValue)
  }

  return (
    <ToggleButtons
      size='small'
      value={currentViewMode}
      exclusive
      onChange={handleChangeViewMode}
      aria-label='session date switcher'
    >
      <ToggleButton value='last-week' aria-label='last-week'>
        {isSmall ? t('weekFilter') : t('lastWeekFilter')}
      </ToggleButton>
      <ToggleButton value='last-month' aria-label='last-month'>
        {isSmall ? t('monthFilter') : t('lastMonthFilter')}
      </ToggleButton>
      <ToggleButton value='all' aria-label='all'>
        {t('allFilter')}
      </ToggleButton>
    </ToggleButtons>
  )
}

export default SessionPeriodSwitcher
