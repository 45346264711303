import React from 'react'

import { Box, Theme, createStyles, makeStyles } from '@material-ui/core'

import { IDictionaryItem } from '../../../../../model/dictionary-item'
import { IKeyValuePair } from '../../../../../model/screen-item'
import TabItem from './tab-item'
import { isTypeKeyValuePair } from './utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'unset',
    },
    containerCenter: {
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'space-between',
      },
    },
  }),
)

interface IProps {
  listItems: IKeyValuePair[] | IDictionaryItem[]
  alignType: 'Left' | 'Center'
  selectedlistItem: string | undefined
  selectItem: (id: string) => void
}

const MultilineTabSelect: React.FC<IProps> = ({ listItems, alignType, selectedlistItem, selectItem }) => {
  const classes = useStyles()

  if (!listItems.at(0)) {
    return null
  }

  return (
    <Box className={`${classes.container} ${alignType === 'Center' ? classes.containerCenter : ''}`}>
      {listItems.map((item) => {
        if (isTypeKeyValuePair(item)) {
          return (
            <TabItem
              key={item.key}
              id={item.key}
              title={item.key}
              handleClick={selectItem}
              isSelected={selectedlistItem === item.key}
            />
          )
        } else {
          return (
            <TabItem
              key={item.code}
              id={item.code}
              title={item.name}
              handleClick={selectItem}
              isSelected={selectedlistItem === item.code}
            />
          )
        }
      })}
    </Box>
  )
}

export default MultilineTabSelect
