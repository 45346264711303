import { DependencyList, useContext, useEffect } from 'react'

import { useAsyncFn } from 'react-use'

import { LogManager } from '../../../infrastructure/logger'
import { Code } from '../../../model/base'
import { IPointOfSale } from '../../../model/pos'
import { IVisit } from '../../../model/visit'
import { ApiContext } from '../../../providers'
import { AsyncStateRetryPromise } from './useAsyncRetry'

export type VisitWithPos = IVisit & { pointOfSale: IPointOfSale | null }

interface VisitWithPosFlag {
  withPos: true
}

interface VisitWithoutPosFlag {
  withPos: false
}

interface IFetchVisitParams {
  visitCode: Code
}

/**
 * Загружать или нет связанную с визитом точку продаж
 */
interface IFetchVisitOptions {
  withPos?: boolean
}

const logger = LogManager.getLogger('useFetchVisit')

export function useFetchVisit(
  params: IFetchVisitParams,
  options: VisitWithPosFlag,
  deps?: DependencyList
): AsyncStateRetryPromise<VisitWithPos | null>

export function useFetchVisit(
  params: IFetchVisitParams,
  options: VisitWithoutPosFlag,
  deps?: DependencyList
): AsyncStateRetryPromise<IVisit | null>

/**
 * Загружает визит найденный по Code
 */
export function useFetchVisit(
  params: IFetchVisitParams,
  options: IFetchVisitOptions,
  deps: DependencyList = []
): AsyncStateRetryPromise<IVisit | VisitWithPos | null> {
  const api = useContext(ApiContext)

  const { visitCode } = params

  const [state, callback] = useAsyncFn(
    async () => {
      try {
        const visit = await api.visits.getVisit(visitCode)

        if (!visit) {
          return null
        }

        if (options.withPos && visit.pointOfSaleCode) {
          const pointOfSale = await api.pos.getPos(visit.pointOfSaleCode)
          const visitWithPos: VisitWithPos = { ...visit, pointOfSale }
          return visitWithPos
        }

        return visit
      } catch (error) {
        const message = 'Ошибка при получении данных о визите'
        logger.error('get', message, error, { visitCode })
        throw new Error(message)
      }
    },
    [visitCode, ...deps],
    { loading: true }
  )

  useEffect(() => {
    if (!api?.visits || !visitCode) {
      return
    }
    void callback()
  }, [api.visits, visitCode])

  const retry = callback
  return { ...state, retry }
}
