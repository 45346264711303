import { RequestConfig } from './build-request'

export function formRequest(request: RequestConfig, target: string): void {
  const formEl = document.createElement('form')

  formEl.method = request.method
  formEl.action = request.url
  formEl.target = target

  if (request.body) {
    if (request.body instanceof FormData) {
      for (const [name, value] of request.body.entries()) {
        const inputEl = document.createElement('input')
        inputEl.type = 'hidden'
        inputEl.name = name
        inputEl.value = value as string
        formEl.appendChild(inputEl)
      }
    } else {
      throw new Error('Unsupported request body')
    }
  }

  document.body.appendChild(formEl)

  formEl.submit()

  formEl.remove()
}
