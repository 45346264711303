import React, { useMemo, useState } from 'react'

import { Dialog } from '@material-ui/core'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'

import { Code } from '../../../../../model/base'
import { IBddmContent } from '../../../../../model/content'
import { IQuestionableListScreenItem } from '../../../../../model/questionable-list-screen-item'
import { checkPredicate } from '../../../../../model/script-predicate'
import { PhotoDialog } from '../../../../documents'
import { usePermissionsAddPhotos } from '../../../../documents/utils'
import { MakePhotoButton } from '../../../../execute-survey-control/photo-button'
import { useUpdateProperty } from '../../../nested/useUpdateProperty'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { IQuestionableRecord } from './utils'

interface Props {
  item: IQuestionableListScreenItem
  record: IQuestionableRecord
  index: number
}

export const QuestionablePhotosDialog: React.FC<Props> = ({ item, record, index }) => {
  const { t } = useTranslation('photo')
  const isPermissionsAddPhoto = usePermissionsAddPhotos()
  const localContext = useScriptTaskContext()
  const updateProperty = useUpdateProperty()

  const content = get(record, item.itemSettings.photosPropertyName ?? '') as IBddmContent
  // const photos = content?.parts ? content.parts.map((item) => item.target) : []
  const photos = useMemo(
    () => content?.parts?.map((item) => item.target) ?? [],
    [record, item.itemSettings.photosPropertyName],
  )
  const [photoDialogCode, setPhotoDialogCode] = useState<Code>()

  const required = item.itemSettings.photosSettings?.required
  let isRequired = false

  if (required) {
    switch (required.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.ScriptPredicate':
        isRequired = checkPredicate(required, record, localContext)
        break
      case 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate':
        isRequired = required.value
    }
  }

  const minOccurs = isRequired ? 1 : 0
  const maxOccurs = item.itemSettings.photosSettings?.maxPhotoCountAvailable ?? 5

  const setTaskPhotosPropertyPath = async (keys: string[]): Promise<void> => {
    const value: IBddmContent = {
      parts: keys.map((key) => {
        return {
          $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceMimeContentRef',
          code: key,
          target: key,
          type: 'JPG',
        }
      }),
    }
    await updateProperty(`${item.propertyName}.${index}.${item.itemSettings.photosPropertyName}`, value)
  }

  const available = item.itemSettings.photosSettings?.available
  if (available) {
    switch (available.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.ScriptPredicate':
        const isAvailable = checkPredicate(available, record, localContext)
        if (!isAvailable) {
          return <></>
        }
        break
      case 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate':
        if (!available.value) {
          return <></>
        }
    }
  }

  return (
    <>
      <Dialog open={!!photoDialogCode} fullScreen>
        <PhotoDialog
          title={t('photoDialogTitle')}
          isShow={!!photoDialogCode}
          inboxIds={photos}
          showCameraOnOpen={true}
          minOccurs={minOccurs}
          maxOccurs={maxOccurs}
          onClose={() => setPhotoDialogCode(undefined)}
          onSubmit={(ids) => {
            void setTaskPhotosPropertyPath([...ids])
          }}
          isReadOnly={false}
          allowAddPhoto={true}
          permissionsAddPhoto={isPermissionsAddPhoto}
          allowDeletePhoto={true}
        />
      </Dialog>

      <MakePhotoButton
        code={item.itemSettings.photosPropertyName ?? ''}
        onClick={setPhotoDialogCode}
        isPhotoError={!(minOccurs <= photos.length && maxOccurs >= photos.length)}
        isPhotoAdded={!!photos.length}
      />
    </>
  )
}
