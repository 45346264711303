import { IKeyValuePairsDataSource } from '../../../model/data-source'
import { getKeyValuePairValue } from '../template-tasks/composite-screen/table-item/label-cell/data-source'
import { VisitAssessment } from './audit-merchandise-service-task'

const source: IKeyValuePairsDataSource = {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.KeyValuePairsDataSource',
  items: [
    {
      $type: 'PMI.FACE.BDDM.Extensions.Classes.KeyValuePair',
      key: 'Positive',
      value: 'Позитивный',
    },
    {
      $type: 'PMI.FACE.BDDM.Extensions.Classes.KeyValuePair',
      key: 'Negative',
      value: 'Негативный',
    },
  ],
}

export function formatVisitAssessmentResult(result: VisitAssessment['result']): string | undefined {
  // console.log('formatting visit assessment status', result)
  return getKeyValuePairValue(source, result)
}
