import { useContext, useMemo } from 'react'

import { IDataObjectOperation } from '../../../../model/user-profile'
import { ProfileContext } from '../../../../providers'
import { IFaceDataObject, IFaceDataObjectReference } from '../models/models'

interface OperationsRequest {
  anyOf?: [IDataObjectOperation, ...IDataObjectOperation[]]
  allOf?: [IDataObjectOperation, ...IDataObjectOperation[]]
}

export function useHasPermissions() {
  const {
    value: { profile },
  } = useContext(ProfileContext)

  const permissions = useMemo(() => {
    return new Map(profile.dataAccessPermissions?.map((x) => [x.dataObject.code, x.availableOperations]))
  }, [profile.dataAccessPermissions])

  function hasPermissions(
    dataObject: IFaceDataObject | IFaceDataObjectReference | undefined,
    operations: OperationsRequest,
  ): boolean {
    if (!dataObject) {
      return false
    }

    const availableOperations = new Set(permissions.get(dataObject.code))
    if (availableOperations.size === 0) {
      return false
    }

    const hasOperation = availableOperations.has.bind(availableOperations)

    const { allOf = [], anyOf = [] } = operations

    if (allOf.length > 0 && anyOf.length > 0) {
      return allOf.every(hasOperation) && anyOf.some(hasOperation)
    } else if (allOf.length > 0) {
      return allOf.every(hasOperation)
    } else if (anyOf.length > 0) {
      return anyOf.some(hasOperation)
    } else {
      return false
    }
  }

  return hasPermissions
}

export function useHasDataObjectPermissions(dataObject: IFaceDataObject | IFaceDataObjectReference | undefined) {
  return useHasPermissions().bind(undefined, dataObject)
}
