import React, { useContext, useEffect, useState } from 'react'

import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  createStyles,
  SwipeableDrawer,
  Theme,
  useMediaQuery,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import classNames from 'classnames'
import { noop } from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import { createStateContext } from 'react-use'

import { HamburgerIcon } from '../components/icons/hamburger'
import { Sidebar } from './index'
import { MenuPage } from './menu-page'
import Menu from './menu/menu'
import { MenuContext } from './menu/menu-context'
import { PublicSvgIcon } from './menu/public-svg-icon'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = (theme: Theme) =>
  createStyles({
    layout: {
      width: '100vw',
      height: '100%',
      display: 'grid',
      gridTemplateColumns: '80px 1fr',
      gridTemplateRows: 'auto 1fr',
      gridTemplateAreas: `
        "menu appBar"
        "menu content"
      `,
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: '100%',
        gridTemplateRows: 'auto 1fr',
        gridTemplateAreas: `
          "appBar"
          "content"
        `,
        '&.useBottomMenu': {
          //   gridTemplateRows: 'auto 1fr 56px',
          //   gridTemplateAreas: `
          //   "appBar"
          //   "content"
          //   "bottomMenu"
          // `,
          paddingBottom: 56,
        },
      },
    },
    bottomMenu: {
      // gridArea: 'bottomMenu',
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
    },
    content: {
      gridArea: 'content',
      overflow: 'hidden scroll',
    },
  })

interface AppProps extends WithStyles<typeof styles> {
  classes: Record<keyof ReturnType<typeof styles>, string>
  appBar?: React.ReactNode
}

interface ILayoutContext {
  isLayout: boolean
}
export const LayoutContext = React.createContext<ILayoutContext | undefined>({} as ILayoutContext)

export const [useSidebarOpen, SidebarOpenProvider] = createStateContext(false)

const Layout: React.FC<AppProps> = ({ children, classes, appBar }) => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'))
  const { useBottomMenu } = useContext(MenuContext)

  return (
    <LayoutContext.Provider value={{ isLayout: true }}>
      <SidebarOpenProvider>
        <div className={classNames(classes.layout, { useBottomMenu })}>
          <Sidebar />
          <Box gridArea='menu'>{!isSmall && <Menu onlyIcons />}</Box>
          {appBar && <Box gridArea='appBar'>{appBar}</Box>}
          <main className={classes.content}>{children}</main>

          {isSmall && useBottomMenu && (
            <div className={classes.bottomMenu}>
              <BottomMenu />
            </div>
          )}
        </div>
      </SidebarOpenProvider>
    </LayoutContext.Provider>
  )
}

const BottomMenu: React.FC = () => {
  const { bottomMenuItems } = useContext(MenuContext)
  const navigate = useNavigate()
  const location = useLocation()
  const [isOpen, setIsOpen] = useState(false)

  const rootPath = location.pathname.split('/').find((pathSection) => pathSection)
  useEffect(() => {
    setIsOpen(false)
  }, [rootPath])
  console.log('layout location', rootPath)
  return (
    <BottomNavigation showLabels value={rootPath}>
      {bottomMenuItems.map((item) => {
        const feature = item.action.feature
        return (
          <BottomNavigationAction
            key={feature.routePath}
            label={item.displayName}
            onClick={() => {
              return navigate(`/${feature.routePath}`)
            }}
            value={feature.routePath}
            icon={<PublicSvgIcon path={item.iconPath ?? ''} />}
          />
        )
      })}
      <BottomNavigationAction
        key='menu'
        label='Меню'
        onClick={() => setIsOpen(true)}
        value='menu'
        icon={<HamburgerIcon />}
      />
      <SwipeableDrawer
        anchor='bottom'
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onOpen={noop}
        disableSwipeToOpen
        disableDiscovery
        PaperProps={{ style: { borderRadius: '16px 16px 0px 0px' } }}
      >
        <MenuPage />
      </SwipeableDrawer>
    </BottomNavigation>
  )
}

export default withStyles(styles, { name: 'PmLayout' })(Layout)
