/* eslint-disable @typescript-eslint/consistent-type-assertions */
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState } from 'react'

import { Box, createStyles, Dialog, IconButton, makeStyles, SvgIcon, Theme, Typography } from '@material-ui/core'
import { useTranslation } from "react-i18next";

import { ReactComponent as ImageBox } from '../../assets/icons/img_box.svg'
import { EmptyMessage } from '../../components'
import { HyperlinkAnswer, IAnswer, NumericAnswer, PhotoAnswer } from '../../model/answer'
import { AnswerReplyCommentRequirements } from '../../model/answer-reply-requirements'
import { Code } from '../../model/base'
import { IContent } from '../../model/content'
import { IQuestion } from '../../model/question'
import { IQuestionGroup } from '../../model/question-group'
import { IQuestionnaire } from '../../model/questionnaire'
import { ISurvey } from '../../model/survey'
import { ITheoryBlock } from '../../model/theory-block'
import { ModalContext } from '../../providers/modal'
import { appToast, getContentText } from '../../utils'
import { PhotoDialog } from '../documents'
import { usePermissionsAddPhotos } from '../documents/utils'
import { getAvailableQuestions } from '../tasks/sales-expert/tabs-new/trainee-skills/predicate-questions'
import { TheoryBlock } from '../tasks/sales-expert/tabs-new/trainee-skills/theory-block'
import { checkAttachmentCount, findPhotos, findRequirementByAnswer } from './answer-requirements-utils'
import { BooleanQuestionActions } from './boolean-question-actions'
import CommentModal from './comment-modal'
import { HyperlinkQuestion } from './hyperlink-question/hyperlink-question'
import NumericQuestion, { useNumericState } from './numeric-question/numeric-question'
import { MakePhotoButton } from './photo-button'
import PhotoQuestion from './photo-question/photo-question'
import { QuestionCard } from './question-card'
import TextQuestionBody, { useTextState } from './text-question/text-question-body'
import { isNullAnswer } from './utils'
import { validateAnswer, validateEsc } from './validate'

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0, 3),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 2),
      },
    },
    titleError: {
      // minHeight: 63,
      background: '#B807181A',
      marginLeft: -17,
      paddingLeft: 17,
      marginRight: -16,
      paddingRight: 16,
    },
    titleNoError: {
      // minHeight: 63
    },
    photoCount: {
      fontSize: 10,
      fontWeight: 500,
    },
  }),
)

interface IProps {
  readOnly?: boolean
  survey: ISurvey
  questionnaire: IQuestionnaire
  onRemoveAnswer: (code: Code) => void
  onBooleanAnswer: (code: Code, val: boolean | null) => void
  onTextAnswer: (code: Code, text: string) => void
  onHyperlinkAnswer: (code: Code, hyperlink: string) => void
  onNumericAnswer: (code: Code, numeric: number) => void
  onPhotoAnswer: (code: Code, photos: string[]) => void
  onSaveComment: (code: Code, comment: string) => void
  onReadyChange: (isReady: boolean) => void
  onPhotosSubmit: (code: Code, ids: string[]) => void
  onNullAnswer: (code: Code) => void
}

export interface IEscRef {
  focusFirstInvalidAnswer: () => void
}

const ExecuteSurveyControl: React.ForwardRefRenderFunction<IEscRef, IProps> = (
  {
    readOnly,
    survey,
    questionnaire,
    onRemoveAnswer,
    onBooleanAnswer,
    onTextAnswer,
    onHyperlinkAnswer,
    onNumericAnswer,
    onPhotoAnswer,
    onSaveComment,
    onPhotosSubmit,
    onReadyChange,
    onNullAnswer,
  },
  ref,
) => {
  const classes = useStyles()
  const { t } = useTranslation('photo')
  const answers = survey.answers ?? []
  const availableQuestions = getAvailableQuestions(questionnaire.questions, answers)
  const availableTheoryBlocks = getAvailableQuestions(questionnaire.theoryBlocks ?? [], answers)
  const modalContext = useContext(ModalContext)
  const isPermissionsAddPhoto = usePermissionsAddPhotos()
  const [photoDialogCode, setPhotoDialogCode] = useState<Code>()
  const setNumericState = useNumericState((state) => state.setValue)
  const setTextState = useTextState((state) => state.setValue)

  const questionnaireGroupEntries: QuestionnaireGroupEntry[] = [...availableQuestions, ...availableTheoryBlocks].sort(
    (a, b) => {
      function getGroupOrder(q: QuestionnaireEntry): number {
        return (
          questionnaire.questionGroups?.find((group) => entryParentGroupCodeEquals(q, group.code))?.orderNumber ?? 0
        )
      }

      const groupOrder = getGroupOrder(a) - getGroupOrder(b)
      return groupOrder || a.orderNumber - b.orderNumber
    },
  )
  const availableGroups =
    questionnaire.questionGroups?.filter((group) =>
      questionnaireGroupEntries.find((entry) => entryParentGroupCodeEquals(entry, group.code)),
    ) ?? []

  const questionnaireEntries: QuestionnaireEntry[] = [
    ...availableGroups,
    ...questionnaireGroupEntries.filter((entry) => !entryHasParentGroupCode(entry)),
  ].sort((a, b) => {
    return a.orderNumber - b.orderNumber
  })

  const isFinished = readOnly ?? false

  const isReady = !validateEsc(availableQuestions, answers).invalidQuestion
  useEffect(() => {
    onReadyChange(isReady)
  }, [isReady])

  const onReadyChangeHandler = ():void => {
    const isReady = !validateEsc(availableQuestions, answers).invalidQuestion
    onReadyChange(isReady)
  }

  useEffect(() => {
    console.log('esc questionnaire', questionnaire, survey)
  }, [questionnaire])

  function checkAnswer(partialAnswer: IAnswer, question: IQuestion, onDone: () => Promise<void>): void {
    const currentReplyRequirement = findRequirementByAnswer(question.answerReplyRequirements, partialAnswer)
    if (currentReplyRequirement?.commentRequirements === AnswerReplyCommentRequirements.Required) {
      return modalContext.open(
        <CommentModal //
          open
          isCommentRequired
          comment={null}
          titleDescription={question.description}
          onSave={async (comment) => {
            await onDone()
            onSaveComment(partialAnswer.questionCode, comment)
            modalContext.close()
          }}
          onClose={modalContext.close}
        />,
      )
    }

    void onDone()
  }

  const onBooleanAnswerRequest = (code: Code, val: boolean | null): void => {
    const question = questionnaire.questions.find((question) => question.code === code)
    if (!question) {
      return console.error('question not found', code, questionnaire)
    }
    const partialAnswer: IAnswer = {
      $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceBooleanAnswer',
      questionCode: code,
      booleanAnswer: val,
    } as IAnswer
    checkAnswer(partialAnswer, question, async () => onBooleanAnswer(code, val))
  }

  const onNullAnswerRequest = (code: Code): void => {
    onNullAnswer(code)
  }

  const onTextAnswerRequest = (code: Code, textAnswer: string): void => {
    onTextAnswer(code, textAnswer)
  }

  const onHyperlinkAnswerRequest = (code: Code, hyperlinkAnswer: string): void => {
    onHyperlinkAnswer(code, hyperlinkAnswer)
  }

  const onNumericAnswerRequest = (code: Code, numericAnswer: number): void => {
    onNumericAnswer(code, numericAnswer)
  }

  const onPhotoAnswerRequest = (code: Code, photoAnswer: string[]): void => {
    onPhotoAnswer(code, photoAnswer)
  }

  const renderQuestionGroupEntry = (entry: QuestionnaireGroupEntry): JSX.Element => {
    if (isQuestion(entry)) {
      const question = entry
      const answer = answers.find((answer) => answer.questionCode === question.code)
      const replyReq = findRequirementByAnswer(question.answerReplyRequirements, answer)
      const photoReq = replyReq?.attachmentRequirements?.find((attReq) => attReq.attachmentType === 'Photo')

      const photoCount = findPhotos(question.code, answers)?.length ?? 0
      const isPhotoError = !checkAttachmentCount(photoReq, photoCount)
      const titleButtons =
        !isNullAnswer(answer) &&
        answer &&
        photoReq &&
        photoReq.maxOccurs > 0 &&
        (!readOnly ? (
          <Box whiteSpace='nowrap' alignItems='flex-end' display='flex' flexDirection='column'>
            <MakePhotoButton
              code={question.code}
              onClick={setPhotoDialogCode}
              isPhotoError={isPhotoError}
              isPhotoAdded={!!photoCount}
            />
          </Box>
        ) : (
          !!photoCount && (
            <Box whiteSpace='nowrap'>
              <IconButton color='primary' size='small' onClick={() => setPhotoDialogCode(question.code)}>
                <SvgIcon component={ImageBox} />
              </IconButton>
            </Box>
          )
        ))

      const getErrorLine = ():boolean => {
        if (answer) {
          return validateAnswer(answer, question).isError
        }
        return question?.required !== false
      }

      return (
        <QuestionCard
          readOnly={readOnly}
          hasAnswer={!!answer && !isNullAnswer(answer)}
          isError={getErrorLine()}
          className={`QUESTION_CODE-${question.code}`}
          title={question.description ?? ''}
          titleButtons={titleButtons}
          onRemoveAnswer={() => {
            setNumericState(question.code, undefined)
            setTextState(question.code, undefined)

            onRemoveAnswer(question.code)
          }}
          question={question}
        >
          {question.$type === 'PMI.FACE.BDDM.Extensions.Classes.FaceBooleanQuestion' && (
            <BooleanQuestionActions
              // titleClassName={titleButtons && !readOnly && isPhotoError ? classes.titleError : classes.titleNoError}
              description={getContentText(question.content)}
              naEnabled={question.notAvailableAnswerEnabled}
              commentReq={replyReq?.commentRequirements}
              title={question.description ?? ''}
              comment={answer?.comment ?? null}
              disabled={isFinished}
              onBooleanAnswer={async (val) => onBooleanAnswerRequest(question.code, val)}
              onSaveComment={async (comment) => onSaveComment(question.code, comment)}
              answer={answer}
              question={question}
              onNullAnswer={() => {
                onNullAnswerRequest(question.code)
              }}
            />
          )}
          {question.$type === 'PMI.FACE.BDDM.Extensions.Classes.FaceTextQuestion' && (
            <TextQuestionBody
              questionCode={question.code}
              // titleClassName={titleButtons && isPhotoError ? classes.titleError : classes.titleNoError}
              // key={String(!!answer)}
              title={question.description ?? ''}
              description={getContentText(question.content)}
              onAnswer={async (answerText) => onTextAnswerRequest(question.code, answerText)}
              onRemove={() => {
                void onRemoveAnswer(question.code)
              }}
              answerEntryValue={answer?.reply as string}
              disabled={isFinished}
              showMode={question.showMode}
              dataList={question.dataList}
              allowOtherOption={question.allowOtherOption}
              placeholder={question.placeholder}
              answer={answer}
              question={question}
              onNullAnswer={() => {
                onNullAnswerRequest(question.code)
              }}
              allowMultipleSelection={question.allowMultipleSelection}
            />
          )}
          {question.$type === 'PMI.FACE.BDDM.Extensions.Classes.FaceNumericQuestion' && (
            <NumericQuestion
              questionCode={question.code}
              description={getContentText(question.content)}
              commentReq={replyReq?.commentRequirements}
              title={question.description ?? ''}
              comment={answer?.comment ?? null}
              answerValue={answer ? (answer as NumericAnswer).reply ?? null : undefined}
              disabled={isFinished}
              onNumericAnswer={async (answerNumeric) => onNumericAnswerRequest(question.code, answerNumeric)}
              onSaveComment={async (comment) => onSaveComment(question.code, comment)}
              minValue={question.minValue}
              maxValue={question.maxValue}
              incrementValue={question.incrementValue}
              errorHelperText={question.errorHelperText}
              answer={answer}
              question={question}
              onNullAnswer={() => {
                onNullAnswerRequest(question.code)
              }}
              getReadyChange={onReadyChangeHandler}
            />
          )}
          {question.$type === 'PMI.FACE.BDDM.Extensions.Classes.FaceHyperlinkQuestion' && (
            <HyperlinkQuestion
              description={getContentText(question.content)}
              commentReq={replyReq?.commentRequirements}
              title={question.description ?? ''}
              comment={answer?.comment ?? null}
              answerValue={answer ? (answer as HyperlinkAnswer).reply ?? null : undefined}
              disabled={isFinished}
              onHyperlinkAnswer={async (val) => onHyperlinkAnswerRequest(question.code, val)}
              onSaveComment={async (comment) => onSaveComment(question.code, comment)}
              linkFormat={question.linkFormat}
              linkDisplayName={question.linkDisplayName}
              finishedDisplayName={question.finishedDisplayName}
              answer={answer}
              question={question}
              onNullAnswer={() => {
                onNullAnswerRequest(question.code)
              }}
            />
          )}
          {question.$type === 'PMI.FACE.BDDM.Extensions.Classes.FacePhotoQuestion' && (
            <PhotoQuestion
              key={String(!!(answer as PhotoAnswer)?.reply?.parts?.length)}
              description={getContentText(question.content)}
              title={question.description ?? ''}
              answerContent={answer ? (answer as PhotoAnswer).reply ?? null : undefined}
              disabled={isFinished}
              onPhotoAnswer={async (val) => onPhotoAnswerRequest(question.code, val)}
              minPhotoCountRequired={question.minPhotoCountRequired}
              maxPhotoCountAvailable={question.maxPhotoCountAvailable}
              errorHelperText={question.errorHelperText}
              answer={answer}
              question={question}
              onNullAnswer={() => {
                onNullAnswerRequest(question.code)
              }}
            />
          )}
        </QuestionCard>
      )
    } else {
      return <TheoryBlock key={entry.code} theoryBlock={entry} />
    }
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        focusFirstInvalidAnswer: () => {
          function selectQuestion(question: IQuestion): void {
            const selector = `.QUESTION_CODE-${CSS.escape(question.code)}`
            const hiddenElement = document.querySelector(selector)
            // console.log('focusing', hiddenElement, selector)
            hiddenElement?.scrollIntoView({ block: 'center', behavior: 'smooth' })
          }

          const { invalidQuestion, message } = validateEsc(availableQuestions, survey.answers)

          if (invalidQuestion) {
            selectQuestion(invalidQuestion)
          }
          if (message) {
            appToast.info(message)
          }
        },
      }
    },
    [survey.answers, availableQuestions],
  )

  const currentDialogQuestion = questionnaire.questions.find((question) => question.code === photoDialogCode)
  const answer = answers.find((answer) => answer.questionCode === currentDialogQuestion?.code)
  const currentDialogRequest = findRequirementByAnswer(currentDialogQuestion?.answerReplyRequirements, answer)
  const currentDialogPhotoReq = currentDialogRequest?.attachmentRequirements?.find(
    (attReq) => attReq.attachmentType === 'Photo',
  )

  const currentPhotos = useMemo(
    () => findPhotos(photoDialogCode ?? '', answers) ?? defaultIds,
    [photoDialogCode, answers],
  )

  return (
    <div className={classes.root}>
      <Dialog open={!!photoDialogCode} fullScreen>
        <PhotoDialog
          // title={currentDialogQuestion?.description ?? ''}
          title={t('photoDialogTitle')}
          isShow={!!photoDialogCode}
          inboxIds={currentPhotos}
          showCameraOnOpen={currentPhotos.length === 0}
          minOccurs={currentDialogPhotoReq?.minOccurs ?? 0}
          maxOccurs={currentDialogPhotoReq?.maxOccurs ?? 0}
          onClose={() => setPhotoDialogCode(undefined)}
          onSubmit={(ids) => {
            console.log('onSubmit', photoDialogCode, ids)
            onPhotosSubmit(photoDialogCode!, ids)
          }}
          isReadOnly={readOnly}
          allowAddPhoto={true}
          permissionsAddPhoto={isPermissionsAddPhoto}
          allowDeletePhoto={true}
        />
      </Dialog>
      {questionnaireEntries.map((entry) => {
        if (isGroup(entry)) {
          const group = entry
          return (
            <section key={group.name}>
              {/* <SurveySectionTitle title={group.name} /> */}
              <Box paddingTop={1} paddingBottom={2} fontSize={22}>
                <Typography color='primary' variant='inherit'>
                  {group.name}
                </Typography>
              </Box>
              {questionnaireGroupEntries
                .filter((entry) => entryParentGroupCodeEquals(entry, group.code))
                .map(renderQuestionGroupEntry)}
            </section>
          )
        } else {
          return renderQuestionGroupEntry(entry)
        }
      })}
      {questionnaireEntries.length < 1 && (
        <Box height='calc(100vh - 205px)' display='flex' flexDirection='column' justifyContent='center'>
          <EmptyMessage message='Список вопросов пуст' />
        </Box>
      )}
    </div>
  )
}

export default React.memo(forwardRef(ExecuteSurveyControl))

type QuestionnaireEntry = IQuestionGroup | QuestionnaireGroupEntry
type QuestionnaireGroupEntry = ITheoryBlock | IQuestion
function isQuestion(entry: QuestionnaireGroupEntry): entry is IQuestion {
  if ('status' in entry) return true
  return false
}
function isGroup(entry: QuestionnaireEntry): entry is IQuestionGroup {
  if ('name' in entry) return true
  return false
}
function entryParentGroupCodeEquals(entry: QuestionnaireEntry, code: string): boolean {
  return isGroup(entry)
    ? entry.parentGroupCode === code
    : isQuestion(entry)
    ? entry.questionGroupCode === code
    : entry.parentGroupCode === code
}
function entryHasParentGroupCode(entry: QuestionnaireEntry): boolean {
  return isGroup(entry)
    ? entry.parentGroupCode != null
    : isQuestion(entry)
    ? entry.questionGroupCode != null
    : entry.parentGroupCode != null
}

const defaultIds: string[] = []

export function renderContent(content?: IContent): React.ReactNode {
  const contentText = getContentText(content)
  return (
    <div>
      {contentText.split('\n').map((line, i) => (
        <div key={i}>{line}</div>
      ))}
    </div>
  )
}