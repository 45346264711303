import React, { ChangeEvent, useEffect, useState } from 'react'

import { Button, createStyles, FormControl, InputLabel, OutlinedInput, Theme, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // width: '500px'
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap'
    },
    button: {
      marginLeft: theme.spacing(2)
    },
    overline: {
      lineHeight: '16px',
      color: '#8A949D',
      fontSize: 12,
      letterSpacing: '0.15px',
      textTransform: 'none',
      fontWeight: 500
    }
  })
)

interface IProps {
  comment: string | null
  titleDescription?: string
  onSave: (commentText: string) => void
  onClose: () => void
  open: boolean
  isCommentRequired?: boolean
}

const CommentModal: React.FC<IProps> = ({
  open,
  comment,
  titleDescription = '',
  onSave,
  onClose,
  isCommentRequired
}) => {
  const DEFAULT_COMMENT_VALUE = comment ?? ''
  const classes = useStyles()
  const [commentValue, setCommentValue] = useState<string>(DEFAULT_COMMENT_VALUE)
  useEffect(() => setCommentValue(comment ?? ''), [comment])

  const { t } = useTranslation('sales-expert-tasks')

  const onCommentChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>): void => setCommentValue(event.target.value)

  const isDisabledSave = (): boolean => {
    const value = commentValue.trim()
    if (isCommentRequired && !value) return true
    return comment?.trim() === value
  }

  return (
    <Dialog
      disableScrollLock
      fullWidth
      maxWidth='sm'
      className={classes.root}
      open={open}
      onClose={onClose}
      aria-labelledby='comment-dialog-title'
    >
      <DialogTitle disableTypography id='comment-dialog-title' className={classes.title}>
        <Typography variant='h5' style={{letterSpacing: '0.4px'}}>{t('commentModalTitle')}</Typography>
        <Typography className={classes.overline} variant='overline'>
          {titleDescription}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth variant='outlined' size='small'>
          <InputLabel htmlFor='comment'>{t('commentInputPlaceholder')}</InputLabel>
          <OutlinedInput
            autoFocus
            value={commentValue}
            onChange={onCommentChangeHandler}
            fullWidth
            rows={6}
            multiline
            id='comment'
            label={t('commentInputPlaceholder')}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          {t('commentModalCancel')}
        </Button>
        <Button onClick={() => onSave(commentValue)} autoFocus color='primary' disabled={isDisabledSave()}>
          {t('commentModalSave')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CommentModal
