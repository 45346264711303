import React, { useEffect } from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core'
import classNames from 'classnames'
import { format, endOfDay, startOfDay } from 'date-fns'

import { grays } from '../../../../../layout/theme'
import { IActionScreenItem } from '../../../../../model/screen-item-base'
import { checkPredicate, IPredicate } from '../../../../../model/script-predicate'
import { useUpdateProperty } from '../../../nested/useUpdateProperty'
import { getContextProperty } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { ItemCard } from '../item-card'
import RangeDateInput from './range-date-Input'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dataPicker: {
      position: 'relative',
      // zIndex: 9,
      color: theme.palette.common.black,
      '& .react-datepicker__tab-loop': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 340,
      },
      '& .react-datepicker-popper': {
        padding: '0 !important',
        width: 'auto',
        inset: '56px 0% auto auto !important',
        transform: 'translateX(0) !important',
      },
      '& .react-datepicker__triangle': {
        display: 'none',
      },
      '& .react-datepicker__current-month': {
        fontSize: 18,
        lineHeight: '21px',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        paddingBottom: 16,
      },
      '& .react-datepicker__header': {
        padding: '22px 0 8px',
        backgroundColor: 'transparent',
        border: 'none',
      },
      '& .react-datepicker__today-button': {
        padding: '22px 0 31px',
        backgroundColor: 'transparent',
        border: 'none',
        color: theme.palette.primary.main,
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: '0.1px',
      },
      '& .react-datepicker': {
        fontFamily: 'Lato',
        border: 'none',
        borderRadius: 12,
        boxShadow: '0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
      },
      '& .react-datepicker__day-name': {
        width: 41,
        padding: '13px 0',
        margin: '-1px 0 0 -1px',
        fontSize: 12,
        fontWeight: 600,
        lineHeight: '14px',
        textTransform: 'capitalize',
      },
      '& .react-datepicker__day': {
        verticalAlign: 'middle',
        margin: '-1px 0 0 -1px',
        padding: '13px 0 12px',
        width: 41,
        fontSize: 12,
        lineHeight: '14px',
        border: '1px solid #C7CDD7',
        '&:hover': {
          borderRadius: 0,
        },
      },
      '& .react-datepicker__day--selected': {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        fontWeight: '600',
      },
      '& .react-datepicker__day--keyboard-selected:not(.react-datepicker__day--in-selecting-range)': {
        borderRadius: 'inherit',
        backgroundColor: 'inherit',
        color: 'inherit',
        fontWeight: 'inherit',
      },
      '& .react-datepicker__day--in-range,  .react-datepicker__day--selecting-range-start': {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: '#fff  !important',
        borderRadius: '0.3rem !important',
      },
      '& .react-datepicker__month': {
        margin: '0 30px',
      },
      '& .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before':
        {
          borderWidth: '2px 2px 0 0',
          borderColor: '#546066',
        },
      '& .react-datepicker__navigation': {
        top: 18,
        '&--next': {
          right: 24,
        },
        '&--previous': {
          left: 24,
        },
      },
      '& .react-datepicker__day--outside-month': {
        backgroundColor: 'inherit',
        fontWeight: 'inherit',
        color: '#858F97',
      },
      '& .react-datepicker__day--disabled': {
        background: '#FCFCFC',
        color: '#858F97',
      },
      '& .react-datepicker__portal': {
        background: 'rgba(4, 4, 4, 0.15)',
      },
      '& .react-datepicker__close-icon': {
        right: '-18px',
      },
    },
    compact: {
      backgroundColor: '#fff',
      width: '244px',
      height: '40px',
      border: `1px solid ${grays.gray5}`,
      marginBottom: 8,
      borderRadius: '40px',
      padding: theme.spacing(0, 3),
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        padding: theme.spacing(0, 2),
      },
    },
    compactError: {
      '&:before': {
        content: `''`,
        height: '40px',
        width: '40px',
        borderRadius: '40px',
        borderLeft: '2px solid #D32F2F',
        borderTop: '2px solid #D32F2F',
        borderBottom: '2px solid transparent',
        borderRight: '2px solid transparent',
        transform: 'rotate(-45deg)',
        position: 'absolute',
        top: 0,
        left: 0,
      },
    },
  }),
)
export interface IRangeDateInputScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.RangeDateInputScreenItem'
  startDatePropertyName: string
  endDatePropertyName: string
  compactMode: boolean
  required?: IPredicate
  dateInterval?: {
    startDate?: string
    endDate?: string
  }
}

interface DateInputScreenItemProps {
  item: IRangeDateInputScreenItem
  startDate: string
  endDate: string
  onChange: (date: [Date | null, Date | null]) => void
  isError: boolean
}
const RangeDateInputScreenItem: React.FC<DateInputScreenItemProps> = (props) => {
  useEffect(() => {
    console.log('RangeDateInputScreenItem resolved value', `startDate - ${startDate}, endDate - ${endDate}`)
  }, [])

  const classes = useStyles()
  const { item, startDate, endDate, onChange, isError } = props
  const { dateInterval } = item
  const minDate = dateInterval?.startDate ? new Date(dateInterval?.startDate) : new Date()
  const maxDate = dateInterval?.endDate ? new Date(dateInterval?.endDate) : new Date('2900-12-31')

  if (item.compactMode) {
    return (
      <div className={classNames([classes.dataPicker, classes.compact, { [classes.compactError]: isError }])}>
        <RangeDateInput
          onChange={onChange}
          selectedStartDate={startDate ? startOfDay(new Date(startDate)).valueOf() : undefined}
          selectedEndDate={endDate ? endOfDay(new Date(endDate)).valueOf() : undefined}
          minDate={minDate}
          maxDate={maxDate}
        ></RangeDateInput>
      </div>
    )
  }
  return (
    <ItemCard label={item.displayName} isError={isError}>
      <div className={classes.dataPicker}>
        <RangeDateInput
          onChange={onChange}
          selectedStartDate={startDate ? startOfDay(new Date(startDate)).valueOf() : undefined}
          selectedEndDate={endDate ? endOfDay(new Date(endDate)).valueOf() : undefined}
          minDate={minDate}
          maxDate={maxDate}
        ></RangeDateInput>
      </div>
    </ItemCard>
  )
}
interface DateInputScreenItemControlProps {
  item: IRangeDateInputScreenItem
  children?: never
}
const RangeDateInputScreenItemControl: React.FC<DateInputScreenItemControlProps> = (props) => {
  useEffect(() => {
    console.log('RangeDateInputScreenItemControl mount', item)
  }, [])

  const { item } = props
  const context = useScriptTaskContext()
  const startDate: string = getContextProperty(context, item.startDatePropertyName)
  const endDate: string = getContextProperty(context, item.endDatePropertyName)
  const updateProperty = useUpdateProperty()

  const required = item.required && checkPredicate(item.required, undefined, context)
  const isError = required && (!startDate || !endDate)

  return (
    <RangeDateInputScreenItem
      item={item}
      startDate={startDate}
      endDate={endDate}
      onChange={async (value) => {
        const [start, end] = value

        await updateProperty(item.startDatePropertyName, start ? format(start, 'yyyy-MM-dd') : start)
        return updateProperty(item.endDatePropertyName, end ? format(end, 'yyyy-MM-dd') : end)
      }}
      isError={!!isError}
    ></RangeDateInputScreenItem>
  )
}

export default RangeDateInputScreenItemControl
