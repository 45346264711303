import { IEntityReference } from '../../../../model/base'
import {
  IIntegerInputScreenItem,
  IQuantityInputScreenItem,
  IRadioGroupScreenItem,
  IScreenItem,
  ISelectPOSScreenItem,
  ISingleSelectScreenItem,
  ISurveysPropertyScreenItem,
  ITabSelectScreenItem,
  SETaskDirectionsScreenItem,
  SETaskRepresentativeScreenItem,
} from '../../../../model/screen-item'
import { checkPredicate } from '../../../../model/script-predicate'
import { ICompositeUserStepScreen } from '../../../../model/user-step-screen'
import { getSurveysByPropertyName } from '../../../_common/hooks/useCreateSurvey'
import { getKeysFromContext } from '../../../documents/utils'
import { validateEmail } from '../../sales-expert/tabs-new/validators'
import { getContextProperty, getTaskProperty } from '../../script-tasks/propertyName'
import { IScriptTaskContext as IPropertiesContext, IScriptTaskContext } from '../../script-tasks/script-task-context'
import { validateSignatureImageItem } from '../report-stage-screen/validate'
import { IQuantity } from '../types'
import { validateBooleanInput, validateBooleanProperty } from './boolean/validate'
import { IContentDocumentSelectorScreenItem } from './content-document-selector-screen-item'
import { IDateInputScreenItem } from './date-input-screen-item'
import { getMaxValue, getMinValue } from './integer-input-item/utils'
import { IPredicateEditScreenItem } from './predicate-items/types'
import { checkPredicateItemValue } from './predicate-items/utils'
import { IQuantityRecord } from './quantity-input-item/utils'
import { validateQuestionableListScreenItem } from './questionable-list-item/validate-questionable'
import { IRangeDateInputScreenItem } from './range-date-item/range-date-input-screen-item'
import { SCOPE_EMPLOYEE_KEY } from './select-employee-for-audit/select-employee-for-audit'
import { ISelectTaskTemplateScreenItem } from './select-task-template-screen-item'
import { getTaskRepresentativeMockItems } from './sev3-items/task-representative-utils'
import { validateFillSurveyScreenItem } from './surveys/validate'
import { checkNumericRangeError, MAX_NUMERIC_VALUE, MIN_NUMERIC_VALUE } from './table-item/number-editor'
import { validateTableScreenItem } from './table-item/validate-table'
import { convertMediaContentList, findAllItems, MediaEntity } from './utils'

export interface IValidation {
  isError: boolean
  message?: string
}

export function validateStringItem(item: IScreenItem, value: string): IValidation {
  if (item.$type === 'PMI.FACE.BDDM.Extensions.Classes.StringPropertyScreenItem') {
    if (item.editSettings?.required && !value?.trim()) {
      return {
        isError: true,
        message: item.editSettings.errorHelperText,
      }
    }
    if (item.editSettings?.maxLength != null && value?.trim().length > item.editSettings.maxLength) {
      return {
        isError: true,
        message: `Количество символов не должно превышать ${item.editSettings.maxLength}`,
      }
    }
  }
  return {
    isError: false,
    message: undefined,
  }
}

export function validateInputStringItem(item: IScreenItem, value: string, context: IScriptTaskContext): IValidation {
  if (item.$type === 'PMI.FACE.BDDM.Extensions.Classes.StringInputScreenItem') {
    const isRequired = checkPredicate(
      item.required ?? { $type: 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate', value: false },
      undefined,
      context,
    )

    if (isRequired && !value?.trim()) {
      return {
        isError: true,
        message: item.errorHelperText,
      }
    }
    if (item.maxLength != null && value?.trim().length > item.maxLength) {
      return {
        isError: true,
        message: `Количество символов не должно превышать ${item.maxLength}`,
      }
    }

    if (value && item.mask && item.mask.length !== value?.trim().length) {
      return {
        isError: true,
        message: `Значение не соответствует маске ${item.mask}`,
      }
    }
  }
  return {
    isError: false,
  }
}

function validateTaskRepresentativeScreenItem(
  item: SETaskRepresentativeScreenItem,
  propertiesContext: IPropertiesContext,
): IValidation {
  const mockItems = getTaskRepresentativeMockItems(item)
  const nameValidation = validateStringItem(
    mockItems.name,
    getTaskProperty(propertiesContext.task, item.representativeNamePropertyName),
  )
  if (nameValidation.isError) {
    return nameValidation
  }

  const agencyValidation = validateStringItem(
    mockItems.agency,
    getTaskProperty(propertiesContext.task, item.agencyNamePropertyName),
  )
  if (agencyValidation.isError) {
    return agencyValidation
  }

  const email = getTaskProperty<string>(propertiesContext.task, item.represenatativeEmailPropertyName)
  const emailValidation = validateStringItem(mockItems.agency, email)
  if (emailValidation.isError) {
    return emailValidation
  }

  if (!validateEmail(email)) {
    return {
      isError: true,
      message: 'emailInputError',
    }
  }

  return {
    isError: false,
    message: undefined,
  }
}

export function validateTaskDirections(
  item: SETaskDirectionsScreenItem,
  propertiesContext: IPropertiesContext,
): IValidation {
  // console.log('validating TaskDirections', item, propertiesContext.surveys)
  const surveys = getSurveysByPropertyName(item.propertyName || 'task.surveys', propertiesContext)
  const lasSurvey = surveys.find((survey) => survey.questionnaire.code === item.educationDirectionLasSurveyCode)
  const lauSurvey = surveys.find((survey) => survey.questionnaire.code === item.educationDirectionLauSurveyCode)
  const lasAnswerCount = lasSurvey?.answers?.length ?? 0
  const lauAnswerCount = lauSurvey?.answers?.length ?? 0
  // console.log({ lasAnswerCount, lauAnswerCount, lasSurvey, lauSurvey })

  if (lasAnswerCount + lauAnswerCount < 1) {
    return {
      isError: true,
      message: 'edNotCompleted',
    }
  }

  return {
    isError: false,
    message: undefined,
  }
}

function validateSurveysProperty(item: ISurveysPropertyScreenItem, propertiesContext: IPropertiesContext): IValidation {
  const surveys = getSurveysByPropertyName(item.propertyName || 'task.surveys', propertiesContext)
  const fittingSurveys =
    surveys?.filter(
      (survey) => survey.questionnaire.code === item.questionnaire.code && survey.status === 'Finished',
    ) ?? []
  const minCount = item.editSettings?.minSurveyCountRequired ?? 0
  if (fittingSurveys.length < minCount) {
    console.log(minCount, propertiesContext)
    return {
      isError: true,
      message: `Минимальное количество завершенных опросов: ${minCount}`,
    }
  }
  return {
    isError: false,
  }
}

function validateItem(item: IScreenItem, propertiesContext: IScriptTaskContext): IValidation {
  switch (item.$type) {
    case 'PMI.FACE.BDDM.Extensions.Classes.StringPropertyScreenItem':
      return validateStringItem(item, getContextProperty(propertiesContext, item.propertyName))
    case 'PMI.FACE.BDDM.Extensions.Classes.StringInputScreenItem':
      return validateInputStringItem(item, getContextProperty(propertiesContext, item.propertyName), propertiesContext)
    case 'PMI.FACE.BDDM.Extensions.Classes.SalesExpertEducationTaskRepresentativeScreenItem':
      return validateTaskRepresentativeScreenItem(item, propertiesContext)
    case 'PMI.FACE.BDDM.Extensions.Classes.SalesExpertEducationTaskDirectionsScreenItem':
      return validateTaskDirections(item, propertiesContext)
    case 'PMI.FACE.BDDM.Extensions.Classes.SurveysPropertyScreenItem':
      return validateSurveysProperty(item, propertiesContext)
    case 'PMI.FACE.BDDM.Extensions.Classes.SignatureImageScreenItem':
      return validateSignatureImageItem(item, propertiesContext)
    case 'PMI.FACE.BDDM.Extensions.Classes.PhotosPropertyScreenItem': {
      return validatePhotosItem(item, propertiesContext)
    }
    case 'PMI.FACE.BDDM.Extensions.Classes.MediaContentListEditorScreenItem': {
      return validatePhotosItem(item, propertiesContext)
    }
    case 'PMI.FACE.BDDM.Extensions.Classes.MediaContentListViewerScreenItem': {
      return validatePhotosItem(item, propertiesContext)
    }
    case 'PMI.FACE.BDDM.Extensions.Classes.MimeContentEditorScreenItem': {
      return validatePhotosItem(item, propertiesContext)
    }
    case 'PMI.FACE.BDDM.Extensions.Classes.TableScreenItem':
      return validateTableScreenItem(item, propertiesContext)

    case 'PMI.FACE.BDDM.Extensions.Classes.QuestionableListScreenItem':
      return validateQuestionableListScreenItem(item, propertiesContext)

    case 'PMI.FACE.BDDM.Extensions.Classes.BooleanPropertyScreenItem':
      return validateBooleanProperty(item, propertiesContext)

    case 'PMI.FACE.BDDM.Extensions.Classes.BooleanInputScreenItem':
      return validateBooleanInput(item, propertiesContext)

    case 'PMI.FACE.BDDM.Extensions.Classes.RadioGroupScreenItem':
      return validateRadioGroupScreenItem(item, propertiesContext)

    case 'PMI.FACE.BDDM.Extensions.Classes.QuantityInputScreenItem':
      return validateQuantityInputScreenItem(item, propertiesContext)

    case 'PMI.FACE.BDDM.Extensions.Classes.IntegerInputScreenItem':
      return validateIntegerInputScreenItem(item, propertiesContext)

    case 'PMI.FACE.BDDM.Extensions.Classes.FillSurveyScreenItem':
      return validateFillSurveyScreenItem(item, propertiesContext)

    case 'PMI.FACE.BDDM.Extensions.Classes.SingleSelectScreenItem':
      return validateSingleSelectScreenItem(item, propertiesContext)

    case 'PMI.FACE.BDDM.Extensions.Classes.DateInputScreenItem':
      return validateDateInputScreenItem(item, propertiesContext)
    case 'PMI.FACE.BDDM.Extensions.Classes.RangeDateInputScreenItem':
      return validateRangeDateInputScreenItem(item, propertiesContext)

    case 'PMI.FACE.BDDM.Extensions.Classes.PredicateEditScreenItem':
      return validatePredicateEditScreenItem(item, propertiesContext)

    case 'PMI.FACE.BDDM.Extensions.Classes.ContentDocumentSelectorScreenItem':
      return validateContentDocumentSelectorScreenItem(item, propertiesContext)

    case 'PMI.FACE.BDDM.Extensions.Classes.SelectPOSScreenItem':
      return validateSelectPOSScreenItem(item, propertiesContext)
    case 'PMI.FACE.BDDM.Extensions.Classes.SelectFieldForceTaskTemplateScreenItem':
      return selectTaskTemplateScreenItem(item, propertiesContext)
    case 'PMI.FACE.BDDM.Extensions.Classes.SelectEmployeeForAuditScreenItem':
      const selectedEmployeeKey =
        getContextProperty(propertiesContext, SCOPE_EMPLOYEE_KEY, null) ??
        getContextProperty<IEntityReference | null>(
          propertiesContext,
          item.selectedPositionRoleReferencePropertyName,
          null,
        )?.code

      const isRequired = item.required ? checkPredicate(item.required, undefined, propertiesContext) : false
      return {
        isError: isRequired && !selectedEmployeeKey,
        message: 'Выберите сотрудника',
      }

    case 'PMI.FACE.BDDM.Extensions.Classes.TabSelectScreenItem': 
      return validateTabSelectScreenItem(item, propertiesContext)

    case 'PMI.FACE.BDDM.Extensions.Classes.DictionaryItemPropertyScreenItem':
      if (item.editSettings?.required && item.actionKind === 'Edit') {
        const entry = getContextProperty(propertiesContext, item.propertyName)

        const value = entry instanceof Array ? entry[0] : entry

        console.log('validating dictionary', value, item, propertiesContext)
        return {
          isError: !value,
          message: item.editSettings?.errorHelperText,
        }
      }
  }

  return {
    isError: false,
    message: undefined,
  }
}

export function validateCompositeScreen(
  screen: ICompositeUserStepScreen,
  propertiesContext: IPropertiesContext,
): { invalidItem?: IScreenItem; message?: string } {
  const items = findAllItems(screen.items)

  for (const item of items) {
    const validation = validateItem(item, propertiesContext)
    if (validation.isError) {
      return {
        invalidItem: item,
        message: validation.message,
      }
    }
  }

  return {}
}

export function validatePhotosItem(entity: MediaEntity, propertiesContext: IScriptTaskContext): IValidation {
  const item = convertMediaContentList(entity, propertiesContext)
  const value = getKeysFromContext(propertiesContext, entity.propertyName)

  if (item.editSettings) {
    const { minPhotoCountRequired, maxPhotoCountAvailable } = item.editSettings
    if (value.length < minPhotoCountRequired || value.length > maxPhotoCountAvailable) {
      return {
        isError: true,
        message: item.editSettings.errorHelperText,
      }
    }
  }

  return {
    isError: false,
  }
}

export function validateRadioGroupScreenItem(
  item: IRadioGroupScreenItem,
  propertiesContext: IScriptTaskContext,
): IValidation {
  const key = getContextProperty(propertiesContext, item.propertyName, undefined) as unknown as string

  if (!key && item.required) {
    return {
      isError: true,
      message: item.errorHelperText ?? 'Не заполнено обязательное поле',
    }
  }

  return {
    isError: false,
  }
}

export function validateQuantityInputScreenItem(
  item: IQuantityInputScreenItem,
  propertiesContext: IScriptTaskContext,
): IValidation {
  const entryQuantity = getContextProperty(propertiesContext, item.propertyName, undefined) as unknown as
    | IQuantity
    | undefined

  const minValue = getMinValue(item, propertiesContext)
  const maxValue = getMaxValue(item, propertiesContext)

  let isRequired = false
  if (item.required) {
    switch (item.required.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.ScriptPredicate':
        isRequired = checkPredicate(item.required, {} as IQuantityRecord, propertiesContext)
        break
      case 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate':
        isRequired = item.required.value
    }
  }

  let isError = false

  if (isRequired) {
    isError = !entryQuantity
  }

  if (!isError) {
    if (typeof entryQuantity?.value === 'number') {
      isError = checkNumericRangeError(entryQuantity.value, minValue, maxValue)
    }
  }

  return {
    isError: isError,
    message: item.errorHelperText ?? `Введите значение от ${MIN_NUMERIC_VALUE} до ${MAX_NUMERIC_VALUE}`,
  }
}

export function validateIntegerInputScreenItem(
  item: IIntegerInputScreenItem,
  propertiesContext: IScriptTaskContext,
): IValidation {
  const value = getContextProperty(propertiesContext, item.propertyName, undefined) as unknown as number | null
  const minValue = getMinValue(item, propertiesContext)
  const maxValue = getMaxValue(item, propertiesContext)

  let isRequired = false
  if (item.required) {
    switch (item.required.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.ScriptPredicate':
        isRequired = checkPredicate(item.required, {} as IQuantityRecord, propertiesContext)
        break
      case 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate':
        isRequired = item.required.value
    }
  }

  let isError = false

  if (isRequired) {
    isError = typeof value !== 'number'
  }

  if (!isError) {
    if (value) {
      isError = checkNumericRangeError(value, minValue, maxValue)
    }
  }

  if (isError) {
    return {
      isError: isError,
      message: item.errorHelperText ?? `Введите значение от ${MIN_NUMERIC_VALUE} до ${MAX_NUMERIC_VALUE}`,
    }
  }

  return {
    isError: false,
  }
}

function validateSingleSelectScreenItem(item: ISingleSelectScreenItem, context: IScriptTaskContext): IValidation {
  const required = item.required
  const visible = item.visible ?? {
    $type: 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate',
    value: true,
  }
  // required не Predicate, поэтому смотрим только в случае если контрол видимый
  const isVisible = checkPredicate(visible, undefined, context)
  if (required && isVisible) {
    let value = getContextProperty(context, item.propertyName)
    if (item.isArrayValue) {
      value = (value as unknown[] | undefined)?.[0]
    }
    if (!value) {
      return {
        isError: true,
        message: item.errorHelperText ?? 'Не заполнено обязательное поле',
      }
    }
  }

  return {
    isError: false,
  }
}

function validateDateInputScreenItem(item: IDateInputScreenItem, context: IScriptTaskContext): IValidation {
  const value = getContextProperty(context, item.propertyName, undefined)
  const required = item.required ?? { $type: 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate', value: false }
  if (!checkPredicate(required, undefined, context)) {
    return {
      isError: false,
    }
  }

  return {
    isError: !value,
    message: 'Не заполнено обязательное поле',
  }
}

function validateRangeDateInputScreenItem(item: IRangeDateInputScreenItem, context: IScriptTaskContext): IValidation {
  const startDate = getContextProperty(context, item.startDatePropertyName, undefined)
  const endDate = getContextProperty(context, item.endDatePropertyName, undefined)
  const required = item.required ?? { $type: 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate', value: false }
  if (!checkPredicate(required, undefined, context)) {
    return {
      isError: false,
    }
  }

  return {
    isError: !startDate || !endDate,
    message: 'Не заполнено обязательное поле',
  }
}

function validatePredicateEditScreenItem(item: IPredicateEditScreenItem, context: IScriptTaskContext): IValidation {
  const value = getContextProperty(context, item.propertyName, undefined)
  const required = item.required ?? { $type: 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate', value: false }
  if (!checkPredicate(required, undefined, context)) {
    return {
      isError: false,
    }
  }

  return {
    isError: !checkPredicateItemValue(value),
    message: 'Необходимо выбрать и корректно заполнить предикат',
  }
}

function validateContentDocumentSelectorScreenItem(
  item: IContentDocumentSelectorScreenItem,
  context: IScriptTaskContext,
): IValidation {
  const value = getContextProperty(context, item.propertyName, undefined)

  const required = item.required ?? { $type: 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate', value: false }
  if (!checkPredicate(required, undefined, context)) {
    return {
      isError: false,
    }
  }

  return {
    isError: !value,
    message: item.errorHelperText ?? 'Не заполнено обязательное поле',
  }
}

function validateSelectPOSScreenItem(item: ISelectPOSScreenItem, context: IScriptTaskContext): IValidation {
  const value = getContextProperty(context, item.propertyName)
  const isRequired = item.required ? checkPredicate(item.required, {} as Record<string, unknown>, context) : false
  if (!isRequired) {
    return {
      isError: false,
    }
  }
  return {
    isError: !value,
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    message: item.errorHelperText || 'Не заполнено обязательное поле',
  }
}

function selectTaskTemplateScreenItem(item: ISelectTaskTemplateScreenItem, context: IScriptTaskContext): IValidation {
  const value = getContextProperty(context, item.propertyName, undefined)

  const required = item.required ?? { $type: 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate', value: false }
  if (!checkPredicate(required, undefined, context)) {
    return {
      isError: false,
    }
  }

  return {
    isError: !value,
    message: item.errorHelperText ?? 'Необходимо выбрать шаблон',
  }
}

function validateTabSelectScreenItem(item: ITabSelectScreenItem, context: IScriptTaskContext): IValidation {
  const isRequired = item.required ? checkPredicate(item.required, {} as Record<string, unknown>, context) : false

  if (!isRequired) {
    return {
      isError: false,
    }
  }
  const value = getContextProperty(context, item.propertyName, undefined)
  return {
    isError: !value,
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    message: 'Не выбрано значение Tab select',
  }
}
