import React, { useContext } from 'react'

import { Box, createStyles, Dialog, DialogTitle, IconButton, SvgIcon, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { getAppFrameStyle } from '../../../assets/pm-shared-styles'
import { BackButton } from '../../../layout/back-button'
import { PageContent } from '../../../layout/page-content'
import { TitleBar } from '../../../layout/title-bar'
import { ModalContext } from '../../../providers/modal'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appFrame: getAppFrameStyle(theme),
    content: {
      marginTop: 88,
      paddingBottom: theme.spacing(12),
      paddingTop: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        marginTop: 64,
        paddingTop: theme.spacing(2),
      },
    },
    nameWrap: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: 32,
      [theme.breakpoints.down('xs')]: {
        paddingRight: 16,
      },
    },
    name: {
      fontSize: 28,
      lineHeight: '36px',
      fontWeight: 500,
      letterSpacing: '0.1px',
      [theme.breakpoints.down('xs')]: {
        fontSize: 22,
        fontWeight: 400,
      },
    },
    backButton: {
      marginLeft: -8,
      marginRight: 8,
      [theme.breakpoints.down('xs')]: {
        marginLeft: -4, // looks worse but easier to click
        width: 32,
        padding: 2,
      },
    },
    root: {
      paddingRight: 24,
      paddingLeft: 24,
      paddingBottom: theme.spacing(12),
      [theme.breakpoints.down('xs')]: {
        paddingTop: 8,
      },
    },
  }),
)

interface IInfoPageWrapper {
  title: string
  onBack: () => void
}

export const InfoPageWrapper: React.FC<IInfoPageWrapper> = ({ children, title, onBack }) => {
  const classes = useStyles()
  const modalContext = useContext(ModalContext)

  return (
    <div className={classes.appFrame}>
      <TitleBar fixed>
        <Box className={classes.nameWrap}>
          <BackButton className={classes.backButton} onBack={onBack} />
          <Typography
            className={classes.name}
            component='h6'
            noWrap={true}
            onClick={() =>
              modalContext.open(
                <Dialog open onClose={modalContext.close}>
                  <DialogTitle disableTypography>
                    {title}
                    <IconButton onClick={modalContext.close} edge='end' size='small'>
                      <SvgIcon viewBox='0 0 18 18' component={CloseIcon} />
                    </IconButton>
                  </DialogTitle>
                </Dialog>,
              )
            }
          >
            {title}
          </Typography>
        </Box>
      </TitleBar>
      <PageContent className={classes.content}>
        <div className={classes.root}>{children}</div>
      </PageContent>
    </div>
  )
}
