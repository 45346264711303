import React, { forwardRef, useImperativeHandle, useState } from 'react'

import { Button, createStyles, IconButton, Menu, MenuItem, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import classnames from 'classnames'
import { useTranslation } from "react-i18next";

import { IQuestionableListScreenItem } from '../../../../../model/questionable-list-screen-item'
import { useBatchUpdate } from '../../../nested/useBatchUpdate'
import { useUpdateProperty } from '../../../nested/useUpdateProperty'
import { getContextProperty, getPropertyAny } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { ScreenRef } from '../../types'
import AnswerResolution from '../answer-resolution'
import { ItemCard } from '../item-card'
import { Collapsible } from './collapsible'
import { QuestionablePhotosDialog } from './questionable-photos-dialog'
import { QuestionableTextDialog } from './questionable-text-dialog'
import { IQuestionableRecord } from './utils'
import { validateQuestionableRecords } from './validate-questionable'

const SUCCESS_COLOR = '#388E3C'
const DECLINE_COLOR = '#D32F2F'
const BUTTON_BORDER_RADIUS = 28

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: {
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '0.15px',
      marginTop: 8,
      marginBottom: 8,
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: '0.25px',
        marginBottom: 0,
      },
    },
    labelWrap: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    label: {
      fontSize: 18,
      fontWeight: 600,
      letterSpacing: '0.15px',
      flexGrow: 1,
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
      },
    },
    buttonsWrap: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        paddingTop: 8,
        paddingBottom: 4,
      },
    },
    successButton: {
      marginRight: theme.spacing(1),
      borderColor: SUCCESS_COLOR,
      color: SUCCESS_COLOR,
      borderRadius: BUTTON_BORDER_RADIUS,
      whiteSpace: 'nowrap',
    },
    declineButton: {
      marginRight: theme.spacing(1),
      borderColor: DECLINE_COLOR,
      color: DECLINE_COLOR,
      borderRadius: BUTTON_BORDER_RADIUS,
      whiteSpace: 'nowrap',
    },
    answerWrap: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
      },
    },
    answerBooleanWrap: {
      paddingRight: 20,
      [theme.breakpoints.down('xs')]: {
        marginTop: 8,
        marginBottom: 8,
      },
    },
    answerCommentWrap: {
      overflow: 'hidden',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  }),
)

interface Props {
  item: IQuestionableListScreenItem
}

// eslint-disable-next-line react/display-name
export const QuestionableListItem = forwardRef<ScreenRef, Props>(({ item }, ref) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const propContext = useScriptTaskContext()
  const updateProperty = useUpdateProperty()
  const batchUpdate = useBatchUpdate()
  const [menuAnchor, setMenuAnchor] = useState<{ anchor: HTMLButtonElement; index: number }>()

  const [isCommentOpen, setCommentOpen] = useState<number>()

  const handleCloseMenu = (): void => setMenuAnchor(undefined)

  const records: IQuestionableRecord[] | null = getContextProperty(propContext, item.propertyName)

  const scrollToInvalid = (): void => {
    if (records?.length) {
      const { isValid, record } = validateQuestionableRecords(records, item, propContext)
      console.log({ isValid, record }, 'recordssss')

      if (record) {
        const selector = `.RECORD_CODE-${CSS.escape(record.contractTerm.code)}`
        const hiddenElement = document.querySelector(selector)
        hiddenElement?.scrollIntoView({ block: 'center', behavior: 'smooth' })
      }
    }
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        validate: () => {
          scrollToInvalid()
        },
      }
    },
    [item, propContext, records],
  )

  if (!records?.length) {
    return (
      <ItemCard>
        <Typography>{item.nullValueCaption}</Typography>
      </ItemCard>
    )
  }

  return (
    <>
      <Menu anchorEl={menuAnchor?.anchor} open={!!menuAnchor} onClose={handleCloseMenu}>
        <MenuItem
          onClick={() => {
            handleCloseMenu()
            void batchUpdate({
              // - обнуление поля, отвечающего за ответ (set null);
              [`${item.propertyName}.${menuAnchor?.index}.${item.itemSettings.answerPropertyName}`]: null,
              // - обнуление поля, отвечающего за комментарий (set null);
              [`${item.propertyName}.${menuAnchor?.index}.${item.itemSettings.commentPropertyName}`]: null,
              // - обнуление поля, отвечающего за фото.
              [`${item.propertyName}.${menuAnchor?.index}.${item.itemSettings.photosPropertyName}`]: null,
            })
          }}
        >
          {t('reset')}
        </MenuItem>
      </Menu>

      {records.map((record, recordI) => {
        const name = getPropertyAny<string>(record, item.itemSettings.textPropertyName ?? '')
        const isComplied = getPropertyAny<boolean | null>(record, item.itemSettings.answerPropertyName ?? '')
        const description = getPropertyAny<string>(record, item.itemSettings.descriptionPropertyName ?? '')

        const isAnswer = isComplied === false || isComplied === true

        return (
          <ItemCard
            key={`record.contractTerm.code${recordI}`}
            isError={!isAnswer}
            label={
              <div className={classnames(classes.labelWrap, `RECORD_CODE-${record.contractTerm.code}`)}>
                <Typography className={classes.label}>{name}</Typography>

                {isAnswer && (
                  <>
                    <QuestionablePhotosDialog item={item} record={record} index={recordI} />
                    <IconButton
                      edge='end'
                      size='small'
                      onClick={(ev) =>
                        setMenuAnchor({
                          anchor: ev.currentTarget,
                          index: recordI,
                        })
                      }
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </>
                )}
              </div>
            }
          >
            {description && (
              <Collapsible>
                <Typography className={classes.description}>{description}</Typography>
              </Collapsible>
            )}
            {!isAnswer ? (
              <div className={classes.buttonsWrap}>
                <Button
                  className={classes.successButton}
                  size='large'
                  variant='outlined'
                  onClick={() => {
                    void updateProperty(`${item.propertyName}.${recordI}.${item.itemSettings.answerPropertyName}`, true)
                  }}
                >
                  {item.itemSettings.answerSettings.trueValueCaption ?? 'Выполнено'}
                </Button>
                <Button
                  className={classes.declineButton}
                  size='large'
                  variant='outlined'
                  onClick={() => {
                    void updateProperty(
                      `${item.propertyName}.${recordI}.${item.itemSettings.answerPropertyName}`,
                      false,
                    )
                    setCommentOpen(recordI)
                  }}
                >
                  {item.itemSettings.answerSettings.falseValueCaption ?? 'Не выполнено'}
                </Button>
              </div>
            ) : (
              <div className={classes.answerWrap}>
                <div className={classes.answerBooleanWrap}>
                  <AnswerResolution
                    resolution={isComplied}
                    text={
                      isComplied
                        ? item.itemSettings.answerSettings.trueValueCaption ?? 'Выполнено'
                        : item.itemSettings.answerSettings.falseValueCaption ?? 'Не выполнено'
                    }
                  />
                </div>
                <div className={classes.answerCommentWrap}>
                  <QuestionableTextDialog
                    item={item}
                    record={record}
                    index={recordI}
                    isModalOpen={recordI === isCommentOpen}
                    setModalOpen={setCommentOpen}
                  />
                </div>
              </div>
            )}
          </ItemCard>
        )
      })}
    </>
  )
})
