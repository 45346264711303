import {
  ProblemStore,
  ProblemStore_location,
  ProblemStore_status,
  ProblemTemplateStore,
  ProblemTemplateStore_escalationReasonCode,
} from '../../data/schema'
import { Code, generateEntityCode, isVersionActive } from '../../model/base'
import { BusinessError } from '../../model/errors'
import { IProblem } from '../../model/problem'
import { IProblemTemplate } from '../../model/problem-template'
import { trace } from '../../utils/trace'
import { CreateProblemRequest, IProblemService, ProblemErrorCode, ProblemSearchRequest } from '../problem-service-api'
import { LocalStorageBaseService } from './local-storage-base-service'

export default class LocalStorageProblemService extends LocalStorageBaseService implements IProblemService {
  private static readonly __className = 'LocalStorageProblemService'

  async getProblemTemplates(): Promise<IProblemTemplate[]> {
    const templates = await this._storage.getAll<IProblemTemplate>(ProblemTemplateStore)
    const now = new Date()
    return templates.filter((t) => isVersionActive(t.version, now))
  }

  async searchProblems({ posCode, status }: ProblemSearchRequest): Promise<IProblem[]> {
    if (posCode) {
      let problems = await this._storage.getByIndexRange<IProblem>(
        ProblemStore, ProblemStore_location, ['=', posCode]
      )
      if (status) {
        problems = problems.filter(p => status.includes(p.status))
      }
      return problems
    }
    else {
      if (status?.length) {
        return (await Promise.all(
          status.map(async s => this._storage.getByIndexRange<IProblem>(ProblemStore, ProblemStore_status, ['=', s]))
        )).flat()
      }
      else {
        return await this._storage.getAll<IProblem>(ProblemStore);
      }
    }
  }

  async getProblem(problemCode: Code): Promise<IProblem | null> {
    return await this._storage.getByKey<IProblem>(ProblemStore, problemCode) ?? null
  }

  @trace()
  async createNewProblems(request: CreateProblemRequest): Promise<IProblem[]> {
    let detailsToCreate = request.problemDetails
    const currentRoleReference = await this._getCurrentRoleReference()
    if (currentRoleReference == null) {
      throw new BusinessError(ProblemErrorCode.UnknownRole, 'Unable to determine current role')
    }

    const templates = await this._storage.getByIndexRange<IProblemTemplate>(
      ProblemTemplateStore,
      ProblemTemplateStore_escalationReasonCode,
      ['=', request.escalationReason.code]
    )
    const date = new Date()
    const template = templates.find(t => isVersionActive(t.version, date))
    if (!template) {
      throw new BusinessError(
        ProblemErrorCode.NoProblemTemplateFound,
        `Не найден активный шаблон проблемы для типа проблемы ${request.escalationReason.code}`,
      )
    }

    if (!template.allowDublicates) {
      const existingDetails = [] as string[]
      const isDuplicate = async (): Promise<boolean> => {
        const selector = request.location?.code != null
          ? this._storage.selectByIndexRange<IProblem>(ProblemStore, ProblemStore_location, ['=', request.location.code])
          : this._storage.selectByIndexRange<IProblem>(ProblemStore, ProblemStore_status, ['=', 'New'])
        for await (const p of selector) {
          if (p.status !== 'New') continue
          if (p.location?.code !== request.location?.code) continue
          if (p.escalationReason.code !== request.escalationReason.code) continue
          if (p.problemDetails) {
            existingDetails.push(p.problemDetails)
          }
          if (!p.problemDetails && !request.problemDetails?.length) {
            return true
          }
        }
        detailsToCreate = request.problemDetails?.filter((detail) => {
          return !existingDetails.join().includes(detail)
        })
        return detailsToCreate?.length === 0
      }
      if (await isDuplicate()) {
        throw new BusinessError(ProblemErrorCode.DuplicateProblem, 'Duplicates not allowed')
      }
    }

    const currentUserReference = await this._getCurrentUserReference()
    const now = Date.now()

    const makeProblem = (problemDetails?: string): IProblem => {
      return {
        code: generateEntityCode('PBLM'),
        codeSpace: this.defaultCodeSpace,
        status: 'New',
        _changeTime: now,
        creationTime: now,
        updateTime: now,
        raisedBy: currentUserReference,
        raisedByPositionRole: currentRoleReference,
        location: request.location,
        problemDetails: problemDetails,
        visit: request.visit,
        attachment: request.attachment,
        escalationReason: request.escalationReason,
        executiveComment: request.executiveComment,
      }
    }

    if (detailsToCreate?.length) {
      const problems = detailsToCreate.map(makeProblem)
      await this._storage.put<IProblem>(ProblemStore, problems)
      return problems
    } else {
      const problem = makeProblem()
      await this._storage.put<IProblem>(ProblemStore, problem)
      return [problem]
    }
  }

  async saveProblem(problem: IProblem): Promise<void> {
    const now = Date.now()
    problem.updateTime = problem._changeTime = now

    // if (
    //   (problem.status === 'Resolved' || problem.status === 'Canceled')
    //   && this._auth.currentUser
    // ) {
    //   problem.assignedTo = {
    //     code: this._auth.currentUser.employee.account.code!,
    //     codeSpace: this._auth.currentUser.employee.account.codeSpace,
    //     $type: this._auth.currentUser.employee.account.$type,
    //   }
    // }

    if (problem.status === 'Resolved') {
      problem.resolutionDate = now
    }
    
    await this._storage.put(ProblemStore, problem)
  }

  @trace()
  async deleteProblem(problemCode: Code): Promise<void> {
    return this._storage.deleteByKey(ProblemStore, problemCode)
  }
}
