import React, { forwardRef, useContext } from 'react'

import { TextField, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Autocomplete } from '@material-ui/lab'
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason
} from '@material-ui/lab/useAutocomplete/useAutocomplete'
import { useTranslation } from 'react-i18next'
import { useAsync } from 'react-use'

import { IThirdParty } from '../../../../../model/visit-task'
import { ApiContext } from '../../../../../providers'

type InputEvent = React.ChangeEvent<Record<string, unknown>>

interface IRepresentativeAutocompleteControlProps {
  inputValue: string
  onInputChange: (value: string) => void
  onSelect: (value: IThirdParty | null) => void
  disabled: boolean
  focus?: boolean
  error?: boolean
  usePlaceholder?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    option: {
      display: 'flex',
      flexDirection: 'column'
    },
    title: {
      fontSize: 16,
      letterSpacing: '0.15px'
    },
    subTitle: {
      fontSize: 14,
      letterSpacing: '0.25px',
      color: theme.palette.text.secondary
    }
  })
)

type Component = React.ForwardRefRenderFunction<HTMLInputElement, IRepresentativeAutocompleteControlProps>
const ThirdPartyAutocompleteControl: Component = (props, ref) => {
  const classes = useStyles()
  const api = useContext(ApiContext)
  const agencyOps = useAsync(async () => await api.employee.getThirdParties(), [])

  const { t } = useTranslation('sales-expert-tasks')

  const handleSelect = (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: IThirdParty | string | null,
    reason: AutocompleteChangeReason
  ): void => {
    if (!value || typeof value === 'string') {
      return
    }

    if (reason === 'clear') {
      // console.log('clear select')
      // props.onSelect(null)
    } else if (reason === 'select-option') {
      props.onSelect(value)
    }
  }

  const handleInputChange = (event: InputEvent, value: string, reason: AutocompleteInputChangeReason): void => {
    if (reason === 'clear') {
      props.onInputChange('')
    } else if (reason === 'reset') {
      // props.onInputChange('')
    } else if (reason === 'input') {
      props.onInputChange(value)
    }
  }

  return (
    <Autocomplete
      loading={agencyOps.loading}
      freeSolo
      disableClearable
      id='agency'
      options={agencyOps.value ?? []}
      size='small'
      onChange={handleSelect}
      inputValue={props.inputValue}
      onInputChange={handleInputChange}
      disabled={props.disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          id='agency'
          label={props.disabled || props.usePlaceholder ? undefined : t('thirdPartyInputPlaceholder')}
          placeholder={props.usePlaceholder ? t('thirdPartyInputPlaceholder') : undefined}
          margin='none'
          variant='outlined'
          error={props.error}
          InputProps={{ ...params.InputProps, type: 'search' }}
          inputRef={(input) => {
            if (props.focus) {
              input?.focus()
            }
            if (ref) {
              if ('current' in ref) {
                ref.current = input
              } else {
                ref(input)
              }
            }
          }}
        />
      )}
      getOptionLabel={(option) => option.name ?? '-'}
      renderOption={(option) => (
        <div key={option.name} className={classes.option}>
          <Typography className={classes.title}>{option?.name ?? '-'}</Typography>
        </div>
      )}
    />
  )
}

export default forwardRef(ThirdPartyAutocompleteControl)
