import React, { forwardRef, useContext } from 'react'

import { intersectionBy } from 'lodash'

import { IDictionaryItem } from '../../../../model/dictionary-item'
import { IScreenItem } from '../../../../model/screen-item'
import { ProfileContext } from '../../../../providers'
import { DocumentsScreenItemContainer } from '../../../documents/components/documents-screen-items/documents-screen-item-container'
import { RefreshAuditedVisitsButton } from '../../audit/refresh-audited-visits-button'
import { ServiceAuditResultsScreenItemControl } from '../../audit/service-audit-results-screen-item'
import { ServiceAuditStatisticsScreenItem } from '../../audit/service-audit-statistics-screen-item'
import { useUpdateProperty } from '../../nested/useUpdateProperty'
import { ScreenRef } from '../types'
import { BooleanInputScreenItemControl } from './boolean/boolean-input-screen-item'
import { BooleanScreenItemControl } from './boolean/boolean-item'
import { BooleanViewScreenItemControl } from './boolean/boolean-view-screen-item'
import ButtonScreenItemControl from './button-screen-item'
import ContentDocumentSelectorScreenItemControl from './content-document-selector-screen-item'
import ContentDocumentPopupViewScreenItemControl from './content-document-view-screen-item/content-document-popup-view-screen-item'
import ContentDocumentViewScreenItemControl from './content-document-view-screen-item/content-document-view-screen-item'
import { CustomScreenItemControl } from './custom-screen-item'
import DateInputScreenItemControl from './date-input-screen-item'
import FlexContainerScreenItemControl from './flex-container-screen-item'
import { HyperlinkItem } from './hyperlink-item/hyperlink-item'
import { IFrameScreenItem } from './iframe-item/iframe-screen-item'
import { IntegerInputItem } from './integer-input-item/integer-input-item'
import JsonEditorScreenItem from './json-items/json-editor-screen-item'
import JsonViewerScreenItem from './json-items/json-viewer-screen-item'
import { LabelItemControl } from './label-item/label-item'
import { PopupRichtextScreenItemControl } from './popup-rich-text-screen-item'
import PosSelectorScreenItem from './pos-selector/pos-selector-screen-item'
import { PredicateEditScreenItem, PredicateViewScreenItem } from './predicate-items'
import { ProblemsListScreenItem } from './problem-list/problems-list-screen-item'
import { PropertyListScreenItemControl } from './property-list-item/property-list-screen-item'
import { QuantityInputItem } from './quantity-input-item/quantity-input-item'
import { QuestionableListItem } from './questionable-list-item/questionable-list-item'
import { RadioGroupItem } from './radio-group-item/radio-group-item'
import RangeDateInputScreenItemControl from './range-date-item/range-date-input-screen-item'
import { RepeaterScreenItem } from './repeater-screen-item'
import { SelectEmployeeForAudit } from './select-employee-for-audit/select-employee-for-audit'
import SelectTaskTemplateScreenItemControl from './select-task-template-screen-item'
import { DictionaryItem } from './sev3-items/dictionary'
import { LastFurtherFocus } from './sev3-items/last-further-focus'
import { TaskDirections } from './sev3-items/task-directions'
import { TaskRepresentative } from './sev3-items/task-representative'
import { SignatureImageStageItem } from './signature-image-item/signature-image-stage-item'
import { SingleSelectScreenItem } from './single-select-screen-item/single-select-screen-item'
import { StringInputScreenItem } from './string-input-screen-item'
import { StringItemContainer } from './string-item'
import { FillSurveyScreenItem } from './surveys/fill-survey-screen-item'
import { SurveyItemEdit } from './surveys/survey-item-edit'
import { SurveyItemView } from './surveys/survey-item-view'
import { ViewSurveyScreenItem } from './surveys/view-survey-screen-item'
import TabSelectScreenItem from './tab-select-item/tab-select-screen-item'
import { TableItem, TableItemContext } from './table-item/table-item'
import TemplateAttachmentsScreenItemContainer from './template-attachments-Item/template-attachments-Item'
import { TextScreenItemControl } from './text-screen-item'
import { IValidation } from './validate'
import { ViewRichTextListScreenItemControl } from './view-rich-text-list-screen-item'
import { ViewRichTextScreenItemControl } from './view-rich-text-screen-item'

interface ScreenItemProps {
  item: IScreenItem
  onChange: (item: IScreenItem, value: string, oldValue: string) => Promise<void>
  onBlur: () => void
  state: { value: string; touched: boolean; validate: IValidation }
}

export const ScreenItem = forwardRef<ScreenRef, ScreenItemProps>(function ScreenItem(
  { item, onChange, onBlur, state },
  ref,
) {
  const updateProperty = useUpdateProperty()
  const { userGroups } = useContext(ProfileContext)

  const setTaskPropertyPath = async (value: unknown): Promise<void> => {
    await updateProperty(item.propertyName, value)
  }

  if (item.visibilityCondition) {
    const { blackList = [], whiteList = [] } = item.visibilityCondition
    if (intersectionBy(blackList, userGroups, 'code').length) {
      console.log('hitting blacklist', item, blackList, userGroups)
      return <></>
    } else if (whiteList.length && !intersectionBy(whiteList, userGroups, 'code').length) {
      console.log('missing whiteList', item, whiteList, userGroups)
      return <></>
    }
  }

  switch (item.$type) {
    case 'PMI.FACE.BDDM.Extensions.Classes.StringPropertyScreenItem': {
      return <StringItemContainer {...{ item, onChange, onBlur, state }} />
    }
    case 'PMI.FACE.BDDM.Extensions.Classes.StringInputScreenItem': {
      return <StringInputScreenItem key={item.propertyName} item={item} />
    }
    case 'PMI.FACE.BDDM.Extensions.Classes.DictionaryItemPropertyScreenItem': {
      return (
        <DictionaryItem
          item={item}
          onChange={setTaskPropertyPath}
          onBlur={onBlur}
          entry={state.value as unknown as IDictionaryItem}
          touched={state.touched}
        />
      )
    }

    case 'PMI.FACE.BDDM.Extensions.Classes.SignatureImageScreenItem': {
      return <SignatureImageStageItem item={item} isReadOnly={item.actionKind !== 'Edit'} />
    }

    case 'PMI.FACE.BDDM.Extensions.Classes.LabelScreenItem': {
      return <LabelItemControl item={item} />
    }

    case 'PMI.FACE.BDDM.Extensions.Classes.SingleSelectScreenItem': {
      return <SingleSelectScreenItem item={item} onChange={setTaskPropertyPath} />
    }

    case 'PMI.FACE.BDDM.Extensions.Classes.PhotosPropertyScreenItem': {
      return <DocumentsScreenItemContainer entity={item} isReadOnly={item.actionKind !== 'Edit'} />
    }

    case 'PMI.FACE.BDDM.Extensions.Classes.MediaContentListEditorScreenItem': {
      return <DocumentsScreenItemContainer entity={item} isReadOnly={false} />
    }

    case 'PMI.FACE.BDDM.Extensions.Classes.MediaContentListViewerScreenItem': {
      return <DocumentsScreenItemContainer entity={item} isReadOnly={true} />
    }

    case 'PMI.FACE.BDDM.Extensions.Classes.MimeContentViewerScreenItem': {
      return <DocumentsScreenItemContainer entity={item} isReadOnly={true} />
    }

    case 'PMI.FACE.BDDM.Extensions.Classes.MimeContentEditorScreenItem': {
      return <DocumentsScreenItemContainer entity={item} isReadOnly={false} />
    }

    case 'PMI.FACE.BDDM.Extensions.Classes.SurveysPropertyScreenItem':
      if (item.actionKind === 'Edit') {
        return <SurveyItemEdit item={item} />
      } else {
        return <SurveyItemView item={item} />
      }
    case 'PMI.FACE.BDDM.Extensions.Classes.SalesExpertEducationTaskRepresentativeScreenItem':
      return <TaskRepresentative ref={ref} item={item} />
    case 'PMI.FACE.BDDM.Extensions.Classes.SalesExpertEducationTaskPrevFutherFocusScreenItem':
      return <LastFurtherFocus item={item} />
    case 'PMI.FACE.BDDM.Extensions.Classes.SalesExpertEducationTaskDirectionsScreenItem':
      return <TaskDirections item={item} />
    case 'PMI.FACE.BDDM.Extensions.Classes.TableScreenItem':
      return (
        <TableItemContext.Provider value={item}>
          <TableItem item={item} isReadOnly={false} />
        </TableItemContext.Provider>
      )
    case 'PMI.FACE.BDDM.Extensions.Classes.BooleanPropertyScreenItem':
      return <BooleanScreenItemControl item={item} />

    case 'PMI.FACE.BDDM.Extensions.Classes.QuestionableListScreenItem':
      return <QuestionableListItem item={item} ref={ref} />

    case 'PMI.FACE.BDDM.Extensions.Classes.RadioGroupScreenItem':
      return <RadioGroupItem item={item} onChange={setTaskPropertyPath} />

    case 'PMI.FACE.BDDM.Extensions.Classes.TemplateAttachmentsScreenItem':
      return <TemplateAttachmentsScreenItemContainer item={item} />

    case 'PMI.FACE.BDDM.Extensions.Classes.IntegerInputScreenItem':
      return <IntegerInputItem item={item} onChange={setTaskPropertyPath} />

    case 'PMI.FACE.BDDM.Extensions.Classes.QuantityInputScreenItem':
      return <QuantityInputItem item={item} onChange={setTaskPropertyPath} />

    case 'PMI.FACE.BDDM.Extensions.Classes.FillSurveyScreenItem':
      return <FillSurveyScreenItem ref={ref} item={item} />
    case 'PMI.FACE.BDDM.Extensions.Classes.ViewSurveyScreenItem':
      return <ViewSurveyScreenItem item={item} />

    case 'PMI.FACE.BDDM.Extensions.Classes.HyperlinkScreenItem':
      return <HyperlinkItem item={item} onChange={item.propertyName ? setTaskPropertyPath : undefined} />

    case 'PMI.FACE.BDDM.Extensions.Classes.CustomScreenItem':
      return <CustomScreenItemControl item={item} />

    case 'PMI.FACE.BDDM.Extensions.Classes.ServiceAuditResultsScreenItem':
      return <ServiceAuditResultsScreenItemControl item={item} />

    case 'PMI.FACE.BDDM.Extensions.Classes.ServiceAuditStatisticsScreenItem':
      return <ServiceAuditStatisticsScreenItem item={item} />

    case 'PMI.FACE.BDDM.Extensions.Classes.TextScreenItem':
      return <TextScreenItemControl item={item} />
    case 'PMI.FACE.BDDM.Extensions.Classes.BooleanInputScreenItem':
      return <BooleanInputScreenItemControl item={item} />
    case 'PMI.FACE.BDDM.Extensions.Classes.BooleanViewScreenItem':
      return <BooleanViewScreenItemControl item={item} />

    case 'PMI.FACE.BDDM.Extensions.Classes.ProblemsListScreenItem':
      return <ProblemsListScreenItem item={item} />
    case 'PMI.FACE.BDDM.Extensions.Classes.IframeScreenItem':
      return <IFrameScreenItem item={item} />

    case 'PMI.FACE.BDDM.Extensions.Classes.RepeaterScreenItem':
      return <RepeaterScreenItem item={item} />

    case 'PMI.FACE.BDDM.Extensions.Classes.ViewRichTextScreenItem':
      return <ViewRichTextScreenItemControl item={item} />
    case 'PMI.FACE.BDDM.Extensions.Classes.ViewRichTextListScreenItem':
      return <ViewRichTextListScreenItemControl item={item} />

    case 'PMI.FACE.BDDM.Extensions.Classes.RefreshAuditedVisitsButtonScreenItem':
      return <RefreshAuditedVisitsButton item={item} />

    case 'PMI.FACE.BDDM.Extensions.Classes.PropertyListScreenItem':
      return <PropertyListScreenItemControl item={item} />

    case 'PMI.FACE.BDDM.Extensions.Classes.PopUpRichTextScreenItem':
      return <PopupRichtextScreenItemControl item={item} />
    case 'PMI.FACE.BDDM.Extensions.Classes.DateInputScreenItem':
      return <DateInputScreenItemControl item={item} />
    case 'PMI.FACE.BDDM.Extensions.Classes.RangeDateInputScreenItem':
      return <RangeDateInputScreenItemControl item={item} />
    case 'PMI.FACE.BDDM.Extensions.Classes.PredicateViewScreenItem':
      return <PredicateViewScreenItem item={item} />
    case 'PMI.FACE.BDDM.Extensions.Classes.PredicateEditScreenItem':
      return <PredicateEditScreenItem item={item} onChange={setTaskPropertyPath} />
    case 'PMI.FACE.BDDM.Extensions.Classes.ButtonScreenItem':
      return <ButtonScreenItemControl item={item} />
    case 'PMI.FACE.BDDM.Extensions.Classes.FlexContainerScreenItem':
      return <FlexContainerScreenItemControl item={item} />
    case 'PMI.FACE.BDDM.Extensions.Classes.ContentDocumentViewScreenItem':
      return <ContentDocumentViewScreenItemControl item={item} />
    case 'PMI.FACE.BDDM.Extensions.Classes.ContentDocumentPopupViewScreenItem':
      return <ContentDocumentPopupViewScreenItemControl item={item} />
    case 'PMI.FACE.BDDM.Extensions.Classes.JsonEditorScreenItem':
      return <JsonEditorScreenItem item={item} />
    case 'PMI.FACE.BDDM.Extensions.Classes.JsonViewerScreenItem':
      return <JsonViewerScreenItem item={item} />
    case 'PMI.FACE.BDDM.Extensions.Classes.ContentDocumentSelectorScreenItem':
      return <ContentDocumentSelectorScreenItemControl item={item} />
    case 'PMI.FACE.BDDM.Extensions.Classes.SelectFieldForceTaskTemplateScreenItem':
      return <SelectTaskTemplateScreenItemControl item={item} />
    case 'PMI.FACE.BDDM.Extensions.Classes.SelectPOSScreenItem':
      return <PosSelectorScreenItem item={item} />
    case 'PMI.FACE.BDDM.Extensions.Classes.SelectEmployeeForAuditScreenItem':
      return <SelectEmployeeForAudit item={item} />
    case 'PMI.FACE.BDDM.Extensions.Classes.TabSelectScreenItem':
      return <TabSelectScreenItem item={item} onChange={setTaskPropertyPath} />

    default:
      const other = item as IScreenItem
      return (
        <div>
          unimplemented type {String(other.$type)} for {other.displayName || other.propertyName}
        </div>
      )
  }
})
