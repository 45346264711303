import React, { useEffect } from 'react'

import { ICustomUserStepScreen } from '../../../../model/user-step-screen'
import { MerchendizerScreenCustom } from './merchendizer-screen'

interface CustomUserStepScreenProps {
  children?: never
  screen: ICustomUserStepScreen
  onReadyChange: (isReady: boolean) => void
}

export const CustomUserStepScreen: React.FC<CustomUserStepScreenProps> = (props) => {
  const { screen, onReadyChange } = props
  // TODO: use screen.screenCode in the future
  if (screen.code === 'testAuditService_Process_MechendizerScreen') {
    return <MerchendizerScreenCustom onReadyChange={onReadyChange} />
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    console.log('CustomUserStepScreen mount', props)
    onReadyChange(true)
  }, [])

  return <>CustomUserStepScreen</>
}
