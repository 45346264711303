import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts'
import { Style, TDocumentDefinitions } from 'pdfmake/interfaces'

import { Code } from '../../../../model/base'
import { ITask, TaskWithSurveys } from '../../../../model/task'
import { IApiContext } from '../../../../providers/api/api-context'
import { dateFormat } from '../../../../utils'
import { getPosAddress } from '../../../_common/pos'
import { surveyPdf, surveyStyles } from '../survey-pdf'

interface IProps {
  task: ITask
  propertyName: string
  api: IApiContext
  posCode: Code
}
interface IStyle {
  name: string
  style: Style
}

function styleDef<T extends Record<string, IStyle>>(styleDict: T): T {
  return styleDict
}

const styles = styleDef({
  title: {
    name: 'title',
    style: {
      fontSize: 15,
      bold: true,
      margin: [0, 0, 0, 10],
      alignment: 'center'
    }
  },
  label: {
    name: 'label',
    style: {
      bold: true,
      fontSize: 15,
      margin: [0, 10, 0, 10]
    }
  }
})

export async function generatePdfSimple({ api, task, propertyName, posCode }: IProps): Promise<Blob> {
  console.log('generating pdf simple', task)
  const newTask = task as TaskWithSurveys
  const surveyCode = newTask[propertyName]?.[0]?.code
  const survey = await api.survey.getSurvey(surveyCode!)
  const questionnaire = await api.questionnaire.getQuestionnaire(survey!.questionnaire.code)
  const pos = await api.pos.getPos(posCode)

  const title = 'РЕЗУЛЬТАТЫ ПРОВЕРКИ ГОТОВНОСТИ ТОРГОВОЙ ТОЧКИ'
  const docDefinition: TDocumentDefinitions = {
    content: [
      {
        text: title,
        style: styles.title.name
      },
      {
        text: 'Основная информация',
        style: styles.label.name
      },
      {
        table: {
          widths: ['auto', '*'],
          body: [
            ['Дата', dateFormat(task.updateTime, 'dd.MM.yyyy')],
            ['Код торговой точки', posCode],
            ['Название торговой точки', pos?.name ?? ''],
            ['Адрес торговой точки', getPosAddress(pos)]
          ]
        }
      },
      {
        text: 'Вопросы',
        style: styles.label.name
      },
      surveyPdf({ survey: survey!, questionnaire: questionnaire! })
    ],

    styles: {
      [styles.title.name]: styles.title.style,
      [styles.label.name]: styles.label.style,
      ...surveyStyles
    },
    info: {
      title,
      creationDate: new Date(),
      keywords: task.template.code
    },
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [35, 55, 35, 55],
    compress: false
  }

  const document = pdfMake.createPdf(docDefinition, undefined, undefined, pdfFonts.pdfMake.vfs)
  // document.download(
  //   'report.pdf',
  //   () => {
  //     console.log('report generated')
  //   },
  //   { autoPrint: false }
  // )
  return new Promise<Blob>((resolve) => document.getBlob(resolve))
}
