import React, { useEffect } from 'react'
import 'leaflet/dist/leaflet.css'

import * as L from 'leaflet'
import { TileLayer, Marker, useMap } from 'react-leaflet'

import ResetButton from '../../../../components/yandex-map-custom/components/reset-button'
import { VisitWithPosIndexed } from '../visits-map-by-day'

interface IVisitsOsmMapProps {
  visits: VisitWithPosIndexed[]
  selectVisit: string | undefined,
  onSelect: (code: string | undefined) => void
}

const VisitsOsmMap: React.FC<IVisitsOsmMapProps> = ({visits, selectVisit, onSelect}) => {
  const map = useMap()
  const markers = visits
    .filter((visit) => visit.pointOfSale?.coordinates)
    .map((visit) => {
      const coordinates = new L.LatLng(visit.pointOfSale!.coordinates!.latitude, visit.pointOfSale!.coordinates!.longitude)
      return new L.Marker(coordinates)
    })

  const bounds = L.featureGroup(markers).getBounds()

  const setBoundsHandler = (): void => {
    if (markers.length > 0) {
      bounds && map.fitBounds(bounds)
    } else {
      // appToast.info('У визитов отсутствуют координаты')
    }

  }

  useEffect(() => {
    setBoundsHandler()
  }, [])

  return (
    <>
      <ResetButton onClick={setBoundsHandler} />
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {[...visits].reverse().map((visit, index) => {
        const isActive = visit.code === selectVisit

        return <VisitPointOsmMap
          key={visit.code}
          zIndex={index}
          visit={visit}
          onClick={() => { onSelect(visit.code) }}
          isActive={isActive}
        />
      })}
    </>
  )
}


interface IVisitPointOsmMapProps {
  zIndex: number,
  visit: VisitWithPosIndexed
  onClick: () => void
  isActive: boolean
}

const VisitPointOsmMap: React.FC<IVisitPointOsmMapProps> = ({
  zIndex,
  visit,
  onClick,
  isActive
}) => {
  if (!visit.pointOfSale?.coordinates) {
    return <></>
  }

  const coordinates = new L.LatLng(visit.pointOfSale?.coordinates.latitude, visit.pointOfSale?.coordinates.longitude)

  const iconLayout = L.divIcon({
    className: 'leaflet-icon',
    iconSize: [50, 50],
    html: `<div class='visit-placemark visit-placemark--${visit.status} ${isActive ? 'visit-placemark--active' : ''}'>${visit.index}</div>`
  })

  return (
    <Marker
      position={coordinates}
      icon={iconLayout}
      zIndexOffset={isActive ? 99 : zIndex}
      eventHandlers={{
        click: onClick,
      }}
    />)
}

export default VisitsOsmMap