import React, { useState } from 'react'

import { Box, createStyles, IconButton, makeStyles, Menu, MenuItem, Theme, Typography } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import { IQuestion } from '../../model/question'
import { formatTemplateString } from '../../utils/format-template-string'
import { getContextProperty } from '../tasks/script-tasks/propertyName'
import { useScriptTaskContext } from '../tasks/script-tasks/script-task-context'
import { ItemCard } from '../tasks/template-tasks/composite-screen/item-card'

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    questionCard: {
      // '&:first-child': {
      //   marginTop: theme.spacing(2.5)
      // }
    },
    label: {
      display: 'flex',
      alignItems: 'flex-start',
      marginRight: theme.spacing(-0.5),
      letterSpacing: '0.15px',
      minHeight: 30,
    },
    title: {
      alignSelf: 'center',
    },
  }),
)

interface Props {
  title: string | React.ReactNode
  hasAnswer: boolean
  isError: boolean
  readOnly?: boolean
  className: string
  titleButtons: string | React.ReactNode
  onRemoveAnswer: () => void
  question: IQuestion
}

export const QuestionCard: React.FC<Props> = ({
  className,
  title,
  hasAnswer,
  isError,
  readOnly = true,
  children,
  titleButtons,
  onRemoveAnswer,
  question,
}) => {
  const classes = useStyles()
  const { t } = useTranslation(['common', 'question'])
  const localContext = useScriptTaskContext()
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement>()

  const handleClose = (): void => setMenuAnchor(undefined)

  const renderLinkGoAgain = (): JSX.Element => {
    if (question.$type === 'PMI.FACE.BDDM.Extensions.Classes.FaceHyperlinkQuestion') {
      const resultLink = formatTemplateString(question.linkFormat, (propertyName) =>
        getContextProperty(localContext, propertyName, ''),
      )

      return (
        <MenuItem href={resultLink} target='_blank' component='a'>
          {t('goAgain', { ns: 'question' })}
        </MenuItem>
      )
    }

    return <></>
  }

  return (
    <Box className={classes.questionCard}>
      <ItemCard
        isError={isError}
        label={
          <div className={classnames([classes.label, className])}>
            <Typography variant='inherit' className={classes.title}>
              {title}
            </Typography>
            <Box width='fit-content' ml='auto' px={0.5}>
              {titleButtons}
            </Box>
            <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={handleClose}>
              {renderLinkGoAgain()}
              <MenuItem
                onClick={async () => {
                  handleClose()
                  await onRemoveAnswer()
                }}
              >
                {t('reset')}
              </MenuItem>
            </Menu>
            {!readOnly && hasAnswer && (
              <IconButton edge='end' size='small' onClick={(ev) => setMenuAnchor(ev.currentTarget)}>
                <MoreVertIcon />
              </IconButton>
            )}
          </div>
        }
      >
        {children}
      </ItemCard>
    </Box>
  )
}
