import React from 'react'

import { Box, Drawer, makeStyles } from '@material-ui/core'
import lodashGet from 'lodash/get'

import { AppVersion } from './app-version'
import { useSidebarOpen } from './layout'
import Menu from './menu/menu'
import UserInfo from './user-info'

const DRAWER_WIDTH = 230
const CLOSED_DRAWER_WIDTH = 80

// todo: https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/layout/Sidebar.tsx
const useStyles = makeStyles(
  (theme) => ({
    root: {},
    docked: {},
    paper: {},
    paperAnchorLeft: {},
    paperAnchorRight: {},
    paperAnchorTop: {},
    paperAnchorBottom: {},
    paperAnchorDockedLeft: {},
    paperAnchorDockedTop: {},
    paperAnchorDockedRight: {},
    paperAnchorDockedBottom: {},
    modal: {},
    drawerPaper: {
      // position: 'relative',
      height: '100%',
      // overflowX: 'hidden',
      width: (props: { open?: boolean }) =>
        props.open
          ? lodashGet(theme, 'sidebar.width', DRAWER_WIDTH)
          : lodashGet(theme, 'sidebar.closedWidth', CLOSED_DRAWER_WIDTH),
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      borderRight: 'none',
      borderRadius: '0px 16px 16px 0px',
      [theme.breakpoints.only('xs')]: {
        marginTop: 0,
        height: '100vh',
        position: 'inherit',
        backgroundColor: theme.palette.primary.main
      },
      [theme.breakpoints.up('md')]: {
        border: 'none'
      },
      zIndex: 'inherit'
    }
  }),
  { name: 'PmSidebar' }
)

const Sidebar: React.FC = () => {
  const [isOpen, setIsOpen] = useSidebarOpen()
  const { drawerPaper, ...classes } = useStyles({ open: isOpen })
  return (
    <Drawer
      anchor='left'
      open={isOpen}
      onClose={() => setIsOpen(false)}
      PaperProps={{
        className: drawerPaper
      }}
      classes={classes}
    >
      <Menu />
      <Box marginTop='auto' marginX={2}>
        <UserInfo />
        <AppVersion />
      </Box>
    </Drawer>
  )
}

export default React.memo(Sidebar)
