import React from 'react'

import { Box, createStyles, Paper, SvgIcon } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import classNames, { Argument as ClassName } from 'classnames'

import { ReactComponent as ChevronRight } from '../../../assets/icons/ChevronRight.svg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 8,
      padding: '12px 24px',
      boxShadow: '0px 3px 7px rgba(96, 97, 112, 0.13)',
      borderRadius: '12px',
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        padding: '12px 12px 12px 20px',
      },
    },
    container: {
      display: 'flex',
      minHeight: 48,
      width: 'calc(100% - 36px)',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        width: 'calc(100% - 30px)',
      },
    },
    selectIcon: {
      width: 36,
      height: 36,
      [theme.breakpoints.down('xs')]: {
        width: 30,
        height: 30,
      },
    },
  }),
)

interface ICardWrapper {
  onClick: () => void
  className?: ClassName
}

export const CardItemWrapper: React.FC<ICardWrapper> = ({ children, onClick, className, ...props }) => {
  const classes = useStyles()

  return (
    <Paper className={classNames(classes.card, className)} onClick={onClick} {...props}>
      <Box className={classes.container}>{children}</Box>
      <SvgIcon
        className={classes.selectIcon}
        component={ChevronRight}
        viewBox='0 0 36 36'
        color='primary'
        fontSize='large'
      />
    </Paper>
  )
}
