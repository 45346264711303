import React, { forwardRef, ForwardRefRenderFunction, useContext, useEffect, useImperativeHandle } from 'react'

import { Box, Button, createStyles, FormHelperText, IconButton, SvgIcon, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import { set as setFp } from 'lodash/fp'
import { useTranslation } from 'react-i18next'
import create from 'zustand'

import { ReactComponent as PencilIcon } from '../../../assets/icons/pencil.svg'
import { IAnswer } from '../../../model/answer'
import { AnswerReplyCommentRequirements } from '../../../model/answer-reply-requirements'
import { Code } from '../../../model/base'
import { IQuestion } from '../../../model/question'
import { ModalContext } from '../../../providers/modal'
import { OpenableTypography } from '../../tasks/components/openable-typography'
import {
  checkNumericError,
  checkNumericRangeError,
  MAX_NUMERIC_VALUE,
  MIN_NUMERIC_VALUE,
  NumberEditor,
} from '../../tasks/template-tasks/composite-screen/table-item/number-editor'
import { useThrottledFn } from '../../tasks/template-tasks/composite-screen/useThrottledFn'
import { isCommentDisabled } from '../answer-requirements-utils'
import CommentModal from '../comment-modal'
import ReadOnlyText from '../read-only-text'
import { isNullAnswer } from '../utils'

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    answerWrapper: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      minHeight: 42,
      minWidth: 0,
    },
    comment: {
      marginLeft: 'auto',
      padding: theme.spacing(0, 1),
    },
    innerWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
)

interface ISurveyQuestionProps {
  questionCode: Code
  disabled?: boolean
  commentReq?: AnswerReplyCommentRequirements
  title: string
  description?: React.ReactNode
  answerValue?: number
  comment: string | null
  onNumericAnswer: (answer: number) => void
  onSaveComment: (comment: string) => void
  minValue?: number
  maxValue?: number
  incrementValue?: number
  errorHelperText?: string
  onNullAnswer: () => void
  answer?: IAnswer
  question: IQuestion
  getReadyChange: () => void
}

export interface IQuestionRef {
  validate: () => boolean
}

export const validateNumeric = (
  value?: number | null,
  minValue = MIN_NUMERIC_VALUE,
  maxValue = MAX_NUMERIC_VALUE,
): boolean => {
  if (typeof value !== 'number') return true
  return checkNumericRangeError(value, minValue, maxValue)
}

interface INumericState {
  store: Record<Code, number | null | undefined>
  setValue: (code: Code, value: number | null | undefined) => void
}

export const useNumericState = create<INumericState>((setState) => ({
  store: {},
  setValue: (code, value) => setState(setFp(['store', code], value)),
}))

const NumericQuestion: ForwardRefRenderFunction<IQuestionRef, ISurveyQuestionProps> = (
  {
    questionCode,
    disabled = false,
    commentReq,
    title = '',
    description,
    answerValue,
    comment = null,
    onNumericAnswer,
    onSaveComment,
    minValue,
    maxValue,
    incrementValue,
    errorHelperText,
    onNullAnswer,
    answer,
    question,
    getReadyChange,
  },
  ref,
) => {
  useImperativeHandle(ref, () => ({
    validate: () => {
      return validateNumeric(value, minValue, maxValue)
    },
  }))

  const hasAnswer = answerValue !== undefined && !isNullAnswer(answer)
  const classes = useStyles()
  const modalContext = useContext(ModalContext)
  const throttleOnAnswer = useThrottledFn(onNumericAnswer, 500, { trailing: true })

  const { t } = useTranslation('sales-expert-tasks')

  // const [value, setValue] = useState(answer)
  const value = useNumericState((state) => state.store[questionCode])
  const setValue = useNumericState((state) => state.setValue)
  const isRequired = answer ? !isNullAnswer(answer) : question?.required !== false
  const isNumericError = checkNumericError(value, isRequired, minValue, maxValue)

  useEffect(() => {
    if (disabled) return
    if (answer) return
    if (question?.required === false) {
      setValue(questionCode, null)
      onNullAnswer()
    } else {
      setValue(questionCode, null)
    }
  }, [answerValue])

  useEffect(() => {
    setValue(questionCode, answerValue)

    return () => setValue(questionCode, undefined)
  }, [questionCode])

  const onChangeValue = (value: number | string): void => {
    if (typeof value === 'number') {
      if (!checkNumericRangeError(value, minValue, maxValue)) {
        throttleOnAnswer(value)
      }
      setValue(questionCode, value)
    } else {
      setValue(questionCode, null)
    }

    getReadyChange()
  }

  const renderNumberEditor = (): JSX.Element => {
    return (
      <NumberEditor
        value={value === null ? '' : value}
        onChange={onChangeValue}
        onBlur={() => {
          throttleOnAnswer.flush()
        }}
        showIncreaseButtons={true}
        minValue={minValue}
        maxValue={maxValue}
        // defaultValue={defaultValue}
        incrementValue={incrementValue}
        isRequired={isRequired}
        disabled={disabled}
      />
    )
  }

  const openHandler = (): void => {
    modalContext.open(
      <CommentModal //
        open
        isCommentRequired={commentReq === AnswerReplyCommentRequirements.Required}
        comment={comment}
        titleDescription={title}
        onSave={(comment) => {
          onSaveComment(comment)
          modalContext.close()
        }}
        onClose={modalContext.close}
      />,
    )
  }

  const renderCommentButton = (): JSX.Element => {
    if (disabled || isCommentDisabled(commentReq)) return <div></div>
    if (comment?.trim()) {
      return (
        <IconButton //
          edge='end'
          className={classes.button}
          color='primary'
          aria-label='change-comment'
          onClick={openHandler}
        >
          <SvgIcon component={PencilIcon} />
        </IconButton>
      )
    } else {
      return (
        <Button className={classes.button} variant='text' color='primary' startIcon={<AddIcon />} onClick={openHandler}>
          {t('commentAction')}
        </Button>
      )
    }
  }

  return (
    <>
      {description && (
        <Box letterSpacing='0.25px' mb={2} whiteSpace='pre-wrap'>
          <Typography variant='body2'>{description}</Typography>
        </Box>
      )}
      {isNullAnswer(answer) && disabled ? (
        <ReadOnlyText>{t('questionAnswerNotGiven')}</ReadOnlyText>
      ) : (
        <>
          <div className={classes.innerWrapper}>
            {renderNumberEditor()}

            {hasAnswer && (
              <div className={classes.answerWrapper}>
                <OpenableTypography className={classes.comment}>{comment}</OpenableTypography>
                <Box>{renderCommentButton()}</Box>
              </div>
            )}
          </div>
          {isNumericError && <FormHelperText error>{errorHelperText ?? t('requiredFieldError')}</FormHelperText>}
        </>
      )}
    </>
  )
}
export default forwardRef(NumericQuestion)
