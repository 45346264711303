import React, { FC, useEffect } from 'react'

import { createStyles, makeStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'

import { IActionScreenItem } from '../../../../../model/screen-item-base'
import { checkPredicate, IPredicate } from '../../../../../model/script-predicate'
import { IStringValueBase } from '../../../../../model/string-value-base'
import { formatTemplateString } from '../../../../../utils/format-template-string'
import { useIsSmall } from '../../../../_common/hooks/useIsSmall'
import { getContextProperty } from '../../../script-tasks/propertyName'
import { IScriptTaskContext, useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { useGetStringValueBase } from '../get-string-value-base'
import { ItemCard } from '../item-card'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: { fontWeight: 400, fontSize: '16px', padding: '8px 0' },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    name: {
      flex: '0 1 50%',
      color: theme.palette.text.secondary,
    },
    value: {
      display: 'flex',
      justifyContent: 'flex-start',
      flex: '1',
      color: theme.palette.text.primary,
    },
  }),
)
export interface IPropertyListScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.PropertyListScreenItem'
  items: IPropertyListTextItem[]
  visible?: IPredicate
}
export interface IPropertyListTextItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.PropertyListTextItem'
  displayName: string
  value: IStringValueBase
  hideEmpty?: boolean
  nullValueCaption?: string
}

interface PropertyListTextItemProps {
  item: IPropertyListTextItem
  context: IScriptTaskContext
}

const PropertyListTextItem: FC<PropertyListTextItemProps> = ({ item, context }) => {
  const classes = useStyles()
  const isSmall = useIsSmall()
  const templateString = useGetStringValueBase(context, undefined, item.value, '')

  let value = formatTemplateString(`${templateString}`, (prop) => getContextProperty(context, prop))

  if (item.hideEmpty && value.includes('undefined')) {
    return <></>
  }

  if (value.includes('undefined') && item.nullValueCaption) {
    value = item.nullValueCaption
  }

  return (
    <div className={classNames(classes.root, { [classes.row]: !isSmall })}>
      <div className={classes.name}>{item.displayName}</div>
      <div className={classes.value}>{value}</div>
    </div>
  )
}

export interface PropertyListScreenItemProps {
  item: IPropertyListScreenItem
  context: IScriptTaskContext
}

export const PropertyListScreenItem: FC<PropertyListScreenItemProps> = ({ item, context }) => {
  useEffect(() => {
    console.log('PropertyListScreenItem resolved item', item)
  }, [])
  return (
    <ItemCard label={item.displayName && <Typography variant='inherit'>{item.displayName}</Typography>}>
      {(item.items || []).map((item, index) => (
        <PropertyListTextItem key={index} item={item} context={context}></PropertyListTextItem>
      ))}
    </ItemCard>
  )
}
export interface PropertyListScreenItemControlProps {
  item: IPropertyListScreenItem
  children?: never
}

export const PropertyListScreenItemControl: FC<PropertyListScreenItemControlProps> = (props) => {
  useEffect(() => {
    console.log('PropertyListScreenItemControl mount', props)
  }, [])

  const { item } = props
  const context = useScriptTaskContext()

  if (item.visible && !checkPredicate(item.visible, undefined, context)) {
    return <></>
  }

  return <PropertyListScreenItem item={props.item} context={context}></PropertyListScreenItem>
}
