import React, { useEffect, useMemo } from 'react'

import { Box, createStyles, makeStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'

import { IActionScreenItem } from '../../../../model/screen-item-base'
import { IStringValueBase } from '../../../../model/string-value-base'
import { dateFormat } from '../../../../utils'
import { formatTemplateString } from '../../../../utils/format-template-string'
import { usePropertyName } from '../../nested/usePropertyName'
import { getContextProperty } from '../../script-tasks/propertyName'
import { IScriptTaskContext, useScriptTaskContext } from '../../script-tasks/script-task-context'
import { AccordionArrow, AccordionCard } from './accordion-card'
import { useGetStringValueBase } from './get-string-value-base'
import { ItemCard } from './item-card'

const useStyles = makeStyles(() =>
  createStyles({
    multiline: {
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
    },
  }),
)

export interface ITextScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.TextScreenItem'
  format?: IStringValueBase
  /** @defaultValue false */
  collapsible?: boolean
  /** @defaultValue "" */
  nullValueCaption?: string
  /** @defaultValue false */
  hideEmpty?: boolean
  /** @defaultValue false */
  useEllipsis?: boolean
}

interface TextScreenItemProps {
  item: ITextScreenItem
  value: string
  children?: never
}

const TextScreenItem: React.FC<TextScreenItemProps> = ({ item, value }) => {
  const classes = useStyles()
  useEffect(() => {
    console.log('TextScreenItem mount resolved value:', value)
  }, [])

  if (item.hideEmpty && !value) {
    return <></>
  }

  const content = (
    <Typography
      noWrap={item.useEllipsis}
      color='textSecondary'
      className={classNames({
        [classes.multiline]: !item.useEllipsis,
      })}
    >
      {value ?? item.nullValueCaption ?? ''}
    </Typography>
  )

  if (item.collapsible) {
    return (
      <AccordionCard
        label={
          <>
            <AccordionArrow />
            <Typography style={{ paddingTop: 12, paddingBottom: 12 }} variant='inherit'>
              {item.displayName}
            </Typography>
          </>
        }
      >
        <Box p={1} pb={0} width='100%'>
          {content}
        </Box>
      </AccordionCard>
    )
  }

  return (
    <ItemCard label={item.displayName && <Typography variant='inherit'>{item.displayName}</Typography>}>
      {content}
    </ItemCard>
  )
}

const PropertyNameReader: React.FC<{ propertyName: string; element: React.ReactElement }> = ({
  propertyName,
  element,
}) => {
  const value: string = usePropertyName(propertyName)
  return React.cloneElement(element, { value })
}

const FormatReader: React.FC<{
  format: IStringValueBase
  stringValueErrorMessage: string
  element: React.ReactElement
}> = ({ format, element, stringValueErrorMessage }) => {
  const context = useScriptTaskContext()
  const templateString = useGetStringValueBase(context, undefined, format, stringValueErrorMessage)
  const value = useMemo(() => {
    if (!templateString) return ''

    const format = templateString
    // TODO: research if any checks for nullValue needed
    return formatTemplateString(format, (prop) => formatCustom(context, prop))
  }, [templateString])
  return React.cloneElement(element, { value })
}

interface TextScreenItemControlProps {
  item: ITextScreenItem
  element?: React.ReactElement
  children?: never
}

export const TextScreenItemControl: React.FC<TextScreenItemControlProps> = ({
  item,
  element = <TextScreenItem item={item} value='' />,
}) => {
  useEffect(() => {
    console.log('TextScreenItemControl mount', item)
  }, [])

  if (item.format) {
    return (
      <FormatReader
        format={item.format}
        stringValueErrorMessage={`TextScreenItem ${item.displayName} ${item.format.$type}`}
        element={element}
      />
    )
  } else {
    return <PropertyNameReader propertyName={item.propertyName} element={element} />
  }
}

/**
 * @param formatPattern formatted like propertyName[|formatter|formatterParam]
 */
export function formatCustom(context: IScriptTaskContext, formatPattern: string): string {
  const segments = formatPattern.split('|')

  if (segments.length > 1) {
    const [propertyName, formatter, formatterParam] = segments as [string, string, string?]
    switch (formatter) {
      case 'date': {
        const date = getContextProperty<number>(context, propertyName)
        return dateFormat(date, formatterParam ?? 'dd.MM.yyyy')
      }
    }
  }

  return getContextProperty(context, segments[0])
}
