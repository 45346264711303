import React, { useEffect } from 'react'
import 'leaflet/dist/leaflet.css'

import { MapContainer } from 'react-leaflet'

import { VisitWithPosIndexed } from '../visits-map-by-day'
import VisitsOsmMap from './visits-osm-map'

interface ILeafletProviderProps {
  visits: VisitWithPosIndexed[]
  selectVisit: string | undefined,
  onSelect: (code: string | undefined) => void
  onInit: () => void
}

const LeafletProvider: React.FC<ILeafletProviderProps> = (props) => {
  useEffect(() => {
    props.onInit()
  }, [])
  return (
    <MapContainer
      style={{width: '100%', height: '100%'}}
      center={[55.75, 37.57]}
      zoom={13}
      zoomControl={false}
      scrollWheelZoom={true}>
      <VisitsOsmMap {...props} />
    </MapContainer>
  )
}

export default LeafletProvider