import React, { useContext, useEffect } from 'react'

import { Box, Button, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { sortBy } from 'lodash'
import { useNavigate, useLocation } from 'react-router-dom'

import { EmptyMessage } from '../../../../../components'
import { IEntityReference, Code } from '../../../../../model/base'
import { ISurveysPropertyScreenItem } from '../../../../../model/screen-item'
import { ApiContext } from '../../../../../providers'
import { useAsyncError } from '../../../../_common/hooks/useAsyncError'
import { useAsyncRetryPromise } from '../../../../_common/hooks/useAsyncRetry'
import { useLocalContextService } from '../../../nested/local-context'
import ContactListItem from '../../../sales-expert/tabs/contacts/contact-list-item'
import { getContextProperty } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { StepContext } from '../../../script-tasks/step-context'
import { ITaskRouteState } from '../../types'
import { ItemCard } from '../item-card'

interface Props {
  item: ISurveysPropertyScreenItem
}

export const SurveyItemEdit: React.FC<Props> = ({ item }) => {
  useEffect(() => {
    console.log('SurveyItem', item)
  }, [item])
  const propertiesContext = useScriptTaskContext()
  const task = propertiesContext.task
  const api = useContext(ApiContext)
  const navigate = useNavigate()
  const location = useLocation()
  const { step: stage } = useContext(StepContext)
  const questionnaireCode = item.questionnaire.code
  const surveyRefs = getContextProperty(propertiesContext, item.propertyName, [])
  const contextService = useLocalContextService()

  const surveysOps = useAsyncRetryPromise(async () => {
    console.log({ surveyRefs })
    const surveys = await api.survey.getSurveys(surveyRefs.map((surveyRef: IEntityReference) => surveyRef.code))
    const fittingSurveys = surveys?.filter((survey) => survey.questionnaire.code === questionnaireCode) ?? []
    const sorted = sortBy(fittingSurveys, ['creationTime', 'code'])
    return sorted
  }, [surveyRefs])

  useAsyncError(surveysOps.error)

  const addSurveys = async (count: number): Promise<void> => {
    const [sourceObj, ...propertyPath] = item.propertyName.split('.')
    console.log('добавляем опросы', { count, sourceObj, propertyPath })
    for (let i = 0; i < count; i++) {
      await api.tasks.createNewTaskSurvey(task.code, questionnaireCode, propertyPath.join('.'))
    }
    await contextService.refetch()
  }

  useEffect(() => {
    if (surveysOps.value) {
      const currentSurveyCount = surveysOps.value.length
      console.log({ currentSurveyCount, settings: item.editSettings })
      const missingSurveyCount = (item.editSettings?.defaultSurveyCount ?? 0) - currentSurveyCount
      if (missingSurveyCount > 0) {
        void addSurveys(missingSurveyCount)
      }
    }
  }, [surveysOps.value])

  if (surveysOps.loading && !surveysOps.value) {
    return <></>
  }

  const addSurvey = (): void => void addSurveys(1)

  const gotoSurvey = (surveyCode: Code, i: number): void => {
    const state = location.state as ITaskRouteState
    state.selectedTab = stage.code
    state.surveyName = `${item.surveyDisplayNamePrefix ?? 'Опрос '}${i + 1}`
    const path = `${location.pathname}/survey-composite/${surveyCode}`
    navigate(path, { state })
  }

  const cancelSurvey = async (surveyCode: Code): Promise<void> => {
    await api.survey.setSurveyStatus({ surveyCode, status: 'Canceled' })
    await contextService.refetch()
  }

  const restoreSurvey = async (surveyCode: Code): Promise<void> => {
    await api.survey.setSurveyStatus({ surveyCode, status: 'Open' })
    await contextService.refetch()
  }

  const surveys = surveysOps.value!
  const reachedMaxSurveyCount =
    surveys /* .filter((survey) => survey.status !== 'Canceled') */.length >=
    (item?.editSettings?.maxSurveyCountAvailable ?? -1)

  return (
    <ItemCard
      label={
        <Box margin={-1} display='flex'>
          <Typography variant='h4'>{item.displayName}</Typography>
          <Box ml='auto' width='fit-content'>
            <Button
              variant='text'
              color='primary'
              size='small'
              startIcon={<AddIcon />}
              onClick={addSurvey}
              disabled={reachedMaxSurveyCount}
            >
              Добавить
            </Button>
          </Box>
        </Box>
      }
    >
      {!surveys.length && <EmptyMessage message='Нет опросов' />}
      {surveys.map((survey, i) => (
        <ContactListItem
          key={survey.code}
          code={survey.code}
          name={`${item.surveyDisplayNamePrefix ?? 'Опрос '}${i + 1}`}
          status={survey.status}
          onItemClick={(code) => gotoSurvey(code, i)}
          onItemRemove={cancelSurvey}
          onItemRepair={restoreSurvey}
        />
      ))}
    </ItemCard>
  )
}
