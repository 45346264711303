import { createStyles, withStyles } from '@material-ui/core'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

const Root = withStyles(
  (theme) =>
    createStyles({
      root: {
        height: 40,
        background: theme.palette.background.paper,
        borderRadius: 40,
        '& .Mui-selected': {
          background: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          '&:hover': {
            background: theme.palette.primary.main
          }
        },
        '& > button': {
          '&:hover': {
            background: 'unset'
          },
          textTransform: 'unset',
          color: theme.palette.text.primary,
          padding: theme.spacing(1, 3)
        }
        // '& > button:hover': {
        //   background: 'unset',
        //   borderColor: theme.palette.primary.main
        // }
      },
      grouped: {
        borderRadius: '40px !important',
        border: 'none !important'
        // marginRight: 8,
        // '&:last-child': {
        //   marginRight: 0
        // },
        // whiteSpace: 'nowrap',
        // fontSize: 14,
        // fontWeight: 500,
        // lineHeight: 1.5,
        // color: grays.gray1,
        // [theme.breakpoints.down('xs')]: {
        //   flex: '1 0 20px'
        // },

        // border: '2px solid #87939D'
        // '&:first-child': {
        //   borderRadius: 8,
        //   border: '2px solid #87939D'
        // }
      }
    }),
  { name: 'ToggleButtons' }
)(ToggleButtonGroup)

export const ToggleButtons = Root
