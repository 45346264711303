/* eslint-disable @typescript-eslint/method-signature-style */

import { InjectionToken } from '../infrastructure/di'
import type { IEntity, IEntityReference } from '../model/base'
import type { IDTETaskTemplate, IDTETaskTemplateUnit } from '../model/dte-task-template'
import type { ITaskResultScreen } from '../model/task-result-screen'
import type { IExecutionProcessSource } from './task-templates-service-api'

type EntityKey = string

export interface IDTETaskTemplatesService {
  getCurrentTaskTemplateVersions(): Promise<IDTETaskTemplate[]>
  getTaskTemplateVersions(templateKey: EntityKey): Promise<IDTETaskTemplate[]>
  getTaskTemplate(templateKey: EntityKey): Promise<IDTETaskTemplateUnit>
  createTaskTemplate(data: DTETaskTemplateUpdateDTO): Promise<EntityKey>
  updateTaskTempate(templateKey: EntityKey, data: DTETaskTemplateUpdateDTO): Promise<void>
  copyTaskTemplate(templateKey: EntityKey): Promise<EntityKey>
  exportTaskTemplate(templateKey: EntityKey): Promise<IEntity[]>
  importTaskTemplate(unit: IEntity[]): Promise<void>
  createNewVersion(templateKey: EntityKey, params: NewVersionParams): Promise<EntityKey>
  updateExecutionProcess(templateKey: EntityKey, data: IExecutionProcessSource): Promise<IEntityReference>
  createExecutionSubprocess(templateKey: EntityKey, data: IExecutionProcessSource): Promise<IEntityReference>
  updateExecutionSubprocess(templateKey: EntityKey, processKey: EntityKey, data: IExecutionProcessSource): Promise<void>
  updateTaskResultScreen(templateKey: EntityKey, data: ITaskResultScreen): Promise<IEntityReference>
}

export interface NewVersionParams {
  startDate: Date
  copyQuestionnaires: boolean
}

export interface DTETaskTemplateUpdateDTO extends Omit<IDTETaskTemplate,
  | '$type'
  | 'version'
  | 'process'
  | 'subprocesses'
  | 'resultScreen'
> {}

export const DTE_TASK_TEMPLATE_API = new InjectionToken<IDTETaskTemplatesService>('dte-task-template-api')
