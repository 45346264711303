import React, { useEffect } from 'react'

import { Layout } from '../../layout'
import { ICustomAppFeature } from '../../model/app-feature'
import { Debug } from '../debug'
import { HyperlinksPage } from '../hyperlinks-page'
import { ProblemsAppFeature } from '../problems/problems-app-feature'
import { ReportsAppFeature } from '../reports/reports-app-feature/reports-app-feature'
import { StoresAppFeature } from '../stores/stores-app-feature/stores-app-feature'
import { SyncPage } from '../sync-page'
import { VisitsAppFeature } from '../visits/visits-app-feature/visits-app-feature'
import { AppFeatureContextProvider } from './app-feature-context'

const FieldForceTaskTemplates = React.lazy(async () => import('../admin/field-force-task-templates/root'))
const DteTaskTemplates = React.lazy(async () => import('../admin/dte-task-templates/root'))
const Questionnaires = React.lazy(async () => import('../admin/questionnaire/questionnaire-root'))
const DataManager = React.lazy(async () => import('../admin/data-manager/data-manager-root'))
const AppFeaturesEditor = React.lazy(async () => import('../admin/app-feature-editor/root'))
const DataObjectsEditor = React.lazy(async () => import('../admin/data-objects-editor/root'))

interface CustomAppFeatureProps {
  children?: never
  feature: ICustomAppFeature
}

export const CustomAppFeature: React.FC<CustomAppFeatureProps> = (props) => {
  const { feature } = props
  useEffect(() => {
    console.log('CustomAppFeature ', feature)
  }, [feature.code])

  const renderFeature = (): JSX.Element => {
    switch (feature.featureKey) {
      case 'visits':
        return <VisitsAppFeature />
      case 'sync':
        return (
          <Layout>
            <SyncPage />
          </Layout>
        )
      case 'reports':
        return <ReportsAppFeature />
      case 'debug':
        return (
          <Layout>
            <Debug />
          </Layout>
        )
      case 'hyperlinks':
        return (
          <Layout>
            <HyperlinksPage />
          </Layout>
        )
      case 'stores':
        return <StoresAppFeature />
      case 'problems':
        return <ProblemsAppFeature />
      case 'field-force-task-templates':
        return (
          <Layout>
            <FieldForceTaskTemplates />
          </Layout>
        )
      case 'dte-task-templates':
        return (
          <Layout>
            <DteTaskTemplates />
          </Layout>
        )
      case 'questionnaires-editor':
        return (
          <Layout>
            <Questionnaires />
          </Layout>
        )
      case 'data-manager':
        return (
          <Layout>
            <DataManager />
          </Layout>
        )
      case 'app-features-editor':
        return (
          <Layout>
            <AppFeaturesEditor />
          </Layout>
        )
      case 'data-objects-editor':
        return (
          <Layout>
            <DataObjectsEditor />
          </Layout>
        )
    }

    return <Layout>CustomAppFeature {feature.code}</Layout>
  }

  return <AppFeatureContextProvider feature={feature}>{renderFeature()}</AppFeatureContextProvider>
}
