import { ContentTable, Style, StyleDictionary, TableCell } from 'pdfmake/interfaces'

import { BooleanAnswer, HyperlinkAnswer, NumericAnswer, PhotoAnswer, TextAnswer } from '../../../model/answer'
import { IQuestion } from '../../../model/question'
import { IQuestionGroup } from '../../../model/question-group'
import { IQuestionnaire } from '../../../model/questionnaire'
import { ISurvey } from '../../../model/survey'
import { getContentText } from '../../../utils'
import { getAvailableQuestions } from '../sales-expert/tabs-new/trainee-skills/predicate-questions'

interface IProps {
  survey: ISurvey
  questionnaire: IQuestionnaire
  surveyName?: string
}

interface IStyle {
  name: string
  style: Style
}

function styleDef<T extends Record<string, IStyle>>(styleDict: T): T {
  return styleDict
}

const styles = styleDef({
  surveyTableHeader: {
    name: 'surveyTableHeader',
    style: {
      bold: true,
      alignment: 'center',
    },
  },
  surveyGroup: {
    name: 'surveyGroup',
    style: {
      fillColor: '#f2f2f2',
    },
  },
  surveyComment: {
    name: 'surveyComment',
    style: {
      margin: [0, 3, 0, 0],
      characterSpacing: -0.25,
      fontSize: 10,
      color: 'grey',
    },
  },
})

export const surveyStyles: StyleDictionary = {
  [styles.surveyTableHeader.name]: styles.surveyTableHeader.style,
  [styles.surveyGroup.name]: styles.surveyGroup.style,
  [styles.surveyComment.name]: styles.surveyComment.style,
}

export function surveyPdf({ survey, questionnaire, surveyName }: IProps): ContentTable {
  const availableQuestions = getAvailableQuestions(questionnaire!.questions, survey!.answers ?? []).sort((a, b) => {
    function getGroupOrder(q: IQuestion): number {
      return questionnaire!.questionGroups?.find((group) => group.code === q.questionGroupCode)?.orderNumber ?? 0
    }

    const groupOrder = getGroupOrder(a) - getGroupOrder(b)
    return groupOrder || a.orderNumber - b.orderNumber
  })

  const availableGroups =
    questionnaire!.questionGroups?.filter((group) =>
      availableQuestions.find((entry) => entry.questionGroupCode === group.code),
    ) ?? []

  const questionnaireEntries = [
    ...availableGroups,
    ...availableQuestions.filter((entry) => !entry.questionGroupCode),
  ].sort((a, b) => {
    return a.orderNumber - b.orderNumber
  })

  function renderEntry(question: IQuestion): TableCell[] {
    const answer = survey!.answers?.find((answer) => answer.questionCode === question.code)
    let value = ''
    if (answer?.$type === 'PMI.FACE.BDDM.Extensions.Classes.FaceNullAnswer') {
      value = 'Не ответил(а) на вопрос'
    } else {
      switch (question.$type) {
        case 'PMI.FACE.BDDM.Extensions.Classes.FaceBooleanQuestion':
          value = (answer as BooleanAnswer)?.reply ?? ''
          break
        case 'PMI.FACE.BDDM.Extensions.Classes.FaceTextQuestion':
          value = (answer as TextAnswer)?.reply ?? ''
          break
        case 'PMI.FACE.BDDM.Extensions.Classes.FaceHyperlinkQuestion':
          value = (answer as HyperlinkAnswer)?.reply ? question.finishedDisplayName ?? 'Выполнено' : 'Не выполнено'
          break
        case 'PMI.FACE.BDDM.Extensions.Classes.FaceNumericQuestion':
          value = String((answer as NumericAnswer)?.reply) ?? ''
          break
        case 'PMI.FACE.BDDM.Extensions.Classes.FacePhotoQuestion':
          value = `${(answer as PhotoAnswer)?.reply?.parts.length ?? 0} фото`
          break
      }
    }

    return [
      [
        { text: question.description, italics: true },
        ...getContentText(question.content)
          .split('\n')
          .map((line) => ({ text: line })),
        { text: answer?.comment ? `Комментарий: ${answer.comment}` : undefined, style: styles.surveyComment.name },
      ],
      { text: value, alignment: 'center' },
    ]
  }

  const defaultSurveyHeader = [
    { text: 'Вопрос', style: styles.surveyTableHeader.name },
    { text: 'Ответ', style: styles.surveyTableHeader.name },
  ]

  const table: ContentTable = {
    table: {
      // headerRows: 1,
      widths: ['*', 'auto'],
      body: [
        surveyName ? [{ text: surveyName, colSpan: 2, style: styles.surveyTableHeader.name }, ''] : defaultSurveyHeader,
        ...questionnaireEntries.flatMap((entry) => {
          if (isGroup(entry)) {
            const group = entry
            const questions = availableQuestions
              .filter((question) => question.questionGroupCode === group.code)
              .map(renderEntry)
            return [
              [{ text: group.name, colSpan: 2, style: [styles.surveyTableHeader.name, styles.surveyGroup.name] }],
              ...questions,
            ]
          } else {
            return [renderEntry(entry)]
          }
        }),
      ],
    },
  }

  return table
}

type QuestionnaireEntry = IQuestionGroup | IQuestion

function isGroup(entry: QuestionnaireEntry): entry is IQuestionGroup {
  if ('name' in entry) return true
  return false
}
