/* eslint-disable */
import { IStorageSchema } from '../infrastructure/storage-service'
import { migrate, refactor } from './migration'

export const VisitStore = 'visit'
export const VisitStore_plannedStartDate = 'plannedStartDate'
export const VisitStore_pos = 'pos'
export const VisitStore_changeTime = '_changeTime'
export const POSStore = 'pos'
export const TaskTemplateStore = 'task-template'
export const TaskStore = 'task'
export const TaskStore_visit = 'visit'
export const TaskStore_updateTime = 'updateTime'
export const TaskStore_status = 'status'
export const TaskStore_changeTime = '_changeTime'
export const TaskReportStore = 'task-report'
export const SurveyStore = 'survey'
export const SurveyStore_visit = '_visit'
export const SurveyStore_task = '_task'
export const SurveyStore_updateTime = 'updateTime'
export const SurveyStore_status = 'status'
export const QuestionnaireStore = 'questionnaire'
export const QuestionnaireStore_updateTime = 'updateTime'
export const ISAStore = 'isa'
export const ISAStore_endDate = 'endDate'
export const ISAStore_pos = 'pos'
export const VisitTaskTopicStore = 'visit-task-topic'
export const SMServiceStore = 'sm-service'
export const SMServiceStore_endDate = 'endDate'
export const UserProfileStore = 'user-profile'
export const DictionaryStore = 'dictionary'
export const POSTaskRegisterStore = 'pos-task-register'
export const POSTaskRegisterStore_pos = 'pos'
export const POSTaskRegisterStore_taskTemplate = 'taskTemplate'
export const POSTaskRegisterExecutionStore = 'pos-task-register-execution'
export const TaskTemplateContentStore = 'task-template-content'
export const ContentDocumentStore = 'content-document'
export const ProductMatrixStore = 'product-matrix'
export const ProductMatrixAssignmentStore = 'product-matrix-assignment'
export const ProductMatrixAssignmentStore_pos = 'pos'
export const BrandVariantStore = 'brand-variant'
export const ContractTermStore = 'contract-term'
export const ContractTermAssignmentStore = 'contract-term-assignment'
export const ContractTermAssignmentStore_pos = 'pos'
export const TaskExecutionScopeStore = 'task-execution-scope'
export const TaskExecutionStateStore = 'task-execution-state'
export const ProblemTemplateStore = 'problem-template'
export const ProblemTemplateStore_updateTime = 'updateTime'
export const ProblemTemplateStore_escalationReasonCode = 'escalationReasonCode'
export const ProblemStore = 'problem'
export const ProblemStore_location = 'location'
export const ProblemStore_status = 'status'
export const ProblemStore_updateTime = 'updateTime'
export const ProblemStore_changeTime = '_changeTime'
export const SupervisedVisitStore = 'visit-supervised'
export const SupervisedVisitStore_changeTime = 'changeTime'
export const SupervisedVisitStore_plannedStartDate = 'plannedStartDate'
export const SupervisedVisitStore_pos = 'pos'
export const HyperlinkStore = 'hyperlink'
export const HyperlinkStore_updateTime = 'updateTime'
export const HyperlinkStore_type = 'type'
export const TaskTemplateQuestionnaireAssignmentStore = 'task-template-questionnaire-assignment'
export const TaskTemplateQuestionnaireAssignmentStore_updateTime = 'updateTime'
export const MetricStore = 'metric'
export const MetricStore_updateTime = 'updateTime'
export const MetricStore_type = 'type'
export const DteParticipantProfileStore = 'dte-participant-profile'
export const DteParticipantProfileStore_updateTime = 'updateTime'
export const DteParticipantProfileStore_status = 'status'
export const VisitAssessmentStore = 'visit-assessment'
export const VisitAssessmentStore_updateTime = 'updateTime'
export const VisitAssessmentStore_visitCode = 'visitCode'
export const VisitAssessmentStore_status = 'status'
export const VisitAssessmentStore_result = 'result'
export const PPOSMStore = 'pposm'
export const PPOSMStore_updateTime = 'updateTime'
export const PPOSMStore_pos = 'pos'
export const POSParticipantProgramStore = 'pos-participant-program'
export const POSParticipantProgramStore_updateTime = 'updateTime'
export const POSParticipantProgramStore_pos = 'pos'
export const QuestionnairePOSAssignmentStore = 'questionnaire-pos-assignment'
export const QuestionnairePOSAssignmentStore_updateTime = 'updateTime'
export const QuestionnairePOSAssignmentStore_questionnaire = 'questionnaire'
export const SupervisedFieldPositionRoleStore = 'supervised-field-position-role'
export const FieldPositionRoleUserProfileStore = 'fpr-user-profile'

const schema: IStorageSchema = {
  version: 54,
  stores: {
    [POSStore]: {
      keyPath: 'code'
    },
    [VisitStore]: {
      keyPath: 'code',
      index: {
        [VisitStore_plannedStartDate]: {
          keyPath: 'plannedStartDate',
          unique: false
        },
        [VisitStore_pos]: {
          keyPath: 'pointOfSaleCode',
          unique: false
        },
        [VisitStore_changeTime]: {
          keyPath: '_changeTime',
          unique: false
        }
      }
    },
    [TaskTemplateStore]: {
      keyPath: '_key'
    },
    [TaskStore]: {
      keyPath: 'code',
      index: {
        [TaskStore_visit]: {
          keyPath: 'visitCode',
          unique: false
        },
        [TaskStore_updateTime]: {
          keyPath: 'updateTime',
          unique: false
        },
        [TaskStore_status]: {
          keyPath: 'status',
          unique: false
        },
        [TaskStore_changeTime]: {
          keyPath: '_changeTime',
          unique: false
        },
      }
    },
    [SurveyStore]: {
      keyPath: 'code',
      index: {
        [SurveyStore_visit]: {
          keyPath: '_visitCode',
          unique: false
        },
        [SurveyStore_task]: {
          keyPath: '_taskCode',
          unique: false
        },
        [SurveyStore_updateTime]: {
          keyPath: 'updateTime',
          unique: false
        },
        [SurveyStore_status]: {
          keyPath: 'status',
          unique: false
        }
      }
    },
    [QuestionnaireStore]: {
      keyPath: 'code',
      index: {
        [QuestionnaireStore_updateTime]: {
          keyPath: 'updateTime',
          unique: false
        }
      }
    },
    [TaskReportStore]: {
      keyPath: 'code'
    },
    [ISAStore]: {
      keyPath: 'code',
      index: {
        [ISAStore_endDate]: {
          keyPath: 'endDate',
          unique: false
        },
        [ISAStore_pos]: {
          keyPath: 'pointOfSale.code',
          unique: false
        }
      }
    },
    [VisitTaskTopicStore]: {
      keyPath: 'code'
    },
    [SMServiceStore]: {
      keyPath: 'code',
      index: {
        [SMServiceStore_endDate]: {
          keyPath: 'endDate',
          unique: false
        }
      }
    },
    [UserProfileStore]: {
      keyPath: 'key'
    },
    [DictionaryStore]: {
      keyPath: 'version.code'
    },
    [POSTaskRegisterStore]: {
      keyPath: 'code',
      index: {
        [POSTaskRegisterStore_pos]: {
          keyPath: 'posCode',
          unique: false
        },
        [POSTaskRegisterStore_taskTemplate]: {
          keyPath: 'taskTemplate.version.code',
          unique: false
        }        
      }
    },
    [POSTaskRegisterExecutionStore]: {
      keyPath: 'code'
    },
    [TaskTemplateContentStore]: {
      keyPath: 'code'
    },
    [ContentDocumentStore]: {
      keyPath: 'code'
    },
    [ProductMatrixStore]: {
      keyPath: 'code'
    },
    [ProductMatrixAssignmentStore]: {
      keyPath: 'code',
      index: {
        [ProductMatrixAssignmentStore_pos]: {
          keyPath: 'productLocation.code',
          unique: false
        }
      }
    },
    [BrandVariantStore]: {
      keyPath: 'code'
    },
    [ContractTermStore]: {
      keyPath: 'code'
    },
    [ContractTermAssignmentStore]: {
      keyPath: 'code',
      index: {
        [ContractTermAssignmentStore_pos]: {
          keyPath: 'pos.code',
          unique: false
        }
      }
    },
    [TaskExecutionScopeStore]: { },
    [TaskExecutionStateStore]: {
      keyPath: 'taskCode'
    },
    [ProblemTemplateStore]: {
      keyPath: 'code',
      index: {
        [ProblemTemplateStore_updateTime]: {
          keyPath: 'updateTime',
          unique: false
        },
        [ProblemTemplateStore_escalationReasonCode]: {
          keyPath: 'escalationReason.code',
          unique: false
        }
      }
    },
    [ProblemStore]: {
      keyPath: 'code',
      index: {
        [ProblemStore_location]: {
          keyPath: 'location.code',
          unique: false
        },
        [ProblemStore_status]: {
          keyPath: 'status',
          unique: false
        },
        [ProblemStore_updateTime]: {
          keyPath: 'updateTime',
          unique: false
        },
        [ProblemStore_changeTime]: {
          keyPath: '_changeTime',
          unique: false
        },
      }
    },
    [SupervisedVisitStore]: {
      keyPath: 'visit.code',
      index: {
        [SupervisedVisitStore_changeTime]: {
          keyPath: 'changeTime',
          unique: false
        },
        [SupervisedVisitStore_plannedStartDate]: {
          keyPath: 'visit.plannedStartDate',
          unique: false
        },
        [SupervisedVisitStore_pos]: {
          keyPath: 'visit.pointOfSaleCode',
          unique: false
        }
      }
    },
    [HyperlinkStore]: {
      keyPath: 'code',
      index: {
        [HyperlinkStore_updateTime]: {
          keyPath: 'updateTime',
          unique: false
        },
        [HyperlinkStore_type]: {
          keyPath: 'type',
          unique: false
        }
      }
    },
    [TaskTemplateQuestionnaireAssignmentStore]: {
      keyPath: 'code',
      index: {
        [TaskTemplateQuestionnaireAssignmentStore_updateTime]: {
          keyPath: 'updateTime',
          unique: false
        },
      }
    },
    [MetricStore]: {
      keyPath: 'code',
      index: {
        [MetricStore_updateTime]: {
          keyPath: 'updateTime',
          unique: false
        },
        [MetricStore_type]: {
          keyPath: '$type',
          unique: false
        },
      }
    },
    [DteParticipantProfileStore]: {
      keyPath: 'code',
      index: {
        [DteParticipantProfileStore_updateTime]: {
          keyPath: 'updateTime',
          unique: false
        },
        [DteParticipantProfileStore_status]: {
          keyPath: 'status',
          unique: false
        },
      }
    },
    [VisitAssessmentStore]: {
      keyPath: 'code',
      index: {
        [VisitAssessmentStore_updateTime]: {
          keyPath: 'updateTime',
          unique: false
        },
        [VisitAssessmentStore_visitCode]: {
          keyPath: 'visit.code',
          unique: false
        },
        [VisitAssessmentStore_status]: {
          keyPath: 'status',
          unique: false
        },
        [VisitAssessmentStore_result]: {
          keyPath: 'result',
          unique: false
        },
      }
    },
    [PPOSMStore]: {
      keyPath: 'code',
      index: {
        [PPOSMStore_updateTime]: {
          keyPath: 'updateTime',
          unique: false
        },
        [PPOSMStore_pos]: {
          keyPath: 'state.location.code',
          unique: false
        },
      }
    },
    [POSParticipantProgramStore]: {
      keyPath: 'code',
      index: {
        [POSParticipantProgramStore_updateTime]: {
          keyPath: 'updateTime',
          unique: false
        },
        [POSParticipantProgramStore_pos]: {
          keyPath: 'pos.code',
          unique: false
        },
      }
    },
    [QuestionnairePOSAssignmentStore]: {
      keyPath: 'code',
      index: {
        [QuestionnairePOSAssignmentStore_updateTime]: {
          keyPath: 'updateTime',
          unique: false
        },
        [QuestionnairePOSAssignmentStore_questionnaire]: {
          keyPath: 'questionnaire.code',
          unique: false
        },
      }
    },
    [SupervisedFieldPositionRoleStore]: {
      keyPath: 'code',
    },
    [FieldPositionRoleUserProfileStore]: {
      keyPath: 'code',
    },
  },
  data: {},
  refactor: refactor,
  migrate: migrate
}

export default schema
