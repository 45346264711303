/* eslint-disable @typescript-eslint/no-use-before-define */

/**
 * @source https://github.dev/JamesNK/Newtonsoft.Json/blob/57025815e564d36821acf778e2c00d02225aab35/Src/Newtonsoft.Json/Utilities/StringUtils.cs#L155
 */
export function camelCase(s: string): string {
  if (s === '' || !char.isUpper(s[0])) {
    return s
  }

  const chars = s.split('')

  for (let i = 0; i < chars.length; i++) {
    if (i === 1 && !char.isUpper(chars[i])) {
      break
    }

    const hasNext = i + 1 < chars.length
    if (i > 0 && hasNext && !char.isUpper(chars[i + 1])) {
      if (char.isSeparator(chars[i + 1])) {
        chars[i] = chars[i].toLowerCase()
      }

      break
    }

    chars[i] = chars[i].toLowerCase()
  }

  return chars.join('')
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace char {
  export function isUpper(char: string): boolean {
    return /[A-Z]/.test(char)
  }

  export function isSeparator(char: string): boolean {
    return char === ' '
  }
}
