import React, { useState } from 'react'

import { useTranslation } from "react-i18next";

import { VisitCancelationReason } from '../../../model/dictionary-item'
import { appToast } from '../../../utils'
import ReasonCancellationList from '../../tasks/reason-cancellation-list'

interface IVisitRemoveModalProps {
  reasonsItems: VisitCancelationReason[]
  onClose: () => void
  onRemoveHandler: (item: VisitCancelationReason) => void
}

export const VisitRemoveModal: React.FC<IVisitRemoveModalProps> = ({ reasonsItems, onClose, onRemoveHandler }) => {
  const { t } = useTranslation('visits')
  const [value, setValue] = useState<VisitCancelationReason>()

  return (
    <div>
      <ReasonCancellationList
        dictionary={reasonsItems}
        titleBar={t('reasonVisitDialogTitle')}
        titleList={t('reasonSelectTitle')}
        isError={!value}
        onClickItem={(item) => {
          setValue(item as VisitCancelationReason)
        }}
        onRemoveHandler={() => {
          if (!value) {
            appToast.info('Выберите причину отмены визита')
            return
          }

          onRemoveHandler(value)
        }}
        onBackHandler={onClose}
      />
    </div>
  )
}
