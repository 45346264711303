import React from 'react'

import { Box, Theme, useMediaQuery } from '@material-ui/core'
import { useTranslation } from "react-i18next";

import { PageContent } from '../../../layout/page-content'
import { TitleBar } from '../../../layout/title-bar'
import { ReportsList } from '../index'

const ReportsPage: React.FC = () => {
  const { t } = useTranslation('menu')
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'))

  return (
    <>
      <TitleBar>{t('reports')}</TitleBar>
      <PageContent>
        <Box paddingX={isSmall ? 2 : 3} paddingY={3}>
          <ReportsList />
        </Box>
      </PageContent>
    </>
  )
}

export default ReportsPage
