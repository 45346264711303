import React, { useContext } from 'react'

import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { Property } from 'csstype'

import { HorizontalAlignmentType, ILabelCell } from '../../../../../../model/table-screen-item'
import { getPropertyAny } from '../../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../../script-tasks/script-task-context'
import { useGetStringValueBase } from '../../get-string-value-base'
import { CellWrapperProps, EditorItemProps } from '../editorUtils'
import { TableItemContext } from '../table-item'
import { useDataSourceValue } from './data-source'
import { LabelMarker } from './label-marker'
import { formatLabelCell, useColorLabelCell } from './utils'

interface IStylesProps {
  textColorCell: string
  textSizeCell: string
  textWeightCell: string
}
const useStyles = makeStyles<Theme, IStylesProps>((theme) =>
  createStyles({
    cell: {
      color: ({ textColorCell }) => textColorCell,
      flexGrow: 1,
      fontSize: ({ textSizeCell }) => textSizeCell,
      fontWeight: ({ textWeightCell }) => textWeightCell as Property.FontWeight,
    },
    useLineBreak: {
      whiteSpace: 'pre-line',
      wordBreak: 'break-word',
    },
  }),
)

export const LabelCell: React.FC<EditorItemProps & CellWrapperProps> = ({ variant, ...props }) => {
  const localContext = useScriptTaskContext()
  const tableItem = useContext(TableItemContext)
  const control = props.col.control as ILabelCell

  const textColorCell = useColorLabelCell(tableItem, control, localContext, props.row)
  const textWeightCell = control.textWeight ?? 'inherit'
  const textSizeCell = control.textSize ?? 'inherit'

  const classes = useStyles({ textColorCell, textWeightCell, textSizeCell })

  const markerColorString = useGetStringValueBase(
    localContext,
    props.row,
    control.markerSettings?.color,
    `$type: ${tableItem.$type}, cellPropertyName: ${props.col.propertyName}, location: control.markerSettings.color`,
  )
  const markerFormString = useGetStringValueBase(
    localContext,
    props.row,
    control.markerSettings?.type,
    `$type: ${tableItem.$type}, cellPropertyName: ${props.col.propertyName}, location: control.markerSettings.type`,
  )

  const textValueString = useGetStringValueBase(
    localContext,
    props.row,
    control.stringValue,
    `$type: ${tableItem.$type}, cellPropertyName: ${props.col.propertyName}, location: control.stringValue`,
  )

  const value = getPropertyAny(props.row, props.col.propertyName ?? '', '')

  const dictionaryItem = useDataSourceValue(control.dataSource!, props.col.propertyName, {
    ...localContext,
    ...props.row,
  })

  function getValue(): string | undefined {
    if (control.stringValue) {
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      return textValueString || control.nullValueCaption || ''
    }

    if (control.format) {
      return formatLabelCell(control, localContext, props.row)
    }

    if (!dictionaryItem.loading) {
      if (dictionaryItem.value) {
        // Если найдены словари, то интерпретируем как словарь, а propertyName как key
        const name = dictionaryItem.value
        console.log('found dictionary and setting ', name)
        if (name) {
          return name
        }
      } else {
        return value || control.nullValueCaption
      }
    }
  }

  const isEllipsis = control.useEllipsis
  const isMarker = !!control.markerSettings

  const cellValue = getValue()

  switch (variant) {
    case 'table':
      return (
        <Box style={{ display: 'flex', maxWidth: '100%' }}>
          <Typography
            variant='inherit'
            className={classNames(classes.cell, {
              [classes.useLineBreak]: !isEllipsis,
            })}
            noWrap={isEllipsis}
            align={props.col.landscapeSettings.horizontalAlignment.toLowerCase() as Lowercase<HorizontalAlignmentType>}
          >
            {isMarker && <LabelMarker status={markerColorString} form={markerFormString} />}
            {cellValue}
          </Typography>
        </Box>
      )
    case 'portrait':
      return (
        <Box style={{ display: 'flex', maxWidth: '100%' }}>
          {cellValue && (
            <Typography
              variant='inherit'
              className={classNames(classes.cell, {
                [classes.useLineBreak]: !isEllipsis,
              })}
              noWrap={isEllipsis}
              align={props.col.portraitSettings.horizontalAlignment.toLowerCase() as Lowercase<HorizontalAlignmentType>}
            >
              {isMarker && <LabelMarker status={markerColorString} form={markerFormString} />}
              {cellValue}
            </Typography>
          )}
        </Box>
      )
    case 'extended-row':
      return (
        <Box style={{ display: 'flex', maxWidth: '100%', flexGrow: 1 }}>
          {cellValue && (
            <Typography
              variant='inherit'
              style={{
                display: 'inline-block',
              }}
              className={classNames(classes.cell, {
                [classes.useLineBreak]: !isEllipsis,
              })}
              noWrap={isEllipsis}
              align={
                props.col.landscapeSettings.horizontalAlignment.toLowerCase() as Lowercase<HorizontalAlignmentType>
              }
            >
              {isMarker && <LabelMarker status={markerColorString} form={markerFormString} />}
              {cellValue}
            </Typography>
          )}
        </Box>
      )
  }
}
