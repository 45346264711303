import React, { useContext, useEffect, useState } from 'react'

import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { LogManager } from '../../../infrastructure/logger'
import { grays } from '../../../layout/theme'
import { Code } from '../../../model/base'
import { ITask } from '../../../model/task'
import { ApiContext } from '../../../providers'
import { useFetchPendingItems } from '../../../providers/menu-data/pending-items-store'
import { ModalContext } from '../../../providers/modal'
import { appToast } from '../../../utils'
import { useIsSmall } from '../../_common/hooks/useIsSmall'
import { useFeatureRoute } from '../../custom-app-feature/app-feature-context'
import { OpenableTypography } from '../../tasks/components/openable-typography'
import { VisitModal } from '../components'
import { visitsRoutes } from '../visits-app-feature/visits-app-feature'
import TaskCardMenu from './task-card-menu'
import { useTaskCancelationReasons } from './useTaskCancelationReasons'
import { getUserCancellationReason } from './utils'

type VariantType = 'day' | 'week' | 'month'

interface CardTaskProps {
  children?: never
  task: ITask
  refetch: VoidFunction
  variant: VariantType
}

interface IStylesProps {
  variant: VariantType
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) =>
  createStyles({
    container: {
      paddingRight: ({ variant }) => (variant === 'day' ? 40 : 0),
      boxShadow: '0px 3px 7px 0px #60617021',
      borderRadius: '8px',
      backgroundColor: 'white',
      minHeight: 56,
      margin: theme.spacing(1, 0),
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      cursor: 'pointer',
      '&.Canceled': {
        backgroundColor: '#F2F3F4',
      },
      '&.Finished': {
        backgroundColor: '#F2F3F4',
      },
      [theme.breakpoints.down('xs')]: {
        alignItems: 'flex-start',
      },
    },
    statusMarker: {
      flexShrink: 0,
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
      backgroundColor: theme.palette.info.main,
      width: 8,
      flexBasis: 8,
      alignSelf: 'stretch',
      marginRight: ({ variant }) => (variant === 'day' ? 20 : 12),
      '.InProgress &': {
        backgroundColor: theme.palette.warning.main,
      },
      '.Canceled &': {
        backgroundColor: grays.gray4,
      },
      '.Finished &': {
        backgroundColor: theme.palette.success.main,
      },
    },
    info: {
      position: 'relative',
      textAlign: 'left',
      flex: '0 0 55%',
      maxWidth: '55%',
      fontSize: 16,
      minHeight: 21,
      paddingLeft: 24,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: -4,
        bottom: -4,
        left: 0,
        width: 2,
        height: 'calc(100% + 8px)',
        backgroundColor: '#E6E9EF',
      },
      [theme.breakpoints.down('xs')]: {
        flex: '0 0 100%',
        maxWidth: '100%',
        textAlign: 'left',
        minHeight: 18,
        paddingLeft: 0,
        color: grays.gray2,
        '&::before': {
          content: 'none',
        },
      },
    },
    name: {
      fontSize: 16,
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 'calc(100% - 35px)',
        fontSize: 14,
      },
    },
    cancelationReason: {
      color: grays.gray3,
      fontSize: 14,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
    nameWrap: {
      width: '100%',
    },
    nameWrapIsRepresentative: {
      flex: '0 0 45%',
      maxWidth: '45%',
      [theme.breakpoints.down('xs')]: {
        flex: '0 0 100%',
        maxWidth: '100%',
      },
    },
    inner: {
      display: 'flex',
      flexWrap: 'wrap',
      flexGrow: 1,
      maxWidth: 'calc(100% - 42px)',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        paddingTop: 8,
      },
    },
  }),
)

const logger = LogManager.getLogger('CardTask')

export const CardTask: React.FC<CardTaskProps> = (props) => {
  const { task, refetch, variant } = props
  useEffect(() => {
    console.log('CardTask mount', props)
  }, [])
  const classes = useStyles({ variant })
  const navigate = useNavigate()
  const api = useContext(ApiContext)
  const fetchPendingItems = useFetchPendingItems()
  const { t } = useTranslation('visits')
  const isSmall = useIsSmall()
  const modalContext = useContext(ModalContext)
  const featureRoute = useFeatureRoute()

  const taskCancelationReasonsOps = useTaskCancelationReasons({ taskCode: task.code })

  const [isCancelTaskModalOpen, setCancelTaskModalOpen] = useState<Code | null>(null)

  const onCancelTaskClickHandler = async (taskCode?: string | null): Promise<void> => {
    if (!taskCode) {
      return
    }

    try {
      if (taskCancelationReasonsOps.value?.length) {
        // Проверяем что есть причины отмены
        const path = generatePath(visitsRoutes.nonVisitTaskRemove, { featureRoute, code: taskCode })
        await navigate(path)
        return
      }

      await onCancelTask(taskCode!)
      // props.checkCanFinish()
    } catch (error) {
      const message = error.message
      logger.error('onCancelTaskClickHandler', message, error)
      throw new Error(error)
    }
  }

  const onCancelTask = async (taskCode: Code): Promise<void> => {
    try {
      await api.tasks.setTaskStatus({
        taskCode,
        taskStatus: 'Canceled',
        cancellationReason: getUserCancellationReason(),
      })
    } catch (e) {
      const message = t('cancelTaskError')
      logger.error('onCancelClick', message, e, { taskCode })
      appToast.error(message)
    }
    refetch()
    fetchPendingItems()
    setCancelTaskModalOpen(null)
    // appToast.success(t('cancelTaskSuccess'))
  }

  const onTaskClickHandler = (): void => {
    if (task.status === 'Canceled') {
      return
    }
    const path = generatePath(visitsRoutes.nonVisitTaskPage, { featureRoute, taskCode: task.code })
    navigate(path)
  }

  const isShowReason =
    (!!task.cancelationReason && variant === 'day') ||
    (!!task.cancelationReason && isSmall && variant === 'week') ||
    (!!task.cancelationReason && isSmall && variant === 'month')

  return (
    <>
      <div className={classnames(classes.container, task.status)} onClick={onTaskClickHandler}>
        <div className={classes.statusMarker}></div>
        <div className={classes.inner}>
          <div
            className={classnames(classes.nameWrap, {
              [classes.nameWrapIsRepresentative]: isShowReason,
            })}
          >
            <Typography className={classes.name} color='inherit' noWrap>
              {task.name}
            </Typography>
          </div>
          {isShowReason && (
            <div
              className={classes.info}
              onClick={(evt) => {
                evt.stopPropagation()
              }}
            >
              <OpenableTypography
                className={classnames(classes.name, classes.cancelationReason)}
                dialogTitle='Причина'
                noWrap
              >
                {task.cancelationReason!.name}
              </OpenableTypography>
            </div>
          )}
        </div>
        {variant === 'day' && (
          <TaskCardMenu
            taskStatus={task.status}
            plannedDate={task.startDate}
            onCancel={() => {
              if (!taskCancelationReasonsOps.value?.length) {
                setCancelTaskModalOpen(task.code)
              } else {
                void onCancelTaskClickHandler(task.code)
              }

              return console.log('cancel')
            }}
            onRestore={async () => {
              modalContext.open(
                <VisitModal
                  open={true}
                  onOk={async () => {
                    await api.tasks.setTaskStatus({
                      taskCode: task.code,
                      taskStatus: 'Planned',
                    })
                    refetch()
                    fetchPendingItems()
                    modalContext.close()
                    return console.log('restore')
                  }}
                  onClose={() => modalContext.close()}
                  title='Подтверждение'
                  message='Вы уверены, что хотите восстановить задачу?'
                />,
              )
            }}
          />
        )}
      </div>
      <VisitModal
        open={!!isCancelTaskModalOpen}
        onOk={() => {
          void onCancelTaskClickHandler(isCancelTaskModalOpen)
        }}
        onClose={() => setCancelTaskModalOpen(null)}
        title={t('confirmAction')}
        message={t('cancelTaskWarning')}
      />
    </>
  )
}
