import { Theme } from '@material-ui/core/styles'
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles'

export const getAppFrameStyle = (theme: Theme): CreateCSSProperties => ({
  display: 'flex',
  flexDirection: 'column',
  // TODO: fix layout and non-layout scrolls
  height: '100vh',
  overflowY: 'auto',
  overflowX: 'hidden',
  flexGrow: 1,
  flexShrink: 0,
  // background: theme.palette.background.default
  // [theme.breakpoints.up('xs')]: {
  //   marginTop: theme.spacing(7)
  // },
  // [theme.breakpoints.down('xs')]: {
  //   marginTop: theme.spacing(7)
  // }
})
