import { useContext, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useAsyncFn } from 'react-use'
import { AsyncState } from 'react-use/lib/useAsyncFn'

import { LogManager } from '../../../infrastructure/logger'
import { ITaskTemplate, TaskTemplateToUserProfileRelationKind } from '../../../model/task-template'
import { IProfile } from '../../../model/user-profile'
import { ApiContext, ProfileContext } from '../../../providers'
import { useAsyncError } from './useAsyncError'

export function isTemplateAvailable(
  template: ITaskTemplate,
  profile: IProfile,
  relations: TaskTemplateToUserProfileRelationKind[],
): boolean {
  return !!template.relatedProfiles?.find(
    (relation) => relation.profile.code === profile.code && relations.includes(relation.relationKind),
  )
}

function sortByOrderNumber(a: ITaskTemplate, b: ITaskTemplate): number {
  if (a.orderNumber == null || b.orderNumber == null) {
    return -1
  }
  return a.orderNumber - b.orderNumber
}

const logger = LogManager.getLogger('useTemplateByRelations')

export function useTemplatesByRelations(
  relations: TaskTemplateToUserProfileRelationKind[],
): AsyncState<ITaskTemplate[]> {
  const profile = useContext(ProfileContext)
  const api = useContext(ApiContext)
  const { t } = useTranslation('sales-expert-tasks')

  const [templatesOps, fetchTemplate] = useAsyncFn(
    async () => {
      try {
        const templates = await api.tasks.getTaskTemplates()
        templates.sort(sortByOrderNumber)
        return templates.filter((template) => isTemplateAvailable(template, profile.value!.profile, relations))
      } catch (error: unknown) {
        const message = t('sales-expert-tasks:taskTypesFetchError')
        logger.error('getVisitTaskTypes', message, error)
        throw new Error(message)
      }
    },
    [profile.value, relations],
    { loading: true },
  )

  useEffect(() => {
    if (profile.value) {
      void fetchTemplate()
    }
  }, [fetchTemplate, profile.value])

  useAsyncError(templatesOps.error)

  return templatesOps
}
