import React, { useContext, forwardRef } from 'react'

import { TextField, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Autocomplete } from '@material-ui/lab'
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason
} from '@material-ui/lab/useAutocomplete/useAutocomplete'
import { useTranslation } from 'react-i18next'
import { useAsync } from 'react-use'

import { ApiContext } from '../../../../../providers'
import { IThirdPartyRepresentative } from '../../../../../services/employee-service-api'

type InputEvent = React.ChangeEvent<Record<string, unknown>>

interface IRepresentativeAutocompleteControlProps {
  inputValue: string
  onInputChange: (value: string) => void
  onSelect: (value: IThirdPartyRepresentative | null) => void
  focus?: boolean
  disabled: boolean
  error?: boolean
  usePlaceholder?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    option: {
      display: 'flex',
      flexDirection: 'column'
    },
    title: {
      fontSize: 16,
      letterSpacing: '0.15px'
    },
    subTitle: {
      fontSize: 14,
      letterSpacing: '0.25px',
      color: theme.palette.text.secondary
    }
  })
)

type Component = React.ForwardRefRenderFunction<HTMLInputElement, IRepresentativeAutocompleteControlProps>
const RepresentativeAutocompleteControl: Component = (props, ref) => {
  const classes = useStyles()
  const api = useContext(ApiContext)
  const representativesOps = useAsync(async () => await api.employee.getRepresentatives(), [])

  const { t } = useTranslation('sales-expert-tasks')

  const handleSelect = (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: IThirdPartyRepresentative | string | null,
    reason: AutocompleteChangeReason
  ): void => {
    if (!value || typeof value === 'string') {
      return
    }

    if (reason === 'clear') {
      // console.log('clear select')
      // props.onSelect(null)
    } else if (reason === 'select-option') {
      props.onSelect(value)
    }
  }

  const handleInputChange = (event: InputEvent, value: string, reason: AutocompleteInputChangeReason): void => {
    if (reason === 'clear') {
      // console.log('clear input')
      props.onInputChange('')
    } else if (reason === 'reset') {
      // console.log('reset')
      // props.onInputChange('')
    } else if (reason === 'input') {
      // console.log('input')
      props.onInputChange(value)
    }
  }

  return (
    <Autocomplete
      loading={representativesOps.loading}
      freeSolo
      disableClearable
      id='name'
      options={representativesOps.value ?? []}
      size='small'
      onChange={handleSelect}
      inputValue={props.inputValue}
      onInputChange={handleInputChange}
      disabled={props.disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          id='name'
          label={props.disabled || props.usePlaceholder ? undefined : t('representativeInputPlaceholder')}
          placeholder={props.usePlaceholder ? t('representativeInputPlaceholder') : undefined}
          margin='none'
          variant='outlined'
          InputProps={{ ...params.InputProps, type: 'search' }}
          inputRef={(input) => {
            if (props.focus) {
              input?.focus()
            }
            if (ref) {
              if ('current' in ref) {
                ref.current = input
              } else {
                ref(input)
              }
            }
          }}
          error={props.error}
        />
      )}
      getOptionLabel={(option) => option.name ?? '-'}
      renderOption={(option) => (
        <div key={option.name} className={classes.option}>
          <Typography className={classes.title}>{option?.name ?? '-'}</Typography>
          <Typography className={classes.subTitle}>{option?.eMails?.[0]?.address ?? '-'}</Typography>
          <Typography className={classes.subTitle}>{option?.agency ?? '-'}</Typography>
        </div>
      )}
    />
  )
}

export default forwardRef(RepresentativeAutocompleteControl)
