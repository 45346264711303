import React, { useState } from 'react'

import { Box, Button, Grid, Link, Slider, TextField } from '@material-ui/core'
import { useAsync } from 'react-use'

import { base64toBlob } from '../documents/utils'

interface IState {
  selectedFile: Blob | null
  resolution: string | null
  quality: string | null
}

export const CompressImage: React.FC = () => {
  const [state, setState] = useState<IState>({
    selectedFile: null,
    resolution: '300',
    quality: '1',
  })

  const [slider, setSlider] = useState(1)

  const onChangeFile = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    const file = evt.target.files?.[0]
    if (file) {
      const blob = new Blob([file], { type: file.type })
      setState((state) => ({ ...state, selectedFile: blob }))
    }
  }

  const onChangeSettings = ({ name, value }: { name: string; value: string }): void => {
    setState((state) => ({ ...state, [name]: value }))
  }

  const optimizedImageOps = useAsync(async () => {
    if (state.selectedFile) {
      const base64OptimizedImage = await makeOptimizedImage(
        state.selectedFile,
        Number(state.resolution) ?? 300,
        Number(state.quality) ?? 80,
        'image/jpeg',
      )

      return base64toBlob(base64OptimizedImage.split(',')[1], state.selectedFile.type)
    }
  }, [state])

  const image = state.selectedFile && URL.createObjectURL(state.selectedFile)
  const optimizedImage = optimizedImageOps.value && URL.createObjectURL(optimizedImageOps.value)

  return (
    <Box px={10} py={10}>
      <Grid container justify='center'>
        <TextField
          value={state.resolution}
          name='resolution'
          type='number'
          label='Resolution(px)'
          variant='standard'
          onChange={(evt) => onChangeSettings({ name: evt.target.name, value: evt.target.value })}
        />
        <Box width='100%' />
        <Box my={2} />

        <Grid item style={{ width: 200 }}>
          <Slider
            value={slider}
            name='quality'
            min={0.1}
            max={1}
            step={0.01}
            valueLabelDisplay='auto'
            onChange={(evt, value) => {
              setSlider(Number(value))
            }}
            onChangeCommitted={(evt, value) => {
              onChangeSettings({ name: 'quality', value: value.toString() })
            }}
          />
        </Grid>
        <Box mx={1} />
        <Grid item style={{ width: 50, marginTop: -20 }}>
          <TextField value={slider} disabled name='quality' type='number' label='Quality' variant='standard' />
        </Grid>
      </Grid>
      <Box my={2} />
      <Grid container wrap='wrap' alignItems='flex-start' justify='center'>
        {image && (
          <Box m={2}>
            <Link
              onClick={() => {
                window.open(image, '_blank')
              }}
              target='_blank'
            >
              <img src={image ?? ''} width={200} />
            </Link>
            <Box my={2} />
            <Box>
              {' '}
              Исходная картинка <br /> {state.selectedFile?.size} bytes
            </Box>
          </Box>
        )}
        {optimizedImage && (
          <Box m={2}>
            <Link
              onClick={() => {
                window.open(optimizedImage, '_blank')
              }}
              target='_blank'
            >
              <img src={optimizedImage ?? ''} width={200} />
            </Link>
            <Box my={2} />
            <Box>
              Сжатая картинка <br /> {optimizedImageOps.loading && 'Расчет...'}
              {optimizedImageOps.value && !optimizedImageOps.loading && `${optimizedImageOps.value?.size} bytes`}
            </Box>
          </Box>
        )}
      </Grid>
      <Box my={2} />
      <Grid container wrap='wrap' justify='center'>
        <Button variant='contained' component='label'>
          Upload File
          <input type='file' accept='image/*' capture='environment' hidden onChange={onChangeFile} />
        </Button>
      </Grid>
    </Box>
  )
}

/**
 * @deprecated в фотках используем optimizeBlobImage из хука, оставляю только для Debug раздела
 */
async function makeOptimizedImage(
  imageBlob: Blob,
  resolution: number,
  quality = 0.4,
  contentType = 'image/jpeg',
): Promise<string> {
  const img = new Image()
  const blobLink = URL.createObjectURL(imageBlob)
  try {
    img.src = blobLink
    await img.decode()
  } finally {
    URL.revokeObjectURL(blobLink)
  }

  const canvas = document.createElement('canvas') as HTMLCanvasElement

  const ratioX = resolution / img.naturalWidth
  const ratioY = resolution / img.naturalHeight
  const ratio = Math.min(ratioX, ratioY)

  canvas.width = img.naturalWidth * ratio
  canvas.height = img.naturalHeight * ratio

  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
  ctx.drawImage(img, 0, 0, img.naturalWidth * ratio, img.naturalHeight * ratio)
  return canvas.toDataURL(contentType, quality)
}
