/* eslint-disable @typescript-eslint/indent */
import { checkPredicate, IPredicate } from '../../../../../model/script-predicate'
import { ITableCell, ITableScreenItem } from '../../../../../model/table-screen-item'
import { getPropertyAny, getContextProperty } from '../../../script-tasks/propertyName'
import { IScriptTaskContext } from '../../../script-tasks/script-task-context'
import { IQuantity } from '../../types'
import { IValidation } from '../validate'
import { ITableRecord } from './editorUtils'
import { checkNumericRangeError } from './number-editor'
import { filterTableRows } from './utils'

function checkIsVisible(
  predicate: IPredicate | undefined,
  record: ITableRecord,
  localContext: IScriptTaskContext,
): boolean {
  if (!predicate) {
    return true
  }
  return checkPredicate(predicate, record, localContext)
}

function checkIsRequired(
  predicate: IPredicate | undefined,
  record: ITableRecord,
  localContext: IScriptTaskContext,
): boolean {
  if (!predicate) {
    return false
  }
  return checkPredicate(predicate, record, localContext)
}

function validateValue(value: unknown): boolean {
  if (typeof value === 'string') {
    return !!value.trim().length
  }
  return value != null
}

function validateOldCell(cell: ITableCell, record: ITableRecord): boolean {
  const control = cell.control
  if ('required' in control && control.required === true) {
    const value = getPropertyAny(record, cell.propertyName)
    return validateValue(value)
  }
  return true
}

function validateTextDialogCell(cell: ITableCell, record: ITableRecord, localContext: IScriptTaskContext): boolean {
  const control = cell.control
  if (control.$type !== 'PMI.FACE.BDDM.Extensions.Classes.TextDialogCell') throw new Error('unreachable')

  const isRequired =
    checkIsVisible(control.visible, record, localContext) && checkIsRequired(control.required, record, localContext)
  if (isRequired) {
    const value = getPropertyAny(record, cell.propertyName)
    const res = validateValue(value)
    console.log('validating text dialog', value, res)
    return res
  }
  return true
}

function validateQuantityInputCell(cell: ITableCell, record: ITableRecord, localContext: IScriptTaskContext): boolean {
  const control = cell.control
  if (control.$type !== 'PMI.FACE.BDDM.Extensions.Classes.QuantityInputCell') throw new Error('unreachable')

  const isRequired =
    checkIsVisible(control.visible, record, localContext) && checkIsRequired(control.required, record, localContext)

  if (isRequired) {
    const entry = getPropertyAny(record, cell.propertyName) as IQuantity | undefined

    if (!entry) {
      return false
    }

    if (checkNumericRangeError(Number(entry.value), control.minValue, control.maxValue)) {
      return false
    }
  }
  return true
}

function validateIntegerInputCell(cell: ITableCell, record: ITableRecord, localContext: IScriptTaskContext): boolean {
  const control = cell.control
  if (control.$type !== 'PMI.FACE.BDDM.Extensions.Classes.IntegerInputCell') throw new Error('unreachable')

  const isRequired =
    checkIsVisible(control.visible, record, localContext) && checkIsRequired(control.required, record, localContext)

  if (isRequired) {
    const value = getPropertyAny(record, cell.propertyName) as number | string | undefined

    if (!value || value === '') {
      return false
    }

    if (checkNumericRangeError(Number(value), control.minValue, control.maxValue)) {
      return false
    }
  }
  return true
}

function validatePhotosDialogCell(cell: ITableCell, record: ITableRecord, localContext: IScriptTaskContext): boolean {
  const control = cell.control
  if (control.$type !== 'PMI.FACE.BDDM.Extensions.Classes.PhotosDialogCell') throw new Error('unreachable')

  const isRequired =
    checkIsVisible(control.visible, record, localContext) && checkIsRequired(control.required, record, localContext)
  if (isRequired) {
    const photos = getPropertyAny(record, cell.propertyName, []) as string[]

    const maxOccurs = control.maxPhotoCountAvailable ?? 5

    const res = photos.length >= 1 && maxOccurs >= photos.length
    return res
  }
  return true
}

function validateAdditionalCondition(item: ITableScreenItem, localContext: IScriptTaskContext): boolean {
  const predicate = item.additionalValidationCondition
  if (!predicate) {
    return true
  }
  return checkPredicate(predicate, undefined, localContext)
}

function validateTableItems(
  records: ITableRecord[],
  item: ITableScreenItem,
  localContext: IScriptTaskContext,
): boolean {
  for (const record of records) {
    for (const cell of item.cells ?? []) {
      switch (cell.control.$type) {
        case 'PMI.FACE.BDDM.Extensions.Classes.TextDialogCell': {
          if (!validateTextDialogCell(cell, record, localContext)) {
            return false
          }
          break
        }

        case 'PMI.FACE.BDDM.Extensions.Classes.PhotosDialogCell': {
          if (!validatePhotosDialogCell(cell, record, localContext)) {
            return false
          }
          break
        }

        case 'PMI.FACE.BDDM.Extensions.Classes.QuantityInputCell': {
          if (!validateQuantityInputCell(cell, record, localContext)) {
            return false
          }
          break
        }

        case 'PMI.FACE.BDDM.Extensions.Classes.IntegerInputCell': {
          if (!validateIntegerInputCell(cell, record, localContext)) {
            return false
          }
          break
        }

        default:
          if (!validateOldCell(cell, record)) return false
      }
    }
  }
  return true
}

export function validateTableScreenItem(item: ITableScreenItem, context: IScriptTaskContext): IValidation {
  const records = getContextProperty<ITableRecord[]>(context, item.propertyName, [])
  const filteredRecords = filterTableRows(records, item, context)
  if (!filteredRecords?.length) {
    if (!item.skipWhenEmpty) {
      return {
        isError: true,
        message: item.nullValueCaption,
      }
    }
  }
  // console.log('validating filtered records', item, filteredRecords, context)
  const isError = filteredRecords
    ? !(validateTableItems(filteredRecords, item, context) && validateAdditionalCondition(item, context))
    : false

  const message = item.errorHelperText
    ? item.errorHelperText
    : item.displayName
    ? `Не заполнены данные в таблице ${item.displayName}`
    : 'Не заполнены данные в таблице'

  return {
    isError,
    message: message,
  }
}
