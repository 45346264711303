import React, { useMemo } from 'react'

import { Box, Typography } from '@material-ui/core'

import { IScreenItem, isScreenItemGroup } from '../../../../model/screen-item'
import { ICompositeTaskResultScreen } from '../../../../model/task-result-screen'
import { useScrollToTop } from '../../../_common/hooks/useScrollToTop'
import { DocumentsScreenItemContainer } from '../../../documents/components/documents-screen-items/documents-screen-item-container'
import { ServiceAuditResultsScreenItemControl } from '../../audit/service-audit-results-screen-item'
import { ServiceAuditStatisticsScreenItem } from '../../audit/service-audit-statistics-screen-item'
import { DebugTree } from '../../debug-tree'
import { getContextProperty } from '../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../script-tasks/script-task-context'
import { BooleanViewScreenItemControl } from './boolean/boolean-view-screen-item'
import { HyperlinkItem } from './hyperlink-item/hyperlink-item'
import { IFrameScreenItem } from './iframe-item/iframe-screen-item'
import { LabelItemControl } from './label-item/label-item'
import { PredicateViewScreenItem } from './predicate-items'
import { RepeaterScreenItem } from './repeater-screen-item'
import { DictionaryItem } from './sev3-items/dictionary'
import { TaskDirections } from './sev3-items/task-directions'
import { SignatureImageStageItem } from './signature-image-item/signature-image-stage-item'
import { StringItem } from './string-item'
import { SurveyItemView } from './surveys/survey-item-view'
import { ViewSurveyScreenItem } from './surveys/view-survey-screen-item'
import { TableItem } from './table-item/table-item'
import { TextScreenItemControl } from './text-screen-item'
import { sortScreenItems } from './utils'

interface Props {
  screen: ICompositeTaskResultScreen
}

export const CompositeResultScreen: React.FC<Props> = ({ screen }) => {
  const propertiesContext = useScriptTaskContext()
  const renderItem = (item: IScreenItem): JSX.Element => {
    if (item.actionKind === 'Edit') {
      throw new Error(`unsupported actionKind 'Edit' on a result screen, item: ${item.displayName}`)
    }
    switch (item.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.StringPropertyScreenItem': {
        const key = item.propertyName
        const value = getContextProperty(propertiesContext, key, '')
        return <StringItem key={key} item={item} value={value} />
      }

      case 'PMI.FACE.BDDM.Extensions.Classes.TextScreenItem':
        return <TextScreenItemControl item={item} />

      case 'PMI.FACE.BDDM.Extensions.Classes.BooleanViewScreenItem':
        return <BooleanViewScreenItemControl item={item} />

      case 'PMI.FACE.BDDM.Extensions.Classes.SurveysPropertyScreenItem':
        return <SurveyItemView item={item} />

      case 'PMI.FACE.BDDM.Extensions.Classes.SalesExpertEducationTaskDirectionsScreenItem':
        return <TaskDirections item={item} />

      case 'PMI.FACE.BDDM.Extensions.Classes.DictionaryItemPropertyScreenItem':
        return <DictionaryItem item={item} entry={getContextProperty(propertiesContext, item.propertyName)} />

      case 'PMI.FACE.BDDM.Extensions.Classes.SignatureImageScreenItem': {
        return <SignatureImageStageItem item={item} isReadOnly={true} />
      }

      case 'PMI.FACE.BDDM.Extensions.Classes.PhotosPropertyScreenItem': {
        return <DocumentsScreenItemContainer entity={item} isReadOnly={true} />
      }

      case 'PMI.FACE.BDDM.Extensions.Classes.MediaContentListViewerScreenItem': {
        return <DocumentsScreenItemContainer entity={item} isReadOnly={true} />
      }

      case 'PMI.FACE.BDDM.Extensions.Classes.MediaContentListEditorScreenItem': {
        return <DocumentsScreenItemContainer entity={item} isReadOnly={false} />
      }

      case 'PMI.FACE.BDDM.Extensions.Classes.LabelScreenItem': {
        return <LabelItemControl item={item} />
      }

      case 'PMI.FACE.BDDM.Extensions.Classes.TableScreenItem': {
        return <TableItem item={item} isReadOnly={true} />
      }

      case 'PMI.FACE.BDDM.Extensions.Classes.ViewSurveyScreenItem':
        return <ViewSurveyScreenItem item={item} />

      case 'PMI.FACE.BDDM.Extensions.Classes.ServiceAuditResultsScreenItem':
        return <ServiceAuditResultsScreenItemControl item={item} />

      case 'PMI.FACE.BDDM.Extensions.Classes.ServiceAuditStatisticsScreenItem':
        return <ServiceAuditStatisticsScreenItem item={item} />

      case 'PMI.FACE.BDDM.Extensions.Classes.HyperlinkScreenItem':
        return <HyperlinkItem item={item} onChange={undefined} />

      case 'PMI.FACE.BDDM.Extensions.Classes.IframeScreenItem':
        return <IFrameScreenItem item={item} />

      case 'PMI.FACE.BDDM.Extensions.Classes.RepeaterScreenItem':
        return <RepeaterScreenItem item={item} />

      case 'PMI.FACE.BDDM.Extensions.Classes.PredicateViewScreenItem':
        return <PredicateViewScreenItem item={item} />

      default:
        return (
          <div>
            unimplemented type {String(item.$type)} for {item.displayName || item.propertyName}
          </div>
        )
    }
  }

  const sorted = useMemo(() => sortScreenItems(screen.items), [screen.items])
  useScrollToTop()

  return (
    <Box pt={0}>
      <DebugTree />
      {sorted.map((item) => {
        if (isScreenItemGroup(item)) {
          const group = item
          return (
            <div key={group.displayName}>
              <Box paddingX={3} paddingTop={1} paddingBottom={2} fontSize={22}>
                <Typography color='primary' variant='inherit'>
                  {group.displayName}
                </Typography>
              </Box>
              <Box marginX={3}>{group.items.map(renderItem)}</Box>
            </div>
          )
        } else {
          return (
            <Box key={item.propertyName} marginX={3} letterSpacing='0.15px'>
              {renderItem(item)}
            </Box>
          )
        }
      })}
    </Box>
  )
}
