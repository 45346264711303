import React, { useCallback, useState } from 'react'

import { Box, createStyles, IconButton, makeStyles, SvgIcon, Theme } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { isToday } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'

import { ReactComponent as PreviewIcon } from '../../../assets/icons/photo-preview.svg'
import { ShareButton } from '../../../components/share-button'
import { StageLoad, TStageLoad } from '../../../components/stage-load'
import { AppBar, Layout } from '../../../layout'
import { TitleBarText } from '../../../layout/app-bar'
import { BackButton } from '../../../layout/back-button'
import { grays } from '../../../layout/theme'
import { useGenerateReport } from '../../_common/hooks/useGenerateReport'
import { useIsSmall } from '../../_common/hooks/useIsSmall'
import { useShareReport } from '../../_common/hooks/useShareReport'
import { useTryUploadAll } from '../../_common/hooks/useTryUpload'
import { useFeatureRoute } from '../../custom-app-feature/app-feature-context'
import { visitsRoutes } from '../../visits/visits-app-feature/visits-app-feature'
import { TaskDescriptionDrawerMenu } from '../components'
import { useScriptTaskContext } from '../script-tasks/script-task-context'
import { TaskResultScreen } from '../template-tasks/task-result-screen'
import { useLocalContextService } from './local-context'
import { TaskProcessControl } from './task-process'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // appFrame: {
    //   ...getAppFrameStyle(theme),
    //   marginTop: theme.spacing(7)
    // },
    appBar: {
      // boxShadow: 'unset',
      // zIndex: theme.zIndex.drawer
      height: 84,

      [theme.breakpoints.down('xs')]: {
        height: 64,
      },
    },
    content: {
      marginTop: 84,
      paddingTop: 1,
      [theme.breakpoints.down('xs')]: {
        marginTop: 64,
      },
      // height: '100%',
    },
    smallBackButton: {
      width: 32,
      height: 32,
      padding: 2,
      marginRight: 8,
      marginLeft: -8,
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    descriptionIcon: {
      color: theme.palette.primary.main,
      lineHeight: '48px',
    },
  }),
)

interface IProps {
  onBack: () => void
}

// const logger = LogManager.getLogger('TaskDialog')

export const NonVisitTaskDialog: React.FC<IProps> = ({ onBack }) => {
  const tryUploadPendingTask = useTryUploadAll()

  const classes = useStyles()
  const propertiesContext = useScriptTaskContext()
  const contextService = useLocalContextService()
  const { task, template: taskTemplate } = propertiesContext
  const taskCode = task.code

  const isSmall = useIsSmall()

  const { t } = useTranslation(['sales-expert-tasks', 'task'])
  const featureRoute = useFeatureRoute()

  const onShareHandler = useShareReport(task, contextService.refetch)
  const onGenerateReport = useGenerateReport(task)

  const checkIsReadyStart = useCallback(async (): Promise<string | undefined> => {
    if (!isToday(propertiesContext.task.startDate)) {
      return 'Невозможно начать задачу, назначенную не на сегодня'
    }
  }, [])

  const onAfterFinishTask = useCallback(async (): Promise<void> => {
    await tryUploadPendingTask()
  }, [taskCode])

  const [stageLoading, setStageLoading] = useState<TStageLoad | null>(null)

  const onBeforeStartTask = (): void => {
    setStageLoading('Task')
  }
  const onAfterStartTask = (): void => {
    setStageLoading(null)
  }

  const readOnly = task.status === 'Canceled' || task.status === 'Finished'

  const renderAppBarIcons = (): JSX.Element => {
    return (
      <Box display='flex' flex={1} justifyContent='space-between'>
        <span className={classes.descriptionIcon}>
          <TaskDescriptionDrawerMenu
            posCode={''}
            taskTemplate={taskTemplate}
            instruction={taskTemplate.helpFileLink?.target}
            description={taskTemplate.description}
            title={t('taskDescriptionDrawerTitle')}
            buttonText={<InfoOutlinedIcon style={{ fontSize: 30 }} />}
          />
        </span>
        <Box //
          display={!readOnly || !taskTemplate.resultFile ? 'none' : 'flex'}
          mr={1}
          hidden={!readOnly || !taskTemplate.resultFile}
          color={grays.gray1}
        >
          <IconButton
            color='inherit'
            component={Link}
            to={generatePath(visitsRoutes.nonVisitTaskPreview, { featureRoute, taskCode })}
          >
            <SvgIcon component={PreviewIcon} />
          </IconButton>
          <ShareButton onShare={onShareHandler} />
        </Box>
      </Box>
    )
  }

  return (
    <Layout
      appBar={
        <AppBar className={classes.appBar}>
          <BackButton className={isSmall ? classes.smallBackButton : undefined} onBack={onBack} />
          <TitleBarText>{taskTemplate?.name}</TitleBarText>
          <Box marginLeft='auto'>{renderAppBarIcons}</Box>
        </AppBar>
      }
    >
      {stageLoading && <StageLoad stage={stageLoading} />}
      <div className={classes.content}>
        {!readOnly ? (
          <TaskProcessControl
            canTaskStart={checkIsReadyStart}
            onBeforeFinish={onGenerateReport}
            onTaskFinish={onAfterFinishTask}
            onBeforeStartTask={onBeforeStartTask}
            onAfterStartTask={onAfterStartTask}
          />
        ) : (
          <TaskResultScreen onBack={onBack} />
        )}
      </div>
    </Layout>
  )
}

export default NonVisitTaskDialog
