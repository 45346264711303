import React, { useEffect, useState } from 'react'

import { DialogContent, DialogContentText } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useTranslation } from 'react-i18next'

import { useWorkbox } from '../../utils/sw/useWorkbox'

const NewVersionModal: React.FC = () => {
  const [open, setOpen] = useState(true)

  const { hasNewVersion, update, version } = useWorkbox()
  useEffect(() => setOpen(hasNewVersion), [hasNewVersion])

  const handleOk = (): void => {
    update()
    setOpen(false)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const { i18n, t, ready } = useTranslation('service-worker')
  useEffect(() => {
    !ready && i18n.reloadResources(i18n.language, 'service-worker')
  }, [i18n.language])

  if (!ready) {
    return null
  }

  return (
    <Dialog
      disableScrollLock
      open={open}
      onClose={handleClose}
      aria-labelledby='new-version-dialog-title'
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id='alert-dialog-title'>{t('hasNewVersionTitle', { version })}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{t('hasNewVersionDescription')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk} color='primary' autoFocus>
          {t('confirmButton')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewVersionModal
