import React, { useEffect, useState } from 'react'

import { IScriptStep } from '../../../model/task-execution-process'
import { useScriptTaskContext } from '../script-tasks/script-task-context'
import { ScriptView } from '../script-tasks/script-view'

interface CaseScriptRunnerProps {
  children?: never
  scripts: IScriptStep[]
  onComplete: VoidFunction
  onError: (error: Error) => void
}

export const CaseScriptRunner: React.FC<CaseScriptRunnerProps> = (props) => {
  useEffect(() => {
    console.log('CaseScriptRunner mount', props)
  }, [])

  const { onError, onComplete, scripts } = props

  const [state, setState] = useState(0)
  const localContext = useScriptTaskContext()

  const currentStep = scripts[state]

  const script = localContext.template.scripts[currentStep.script.code]
  return (
    <ScriptView
      localContext={localContext}
      onError={onError}
      onSuccess={() => {
        if (state < scripts.length - 1) {
          setState((v) => v + 1)
        } else {
          onComplete()
        }
      }}
      script={script}
    />
  )
}
