import { useContext, useEffect } from 'react'

import MonacoEditor, { loader, useMonaco } from '@monaco-editor/react'
import { useQuery } from '@tanstack/react-query'

import { ApiContext } from '../../../../providers'
import { getMonacoEditorOptions } from '../../../admin/_common/monaco-options'
import { BddmTypesApi } from '../../../admin/bddm-types/bddm-types-api'
import { transformTypes } from '../../../admin/bddm-types/transform-types'
import { Typespace } from '../../../admin/bddm-types/typespace'
import { AstPredicateLanguageService } from './lang-service/lang-service'
import { astPredicateLangSyntax } from './lang-service/lang-syntax'

void loader.init().then((monaco) => {
  monaco.languages.register({ id: AstPredicateLanguageService.LANGUAGE_ID })
})

interface Props {
  /** BDDM type names */
  types: string[]
  value: string
  onChange: (value: string) => void
  disabled?: boolean
}

export function AstPredicateInput(props: Props): JSX.Element {
  const { bddmTypesApi } = useServices()

  const monaco = useMonaco()

  const { data: bddmTypes } = useQuery({
    queryKey: ['bddm-types'],
    queryFn: async () => bddmTypesApi.getBddmTypes(),
    refetchOnMount: false,
  })

  useEffect(
    function setupLangService() {
      if (!monaco) {
        return
      }

      if (!bddmTypes) {
        return
      }

      const typespace = new Typespace(transformTypes(bddmTypes))
      const langService = new AstPredicateLanguageService(typespace, props.types)

      const syntaxReg = monaco.languages.setMonarchTokensProvider(
        AstPredicateLanguageService.LANGUAGE_ID,
        astPredicateLangSyntax,
      )

      const completionReg = monaco.languages.registerCompletionItemProvider(
        AstPredicateLanguageService.LANGUAGE_ID,
        langService.completionProvider,
      )

      return () => {
        syntaxReg.dispose()
        completionReg.dispose()
      }
    },
    [monaco, bddmTypes, props.types],
  )

  return (
    <MonacoEditor
      options={getMonacoEditorOptions({
        lineNumbers: 'off',
        lineDecorationsWidth: 0,
        folding: false,
        fixedOverflowWidgets: true,
        readOnly: props.disabled,
        fontFamily: 'Lato',
        fontSize: 16,
        renderLineHighlight: 'none',
        scrollbar: {
          useShadows: false,
          verticalScrollbarSize: 8,
        },
        wrappingIndent: 'indent',
        wrappingStrategy: 'advanced',
      })}
      value={props.value}
      onChange={(value) => props.onChange(value ?? '')}
      language={AstPredicateLanguageService.LANGUAGE_ID}
    />
  )
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function useServices() {
  const { _httpClientFactory } = useContext(ApiContext)

  return {
    bddmTypesApi: new BddmTypesApi(_httpClientFactory.getHttpClient()),
  }
}
