import React from 'react'

import { Box, Theme, Typography, createStyles, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      padding: 8,
      cursor: 'pointer',
    },
    selected: {
      color: theme.palette.primary.main,
      background: theme.palette.action.selected,
      cursor: 'default',
    },
  }),
)

interface IProps {
  id: string
  title: string
  isSelected: boolean
  handleClick: (val: string) => void
}

const TabItem: React.FC<IProps> = ({ id, title, isSelected, handleClick }) => {
  const classes = useStyles()

  return (
    <Box className={`${classes.item} ${isSelected ? classes.selected : ''}`} onClick={() => handleClick(id)}>
      <Typography>{title}</Typography>
    </Box>
  )
}

export default TabItem
