/* eslint-disable @typescript-eslint/method-signature-style */

import { IKeyValuePair } from '../../model/data-source'
import { EncryptionSettingsReference } from '../../model/encryption-settings'
import { InjectionToken } from '../di'

export interface IEncryptionService {
  /**
   * Шифрует переданные данные для дальнейшей отправки
   */
  encryptKeyValuePairs(request: EncryptKeyValuePairsRequest): Promise<IKeyValuePair[]>

  getEncryptionSettingsRefs(): Promise<EncryptionSettingsReference[]>
}

export const ENCRYPTION_SERVICE_API = new InjectionToken<IEncryptionService>('encryption-service-api')

export interface EncryptKeyValuePairsRequest {
  items: IKeyValuePair[]
  encryption: EncryptionSettingsReference
}
