/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useContext, useEffect} from 'react'

import {Box, Button, createStyles, Typography} from '@material-ui/core'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {format} from 'date-fns'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {useAsync, useAsyncFn, useNetworkState} from 'react-use'

import {PageContent} from '../../layout/page-content'
import {TitleBar} from '../../layout/title-bar'
import {ApiContext, MaintenanceContext, SyncContext} from '../../providers'
import {usePendingItems} from '../../providers/menu-data/pending-items-store'
import {formatDuration} from '../../utils/duration'
import {SendDumpButton} from '../dump-page'
import {ErrorPage} from '../error-page'
import {ItemCard} from '../tasks/template-tasks/composite-screen/item-card'
import {SyncErrorList} from "./sync-error-list";
import {ISyncAutoLocationState} from './sync-location-types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageContainer: {
      padding: 24,
      [theme.breakpoints.down('xs')]: {
        padding: 18,
      },
    },
    row: {
      display: 'grid',
      gridTemplateColumns: '38fr 62fr',
      columnGap: 10,
      marginBottom: 16,
      '&:last-child': {
        marginBottom: 0,
      },
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    btnWrapper: {
      position: 'absolute',
      right: 7,
      top: 95,
      [theme.breakpoints.down('xs')]: {
        right: -6,
        top: 88,
      },
    },
    rowBtn: {
      display: 'flex',
      justifyContent: 'center',
    },
    syncButton: {
      height: 40,
      width: 172,
      borderRadius: 28,
      marginTop: 20,
    },
  }),
)

const SyncPage: React.FC<{ onSyncSuccess?: VoidFunction }> = ({onSyncSuccess}) => {
  const {t, i18n} = useTranslation(['sync', 'menu'])
  const navigate = useNavigate()
  const sync = useContext(SyncContext)
  const classes = useStyles()
  const networkState = useNetworkState()
  const isOffline = !networkState.online
  const api = useContext(ApiContext)
  const {maintenance} = useContext(MaintenanceContext) ?? {}

  const {setPendingItems: setIsPending, isPending, pendingPhotoCount} = usePendingItems()

  const [changedDataOps, runCount] = useAsyncFn(
    async () => {
      const summary = await sync.syncService.countPendingItems()
      const {problemCount, taskCount, visitCount} = summary

      const res = problemCount + taskCount + visitCount
      setIsPending(Boolean(res))
      return summary
    },
    [sync?.syncService],
    {loading: true},
  )

  useEffect(() => {
    if (sync?.syncService) {
      void runCount()
    }
  }, [sync?.syncService])

  const onSyncHandler = async (): Promise<void> => {
    const state: ISyncAutoLocationState = {
      doNotLogoff: true,
    }
    navigate('/sync-auto', {state})
  }

  const getDataInfo = (): string => {
    if (changedDataOps.loading && isPending) {
      return t('pendingChanges')
    }
    if (!isPending) {
      return t('noPendingChanges')
    }
    const { problemCount, taskCount, visitCount } = changedDataOps.value!
    const details = [
      visitCount && t('countVisit', { count: visitCount }),
      taskCount && t('countTask', { count: taskCount }),
      problemCount && t('countProblem', { count: problemCount }),
    ]
      .filter((v) => v)
      .join(', ')
    return details
  }

  const getPhotoInfo = (): string => {
    if (!pendingPhotoCount) {
      return t('allPhotoDownload')
    }
    return `${t('countPhotoOrder')}: ${pendingPhotoCount}`

  }

  const data = useAsync(async () => {
    const [start, end] = await Promise.all([api.userProfile.getLastSyncAttempt(), api.userProfile.getLastSyncSuccess()])
    return {start, end}
  }, [api?.userProfile])

  if (data.error) {
    return <ErrorPage errorMessage={data.error.message}/>
  }
  if (data.loading && !data.value) return <></>

  const {start, end} = data.value!
  const lastSyncDate = format(end!, 'dd.MM.yyyy, HH:mm:ss')
  const isDurationValid = start && start.valueOf() <= end!.valueOf()
  const duration = end && isDurationValid ? `(${t('duration')} ${formatDuration({start, end}, i18n.language)})` : ''

  return (
    <>
      <TitleBar>{t('sync', {ns: 'menu'})}</TitleBar>
      <PageContent className={classes.pageContainer}>
        <ItemCard label={t('sendingData')}>
          <Box className={classes.btnWrapper}>
            <SendDumpButton isDisabled={isOffline}/>
          </Box>
          <Box>
            <Box className={classes.row}>
              <Typography color='textSecondary'>
                {t('noSendData')}
              </Typography>
              <Typography>{getDataInfo()}</Typography>
            </Box>
            {
              !isOffline &&
              <Box className={classes.row}>
                <Typography color='textSecondary'>
                  {t('lastSync')}
                </Typography>
                <Typography>{`${lastSyncDate} ${duration}`}</Typography>
              </Box>
            }
            {
              (isOffline || maintenance || !isDurationValid) &&
              <Box className={classes.row}>
                <Typography color='textSecondary'>
                  {t('info')}
                </Typography>
                <Typography>{isOffline ? t('noSyncOffline') : maintenance ? t('maintenance') : t('syncError')}</Typography>
              </Box>
            }
          </Box>
          <div className={classes.rowBtn}>
            <Button
              className={classes.syncButton}
              disabled={isOffline}
              size='large'
              type='submit'
              variant='contained'
              color='primary'
              onClick={onSyncHandler}
            >
              {t('syncAction')}
            </Button>
          </div>
        </ItemCard>

        <ItemCard label={t('loadPhoto')}>
          <Typography color='textSecondary'>
            {getPhotoInfo()}
          </Typography>
          <Typography color='textSecondary'>
            {t('backgroundPhoto')}
          </Typography>
        </ItemCard>

        <SyncErrorList retrySyncData={runCount}/>
      </PageContent>
    </>
  )
}

export default SyncPage
