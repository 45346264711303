import { ICancellationToken, OperationCancelledException } from './cancellation'

export interface RetryPolicy {
  retryCount: number
  errorFilter?: (e: unknown) => boolean
  onRetry?: (e: unknown, errorName: string, errorMessage: string, attempt: number) => Promise<void>
}

export async function retry<T>(
  action: (attempt: number, ct?: ICancellationToken) => Promise<T>,
  policy: RetryPolicy, 
  ct?: ICancellationToken
): Promise<T> {
  for (let attempt = 0; attempt <= policy.retryCount; attempt++) {
    if (ct?.isCancellationRequested) {
      throw new OperationCancelledException()
    }
    const isLastAttempt = (attempt === policy.retryCount)
    try {
      return await action(attempt, ct)
    } catch (e) {
      if (e instanceof OperationCancelledException) {
        throw e
      }
      if (isLastAttempt || policy.errorFilter?.(e) === false) {
        throw e
      }
      if (policy.onRetry) {
        await policy.onRetry(e,  e?.name ?? '', e?.message?.toLowerCase() ?? '', attempt)
      }
    }
  }
  throw new Error('Retry unexpected error')
}