import React, { useContext, useEffect, useState } from 'react'

import { Paper, Container, Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { useNetworkState } from 'react-use'

import { LogManager } from '../../infrastructure/logger'
import { AuthContext } from '../../providers'
import { SendDumpButton } from '../dump-page'
import FooterVersion from './footer-version'
import { LoginPageNoNetwork } from './login-page-no-network'
import { MaintenanceMarker } from './maintenance-marker'

const PAGE_BACKGROUND_COLOR = 'white'
const CLICK_TIMEOUT_MILLISECONDS = 500

const useStyles = makeStyles((theme) => ({
  wrap: {
    position: 'relative',
    height: 'calc(100% - 80px)',
    minHeight: 600,
    background: PAGE_BACKGROUND_COLOR,
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: '#3182CE',
    },
    '&::after': {
      opacity: 0.5,
    },
    '&::before': {
      opacity: 0.6,
      backgroundImage: 'url("/mos-city-bg.jpeg")',
      backgroundSize: 'cover',
      backgroundPosition: 'right center',
      [theme.breakpoints.down('xs')]: {
        backgroundPosition: '61% center',
      },
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 320,
    },
  },
  root: {
    position: 'relative',
    zIndex: 1,
    maxWidth: 340,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 244,
    },
  },
  paper: {
    width: '100%',
    paddingTop: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 40,
    minHeight: 290,
    [theme.breakpoints.down('xs')]: {
      // height: 244,
      minHeight: 244,
      paddingTop: 30,
    },
  },
  formWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  actionsContainer: {
    display: 'grid',
    gap: '16px',
    justifyItems: 'center',
    margin: '30px 0 44px',
    [theme.breakpoints.down('xs')]: {
      margin: '20px 0 20px',
    },
  },
  submit: {
    height: 40,
    width: 'fit-content',
    whiteSpace: 'nowrap',
    borderRadius: 28,
  },
  logo: {
    width: 250,
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      width: 170,
    },
  },
}))

const logger = LogManager.getLogger('LoginPageBySso')

const LoginPageBySso: React.FC = () => {
  const { login, authOptions, selectOption } = useContext(AuthContext)
  const networkState = useNetworkState()
  const isOffline = !networkState.online
  const classes = useStyles()
  const [showOptions, setShowOptions] = useState(false)

  const [isDumpModal, setIsDumpModal] = useState(false)
  useEffect(() => {
    setIsDumpModal(false)
  }, [isOffline])

  const onLoginHandler = async (): Promise<void> => {
    try {
      if (login) {
        await login()
      }
    } catch (e) {
      logger.error('onLoginHandler', 'Ошибка входа', e)
    }
  }

  const handleMainClick = async (): Promise<void> => {
    if (authOptions.length > 1) {
      setShowOptions(true)
    } else {
      void onLoginHandler()
    }
  }

  if (isOffline) {
    return <LoginPageNoNetwork />
  }

  let touchCount = 0
  const logoClickHandler = (evt: React.MouseEvent<HTMLElement>): void => {
    evt.preventDefault()
    touchCount += 1

    setTimeout(function () {
      touchCount = 0
    }, CLICK_TIMEOUT_MILLISECONDS)

    if (touchCount === 3) {
      setIsDumpModal(true)
    }
  }

  return (
    <>
      <Box className={classes.wrap}>
        <Container disableGutters fixed className={classes.root} component='main'>
          <Paper elevation={3} className={classes.paper}>
            <Container fixed className={classes.formWrap} component='section' maxWidth='xs'>
              <img
                className={classnames('Header-logo', classes.logo)}
                src='/pmi-logo-login-new.png'
                alt='Philip Morris logo'
                onClick={logoClickHandler}
              />
              <MaintenanceMarker />

              <div className={classes.actionsContainer}>
                {!showOptions ? (
                  <Button
                    size='large'
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    className={classes.submit}
                    onClick={handleMainClick}
                  >
                    Войти
                  </Button>
                ) : (
                  <>
                    {authOptions.map((option) => (
                      <Button
                        key={option.key}
                        size='large'
                        type='submit'
                        fullWidth
                        variant='contained'
                        color='primary'
                        className={classes.submit}
                        onClick={async () => {
                          await selectOption(option.key!)
                          await onLoginHandler()
                        }}
                      >
                        {option.displayName ?? 'Войти'}
                      </Button>
                    ))}
                  </>
                )}
              </div>
              <SendDumpButton isInitModal={isDumpModal} onCloseModal={setIsDumpModal} isHide={true} />
            </Container>
          </Paper>
        </Container>
      </Box>
      <FooterVersion />
    </>
  )
}

export default LoginPageBySso
