import React from 'react'

import { SvgIconProps } from '@material-ui/core'
import { useNetworkState } from 'react-use'

import { ReloadIcon } from './reload'
import { SyncDisabledIcon } from './sync-disabled'

export const SyncIcon: React.FC<SvgIconProps> = (props) => {
  const networkState = useNetworkState()
  if (networkState.online) {
    return <ReloadIcon {...props} />
  } else {
    return <SyncDisabledIcon {...props} />
  }
}
