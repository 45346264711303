import React, { useContext, useMemo } from 'react'

import { Box, CircularProgress, createStyles, makeStyles, Typography } from '@material-ui/core'
import { useQuery } from '@tanstack/react-query'

import { JustifyFlexLayoutContent } from '../../../../../model/composite-screen-item-layout'
import { ApiContext } from '../../../../../providers'
import { useIsSmall } from '../../../../_common/hooks/useIsSmall'
import { ItemCard } from '../item-card'
import { IContentDocumentPopupViewScreenItem } from './content-document-popup-view-screen-item'
import { IContentDocumentViewScreenItem } from './content-document-view-screen-item'

const useStyles = makeStyles((theme) =>
  createStyles({
    label: {
      fontSize: 18,
      lineHeight: '23px',
      fontWeight: 600,
      letterSpacing: '0.15px',
      position: 'relative',
      padding: '16px 24px',
      [theme.breakpoints.down('xs')]: {
        padding: '14px 16px',
        fontSize: 16,
      },
    },
  }),
)
interface Props {
  item: IContentDocumentPopupViewScreenItem | IContentDocumentViewScreenItem
  imageCode: string
  onClick: () => void
  isModal?: boolean
  cursorPointer?: boolean
  showDisplayName?: boolean
}
const ContentDocumentView: React.FC<Props> = ({
  item,
  onClick,
  imageCode,
  isModal,
  cursorPointer = true,
  showDisplayName,
}) => {
  const classes = useStyles()
  const { richtext: api } = useContext(ApiContext)

  const { borderRadius, desktopLayout, mobileLayout } = item
  const imgUrl = `content-documents/v1.0/${imageCode}/get-target`

  const { data, isLoading, isError } = useQuery({
    queryKey: ['content-document-view', imageCode],
    queryFn: async () => api.getImage(imgUrl),
    retry: false,
    suspense: false,
    onError: (error) => {
      console.log(error)
    },
  })

  const isSmall = useIsSmall()
  const { width, horizontalAlignment } = useMemo(
    () => (isSmall ? mobileLayout ?? {} : desktopLayout ?? {}),
    [isSmall, mobileLayout, desktopLayout],
  )

  if (isLoading) {
    return (
      <Box display='flex' alignItems='center' justifyContent='center' mb={1}>
        <CircularProgress />
      </Box>
    )
  }

  if (isError) {
    return <ItemCard>Изображение недоступно. {imageCode}</ItemCard>
  }

  return (
    <>
      <Box display='flex' flexDirection='column'>
        {showDisplayName && (
          <Box display='flex' alignItems='center' justifyContent={JustifyFlexLayoutContent[horizontalAlignment]}>
            <Typography
              variant='inherit'
              style={{
                width: `${width}%`,
              }}
              className={classes.label}
            >
              {item.displayName}
            </Typography>
          </Box>
        )}
        <Box display='flex' alignItems='center' justifyContent={JustifyFlexLayoutContent[horizontalAlignment]} mb={1}>
          <img
            onClick={onClick}
            style={{
              width: `${isModal ? 100 : width}%`,
              objectFit: 'cover',
              verticalAlign: 'top',
              borderRadius: `${isModal ? 0 : borderRadius}px`,
              cursor: cursorPointer ? 'pointer' : '',
            }}
            src={data}
            alt='Не удалось загрузить изображение'
          />
        </Box>
      </Box>
    </>
  )
}

export default ContentDocumentView
