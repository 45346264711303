/* eslint-disable @typescript-eslint/indent */
import { isAfter, startOfDay, startOfMonth, startOfWeek } from 'date-fns'

import { ITask, TaskStatus } from '../../model/task'
import { SessionViewMode } from '../tasks/template-tasks/utils'

export function sortByTaskStatus(a: ITask, b: ITask): number {
  if (!a.status || !b.status) {
    return -1
  }
  // Задачи сортируются по статусу (сначала "выполняется", потом "запланирована", потом "завершена")
  const itemOrder: TaskStatus[] = ['InProgress', 'Planned', 'Finished', 'Canceled']
  return itemOrder.indexOf(a.status) - itemOrder.indexOf(b.status)
}

export function sortByCode(a: ITask, b: ITask): number {
  if (!a.template.version?.code || !b.template.version?.code) {
    return -1
  }
  // Задачи должны сортироваться по идентификатору (по возрастанию)
  return a.template.version.code.localeCompare(b.template.version.code)
}

export const filterTasksByPeriod =
  (period: SessionViewMode) =>
  (item: ITask): boolean => {
    const TODAY = Date.now()
    const targetDate = startOfDay(item.creationTime ?? item.updateTime)
    switch (period) {
      case 'last-month':
        return isAfter(targetDate, startOfMonth(TODAY))
      case 'last-week':
        return isAfter(targetDate, startOfWeek(TODAY))
      case 'all':
        return true
    }
  }

export function filterTaskByQuery(task: ITask, filterQuery: string): boolean | undefined {
  return `
  ${task.name ? task.name : ''}`
    .trim()
    .toLowerCase()
    .includes(filterQuery.trim().toLowerCase())
}
