import { makeImageThumbnail, makePdfThumbnail } from '../../utils/thumbnail'
import { BlobMetadata } from './blob-storage-api'

export async function generateThumbnail(blob: Blob, metadata: BlobMetadata): Promise<void> {
  if (blob.type.startsWith('image/')) {
    try {
      metadata.thumbnail = await makeImageThumbnail(blob, 200, 200)
    } catch (e) {
      console.warn(`Failed generating thumbnail: ${e}`)
    }
  } else if (blob.type.startsWith('application/pdf')) {
    try {
      metadata.thumbnail = await makePdfThumbnail(blob, 200, 200)
    } catch (e) {
      console.warn(`Failed generating thumbnail: ${e}`)
    }
  }
}