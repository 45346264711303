import React, { useCallback, useEffect, useState } from 'react'

import { Box, createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { getAppFrameStyle } from '../../../../assets/pm-shared-styles'
import { PageContent } from '../../../../layout/page-content'
import { TitleBar } from '../../../../layout/title-bar'
import SearchInput from '../../../tasks/sales-expert/tabs/session/search-input'
import { IsaReportList } from './components'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appFrame: getAppFrameStyle(theme),
    root: {
      padding: theme.spacing(3, 3),
    },
  }),
)

const IsaReportPage: React.FC = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useState<string>('')

  const onBackHandler = useCallback((): void => navigate(-1), [])
  const onSearchHandler = useCallback((queryString) => setSearchQuery(queryString), [])

  const { i18n, t, ready } = useTranslation(['reports', 'common'])
  useEffect(() => {
    !ready && i18n.reloadResources(i18n.language, 'reports')
  }, [ready])

  return (
    <div className={classes.appFrame}>
      <TitleBar onBack={onBackHandler}>{t('isaReportsTitle')}</TitleBar>
      <PageContent>
        <div className={classes.root}>
          <Box>
            <SearchInput query={searchQuery} onChange={onSearchHandler} placeholder={t('search', { ns: 'common' })} />
          </Box>
          <Box mt={3}>
            <IsaReportList searchQuery={searchQuery} />
          </Box>
        </div>
      </PageContent>
    </div>
  )
}

export default IsaReportPage
