import React from 'react'

import { createStyles, Theme, makeStyles } from '@material-ui/core'

import { IPointOfSale } from '../../../../model/pos'
import { ITask } from '../../../../model/task'
import { dateFormat } from '../../../../utils'
import { getSurveysByPropertyName } from '../../../_common/hooks/useCreateSurvey'
import { getPosAddress } from '../../../_common/pos'
import { DebugTree } from '../../debug-tree'
import { useScriptTaskContext } from '../../script-tasks/script-task-context'
import { SurveyPreview } from '../survey-preview'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: {
      fontStyle: 'italic',
    },
    comment: {
      marginTop: 3,
      letterSpacing: -0.25,
      fontSize: '0.75em',
      color: 'grey',
    },
    groupDivider: {
      backgroundColor: '#f2f2f2',
    },
    title: {
      textAlign: 'center',
      fontSize: '1.5em',
      fontWeight: 'bold',
    },
    label: {
      fontSize: '1.4em',
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    root: {
      padding: theme.spacing(4),
      fontSize: 18,
      '& table': {
        borderCollapse: 'collapse',
        width: '100%',
      },
      '& table th, & table td': {
        border: '1px solid black',
        padding: 2,
      },
    },
  }),
)

interface IProps {
  task: ITask
  pos: IPointOfSale | null
}

export const PreviewPdfSimple: React.FC<IProps> = ({ task, pos }) => {
  const classes = useStyles()

  const context = useScriptTaskContext()
  const surveys = getSurveysByPropertyName('task.surveys', context)
  const questionnaire = context.questionnaires[0]
  const survey = surveys[0]

  console.log('PreviewPdfSimple', { questionnaire, survey })

  return (
    <div className={classes.root}>
      <DebugTree />
      <div className={classes.title}>РЕЗУЛЬТАТЫ ПРОВЕРКИ ГОТОВНОСТИ ТОРГОВОЙ ТОЧКИ</div>
      <div className={classes.label}>Основная информация</div>
      <table>
        {[
          ['Дата', dateFormat(task.updateTime, 'dd.MM.yyyy')],
          ['Код торговой точки', pos?.code],
          ['Название торговой точки', pos?.name ?? ''],
          ['Адрес торговой точки', getPosAddress(pos)],
        ].map((row, i) => (
          <tr key={i}>
            {row.map((cell, i) => (
              <td key={i}>{cell}</td>
            ))}
          </tr>
        ))}
      </table>
      <div className={classes.label}>Вопросы</div>
      <SurveyPreview survey={survey} questionnaire={questionnaire} />
    </div>
  )
}
