import React, { useContext, useState } from 'react'

import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  OutlinedInput,
  SvgIcon,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import classnames from 'classnames'
import { get } from 'lodash'

import { ReactComponent as PencilIcon } from '../../../../../assets/icons/pencil.svg'
import { checkPredicate } from '../../../../../model/script-predicate'
import { useUpdateProperty } from '../../../nested/useUpdateProperty'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { CellWrapperProps, EditorItemProps } from './editorUtils'
import { TableItemContext } from './table-item'

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      padding: 0,
    },
    error: {
      color: theme.palette.error.main,
    },
    dialogContent: {
      width: 400,
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
      },
    },
  }),
)

const TextDialogCellControl: React.FC<EditorItemProps> = ({ col, row, i }) => {
  const classes = useStyles()
  if (col.control.$type !== 'PMI.FACE.BDDM.Extensions.Classes.TextDialogCell') throw new Error('unreachable')
  const fieldValue = get(row, col.propertyName ?? '', undefined) as string
  const updateProperty = useUpdateProperty()
  const tableItem = useContext(TableItemContext)
  const localContext = useScriptTaskContext()

  let isRequired = false
  if (col.control.required) {
    switch (col.control.required.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.ScriptPredicate':
        isRequired = checkPredicate(col.control.required, row, localContext)
        break
      case 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate':
        isRequired = col.control.required.value
    }
  }

  const [isModalOpen, setModalOpen] = useState(false)
  const openModal = (): void => {
    setValue(fieldValue)
    setModalOpen(true)
  }
  const closeModal = (): void => setModalOpen(false)

  const [value, setValue] = useState(fieldValue)

  const isError = isRequired ? !fieldValue : false

  return (
    <>
      <Dialog open={isModalOpen} onClose={closeModal}>
        <DialogTitle>{col.control.showDialogCaption ?? 'Комментарий'}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <OutlinedInput
            autoFocus
            value={value}
            onChange={(evt) => {
              setValue(evt.target.value)
            }}
            fullWidth
            rows={6}
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={closeModal}>
            Отменить
          </Button>
          <Button
            color='primary'
            onClick={() => {
              void updateProperty(`${tableItem.propertyName}.${i}.${col.propertyName}`, value)
              closeModal()
            }}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
      {!fieldValue ? (
        <Button
          className={classnames(classes.button, { [classes.error]: isError })}
          color={'primary'}
          startIcon={<AddIcon />}
          onClick={openModal}
        >
          {col.control.showDialogCaption ?? 'Комментарий'}
        </Button>
      ) : (
        <Box>
          {fieldValue}
          <IconButton
            onClick={openModal}
            edge='end'
            className={classes.button}
            color='primary'
            aria-label='change-comment'
          >
            <SvgIcon component={PencilIcon} />
          </IconButton>
        </Box>
      )}
    </>
  )
}

export const TextDialogCell: React.FC<EditorItemProps & CellWrapperProps> = ({ variant, ...props }) => {
  const { col, row } = props
  const localContext = useScriptTaskContext()
  if (col.control.$type !== 'PMI.FACE.BDDM.Extensions.Classes.TextDialogCell') throw new Error('unreachable')

  if (col.control.visible) {
    switch (col.control.visible.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.ScriptPredicate':
        const isVisible = checkPredicate(col.control.visible, row, localContext)
        // console.log({isVisible, isComplied: row.isComplied})
        if (!isVisible) {
          return <Box></Box>
        }
        break
      case 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate':
        if (!col.control.visible.value) {
          return <Box></Box>
        }
    }
  }

  switch (variant) {
    case 'table':
      return <TextDialogCellControl {...props} />
    case 'portrait':
    case 'extended-row':
      return (
        <Box>
          <TextDialogCellControl {...props} />
        </Box>
      )
  }
}
