/* eslint-disable @typescript-eslint/method-signature-style */

import { InjectionToken } from '../infrastructure/di'
import type { EntityKey, IEntity, IEntityReference } from '../model/base'
import type { IExecutionProcessSource } from '../model/execution-process-source'
import type { InlineFaceScript } from '../model/face-script'
import type { IReportProcessor } from '../model/report-processor'
import type { ITaskResultScreen } from '../model/task-result-screen'
import type { IAvailableTaskCancelationReason, ITaskTemplate } from '../model/task-template'
import type { ITaskTemplateModel } from '../model/task-template-model'

export interface ITaskTemplatesService {
  getCurrentTaskTemplateVersions(): Promise<ITaskTemplateModel[]>

  getTaskTemplateVersions(templateKey: EntityKey): Promise<ITaskTemplateModel[]>

  getTaskTemplate(templateKey: EntityKey): Promise<ITaskTemplate>

  createTaskTemplate(data: TaskTemplateData): Promise<EntityKey>

  updateTaskTempate(templateKey: EntityKey, data: TaskTemplateData): Promise<void>

  copyTaskTemplate(templateKey: EntityKey): Promise<EntityKey>

  exportTaskTemplate(templateKey: EntityKey): Promise<IEntity[]>

  importTaskTemplate(unit: IEntity[]): Promise<void>

  createNewVersion(templateKey: EntityKey, params: NewVersionParams): Promise<EntityKey>

  updateExecutionProcess(templateKey: EntityKey, data: IExecutionProcessSource): Promise<IEntityReference>

  createExecutionSubprocess(templateKey: EntityKey, data: IExecutionProcessSource): Promise<IEntityReference>

  updateExecutionSubprocess(templateKey: EntityKey, processKey: EntityKey, data: IExecutionProcessSource): Promise<void>

  updateTaskResultScreen(templateKey: EntityKey, data: ITaskResultScreen): Promise<IEntityReference>

  updateTaskResultFile(templateKey: EntityKey, data: IReportProcessor): Promise<IEntityReference>

  updateTaskResultScreenBeforeScript(templateKey: EntityKey, script: InlineFaceScript): Promise<void>

  updateTaskResultFileBeforeScript(templateKey: EntityKey, script: InlineFaceScript): Promise<void>
}

export interface NewVersionParams {
  startDate: Date
  copyQuestionnaires: boolean
}

export interface TaskTemplateData
  extends Omit<
    ITaskTemplate,
    | 'version'
    | 'helpFileLink'
    | 'afterTaskCreateEvent'
    | 'process'
    | 'subProcesses'
    | 'resultScreen'
    | 'resultScreenScript'
    | 'resultFile'
    | 'resultFileScript'
    | 'scripts'
    | 'userStepScreens'
    | 'availableTaskCancelationReasons'
    | 'educationDirectionLasQuestionnaireCode'
    | 'educationDirectionLauQuestionnaireCode'
    | 'traineeSkillsQuestionnaireCode'
    | 'questionnaireCode'
    | 'preContactsQuestionnaireCode'
    | 'postContactsQuestionnaireCode'
  > {
  availableTaskCancelationReasons?: Array<
    ChangeFieldType<
      IAvailableTaskCancelationReason,
      'reason',
      Pick<IAvailableTaskCancelationReason['reason'], '$type' | 'code' | 'name'>
    >
  >
}

export type { IExecutionProcessSource }

export const TASK_TEMPLATE_API = new InjectionToken<ITaskTemplatesService>('task-template-api')

type ChangeFieldType<T, K extends keyof T, V> = Omit<T, K> & { [P in K]: V }
