import React from 'react'

import { createStyles, Paper, SvgIcon, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'

import { ReactComponent as ChevronRight } from '../../../assets/icons/ChevronRight.svg'
import { useFeatureRoute } from '../../custom-app-feature/app-feature-context'
import { reportsRoutes } from '../reports-app-feature/reports-app-feature'

type IReport = typeof reportList[number]

const reportList = [
  {
    id: 'sales-expert',
    path: reportsRoutes.salesExpertReportPage,
  },
  {
    id: 'isa',
    path: reportsRoutes.isaReportPage,
  },
  {
    id: 'sm',
    path: reportsRoutes.smReportPage,
  },
] as const

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reportItem: {
      cursor: 'pointer',
      display: 'flex',
      minWidth: 320,
      alignItems: 'center',
      padding: '7px 24px',
      marginBottom: theme.spacing(1),
      minHeight: theme.spacing(9),
      borderRadius: 12,
      boxShadow: '0px 3px 7px rgba(96, 97, 112, 0.13)',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
      },
    },
    nameWrap: {
      display: 'flex',
      alignItems: 'center',
    },
    name: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.1px',
    },
    icon: {
      marginRight: theme.spacing(2),
    },
    selectIcon: {
      width: 36,
      height: 36,
      padding: 0,
      marginLeft: 'auto',
    },
  }),
)

const ReportsList: React.FC = () => {
  const classes = useStyles()

  const { t } = useTranslation('reports')
  const featureRoute = useFeatureRoute()

  const renderReport = (report: IReport): JSX.Element => {
    const path = generatePath(report.path, { featureRoute })
    return (
      <Link key={report.id} to={path} state={{ reportName: t(report.id) }}>
        <Paper elevation={0} className={classes.reportItem}>
          <div className={classes.nameWrap}>
            {/*<DescriptionIcon className={classes.icon} />*/}
            <Typography className={classes.name} variant='h5' color='inherit'>
              {t(report.id)}
            </Typography>
          </div>
          <SvgIcon
            className={classes.selectIcon}
            component={ChevronRight}
            viewBox='0 0 36 36'
            color='primary'
            fontSize='large'
          />
        </Paper>
      </Link>
    )
  }

  return <>{reportList.map((report) => renderReport(report))}</>
}

export default ReportsList
