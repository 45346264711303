import React, { useCallback, useContext, useEffect, useState } from 'react'

import { Button, Dialog, DialogActions, DialogTitle, Grid, IconButton } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Fab from '@material-ui/core/Fab'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AddIcon from '@material-ui/icons/Add'
import classnames from 'classnames'
import { startOfDay } from 'date-fns'
import endOfDay from 'date-fns/endOfDay'
import isPast from 'date-fns/isPast'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { getFloatActionButtonStyle } from '../../../assets/pm-shared-styles'
import { ViewModeButton } from '../../../components/yandex-map-custom/components/view-mode-button'
import { PageContent } from '../../../layout/page-content'
import { grays } from '../../../layout/theme'
import { TitleBar } from '../../../layout/title-bar'
import { ADD_VISITS_ENABLED, SHOW_VISITS_ON_MAP } from '../../../model/user-profile'
import { ModalContext } from '../../../providers/modal'
import { useAvailableTemplateList } from '../../_common/hooks/useAvailableTemplateList'
import { useHasPermission } from '../../_common/hooks/useGetPpermission'
import { useFeatureRoute } from '../../custom-app-feature/app-feature-context'
import { VisitPeriodSwitcher, VisitsViewSwitcher } from '../components'
import { buildPeriodInitialByVariant } from '../components/visit-period-switcher/visit-period-switcher'
import { VisitSearchMobile } from '../components/visit-search-mobile/visit-search-mobile'
import { VisitSearch } from '../components/visit-search/visit-search'
import {
  ActivitiesListByDay,
  ActivitiesListByMonth,
  ActivitiesListByWeek,
  VisitPeriod,
  VisitsMapByDay,
  VisitsViewDayMode,
  VisitsViewMode,
} from '../index'
import { visitsRoutes } from '../visits-app-feature/visits-app-feature'
import { VisitPageUiContext } from './visits-page-ui-context'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 152,
      width: 'calc(100vw - 80px)',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        paddingTop: 168,
      },
    },
    wrapper: {
      padding: '0 24px 0 24px',
      flexGrow: 1,
      height: '100%',
    },
    addButton: {
      ...getFloatActionButtonStyle(theme),
      zIndex: 1299,
      paddingRight: 22,
      textTransform: 'none',
      [theme.breakpoints.down('xs')]: {
        right: '50%',
        transform: 'translateX(50%)',
        margin: '0 auto',
      },
    },
    wrapperModalButtons: {
      flexWrap: 'wrap',
      '& > button': {
        marginRight: 'auto',
      },
      '& > button:not(:first-child)': {
        marginLeft: 0,
      },
    },
    addIcon: {
      marginRight: theme.spacing(1),
    },
    viewModeDay: {
      color: theme.palette.common.black,
      fontWeight: 500,
      fontSize: 14,
      minWidth: 130,
      borderRadius: 40,
      height: 40,
      backgroundColor: '#F2F3F4',
      textTransform: 'none',
      padding: '10px 18px',
      [theme.breakpoints.down('xs')]: {
        minWidth: 52,
        height: 36,
        padding: '5px 3px',
      },
      '&:hover': {
        backgroundColor: '#F2F3F4',
      },
    },
    gridItem: {
      marginBottom: 8,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 0,
      },
    },
    periodSwitcher: {
      marginRight: 24,

      [theme.breakpoints.down('xs')]: {
        marginBottom: 12,
        order: -1,
        width: '100%',
        margin: '0',
      },
    },
    viewModeSwitcher: {
      // marginLeft: 24
    },
    buttonSearch: {
      // padding: 0
    },
    titleBar: {
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 24,
        right: 24,
        width: 131,
        height: 112,
        backgroundImage: 'url("/pmi-logo-title.png")',
        backgroundSize: 'contain',
      },
      [theme.breakpoints.down('xs')]: {
        '&::before': {
          content: 'none',
        },
      },
    },
    iconSearch: {
      color: grays.gray1,
    },
    pageContent: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
)

const ActivitiesPage: React.FC = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { uiState, uiStateMethods } = useContext(VisitPageUiContext)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'))

  const templatesOps = useAvailableTemplateList(['Assign', 'Proceed'])

  const nonVisitTasks = templatesOps.value?.filter((template) => template.nonVisitTask)

  const isNonVisitTasks = !!nonVisitTasks?.length
  const isAddVisitPermissions = useHasPermission(ADD_VISITS_ENABLED)

  function getCalendarType(): 'visit' | 'nonVisitTask' | 'choice' | undefined {
    switch (`${isNonVisitTasks}-${isAddVisitPermissions}`) {
      case 'true-true':
        return 'choice'
      case 'true-false':
        return 'nonVisitTask'
      case 'false-true':
        return 'visit'
    }
  }

  const calendarType = getCalendarType()

  const [isOpenMobileSearch, setIsOpenMobileSearch] = React.useState(false)

  const changeUrlParameter = (parameter: string, value: string): void => {
    const currentUrlParams = new URLSearchParams(window.location.search)
    currentUrlParams.set(parameter, value)
    navigate(window.location.pathname + '?' + currentUrlParams.toString())
  }

  const handleChangeViewMode = useCallback((newValue: VisitsViewMode): void => {
    const newPeriod = buildPeriodInitialByVariant(new Date(), newValue)
    uiStateMethods.setStartPeriodDate(newPeriod.start.valueOf())
    uiStateMethods.setEndPeriodDate(newPeriod.end.valueOf())
    uiStateMethods.setViewMode(newValue)

    changeUrlParameter('viewMode', newValue)

    newValue === 'day' && setIsOpenMobileSearch(false)
  }, [])

  const handleChangeViewDayMode = useCallback((newValue: VisitsViewDayMode): void => {
    uiStateMethods.setViewDayMode(newValue)
    changeUrlParameter('viewDayMode', newValue)
  }, [])

  const handlePeriodChange = useCallback((period: VisitPeriod): void => {
    uiStateMethods.setStartPeriodDate(period.start.valueOf())
    uiStateMethods.setEndPeriodDate(period.end.valueOf())

    changeUrlParameter('startPeriodDate', period.start.valueOf().toString())
  }, [])

  const { i18n, ready, t } = useTranslation('visits')
  useEffect(() => {
    !ready && i18n.reloadResources(i18n.language, 'visits')
  }, [ready])

  const isMapViewPermissions = useHasPermission(SHOW_VISITS_ON_MAP)

  useEffect(() => {
    if (!isMapViewPermissions) {
      handleChangeViewDayMode('list')
    }
  }, [isMapViewPermissions])

  const onSearchHandler = (queryString: string): void => setSearchQuery(queryString)

  const renderList = (): JSX.Element => {
    switch (uiState.viewMode) {
      case 'day':
        if (uiState.viewDayMode === 'list') {
          return (
            <>
              {/*{!isSmall && <VisitSearch query={searchQuery} onChange={onSearchHandler} />}*/}
              <ActivitiesListByDay
                periodStartDate={uiState.startPeriodDate}
                periodEndDate={uiState.endPeriodDate}
                filterQuery={searchQuery}
              />
            </>
          )
        } else {
          return (
            <VisitsMapByDay
              periodStartDate={uiState.startPeriodDate}
              periodEndDate={uiState.endPeriodDate}
              filterQuery={searchQuery}
              onChange={onSearchHandler}
            />
          )
        }

      case 'week':
        return (
          <>
            {!isSmall && <VisitSearch query={searchQuery} onChange={onSearchHandler} />}
            <ActivitiesListByWeek
              periodStartDate={uiState.startPeriodDate}
              periodEndDate={uiState.endPeriodDate}
              filterQuery={searchQuery}
            />
          </>
        )
      case 'month':
        return (
          <>
            {!isSmall && <VisitSearch query={searchQuery} onChange={onSearchHandler} />}
            <ActivitiesListByMonth
              periodStartDate={uiState.startPeriodDate}
              periodEndDate={uiState.endPeriodDate}
              query={searchQuery}
            />
          </>
        )
      default:
        return <span>Error: please check viewMode property in VisitPage component</span>
    }
  }

  const renderViewModeButtons = (): JSX.Element =>
    uiState.viewMode === 'day' ? (
      <ViewModeButton
        uiState={uiState.viewDayMode}
        onClick={() => {
          const currentViewDayMode = uiState.viewDayMode !== 'list' ? 'list' : 'map'
          handleChangeViewDayMode(currentViewDayMode)
        }}
      />
    ) : (
      <></>
    )

  const justify = isSmall ? 'center' : 'flex-start'

  const renderButtonSearch = (): JSX.Element | undefined => {
    if (isSmall) {
      return (
        <IconButton
          className={classes.buttonSearch}
          onClick={() => {
            setIsOpenMobileSearch(true)
          }}
        >
          <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='11' cy='11' r='7' stroke='#546066' strokeWidth='2' />
            <path d='M20 20L17 17' stroke='#546066' strokeWidth='2' strokeLinecap='round' />
          </svg>
        </IconButton>
      )
    }
  }

  return (
    <Box className={classes.root} minHeight='100%' display='flex' flexDirection='column'>
      {isSmall && (
        <VisitSearchMobile
          query={searchQuery}
          onChange={onSearchHandler}
          isOpen={isOpenMobileSearch}
          onClose={() => {
            setSearchQuery('')
            setIsOpenMobileSearch(false)
          }}
        />
      )}
      <TitleBar
        fixed
        icons={uiState.viewMode !== 'day' && renderButtonSearch()}
        additionalToolbar={
          <Box width='100%' className={classes.titleBar}>
            <Grid container alignItems='center' justify={justify}>
              <Grid item xs='auto' className={classes.gridItem}>
                <VisitsViewSwitcher currentViewMode={uiState.viewMode} onChangeViewMode={handleChangeViewMode} />
              </Grid>
              <Grid item xs='auto' className={classnames(classes.periodSwitcher, classes.gridItem)}>
                <VisitPeriodSwitcher
                  selectedStartDate={new Date(uiState.startPeriodDate)}
                  variant={uiState.viewMode}
                  onPeriodChange={handlePeriodChange}
                />
              </Grid>
              {uiState.viewMode === 'day' && isMapViewPermissions ? (
                <Grid item xs='auto' className={classnames(classes.viewModeSwitcher, classes.gridItem)}>
                  {renderViewModeButtons()}
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Box>
        }
      >
        {t('calendar')}
      </TitleBar>
      <PageContent className={classes.pageContent}>
        <Box className={classes.wrapper} display='flex' flexDirection='column'>
          <AddButton
            isSmall={isSmall}
            selectedDate={uiState.startPeriodDate}
            viewMode={uiState.viewMode}
            type={calendarType}
          />
          {renderList()}
        </Box>
      </PageContent>
    </Box>
  )
}

export default ActivitiesPage

const AddButton: React.FC<{
  selectedDate?: number
  isSmall: boolean
  viewMode: VisitsViewMode
  type?: 'visit' | 'nonVisitTask' | 'choice'
}> = (props) => {
  const today = startOfDay(new Date()).valueOf()
  const date: number = props.selectedDate ?? today
  const navigate = useNavigate()
  const modalContext = useContext(ModalContext)
  const featureRoute = useFeatureRoute()

  const classes = useStyles()
  const disabled = props.viewMode === 'day' && isPast(endOfDay(date))
  const { t } = useTranslation('visits')

  if (!props.type) return <></>

  let text: string
  let onClickHandler: () => void
  switch (props.type) {
    case 'visit': {
      text = t('addVisit')
      onClickHandler = () => {
        const path = generatePath(visitsRoutes.visitAdd, { featureRoute })
        navigate(path, { state: { selectedDate: isPast(endOfDay(date)) ? today : date } })
      }
      break
    }
    case 'nonVisitTask': {
      text = 'Задача'
      onClickHandler = () => {
        const path = generatePath(visitsRoutes.nonVisitTaskAdd, { featureRoute })
        navigate(path, { state: { selectedDate: isPast(endOfDay(date)) ? today : date } })
      }
      break
    }
    case 'choice': {
      text = 'Добавить'
      onClickHandler = () => {
        modalContext.open(
          <Dialog
            open
            onClose={() => {
              modalContext.close()
            }}
          >
            <DialogTitle id='alert-dialog-title'>Выберите вариант</DialogTitle>
            <DialogActions className={classes.wrapperModalButtons}>
              <Button
                autoFocus
                color='primary'
                onClick={() => {
                  const path = generatePath(visitsRoutes.visitAdd, { featureRoute })
                  navigate(path, { state: { selectedDate: isPast(endOfDay(date)) ? today : date } })
                  modalContext.close()
                }}
              >
                Визит в торговую точку
              </Button>
              <Button
                color='primary'
                onClick={() => {
                  navigate(`/${featureRoute}/nonVisitTask/add`, {
                    state: { selectedDate: isPast(endOfDay(date)) ? today : date },
                  })
                  modalContext.close()
                }}
              >
                Полевая активность
              </Button>
            </DialogActions>
          </Dialog>,
        )
      }
      break
    }
  }

  return (
    <>
      <Fab
        color='primary'
        aria-label='add'
        variant='extended'
        className={classes.addButton}
        disabled={disabled}
        onClick={onClickHandler}
      >
        <AddIcon className={classes.addIcon} />
        {text}
      </Fab>
    </>
  )
}
