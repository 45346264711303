import React, { useContext } from 'react'

import { Box, createStyles, Divider, Grid, makeStyles, Paper, Theme, Tooltip, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useAsync } from 'react-use'

import { LogManager } from '../../../../../infrastructure/logger'
import { grays } from '../../../../../layout/theme'
import { IProblem } from '../../../../../model/problem'
import { IUserProfile } from '../../../../../model/user-profile'
import { ApiContext } from '../../../../../providers'
import { dateFormat } from '../../../../../utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
      position: 'relative',
      fontSize: theme.typography.h4.fontSize,
      minHeight: 80,
      boxShadow: '0 3px 7px rgba(96, 97, 112, 0.13)',
      borderRadius: 12,
      overflow: 'hidden',
      padding: '16px 26px 16px 30px',
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        padding: '16px 40px 16px 30px',
      },
    },
    rootStatusCanceled: {
      background: '#F2F3F4 !important',
    },
    rootStatusFinished: {
      background: '#F2F3F4 !important',
    },
    gridContainer: {
      flexWrap: 'nowrap',
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
      },
    },
    status: {
      position: 'absolute',
      height: '100%',
      marginTop: -15,
      marginLeft: -30,
      width: 8,
      [theme.breakpoints.down('xs')]: {
        marginTop: -18,
        marginLeft: -30,
      },
    },
    statusRound: {
      position: 'absolute',
      top: 5,
      left: 25,
      width: 10,
      height: 10,
      borderRadius: '50%',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    statusNew: {
      background: theme.palette.info.main,
    },
    statusFinished: {
      background: theme.palette.success.main,
    },
    statusInProgress: {
      background: theme.palette.warning.main,
    },
    statusCanceled: {
      background: theme.palette.grey[600],
    },
    descriptionWrap: {
      flex: '1 1 349px',
      overflow: 'hidden',
      paddingRight: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        paddingRight: 0,
        maxWidth: '100%',
        flexBasis: '100%',
        order: 1,
      },
    },
    name: {
      fontWeight: 600,
      fontSize: theme.typography.h4.fontSize,
      lineHeight: theme.typography.h4.lineHeight,
      width: '100%',
    },
    description: {
      fontSize: theme.typography.fontSize,
      lineHeight: theme.typography.h4.lineHeight,
      color: theme.palette.text.primary,
      width: '100%',
    },
    description_grey: {
      fontSize: theme.typography.fontSize,
      lineHeight: theme.typography.h4.lineHeight,
      color: grays.gray2,
      width: '100%',
    },
    verticalLine: {
      margin: 0,
      backgroundColor: grays.gray5,
      width: 2,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    posWrap: {
      flex: '1 1 240px',
      overflow: 'hidden',
      padding: '0 27px 0 24px',
      [theme.breakpoints.down('xs')]: {
        padding: 0,
        order: 3,
      },
    },
    posDescription: {
      display: 'flex',
      alignItems: 'baseline',
      marginBottom: 4,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 0,
      },
    },
    posCode: {
      fontWeight: 400,
      fontSize: theme.typography.fontSize,
      lineHeight: '20px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    stringDevider: {
      padding: '0 8px',
      color: grays.gray2,
      lineHeight: '20px',
    },
    posName: {
      fontWeight: 600,
      fontSize: theme.typography.fontSize,
      lineHeight: '20px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    posAddress: {
      fontWeight: 400,
      minHeight: 20,
      fontSize: theme.typography.fontSize,
      lineHeight: '20px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    detailsWrap: {
      position: 'relative',
      paddingLeft: theme.spacing(3),
      flex: '0 0 149px',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        flexBasis: '100%',
        paddingLeft: 0,
        order: 2,
      },
    },
    statusName: {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.fontSize,
      lineHeight: '20px',
      color: grays.gray2,
      width: '100%',
      marginLeft: 20,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    statusDate: {
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.fontSize,
      lineHeight: '20px',
      width: '100%',
      color: grays.gray2,
      marginTop: 4,
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
      },
    },
  }),
)

interface IItem {
  problem: IProblem
  currentUser: IUserProfile
  onClick: (problemCode: string, posCode: string) => void
}

const logger = LogManager.getLogger('fetchProblemsList')

const ProblemListPosScreenItem: React.FC<IItem> = ({ problem, currentUser, onClick }) => {
  const classes = useStyles()
  const { t } = useTranslation('problems')
  const api = useContext(ApiContext)
  const creationDateHM = dateFormat(problem.creationTime!, 'HH:mm')
  const creationDateDMY = dateFormat(problem.creationTime!, 'dd.MM.yyyy')
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const comment = problem.problemDetails || problem.executiveComment || ''
  const posCode = problem.location!.code
  const problemId = problem.code

  let resultStatus = ''
  if (
    problem.status === 'New' &&
    (!problem.assignedTo || currentUser.fieldPositionRole?.code !== problem.assignedToPositionRole?.code)
  ) {
    resultStatus = 'New'
  }
  if (problem.status === 'New' && currentUser.fieldPositionRole?.code === problem.assignedToPositionRole?.code) {
    resultStatus = 'Processed'
  }
  if (problem.status === 'Resolved') {
    resultStatus = 'Resolved'
  }
  if (problem.status === 'Canceled') {
    resultStatus = 'Canceled'
  }
  const statusCaption = t(resultStatus)

  const pos = useAsync(async () => {
    try {
      return await api.pos.getPos(posCode)
    } catch (error) {
      const message = 'Ошибка при получении проблем'
      logger.error('get', message, error)
      throw new Error(message)
    }
  }, [posCode])
  const address = pos.value ? `${pos.value?.address?.fullAddress}, ${pos.value?.address?.city ?? ''}` : ''
  const legalEntity =
    pos.value?.associatedLegalEntities &&
    pos.value.associatedLegalEntities.length > 0 &&
    pos.value?.associatedLegalEntities?.[0].legalEntity?.name

  return (
    <Paper
      elevation={3}
      square
      className={classNames(classes.root, {
        [classes.rootStatusFinished]: resultStatus === 'Resolved',
        [classes.rootStatusCanceled]: resultStatus === 'Canceled',
      })}
      onClick={() => onClick(problemId, posCode)}
    >
      <Tooltip title={statusCaption}>
        <div
          className={classNames(classes.status, {
            [classes.statusNew]: resultStatus === 'New',
            [classes.statusInProgress]: resultStatus === 'Processed',
            [classes.statusFinished]: resultStatus === 'Resolved',
            [classes.statusCanceled]: resultStatus === 'Canceled',
          })}
        />
      </Tooltip>
      <Grid container className={classes.gridContainer} alignItems='center' justify='space-between'>
        <Grid item className={classes.descriptionWrap}>
          <Box>
            <Box>
              <Typography className={classes.name} component='h6' noWrap>
                {problem.escalationReason.name}
              </Typography>
              <Typography className={comment ? classes.description : classes.description_grey} noWrap>
                {comment || 'Комментарий автора отсутствует'}
              </Typography>
            </Box>
          </Box>
        </Grid>
        {<Divider orientation='vertical' variant='middle' flexItem className={classes.verticalLine} />}
        <Grid item className={classes.posWrap}>
          <Box className={classes.posDescription}>
            <Typography className={classes.posCode}>{posCode}</Typography>
            <Typography className={classes.stringDevider}>|</Typography>
            <Typography className={classes.posName}>{problem.location?.name}</Typography>
            <Typography className={classes.stringDevider}>|</Typography>
            <Typography className={classes.posName}>{legalEntity}</Typography>
          </Box>
          <Typography className={classes.posAddress}>{address}</Typography>
        </Grid>
        {<Divider orientation='vertical' variant='middle' flexItem className={classes.verticalLine} />}
        <Grid item className={classes.detailsWrap}>
          <Tooltip title={statusCaption}>
            <div
              className={classNames(classes.statusRound, {
                [classes.statusNew]: resultStatus === 'New',
                [classes.statusInProgress]: resultStatus === 'Processed',
                [classes.statusFinished]: resultStatus === 'Resolved',
                [classes.statusCanceled]: resultStatus === 'Canceled',
              })}
            />
          </Tooltip>
          <Box display='flex' alignItems='center'>
            <Box>
              <Typography className={classes.statusName} component='h5'>
                {statusCaption}
              </Typography>
              <Typography className={classes.statusDate}>{`${creationDateHM} | ${creationDateDMY}`}</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default React.memo(ProblemListPosScreenItem)
