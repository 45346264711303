import React, { useEffect, useMemo, useRef, useState } from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { findLastIndex, last } from 'lodash'
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'

import { Layout } from '../../../layout'
import AppBar, { TitleBarText } from '../../../layout/app-bar'
import { BackButton } from '../../../layout/back-button'
import { PageContent } from '../../../layout/page-content'
import { Code } from '../../../model/base'
import { SubprocessSettings } from '../../../model/subprocess-setting'
import { isTabStep } from '../../../model/task-execution-process'
import { appToast } from '../../../utils'
import { formatTemplateString } from '../../../utils/format-template-string'
import { getContextProperty, getPropertyAny } from '../script-tasks/propertyName'
import { useScriptTaskContext } from '../script-tasks/script-task-context'
import { ScriptView } from '../script-tasks/script-view'
import { SubProcessView } from '../sub-process/sub-process-view'
import { ScreenRef } from '../template-tasks/types'
import { useExecutionState } from './execution-state'
import { iterProcessItems } from './iter-template-utils'
import { ILocalContext, makeStateKey, SubProcessDefiningItem, useLocalContextService } from './local-context'
import { getCurrentRecord, SubProcessContextProvider } from './nested-context'
import { SubProcessViewHorizontal } from './sub-process-view-horizontal'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      height: 84,
      [theme.breakpoints.down('xs')]: {
        height: 64,
      },
    },
    content: {
      marginTop: 84,
      paddingBottom: theme.spacing(12),
      paddingTop: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        marginTop: 64,
        paddingTop: theme.spacing(2),
      },
    },
    smallBackButton: {
      [theme.breakpoints.down('xs')]: {
        width: 32,
        height: 32,
        padding: 2,
        marginRight: 8,
        marginLeft: -8,
      },
    },
  }),
)

interface Params {
  processCode: string
  recordCode: string
}

export const NestedProcessPage: React.FC<{ onBack?: VoidFunction }> = ({ onBack }) => {
  const { processCode, recordCode } = useParams() as unknown as Params
  const navigate = useNavigate()

  const localContext = useScriptTaskContext()

  const item = findItem(localContext, processCode)!
  const records = getContextProperty<unknown[]>(localContext, item.propertyName, [])

  const recordIndex = records.findIndex((r) => getPropertyAny(r, item.recordKeyPropertyName!) === recordCode)
  const record = records[recordIndex]
  const process = (localContext.processUnit ?? localContext.template).subProcesses[processCode]
  // console.log('NestedProcess', { record, records, propertyName: item.propertyName })
  const processSetting =
    getItemSubprocesses(item)?.find((settings) => settings.process.code === process.code) ?? ({} as never)

  const { finishActionName, displayNameFormat } = processSetting

  useEffect(() => {
    console.log('NestedProcessPage mount', { recordCode, processCode, record, process, processSetting })
  }, [])

  return (
    <SubProcessContextProvider process={process} item={item} record={record} recordIndex={recordIndex}>
      <Routes>
        <Route
          path=''
          element={
            <NestedProcessControl
              finishActionName={finishActionName ?? 'Завершить'}
              onBack={onBack}
              displayNameFormat={displayNameFormat ?? ''}
            />
          }
        />
        <Route
          path={`process/:processCode/:recordCode/*`}
          element={
            <NestedProcessPage
              onBack={() => {
                console.log('going back item from state', item, localContext.subProcessStack)
                return navigate('', { state: { item } })
              }}
            />
          }
        />
      </Routes>
    </SubProcessContextProvider>
  )
}

interface NestedProcessProps {
  children?: never
  finishActionName: string
  displayNameFormat: string
  onBack?: VoidFunction
}

const NestedProcessControl: React.FC<NestedProcessProps> = (props) => {
  const classes = useStyles()
  const { finishActionName, displayNameFormat, onBack } = props
  const localContext = useScriptTaskContext()
  const { subProcessStack } = localContext
  const currentFrame = last(subProcessStack) ?? ({} as never)
  const stateKey = makeStateKey(currentFrame)
  const [isReady, setIsReady] = useState<boolean>(false)
  const currentScreenRef = useRef<ScreenRef>(null)
  const steps = currentFrame.process.steps ?? []
  const contextService = useLocalContextService()

  const stepIndex = useExecutionState((store) => store.taskState?.subProcesses[stateKey]?.currentStep ?? 0)
  const setSubProcessStep = useExecutionState((store) => store.setSubProcessStep)

  const currentStep = steps[stepIndex] ?? steps[0]
  const currentStepCode = currentStep.code
  // console.log({ stepIndex, savedIndex: recordState?.currentStep })
  const tabs = useMemo(() => steps.filter(isTabStep), [steps])

  useEffect(() => {
    return () => {
      if (contextService.inMemory) {
        // clean up state on unmount for inMemory services
        setSubProcessStep(stateKey, 0)
      }
    }
  }, [])

  const setCurrentStepCode = (code: Code): void => {
    const index = steps.findIndex((step) => step.code === code)
    // console.log('setting currentStep code', code, index, steps)
    setSubProcessStep(stateKey, index)
  }
  const setCurrentStep = (index: number): void => {
    if (index < 0) return
    setSubProcessStep(stateKey, index)
    void contextService.updateStackSteps()
  }

  const goToNext = async (): Promise<void> => {
    const newIndex = steps.findIndex((step) => step.code === currentStep.code) + 1
    const nextStage = steps[newIndex]

    console.log('goToNext', { nextStage, currentStep, steps })
    if (newIndex) {
      setCurrentStep(newIndex)
    }
  }
  const onFinish = async (): Promise<void> => {
    onBack?.()
    setCurrentStep(0)
    console.log('finishing process')
  }

  const handleAction = async (): Promise<void> => {
    await contextService.onLoad()
    await contextService.refetch()
    const processFinishStep = steps[steps.length - 1]
    console.log('Action', { currentStep, processFinishStep })
    if (currentStep.code === processFinishStep.code) {
      return void onFinish()
    }
    void goToNext()
  }

  const handleScreenAction = async (): Promise<void> => {
    if (!isReady) {
      return currentScreenRef.current?.validate()
    }

    return handleAction()
  }

  const handleScriptError = async (error: Error): Promise<void> => {
    appToast.error(error.message)
    const currentIndex = steps.findIndex((step) => step.code === currentStep.code)
    if (currentIndex > 0) {
      const previousStepIndex = findLastIndex(steps.slice(0, currentIndex), isTabStep)
      if (previousStepIndex > -1) {
        setCurrentStep(previousStepIndex)
      }
    } else {
      onBack?.()
    }
  }

  if (currentStep.$type === 'PMI.FACE.BDDM.Extensions.Classes.ScriptStep') {
    return (
      <ScriptView
        localContext={localContext}
        script={(localContext.template ?? localContext.processUnit).scripts[currentStep.script.code]}
        onSuccess={handleAction}
        onError={handleScriptError}
      />
    )
  }

  const stepperType = currentFrame.process.stepperType ?? 'Vertical'

  return (
    <>
      <Layout
        appBar={
          <AppBar className={classes.appBar}>
            {onBack && <BackButton className={classes.smallBackButton} onBack={onBack} />}
            <TitleBarText>
              {formatTemplateString(displayNameFormat, (prop) =>
                getContextProperty({ ...localContext, ...getCurrentRecord(localContext) }, prop),
              )}
            </TitleBarText>
          </AppBar>
        }
      >
        <PageContent className={classes.content}>
          {stepperType === 'Vertical' && (
            <SubProcessView
              finishActionName={finishActionName}
              currentScreenRef={currentScreenRef}
              allSteps={steps}
              tabs={tabs}
              currentTab={currentStepCode!}
              onTabChange={setCurrentStepCode}
              onAction={handleScreenAction}
              onScriptError={handleScriptError}
              setIsReady={setIsReady}
              isReady={isReady}
            />
          )}
          {stepperType === 'Horizontal' && (
            <SubProcessViewHorizontal
              finishActionName={finishActionName}
              currentScreenRef={currentScreenRef}
              allSteps={steps}
              currentTab={currentStepCode!}
              onTabChange={setCurrentStepCode}
              onAction={handleScreenAction}
              onScriptError={handleScriptError}
              setIsReady={setIsReady}
              isReady={isReady}
            />
          )}
          {stepperType === 'Unrecognized' && <h1>Wrong stepper type</h1>}
        </PageContent>
      </Layout>
    </>
  )
}

function findItem(localContext: ILocalContext, processCode: Code): SubProcessDefiningItem | undefined {
  for (const item of iterProcessItems(localContext)) {
    if (item.$type === 'PMI.FACE.BDDM.Extensions.Classes.TableScreenItem') {
      for (const subProcess of item.rowSubprocesses ?? []) {
        if (subProcess.process.code === processCode) {
          return item
        }
      }
    }
    if (item.$type === 'PMI.FACE.BDDM.Extensions.Classes.ViewRichTextListScreenItem') {
      for (const subProcess of item.subprocesses ?? []) {
        if (subProcess.process.code === processCode) {
          return item
        }
      }
    }
  }
}

function getItemSubprocesses(item: SubProcessDefiningItem): SubprocessSettings[] | undefined {
  switch (item.$type) {
    case 'PMI.FACE.BDDM.Extensions.Classes.TableScreenItem':
      return item.rowSubprocesses
    case 'PMI.FACE.BDDM.Extensions.Classes.ViewRichTextListScreenItem':
      return item.subprocesses
  }
}
