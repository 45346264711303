export const AdminPermissionsMap = {
  TT_VIEW: 'FieldForceTaskTemplatesView',
  TT_EDIT_PARAMS: 'FieldForceTaskTemplatesEditParams',
  TT_EDIT_PROFILE_RELATIONS: 'FieldForceTaskTemplatesEditUserProfileRelations',
  TT_IMPORT: 'FieldForceTaskTemplatesImport',
  TT_MANAGE: 'FieldForceTaskTemplatesFullManagement',
  QS_VIEW: 'QuestionnairesView',
  QS_MANAGE: 'QuestionnairesFullManagement',
  DTE_TT_VIEW: 'DteTaskTemplatesView',
  DTE_TT_IMPORT: 'DteTaskTemplatesImport',
  DTE_TT_EDIT_PARAMS: 'DteTaskTemplatesEditParams',
  DTE_TT_EDIT_PROFILE_RELATIONS: 'DteTaskTemplatesEditUserProfileRelations',
  DTE_TT_MANAGE: 'DteTaskTemplatesFullManagement',
  DO_MANAGE: 'DataObjectsEditorFullManagement',
  DATA_MANAGER_VIEW: 'DataManagerView',
  APP_FEATURES_MANAGE: 'AppFeaturesEditorFullManagement',
} as const

/** @deprecated use AdminPermissionsMap */
export const TT_EDIT_PARAMS = AdminPermissionsMap.TT_EDIT_PARAMS
/** @deprecated use AdminPermissionsMap */
export const TT_EDIT_PROFILE_RELATIONS = AdminPermissionsMap.TT_EDIT_PROFILE_RELATIONS
/** @deprecated use AdminPermissionsMap */
export const TT_IMPORT = AdminPermissionsMap.TT_IMPORT
/** @deprecated use AdminPermissionsMap */
export const TT_MANAGE = AdminPermissionsMap.TT_MANAGE
/** @deprecated use AdminPermissionsMap */
export const QS_MANAGE = AdminPermissionsMap.QS_MANAGE
/** @deprecated use AdminPermissionsMap */
export const DTE_TT_IMPORT = AdminPermissionsMap.DTE_TT_IMPORT
/** @deprecated use AdminPermissionsMap */
export const DTE_TT_EDIT_PARAMS = AdminPermissionsMap.DTE_TT_EDIT_PARAMS
/** @deprecated use AdminPermissionsMap */
export const DTE_TT_EDIT_PROFILE_RELATIONS = AdminPermissionsMap.DTE_TT_EDIT_PROFILE_RELATIONS
/** @deprecated use AdminPermissionsMap */
export const DTE_TT_MANAGE = AdminPermissionsMap.DTE_TT_MANAGE
