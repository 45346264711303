import React, { useCallback, useContext, useState } from 'react'

import { Box, createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import addDays from 'date-fns/addDays'
import isEqual from 'date-fns/isEqual'
import startOfDay from 'date-fns/startOfDay'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { getAppFrameStyle, getFloatActionButtonStyle } from '../../../assets/pm-shared-styles'
import { ValidationFab } from '../../../components/validation-fab'
import { PageContent } from '../../../layout/page-content'
import { TitleBar } from '../../../layout/title-bar'
import { VisitOutOfRouteReason } from '../../../model/dictionary-item'
import { ApiContext } from '../../../providers'
import { AppRoutes } from '../../../routes'
import { appToast } from '../../../utils'
import { useIsSmall } from '../../_common/hooks/useIsSmall'
import { getPosAddress } from '../../_common/pos'
import { StoresSearch } from '../../stores/components'
import { getRegisteredNameFromPos } from '../../stores/store-menu-page/utils'
import StoresListItem from '../../stores/stores-list-item/stores-list-item'
import StoresList from '../../stores/stores-list/stores-list'
import { FormSelectResult } from '../../tasks/sales-expert/tabs-new/components/form-select-result'
import { ItemCard } from '../../tasks/template-tasks/composite-screen/item-card'
import VisitCreateDatePicker from '../visit-create-date-picker/visit-create-date-picker'
import { VisitPageUiContext } from '../visits-page/visits-page-ui-context'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appFrame: getAppFrameStyle(theme),
    addButton: getFloatActionButtonStyle(theme),
  }),
)
const DEFAULT_DATE = addDays(new Date(), 1).valueOf()

const VisitEditPage: React.FC = () => {
  const { uiStateMethods } = useContext(VisitPageUiContext)
  const api = useContext(ApiContext)
  const classes = useStyles()
  const currentRouteParams = useParams<{ id: string }>()
  const navigate = useNavigate()
  const [selectedDate, setSelectedDate] = useState<number>(DEFAULT_DATE)
  const [selectedPosCode, setSelectedPosCode] = useState<string | null>(null)
  const [selectedAvailableReasons, setSelectedAvailableReasons] = useState<VisitOutOfRouteReason>()
  const [searchQuery, setSearchQuery] = useState<string>('')

  const userProfile = useAsync(async () => await api.userProfile.getCurrentProfile(), [])
  const availableOutOfRouteVisitReasons = userProfile.value?.profile.availableOutOfRouteVisitReasons

  const isSmall = useIsSmall()

  const { t } = useTranslation('visits')

  // We can show out of coverage POS when they are selected
  const posListData = useAsync(async () => await api.pos.searchPos(), [])

  const editableVisit = useAsync(async () => {
    const selectedVisit = await api.visits.getVisit(currentRouteParams.id!)
    if (!selectedVisit) {
      console.log(`ERROR: Visit with id ${currentRouteParams.id} was not found`)
      return null
    }
    const defaultDate = selectedVisit.plannedStartDate ?? DEFAULT_DATE
    setSelectedDate(defaultDate)
    setSelectedPosCode(selectedVisit.pointOfSaleCode ?? null)
    setSelectedAvailableReasons(selectedVisit.outOfRouteReason)

    return selectedVisit
  }, [currentRouteParams])

  const onBackHandler = (): void => navigate(-1)

  const onSearchHandler = useCallback((queryString: string): void => setSearchQuery(queryString), [])

  const addButtonIsDisable = (): boolean => {
    const isEqualAvailableReasons = selectedAvailableReasons === editableVisit.value?.outOfRouteReason
    const isEqualPosCode = selectedPosCode === editableVisit.value?.pointOfSaleCode
    const isEqualDate =
      !!editableVisit.value?.plannedStartDate &&
      isEqual(startOfDay(new Date(selectedDate)), startOfDay(new Date(editableVisit.value?.plannedStartDate)))
    return !selectedPosCode || (isEqualPosCode && isEqualDate && isEqualAvailableReasons)
  }

  const editHandler = async (): Promise<void> => {
    if (!editableVisit?.value || !selectedPosCode || !selectedDate) {
      return
    }
    try {
      const shiftedVisit = await api.visits.shiftVisit({
        visitCode: editableVisit.value.code,
        pointOfSaleCode: selectedPosCode,
        date: new Date(selectedDate).valueOf(),
        outOfRouteReason: selectedAvailableReasons,
      })
      console.log('shiftedVisit', shiftedVisit)
      const path = '/'.concat(AppRoutes.visits.path)
      uiStateMethods.setStartPeriodDate(selectedDate)
      uiStateMethods.setEndPeriodDate(selectedDate)
      navigate(path)
      // appToast.success(t('visitEditSuccess'))
    } catch (e) {
      appToast.error(t('visitEditError'))
    }
  }

  // Если в списке ТТ из зоны покрытия пользователя есть всего одна ТТ, и она совпадает с текущей ТТ визита,
  // контрол выбора ТТ отображать не надо
  const renderSearchPosList = (): JSX.Element | null => {
    if (!posListData.value) {
      return null
    }

    const isPosOnlyOneAndEqual =
      posListData.value?.length === 1 && posListData.value.find((x) => x.code === editableVisit.value?.pointOfSaleCode)

    if (isPosOnlyOneAndEqual) {
      return null
    }

    if (!posListData.value?.length) {
      const DEFAULT_EMPTY_MESSAGE = t('noPosMessageDesc')
      return (
        <Box mt={3}>
          <Typography align='center'>{DEFAULT_EMPTY_MESSAGE}</Typography>
        </Box>
      )
    }

    const currentPosCode = editableVisit.value?.pointOfSaleCode

    return (
      <>
        <StoresSearch query={searchQuery} onChange={onSearchHandler} isContrast={true} />

        <Box mx={isSmall ? -2 : -3}>
          <StoresList
            searchQuery={searchQuery}
            selectable
            onSelect={(selectedPosCode) => setSelectedPosCode(selectedPosCode)}
            selectedId={selectedPosCode}
            currentPosCode={currentPosCode}
          />
        </Box>
      </>
    )
  }

  if (!posListData?.value) {
    return null
  }

  if (!editableVisit?.value) {
    return null
  }

  const posFromEditableVisit = posListData.value.find((x) => x.code === editableVisit.value!.pointOfSaleCode)

  const renderOutOfRouteVisitReasons = (): JSX.Element => {
    if (editableVisit?.value?.source !== 'OutOfRoutePlan') {
      return <></>
    }

    if (availableOutOfRouteVisitReasons?.length) {
      return (
        <Box px={3} pb={20}>
          <ItemCard //
            label={t('createVisitReason')}
            isError={!selectedAvailableReasons && !editableVisit?.value?.outOfRouteReason}
          >
            <FormSelectResult
              // id={item.propertyName}
              // onBlur={onBlur}
              disabled={false}
              value={selectedAvailableReasons?.code ?? editableVisit?.value?.outOfRouteReason?.code ?? ''}
              onChange={(ev) => {
                const targetDictionary = availableOutOfRouteVisitReasons?.find((el) => el.code === ev.target.value)
                setSelectedAvailableReasons(targetDictionary)
              }}
              variant='outlined'
              placeholder='Выбор причины добавления визита'
              dictionaries={availableOutOfRouteVisitReasons}
              // helperText={t('requiredFieldError')}
              // error={!selectedAvailableReasons}
              showListButtonCaption={t('choose')}
              isNullable={false}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            />
          </ItemCard>
        </Box>
      )
    }

    const outOfRouteReason = editableVisit?.value?.outOfRouteReason

    if (outOfRouteReason) {
      return (
        <Box px={3}>
          <ItemCard label='Причина добавления' isError={false}>
            <Typography variant='body1'>{outOfRouteReason.name}</Typography>
          </ItemCard>
        </Box>
      )
    }

    return <></>
  }

  const registeredName = posFromEditableVisit && getRegisteredNameFromPos(posFromEditableVisit)

  return (
    <div className={classes.appFrame}>
      <TitleBar onBack={onBackHandler}>{t('editingVisitTitle')}</TitleBar>
      <PageContent>
        <ValidationFab //
          aria-label='add'
          isValid={!addButtonIsDisable()}
          onClick={(isValid) => isValid && editHandler()}
        >
          {t('confirmEdit')}
        </ValidationFab>

        <Box pt={3} px={3}>
          <VisitCreateDatePicker
            selectedDate={selectedDate}
            onChange={(x) => {
              x && setSelectedDate(x.valueOf())
            }}
          />
        </Box>

        <Box px={3}>
          <ItemCard //
            label={t('posItemTitle')}
          >
            <Box mx={isSmall ? -2 : -3}>
              {posFromEditableVisit && (
                <StoresListItem
                  name={posFromEditableVisit.name}
                  address={getPosAddress(posFromEditableVisit)}
                  code={posFromEditableVisit.code}
                  registerName={registeredName}
                />
              )}
            </Box>
          </ItemCard>
        </Box>

        <Box px={3}>
          <ItemCard //
            label={t('selectPos')}
          >
            {renderSearchPosList()}
          </ItemCard>
        </Box>

        {renderOutOfRouteVisitReasons()}
      </PageContent>
    </div>
  )
}

export default VisitEditPage
