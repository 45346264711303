import React from 'react'

import {
  Box,
  MenuItem,
  Typography,
  Select,
  SelectProps,
  FormHelperText,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { useTranslation } from "react-i18next";

import { IDictionaryContent } from '../../../../../model/content'
import { IDictionaryItem } from '../../../../../model/dictionary-item'
import { getDictionaryContentText } from '../../../../../utils'
import { TableSelect } from '../../../template-tasks/composite-screen/table-item/single-select-cell'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      margin: theme.spacing(1, 0),
      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
      maxWidth: '100%',
      // width: '100%',
    },
    menuItem: {
      paddingTop: 12,
      paddingBottom: 12,
      whiteSpace: 'unset',
    },
  }),
)

interface Props {
  helperText?: React.ReactNode
  error?: boolean
  disabled?: boolean
  dictionaries: IDictionaryItem[] | null | undefined
  titleItems?: string
  showListButtonCaption?: string
  isNullable?: boolean
  nullValueCaption?: string
}

export const FormSelectResult: React.FC<Props & SelectProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation('screen-items')

  if (!props.dictionaries) {
    return <></>
  }

  interface IDictionaryItemWithContent extends IDictionaryItem {
    content: IDictionaryContent
  }

  const currentDictionary = props.dictionaries.find((el) => el.code === props.value)

  const dictionaryContentText = getDictionaryContentText((currentDictionary as IDictionaryItemWithContent)?.content)

  if (props.disabled) {
    const displayValue =
      props.value === null ? props.nullValueCaption ?? 'Не выбрано' : currentDictionary?.name ?? 'Не выбрано'

    return (
      <Box py={1}>
        <Typography color='textSecondary'>{displayValue}</Typography>
        {dictionaryContentText && (
          <Box marginTop={1}>
            <Typography color='textSecondary'>{dictionaryContentText}</Typography>
          </Box>
        )}
      </Box>
    )
  } else {
    return (
      <>
        <Select
          {...props}
          className={classes.select}
          displayEmpty
          input={<TableSelect color='primary' />}
          renderValue={(val) => {
            if (typeof val === 'string') {
              const name = props.dictionaries?.find((item) => item.code === val)?.name
              if (name) return name
              if (val) return val
            }

            if (val === null) {
              return props.nullValueCaption ?? t('notСhosen')
            }

            return props.showListButtonCaption ?? t('choose')
          }}
        >
          {props.titleItems && (
            <MenuItem className={classes.menuItem} value='' disabled>
              <span style={{ color: '#989898' }}>{props.titleItems}</span>
            </MenuItem>
          )}

          {props.isNullable && (
            <MenuItem className={classes.menuItem} value={null as unknown as string}>
              {props.nullValueCaption ?? t('notСhosen')}
            </MenuItem>
          )}

          {props.dictionaries.map((item) => (
            <MenuItem className={classes.menuItem} key={item.code} value={item.code}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
        <Box>{props.error ? <FormHelperText error>{props.helperText}</FormHelperText> : <></>}</Box>
        <Box>
          {dictionaryContentText && (
            <Typography variant='caption' color='textSecondary'>
              {dictionaryContentText}
            </Typography>
          )}
        </Box>
      </>
    )
  }
}
