import React from 'react'

import { Box, Button, createStyles, IconButton, makeStyles, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import { IHeaderLabel } from '../header-label'

const useStyles = makeStyles((theme) =>
  createStyles({
    displayName: {
      fontSize: 16,
      fontWeight: 500,
    },
    addButton: {
      textTransform: 'none',
      fontSize: 15,
      lineHeight: '26px',
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
    addButtonIcon: {
      marginRight: 4,
    },
    chevron: {
      transform: 'rotate(-180deg)',
      transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      marginRight: theme.spacing(1),
      '.Mui-expanded &': {
        transform: 'rotate(0deg)',
      },
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
      },
    },
  }),
)

interface ICardHeaderLabel {
  isCollapsible?: boolean
}

export const CardHeaderLabel: React.FC<IHeaderLabel & ICardHeaderLabel> = ({
  displayName,
  addRecordEnabled,
  onAddRecord,
  buttonDisplayName,
  isCollapsible,
}) => {
  const classes = useStyles()
  if (!displayName && !addRecordEnabled) return null
  return (
    <Box display='flex' alignItems='center' width='100%'>
      {displayName && <Typography className={classes.displayName}>{displayName}</Typography>}
      {isCollapsible && (
        <IconButton size='small' className={classes.chevron}>
          <ArrowDropDownIcon />
        </IconButton>
      )}
      {addRecordEnabled && (
        <Box ml='auto' width='fit-content'>
          <Button className={classes.addButton} variant='text' color='primary' onClick={onAddRecord} disabled={false}>
            <AddIcon className={classes.addButtonIcon} />
            {buttonDisplayName ?? 'Добавить'}
          </Button>
        </Box>
      )}
    </Box>
  )
}
