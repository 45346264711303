import React, { useContext, useState } from 'react'

import { Checkbox, createStyles, Theme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import { getAppFrameStyle } from '../../../assets/pm-shared-styles'
import { ValidationFab } from '../../../components/validation-fab'
import { PageContent } from '../../../layout/page-content'
import { TitleBar } from '../../../layout/title-bar'
import { createReferenceToEntity, EntityKey, getEntityKey } from '../../../model/base'
import { ITaskTemplate } from '../../../model/task-template'
import { ApiContext, ProfileContext } from '../../../providers'
import { useDefaultCodeSpace } from '../../../providers/config/useDefaultCodeSpace'
import { CreateTaskRequest } from '../../../services/task-service-api'
import { appToast } from '../../../utils'
import { useAvailableTemplateList } from '../../_common/hooks/useAvailableTemplateList'
import { useFeatureRoute } from '../../custom-app-feature/app-feature-context'
import { ErrorPage } from '../../error-page'
import { ItemCard } from '../../tasks/template-tasks/composite-screen/item-card'
import { visitsRoutes } from '../visits-app-feature/visits-app-feature'
import { useAfterTaskCreateEventHandler } from './visit-task-event-handler'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appFrame: getAppFrameStyle(theme),
    item: {
      cursor: 'pointer',
    },
    saveButton: {
      color: 'white',
      position: 'fixed',
      zIndex: 9999,
      bottom: '3%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  }),
)

const VisitTaskCreatePage: React.FC = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const api = useContext(ApiContext)
  const profile = useContext(ProfileContext)
  const currentRouteParams = useParams<{ id: string }>()
  const visitCode = currentRouteParams.id!
  const featureRoute = useFeatureRoute()
  const handleAfterTaskCreateEvent = useAfterTaskCreateEventHandler(visitCode)
  const templatesOps = useAvailableTemplateList(['Assign', 'Proceed'])

  const [selectedTaskKeys, setSelectedTaskKeys] = useState<EntityKey[]>([])

  const { t } = useTranslation('visits')
  const defaultCodeSpace = useDefaultCodeSpace()

  const onBackHandler = (): void => navigate(-1)
  const onSelectHandler = (itemId: EntityKey): void => {
    selectedTaskKeys.includes(itemId)
      ? setSelectedTaskKeys(selectedTaskKeys.filter((x) => x !== itemId))
      : setSelectedTaskKeys([...selectedTaskKeys, itemId])
  }
  const saveHandler = async (isValid: boolean): Promise<void> => {
    const visit = await api.visits.getVisit(visitCode)
    if (!isValid) {
      appToast.info('Не выбран тип задачи')
      return
    }

    const taskRequests: CreateTaskRequest[] = []
    for (const taskKey of selectedTaskKeys) {
      const template = templatesOps.value?.find((template) => getEntityKey(template) === taskKey)
      if (!template) return

      const executivePosition = await api.userProfile.getCurrentFieldPositionRoleReference()

      taskRequests.push({
        $type: template.taskObjectType,
        name: template.name,
        startDate: visit?.plannedStartDate ?? Date.now(),
        source: 'SelfAssigned',
        template: createReferenceToEntity(template, defaultCodeSpace, undefined, true),
        executive: profile.value?.employee?.account,
        executivePosition,
        executivePositionRole: executivePosition,
        status: 'Planned',
      })
    }

    const addTaskToVisitResponse = await api.tasks.createVisitTasks({
      visitCode,
      createTaskRequests: taskRequests,
    })
    console.log('addTaskToVisitResponse', addTaskToVisitResponse)

    for (const task of addTaskToVisitResponse) {
      const template = templatesOps.value?.find((template) => template.code === task.template.code)
      await handleAfterTaskCreateEvent(task, template!)
    }

    const path = generatePath(visitsRoutes.visitView, { featureRoute, id: visitCode })
    navigate(path)
    // const successMessage: string = t('taskAdded', { count: selectedTaskCodes.length })
    // appToast.success(successMessage)
  }

  const isSelected = (id: EntityKey): boolean => selectedTaskKeys.includes(id)

  const renderItem = (item: ITaskTemplate): JSX.Element => {
    const key = getEntityKey(item)
    return (
      <Box className={classes.item} key={key} display='flex' alignItems='center' component='label'>
        <Checkbox color='primary' onClick={() => onSelectHandler(key)} checked={isSelected(key)} />
        <Typography>{item.name}</Typography>
      </Box>
    )
  }

  if (templatesOps.error) {
    return <ErrorPage errorMessage={templatesOps.error.message} />
  }

  if (!templatesOps.value && templatesOps.loading) {
    return null
  }

  const templates = templatesOps.value!.filter((template) => !template.nonVisitTask && template.status === 'Active')
  templates.sort((a, b) => {
    if (a.name > b.name) return 1
    if (a.name < b.name) return -1
    return 0
  })

  if (!templates.length) {
    return (
      <div className={classes.appFrame}>
        <TitleBar onBack={onBackHandler}>{t('addingTaskTitle')}</TitleBar>
        <PageContent>
          <Box mt={3} p={1}>
            <Typography variant='h6' component='h6' align='center'>
              {t('noTaskTypesMessageTitle')}
              <br />
              {t('noTaskTypesMessageDesc')}
            </Typography>
          </Box>
        </PageContent>
      </div>
    )
  }

  return (
    <div className={classes.appFrame}>
      <TitleBar onBack={onBackHandler}>{t('addingTaskTitle')}</TitleBar>
      <PageContent>
        <Box p={3}>
          <ItemCard
            isError={!selectedTaskKeys.length}
            label={<Typography variant='inherit'>{t('selectTaskType')}</Typography>}
          >
            <Box ml={-1}>{templates.map(renderItem)}</Box>
          </ItemCard>
        </Box>

        <ValidationFab aria-label='add' isValid={!!selectedTaskKeys.length} onClick={saveHandler}>
          {t('confirmAdd') as string}
        </ValidationFab>
      </PageContent>
    </div>
  )
}

export default VisitTaskCreatePage
