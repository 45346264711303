import { Code } from './base'
import { DateTime } from './common'
import { IContent } from './content'
import { IQuestionnaireProfileReference } from './questionnaire-profile'

export type IAnswer =
  | BooleanAnswer
  | TextAnswer
  | NumericAnswer
  | SelectProfileAnswer
  | HyperlinkAnswer
  | PhotoAnswer
  | NullAnswer

/** Ответ на вопрос */
interface AnswerBase {
  /** Код вопроса, на который был дан ответ */
  questionCode: Code

  /** Дата и время, когда был задан вопрос */
  askDate: DateTime

  /** Дата и время, когда был дан ответ на вопрос */
  replyDate: DateTime

  /**
   * Количество баллов, полученное за ответ на вопрос.
   * Если за ответ получено 0 баллов, то в данное поле пишется значение 0.
   * Поле не заполняется только в том случае, когда у соответствующего вопроса не было заполнено поле maxScore
   */
  score?: number

  /**
   * Максимальное количество баллов, которое можно получить за вопрос.
   * Берется из соответствующего вопроса в момент фиксации ответа.
   * Поле не заполняется только в том случае, когда у соответствующего вопроса не было заполнено поле maxScore
   */
  maxScore?: number

  /** Комментарий, который пользователь оставил вместе с ответом на вопрос */
  comment?: string

  /** Контент, связанный с ответом на вопрос */
  attachment?: IContent
}

export interface TextAnswer extends AnswerBase {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceTextAnswer'
  reply?: string
}

export interface BooleanAnswer extends AnswerBase {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceBooleanAnswer'
  booleanAnswer?: boolean
  reply?: string
}

export interface NumericAnswer extends AnswerBase {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceNumericAnswer'
  reply: number
}

export interface SelectProfileAnswer extends AnswerBase {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceSelectProfileAnswer'
  reply: IQuestionnaireProfileReference
}

export interface HyperlinkAnswer extends AnswerBase {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceHyperlinkAnswer'
  reply?: string
}

export interface NullAnswer extends AnswerBase {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceNullAnswer'
  reply?: string
}

export interface PhotoAnswer extends AnswerBase {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.FacePhotoAnswer'
  reply: IContent
}

export function isBooleanAnswer(answer: IAnswer): answer is BooleanAnswer {
  return answer.$type === 'PMI.FACE.BDDM.Extensions.Classes.FaceBooleanAnswer'
}

export function isSelectProfileAnswer(answer: IAnswer): answer is SelectProfileAnswer {
  return answer.$type === 'PMI.FACE.BDDM.Extensions.Classes.FaceSelectProfileAnswer'
}

export function setBooleanAnswerReply(answer: BooleanAnswer, resolution: boolean | null | undefined): void {
  answer.booleanAnswer = resolution == null ? undefined : resolution
  answer.reply = resolution === true ? 'Да' : resolution === false ? 'Нет' : 'N/A'
}
