/* eslint-disable @typescript-eslint/no-explicit-any */
import { IStorageSchema } from '../infrastructure/storage-service'
import { setBooleanAnswerReply } from '../model/answer'
import { IQuestionnaireReference } from '../model/questionnaire'
import {
  DictionaryStore,
  POSStore,
  QuestionnaireStore,
  SurveyStore,
  TaskReportStore,
  TaskStore,
  TaskTemplateStore,
  UserProfileStore,
  VisitStore,
} from './schema'

const VisitTaskStore = 'visit-task'
const VisitTaskTemplateStore = 'visit-task-template'
const VisitTaskReportStore = 'visit-task-report'

export const migrate: IStorageSchema['migrate'] = async function (
  oldVersion,
  newVersion,
  schema,
  db,
  tx,
  logger,
): Promise<void> {
  if (oldVersion < 17 && newVersion >= 17) {
    await migrateToVersionedTaskTemplates()
  }
  if (oldVersion < 18 && newVersion >= 18) {
    await migrateServiceExpertEducationV0002()
  }
  if (oldVersion < 19 && newVersion >= 19) {
    await migrateTextAnswer()
  }
  if (oldVersion < 20 && newVersion >= 20) {
    await migrateToBddmLikeTasks()
  }
  if (oldVersion < 21 && newVersion >= 21) {
    await migrateServiceSEv2Tasks()
  }
  if (oldVersion < 22 && newVersion >= 22) {
    await remigrateServiceSEv2Tasks()
  }
  if (oldVersion < 23 && newVersion >= 23) {
    await remigrateFailedServiceSEv2Tasks()
  }
  if (oldVersion < 24 && newVersion >= 24) {
    await remigrateFailedServiceSEv2Tasks()
  }
  if (oldVersion < 25 && newVersion >= 25) {
    await remigrateFailedServiceSEv2Tasks()
  }
  if (oldVersion < 26 && newVersion >= 26) {
    await migratePosCodeSpace()
  }
  if (oldVersion < 29 && newVersion >= 29) {
    await migratePosCoverage()
  }
  if (oldVersion < 34 && newVersion >= 34) {
    await migrateDictionaries()
  }
  if (oldVersion < 39 && newVersion >= 39) {
    // clear Questionnaire storage for sync-service to refill it with new data
    await tx.objectStore(QuestionnaireStore).clear()
    await migrateSurveys()
  }

  async function migrateToVersionedTaskTemplates(): Promise<void> {
    // re-create VisitTaskTemplateStore, for keyPath has changed
    logger.info('migrateToVersionedTaskTemplates', `Deleting store '${VisitTaskTemplateStore}'`)
    try {
      db.deleteObjectStore(VisitTaskTemplateStore)
    } catch (err: any) {
      if (err.name === 'NotFoundError') {
        /*ok*/
      } else throw err
    }

    logger.info('migrateToVersionedTaskTemplates', `Creating store '${VisitTaskTemplateStore}'`)
    try {
      const storeDef = schema.stores[VisitTaskTemplateStore]
      const store = db.createObjectStore(VisitTaskTemplateStore, {
        keyPath: storeDef.keyPath,
        autoIncrement: storeDef.autoIncrement,
      })
      if (storeDef.index) {
        for (const indexName in storeDef.index) {
          logger.info('migrateToVersionedTaskTemplates', `Creating index '${VisitTaskTemplateStore}.${indexName}'`)
          const indexDef = storeDef.index[indexName]
          store.createIndex(indexName, indexDef.keyPath, {
            unique: indexDef.unique,
            multiEntry: indexDef.multi,
          })
        }
      }
    } catch (err: any) {
      if (err.name === 'ConstraintError') {
        /*already exists, ok*/
      } else throw err
    }

    // migrate VisitTaskStore
    let visitTasks
    try {
      visitTasks = await tx.objectStore(VisitTaskStore).getAll()
    } catch (err) {
      /* ignore */
      return
    }
    for (const visitTask of visitTasks) {
      switch (visitTask.taskTypeCode) {
        case 'sales_expert':
          visitTask.templateCode = 'ServiceSE'
          visitTask.templateVersionCode = 'ServiceSE_v1'
          delete visitTask.taskTypeCode
          break
        case 'sales_expert_new':
          visitTask.templateCode = 'ServiceSE'
          visitTask.templateVersionCode = 'ServiceSE_v2'
          delete visitTask.taskTypeCode
          break
      }
      await tx.objectStore(VisitTaskStore).put(visitTask)
    }
  }

  async function migrateServiceExpertEducationV0002(): Promise<void> {
    // clear Questionnaire storage and VisitTaskTemplateStore, for sync service to refill it with new data
    await tx.objectStore(QuestionnaireStore).clear()
    await tx.objectStore(VisitTaskTemplateStore).clear()
  }

  async function migrateTextAnswer(): Promise<void> {
    // clear Questionnaire storage, for sync service to refill it with new data
    await tx.objectStore(QuestionnaireStore).clear()
  }

  async function migrateToBddmLikeTasks(): Promise<void> {
    let visitTasks
    try {
      visitTasks = await tx.objectStore(VisitTaskStore).getAll()
    } catch (err) {
      /* ignore */
      return
    }

    function buildEntityRef(entityCode: any): any {
      return {
        code: entityCode,
        codeSpace: 'FACE',
      }
    }

    function buildSurveyRefList(task: any): any {
      const surveyRefs = []
      if (task.preContactsSurveyCode) {
        surveyRefs.push(buildEntityRef(task.preContactsSurveyCode))
      }
      if ((task.contacts as any[])?.length) {
        for (const contact of task.contacts as any[]) {
          surveyRefs.push(buildEntityRef(contact.surveyCode))
        }
      }
      if (task.postContactsSurveyCode) {
        surveyRefs.push(buildEntityRef(task.postContactsSurveyCode))
      }
      if (task.educationDirectionLasSurveyCode) {
        surveyRefs.push(buildEntityRef(task.educationDirectionLasSurveyCode))
      }
      if (task.educationDirectionLauSurveyCode) {
        surveyRefs.push(buildEntityRef(task.educationDirectionLauSurveyCode))
      }
      if (task.traineeSkillsSurveyCode) {
        surveyRefs.push(buildEntityRef(task.traineeSkillsSurveyCode))
      }
      if (task.surveys?.length) {
        task.surveys.forEach((surveyCode: any) => surveyRefs.push(buildEntityRef(surveyCode)))
      }
      return surveyRefs
    }

    function buildContactList(task: any): any {
      return (task.contacts as any[])?.map((contact) => ({
        name: contact.name,
        orderNumber: contact.orderNumber,
        survey: buildEntityRef(contact.surveyCode),
      }))
    }

    function buildDictionaryItem(dictionaryItem: any): any {
      return {
        $type: 'PMI.FACE.BDDM.Extensions.Classes.FACE_DictionaryItem',
        code: dictionaryItem.code,
        name: dictionaryItem.name,
        orderNumber: dictionaryItem.orderNumber,
        status: dictionaryItem.status,
        content: dictionaryItem.content
          ? {
              parts: dictionaryItem.content?.parts.map((part: any) => {
                switch (part.type) {
                  case 'Binary':
                    return {
                      $type: 'PMI.BDDM.Common.BinaryMimeContent',
                      data: part.data,
                      type: part.mimeType,
                    }
                  case 'text':
                    return {
                      $type: 'PMI.BDDM.Common.TextMimeContent',
                      text: part.text,
                      type: part.mimeType,
                    }
                  case 'faceRef':
                    return {
                      $type: 'PMI.BDDM.Common.FaceMimeContentRef',
                      code: part.code,
                      target: part.target,
                      type: part.mimeType,
                    }
                  case 'ref':
                    return {
                      $type: 'PMI.BDDM.Common.MimeContentRef',
                      target: part.target,
                      type: part.mimeType,
                    }
                  default:
                    logger.warn('Unknown ContentPart', part)
                    return part
                }
              }),
            }
          : undefined,
      }
    }

    function buildFeedbackItems(task: any): any {
      const feedbackItems = []

      const behaviorForCorrection = task.behaviorForCorrection ?? task.feedbackItems?.behaviorForCorrection
      if (behaviorForCorrection) {
        feedbackItems.push({
          name: 'BehaviorForCorrection',
          value: behaviorForCorrection,
        })
      }

      const completionsSummary = task.completionsSummary ?? task.feedbackItems?.completionsSummary
      if (completionsSummary) {
        feedbackItems.push({
          name: 'CompletionsSummary',
          value: completionsSummary,
        })
      }

      const plannedActions = task.plannedActions ?? task.feedbackItems?.plannedActions
      if (plannedActions) {
        feedbackItems.push({
          name: 'PlannedActions',
          value: plannedActions,
        })
      }

      const furtherFocus = task.furtherFocus ?? task.feedbackItems?.furtherFocus
      if (furtherFocus) {
        feedbackItems.push({
          name: 'FurtherFocus',
          value: furtherFocus,
        })
      }

      return feedbackItems
    }

    function buildRepresentative(representative: any): any {
      return {
        name: representative.name,
        eMails: representative.email
          ? [
              {
                address: representative.email,
              },
            ]
          : [],
      }
    }

    function buildThirdParty(thirdParty: any): any {
      return {
        code: '0000',
        codeSpace: 'FACE',
        name: thirdParty.name,
      }
    }

    for (const task of visitTasks) {
      if (task.templateVersionCode === 'ServiceSE_v1') continue

      const template = await tx.objectStore(VisitTaskTemplateStore).get(task.templateVersionCode)

      const bddmTask = {
        $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceThirdPartyRepresentativeTask',
        // FieldForceTask
        code: task.code,
        codeSpace: 'FACE',
        name: 'ПО SE',
        visitCode: task.visitCode,
        creationTime: task.creationTime,
        updateTime: task.updateTime,
        startDate: task.factStartTime,
        endDate: task.factEndTime,
        source: 'SelfAssigned',
        template: {
          code: template?.code ?? task.templateCode,
          codeSpace: 'FACE',
          name: template?.name,
          version: {
            code: template?.version?.code ?? task.templateVersionCode,
            startDate: template?.version?.startDate,
            endDate: template?.version?.endDate,
          },
        },
        status: task.status,
        cancelationReason: task.cancelationReason,
        comment: task.comment,
        surveys: buildSurveyRefList(task),
        // FaceThirdPartyRepresentativeTask
        preCanceledStatus: task.preCanceledStatus,
        contacts: buildContactList(task),
        preContactsSurvey: task.preContactsSurveyCode ? buildEntityRef(task.preContactsSurveyCode) : undefined,
        postContactsSurvey: task.postContactsSurveyCode ? buildEntityRef(task.postContactsSurveyCode) : undefined,
        educationDirectionLasSurvey: task.educationDirectionLasSurveyCode
          ? buildEntityRef(task.educationDirectionLasSurveyCode)
          : undefined,
        educationDirectionLauSurvey: task.educationDirectionLauSurveyCode
          ? buildEntityRef(task.educationDirectionLauSurveyCode)
          : undefined,
        traineeSkillsSurvey: task.traineeSkillsSurveyCode ? buildEntityRef(task.traineeSkillsSurveyCode) : undefined,
        taskResult: task.taskResult ? buildDictionaryItem(task.taskResult) : undefined,
        reportLink: task.reportLink,
        topicCodes: task.topicCodes,
        businessTopic: task.businessTopic,
        result: task.result,
        // ThirdPartyRepresentativeTask
        feedbackItems: buildFeedbackItems(task),
        furtherDevelopment: task.furtherDevelopment,
        recommendations: task.recommendations,
        representative: task.representative ? buildRepresentative(task.representative) : undefined,
        strengths: task.strengths,
        theme: task.theme,
        type: 'Education',
        // FieldForceThirdPartyTask
        thirdParty: task.thirdParty ? buildThirdParty(task.thirdParty) : undefined,
      }

      await tx.objectStore(VisitTaskStore).put(bddmTask)
    }
  }

  async function migrateServiceSEv2Tasks(): Promise<void> {
    let visitTasks
    try {
      visitTasks = await tx.objectStore(VisitTaskStore).getAll()
    } catch (err) {
      /* ignore */
      return
    }

    function getFeedbackItem(task: any, name: string): string {
      return task.feedbackItems?.filter((item: any) => item.name === name)?.[0]?.value
    }

    for (const taskV2 of visitTasks) {
      if (taskV2.template?.version?.code !== 'ServiceSE_v2') continue

      const taskV3 = {
        visitCode: taskV2.visitCode,
        $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceSalesExpertEducationTask',
        code: taskV2.code,
        codeSpace: 'FACE',
        creationTime: taskV2.creationTime,
        updateTime: Date.now(),
        startDate: taskV2.startDate,
        endDate: taskV2.endDate,
        cancelationReason: taskV2.cancelationReason,
        comment: taskV2.comment,
        createdBy: taskV2.createdBy,
        description: taskV2.description,
        executive: taskV2.executive,
        executivePosition: taskV2.executivePosition,
        executivePositionRole: taskV2.executivePositionRole,
        name: taskV2.name,
        source: taskV2.source,
        status: taskV2.status,
        surveys: taskV2.surveys,
        template: {
          code: 'ServiceSE',
          codeSpace: 'FACE',
          name: 'Полевое обучение Sales Expert',
          version: {
            code: 'ServiceSE_v3',
            startDate: +new Date('2022-01-21T00:00:00.000Z'),
          },
        },
        businessGoal: taskV2.theme,
        completionsSummary: getFeedbackItem(taskV2, 'CompletionsSummary'),
        customAgencyName: taskV2.thirdParty?.name,
        educationGoal: getFeedbackItem(taskV2, 'BehaviorForCorrection'),
        futherFocus: getFeedbackItem(taskV2, 'FurtherFocus'),
        plannedActions: getFeedbackItem(taskV2, 'PlannedActions'),
        representative: taskV2.representative,
        taskResult:
          taskV2.taskResult != null
            ? {
                code: taskV2.taskResult.code,
                name: taskV2.taskResult.name,
                content: taskV2.taskResult.content,
                dictionary: {
                  code: 'SERVICE_SE_TASK_RESULT',
                  codeSpace: 'FACE',
                  version: {
                    code: 'SERVICE_SE_TASK_RESULT_v1',
                    startDate: +new Date('2021-01-01T00:00:00Z'),
                  },
                },
                orderNumber: taskV2.taskResult.orderNumber,
              }
            : null,
        preCanceledStatus: taskV2.preCanceledStatus,
        reportLink: taskV2.reportLink,
        _changeTime: taskV2._changeTime,
        _sync: taskV2._sync,
      }

      await tx.objectStore(VisitTaskStore).put(taskV3)
    }
  }

  async function remigrateServiceSEv2Tasks(): Promise<void> {
    // prev migration fix: for tasks to be saved visits have to be updated

    let visits
    try {
      visits = await tx.objectStore(VisitStore).getAll()
    } catch (err) {
      /* ignore */
      return
    }

    let visitTasks
    try {
      visitTasks = await tx.objectStore(VisitTaskStore).getAll()
    } catch (err) {
      /* ignore */
      return
    }

    for (let i = 0; i < visits.length; i++) {
      const visit = visits[i]
      const visitNeedsUpdate = visitTasks.some(
        (task: any) => task.visitCode === visit.code && visit._changeTime < task.updateTime,
      )
      if (visitNeedsUpdate) {
        visit._changeTime = Date.now()
        await tx.objectStore(VisitStore).put(visit)
      }
    }
  }

  async function remigrateFailedServiceSEv2Tasks(): Promise<void> {
    let visits
    try {
      visits = await tx.objectStore(VisitStore).getAll()
    } catch (err) {
      /* ignore */
      return
    }

    let visitTasks
    try {
      visitTasks = await tx.objectStore(VisitTaskStore).getAll()
    } catch (err) {
      /* ignore */
      return
    }

    const now = Date.now()

    for (let i = 0; i < visitTasks.length; i++) {
      const task = visitTasks[i]
      const visit = visits.find((v: any) => v.code === task.visitCode)
      if (!visit) continue
      let hasChanges = false
      if (!task.startDate) {
        task.startDate = visit.startDate ?? now
        hasChanges = true
      }
      if (!task.creationTime) {
        task.creationTime = now
        hasChanges = true
      }
      if (!task.codeSpace) {
        task.codeSpace = 'FACE'
        hasChanges = true
      }
      if (!task.source) {
        task.source = 'SelfAssigned'
        hasChanges = true
      }
      if (task.template && !task.template.codeSpace) {
        task.template.codeSpace = 'FACE'
        hasChanges = true
      }
      if (hasChanges) {
        task.updateTime = now
        visit._changeTime = now
        await tx.objectStore(VisitTaskStore).put(task)
        await tx.objectStore(VisitStore).put(visit)
      }
    }
  }

  async function migratePosCodeSpace(): Promise<void> {
    const validCodeSpace = 'MDM'

    // set pos.codeSpace
    let poses
    try {
      poses = await tx.objectStore(POSStore).getAll()
    } catch (err) {
      /* ignore */
      return
    }
    for (let i = 0; i < poses.length; i++) {
      const pos = poses[i]
      if (pos.codeSpace !== validCodeSpace) {
        pos.codeSpace = validCodeSpace
        await tx.objectStore(POSStore).put(pos)
      }
    }

    // set task.productLocation.codeSpace
    let visitTasks
    try {
      visitTasks = await tx.objectStore(VisitTaskStore).getAll()
    } catch (err) {
      /* ignore */
      return
    }

    let visits
    try {
      visits = await tx.objectStore(VisitStore).getAll()
    } catch (err) {
      /* ignore */
      return
    }

    const now = Date.now()

    for (let i = 0; i < visitTasks.length; i++) {
      const task = visitTasks[i]
      if (task.productLocation && task.productLocation.codeSpace !== validCodeSpace) {
        task.productLocation.codeSpace = validCodeSpace
        task.updateTime = now
        await tx.objectStore(VisitTaskStore).put(task)

        const visit = visits.find((v: any) => v.code === task.visitCode)
        if (visit) {
          visit._changeTime = now
          await tx.objectStore(VisitStore).put(visit)
        }
      }
    }
  }

  async function migratePosCoverage(): Promise<void> {
    let pointsOfSale
    try {
      pointsOfSale = await tx.objectStore(POSStore).getAll()
    } catch (err) {
      /* ignore */
      return
    }

    const profile = (await tx.objectStore(UserProfileStore).get('Profiles.Current'))?.value
    const positionRoleCode = profile?.fieldPositionRole?.code

    for (let i = 0; i < pointsOfSale.length; i++) {
      const pos = pointsOfSale[i]
      pos.positionRoleCoverage = pos.isInCoverage === true && !!positionRoleCode ? [positionRoleCode] : []
      await tx.objectStore(POSStore).put(pos)
    }
  }

  async function migrateDictionaries(): Promise<void> {
    // clear DictionaryStore and VisitTaskTemplateStore storages, for sync service to refill it with new data
    await tx.objectStore(DictionaryStore).clear()
    await tx.objectStore(TaskTemplateStore).clear()

    // set task.taskResult.$type
    let visitTasks
    try {
      visitTasks = await tx.objectStore(TaskStore).getAll()
    } catch (err) {
      /* ignore */
      return
    }
    for (let i = 0; i < visitTasks.length; i++) {
      const task = visitTasks[i]
      if (task.taskResult?.code) {
        task.taskResult = {
          $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceSalesExpertTaskResult',
          code: task.taskResult.code,
          name: task.taskResult.name,
          content: task.taskResult?.parts?.length
            ? {
                parts: task.taskResult.parts.map((p: any) => ({
                  $type: 'PMI.BDDM.Common.TextMimeContent',
                  text: p.text,
                  type: p.type,
                })),
              }
            : undefined,
        }
        await tx.objectStore(TaskStore).put(task)
      }
    }
  }

  async function migrateSurveys(): Promise<void> {
    let surveys
    try {
      surveys = await tx.objectStore(SurveyStore).getAll()
    } catch (err) {
      /* ignore */
      return
    }

    logger.info('migrateSurveys', `Migrating ${surveys.length} surveys...`)

    for (const survey of surveys) {
      logger.info('migrateSurveys', `Migrating survey ${survey.code}`)

      survey.$type = 'PMI.FACE.BDDM.Extensions.Classes.FaceFieldForceSurvey'

      survey.startDate = new Date(2022, 0).getTime()

      survey.questionnaire = <IQuestionnaireReference>{
        $type: 'PMI.BDDM.Transactionaldata.FieldForceQuestionnaireReference',
        code: survey.questionnaireCode,
      }
      delete survey.questionnaireCode

      survey._visitCode = survey.visitCode
      delete survey.visitCode

      survey._taskCode = survey.taskCode
      delete survey.taskCode

      if (survey.answers) {
        for (const answer of survey.answers) {
          logger.info('migrateSurveys', `Migrating '${answer.answerType}' in Survey ${survey.code}`)
          answer.askDate = answer.replyDate
          switch (answer.answerType) {
            case 'BooleanAnswer':
              answer.$type = 'PMI.FACE.BDDM.Extensions.Classes.FaceBooleanAnswer'
              setBooleanAnswerReply(answer, answer.booleanAnswer)
              break
            case 'TextAnswer':
              answer.$type = 'PMI.FACE.BDDM.Extensions.Classes.FaceTextAnswer'
              if (answer.textAnswer) {
                answer.reply = answer.textAnswer
                delete answer.textAnswer
              }
              break
            case 'SelectProfileAnswer':
              answer.$type = 'PMI.FACE.BDDM.Extensions.Classes.FaceSelectProfileAnswer'
              if (answer.profileCode) {
                answer.reply = {
                  $type: 'PMI.BDDM.Transactionaldata.FieldForceQuestionnaireFlowReference',
                  code: answer.profileCode,
                  codeSpace: 'FACE',
                }
                delete answer.profileCode
              }
              break
            default:
              logger.warn('migrateSurveys', `Unknown answerType '${answer.answerType}'`)
          }
          if (answer.attachment?.parts?.length) {
            for (const part of answer.attachment.parts) {
              part.$type = 'PMI.FACE.BDDM.Extensions.Classes.FaceMimeContentRef'
              if (part.mimeType) {
                part.type = part.mimeType
                delete part.mimeType
              } else {
                part.type = 'binary/image'
              }
            }
          }
        }
      }

      await tx.objectStore(SurveyStore).put(survey)
    }
  }
}

export const refactor: IStorageSchema['refactor'] = async function (
  oldVersion,
  newVersion,
  schema,
  db,
  tx,
  logger,
): Promise<void> {
  if (oldVersion < 37 && newVersion >= 37) {
    logger.info('refactor_37', 'Renaming visit-task-* stores')
    tx.objectStore(VisitTaskStore).name = TaskStore
    tx.objectStore(VisitTaskTemplateStore).name = TaskTemplateStore
    tx.objectStore(VisitTaskReportStore).name = TaskReportStore
  }
  if (oldVersion < 52 && newVersion >= 52) {
    logger.info('refactor_52', `Recreating ${TaskTemplateStore} store`)
    // FACE-3090 Update task-templates download and store logic in IndexedDB
    db.deleteObjectStore(TaskTemplateStore)
  }
}
