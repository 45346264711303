/* eslint-disable @typescript-eslint/promise-function-async */
import { deleteDB, openDB, IDBPDatabase, IDBPTransaction } from 'idb'

import { retry, RetryPolicy } from '../../utils'
import { ICancellationToken } from '../../utils/cancellation'
import { delay } from '../../utils/delay'
import { ConsoleLogger } from '../logger'
import whenIdbReady from './safari-14-idb-fix'
import {
  EntityKey,
  RangeDef,
  IStorageSchema,
  IStorageService,
  SortOrder,
  IStorageOperations, StorageErrorCode, StorageError
} from './storage-service-api'

interface IStorageTransaction extends IStorageOperations {
  readonly done: Promise<void>
  abort: () => void
}

const OpToKeyRange: { [key: string]: (args: unknown[]) => IDBKeyRange } = {
  '=': (args: unknown[]) => IDBKeyRange.only(args[0]),
  '<': (args: unknown[]) => IDBKeyRange.upperBound(args[0], true),
  '<=': (args: unknown[]) => IDBKeyRange.upperBound(args[0], false),
  '>': (args: unknown[]) => IDBKeyRange.lowerBound(args[0], true),
  '>=': (args: unknown[]) => IDBKeyRange.lowerBound(args[0], false),
  '<=<=': (args: unknown[]) => IDBKeyRange.bound(args[0], args[1], false, false),
  '<<': (args: unknown[]) => IDBKeyRange.bound(args[0], args[1], true, true)
}

function makeKeyRange(range: RangeDef | null | undefined): IDBKeyRange | null {
  if (range) {
    const builder = OpToKeyRange[range[0]]
    if (builder) {
      return builder(range.slice(1))
    }
  }
  return null
}

const Direction: { [key: string]: IDBCursorDirection } = {
  asc: 'next',
  desc: 'prev'
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type TxRW = IDBPTransaction<unknown, any, 'readwrite'>
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type TxAny = TxRW | IDBPTransaction<unknown, any, 'readonly'>


export default class StorageService implements IStorageService {
  private static readonly __className = 'StorageService'
  private readonly logger
  private readonly _name: string
  private readonly _schema: IStorageSchema
  private _db: IDBPDatabase | null = null

  constructor(name: string, schema: IStorageSchema) {
    console.debug(`${StorageService.__className}[${name}]::ctor`)
    this.logger = new ConsoleLogger(`${StorageService.__className}[${name}]`)
    this._name = name
    this._schema = schema
    this._init()
  }

  private _init(): void {
    this.logger.debug('_init', 'Warming up database engine')
    void whenIdbReady()
  }

  private _connecting = false

  private async _connect(): Promise<IDBPDatabase> {
    if (this._db) {
      return this._db
    }
    while (this._connecting) {
      await delay(50)
    }
    if (this._db) {
      return this._db
    }

    this._connecting = true

    this.logger.debug('_connect', 'Waiting database ready')
    await whenIdbReady()

    const schema = this._schema
    this.logger.debug('_connect', 'Opening database')
    const db = await openDB(
      this._name,
      schema.version,
      {
        upgrade: async (
          db,
          oldVersion,
          newVersion,
          tx
        ) => {
          this.logger.info('_connect:upgrade', `Upgrading database from version ${oldVersion} to ${schema.version}`)
          try {
            // refactor schema (rename stores, etc...)
            if (oldVersion > 0 && schema.refactor) {
              await schema.refactor(oldVersion, newVersion ?? 0, schema, db, tx, this.logger)
            }
            // adding stores
            if (schema.stores) {
              for (const storeName in schema.stores) {
                const storeDef = schema.stores[storeName]
                let store
                if (db.objectStoreNames.contains(storeName)) {
                  store = tx.objectStore(storeName)
                } else {
                  this.logger.info('_connect:upgrade', `Creating store '${storeName}'`)
                  store = db.createObjectStore(storeName, {
                    keyPath: storeDef.keyPath,
                    autoIncrement: storeDef.autoIncrement
                  })
                }

                // adding indices
                if (storeDef.index) {
                  for (const indexName in storeDef.index) {
                    if (!store.indexNames.contains(indexName)) {
                      this.logger.info('_connect:upgrade', `Creating index '${storeName}.${indexName}'`)
                      const indexDef = storeDef.index[indexName]
                      store.createIndex(indexName, indexDef.keyPath, {
                        unique: indexDef.unique,
                        multiEntry: indexDef.multi
                      })
                    }
                  }
                }
                // removing indices
                const indicesToRemove = []
                for (let i = 0; i < store.indexNames.length; i++) {
                  const indexName: string = store.indexNames[i]
                  if (!(storeDef.index && indexName in storeDef.index)) {
                    indicesToRemove.push(indexName)
                  }
                }
                for (const indexName of indicesToRemove) {
                  this.logger.info('_connect:upgrade', `Deleting index '${storeName}.${indexName}'`)
                  store.deleteIndex(indexName)
                }
              }
            }
            // removing stores
            const storesToRemove = []
            for (let i = 0; i < db.objectStoreNames.length; i++) {
              const storeName = db.objectStoreNames[i]
              if (!(schema.stores && storeName in schema.stores)) {
                storesToRemove.push(storeName)
              }
            }
            for (const storeName of storesToRemove) {
              this.logger.info('_connect:upgrade', `Deleting store '${storeName}'`)
              db.deleteObjectStore(storeName)
            }

            // applying migration if exists
            if (oldVersion > 0 && schema.migrate) {
              await schema.migrate(oldVersion, newVersion ?? 0, schema, db, tx, this.logger)
            }

            // insert data
            if (oldVersion === 0 && schema.data) {
              for (const storeName in schema.data) {
                const store = tx.objectStore(storeName)
                const items = schema.data[storeName]
                if (items) {
                  this.logger.info('_connect:upgrade', `Adding ${items.length} items to store '${storeName}'`)
                  for (const item of items) {
                    try {
                      await store.put(item)
                    } catch (e) {
                      this.logger.warn('_connect:upgrade', `Error adding item to store '${storeName}'`, e, item)
                    }
                  }
                }
              }
            }
          } catch (e) {
            this.logger.error(
              '_connect:upgrade', `Error upgrading database from ${oldVersion} to ${schema.version}`, e
            )
            throw e
          }
        },
        blocked: () => {
          this.logger.warn(
            '_connect:blocked',
            `Cant upgrade to version ${schema.version} because current version ${db.version} is still opened`
          )
        },
        blocking: () => {
          this.logger.warn(
            '_connect:blocking',
            `Currently opened version ${db.version} prevents upgrading to version ${schema.version}`
          )
          this._close()
        },
        terminated: () => {
          this.logger.info('_connect:terminated', 'Connection terminated')
          this._db = null
        }
      }).finally(() => { this._connecting = false })

    this._db = db
    return this._db
  }

  private _close(): void {
    if (this._db) {
      this.logger.info('_close', 'Closing database')
      this._db.close()
      this._db = null
    }
  }

  public async drop(): Promise<void> {
    this._close()
    this.logger.info('drop', 'Deleting database')
    await deleteDB(this._name)
  }

  public close(): void {
    this._close()
  }

  public dispose(): void {
    this._close()
  }

  public async deleteAll(store: string): Promise<void> {
    await (await this._connect()).clear(store)
  }

  public async exists(store: string, key: EntityKey): Promise<boolean> {
    return (await (await this._connect()).count(store, key)) > 0
  }

  private _getUpdateTrigger<T>(store: string, bypass?: boolean): (item: T) => T {
    const updateTrigger = bypass === false ? this._schema.stores[store]?.updateTrigger : null
    return updateTrigger ? (item => updateTrigger(item)) : (item => item)
  }

  private async _put<T>(
    tx: TxRW,
    store: string,
    items: T[] | T,
    bypassTrigger?: boolean
  ): Promise<EntityKey[]> {
    const updateTrigger = this._getUpdateTrigger<T>(store, bypassTrigger)
    const objectStore = tx.objectStore(store)
    let result: EntityKey[]
    if (Array.isArray(items)) {
      // result = await Promise.all(items.map(item => objectStore.put(updateTrigger(item))))
      result = []
      for (const item of items) {
        result.push(await objectStore.put(updateTrigger(item)))
      }
    } else {
      result = [await objectStore.put(updateTrigger(items))]
    }
    return result
  }

  public put<T>(store: string, items: T[] | T, bypassTrigger?: boolean): Promise<EntityKey[]> {
    return this._execute(store, tx => this._put(tx as TxRW, store, items, bypassTrigger), 'rw')
  }

  public async putByKey<T>(store: string, item: T, key: EntityKey): Promise<EntityKey> {
    return await (await this._connect()).put(store, item, key)
  }

  private async _putIf<T>(
    tx: TxRW,
    store: string,
    items: T[] | T,
    keyFunc: (obj: T) => EntityKey,
    condition: (oldItem: T | undefined, newItem: T) => boolean,
    bypassTrigger?: boolean
  ): Promise<EntityKey[]> {
    const keys: EntityKey[] = []
    const updateTrigger = this._getUpdateTrigger<T>(store, bypassTrigger)
    const objectStore = tx.objectStore(store)
    for (const newItem of Array.isArray(items) ? items : [items]) {
      const key = keyFunc(newItem)
      const oldItem = (await objectStore.get(key)) as T
      if (condition(oldItem, newItem)) {
        keys.push(await objectStore.put(updateTrigger(newItem)))
      }
    }
    return keys
  }

  public putIf<T>(
    store: string,
    items: T[] | T,
    keyFunc: (obj: T) => EntityKey,
    condition: (oldItem: T | undefined, newItem: T) => boolean,
    bypassTrigger?: boolean
  ): Promise<EntityKey[]> {
    return this._execute(
      store,
      tx => this._putIf(tx as TxRW, store, items, keyFunc, condition, bypassTrigger),
      'rw'
    )
  }

  public async deleteByKey(store: string, key: EntityKey): Promise<void> {
    await (await this._connect()).delete(store, key)
  }

  public async deleteByKeyRange(store: string, range: RangeDef): Promise<void> {
    const keyRange = makeKeyRange(range)
    if (keyRange) {
      await (await this._connect()).delete(store, keyRange)
    }
  }

  private async _deleteByKeys(tx: TxRW, store: string, keys: EntityKey[]): Promise<void> {
    const objectStore = tx.objectStore(store)
    //await Promise.all(keys.map(key => objectStore.delete(key)))
    for (const key of keys) {
      await objectStore.delete(key)
    }
  }

  public async deleteByKeys(store: string, keys: EntityKey[]): Promise<void> {
    if (keys != null && keys.length > 0) {
      await this._execute(store, tx => this._deleteByKeys(tx as TxRW, store, keys), 'rw')
    }
  }

  private async _getAll<T>(tx: TxAny, store: string, order?: SortOrder): Promise<T[]> {
    if (order == null) {
      return await tx.objectStore(store).getAll()
    } else {
      const result = []
      for await (const cursor of tx.objectStore(store).iterate(null, Direction[order])) {
        result.push(cursor.value as T)
      }
      return result
    }
  }

  public getAll<T>(store: string, order?: SortOrder): Promise<T[]> {
    return this._execute(store, tx => this._getAll<T>(tx, store, order), 'r')
  }

  private async _getWhere<T>(
    tx: TxAny,
    store: string,
    predicate: (obj: T) => boolean,
    count?: number,
    order: SortOrder = 'asc'
  ): Promise<T[]> {
    const result = []
    for await (const cursor of tx.objectStore(store).iterate(null, Direction[order])) {
      const item = cursor.value as T
      if (predicate(item)) {
        result.push(item)
        if (count && result.length === count) {
          break
        }
      }
    }
    return result
  }

  public getWhere<T>(
    store: string,
    predicate: (obj: T) => boolean,
    count?: number,
    order: SortOrder = 'asc'
  ): Promise<T[]> {
    return this._execute(store, tx => this._getWhere<T>(tx, store, predicate, count, order), 'r')
  }

  private async _getFirstWhere<T>(tx: TxAny, store: string, predicate: (obj: T) => boolean): Promise<T | undefined> {
    for await (const cursor of tx.objectStore(store).iterate(null)) {
      const item = cursor.value as T
      if (predicate(item)) {
        return item
      }
    }
  }

  public getFirstWhere<T>(store: string, predicate: (obj: T) => boolean): Promise<T | undefined> {
    return this._execute(store, tx => this._getFirstWhere<T>(tx, store, predicate), 'r')
  }

  private async _existsWhere<T>(tx: TxAny, store: string, predicate: (obj: T) => boolean): Promise<boolean> {
    for await (const cursor of tx.objectStore(store).iterate(null)) {
      const item = cursor.value as T
      if (predicate(item)) {
        return true
      }
    }
    return false
  }

  public existsWhere<T>(store: string, predicate: (obj: T) => boolean): Promise<boolean> {
    return this._execute(store, tx => this._existsWhere(tx, store, predicate), 'r')
  }

  public async getByKey<T>(store: string, key: EntityKey): Promise<T | undefined> {
    return await (await this._connect()).get(store, key)
  }

  private async _getByKeys<T>(tx: TxAny, store: string, keys: EntityKey[]): Promise<T[]> {
    const items = []
    const objectStore = tx.objectStore(store)
    for (const key of keys) {
      if (key != null) {
        const entity = await objectStore.get(key)
        if (entity !== undefined) {
          items.push(entity)
        }
      }
    }
    return items
  }

  public async getByKeys<T>(store: string, keys: EntityKey[]): Promise<T[]> {
    if (keys != null && keys.length > 0) {
      return await this._execute(store, tx => this._getByKeys<T>(tx, store, keys), 'r')
    }
    return []
  }

  public async getByKeyRange<T>(store: string, range: RangeDef | null, count?: number): Promise<T[]> {
    return await (await this._connect()).getAll(store, makeKeyRange(range), count)
  }

  public async getByIndexRange<T>(store: string, index: string, range: RangeDef | null, count?: number): Promise<T[]> {
    return await (await this._connect()).getAllFromIndex(store, index, makeKeyRange(range), count)
  }

  public async getKeys<K extends EntityKey>(
    store: string,
    range: RangeDef | null,
    count?: number
  ): Promise<K[]> {
    const db = await this._connect()
    const keys = await db.getAllKeys(store, makeKeyRange(range), count)
    return (keys as K[])
  }

  public async getKeysByIndexRange<K extends EntityKey>(
    store: string,
    index: string,
    range: RangeDef | null,
    count?: number
  ): Promise<K[]> {
    const db = await this._connect()
    const keys = await db.getAllKeysFromIndex(store, index, makeKeyRange(range), count)
    return (keys as K[])
  }

  private _getOneByKeyRange<T>(store: string, range: RangeDef | null, order: SortOrder): Promise<T | undefined> {
    return this._execute(
      store,
      async tx => (await tx.objectStore(store).openCursor(makeKeyRange(range), Direction[order]))?.value as T,
      'r'
    )
  }

  public getFirstByKeyRange<T>(store: string, range: RangeDef | null): Promise<T | undefined> {
    return this._getOneByKeyRange(store, range, 'asc')
  }

  public getLastByKeyRange<T>(store: string, range: RangeDef | null): Promise<T | undefined> {
    return this._getOneByKeyRange(store, range, 'desc')
  }

  private _getOneByIndexRange<T>(
    store: string,
    index: string,
    range: RangeDef | null,
    order: SortOrder
  ): Promise<T | undefined> {
    return this._execute(
      store,
      async tx => (await tx.objectStore(store).index(index).openCursor(makeKeyRange(range), Direction[order]))?.value as T,
      'r'
    )
  }

  public getFirstByIndexRange<T>(store: string, index: string, range: RangeDef | null): Promise<T | undefined> {
    return this._getOneByIndexRange(store, index, range, 'asc')
  }

  public getLastByIndexRange<T>(store: string, index: string, range: RangeDef | null): Promise<T | undefined> {
    return this._getOneByIndexRange(store, index, range, 'desc')
  }

  private _getOneKeyByIndexRange<K extends EntityKey>(
    store: string,
    index: string,
    range: RangeDef | null,
    order: SortOrder
  ): Promise<K | undefined> {
    return this._execute(
      store,
      async tx => (await tx.objectStore(store).index(index).openKeyCursor(makeKeyRange(range), Direction[order]))?.key as K,
      'r'
    )
  }

  public getFirstKeyByIndexRange<K extends EntityKey>(
    store: string,
    index: string,
    range: RangeDef | null
  ): Promise<K | undefined> {
    return this._getOneKeyByIndexRange(store, index, range, 'asc')
  }

  public getLastKeyByIndexRange<K extends EntityKey>(
    store: string,
    index: string,
    range: RangeDef | null
  ): Promise<K | undefined> {
    return this._getOneKeyByIndexRange(store, index, range, 'desc')
  }

  private _getOneKeyByKeyRange<K extends EntityKey>(
    store: string,
    range: RangeDef | null,
    order: SortOrder
  ): Promise<K | undefined> {
    return this._execute(
      store,
      async tx => (await tx.objectStore(store).openKeyCursor(makeKeyRange(range), Direction[order]))?.key as K,
      'r'
    )
  }

  public getFirstKeyByKeyRange<K extends EntityKey>(store: string, range: RangeDef | null): Promise<K | undefined> {
    return this._getOneKeyByKeyRange(store, range, 'asc')
  }

  public getLastKeyByKeyRange<K extends EntityKey>(store: string, range: RangeDef | null): Promise<K | undefined> {
    return this._getOneKeyByKeyRange(store, range, 'desc')
  }

  public async count(store: string): Promise<number> {
    return await (await this._connect()).count(store)
  }

  private async *_selectAll<T>(tx: TxAny, store: string, order: SortOrder = 'asc'): AsyncIterableIterator<T> {
    for await (const cursor of tx.objectStore(store).iterate(null, Direction[order])) {
      yield cursor.value as T
    }
  }

  public async *selectAll<T>(store: string, order: SortOrder = 'asc'): AsyncIterableIterator<T> {
    const db = await this._connect()
    const tx = db.transaction(store, 'readonly')
    yield* this._selectAll<T>(tx, store, order)
  }

  private async *_selectByKeyRange<T>(
    tx: TxAny,
    store: string,
    range: RangeDef | null,
    order: SortOrder = 'asc'
  ): AsyncIterableIterator<T> {
    const query = makeKeyRange(range)
    for await (const cursor of tx.objectStore(store).iterate(query, Direction[order])) {
      yield cursor.value as T
    }
  }

  public async *selectByKeyRange<T>(
    store: string,
    range: RangeDef | null,
    order: SortOrder = 'asc'
  ): AsyncIterableIterator<T> {
    const db = await this._connect()
    const tx = db.transaction(store, 'readonly')
    yield* this._selectByKeyRange<T>(tx, store, range, order)
  }

  private async *_selectWhere<T>(
    tx: TxAny,
    store: string,
    predicate: (obj: T) => boolean,
    order: SortOrder = 'asc'
  ): AsyncIterableIterator<T> {
    for await (const cursor of tx.objectStore(store).iterate(null, Direction[order])) {
      const item = cursor.value as T
      if (predicate(item)) {
        yield item
      }
    }
  }

  public async *selectWhere<T>(
    store: string,
    predicate: (obj: T) => boolean,
    order: SortOrder = 'asc'
  ): AsyncIterableIterator<T> {
    const db = await this._connect()
    const tx = db.transaction(store, 'readonly')
    yield* this._selectWhere<T>(tx, store, predicate, order)
  }

  public countByKeyRange(store: string, range: RangeDef | null): Promise<number> {
    return this._execute(store, tx => tx.objectStore(store).count(makeKeyRange(range)), 'r')
  }

  private async _countWhere<T>(tx: TxAny, store: string, predicate: (obj: T) => boolean): Promise<number> {
    let count = 0
    for await (const cursor of tx.objectStore(store).iterate(null)) {
      if (predicate(cursor.value as T)) {
        count++
      }
    }
    return count
  }

  public countWhere<T>(store: string, predicate: (obj: T) => boolean): Promise<number> {
    return this._execute(store, tx => this._countWhere(tx, store, predicate), 'r')
  }

  private async *_selectByIndexRange<T>(
    tx: TxAny,
    store: string,
    index: string,
    range: RangeDef | null,
    order: SortOrder = 'asc'
  ): AsyncIterableIterator<T> {
    const query = makeKeyRange(range)
    for await (const cursor of tx.objectStore(store).index(index).iterate(query, Direction[order])) {
      yield cursor.value as T
    }
  }

  public async *selectByIndexRange<T>(
    store: string,
    index: string,
    range: RangeDef | null,
    order: SortOrder = 'asc'
  ): AsyncIterableIterator<T> {
    const db = await this._connect()
    const tx = db.transaction(store, 'readonly')
    yield* this._selectByIndexRange(tx, store, index, range, order)
  }

  public countByIndexRange(store: string, index: string, range: RangeDef | null): Promise<number> {
    return this._execute(store, tx => tx.objectStore(store).index(index).count(makeKeyRange(range)), 'r')
  }

  private async _updateWhere<T>(
    tx: TxRW,
    store: string,
    predicate: (obj: T) => boolean,
    updateFunc: (obj: T, key?: EntityKey) => T | null | undefined
  ): Promise<void> {
    const updateTrigger = this._getUpdateTrigger(store)
    for await (const cursor of tx.objectStore(store).iterate()) {
      let entity = cursor.value
      if (predicate(entity)) {
        entity = updateFunc(entity, cursor.key)
        if (entity) {
          await cursor.update(updateTrigger(entity))
        } else {
          await cursor.delete()
        }
      }
    }
  }

  public updateWhere<T>(
    store: string,
    predicate: (obj: T) => boolean,
    updateFunc: (obj: T, key?: EntityKey) => T | null | undefined
  ): Promise<void> {
    return this._execute(store, tx => this._updateWhere<T>(tx as TxRW, store, predicate, updateFunc), 'rw')
  }

  private async _updateByKeyRange<T>(
    tx: TxRW,
    store: string,
    range: RangeDef | null,
    updateFunc: (obj: T, key?: EntityKey) => T | null | undefined
  ): Promise<void> {
    const query = makeKeyRange(range)
    const updateTrigger = this._getUpdateTrigger(store)
    for await (const cursor of tx.objectStore(store).iterate(query)) {
      let entity = cursor.value
      entity = updateFunc(entity, cursor.key)
      if (entity) {
        await cursor.update(updateTrigger(entity))
      } else {
        await cursor.delete()
      }
    }
  }

  public updateByKeyRange<T>(
    store: string,
    range: RangeDef | null,
    updateFunc: (obj: T, key?: EntityKey) => T | null | undefined
  ): Promise<void> {
    return this._execute(store, tx => this._updateByKeyRange(tx as TxRW, store, range, updateFunc), 'rw')
  }

  private async _updateByIndexRange<T>(
    tx: TxRW,
    store: string,
    index: string,
    range: RangeDef | null,
    updateFunc: (obj: T, key?: EntityKey) => T | null | undefined
  ): Promise<void> {
    const query = makeKeyRange(range)
    const updateTrigger = this._getUpdateTrigger(store)
    for await (const cursor of tx.objectStore(store).index(index).iterate(query)) {
      let entity = cursor.value
      entity = updateFunc(entity, cursor.key)
      if (entity) {
        await cursor.update(updateTrigger(entity))
      } else {
        await cursor.delete()
      }
    }
  }

  public updateByIndexRange<T>(
    store: string,
    index: string,
    range: RangeDef | null,
    updateFunc: (obj: T, key?: EntityKey) => T | null | undefined
  ): Promise<void> {
    return this._execute(store, tx => this._updateByIndexRange(tx as TxRW, store, index, range, updateFunc), 'rw')
  }

  private async _transaction(stores: string[], mode: 'r' | 'rw' = 'rw'): Promise<TxAny> {
    const db = await this._connect()
    return db.transaction(stores, mode === 'rw' ? 'readwrite' : 'readonly', { durability: 'strict' })
  }

  private _wrap(tx: TxAny): IStorageTransaction {
    /* eslint-disable-next-line @typescript-eslint/no-this-alias */
    const parent = this
    return {
      get done() {
        return tx.done
      },
      abort(): void {
        tx.abort()
      },
      deleteAll(store: string): Promise<void> {
        return (tx as TxRW).objectStore(store).clear()
      },
      deleteByKey(store: string, key: EntityKey): Promise<void> {
        return (tx as TxRW).objectStore(store).delete(key)
      },
      deleteByKeys(store: string, keys: EntityKey[]): Promise<void> {
        return parent._deleteByKeys(tx as TxRW, store, keys)
      },
      async deleteByKeyRange(store: string, range: RangeDef): Promise<void> {
        const key = makeKeyRange(range)
        if (key) {
          await (tx as TxRW).objectStore(store).delete(key)
        }
      },
      count(store: string): Promise<number> {
        return tx.objectStore(store).count()
      },
      countByIndexRange(store: string, index: string, range: RangeDef | null): Promise<number> {
        return tx.objectStore(store).index(index).count(makeKeyRange(range))
      },
      countByKeyRange(store: string, range: RangeDef | null): Promise<number> {
        return tx.objectStore(store).count(makeKeyRange(range))
      },
      countWhere<T>(store: string, predicate: (obj: T) => boolean): Promise<number> {
        return parent._countWhere(tx, store, predicate)
      },
      async exists(store: string, key: EntityKey): Promise<boolean> {
        return (await tx.objectStore(store).count(key)) > 0
      },
      getByIndexRange<T>(store: string, index: string, range: RangeDef | null, count?: number): Promise<T[]> {
        return tx.objectStore(store).index(index).getAll(makeKeyRange(range), count)
      },
      getByKey<T>(store: string, key: EntityKey): Promise<T | undefined> {
        return tx.objectStore(store).get(key)
      },
      getByKeyRange<T>(store: string, range: RangeDef | null, count?: number): Promise<T[]> {
        return tx.objectStore(store).getAll(makeKeyRange(range), count)
      },
      getByKeys<T>(store: string, keys: EntityKey[]): Promise<T[]> {
        return parent._getByKeys<T>(tx, store, keys)
      },
      getWhere<T>(store: string, predicate: (obj: T) => boolean, count?: number, order?: SortOrder): Promise<T[]> {
        return parent._getWhere<T>(tx, store, predicate, count, order)
      },
      getFirstWhere<T>(store: string, predicate: (obj: T) => boolean): Promise<T | undefined> {
        return parent._getFirstWhere(tx, store, predicate)
      },
      existsWhere<T>(store: string, predicate: (obj: T) => boolean): Promise<boolean> {
        return parent._existsWhere(tx, store, predicate)
      },
      getAll<T>(store: string, order?: SortOrder): Promise<T[]> {
        return parent._getAll(tx, store, order)
      },
      getKeys<K extends EntityKey>(store: string, range: RangeDef | null, count?: number): Promise<K[]> {
        return (tx.objectStore(store).getAllKeys(makeKeyRange(range), count) as unknown) as Promise<K[]>
      },
      getKeysByIndexRange<K extends EntityKey>(store: string, index: string, range: RangeDef | null, count?: number): Promise<K[]> {
        return (tx.objectStore(store).index(index).getAllKeys(makeKeyRange(range), count) as unknown) as Promise<K[]>
      },
      async getFirstByKeyRange<T>(store: string, range: RangeDef | null): Promise<T | undefined> {
        return (await tx.objectStore(store).openCursor(makeKeyRange(range), Direction.asc))?.value as T
      },
      async getLastByKeyRange<T>(store: string, range: RangeDef | null): Promise<T | undefined> {
        return (await tx.objectStore(store).openCursor(makeKeyRange(range), Direction.desc))?.value as T
      },
      async getFirstByIndexRange<T>(store: string, index: string, range: RangeDef | null): Promise<T | undefined> {
        return (await tx.objectStore(store).index(index).openCursor(makeKeyRange(range), Direction.asc))?.value as T
      },
      async getLastByIndexRange<T>(store: string, index: string, range: RangeDef | null): Promise<T | undefined> {
        return (await tx.objectStore(store).index(index).openCursor(makeKeyRange(range), Direction.desc))?.value as T
      },
      async getFirstKeyByKeyRange<K extends EntityKey>(store: string, range: RangeDef | null): Promise<K | undefined> {
        return (await tx.objectStore(store).openKeyCursor(makeKeyRange(range), Direction.asc))?.key as K
      },
      async getLastKeyByKeyRange<K extends EntityKey>(store: string, range: RangeDef | null): Promise<K | undefined> {
        return (await tx.objectStore(store).openKeyCursor(makeKeyRange(range), Direction.desc))?.key as K
      },
      async getFirstKeyByIndexRange<K extends EntityKey>(
        store: string,
        index: string,
        range: RangeDef | null
      ): Promise<K | undefined> {
        return (await tx.objectStore(store).index(index).openKeyCursor(makeKeyRange(range), Direction.asc))?.key as K
      },
      async getLastKeyByIndexRange<K extends EntityKey>(
        store: string,
        index: string,
        range: RangeDef | null
      ): Promise<K | undefined> {
        return (await tx.objectStore(store).index(index).openKeyCursor(makeKeyRange(range), Direction.desc))?.key as K
      },
      put<T>(store: string, items: T[] | T, bypassTrigger?: boolean): Promise<EntityKey[]> {
        return parent._put<T>(tx as TxRW, store, items, bypassTrigger)
      },
      putIf<T>(
        store: string,
        items: T[] | T,
        keyFunc: (obj: T) => EntityKey,
        condition: (oldItem: T | undefined, newItem: T) => boolean,
        bypassTrigger?: boolean
      ): Promise<EntityKey[]> {
        return parent._putIf<T>(tx as TxRW, store, items, keyFunc, condition, bypassTrigger)
      },
      putByKey<T>(store: string, item: T, key: EntityKey): Promise<EntityKey> {
        return (tx as TxRW).objectStore(store).put(item, key)
      },
      selectAll<T>(store: string, order?: SortOrder): AsyncIterableIterator<T> {
        return parent._selectAll(tx, store, order)
      },
      selectByIndexRange<T>(
        store: string,
        index: string,
        range: RangeDef | null,
        order?: SortOrder
      ): AsyncIterableIterator<T> {
        return parent._selectByIndexRange<T>(tx, store, index, range, order)
      },
      selectByKeyRange<T>(store: string, range: RangeDef | null, order?: SortOrder): AsyncIterableIterator<T> {
        return parent._selectByKeyRange<T>(tx, store, range, order)
      },
      selectWhere<T>(store: string, predicate: (obj: T) => boolean, order?: SortOrder): AsyncIterableIterator<T> {
        return parent._selectWhere<T>(tx, store, predicate, order)
      },
      updateByIndexRange<T>(
        store: string,
        index: string,
        range: RangeDef | null,
        updateFunc: (obj: T, key?: EntityKey) => T | null | undefined
      ): Promise<void> {
        return parent._updateByIndexRange<T>(tx as TxRW, store, index, range, updateFunc)
      },
      updateByKeyRange<T>(
        store: string,
        range: RangeDef | null,
        updateFunc: (obj: T, key?: EntityKey) => T | null | undefined
      ): Promise<void> {
        return parent._updateByKeyRange<T>(tx as TxRW, store, range, updateFunc)
      },
      updateWhere<T>(
        store: string,
        predicate: (obj: T) => boolean,
        updateFunc: (obj: T, key?: EntityKey) => T | null | undefined
      ): Promise<void> {
        return parent._updateWhere<T>(tx as TxRW, store, predicate, updateFunc)
      }
    }
  }

  private readonly _defaultRetryPolicy: RetryPolicy = {
    retryCount: 3,
    onRetry: async (e, errorName, errorMessage) => {
      //TODO: review detection method of 'out of space' condition in various browsers
      if (
        errorName === 'QuotaExceededError'
        || errorMessage.includes('error preparing blob')
        || errorMessage.includes('disk is full')
      ) {
        this.logger.warn('_onRetry', 'Storage is out of space', e)
        throw new StorageError(StorageErrorCode.OutOfSpace, `Storage '${this._name}' is out of space`)
      }

      // Handling 'private browsing' mode restrictions in Safari
      if (errorMessage.includes('bloburls are not yet supported')) {
        this.logger.warn('_onRetry', 'Possibly encountered "Private Browsing" mode restrictions', e)
        throw new StorageError(StorageErrorCode.Restriction, `Storage '${this._name}' is restricted`)
      }

      //TODO: review method of recovery from 'broken' state in various browsers
      if (errorName === 'TransactionInactiveError') {
        this.logger.warn('_onRetry', 'Transaction error', e)
        // just retry
        return
      }

      if (errorName === 'InvalidStateError' && errorMessage.includes('connection is closing')) {
        this.logger.warn('_onRetry', 'State error', e)
        try {
          this._close()
        } catch (ex) {
          this.logger.warn('_onRetry', 'Error resetting connection', ex)
        }
        return
      }

      this.logger.warn('_onRetry', 'Non-retryable error', e)
      throw e
    }
  }

  private _execute<T>(
    store: string,
    action: (tx: TxAny) => Promise<T>,
    mode: 'r' | 'rw'
  ): Promise<T> {
    return retry(
      async () => {
        const tx = await this._transaction([store], mode)
        try {
          const result = await action(tx)
          await tx.done
          return result
        } catch (e) {
          // aborting failed transaction
          try {
            tx.abort()
            await tx.done
          } catch (e1) {
            if (e1.name === 'AbortError') {
              // expected error - ignore
            } else {
              this.logger.warn('execute', 'Error aborting transaction', e1)
            }
          }
          throw e
        }
      },
      this._defaultRetryPolicy
    )
  }

  public execute<T>(
    stores: string[],
    action: (tx: IStorageOperations) => Promise<T>,
    mode: 'r' | 'rw' = 'rw',
    ct?: ICancellationToken
  ): Promise<T> {
    return retry(
      async () => {
        const tx = this._wrap(await this._transaction(stores, mode))
        try {
          const result = await action(tx)
          await tx.done
          return result
        } catch (e) {
          // aborting failed transaction
          try {
            tx.abort()
            await tx.done
          } catch (e1) {
            if (e1.name === 'AbortError') {
              // expected error - ignore
            } else {
              this.logger.warn('execute', 'Error aborting transaction', e1)
            }
          }
          throw e
        }
      },
      this._defaultRetryPolicy,
      ct
    )
  }
}