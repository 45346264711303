import React, { useContext } from 'react'

import { Box, createStyles, InputBase, makeStyles, MenuItem, Select, withStyles } from '@material-ui/core'
import { useAsync } from 'react-use'

import { IDictionary, IDictionaryBase } from '../../../../../model/dictionary'
import { IDictionaryItem } from '../../../../../model/dictionary-item'
import { ISingSelectCell } from '../../../../../model/table-screen-item'
import { ApiContext } from '../../../../../providers'
import { useUpdateProperty } from '../../../nested/useUpdateProperty'
import { getPropertyAny } from '../../../script-tasks/propertyName'
import { CellWrapperProps, EditorItemProps } from './editorUtils'
import { TableItemContext } from './table-item'

export const TableSelect = withStyles(
  (theme) =>
    createStyles({
      root: {
        // paddingRight: 30,
        '& .MuiSelect-icon': {
          display: 'none',
        },
      },
      input: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.palette.primary.main,
        fontWeight: 500,
        fontSize: 16,
        letterSpacing: '0.15',
        padding: '0',
        '&.MuiSelect-select.MuiSelect-select': {
          paddingRight: 0,
        },
        '&.MuiSelect-selectMenu': {
          whiteSpace: 'normal',
        },
        '&:focus': {
          backgroundColor: 'inherit',
          // textDecoration: 'underline',
        },
      },
    }),
  { name: 'CustomSelect' },
)(InputBase)

const useStyles = makeStyles((theme) =>
  createStyles({
    menuItem: {
      whiteSpace: 'normal',
      letterSpacing: '0.15px',
      '&:last-child': {
        paddingBottom: 9,
      },
    },
  }),
)

export interface IDictionaryWithItems extends IDictionaryBase {
  items: IDictionaryItem[]
}

const SingleSelectCellControl: React.FC<EditorItemProps & { dictionary: IDictionary }> = ({
  col,
  row,
  i,
  dictionary,
}) => {
  const classes = useStyles()
  const editor = col
  const control = editor.control as ISingSelectCell
  const value = getPropertyAny(row, col.propertyName ?? '', { code: '', name: '' })
  const updateProperty = useUpdateProperty()
  const tableItem = useContext(TableItemContext)

  return (
    <Select
      value={value?.code}
      onChange={(evt) => {
        void updateProperty(
          `${tableItem.propertyName}.${i}.${col.propertyName}`,
          (dictionary as IDictionaryWithItems).items?.find((item) => item.code === evt.target.value),
        )
      }}
      input={<TableSelect color='primary' />}
      displayEmpty
      renderValue={(val) => {
        if (typeof val === 'string') {
          const name = (dictionary as IDictionaryWithItems).items?.find((item) => item.code === val)?.name
          if (name) return name
        }

        return control.buttonCaption ?? 'Выбрать'
      }}
    >
      {dictionary.items?.map((item) => (
        <MenuItem className={classes.menuItem} key={item.code} value={item.code}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
  )
}

export const SingleSelectCell: React.FC<EditorItemProps & CellWrapperProps> = ({ variant, ...props }) => {
  const api = useContext(ApiContext)
  const control = props.col.control as ISingSelectCell

  const dictionary = useAsync(async (): Promise<IDictionary | null> => {
    switch (control.dataSource?.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.ContextPropertyDataSource':
        return getPropertyAny<IDictionary>(props.row, control.dataSource.propertyName)
      case 'PMI.FACE.BDDM.Extensions.Classes.DictionaryReferenceDataSource':
        return await api.dictionary.getDictionaryByVersionCode(control.dataSource.dictionary.version!.code)
      default:
        return null
    }
  }, [])

  if (dictionary.loading && !dictionary.value) return <></>

  switch (variant) {
    case 'table':
      return <SingleSelectCellControl {...props} dictionary={dictionary.value!} />
    case 'portrait':
    case 'extended-row':
      return (
        <Box mr={1}>
          <SingleSelectCellControl {...props} dictionary={dictionary.value!} />
        </Box>
      )
  }
}
