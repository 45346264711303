import React from 'react'

import { Box, Typography } from '@material-ui/core'
import { useBoolean } from 'react-use'

import { Code } from '../../../../model/base'
import { getSurveysByPropertyName } from '../../../_common/hooks/useCreateSurvey'
import { useScrollToTop } from '../../../_common/hooks/useScrollToTop'
import { useScriptTaskContext } from '../../script-tasks/script-task-context'
import { AccordionArrow, AccordionCard } from '../composite-screen/accordion-card'
import { SurveyView } from '../composite-screen/surveys/survey-view'

interface IProps {
  questionnaireCode: Code
  title?: string
}

export const SimpleSurveyTaskResult: React.FC<IProps> = ({ questionnaireCode, title }) => {
  useScrollToTop()
  const localContext = useScriptTaskContext()

  const [isOpen, toggle] = useBoolean(true)

  const surveys = getSurveysByPropertyName('task.surveys', localContext)
  const questionnaire = localContext.questionnaires?.find((questionnaire) => questionnaire.code === questionnaireCode)
  const survey = surveys.find((survey) => survey.questionnaire.code === questionnaireCode)

  console.log('SimpleSurveyTaskResult', { questionnaire, survey })

  return (
    <Box p={3} pt={0}>
      <AccordionCard
        isOpen={isOpen}
        onChange={toggle}
        label={
          <>
            <AccordionArrow />
            <Typography style={{ paddingTop: 12, paddingBottom: 12 }} variant='inherit'>
              {title}
            </Typography>
            <Box width='8px'></Box>
          </>
        }
      >
        <Box width='100%'>
          <SurveyView survey={survey!} questionnaire={questionnaire!} />
        </Box>
      </AccordionCard>
    </Box>
  )
}
