import React from 'react'

import { makeStyles, Theme } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'

import { IPointOfSale } from '../../../model/pos'
import { StoreItemMap } from './store-item-map'

const useStyles = makeStyles((theme: Theme) => ({
  storeCardDialog: {
    '& .MuiDialog-paper': {
      borderRadius: 8,
    },
  },
}))

interface IStoreMobileCard {
  stores: IPointOfSale[]
  selectStoreCodes: string[]
  onClose: () => void
}

const StoreMobileCard: React.FC<IStoreMobileCard> = ({ stores, selectStoreCodes, onClose }) => {
  const classes = useStyles()
  const store = stores.find((store) => store.code === selectStoreCodes?.[0])
  if (!store) return <></>
  return (
    <Dialog className={classes.storeCardDialog} open={!!store} onClose={onClose}>
      <StoreItemMap isSelect store={store} />
    </Dialog>
  )
}

export default StoreMobileCard
