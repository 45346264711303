import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react'

import { set } from 'lodash/fp'

import { Code, CodeSpace, generateEntityCode, IEntityReference } from '../../../../model/base'
import { IContractTerm } from '../../../../model/contract-term'
import { IProblem } from '../../../../model/problem'
import { IProductMatrix } from '../../../../model/product-matrix'
import { IQuestionnaire } from '../../../../model/questionnaire'
import { ISupervisedFieldPositionRole } from '../../../../model/supervised-field-position-role'
import { ITask } from '../../../../model/task'
import { ITaskExecutionScope, ITaskExecutionState } from '../../../../model/task-execution'
import { ITaskStage } from '../../../../model/task-stage'
import { ITaskTemplate } from '../../../../model/task-template'
import { IUserProfile } from '../../../../model/user-profile'
import { ApiContext } from '../../../../providers'
import { useAsyncError } from '../../../_common/hooks/useAsyncError'
import { useAsyncRetryPromise } from '../../../_common/hooks/useAsyncRetry'
import { IWebMethodRequest, IWebMethodResult } from '../../../process/execute-web-method'
import { VisitAssessment } from '../../../tasks/audit/audit-merchandise-service-task'
import { ILocalContextService, LocalContextServiceContext } from '../../../tasks/nested/local-context'
import { IScriptTaskContext, ScriptTaskContext } from '../../../tasks/script-tasks/script-task-context'

interface ProblemContextProps {
  posCode: Code
  problem?: IProblem
}

class InMemoryProblemContextService implements ILocalContextService {
  constructor(
    public problem: IProblem = { code: generateEntityCode('prob') } as IProblem,
    readonly refetch: () => Promise<IScriptTaskContext>,
  ) {}

  inMemory?: boolean | undefined
  rootRecordName?: string | undefined
  cancelRefetch?: VoidFunction | undefined
  executeWebMethod?: ((req: IWebMethodRequest) => Promise<IWebMethodResult>) | undefined

  updateProperty = async (propertyName: string, value: unknown): Promise<void> => {
    if (!propertyName?.startsWith('problem.')) {
      throw new Error('invalid propertyName')
    }
    this.problem = set(propertyName.replace(/problem./, ''), value, this.problem)
  }

  updateStackSteps = async (): Promise<void> => {
    // do nothing
  }

  saveTask!: (task: ITask) => Promise<void>
  saveScope!: (scope: ITaskExecutionScope) => Promise<void>
  saveTaskState!: (taskState: ITaskExecutionState) => Promise<void>
  onLoad!: () => Promise<void>
  getProductMatrix!: () => Promise<IProductMatrix[]>
  getFprUserProfiles!: () => Promise<IUserProfile[]>
  getSupervisedRoleByCode!: (roleCode: Code) => Promise<ISupervisedFieldPositionRole | undefined>
  getContractTerms!: () => Promise<IContractTerm[]>
  createVisitProblem!: () => Promise<string>
  getTaskTemplateStages!: (taskCode: string, searchInSupervisedTasks: boolean) => Promise<ITaskStage[]>
  getTaskTemplateByKey!: (ref: IEntityReference) => Promise<ITaskTemplate | null>
  getTaskTemplateByTaskCode!: (taskCode: string, searchInSupervisedTasks: boolean) => Promise<ITaskTemplate | null>
  loadMediaForSupervisedVisit!: (visitCode: string) => Promise<void>
  getPosExtension!: (posCode: string) => Promise<VisitAssessment['posExtension'] | undefined>
  getQuestionnaireByKey!: (ref: IEntityReference) => Promise<IQuestionnaire | null>
  getDefaultCodeSpace!: () => CodeSpace
}

export const ProblemContextProvider: React.FC<ProblemContextProps> = (props) => {
  const { posCode, children, problem } = props
  useEffect(() => {
    console.log('ProblemContext mount', props)
  }, [])
  const api = useContext(ApiContext)
  const serviceRef = useRef<InMemoryProblemContextService>()

  const contextOps = useAsyncRetryPromise(async () => {
    const pos = await api.pos.getPos(posCode)
    if (!pos) throw new Error('no pos found')

    return {
      pos,
      problem: serviceRef.current?.problem,
    } as unknown as IScriptTaskContext
  }, [])

  useLayoutEffect(() => {
    serviceRef.current = new InMemoryProblemContextService(problem, contextOps.retry)
  }, [])

  useAsyncError(contextOps.error)
  if (contextOps.loading && !contextOps.value) return <></>

  const value = contextOps.value!

  return (
    <LocalContextServiceContext.Provider value={serviceRef.current!}>
      <ScriptTaskContext.Provider value={value}>{children}</ScriptTaskContext.Provider>
    </LocalContextServiceContext.Provider>
  )
}
