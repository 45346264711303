import React from 'react'

import AppConfig from '../../infrastructure/config-service/app-config'

export interface IConfigContext {
  config: AppConfig
}

const ConfigContext = React.createContext<IConfigContext>(undefined!)
export default ConfigContext
