import {DictionaryStore, HyperlinkStore, HyperlinkStore_type} from '../../data/schema'
import { Code } from '../../model/base'
import { IDictionary } from '../../model/dictionary'
import { IHyperlinkDescription } from '../../model/hyperlink'
import { IDictionaryService } from '../dictionary-service-api'
import { LocalStorageBaseService } from './local-storage-base-service'

export default class LocalStorageDictionaryService extends LocalStorageBaseService implements IDictionaryService
{
  async getDictionaryByVersionCode(versionCode: Code): Promise<IDictionary | null> {
    return (await this._storage.getByKey(DictionaryStore, versionCode)) ?? null
  }

  async getHyperlinks(type?: IHyperlinkDescription['type']): Promise<IHyperlinkDescription[]> {
    return type == null
      ? this._storage.getAll<IHyperlinkDescription>(HyperlinkStore)
      : this._storage.getByIndexRange<IHyperlinkDescription>(HyperlinkStore, HyperlinkStore_type, ['=', type])
  }
}
