import React from 'react'

import { createStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
  createStyles({
    answerWrapperInline: {
      backgroundColor: '#F4F8FB',
      fontSize: 16,
      lineHeight: '20px',
      padding: '14px 10px',
    },
  }),
)

const ReadOnlyText: React.FC = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.answerWrapperInline}>{children}</div>
}

export default ReadOnlyText
