import React, { useState } from 'react';

import { 
  Select,
  makeStyles,
  Theme,
  createStyles,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";

import { ItemCard } from '../../../../tasks/template-tasks/composite-screen/item-card';
import { TableSelect } from '../../../../tasks/template-tasks/composite-screen/table-item/single-select-cell';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      margin: theme.spacing(1, 0),
      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
      width: '100%'
    },
    menuItem: {
      paddingTop: 12,
      paddingBottom: 12,
      whiteSpace: 'unset',
    },
  }),
)

interface ITemplate {
  name: string
  code: string
}

interface IProps {
  itemsList: ITemplate[]
  displayName: string
  isRequired: boolean
  onChange: (value: string) => void 
}

const ProblemSelectTemplateScreenItem: React.FC<IProps> = ({itemsList, displayName, isRequired, onChange}) => {
  const { t } = useTranslation('screen-items')
  const classes = useStyles()
  const [template, setTemplate] = useState<string>('')

  const handleSingleUpdate = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setTemplate(event.target.value as string)
    onChange(event.target.value as string);
  }

  return (
    <ItemCard
      isError={isRequired && !template}
      label={<Typography variant='inherit'>{displayName}</Typography>}
    >
      <Select
        className={classes.select}
        displayEmpty
        input={<TableSelect color='primary'/>}
        onChange={handleSingleUpdate}
        renderValue={(val) => {
          const template = itemsList.find((item) => item.code === val)
          return val ? template!.name : t('choose')
        }}
      >
        {itemsList!.map((item) => (
          <MenuItem key={item.code} className={classes.menuItem} value={item.code}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </ItemCard>
  )
}

export default ProblemSelectTemplateScreenItem;