import React, { useEffect } from 'react'

import { Accordion, AccordionDetails, AccordionSummary, Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import AddIcon from '@material-ui/icons/Add'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { Link } from 'react-router-dom'

import { IActionScreenItem } from '../../../../../model/screen-item-base'
import { ProblemsPosList } from '../../../../stores/store-menu-page/problems/problems-pos-list'
import { usePropertyName } from '../../../nested/usePropertyName'

export interface IProblemsListScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.ProblemsListScreenItem'
  posCodePropertyName: string
}

interface IProblemsListScreenItemProps {
  item: IProblemsListScreenItem
  children?: never
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: {
      paddingTop: 15,
      '& .MuiPaper-elevation1': {
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
      '& .MuiAccordion-root:before': {
        display: 'none',
      },
      '& .MuiAccordionSummary-root': {
        justifyContent: 'flex-start',
        minHeight: '40px !important',
        padding: 0,
      },
      '& .MuiAccordionSummary-content.Mui-expanded, & .MuiAccordion-root.Mui-expanded': {
        margin: 0,
      },
      '& .MuiAccordionSummary-content': {
        margin: 0,
        flexGrow: 0,
      },
      '& .MuiAccordionDetails-root': {
        width: '100%',
        padding: '8px 0 20px',
      },
      '& .MuiAccordionSummary-expandIcon.Mui-expanded, & .MuiAccordionSummary-expandIcon': {
        padding: '0 !important',
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: 11,
      },
    },
    accordionTitle: {
      marginLeft: 4,
      marginRight: 6,
      fontSize: 16,
      lineHeight: '26px',
      fontWeight: 500,
    },
    accordionButton: {
      position: 'absolute',
      top: 9,
      right: 0,
      textTransform: 'none',
      fontSize: 15,
      lineHeight: '26px',
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
    accordionButtonIcon: {
      marginRight: 4,
    },
    problemListWrap: {
      width: '100%',
      '& .MuiPaper-root': {
        backgroundColor: 'white',
      },
      '& #listWrap': {
        paddingBottom: 0,
      },
    },
  })
)

export const ProblemsListScreenItem: React.FC<IProblemsListScreenItemProps> = ({ item }) => {
  const posCode = usePropertyName(item.posCodePropertyName) as string
  const classes = useStyles()

  useEffect(() => {
    console.log('ProblemsListScreenItem mount', item)
  }, [])

  return (
    <div className={classes.accordion}>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ArrowDropUpIcon />} aria-controls='tasks-content' id='tasks-header'>
          <Typography className={classes.accordionTitle}>{item.displayName}</Typography>
          <Link to={`/stores/${posCode}/addProblem`}>
            <Button className={classes.accordionButton}>
              <AddIcon className={classes.accordionButtonIcon} /> Проблема
            </Button>
          </Link>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.problemListWrap}>
            <ProblemsPosList posCode={posCode} />
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
