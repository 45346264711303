import React, { useEffect } from 'react'

import { Box, LinearProgress } from '@material-ui/core'
import { cloneDeep, uniqBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useAsync } from 'react-use'

import { EmptyMessage } from '../../../components'
import { AlignedFab } from '../../../components/validation-fab'
import { LogManager } from '../../../infrastructure/logger'
import { PageContent } from '../../../layout/page-content'
import { InlineFaceScript } from '../../../model/face-script'
import { delay } from '../../../utils'
import { IProcessContextService } from '../../process/process-context-service'
import { useLocalContextService } from '../nested/local-context'
import { IScriptTaskContext, ScriptTaskContext, useScriptTaskContext } from '../script-tasks/script-task-context'
import { showMessage } from '../script-tasks/script-toast'
import { CompositeResultScreen } from './composite-screen/composite-result-screen'
import { SimpleSurveyTaskResult } from './simple-survey/simple-survey-task-result'

interface TaskResultScreenProps {
  children?: never
  onBack: VoidFunction
}

const logger = LogManager.getLogger('TaskResultScreen')

export const TaskResultScreen: React.FC<TaskResultScreenProps> = (props) => {
  useEffect(() => {
    console.log('TaskResultScreen mount', props)
  }, [])
  const { onBack } = props
  const rootContext = useScriptTaskContext()
  const taskTemplate = rootContext.template
  const contextService = useLocalContextService()
  const { t } = useTranslation('task')

  const ops = useAsync(async () => {
    const { resultScreenScript } = taskTemplate
    if (!resultScreenScript) {
      return rootContext
    }
    const context = cloneDeep(rootContext)
    try {
      return await runResultScreenScript(resultScreenScript, context, contextService)
    } catch (err: unknown) {
      logger.error('runResultScreenScript', 'error running script', err, { resultScreenScript })
      throw err
    }
  }, [])

  if (ops.loading)
    return (
      <PageContent>
        <Box padding={3} height='80vh' display='flex' flexDirection='column' justifyContent='center'>
          <LinearProgress />
        </Box>
      </PageContent>
    )

  if (ops.error)
    return (
      <PageContent>
        <Box padding={3} height='80vh' display='flex' flexDirection='column' justifyContent='center'>
          <EmptyMessage message='Не удалось выполнить инициализирующий скрипт' stretch={false} />
          <EmptyMessage message={`Ошибка: ${ops.error.message}`} stretch={false} />
        </Box>
      </PageContent>
    )

  return (
    <ScriptTaskContext.Provider value={ops.value!}>
      <PageContent>
        <Box paddingTop={3}></Box>
        {!taskTemplate.resultScreen && <Box>no result screen</Box>}
        {taskTemplate.resultScreen?.$type === 'PMI.FACE.BDDM.Extensions.Classes.SimpleSurveyTaskResultScreen' && (
          <SimpleSurveyTaskResult
            title={taskTemplate.name}
            questionnaireCode={taskTemplate.resultScreen.questionnaire.code}
          />
        )}
        {taskTemplate.resultScreen?.$type === 'PMI.FACE.BDDM.Extensions.Classes.CompositeTaskResultScreen' && (
          <CompositeResultScreen screen={taskTemplate.resultScreen} />
        )}
        <Box paddingTop={12}></Box>
        {/* <EditCompletedTaskFab /> */}
        <AlignedFab onClick={onBack}>{t('backToTasks')}</AlignedFab>
      </PageContent>
    </ScriptTaskContext.Provider>
  )
}

// eslint-disable-next-line no-eval
const AsyncFunction: FunctionConstructor = eval('Object.getPrototypeOf(async function () {}).constructor')
export async function runResultScreenScript(
  script: InlineFaceScript,
  localContext: IScriptTaskContext,
  contextService: IProcessContextService,
): Promise<IScriptTaskContext> {
  if (!script.body) return localContext

  const service = {
    delay,
    clone: cloneDeep,
    uniqBy,
  }

  const params = {
    ...localContext,
    service,
    showMessage,
    window: 'window',
    self: 'self',
    globalThis: 'globalThis',
    getProductMatrix: contextService.getProductMatrix,
    getFprUserProfiles: contextService.getFprUserProfiles,
    getSupervisedRoleByCode: contextService.getSupervisedRoleByCode,
    getContractTerms: contextService.getContractTerms,
    getTaskTemplateStages: contextService.getTaskTemplateStages,
    getTaskTemplateByKey: contextService.getTaskTemplateByKey,
    getTaskTemplateByTaskCode: contextService.getTaskTemplateByTaskCode,
    loadMediaForSupervisedVisit: contextService.loadMediaForSupervisedVisit,
    executeWebMethod: contextService.executeWebMethod,
    getPosExtension: contextService.getPosExtension,
  }
  const fn = new AsyncFunction(...Object.keys(params), script.body)
  const res = await fn.call('this', ...Object.values(params))
  console.log('result from running result screen script', res, params.scope, script.body)
  return {
    ...localContext,
    scope: params.scope,
    task: params.task,
    surveys: params.surveys,
  }
}
