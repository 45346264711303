import { useCallback, useContext } from 'react'

import { ITask } from '../../../model/task'
import { IVisitTask } from '../../../model/visit-task'
import { IVisitTaskReport } from '../../../model/visit-task-report'
import { SyncContext } from '../../../providers'
import { useGenerateReportCustom } from '../../report-page/_utils/useGenerateReportCustom'

export function useGenerateReport(task: ITask | null | undefined): () => Promise<string | undefined> {
  const sync = useContext(SyncContext)
  const custom = useGenerateReportCustom(task?.code)

  const generateReport = useCallback(async () => {
    if (!task) {
      console.info('generateReport', 'task not provided')
      return
    }
    let pdf: Blob | undefined
    switch (task.template.version?.code) {
      default:
        pdf = await custom()
    }
    if (!pdf) return

    const reportLink = await sync.syncService.saveTaskReport(<IVisitTaskReport>{
      visitCode: (task as IVisitTask).visitCode,
      taskCode: task.code,
      data: pdf,
      creationTime: Date.now(),
      updateTime: Date.now()
    })
    return reportLink
  }, [task])

  return generateReport
}
