type ErrorCode = unknown

export interface IBaseError {
  code: ErrorCode
  message: string
  details: unknown
}

// business
export enum BusinessErrorCode {
  Unknown = 'Unknown',
  EntityNotFound = 'NotFound',
  EmptySet = 'EmptySet',
  Unauthorized = 'Unauthorized',
}

export class BusinessError<T = BusinessErrorCode> extends Error implements IBaseError {
  readonly code: T
  readonly details: unknown

  constructor(code: T, message: string, details?: unknown) {
    super(message)
    this.name = 'BusinessError'
    this.code = code
    this.details = details
  }
}

// validation
export enum ValidationErrorCode {
  Unknown,
  RequiredFieldsMissing,
  PreconditionFailed,
}

export class ValidationError extends BusinessError<ValidationErrorCode> {}

export class NetworkError extends Error {
  static readonly Offline = 'Offline'
  static readonly Canceled = 'Canceled'
  static readonly Failed = 'Failed'

  readonly code: string

  constructor(code: string, message?: string) {
    super(message)
    this.code = code
    this.name = 'NetworkError'
  }
}

export class ServiceStateError extends Error {
  static readonly Maintenance = 'Maintenance'
  static readonly UpgradeRequired = 'UpgradeRequired'

  readonly code: string

  constructor(code: string, message?: string) {
    super(message)
    this.code = code
    this.name = 'ServiceStateError'
  }
}

export class AuthError extends Error {
  static readonly Unauthorized = 'Unauthorized'
  static readonly Forbidden = 'Forbidden'

  readonly code: string

  constructor(code: string, message?: string) {
    super(message)
    this.code = code
    this.name = 'AuthError'
  }
}

export class ServiceError extends Error {
  readonly code: string
  readonly details?: unknown

  constructor(code: string, message?: string, details?: unknown) {
    super(message)
    this.code = code
    this.details = details
    this.name = 'ServiceError'
  }
}

export class UnknownError extends Error {}
