import React, { forwardRef, useEffect } from 'react'

import { IUserStep } from '../../../model/task-execution-process'
import { SelectEmployeeForAuditUserStepScreen } from '../audit/select-employee-for-audit-user-step-screen'
import { ShowTaskResultUserStepScreen } from '../audit/show-task-result-user-step-screen'
import { getContextScreens } from '../nested/local-context'
import { CompositeScreenStage } from '../template-tasks/composite-screen/composite-screen-stage'
import { CustomUserStepScreen } from '../template-tasks/custom/custom-user-step-screen'
import { PreviousTasks } from '../template-tasks/previous-tasks'
import { ReportTaskStage } from '../template-tasks/report-stage-screen/report-task-stage'
import SelectPOSStepScreen from '../template-tasks/select-pos-step-screen'
import SelectTaskTemplateScreen from '../template-tasks/select-task-template-screen'
import { SimpleSurveyTaskStage } from '../template-tasks/simple-survey/simple-survey-task-stage'
import { ScreenRef } from '../template-tasks/types'
import { useScriptTaskContext } from './script-task-context'
import { StepContext } from './step-context'

interface Props {
  step: IUserStep
  onReadyChange: (isReady: boolean) => void
  nextButton: JSX.Element | undefined
}

export const UserStepControl = forwardRef<ScreenRef, Props>(function UserStepControl(
  { step, onReadyChange, nextButton },
  ref,
) {
  const localContext = useScriptTaskContext()
  const { template, visit } = localContext

  const screen = getContextScreens(localContext)[step.screen.code]

  useEffect(() => {
    console.log('UserStepControl', step, screen)
  }, [step.screen.code])
  return (
    <StepContext.Provider value={{ step, screen }}>
      {screen.$type === 'PMI.FACE.BDDM.Extensions.Classes.SimpleSurveyUserStepScreen' && (
        <SimpleSurveyTaskStage
          ref={ref}
          propertyName={screen.propertyName}
          questionnaireCode={screen.questionnaire?.code.trim()}
          onReadyChange={onReadyChange}
        />
      )}
      {screen.$type === 'PMI.FACE.BDDM.Extensions.Classes.PreviousTasksUserStepScreen' && (
        <PreviousTasks //
          screen={screen}
          posCode={visit!.pointOfSaleCode}
          template={template}
          onReadyChange={onReadyChange}
        />
      )}
      {screen.$type === 'PMI.FACE.BDDM.Extensions.Classes.CompositeUserStepScreen' && (
        <CompositeScreenStage //
          ref={ref}
          onReadyChange={onReadyChange}
          key={step.code}
          screen={screen}
        />
      )}
      {screen.$type === 'PMI.FACE.BDDM.Extensions.Classes.CustomUserStepScreen' && (
        <CustomUserStepScreen screen={screen} onReadyChange={onReadyChange} />
      )}
      {screen.$type === 'PMI.FACE.BDDM.Extensions.Classes.ReportUserStepScreen' && (
        <ReportTaskStage ref={ref} onReadyChange={onReadyChange} screen={screen} resultFile={template.resultFile} />
      )}
      {screen.$type === 'PMI.FACE.BDDM.Extensions.Classes.ShowTaskResultUserStepScreen' && (
        <ShowTaskResultUserStepScreen onReadyChange={onReadyChange} screen={screen} />
      )}
      {screen.$type === 'PMI.FACE.BDDM.Extensions.Classes.SelectEmployeeForAuditUserStepScreen' && (
        <SelectEmployeeForAuditUserStepScreen ref={ref} onReadyChange={onReadyChange} screen={screen} />
      )}
      {screen.$type === 'PMI.FACE.BDDM.Extensions.Classes.SelectFieldForceTaskTemplateScreen' && (
        <SelectTaskTemplateScreen onReadyChange={onReadyChange} screen={screen} />
      )}
      {screen.$type === 'PMI.FACE.BDDM.Extensions.Classes.SelectPOSScreen' && (
        <SelectPOSStepScreen ref={ref} onReadyChange={onReadyChange} screen={screen} />
      )}
      {nextButton && React.cloneElement(nextButton, undefined, step?.nextStepButtonTitle ?? 'Далее')}
    </StepContext.Provider>
  )
})
