import React, { useContext } from 'react'

import { MenuItemDTO } from '../../model/menu-item'
import { ProfileContext } from '../../providers'

interface IMenuContext {
  bottomMenuItems: MenuItemDTO[]
  permittedMenuItems: MenuItemDTO[]
  useBottomMenu: boolean
}

export const MenuContext = React.createContext({
  permittedMenuItems: [],
  bottomMenuItems: [],
  useBottomMenu: false,
} as IMenuContext)

export const MenuContextProvider: React.FC = ({ children }) => {
  const profile = useContext(ProfileContext)

  // const useBottomMenu = true
  const useBottomMenu = profile.value.profile.mobileMenuStyle === 'BottomMenuString'

  const permittedMenuItems = profile.menuItems

  const bottomMenuItems = permittedMenuItems
    // .filter((item) => item.bottomNavigation)
    .slice(0, 3)

  const value: IMenuContext = {
    permittedMenuItems,
    bottomMenuItems,
    useBottomMenu,
  }

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>
}
