import React, { useContext, useEffect, useState } from 'react'

import { CircularProgress, createStyles, IconButton, makeStyles, Menu, MenuItem, Theme } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { isEmpty } from 'lodash'
import { useAsyncFn } from 'react-use'

import { BlobStorageErrorCode } from '../../../../infrastructure/blob-storage/blob-storage-api'
import { IMediaStorageSettings } from '../../../../model/screen-item'
import { DOWNLOAD_MEDIA_CONTENT } from '../../../../model/user-profile'
import { ApiContext } from '../../../../providers'
import { appToast } from '../../../../utils'
import { useHasPermission } from '../../../admin/permissions/permisson'
import { mapError } from '../../load-error-icon'
import { getFileNameByRoute } from '../document-thumbnail-item/utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconWrapper: {
      display: 'flex',
      marginTop: -2,
      marginBottom: -2,
    },
  }),
)

interface IZipDocumentsButton {
  ids: string[]
  storage: IMediaStorageSettings
}

export const ZipDocumentsMenu: React.FC<IZipDocumentsButton> = ({ ids, storage }) => {
  const classes = useStyles()
  const api = useContext(ApiContext)
  const blobStorage = api.blobStorage
  const blobWebApi = api.blobWebApi

  const hasPermission = useHasPermission()

  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement>()
  const handleCloseMenu = (): void => setMenuAnchor(undefined)

  useEffect(() => {
    console.log('ZipDocumentsButton mount', ids)
  }, [])

  const [{ loading }, runZip] = useAsyncFn(
    async () => {
      if (!ids.length) {
        appToast.info('Список файлов пуст')
        return
      }

      const zip = new JSZip()

      try {
        for (const id of ids) {
          let image: Blob | undefined

          switch (storage?.$type) {
            case 'PMI.FACE.BDDM.Extensions.Classes.CloudMediaStorageSettings':
              image = await blobWebApi.get(`blobapi/${storage.routePath ?? ''}`, id)
              break
            case 'PMI.FACE.BDDM.Extensions.Classes.LocalMediaStorageSettings':
            default:
              image = await blobStorage!.getBlob(id, true)
              break
          }

          if (image) {
            const fileName = getFileNameByRoute(id)
            zip.file(fileName, image)
          }
        }
      } catch (error) {
        if (error.code !== BlobStorageErrorCode.NotFound) {
          appToast.error(mapError(error))
        }
      }

      if (isEmpty(zip.files)) {
        appToast.info('Файлов для скачивания не найдено')
        return
      }

      try {
        await zip.generateAsync({ type: 'blob' }).then(function (content) {
          saveAs(content, 'content.zip')
        })
      } catch (e) {
        appToast.error(
          'При формировании архива произошла ошибка. Попробуйте выйти из приложения и войти повторно или обратитесь в техническую поддержку',
        )
      }
    },
    [ids],
    { loading: false },
  )

  if (!hasPermission(DOWNLOAD_MEDIA_CONTENT)) {
    return <></>
  }

  if (!ids.length) {
    return <></>
  }

  return (
    <div className={classes.iconWrapper}>
      <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={handleCloseMenu}>
        <MenuItem
          onClick={() => {
            handleCloseMenu()
            void runZip()
          }}
        >
          Скачать архив
        </MenuItem>
      </Menu>
      {loading ? (
        <CircularProgress size={30} />
      ) : (
        <IconButton size='small' onClick={(ev) => setMenuAnchor(ev.currentTarget)}>
          <MoreVertIcon />
        </IconButton>
      )}
    </div>
  )
}
