import platform from 'platform'
import { Workbox } from 'workbox-window'

import { delay } from '../../utils/delay'
import { APP_VERSION, BUILD_INFO } from '../../version'
import IAuthService from '../auth/auth-service-api'
import { LogManager } from '../logger'
import { IUserProfileService } from '../user-profile'
import { IUserMetricService } from './user-metric-service-api'

interface IGitVersion {
  hash: string
  date: string
  branch: string
  // author: string
}

interface IUserLoginInfo {
  userName: string
  timestamp: Date
  appVersion?: string
  browser: {
    kind: 'Chrome' | 'Safari' | 'Firefox' | string
    version: string
    os: string
  }
  build?: IGitVersion
  screen: {
    height: number
    width: number
  }
}

export default class UserMetricService implements IUserMetricService {
  private readonly logger = LogManager.getLogger('UserMetricService')

  constructor(private readonly auth: IAuthService) {}

  async userPreLogIn(userLogin: string): Promise<void> {
    const info = await this.getUserLoginInfo()
    info.userName = userLogin
    this.logger.userMetric('userPreLogin', 'User session start', info)
  }

  async userLogIn(): Promise<void> {
    const info = await this.getUserLoginInfo()
    this.logger.userMetric('userLogin', 'User logged in', info)
  }

  async userSync(userProfile: IUserProfileService): Promise<void> {
    const start = await userProfile.getLastSyncAttempt()
    const end = await userProfile.getLastSyncSuccess()
    if (start && end) {
      const durationInMilliseconds = end.valueOf() - start.valueOf()
      const durationInSeconds = Math.round(durationInMilliseconds / 1000)
      this.logger.userMetric('userSync', 'Data Synchronization finished', { durationInSeconds })
    }
  }

  private async getUserLoginInfo(): Promise<IUserLoginInfo> {
    //const build = await this.getGitVersion()
    const build = BUILD_INFO
    //const appVersion = await this.getAppVersion()
    const appVersion = APP_VERSION

    return {
      userName: this.auth.currentUserName ?? '',
      timestamp: new Date(),
      appVersion,
      browser: {
        kind: platform.name ?? '',
        version: platform.version ?? '',
        os: platform.os?.toString() ?? ''
      },
      build,
      screen: {
        width: window.screen.width,
        height: window.screen.height
      }
    }
  }

  // deprecated
  private async getGitVersion(): Promise<IGitVersion | undefined> {
    try {
      const res = await fetch('/assets/git-info.json', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
      const { author: _, ...config } = await res.json()
      return config
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        // this file likely doesn't exist in dev, ignore
        this.logger.debug('getUserLoginInfo', "can't get git version", error)
      } else {
        this.logger.error('getUserLoginInfo', "can't get git version", error)
      }
    }
  }

  // deprecated
  private async getAppVersion(): Promise<string | undefined> {
    try {
      const wb = new Workbox('/service-worker.js')
      await wb.register()
      const swVersion = await Promise.race<string>([
        wb.messageSW({ type: 'GET_VERSION' }),
        delay(15000).then(() => "can't resolve app version")
      ])
      return swVersion
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        // no service worker in dev
        this.logger.debug('getAppVersion', "can't get app version", error)
      } else {
        this.logger.error('getAppVersion', "can't get app version", error)
      }
    }
  }
}
