import { IDTEProgram } from '../../../../model/dte-program'

export type ProgramStatus = 'Все' | 'Действующая' | 'Не начата' | 'Недействующая'

export const STATUS_LIST_WITH_ORDER: ProgramStatus[] = ['Все', 'Действующая', 'Не начата', 'Недействующая']

export function sortProgramsByStatus(a: ProgramStatus, b: ProgramStatus): number {
  if (!a || !b) {
    return -1
  }
  return STATUS_LIST_WITH_ORDER.indexOf(a) - STATUS_LIST_WITH_ORDER.indexOf(b)
}

export function getProgramStatus(program: IDTEProgram): ProgramStatus {
  const today = +new Date()

  if (program.validFrom >= today) {
    return 'Не начата'
  }

  if (program.validTo <= today) {
    return 'Недействующая'
  }

  return 'Действующая'
}
