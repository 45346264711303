import { IHyperlinkDescription } from '../../../../model/hyperlink'
import { checkPredicate } from '../../../../model/script-predicate'
import { IScriptTaskContext } from '../../../tasks/script-tasks/script-task-context'

export const checkAvailability = (link: IHyperlinkDescription, context: IScriptTaskContext): boolean => {
  const visible = link.visible
  if (visible) {
    return checkPredicate(visible, undefined, context)
  }

  return true
}
