import React from 'react'

import {
  createStyles,
  Fab,
  FormControl,
  FormControlLabel,
  Icon,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import DoneIcon from '@material-ui/icons/Done'
import DonutLargeIcon from '@material-ui/icons/DonutLarge'

import { getAppFrameStyle } from '../../assets/pm-shared-styles'
import { PageContent } from '../../layout/page-content'
import { TitleBar } from '../../layout/title-bar'
import { FieldForceTaskCancelationReason, VisitCancelationReason } from '../../model/dictionary-item'
import { ItemCard } from './template-tasks/composite-screen/item-card'

const DECLINE_COLOR = '#D32F2F'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appFrame: getAppFrameStyle(theme),
    item: {
      cursor: 'pointer',
    },
    saveButton: {
      color: 'white',
      position: 'fixed',
      zIndex: 9999,
      bottom: '3%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    label: {
      margin: 0,

      width: '100%',
      // minHeight: 40,
      marginBottom: 16,
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      '& .MuiFormControlLabel-label': {
        // paddingTop: 8,
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '24px',
      },
      '&:last-child': {
        marginBottom: 0,
      },
    },
    removeButton: {
      // backgroundColor: DECLINE_COLOR,
      position: 'fixed',
      zIndex: 9999,
      bottom: 32,
      right: 24,
      textTransform: 'none',
      '&:hover': {
        // backgroundColor: DECLINE_COLOR,
      },
      [theme.breakpoints.down('xs')]: {
        right: '50%',
        transform: 'translateX(50%)',
      },
    },
    wrapper: {
      marginBottom: 100,
    },
    form: {
      width: '100%',
    },
    formTitle: {
      padding: '14px 26px',
      minHeight: 56,
      fontWeight: 400,
      fontSize: 22,
      lineHeight: '28px',
      letterSpacing: '0.15px',
    },
    formTitleError: {
      boxShadow: `2px 0px 0px 0px ${DECLINE_COLOR} inset`,
    },
    titleWrap: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: 8,
      padding: '17px 26px',
      fontSize: 16,
      lineHeight: '24px',
    },
    titleName: {
      minWidth: 250,
      color: '#A7AEB9',
      paddingRight: 32,
      [theme.breakpoints.down('xs')]: {
        minWidth: '100%',
      },
    },
    titleValue: {
      fontWeight: 500,
    },
    radio: {
      padding: 0,
      marginLeft: 10,
    },
  }),
)

interface IVisitTaskListProps<T> {
  title?: string
  titleList?: string
  titleBar?: string
  isError: boolean
  onClickItem: (item: T) => void
  onBackHandler: () => void
  onRemoveHandler: () => void
  dictionary: T[]
}

const ReasonCancellationList: React.FC<IVisitTaskListProps<FieldForceTaskCancelationReason | VisitCancelationReason>> =
  ({ title, titleList, titleBar, isError, onClickItem, onBackHandler, onRemoveHandler, dictionary }) => {
    const classes = useStyles()

    return (
      <div className={classes.appFrame}>
        <TitleBar onBack={onBackHandler}>{titleBar ?? 'Отмена'}</TitleBar>
        <PageContent>
          <Box p={3}>
            {dictionary.length ? (
              <div>
                {/*{title && (*/}
                {/*  <Paper variant='outlined' square className={classes.titleWrap}>*/}
                {/*    <div className={classes.titleName}>Название задачи</div>*/}
                {/*    <div className={classes.titleValue}>{title}</div>*/}
                {/*  </Paper>*/}
                {/*)}*/}
                <ItemCard isError={isError} label={titleList ?? 'Выберите причину отмены'}>
                  <FormControl className={classes.form}>
                    <RadioGroup aria-labelledby='demo-radio-buttons-group-label' name='radio-buttons-group'>
                      {dictionary.map((item) => (
                        <FormControlLabel
                          key={item.code}
                          className={classes.label}
                          value={item.name}
                          control={<Radio className={classes.radio} color='primary' />}
                          label={item.name}
                          color='secondary'
                          labelPlacement='start'
                          onClick={() => {
                            onClickItem(item)
                          }}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </ItemCard>
              </div>
            ) : (
              <Typography>Нет доступных причин отмены</Typography>
            )}
            <Fab
              className={classes.removeButton}
              onClick={onRemoveHandler}
              color='primary'
              aria-label='submit'
              variant='extended'
            >
              <Icon style={{ marginRight: '9px' }}>{!isError ? <DoneIcon /> : <DonutLargeIcon />}</Icon>
              Готово
            </Fab>
          </Box>
        </PageContent>
      </div>
    )
  }

export default ReasonCancellationList
