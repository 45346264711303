import React, { useContext, useEffect } from 'react'

import { Box, Button, createStyles, IconButton, SvgIcon, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import { useTranslation } from 'react-i18next'

import { ReactComponent as PencilIcon } from '../../assets/icons/pencil.svg'
import { BooleanAnswer, IAnswer } from '../../model/answer'
import { AnswerReplyCommentRequirements } from '../../model/answer-reply-requirements'
import { IQuestion } from '../../model/question'
import { ModalContext } from '../../providers/modal'
import { OpenableTypography } from '../tasks/components/openable-typography'
import AnswerResolution from '../tasks/sales-expert/questionnaire/components/answer-resolution'
import { isCommentDisabled } from './answer-requirements-utils'
import CommentModal from './comment-modal'
import ReadOnlyText from './read-only-text'
import { isNullAnswer } from './utils'

const SUCCESS_COLOR = '#388E3C'
const DECLINE_COLOR = '#D32F2F'
const NA_COLOR = '#3870B2'
const BUTTON_BORDER_RADIUS = 28
const BUTTON_WIDTH = 70

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    actions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    successButton: {
      marginRight: theme.spacing(1),
      borderColor: SUCCESS_COLOR,
      color: SUCCESS_COLOR,
      borderRadius: BUTTON_BORDER_RADIUS,
      width: BUTTON_WIDTH,
    },
    declineButton: {
      marginRight: theme.spacing(1),
      borderColor: DECLINE_COLOR,
      color: DECLINE_COLOR,
      borderRadius: BUTTON_BORDER_RADIUS,
      width: BUTTON_WIDTH,
    },
    naButton: {
      borderColor: NA_COLOR,
      color: NA_COLOR,
      borderRadius: BUTTON_BORDER_RADIUS,
      width: BUTTON_WIDTH,
    },
    answerWrapper: {
      display: 'flex',
      alignItems: 'center',
      minHeight: 42,
    },
    comment: {
      marginLeft: 'auto',
      padding: theme.spacing(0, 1),
    },
  }),
)

interface ISurveyQuestionProps {
  disabled?: boolean
  commentReq?: AnswerReplyCommentRequirements
  title: string
  description?: React.ReactNode
  answerResolutionValue?: boolean | null
  comment: string | null
  onBooleanAnswer: (resolution: boolean | null) => void
  onSaveComment: (comment: string) => void
  naEnabled?: boolean
  onNullAnswer: () => void
  answer?: IAnswer
  question: IQuestion
}

export const BooleanQuestionActions: React.FC<ISurveyQuestionProps> = ({
  disabled = false,
  commentReq,
  title = '',
  description,
  comment = null,
  onBooleanAnswer,
  onSaveComment,
  naEnabled,
  onNullAnswer,
  answer,
  question,
}) => {
  const answerResolutionValue = answer ? (answer as BooleanAnswer).booleanAnswer ?? null : undefined
  const hasAnswer = answerResolutionValue !== undefined && !isNullAnswer(answer)
  const classes = useStyles()
  const modalContext = useContext(ModalContext)

  const { t } = useTranslation('sales-expert-tasks')

  useEffect(() => {
    if (disabled) return
    if (answer) return
    if (question?.required === false) {
      onNullAnswer()
    }
  }, [answerResolutionValue])

  const renderAnswerButtons = (): JSX.Element => {
    if (!hasAnswer) {
      return (
        <div className={classes.actions}>
          <Button
            className={classes.successButton}
            size='large'
            variant='outlined'
            onClick={() => onBooleanAnswer(true)}
          >
            {t('common:yes')}
          </Button>
          <Button
            className={classes.declineButton}
            size='large'
            variant='outlined'
            onClick={() => onBooleanAnswer(false)}
          >
            {t('common:no')}
          </Button>
          {naEnabled && (
            <Button //
              className={classes.naButton}
              size='large'
              variant='outlined'
              onClick={() => onBooleanAnswer(null)}
            >
              {t('questionNoAnswer')}
            </Button>
          )}
        </div>
      )
    } else {
      return (
        <div className={classes.answerWrapper}>
          {<AnswerResolution resolution={answerResolutionValue} />}

          <OpenableTypography className={classes.comment}>{comment}</OpenableTypography>
          <Box>{renderCommentButton()}</Box>
        </div>
      )
    }
  }

  const openHandler = (): void => {
    modalContext.open(
      <CommentModal //
        open
        isCommentRequired={commentReq === AnswerReplyCommentRequirements.Required}
        comment={comment}
        titleDescription={title}
        onSave={(comment) => {
          onSaveComment(comment)
          modalContext.close()
        }}
        onClose={modalContext.close}
      />,
    )
  }

  const renderCommentButton = (): JSX.Element => {
    if (disabled || isCommentDisabled(commentReq)) return <div></div>
    if (comment?.trim()) {
      return (
        <IconButton //
          edge='end'
          className={classes.button}
          color='primary'
          aria-label='change-comment'
          onClick={openHandler}
        >
          <SvgIcon component={PencilIcon} />
        </IconButton>
      )
    } else {
      return (
        <Button //
          className={classes.button}
          variant='text'
          color='primary'
          startIcon={<AddIcon />}
          onClick={openHandler}
        >
          {t('commentAction')}
        </Button>
      )
    }
  }

  return (
    <>
      {description && (
        <Box letterSpacing='0.25px' mb={2} whiteSpace='pre-wrap'>
          <Typography variant='body2'>{description}</Typography>
        </Box>
      )}
      {isNullAnswer(answer) && disabled ? (
        <ReadOnlyText>{t('questionAnswerNotGiven')}</ReadOnlyText>
      ) : (
        renderAnswerButtons()
      )}
    </>
  )
}
