import React from 'react'

import { Box } from '@material-ui/core'
import MonacoEditor from '@monaco-editor/react'
import zodToJsonSchema from 'zod-to-json-schema'

import { IEntityReference } from '../../../../../../model/base'
import { ScriptPredicate } from '../../../../../../model/script-predicate'
import { getMonacoEditorOptions } from '../../../../../admin/_common/monaco-options'
import { FaceScriptReference } from '../../../../../admin/composite-screen-builder/items/common-schemas'

interface IScriptPredicateControl {
  value: ScriptPredicate
  onChange?: (value: ScriptPredicate) => void
  readonly?: boolean
}

export const createScriptPredicate = (value: IEntityReference): ScriptPredicate => ({
  $type: 'PMI.FACE.BDDM.Extensions.Classes.ScriptPredicate',
  script: value,
})

export const ScriptPredicateControl: React.FC<IScriptPredicateControl> = ({ value, onChange, readonly = false }) => {
  return (
    <Box height='150px'>
      <MonacoEditor
        language='json'
        value={JSON.stringify(value.script, undefined, 2)}
        onChange={(value) => {
          const entity = value?.trim() ? JSON.parse(value) : undefined
          onChange?.(createScriptPredicate(entity))
        }}
        options={getMonacoEditorOptions({
          readOnly: readonly,
        })}
        beforeMount={(monaco) => {
          monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
            validate: true,
            schemaValidation: 'error',
            schemas: [
              {
                uri: 'face://model-schema',
                fileMatch: ['*'],
                schema: zodToJsonSchema(FaceScriptReference),
              },
            ],
          })
        }}
      />
    </Box>
  )
}
