import React, { useEffect, useState } from 'react'

import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Icon,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import DonutLargeIcon from '@material-ui/icons/DonutLarge'
import { useCounter } from 'react-use'

import { getAppFrameStyle } from '../../../assets/pm-shared-styles'
import { BlobStorageError } from '../../../infrastructure/blob-storage/blob-storage-api'
import { LogManager } from '../../../infrastructure/logger'
import { IMediaStorageSettings } from '../../../model/screen-item'
import { appToast } from '../../../utils'
import { isIosDevice } from '../../../utils/is-ios-device'
import { DocumentViewer } from '../../viewers/document-viewer/document-viewer'
import { AttentionModal } from '../components/attention-modal/attention-modal'
import { DocumentAppBar } from '../components/document-app-bar/document-app-bar'
import { DocumentThumbnailItem } from '../components/document-thumbnail-item/document-thumbnail-item'
import { PhotoInput } from '../components/photo-input/photo-input'
import { useOptimizeBlobImage } from '../hooks'
import { useDocumentMethods } from '../hooks/use-document-methods'
import { createPhotoItem, getErrorToast, ITempFileItem, useFetchFilesByKeys } from '../utils'
import PhotoLoad from './photo-load'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      zIndex: 2,
      top: 0,
      left: 0,
      width: '100%',
      minHeight: '100%',
      background: 'inherit',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.default,
    },
    appFrame: getAppFrameStyle(theme),
    mediaList: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: theme.spacing(-1),
      padding: theme.spacing(3),
      paddingTop: 112,
      paddingBottom: 100,
      [theme.breakpoints.down('xs')]: {
        margin: -10,
        paddingTop: 85,
      },
    },
    submit: {
      position: 'absolute',
      zIndex: 9999,
      bottom: 32,
      right: 24,
      minWidth: 113,
      textTransform: 'none',
      [theme.breakpoints.down('xs')]: {
        right: '50%',
        transform: 'translateX(50%)',
      },
    },
    emptyList: {
      width: '100%',
      height: 'calc(100vh - 168px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
)

export interface IPhotoDialog {
  title: string
  isShow?: boolean
  showCameraOnOpen?: boolean
  inboxIds?: string[]
  minOccurs: number
  maxOccurs: number
  onClose: () => void
  onSubmit: (newPhotos: string[]) => void
  isReadOnly: boolean | undefined | null
  allowAddPhoto: boolean
  permissionsAddPhoto: boolean
  nullValueCaption?: string
  allowDeletePhoto: boolean
  storage?: IMediaStorageSettings
}

const logger = LogManager.getLogger('PhotoDialog')

const PhotoDialog: React.FC<IPhotoDialog> = ({
  title,
  isShow = false,
  showCameraOnOpen = false,
  inboxIds,
  minOccurs,
  maxOccurs,
  onClose,
  onSubmit,
  isReadOnly = false,
  allowAddPhoto,
  permissionsAddPhoto,
  nullValueCaption,
  allowDeletePhoto,
  storage,
}) => {
  const [scroll, setScroll] = useState(window.scrollY)
  useEffect(() => {
    if (isIosDevice()) {
      if (isShow) {
        setScroll(window.scrollY)
        setTimeout(() => {
          document.body.style.position = 'fixed'
        }, 200)
      } else {
        document.body.style.position = 'static'
        window.scrollTo(0, scroll)
      }
    }
  }, [isShow])

  const onCloseHandled = (): void => {
    onClose()
  }

  if (minOccurs < 0) {
    throw new Error('Минимальное количество фотографий для выбора пользователем должно быть равное или больше нуля')
  }
  if (minOccurs > maxOccurs) {
    throw new Error('Минимальное количество фотографий для выбора пользователем должно быть меньше максимального')
  }
  const [isSelectMode, setIsSelectMode] = useState<boolean>(false)
  const [checkedList, setCheckedList] = useState<string[]>([])
  const [isConfirmation, setIsConfirmation] = useState<boolean>(false)
  const [isLoadingToDB, setIsLoadingToDB] = useState<boolean>(false)
  const [downloadError, setDownloadError] = useState<BlobStorageError>()

  const classes = useStyles()

  const optimizeBlobImage = useOptimizeBlobImage()
  const { addBlob } = useDocumentMethods(storage)

  const [files, setFiles] = useState<ITempFileItem[]>([])
  const [filesBeAdded, setFilesBeAdded] = useState<ITempFileItem[]>([])
  const [isChanges, setIsChanges] = useState<boolean>(false)

  const { value: loadItems = [] } = useFetchFilesByKeys(inboxIds)
  const [retryCount, { inc: retryInc }] = useCounter(0)
  const retry = (): void => {
    setDownloadError(undefined)
    retryInc()
  }

  useEffect(() => {
    setFiles(loadItems)
  }, [loadItems])

  const checkIsNoValidCountPhotos = (): false | string => {
    if (files.length < minOccurs) {
      return `К вопросу необходимо прикрепить минимум ${minOccurs} фото`
    }
    if (files.length > maxOccurs) {
      return `К вопросу необходимо прикрепить не более ${maxOccurs} фото`
    }
    return false
  }

  const [isAttentionModal, setIsAttentionModal] = useState<boolean>(false)
  const [openedPhoto, setOpenedPhoto] = useState<ITempFileItem | null>(null)
  const onLongPress = (): void => {
    if (isReadOnly) {
      return
    }
    if (!isSelectMode) {
      setIsSelectMode(true)
    }
  }

  const onChangeInput = (file: Blob | undefined): void => {
    if (file) {
      const newFile = createPhotoItem(file)

      setFilesBeAdded([...filesBeAdded, newFile])
      setFiles((oldFiles) => [newFile, ...oldFiles])
      setIsChanges(true)
    }
  }

  const onClickSubmit = async (): Promise<void> => {
    const noValidCountPhotoError = checkIsNoValidCountPhotos()

    if (noValidCountPhotoError) {
      setIsAttentionModal(true)
      return
    }

    if (files.length) {
      setIsLoadingToDB(true)

      try {
        const resultKeys = await Promise.all(
          files.map(async (file) => {
            if (filesBeAdded.find((fileBeAdded) => fileBeAdded === file)) {
              const optimizedImage = await optimizeBlobImage(file.blob!)
              const metadata = await addBlob(optimizedImage)

              return metadata.key
            } else {
              return file.metadata.key
            }
          }),
        )

        onCloseHandled()
        onSubmit(resultKeys)
      } catch (err) {
        logger.error('onClickSubmit', 'Error adding photos', err)
        getErrorToast(err as BlobStorageError)
      } finally {
        setIsLoadingToDB(false)
      }
    } else {
      onCloseHandled()
      onSubmit([])
    }
  }

  const onClickDelete = (id: string): void => {
    if (!allowDeletePhoto) {
      if (inboxIds?.includes(id)) {
        appToast.info('Вы не можете удалять файлы сделанные не вами')
        return
      }
    }

    setOpenedPhoto(null)
    if (!inboxIds) {
      return
    }
    const result = files.filter((file) => file.metadata.key !== id)
    setFiles(result)
    setIsChanges(true)
  }

  const onUpdateHandler = async (file: Blob): Promise<void> => {
    const oldKey = openedPhoto?.metadata.key
    if (!oldKey) return
    const removeIndex = files.findIndex((file) => file.metadata.key === oldKey)
    const newFile = createPhotoItem(file)
    files.splice(removeIndex, 1, newFile)
    setFilesBeAdded([...filesBeAdded, newFile])
    setFiles([...files])

    setIsChanges(true)
    setOpenedPhoto(newFile)
  }

  if (!isShow) {
    return <></>
  }

  const renderWrapperInput = (): JSX.Element => {
    if (!allowAddPhoto && files.length === 0) {
      return <Typography className={classes.emptyList}>{nullValueCaption ?? 'Список фотографий пуст'}</Typography>
    }

    if (!permissionsAddPhoto && files.length === 0) {
      return (
        <Typography className={classes.emptyList}>
          Добавление фотографий возможно только с мобильного устройства
        </Typography>
      )
    } else {
      return (
        <PhotoInput
          onChange={onChangeInput}
          showCameraOnOpen={showCameraOnOpen}
          permissionsAddPhoto={permissionsAddPhoto}
          allowAddPhoto={allowAddPhoto}
        />
      )
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.appFrame}>
        <DocumentAppBar
          title={title}
          onBack={() => {
            if (isChanges) {
              setIsConfirmation(true)
            } else {
              onCloseHandled()
            }
          }}
          isReadOnly={!files.length ? true : isReadOnly}
          isSelectMode={isSelectMode}
          onCancel={() => {
            setIsSelectMode(false)
            setCheckedList([])
          }}
          onDeleteSelected={() => {
            if (!allowDeletePhoto) {
              for (const id of checkedList) {
                if (inboxIds?.includes(id)) {
                  appToast.info('Вы не можете удалять файлы сделанные не вами')
                  return
                }
              }
            }

            const difference = files.filter((file) => {
              return !checkedList.includes(file.metadata.key)
            })

            setIsSelectMode(false)
            setFiles(difference)
            setIsChanges(true)
          }}
          onSelectAll={() => {
            setIsSelectMode(true)
            setCheckedList(files.map((file) => file.metadata.key))
          }}
          downloadImageError={downloadError}
        />
        <Box className={classes.mediaList}>
          {!isReadOnly && renderWrapperInput()}
          {isReadOnly && !files.length ? <Typography className={classes.emptyList}>Список пуст</Typography> : <></>}
          {files?.map((photo) => {
            const isChecked = !!checkedList.find((id) => id === photo.metadata.key)
            return (
              <DocumentThumbnailItem
                key={photo.metadata.key + String(retryCount)}
                isSelectMode={isSelectMode}
                isChecked={isChecked}
                item={photo}
                onClickItem={() => {
                  if (!isSelectMode) {
                    setOpenedPhoto(photo)
                    return
                  }

                  if (!isChecked) {
                    setCheckedList([...checkedList, photo.metadata.key])
                  } else {
                    setCheckedList(checkedList.filter((id) => id !== photo.metadata.key))
                  }
                }}
                onLongPress={(evt) => {
                  onLongPress()
                  if (evt.type === 'touchstart') {
                    setCheckedList([...checkedList, photo.metadata.key])
                  }
                }}
                onDownloadError={setDownloadError}
                storage={storage}
              />
            )
          })}
          {!isReadOnly && allowAddPhoto ? (
            <Fab
              className={classes.submit}
              onClick={onClickSubmit}
              color='primary'
              aria-label='submit'
              variant='extended'
            >
              <Icon style={{ marginRight: '9px' }}>
                {!checkIsNoValidCountPhotos() ? <DoneIcon /> : <DonutLargeIcon />}
              </Icon>
              Готово
            </Fab>
          ) : (
            <></>
          )}
        </Box>
        {openedPhoto && (
          <DocumentViewer
            title={title}
            isReadOnly={isReadOnly}
            blob={openedPhoto.blob}
            idKey={openedPhoto.metadata.key}
            onClose={() => {
              retry()
              setOpenedPhoto(null)
            }}
            onDelete={() => {
              onClickDelete(openedPhoto?.metadata.key)
            }}
            isPrev={files.indexOf(openedPhoto) !== 0}
            isNext={files.indexOf(openedPhoto) !== files.length - 1}
            onPrevClick={() => {
              setOpenedPhoto(files[files.indexOf(openedPhoto) - 1])
            }}
            onNextClick={() => {
              setOpenedPhoto(files[files.indexOf(openedPhoto) + 1])
            }}
            storage={storage}
            onUpdate={onUpdateHandler}
          />
        )}

        <Dialog
          open={isConfirmation}
          disableScrollLock
          onClose={() => {
            setIsConfirmation(false)
          }}
        >
          <DialogTitle id='confirmation'>Подтверждение</DialogTitle>
          <DialogContent>Последние изменения не будут сохранены</DialogContent>
          <DialogActions>
            <Button
              color='primary'
              onClick={() => {
                setIsConfirmation(false)
              }}
            >
              Закрыть
            </Button>
            <Button
              color='primary'
              onClick={() => {
                setIsConfirmation(false)
                onCloseHandled()
              }}
            >
              Подтвердить
            </Button>
          </DialogActions>
        </Dialog>

        <AttentionModal
          isOpen={isAttentionModal}
          message={checkIsNoValidCountPhotos()}
          onClose={() => {
            setIsAttentionModal(false)
          }}
        />
      </div>
      {isLoadingToDB ? <PhotoLoad /> : ''}
    </div>
  )
}

export default PhotoDialog
