import React, { useEffect, useState } from 'react'

import { noop } from 'lodash'

import { ISwitchStepCase } from '../../../model/task-execution-process'
import { appToast } from '../../../utils'
// import { useScriptTaskContext } from '../script-tasks/script-task-context'
import { UserStepControl } from '../script-tasks/user-step-control'
import { ScreenRef } from '../template-tasks/types'
import { CaseScriptRunner } from './case-script-runner'

interface SwitchCaseControlProps {
  children?: never
  switchCase: ISwitchStepCase
  onReadyChange: (isReady: boolean) => void
  nextButton: JSX.Element | undefined
  screenRef: React.Ref<ScreenRef>
  onScriptError: (error: Error) => void
}

enum SwitchCaseState {
  BeforeScript,
  UserStep,
  AfterScript,
}

export const SwitchCaseControl: React.FC<SwitchCaseControlProps> = (props) => {
  useEffect(() => {
    console.log('SwitchCaseControl mount', props)
  }, [])

  // const localContext = useScriptTaskContext()
  const { switchCase, onReadyChange, nextButton, screenRef, onScriptError } = props

  // const switchCaseCode = /* switchCase.code */ 'testCaseCode'
  // const { currentStep = SwitchCaseState.BeforeScript } = localContext.taskState.cases?.[switchCaseCode] ?? {}
  const currentStep = SwitchCaseState.BeforeScript
  // TODO: save currentStep?
  const [state, setState] = useState<SwitchCaseState>(currentStep)

  if (switchCase.beforeScriptSteps?.length && state === SwitchCaseState.BeforeScript) {
    return (
      <CaseScriptRunner
        scripts={switchCase.beforeScriptSteps}
        onComplete={() => setState(SwitchCaseState.UserStep)}
        onError={onScriptError}
      />
    )
  }

  if (!switchCase.afterScriptSteps?.length) {
    return (
      <UserStepControl
        ref={screenRef}
        step={switchCase.userStep}
        onReadyChange={onReadyChange}
        nextButton={nextButton}
      />
    )
  }

  const stepAction: VoidFunction = nextButton?.props.onClick ?? noop

  const onComplete = (): void => {
    setState(SwitchCaseState.UserStep)
    stepAction()
  }

  if (state === SwitchCaseState.AfterScript) {
    return (
      <CaseScriptRunner
        scripts={switchCase.afterScriptSteps}
        onComplete={onComplete}
        onError={(error) => {
          appToast.error(error.message)
          setState(SwitchCaseState.UserStep)
        }}
      />
    )
  }

  const screenButton =
    nextButton &&
    React.cloneElement(nextButton, {
      onClick: () => {
        const isReady: boolean = nextButton.props.isValid
        if (isReady) {
          setState(SwitchCaseState.AfterScript)
        } else if (screenRef && 'current' in screenRef) {
          screenRef?.current?.validate()
        }
      },
    })

  return (
    <UserStepControl //
      ref={screenRef}
      step={switchCase.userStep}
      onReadyChange={onReadyChange}
      nextButton={screenButton}
    />
  )
}
