import React from 'react'

import { Accordion, AccordionDetails, AccordionSummary, Box, createStyles, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import classnames from 'classnames'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: {
      '& .MuiPaper-elevation1': {
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
      '& .MuiAccordion-root:before': {
        display: 'none',
      },
      '& .MuiAccordionSummary-root': {
        justifyContent: 'flex-end',
        flexDirection: 'row-reverse',
        // minHeight: '40px !important',
        padding: '0px 0px',
        height: 'auto !important',
        minHeight: '0',
        marginLeft: -8,
      },
      '& .MuiAccordionSummary-content.Mui-expanded, & .MuiAccordion-root.Mui-expanded': {
        margin: 0,
        '& $open': {
          display: 'block',
        },
        '& $close': {
          display: 'none',
        },
      },
      '& .MuiAccordionSummary-content': {
        margin: 0,
        flexGrow: 0,
      },
      '& .MuiAccordionDetails-root': {
        width: '100%',
        padding: '8px 0',
      },
      '& .MuiAccordionSummary-expandIcon.Mui-expanded, & .MuiAccordionSummary-expandIcon': {
        transform: 'rotate(90deg)',
        padding: '0 !important',
      },
      '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
        transform: 'rotate(180deg)',
      },
      '& .MuiIconButton-edgeEnd': {
        margin: 0,
      },
    },
    accordionTitle: {
      // marginLeft: 15,
      // marginRight: 6,
      fontSize: 15,
      lineHeight: '22px',
      fontWeight: 500,
      letterSpacing: '0.15px',
      color: theme.palette.primary.main,
    },
    open: {
      display: 'none',
    },
    close: {
      display: 'block',
    },
  }),
)

export const Collapsible: React.FC = ({ children }) => {
  const classes = useStyles()
  return (
    <Box>
      <div className={classes.accordion}>
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ArrowDropUpIcon />} aria-controls='tasks-content' id='tasks-header'>
            <Typography className={classnames(classes.accordionTitle, classes.close)}>Развернуть</Typography>
            <Typography className={classnames(classes.accordionTitle, classes.open)}>Свернуть</Typography>
          </AccordionSummary>
          <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
      </div>
    </Box>
  )
}
