import { IAnswer } from './answer'
import { Code } from './base'

export interface SimpleAnswerPredicate {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.SimpleAnswerPredicate'
  answer?: 'true' | 'false' | string
  operationKind: 'EQUALS' | 'NOT_EQUAL' | 'NOT_EMPTY'
  questionCode: Code
}

export interface GroupAnswerPredicate {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.GroupAnswerPredicate'
  conjunctionKind: 'AND' | 'OR'
  predicates: AnswerPredicate[]
}

export type AnswerPredicate = SimpleAnswerPredicate | GroupAnswerPredicate

export function checkAnswerPredicate(predicate: AnswerPredicate, answers: IAnswer[]): boolean {
  if (predicate.$type === 'PMI.FACE.BDDM.Extensions.Classes.SimpleAnswerPredicate') {
    const foundAnswer = answers.find((a) => a.questionCode === predicate.questionCode)
    switch (foundAnswer?.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.FaceBooleanAnswer':
        const predicateBoolAnswer =
          predicate.answer?.toLowerCase() === 'true' ? true : predicate.answer?.toLowerCase() === 'false' ? false : null
        const surveyBoolAnswer = foundAnswer?.booleanAnswer ?? null
        switch (predicate.operationKind) {
          case 'NOT_EQUAL':
            return predicateBoolAnswer !== surveyBoolAnswer
          case 'NOT_EMPTY':
            return surveyBoolAnswer != null
          case 'EQUALS':
          default:
            return predicateBoolAnswer === surveyBoolAnswer
        }

      case 'PMI.FACE.BDDM.Extensions.Classes.FaceTextAnswer':
        const predicateTextAnswer = predicate.answer?.trim().toLocaleLowerCase() ?? null
        const surveyTextAnswer = foundAnswer?.reply?.trim().toLocaleLowerCase() ?? null
        switch (predicate.operationKind ?? 'EQUALS') {
          case 'EQUALS':
            return predicateTextAnswer === surveyTextAnswer
          case 'NOT_EQUAL':
            return predicateTextAnswer !== surveyTextAnswer
          case 'NOT_EMPTY':
            return !!surveyTextAnswer
        }
    }
    return false
  } else if (predicate.conjunctionKind === 'AND') {
    return predicate.predicates.every((p) => checkAnswerPredicate(p, answers))
  } else {
    return predicate.predicates.some((p) => checkAnswerPredicate(p, answers))
  }
}
