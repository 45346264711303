import React from 'react'

import { Avatar, Box, createStyles, Grid, Paper, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { dateFormat } from '../../../../../utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      padding: theme.spacing(2),
      overflow: 'overlay',
      minHeight: theme.spacing(11)
    },
    ownerName: {
      color: theme.palette.text.secondary
    },
    dateTitle: {
      color: '#3870B2',
      textTransform: 'uppercase'
    }
  })
)

interface ISessionItemInfoProps {
  date: number | undefined
  pointOfSaleName: string
  ownerName: string
}

const SMItemInfo: React.FC<ISessionItemInfoProps> = ({ date, pointOfSaleName, ownerName }) => {
  const classes = useStyles()
  // const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'))

  const formattedDate = date ? dateFormat(new Date(date), 'dd MMMM') : '-'

  return (
    <Paper square elevation={3} className={classes.root}>
      <Grid container xs={12} alignItems='center'>
        <Grid item xs={12} sm={4} md={4}>
          <Typography variant='h4' color='secondary' className={classes.dateTitle}>
            {formattedDate}
          </Typography>
        </Grid>
        <Grid item xs={12} sm md>
          <Box display='flex' alignItems='center'>
            <Avatar style={{ marginRight: 16 }} alt={pointOfSaleName} src='/static/images/avatar/1.jpg' />
            <Typography variant='h6'>{pointOfSaleName}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <Typography className={classes.ownerName}>{ownerName}</Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default React.memo(SMItemInfo)
