import React from 'react'

import { Box, createStyles, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import classNames from 'classnames'

import { XSOnlySidebarButton } from './sidebar-button'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        position: 'sticky',
        top: 0,
        zIndex: 1299,
        background: 'white',
      },
      baseToolbar: {
        position: 'relative',
        height: 88,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
          height: 64,
        },
      },
      additionalToolbar: {
        display: 'flex',
        alignItems: 'center',
      },
      content: {
        whiteSpace: 'nowrap',
        flexGrow: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: 28,
        fontWeight: 600,
        letterSpacing: '0.1px',
        [theme.breakpoints.down('xs')]: {
          fontSize: 22,
          paddingLeft: theme.spacing(0),
        },
      },
      backButton: {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          marginLeft: 10,
        },
        marginRight: 10,
      },
      fixed: {
        position: 'fixed',
        right: 0,
        left: 80,
        [theme.breakpoints.down('xs')]: {
          left: 0,
        },
      },
      gridContainer: {
        marginTop: -15,
        // backgroundColor: theme.palette.background.default,
        padding: '0px 23px 30px 23px',
        [theme.breakpoints.down('xs')]: {
          marginTop: 0,
          padding: '0px 23px 23px 23px',
        },
      },
    }),
  { name: 'TitleBar' },
)

interface Props {
  onBack?: VoidFunction
  icons?: React.ReactNode
  additionalToolbar?: React.ReactNode
  fixed?: boolean
}

export const TitleBar: React.FC<Props> = ({ children, onBack, icons, additionalToolbar, fixed = false }) => {
  const classes = useStyles()

  return (
    <div className={classNames(classes.root, fixed && classes.fixed)}>
      <div className={classes.baseToolbar}>
        {onBack && (
          <IconButton className={classes.backButton} color='inherit' onClick={onBack}>
            <ArrowBackIosIcon viewBox='0 0 12 24' />
          </IconButton>
        )}
        {!onBack && <XSOnlySidebarButton />}
        <Typography variant='h1' className={classes.content}>
          {children}
        </Typography>
        <Box marginLeft='auto' paddingRight={1}>
          {icons}
        </Box>
      </div>
      <div className={classes.additionalToolbar}>
        {additionalToolbar && (
          <Grid container className={classes.gridContainer} alignItems='center' justify='space-between'>
            {additionalToolbar}
          </Grid>
        )}
      </div>
    </div>
  )
}
