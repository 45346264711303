import React, { useContext } from 'react'

import { makeStyles, Theme, createStyles, IconButton, useMediaQuery, Box } from '@material-ui/core'

import { DteIcon } from '../components/icons/dte-icon'
import { HamburgerIcon } from '../components/icons/hamburger'
import { useSidebarOpen } from './layout'
import { MenuContext } from './menu/menu-context'

const SIDEBAR_OPEN_BUTTON_WIDTH = 64
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sidebarOpenButton: {
      width: SIDEBAR_OPEN_BUTTON_WIDTH,
      // width: '100%',
      borderRadius: 0,
    },
  }),
)

interface Props {
  icon?: React.ReactNode
}
export const SidebarButton: React.FC<Props> = ({ icon = <HamburgerIcon /> }) => {
  const [isOpen, setIsOpen] = useSidebarOpen()
  const classes = useStyles()
  return (
    <IconButton className={classes.sidebarOpenButton} color='inherit' onClick={() => setIsOpen(!isOpen)}>
      {icon}
    </IconButton>
  )
}

export const XSOnlySidebarButton: React.FC = () => {
  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'))
  const { useBottomMenu } = useContext(MenuContext)

  return isXSmall ? (
    <SidebarButton icon={useBottomMenu ? <DteIcon /> : <HamburgerIcon />} />
  ) : (
    <Box marginRight={3}></Box>
  )
}
