import React, { useContext, useRef, useState } from 'react'

import { Box, CircularProgress, Paper, Typography, createStyles, makeStyles, Theme } from '@material-ui/core'
import { useVirtualizer } from '@tanstack/react-virtual'
import { useAsync } from 'react-use'

import { LogManager } from '../../../../../infrastructure/logger'
import { grays } from '../../../../../layout/theme'
import { IPointOfSale } from '../../../../../model/pos'
import { IInlineScriptPredicateFunction } from '../../../../../model/screen-item'
import { ApiContext } from '../../../../../providers'
import { appToast } from '../../../../../utils'
import { getPosAddress } from '../../../../_common/pos'
import StoresSearch from '../../../../stores/components/stores-search/stores-search'
import { getRegisteredNameFromPos } from '../../../../stores/store-menu-page/utils'
import StoresListItem from '../../../../stores/stores-list-item/stores-list-item'
import { SyncScriptRunner } from '../../../script-tasks/run-sync-script'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    posList: {
      height: 'inherit',
      border: `1px solid ${grays.gray5}`,
      overflow: 'hidden', // FACE-4495 prevent the bottom border from disappearing
    },
    listWrap: {
      height: 'inherit',
      display: 'flex',
      overflow: 'auto',
      padding: `12px 0 12px`,
      position: 'relative',
    },
    loaderWrapper: {
      position: 'absolute',
      top: 64,
      left: 0,
      bottom: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    captionOfEmptyResult: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'rgba(0, 0, 0, 0.38)',
      whiteSpace: 'nowrap',
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 20,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '100%',
      textAlign: 'center',
    },
  }),
)

interface IProps {
  condition: IInlineScriptPredicateFunction | undefined
  selectedItem: IPointOfSale | undefined | null
  selectPos: (pos: IPointOfSale) => void
}

const logger = LogManager.getLogger('posSelector')

const PosSelectorList: React.FC<IProps> = ({ condition, selectedItem, selectPos }) => {
  const classes = useStyles()
  const localContext = useScriptTaskContext()
  const api = useContext(ApiContext)
  const isCondition = !!condition?.body.scriptBody && !!condition?.argumentName
  const containerRef = useRef<HTMLDivElement>(null)

  const [searchString, setSearchString] = useState('')
  const [posList, setPosList] = useState<IPointOfSale[]>()
  const [isLoading, setIsLoading] = useState(true)

  const select = (pos: IPointOfSale): void => {
    selectPos(pos)
  }

  const search = (): IPointOfSale[] | undefined => {
    return posList?.filter((pos) => {
      return (
        pos.name.toLowerCase().includes(searchString) ||
        pos.code.toLowerCase().includes(searchString) ||
        getPosAddress(pos).toLowerCase().includes(searchString) ||
        getRegisteredNameFromPos(pos)?.toLowerCase().includes(searchString)
      )
    })
  }

  const filteredList = search() ?? []
  const rowVirtualizer = useVirtualizer({
    count: filteredList.length,
    getScrollElement: () => containerRef.current,
    estimateSize: () => 88,
    overscan: 1,
    enableSmoothScroll: false,
  })

  useAsync(async () => {
    try {
      const scriptRunner = isCondition
        ? new SyncScriptRunner(condition!.body.scriptBody, localContext as never, condition!.argumentName)
        : null
      const profile = await api.userProfile.getCurrentProfile()
      const allPos = await api.pos.searchPos({ positionRoleCoverage: profile?.fieldPositionRole?.code ?? '' })
      setIsLoading(false)
      if (scriptRunner) {
        setPosList(allPos.filter((pos) => scriptRunner.run(pos)))
        return
      }
      setPosList(allPos)
    } catch (err) {
      setIsLoading(false)
      const message = `Ошибка ${err.message}`
      appToast.info(message)
      logger.error('get', message, err)
      throw new Error(message)
    }
  }, [])

  return (
    <>
      <Box style={{ paddingBottom: 24 }}>
        <StoresSearch query={searchString} onChange={(val) => setSearchString(val.toLowerCase())} />
      </Box>
      <Paper elevation={0} square className={classes.posList}>
        <div id='listWrap' className={classes.listWrap} ref={containerRef}>
          {isLoading ? (
            <Box className={classes.loaderWrapper}>
              <CircularProgress />
            </Box>
          ) : !posList || !posList.at(0) ? (
            <Typography className={classes.captionOfEmptyResult}>Нет ни одной подходящей торговой точки</Typography>
          ) : !filteredList.at(0) ? (
            <Typography className={classes.captionOfEmptyResult}>Поиск не дал результатов</Typography>
          ) : (
            <Box
              style={{
                height: `${rowVirtualizer.getTotalSize()}px`,
                width: '100%',
                position: 'relative',
              }}
            >
              {rowVirtualizer.getVirtualItems().map((virtualItem) => {
                const store = filteredList[virtualItem.index]
                return (
                  <div
                    key={virtualItem.key}
                    data-code={store?.code}
                    data-index={virtualItem.index}
                    ref={virtualItem.measureElement}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      transform: `translateY(${virtualItem.start}px)`,
                    }}
                    onClick={() => select(store)}
                  >
                    <StoresListItem
                      name={store.name}
                      code={store.code}
                      address={getPosAddress(store)}
                      registerName={getRegisteredNameFromPos(store)}
                      selected={store.code === selectedItem?.code}
                    />
                  </div>
                )
              })}
            </Box>
          )}
        </div>
      </Paper>
    </>
  )
}

export default PosSelectorList
