import React, { useContext } from 'react'

import { Box, createStyles } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { sortBy } from 'lodash'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { EmptyText } from '../../../../components/empty-text'
import { ApiContext } from '../../../../providers'
import { ErrorPage } from '../../../error-page'
import { GroupWrapper } from '../../components/group-wrapper'
import { IStoreMenuLocationState } from '../store-menu-page'
import { PposmItem } from './pposm-item'
import { filteredByManufactured } from './utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 35,
    },
    listWrapper: {
      height: 'calc(100vh - 210px)',
      paddingBottom: 24,
      overflow: 'auto',
      [theme.breakpoints.down('xs')]: {
        height: 'calc(100vh - 184px)',
      },
    },
  }),
)

export const Pposm: React.FC = () => {
  const classes = useStyles()
  const api = useContext(ApiContext)
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state as IStoreMenuLocationState
  const { posCode } = useParams<{ posCode: string }>()

  const equipmentsOps = useAsync(async () => api.pos.searchPPOSM({ posCode: posCode! }))

  const onClickHandler = (code: string): void => {
    navigate(`/stores/store/${posCode}/pposm/${code}`, {
      state: { ...state, prevPPOSMPath: location.pathname },
    })
  }

  const renderEmpty = (text: string): JSX.Element => (
    <div style={{ height: '100%' }}>
      <EmptyText>{text}</EmptyText>
    </div>
  )

  if (equipmentsOps.error) {
    return <ErrorPage errorMessage={equipmentsOps.error.message} />
  }

  if (equipmentsOps.loading) {
    return <Box className={classes.listWrapper}> {renderEmpty('Загрузка')} </Box>
  }

  const equipments = equipmentsOps.value

  if (!equipments?.length) {
    return <Box className={classes.listWrapper}> {renderEmpty('Список пуст')} </Box>
  }

  const { pmi, other } = filteredByManufactured(equipments)

  return (
    <>
      <div className={classes.root}>
        <Box className={classes.listWrapper}>
          {!!pmi.length && (
            <GroupWrapper title='Оборудование PMI'>
              {sortBy(pmi, 'name').map((pposm) => (
                <PposmItem key={pposm.code} pposm={pposm} onClick={() => onClickHandler(pposm.code)} />
              ))}
            </GroupWrapper>
          )}
          {!!other.length && (
            <GroupWrapper title='Остальное оборудование'>
              {sortBy(other, 'name').map((pposm) => (
                <PposmItem key={pposm.code} pposm={pposm} onClick={() => onClickHandler(pposm.code)} />
              ))}
            </GroupWrapper>
          )}
        </Box>
      </div>
    </>
  )
}
