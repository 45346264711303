import React, { useEffect } from 'react'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Button, createStyles, LinearProgress, makeStyles, Theme } from '@material-ui/core'
import { useLatest } from 'react-use'

import { IFaceScript } from '../../../model/face-script'
import { DebugTree } from '../debug-tree'
import { useLocalContextService } from '../nested/local-context'
import { runScript } from './run-script'
import { IScriptTaskContext, useScriptTaskContext } from './script-task-context'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: 50,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 'calc(100vh - 100px)',
      flexDirection: 'column',
    },
    progress: {
      // flexGrow: 1,
      alignSelf: 'stretch',
    },
    script: {
      overflowX: 'auto',
      maxWidth: '100%',
      margin: theme.spacing(0, -3),
    },
  }),
)

interface Props {
  script: IFaceScript
  onSuccess: VoidFunction
  onError: (err: Error) => void

  /**
   * Assume that we are rendering outside of the tree \
   * TODO: check if still needed
   */
  localContext: IScriptTaskContext
}

const RUN_MANUALLY = false

export const ScriptView: React.FC<Props> = ({ script, onSuccess, onError }) => {
  const classes = useStyles()
  const contextService = useLocalContextService()
  const localContext = useScriptTaskContext()
  const contextRef = useLatest(localContext)

  function run(): void {
    runScript(script, contextRef.current, contextService).then(onSuccess, onError)
  }

  useEffect(() => {
    if (!RUN_MANUALLY) {
      void contextService.onLoad().then(run)
    }
  }, [script.code])

  return (
    <div className={classes.wrapper}>
      <DebugTree />
      {/* Running script {script.code} {script.description} */}
      {RUN_MANUALLY && <pre className={classes.script}>{script.body}</pre>}
      <LinearProgress className={classes.progress} />
      {RUN_MANUALLY && <Button onClick={run}>Test Run Button</Button>}
      {RUN_MANUALLY && <Button onClick={() => onError(new Error('going back'))}>Test Back Button</Button>}
    </div>
  )
}
