import React from 'react'

import { Box, createStyles, makeStyles, Theme } from '@material-ui/core'

import { ValidationFab } from '../../../../../components/validation-fab'
import { PageContent } from '../../../../../layout/page-content'
import { TitleBar } from '../../../../../layout/title-bar'
import { IPointOfSale } from '../../../../../model/pos'
import { IInlineScriptPredicateFunction } from '../../../../../model/screen-item'
import { appToast } from '../../../../../utils'
import PosSelectorList from './pos-selector-list'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    posModal: {
      position: 'fixed',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      zIndex: theme.zIndex.modal + 1,
      height: '100vh',
      backgroundColor: theme.palette.background.default,
      overflow: 'auto',
    },
    content: {
      height: 'calc(100vh - 306px)',
      padding: '25px 28px 0',
    },
  }),
)

interface IProps {
  condiditon: IInlineScriptPredicateFunction | undefined
  selectedItem: IPointOfSale | undefined | null
  selectPos: (pos: IPointOfSale) => void
  toggleModalVisibility: (val: boolean) => void
}

const PosListModal: React.FC<IProps> = ({ condiditon, selectedItem, selectPos, toggleModalVisibility }) => {
  const classes = useStyles()

  return (
    <Box className={classes.posModal}>
      <TitleBar onBack={() => toggleModalVisibility(false)}>Выбор торговой точки</TitleBar>
      <PageContent className={classes.content}>
        <PosSelectorList condition={condiditon} selectedItem={selectedItem} selectPos={selectPos} />
        <ValidationFab
          isValid={!!selectedItem}
          onClick={() => (selectedItem ? toggleModalVisibility(false) : appToast.info('Не выбрана торговая точка'))}
        >
          Подтвердить
        </ValidationFab>
      </PageContent>
    </Box>
  )
}

export default PosListModal
