import { BlobMetadata } from './blob-storage-api'

export function getBlobKind(blob: Blob): string {
  if (blob.type) {
    return blob.type.split('/')[0]
  }
  return 'blob'
}

export function cleanupBlobPredicate(metadata: BlobMetadata, timeBefore: number): boolean {
  const { deleteAfter, syncTime, timestamp } = metadata
  if (deleteAfter) {
    return deleteAfter < Date.now()
  }
  if (syncTime) {
    return syncTime < timeBefore
  }

  return timestamp < timeBefore
}
