import React, { useEffect } from 'react'

import { Code } from '../../../model/base'
import { IShowTaskResultUserStepScreen } from '../../../model/user-step-screen'
import { getContextProperty } from '../script-tasks/propertyName'
import { useScriptTaskContext } from '../script-tasks/script-task-context'
import { TaskResultView } from './task-result-view'

interface ShowTaskResultUserStepScreenProps {
  children?: never
  onReadyChange: (isReady: boolean) => void
  screen: IShowTaskResultUserStepScreen
}

export const ShowTaskResultUserStepScreen: React.FC<ShowTaskResultUserStepScreenProps> = (props) => {
  const outerContext = useScriptTaskContext()
  const { onReadyChange, screen } = props
  useEffect(() => {
    console.log('ShowTaskResultUserStepScreen mount', outerContext, screen)
    onReadyChange(true)
  }, [])
  const targetTaskCode = getContextProperty<Code>(outerContext, screen.taskCodePropertyName)
  // TODO: extract fetching logic when implementing for local tasks
  return <TaskResultView targetTaskCode={targetTaskCode} />
}
