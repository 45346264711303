import { TypeDecl } from './models'
import {
  addTypeFieldToRecords,
  camelCaseFieldNames,
  removeField,
  replaceUsagesOfBaseTypesWithUnionOfDerived,
  TypesTransformer,
} from './type-transformers'

export function transformPipeline(transformers: TypesTransformer[]) {
  return function transformTypes(types: TypeDecl[]) {
    types = JSON.parse(JSON.stringify(types))

    for (const transform of transformers) {
      transform(types)
    }

    return types
  }
}

export const transformTypes = transformPipeline([
  removeField('PMI.BDDM.Common.DictionaryItem', 'ParentItem'),
  camelCaseFieldNames(),
  addTypeFieldToRecords(),
  replaceUsagesOfBaseTypesWithUnionOfDerived(),
])
