import React from 'react'

// import { GeolocationContext } from '.'

export const RequireGeo: React.FC = () => {
  // const { showNoGeoToast } = useContext(GeolocationContext)
  // useEffect(() => {
  // showNoGeoToast()
  // }, [])
  return null
}
