import React, { useCallback, useRef } from 'react'

import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import QuickPinchZoom, {
  hasTranslate3DSupport,
  make2dTransformValue,
  make3dTransformValue
} from 'react-swipe-quick-pinch-zoom'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    image: {
      width: '100%',
      height: '100%',
      display: 'block',
      objectFit: 'contain',
      objectPosition: 'center center',
      maxWidth: 'none',
    },
  })
)

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

const use3DTransform = hasTranslate3DSupport() && !isSafari

const makeTransformValue = use3DTransform
  ? make3dTransformValue
  : make2dTransformValue

export type ImageFile = string

interface IPdfViewerProps {
  image: ImageFile
}

const ImageViewer: React.FC<IPdfViewerProps> = ({ image }): JSX.Element => {
  const classes = useStyles()
  const photoRef = useRef<HTMLImageElement>(null)
  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: photoElement } = photoRef
    if (photoElement) {
      const value = makeTransformValue({ x, y, scale })
      photoElement.style.setProperty('transform', value)
    }
  }, [photoRef])

  return (
    <>
      <QuickPinchZoom onUpdate={onUpdate}>
        <img
          className={classes.image}
          ref={photoRef}
          src={image}
          alt=''
        />
      </QuickPinchZoom>
    </>
  )
}

export default ImageViewer