import React, { useContext, useLayoutEffect, useMemo, useRef } from 'react'

import { useUpdate } from 'react-use'

import schema from '../../data/schema'
import { BddmTypesApi } from '../../features/admin/bddm-types/bddm-types-api'
import { BlobStorage } from '../../infrastructure/blob-storage/blob-storage'
import { extractExifProperties } from '../../infrastructure/blob-storage/metadata-exif'
import { generateThumbnail } from '../../infrastructure/blob-storage/metadata-thumbnail'
import { fromClass, provide } from '../../infrastructure/di'
import { DumpService } from '../../infrastructure/dump-service/dump-service'
import { ENCRYPTION_SERVICE_API, EncryptionService } from '../../infrastructure/encryption'
import { HTTP_CLIENT, HTTP_CLIENT_FACTORY, HttpClientFactory } from '../../infrastructure/http-client-factory'
import { StorageService } from '../../infrastructure/storage-service'
import { UserProfileService } from '../../infrastructure/user-profile'
import { AppFeatureService } from '../../services-admin/app-feature-service'
import { APP_FEATURE_SERVICE_API } from '../../services-admin/app-feature-service-api'
import { APP_MENUS_SERVICE_API, AppMenusService } from '../../services-admin/app-menus-service'
import { BLOB_WEB_API } from '../../services-admin/blob-web-api'
import { BlobWebApiService } from '../../services-admin/blob-web-api-service'
import { DataCatalogService } from '../../services-admin/data-catalog-service'
import { DataObjectsService } from '../../services-admin/data-objects-service'
import { DATA_OBJECTS_SERVICE_API } from '../../services-admin/data-objects-service-api'
import { DTETaskTemplatesService } from '../../services-admin/dte-task-templates-service'
import { DTE_TASK_TEMPLATE_API } from '../../services-admin/dte-task-templates-service-api'
import { ExecutionProcessService } from '../../services-admin/execution-process-service'
import { EXECUTION_PROCESS_API } from '../../services-admin/execution-process-service-api'
import { QuestionnaireService } from '../../services-admin/questionnaire-service'
import { QUESTIONNAIRE_API } from '../../services-admin/questionnaire-service-api'
import { TaskTemplatesService } from '../../services-admin/task-templates-service'
import { TASK_TEMPLATE_API } from '../../services-admin/task-templates-service-api'
import { DATA_COLLECTIONS_API } from '../../services/data-collection-service-api'
import { DataCollectionService } from '../../services/impl/data-collection-service'
import LocalStorageAuditService from '../../services/impl/local-storage-audit-service'
import LocalStorageDictionaryService from '../../services/impl/local-storage-dictionary-service'
import LocalStorageDocumentService from '../../services/impl/local-storage-document-service'
import LocalStorageDteService from '../../services/impl/local-storage-dte-service'
import LocalStorageEmployeeService from '../../services/impl/local-storage-employee-service'
import LocalStorageFprUserProfileService from '../../services/impl/local-storage-fpr-user-profile-service'
import LocalStoragePOSService from '../../services/impl/local-storage-pos-service'
import LocalStoragePOSTaskRegisterService from '../../services/impl/local-storage-pos-task-register-service'
import LocalStorageProblemService from '../../services/impl/local-storage-problem-service'
import LocalStorageQuestionnaireService from '../../services/impl/local-storage-questionnaire-service'
import LocalStorageSupervisedService from '../../services/impl/local-storage-supervised-servise'
import LocalStorageSurveyService from '../../services/impl/local-storage-survey-service'
import LocalStorageTaskService from '../../services/impl/local-storage-task-service'
import LocalStorageVisitService from '../../services/impl/local-storage-visit-service'
import { RichTextService } from '../../services/impl/rich-text-service'
import { unscramble } from '../../utils'
import { AuthContext } from '../auth'
import { ConfigContext } from '../config'
import { useDefaultCodeSpace } from '../config/useDefaultCodeSpace'
import { ServiceProvider } from '../service'
import ApiContext, { IApiContext } from './api-context'

const ApiProvider: React.FC = ({ children }) => {
  const auth = useContext(AuthContext)
  const cfg = useContext(ConfigContext)
  const apiContext = useRef<IApiContext>()
  const update = useUpdate()
  const defaultCodeSpace = useDefaultCodeSpace()

  useLayoutEffect(() => {
    if (!auth.currentUserName) {
      return console.info('currentUserName is undefined')
    }
    if (apiContext.current) {
      return console.info('apiContext already created', apiContext.current)
    }

    const userName = auth.currentUserName

    const mainStorage = new StorageService(userName, schema)
    const httpClientFactory = new HttpClientFactory(cfg.config.apiUrl, auth.authService)
    const profileService = new UserProfileService(mainStorage, auth.authService, httpClientFactory, defaultCodeSpace)

    const mediaStorage = new BlobStorage(`${userName}-media`, 'media', httpClientFactory, {
      blobStoragePath: cfg.config.blobStoragePath ?? cfg.config.fileStoragePath,
      blobSecret: unscramble(cfg.config.blobSecret),
      cleanupIntervalInMinutes: 2 * 60, // 2h
      purgeBlobsOlderThanDays: 7,
      purgeRequestsOlderThanDays: 7,
    })
    mediaStorage.registerMetadataGenerator('exif', extractExifProperties)
    mediaStorage.registerMetadataGenerator('thumbnail', generateThumbnail)

    apiContext.current = {
      storageService: mainStorage,
      visits: new LocalStorageVisitService(auth.authService, profileService, mainStorage, defaultCodeSpace),
      pos: new LocalStoragePOSService(auth.authService, profileService, mainStorage, defaultCodeSpace),
      tasks: new LocalStorageTaskService(auth.authService, profileService, mainStorage, defaultCodeSpace),
      survey: new LocalStorageSurveyService(auth.authService, profileService, mainStorage, defaultCodeSpace),
      questionnaire: new LocalStorageQuestionnaireService(
        auth.authService,
        profileService,
        mainStorage,
        defaultCodeSpace,
      ),
      employee: new LocalStorageEmployeeService(auth.authService, profileService, mainStorage, defaultCodeSpace),
      audit: new LocalStorageAuditService(auth.authService, profileService, mainStorage, defaultCodeSpace),
      userProfile: profileService,
      dictionary: new LocalStorageDictionaryService(auth.authService, profileService, mainStorage, defaultCodeSpace),
      posTaskRegister: new LocalStoragePOSTaskRegisterService(
        auth.authService,
        profileService,
        mainStorage,
        defaultCodeSpace,
      ),
      document: new LocalStorageDocumentService(auth.authService, profileService, mainStorage, defaultCodeSpace),
      blobStorage: mediaStorage,
      blobWebApi: new BlobWebApiService(httpClientFactory.getHttpClient(), cfg.config.apiUrl, 'blobapi'),
      problem: new LocalStorageProblemService(auth.authService, profileService, mainStorage, defaultCodeSpace),
      dte: new LocalStorageDteService(auth.authService, profileService, mainStorage, defaultCodeSpace),
      encryption: new EncryptionService(httpClientFactory.getHttpClient()),
      richtext: new RichTextService(httpClientFactory.getHttpClient()),
      dumpService: new DumpService(auth.authService, profileService, httpClientFactory),
      dataCollections: new DataCollectionService(httpClientFactory.getHttpClient()),
      supervised: new LocalStorageSupervisedService(auth.authService, profileService, mainStorage, defaultCodeSpace),
      fprUserProfiles: new LocalStorageFprUserProfileService(
        auth.authService,
        profileService,
        mainStorage,
        defaultCodeSpace,
      ),

      _httpClientFactory: httpClientFactory,
    }

    update()
    return () => {
      mediaStorage?.dispose()
      apiContext.current = undefined
    }
  }, [auth.currentUserName, auth.authService, cfg.config])

  const providers = useMemo(() => {
    return [
      provide(HTTP_CLIENT_FACTORY, () => apiContext.current!._httpClientFactory),
      provide(HTTP_CLIENT, (c) => c.get(HTTP_CLIENT_FACTORY).getHttpClient()),

      provide(EXECUTION_PROCESS_API, fromClass(ExecutionProcessService, [HTTP_CLIENT])),
      provide(TASK_TEMPLATE_API, fromClass(TaskTemplatesService, [HTTP_CLIENT])),
      provide(DTE_TASK_TEMPLATE_API, fromClass(DTETaskTemplatesService, [HTTP_CLIENT])),
      provide(QUESTIONNAIRE_API, fromClass(QuestionnaireService, [HTTP_CLIENT])),
      provide(DataCatalogService, fromClass(DataCatalogService, [HTTP_CLIENT])),
      provide(UserProfileService, () => apiContext.current!.userProfile),
      provide(BLOB_WEB_API, (c) => new BlobWebApiService(c.get(HTTP_CLIENT), cfg.config.blobStoragePath)),
      provide(ENCRYPTION_SERVICE_API, fromClass(EncryptionService, [HTTP_CLIENT])),
      provide(BddmTypesApi, fromClass(BddmTypesApi, [HTTP_CLIENT])),
      provide(DATA_OBJECTS_SERVICE_API, fromClass(DataObjectsService, [HTTP_CLIENT])),
      provide(APP_FEATURE_SERVICE_API, fromClass(AppFeatureService, [HTTP_CLIENT])),
      provide(APP_MENUS_SERVICE_API, fromClass(AppMenusService, [HTTP_CLIENT])),
      provide(DATA_COLLECTIONS_API, () => apiContext.current!.dataCollections),
      provide(DataCollectionService, () => apiContext.current!.dataCollections as DataCollectionService),
    ]
  }, [])

  if (!apiContext.current) return <></>
  return (
    <ApiContext.Provider value={apiContext.current}>
      <ServiceProvider providers={providers}>{children}</ServiceProvider>
    </ApiContext.Provider>
  )
}

export default ApiProvider
