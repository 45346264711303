import React, { useMemo } from 'react'

import { Box, createStyles, makeStyles, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core'
import classnames from 'classnames'

import { grays } from '../../../../../../layout/theme'
import { checkPredicate } from '../../../../../../model/script-predicate'
import { HorizontalAlignmentType, ITableScreenItem } from '../../../../../../model/table-screen-item'
import { useUpdateProperty } from '../../../../nested/useUpdateProperty'
import { getContextProperty, getPropertyAny } from '../../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../../script-tasks/script-task-context'
import { ITableRecord } from '../editorUtils'
import { RowMenu } from '../row-menu'
import { renderCell } from '../table-item-landscape'
import { groupByIndex } from '../table-item-portrait'
import { useGotoSubProcess } from '../useGotoSubProcess'
import { filterTableRows } from '../utils'
import { CardMarker } from './card-marker'

const useStyles = makeStyles((theme) =>
  createStyles({
    // displayName: {
    //   fontSize: 16,
    //   marginBottom: 16,
    //   fontWeight: 500,
    // },
    innerWrap: {
      marginBottom: 28,
    },
    item: {
      position: 'relative',
      padding: '21px 24px 21px 32px',
      backgroundColor: '#fff',
      borderRadius: 12,

      marginBottom: 8,
      boxShadow: '0px 3px 7px rgba(96, 97, 112, 0.13)',
      cursor: 'pointer',
    },
    itemWithMenu: {
      paddingRight: 62,
    },
    cell: {
      maxWidth: 0,
      borderBottom: 0,
      padding: 0,
      paddingLeft: 44,
      paddingRight: 44,
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 0,
      },
      '&:not(:last-child)': {
        // borderRight: `2px solid ${grays.gray5}`,
      },
    },
    menuWrap: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: 0,
      maxWidth: 'none',
      width: 50,
    },
    tableFixed: {
      tableLayout: 'fixed',
    },
  }),
)

interface Props {
  item: ITableScreenItem
}

export const CardVariantLandscape: React.FC<Props> = ({ item }) => {
  if (item.tableStyle?.$type !== 'PMI.FACE.BDDM.Extensions.Classes.CardsTableStyle') throw new Error('unreachable')
  const classes = useStyles()
  const updateProperty = useUpdateProperty()
  const propContext = useScriptTaskContext()
  const gotoSubProcess = useGotoSubProcess(item)
  const rows: ITableRecord[] | null = getContextProperty(propContext, item.propertyName)
  const filteredRows = filterTableRows(rows, item, propContext)

  if (!item.cells?.length) {
    throw new Error('Table Item cells is empty')
  }

  const cells = item.cells

  const tableLines = useMemo(() => {
    return groupByIndex(cells, (cell) => cell.landscapeSettings.lineNumber ?? 0).map((line) =>
      line.sort(
        (cell1, cell2) => (cell1.landscapeSettings.columnNumber ?? 0) - (cell2.landscapeSettings.columnNumber ?? 0),
      ),
    )
  }, [item.cells])

  if (!filteredRows?.length) {
    return (
      <>
        {/*{item.displayName && <Typography className={classes.displayName}>{item.displayName}</Typography>}*/}
        <Typography color='textSecondary'>{item.nullValueCaption ?? 'Список строк пуст'}</Typography>
        <Box pb={2}></Box>
      </>
    )
  }
  const isMarker = !!item.tableStyle?.rowStyle?.marker
  const isSeparator =
    item.tableStyle?.rowStyle?.showSeparatorLine === undefined ? true : item.tableStyle?.rowStyle?.showSeparatorLine

  return (
    <>
      {/*{item.displayName && <Typography className={classes.displayName}>{item.displayName}</Typography>}*/}
      <div className={classes.innerWrap}>
        {filteredRows.map((row, rowI) => {
          const defaultAction = item.rowSubprocesses?.find((settings) => {
            return checkPredicate(
              settings.defaultProcessCondition ?? {
                $type: 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate',
                value: false,
              },
              row,
              propContext,
            )
          })
          const withRowMenu = !(!item.deleteRecordEnabled && item.rowSubprocesses?.length === 1 && defaultAction)
          const recordCodePropertyKeyName = item.recordKeyPropertyName ?? 'checkNumber'
          const recordCode = getPropertyAny(row, recordCodePropertyKeyName, rowI.toString())

          const foundIndex =
            rows?.findIndex((row) => getPropertyAny<unknown>(row, recordCodePropertyKeyName) === recordCode) ?? -1
          const realIndex = foundIndex > -1 ? foundIndex : undefined
          const onRowClick = (): void => {
            if (!defaultAction) return
            gotoSubProcess(defaultAction.process.code, recordCode)
          }

          // const maxCountLineCol = tableLines.find((line) => )
          const maxCountLineCol = tableLines.reduce((acc, curr) => (acc.length > curr.length ? acc : curr)).length

          return (
            <div
              key={recordCode}
              className={classnames(classes.item, { [classes.itemWithMenu]: withRowMenu })}
              onClick={onRowClick}
            >
              {isMarker && <CardMarker record={row} />}
              <Table className={classes.tableFixed}>
                <TableBody>
                  {tableLines.map((line, lineI) => {
                    return (
                      <TableRow key={lineI}>
                        {line.map((col, i) => {
                          const align =
                            col.landscapeSettings.horizontalAlignment.toLowerCase() as Lowercase<HorizontalAlignmentType>
                          const width = col.landscapeSettings.columnSize
                          // const rowSpan = i === 2 ? 2 : 1
                          return (
                            <TableCell
                              className={classes.cell}
                              key={(col.propertyName ?? '') + String(i)}
                              align={align}
                              width={width && `${width}%`}
                              style={{
                                borderRight:
                                  i + 1 < maxCountLineCol && isSeparator
                                    ? `2px solid ${grays.gray5}`
                                    : `2px solid transparent`,
                              }}
                              // rowSpan={rowSpan}
                            >
                              {renderCell(col, row, realIndex ?? rowI, i, 'table', false, true)}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
              {withRowMenu && (
                <div className={classes.menuWrap}>
                  <RowMenu
                    item={item}
                    row={row}
                    onDelete={() => {
                      const keyProp = item.recordKeyPropertyName ?? 'checkNumber'
                      const rowKey = getPropertyAny(row, keyProp)
                      const result = rows?.filter((record) => getPropertyAny(record, keyProp) !== rowKey)
                      console.log({ rows, rowKey, keyProp, result })
                      void updateProperty(item.propertyName, result)
                    }}
                  />
                </div>
              )}
            </div>
          )
        })}
      </div>
    </>
  )
}
