import React, { forwardRef, useImperativeHandle, useRef } from 'react'

import { Box } from '@material-ui/core'

import { EmptyMessage } from '../../../../components'
import { Code } from '../../../../model/base'
import { BusinessError } from '../../../../model/errors'
import { useAsyncError } from '../../../_common/hooks/useAsyncError'
import { useCreateSurvey } from '../../../_common/hooks/useCreateSurvey'
import { useFetchQuestionnaire } from '../../../_common/hooks/useFetchQuestionnaire'
import { useScrollToTop } from '../../../_common/hooks/useScrollToTop'
import ExecuteSurveyControl, { IEscRef } from '../../../execute-survey-control/execute-survey-control'
import { useSurveyMethods } from '../../../execute-survey-control/use-survey-methods'
import { ScreenRef } from '../types'

interface IProps {
  questionnaireCode: Code
  propertyName: string
  onReadyChange: (isReady: boolean) => void
}

export const SimpleSurveyTaskStage = forwardRef<ScreenRef, IProps>(function SimpleSurveyTaskStage(
  { propertyName, questionnaireCode, onReadyChange },
  ref,
) {
  const controlRef = useRef<IEscRef>(null)

  const questionnaireOps = useFetchQuestionnaire({ questionnaireCode }, [questionnaireCode])
  useScrollToTop()

  useImperativeHandle(
    ref,
    () => ({
      validate: () => {
        controlRef.current?.focusFirstInvalidAnswer()
      },
    }),
    [],
  )

  const surveyOps = useCreateSurvey(questionnaireOps.value?.code, propertyName)

  const {
    onBooleanAnswer,
    onTextAnswer,
    onRemoveAnswer,
    onSaveComment,
    onPhotosSubmit,
    onHyperlinkAnswer,
    onNumericAnswer,
    onPhotoAnswer,
    onNullAnswer,
  } = useSurveyMethods(surveyOps.value, questionnaireOps.value, surveyOps.retry)

  // useAsyncError(questionnaireOps.error)
  useAsyncError(surveyOps.error)
  if (questionnaireOps.loading && !questionnaireOps.value) return <></>
  if (questionnaireOps.error) {
    if (questionnaireOps.error instanceof BusinessError) {
      return (
        <Box pt={0.5}>
          <EmptyMessage message={questionnaireOps.error.message} />
        </Box>
      )
    } else {
      throw questionnaireOps.error
    }
  }
  if (surveyOps.loading && !surveyOps.value) return <></>

  const questionnaire = questionnaireOps.value!
  const survey = surveyOps.value!

  return (
    <Box pt={0.5}>
      <ExecuteSurveyControl
        readOnly={false}
        ref={controlRef}
        survey={survey}
        questionnaire={questionnaire}
        onRemoveAnswer={onRemoveAnswer}
        onBooleanAnswer={onBooleanAnswer}
        onHyperlinkAnswer={onHyperlinkAnswer}
        onNumericAnswer={onNumericAnswer}
        onPhotoAnswer={onPhotoAnswer}
        onSaveComment={onSaveComment}
        onTextAnswer={onTextAnswer}
        onPhotosSubmit={onPhotosSubmit}
        onReadyChange={onReadyChange}
        onNullAnswer={onNullAnswer}
      />
    </Box>
  )
})
