import { IAwaitable } from './awaitable'
import { IDisposable } from './disposable'

export class OperationCancelledException extends Error {
  constructor() {
    super('Operation cancelled')
  }
}

export interface ICancellationToken extends IAwaitable{
  isCancellationRequested: boolean
  promise: Promise<void>
}

export function throwIfCancellationRequested(cancellation?: ICancellationToken): void {
  if (cancellation?.isCancellationRequested) {
    throw new OperationCancelledException()
  }
}

export class CancellationSource implements ICancellationToken, IDisposable, IAwaitable {
  private _isCancellationRequested = false
  private _promise: Promise<void> | undefined
  private _resolve: (() => void) | undefined
  private _reject: (() => void) | undefined

  get isCancellationRequested(): boolean {
    return this._isCancellationRequested
  }

  cancel(): void {
    this._isCancellationRequested = true
    this._promise = undefined
    if (this._resolve) {
      const resolve = this._resolve
      this._resolve = this._reject = undefined
      resolve()
    }
  }

  get promise(): Promise<void> {
    return this._promise ??
      (this._promise = new Promise<void>((resolve, reject) => { this._resolve = resolve; this._reject = reject }))
  }

  dispose(): void {
    this._promise = undefined
    if (this._reject) {
      const reject = this._reject
      this._resolve = this._reject = undefined
      reject()
    }
  }
}
