import { DependencyList, useCallback, useContext, useEffect, useRef, useState } from 'react'

import IAuthService from '../../../infrastructure/auth/auth-service-api'
import { IUserMetricService, UserMetricService } from '../../../infrastructure/user-metric'
import { ApiContext, MaintenanceContext } from '../../../providers'
import { SyncContext } from '../../../providers/sync'

interface UseSyncParams {
  // если передать этот флаг, то хук не будет запускаться при первом маунте автоматически
  runManually: boolean
}

interface UseSyncResult {
  progress: number
  progressMessage: string
  finished: boolean
  inProgress: boolean
  error: unknown
  retrySync: () => void
}

const DEFAULT_PROGRESS_MSG = ''
const DEFAULT_PROGRESS = 0
const DEFAULT_ATTEMPT = 0

/**
 * Синхронизирует данные приложения
 */
export function useSync(params?: UseSyncParams, deps: DependencyList = []): UseSyncResult {
  const { syncService } = useContext(SyncContext)
  const { setMaintenance } = useContext(MaintenanceContext)
  const api = useContext(ApiContext)
  const userMetricRef = useRef<IUserMetricService | null>(null)
  const [attempt, setAttempt] = useState<number>(DEFAULT_ATTEMPT)
  const [progress, setProgress] = useState<number>(DEFAULT_PROGRESS)
  const [progressMessage, setProgressMessage] = useState<string>(DEFAULT_PROGRESS_MSG)
  const [finished, setFinished] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [error, setError] = useState<unknown>(null)

  useEffect(() => {
    userMetricRef.current = new UserMetricService({} as IAuthService)
  }, [])

  const syncFn = async (): Promise<void> => {
    setError(null)
    setFinished(false)
    setInProgress(true)
    try {
      await syncService.sync('Full', (progress, message = DEFAULT_PROGRESS_MSG) => {
        setProgress(progress)
        setProgressMessage(message)
      })
      setFinished(true)
      setInProgress(false)
      setMaintenance(false)
      await userMetricRef.current?.userSync(api.userProfile)
    } catch (e) {
      setFinished(false)
      setInProgress(false)
      setError(e)
    }
  }

  const retrySync = useCallback(async () => {
    setProgress(DEFAULT_PROGRESS)
    setProgressMessage(DEFAULT_PROGRESS_MSG)
    setError(null)
    setFinished(false)
    setInProgress(false)
    // timeout for animations
    await setTimeout(() => {
      setAttempt((currentAttempt) => currentAttempt + 1)
    }, 400)
  }, [...deps])

  useEffect(() => {
    if (params?.runManually && attempt === DEFAULT_ATTEMPT) {
      return
    }
    void syncFn()
  }, [attempt, ...deps])

  return { progress, inProgress, finished, progressMessage, error, retrySync }
}
