import React from 'react'

import { createStyles, Grid, makeStyles, Switch } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(
  createStyles({
    languageSwitch: {
      padding: 15,
      paddingLeft: 25
    }
  })
)

const SHOW_LANGUAGE_SWITCH = false

export const LanguageSwitch: React.FC = () => {
  const { i18n } = useTranslation()
  const classes = useStyles()

  if (!SHOW_LANGUAGE_SWITCH) return null

  return (
    <Grid component='label' container alignItems='center' className={classes.languageSwitch} spacing={1}>
      <Grid>Ru1</Grid>
      <Grid>
        <Switch
          color='primary'
          onChange={(ev) => void i18n.changeLanguage(ev.target.checked ? 'ru' : 'ru1')}
          checked={i18n.language === 'ru'}
        />
      </Grid>
      <Grid>Ru</Grid>
    </Grid>
  )
}
