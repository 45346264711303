import { useContext, useEffect, useState } from 'react'

import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { ApiContext } from '../../providers'

export function useCollectionBlob(imageCode: string | undefined): UseQueryResult<Blob | null> {
  const { richtext: api } = useContext(ApiContext)
  const enabled = imageCode !== undefined
  const imgUrl = `content-documents/v1.0/${imageCode}/get-target`

  const query = useQuery({
    queryKey: ['content-document-view', 'blob', imageCode],
    enabled,
    queryFn: async () => api.getBlob(imgUrl),
    retry: false,
    suspense: false,
    refetchOnMount: false,
    staleTime: 5 * 60 * 1000,
    onError: (error) => {
      console.log(error)
    },
  })

  return {
    ...query,
    isLoading: enabled && query.isLoading,
  } as typeof query
}

export function useBlobToString(blob: Blob | null): string {
  const [result, setResult] = useState('')

  useEffect(() => {
    if (blob === null) {
      setResult('')
      return
    }
    void new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        const result = String(reader.result)
        setResult(result)
        resolve(result)
      }
      reader.onerror = (e) => {
        setResult('')
        reject(e)
      }
    })
  }, [blob])

  return result
}
