/* eslint-disable @typescript-eslint/promise-function-async */
import { ICancellationToken, OperationCancelledException } from './cancellation'
import { IEvent } from './event'

export function delay(interval: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, interval)
  })
}

export function timeout(interval: number): Promise<void> {
  return new Promise((resolve, reject) => {
    setTimeout(() => reject(new Error('timeout')), interval)
  })
}

export function whenEvent<T>(
  event: IEvent<T>,
  predicate: (arg: T) => boolean,
  ct?: ICancellationToken
): Promise<void> {
  return new Promise((resolve, reject) => {
    let done = false

    function handler(arg: T): void {
      if (predicate(arg)) {
        event.remove(handler)
        done = true
        resolve()
      }
    }

    event.add(handler)

    void ct?.promise.then(() => {
      if (!done) {
        event.remove(handler)
        done = true
        reject(new OperationCancelledException())
      }
    })
  })
}

export function whenOnline(timeout: number): Promise<void> {
  if (window.navigator.onLine) {
    return Promise.resolve()
  }
  return new Promise((resolve, reject) => {
    function onOnline(): void {
      window.removeEventListener('online', onOnline)
      window.clearTimeout(th)
      resolve()
    }
    function onTimeout(): void {
      window.removeEventListener('online', onOnline)
      reject(new Error('timeout'))
    }
    window.addEventListener('online', onOnline)
    const th = setTimeout(onTimeout, timeout)
  })
}