import React from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core'

import { BooleanAnswer, HyperlinkAnswer, NumericAnswer, PhotoAnswer, TextAnswer } from '../../../model/answer'
import { IQuestion } from '../../../model/question'
import { IQuestionGroup } from '../../../model/question-group'
import { IQuestionnaire } from '../../../model/questionnaire'
import { ISurvey } from '../../../model/survey'
import { renderContent } from '../../execute-survey-control/execute-survey-control'
import { getAvailableQuestions } from '../sales-expert/tabs-new/trainee-skills/predicate-questions'
import { filterTextDialog } from './composite-screen/surveys/survey-view'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: {
      fontStyle: 'italic',
    },
    comment: {
      marginTop: 3,
      letterSpacing: -0.25,
      fontSize: '0.75em',
      color: 'grey',
    },
    groupDivider: {
      backgroundColor: '#f2f2f2',
    },
    answer: {
      textAlign: 'center',
      verticalAlign: 'top',
    },
  }),
)

interface IProps {
  survey: ISurvey
  questionnaire: IQuestionnaire
  surveyName?: string
}

export const SurveyPreview: React.FC<IProps> = ({ survey, questionnaire, surveyName }) => {
  const classes = useStyles()

  const availableQuestions = getAvailableQuestions(questionnaire?.questions ?? [], survey!.answers ?? [])
    .filter(filterTextDialog)
    .sort((a, b) => {
      function getGroupOrder(q: IQuestion): number {
        return questionnaire.questionGroups?.find((group) => group.code === q.questionGroupCode)?.orderNumber ?? 0
      }

      const groupOrder = getGroupOrder(a) - getGroupOrder(b)
      return groupOrder || a.orderNumber - b.orderNumber
    })

  const availableGroups =
    questionnaire.questionGroups?.filter((group) =>
      availableQuestions.find((entry) => entry.questionGroupCode === group.code),
    ) ?? []

  const questionnaireEntries = [
    ...availableGroups,
    ...availableQuestions.filter((entry) => !entry.questionGroupCode),
  ].sort((a, b) => {
    return a.orderNumber - b.orderNumber
  })

  function renderEntry(question: IQuestion): React.ReactElement {
    const answer = survey!.answers?.find((answer) => answer.questionCode === question.code)
    let value = ''

    if (answer?.$type === 'PMI.FACE.BDDM.Extensions.Classes.FaceNullAnswer') {
      value = 'Не ответил(а) на вопрос'
    } else {
      switch (question.$type) {
        case 'PMI.FACE.BDDM.Extensions.Classes.FaceBooleanQuestion':
          value = (answer as BooleanAnswer)?.reply ?? ''
          break
        case 'PMI.FACE.BDDM.Extensions.Classes.FaceTextQuestion':
          value = (answer as TextAnswer)?.reply ?? ''
          break
        case 'PMI.FACE.BDDM.Extensions.Classes.FaceHyperlinkQuestion':
          value = (answer as HyperlinkAnswer)?.reply ? question.finishedDisplayName ?? 'Выполнено' : 'Не выполнено'
          break
        case 'PMI.FACE.BDDM.Extensions.Classes.FaceNumericQuestion':
          value = String((answer as NumericAnswer)?.reply) ?? ''
          break
        case 'PMI.FACE.BDDM.Extensions.Classes.FacePhotoQuestion':
          value = `${(answer as PhotoAnswer)?.reply.parts.length} фото`
          break
      }
    }

    return (
      <tr key={question.code}>
        <td>
          <div className={classes.description}>{question.description}</div>
          {renderContent(question.content)}
          {answer?.comment && <div className={classes.comment}>Комментарий: {answer.comment}</div>}
        </td>
        <td className={classes.answer}>{value}</td>
      </tr>
    )
  }

  return (
    <table>
      <tbody>
        <tr>
          {surveyName ? (
            <th colSpan={2}>{surveyName}</th>
          ) : (
            <>
              <th>Вопрос</th>
              <th>Ответ</th>
            </>
          )}
        </tr>
        {questionnaireEntries.map((entry) => {
          if (isGroup(entry)) {
            const group = entry
            const questions = availableQuestions
              .filter((question) => question.questionGroupCode === group.code)
              .map(renderEntry)
            return (
              <React.Fragment key={group.code}>
                <tr>
                  <th colSpan={2} className={classes.groupDivider}>
                    {group.name}
                  </th>
                </tr>
                {questions}
              </React.Fragment>
            )
          } else {
            return renderEntry(entry)
          }
        })}
      </tbody>
    </table>
  )
}

type QuestionnaireEntry = IQuestionGroup | IQuestion

function isGroup(entry: QuestionnaireEntry): entry is IQuestionGroup {
  if ('name' in entry) return true
  return false
}
