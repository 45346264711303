import React, { useContext, useState, useEffect } from 'react'

import { createStyles, Theme, Box, Fab, Typography, Drawer } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { useTranslation } from 'react-i18next'
import { useAsync, useNetworkState } from 'react-use'

import { LogManager } from '../../../../../infrastructure/logger'
import { grays } from '../../../../../layout/theme'
import { createReferenceToEntity, IEntityReference } from '../../../../../model/base'
import { FieldForceVisitEscalationReason } from '../../../../../model/dictionary-item'
import { BusinessError } from '../../../../../model/errors'
import { IPOSReference } from '../../../../../model/pos'
import { IProblemStatusProcessRule, IProblemTemplate } from '../../../../../model/problem-template'
import { IPhotosPropertyScreenItem, IScreenItem, IStringInputScreenItem } from '../../../../../model/screen-item'
import { ApiContext, ProfileContext } from '../../../../../providers'
import { useDefaultCodeSpace } from '../../../../../providers/config/useDefaultCodeSpace'
import { ProblemErrorCode } from '../../../../../services/problem-service-api'
import { appToast } from '../../../../../utils'
import { useTryUpload } from '../../../../_common/hooks/useTryUpload'
import { DocumentsScreenItemContainer } from '../../../../documents/components/documents-screen-items/documents-screen-item-container'
import { ErrorPage } from '../../../../error-page'
import DocumentListDrawerMenu from '../../../../tasks/components/document-list-drawer-menu'
import { useUpdateProperty } from '../../../../tasks/nested/useUpdateProperty'
import { useScriptTaskContext } from '../../../../tasks/script-tasks/script-task-context'
import { ItemCard } from '../../../../tasks/template-tasks/composite-screen/item-card'
import { StringInputScreenItem } from '../../../../tasks/template-tasks/composite-screen/string-input-screen-item'
import ProblemSelectDetailsScreenItem from '../items/problem-select-details-screen-item'
import ProblemSelectTemplateScreenItem from '../items/problem-select-template-screen-item'
import ProblemValidateButton from '../items/problem-validate-button'
import { getOptionalFields, getRequiredFields } from '../utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerPaper: {
      height: '90%',
      borderTopLeftRadius: theme.spacing(2),
      borderTopRightRadius: theme.spacing(2),
      padding: '48px 66px',
      zIndex: 20,
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(4),
      },
    },
    descriptionTitle: {
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: 24,
      lineHeight: '24px',
      marginBottom: 25,
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.h3.fontSize,
      },
    },
    closeButton: {
      textTransform: 'none',
      margin: 'auto auto 0',
      flex: '0 0 auto',
    },
    infoIcon: {
      position: 'absolute',
      bottom: 26,
      right: 29,
      width: 20,
      height: 20,
      color: theme.palette.primary.main,
      cursor: 'pointer',
      zIndex: 10,
      [theme.breakpoints.down('xs')]: {
        bottom: 20,
      },
    },
    select: {
      margin: theme.spacing(1, 0),
      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
      width: '100%',
    },
    menuItem: {
      paddingTop: 12,
      paddingBottom: 12,
      whiteSpace: 'unset',
    },
  }),
)

interface IProps {
  navigateHandler: () => void
}

interface IDocuments {
  relatedDocuments: IEntityReference[]
}

interface IProblemDetails {
  problemDetailsCaption: string
  problemDetailsList: string[]
  problemDetailsRequired: boolean
  problemDetailsUsage: string
}

const DocumentsViewer: React.FC<IDocuments> = ({ relatedDocuments }) => {
  const api = useContext(ApiContext)
  const ids: string[] = []
  const documents = useAsync(async () => {
    return await api.document!.getContentDocuments(relatedDocuments)
  }, [relatedDocuments])

  if (documents.error) {
    return <ErrorPage errorMessage={documents.error.message} />
  }
  if (documents.loading && !documents.value) return <></>

  for (const doc of documents!.value!) {
    if (doc.status === 'Active') {
      ids.push(doc.content.target)
    }
  }
  return <DocumentListDrawerMenu inboxIds={ids} title='Документы' />
}

const logger = LogManager.getLogger('problemCreateBase')

const ProblemCreateBase: React.FC<IProps> = ({ navigateHandler }) => {
  const MAX_PHOTO_COUNT = 5
  const { t } = useTranslation('problems')
  const currentUser = useContext(ProfileContext)
  const tryUploadPendingProblem = useTryUpload('problem')
  const classes = useStyles()
  const api = useContext(ApiContext)
  const updateProperty = useUpdateProperty()
  const localContext = useScriptTaskContext()
  const { problem } = localContext
  const pos = localContext.pos!
  let availableProblemTemplates = [] as IProblemTemplate[]
  const networkState = useNetworkState()
  const isOnline = networkState.online
  const defaultCodeSpace = useDefaultCodeSpace()

  const [reason, setReason] = useState<FieldForceVisitEscalationReason>()
  const [creationProcess, setCreationProcess] = useState<IProblemStatusProcessRule>()
  const [problemDetails, setProblemDetails] = useState<IProblemDetails>()
  const [selectedTemplate, setSelectedTemplate] = useState<IProblemTemplate>()
  const [isAllRequiredFieldsFilled, setIsAllRequiredFieldsFilled] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('Некорректно заполнено обязательное поле')
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleChange = async (item: IScreenItem, value: string, oldValue: string): Promise<void> => {
    await updateProperty(item.propertyName, value)
  }

  const setTaskPropertyPath = async (value: unknown): Promise<void> => {
    await updateProperty('problem.executiveComment', value)
  }

  const addProblems = async (details?: string[]): Promise<void> => {
    const newProblems = await api.problem.createNewProblems({
      escalationReason: reason as FieldForceVisitEscalationReason,
      executiveComment: creationProcess?.commentAvailable ? problem!.executiveComment ?? '' : '',
      attachment: creationProcess!.photoAvailable ? problem!.attachment : undefined,
      problemDetails: problemDetails?.problemDetailsUsage ? details : undefined,
      location: createReferenceToEntity<IPOSReference>(pos, defaultCodeSpace, 'PMI.BDDM.Staticdata.POSReference'),
    })
    if (isOnline) {
      void tryUploadPendingProblem(newProblems.map((problem) => problem.code))
    }
  }

  const createProblem = async (): Promise<void> => {
    if (availableProblemTemplates.length === 0) {
      appToast.info('Не выбран тип проблемы. Добавление невозможно')
      return
    }
    if (!isAllRequiredFieldsFilled) {
      appToast.info(errorMessage)
      return
    }
    const details = problem?.problemDetails?.split('$%')
    try {
      await addProblems(details)
      navigateHandler()
    } catch (err) {
      if (err instanceof BusinessError && err.code === ProblemErrorCode.DuplicateProblem) {
        appToast.info('Нельзя создавать дубликат проблемы')
        return
      }
      const message = 'Ошибка при добавлении проблемы'
      logger.error('get', message, err)
      appToast.error(message)
    }
  }

  useEffect(() => {
    if (problemsTemplates?.value) {
      const selectedTemplate = problemsTemplates!.value!.find((item) => item.escalationReason.code === reason!.code)
      setSelectedTemplate(selectedTemplate)
      const creationStaticProcessNew = selectedTemplate!.statusProcessRules.find(
        (process) => process.problemStatus === 'New',
      )
      setCreationProcess(creationStaticProcessNew as IProblemStatusProcessRule)
      setProblemDetails({
        problemDetailsCaption: selectedTemplate!.problemDetailsCaption as string,
        problemDetailsList: selectedTemplate!.problemDetailsList?.map((i) => i.name) ?? [],
        problemDetailsRequired: selectedTemplate!.problemDetailsRequired as boolean,
        problemDetailsUsage: selectedTemplate!.problemDetailsUsage as string,
      })
    }
  }, [reason])

  useEffect(() => {
    if (!creationProcess) return

    const optionalFields = getOptionalFields(creationProcess)
    const requiredFields = getRequiredFields(creationProcess)
    if (problemDetails?.problemDetailsRequired) {
      requiredFields.push('problemDetailsCaption')
    }

    const isOptionalAttachmentsValid =
      !problem!.attachment ||
      problem!.attachment.parts.length === 0 ||
      problem!.attachment.parts.length <= MAX_PHOTO_COUNT

    const isRequiredAttachmentsValid =
      problem!.attachment && problem!.attachment.parts.length > 0 && problem!.attachment.parts.length <= MAX_PHOTO_COUNT

    const optionalFieldsValidation = optionalFields.every((field) => {
      if (field === 'photoAvailable') {
        return isOptionalAttachmentsValid
      }
      if (field === 'commentAvailable') {
        return true
      }
      return false
    })

    const requiredFieldsValidation = requiredFields.every((field) => {
      if (field === 'commentRequired') {
        return Boolean(problem!.executiveComment)
      }
      if (field === 'problemDetailsCaption') {
        return Boolean(problem!.problemDetails)
      }
      if (field === 'photoRequired') {
        return isRequiredAttachmentsValid
      }
      return false
    })

    if (requiredFields.includes('commentRequired') && !problem!.executiveComment) {
      setErrorMessage('Некорректно заполнено обязательное поле')
    }
    if (requiredFields.includes('problemDetailsCaption') && !problem!.problemDetails) {
      setErrorMessage('Некорректно заполнено обязательное поле')
    }
    if (optionalFields.includes('photoAvailable') && !isOptionalAttachmentsValid) {
      setErrorMessage('Необходимо добавить от 1 до 5 фото')
    }
    if (requiredFields.includes('photoRequired') && !isRequiredAttachmentsValid) {
      setErrorMessage('Необходимо добавить от 1 до 5 фото')
    }

    setIsAllRequiredFieldsFilled(optionalFieldsValidation && requiredFieldsValidation)
  }, [problem])

  const problemsTemplates = useAsync(async () => {
    try {
      return await api.problem.getProblemTemplates()
    } catch (error) {
      const message = 'Ошибка при получении шаблонов проблем'
      logger.error('get', message, error)
      throw new Error(message)
    }
  }, [])

  if (problemsTemplates.error) {
    return <ErrorPage errorMessage={problemsTemplates.error.message} />
  }
  if (problemsTemplates.loading && !problemsTemplates.value) return <></>

  availableProblemTemplates = problemsTemplates!.value!.filter((template) => {
    if (!template.reportingRule.jobFunctions) {
      return false
    }
    return template.reportingRule.jobFunctions.some(
      (item) => item.code === currentUser.value?.fieldPositionRole?.jobFunction.code,
    )
  })

  const itemsList = availableProblemTemplates
    .map((template) => ({ name: template.escalationReason.name, code: template.escalationReason.code }))
    .sort((a, b) => {
      if (a.name > b.name) return 1
      if (a.name < b.name) return -1
      return 0
    })

  const screenItem: IPhotosPropertyScreenItem = {
    $type: 'PMI.FACE.BDDM.Extensions.Classes.PhotosPropertyScreenItem',
    propertyName: 'problem.attachment',
    displayName: 'Фото',
    orderNumber: 1,
    editSettings: {
      minPhotoCountRequired: creationProcess?.photoRequired ? 1 : 0,
      maxPhotoCountAvailable: MAX_PHOTO_COUNT,
    },
    viewSettings: {},
  }

  const inputItem: IStringInputScreenItem = {
    $type: 'PMI.FACE.BDDM.Extensions.Classes.StringInputScreenItem',
    propertyName: 'problem.executiveComment',
    placeholder: 'Введите комментарий автора',
    errorHelperText: 'Некорректно заполнено обязательное поле',
    maxLength: 1000,
    required: {
      $type: 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate',
      value: creationProcess?.commentRequired as boolean,
    },
    displayName: 'Комментарий автора',
    orderNumber: 1,
  }

  return (
    <>
      {availableProblemTemplates?.length > 0 ? (
        <Box mb='10px' position='relative'>
          <ProblemSelectTemplateScreenItem
            itemsList={itemsList}
            displayName={t('typeOfProblem')}
            isRequired={true}
            onChange={async (code) => {
              const reason = availableProblemTemplates.find(
                (template) => template.escalationReason.code === code,
              )?.escalationReason
              setReason(reason)
              await updateProperty('problem.escalationReason', reason)
            }}
          />
          {
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            reason && (selectedTemplate?.description || selectedTemplate?.relatedDocuments) && (
              <Box className={classes.infoIcon} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <InfoOutlinedIcon />
              </Box>
            )
          }
        </Box>
      ) : (
        <Box mb='10px'>
          <ItemCard isError={true} label={<Typography variant='inherit'>{t('problemType')}</Typography>}>
            <Typography variant='body1' color='textSecondary'>
              {t('thereAreNoTypesAvailableForSelection')}
            </Typography>
          </ItemCard>
        </Box>
      )}

      {problemDetails?.problemDetailsUsage && (
        <Box mb='0px'>
          <ProblemSelectDetailsScreenItem
            itemsList={problemDetails.problemDetailsList}
            displayName={problemDetails.problemDetailsCaption || 'Детали'}
            isMultiSelect={problemDetails.problemDetailsUsage === 'MultiSelect'}
            isRequired={problemDetails.problemDetailsRequired}
            onChange={async (value) => await updateProperty('problem.problemDetails', value)}
          />
        </Box>
      )}

      {creationProcess?.photoAvailable && (
        <Box mb='10px'>
          <DocumentsScreenItemContainer entity={screenItem} isReadOnly={false} />
        </Box>
      )}

      {creationProcess?.commentAvailable && (
        <Box mb='10px'>
          <StringInputScreenItem
            item={inputItem}
            handleChange={handleChange}
            setTaskPropertyPath={setTaskPropertyPath}
          />
        </Box>
      )}

      <ProblemValidateButton handleClick={createProblem} validationState={isAllRequiredFieldsFilled} caption='add' />

      <Drawer
        anchor='bottom'
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        PaperProps={{
          className: classes.drawerPaper,
        }}
      >
        <Typography className={classes.descriptionTitle}>{t('descriptionOfTheProblemType')}</Typography>

        <Box mb='-25px'>
          <ItemCard label={t('Description')}>
            <Typography style={{ color: grays.gray2 }}>
              {
                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                selectedTemplate?.description || selectedTemplate?.escalationReason.name || t('descriptionisMissing')
              }
            </Typography>
          </ItemCard>
        </Box>

        <Box>
          {selectedTemplate?.relatedDocuments && (
            <DocumentsViewer relatedDocuments={selectedTemplate!.relatedDocuments} />
          )}
        </Box>

        <Fab
          color='primary'
          aria-label='add'
          variant='extended'
          className={classes.closeButton}
          onClick={() => setIsMenuOpen(false)}
        >
          <Typography>{t('close')}</Typography>
        </Fab>
      </Drawer>
    </>
  )
}

export default ProblemCreateBase
