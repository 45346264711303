import React from 'react'

import { createStyles } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import { makeStyles, Theme } from '@material-ui/core/styles'
import DoneIcon from '@material-ui/icons/Done'
import DonutLargeIcon from '@material-ui/icons/DonutLarge'
import endOfDay from 'date-fns/endOfDay'
import _isPast from 'date-fns/isPast'
import _isToday from 'date-fns/isToday'
import { useTranslation } from 'react-i18next'

import { IVisit } from '../../../model/visit'
import { RequireGeo } from '../../../providers'

export interface IVisitActionButtonProps {
  visit: IVisit
  loading: boolean
  onStartClick: () => void
  onEditClick: () => void
  onFinishClick: () => void
  isCanFinish: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    finishButton: {
      fontWeight: 500,
      zIndex: 1600,
      textTransform: 'none',
      color: '#538928',
    },
    editButton: {
      fontWeight: 500,
      zIndex: 1600,
      textTransform: 'none',
      color: theme.palette.primary.main,
    },
    finishIcon: {
      textTransform: 'none',
      marginRight: 6,
    },
  }),
)

const VisitActionButton: React.FC<IVisitActionButtonProps> = (props) => {
  const { visit } = props
  const classes = useStyles()
  const { t } = useTranslation('visits')

  if (!visit) {
    return null
  }

  const isToday = _isToday(new Date(visit.plannedStartDate))
  const isPast = _isPast(endOfDay(visit.plannedStartDate))

  const StartButton = (
    <Fab
      color='primary'
      aria-label='start'
      variant='extended'
      className={classes.editButton}
      onClick={props.onStartClick}
      disabled={visit.status !== 'Planned' || isPast}
    >
      <RequireGeo />
      {t('visitStartAction')}
    </Fab>
  )

  // https://jira.app.pconnect.biz/browse/FACE-2196
  // const EditButton = (
  //   <Fab
  //     color='primary'
  //     aria-label='edit'
  //     variant='extended'
  //     className={classes.editButton}
  //     onClick={props.onEditClick}
  //     disabled={visit.status !== 'Planned' || isPast}
  //   >  {t('visitEditAction')} </Fab>
  // )

  const FinishButton = (
    <Fab
      color='primary'
      aria-label='finish'
      variant='extended'
      className={classes.finishButton}
      onClick={props.onFinishClick}
      disabled={props.loading}
    >
      {props.isCanFinish ? (
        <DoneIcon className={classes.finishIcon} />
      ) : (
        <DonutLargeIcon className={classes.finishIcon} />
      )}{' '}
      {t('visitFinishAction')}
    </Fab>
  )

  if (visit.status === 'Canceled' || visit.status === 'Finished') {
    return null
  }

  if (visit.status === 'InProgress') {
    return FinishButton
  }

  if (visit.status === 'Planned' && isToday) {
    return StartButton
  }

  return null
}

export default VisitActionButton
