import React, { useContext, useState } from 'react'

import { createStyles, Dialog, IconButton, Menu, MenuItem } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { MoreVert } from '@material-ui/icons'
// import endOfDay from 'date-fns/endOfDay'
// import _isPast from 'date-fns/isPast'
import _isToday from 'date-fns/isToday'
import { useTranslation } from 'react-i18next'
import { generatePath, Link, useNavigate } from 'react-router-dom'

import { LogManager } from '../../../infrastructure/logger'
import { VisitCancelationReason } from '../../../model/dictionary-item'
import { IPointOfSale } from '../../../model/pos'
import { IVisit } from '../../../model/visit'
import { ApiContext } from '../../../providers'
import { useFetchPendingItems } from '../../../providers/menu-data/pending-items-store'
import { ModalContext } from '../../../providers/modal'
import { appToast, dateFormat } from '../../../utils'
import { getGeolocation } from '../../../utils/get-geolocation'
import { VisitWithPos } from '../../_common/hooks/useFetchVisit'
import { useSaveVisitCoordinates } from '../../_common/hooks/useSaveVisitCoordinates'
import { useTryUpload } from '../../_common/hooks/useTryUpload'
import { useFeatureRoute } from '../../custom-app-feature/app-feature-context'
import { VisitModal } from '../components'
import { visitsRoutes } from '../visits-app-feature/visits-app-feature'
import { setVisitStatusFn } from './utils'
import { VisitRemoveModal } from './visit-remove-modal'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // position: 'fixed',
      // top: 21,
      // right: 16,
      // [theme.breakpoints.down('xs')]: {
      //   top: 8,
      //   right: 6
      // },
    },
  }),
)

const logger = LogManager.getLogger('VisitMenu')

export interface IVisitMenuProps {
  visit: VisitWithPos
  isOpenVisitMenuItem?: boolean
  // onEdit: () => void
  reasonsCancelledItems?: VisitCancelationReason[]
  retry?: () => void
}

const VisitMenu: React.FC<IVisitMenuProps> = (props) => {
  const classes = useStyles()
  const api = useContext(ApiContext)
  const modalContext = useContext(ModalContext)
  const fetchPendingItems = useFetchPendingItems()
  // const { id: visitCode } = useParams<{ id: string }>()
  const saveVisitCoordinates = useSaveVisitCoordinates()

  const navigate = useNavigate()
  const featureRoute = useFeatureRoute()
  const tryUploadVisit = useTryUpload('visit')

  const { reasonsCancelledItems, visit, retry, isOpenVisitMenuItem = true } = props

  const { t } = useTranslation('visits')

  const [isCancelModalOpen, setCancelModalOpen] = useState<VisitCancelationReason | true>()
  const [isRepairModalOpen, setRepairModalOpen] = useState(false)

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement>()
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(undefined)
  }

  // const isToday = _isToday(new Date(visit.plannedStartDate))
  // const isPast = _isPast(endOfDay(visit.plannedStartDate))
  //
  // const onEdit = async (): Promise<void> => {
  //   await navigate(`/visits/${visit.code}/edit`)
  // }

  const onCancel = (): void => {
    if (!reasonsCancelledItems?.length) {
      setCancelModalOpen(true)
      return
    }

    modalContext.open(
      <Dialog open fullScreen onClose={() => modalContext.close()}>
        <VisitRemoveModal
          reasonsItems={reasonsCancelledItems}
          onClose={() => {
            modalContext.close()
          }}
          onRemoveHandler={(item) => {
            setCancelModalOpen(item)
          }}
        />
      </Dialog>,
    )
  }

  const onRepair = (): void => {
    setRepairModalOpen(true)
  }

  const onVisitCancel = async (): Promise<void> => {
    try {
      await setVisitStatusFn({
        apiContext: api,
        visitCode: visit.code,
        visitStatus: 'Canceled',
        cancelationReason:
          typeof isCancelModalOpen !== 'boolean'
            ? isCancelModalOpen
            : {
                $type: 'PMI.BDDM.Transactionaldata.VisitCancelationReason',
                code: 'CanceledByUser',
                name: 'Отменено пользователем',
              },
      })
      await tryUploadVisit(visit.code)
      modalContext.close()
    } catch (e) {
      appToast.error('Ошибка при отмене визита')
    }
    fetchPendingItems()

    setCancelModalOpen(undefined)
    setAnchorEl(undefined)
    retry?.()
    await navigate(generatePath(visitsRoutes.root, { featureRoute }))
  }

  const onVisitRepair = async (): Promise<void> => {
    setRepairModalOpen(false)
    try {
      if (visit.preCanceledStatus === 'InProgress') {
        await saveVisitCoordinates(visit.code, await getGeolocation())
      }
    } catch (e) {
      logger.error('onVisitRepair', 'coordinates', e, { visitCode: visit.code })
    }
    try {
      const res = await api.visits.restoreCanceledVisit(visit.code)
      if (!res) {
        throw new Error('restoreCanceledVisit failed')
      }
      await tryUploadVisit(visit.code)
    } catch (e) {
      const message = 'Ошибка при восстановлении визита'
      logger.error('onVisitRepair', message, e, { visitCode: visit.code })
      appToast.error(message)
    }
    fetchPendingItems()

    setRepairModalOpen(false)
    setAnchorEl(undefined)
    retry?.()
  }

  return (
    <div className={classes.root}>
      <IconButton
        id='demo-positioned-button'
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id='demo-positioned-menu'
        aria-labelledby='demo-positioned-button'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={(evt) => {
          evt.stopPropagation()
          evt.preventDefault()
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {isOpenVisitMenuItem && (
          <MenuItem>
            <Link to={generatePath(visitsRoutes.visitView, { featureRoute, id: visit.code })}>
              {t('visitOpenAction')}
            </Link>
            {/*Открыть визит*/}
          </MenuItem>
        )}
        {visit.pointOfSaleCode && (
          <MenuItem
            onClick={() => {
              navigate(`/visits/${visit.code}/store/${visit.pointOfSaleCode}/menu`, {
                state: {
                  prevPath: location.pathname,
                  rootPrevPath: location.pathname,
                },
              })
            }}
          >
            {t('visitOpenPOS')}
          </MenuItem>
        )}
        {/*FACE-2196 Visit edit*/}
        {/*{isToday && (*/}
        {/*  <MenuItem disabled={visit.status !== 'Planned' || isPast} onClick={onEdit}>*/}
        {/*    {t('visitEditAction')}*/}
        {/*  </MenuItem>*/}
        {/*)}*/}
        {props.visit.status === 'Canceled' ? (
          <MenuItem disabled={!_isToday(visit.plannedStartDate)} onClick={onRepair}>
            {t('visitRestoreAction')}
          </MenuItem>
        ) : (
          <MenuItem
            disabled={!(props.visit.status === 'Planned' || props.visit.status === 'InProgress')}
            onClick={() => {
              onCancel()
              setAnchorEl(undefined)
            }}
          >
            {t('visitCancelAction')}
          </MenuItem>
        )}
      </Menu>

      <VisitModal
        open={!!isCancelModalOpen}
        onOk={onVisitCancel}
        onClose={() => setCancelModalOpen(undefined)}
        title='Подтверждение'
        message={getCancelMessage(visit)}
      />

      <VisitModal
        open={isRepairModalOpen}
        onOk={onVisitRepair}
        onClose={() => setRepairModalOpen(false)}
        title='Подтверждение'
        message='Вы уверены, что хотите восстановить визит?'
      />
    </div>
  )
}

export default VisitMenu

function getCancelMessage(visit: IVisit & { pointOfSale: IPointOfSale | null }): string {
  if (!visit || !visit.plannedStartDate || !visit.pointOfSale?.name) {
    return 'Визит будет отменен'
  }

  return `Визит в "${visit.pointOfSale.name}" на ${dateFormat(
    new Date(visit.plannedStartDate),
    'dd.MM.yyyy',
  )} будет отменен`
}
