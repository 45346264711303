import React, { useEffect } from 'react'

import { Box, Dialog } from '@material-ui/core'

import { PageContent } from '../../../../../layout/page-content'
import { TitleBar } from '../../../../../layout/title-bar'
import { SubprocessAddItemToListAction } from '../../../../../model/table-screen-item'
import { ITableRecordEntity } from '../../../../admin/data-manager/models/models'
import { InMemoryProcessContext } from '../../../../process/in-memory-process-context'
import { ExecutionProcessControl } from '../../../nested/execution-process-control'
import { useLocalContextService } from '../../../nested/local-context'
import { getContextProperty } from '../../../script-tasks/propertyName'
import { IScriptTaskContext, useScriptTaskContext } from '../../../script-tasks/script-task-context'

interface Props {
  onClose: VoidFunction
  action: SubprocessAddItemToListAction
  context: IScriptTaskContext
  onSubmit: (record: unknown) => void
  children?: never
}

export const SubprocessAddItemToListControl: React.FC<Props> = (props) => {
  const { onClose, action, context, onSubmit } = props
  const process = context.template.subProcesses[action.process.code]
  useEffect(() => {
    console.log('SubprocessAddItemToListControl mount', process)
  }, [])
  const title = action.displayNameFormat ?? 'Добавление'
  return (
    <Dialog open fullScreen onClose={onClose}>
      <TitleBar onBack={onClose}>{title}</TitleBar>
      <PageContent>
        <Box height='100%' zIndex='1'>
          <InMemoryProcessContext
            processUnit={{
              process,
              screens: context.template.userStepScreens,
              scripts: context.template.scripts,
            }}
            rootRecordName={action.elementVariable ?? 'newItem'}
            initialState={context}
          >
            <ModalProcess onSubmit={onSubmit} />
          </InMemoryProcessContext>
        </Box>
      </PageContent>
    </Dialog>
  )
}
const ModalProcess: React.FC<Pick<Props, 'onSubmit'>> = (props) => {
  const { onSubmit } = props
  const processContext = useScriptTaskContext()
  const processContextService = useLocalContextService()
  const { processUnit } = processContext
  const record = getContextProperty<ITableRecordEntity>(processContext, processContextService.rootRecordName!)
  return (
    <ExecutionProcessControl
      process={processUnit!.process}
      useFinishConfirmation={false}
      onProcessFinish={onSubmit && (async () => onSubmit(record))}
    />
  )
}
