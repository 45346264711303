import { sortBy, orderBy } from 'lodash'

import { DateTime } from './common'

export type Code = string & { __flavor?: 'Code' }
export type CodeSpace = string & { __flavor?: 'CodeSpace' }
export type EntityKey = string & { __flavor?: 'EntityKey' }

export interface IBDDMObject {
  code: Code
  codeSpace?: CodeSpace
}

export interface IEntity extends IBDDMObject {
  _key?: EntityKey
  $type?: string
  //alternateCodes?: IAlternateCode[]
  creationTime?: DateTime
  updateTime: DateTime
  version?: IEntityVersion
}

export interface IEntityVersion {
  code: Code
  startDate: DateTime
  endDate?: DateTime
}

export interface IEntityReference extends IBDDMObject {
  $type?: string
  name?: string
  version?: IEntityVersion
}

export interface IAlternateCode {
  code: Code
  codeSpace: CodeSpace
}

export enum SyncErrorCode {
  Unauthorized = 'Unauthorized',
  Forbidden = 'Forbidden',
  Maintenance = 'Maintenance',
  BusinessError = 'BusinessError',
  ServerError = 'ServerError',
  NetworkError = 'NetworkError',
  OtherError = 'OtherError',
}

export interface ISyncStatus {
  changeTime?: DateTime
  lastSaveTime?: DateTime
  lastSaveError?: string
  lastSaveErrorCode?: SyncErrorCode
  lastLoadTime?: DateTime
  lastLoadError?: string
  lastLoadErrorCode?: SyncErrorCode
  archiveTime?: DateTime
}

export interface ISyncable {
  _changeTime?: DateTime
  _sync?: ISyncStatus
}

export interface ISyncEntity extends IEntity, ISyncable {}

export function generateEntityCode(prefix: string): Code {
  const seed = Math.floor(Math.random() * 100000)
  return `${prefix ?? ''}${seed.toString().padStart(5, '0')}${Date.now().toString(36).toUpperCase()}`
}

export function isVersionActive(version: IEntityVersion | null | undefined, date: Date): boolean {
  if (!version) {
    return false
  }
  const ts = date.getTime()
  return version.startDate <= ts && (version.endDate == null || ts < version.endDate)
}

export function findActiveVersion<T extends IEntity>(entities: T[]): T | undefined {
  const activeVersions = entities.filter((e) => isVersionActive(e.version, new Date()))
  if (activeVersions.length === 0) {
    return undefined
  }
  if (activeVersions.length === 1) {
    return activeVersions[0]
  }
  const sorted = sortBy(activeVersions, 'version.code')
  return sorted[sorted.length - 1]
}

export function getLatestVersion<T extends { version: IEntityVersion }>(entities: T[]): T | undefined {
  return orderBy(entities, (x) => x.version.startDate, 'desc')[0]
}

export type VersionStatus = 'obsolete' | 'current' | 'pending'

export function getEntityVersionStatus(entity: { version: IEntityVersion }, date: Date | number): VersionStatus
export function getEntityVersionStatus(
  entity: { version: IEntityVersion } | undefined,
  date: Date | number,
): VersionStatus | undefined
export function getEntityVersionStatus(
  entity: { version: IEntityVersion } | undefined,
  date: Date | number,
): VersionStatus | undefined {
  if (!entity) {
    return undefined
  }

  return getVersionStatus(entity.version, date)
}

export function getVersionStatus(version: IEntityVersion, date: Date | number): VersionStatus {
  const ts = new Date(date).getTime()

  if (version.startDate > ts) {
    return 'pending'
  }

  if ((version.endDate ?? Infinity) > ts) {
    return 'current'
  }

  return 'obsolete'
}

interface ReferenceableObject {
  code: Code
  codeSpace?: CodeSpace
  $type?: string
  version?: IEntityVersion
}

export function createReferenceToEntity<T extends IEntityReference = IEntityReference>(
  obj: ReferenceableObject,
  defaultCodeSpace: CodeSpace,
  $type?: T['$type'] | null,
  withoutVersion?: boolean,
): T {
  const ref: IEntityReference = {
    code: obj.code,
    codeSpace: obj.codeSpace ?? defaultCodeSpace,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    name: (obj as any).name, // assuming entity has 'Name' property
  }

  if ($type) {
    ref.$type = $type
  } else if ($type === undefined && obj.$type) {
    //HACK: assuming reference types have '..Reference' suffix
    ref.$type = obj.$type + 'Reference'
  }

  const version = obj.version
  if (version && !withoutVersion) {
    ref.version = {
      code: version.code,
      startDate: version.startDate,
      endDate: version.endDate,
    }
  }

  return ref as T
}

export function createReferenceByKey<T extends IEntityReference = IEntityReference>(
  key: EntityKey,
  defaultCodeSpace: CodeSpace,
  type?: T['$type'] | null,
): T {
  return createReferenceToEntity<T>({ ...parseEntityKey(key) }, defaultCodeSpace, type)
}

export function isReferenceToEntity(ref: IEntityReference, obj: IEntity | IEntityReference): boolean {
  if (obj.code === ref.code && obj.codeSpace === ref.codeSpace) {
    const objVersion = obj.version
    const refToVersion = ref.version
    if (objVersion == null && refToVersion == null) {
      return true
    }
    if (objVersion != null && refToVersion != null) {
      return objVersion.code === refToVersion.code
      // TODO: что если версия сейчас не активна?
    }
    if (objVersion == null) {
      // TODO: принимаем ли ссылку с версией на объект вез версии?
      return true
    }
  }
  return false
}

export function isReferenceToEntityIgnoreVersion(ref: IEntityReference, obj: IEntity | IEntityReference): boolean {
  return obj.code === ref.code && obj.codeSpace === ref.codeSpace
}

export function getEntityKey(obj: IEntity | IEntityReference, omitVersion = false): EntityKey {
  let key: string = obj.code
  if (obj.codeSpace) key = `${obj.codeSpace}~${key}`
  if (!omitVersion && obj.version?.code) key = `${key}.${obj.version.code}`
  return key as EntityKey
}

export function parseEntityKey(key: EntityKey): { code: Code; codeSpace?: CodeSpace; versionCode?: Code } {
  const codeSpaceSeparatorIndex = key.indexOf('~')
  const versionCodeSeparatorIndex = key.lastIndexOf('.')

  if (codeSpaceSeparatorIndex > -1 && versionCodeSeparatorIndex > -1) {
    return {
      codeSpace: key.substring(0, codeSpaceSeparatorIndex) as CodeSpace,
      code: key.substring(codeSpaceSeparatorIndex + 1, versionCodeSeparatorIndex),
      versionCode: key.substring(versionCodeSeparatorIndex + 1),
    }
  } else if (codeSpaceSeparatorIndex > -1) {
    return {
      codeSpace: key.substring(0, codeSpaceSeparatorIndex) as CodeSpace,
      code: key.substring(codeSpaceSeparatorIndex + 1),
    }
  } else if (versionCodeSeparatorIndex > -1) {
    return {
      code: key.substring(0, versionCodeSeparatorIndex),
      versionCode: key.substring(versionCodeSeparatorIndex + 1),
    }
  } else {
    return {
      code: key as string,
    }
  }
}

export function entityKeyToReference(key: EntityKey, $type?: string): IEntityReference {
  const x = parseEntityKey(key)
  const ref: IEntityReference = { code: x.code }
  if ($type) {
    ref.$type = $type
  }
  if (x.codeSpace) {
    ref.codeSpace = x.codeSpace
  }
  if (x.versionCode) {
    ref.version = { code: x.versionCode, startDate: Date.now() }
  }
  return ref
}
