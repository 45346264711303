import { DependencyList, useContext, useEffect } from 'react'

import useAsyncFn, { AsyncState } from 'react-use/lib/useAsyncFn'

import { LogManager } from '../../../infrastructure/logger'
import { Code } from '../../../model/base'
import { BusinessError } from '../../../model/errors'
import { IQuestionnaire } from '../../../model/questionnaire'
import { ApiContext } from '../../../providers'
import { useScriptTaskContext } from '../../tasks/script-tasks/script-task-context'

interface IParams {
  questionnaireCode: Code
}

export enum QuestionnaireErrorCode {
  NotFound,
  AssignmentNotFound,
}

const logger = LogManager.getLogger('useFetchQuestionnaire')

/**
 * Загружает список вопросов. Если ни один из параметров не дан, ожидает их подгрузки
 */
export function useFetchQuestionnaire(
  { questionnaireCode }: IParams,
  deps: DependencyList = [],
): AsyncState<IQuestionnaire | null> {
  const api = useContext(ApiContext)
  const { pos, template } = useScriptTaskContext()

  // inline useAsync, to not return null with loading false, when parameters are not passed yet
  const [state, cb] = useAsyncFn(
    async () => {
      try {
        const code = questionnaireCode

        // if (questionnaireCode) {
        //   code = questionnaireCode
        // } else {
        //   if (!questionnaireAssignment) {
        //     const msg = `Не найден подходящий опросник для торговой точки`
        //     throw new BusinessError(QuestionnaireErrorCode.AssignmentNotFound, msg, {
        //       posCode: pos?.code,
        //       templateCode: template.code,
        //     })
        //   }
        //   code = questionnaireAssignment.questionnaire.code
        // }

        const questionnaire = await api.questionnaire.getQuestionnaire(code)

        if (!questionnaire) {
          throw new BusinessError(QuestionnaireErrorCode.NotFound, `Не найден опросник ${code}`, { code })
        }

        return questionnaire
      } catch (error: unknown) {
        if (error instanceof BusinessError) {
          throw error
        }
        const message = 'Ошибка при загрузке списка вопросов'
        logger.error('get', message, error)
        throw new Error(message)
      }
    },
    [...deps],
    { loading: true },
  )

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    if (!questionnaireCode && !(pos?.code || template?.code)) {
      throw new Error('Questionnaire Code не найден')
    } else {
      void cb()
    }
  }, [cb])

  return state
}
