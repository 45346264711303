import React from 'react'

import { Container, createStyles } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      backgroundColor: 'white',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },
    contentWrap: {
      padding: theme.spacing(2),
      minHeight: 250,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1
    },
    content: {
      position: 'relative',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    logo: {
      display: 'block',
      width: 221,
      margin: '30px auto 20px',
      [theme.breakpoints.down('xs')]: {
        width: 157,
        margin: '18px auto 10px',
      }
    },
    footer: {
      pointerEvents: 'none',
      height: 254,
      position: 'relative',
      '&::before, &::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: '#3182CE',
      },
      '&::after': {
        opacity: 0.5
      },
      '&::before': {
        opacity: 0.6,
        backgroundImage: 'url("/mos-city-bg.jpeg")',
        backgroundSize: 'cover',
        backgroundPosition: '100% 94%',
      },
      [theme.breakpoints.down('xs')]: {
        height: 212,
      }
    }
  })
)

export const LockedPage: React.FC = ({ children }) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.root}>
        <img
          className={classes.logo}
          src='/pmi-logo-login-new.png'
          alt='Philip Morris logo'
        />
        <Container disableGutters fixed className={classes.contentWrap} component='main' maxWidth='sm'>
          <div className={classes.content}>{children}</div>
        </Container>
        <div className={classes.footer} />
      </div>
    </>
  )
}
