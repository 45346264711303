import React, { useEffect, useRef, useState } from 'react'

import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { SwipeEventData, useSwipeable } from 'react-swipeable'

const SWIPED_LEFT_INITIAL = 0
const SWIPE_LEFT_OPEN_DELTA_DEFAULT = 300
const SWIPE_THRESHOLD = 100

const SLIDER_Z_INDEX = 105 // should be more than menu
const SLIDER_MENU_Z_INDEX = 100

interface ISwipeSlider {
  menu: JSX.Element // menu component
  onSwiping?: (isSwiping: boolean) => void // event fire while menu is swiping
  closeSwipeMenu?: boolean // menu will be close if props "closeSwipeMenu" passed with true value
  disabled?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative'
    },
    swipeContentWrapper: ({ disabled }: { disabled?: boolean }) => ({
      cursor: disabled ? 'unset' : 'ew-resize',
      position: 'relative',
      zIndex: SLIDER_Z_INDEX
    }),
    swipeMenu: {
      overflow: 'auto',
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: SLIDER_MENU_Z_INDEX,
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-end'
    }
  })
)

export const SwipeSlider: React.FC<ISwipeSlider> = (props) => {
  const classes = useStyles({ disabled: props.disabled })
  const menuRef = useRef<HTMLDivElement>(null)
  const [swipeLeftValue, setSwipeLeftValue] = useState<number>(SWIPED_LEFT_INITIAL)
  const [isSwipeEnd, setIsSwipeEnd] = useState<boolean>(true)
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  const menuWidth = menuRef.current?.offsetWidth ?? SWIPE_LEFT_OPEN_DELTA_DEFAULT

  useEffect(() => {
    if (props.closeSwipeMenu) {
      setSwipeLeftValue(SWIPED_LEFT_INITIAL)
      setIsSwipeEnd(true)
      setIsMenuOpen(false)
    }
  }, [props.closeSwipeMenu])

  useEffect(() => {
    // swipe left
    if (Math.abs(swipeLeftValue) > SWIPE_THRESHOLD) {
      setSwipeLeftValue(-menuWidth)
      setIsMenuOpen(true)
    } else {
      setSwipeLeftValue(SWIPED_LEFT_INITIAL)
      setIsMenuOpen(false)
    }
  }, [isSwipeEnd])

  const handlers = useSwipeable({
    onSwiped: () => {
      setIsSwipeEnd(true)
      if (props.onSwiping) {
        props.onSwiping(false)
      }
    },
    onSwipedRight: () => {
      setSwipeLeftValue(0)
      setIsMenuOpen(false)
    },
    onSwiping: (eventData: SwipeEventData) => {
      if (props.disabled) return
      if (props.onSwiping) {
        props.onSwiping(true)
      }
      if (eventData.dir === 'Left' && Math.abs(eventData.deltaX) < menuWidth && !isMenuOpen) {
        setSwipeLeftValue(eventData.deltaX)
        setIsSwipeEnd(false)
      }
    },
    trackMouse: true,
    preventDefaultTouchmoveEvent: false
  })

  return (
    <div className={classes.root} {...handlers}>
      <div className={classes.swipeContentWrapper} style={{ left: swipeLeftValue }}>
        {props.children}
      </div>
      <div ref={menuRef} className={classes.swipeMenu}>
        {props.menu}
      </div>
    </div>
  )
}

export default SwipeSlider
