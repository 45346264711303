import React from 'react'

import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import { Layout } from '../layout'
import { PageContent } from '../layout/page-content'
import { TitleBar } from '../layout/title-bar'

export const PageSkeleton: React.FC = () => {
  return (
    <Layout>
      <TitleBar>
        <Skeleton width='30%' />
      </TitleBar>
      <PageContent>
        <Box padding={3}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Box>
      </PageContent>
    </Layout>
  )
}
