import type * as monaco from 'monaco-editor'

import { isNonNullable } from '../../../../../utils/isNonNullable'
import { FieldDecl, TypeDecl } from '../../../../admin/bddm-types/models'
import { Typespace } from '../../../../admin/bddm-types/typespace'
import * as ExprUtils from './expr-utils'
import { AnyExpr, getParseTree, PropPath } from './parser/parse'

export class AstPredicateLanguageService {
  static LANGUAGE_ID = 'face-ast-predicate-lang'

  public readonly completionProvider: monaco.languages.CompletionItemProvider = {
    triggerCharacters: ['.'],
    provideCompletionItems: this.provideCompletionItems.bind(this),
  }

  constructor(private readonly typespace: Typespace, private readonly typeNames: string[]) {}

  private get rootTypeDeclList(): TypeDecl[] {
    return this.typeNames.map((typeName) => this.typespace.findByName(typeName)).filter(isNonNullable)
  }

  private provideCompletionItems(
    model: monaco.editor.ITextModel,
    position: monaco.Position,
    context: monaco.languages.CompletionContext,
    token: monaco.CancellationToken,
  ): monaco.languages.CompletionList | null {
    const text = model.getValue()
    const parseTree = getParseTree(text)
    console.log('expr', parseTree)
    const offset = model.getOffsetAt(position)
    const [node, ...nodePath] = ExprUtils.findNodePathAtOffset(parseTree, offset)
    console.log('node at position', node)

    if (!parseTree) {
      const fields = this.rootTypeDeclList.flatMap((x) => this.typespace.getAllFields(x.type))

      return { suggestions: fields.map((field) => this.fieldToCompletionItem(field)) }
    }

    if (!node) {
      const fields = this.rootTypeDeclList.flatMap((x) => this.typespace.getAllFields(x.type))

      return { suggestions: fields.map((field) => this.fieldToCompletionItem(field)) }
    }

    if (node.kind === 'prop_path') {
      return this.propPath([node, ...nodePath], offset)
    }

    return null
  }

  private propPath(nodePath: [PropPath, ...AnyExpr[]], offset: number): monaco.languages.CompletionList | null {
    const propPath = ExprUtils.resolvePropPath(nodePath)
    if (!propPath) {
      return null
    }
    const accessor = propPath.find((segment) => ExprUtils.containsOffset(segment, offset))
    const path = accessor ? propPath.slice(0, propPath.indexOf(accessor)) : propPath

    const typeResolutionOptions = {
      type: Typespace.composeTypeHandlers([Typespace.unwrapOptional, Typespace.unwrapList]),
    }

    const fieldType = this.typespace.getFieldType(
      this.typespace.makeUnion(this.rootTypeDeclList.map((x) => x.type)),
      path.map(ExprUtils.propSegValue),
      typeResolutionOptions,
    )
    if (!fieldType) {
      return null
    }
    const fields = this.typespace.getAllFields(fieldType, typeResolutionOptions)
    return { suggestions: fields.map((field) => this.fieldToCompletionItem(field)) }
  }

  private fieldToCompletionItem(field: FieldDecl): monaco.languages.CompletionItem {
    return {
      kind: 3, // CompletionItemKind.Field
      label: field.name,
      insertText: field.name,
      detail: this.typespace.getDisplayName(field.type),
      range: undefined!,
    }
  }
}
