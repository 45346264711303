import { IVisit } from '../../../model/visit'
import { IApiContext } from '../../../providers/api/api-context'
import { SetVisitStatusRequest } from '../../../services/visit-service-api'
import { appToast } from '../../../utils'

interface ISetVisitStatusFn {
  apiContext: IApiContext
}

export async function setVisitStatusFn({
  apiContext: api,
  visitCode,
  visitStatus,
  cancelationReason,
}: SetVisitStatusRequest & ISetVisitStatusFn): Promise<IVisit | null> {
  let isVisitCanComplete = true
  // даем завершать только те визиты визиты, у которых есть только завершенные или отмененные задачи.
  if (visitStatus === 'Finished') {
    try {
      const tasks = await api.tasks.searchVisitTasks({ visitCode })
      isVisitCanComplete = tasks.every((x) => x.status === 'Finished' || x.status === 'Canceled')
    } catch (e) {
      const msg = 'Ошибка при получении задач визита'
      appToast.error(msg)
      return null
    }
  }
  if (!isVisitCanComplete) {
    return null
  }
  try {
    return await api.visits.setVisitStatus({
      visitCode,
      visitStatus,
      cancelationReason,
    })
  } catch (e) {
    const msg = 'Ошибка при смене статуса визита'
    appToast.error(msg)
    return null
  }
}
