import React, { useContext, useEffect, useState } from 'react'

import { Button, Dialog, DialogActions, DialogTitle, IconButton, Menu, MenuItem } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { checkPredicate } from '../../../../../model/script-predicate'
import { ITableScreenItem } from '../../../../../model/table-screen-item'
import { ModalContext } from '../../../../../providers/modal'
import { isNonNullable } from '../../../../../utils/isNonNullable'
import { getPropertyAny } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { ITableRecord } from './editorUtils'
import { useGotoSubProcess } from './useGotoSubProcess'

interface RowMenuProps {
  children?: never
  item: ITableScreenItem
  row: ITableRecord
  onDelete: VoidFunction
}

export const RowMenu: React.FC<RowMenuProps> = (props) => {
  useEffect(() => {
    console.log('RowMenu mount', props)
  }, [])
  const { row, item, onDelete } = props

  const propContext = useScriptTaskContext()
  const modalContext = useContext(ModalContext)
  const gotoSubProcess = useGotoSubProcess(item)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>()
  const open = !!anchorEl

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(undefined)
  }

  const recordCodePropertyKeyName = item.recordKeyPropertyName ?? 'checkNumber'
  const recordCode = getPropertyAny<string>(row, recordCodePropertyKeyName)

  const menuItems =
    item.rowSubprocesses
      ?.map((settings) => {
        if (
          checkPredicate(
            settings.availabilityCondition ?? {
              $type: 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate',
              value: true,
            },
            row,
            propContext,
          )
        ) {
          return (
            <MenuItem
              key={recordCode}
              onClick={(ev) => {
                ev.stopPropagation()

                function gotoProcess(): void {
                  gotoSubProcess(settings.process.code, recordCode)
                }

                if (settings.processStartConfirmationText) {
                  modalContext.open(
                    <ConfirmationModal onSubmit={gotoProcess} onCancel={() => setAnchorEl(undefined)}>
                      {settings.processStartConfirmationText}
                    </ConfirmationModal>,
                  )
                } else {
                  gotoProcess()
                }
              }}
            >
              {/* {formatTemplateString(
                settings.displayNameFormat ?? '',
                (prop) => getPropertyAny<string>(row, prop) ?? getContextProperty(propContext, prop, ''),
              )} */}
              {settings.menuActionName}
            </MenuItem>
          )
        } else {
          return undefined
        }
      })
      .filter(isNonNullable) ?? []

  if (
    checkPredicate(
      item.deleteRecordEnabled ?? {
        $type: 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate',
        value: false,
      },
      row,
      propContext,
    )
  ) {
    menuItems.push(
      <MenuItem
        onClick={(ev) => {
          ev.stopPropagation()
          modalContext.open(
            <ConfirmationModal
              onSubmit={() => {
                onDelete()
                setAnchorEl(undefined)
              }}
              onCancel={() => setAnchorEl(undefined)}
            >
              {item.deleteRecordConfirmationText ?? 'Вы уверены, что хотите удалить запись?'}
            </ConfirmationModal>,
          )
        }}
      >
        {item.deleteRecordButtonDisplayName ?? 'Удалить'}
      </MenuItem>,
    )
  }

  if (!menuItems.length) {
    return <></>
  }

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: 'left',
        }}
      >
        {menuItems}
      </Menu>
      <IconButton style={{ marginTop: -4, marginBottom: -4, padding: 6 }} onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
    </>
  )
}

interface ConfirmationProps {
  onSubmit: VoidFunction
  onCancel: VoidFunction
}
const ConfirmationModal: React.FC<ConfirmationProps> = ({ onSubmit, onCancel, children }) => {
  const modalContext = useContext(ModalContext)
  return (
    <Dialog
      open
      onClose={() => {
        onCancel()
        modalContext.close()
      }}
    >
      <DialogTitle id='alert-dialog-title'>{children}</DialogTitle>
      <DialogActions>
        <Button
          autoFocus
          color='primary'
          onClick={() => {
            onCancel()
            modalContext.close()
          }}
        >
          Нет
        </Button>
        <Button
          color='primary'
          onClick={() => {
            onSubmit()
            modalContext.close()
          }}
        >
          Да
        </Button>
      </DialogActions>
    </Dialog>
  )
}
