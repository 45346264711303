import React, { useCallback } from 'react'

import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom'

import { getAppFrameStyle } from '../../../../assets/pm-shared-styles'
import { PageContent } from '../../../../layout/page-content'
import { TitleBar } from '../../../../layout/title-bar'
import SMResultPage from './sm-result-page'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appFrame: { ...getAppFrameStyle(theme), height: '100%' },
    root: {
      height: '100%',
    },
  }),
)

const SMReportPage: React.FC = () => {
  const { t } = useTranslation('reports')
  const classes = useStyles()
  const navigate = useNavigate()

  const onBackHandler = useCallback((): void => navigate(-1), [])

  return (
    <div className={classes.appFrame}>
      <TitleBar onBack={onBackHandler}>{t('sm')}</TitleBar>
      <PageContent>
        <div className={classes.root}>
          <SMResultPage />
        </div>
      </PageContent>
    </div>
  )
}

export default SMReportPage
