import React, { useContext } from 'react'

import { Box, createStyles, IconButton, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { useTranslation } from 'react-i18next'
import { useAsync } from 'react-use'

import { LogManager } from '../../../../infrastructure/logger'
import { grays } from '../../../../layout/theme'
import { SETaskRepresentativeScreenItem } from '../../../../model/screen-item'
import { ITaskTemplate } from '../../../../model/task-template'
import { ApiContext } from '../../../../providers'
import { IVisitTaskSummary3 } from '../../../../services/task-service-api'
import { dateFormat } from '../../../../utils'
import { useIsSmall } from '../../../_common/hooks/useIsSmall'
import { getTaskProperty } from '../../../tasks/script-tasks/propertyName'
import { ScriptTaskContextProvider } from '../../../tasks/script-tasks/script-task-context'
import { CompositeResultScreen } from '../../../tasks/template-tasks/composite-screen/composite-result-screen'
import { SimpleSurveyTaskResult } from '../../../tasks/template-tasks/simple-survey/simple-survey-task-result'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(0),
    },
    content: {
      marginTop: theme.spacing(1),
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2, 0.5),
      },
      overflow: 'overlay',
      minHeight: theme.spacing(11),
    },
    backButton: {
      width: 56,
      // marginRight: 7,
      paddingRight: 15,
    },
    dateTitle: {
      textTransform: 'uppercase',
    },
  }),
)

const SEV3_VERSION = 'ServiceSE_v3'

const logger = LogManager.getLogger('SEReportItemView')

interface IPreviousTaskViewProps {
  item: IVisitTaskSummary3
  onBack: () => void
  representativeItem: SETaskRepresentativeScreenItem | undefined
}

const SEReportItemView: React.FC<IPreviousTaskViewProps> = ({ item, onBack, representativeItem }) => {
  const classes = useStyles()
  const api = useContext(ApiContext)
  const { t } = useTranslation('sales-expert-tasks')
  const { task, date, pos } = item

  const taskTemplateListOps = useAsync(async () => {
    try {
      return await api.tasks.getTaskTemplates()
    } catch (error) {
      const message = t('taskTypesFetchError')
      logger.error('getVisitTaskTypes', message, error)
      throw new Error(message)
    }
  }, [])

  const formattedDate = dateFormat(date, 'dd MMM')

  if (taskTemplateListOps.loading && !taskTemplateListOps.value) return <></>

  const templateByVersion =
    task && taskTemplateListOps.value?.find((template) => template.version.code === task!.template.version!.code)
  const templateByCode = task && taskTemplateListOps.value?.find((template) => template.code === task!.template.code)

  const renderResultScreen = (template: ITaskTemplate): JSX.Element => {
    // console.log('renderResultScreen', taskTemplateListOps, task, template)
    if (!template.resultScreen) {
      return <h1>В шаблоне задачи не настроен экран отображения результатов выполнения задачи</h1>
    }
    switch (template.resultScreen.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.SimpleSurveyTaskResultScreen':
        return <SimpleSurveyTaskResult questionnaireCode={template.resultScreen.questionnaire.code} />
      case 'PMI.FACE.BDDM.Extensions.Classes.CompositeTaskResultScreen':
        return <CompositeResultScreen screen={template.resultScreen} />
      default:
        return <h1>Неверный тип: {template.resultScreen.$type}</h1>
    }
  }

  return (
    <div className={classes.root}>
      <Box className={classes.wrapper}>
        <IconButton className={classes.backButton} color='primary' onClick={onBack}>
          <ArrowBackIosIcon viewBox='0 0 12 24' />
        </IconButton>
        <Box flexGrow={1}>
          <Typography noWrap variant='h6' className={classes.dateTitle}>
            {formattedDate}
          </Typography>
        </Box>
        <Divider />
        <Box flexGrow={1}>
          <Box textAlign='left' display='inline-block'>
            <Typography variant='body1'>{pos.name}</Typography>
            <Typography variant='body2' color='textSecondary'>
              {pos.code}
            </Typography>
          </Box>
        </Box>

        {SEV3_VERSION === task.template.version?.code && representativeItem && (
          <>
            <Divider />
            <Box flexGrow={1}>
              <Typography variant='body1'>
                {getTaskProperty(task, representativeItem.representativeNamePropertyName, '')}
              </Typography>
            </Box>
          </>
        )}
      </Box>
      <ScriptTaskContextProvider taskCode={task.code} visitCode={task.visitCode}>
        {renderResultScreen(templateByVersion ?? templateByCode!)}
      </ScriptTaskContextProvider>
    </div>
  )
}

export default SEReportItemView

const Divider: React.FC = () => {
  const isSmall = useIsSmall()
  return <Box height={28} marginX={isSmall ? 1 : 3} borderLeft={`2px solid ${grays.gray4}`}></Box>
}
