import React from 'react'

import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import { grays } from '../../../../layout/theme'
import { VisitStatus } from '../../../../model/visit'
import { getRegisteredNameFromPos } from '../../../stores/store-menu-page/utils'
import { getVisitSourceString } from "../../utils";
import VisitMenu from '../../visit-view-page/visit-menu'
import { UseReasonsCancelled } from '../../visits-list-item/use-reasons-cancelled'
import { VisitWithPosIndexed } from '../visits-map-by-day'

interface IStylesProps {
  status: VisitStatus
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) => {
  const ColorsStatus = {
    Planned: theme.palette.info.main,
    InProgress: theme.palette.warning.main,
    Finished: theme.palette.success.main,
  }
  return createStyles({
    item: {
      width: 270,
      position: 'relative',
      padding: '16px 10px 16px 28px',
      boxShadow: '0px 3px 7px rgba(96, 97, 112, 0.13), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3))',
    },
    itemSelect: {
      boxShadow: ({ status }) =>
        `0px 0px 0px 2px ${
          ColorsStatus[status as keyof typeof ColorsStatus]
        } inset, 0px 3px 7px rgba(96, 97, 112, 0.13), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)`,
    },
    title: {
      width: 'calc(100% - 30px) !important',
      margin: 0,
      marginBottom: '4px',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
    },
    address: {
      margin: 0,
      marginBottom: '8px',
      minHeight: '40px',
      fontSize: '14px',
      lineHeight: '20px',
    },
    code: {
      width: 100,
      margin: 0,
      marginBottom: '1px',
      fontSize: '14px',
      lineHeight: '24px',
      color: grays.gray3,
      letterSpacing: '0.25px',
    },
    lineStatus: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      width: '8px',
      height: '100%',
      backgroundColor: ({ status }) => ColorsStatus[status as keyof typeof ColorsStatus],
      borderRadius: '8px 0 0 8px',
    },
    textStatus: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 2px 0 18px',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 500,
      color: grays.gray2,
      letterSpacing: '0.1px',
      borderRadius: '12px 0px 0px 12px',
      textTransform: 'capitalize',
      '&::before': {
        content: '""',
        display: 'inline-block',
        marginRight: 10,
        width: 10,
        height: 10,
        borderRadius: '50%',
        backgroundColor: ({ status }) => ColorsStatus[status as keyof typeof ColorsStatus],
      },
    },
    index: {
      position: 'absolute',
      top: '-6px',
      right: '-6px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '24px',
      height: '24px',
      border: '2px solid #ffffff',
      borderRadius: '50%',
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: 500,
      color: '#ffffff',
      backgroundColor: ({ status }) => ColorsStatus[status as keyof typeof ColorsStatus],
    },
    wrapCodeStatus: {
      display: 'flex',
    },
    visitMenu: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    source: {
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.1px',
    },
  })
})

interface IMapVisitMobileCardProps {
  visit: VisitWithPosIndexed
  retryVisits?: () => void
}

export const MapVisitMobileCard: React.FC<IMapVisitMobileCardProps> = ({ visit, retryVisits }) => {
  const classes = useStyles({ status: visit.status })
  const { t } = useTranslation('visits')

  const registeredName = visit.pointOfSale && getRegisteredNameFromPos(visit.pointOfSale)
  const reasonsCancelledItems = UseReasonsCancelled(visit)

  return (
    <div className={classnames(classes.item, classes.itemSelect)}>
      <span className={classes.lineStatus} />
      <h3 className={classes.title}>{visit.pointOfSale?.name}</h3>
      <p className={classes.address}>
        {visit.pointOfSale?.address?.fullAddress}
        <br />
        {visit.pointOfSale?.address?.city}
      </p>
      <div className={classes.wrapCodeStatus}>
        <Typography noWrap className={classes.code}>
          {visit.pointOfSale?.code}
          {registeredName ? `\u00A0|\u00A0${registeredName}` : ''}
        </Typography>
        <span className={classes.textStatus}>{t(visit.status)}</span>
      </div>
      <Typography noWrap className={classes.source}>
        {getVisitSourceString(visit.source)}
      </Typography>
      {/*<span className={classes.index}>{visit.index}</span>*/}
      <div className={classes.visitMenu} onClick={(evt) => evt.stopPropagation()}>
        <VisitMenu visit={visit} reasonsCancelledItems={reasonsCancelledItems} retry={retryVisits} />
      </div>
    </div>
  )
}
