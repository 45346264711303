import { IProblem } from "../../../../model/problem"
import { IProblemStatusProcessRule } from "../../../../model/problem-template"

export const getRequiredFields = (obj: IProblemStatusProcessRule): string[] => {
  const res = [] as string[]
  Object.keys(obj).forEach((key) => {
    if (key.includes('Required') && obj[key as keyof typeof obj]) {
      res.push(key)
    }
  })
  return res
}

export const getOptionalFields = (obj: IProblemStatusProcessRule): string[] => {
  const res = [] as string[]
  Object.keys(obj).forEach((key) => {
    if (key.includes('Available') && obj[key as keyof typeof obj]) {
      res.push(key)
    }
  })
  return res
}

export const getProblems60DaysPeriod = (problems: IProblem[]): IProblem[] => {
  const today = new Date()
  const priorDate = new Date().setDate(today.getDate() - 60)
  return problems.filter((item) => {
    if (item.status === 'New') {
      return true
    } 
    return item.creationTime && item.creationTime >= priorDate
  })
}