import React, { Suspense, useContext, useEffect, useRef } from 'react'

import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query'
import { uniq, set, cloneDeep } from 'lodash'
import { useMountedState } from 'react-use'

import { Code, CodeSpace, createReferenceToEntity, getEntityKey } from '../../model/base'
import { IPOSReference } from '../../model/pos'
import { ITask } from '../../model/task'
import { ITaskExecutionScope } from '../../model/task-execution'
import { ITaskTemplate } from '../../model/task-template'
import { IPosVisitReference } from '../../model/visit'
import { isInVisitTask } from '../../model/visit-task'
import { ApiContext, ConfigContext, ProfileContext } from '../../providers'
import { IApiContext } from '../../providers/api/api-context'
import { useDefaultCodeSpace } from '../../providers/config/useDefaultCodeSpace'
import { TaskScheduler } from '../../utils'
import { useBusinessSettings } from '../_common/hooks/useBusinessSettings'
import { useWebUrl } from '../_common/hooks/useWebUrl'
import { useExecutionState } from '../tasks/nested/execution-state'
import {
  fetchAllTaskSurveys,
  findAssignableQuestionnaires,
  ILocalContext,
  LocalContextServiceContext,
  useLocalContextService,
} from '../tasks/nested/local-context'
import { tryFindTaskTemplate } from '../tasks/nested/tryFindTaskTemplate'
import { handlePropertyName } from '../tasks/script-tasks/propertyName'
import { IScriptTaskContext, ScriptTaskContext } from '../tasks/script-tasks/script-task-context'
import { ApiProcessContextServiceBase, IProcessContextService } from './process-context-service'

export const contextQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'always',
      suspense: true,
      staleTime: 60_000,
      refetchOnMount: 'always',
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
})

class QueryApiProcessContextService extends ApiProcessContextServiceBase {
  scheduledRefetch: ReturnType<typeof setTimeout> | undefined
  // asyncChanges: Array<() => Promise<void>> = []
  readonly writeScheduler = new TaskScheduler('ProcessContextWriteScheduler', 1)
  private nextId = 0
  constructor(
    readonly taskCode: Code,
    readonly api: IApiContext,
    //
    private readonly queryClient: QueryClient,
    protected readonly defaultCodeSpace: CodeSpace,
  ) {
    const refetch = async (changes?: Array<[string, unknown]>): Promise<IScriptTaskContext> => {
      if (changes) {
        const taskChanges = changes.filter(([path]) => path?.startsWith('task'))
        const scopeChanges = changes.filter(([path]) => path?.startsWith('fullScope'))
        this.queryClient.setQueryData<ITask>(['tasks', taskCode], (oldTask) => {
          if (!oldTask || !taskChanges.length) return
          const newTask = cloneDeep(oldTask)
          for (const [path, value] of taskChanges) {
            const properPath = handlePropertyName(path.replace(/^task./, ''))
            set(newTask, properPath, value)
          }
          return newTask as ITask
        })
        this.queryClient.setQueryData<ITaskExecutionScope>(['fullScope', taskCode], (oldScope) => {
          if (!oldScope || !scopeChanges.length) return
          const newScope = cloneDeep(oldScope)
          for (const [path, value] of scopeChanges) {
            const properPath = handlePropertyName(path.replace(/^fullScope./, ''))
            set(newScope, properPath, value)
          }
          return newScope as ITaskExecutionScope
        })
        if (!this.scheduledRefetch) {
          console.log('scheduling refetch')
          this.scheduledRefetch = setTimeout(async () => {
            console.log('scheduled refetch')
            await this.refetch()
            this.scheduledRefetch = undefined
          }, 10_000)
        }
      } else {
        await Promise.all(
          [
            ['tasks', taskCode],
            ['taskState', taskCode],
            ['fullScope', taskCode],
          ].map(async (queryKey) =>
            this.queryClient.refetchQueries({
              queryKey,
              type: 'active',
              exact: true,
            }),
          ),
        )
        await this.queryClient.refetchQueries(['tasks', taskCode, 'surveys'])
        await this.queryClient.refetchQueries(['tasks', taskCode, 'questionnaires'])
      }
      return this.makeContextFromQueryClient()
    }
    super(taskCode, api, refetch, defaultCodeSpace)
  }

  onLoad = async (): Promise<void> => {
    return this.writeScheduler.whenIdle()
  }

  private readonly makeContextFromQueryClient = (): IScriptTaskContext => {
    const surveys = this.queryClient.getQueryData(['tasks', this.taskCode, 'surveys'])
    // console.log('surveys after refetch', surveys)
    return {
      surveys,
    } as IScriptTaskContext
  }

  updateProperty = async (propertyName: string, value: unknown): Promise<void> => {
    const action = async (): Promise<void> => super.updateProperty(propertyName, value)
    const id = `updateProperty-${propertyName}-${this.nextId++}`
    this.writeScheduler.enqueueTasks([{ id, action }])
  }

  saveScope = async (scope: ITaskExecutionScope): Promise<void> => {
    const action = async (): Promise<void> => super.saveScope(scope)
    const id = `saveScope-${this.taskCode}-${this.nextId++}`
    this.writeScheduler.enqueueTasks([{ id, action }])
  }

  saveTask = async (task: ITask): Promise<void> => {
    const action = async (): Promise<void> => super.saveTask(task)
    const id = `saveTask-${this.taskCode}-${this.nextId++}`
    this.writeScheduler.enqueueTasks([{ id, action }])
  }

  cancelRefetch = (): void => {
    clearTimeout(this.scheduledRefetch)
    this.scheduledRefetch = undefined
  }

  updateStackSteps = async (): Promise<void> => {
    // TODO: update upwards only
    const localTaskState = useExecutionState.getState().taskState
    await this.api.tasks.saveTaskExecutionState(localTaskState)
    await this.queryClient.refetchQueries(['taskState', this.taskCode])
  }

  dispose = (): void => {
    this.writeScheduler.dispose()
  }
}

const Inner: React.FC<ProcessContextProps> = (props) => {
  const { taskCode, children } = props

  const api = useContext(ApiContext)
  const setTaskStateStore = useExecutionState((store) => store.init)
  const isMounted = useMountedState()
  const contextService = useLocalContextService()
  const defaultCodeSpace = useDefaultCodeSpace()

  useEffect(() => {
    if (!api.tasks) return
    void (async () => {
      const taskState = (await api.tasks.getTaskExecutionState(taskCode)) ?? {
        taskCode,
        cases: {},
        currentStep: 0,
        subProcesses: {},
      }
      if (isMounted()) {
        setTaskStateStore(taskState)
      }
    })()
    return () => setTaskStateStore(undefined!)
  }, [taskCode, api.tasks])

  const { data: task } = useQuery({
    queryKey: ['tasks', taskCode],
    queryFn: async () => {
      console.log('fetching task', taskCode)
      return api.tasks.getTask(taskCode)
    },
  })

  useEffect(() => {
    console.log('task updated in ProcessContext', task)
  }, [task])

  const { data: taskState } = useQuery({
    queryKey: ['taskState', taskCode],
    queryFn: async () => {
      const savedTaskState = await api.tasks.getTaskExecutionState(taskCode)
      return (
        savedTaskState ?? {
          taskCode,
          cases: {},
          currentStep: 0,
          subProcesses: {},
        }
      )
    },
  })

  const { data: fullScope } = useQuery({
    queryKey: ['fullScope', taskCode],
    queryFn: async () => {
      const savedTaskScope = await api.tasks.getTaskExecutionScope(taskCode)
      console.log('fetching scope', savedTaskScope?.task)
      return (
        savedTaskScope ?? {
          task: {},
        }
      )
    },
  })

  const profileContext = useContext(ProfileContext)

  const templateKey = task?.template && getEntityKey(task.template)
  const { data: template } = useQuery({
    queryKey: ['template', templateKey],
    queryFn: async () => tryFindTaskTemplate(api, profileContext!.value!.profile, task!),
    enabled: !!task?.template && !!profileContext?.value?.profile,
  })

  const { data: surveys } = useQuery({
    queryKey: ['tasks', taskCode, 'surveys'],
    queryFn: async () => {
      const task = contextQueryClient.getQueryData<ITask>(['tasks', taskCode])
      const templateKey = task?.template && getEntityKey(task.template)
      const template = contextQueryClient.getQueryData<ITaskTemplate>(['template', templateKey])
      console.log('refetching surveys', task, template)
      return fetchAllTaskSurveys(task!, template!, api)
    },
    enabled: !!(task && template),
  })

  const { data: questionnaires } = useQuery({
    queryKey: ['tasks', taskCode, 'questionnaires'],
    queryFn: async () => {
      const surveys = contextQueryClient.getQueryData<IScriptTaskContext['surveys']>(['tasks', taskCode, 'surveys'])
      return api.questionnaire.getQuestionnaires(
        uniq(
          Object.values(surveys!)
            .flat()
            .map((survey) => survey.questionnaire.code),
        ),
      )
    },
    enabled: !!surveys,
  })

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const visitCode = props.visitCode || (task && isInVisitTask(task) && task.visitCode) || undefined
  const { data: visit } = useQuery({
    queryKey: ['visits', visitCode],
    queryFn: async () => {
      const visit = await api.visits.getVisit(visitCode!)
      if (visit) {
        ;(contextService as QueryApiProcessContextService).visitRef = createReferenceToEntity<IPosVisitReference>(
          visit,
          defaultCodeSpace,
          'PMI.BDDM.Transactionaldata.FieldForcePOSVisitReference',
        )
        return visit
      }
    },
    enabled: !!visitCode,
  })

  if (visit) {
    ;(contextService as QueryApiProcessContextService).visitRef = createReferenceToEntity<IPosVisitReference>(
      visit,
      defaultCodeSpace,
      'PMI.BDDM.Transactionaldata.FieldForcePOSVisitReference',
    )
  }

  const posCode = visit?.pointOfSaleCode
  const { data: pos } = useQuery({
    queryKey: ['pos', posCode],
    queryFn: async () => {
      const date = new Date()
      const pos = await api.pos.getPos(posCode!)
      if (pos) {
        pos.checkOutCount = await api.pos.getCheckoutCount(posCode!, date)
        pos.posModelStoreLevel = await api.pos.getModelStoreLevel(posCode!, date)
      }
      return pos
    },
    enabled: !!posCode,
  })

  if (pos) {
    ;(contextService as QueryApiProcessContextService).posRef = createReferenceToEntity<IPOSReference>(
      pos,
      defaultCodeSpace,
      'PMI.BDDM.Staticdata.POSReference',
    )
  }

  const { data: questionnairePosAssignments } = useQuery({
    queryKey: ['pos', posCode, 'questionnairePosAssignments'],
    queryFn: async () => {
      const assignableQuestionnaires = findAssignableQuestionnaires(template!)
      const res = await Promise.all(
        assignableQuestionnaires.map(async (q) => api.questionnaire.getQuestionnairePOSAssignments(q.code, posCode!)),
      )
      return res.flat()
    },
    enabled: !!posCode && !!template,
  })

  const { config } = useContext(ConfigContext)
  const webUrl = useWebUrl()
  const businessParameters = useBusinessSettings()

  if (!template) return <>no template</>

  const context: ILocalContext = {
    apiUrl: config.apiUrl,
    employee: profileContext.value?.employee,
    profileCode: profileContext.value?.profile?.code,
    fieldPositionRole: profileContext.value?.fieldPositionRole,
    participantProfile: profileContext.value.participantProfile,
    fullScope: fullScope!,
    questionnaires: questionnaires!,
    scope: fullScope?.task,
    surveys: surveys!,
    task: task!,
    taskState: taskState!,
    template: template,
    webUrl,
    pos: pos ?? undefined,
    questionnairePosAssignments,
    visit: visit ?? undefined,
    businessParameters,
    readOnly: task?.status === 'Finished',
  }
  console.log('updated context')
  return <ScriptTaskContext.Provider value={context}>{children}</ScriptTaskContext.Provider>
}

interface ProcessContextProps {
  // children?: never
  taskCode: Code
  visitCode?: Code
}

export const ProcessContextProvider: React.FC<ProcessContextProps> = (props) => {
  useEffect(() => {
    console.log('ProcessContext mount', props)
  }, [])

  const { taskCode } = props
  const serviceRef = useRef<IProcessContextService>()
  const api = useContext(ApiContext)
  const config = useContext(ConfigContext)

  useEffect(() => {
    return () => (serviceRef.current as QueryApiProcessContextService)?.dispose()
  }, [])

  if (!serviceRef.current) {
    serviceRef.current = new QueryApiProcessContextService(
      taskCode,
      api,
      contextQueryClient,
      config.config.defaultCodeSpace,
    )
  }

  return (
    <QueryClientProvider client={contextQueryClient}>
      <LocalContextServiceContext.Provider value={serviceRef.current}>
        <Suspense fallback={<></>}>
          <Inner {...props} />
        </Suspense>
      </LocalContextServiceContext.Provider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  )
}
