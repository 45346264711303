import React, { useEffect } from 'react'

import { Box, Button } from '@material-ui/core'

interface ScreenOrientationProps {
  children?: never
}

export const ScreenOrientation: React.FC<ScreenOrientationProps> = (props) => {
  useEffect(() => {
    console.log('ScreenOrientation mount', props)
  }, [])

  return (
    <Box width='100%' p={6} display='grid' justifyContent='center' style={{ gap: '16px' }}>
      <Button
        variant='contained'
        color='primary'
        onClick={() => {
          void screen?.orientation?.lock('portrait').catch((e) => console.error(e))
        }}
      >
        portrait
      </Button>
      <Button
        variant='contained'
        color='primary'
        onClick={() => {
          void screen?.orientation?.lock('landscape').catch((e) => console.error(e))
        }}
      >
        landscape
      </Button>
      <Button
        variant='contained'
        color='primary'
        onClick={() => {
          void screen?.orientation?.unlock()
        }}
      >
        unlock
      </Button>
    </Box>
  )
}
