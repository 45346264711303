import type { IHttpClient } from '../infrastructure/http-client-factory'
import type { AppPageStatus, IAppPage, IProcessAppFeature } from '../model/app-feature'
import type { EntityKey, IEntity, IEntityReference, Code } from '../model/base'
import type { IExecutionProcessSource } from '../model/execution-process-source'
import type { MenuItemAdmin } from '../model/menu-item'
import { cleanObjectOfEmptyStrings } from '../utils'

export type IProcessAppFeatureDTO = Pick<IProcessAppFeature, 'name' | 'routePath'>

export interface IAppPagePostDTO extends Pick<IAppPage, '$type' | 'displayName' | 'routePath'> {
  status: AppPageStatus | ''
}

export interface IProcessAppFeatureCreateDTO extends IProcessAppFeatureDTO {
  mainPage: Pick<IAppPage, 'displayName' | 'routePath' | 'status'>
}

type IAppPageImpEx = [IAppPage, ...IEntity[]]

export class AppFeatureService {
  constructor(private readonly _http: IHttpClient) {}

  async getAppFeaturesList(): Promise<MenuItemAdmin[]> {
    const res = await this._http.request({
      method: 'GET',
      url: 'app-features/menu',
    })

    return res.data
  }

  async getAppFeature(key: EntityKey): Promise<MenuItemAdmin> {
    const res = await this._http.request({
      method: 'GET',
      url: `app-features/menu/${key}`,
    })

    return res.data
  }

  async createProcessAppFeature(data: IProcessAppFeatureCreateDTO): Promise<IProcessAppFeature> {
    const res = await this._http.request({
      method: 'POST',
      url: `app-features/create-process-feature`,
      data,
    })

    return res.data
  }

  async updateProcessAppFeature(featureKey: EntityKey, data: IProcessAppFeatureDTO): Promise<IProcessAppFeature> {
    const res = await this._http.request({
      method: 'POST',
      url: `app-features/${featureKey}/update-process-feature`,
      data,
    })

    return res.data
  }

  async deleteProcessAppFeature(featureCode: Code): Promise<void> {
    const res = await this._http.request({
      method: 'DELETE',
      url: `app-features/${featureCode}`,
    })

    return res.data
  }

  async getAppFeaturePage(appFeatureKey: EntityKey, pageKeys: EntityKey[]): Promise<IAppPage[]> {
    const pagesQuery = pageKeys.map((key) => `pageKeys=${key}`).join('&')
    const res = await this._http.request({
      method: 'GET',
      url: `app-features/${appFeatureKey}/pages/batch?${pagesQuery}`,
    })

    return res.data
  }

  async createAppFeatureMainPage(appFeatureKey: EntityKey, data: IAppPagePostDTO): Promise<IAppPage> {
    const res = await this._http.request({
      method: 'POST',
      url: `app-features/${appFeatureKey}/main-page`,
      data: data,
    })

    return res.data
  }

  async updateAppFeatureMainPage(appFeatureKey: EntityKey, data: IAppPagePostDTO): Promise<IAppPage> {
    const res = await this._http.request({
      method: 'PUT',
      url: `app-features/${appFeatureKey}/main-page`,
      data: cleanObjectOfEmptyStrings(data),
    })

    return res.data
  }

  async createAppFeatureSubPage(appFeatureKey: EntityKey, data: IAppPagePostDTO): Promise<IAppPage> {
    const res = await this._http.request({
      method: 'POST',
      url: `app-features/${appFeatureKey}/pages`,
      data: cleanObjectOfEmptyStrings(data),
    })

    return res.data
  }

  async updateAppFeatureSubPage(props: {
    appFeatureKey: EntityKey
    appPageKey: EntityKey
    data: IAppPagePostDTO
  }): Promise<IAppPage> {
    const { appFeatureKey, appPageKey, data } = props
    const res = await this._http.request({
      method: 'PUT',
      url: `app-features/${appFeatureKey}/pages/${appPageKey}`,
      data: cleanObjectOfEmptyStrings(data),
    })

    return res.data
  }

  async deleteAppFeaturePage(props: { appFeatureKey: EntityKey; appPageKey: EntityKey }): Promise<void> {
    const { appFeatureKey, appPageKey } = props
    const res = await this._http.request({
      method: 'DELETE',
      url: `app-features/${appFeatureKey}/pages/${appPageKey}`,
    })

    return res.data
  }

  async createAppPageMainProcess(props: {
    appFeatureKey: EntityKey
    appPageKey: EntityKey
    data: IExecutionProcessSource
  }): Promise<IEntityReference> {
    const { appFeatureKey, appPageKey, data } = props
    const res = await this._http.request({
      method: 'POST',
      url: `app-features/${appFeatureKey}/pages/${appPageKey}/main-process`,
      data: cleanObjectOfEmptyStrings(data),
    })

    return res.data
  }

  async updateAppPageMainProcess(props: {
    appFeatureKey: EntityKey
    appPageKey: EntityKey
    data: IExecutionProcessSource
  }): Promise<IEntityReference> {
    const { appFeatureKey, appPageKey, data } = props
    const res = await this._http.request({
      method: 'PUT',
      url: `app-features/${appFeatureKey}/pages/${appPageKey}/main-process`,
      data: cleanObjectOfEmptyStrings(data),
    })

    return res.data
  }

  async exportAppPage(props: { appFeatureKey: EntityKey; appPageKey: EntityKey }): Promise<IAppPageImpEx> {
    const { appFeatureKey, appPageKey } = props
    const res = await this._http.request({
      method: 'GET',
      url: `app-features/${appFeatureKey}/pages/${appPageKey}/export`,
    })

    return res.data
  }

  async importMainAppPage(props: { appFeatureKey: EntityKey; data: IAppPageImpEx }): Promise<void> {
    const { appFeatureKey, data } = props
    const res = await this._http.request({
      method: 'POST',
      url: `app-features/${appFeatureKey}/main-page/import`,
      data,
    })

    return res.data
  }

  async importSubAppPage(props: {
    appFeatureKey: EntityKey
    appPageKey: EntityKey
    data: IAppPageImpEx
  }): Promise<void> {
    const { appFeatureKey, appPageKey, data } = props
    const res = await this._http.request({
      method: 'POST',
      url: `app-features/${appFeatureKey}/pages/${appPageKey}/import`,
      data,
    })

    return res.data
  }

  async createAppPageSubprocess(props: {
    appFeatureKey: EntityKey
    appPageKey: EntityKey
    data: IExecutionProcessSource
  }): Promise<IEntityReference> {
    const { appFeatureKey, appPageKey, data } = props
    const res = await this._http.request({
      method: 'POST',
      url: `app-features/${appFeatureKey}/pages/${appPageKey}/subprocesses`,
      data: cleanObjectOfEmptyStrings(data),
    })

    return res.data
  }

  async updateAppPageSubprocess(props: {
    appFeatureKey: EntityKey
    appPageKey: EntityKey
    processKey: EntityKey
    data: IExecutionProcessSource
  }): Promise<IEntityReference> {
    const { appFeatureKey, appPageKey, processKey, data } = props
    const res = await this._http.request({
      method: 'PUT',
      url: `app-features/${appFeatureKey}/pages/${appPageKey}/subprocesses/${processKey}`,
      data: cleanObjectOfEmptyStrings(data),
    })

    return res.data
  }

  async deleteAppPageSubprocess(props: {
    appFeatureKey: EntityKey
    appPageKey: EntityKey
    processKey: EntityKey
  }): Promise<IEntityReference> {
    const { appFeatureKey, appPageKey, processKey } = props
    const res = await this._http.request({
      method: 'DELETE',
      url: `app-features/${appFeatureKey}/pages/${appPageKey}/subprocesses/${processKey}`,
    })

    return res.data
  }
}
