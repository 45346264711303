import React, { useContext, useState } from 'react'

import { createStyles, Dialog, makeStyles, Theme } from '@material-ui/core'
import { useAsync, useCounter } from 'react-use'

import { BlobStorageError } from '../../../../../infrastructure/blob-storage/blob-storage-api'
import { ITemplateAttachmentsScreenItem } from '../../../../../model/screen-item'
import { ApiContext } from '../../../../../providers'
import { DocumentsScreenItem } from '../../../../documents/components/documents-screen-items/documents-screen-item'
import { DocumentsScreenItemLabel } from '../../../../documents/components/documents-screen-items/documents-screen-item-label'
import { DEFAULT_STORAGE, ITempFileItem, useFetchFilesByKeys } from '../../../../documents/utils'
import { DocumentViewer } from '../../../../viewers/document-viewer/document-viewer'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // marginTop: 25,
    },
  }),
)

export interface ITemplateAttachmentsScreenItemContainer {
  item: ITemplateAttachmentsScreenItem
}

// TaskTemplateContentControl
const TemplateAttachmentsScreenItemContainer: React.FC<ITemplateAttachmentsScreenItemContainer> = ({ item }) => {
  const classes = useStyles()

  const api = useContext(ApiContext)
  const localContext = useScriptTaskContext()
  const { template, visit } = localContext

  const [downloadError, setDownloadError] = useState<BlobStorageError>()
  const ops = useAsync(async () => {
    const documentsList = await api.document?.getTaskTemplateDocuments({
      taskTemplateVersionCode: template.version.code,
      posCode: visit?.pointOfSaleCode,
    })
    const ids: string[] = []
    if (documentsList) {
      for (const doc of documentsList) {
        if (doc.status === 'Active') {
          ids.push(doc.content.target)
        }
      }
    }
    return ids
  }, [])

  const documentsList = ops.value

  const { value: files = [] } = useFetchFilesByKeys(documentsList)
  const [retryCount, { inc: retryInc }] = useCounter(0)
  const retry = (): void => {
    setDownloadError(undefined)
    retryInc()
  }

  const [openedDocument, setOpenedDocument] = useState<ITempFileItem | null>(null)

  if (!files?.length && item.hideEmpty) {
    return <></>
  }

  return (
    <div className={classes.root}>
      <DocumentsScreenItem
        key={retryCount}
        label={
          <DocumentsScreenItemLabel title={item.displayName} isReadOnly={true} downloadImageError={downloadError} />
        }
        documents={files}
        onClickItem={(item) => {
          setOpenedDocument(item)
        }}
        isReadOnly={true}
        nullValueCaption={item.nullValueCaption ?? 'Список связанных файлов пуст'}
        onDownloadError={setDownloadError}
        storage={DEFAULT_STORAGE}
      />
      {openedDocument && (
        <Dialog open={!!openedDocument} fullScreen>
          <DocumentViewer
            title={item.displayName}
            isReadOnly={true}
            blob={openedDocument.blob}
            idKey={openedDocument.metadata.key}
            onClose={() => {
              retry()
              setOpenedDocument(null)
            }}
            isPrev={files.indexOf(openedDocument) !== 0}
            isNext={files.indexOf(openedDocument) !== files.length - 1}
            onPrevClick={() => {
              setOpenedDocument(files[files.indexOf(openedDocument) - 1])
            }}
            onNextClick={() => {
              setOpenedDocument(files[files.indexOf(openedDocument) + 1])
            }}
          />
        </Dialog>
      )}
    </div>
  )
}

export default TemplateAttachmentsScreenItemContainer
