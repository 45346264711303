import React, { useContext, useEffect, useRef } from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { useVirtualizer } from '@tanstack/react-virtual'
import { useLocation, useNavigate } from 'react-router-dom'

import { IProblem } from '../../../../model/problem'
import { IUserProfile } from '../../../../model/user-profile'
import { ProfileContext } from '../../../../providers'

interface IProps {
  ListItem: React.FC<IItem>
  items: IProblem[]
}

interface LocationState {
  scrollOffset?: number
}

interface IItem {
  problem: IProblem
  currentUser: IUserProfile
  onClick: (problemId: string, posCode: string) => void
}

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    listWrap: {
      display: 'flex',
      height: `100%`,
      overflow: 'auto',
      paddingBottom: 96,
    },
    captionOfEmptyResult: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'rgba(0, 0, 0, 0.38)',
      whiteSpace: 'nowrap',
    },
    progress: {
      margin: '0 auto',
    },
  })
)

const ProblemsListVirtual: React.FC<IProps> = ({ ListItem, items }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const currentUser = useContext(ProfileContext)
  const { state } = useLocation() as { state: LocationState }
  const containerRef = useRef<HTMLDivElement>(null)
  const restoringScroll = useRef(!!state?.scrollOffset)
  const filteredList = items ?? []

  const rowVirtualizer = useVirtualizer({
    count: filteredList.length,
    getScrollElement: () => containerRef.current,
    estimateSize: () => 88,
    overscan: 15,
    initialOffset: state?.scrollOffset,
    enableSmoothScroll: false,
  })
  useEffect(() => {
    if (rowVirtualizer.options.count > 0 && restoringScroll.current) {
      rowVirtualizer.scrollToOffset(state!.scrollOffset!)
      restoringScroll.current = false
    }
  }, [rowVirtualizer.options.count])

  const handleClick = (problemCode: string, posCode: string): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const scrollOffset = (rowVirtualizer as any).scrollOffset
    navigate(`/stores/store/${posCode}/problems/problem/${problemCode}`, {
      state: {
        ...state,
        scrollOffset,
        prevProblemPath: location.pathname,
      },
    })
  }

  return (
    <div id='listWrap' className={classes.listWrap} ref={containerRef}>
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualItem) => {
          const item = filteredList[virtualItem.index]
          return (
            <div
              key={virtualItem.key}
              data-code={item.code}
              data-index={virtualItem.index}
              ref={virtualItem.measureElement}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${virtualItem.start}px)`,
              }}
            >
              {<ListItem 
                problem={item}
                currentUser={currentUser.value!}
                onClick={handleClick}
              />}
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default ProblemsListVirtual