import React, { useEffect, useMemo } from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core'

import { ValidationFab } from '../../../components/validation-fab'
import { IProcessStep, isTabStep } from '../../../model/task-execution-process'
import { useInlineActionButton } from '../../dte/useInlineActionButton'
import { DebugTree } from '../debug-tree'
import { useScriptTaskContext } from '../script-tasks/script-task-context'
import { UserStepControl } from '../script-tasks/user-step-control'
import { SwitchStepControl } from '../switch/switch-step-control'
import { ProceedTab, ProceedTabs } from '../template-tasks/proceed-tabs'
import { ScreenRef } from '../template-tasks/types'
import { useLocalContextService } from './local-context'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      marginTop: 60,
      paddingBottom: theme.spacing(12),
      paddingTop: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        // marginTop: 54,
        paddingTop: theme.spacing(2),
      },
    },
    tabRoot: {
      borderBottomColor: theme.palette.primary.main.concat('60'),
    },
    tabWrapper: {
      textAlign: 'center',
      justifyContent: 'center',
    },
    tabSelected: {
      background: 'unset',
      borderBottomColor: theme.palette.primary.main,
      '&:hover': {
        background: 'unset',
      },
    },
    tabsFlexContainer: {
      gap: theme.spacing(1),
    },
  }),
)

interface SubProcessViewProps {
  children?: never
  allSteps: IProcessStep[]
  currentTab: string
  onTabChange: (tab: string) => void
  onAction: VoidFunction
  isReady: boolean
  setIsReady: (isReady: boolean) => void
  currentScreenRef: React.Ref<ScreenRef> | undefined
  onScriptError: (error: Error) => void
  finishActionName: string
}

export const SubProcessViewHorizontal: React.FC<SubProcessViewProps> = (props) => {
  useEffect(() => {
    console.log('SubProcessViewHorizontal mount', props)
  }, [])
  const classes = useStyles()

  const { currentTab, onTabChange, setIsReady, onAction, allSteps, isReady, currentScreenRef, onScriptError } = props
  const { recordState } = useScriptTaskContext()
  const { inMemory } = useLocalContextService()

  const tabs = useMemo(() => allSteps.filter(isTabStep), [allSteps])

  const inlineActionButton = useInlineActionButton()
  const nextButton = <ValidationFab onClick={onAction} isValid={isReady} useInline={inlineActionButton} />

  function renderStep(): JSX.Element {
    const tab = tabs.find((tab) => tab.code === currentTab)
    if (!tab) return <></>
    const active = true
    return (
      <>
        {tab.$type === 'PMI.FACE.BDDM.Extensions.Classes.UserStep' && (
          <UserStepControl
            ref={active ? currentScreenRef : undefined}
            step={tab}
            onReadyChange={setIsReady}
            nextButton={nextButton}
          />
        )}
        {tab.$type === 'PMI.FACE.BDDM.Extensions.Classes.SwitchStep' && (
          <SwitchStepControl
            ref={active ? currentScreenRef : undefined}
            step={tab}
            onReadyChange={setIsReady}
            onScriptError={onScriptError}
            nextButton={nextButton}
          />
        )}
      </>
    )
  }

  return (
    <div className={classes.container}>
      <DebugTree />
      <ProceedTabs
        classes={{ flexContainer: classes.tabsFlexContainer }}
        value={currentTab ?? ''}
        onChange={onTabChange}
      >
        {tabs.map((stage, i) => {
          const isDisabled = inMemory
            ? allSteps.indexOf(stage) > allSteps.findIndex((step) => step.code === currentTab)
            : allSteps.indexOf(stage) > (recordState?.currentStep ?? 0)

          return (
            <ProceedTab
              classes={{
                root: classes.tabRoot,
                wrapper: classes.tabWrapper,
                selected: classes.tabSelected,
              }}
              disabled={isDisabled}
              value={stage.code}
              key={stage.code}
              label={stage.displayName}
            />
          )
        })}
      </ProceedTabs>
      {renderStep()}
    </div>
  )
}
