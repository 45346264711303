import React, { useState } from 'react'

import { Accordion, AccordionDetails, AccordionSummary, createStyles, makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles<Theme, { isCollapsible?: boolean }>((theme) =>
  createStyles({
    content: {
      width: '100%',
    },
    accordionRoot: {
      padding: 0,
      margin: 0,
      backgroundColor: 'transparent',
    },
    summaryRoot: {
      padding: 0,
      userSelect: 'inherit',
    },
    detailsRoot: {
      padding: 0,
    },
    summaryContent: {
      cursor: (props) => (props.isCollapsible ? 'pointer' : 'auto !important'),
    },
  }),
)

interface ICardVariantWrap {
  label?: React.ReactNode
  isCollapsible?: boolean
}

export const CardVariantWrap: React.FC<ICardVariantWrap> = ({ label, isCollapsible, children }) => {
  const classes = useStyles({ isCollapsible })
  const [open, setOpen] = useState(true)

  const switchAccordion = (): void => {
    if (isCollapsible) {
      setOpen((prev) => !prev)
    }
  }

  return (
    <Accordion
      defaultExpanded={false}
      expanded={open}
      className={classes.accordionRoot}
      elevation={0}
      square
      onChange={switchAccordion}
    >
      {!!label && (
        <AccordionSummary
          classes={{ root: classes.summaryRoot, content: classes.summaryContent }}
          aria-controls='table-content'
          id='table-header'
        >
          {label}
        </AccordionSummary>
      )}
      <AccordionDetails className={classes.detailsRoot}>
        <div className={classes.content}>{children}</div>
      </AccordionDetails>
    </Accordion>
  )
}
