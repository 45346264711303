import React, { useEffect, useRef, useState } from 'react'

import {
  Box,
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  Theme,
  Typography,
  // useMediaQuery,
} from '@material-ui/core'
import classnames from 'classnames'
import SignatureCanvas from 'react-signature-canvas'

import { useIsSmall } from '../_common/hooks/useIsSmall'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiDialog-paper': {
        borderRadius: 12,
      },
      [theme.breakpoints.down('xs')]: {
        '& .MuiDialog-paperFullScreen': {
          borderRadius: 0,
        },
      },
    },
    title: {
      '& > h2': {
        fontSize: 24,
        fontWeight: 400,
        letterSpacing: '0.4px',
      },
    },
    wrap: {
      paddingTop: 0,
      paddingBottom: 0,
      overflowY: 'hidden',
    },
    content: {
      overflow: 'hidden',
      maxWidth: '100%',
      width: 524,
      height: 168,
      border: '1px solid rgba(0, 0, 0, 0.08)',
      borderRadius: 8,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: 137,
      },
    },
    button: {
      fontWeight: 500,
      fontSize: 14,
      color: theme.palette.primary.main,
    },
    buttonClear: {
      marginRight: 'auto',
    },
    buttons: {
      padding: '27px 27px',
      paddingTop: 0,
    },
    formControlLabel: {
      fontSize: 14,
    },
  }),
)

type Value = { image: string } | { rejection: boolean }

interface ISignatureProps {
  isOpen: boolean
  onClose: () => void
  onChange: (value: Value) => void
  isRejection?: boolean
}

export const Signature: React.FC<ISignatureProps> = ({ isOpen, onClose, onChange, isRejection }) => {
  const classes = useStyles()
  const isSmall = useIsSmall()
  // const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'))
  const sigRef = useRef<SignatureCanvas>(null)
  const canvasParent = useRef<HTMLDivElement>(null)

  const [isDraw, setIsDraw] = useState(false)

  const getSize = (): { width: number; height: number } => {
    return {
      width: canvasParent.current?.offsetWidth ?? 524,
      height: canvasParent.current?.offsetHeight ?? 168,
    }
  }
  const [size, setSize] = useState(getSize())

  useEffect(() => {
    setSize(getSize())
  }, [canvasParent.current])

  const handleClose = (): void => {
    setIsCheckRejection(!!isRejection)
    onClose()
    setIsDraw(false)
  }

  const handleClear = (): void => {
    if (sigRef.current) {
      sigRef.current.clear()
      setIsDraw(false)
    }
  }

  const handleSubmit = (): void => {
    if (sigRef.current) {
      const image = sigRef.current.toDataURL('image/png', 0.5)
      onChange({ image })
    } else {
      onChange({ rejection: isCheckRejection })
    }

    setIsDraw(false)
    onClose()
  }

  useEffect(() => {
    const resizeListener = (): void => {
      setSize(getSize())
    }
    window.addEventListener('resize', resizeListener)
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  const [isCheckRejection, setIsCheckRejection] = useState(false)

  return (
    <>
      <Dialog
        className={classes.root}
        fullWidth={isSmall}
        // fullScreen={isSmall}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle className={classes.title} id='alert-dialog-title'>
          Подпись
        </DialogTitle>
        <DialogContent className={classes.wrap}>
          <div className={classes.content} ref={canvasParent}>
            {!isCheckRejection ? (
              <SignatureCanvas
                penColor='black'
                canvasProps={{ width: size.width, height: size.height, className: 'sigCanvas' }}
                ref={sigRef}
                onBegin={() => {
                  setIsDraw(true)
                }}
              />
            ) : (
              <Box p={1}>От подписи отказался (лась)</Box>
            )}
          </div>
          {isRejection !== undefined && (
            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  checked={isCheckRejection}
                  onChange={(evt) => {
                    const value = evt.target.checked
                    setIsCheckRejection(value)

                    if (!value) {
                      setIsDraw(false)
                    }
                  }}
                />
              }
              label={<Typography className={classes.formControlLabel}>От подписи отказался (лась)</Typography>}
            />
          )}
        </DialogContent>
        <DialogActions className={classes.buttons}>
          {!isCheckRejection && (
            <Button onClick={handleClear} className={classnames(classes.button, classes.buttonClear)}>
              Очистить
            </Button>
          )}
          <Button onClick={handleClose} className={classes.button}>
            Отменить
          </Button>
          <Button onClick={handleSubmit} disabled={!(isDraw || isCheckRejection)} autoFocus className={classes.button}>
            Готово
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
