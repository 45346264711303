import { grays } from '../../../layout/theme'

export const DocumentIcon = (): JSX.Element => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M9 7L13 7' stroke={grays.gray1} strokeWidth='2' strokeLinecap='round' />
      <path d='M9 15L12 15' stroke={grays.gray1} strokeWidth='2' strokeLinecap='round' />
      <path d='M9 11L15 11' stroke={grays.gray1} strokeWidth='2' strokeLinecap='round' />
      <path
        d='M19 11V9C19 6.17157 19 4.75736 18.1213 3.87868C17.2426 3 15.8284 3 13 3H11C8.17157 3 6.75736 3 5.87868 3.87868C5 4.75736 5 6.17157 5 9V15C5 17.8284 5 19.2426 5.87868 20.1213C6.75736 21 8.17157 21 11 21H12'
        stroke={grays.gray1}
        strokeWidth='2'
      />
      <circle cx='17.5' cy='17.5' r='2.5' stroke={grays.gray1} strokeWidth='2' />
      <path d='M21 21L19.5 19.5' stroke={grays.gray1} strokeWidth='2' strokeLinecap='round' />
    </svg>
  )
}

export const FolderIcon = (): JSX.Element => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4 9C4 7.11438 4 6.17157 4.58579 5.58579C5.17157 5 6.11438 5 8 5H8.34315C9.16065 5 9.5694 5 9.93694 5.15224C10.3045 5.30448 10.5935 5.59351 11.1716 6.17157L11.8284 6.82843C12.4065 7.40649 12.6955 7.69552 13.0631 7.84776C13.4306 8 13.8394 8 14.6569 8H16C17.8856 8 18.8284 8 19.4142 8.58579C20 9.17157 20 10.1144 20 12V15C20 16.8856 20 17.8284 19.4142 18.4142C18.8284 19 17.8856 19 16 19H8C6.11438 19 5.17157 19 4.58579 18.4142C4 17.8284 4 16.8856 4 15V9Z'
        stroke={grays.gray1}
        strokeWidth='2'
      />
    </svg>
  )
}

export const CloseIcon = (): JSX.Element => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M18 6L6 18' stroke='#333333' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 6L18 18' stroke='#333333' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}

export const ExpandIcon = (): JSX.Element => {
  return (
    <svg width='6' height='24' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.76953 4.80794L1.31215 1.09346C0.791086 0.659238 0 1.02976 0 1.70803V8.29197C0 8.97024 0.791085 9.34076 1.31215 8.90654L5.76953 5.19206C5.88947 5.09211 5.88947 4.90789 5.76953 4.80794Z'
        fill='#333333'
      />
    </svg>
  )
}

export const ReduceIcon = (): JSX.Element => {
  return (
    <svg width='10' height='24' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.80794 5.76953L1.09346 1.31215C0.659238 0.791085 1.02976 -4.31825e-07 1.70803 -4.02177e-07L8.29197 -1.14384e-07C8.97024 -8.47357e-08 9.34076 0.791085 8.90654 1.31215L5.19206 5.76953C5.09211 5.88947 4.90789 5.88947 4.80794 5.76953Z'
        fill='#333333'
      />
    </svg>
  )
}

export const SortingAscIcon = (): JSX.Element => {
  return (
    <svg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0 5L5 0L10 5H0Z' fill='#858F97' />
    </svg>
  )
}

export const SortingDescIcon = (): JSX.Element => {
  return (
    <svg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0 0L5 5L10 0H0Z' fill='#858F97' />
    </svg>
  )
}

export const LinkIcon = (): JSX.Element => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M3 9V15C3 17.8284 3 19.2426 3.87868 20.1213C4.75736 21 6.17157 21 9 21H15C17.8284 21 19.2426 21 20.1213 20.1213C21 19.2426 21 17.8284 21 15V9C21 6.17157 21 4.75736 20.1213 3.87868C19.2426 3 17.8284 3 15 3H9'
          stroke='#3077B8'
          strokeWidth='2'
          strokeLinecap='round'
        />
        <path
          d='M15 15V16H16V15H15ZM7.70711 6.29289C7.31658 5.90237 6.68342 5.90237 6.29289 6.29289C5.90237 6.68342 5.90237 7.31658 6.29289 7.70711L7.70711 6.29289ZM14 8V15H16V8H14ZM15 14H8V16H15V14ZM15.7071 14.2929L7.70711 6.29289L6.29289 7.70711L14.2929 15.7071L15.7071 14.2929Z'
          fill='#3077B8'
        />
      </svg>
    </div>
  )
}
