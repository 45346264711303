import { SyncErrorCode } from '../../model/base'
import { SyncError, SyncErrorSource } from '../../services/sync-service-api'

export function getTitle(error: unknown): string {
  if (error instanceof SyncError) {
    switch (error.source) {
      case SyncErrorSource.UploadReport:
        return 'При отправке pdf-файла на сервер произошла ошибка'
      case SyncErrorSource.UploadVisit:
        return 'При отправке визита на сервер произошла ошибка'
      case SyncErrorSource.LoadPos:
        return 'При получении списка торговых точек произошла ошибка'
      case SyncErrorSource.LoadTaskTemplates:
        return 'При получении списка шаблонов задач произошла ошибка'
      case SyncErrorSource.LoadQuestionnaires:
        return 'При получении списка опросников произошла ошибка'
      case SyncErrorSource.LoadVisitsDelta:
        return 'При получении списка изменений визитов произошла ошибка'
      case SyncErrorSource.LoadVisits:
        return 'При получении визитов произошла ошибка'
      case SyncErrorSource.LoadSupervisedVisitsDelta:
        return 'При получении списка изменений контролируемых визитов ошибка'
      case SyncErrorSource.LoadSupervisedVisits:
        return 'При получении контролируемых визитов произошла ошибка'
      case SyncErrorSource.LoadAudits:
        return 'При получении результатов ISA произошла ошибка'
      case SyncErrorSource.LoadSMServices:
        return 'При получении результатов Service SM произошла ошибка'
      case SyncErrorSource.LoadPOSTaskRegisters:
        return 'При получении списка назначений произошла ошибка'
      case SyncErrorSource.LoadPOSTaskRegisterExecutions:
        return 'При получении списка связей назначений и задач произошла ошибка'
      case SyncErrorSource.LoadTaskTemplateContents:
        return 'При получении связей с медиаматериалами произошла ошибка'
      case SyncErrorSource.LoadContentDocuments:
        return 'При получении медиаматериалов произошла ошибка'
      case SyncErrorSource.LoadProductMatrixAssignments:
        return 'При получении привязок к продуктовым матрицам произошла ошибка'
      case SyncErrorSource.LoadProductMatrices:
        return 'При получении продуктовых матриц произошла ошибка'
      case SyncErrorSource.LoadContractTermAssignments:
        return 'При получении привязок к контрактным условиям произошла ошибка'
      case SyncErrorSource.LoadContractTerms:
        return 'При получении контрактных условий произошла ошибка'
      case SyncErrorSource.LoadProblemTemplates:
        return 'При получении списка шаблонов проблем произошла ошибка'
      case SyncErrorSource.LoadProblems:
        return 'При получении списка проблем произошла ошибка'
      case SyncErrorSource.UploadProblem:
        return 'При отправке проблемы на сервер произошла ошибка'
      case SyncErrorSource.UploadTask:
        return 'При отправке задачи на сервер произошла ошибка'
      case SyncErrorSource.LoadTasksDelta:
        return 'При получении задач произошла ошибка'
      case SyncErrorSource.LoadTaskTemplatesDelta:
        return 'При получении списка изменений шаблонов задач произошла ошибка'
      case SyncErrorSource.LoadDictionaries:
        return 'При получении справочников произошла ошибка'
      case SyncErrorSource.LoadHyperlinks:
        return 'При получении гиперссылок произошла ошибка'
      case SyncErrorSource.LoadSupervisedFieldPositionRoles:
        return 'При получении полевых ролей произошла ошибка'
      case SyncErrorSource.LoadFieldPositionRoleUserProfiles:
        return 'При получении профилей пользователей произошла ошибка'
    }
  }
  return 'Ошибка при синхронизации'
}

export function getDescription(error: unknown): string {
  if (error instanceof SyncError) {
    switch (error.code) {
      case SyncErrorCode.Unauthorized:
        return 'Пользователь не аутентифицирован'
      case SyncErrorCode.Forbidden:
        return 'Пользователь не включен в необходимые группы безопасности'
      case SyncErrorCode.BusinessError:
        if (error.businessErrorTitle) return error.businessErrorTitle
        if (error.businessErrorType) return `Произошла бизнес-ошибка с кодом ${error.businessErrorType}`
        return 'Произошла бизнес-ошибка'
      case SyncErrorCode.Maintenance:
        return 'Сервер временно недоступен, ведутся регламентные работы'
      case SyncErrorCode.ServerError:
        return `Во время выполнения метода произошла ошибка (статус ${error.httpStatus})`
      case SyncErrorCode.NetworkError:
        return 'Сервис недоступен'
    }
  }
  return 'Другая ошибка'
}

