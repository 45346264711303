import { EditorProps } from '@monaco-editor/react'

export function getMonacoEditorOptions(options: EditorProps['options']): EditorProps['options'] {
  return {
    minimap: { enabled: false },
    fixedOverflowWidgets: true,
    wordWrap: 'on',
    wrappingIndent: 'deepIndent',
    unicodeHighlight: { allowedLocales: { en: true, ru: true } },
    ...options,
  }
}
