import _ from 'lodash'

import {SMServiceStore, SMServiceStore_endDate, TaskStore} from '../../data/schema'
import { RangeDef } from '../../infrastructure/storage-service'
import { Code } from '../../model/base'
import { IStoreManagerService } from '../../model/sm-service'
import { IRepresentative, IThirdParty } from '../../model/visit-task'
import {
  IEmployeeService,
  IStoreManagerServiceInfo,
  IStoreManagerServiceRequest,
  IThirdPartyRepresentative
} from '../employee-service-api'
import { LocalStorageBaseService } from './local-storage-base-service'

/** @deprecated */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SE2Task = Record<string, any> & {
  representative?: IRepresentative
}

export default class LocalStorageEmployeeService extends LocalStorageBaseService implements IEmployeeService
{
  async getThirdParties(): Promise<IThirdParty[]> {
    const tasks = await this._storage.getAll<SE2Task>(TaskStore)
    return _.uniqBy(
      tasks.filter((vt) => vt.status === 'Finished' && vt.thirdParty?.name).map((vt) => vt.thirdParty!),
      (t) => t.name?.trim()
    )
  }

  async getRepresentatives(): Promise<IThirdPartyRepresentative[]> {
    const tasks = await this._storage.getAll<SE2Task>(TaskStore)
    return _.uniqBy(
      tasks
        .filter(
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          (vt) => vt.status === 'Finished' && (vt.representative?.name || vt.representative?.eMails?.[0]?.address)
        )
        .map((vt) => {
          const r: IThirdPartyRepresentative = {
            name: vt.representative?.name,
            eMails: vt.representative?.eMails?.map((email) => ({
              address: email.address
            })),
            agency: _.get(vt, 'customAgencyName')
          }
          return r
        }),
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      (r) => (r.name?.trim() ?? '') + (r.eMails?.[0]?.address?.trim() ?? '') + (r.agency?.trim() ?? '')
    )
  }

  async searchStoreManagerServices(
    request: IStoreManagerServiceRequest | undefined
  ): Promise<IStoreManagerServiceInfo[]> {
    let range: RangeDef | null = null
    if (request != null) {
      if (request.fromDate && request.toDate) {
        range = ['<=<=', request.fromDate, request.toDate]
      } else if (request.fromDate) {
        range = ['>=', request.fromDate]
      } else if (request.toDate) {
        range = ['<=', request.toDate]
      }
    }
    const result: IStoreManagerServiceInfo[] = []
    for await (const sm of this._storage.selectByIndexRange<IStoreManagerService>(
      SMServiceStore,
      SMServiceStore_endDate,
      range,
      'desc'
    )) {
      result.push({
        code: sm.code,
        endDate: sm.endDate,
        ownerName: sm.ownerName,
        pointOfSaleName: sm.pointOfSaleName
      })
    }
    return result
  }

  async getStoreManagerService(code: Code): Promise<IStoreManagerService | null> {
    return (await this._storage.getByKey(SMServiceStore, code)) ?? null
  }
}
