import React from 'react'

import { ShareButton } from '../../../../../components/share-button'
import { LogManager } from '../../../../../infrastructure/logger'
import { appToast } from '../../../../../utils'

interface SmItemShareProps {
  link: string | undefined
}

const logger = LogManager.getLogger('SmItemShare')

const SmItemShare: React.FC<SmItemShareProps> = ({ link }) => {
  const shareHandler = async (): Promise<void> => {
    if (!link) {
      const message = 'Отсутствует ссылка на отчет'
      logger.error('shareHandler', 'reportLink was not found', null, link)
      appToast.error(message)
      return
    }
    if (!navigator.share) {
      const message = 'Ваша система не поддерживает функцию отправки файлов'
      logger.error('shareHandler', "Your system doesn't support sharing files.")
      appToast.error(message)
      return
    }
    try {
      await navigator.share({
        url: link,
        title: 'Отчет по результатам'
      })
    } catch (e) {
      logger.error('shareHandler', 'Sharing failed', e)
    }
  }

  return (
    <div style={{ marginLeft: 'auto', marginRight: '8px' }}>
      <ShareButton onShare={shareHandler} />
    </div>
  )
}

export default SmItemShare
