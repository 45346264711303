import React, { useEffect, useMemo, useRef, useState } from 'react'

import {
  Box,
  Container,
  createStyles,
  Fade,
  LinearProgress,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { useVirtualizer } from '@tanstack/react-virtual'

import { useContentDocumentsListQuery } from '../../features/admin/dte-task-templates/utils/api'
import { ModalPage } from '../../features/admin/screen-editors/modal-page'
import { PageContent } from '../../layout/page-content'
import { TitleBar } from '../../layout/title-bar'
import { createReferenceByKey, getEntityKey, IEntityReference } from '../../model/base'
import type { MimeContentType } from '../../model/content'
import { useDefaultCodeSpace } from '../../providers/config/useDefaultCodeSpace'
import { EmptyMessage } from '../index'
import { ValidationFab } from '../validation-fab'
import { ContentDocumentViewer } from './content-document-viewer'
import { DocumentThumbnail } from './document-thumbnail'

interface IModalPropsCommon<T> {
  initValue: T | null | undefined
  mimeTypeFilter?: MimeContentType[]
  required?: boolean
  onCancel: () => void
  onSubmit: (value: T | null) => void
}

interface IModalProps<T> extends IModalPropsCommon<T> {
  open: boolean
  /** Default: true */
  fullscreen?: boolean
}

interface IModalPropsInner<T> extends IModalPropsCommon<T> {
  onLoaded: () => void
  containerRef: React.RefObject<HTMLDivElement>
}

export function ContentDocumentSelectorModal<TValue extends IEntityReference>(props: IModalProps<TValue>): JSX.Element {
  const { open, fullscreen, ...rest } = props
  const styles = useStyles({})
  const [isLoading, setIsLoading] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setIsLoading(true)
  }, [open])

  const onInnerLoaded = (): void => {
    if (isLoading) {
      setIsLoading(false)
    }
  }

  return (
    <ModalPage open={open} fullscreen={fullscreen ?? true}>
      <div className={styles.root} ref={containerRef}>
        <TitleBar onBack={props.onCancel}>Выбор документа</TitleBar>

        <PageContent>
          <Fade in={isLoading}>
            <LinearProgress variant='query' />
          </Fade>
          {open && (
            <ContentDocumentSelectorModalInternal {...rest} onLoaded={onInnerLoaded} containerRef={containerRef} />
          )}
        </PageContent>
      </div>
    </ModalPage>
  )
}

function ContentDocumentSelectorModalInternal<TValue extends IEntityReference>(
  props: IModalPropsInner<TValue>,
): JSX.Element {
  const { onLoaded, containerRef } = props
  const [value, setValue] = useState<TValue | null>(props.initValue ?? null)
  const [filter, setFilter] = useState('')
  const [firstRender, setFirstRender] = useState(true)
  const [viewingDocument, setViewingDocument] = useState<typeof documentsListExtended[number] | null>(null)
  const styles = useStyles({})
  const twoCol = useMediaQuery((theme: Theme) => theme.breakpoints.down(theme.spacing(122)))
  const oneCol = useMediaQuery((theme: Theme) => theme.breakpoints.down(theme.spacing(88)))
  const defaultCodeSpace = useDefaultCodeSpace()

  // Dirty hacks for better appearance
  useEffect(() => {
    const timer = setTimeout(() => {
      if (firstRender) {
        setFirstRender(false)
      }
    }, 300)
    return () => {
      clearTimeout(timer)
    }
  }, [firstRender])

  const { data: _documentsList, isLoading: isListLoading } = useContentDocumentsListQuery({
    filterMimeTypes: props.mimeTypeFilter,
  })
  const isLoading = firstRender || isListLoading
  useEffect(() => {
    if (!isLoading) {
      onLoaded()
    }
  }, [isLoading])

  const documentsListExtended = useMemo(
    () =>
      ((firstRender ? undefined : _documentsList) ?? []).map((doc) => ({
        doc,
        entityKey: getEntityKey(doc),
        label: doc.name ?? doc.code,
        entityReference: createReferenceByKey<TValue>(getEntityKey(doc), defaultCodeSpace),
        searchString: [doc.name, doc.code, getEntityKey(doc), doc.description]
          .filter((v) => !!v)
          .join('Λ')
          .toUpperCase(),
      })),
    [_documentsList, firstRender],
  )

  const selectedDocument = useMemo(() => {
    if (!value) {
      return undefined
    }
    const searchKey = getEntityKey(value)
    return documentsListExtended.find((element) => element.entityKey === searchKey) ?? undefined
  }, [value, documentsListExtended])

  const filteredDocumentList = useMemo(
    () =>
      filter
        ? documentsListExtended?.filter((element) => element.searchString.includes(filter.toUpperCase()))
        : documentsListExtended,
    [filter, documentsListExtended],
  )

  const virtualRows = useMemo(() => {
    const virtualColumnsCount = oneCol ? 1 : twoCol ? 2 : 3
    const rows = []
    let current = 0
    while (current < filteredDocumentList.length) {
      const items = filteredDocumentList.slice(current, current + virtualColumnsCount)
      current += virtualColumnsCount
      rows.push({
        key: items.map((e) => e.entityKey).join('_'),
        items,
      })
    }
    return rows
  }, [filteredDocumentList, twoCol, oneCol])

  const rowVirtualizer = useVirtualizer({
    count: virtualRows.length,
    getScrollElement: () => containerRef.current,
    estimateSize: () => 192,
    overscan: 8,
    enableSmoothScroll: false,
  })

  return (
    <>
      <Container maxWidth='md'>
        <Box p={3} pb={20}>
          <Box mb={3}>
            <Paper elevation={0}>
              <Box p={3}>
                <Box mb={2}>
                  <Typography variant='h3'>Выбрано</Typography>
                </Box>
                {value ? (
                  <>
                    <Box mb={1}>
                      <Typography variant='body1'>{selectedDocument?.label ?? value.code}</Typography>
                    </Box>

                    <DocumentThumbnail
                      loading={isLoading}
                      document={selectedDocument?.doc}
                      onClick='openDocumentViewer'
                      showDeleteButton={!props.required}
                      onDelete={() => setValue(null)}
                      showLabel={false}
                    />
                  </>
                ) : (
                  <EmptyMessage marginTop={10} marginBottom={16} message='Документ не выбран' stretch={false} />
                )}
              </Box>
            </Paper>
          </Box>

          <Box mb={3}>
            <Paper elevation={0}>
              <Box p={3}>
                <Box mb={2}>
                  <Typography variant='h3'>Документы</Typography>
                </Box>
                <Box mb={2}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    placeholder='Найти'
                    value={filter}
                    onChange={(event) => setFilter(event.target.value)}
                  />
                </Box>
                {!firstRender && !!virtualRows?.length && (
                  <Box className={styles.virtualListContainer} height={rowVirtualizer.getTotalSize()}>
                    {rowVirtualizer.getVirtualItems().map((virtualItem) => {
                      const virtualRow = virtualRows[virtualItem.index]
                      return (
                        <div
                          key={virtualRow.key}
                          className={styles.virtualRowContainer}
                          style={{
                            transform: `translateY(${virtualItem.start}px)`,
                          }}
                        >
                          <div className={styles.virtualRow}>
                            {virtualRow.items.map((document) => (
                              <DocumentThumbnail
                                key={document.entityKey}
                                document={document.doc}
                                onClick={() => setViewingDocument(document)}
                              />
                            ))}
                          </div>
                        </div>
                      )
                    })}
                  </Box>
                )}
                {!isLoading && virtualRows.length === 0 && (
                  <EmptyMessage marginTop={4} marginBottom={4} message='Документы отсутствуют' minHeightDiff={800} />
                )}
              </Box>
            </Paper>
          </Box>

          <ValidationFab isValid={!props.required || !!value} onClick={() => props.onSubmit(value)}>
            Сохранить
          </ValidationFab>
        </Box>
      </Container>

      <ContentDocumentViewer
        open={!!viewingDocument}
        code={viewingDocument?.doc.code ?? null}
        documentTitle={viewingDocument?.label}
        onClose={() => setViewingDocument(null)}
        elementsCount={filteredDocumentList.length}
        onNext={() => {
          selectSiblingDocument('next')
        }}
        onPrev={() => {
          selectSiblingDocument('prev')
        }}
        // onSelect={() => setValue(viewingDocument?.entityReference ?? null)}
        // One less click
        onSelect={() => props.onSubmit(viewingDocument?.entityReference ?? null)}
      />
    </>
  )

  function selectSiblingDocument(direction: 'next' | 'prev'): void {
    setViewingDocument((currentDocument) => {
      const documentsCount = filteredDocumentList.length
      const currentIndex = filteredDocumentList.findIndex((doc) => doc.entityKey === currentDocument?.entityKey)
      if (currentIndex === -1) {
        return null
      }
      const newIndex = (currentIndex + (direction === 'next' ? 1 : -1) + documentsCount) % documentsCount
      return filteredDocumentList[newIndex]
    })
  }
}

const useStyles = makeStyles(
  (/*theme: Theme*/) =>
    createStyles({
      root: {
        height: '100vh',
        overflowY: 'scroll',
      },
      virtualListContainer: {
        width: '100%',
        position: 'relative',
      },
      virtualRowContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
      },
      virtualRow: {
        display: 'flex',
        justifyContent: 'space-around',
      },
    }),
  { name: 'ContentDocumentSelectorModal' },
)
