export interface ICompositeScreenItemLayout {
  width: number
  horizontalAlignment: JustifyLayoutContent
}

export type JustifyLayoutContent = 'Left' | 'Center' | 'Right' | 'Unrecognized'

export enum JustifyFlexLayoutContent {
  'Left' = 'flex-start',
  'Center' = 'center',
  'Right' = 'flex-end',
  'Unrecognized' = 'center',
}
