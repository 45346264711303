import React from 'react'

import { useAsync } from 'react-use'

import { ErrorPage } from '../../features/error-page'
import { ConfigError, ConfigErrorCode } from '../../infrastructure/config-service'
import ConfigService from '../../infrastructure/config-service/config-service'
import { LogManager } from '../../infrastructure/logger'
import { LockedLoadingScreen } from '../../layout/locked-loading-screen'
import ConfigContext, { IConfigContext } from './config-context'

// const logger = LogManager.getLogger('ConfigProvider')

const ConfigProvider: React.FC = ({ children }) => {
  const config = useAsync(async () => {
    try {
      return await new ConfigService().getAppConfig()
    } catch (error) {
      let message = 'Ошибка при загрузке файла конфигурации'
      if (error instanceof ConfigError) {
        if (error.code === ConfigErrorCode.MaintenanceInProgress) {
          message = 'Ведутся регламентные работы'
        }
      }
      LogManager.flush()
      throw new Error(message)
    }
  })

  if (config.error) {
    return (
      <ErrorPage
        errorMessage={config.error.message}
        onBack={() => document.location.replace(document.location.origin)}
        title=''
      />
    )
  }

  if (!config.value || config.loading) {
    // todo: spinner?
    console.info('waiting for config context')
    return <LockedLoadingScreen>Loading config</LockedLoadingScreen>
  }

  const configContext: IConfigContext = {
    config: config.value,
  }

  return <ConfigContext.Provider value={configContext}>{children}</ConfigContext.Provider>
}
export default ConfigProvider
