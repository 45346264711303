import React from 'react'

import { createStyles, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames, { Argument as ClassName } from 'classnames'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 16,
    },
    title: {
      marginBottom: 18,
      fontSize: 22,
      lineHeight: '28px',
      letterSpacing: '0.15px',
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
        marginBottom: 16,
      },
    },
  }),
)

interface IGroupWrapper {
  title: string
  className?: ClassName
}

export const GroupWrapper: React.FC<IGroupWrapper> = ({ children, className, title }) => {
  const classes = useStyles()
  return (
    <div className={classNames(classes.root, className)}>
      <Typography color='primary' className={classes.title}>
        {title}
      </Typography>
      {children}
    </div>
  )
}
