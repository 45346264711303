import React, { useCallback, useContext, useEffect, useState } from 'react'

import { createStyles, Paper, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { getAppFrameStyle } from '../../../../assets/pm-shared-styles'
import { EmptyMessage } from '../../../../components'
import { PageContent } from '../../../../layout/page-content'
import { TitleBar } from '../../../../layout/title-bar'
import { ApiContext } from '../../../../providers'
import { dateFormat } from '../../../../utils'
import { getPosAddress } from '../../../_common/pos'
import { ErrorPage } from '../../../error-page'
import { IsaAnswerSelectControl, IsaAuditAnswers, IsaPosInfo } from './components'
import { onlyUnique } from './utils/get-isa-answer-type'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appFrame: getAppFrameStyle(theme),
    root: {
      marginBottom: theme.spacing(5),
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      minHeight: theme.spacing(10),
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        margin: theme.spacing(2, 0),
      },
    },
    date: {
      color: '#3870B2',
      fontSize: 34,
      textTransform: 'uppercase',
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(2, 0),
      },
    },
    content: {},
  }),
)

const IsaResultsPage: React.FC = () => {
  const api = useContext(ApiContext)
  const classes = useStyles()
  const navigate = useNavigate()
  const { id: auditCode } = useParams<{ id: string }>()
  const [filterByAnswerType, setFilterByAnswerType] = useState<string | null>(null)
  const auditDataOps = useAsync(async () => await api.audit.getAudit(auditCode!), [auditCode])

  const onBackHandler = useCallback((): void => navigate(-1), [])
  const onAnswerSelectHandler = useCallback((value): void => setFilterByAnswerType(value === 'ALL' ? null : value), [])

  const { i18n, t, ready } = useTranslation('reports')
  useEffect(() => {
    !ready && i18n.reloadResources(i18n.language, 'reports')
  }, [ready])

  if (auditDataOps.error) {
    return <ErrorPage errorMessage={t('auditDataFetchError')} />
  }

  if (!auditDataOps.value) {
    if (!auditDataOps.loading) {
      return <EmptyMessage message={t('auditDataNotFound')} />
    }
    return <></>
  }

  const audit = auditDataOps.value
  const answerTypes = audit.inquiries
    .flatMap((x) => x.answers.map((z) => z.answerText))
    .filter(onlyUnique)
    .sort((a, b) => a.localeCompare(b))
  // const filteredAnswers = audit.inquiries.filter((x) =>
  //   filterByAnswerType ? x.answers.map((a) => a.answerText).includes(filterByAnswerType) : true
  // )
  // console.log(filterByAnswerType, filteredAnswers)
  const auditFormattedDate = dateFormat(audit.startDate, 'cccccc dd.MM.yyyy')
  const auditPos = auditDataOps.value.pointOfSale

  return (
    <div className={classes.appFrame}>
      <TitleBar onBack={onBackHandler}>{t('isaResultsTitle')}</TitleBar>

      <PageContent>
        <div className={classes.root}>
          <IsaPosInfo name={auditPos.name} address={getPosAddress(auditPos)} posCode={auditPos.code} />
          <Paper square elevation={0} className={classes.title}>
            <Typography variant='h4' color='secondary' className={classes.date}>
              {auditFormattedDate}
            </Typography>
            <IsaAnswerSelectControl onSelect={onAnswerSelectHandler} options={answerTypes} />
          </Paper>

          <div className={classes.content}>
            {!audit.inquiries.length ? <EmptyMessage message={t('noAuditData')} /> : null}
            {audit.inquiries.map((group) => (
              <IsaAuditAnswers
                key={group.code}
                groupName={group.name ?? '-'}
                answers={group.answers}
                filterByAnswerType={filterByAnswerType}
              />
            ))}
          </div>
        </div>
      </PageContent>
    </div>
  )
}

export default IsaResultsPage
