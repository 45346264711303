import React from 'react'

import { Avatar, Box, createStyles, Grid, Paper, Theme, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

interface IIsaPosInfoProps {
  name: string
  address: string
  posCode: string
}

const useStyles = makeStyles<Theme, IIsaPosInfoProps>((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      position: 'relative',
      fontSize: 16
    },
    gridContainer: {
      minHeight: 88,
      [theme.breakpoints.up('xs')]: {
        paddingLeft: theme.spacing(1)
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(2)
      }
    },
    code: {
      marginTop: theme.spacing(1),
      color: theme.palette.text.secondary,
      [theme.breakpoints.up('xs')]: {
        paddingLeft: 50
      }
    },
    address: {
      [theme.breakpoints.up('xs')]: {
        paddingLeft: 50,
        maxWidth: 280
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: 500
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: 700
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 800
      }
    }
  })
)

const IsaPosInfo: React.FC<IIsaPosInfoProps> = (props) => {
  const classes = useStyles(props)

  return (
    <Paper elevation={3} square className={classes.root}>
      <Grid container spacing={3} xs={12} className={classes.gridContainer} alignItems='center' justify='space-between'>
        <Grid item md={4} sm={4} xs={12}>
          <Box display='flex' alignItems='center'>
            <Avatar alt={props.name} src='/static/images/avatar/1.jpg' />
            <Box ml={1}>
              <Typography variant='h6' component='h6' noWrap>
                {props.name}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={8} sm={8} xs={12} direction='column'>
          <Tooltip title={props.address}>
            <Typography noWrap className={classes.address}>
              {props.address}
            </Typography>
          </Tooltip>
          <Typography className={classes.code}>{props.posCode}</Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default IsaPosInfo
