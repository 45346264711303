import React, { useCallback } from 'react'

import { createStyles, Grid, Paper, Theme, Typography, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import {
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfISOWeek,
  endOfMonth as _endOfMonth,
  isSameDay,
  isSameMonth,
  isToday,
  startOfISOWeek,
} from 'date-fns'
import addMonths from 'date-fns/addMonths'
import { useTranslation } from 'react-i18next'

import { grays } from '../../../layout/theme'
import { ITask } from '../../../model/task'
import { VisitStatus } from '../../../model/visit'
import { dateFormat } from '../../../utils'
import { VisitWithPos } from '../../_common/hooks/useSearchVisits'
import { sortByStatus } from '../../_common/visits'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    additionalData: {
      padding: theme.spacing(0, 2),
      width: 250,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#BEE3EF',
      '& > p': {
        fontWeight: 300,
        fontSize: 48,
        lineHeight: '40px',
        textAlign: 'center',
        textTransform: 'uppercase',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none !important',
      },
    },
    calendarRoot: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      maxWidth: '100%',
    },
    cell: {
      marginTop: -1,
      marginLeft: -1,
      fontSize: 14,
      lineHeight: '16px',
      letterSpacing: '0.1px',
      border: `1px solid ${grays.gray4}`,
      display: 'flex',
      flex: '0 0 14.5%',
      maxWidth: '14.5%',
      alignItems: 'center',
      minHeight: 104,

      [theme.breakpoints.down('sm')]: {
        minHeight: 82,
        '& $weekDayContent': {
          fontSize: 16,
          alignItems: 'center',
          padding: '9px 6px',
        },
        '& $weekDayNumber': {
          width: 40,
          height: 24,
          fontSize: 16,
          justifyContent: 'center',
        },
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
        lineHeight: '16px',
        minHeight: 55,
        '& $weekDayContent': {
          padding: '6px 1px 0',
          fontSize: 12,
          lineHeight: '12px',
        },
        '& $weekDayNumber': {
          width: 34,
          height: 22,
          fontSize: 14,
          lineHeight: '16px',
        },
      },
    },
    cellSmall: {
      minHeight: 82,
      justifyContent: 'center',
      '& $weekDayContent': {
        padding: '9px 6px',
        alignItems: 'center',
      },
      '& $weekDayNumber': {
        width: 40,
        height: 24,
        fontSize: 16,
        justifyContent: 'center',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
        lineHeight: '16px',
        minHeight: 55,
        '& $weekDayContent': {
          padding: '6px 1px 0',
          fontSize: 12,
          lineHeight: '12px',
        },
        '& $weekDayNumber': {
          width: 34,
          height: 22,
          fontSize: 14,
          lineHeight: '16px',
        },
      },
    },
    header: {
      fontSize: 16,
      lineHeight: '16px',
      color: grays.gray2,
      fontWeight: 500,
      minHeight: 'auto',
      border: 'none',
      paddingBottom: 10,
      [theme.breakpoints.down('xs')]: {
        color: grays.gray3,
      },
    },
    headerSmall: {
      marginBottom: 13,
      fontSize: 14,
      lineHeight: '20px',
      color: '#49454F',
      letterSpacing: '0.1px',
      fontWeight: 500,
    },
    weekDayContent: {
      padding: '7px 6px',
      fontSize: 14,
      fontWeight: 500,
      display: 'flex',
      width: '100%',
      height: '100%',
      position: 'relative',
      cursor: 'pointer',
      flexDirection: 'column',
      color: '#333333',
      '&:hover': {
        background: 'white',
      },
      [theme.breakpoints.down('md')]: {
        '&:hover': {
          background: 'transparent',
        },
      },
    },
    weekDayNumber: {
      marginBottom: 6,
      display: 'flex',
      alignItems: 'center',
      width: 34,
      height: 24,
      fontSize: 14,
      lineHeight: '16px',
      fontWeight: 500,
      borderRadius: 100,
      [theme.breakpoints.down('xs')]: {
        margin: '0 auto',
        justifyContent: 'center',
      },
    },
    today: {
      '& $weekDayNumber': {
        justifyContent: 'center',
        color: 'white',
        backgroundColor: '#1565C0',
      },
    },
    hasEvent: {
      background: '#E7E7E8',
    },
    eventBadge: {
      width: '100%',
      height: '100%',
      '& .MuiBadge-badge': {
        backgroundColor: '#3870B2',
        color: '#CBDAEB',
      },
    },
    disable: {
      pointerEvents: 'none',
      color: grays.gray4,
    },
    notCurrentMonth: {
      pointerEvents: 'none',
      color: grays.gray3,
      '& $labelVisitWrapSmall': {
        opacity: 0.6,
      },
    },
    select: {
      background: 'white !important',
    },
    labelVisitText: {
      overflow: 'hidden',
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    labelVisitTextSmall: {
      maxWidth: '100%',
      color: '#1565C0',
    },
    labelVisitMore: {
      fontSize: 12,
      lineHeight: '16px',
      color: '#858F97',
    },
    labelVisitWrap: {
      display: 'flex',
      marginBottom: 5,
      alignItems: 'center',
    },
    labelVisitWrapSmall: {
      display: 'flex',
      marginTop: 8,
      marginBottom: 5,
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        marginTop: 6,
      },
    },
    labelVisitDot: {
      flexShrink: 0,
      width: 10,
      height: 10,
      marginRight: 10,
      borderRadius: '50%',
      [theme.breakpoints.down('xs')]: {
        width: 9,
        height: 9,
        marginRight: 4,
      },
    },
    labelVisitDotSmall: {
      flexShrink: 0,
      width: 12,
      height: 12,
      marginRight: 6,
      borderRadius: '50%',
      [theme.breakpoints.down('xs')]: {
        width: 9,
        height: 9,
        marginRight: 4,
      },
    },
    statusPlanned: {
      background: theme.palette.info.main,
    },
    statusFinished: {
      background: theme.palette.success.main,
    },
    statusInProgress: {
      background: theme.palette.warning.main,
    },
    statusCanceled: {
      background: theme.palette.grey[600],
    },
  }),
)

export interface ICalendarEvent {
  date: number
  count?: number
}

interface ICalendarProps {
  startOfMonth: number
  visits: VisitWithPos[]
  tasks: ITask[]
  onCellClick: (date: number) => void
  selectDate: number
}

interface IWeek {
  /**
   * номер недели в месяце
   */
  weekNumber: number
  daysInWeek: IDayOfWeek[]
}

interface IDayOfWeek {
  /**
   * день недели
   */
  date: Date
  /**
   * входит ли день недели в текущий месяц
   */
  isSameMonth: boolean
  /**
   * это сегоднящний день или нет
   */
  isToday: boolean
  // hasEvent: boolean
  // eventCount?: number
  visits: VisitWithPos[]
  tasks: ITask[]
}

const Calendar: React.FC<ICalendarProps> = (props) => {
  const classes = useStyles()
  const onCellClickHandler = useCallback((date: number) => props.onCellClick(date), [])

  const startOfMonth = props.startOfMonth
  const endOfMonth = _endOfMonth(props.startOfMonth)

  const weekMatrix = eachWeekOfInterval(
    {
      start: startOfMonth,
      end: endOfMonth,
    },
    { weekStartsOn: 1 },
  )

  const weeksMatrix: IWeek[] = weekMatrix
    .map((weekDay) =>
      eachDayOfInterval({
        start: startOfISOWeek(weekDay),
        end: endOfISOWeek(weekDay),
      }),
    )
    .map((weekDays, idx) => ({
      weekNumber: idx + 1,
      daysInWeek: weekDays.map((weekDay) => {
        // const foundEvent = props.events.find((event) =>
        //   isWithinInterval(event.date, { start: startOfDay(weekDay), end: endOfDay(weekDay) })
        // )
        const foundVisits = props.visits.filter((visit) => isSameDay(visit.plannedStartDate, weekDay))
        const foundTasks = props.tasks.filter((task) => isSameDay(task.startDate, weekDay))
        // const eventCount = foundEvent?.count
        return {
          date: weekDay,
          isSameMonth: isSameMonth(startOfMonth, weekDay),
          isToday: isToday(weekDay),
          visits: foundVisits,
          tasks: foundTasks,
          // hasEvent: !!foundEvent,
          // eventCount
        }
      }),
    }))

  return (
    <Paper className={classes.root}>
      {/*<div className={classNames([classes.cell, classes.additionalData])}>*/}
      {/*  <Typography component='p'>{currentDateFormatted}</Typography>*/}
      {/*</div>*/}
      <div className={classes.calendarRoot}>
        <CalendarHeader date={props.startOfMonth} />
        {weeksMatrix.map((week) => (
          <CalendarWeek
            key={week.weekNumber}
            weekDays={week.daysInWeek}
            onCellClick={onCellClickHandler}
            selectDate={props.selectDate}
          />
        ))}
      </div>
    </Paper>
  )
}
export default Calendar

interface ICalendarHeader {
  date: number
}

const CalendarHeader: React.FC<ICalendarHeader> = ({ date }) => {
  const classes = useStyles()
  // const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'))
  const { i18n } = useTranslation()
  const list =
    i18n.language === 'ru' ? ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'] : ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']

  // if (isSmall) {
  //   return <div className={classes.headerSmall}>{capitalizeFirstLetter(dateFormat(date, 'LLLL yyyy'))}</div>
  // }

  return (
    <Grid container wrap='nowrap'>
      {list.map((day) => (
        <Grid key={day} item xs className={classnames([classes.cell, classes.header])}>
          {day}
        </Grid>
      ))}
    </Grid>
  )
}

interface ICalendarWeekProps {
  weekDays: IDayOfWeek[]
  onCellClick: (date: number) => void
  selectDate: number
}

const CalendarWeek: React.FC<ICalendarWeekProps> = (props) => {
  const onCellClickHandler = useCallback((date: number) => props.onCellClick(date), [])

  return (
    <Grid container wrap='nowrap'>
      {props.weekDays.map((weekday) => (
        <CalendarDay
          key={weekday.date.valueOf()}
          weekDay={weekday}
          onCellClick={onCellClickHandler}
          selectDate={props.selectDate}
        />
      ))}
    </Grid>
  )
}

interface ICalendarDayProps {
  weekDay: IDayOfWeek
  onCellClick: (date: number) => void
  selectDate: number
}

const CalendarDay: React.FC<ICalendarDayProps> = (props) => {
  const classes = useStyles()
  const isMedium = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))
  const formattedDay = dateFormat(props.weekDay.date, 'd')
  // const text = !props.weekDay.isSameMonth ? '' : formattedDay
  const text = formattedDay

  const maxDate = addMonths(new Date(), 2)
  const isSelect = isSameDay(props.selectDate, props.weekDay.date.valueOf())

  const renderCellLabels = (visits: VisitWithPos[], tasks: ITask[]): JSX.Element => {
    if (!props.selectDate && !isMedium) {
      // TODO: эта ветка не работает - щас всегда есть выбранная дата
      return (
        <>
          {visits.map((visit, index) =>
            index < 2 ? (
              <div className={classes.labelVisitWrap} key={visit.code}>
                <div
                  className={classnames(classes.labelVisitDot, {
                    [classes.statusPlanned]: visit.status === 'Planned',
                    [classes.statusFinished]: visit.status === 'Finished',
                    [classes.statusInProgress]: visit.status === 'InProgress',
                    [classes.statusCanceled]: visit.status === 'Canceled',
                  })}
                />
                <div className={classes.labelVisitText}>{visit.pointOfSale.name}</div>
              </div>
            ) : (
              ''
            ),
          )}
          {visits.length > 2 ? <div className={classes.labelVisitMore}>Еще {visits.length - 2}</div> : <></>}
        </>
      )
    } else {
      if (!visits.length && !tasks.length) return <></>

      // Преоритет отображения точки в статусе
      const statusPriority: VisitStatus[] = ['InProgress', 'Planned', 'Finished', 'Canceled']

      const getCellStatus = (): string | undefined => {
        for (const status of statusPriority) {
          const findVisit = visits.find((visit) => visit.status === status)
          if (findVisit) {
            return findVisit.status
          }
        }

        for (const status of statusPriority) {
          const findTask = tasks.find((task) => task.status === status)
          if (findTask) {
            return findTask.status
          }
        }
      }
      const cellStatus = getCellStatus()

      return (
        <>
          <div className={classes.labelVisitWrapSmall}>
            <div
              className={classnames(classes.labelVisitDotSmall, {
                [classes.statusPlanned]: cellStatus === 'Planned',
                [classes.statusFinished]: cellStatus === 'Finished',
                [classes.statusInProgress]: cellStatus === 'InProgress',
                [classes.statusCanceled]: cellStatus === 'Canceled',
              })}
            />
            <div className={classes.labelVisitTextSmall}>{visits.length + tasks.length}</div>
          </div>
        </>
      )
    }
  }

  return (
    <Grid
      item
      xs
      className={classnames(classes.cell, {
        [classes.cellSmall]: !!props.selectDate,
      })}
    >
      {/*{props.weekDay.isSameMonth && (*/}
      {/*<Badge color='primary' max={9} badgeContent={props.weekDay?.eventCount} className={classes.eventBadge}>*/}
      <div
        onClick={() => props.onCellClick(props.weekDay.date.valueOf())}
        className={classnames(classes.weekDayContent, {
          [classes.today]: props.weekDay.isToday && props.weekDay.isSameMonth,
          // [classes.hasEvent]: props.weekDay.hasEvent,
          [classes.disable]: props.weekDay.date > maxDate,
          [classes.notCurrentMonth]: !props.weekDay.isSameMonth,
          [classes.select]: isSelect,
        })}
      >
        <Typography className={classes.weekDayNumber}>{text}</Typography>
        {renderCellLabels(props.weekDay.visits.sort(sortByStatus), props.weekDay.tasks)}
      </div>
      {/*</Badge>*/}
      {/*)}*/}
    </Grid>
  )
}
