import React, { useState } from 'react'

import { Box, createStyles, IconButton, makeStyles, Menu, MenuItem, Theme } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'

import { IPointOfSale } from '../../../../../model/pos'
import { getPosAddress } from '../../../../_common/pos'
import { getRegisteredNameFromPos } from '../../../../stores/store-menu-page/utils'
import StoresListItem from '../../../../stores/stores-list-item/stores-list-item'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
    },
    listItem: {
      display: 'flex',
      width: '100%',
      '& .MuiPaper-root': {
        overflow: 'hidden',
        flexBasis: '100%',
        padding: 0,
      },
    },
    iconBtn: {
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        top: 8,
        right: 0,
      },
    },
  }),
)

interface IProps {
  item: IPointOfSale
  toggleModalVisibility: (val: boolean) => void
  reset: () => void
}

const SelectedPosItem: React.FC<IProps> = ({ item, toggleModalVisibility, reset }) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>()
  const isMenuOpen = Boolean(anchorEl)

  const clear = (): void => {
    setAnchorEl(undefined)
    reset()
  }

  return (
    <Box className={classes.item}>
      <Box className={classes.listItem} onClick={() => toggleModalVisibility(true)}>
        <StoresListItem
          name={item.name}
          code={item.code}
          address={getPosAddress(item)}
          registerName={getRegisteredNameFromPos(item)}
        />
      </Box>
      <IconButton
        className={classes.iconBtn}
        size='small'
        id='demo-positioned-button'
        aria-controls={isMenuOpen ? 'demo-positioned-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={isMenuOpen ? 'true' : undefined}
        onClick={(ev) => {
          setAnchorEl(ev.currentTarget)
        }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(undefined)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={clear}>Cбросить выбранную ТТ</MenuItem>
      </Menu>
    </Box>
  )
}

export default SelectedPosItem
