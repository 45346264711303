export function runSyncScript<ScriptResult>(scriptBody: string, context: Record<string, unknown>): ScriptResult {
  const params = {
    ...context,
    window: 'window',
    self: 'self',
    globalThis: 'globalThis',
  }
  // eslint-disable-next-line @typescript-eslint/no-implied-eval, no-new-func
  const fn = new Function(...Object.keys(params), scriptBody)
  const result: ScriptResult = fn.call('this', ...Object.values(params))
  // console.log('running sync script', { res: result, scriptBody })

  return result
}


/* eslint-disable @typescript-eslint/no-explicit-any */
type AnyFunction = (...args: any[]) => any

/** Premature optimization for running thousands of scripts */
export class SyncScriptRunner {
  params: Record<string, unknown>
  fn: AnyFunction

  constructor(scriptBody: string, context: Record<string, unknown>, readonly recordName = 'record') {
    this.params = {
      [recordName]: {},
      ...context,
      window: 'window',
      self: 'self',
      globalThis: 'globalThis',
    }
    // eslint-disable-next-line @typescript-eslint/no-implied-eval, no-new-func
    this.fn = new Function(...Object.keys(this.params), scriptBody) as AnyFunction
  }

  run(record: unknown): any {
    this.params[this.recordName] = record // mutable to keep the object properties ordering
    return this.fn.call('this', ...Object.values(this.params))
  }
}
