import { useContext } from 'react'

import { Code } from '../../../model/base'
import { ApiContext } from '../../../providers'
import { useFetchPendingItems } from '../../../providers/menu-data/pending-items-store'
import { ModalContext } from '../../../providers/modal'
import { useTryUpload, useTryUploadAll } from '../../_common/hooks/useTryUpload'
import { useScriptTaskContext } from './script-task-context'

export function useTaskMethods(): {
  startTask: () => Promise<void>
  finishTask: (reportLink: string) => Promise<boolean>
} {
  const api = useContext(ApiContext)
  const modalContext = useContext(ModalContext)
  const localContext = useScriptTaskContext()
  const tryUploadVisit = useTryUpload('visit')
  const fetchPendingItems = useFetchPendingItems()
  const tryUploadPendingVisit = useTryUploadAll()

  const taskCode = localContext.task.code
  const visitCode = localContext.visit?.code

  const startTask = async (): Promise<void> => {
    await api.tasks.setTaskStatus({ taskCode, taskStatus: 'InProgress', visitCode: localContext.visit?.code })
    if (visitCode) {
      void tryUploadPendingVisit(true)
    }
  }

  const finishTask = async (reportLink = ''): Promise<boolean> => {
    const task = await api.tasks.finishTask({ taskCode, reportLink })

    function finishSurvey(surveyCode: Code | undefined): void {
      if (!surveyCode) return
      void api.survey.setSurveyStatus({ surveyCode, status: 'Finished' })
    }

    task.surveys?.forEach((ref) => finishSurvey(ref.code))
    // appToast.success('Задача завершена')
    modalContext.close()

    if (visitCode) {
      void tryUploadVisit(visitCode).then(fetchPendingItems)
    }
    return true
  }

  return { startTask, finishTask }
}
