import React, { useEffect, useState } from 'react'

import { Typography, Box, Button, MenuItem, Menu, IconButton } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { useTranslation } from 'react-i18next'

import { IBooleanPropertyScreenItem } from '../../../../../model/screen-item'
import { useUpdateProperty } from '../../../nested/useUpdateProperty'
import { getPropertyAny } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import AnswerResolution from '../answer-resolution'
import { ItemCard } from '../item-card'
import { useBooleanStyles } from './styles'

interface Props {
  item: IBooleanPropertyScreenItem
}

export const BooleanScreenItemControl: React.FC<Props> = ({ item }) => {
  useEffect(() => {
    console.log('BooleanScreenItemControl', item)
  }, [])

  const classes = useBooleanStyles()
  const { t } = useTranslation('common')
  const context = useScriptTaskContext()
  const updateProperty = useUpdateProperty()

  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement>()
  const handleCloseMenu = (): void => setMenuAnchor(undefined)

  const value = getPropertyAny<boolean>(context, item.propertyName) as boolean | undefined | null

  if (item.actionKind === 'View') {
    if (item.viewSettings?.hideEmpty === true && value === null) {
      return <></>
    }
  }

  const isCompact = false

  const isAnswer = value === false || value === true || value === null

  const renderLabel = (): JSX.Element => {
    return (
      <>
        <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={handleCloseMenu}>
          <MenuItem
            onClick={() => {
              handleCloseMenu()
              void updateProperty(item.propertyName, undefined)
            }}
          >
            {t('reset')}
          </MenuItem>
        </Menu>
        <Box className={classes.label}>
          <Typography variant='inherit'>{item.displayName}</Typography>
          <Box ml='auto' pl={2}>
            {isAnswer && isCompact && (
              <AnswerResolution
                resolution={value}
                onlyText={false}
                text={value === true ? 'Да' : value === false ? 'Нет' : item.viewSettings?.nullValueCaption ?? 'N/A'}
              />
            )}
            {item.actionKind === 'Edit' && isAnswer && (
              <IconButton edge='end' size='small' onClick={(ev) => setMenuAnchor(ev.currentTarget)}>
                <MoreVertIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      </>
    )
  }

  return (
    <ItemCard label={renderLabel()} isError={!isAnswer}>
      {item.actionKind === 'Edit' && !isAnswer && (
        <div className={classes.buttonsWrap}>
          <Button
            className={classes.successButton}
            size='large'
            variant='outlined'
            onClick={() => {
              void updateProperty(item.propertyName, true)
            }}
          >
            Да
          </Button>
          <Button
            className={classes.declineButton}
            size='large'
            variant='outlined'
            onClick={() => {
              void updateProperty(item.propertyName, false)
            }}
          >
            Нет
          </Button>
          {item.editSettings?.nullable && (
            <Button
              className={classes.naButton}
              size='large'
              variant='outlined'
              onClick={() => {
                void updateProperty(item.propertyName, null)
              }}
            >
              {item.viewSettings?.nullValueCaption ?? 'N/A'}
            </Button>
          )}
        </div>
      )}
      {isAnswer && !isCompact && (
        <div className={classes.answerWrap}>
          <AnswerResolution
            resolution={value}
            onlyText={false}
            text={value === true ? 'Да' : value === false ? 'Нет' : item.viewSettings?.nullValueCaption ?? 'N/A'}
          />
        </div>
      )}
    </ItemCard>
  )
}
