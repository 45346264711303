import * as React from 'react'

// import { repeat } from 'lodash'
import { useNavigate } from 'react-router-dom'

import { ITaskTemplate } from '../../../model/task-template'
import { TemplatePreview } from './template-preview'

export const TestTemplatePage: React.FC = () => {
  const navigate = useNavigate()
  return <TemplatePreview template={testTemplate} initialData={initialData} onBack={(): void => navigate('')} />
}

const initialData = {
  richText: `
  <div>
    <h1>h1 title</h1> 
    <p>Paragraph</p>
    <input type="text" placeholder="some input">
  </div>
`,
}

const testTemplate: ITaskTemplate = {
  code: 'test template',
  name: 'Test template',
  orderNumber: 0,
  version: {
    code: '',
    startDate: 0,
  },
  process: {
    code: 'process-1',
    updateTime: 0,
    steps: [
      {
        $type: 'PMI.FACE.BDDM.Extensions.Classes.UserStep',
        availableBeforeTaskStart: true,
        code: 'step-1',
        displayName: 'Step 1',
        screen: {
          code: 'screen-1',
        },
      },
    ],
  },
  subProcesses: {},
  resultScreen: undefined!,
  resultFile: undefined!,
  scripts: {},
  userStepScreens: {
    'screen-1': {
      code: 'screen-1',
      $type: 'PMI.FACE.BDDM.Extensions.Classes.CompositeUserStepScreen',
      items: [
        {
          $type: 'PMI.FACE.BDDM.Extensions.Classes.DateInputScreenItem',
          displayName: 'Date input',
          orderNumber: 0,
          propertyName: 'scope.inputDate',
          required: {
            $type: 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate',
            value: true,
          },
        },
        {
          $type: 'PMI.FACE.BDDM.Extensions.Classes.ViewRichTextScreenItem',
          displayName: 'ViewRichTextScreenItem',
          orderNumber: 1,
          propertyName: 'richText',
          richTextContent: initialData.richText,
          redirectPath: '/test',
          borderRadius: 0,
          useDefaultPadding: false,
          desktopLayout: {
            width: 100,
            horizontalAlignment: 'Center',
          },
          mobileLayout: {
            width: 100,
            horizontalAlignment: 'Center',
          },
        },
        // {
        //   $type: 'PMI.FACE.BDDM.Extensions.Classes.TextScreenItem',
        //   displayName: 'Text item test',
        //   orderNumber: 0,
        //   propertyName: 'pos.name',
        //   format: {
        //     $type: 'PMI.FACE.BDDM.Extensions.Classes.ConstStringValue',
        //     value: 'Город: {pos.address.city}\nАдрес: {pos.address.fullAddress}',
        //     // value: repeat('ellipsistest', 100),
        //   },
        //   collapsible: true,
        //   hideEmpty: true,
        //   nullValueCaption: 'no value',
        //   useEllipsis: false,
        // },
        {
          $type: 'PMI.FACE.BDDM.Extensions.Classes.HyperlinkScreenItem',
          displayName: 'test pdf link',
          linkFormat: 'https://face-dev-api.pmruservice.com/s3/reports/{task.printedForm.parts[0].target}',
          propertyName: '',
          orderNumber: 2,
        },
        // {
        //   $type: 'PMI.FACE.BDDM.Extensions.Classes.BooleanInputScreenItem',
        //   displayName: 'Boolean input item test',
        //   orderNumber: 1,
        //   propertyName: 'task.boolean',
        //   nullable: true,
        // },
        // {
        //   $type: 'PMI.FACE.BDDM.Extensions.Classes.BooleanViewScreenItem',
        //   displayName: 'Boolean view item test',
        //   orderNumber: 2,
        //   propertyName: 'task.boolean',
        //   hideEmpty: true,
        // },
        // {
        //   $type: 'PMI.FACE.BDDM.Extensions.Classes.ProblemsListScreenItem',
        //   displayName: 'Проблемы',
        //   orderNumber: 3,
        //   posCodePropertyName: 'pos.code',
        //   propertyName: '',
        // },
      ],
    },
  },
  updateTime: 0,
}
