import React, { useContext, useEffect, useState } from 'react'

import { makeStyles, Theme, createStyles, Paper, Box } from '@material-ui/core'
import { isBefore, set } from 'date-fns'
import { useNetworkState } from 'react-use'

import { LogManager } from '../../infrastructure/logger'
import { ApiContext, AuthContext } from '../../providers'
import { useBusinessSettings } from '../_common/hooks/useBusinessSettings'
import { SyncPage, SyncPageAuto } from '../sync-page'

const logger = LogManager.getLogger('RequireSync')

export const RequireSync: React.FC = ({ children }) => {
  const api = useContext(ApiContext)
  const auth = useContext(AuthContext)
  const businessSettings = useBusinessSettings()
  const [forceSync, setForceSync] = useState(false)

  useEffect(() => {
    if (!api?.userProfile || !auth?.authService) {
      return
    }

    function logoff(): void {
      setForceSync(true)
    }

    async function checkSync(): Promise<void> {
      if (!businessSettings) return
      try {
        const lastSyncDate = await api.userProfile.getLastSyncSuccess()
        if (lastSyncDate) {
          const currentDate = new Date()
          const { requiredSyncTime } = businessSettings
          const match = requiredSyncTime?.match(/^(?<hours>\d\d):(?<minutes>\d\d)$/)
          if (match === null) {
            logger.warn('checkSync', 'error parsing requiredSyncTime', null, requiredSyncTime)
          }
          const { hours = '06', minutes = '00' } = match?.groups ?? {}
          const deadline = set(currentDate, {
            hours: Number(hours),
            minutes: Number(minutes),
            seconds: 0,
            milliseconds: 0
          })
          console.debug('RequireSync: lastSyncDate=', lastSyncDate, '; deadline=', deadline)
          if (isBefore(deadline, currentDate) && isBefore(lastSyncDate, deadline)) {
            logger.debug('checkSync', 'forced sync', { lastSyncDate })
            logoff()
          }
        } else {
          logger.debug('checkSync', 'no sync yet')
          logoff()
        }
      } catch (error) {
        logger.error('checkSync', 'error getting last sync date', error)
        logoff()
      }
    }

    logger.debug('onInit', 'checking last sync')
    void checkSync()

    function handleChange(): void {
      if (document.visibilityState === 'visible') {
        logger.debug('visibilityChange', 'checking last sync')
        void checkSync()
      }
    }
    document.addEventListener('visibilitychange', handleChange)
    return () => {
      document.removeEventListener('visibilitychange', handleChange)
    }
  }, [api?.userProfile, auth?.authService, businessSettings])

  if (forceSync) {
    return <ForcedSyncPage onSyncSuccess={() => setForceSync(false)} />
  }
  return <>{children}</>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topPanel: {
      background: theme.palette.secondary.main,
      height: theme.spacing(4),
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%'
    },
    bottomPanel: {
      background: theme.palette.secondary.main,
      height: theme.spacing(4),
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%'
    }
  })
)

const ForcedSyncPage: React.FC<{ onSyncSuccess: VoidFunction }> = ({ onSyncSuccess }) => {
  const ns = useNetworkState()
  const classes = useStyles()
  if (ns.online) {
    return <SyncPageAuto onSyncSuccess={onSyncSuccess} />
  } else {
    return (
      <>
        <Paper square elevation={5} className={classes.topPanel} />
        {/** override global styles to fit on any screen */}
        <Box pt={9} pb={5} height='100vh'>
          <SyncPage onSyncSuccess={onSyncSuccess} />
        </Box>
        <Paper square elevation={5} className={classes.bottomPanel} />
      </>
    )
  }
}
