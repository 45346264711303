import React from 'react'

import { createStyles, IconButton, makeStyles, SvgIcon } from '@material-ui/core'

import { ReactComponent as ExclamationWarnIcon } from '../../assets/icons/exclamation-warn.svg'
import { BlobStorageError, BlobStorageErrorCode } from '../../infrastructure/blob-storage/blob-storage-api'
import { appToast } from '../../utils'

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      padding: 0,
      paddingRight: 12,
    },
    warnIcon: {
      color: '#FF9800',
    },
  }),
)

interface ILoadErrorIcon {
  loadImageError: BlobStorageError
}

export const LoadErrorIcon: React.FC<ILoadErrorIcon> = ({ loadImageError }) => {
  const classes = useStyles()

  const onClick = (): void => {
    if (
      loadImageError.code === BlobStorageErrorCode.Unauthorized ||
      loadImageError.code === BlobStorageErrorCode.Offline
    ) {
      appToast.info(mapError(loadImageError))
    } else {
      appToast.error(mapError(loadImageError))
    }
  }

  if (loadImageError.code === BlobStorageErrorCode.NotFound) {
    return <></>
  }

  return (
    <IconButton className={classes.button} onClick={onClick}>
      {loadImageError.code === BlobStorageErrorCode.Unauthorized ||
      loadImageError.code === BlobStorageErrorCode.Offline ? (
        <SvgIcon className={classes.warnIcon} component={ExclamationWarnIcon} />
      ) : (
        <SvgIcon color='error' component={ExclamationWarnIcon} />
      )}
    </IconButton>
  )
}

export function mapError(error: BlobStorageError): string {
  switch (error.code) {
    case BlobStorageErrorCode.Offline:
      return 'Для получения фотографий необходимо подключение к сети Интернет'
    case BlobStorageErrorCode.Unauthorized:
      return 'Пользователь не аутентифицирован. Необходимо выйти из приложения и войти повторно'
    default:
      return 'При получении фотографий произошла ошибка. Попробуйте выйти из приложения и войти повторно или обратитесь в техническую поддержку'
  }
}
