import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'

import { Box, createStyles, LinearProgress, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import device from 'current-device'
import { Document, Page } from 'react-pdf'
import QuickPinchZoom, {
  hasTranslate3DSupport,
  make2dTransformValue,
  make3dTransformValue,
} from 'react-swipe-quick-pinch-zoom'

import { LogManager } from '../../../infrastructure/logger'
import { appToast } from '../../../utils'
import { PdfDocContext, PdfDocumentActionKind } from '../pdf-reducer'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    viewerWrapper: {
      // position: 'fixed',
      // top: 0,
      // left: 0,
      zIndex: 9999,
      display: 'flex',
      width: '100%',
      height: '100%',
      backgroundColor: '#ffffff',
      overflowY: 'scroll',
    },
    buttonClose: {
      position: 'fixed',
      bottom: '2%',
      right: '2%',
    },
    transform: {
      minHeight: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      overflow: 'hidden',
    },
    pageNav: {
      position: 'fixed',
      bottom: 10,
      left: '50%',
      display: 'flex',
      transform: 'translateX(-50%)',
      zIndex: 4,
      margin: 0,
      padding: 5,
      backgroundColor: '#fff',
      borderRadius: 8,
      boxShadow: '0px 0px 8px 0px rgba(34, 60, 80, 0.2)',
    },
    pageNavNumber: {
      margin: 0,
      padding: '0 10px',
    },
    innerItem: {
      minHeight: '200vh',
      display: 'block',
      width: '100% !important',
      height: 'auto !important',
      transformOrigin: '0 0',
      '& canvas': {
        display: 'block',
        width: '100% !important',
        height: 'auto !important',
      },
    },
    canvasWrap: {
      position: 'relative',
      width: '200vw',
      transformOrigin: '0 0',
      transform: 'scale(0.5)',
      cursor: 'grab',
    },
    loader: {
      width: '100%',
      height: '100%',
      backgroundColor: '#F4F4F4',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .MuiLinearProgress-root': {
        width: '80%',
      },
    },
  }),
)

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

const use3DTransform = hasTranslate3DSupport() && !isSafari

const makeTransformValue = use3DTransform ? make3dTransformValue : make2dTransformValue

export type PdfFile = string | undefined | null

interface IPdfViewerProps {
  pdf: PdfFile
}

const logger = LogManager.getLogger('PdfViewer')

const PdfViewer: React.FC<IPdfViewerProps> = ({ pdf }): JSX.Element => {
  const classes = useStyles()
  const { state, dispatch } = useContext(PdfDocContext)

  const [canvasWrapHeight, setCanvasWrapHeight] = useState<string | number>()

  const quickPinchZoomRef = useRef<QuickPinchZoom>(null)
  const innerItemRef = useRef<HTMLDivElement>(null)
  const canvasWrapRef = useRef<HTMLDivElement>(null)
  const canvas = useRef<HTMLCanvasElement>(null)
  const [isLoad, setIsLoad] = useState(true)

  const devicePixelRatio = device.desktop() ? 3 : 2

  useEffect(() => {
    window.addEventListener('resize', setCanvasWrapHeightHandler)
    dispatch({ type: PdfDocumentActionKind.SetNumPages, payload: 0 })

    return () => {
      window.removeEventListener('resize', setCanvasWrapHeightHandler)
    }
  }, [])

  const onUpdate = useCallback(
    ({ x, y, scale }) => {
      const { current: pdfElement } = innerItemRef
      if (pdfElement) {
        const value = makeTransformValue({ x: x * 2, y: y * 2, scale: scale })

        pdfElement.style.setProperty('transform', value)
        // const isEndScroll = -(Math.round(pdfElement.offsetHeight / 2 - window.innerHeight))
      }
    },
    [innerItemRef],
  )

  const setCanvasWrapHeightHandler = useCallback((): void => {
    const height = innerItemRef?.current?.offsetHeight ? innerItemRef?.current?.offsetHeight / 2 : 'auto'
    setCanvasWrapHeight(height)
    quickPinchZoomRef.current?.scaleTo({ x: 0, y: 0, scale: 1 })
  }, [innerItemRef])

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    dispatch({ type: PdfDocumentActionKind.SetNumPages, payload: numPages })
    dispatch({ type: PdfDocumentActionKind.SetCurrentPage, payload: 1 })
  }

  const renderLoader = (): JSX.Element => (
    <div className={classes.loader}>
      <LinearProgress />
    </div>
  )

  return (
    <>
      {isLoad && renderLoader()}
      <Box className={classes.viewerWrapper}>
        {!!state.numPages && canvasWrapHeight && (
          <div className={classes.pageNav}>
            <p className={classes.pageNavNumber}>
              {state.currentPage} / {state.numPages}
            </p>
          </div>
        )}
        <QuickPinchZoom ref={quickPinchZoomRef} onUpdate={onUpdate}>
          <div>
            <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess} loading={''}>
              <div ref={canvasWrapRef} className={classes.canvasWrap} style={{ height: `${canvasWrapHeight}px` }}>
                <div className={classes.innerItem} ref={innerItemRef}>
                  {!!state.numPages && (
                    <Page
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      canvasRef={canvas}
                      pageNumber={state.currentPage}
                      devicePixelRatio={devicePixelRatio}
                      onRenderSuccess={() => {
                        setCanvasWrapHeightHandler()
                        setIsLoad(false)
                      }}
                      loading={''}
                      onRenderError={(error) => {
                        appToast.error(error.message)
                        logger.error('onRenderError', 'Error render pdf', error)
                      }}
                      onLoadError={(error) => {
                        appToast.error(error.message)
                        logger.error('onLoadError', 'Error load pdf', error)
                      }}
                    />
                  )}
                </div>
              </div>
            </Document>
          </div>
        </QuickPinchZoom>
      </Box>
    </>
  )
}

export default PdfViewer