import React from 'react'

import { FormHelperText, Typography } from '@material-ui/core'

import { IIntegerInputScreenItem } from '../../../../../model/screen-item'
import { checkPredicate } from '../../../../../model/script-predicate'
import { getContextProperty } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { ItemCard } from '../item-card'
import { checkNumericError, NumberEditor } from '../table-item/number-editor'
import { validateIntegerInputScreenItem } from '../validate'
import { getMaxValue, getMinValue, IIntegerRecord } from './utils'

interface Props {
  item: IIntegerInputScreenItem
  onChange: (value: number | null | undefined) => void
}

export const IntegerInputItem: React.FC<Props> = ({ item, onChange }) => {
  const propertiesContext = useScriptTaskContext()
  const value = getContextProperty(propertiesContext, item.propertyName, undefined) as unknown as
    | number
    | null
    | undefined

  const minValue = getMinValue(item, propertiesContext)
  const maxValue = getMaxValue(item, propertiesContext)

  let isRequired = false
  if (item.required) {
    switch (item.required.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.ScriptPredicate':
        isRequired = checkPredicate(item.required, {} as IIntegerRecord, propertiesContext)
        break
      case 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate':
        isRequired = item.required.value
    }
  }

  const isError = validateIntegerInputScreenItem(item, propertiesContext).isError
  const isNumericError = checkNumericError(value, isRequired, minValue, maxValue)

  return (
    <ItemCard
      isError={isError}
      label={(() => item.displayName && <Typography variant='inherit'>{item.displayName}</Typography>)()}
    >
      <NumberEditor
        value={value === null ? '' : value}
        onChange={(value) => {
          if (typeof value === 'number') {
            onChange(value)
          } else {
            onChange(null)
          }
        }}
        showIncreaseButtons={item.showIncreaseButtons ?? false}
        minValue={minValue}
        maxValue={maxValue}
        defaultValue={item.defaultValue}
        incrementValue={item.incrementValue}
        isRequired={isRequired}
      />
      {isNumericError && (
        <FormHelperText error>
          {item.errorHelperText ?? 'Не заполнено обязательное поле или не верный диапазон'}
        </FormHelperText>
      )}
    </ItemCard>
  )
}
