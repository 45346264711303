import React from 'react'

import { Box, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { EmptyMessage } from '../../../components'
import { appToast } from '../../../utils'
import { useSearchVisits } from '../../_common/hooks/useSearchVisits'
import { sortByTaskStatus } from '../../_common/tasks'
import { sortByCreationTime, sortByStatus } from '../../_common/visits'
import { ErrorPage } from '../../error-page'
import { CardTask } from '../task-list/card-task'
import { useSearchNonVisitTasks } from '../task-list/useSearchNonVisitTasks'
import VisitsListItem from '../visits-list-item/visits-list-item'
import { EmptyActivitiesMessage } from './components/empty-activities-message'

export interface IVisitListProps {
  periodStartDate: number
  periodEndDate: number
  filterQuery: string
}

const ActivitiesListByDay: React.FC<IVisitListProps> = ({ periodStartDate, periodEndDate, filterQuery }) => {
  const visitListData = useSearchVisits({ periodStartDate, periodEndDate }, { withPos: true })
  const taskListData = useSearchNonVisitTasks({ periodStartDate, periodEndDate })
  const { t } = useTranslation('visits')

  if (visitListData.error ?? taskListData.error) {
    const message = t('errorFetchingList')
    appToast.error(message)
    return <ErrorPage errorMessage={message} />
  }

  if (visitListData.loading || taskListData.loading) {
    return <EmptyMessage message={t('loading')} marginTop={10} />
  }

  if ((!visitListData.value || !visitListData.value.length) && (!taskListData.value || !taskListData.value.length)) {
    return <EmptyActivitiesMessage>{t('noVisits')}</EmptyActivitiesMessage>
  }

  const sortedAndFilteredList = visitListData
    .value!.sort(sortByCreationTime)

    // .value!.filter(filterCancelledVisitsByToday)
    // .filter((visit) => filterVisitByQuery(visit, filterQuery))

    // .sort(sortByCode)
    // .sort(sortByDate)
    .sort(sortByStatus)

  const taskList = taskListData.value!.sort(sortByTaskStatus)

  if (!sortedAndFilteredList.length && !taskList.length) {
    return <EmptyActivitiesMessage>{t('noQueryVisits')}</EmptyActivitiesMessage>
  }

  // const filterListByQuery = sortedAndFilteredList
  // console.log(filterQuery, filterListByQuery)
  return (
    <>
      <Box mt={3}>
        {taskList.map((task) => (
          <CardTask key={task.code} task={task} refetch={async () => taskListData.retry()} variant='day' />
        ))}
      </Box>
      <Grid container style={{ marginTop: 24, marginBottom: 160, position: 'relative', zIndex: 1 }}>
        {sortedAndFilteredList.map((item) => (
          <Grid item xs={12} key={item.code} style={{ marginBottom: 8 }}>
            <VisitsListItem visit={item} retryVisits={() => visitListData.retry()} />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default React.memo(ActivitiesListByDay)
