/* eslint-disable @typescript-eslint/promise-function-async */
import { mapNotNull, removeWhere } from './array'
import { IAwaitable } from './awaitable'
import { CancellationSource, ICancellationToken } from './cancellation'
import { Deferred } from './deferred'
import { ObjectDisposedError, IDisposable } from './disposable'

export type TaskId = string

export enum TaskStatus {
  Pending = 'Pending',
  Running = 'Running',
  Completed = 'Completed',
  Failed = 'Failed'
}

export interface Task {
  id: TaskId
  status?: TaskStatus
  priority?: number
  deleteAfter?: number
  result?: IAwaitable
  action: (cancellationToken?: ICancellationToken) => Promise<unknown>
}

function _yield(): Promise<void> {
  return new Promise((resolve) => { setTimeout(resolve) })
}

class Signal implements IAwaitable {
  private _promise: Promise<void> | undefined
  private _resolve: (() => void) | undefined

  signal(): void {
    this._promise = undefined
    if (this._resolve) {
      const resolve = this._resolve
      this._resolve = undefined
      resolve()
    }
  }

  get promise(): Promise<void> {
    return this._promise ?? (this._promise = new Promise<void>((resolve => { this._resolve = resolve })))
  }
}

class Lock implements IAwaitable {
  private _locked = false
  private _promise: Promise<void> = Promise.resolve()
  private _resolve?: () => void

  constructor(state = false) {
    this.set(state)
  }

  set(state: boolean): boolean {
    if (this._locked !== state) {
      this._locked = state
      if (state) {
        this._promise = new Promise<void>((resolve => { this._resolve = resolve }))
      } else {
        this._promise = Promise.resolve()
        if (this._resolve) {
          const resolve = this._resolve
          this._resolve = undefined
          resolve()
        }
      }
      return true
    }
    return false
  }

  get promise(): Promise<void> {
    return this._promise
  }
}

interface TaskExecution {
  task: Task
  execution: Promise<void>
}

export class TaskScheduler implements IDisposable {
  private readonly _queue: Task[] = []
  private readonly _slots: Array<TaskExecution | undefined>
  private readonly _name: string
  private _cancellation = new CancellationSource()
  private _isRunning = false
  private _isDisposed = false
  private readonly _enqueueSignal = new Signal()
  private readonly _pauseLock = new Lock()

  constructor(name: string, concurrency = 1) {
    this._name = `TaskScheduler[${name}]`
    console.log(`${this._name}::ctor (concurrency=${concurrency})`)
    this._slots = new Array<TaskExecution>(concurrency)
  }

  private async _schedule(): Promise<void> {
    if (this._isRunning) {
      return
    }

    this._isRunning = true
    const cancellation = this._cancellation

    while (this._queue.length > 0) {
      try { await Promise.race([cancellation.promise, this._pauseLock.promise]) } catch (e) { /* dont care */ }

      if (cancellation.isCancellationRequested) break

      for (let i=0; i < this._slots.length && this._queue.length > 0;) {
        if (this._slots[i] == null) {
          const task = this._queue.shift()
          if (task) {
            console.log(`${this._name}: Starting task '${task.id}' (slot ${i})`)
            task.status = TaskStatus.Running
            try {
              const slotIndex = i
              this._slots[slotIndex] = {
                task,
                execution: task.action(cancellation)
                  .then(
                    () => {
                      task.status = TaskStatus.Completed;
                      (task.result as Deferred<void>)?.resolve();
                    },
                    (e) => {
                      console.debug(`${this._name}: Unhandled error in task '${task.id}' >`, e)
                      task.status = TaskStatus.Failed;
                      (task.result as Deferred<void>)?.reject(e);
                    }
                  )
                  .finally(() => {
                    this._slots[slotIndex] = undefined;
                  })
              }
              i++
            } catch (e) {
              task.status = TaskStatus.Failed;
              console.error(`${this._name}: Error invoking task '${task.id}'`, e);
              (task.result as Deferred<void>)?.reject(e);
            }
          }
        } else {
          i++
        }
      }

      const executions = mapNotNull(this._slots, t => t.execution)
      if (executions.length > 0) {
        try {
          if (executions.length === this._slots.length) {
            await Promise.race([cancellation.promise, ...executions])
          } else {
            await Promise.race([this._enqueueSignal.promise, cancellation.promise, ...executions])
          }
        } catch (e) {
          // whatever
        }
      }

      if (cancellation.isCancellationRequested) break

      await _yield()
    }

    console.log(`${this._name}: gone to sleep`)
    this._isRunning = false
  }

  private _cancel(): void {
    this._cancellation.cancel()
    this._cancellation = new CancellationSource()
  }

  private async _waitCurrentExecutions(): Promise<void> {
    try {
      await Promise.allSettled(this._slots.map(t => t?.execution))
    } catch (e) {
      // do not care
    }
  }

  public enqueueTasks(tasks: Task[]): void {
    if (this._isDisposed) {
      throw new ObjectDisposedError()
    }
    if (tasks.length) {
      tasks.forEach(t => { t.status = TaskStatus.Pending; t.result = new Deferred<void>() })
      this._queue.push(...tasks)
      this._queue.sort(
        (t1, t2) =>
          // eslint-disable-next-line eqeqeq
          (t1.priority == t2.priority) ? 0 : (t1.priority == null ? 1 : (t2.priority == null ? -1 : t2.priority - t1.priority))
      )
      if (this._isRunning) {
        this._enqueueSignal.signal()
      } else {
        void this._schedule()
      }
    }
  }

  public evictPendingTasks(ids: Set<TaskId>): void {
    const removed = removeWhere(this._queue, task => ids.has(task.id))
    removed.forEach(t => { t.status = undefined })
  }

  public async abort(): Promise<void> {
    this._queue.forEach(t => { t.status = undefined })
    this._queue.splice(0)
    this._cancel()
    await this._waitCurrentExecutions()
  }

  public async whenIdle(): Promise<void> {
    while (this._isRunning) {
      await this._waitCurrentExecutions()
      await _yield()
    }
  }

  public suspend(): void {
    if (this._pauseLock.set(true)) {
      console.log(`${this._name}: suspended`)
    }
  }

  public resume(): void {
    if (this._pauseLock.set(false)) {
      console.log(`${this._name}: resumed`)
    }
  }

  public get isIdle(): boolean {
    return !this._isRunning
  }

  public get pendingTaskCount(): number {
    return this._queue.length
  }

  public get runningTaskCount(): number {
    return this._slots.reduce((sum, s) => sum + (s ? 1 : 0), 0)
  }

  public get totalTaskCount(): number {
    return this.pendingTaskCount + this.runningTaskCount
  }

  public dispose(): void {
    this._isDisposed = true
  }
}

