import { IAuditInquiryAnswer } from '../../../../../model/audit'

export function onlyUnique<T>(value: T, index: number, self: T[]): boolean {
  return self.indexOf(value) === index
}

/** deprecated */
export function getIsaAnswerType(answer: IAuditInquiryAnswer): boolean | null {
  // const text = answer.answerText
  // if (text === 'Да') {
  //   return true
  // } else if (text === 'Нет') {
  //   return false
  // } else {
  //   return null
  // }
  return null
}
