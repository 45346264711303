import React from 'react'

import { Button, createStyles, makeStyles, Theme } from '@material-ui/core'
import { Fullscreen } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    resetButton: {
      width: '32px',
      height: '32px',
      minWidth: '32px',
      padding: '0',
      borderRadius: '8px',
      background: 'rgba(255, 255, 255, 0.5)',
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.5)',
      },
      '& svg path': {
        fill: '#546066',
      },
    },
  }),
)

interface IResetButtonProps {
  onClick: () => void
}

const ResetButton: React.FC<IResetButtonProps> = ({ onClick }) => {
  const classes = useStyles()
  return (
    <Button className={classes.resetButton} onClick={onClick}>
      <Fullscreen fill='#546066' />
    </Button>
  )
}

export default ResetButton
