import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import { Box, CircularProgress, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'

import { ICompositeScreenItemLayout, JustifyFlexLayoutContent } from '../../../../model/composite-screen-item-layout'
import { IActionScreenItem } from '../../../../model/screen-item-base'
import { formatTemplateString } from '../../../../utils/format-template-string'
import { useIsSmall } from '../../../_common/hooks/useIsSmall'
import { useRichTextImg } from '../../../_common/hooks/useRichTextImg'
import { getContextProperty } from '../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../script-tasks/script-task-context'
import { ItemCard } from './item-card'

export interface IViewRichTextScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.ViewRichTextScreenItem'
  richTextContent: string
  /** @defaultValue false */
  useDefaultPadding: boolean
  /** @defaultValue 0 */
  borderRadius: number
  mobileLayout: ICompositeScreenItemLayout
  desktopLayout: ICompositeScreenItemLayout
  redirectPath?: string
}

interface ViewRichTextScreenItemProps {
  value: string
  item: IViewRichTextScreenItem
  loading: boolean
  children?: never
}

interface IStylesProps {
  itemWidth?: number
  borderRadius?: number
  useDefaultPadding?: boolean
  hasTitle?: boolean
}
const useStyles = makeStyles<Theme, IStylesProps>((theme) =>
  createStyles({
    root: {
      '& .MuiAccordion-root > .MuiAccordionDetails-root > div:nth-child(1)': {
        padding: ({ hasTitle, useDefaultPadding }) => (!hasTitle && !useDefaultPadding ? 0 : null),
      },
      '& .MuiAccordion-root > .MuiAccordionDetails-root > div:nth-child(2)': {
        padding: ({ useDefaultPadding }) => (useDefaultPadding ? null : 0),
      },
    },
    itemBlock: {
      width: ({ itemWidth }) => `${itemWidth ?? 100}%`,
      '& > .MuiPaper-root': {
        borderRadius: ({ borderRadius }) => `${borderRadius}px`,
        overflow: 'hidden',
      },
    },
    loader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    hasRoutePath: {
      cursor: 'pointer',
    },
  }),
)

const ViewRichTextScreenItem: React.FC<ViewRichTextScreenItemProps> = (props) => {
  const classes = useStyles({})
  const { item, value, loading } = props
  useEffect(() => {
    console.log('ViewRichTextScreenItem resolved value', value)
  }, [])

  return (
    <ItemCard label={item.displayName && !loading && <Typography variant='inherit'>{item.displayName}</Typography>}>
      {loading ? (
        <Box className={classes.loader}>
          <CircularProgress />
        </Box>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: value }}></div>
      )}
    </ItemCard>
  )
}

interface ViewRichTextScreenItemControlProps {
  item: IViewRichTextScreenItem
  children?: never
}

export const ViewRichTextScreenItemControl: React.FC<ViewRichTextScreenItemControlProps> = (props) => {
  useEffect(() => {
    console.log('ViewRichTextScreenItemControl mount', props)
  }, [])

  const navigate = useNavigate()
  const location = useLocation()
  const { item } = props
  const context = useScriptTaskContext()
  const val = formatTemplateString(item.richTextContent, (prop) => getContextProperty(context, prop, ''))
  const { isLoading, value } = useRichTextImg(val)
  const redirectPath = formatTemplateString(item.redirectPath ?? '', (prop) => getContextProperty(context, prop))
  const onClickItem = useCallback(() => {
    if (redirectPath && redirectPath !== '') {
      navigate(`${redirectPath}`, { state: { returnUrl: location.pathname } })
    }
  }, [redirectPath])

  const { desktopLayout, mobileLayout } = item
  const isSmall = useIsSmall()
  const { width, horizontalAlignment } = useMemo(() => (isSmall ? mobileLayout ?? {} : desktopLayout ?? {}), [isSmall])

  const classes = useStyles({
    hasTitle: !!props.item.displayName,
    borderRadius: props.item.borderRadius,
    useDefaultPadding: props.item.useDefaultPadding,
    itemWidth: width,
  })

  return (
    <RichtextItemWrapper>
      <div
        style={{
          display: 'flex',
          justifyContent: `${JustifyFlexLayoutContent[horizontalAlignment]}`,
        }}
        className={classNames(classes.root, {
          [classes.hasRoutePath]: redirectPath !== '',
        })}
      >
        <div onClick={onClickItem} className={classes.itemBlock}>
          <ViewRichTextScreenItem item={item} value={value} loading={isLoading} />
        </div>
      </div>
    </RichtextItemWrapper>
  )
}

interface RichtextItemWrapperProps {
  children: React.ReactNode
}
export const RichtextItemWrapper: React.FC<RichtextItemWrapperProps> = ({ children }) => {
  const refContainer = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const updateFontSize = (): void => {
      if (refContainer?.current) {
        refContainer.current.style.fontSize = `${refContainer?.current.clientWidth / 100}px`
      }
    }
    updateFontSize()
    window.addEventListener('resize', updateFontSize)
    return () => window.removeEventListener('resize', updateFontSize)
  }, [])

  return <div ref={refContainer}>{children}</div>
}
