import React from 'react'

import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'

import { BlobStorageError } from '../../../infrastructure/blob-storage/blob-storage-api'
import { DocumentThumbnailItem } from '../../documents/components/document-thumbnail-item/document-thumbnail-item'
import { PhotoInput } from '../../documents/components/photo-input/photo-input'
import { ITempFileItem, usePermissionsAddPhotos } from '../../documents/utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    documentList: {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: '-8px',
      [theme.breakpoints.down('xs')]: {
        marginLeft: '-5px',
      },
    },
  }),
)

interface Props {
  isReadOnly?: boolean
  photos: ITempFileItem[]
  onChangeInput: (file: Blob | undefined) => void
  checkedList?: string[]
  isSelectMode?: boolean
  onClickItem: (item: ITempFileItem, isChecked: boolean) => void
  onLongPressItem?: (evt: MouseEvent | TouchEvent, item: ITempFileItem) => void
  onDownloadError: React.Dispatch<React.SetStateAction<BlobStorageError | undefined>>
}

export const PhotoQuestionEditor: React.FC<Props> = ({
  isReadOnly,
  photos,
  onChangeInput,
  checkedList = [],
  isSelectMode,
  onLongPressItem,
  onClickItem,
  onDownloadError,
}) => {
  const classes = useStyles()

  const permissionsAddPhoto = usePermissionsAddPhotos()
  // const permissionsAddPhoto = false

  const renderWrapperInput = (): JSX.Element => {
    if (!permissionsAddPhoto && !photos.length) {
      return <Typography>Добавление фотографий возможно только с мобильного устройства</Typography>
    } else {
      return (
        <PhotoInput
          onChange={onChangeInput}
          showCameraOnOpen={false}
          permissionsAddPhoto={permissionsAddPhoto}
          allowAddPhoto={true}
          isContrast={true}
        />
      )
    }
  }

  return (
    <div className={classes.documentList}>
      {!isReadOnly && renderWrapperInput()}
      {photos?.map((document) => {
        const isChecked = !!checkedList.find((id) => id === document.metadata.key)
        return (
          <DocumentThumbnailItem
            key={document.metadata.key}
            isSelectMode={!!isSelectMode}
            isChecked={isChecked}
            item={document}
            onClickItem={() => {
              onClickItem(document, isChecked)
            }}
            onLongPress={(evt) => {
              onLongPressItem?.(evt, document)
            }}
            onDownloadError={onDownloadError}
          />
        )
      })}
    </div>
  )
}
