import React, { useContext, useEffect } from 'react'

import { ErrorBoundary } from 'react-error-boundary'
import { generatePath, Route, Routes, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useAsync, useCounter } from 'react-use'

import { Code } from '../../../model/base'
import { ApiContext } from '../../../providers'
import { useAsyncError } from '../../_common/hooks/useAsyncError'
import { useFeatureRoute } from '../../custom-app-feature/app-feature-context'
import { ProcessContextProvider } from '../../process/process-context'
import { visitsRoutes } from '../../visits/visits-app-feature/visits-app-feature'
import { BusinessErrorBoundary } from './business-error-boundary'
import { NestedProcessPage } from './nested-process'
import NonVisitTaskDialog from './non-visit-task-dialog'

interface TaskPageProps {
  taskCode: Code
}

export const TaskPage: React.FC = () => {
  const params = useParams() as unknown as TaskPageProps
  const location = useLocation()
  const navigate = useNavigate()
  const featureRoute = useFeatureRoute()
  const api = useContext(ApiContext)
  const [searchParams] = useSearchParams()
  const autostart = searchParams.get('autostart') === 'true'
  const [counter, { inc }] = useCounter()

  useEffect(() => {
    console.log('TaskPage mount', params, 'autostart', autostart)
  }, [])

  const autostartOps = useAsync(async () => {
    if (autostart) {
      const task = await api.tasks.getTask(params.taskCode)
      if (task?.status === 'Planned') {
        await api.tasks.setTaskStatus({ taskCode: params.taskCode, taskStatus: 'InProgress' })
        // remount context to update from the outside
        inc()
      }
    }
  }, [])

  useAsyncError(autostartOps.error)
  if (autostartOps.loading) return <></>

  const goBack = (): void => {
    if (location.state && typeof location.state === 'object') {
      const state = location.state as { returnUrl: unknown } // TODO: remove this cast in typescript 4.9
      if (typeof state.returnUrl === 'string') {
        return navigate(state.returnUrl)
      }
    }
    return navigate(generatePath(visitsRoutes.root, { featureRoute }))
  }
  const toUpperLevel = (): void => navigate('')

  return (
    <ErrorBoundary FallbackComponent={BusinessErrorBoundary} onReset={goBack}>
      <ProcessContextProvider key={counter} {...params}>
        <Routes>
          <Route path='' element={<NonVisitTaskDialog onBack={goBack} />} />
          <Route path={`process/:processCode/:recordCode/*`} element={<NestedProcessPage onBack={toUpperLevel} />} />
        </Routes>
      </ProcessContextProvider>
    </ErrorBoundary>
  )
}
