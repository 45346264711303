import { startOfDay } from 'date-fns'

import {
  POSStore,
  QuestionnaireStore,
  SurveyStore,
  VisitStore,
  TaskStore,
  TaskStore_visit,
  SurveyStore_visit,
  SurveyStore_task, TaskExecutionStateStore, TaskExecutionScopeStore, TaskTemplateStore,
} from '../../data/schema'
import IAuthService from '../../infrastructure/auth/auth-service-api'
import { IStorageOperations, IStorageService } from '../../infrastructure/storage-service'
import { IUserProfileService } from '../../infrastructure/user-profile'
import {
  Code, CodeSpace, createReferenceToEntity,
  findActiveVersion,
  getEntityKey,
  IEntityReference,
  isReferenceToEntityIgnoreVersion
} from '../../model/base'
import { IBusinessParameters } from '../../model/business-parameters'
import { FieldForceTaskCancelationReason } from '../../model/dictionary-item'
import { BusinessError, BusinessErrorCode } from '../../model/errors'
import { IPointOfSale } from '../../model/pos'
import { IQuestionnaire } from '../../model/questionnaire'
import { ISurvey, SurveyStatus } from '../../model/survey'
import { ITask } from '../../model/task'
import { ITaskTemplate } from '../../model/task-template'
import { IGenericUserReference, IPositionRoleReference } from '../../model/user'
import { IPosition, IProfile } from '../../model/user-profile'
import { IVisit } from '../../model/visit'
import { isInVisitTask, IVisitTask, VisitTaskStatus } from '../../model/visit-task'
import { dateFormatIso } from '../../utils'

export abstract class LocalStorageBaseService {
  protected readonly _auth: IAuthService
  protected readonly _storage: IStorageService
  protected readonly _profile: IUserProfileService

  constructor(
    auth: IAuthService, 
    profile: IUserProfileService, 
    storage: IStorageService,
    protected readonly defaultCodeSpace: CodeSpace
  ) {
    this._auth = auth
    this._profile = profile
    this._storage = storage
  }

  /** возвращает текущий профиль (или undefined) */
  protected async _getCurrentProfile(): Promise<IProfile | undefined> {
    return (await this._profile.getCurrentProfile())?.profile
  }

  /** возвращает ссылку (GenericUserReference) на текущего пользователя (или undefined) */
  protected async _getCurrentUserReference(): Promise<IGenericUserReference | undefined> {
    const profile = await this._profile.getCurrentProfile()
    return profile?.employee?.account
  }

  /** возвращает ссылку (PositionRoleReference) на полевую роль текущего профиля пользователя (или undefined) */
  protected async _getCurrentRoleReference(): Promise<IPositionRoleReference | undefined> {
    return this._profile.getCurrentFieldPositionRoleReference()
  }

  /** возвращает должность в текущем профиле пользователя (или undefined) */
  protected async _getCurrentProfilePosition(): Promise<IPosition | undefined> {
    return (await this._profile.getCurrentProfile())?.position
  }

  /** возвращает задачи визита */
  protected async _getVisitTasks(visitCode: Code, tx: IStorageOperations): Promise<IVisitTask[]> {
    return await tx.getByIndexRange<IVisitTask>(TaskStore, TaskStore_visit, ['=', visitCode])
  }

  /** возвращает опросник по коду (или null) */
  protected async _getQuestionnaire(
    questionnaireCode: Code,
    storage: IStorageOperations,
  ): Promise<IQuestionnaire | null> {
    return (await storage.getByKey<IQuestionnaire>(QuestionnaireStore, questionnaireCode)) ?? null
  }

  /** возвращает опросник по коду (или выбрасывает ошибку) */
  protected async _getQuestionnaireOrThrow(questionnaireCode: Code, tx: IStorageOperations): Promise<IQuestionnaire> {
    const questionnaire = await this._getQuestionnaire(questionnaireCode, tx)
    if (questionnaire == null) {
      throw new BusinessError(
        BusinessErrorCode.EntityNotFound,
        `Questionnaire ${questionnaireCode} not found`,
        {type: 'questionnaire', code: questionnaireCode}
      )
    }
    return questionnaire
  }

  /** возвращает опрос по коду (или null) */
  protected async _getSurvey(surveyCode: Code, tx: IStorageOperations): Promise<ISurvey | null> {
    return (await tx.getByKey<ISurvey>(SurveyStore, surveyCode)) ?? null
  }

  /** возвращает опрос по коду (или выбрасывает ошибку) */
  protected async _getSurveyOrThrow(surveyCode: Code, tx: IStorageOperations): Promise<ISurvey> {
    const survey = await this._getSurvey(surveyCode, tx)
    if (!survey) {
      throw new BusinessError(
        BusinessErrorCode.EntityNotFound, `Survey ${surveyCode} not found`, {type: 'survey', code: surveyCode}
      )
    }
    return survey
  }

  protected async _getTaskVersionedTemplateRef(ref: IEntityReference, tx: IStorageOperations): Promise<IEntityReference | null> {
    if (ref.version) {
      const key = getEntityKey(ref)
      const template = await tx.getByKey<ITaskTemplate>(TaskTemplateStore, key)
      if (!template) return null

      return createReferenceToEntity(template, this.defaultCodeSpace)
    }
    const templatesByCode = await tx.getWhere<ITaskTemplate>(
      TaskTemplateStore, t => isReferenceToEntityIgnoreVersion(ref, t)
    )
    const template = findActiveVersion(templatesByCode) ?? null
    if (!template) return null

    return createReferenceToEntity(template, this.defaultCodeSpace)
  }

  /** возвращает задачу по коду (или null) */
  protected async _getTask(taskCode: Code, tx: IStorageOperations): Promise<ITask | null> {
    return (await tx.getByKey<ITask>(TaskStore, taskCode)) ?? null
  }

  /** возвращает задачу по коду (или выбрасывает ошибку) */
  protected async _getTaskOrThrow(taskCode: Code, tx: IStorageOperations): Promise<ITask> {
    const task = await this._getTask(taskCode, tx)
    if (!task) {
      throw new BusinessError(
        BusinessErrorCode.EntityNotFound, `Task ${taskCode} not found`, {type: 'task', code: taskCode}
      )
    }
    return task
  }

  /** возвращает ТорговуюТочку по коду (или null) */
  protected async _getPos(posCode: Code, tx: IStorageOperations): Promise<IPointOfSale | null> {
    return (await tx.getByKey(POSStore, posCode)) ?? null
  }

  /** возвращает ТорговуюТочку по коду (или выбрасывает ошибку) */
  protected async _getPosOrThrow(posCode: Code, tx: IStorageOperations): Promise<IPointOfSale> {
    const pos = await this._getPos(posCode, tx)
    if (!pos) {
      throw new BusinessError(
        BusinessErrorCode.EntityNotFound, `POS ${posCode} not found`, {type: 'pos', code: posCode}
      )
    }
    return pos
  }

  /** возвращает визит по коду (или null) */
  protected async _getVisit(visitCode: Code, tx: IStorageOperations): Promise<IVisit | null> {
    return (await tx.getByKey<IVisit>(VisitStore, visitCode)) ?? null
  }

  /** возвращает визит по коду (или выбрасывает ошибку) */
  protected async _getVisitOrThrow(visitCode: Code, tx: IStorageOperations): Promise<IVisit> {
    const visit = await this._getVisit(visitCode, tx)
    if (!visit) {
      throw new BusinessError(
        BusinessErrorCode.EntityNotFound, `Visit ${visitCode} not found`, {type: 'visit', code: visitCode}
      )
    }
    return visit
  }

  /** обновляет время изменения визита (если нужно) */
  protected async _updateVisitChangeTime(visitCode: Code | null | undefined, tx: IStorageOperations): Promise<void> {
    if (visitCode) {
      const visit = await this._getVisit(visitCode, tx)
      if (visit) {
        visit._changeTime = Date.now()
        await tx.put(VisitStore, visit)
      }
    }
  }

  /** обновляет время изменения задачи (если нужно) */
  protected async _updateTaskChangeTime(taskCode: Code | null | undefined, tx: IStorageOperations): Promise<void> {
    if (taskCode) {
      const task = await this._getTask(taskCode, tx)
      if (task) {
        task._changeTime = Date.now()
        await tx.put(TaskStore, task)
      }
    }
  }

  /** Полностью удаляет визит со связанными задачами, опросами и контекстом задач */
  protected async _purgeVisit(visitCode: Code, tx: IStorageOperations): Promise<void> {
    console.debug(`Purging visit ${visitCode}`)
    const visitTaskKeys = await tx.getKeysByIndexRange(TaskStore, TaskStore_visit, ['=', visitCode])
    await Promise.all([
      tx.deleteByKeys(TaskStore, visitTaskKeys),
      tx.deleteByKeys(TaskExecutionStateStore, visitTaskKeys),
      tx.deleteByKeys(TaskExecutionScopeStore, visitTaskKeys),
      tx.deleteByKeys(SurveyStore, await tx.getKeysByIndexRange(SurveyStore, SurveyStore_visit, ['=', visitCode])),
      tx.deleteByKey(VisitStore, visitCode),
    ])
  }

  /** Полностью удаляет задачу со связанными опросами и контекстом */
  protected async _purgeTask(taskCode: Code, tx: IStorageOperations): Promise<void> {
    console.debug(`Purging task ${taskCode}`)
    await Promise.all([
      tx.deleteByKeys(SurveyStore, await tx.getKeysByIndexRange(SurveyStore, SurveyStore_task, ['=', taskCode])),
      tx.deleteByKey(TaskExecutionStateStore, taskCode),
      tx.deleteByKey(TaskExecutionScopeStore, taskCode),
      tx.deleteByKey(TaskStore, taskCode),
    ])
  }

  /** Возвращает опросы, связанные с задачей */
  protected async _getTaskSurveys(task: ITask, tx: IStorageOperations): Promise<ISurvey[]> {
    return await tx.getByIndexRange<ISurvey>(SurveyStore, SurveyStore_task, ['=', task.code])
  }

  /** Возвращает опросы, связанные с визитом */
  protected async _getVisitSurveys(visitCode: Code, tx: IStorageOperations): Promise<ISurvey[]> {
    return await tx.getByIndexRange<ISurvey>(SurveyStore, SurveyStore_visit, ['=', visitCode])
  }

  /** устанавливает статус задачи */
  protected _setTaskStatus(
    task: ITask,
    status: VisitTaskStatus,
    cancelationReason?: FieldForceTaskCancelationReason,
  ): boolean {
    if (task.status === status) {
      return false
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const faceTask = task as any
    if (status === 'Canceled') {
      faceTask.preCanceledStatus = task.status
      task.cancelationReason = cancelationReason
      // ??
      // <FieldForceTaskCancelationReason>{
      //   $type: 'PMI.BDDM.Transactionaldata.FieldForceTaskCancelationReason',
      //   code: 'VisitCancelled',
      //   name: 'В связи с отменой визита',
      // }
    } else {
      faceTask.preCanceledStatus = undefined
      task.cancelationReason = undefined
    }
    const now = Date.now()
    if (task.status === 'Planned' && status === 'InProgress') {
      task.startDate = now
      task.localStartDate = dateFormatIso(now)
    } else if (task.status === 'InProgress' && status === 'Finished') {
      task.endDate = now
      task.localEndDate = dateFormatIso(now)
    }
    task.status = status
    task.updateTime = task._changeTime = now
    return true
  }

  /** обновляет данные визитной задачи по визиту */
  protected _updateVisitTaskFromVisit(task: IVisitTask, visit: IVisit, pos: IPointOfSale | null): void {
    task.productLocation = visit.pointOfSaleCode
      ? {
          $type: 'PMI.BDDM.Staticdata.POSReference',
          code: visit.pointOfSaleCode,
          codeSpace: pos?.codeSpace,
          name: pos?.name,
        }
      : undefined
    if (task.status === 'Planned' && !!visit.plannedStartDate) {
      const visitPlannedDate = startOfDay(visit.plannedStartDate)
      task.startDate = visitPlannedDate.getTime()
      task.localStartDate = dateFormatIso(visitPlannedDate)
    }
    task.updateTime = task._changeTime = Date.now()
  }

  /** устанавливает статус опроса */
  protected _setSurveyStatus(survey: ISurvey, status: SurveyStatus): boolean {
    if (survey.status === status) {
      return false
    }
    if (status === 'Canceled') {
      survey.preCanceledStatus = survey.status
      survey.cancelationReason = undefined
      /*
      survey.cancelationReason = {
        $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceSurveyCancelationReason',
        code: 'CanceledByUser',
        name: 'Отменено пользователем'
      }
      */
    } else {
      survey.preCanceledStatus = undefined
      survey.cancelationReason = undefined
    }
    survey.status = status
    survey.updateTime = Date.now()
    return true
  }

  /** устанавливает ссылку на отчет по задаче */
  protected _setTaskReportLink(task: ITask, reportLink: string): boolean {
    if (task.reportLink === reportLink) {
      return false
    }
    if (reportLink) {
      if (isInVisitTask(task)) {
        task.printedForm = {
          parts: [
            {
              type: 'PDF',
              $type: 'PMI.BDDM.Common.MimeContentRef',
              target: `${task.visitCode}-${task.code}-report.pdf`,
            },
          ],
        }
      } else {
        task.printedForm = {
          parts: [
            {
              type: 'PDF',
              $type: 'PMI.BDDM.Common.MimeContentRef',
              target: `X-${task.code}-report.pdf`,
            },
          ],
        }
      }
    }

    task.reportLink = reportLink
    task._changeTime = Date.now()
    return true
  }

  /** возвращает настройки профиля */
  protected async _getBusinessParameters(): Promise<IBusinessParameters | undefined> {
    return (await this._profile.getBusinessParameters())
  }
}
