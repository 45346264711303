/* eslint-disable @typescript-eslint/naming-convention */
import { IStorageSchema } from '../storage-service'
import { BlobMetadata, BlobSyncStatus } from './blob-storage-api'

export const BlobStore = 'blob'

export const BlobMetaStore = 'blob-meta'
export const BlobMetaStore_timestamp = 'timestamp'
export const BlobMetaStore_syncStatus = 'syncStatus'
export const BlobMetaStore_dateTime = 'dateTime'
export const BlobMetaStore_syncTime = 'syncTime'

export const BlobReceiveQueueStore = 'blob-receive-queue'
export const BlobReceiveQueueStore_timestamp = 'timestamp'

const schema: IStorageSchema = {
  version: 6,
  stores: {
    [BlobStore]: {
      keyPath: 'key'
    },
    [BlobMetaStore]: {
      keyPath: 'key',
      index: {
        [BlobMetaStore_timestamp]: {
          keyPath: 'timestamp',
          unique: false
        },
        [BlobMetaStore_syncStatus]: {
          keyPath: 'syncStatus',
          unique: false
        },
        [BlobMetaStore_dateTime]: {
          keyPath: 'dateTime',
          unique: false
        },
        [BlobMetaStore_syncTime]: {
          keyPath: 'syncTime',
          unique: false
        }
      }
    },
    [BlobReceiveQueueStore]: {
      keyPath: 'key',
      index: {
        [BlobReceiveQueueStore_timestamp]: {
          keyPath: 'timestamp',
          unique: false
        },
      }
    }
  },
  migrate: async (oldVersion, newVersion, _, db, tx, logger) => {
    if (oldVersion < 5 && newVersion >= 5) {
       await convertBlobMetadataStatus()
    }

    async function convertBlobMetadataStatus(): Promise<void> {
      let metas: BlobMetadata[]
      try {
        metas = await tx.objectStore(BlobMetaStore).getAll()
      } catch (err) {
        /* ignore */
        return
      }
      logger.info('convertBlobMetadataStatus', `Converting status of ${metas.length} blobs`)
      for (let i = 0; i < metas.length; i++) {
        const blobMeta = metas[i]
        switch (blobMeta.syncStatus as unknown) {
          case 0:
            blobMeta.syncStatus = BlobSyncStatus.None
            break
          case 1:
            blobMeta.syncStatus = BlobSyncStatus.Pending
            break
          case 2:
            blobMeta.syncStatus = BlobSyncStatus.Sent
            break
          case 3:
            blobMeta.syncStatus = BlobSyncStatus.Received
            break
        }
        await tx.objectStore(BlobMetaStore).put(blobMeta)
      }
    }
  }
}

export default schema
    