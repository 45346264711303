import React, { useState } from 'react'

import { Box, createStyles, IconButton, Menu, MenuItem, Paper, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import MoreVert from '@material-ui/icons/MoreVert'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { grays } from '../../../../../layout/theme'
import { Code } from '../../../../../model/base'
import { SurveyStatus } from '../../../../../model/survey'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      display: 'flex',
      alignItems: 'center',
      // justifyContent: 'space-between',
      flexWrap: 'wrap',
      padding: theme.spacing(0),
      cursor: 'pointer',
      height: theme.spacing(7)
    },
    statusIndicator: {
      height: 28,
      background: theme.palette.info.main,
      width: 3,
      borderRadius: '100px',
      margin: theme.spacing(1.75, 2, 1.75, 0)
    },
    Open: {
      backgroundColor: theme.palette.info.main
    },
    InProgress: {
      backgroundColor: theme.palette.warning.main
    },
    Finished: {
      backgroundColor: theme.palette.success.main
    },
    Canceled: {
      backgroundColor: grays.gray3
    },
    isCanceledStatus: {
      // background: 'rgb(237,237,237)',
      // textDecoration: 'line-through',
      color: grays.gray4
      // textDecorationColor: theme.palette.grey['500']
    },
    nameWrap: {
      display: 'flex',
      alignItems: 'center'
    },
    name: {
      fontSize: 16
    },
    statusText: {
      fontWeight: 500,
      fontSize: 10,
      textTransform: 'uppercase',
      marginLeft: 'auto',
      letterSpacing: '1.5px'
    }
  })
)

interface IContactListItemProps {
  code: string
  name: string
  status: SurveyStatus
  onItemClick: (code: Code) => void
  onItemRemove?: (code: Code) => void
  onItemRepair?: (code: Code) => void
}

const ContactListItem: React.FC<IContactListItemProps> = ({
  status,
  code,
  name,
  onItemClick,
  onItemRemove,
  onItemRepair
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>()

  const { t } = useTranslation('sales-expert-tasks')

  const isMenuOpen = Boolean(anchorEl)

  const showRemoveButton = (status === 'InProgress' || status === 'Open') && !!onItemRemove
  const showRepairButton = status === 'Canceled' && !!onItemRepair
  const hasMenu = showRemoveButton || showRepairButton

  const closeMenu = (): void => setAnchorEl(undefined)

  return (
    <Paper
      onClick={() => status !== 'Canceled' && onItemClick(code)}
      elevation={0}
      square
      className={classNames(classes.listItem, {
        [classes.isCanceledStatus]: status === 'Canceled'
      })}
    >
      <div className={classNames(classes.statusIndicator, classes[status])}></div>
      <div className={classes.nameWrap}>
        {/* <ContactStatusIcon status={status} /> */}
        <Typography className={classes.name} color='inherit'>
          {name}
        </Typography>
      </div>
      {hasMenu && (
        <Box ml='auto' onClick={(ev) => ev.stopPropagation()}>
          <IconButton
            size='small'
            id='demo-positioned-button'
            aria-controls={isMenuOpen ? 'demo-positioned-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={isMenuOpen ? 'true' : undefined}
            onClick={(ev) => {
              setAnchorEl(ev.currentTarget)
            }}
          >
            <MoreVert />
          </IconButton>
          <Menu
            open={!!isMenuOpen}
            anchorEl={anchorEl}
            onClose={closeMenu}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 0,
              horizontal: 'left'
            }}
          >
            {showRepairButton && (
              <MenuItem
                onClick={() => {
                  onItemRepair?.(code)
                  closeMenu()
                }}
              >
                {t('contactRestoreAction')}
              </MenuItem>
            )}
            {showRemoveButton && (
              <MenuItem
                onClick={() => {
                  onItemRemove?.(code)
                  closeMenu()
                }}
              >
                {t('contactCancelAction')}
              </MenuItem>
            )}
          </Menu>
        </Box>
      )}
    </Paper>
  )
}

export default React.memo(ContactListItem)
