import React, { useState } from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  IconButton,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import { grays } from '../../../../layout/theme'

interface Props {
  label?: React.ReactNode
  isError?: boolean
  noContentPadding?: boolean
  isCollapsible?: boolean
}

const useStyles = makeStyles<Theme, { noContentPadding?: boolean; isCollapsible?: boolean }>((theme) =>
  createStyles({
    root: {
      minHeight: 56,
      border: `1px solid ${grays.gray5}`,
      marginBottom: 8,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 8,
      },
    },
    labelWrap: {
      width: '100%',
      fontSize: 18,
      lineHeight: '23px',
      fontWeight: 600,
      letterSpacing: '0.15px',
      position: 'relative',
      padding: '16px 24px',
      [theme.breakpoints.down('xs')]: {
        padding: '14px 16px',
        fontSize: 16,
      },
      borderBottom: `1px solid ${grays.gray5}`,
      marginBottom: -1,
    },
    labelContent: {
      display: 'grid',
      gridTemplateColumns: 'auto 100%',
    },
    content: {
      width: '100%',
      padding: (props) => (props.noContentPadding ? 0 : theme.spacing(2, 3)),
      [theme.breakpoints.down('xs')]: {
        padding: (props) => (props.noContentPadding ? 0 : theme.spacing(2)),
      },
    },
    errorMarker: {
      width: 2,
      position: 'absolute',
      left: -0.2,
      top: -0.2,
      bottom: -0.2,
      background: '#D32F2F',
    },
    accordionRoot: {
      padding: 0,
      margin: 0,
    },
    summaryRoot: {
      padding: 0,
      userSelect: 'inherit',
    },
    summaryContent: {
      width: '100%',
      cursor: (props) => (props.isCollapsible ? 'pointer' : 'auto !important'),
    },
    detailsRoot: {
      padding: 0,
    },
    chevron: {
      transform: 'rotate(-90deg)',
      transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      marginLeft: theme.spacing(-1.5),
      marginRight: theme.spacing(0.5),
      '.Mui-expanded &': {
        transform: 'rotate(0deg)',
      },
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
      },
    },
  }),
)

const ErrorMarker: React.FC = () => {
  const classes = useStyles({})
  return <div className={classes.errorMarker} />
}

export const ItemCard: React.FC<Props> = ({ children, label, isError, noContentPadding, isCollapsible }) => {
  const classes = useStyles({ noContentPadding, isCollapsible })
  const [open, setOpen] = useState(true)

  const switchAccordion = (): void => {
    if (isCollapsible) {
      setOpen((prev) => !prev)
    }
  }

  return (
    <Paper elevation={0} square className={classes.root}>
      <Accordion
        defaultExpanded={false}
        expanded={open}
        onChange={switchAccordion}
        elevation={0}
        square
        className={classes.accordionRoot}
      >
        {!!label && (
          <AccordionSummary classes={{ root: classes.summaryRoot, content: classes.summaryContent }}>
            <div className={classes.labelWrap}>
              {isError && <ErrorMarker />}
              <div className={classes.labelContent}>
                {isCollapsible ? (
                  <IconButton size='small' className={classes.chevron}>
                    <ArrowDropDownIcon />
                  </IconButton>
                ) : (
                  <div />
                )}
                {label}
              </div>
            </div>
          </AccordionSummary>
        )}
        <AccordionDetails className={classes.detailsRoot}>
          {!!React.Children.toArray(children).length && <div className={classes.content}>{children}</div>}
        </AccordionDetails>
      </Accordion>
    </Paper>
  )
}
