import { useContext } from 'react'

import { Token } from '../../infrastructure/di'
import { ServiceContext } from './service-context'

export function useService<T>(token: Token<T>): T {
  const container = useContext(ServiceContext)

  return container.get(token)
}
