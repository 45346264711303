/* eslint-disable  @typescript-eslint/no-explicit-any */
import {AxiosResponse} from 'axios'
import {
  addDays,
  addMonths,
  differenceInDays,
  endOfDay,
  isBefore,
  startOfDay,
  startOfYesterday
} from 'date-fns'
import _, {isFinite} from 'lodash'

import {
  BrandVariantStore,
  ContentDocumentStore,
  ContractTermAssignmentStore,
  ContractTermStore,
  DictionaryStore,
  DteParticipantProfileStore, FieldPositionRoleUserProfileStore,
  HyperlinkStore,
  HyperlinkStore_updateTime,
  ISAStore,
  ISAStore_pos,
  MetricStore,
  POSParticipantProgramStore,
  POSStore,
  POSTaskRegisterExecutionStore,
  POSTaskRegisterStore,
  PPOSMStore,
  ProblemStore,
  ProblemStore_changeTime,
  ProblemStore_updateTime,
  ProblemTemplateStore,
  ProblemTemplateStore_updateTime,
  ProductMatrixAssignmentStore,
  ProductMatrixStore, QuestionnairePOSAssignmentStore, QuestionnairePOSAssignmentStore_updateTime,
  QuestionnaireStore,
  QuestionnaireStore_updateTime,
  SMServiceStore,
  SMServiceStore_endDate,
  SupervisedFieldPositionRoleStore,
  SupervisedVisitStore,
  SurveyStore, TaskExecutionScopeStore, TaskExecutionStateStore,
  TaskReportStore,
  TaskStore, TaskStore_changeTime,
  TaskTemplateContentStore,
  TaskTemplateQuestionnaireAssignmentStore,
  TaskTemplateStore,
  VisitAssessmentStore,
  VisitStore, VisitStore_changeTime
} from '../../data/schema'
import IAuthService from '../../infrastructure/auth/auth-service-api'
import AppConfig from '../../infrastructure/config-service/app-config'
import globalEventBus from '../../infrastructure/global-event-bus'
import {
  EVENT_NEED_RECEIVE_BLOBS,
  EVENT_NEED_REMOVE_BLOBS,
  EVENT_NEED_SEND_BLOBS
} from '../../infrastructure/global-events'
import {IHttpClient, IHttpClientFactory} from '../../infrastructure/http-client-factory'
import {LogManager} from '../../infrastructure/logger'
import {IStorageOperations, IStorageService} from '../../infrastructure/storage-service'
import {IUserProfileService} from '../../infrastructure/user-profile'
import {IAudit} from '../../model/audit'
import {
  Code,
  EntityKey,
  getEntityKey,
  IEntity,
  IEntityReference,
  isVersionActive, ISyncable,
  ISyncEntity,
  SyncErrorCode
} from '../../model/base'
import {IBrandVariant} from '../../model/brand-variant'
import {IBusinessParameters} from '../../model/business-parameters'
import {DateTime, IDateTimeInterval} from '../../model/common'
import {IContentDocument} from '../../model/content-document'
import {IContractTerm} from '../../model/contract-term'
import {IContractTermAssignment} from '../../model/contract-term-assignment'
import {IDictionary} from '../../model/dictionary'
import {IDteParticipantProfile} from '../../model/dte-participant-profile'
import {IPOSParticipantProgram} from "../../model/dte-pos-participant-program";
import {IHyperlinkDescription} from '../../model/hyperlink'
import {IMetric} from "../../model/metric"
import {IPointOfSale, isPOSInCoverage, setPOSIsInCoverage} from '../../model/pos'
import {IPOSTaskRegister, IPOSTaskRegisterExecution} from '../../model/pos-task-register'
import {IPPOSM} from "../../model/pposm";
import {IProblem} from "../../model/problem";
import {IProblemTemplate} from '../../model/problem-template'
import {IProductMatrix} from '../../model/product-matrix'
import {IProductMatrixAssignment} from '../../model/product-matrix-assignment'
import {IQuestionnaire} from '../../model/questionnaire'
import {IQuestionnairePOSAssignment} from '../../model/questionnaire-pos-assignment'
import {IStoreManagerService} from '../../model/sm-service'
import {ISupervisedFieldPositionRole} from "../../model/supervised-field-position-role";
import {ISupervisedVisitUnit} from '../../model/supervised-visit-unit'
import {ITask} from '../../model/task'
import {ITaskReport} from '../../model/task-report'
import {ITaskTemplate} from '../../model/task-template'
import {ITaskTemplateContent} from '../../model/task-template-content'
import {ITaskTemplateQuestionnaireAssignment} from '../../model/task-template-questionnaire-assignment'
import {ITaskUnit} from "../../model/task-unit";
import {IGenericUserReference, IPositionRoleReference} from "../../model/user"
import {IProfile, IUserProfile, IUserProfileReference} from '../../model/user-profile'
import {IVisit} from '../../model/visit'
import {IVisitAssessment} from "../../model/visit-assessment";
import {isInVisitTask, IVisitTask} from '../../model/visit-task'
import {IVisitTaskReport} from '../../model/visit-task-report'
import {IVisitUnit} from "../../model/visit-unit";
import {ICancellationToken, OperationCancelledException, throwIfCancellationRequested} from '../../utils/cancellation'
import {getMediaDeleteTime, IMediaReceiveSetting} from '../../utils/media'
import {
  checkLoadStartDate, filterEntityList,
  filterProblemsList,
  scanMediaRefs,
  scanMediaRefsTaskSpecialHandling
} from '../../utils/media-scan'
import {maxOfAsync} from '../../utils/min-max'
import {
  IPendingItems,
  IPendingItemsSummary,
  ISyncService,
  OperationProgressCallback,
  SyncError,
  SyncErrorSource, SyncMode
} from '../sync-service-api'
import IVisitService from '../visit-service-api'
import {LocalStorageBaseService} from './local-storage-base-service'

const VISITS_BATCH_SIZE = 100
const SUPERVISED_VISITS_BATCH_SIZE = 100

interface ISyncDeltaRequest {
  profile?: IUserProfileReference
  positionRole?: IPositionRoleReference
  stock: {
    [key: EntityKey | Code]: DateTime
  }
  timestamp: DateTime
  dateTimeInterval?: IDateTimeInterval
}

interface ISyncDeltaResponse<T> {
  update: T[]
  remove: Code[]
  timestamp: DateTime
}

interface IVisitUnitDto extends IVisitUnit {
  changeTime: DateTime
}

interface ISupervisedVisitUnitDto extends ISupervisedVisitUnit {
  changeTime: DateTime
}

interface ITaskUnitDto extends ITaskUnit {
  changeTime: DateTime
}

interface IDelta {
  added: Code[]
  updated: Code[]
  removed: Code[]
}

class Delta implements IDelta {
  added: Code[]
  updated: Code[]
  removed: Code[]

  constructor(keys: Code[], addedOrUpdated: Code[], removed: Code[]) {
    this.added = addedOrUpdated.filter(k => !keys.includes(k))
    this.updated = addedOrUpdated.filter(k => keys.includes(k))
    this.removed = removed.filter(k => keys.includes(k))
  }
}

interface IPOSTaskRegisterFilterDto {
  posCodes?: string[]
  taskTemplates?: IEntityReference[]
  startDate?: DateTime
  endDate?: DateTime
  updatedAfter?: DateTime
  onlyActive?: boolean
}

interface IContentDocumentFilterDto {
  codes: string[]
  updatedAfter?: DateTime
}

interface IProductMatrixAssignmentFilterDto {
  posCodes?: string[]
  startDate?: DateTime
  endDate?: DateTime
  updatedAfter?: DateTime
  onlyActive?: boolean
}

interface IProductMatrixDiffQueryDto {
  stock: {
    [productMatrixCode: string]: DateTime | null
  }
}

interface IProductMatrixDiffDto {
  updates: {
    [productMatrixCode: string]: IProductMatrix | null
  }
}

interface IBrandVariantFilterDto {
  updatedAfter?: DateTime
  pageNumber: number
  pageSize: number
}

interface IBrandVariantsDto {
  items: IBrandVariant[]
  pageNumber: number
  pageSize: number
  pageCount: number
  totalItemCount: number
}

interface IContractTermAssignmentFilterDto {
  posCodes?: string[]
  updatedAfter?: DateTime
  onlyActive?: boolean
}

interface IContractTermDiffQueryDto {
  stock: {
    [contractTermCode: string]: DateTime | null
  }
}

interface IContractTermDiffDto {
  updates: {
    [contractTermCode: string]: IContractTerm | null
  }
}

type SyncStep = [string, string, (ctx: SyncContext) => Promise<unknown>]

class SyncContext {
  readonly currentProfile?: IProfile
  readonly currentProfileReference?: IUserProfileReference
  readonly currentProfileKey?: string
  readonly currentRoleReference?: IPositionRoleReference
  readonly currentRoleKey?: string
  readonly currentUserReference?: IGenericUserReference
  readonly businessParameters?: IBusinessParameters

  readonly cancellation?: ICancellationToken

  readonly getHttpClient: () => IHttpClient

  readonly mediaToSend = new Set<string>()

  readonly mediaToDelete = new Set<string>()
  readonly mediaToReceive = new Map<string, IMediaReceiveSetting>()

  constructor(
    getHttpClient: () => IHttpClient,
    currentProfile?: IProfile,
    currentRoleReference?: IPositionRoleReference,
    currentUserReference?: IGenericUserReference,
    businessParameters?: IBusinessParameters,
    cancellation?: ICancellationToken
  ) {
    this.getHttpClient = getHttpClient
    this.cancellation = cancellation
    this.currentUserReference = currentUserReference
    this.currentProfile = currentProfile
    this.currentProfileReference = currentProfile ? { code: currentProfile.code, name: currentProfile.name } : undefined
    if (this.currentProfileReference) {
      this.currentProfileKey = encodeURIComponent(getEntityKey(this.currentProfileReference))
    }
    this.currentRoleReference = currentRoleReference
    if (this.currentRoleReference) {
      this.currentRoleKey = encodeURIComponent(getEntityKey(this.currentRoleReference))
    }
    this.businessParameters = businessParameters
  }

  throwIfCancellationRequested(): void {
    throwIfCancellationRequested(this.cancellation)
  }

  currentStep?: string
}

export class SyncService extends LocalStorageBaseService implements ISyncService {
  private static readonly __className = 'SyncService'
  private readonly logger = LogManager.getLogger(SyncService.__className)
  private readonly _config: AppConfig
  private readonly _httpClientFactory: IHttpClientFactory
  private readonly _visitService: IVisitService
  private readonly _userProfileService: IUserProfileService
  private _lastSyncTime = 0

  constructor(
    config: AppConfig,
    authService: IAuthService,
    storageService: IStorageService,
    visitService: IVisitService,
    userProfileService: IUserProfileService,
    httpClientFactory: IHttpClientFactory
  ) {
    super(authService, userProfileService, storageService, config.defaultCodeSpace)
    this._config = config
    this._httpClientFactory = httpClientFactory
    this._userProfileService = userProfileService
    this._visitService = visitService
  }

  private static isFreshEntity(entity: ISyncable): boolean {
    return entity?._sync?.changeTime == null
  }

  private static isNewOrChangedEntity(entity: ISyncable): boolean {
    if (entity?._changeTime == null) {
      return false
    }
    if (entity._sync?.changeTime != null) {
      return entity._changeTime > entity._sync.changeTime
    }
    return true
  }

  private static commitReceivedEntity(entity: ISyncable, error?: any): void {
    const sync = entity._sync ?? (entity._sync = {})
    sync.lastLoadTime = Date.now()
    if (error) {
      sync.lastLoadError = error instanceof Error ? error.message : error.toString()
    } else {
      sync.changeTime = entity._changeTime ?? (entity as IEntity).updateTime
      delete sync.lastLoadError
    }
  }

  private static commitSavedEntity(entity: ISyncable, changeTime: DateTime): void {
    if (entity._sync == null) {
      entity._sync = {}
    } else {
      delete entity._sync.lastSaveError
      delete entity._sync.lastSaveErrorCode
    }
    entity._sync.changeTime = changeTime
  }

  private static commitArchivedEntity(entity: ISyncable): void {
    if (entity._sync == null) {
      entity._sync = {}
    }
    entity._sync.archiveTime = Date.now()
  }

  private static commitUnarchivedEntity(entity: ISyncable): void {
    if (entity._sync != null) {
      delete entity._sync.archiveTime
    }
  }

  private static syncError(source: SyncErrorSource, message: string, error?: any): SyncError {
    let code = SyncErrorCode.OtherError
    let businessErrorType, businessErrorTitle
    let httpStatus
    console.log(error);
    if (error?.response?.status > 0) {
      httpStatus = error.response.status
      if (httpStatus === 401) {
        code = SyncErrorCode.Unauthorized
      } else if (httpStatus === 403) {
        code = SyncErrorCode.Forbidden
      } else if (httpStatus === 400) {
        /*if (error.response.data?.type === 'UPGRADE_REQUIRED') {
          code = SyncErrorCode.UpgradeRequired
        } else*/
        if (error.response.data?.type === 'MAINTENANCE_IN_PROGRESS') {
          code = SyncErrorCode.Maintenance
        } else {
          code = SyncErrorCode.BusinessError
          businessErrorType = error.response.data?.type
          businessErrorTitle = error.response.data?.title
        }
      } else if (httpStatus === 503 && error.response.data?.type === 'MAINTENANCE_IN_PROGRESS') {
        code = SyncErrorCode.Maintenance
      } else {
        code = SyncErrorCode.ServerError
      }
    } else if (error?.code === 'ERR_NETWORK') {
      code = SyncErrorCode.NetworkError
    }
    return new SyncError(
      source, code, message, httpStatus,
      error instanceof Error
        ? {code: (error as any).code, name: error.name, message: error.message}
        : error,
      businessErrorType, businessErrorTitle
    )
  }

  private async _getUserProfileTaskTemplates(profile: IUserProfile | null, now: Date): Promise<ITaskTemplate[]> {
    return await this._storage.getWhere<ITaskTemplate>(TaskTemplateStore,
      tmpl => (
        isVersionActive(tmpl.version, now) &&
        !!tmpl.relatedProfiles?.some(rel => (
          rel.profile?.code === profile?.profile.code &&
          (rel.relationKind === 'Assign' || rel.relationKind === 'Proceed')
        ))
      )
    )
  }

  private async _getCoverage(profile: IUserProfile | null): Promise<IPointOfSale[]> {
    return profile?.fieldPositionRole
      ? await this._storage.getWhere<IPointOfSale>(POSStore, pos => isPOSInCoverage(pos, profile.fieldPositionRole!))
      : []
  }

  private async _buildVisitUnit(visit: IVisit, tx: IStorageOperations): Promise<IVisitUnit> {
    return {
      visit: visit,
      tasks: await this._getVisitTasks(visit.code, tx),
      surveys: await this._getVisitSurveys(visit.code, tx)
    }
  }

  private async _buildTaskUnit(task: ITask, tx: IStorageOperations): Promise<ITaskUnit> {
    return {
      task: task,
      surveys: await this._getTaskSurveys(task, tx)
    }
  }

  private _shouldRetainEntity(entity: ISyncable, now: Date): boolean {
    return entity._sync?.lastLoadTime == null
      || addDays(entity._sync.lastLoadTime, this._config.dataRetentionDays ?? 60) > now
  }

  private async _buildSyncDeltaRequest<T extends ISyncEntity>(
    ctx: SyncContext,
    store: string,
    filter?: (entity: T) => boolean,
    keyFunc?: (entity: T) => EntityKey
  ): Promise<ISyncDeltaRequest> {
    const request: ISyncDeltaRequest = {
      profile: ctx.currentProfileReference,
      positionRole: ctx.currentRoleReference,
      stock: {},
      timestamp: Date.now()
    }
    const selector = filter ? this._storage.selectWhere<T>(store, filter) : this._storage.selectAll<T>(store)
    for await (const entity of selector) {
      const timestamp = entity._changeTime ?? entity.updateTime
      request.stock[keyFunc?.(entity) ?? entity.code] = timestamp
      request.timestamp = Math.max(request.timestamp, timestamp)
    }
    return request
  }

  private _getLoadMediaStartDate(ctx: SyncContext): number | undefined {
    const bp = ctx.businessParameters

    if (bp?.purgeBlobsOlderThanDays) {
      return Date.now() - parseFloat(bp.purgeBlobsOlderThanDays) * 24 * 60 * 60 * 1000
    }
  }

  private async _saveEntity<T extends ISyncEntity>(
    ctx: SyncContext,
    entity: T,
    entityName: string,
    entityStoreName: string,
    source: SyncErrorSource,
    sendFunc: (httpClient: IHttpClient) => Promise<void>,
    changeTime: DateTime
  ): Promise<boolean> {
    this.logger.debug(`_saveEntity(${entityName})`, `Saving ${entityName} ${getEntityKey(entity)}`)
    if (entity._sync == null) {
      entity._sync = {}
    }
    const httpClient = ctx.getHttpClient()
    let error: SyncError | undefined
    try {
      entity._sync.lastSaveTime = Date.now()
      await sendFunc(httpClient)
      SyncService.commitSavedEntity(entity, changeTime)
    } catch (e: any) {
      error = SyncService.syncError(source, 'Send error', e)
      this.logger.error(`_saveEntity(${entityName})`, `Error saving ${entityName} ${getEntityKey(entity)}`, error)
      entity._sync.lastSaveError = error.toString()
      entity._sync.lastSaveErrorCode = error.code
    }

    await this._storage.put(entityStoreName, entity)

    if (error) {
      // do not throw on these errors
      if ([SyncErrorCode.ServerError, SyncErrorCode.NetworkError, SyncErrorCode.BusinessError].includes(error.code)) {
        return false
      }
      throw error
    }
    return true
  }

  private async _loadEntities<T extends ISyncable>(
    ctx: SyncContext,
    entityListName: string,
    entityStoreName: string,
    source: SyncErrorSource,
    receiveFunc: (httpClient: IHttpClient, currentRoleKey?: string) => Promise<AxiosResponse<T[], any>>,
    hasScanMedia?: {
      filterScanMediaFunc: (entities: T[]) => T[],
      daysOfLifeMedia: string
    }
  ): Promise<T[] | undefined> {
    //this.logger.debug(`_loadEntities(${entityListName})`, `Loading ${entityListName}`)
    const httpClient = ctx.getHttpClient()
    let list: T[] | undefined
    try {
      const response = await receiveFunc(httpClient, ctx.currentRoleKey)
      list = response.data
    } catch (e) {
      const error = SyncService.syncError(source, 'Receive error', e)
      this.logger.error(`_loadEntities(${entityListName})`, `Error loading ${entityListName}`, error)
      throw error
    }

    if (list?.length) {
      console.debug(`Received ${entityListName}: ${list.length}`)
      list.forEach(entity => SyncService.commitReceivedEntity(entity))
      await this._storage.put(entityStoreName, list)

      if (hasScanMedia) {
        const unitList = hasScanMedia.filterScanMediaFunc(list)
        const deleteTime = getMediaDeleteTime(hasScanMedia.daysOfLifeMedia)
        scanMediaRefs(unitList).forEach((t) => {
          return ctx.mediaToReceive.set(t, {deleteAfter: deleteTime})
        })
      }
    }

    return list
  }

  /* -- Dictionaries -- */

  private async _loadDictionaries(ctx: SyncContext): Promise<void> {
    await this._loadEntities<IDictionary>(
      ctx,
     'Dictionaries', DictionaryStore, SyncErrorSource.LoadDictionaries,
     async httpClient => httpClient.get('dictionaries/v2.0/get-list'),
    )
  }

  /* -- DteParticipantProfiles -- */

  private async _loadDteParticipantProfiles(ctx: SyncContext): Promise<void> {
    await this._loadEntities<IDteParticipantProfile>(
      ctx,
      'DTEParticipantProfiles', DteParticipantProfileStore, SyncErrorSource.Other,
      async (httpClient, roleKey) => httpClient.get(
        `dte/participant-profiles/by-position-role/${roleKey}`
      )
    )
  }

  /* -- Hyperlinks -- */

  private async _loadHyperlinks(ctx: SyncContext): Promise<void> {
    const since = await this._storage.getLastKeyByIndexRange<number>(
      HyperlinkStore, HyperlinkStore_updateTime, null
    )
    await this._loadEntities<IHyperlinkDescription>(
      ctx,
      'Hyperlinks', HyperlinkStore, SyncErrorSource.LoadHyperlinks,
      async (httpClient) => httpClient.get(
        'hyperlinks',{ params: { since } }
      )
    )
  }

  /* -- SupervisedFieldPositionRoles -- */

  private async _loadSupervisedFieldPositionRoles(ctx: SyncContext): Promise<void> {
    await this._storage.deleteAll(SupervisedFieldPositionRoleStore);
    await this._loadEntities<ISupervisedFieldPositionRole>(
      ctx,
      'SupervisedFieldPositionRoles', SupervisedFieldPositionRoleStore, SyncErrorSource.LoadSupervisedFieldPositionRoles,
      async (httpClient) => httpClient.get(
        `field-position-roles/v1.0/get-supervised-by/${getEntityKey(ctx.currentRoleReference!)}`
      )
    )
  }

  /* -- FieldPositionRoleUserProfiles -- */

  private async _loadFieldPositionRoleUserProfiles(ctx: SyncContext): Promise<void> {
    await this._storage.deleteAll(FieldPositionRoleUserProfileStore);
    await this._loadEntities<IProfile>(
      ctx,
      'FieldPositionRoleUserProfiles', FieldPositionRoleUserProfileStore, SyncErrorSource.LoadFieldPositionRoleUserProfiles,
      async (httpClient) => httpClient.get(
        `user/v1/get-field-position-role-profiles`
      )
    )
  }

  /* -- TaskTemplates -- */

  private async _loadTaskTemplates(ctx: SyncContext): Promise<void> {
    const query = await this._buildSyncDeltaRequest<ITaskTemplate>(ctx, TaskTemplateStore, undefined, getEntityKey)
    // временной период +/- 2 месяца от текущей даты (FACE-3749)
    query.dateTimeInterval = {
      startDate: addMonths(new Date(), -2).getTime(),
      endDate: addMonths(new Date(), 2).getTime(),
    }
    let delta: ISyncDeltaResponse<Code>
    try {
      const response = await ctx.getHttpClient().post<ISyncDeltaResponse<Code>>('task-templates/v2.0/delta-query', query)
      delta = response.data
    } catch (e) {
      this.logger.error('_loadTaskTemplates', 'Failed requesting task-templates delta', e, (e as any)?.response?.data)
      throw SyncService.syncError(SyncErrorSource.LoadTaskTemplatesDelta, 'Error getting task-templates delta', e)
    }

    if (delta.remove?.length) {
      ctx.throwIfCancellationRequested()
      await this._storage.execute(
        [TaskTemplateStore],
        async (tx) => {
          for (const keyToRemove of delta.remove!) {
            if (keyToRemove) {
              await tx.deleteByKey(TaskTemplateStore, keyToRemove)
            }
          }
        }
      )
    }

    // batch loading task-templates
    let count = 0
    const loadChunks = _.chunk([...(delta.update ?? [])].filter(x => x != null), 100)
    for (const chunk of loadChunks) {
      ctx.throwIfCancellationRequested()
      this.logger.debug('_loadTaskTemplates', `Loading batch of ${chunk.length} TaskTemplates`)

      let taskTemplates: ITaskTemplate[]
      try {
        const response = await ctx.getHttpClient().post<ITaskTemplate[]>(`task-templates/v2.0/batch-query`, chunk)
        taskTemplates = response.data
      } catch (e) {
        this.logger.error('_loadTaskTemplates', `Error loading batch of TaskTemplates`, e, (e as any)?.response?.data)
        await this._storage.execute([TaskTemplateStore], async (tx) => {
          const taskTemplates = await tx.getByKeys<ITaskTemplate>(TaskTemplateStore, chunk)
          taskTemplates.forEach((tt) => SyncService.commitReceivedEntity(tt, e))
          await tx.put(TaskTemplateStore, taskTemplates)
        })
        throw SyncService.syncError(SyncErrorSource.LoadTaskTemplates, 'Error loading batch of TaskTemplates', e)
      }

      if (chunk.length !== taskTemplates?.length) {
        this.logger.warn('_loadTaskTemplates', `Requested ${chunk.length} TaskTemplates, loaded ${taskTemplates?.length}`)
      }

      taskTemplates.forEach(tt => {
        SyncService.commitReceivedEntity(tt)
        tt._key = getEntityKey(tt)
      })
      await this._storage.put(TaskTemplateStore, taskTemplates)
      count += taskTemplates.length
    }

    console.debug(`Received TaskTemplates: ${count}`)
  }

  private async _loadDteTaskTemplates(ctx: SyncContext): Promise<void> {
    const currentProfileReference = ctx.currentProfileReference

    const since = await maxOfAsync(
      this._storage.selectWhere<ITaskTemplate>(
        TaskTemplateStore,
        tt => currentProfileReference != null
          ? tt.relatedProfiles?.find(rp => rp.profile.code === currentProfileReference.code) != null
          : true
      ),
      (tt) => tt.updateTime
    )

    let list: ITaskTemplate[] | undefined
    try {
      const response = await ctx.getHttpClient().get(
        'task-templates/v2.0/dte/get-list',
        { params: { since, profile: currentProfileReference?.code } }
      )
      list = response.data
    } catch (e) {
      const error = SyncService.syncError(SyncErrorSource.LoadTaskTemplates, 'Receive error', e)
      this.logger.error(`_loadDteTaskTemplates`, `Error loading DTETaskTemplates`, error)
      throw error
    }

    if (list?.length) {
      console.debug(`Received DTETaskTemplates: ${list.length}`)
      list.forEach(tt => {
        SyncService.commitReceivedEntity(tt)
        tt._key = getEntityKey(tt)
      })
      await this._storage.put(TaskTemplateStore, list)
    }
  }

  /* -- Questionnaires -- */

  private async _loadQuestionnaires(ctx: SyncContext): Promise<void> {
    const since = await this._storage.getLastKeyByIndexRange<number>(QuestionnaireStore, QuestionnaireStore_updateTime, null)
    await this._loadEntities<IQuestionnaire>(
      ctx,
      'Questionnaires', QuestionnaireStore, SyncErrorSource.LoadQuestionnaires,
      async (httpClient) => httpClient.get(
        'questionnaires', { params: { since } }
      ),
      {
        filterScanMediaFunc: (questionnaire) => filterEntityList(questionnaire, undefined),
        daysOfLifeMedia: '60'
      }
    )
  }

  private async _loadQuestionnairePOSAssignments(ctx: SyncContext): Promise<void> {
    const since = await this._storage.getLastKeyByIndexRange<number>(
      QuestionnairePOSAssignmentStore, QuestionnairePOSAssignmentStore_updateTime, null
    )
    await this._loadEntities<IQuestionnairePOSAssignment>(
      ctx,
      'QuestionnairePOSAssignments', QuestionnairePOSAssignmentStore, SyncErrorSource.Other,
      async (httpClient) => httpClient.get(
        `questionnaire-pos-assignments/by-position-role/${ctx.currentRoleKey}`, { params: { since } }
      )
    )
  }

  /* -- POS Metrics -- */

  private async _loadPOSMetrics(ctx: SyncContext): Promise<void> {
    await this._loadEntities<IMetric>(
      ctx,
      'POSMetrics', MetricStore, SyncErrorSource.Other,
      async (httpClient, roleKey) => httpClient.get(
        `metrics/pos/by-position-role/${roleKey}`
      )
    )
  }

  /* -- TaskTemplateQuestionnaireAssignments -- */

  private async _loadTaskTemplateQuestionnaireAssignments(ctx: SyncContext): Promise<void> {
    const request = await this._buildSyncDeltaRequest<ITaskTemplateQuestionnaireAssignment>(
      ctx, TaskTemplateQuestionnaireAssignmentStore, t => true /*TODO: all??*/
    )
    let delta: ISyncDeltaResponse<ITaskTemplateQuestionnaireAssignment>
    const client = this._httpClientFactory.getHttpClient()
    try {
      const response = await client.post<ISyncDeltaResponse<ITaskTemplateQuestionnaireAssignment>>(
        'task-templates/questionnaire-assignments/delta-query', request
      )
      delta = response.data
    } catch (e) {
      this.logger.error(
        '_loadTaskTemplateQuestionnaireAssignments',
        'Failed loading TaskTemplateQuestionnaireAssignments',
        e, (e as any)?.response?.data
      )
      throw SyncService.syncError(SyncErrorSource.Other, 'Error loading TaskTemplateQuestionnaireAssignments', e)
    }

    if (delta) {
      if (delta.remove?.length) {
        await this._storage.deleteByKeys(TaskTemplateQuestionnaireAssignmentStore, delta.remove)
      }
      if (delta.update?.length) {
        await this._storage.put(TaskTemplateQuestionnaireAssignmentStore, delta.update)
      }
    }
  }

  /* -- Problems -- */

  private async _loadProblems(ctx: SyncContext): Promise<void> {
    const since = await this._storage.getLastKeyByIndexRange<number>(ProblemStore, ProblemStore_updateTime, null)
    const loadStartDateScanMedia = this._getLoadMediaStartDate(ctx)

    await this._loadEntities<IProblem>(
      ctx,
      'Problems', ProblemStore, SyncErrorSource.LoadProblems,
      async (httpClient, roleKey) => httpClient.get(
        `problems/by-position-role/${roleKey}`,{ params: { since } }
      ),
      {
        filterScanMediaFunc: (problems) => filterProblemsList(problems, loadStartDateScanMedia),
        daysOfLifeMedia: ctx.businessParameters?.purgeBlobsOlderThanDays ?? '3'
      }
    )
  }

  private async _saveProblems(ctx: SyncContext): Promise<void> {
    const problems = await this._storage.getWhere<IProblem>(ProblemStore, SyncService.isNewOrChangedEntity)
    for (const problem of problems) {
     ctx.throwIfCancellationRequested()
      await this._saveProblem(problem, ctx)
    }
  }

  private async _saveProblem(problem: IProblem, ctx: SyncContext): Promise<void> {
    const problemDto = { ...problem, _sync: undefined, _changeTime: undefined }
    if (
      await this._saveEntity(
        ctx,
        problem, 'Problem', ProblemStore, SyncErrorSource.UploadProblem,
        async httpClient => httpClient.post('problems/save', problemDto),
        problem._changeTime ?? problem.updateTime
      )
    ) {
      scanMediaRefs(problem).forEach(key => ctx.mediaToSend.add(key))
    }
  }

  /* -- ProblemTemplates -- */

  private async _loadProblemTemplates(ctx: SyncContext): Promise<void> {
    const since = await this._storage.getLastKeyByIndexRange<number>(ProblemTemplateStore, ProblemTemplateStore_updateTime, null)
    await this._loadEntities<IProblemTemplate>(
      ctx,
      'ProblemTemplates', ProblemTemplateStore, SyncErrorSource.LoadProblemTemplates,
      async (httpClient) => httpClient.get(
        'problem-templates/v1.0/get-list', { params: { since } }
      )
    )
  }

  /* -- ISA -- */

  private async _loadInternalStoreAudits(ctx: SyncContext): Promise<void> {
    const profile = await this._profile.getCurrentProfile()
    const posInCoverage = await this._getCoverage(profile)

    const client = ctx.getHttpClient()
    for (const pos of posInCoverage) {
      ctx.throwIfCancellationRequested()

      const posCode = pos.code
      const lastUpdateTime = await maxOfAsync(
        this._storage.selectByIndexRange<IAudit>(ISAStore, ISAStore_pos, ['=', posCode]),
        (a) => a.updateTime
      )

      let isa: IAudit[]
      try {
        const response = await client.get<IAudit[]>(`pos/${encodeURIComponent(posCode)}/isa`, {
          params: { since: lastUpdateTime }
        })
        isa = response.data
      } catch (e) {
        this.logger.error('_loadInternalStoreAudits', `Error loading ISA for POS ${posCode}`, e, (e as any)?.response?.data)
        throw SyncService.syncError(SyncErrorSource.LoadAudits, 'Error getting ISA', e)
      }

      if (isa?.length) {
        isa.forEach((a) => SyncService.commitReceivedEntity(a))
        await this._storage.put(ISAStore, isa)
      }
    }
  }

  private async _processAuditsOutOfCoverage(posCodes: Code[]): Promise<void> {
    // Drop all audits from obsolete POS
    await this._storage.execute(
      [ISAStore],
      async (tx) => {
        const auditsToDrop = await tx.getWhere<IAudit>(ISAStore, (aud) => posCodes.includes(aud.pointOfSale.code))
        if (auditsToDrop.length > 0) {
          await tx.deleteByKeys(ISAStore, auditsToDrop.map((a) => a.code))
        }
      })
  }

  /* -- SM-Services -- */

  private async _loadStoreManagerServices(ctx: SyncContext): Promise<void> {
    const lastEndDate = await this._storage.getLastKeyByIndexRange<number>(SMServiceStore, SMServiceStore_endDate, null)

    let sms: IStoreManagerService[]

    const client = ctx.getHttpClient()
    try {
      const response = await client.get<IStoreManagerService[]>('user/smservices', {
        params: { since: lastEndDate }
      })
      sms = response.data
    } catch (e) {
      this.logger.error('_loadStoreManagerServices', 'Error loading StoreManagerServices', e, (e as any)?.response?.data)
      throw SyncService.syncError(SyncErrorSource.LoadSMServices, 'Error SM service', e)
    }

    if (sms?.length) {
      sms.forEach((sm) => SyncService.commitReceivedEntity(sm))
      await this._storage.put(SMServiceStore, sms)
    }
  }

  /* -- POS -- */

  private async _loadPOS(ctx: SyncContext): Promise<void> {
    const currentRoleReference = ctx.currentRoleReference
    if (currentRoleReference == null) {
      return
    }
    let coverage: IPointOfSale[]
    const client = ctx.getHttpClient()
    try {
      const response = await client.get<IPointOfSale[]>(`pos/v1.2/by-position-role/${ctx.currentRoleKey}`)
      coverage = response.data
    } catch (e: any) {
      this.logger.error('_loadPOS', `Error loading POS for position role ${currentRoleReference.code}`, e, (e as any)?.response?.data)
      throw SyncService.syncError(SyncErrorSource.LoadPos, 'Error loading POS', e)
    }

    const { gotOutOfCoverage: posCodes } = await this._applyPOSCoverage(currentRoleReference.code, coverage)
    if (posCodes.length) {
      await this._processAuditsOutOfCoverage(posCodes)
      await this._processVisitsOutOfCoverage(posCodes)
    }
  }

  private async _applyPOSCoverage(positionRoleCode: Code, coverage: IPointOfSale[]): Promise<{ gotOutOfCoverage: Code[] }> {
    const gotOutOfCoverage: Code[] = []

    await this._storage.execute([POSStore], async (tx) => {
      const allPos = await tx.getAll<IPointOfSale>(POSStore)

      const changes: IPointOfSale[] = []
      const toDelete: Code[] = []

      allPos.forEach((pos) => {
        const wasInCoverage = isPOSInCoverage(pos, positionRoleCode)
        const idx = coverage.findIndex((x) => x.code === pos.code)
        const isNowInCoverage = idx >= 0
        if (isNowInCoverage) {
          if (!wasInCoverage) {
            this.logger.info('_applyPOSCoverage', `PointOfSale ${pos.code} is now in coverage for PositionRole ${positionRoleCode}`)
          }
          const oldCoverage = pos.positionRoleCoverage
          pos = coverage[idx]
          coverage.splice(idx, 1)
          pos.positionRoleCoverage = oldCoverage
          setPOSIsInCoverage(pos, positionRoleCode, true)
          SyncService.commitReceivedEntity(pos)
          SyncService.commitUnarchivedEntity(pos)
          changes.push(pos)
        } else if (wasInCoverage) {
          this.logger.info('_applyPOSCoverage', `PointOfSale ${pos.code} got out of coverage for PositionRole ${positionRoleCode}`)
          setPOSIsInCoverage(pos, positionRoleCode, false)
          if (!pos.positionRoleCoverage?.length) {
            SyncService.commitArchivedEntity(pos)
          }
          gotOutOfCoverage.push(pos.code)
          changes.push(pos)
        } else if (!pos._sync?.archiveTime) {
          if (!pos.positionRoleCoverage?.length) {
            SyncService.commitArchivedEntity(pos)
            changes.push(pos)
          }
        } else {
          const daysSinceArchiving = differenceInDays(Date.now(), pos._sync.archiveTime)
          if (daysSinceArchiving >= (this._config.dataRetentionDays ?? 60)) {
            toDelete.push(pos.code)
          }
        }
      })

      coverage.forEach((pos) => {
        this.logger.info('_applyPOSCoverage', `PointOfSale ${pos.code} is now in coverage for PositionRole ${positionRoleCode}`)
        setPOSIsInCoverage(pos, positionRoleCode, true)
        SyncService.commitReceivedEntity(pos)
        changes.push(pos)
      })

      await tx.put(POSStore, changes)

      if (toDelete.length) {
        await tx.deleteByKeys(POSStore, toDelete)
        this.logger.info('_applyPOSCoverage', `These PointOfSales are deleted: ${toDelete}`)
      }
    })

    return { gotOutOfCoverage }
  }

  /* -- Visits -- */

  private async _saveVisit(visit: IVisit, ctx: SyncContext): Promise<void> {
    //this.logger.debug('_saveVisit', `Saving Visit ${visit.code}`)

    const visitUnit = await this._buildVisitUnit(visit, this._storage)
    const visitUnitDto: IVisitUnitDto = {
      visit: {...visit, _changeTime: undefined, _sync: undefined},
      tasks: visitUnit.tasks,
      surveys: visitUnit.surveys,
      changeTime: visit._changeTime ?? visit.updateTime
    }

    if (
      await this._saveEntity(
        ctx,
        visit, 'Visit', VisitStore, SyncErrorSource.UploadVisit,
        async httpClient => httpClient.post('visits/v1.3/save-visit', visitUnitDto),
        visitUnitDto.changeTime
      )
    ) {
      scanMediaRefsTaskSpecialHandling(visitUnit).forEach(key => ctx.mediaToSend.add(key))
    }
  }

  private async _saveVisits(ctx: SyncContext): Promise<void> {
    const visits = await this._storage.getWhere<IVisit>(VisitStore, SyncService.isNewOrChangedEntity)
    for (const visit of visits) {
      ctx.throwIfCancellationRequested()
      await this._saveVisit(visit, ctx)
    }
  }

  private async _processVisitsOutOfCoverage(posCodes: Code[]): Promise<void> {
    // Cancel all future planned visits to obsolete POS
    const today = startOfDay(Date.now())
    const isTodayOrLater = (date: DateTime): boolean => !isBefore(startOfDay(date), today)

    for (const posCode of posCodes) {
      const visitsToCancel = (await this._visitService.searchVisits({ pointOfSaleCode: posCode })).filter(
        (v) => v.status === 'Planned' && isTodayOrLater(v.plannedStartDate)
      )

      for (const visit of visitsToCancel) {
        this.logger.info(
          '_processVisitsOutOfCoverage',
          `Cancelling visit ${visit.code} due to POS getting out of coverage`
        )
        await this._visitService.setVisitStatus({ visitCode: visit.code, visitStatus: 'Canceled' })
      }
    }
  }

  private async _loadVisits(ctx: SyncContext): Promise<IDelta> {
    const query = await this._buildSyncDeltaRequest<IVisit>(ctx, VisitStore, v => !SyncService.isFreshEntity(v))
    let delta: ISyncDeltaResponse<Code>
    try {
      const response = await ctx.getHttpClient().post<ISyncDeltaResponse<Code>>('visits/delta-query', query)
      delta = response.data
    } catch (e) {
      this.logger.error('_loadVisits', 'Failed requesting visits delta', e, (e as any)?.response?.data)
      throw SyncService.syncError(SyncErrorSource.LoadVisitsDelta, 'Error getting visits delta', e)
    }

    if (delta.remove?.length) {
      ctx.throwIfCancellationRequested()
      await this._storage.execute(
        [VisitStore, TaskStore, SurveyStore, TaskExecutionStateStore, TaskExecutionScopeStore],
        async (tx) => {
          for (const visitCodeToRemove of delta.remove!) {
            if (visitCodeToRemove) {
              const visit = await this._getVisit(visitCodeToRemove, tx)
              if (visit) {
                const oldVisitUnit = await this._buildVisitUnit(visit, tx)
                scanMediaRefsTaskSpecialHandling(oldVisitUnit).forEach(key => ctx.mediaToDelete.add(key))
                await this._purgeVisit(visitCodeToRemove, tx)
              }
            }
          }
        })
    }

    // batch loading visits
    const loadChunks = _.chunk([...(delta.update ?? [])].filter(x => x != null), VISITS_BATCH_SIZE)
    for (const chunk of loadChunks) {
      ctx.throwIfCancellationRequested()
      await this._loadVisitsBatch(chunk, ctx)
    }

    return new Delta(Object.keys(query.stock), delta.update, delta.remove)
  }

  private async _loadVisitsBatch(visitCodes: Code[], ctx: SyncContext): Promise<void> {
    this.logger.debug('_loadVisitsBatch', `Loading ${visitCodes.length} Visits`)
    if (!visitCodes?.length) {
      return
    }

    let visitUnitDtos: IVisitUnitDto[]
    try {
      const response = await ctx.getHttpClient().post<IVisitUnitDto[]>(`visits/batch-query`, visitCodes)
      visitUnitDtos = response.data
    } catch (e) {
      this.logger.error('_loadVisitsBatch', `Error loading batch of Visits`, e, (e as any)?.response?.data)
      await this._storage.execute([VisitStore], async (tx) => {
        const visits = await tx.getByKeys<IVisit>(VisitStore, visitCodes)
        visits.forEach(visit => SyncService.commitReceivedEntity(visit, e))
        await tx.put(VisitStore, visits)
      })
      throw SyncService.syncError(SyncErrorSource.LoadVisits, 'Error loading batch of Visits', e)
    }

    if (visitCodes.length !== visitUnitDtos?.length) {
      this.logger.warn('_loadVisitsBatch', `Requested ${visitCodes.length} visits, loaded ${visitUnitDtos?.length}`)
    }

    for (const visitUnitDto of visitUnitDtos) {
      const newVisit = visitUnitDto.visit
      const visitCode = newVisit.code
      newVisit._changeTime = visitUnitDto.changeTime
      SyncService.commitReceivedEntity(newVisit)

      let oldMediaKeys = new Set<string>()
      await this._storage.execute(
        [VisitStore, TaskStore, SurveyStore, TaskExecutionStateStore, TaskExecutionScopeStore],
        async (tx) => {
          const oldVisit = await this._getVisit(visitCode, tx)
          if (oldVisit) {
            const oldVisitUnit = await this._buildVisitUnit(oldVisit, tx)
            oldMediaKeys = scanMediaRefsTaskSpecialHandling(oldVisitUnit)
            oldMediaKeys.forEach(key => ctx.mediaToDelete.add(key))
            await this._purgeVisit(visitCode, tx)
            newVisit._sync = {...oldVisit._sync, ...newVisit._sync}
          }

          if (visitUnitDto.surveys) {
            visitUnitDto.surveys.forEach(s => {
              s._visitCode = visitCode
            })
            await tx.put(SurveyStore, visitUnitDto.surveys)
          }

          if (visitUnitDto.tasks) {
            visitUnitDto.tasks.forEach(t => {
              t.visitCode = visitCode
              t._isVisitTask = true
              t._isTaskRegister = false
            })
            await tx.put(TaskStore, visitUnitDto.tasks)
          }

          await tx.put(VisitStore, newVisit)
        }
      )

      if (checkLoadStartDate(visitUnitDto.visit, this._getLoadMediaStartDate(ctx))) {
        const deleteTime = getMediaDeleteTime(ctx.businessParameters?.purgeBlobsOlderThanDays ?? '3')
        scanMediaRefsTaskSpecialHandling(visitUnitDto).forEach((key) => {
          return ctx.mediaToReceive.set(key, {deleteAfter: deleteTime})
        })
      }
    }
  }

  /* -- PPOSM -- */

  private async _loadPPOSM(ctx: SyncContext): Promise<void> {
    await this._loadEntities<IPPOSM>(
      ctx,
      'PPOSM', PPOSMStore, SyncErrorSource.Other,
      async (httpClient, roleKey) => httpClient.get(
        `pposm/by-position-role/${roleKey}`
      )
    )
  }

  /* -- POSParticipantPrograms -- */

  private async _loadPOSParticipantPrograms(ctx: SyncContext): Promise<void> {
    await this._loadEntities<IPOSParticipantProgram>(
      ctx,
      'POSParticipantPrograms', POSParticipantProgramStore, SyncErrorSource.Other,
      async (httpClient, roleKey) => httpClient.get(
        `dte/pos-participant-programs/by-position-role/${roleKey}`
      )
    )
  }

  /* -- Visit-Assessments -- */

  private async _loadVisitAssessments(ctx: SyncContext): Promise<void> {
    const query = await this._buildSyncDeltaRequest<IVisitAssessment>(ctx, VisitAssessmentStore)
    let delta: ISyncDeltaResponse<IVisitAssessment>
    try {
      const response = await ctx.getHttpClient().post<ISyncDeltaResponse<IVisitAssessment>>(
        `visits/assessments/delta-query`, query
      )
      delta = response.data
    } catch (e) {
      this.logger.error('_loadVisitAssessments', 'Error loading VisitAssessments delta', e, (e as any)?.response?.data)
      throw SyncService.syncError(SyncErrorSource.Other, 'Error getting VisitAssessments delta', e)
    }

    if (delta?.update?.length) {
      const visitAssessments = delta.update
      console.debug(`Received ${visitAssessments.length} VisitAssessments`)
      await this._storage.put(VisitAssessmentStore, visitAssessments)
    }
  }

  /* -- Non-Visit Tasks --*/
  private async _saveNonVisitTask(task: ITask, ctx: SyncContext): Promise<void> {
    const taskUnit = await this._buildTaskUnit(task, this._storage)
    const taskUnitDto: ITaskUnitDto = {
      task: {...task, _changeTime: undefined, _sync: undefined},
      surveys: taskUnit.surveys,
      changeTime: task._changeTime ?? Math.max(task.updateTime, ...(taskUnit.surveys?.map(s => s.updateTime) ?? []))
    }
    if (
      await this._saveEntity(
        ctx,
        task, 'Task', TaskStore, SyncErrorSource.UploadTask,
        async httpClient => httpClient.post('tasks/save', taskUnitDto),
        taskUnitDto.changeTime
      )
    ) {
      scanMediaRefsTaskSpecialHandling(taskUnit).forEach(key => ctx.mediaToSend.add(key))
    }
  }

  private async _saveNonVisitTasks(ctx: SyncContext): Promise<void> {
    const tasks = await this._storage.getWhere<ITask>(
      TaskStore,
      t => !(t._isVisitTask ?? isInVisitTask(t)) && SyncService.isNewOrChangedEntity(t)
    )
    for (const task of tasks) {
      ctx.throwIfCancellationRequested()
      await this._saveNonVisitTask(task, ctx)
    }
  }

  private async _loadNonVisitTasks(ctx: SyncContext): Promise<void> {
    const request = await this._buildSyncDeltaRequest<ITask>(
      ctx, TaskStore, t => !t._isVisitTask && !isInVisitTask(t) && !SyncService.isFreshEntity(t)
    )
    // временной период +/- месяц от текущей даты
    request.dateTimeInterval = {
      startDate: addMonths(new Date(), -2).getTime(),
      endDate: addMonths(new Date(), 1).getTime(),
    }
    let delta: ISyncDeltaResponse<ITaskUnitDto>
    try {
      const response = await ctx.getHttpClient().post<ISyncDeltaResponse<ITaskUnitDto>>(
        'tasks/delta-query', request, { params: { kind: 'NonVisitTask' } }
      )
      delta = response.data
    } catch (e) {
      this.logger.error('_loadNonVisitTasks', 'Failed loading NonVisitTasks', e, (e as any)?.response?.data)
      throw SyncService.syncError(SyncErrorSource.LoadTasksDelta, 'Error getting NonVisitTasks', e)
    }

    if (delta) {
      await this._applyTasksDelta(delta, false, false, ctx)
    }
  }

  private async _loadTaskRegisters(ctx: SyncContext): Promise<void> {
    const request = await this._buildSyncDeltaRequest<ITask>(ctx, TaskStore, t => Boolean(t._isTaskRegister))
    // временной период +/- месяц от текущей даты
    request.dateTimeInterval = {
      startDate: addMonths(new Date(), -1).getTime(),
      endDate: addMonths(new Date(), 1).getTime(),
    }
    let delta: ISyncDeltaResponse<ITaskUnitDto>
    try {
      const response = await ctx.getHttpClient().post<ISyncDeltaResponse<ITaskUnitDto>>(
        'tasks/delta-query', request, { params: { kind: 'VisitTaskRegister' } }
      )
      delta = response.data
    } catch (e) {
      this.logger.error('_loadTaskRegisters', 'Failed loading TaskRegisters', e, (e as any)?.response?.data)
      throw SyncService.syncError(SyncErrorSource.LoadTasksDelta, 'Error getting TaskRegisters', e)
    }

    if (delta) {
      await this._applyTasksDelta(delta, true, true, ctx)
    }
  }

  private async _applyTasksDelta(
    delta: ISyncDeltaResponse<ITaskUnitDto>,
    isVisitTask: boolean,
    isTaskRegister: boolean,
    ctx: SyncContext
  ): Promise<void> {
    const removeTask = async (oldTaskCode: Code, tx: IStorageOperations): Promise<ITask | null> => {
      const oldTask = await this._getTask(oldTaskCode, tx)
      if (oldTask) {
        const oldTaskUnit = await this._buildTaskUnit(oldTask, tx)
        scanMediaRefsTaskSpecialHandling(oldTaskUnit).forEach(key => ctx.mediaToDelete.add(key))
        await this._purgeTask(oldTask.code, tx)
      }
      return oldTask
    }

    if (delta.remove?.length) {
      ctx.throwIfCancellationRequested()
      await this._storage.execute([TaskStore, SurveyStore, TaskExecutionStateStore, TaskExecutionScopeStore], async (tx) => {
        for (const taskCodeToRemove of delta.remove!) {
          await removeTask(taskCodeToRemove, tx)
        }
      })
    }

    if (delta.update?.length) {
      for (const taskUnitDto of delta.update) {
        ctx.throwIfCancellationRequested()
        if (!taskUnitDto)
          continue

        const newTask = taskUnitDto.task
        newTask._changeTime = taskUnitDto.changeTime
        SyncService.commitReceivedEntity(newTask)

        await this._storage.execute(
          [TaskStore, SurveyStore, TaskExecutionStateStore, TaskExecutionScopeStore],
          async (tx) => {
            const oldTask = await removeTask(newTask.code, tx)
            if (oldTask) {
              newTask._sync = { ...oldTask._sync, ...newTask._sync }
            }

            if (taskUnitDto.surveys) {
              taskUnitDto.surveys.forEach(s => { s._taskCode = newTask.code })
              await tx.put(SurveyStore, taskUnitDto.surveys)
            }

            newTask._isVisitTask = isVisitTask
            newTask._isTaskRegister = isTaskRegister

            await tx.put(TaskStore, newTask)
          }
        )

        if (checkLoadStartDate(taskUnitDto.task, this._getLoadMediaStartDate(ctx))) {
          const deleteTime = getMediaDeleteTime(ctx.businessParameters?.purgeBlobsOlderThanDays ?? '3')
          scanMediaRefsTaskSpecialHandling(taskUnitDto).forEach((key) => {
            return ctx.mediaToReceive.set(key, {deleteAfter: deleteTime})
          })
        }
      }
    }
  }

  /* -- Reports-- */

  private static makeTaskReportKey(visitCode: Code | null | undefined, taskCode: Code): string {
    return visitCode ? `${visitCode}.${taskCode}` : taskCode
  }

  private async _uploadTaskReport(taskReport: ITaskReport): Promise<string> {
    const visitTaskReport = taskReport as IVisitTaskReport
    let reportLink
    if (visitTaskReport.visitCode) {
      const encodedVisitCode = encodeURIComponent(visitTaskReport.visitCode)
      const encodedTaskCode = encodeURIComponent(visitTaskReport.taskCode)
      reportLink = `/visits/${encodedVisitCode}/tasks/${encodedTaskCode}/report`
    } else {
      const encodedTaskCode = encodeURIComponent(taskReport.taskCode)
      reportLink = `/tasks/${encodedTaskCode}/report`
    }
    const reportKey = SyncService.makeTaskReportKey(visitTaskReport.visitCode, visitTaskReport.taskCode)
    this.logger.debug('_uploadTaskReport', `Uploading TaskReport ${reportKey}`)
    const httpClient = this._httpClientFactory.getHttpClient()
    try {
      await httpClient.post(reportLink, taskReport.data, { headers: { 'Content-Type': taskReport.data.type } })
    } catch (e: any) {
      const message = `Error uploading TaskReport ${reportKey}`
      const error = SyncService.syncError(SyncErrorSource.UploadReport, message, e)
      this.logger.error('_uploadTaskReport', message, e, error)
      throw error
    }
    return reportLink
  }

  private async _saveReports(ctx: SyncContext): Promise<void> {
    while (!ctx.cancellation?.isCancellationRequested) {
      const reports = await this._storage.getByKeyRange<ITaskReport>(TaskReportStore, null, 1)
      if (!reports?.length) {
        break
      }

      const report = reports[0]
      const reportLink = await this._uploadTaskReport(report)

      await this._storage.execute([VisitStore, TaskStore, TaskReportStore], async (tx) => {
        const reportKey = SyncService.makeTaskReportKey((report as IVisitTaskReport).visitCode, report.taskCode)
        await tx.deleteByKey(TaskReportStore, reportKey)

        const task = await this._getTask(report.taskCode, tx)
        if (task && this._setTaskReportLink(task, reportLink)) {
          await tx.put(TaskStore, task)
          await this._updateVisitChangeTime((task as IVisitTask).visitCode, tx)
        }
      })
    }
  }

  /* -- POSTaskRegisters -- */
  private async _loadPOSTaskRegisters(ctx: SyncContext): Promise<void> {
    const maxUpdateTime = await maxOfAsync(
      this._storage.selectAll<IPOSTaskRegister>(POSTaskRegisterStore),
      x => x.updateTime
    )

    const now = new Date()
    const currentProfile = await this._profile.getCurrentProfile()
    const coverage = await this._getCoverage(currentProfile)
    const availableTemplates = await this._getUserProfileTaskTemplates(currentProfile, now)

    let newPOSTaskRegisters: IPOSTaskRegister[] = []

    if (coverage?.length) {
      if (availableTemplates?.length) {
        const query: IPOSTaskRegisterFilterDto = {
          posCodes: coverage.map(pos => pos.code),
          taskTemplates: availableTemplates.map(tmpl => ({
            code: tmpl.code,
            version: {
              code: tmpl.version?.code,
              startDate: tmpl.version?.startDate
            }
          })),
          startDate: +startOfYesterday(),
          endDate: +endOfDay(addDays(now, 30)),
          updatedAfter: maxUpdateTime,
          onlyActive: !maxUpdateTime
        }
        try {
          const response = await ctx.getHttpClient().post<IPOSTaskRegister[]>('pos-task-registers/v1.0/get-list', query)
          newPOSTaskRegisters = response.data
        } catch (e) {
          this.logger.error('_loadPOSTaskRegisters', 'Error loading POSTaskRegisters', e, (e as any)?.response?.data)
          throw SyncService.syncError(SyncErrorSource.LoadPOSTaskRegisters, 'Error getting POS task registers', e)
        }
      } else {
        console.info(`No available task templates for profile '${currentProfile?.profile?.code}' and current date. Will not load POSTaskRegisters`)
      }
    } else {
      console.info('Empty POS coverage. Will not load POSTaskRegisters')
    }

    await this._storage.execute([POSTaskRegisterStore], async tx => {
      if (newPOSTaskRegisters?.length) {
        await tx.put(POSTaskRegisterStore, newPOSTaskRegisters)
      }

      const posTaskRegisters = await tx.getAll<IPOSTaskRegister>(POSTaskRegisterStore)
      const codesToDelete = posTaskRegisters
        .filter(reg => {
          if (coverage.some(pos => pos.code === reg.pos?.code)) {
            return false
          }
          if (availableTemplates.some(tmpl => (
            tmpl.code === reg.taskTemplate?.code
             && tmpl.version?.code === reg.taskTemplate?.version?.code
          ))) {
            return false
          }
          if (!reg.endDate || addDays(reg.endDate, this._config.dataRetentionDays ?? 60) > now) {
            return false
          }
          return true
        })
        .map(reg => reg.code)

      if (codesToDelete.length)   {
        await tx.deleteByKeys(POSTaskRegisterStore, codesToDelete)
      }
    })
  }

  private async _loadPOSTaskRegisterExecutions(ctx: SyncContext): Promise<void> {
    const maxUpdateTime = await maxOfAsync(
      this._storage.selectAll<IPOSTaskRegisterExecution>(POSTaskRegisterExecutionStore),
      x => x.updateTime
    )

    const now = new Date()

    const currentProfile = await this._profile.getCurrentProfile()
    const coverage = await this._getCoverage(currentProfile)
    const availableTemplates = await this._getUserProfileTaskTemplates(currentProfile, now)

    let newExecutions: IPOSTaskRegisterExecution[] = []

    if (coverage?.length) {
      if (availableTemplates?.length) {
        const query: IPOSTaskRegisterFilterDto = {
          posCodes: coverage.map(pos => pos.code),
          taskTemplates: availableTemplates.map(tmpl => ({
            code: tmpl.code,
            version: {
              code: tmpl.version?.code,
              startDate: tmpl.version?.startDate
            }
          })),
          startDate: +startOfYesterday(),
          endDate: +endOfDay(addDays(now, 30)),
          updatedAfter: maxUpdateTime,
          onlyActive: !maxUpdateTime
        }

        try {
          const response = await ctx.getHttpClient().post<IPOSTaskRegisterExecution[]>('pos-task-registers/v1.0/get-execution-list', query)
          newExecutions = response.data
        } catch (e) {
          this.logger.error('_loadPOSTaskRegisterExecutions', 'Error loading POSTaskRegisterExecutions', e, (e as any)?.response?.data)
          throw SyncService.syncError(SyncErrorSource.LoadPOSTaskRegisterExecutions, 'Error getting POS task register executions', e)
        }
      } else {
        console.info(`No available task templates for profile '${currentProfile?.profile?.code}' and current date. Will not load POSTaskRegisterExecutions`)
      }
    } else {
      console.info('Empty POS coverage. Will not load POSTaskRegisterExecutions')
    }

    newExecutions?.forEach(x => SyncService.commitReceivedEntity(x))

    await this._storage.execute([POSTaskRegisterStore, POSTaskRegisterExecutionStore], async tx => {
      if (newExecutions?.length) {
        await tx.put(POSTaskRegisterExecutionStore, newExecutions)
      }

      const registers = await tx.getAll<IPOSTaskRegister>(POSTaskRegisterStore)
      const executions = await tx.getAll<IPOSTaskRegisterExecution>(POSTaskRegisterExecutionStore)

      const codesToDelete = executions
        .filter(exec => {
          if (registers.some(reg => reg.code === exec.code)) {
            return false
          }
          if (this._shouldRetainEntity(exec, now)) {
            return false
          }
          return true
        })
        .map(reg => reg.code)

      if (codesToDelete.length)   {
        await tx.deleteByKeys(POSTaskRegisterExecutionStore, codesToDelete)
      }
    })
  }

  /* -- TaskTemplateContents -- */
  private async _loadTaskTemplateContents(ctx: SyncContext): Promise<void> {
    const maxUpdateTime = await maxOfAsync(
      this._storage.selectAll<ITaskTemplateContent>(TaskTemplateContentStore),
      x => x.updateTime
    )

    const now = new Date()

    const currentProfile = await this._profile.getCurrentProfile()
    const coverage = await this._getCoverage(currentProfile)
    const availableTemplates = await this._getUserProfileTaskTemplates(currentProfile, now)

    let newTaskTemplateContents: ITaskTemplateContent[] = []

    if (coverage.length) {
      if (availableTemplates.length) {
        const query = {
          //posCodes: coverage.map(pos => pos.code),
          positionRole: ctx.currentRoleReference,
          taskTemplates: availableTemplates.map((tmpl) => ({
            code: tmpl.code,
            codeSpace: tmpl.codeSpace ?? this.defaultCodeSpace,
            version: {
              code: tmpl.version?.code,
              startDate: tmpl.version?.startDate,
            },
          })),
          updatedAfter: maxUpdateTime,
          onlyActive: !maxUpdateTime,
        }

        try {
          const response = await ctx.getHttpClient().post<ITaskTemplateContent[]>('task-template-contents/v1.0/get-list', query)
          newTaskTemplateContents = response.data
        } catch (e) {
          this.logger.error('_loadTaskTemplateContents', 'Error loading TaskTemplateContents', e, (e as any)?.response?.data)
          throw SyncService.syncError(SyncErrorSource.LoadTaskTemplateContents, 'Error getting TaskTemplateContents', e)
        }
      } else {
        console.info(`No available task templates for profile '${currentProfile?.profile?.code}' and current date. Will not load TaskTemplateContents`)
      }
    } else {
      console.info('Empty POS coverage. Will not load TaskTemplateContents')
    }

    newTaskTemplateContents?.forEach(x => SyncService.commitReceivedEntity(x))

    await this._storage.execute([TaskTemplateContentStore], async tx => {
      if (newTaskTemplateContents?.length) {
        await tx.put(TaskTemplateContentStore, newTaskTemplateContents)
      }

      const taskTemplateContents = await tx.getAll<ITaskTemplateContent>(TaskTemplateContentStore)
      const codesToDelete = taskTemplateContents
        .filter(ttc => {
          if (!ttc.productLocation || coverage.some(pos => pos.code === ttc.productLocation!.code)) {
            return false
          }
          if (availableTemplates.some(tmpl => (tmpl.code === ttc.template?.code && tmpl.version?.code === ttc.template?.version?.code))) {
            return false
          }
          if (this._shouldRetainEntity(ttc, now)) {
            return false
          }
          return true
        })
        .map(ttc => ttc.code)

      if (codesToDelete.length)   {
        await tx.deleteByKeys(TaskTemplateContentStore, codesToDelete)
      }
    })
  }

  /* --ContentDocuments-- */
  private async _loadContentDocuments(ctx: SyncContext): Promise<void> {
    const maxUpdateTime = await maxOfAsync(
      this._storage.selectAll<IContentDocument>(ContentDocumentStore),
      x => x.updateTime
    )

    const now = new Date()

    // collect references to ContentDocuments
    const taskTemplateContents = await this._storage.getAll<ITaskTemplateContent>(TaskTemplateContentStore)
    const problemTemplates = await this._storage.getAll<IProblemTemplate>(ProblemTemplateStore)

    const docCodes = new Set<string>([
      ...taskTemplateContents.map(ttc => ttc.contentDocument?.code).filter(Boolean),
      ...problemTemplates.flatMap(pt => pt.relatedDocuments?.map(rd => rd.code) ?? [])
    ])

    let newContentDocuments: IContentDocument[] = []
    if (docCodes.size) {
      const query: IContentDocumentFilterDto = {
        codes: [...docCodes.values()],
        updatedAfter: maxUpdateTime
      }
      try {
        const response = await ctx.getHttpClient().post<IContentDocument[]>('content-documents/v1.0/get-list', query)
        newContentDocuments = response.data
      } catch (e) {
        this.logger.error('_loadContentDocuments', 'Error loading ContentDocuments', e, (e as any)?.response?.data)
        throw SyncService.syncError(SyncErrorSource.LoadContentDocuments, 'Error getting ContentDocuments', e)
      }
    } else {
      console.info('No references to ContentDocument. Will not load ContentDocuments')
    }

    newContentDocuments?.forEach(x => SyncService.commitReceivedEntity(x))

    await this._storage.execute([ContentDocumentStore], async tx => {
      if (newContentDocuments?.length) {
        await tx.put(ContentDocumentStore, newContentDocuments)
        const deleteTime = getMediaDeleteTime('60')
        newContentDocuments.forEach((doc) => {
          return ctx.mediaToReceive.set(doc.content.target, {deleteAfter: deleteTime})
        })
      }
      // cleanup
      const contentDocuments = await tx.getAll<IContentDocument>(ContentDocumentStore)
      const documentsToDelete = contentDocuments
        .filter(doc => {
          if (docCodes.has(doc.code)) {
            return false
          }
          if (this._shouldRetainEntity(doc, now)) {
            return false
          }
          return true
        })

      if (documentsToDelete.length)   {
        await tx.deleteByKeys(ContentDocumentStore, documentsToDelete.map(doc => doc.code))
        documentsToDelete.forEach(doc => ctx.mediaToDelete.add(doc.content.target))
      }
    })
  }

  /* -- ProductMatrixAssignments-- */
  private async _loadProductMatrixAssignments(ctx: SyncContext): Promise<void> {
    const maxUpdateTime = await maxOfAsync(
      this._storage.selectAll<IProductMatrixAssignment>(ProductMatrixAssignmentStore),
      x => x.updateTime
    )

    const now = new Date()

    const currentProfile = await this._profile.getCurrentProfile()
    const coverage = await this._getCoverage(currentProfile)

    let newProductMatrixAssignments: IProductMatrixAssignment[] = []

    if (coverage?.length) {
      const query: IProductMatrixAssignmentFilterDto = {
        posCodes: coverage.map(pos => pos.code),
        updatedAfter: maxUpdateTime,
        onlyActive: !maxUpdateTime
      }
      try {
        const response = await ctx.getHttpClient().post<IProductMatrixAssignment[]>('product-matrices/v1.0/get-assignment-list', query)
        newProductMatrixAssignments = response.data
      } catch (e) {
        this.logger.error('_loadProductMatrixAssignments', 'Error loading ProductMatrixAssignments', e, (e as any)?.response?.data)
        throw SyncService.syncError(SyncErrorSource.LoadProductMatrixAssignments, 'Error getting ProductMatrixAssignments', e)
      }

    } else {
      console.info('Empty POS coverage. Will not load ProductMatrixAssignments')
    }

    newProductMatrixAssignments?.forEach(x => SyncService.commitReceivedEntity(x))

    await this._storage.execute([ProductMatrixAssignmentStore], async tx => {
      if (newProductMatrixAssignments?.length) {
        await tx.put(ProductMatrixAssignmentStore, newProductMatrixAssignments)
      }

      const productMatrixAssignments = await tx.getAll<IProductMatrixAssignment>(ProductMatrixAssignmentStore)
      const codesToDelete = productMatrixAssignments
        .filter(pma => {
          if (!pma.productLocation || coverage.some(pos => pos.code === pma.productLocation!.code)) {
            return false
          }
          if (pma.isActive && (!pma.endDate || new Date(pma.endDate) > now)) {
            return false
          }
          if (this._shouldRetainEntity(pma, now)) {
            return false
          }
          return true
        })
        .map(pma => pma.code)

      if (codesToDelete.length)   {
        await tx.deleteByKeys(ProductMatrixAssignmentStore, codesToDelete)
      }
    })
  }

  /* -- ProductMatrices-- */
  private async _loadProductMatrices(ctx: SyncContext): Promise<void> {
    const query: IProductMatrixDiffQueryDto = {
      stock: {}
    }

    const now = new Date()

    let diff: IProductMatrixDiffDto | null = null

    for await (const pma of this._storage.selectAll<IProductMatrixAssignment>(ProductMatrixAssignmentStore)) {
      if (pma.matrix?.code) {
        query.stock[pma.matrix.code] = null
      }
    }

    if (!Object.keys(query.stock).length) {
      console.info('No ProductMatrices to load')

    } else {
      for await (const matrix of this._storage.selectAll<IProductMatrix>(ProductMatrixStore)) {
        if (matrix.code in query.stock) {
          const timestamp = matrix._changeTime ?? matrix.updateTime
          query.stock[matrix.code] = timestamp
        }
      }
      try {
        const response = await ctx.getHttpClient().post<IProductMatrixDiffDto>('product-matrices/v1.0/get-list', query)
        diff = response.data
      } catch (e) {
        this.logger.error('_loadProductMatrices', 'Error loading ProductMatrices', e, (e as any)?.response?.data)
        throw SyncService.syncError(SyncErrorSource.LoadProductMatrices, 'Error getting ProductMatrices', e)
      }
    }

    const newMatrices = diff?.updates ? Object.values(diff!.updates).filter(Boolean) : null

    newMatrices?.forEach(x => x && SyncService.commitReceivedEntity(x!))

    await this._storage.execute([ProductMatrixStore], async tx => {
      if (newMatrices?.length) {
        await tx.put(ProductMatrixStore, newMatrices)
      }

      const matrices = await tx.getAll<IProductMatrix>(ProductMatrixStore)
      const codesToDelete = matrices
        .filter(matrix => {
          if (diff?.updates && matrix.code in diff!.updates) {
            return false
          }
          if (this._shouldRetainEntity(matrix, now)) {
            return false
          }
          return true
        })
        .map(matrix => matrix.code)

      if (codesToDelete.length)   {
        await tx.deleteByKeys(ProductMatrixStore, codesToDelete)
      }
    })
  }

  /* -- BrandVariants -- */
  private async _loadBrandVariants(ctx: SyncContext): Promise<void> {
    const maxUpdateTime = await maxOfAsync(
      this._storage.selectAll<IBrandVariant>(BrandVariantStore),
      x => x.updateTime
    )

    let newBrandVariants: IBrandVariant[]

    const PAGE_SIZE = 50

    try {
      newBrandVariants = await loadAllPagesInParallel<IBrandVariantsDto, IBrandVariant>(
        async pageNumber => ctx.getHttpClient().post<IBrandVariantsDto>('brand-variants/v1.0/get-list', {
          updatedAfter: maxUpdateTime,
          pageNumber,
          pageSize: PAGE_SIZE
        } as IBrandVariantFilterDto).then(response => response.data),
        page => page.items,
        page => page.pageCount
      )
    } catch (e) {
      this.logger.error('_loadBrandVariants', 'Error loading BrandVariants', e, (e as any)?.response?.data)
      throw SyncService.syncError(SyncErrorSource.LoadProductMatrixAssignments, 'Error getting BrandVariants', e)
    }

    // newBrandVariants?.forEach(x => SyncService.commitReceivedEntity(x))

    if (newBrandVariants?.length) {
      await this._storage.put(BrandVariantStore, newBrandVariants)
    }
  }

  /* -- ContractTermAssignments-- */
  private async _loadContractTermAssignments(ctx: SyncContext): Promise<void> {
    const maxUpdateTime = await maxOfAsync(
      this._storage.selectAll<IContractTermAssignment>(ContractTermAssignmentStore),
      x => x.updateTime
    )

    const now = new Date()

    const currentProfile = await this._profile.getCurrentProfile()
    const coverage = await this._getCoverage(currentProfile)

    let newContractTermAssignments: IContractTermAssignment[] = []

    if (coverage?.length) {
      const query: IContractTermAssignmentFilterDto = {
        posCodes: coverage.map(pos => pos.code),
        updatedAfter: maxUpdateTime,
        onlyActive: !maxUpdateTime
      }
      try {
        const response = await ctx.getHttpClient().post<IContractTermAssignment[]>('contract-terms/v1.0/get-assignment-list', query)
        newContractTermAssignments = response.data
      } catch (e) {
        this.logger.error('_loadContractTermAssignments', 'Error loading ContractTermAssignments', e, (e as any)?.response?.data)
        throw SyncService.syncError(SyncErrorSource.LoadContractTermAssignments, 'Error getting ContractTermAssignments', e)
      }

    } else {
      console.info('Empty POS coverage. Will not load ContractTermAssignments')
    }

    newContractTermAssignments?.forEach(x => SyncService.commitReceivedEntity(x))

    await this._storage.execute([ContractTermAssignmentStore], async tx => {
      if (newContractTermAssignments?.length) {
        await tx.put(ContractTermAssignmentStore, newContractTermAssignments)
      }

      const contractTermAssignments = await tx.getAll<IContractTermAssignment>(ContractTermAssignmentStore)
      const codesToDelete = contractTermAssignments
        .filter(cta => {
          if (!cta.pos || coverage.some(pos => pos.code === cta.pos!.code)) {
            return false
          }
          if (cta.isActive) {
            return false
          }
          if (this._shouldRetainEntity(cta, now)) {
            return false
          }
          return true
        })
        .map(pma => pma.code)

      if (codesToDelete.length)   {
        await tx.deleteByKeys(ContractTermAssignmentStore, codesToDelete)
      }
    })
  }

  /* -- ContractTerms --  */
  private async _loadContractTerms(ctx: SyncContext): Promise<void> {
    const query: IContractTermDiffQueryDto = {
      stock: {}
    }

    const now = new Date()

    let diff: IContractTermDiffDto | null = null

    for await (const pma of this._storage.selectAll<IContractTermAssignment>(ContractTermAssignmentStore)) {
      pma.assignedTerms?.forEach(term => {
        query.stock[term.contractTerm.code] = null
      })
    }

    if (!Object.keys(query.stock).length) {
      console.info('No ContractTerms to load')

    } else {
      for await (const contractTerm of this._storage.selectAll<IContractTerm>(ContractTermStore)) {
        if (contractTerm.code in query.stock) {
          const timestamp = contractTerm._changeTime ?? contractTerm.updateTime
          query.stock[contractTerm.code] = timestamp
        }
      }
      try {
        const response = await ctx.getHttpClient().post<IContractTermDiffDto>('contract-terms/v1.0/get-list', query)
        diff = response.data

      } catch (e) {
        this.logger.error('_loadContractTerms', 'Error loading ContractTerms', e, (e as any)?.response?.data)
        throw SyncService.syncError(SyncErrorSource.LoadContractTerms, 'Error getting ContractTerms', e)
      }
    }

    const newContractTerms = diff?.updates ? Object.values(diff!.updates).filter(Boolean) : null

    newContractTerms?.forEach(x => x && SyncService.commitReceivedEntity(x!))

    await this._storage.execute([ContractTermStore], async tx => {
      if (newContractTerms?.length) {
        await tx.put(ContractTermStore, newContractTerms)
      }

      const contractTerms = await tx.getAll<IContractTerm>(ContractTermStore)
      const codesToDelete = contractTerms
        .filter(contractTerm => {
          if (diff?.updates && contractTerm.code in diff!.updates) {
            return false
          }
          if (this._shouldRetainEntity(contractTerm, now)) {
            return false
          }
          return true
        })
        .map(contractTerm => contractTerm.code)

      if (codesToDelete.length)   {
        await tx.deleteByKeys(ContractTermStore, codesToDelete)
      }
    })
  }

  /* -- SupervisedVisits -- */

  private async _loadSupervisedVisits(ctx: SyncContext, executiveRole?: IPositionRoleReference): Promise<IDelta> {
    const stock: ISyncDeltaRequest['stock'] = {}
    for await (const svu of this._storage.selectAll<ISupervisedVisitUnit>(SupervisedVisitStore)) {
      stock[svu.visit.code] = svu._changeTime ?? svu.visit.updateTime
    }
    const request: ISyncDeltaRequest = {
      profile: ctx.currentProfileReference,
      positionRole: ctx.currentRoleReference,
      timestamp: Date.now(),
      stock
    }

    let delta: ISyncDeltaResponse<Code>
    try {
      const response = await ctx.getHttpClient().post<ISyncDeltaResponse<Code>>(
        'visits/supervised/delta-query', request,
        {params: {executiveRoleKey: executiveRole ? getEntityKey(executiveRole) : null}}
      )
      delta = response.data
    } catch (e) {
      this.logger.error('_loadSupervisedVisits', 'Failed requesting supervised visits delta', e, (e as any)?.response?.data)
      throw SyncService.syncError(SyncErrorSource.LoadSupervisedVisitsDelta, 'Error getting supervised visits delta', e)
    }

    if (!executiveRole && delta.remove?.length) {
      ctx.throwIfCancellationRequested()
      await this._storage.execute(
        [SupervisedVisitStore],
        async (tx) => {
          for (const visitCodeToRemove of delta.remove!) {
            if (visitCodeToRemove) {
              const svu = await tx.getByKey(SupervisedVisitStore, visitCodeToRemove)
              if (svu) {
                scanMediaRefsTaskSpecialHandling(svu).forEach(key => ctx.mediaToDelete.add(key))
                await tx.deleteByKey(SupervisedVisitStore, visitCodeToRemove)
              }
            }
          }
        })
    }

    // batch loading supervised visits
    const loadChunks = _.chunk([...(delta.update ?? [])].filter(x => x != null), SUPERVISED_VISITS_BATCH_SIZE)
    for (const chunk of loadChunks) {
      ctx.throwIfCancellationRequested()
      await this._loadSupervisedVisitsBatch(chunk, ctx)
    }

    return new Delta(Object.keys(stock), delta.update, delta.remove)
  }

  private async _loadSupervisedVisitsBatch(visitCodes: Code[], ctx: SyncContext): Promise<void> {
    this.logger.debug('_loadSupervisedVisitsBatch', `Loading ${visitCodes.length} Supervised Visits`)
    if (!visitCodes?.length) {
      return
    }
    let visitUnitDtos: ISupervisedVisitUnitDto[]
    try {
      const response = await ctx.getHttpClient().post<ISupervisedVisitUnitDto[]>(`visits/supervised/batch-query`, visitCodes)
      visitUnitDtos = response.data
    } catch (e: any) {
      this.logger.error(
        '_loadSupervisedVisits',
        `Error loading batch of Supervised Visits`,
        e, (e as any)?.response?.data
      )
      await this._storage.execute([SupervisedVisitStore], async (tx) => {
        const units = await tx.getByKeys<ISupervisedVisitUnit>(SupervisedVisitStore, visitCodes)
        units.forEach(svu => SyncService.commitReceivedEntity(svu, e))
        await tx.put(SupervisedVisitStore, units)
      })
      throw SyncService.syncError(SyncErrorSource.LoadSupervisedVisits, 'Error loading batch of Supervised Visits', e)
    }

    if (visitUnitDtos?.length) {
      if (visitCodes.length !== visitUnitDtos?.length) {
        this.logger.warn('_loadSupervisedVisitsBatch', `Requested ${visitCodes.length} visits, loaded ${visitUnitDtos?.length}`)
      }

      const visitUnits = visitUnitDtos.map(
        (dto) =>
          <ISupervisedVisitUnit>{
            visit: dto.visit,
            tasks: dto.tasks,
            surveys: dto.surveys,
            pos: dto.pos,
            employee: dto.employee,
            role: dto.role,
            _changeTime: dto.changeTime,
          },
      )
      visitUnits.forEach(svu => SyncService.commitReceivedEntity(svu))
      //TODO: should delete previous (existing) media references?
      await this._storage.put(SupervisedVisitStore, visitUnits)
      if (ctx.currentProfile?.loadMediaForSupervisedVisits === true) {
        for (const visitUnit of visitUnits) {
          if (checkLoadStartDate(visitUnit.visit, this._getLoadMediaStartDate(ctx))) {
            const deleteTime = getMediaDeleteTime(ctx.businessParameters?.purgeBlobsOlderThanDays ?? '3')
            scanMediaRefsTaskSpecialHandling(visitUnit).forEach((key) => {
              return ctx.mediaToReceive.set(key, {deleteAfter: deleteTime})
            })
          }
        }
      }
    }
  }

  static isDteProfile(profile?: IProfile): boolean {
    return profile?.code?.includes('Dte') ?? false
  }

  /* -- sync-- */

  private async _sync(
    mode: SyncMode,
    ctx: SyncContext,
    onProgress?: OperationProgressCallback
  ): Promise<void> {
    const profile = ctx.currentProfile
    const isDteProfile = SyncService.isDteProfile(profile)

    const saveSteps: Array<SyncStep | undefined> = isDteProfile
      ? [ // DTE
        /* none */
      ]
      : [ // SFA
        ['save-reports', 'Saving Reports', this._saveReports],
        ['save-visits', 'Saving Visits', this._saveVisits],
        ['save-nonvisittasks', 'Saving NonVisitTasks', this._saveNonVisitTasks],
        ['save-problems', 'Saving Problems', this._saveProblems],
      ]

    const loadSteps: Array<SyncStep | undefined> = isDteProfile
      ? [ // DTE
        ['load-questionnaires', 'Loading Questionnaires', this._loadQuestionnaires],
        ['load-dtetasktemplates', 'Loading DTETaskTemplates', this._loadDteTaskTemplates],
      ]
      : [ // SFA
        ['load-pos', 'Loading POS', this._loadPOS],
        ['load-tasktemplates', 'Loading TaskTemplates', this._loadTaskTemplates],
        ['load-questionnaires', 'Loading Questionnaires', this._loadQuestionnaires],
        profile?.loadVisits !== false ? ['load-visits', 'Loading Visits', this._loadVisits] : undefined,
        profile?.loadNonVisitTasks !== false ? ['load-nonvisittasks', 'Loading NonVisitTasks', this._loadNonVisitTasks] : undefined,
        profile?.loadTaskRegisters !== false ? ['load-taskregisters', 'Loading TaskRegisters', this._loadTaskRegisters] : undefined,
        //['Loading ISA', this._loadInternalStoreAudits],
        ['load-smservices', 'Loading SMServices', this._loadStoreManagerServices],
        ['load-dictionaries', 'Loading Dictionaries', this._loadDictionaries],
        ['load-hyperlinks', 'Loading Hyperlinks', this._loadHyperlinks],
        ['load-postaskregisters', 'Loading POSTaskRegisters', this._loadPOSTaskRegisters],
        ['load-postaskregisterexecutions', 'Loading POSTaskRegisterExecutions', this._loadPOSTaskRegisterExecutions],
        profile?.loadTaskTemplateContents !== false ? ['load-tasktemplatecontents', 'Loading TaskTemplateContents', this._loadTaskTemplateContents] : undefined,
        ['load-contentdocuments', 'Loading ContentDocuments', this._loadContentDocuments],
        profile?.loadProductMatrices !== false ? ['load-productmatrixassignments', 'Loading ProductMatrixAssignments', this._loadProductMatrixAssignments] : undefined,
        profile?.loadProductMatrices !== false ? ['load-productmatrices', 'Loading ProductMatrices', this._loadProductMatrices] : undefined,
        //['Loading BrandVariants', this._loadBrandVariants],
        profile?.loadContractTerms !== false ? ['load-contracttermassignments', 'Loading ContractTermAssignments', this._loadContractTermAssignments] : undefined,
        profile?.loadContractTerms !== false ? ['load-contractterms', 'Loading ContractTerms', this._loadContractTerms] : undefined,
        ['load-problemtemplates', 'Loading ProblemTemplates', this._loadProblemTemplates],
        profile?.loadVisitProblems !== false ? ['load-problems', 'Loading Problems', this._loadProblems] : undefined,
        profile?.loadSupervisedVisits !== false ? ['load-supervisedvisits', 'Loading SupervisedVisits', this._loadSupervisedVisits] : undefined,
        ['load-tasktemplatequestionnaireassignments', 'Loading TaskTemplateQuestionnaireAssignments', this._loadTaskTemplateQuestionnaireAssignments],
        profile?.loadPOSMetrics !== false ? ['load-posmetrics', 'Loading POSMetrics', this._loadPOSMetrics] : undefined,
        ['load-dteparticipantprofiles', 'Loading DTEParticipantProfiles', this._loadDteParticipantProfiles],
        ['load-visitassessments', 'Loading VisitAssessments', this._loadVisitAssessments],
        ['load-pposm', 'Loading PPOSM', this._loadPPOSM],
        ['load-posparticipantprograms', 'Loading POSParticipantPrograms', this._loadPOSParticipantPrograms],
        ['load-questionnaireposassignments', 'Loading QuestionnairePOSAssignments', this._loadQuestionnairePOSAssignments],
        profile?.loadSupervisedFieldPositionRoles === true
          ? ['load-supervisedfieldpositionroles', 'Loading SupervisedFieldPositionRoles', this._loadSupervisedFieldPositionRoles]
          : undefined,
        profile?.loadUserProfiles === true
          ? ['load-userprofiles', 'Loading FieldPositionRoleUserProfiles', this._loadFieldPositionRoleUserProfiles]
          : undefined,
      ]

    const minimalSteps: Array<SyncStep | undefined> = isDteProfile
      ? [ // DTE
        ['load-dtetasktemplates', 'Loading DTETaskTemplates', this._loadDteTaskTemplates],
      ]
      : [ // SFA
        ['load-tasktemplates', 'Loading TaskTemplates', this._loadTaskTemplates],
        ['load-nonvisittasks', 'Loading NonVisitTasks', this._loadNonVisitTasks],
      ]

    let steps
    switch (mode) {
      case 'Full':
        steps = [...saveSteps, ...loadSteps]
        break
      case 'SaveOnly':
        steps = saveSteps
        break
      case 'LoadOnly':
        steps = loadSteps
        break
      case 'Minimal':
        steps = minimalSteps
        break
    }
    steps = steps.filter(s => s != null) as SyncStep[]

    const stepProgress = 100 / (steps.length ?? 1)
    let progress = steps.length ? 0 : 100
    try {
      for (const [stepKey, stepMessage, stepAction] of steps) {
        ctx.currentStep = stepKey
        ctx.throwIfCancellationRequested()
        this.logger.debug('_sync', `${Math.trunc(progress)}% : ${stepMessage}...`)
        onProgress?.(progress, `${stepMessage}`)
        await stepAction.call(this, ctx)
        progress += stepProgress
      }
    } finally {
      ctx.currentStep = 'post-sync'
      this._postSync(ctx)
    }

    ctx.currentStep = undefined
    progress = 100
    this.logger.debug('_sync', `${Math.trunc(progress)}% : Done!`)
    onProgress?.(progress, 'Done')
  }

  private _postSync(ctx: SyncContext): void {
    // normalize collected media materials
    ctx.mediaToSend.forEach(key => {
      ctx.mediaToDelete.delete(key)
      ctx.mediaToReceive.delete(key)
    })
    ctx.mediaToReceive.forEach((_,key) => {
      ctx.mediaToDelete.delete(key)
    })

    // emit media materials events
    if (ctx.mediaToDelete.size > 0) {
      globalEventBus.emit(EVENT_NEED_REMOVE_BLOBS, Array.from(ctx.mediaToDelete))
    }
    if (ctx.mediaToReceive.size > 0) {
      globalEventBus.emit(EVENT_NEED_RECEIVE_BLOBS, Array.from(ctx.mediaToReceive))
    }
    if (ctx.mediaToSend.size > 0) {
      globalEventBus.emit(EVENT_NEED_SEND_BLOBS, Array.from(ctx.mediaToSend))
    }
  }

  private async _createSyncContext(cancellation?: ICancellationToken): Promise<SyncContext> {
    return new SyncContext(
      () => this._httpClientFactory.getHttpClient(),
      await this._getCurrentProfile(),
      await this._getCurrentRoleReference(),
      await this._getCurrentUserReference(),
      await this._getBusinessParameters(),
      cancellation
    )
  }

  private async _markSyncStart(): Promise<void> {
    try {
      await this._profile.setLastSyncAttempt(new Date())
    } catch (e) {
      // ignore
    }
  }

  private async _markSyncSuccess(): Promise<void> {
    try {
      await this._profile.setLastSyncSuccess(new Date())
    } catch (e) {
      // ignore
    }
  }

  public async sync(mode: SyncMode, onProgress?: OperationProgressCallback, cancellation?: ICancellationToken): Promise<void> {
    await this._markSyncStart()
    const syncTime = Date.now()
    const ctx = await this._createSyncContext(cancellation)
    try {
      await this._sync(mode, ctx, onProgress)
    } catch (e: any) {
      if (e instanceof SyncError || e instanceof OperationCancelledException) {
        throw e
      }
      this.logger.error('sync', 'Internal error during sync', e, { syncStep: ctx.currentStep })
      throw new SyncError(SyncErrorSource.Internal, SyncErrorCode.OtherError, 'Sync error', undefined, e)
    }
    this._lastSyncTime = syncTime
    await this._markSyncSuccess()
  }

  public async countPendingItems(): Promise<IPendingItemsSummary> {
    const countItems = async <T>(
      tx: IStorageOperations,
      storeName: string,
      changeTimeIndexName: string,
      predicate: (item: T) => boolean
    ): Promise<number> => {
      let n = 0
      for await(const item of tx.selectByIndexRange<T>(storeName, changeTimeIndexName, ['>=', 0])) {
        n += predicate(item) ? 1 : 0
      }
      return n
    }

    return await this._storage.execute([VisitStore, TaskStore, ProblemStore, TaskReportStore], async tx =>
        <IPendingItemsSummary>{
          visitCount: await countItems<IVisit>(tx, VisitStore, VisitStore_changeTime, SyncService.isNewOrChangedEntity),
          taskCount: await countItems<ITask>(tx, TaskStore, TaskStore_changeTime, t => !isInVisitTask(t) && SyncService.isNewOrChangedEntity(t)),
          problemCount: await countItems<IProblem>(tx, ProblemStore, ProblemStore_changeTime, SyncService.isNewOrChangedEntity),
          reportCount: await tx.count(TaskReportStore)
        },
      'r'
    )
  }

  public async hasPendingItems(): Promise<boolean> {
    const hasPendingProblems = async () : Promise<boolean> => {
      for await(const p of this._storage.selectByIndexRange<IProblem>(ProblemStore, ProblemStore_changeTime, ['>=', 0])) {
        if (SyncService.isNewOrChangedEntity(p)) return true
      }
      return false
    }
    return (
      (await this._storage.existsWhere<IVisit>(VisitStore, SyncService.isNewOrChangedEntity))
      || (await this._storage.existsWhere<ITask>(TaskStore, t => !isInVisitTask(t) && SyncService.isNewOrChangedEntity(t)))
      || (await hasPendingProblems())
    )
  }

  public async getPendingItems(): Promise<IPendingItems> {
    const getItems = async <T extends ISyncEntity>(
      tx: IStorageOperations,
      storeName: string,
      changeTimeIndexName: string,
      predicate: (item: T) => boolean
    ): Promise<T[]> => {
      const items = []
      for await(const item of tx.selectByIndexRange<T>(storeName, changeTimeIndexName, ['>=', 0])) {
        if (predicate(item)) items.push(item)
      }
      return items
    }

    return await this._storage.execute([VisitStore, TaskStore, ProblemStore], async tx =>
        <IPendingItems>{
          visits: await getItems<IVisit>(tx, VisitStore, VisitStore_changeTime, SyncService.isNewOrChangedEntity),
          tasks: await getItems<ITask>(tx, TaskStore, TaskStore_changeTime, t => !isInVisitTask(t) && SyncService.isNewOrChangedEntity(t)),
          problems: await getItems<IProblem>(tx, ProblemStore, ProblemStore_changeTime, SyncService.isNewOrChangedEntity)
        },
      'r'
    )
  }

  public async saveTaskReport(report: ITaskReport): Promise<string> {
    const reportKey = SyncService.makeTaskReportKey((report as IVisitTaskReport).visitCode, report.taskCode)
    report.code = reportKey
    report.updateTime = Date.now()
    await this._storage.put(TaskReportStore, report)
    const reportLink = await this._uploadTaskReport(report)
    await this._storage.deleteByKey(TaskReportStore, reportKey)
    return reportLink
  }

  public async uploadPendingTaskReport(visitCode: Code, taskCode: Code): Promise<string | null> {
    const reportKey = SyncService.makeTaskReportKey(visitCode, taskCode)
    const report = await this._storage.getByKey<IVisitTaskReport>(TaskReportStore, reportKey)
    if (report == null) {
      return null
    }
    const reportLink = await this._uploadTaskReport(report)
    await this._storage.deleteByKey(TaskReportStore, reportKey)
    return reportLink
  }

  public async tryUploadPendingVisit(visitCode: Code): Promise<boolean> {
    const visit = await this._storage.getByKey<IVisit>(VisitStore, visitCode)
    if (visit != null && SyncService.isNewOrChangedEntity(visit)) {
      const ctx = await this._createSyncContext()
      await this._saveVisit(visit, ctx)
      this._postSync(ctx)
      return true
    }
    return false
  }

  public async tryUploadPendingProblem(problemCode: Code): Promise<boolean> {
    const problem = await this._storage.getByKey<IProblem>(ProblemStore, problemCode)
    if (problem != null && SyncService.isNewOrChangedEntity(problem)) {
      const ctx = await this._createSyncContext()
      await this._saveProblem(problem, ctx)
      this._postSync(ctx)
      return true
    }
    return false
  }

  public async tryUploadPendingTask(taskCode: Code): Promise<boolean> {
    const task = await this._storage.getByKey<ITask>(TaskStore, taskCode)
    if (task != null && SyncService.isNewOrChangedEntity(task)) {
      if (isInVisitTask(task)) {
        console.warn(`Unable to save in-visit task ${taskCode} here`)
        return false
      }
      const ctx = await this._createSyncContext()
      const isDteProfile = SyncService.isDteProfile(ctx.currentProfile)
      if (isDteProfile) {
        console.warn('Cant save tasks by DteProfile')
        return false
      }
      await this._saveNonVisitTask(task, ctx)
      this._postSync(ctx)
      return true
    }
    return false
  }

  public async refreshSupervisedVisits(executiveRole?: IPositionRoleReference): Promise<number | undefined> {
    const ctx = await this._createSyncContext()
    const delta = await this._loadSupervisedVisits(ctx, executiveRole)
    this._postSync(ctx)
    return delta.added.length;
  }
}

async function loadAllPagesInParallel<Page, Result> (
  fetchPage: (pageNumber: number) => Promise<Page>,
  getPayload: (page: Page) => Result | Result[],
  getPageCount: (page: Page) => number
): Promise<Result[]> {

  const result: Result[] = []

  const addToResult = (page: Page): void => {
    const payload = getPayload(page)
    if (payload != null) {
      if (Array.isArray(payload)) {
        result.push(...payload)
      } else {
        result.push(payload)
      }
    }
  }

  const firstPage = await fetchPage(0)
  addToResult(firstPage)

  let pageCount = getPageCount(firstPage)
  if (!isFinite(pageCount) || pageCount < 1) {
    pageCount = 1
  }

  const pageNumbers = Array.from(Array(pageCount-1).keys())
  await Promise.all(pageNumbers.map(async i => addToResult(await fetchPage(i + 1))))

  return result
}