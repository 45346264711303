import React, { useEffect } from 'react'

import { Routes, Route } from 'react-router-dom'

import { Layout } from '../../layout'
import { ProblemPosSelectorPage, ProblemCreateSelectPage } from '../stores'
import ProblemsPage from '../stores/store-menu-page/problems/problems-page'

interface ProblemsAppFeatureProps {
  children?: never
}

export const ProblemsAppFeature: React.FC<ProblemsAppFeatureProps> = (props) => {
  useEffect(() => {
    console.log('ProblemsAppFeature mount', props)
  }, [])

  return (
    <Layout>
      <Routes>
        {/* <Route path='/stores/:posCode/addProblem' element={<ProblemCreatePage />} /> */}
        <Route path={relativeRoutes.root} element={<ProblemsPage />} />
        <Route path='problemPosSelector' element={<ProblemPosSelectorPage />} />
        <Route path='problemPosSelector/addProblem/:posCode' element={<ProblemCreateSelectPage />} />
      </Routes>
    </Layout>
  )
}
export const problemsRoutes = {
  root: '/:featureRoute',
} as const

const relativeRoutes = Object.fromEntries(
  Object.entries(problemsRoutes).map(([key, value]) => [key, value.replace(/^\/:featureRoute/, '')]),
) as typeof problemsRoutes
