import React, { forwardRef, useContext, useEffect, useImperativeHandle } from 'react'

import RouteMapMenuButton from '../../../../components/yandex-map-custom/components/route-map-menu-button'
import {MapMenu} from "../../../../components/yandex-map-custom/map-menu";
import { YandexMapPoint } from '../../../../components/yandex-map-custom/yandex-map-point'
import { YandexMapContext } from '../../../../components/yandex-map-custom/yandex-map-provider'
import { VisitStatus } from '../../../../model/visit'
import { appToast } from '../../../../utils'
import { VisitWithPosIndexed } from '../visits-map-by-day'

interface IVisitsYandexMapProps {
  visits: VisitWithPosIndexed[]
  selectVisitCode: string | undefined
  onSelect: (code: string | undefined) => void
}

export interface IVisitsYandexMapRef {
  focus: (visitCode: string) => void
}

const VisitsYandexMap: React.ForwardRefRenderFunction<IVisitsYandexMapRef, IVisitsYandexMapProps> = (
  { visits, selectVisitCode, onSelect },
  ref,
) => {
  const { ymapsAPI, instanceMap, setBounds, userGeolocation } = useContext(YandexMapContext)
  useImperativeHandle(
    ref,
    () => ({
      focus: (visitCode) => {
        const visit = visits.find((visit) => visit.code === visitCode)
        const latitude = visit?.pointOfSale?.coordinates?.latitude
        const longitude = visit?.pointOfSale?.coordinates?.longitude
        if (latitude && longitude) {
          void instanceMap?.setCenter([latitude, longitude])
          return
        }
        if (visitCode) {
          appToast.info('Координаты отсутствуют')
        }
      },
    }),
    [ymapsAPI],
  )

  const geoCoordinates: number[][] = []
  visits.forEach((visit) => {
    if (visit.pointOfSale?.coordinates) {
      const coordinates = [visit.pointOfSale?.coordinates?.latitude, visit.pointOfSale?.coordinates?.longitude]
      geoCoordinates.push(coordinates)
    }
  })

  useEffect(() => {
    setBounds(geoCoordinates)
  }, [])

  const createIconLayout = (index: number, isActive: boolean, status: VisitStatus): ymaps.layout.templateBased.Base => {
    const iconLayout = ymapsAPI?.templateLayoutFactory.createClass(
      `<div class='visit-placemark visit-placemark--${status} ${
        isActive ? 'visit-placemark--active' : ''
      }'>${index}</div>`,
      {},
    )

    return iconLayout
  }

  const reverseVisits = [...visits].reverse() // Реверс чтобы точки рендерелись в обратном порядке и наименьший индекс был выше в DOM

  return (
    <>
      {reverseVisits.map((visit, index) => {
        const isActive = visit.code === selectVisitCode
        const icon = createIconLayout(visit.index, isActive, visit.status)
        const coordinates = visit.pointOfSale?.coordinates
        if (!coordinates) return <></>

        return (
          <YandexMapPoint
            key={`${visit.code}${isActive}`}
            code={visit.code}
            coordinates={coordinates}
            icon={icon}
            onClick={() => {
              onSelect(visit.code)
            }}
            zIndex={isActive ? 210 : 110 + index}
          />
        )
      })}
      <MapMenu>
        <RouteMapMenuButton
          visits={reverseVisits}
          userGeolocation={{
            userLatitude: userGeolocation.latitude,
            userLongitude: userGeolocation.longitude,
          }}
        />
      </MapMenu>
    </>
  )
}

export default forwardRef(VisitsYandexMap)
