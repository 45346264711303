import React from 'react'

import { createStyles, Divider, Grid, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { grays } from '../../../../layout/theme'
import { IPPOSM } from '../../../../model/pposm'
import { useIsSmall } from '../../../_common/hooks/useIsSmall'
import { CardItemWrapper } from '../../components/card-item-wrapper'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalLine: {
      margin: 0,
      backgroundColor: grays.gray5,
      width: 2,
    },
    nameCol: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      alignItems: 'center',
      maxWidth: '47%',
      flex: '0 0 47%',
      paddingRight: '7%',
    },
    typeCol: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '23%',
      flex: '0 0 23%',
      paddingRight: '7%',
      paddingLeft: '7%',
    },
    statusCol: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '29%',
      flex: '0 0 29%',
      paddingLeft: '8%',
    },
    name: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.1px',
    },
    code: {
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: '0.25px',
      color: grays.gray2,
      [theme.breakpoints.down('xs')]: {
        color: 'inherit',
        lineHeight: '22px',
      },
    },
    type: {
      width: '100%',
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: '0.1px',
      [theme.breakpoints.down('xs')]: {
        lineHeight: '20px',
      },
    },
    status: {
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: grays.gray2,
    },
  }),
)

interface IEquipmentItem {
  pposm: IPPOSM
  onClick: () => void
}

export const PposmItem: React.FC<IEquipmentItem> = ({ pposm, onClick }) => {
  const classes = useStyles()
  const isSmall = useIsSmall()

  const serialText = pposm.serial ?? pposm.serialNumber ?? null

  if (isSmall) {
    return (
      <CardItemWrapper onClick={onClick}>
        <Typography noWrap className={classes.name}>
          {pposm.name}
        </Typography>

        <Typography noWrap className={classes.code}>
          {serialText ? `${serialText} | ` : ''}
          {pposm.type?.name}
        </Typography>
        <Typography noWrap className={classes.status}>
          {pposm.state.status}
        </Typography>
      </CardItemWrapper>
    )
  }

  return (
    <CardItemWrapper onClick={onClick}>
      <Grid container>
        <Grid item className={classes.nameCol}>
          <Typography noWrap className={classes.name}>
            {pposm.name}
          </Typography>
          {serialText && (
            <Typography noWrap className={classes.code}>
              {serialText}
            </Typography>
          )}
        </Grid>
        {<Divider orientation='vertical' flexItem className={classes.verticalLine} />}
        <Grid item className={classes.typeCol}>
          <Typography noWrap className={classes.type} align='center'>
            {pposm.type?.name ?? '-'}
          </Typography>
        </Grid>
        {<Divider orientation='vertical' flexItem className={classes.verticalLine} />}
        <Grid item className={classes.statusCol}>
          <Typography noWrap className={classes.status}>
            {pposm.state.status}
          </Typography>
        </Grid>
      </Grid>
    </CardItemWrapper>
  )
}
