import React, { useEffect } from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Paper,
  Theme,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import classnames from 'classnames'

import { LogManager } from '../../infrastructure/logger'

const PAGE_BACKGROUND_COLOR = 'rgba(0, 61, 165, 0.02)'

const useStyles = makeStyles((theme) => ({
  wrap: {
    height: '100%',
    background: PAGE_BACKGROUND_COLOR
  },
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  paper: {
    width: '100%',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 24
  },
  isSmall: {
    height: '100%',
    borderRadius: 'unset'
  },
  formWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    marginBottom: 16
  },
  resetBtn: {
    marginBottom: 16
  }
}))

interface IErrorPageProps {
  error: Error
  resetErrorBoundary: () => void
}

const logger = LogManager.getLogger('ErrorBoundary')

/**
 * Страница отображается в случае непредвиденной ошибки автоматически
 */
const ErrorPageInternal: React.FC<IErrorPageProps> = ({ error, resetErrorBoundary }) => {
  useEffect(() => {
    const message = getErrorMessage(error)
    logger.critical('Global JS Error', message, error)
  }, [error])
  const classes = useStyles()
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'))

  return (
    <Box className={classes.wrap}>
      <Container disableGutters fixed className={classes.root} component='main' maxWidth='sm'>
        <Paper
          elevation={10}
          className={classnames(classes.paper, {
            [classes.isSmall]: isSmall
          })}
        >
          <Container fixed className={classes.formWrap} component='section' maxWidth='xs'>
            <Typography variant='h4' className={classes.title}>
              Произошла ошибка
            </Typography>

            <Button variant='contained' color='primary' onClick={resetErrorBoundary} className={classes.resetBtn}>
              Перейти на главную страницу
            </Button>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                <Typography>Детали ошибки</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{getErrorMessage(error)}</Typography>
              </AccordionDetails>
            </Accordion>
          </Container>
        </Paper>
      </Container>
    </Box>
  )
}

function getErrorMessage(error: unknown): string {
  let message = ''
  if (error && typeof error === 'object' && 'message' in error) {
    message = (error as Error).message
  }
  return message
}

export default ErrorPageInternal
