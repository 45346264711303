import React from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  createStyles,
  makeStyles,
  SvgIcon,
  Theme,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { nanoid } from 'nanoid'

import { ReactComponent as ExclamationMarkIcon } from '../../../../../assets/icons/exclamation-mark.svg'
import { ReactComponent as ExclamationWarnIcon } from '../../../../../assets/icons/exclamation-warn.svg'
import { LogManager } from '../../../../../infrastructure/logger'
import { grays } from '../../../../../layout/theme'
import { FaceMimeContentRef, IContent, IMimeContentRef, MimeContentRef } from '../../../../../model/content'
import { ITheoryBlock } from '../../../../../model/theory-block'
import { appToast } from '../../../../../utils'
import { ItemCard } from '../../../template-tasks/composite-screen/item-card'
import { useTheoryContent } from './utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: '0.15px',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    summary: {
      alignItems: 'center',
    },
    summaryRoot: {
      padding: '0 22px',
      borderBottom: `1px solid ${grays.gray5}`,
      [theme.breakpoints.down('xs')]: {
        padding: '0 14px',
      },
      '&:not(.Mui-expanded)': {
        marginBottom: -1,
      },
    },
    details: {
      padding: '16px 24px',
      [theme.breakpoints.down('xs')]: {
        padding: '16px',
      },
    },
    card: {
      border: `1px solid ${grays.gray5}`,
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(0.5),
      },
      '&.Mui-expanded, &.Mui-expanded:last-child': {
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
          marginBottom: theme.spacing(0.5),
        },
      },
      '&::before': {
        content: 'none',
      },
    },
    image: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    contentItem: {
      '&:not(:last-child)': {
        paddingBottom: 8,
      }
    },
  }),
)

const logger = LogManager.getLogger('TheoryBlock')

interface IProps {
  theoryBlock: ITheoryBlock
}

export const TheoryBlock: React.FC<IProps> = ({ theoryBlock }) => {
  const classes = useStyles()

  function getIcon(content: IContent | undefined): JSX.Element {
    const ref = content?.parts.find(
      (part): part is MimeContentRef | FaceMimeContentRef => (part as IMimeContentRef).target != null,
    )
    if (ref?.target?.includes('orange')) {
      return <SvgIcon color='error' className={classes.icon} component={ExclamationWarnIcon} />
    }
    return <SvgIcon color='primary' className={classes.icon} component={ExclamationMarkIcon} />
  }

  const contentOps = useTheoryContent(theoryBlock.content, [])

  if (contentOps.error) {
    const error = contentOps.error

    logger.error('useTheoryContent', error.message, error)
    appToast.error(`Ошибка при получении контента теоретического блока`)
  }

  const renderContent = (): JSX.Element => {
    if (contentOps.loading) {
      return <CircularProgress />
    }

    if (!contentOps.loading && contentOps.value?.length) {
      return (
        <>
          {contentOps.value.map((item) => (
            <Box key={nanoid()} className={classes.contentItem}>
              {item.type === 'text' ? (
                <Typography key={nanoid()} variant='body2'>
                  {item.content}
                </Typography>
              ) : (
                <img className={classes.image} src={item.content} alt='' />
              )}
            </Box>
          ))}
        </>
      )
    }

    return <Typography variant='body2'>Нет данных</Typography>
  }

  if (theoryBlock.showHeader === false) {
    return <ItemCard>{renderContent()}</ItemCard>
  }

  return (
    <Accordion className={classes.card} square elevation={0}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{ content: classes.summary, root: classes.summaryRoot }}
      >
        {getIcon(theoryBlock.content)}
        <Typography className={classes.description}>{theoryBlock.description}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <Box letterSpacing='0.25px' whiteSpace='pre-wrap'>
          {renderContent()}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}
