import React, { useContext, useEffect, useMemo, useState } from 'react'

import { Fade, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core'
import classnames from 'classnames'
import { format } from 'date-fns'
import { template, TemplateSettings } from 'lodash'
import { useAsync } from 'react-use'

import { JustifyFlexLayoutContent, JustifyLayoutContent } from '../../../../../model/composite-screen-item-layout'
import { IIFrameScreenItem } from '../../../../../model/iframe-screen-item'
import { ApiContext } from '../../../../../providers'
import { useIsSmall } from '../../../../_common/hooks/useIsSmall'
import { getContextScreens } from '../../../nested/local-context'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { ItemCard } from '../item-card'
import { buildRequest } from './build-request'
import { ErrorDetails } from './error-details'
import { formRequest } from './form-request'

const PADDING = 4
const HEADER_MOBILE_SIZE = 64
const HEADER_DESKTOP_SIZE = 88
const MARGIN_BOTTOM = 96
const STEPS_HEIGHT = 84

interface IFrameScreenItemProps {
  item: IIFrameScreenItem
  children?: never
}

interface IStylesProps {
  fullScreen: boolean
  isSmall: boolean
  hasTitle: boolean
  multipleScreens: boolean
  itemWidth: number
  horizontalAlignment: JustifyLayoutContent
}

const itemHeight = (props: IStylesProps): string => {
  if (!props.fullScreen) {
    return '100%'
  }
  let padding = PADDING + MARGIN_BOTTOM + (props.isSmall ? HEADER_MOBILE_SIZE : HEADER_DESKTOP_SIZE)
  if (props.multipleScreens) {
    padding += STEPS_HEIGHT
  }
  return `calc(100vh - ${padding}px)`
}

const useStyles = makeStyles<Theme, IStylesProps>((theme) => ({
  root: {
    '& .MuiPaper-root': {
      height: (props) => itemHeight(props),
      display: 'flex',
      flexDirection: 'column',
      marginBottom: (props) => (props.fullScreen ? 0 : null),
      width: ({ itemWidth, fullScreen }) => (fullScreen ? `100%` : `${itemWidth ?? 100}%`),
    },
    '& .MuiPaper-root > div:nth-child(1)': {
      flex: ({ hasTitle }) => (hasTitle ? '0 1' : 1),
      padding: ({ fullScreen, hasTitle }) => (fullScreen && !hasTitle ? 0 : null),
    },
    '& .MuiPaper-root > div:nth-child(2)': {
      flex: 1,
      padding: ({ fullScreen }) => (fullScreen ? 0 : null),
    },
  },
  fullScreenMargin: { marginLeft: '-24px', marginRight: '-24px', marginBottom: '-96px' },
  alignContent: {
    display: 'flex',
    justifyContent: ({ horizontalAlignment }) => JustifyFlexLayoutContent[horizontalAlignment],
  },
}))

export const IFrameScreenItem: React.FC<IFrameScreenItemProps> = ({ item }) => {
  useEffect(() => {
    console.log('IFrameScreenItem mount', item)
  }, [])

  const {
    requestId: receiver,
    error: requestError,
    loading: requestLoading,
  } = useFormRequest(
    { item },
    {
      onSuccess() {
        setIframeLoading(true)
      },
      onError(error) {
        console.error(error)
      },
    },
  )

  const [iframeLoading, setIframeLoading] = useState(false)

  const loading = requestLoading || iframeLoading

  const isSmall = useIsSmall()
  const fullScreen = useMemo(() => !(item.height && item.height !== 0), [item.height])
  const context = useScriptTaskContext()
  const multipleScreens = Object.keys(getContextScreens(context)).length > 1
  const { desktopLayout, mobileLayout } = item
  const { width, horizontalAlignment } = useMemo(() => (isSmall ? mobileLayout ?? {} : desktopLayout ?? {}), [isSmall])
  const classes = useStyles({
    isSmall,
    fullScreen,
    hasTitle: !!item.displayName,
    multipleScreens,
    itemWidth: width,
    horizontalAlignment,
  })

  return (
    <div
      className={classnames(classes.root, {
        [classes.fullScreenMargin]: fullScreen,
        [classes.alignContent]: !!width && !fullScreen,
      })}
    >
      <ItemCard label={item.displayName && <Typography variant='inherit'>{item.displayName}</Typography>}>
        {loading && (
          <Fade in={loading}>
            <LinearProgress variant='query' />
          </Fade>
        )}

        <iframe
          name={receiver}
          width='100%'
          onLoad={() => setIframeLoading(false)}
          hidden={Boolean(requestError)}
          style={{ border: 'none', height: fullScreen ? '100%' : item.height }}
        />

        {requestError && <ErrorDetails error={requestError} />}
      </ItemCard>
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function useFormRequest(
  props: { item: IIFrameScreenItem },
  options?: {
    onSuccess?: () => void
    onError?: (error: unknown) => void
  },
) {
  const api = useContext(ApiContext)
  const context = useScriptTaskContext()

  const [requestId] = useState(() => `form-request-${Math.random()}`)

  const state = useAsync(async () => {
    const buildRequest_ = buildRequest({
      interpolate(value) {
        const templateSettings: TemplateSettings = {
          interpolate: /{{([\s\S]+?)}}/g,
          escape: /($^)/,
          evaluate: /($^)/,
        }
        const contextWithTimestamp = {
          ...context,
          timestamp: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        }
        return template(value, templateSettings)(contextWithTimestamp)
      },
      async encrypt(items, encryption) {
        return api.encryption.encryptKeyValuePairs({
          encryption: encryption,
          items: items.map((pair) => ({ key: pair.key, value: pair.value })),
        })
      },
    })

    try {
      const request = await buildRequest_(props.item)
      formRequest(request, requestId)
      options?.onSuccess?.()
    } catch (error) {
      options?.onError?.(error)
      throw error
    }
  }, [props.item])

  return { ...state, requestId }
}
