export interface IDisposable {
  dispose: () => void
}

export class ObjectDisposedError extends Error {
  constructor() {
    super('Object disposed')
  }
}

export function isDisposable(obj: unknown): obj is IDisposable {
  if (obj === undefined || obj === null) {
    return false
  }
  if (typeof obj !== 'object') {
    return false
  }

  return typeof (obj as IDisposable).dispose === 'function'
}
