import React, { forwardRef, useCallback, useEffect, useImperativeHandle } from 'react'

import { Box, FormControl, FormHelperText, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useMap } from 'react-use'

import { LogManager } from '../../../../../infrastructure/logger'
import { SETaskRepresentativeScreenItem } from '../../../../../model/screen-item'
import { IThirdPartyRepresentative } from '../../../../../services/employee-service-api'
import { appToast } from '../../../../../utils'
import { useBatchUpdate } from '../../../nested/useBatchUpdate'
import { RepresentativeAutocompleteControl } from '../../../sales-expert/components/controls'
import { validateEmail } from '../../../sales-expert/tabs-new/validators'
import { getTaskProperty } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { ScreenRef } from '../../types'
import { ItemCard } from '../item-card'
import { StringItem } from '../string-item'
import { getTaskRepresentativeMockItems } from './task-representative-utils'

interface ITaskRepresentativeForm {
  name: string
  agency: string
  email: string
}

interface Props {
  item: SETaskRepresentativeScreenItem
}

const logger = LogManager.getLogger('TaskRepresentativeScreenItem')

export const TaskRepresentative = forwardRef<ScreenRef, Props>(function TaskRepresentative({ item }, ref) {
  const propertiesContext = useScriptTaskContext()
  const batchUpdate = useBatchUpdate()
  const { t } = useTranslation('sales-expert-tasks')
  // console.log(propertiesContext, item)

  const mockItems = getTaskRepresentativeMockItems(item, t)

  const inputs = {
    name: getTaskProperty(propertiesContext.task, item.representativeNamePropertyName, ''),
    agency: getTaskProperty(propertiesContext.task, item.agencyNamePropertyName, ''),
    email: getTaskProperty(propertiesContext.task, item.represenatativeEmailPropertyName, ''),
  }

  const [formValues, { set: setFormValues, get: getFormValue }] = useMap<ITaskRepresentativeForm>(inputs)

  type TouchedState = { [key in keyof ITaskRepresentativeForm]: boolean }
  const [, { set: setTouchedState, get: getFieldTouched, setAll: setAllTouched }] = useMap<TouchedState>({
    name: false,
    agency: false,
    email: false,
  })

  useImperativeHandle(
    ref,
    () => {
      return {
        validate: () => {
          setAllTouched({
            name: true,
            agency: true,
            email: true,
          })
        },
      }
    },
    [],
  )

  const handleSelectRepresentative = useCallback((value: IThirdPartyRepresentative | null): void => {
    if (!value) {
      return
    }
    setFormValues('name', value.name ?? '-')
    setFormValues('agency', value.agency ?? '-')
    setFormValues('email', value.eMails?.[0]?.address ?? '-')
  }, [])

  const handleChangeInputRepresentative = useCallback((value: string | null): void => {
    setFormValues('name', value ?? '')
  }, [])

  const onChangeRepresentativeInfoHandler = async (formValues: ITaskRepresentativeForm): Promise<void> => {
    const taskCode = propertiesContext.task.code
    try {
      await batchUpdate({
        ['task.' + item.representativeNamePropertyName]: formValues.name,
        ['task.' + item.agencyNamePropertyName]: formValues.agency,
        ['task.' + item.represenatativeEmailPropertyName]: formValues.email,
      })
      // appToast.success('Основаная информация о задаче изменена')
    } catch (e) {
      const message = t('taskChangeRepresentativeInfoError')
      logger.error('onChangeRepresentativeInfoHandler', message, e, { taskCode, formValues })
      appToast.error(message)
    }
  }

  useEffect(() => {
    if (formValues.name === inputs.name && formValues.agency === inputs.agency && formValues.email === inputs.email) {
      console.log('VALUES NOT CHANGED')
      return
    }
    void onChangeRepresentativeInfoHandler(formValues)
  }, [formValues.name, formValues.agency, formValues.email])

  const isFilled = (field: keyof ITaskRepresentativeForm): boolean => {
    const formValue = getFormValue(field)
    if (formValue) {
      if (typeof formValue === 'string') {
        return !!formValue.trim()
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const isError = (field: keyof ITaskRepresentativeForm): boolean => {
    const isTouched = !!getFieldTouched(field)
    return isTouched && !isFilled(field)
  }

  const readOnly = item.actionKind === 'View'
  const emailEmpty = isError('email')

  return (
    <>
      <FormControl margin='none' variant='outlined' fullWidth onBlur={() => setTouchedState('name', true)}>
        <ItemCard
          isError={!isFilled('name')}
          label={<Typography variant='inherit'>{mockItems.name.displayName}</Typography>}
        >
          <Box my={inputExtraMargin}>
            <RepresentativeAutocompleteControl
              usePlaceholder
              // ref={nameRef}
              disabled={readOnly}
              inputValue={formValues.name}
              onInputChange={handleChangeInputRepresentative}
              onSelect={handleSelectRepresentative}
              error={isError('name')}
            />
          </Box>
          {isError('name') && <FormHelperText error>{t('requiredFieldError')}</FormHelperText>}
        </ItemCard>
      </FormControl>
      <StringItem
        item={mockItems.agency}
        isFilled={isFilled('agency')}
        value={formValues.agency}
        onChange={(ev) => setFormValues('agency', ev.target.value)}
        isError={isError('agency')}
        errorMessage={t('requiredFieldError')}
        onBlur={() => setTouchedState('agency', true)}
      />
      <StringItem
        item={mockItems.email}
        isFilled={isFilled('email')}
        value={formValues.email}
        onChange={(ev) => setFormValues('email', ev.target.value)}
        isError={emailEmpty || !validateEmail(formValues.email)}
        errorMessage={emailEmpty ? t('requiredFieldError') : t('emailInputError')}
        onBlur={() => setTouchedState('email', true)}
      />
    </>
  )
})

const inputExtraMargin = { xs: 0, sm: 1 }
