import { useTheme } from '@material-ui/core'
import { Palette, PaletteColor } from '@material-ui/core/styles/createPalette'

import { grays, TEXT_BLACK } from '../../../../../../layout/theme'
import { ILabelCell, ITableScreenItem } from '../../../../../../model/table-screen-item'
import { formatTemplateString } from '../../../../../../utils/format-template-string'
import { getContextProperty, getPropertyAny } from '../../../../script-tasks/propertyName'
import { IScriptTaskContext } from '../../../../script-tasks/script-task-context'
import { useGetStringValueBase } from '../../get-string-value-base'
import { ITableRecord } from '../editorUtils'


export function formatLabelCell(
  control: ILabelCell,
  localContext: IScriptTaskContext,
  record: ITableRecord,
): string | undefined {
  if (!control.format) return

  let validation = false
  const resultString = formatTemplateString(control.format, (propertyName) => {
    const value = getPropertyAny<string>(record, propertyName) ?? getContextProperty(localContext, propertyName, '')
    if (value) {
      validation = true
    }
    return value
  })
  if (validation) {
    return resultString
  }
  return control.nullValueCaption
}

export function useColorLabelCell(
  tableItem: ITableScreenItem,
  control: ILabelCell,
  localContext: IScriptTaskContext,
  record: ITableRecord,
): string {
  const theme = useTheme()
  let textColor

  if (control.textColor) {
    let validation = false
    const resultString = formatTemplateString(control.textColor, (propertyName) => {
      const value = getPropertyAny<string>(record, propertyName) ?? getContextProperty(localContext, propertyName, '')
      if (value) {
        validation = true
      }
      return value
    })

    textColor = validation ? resultString : control.textColor
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    textColor = useGetStringValueBase(
      localContext,
      record,
      tableItem.tableStyle?.rowStyle?.textColor,
      `$type: ${tableItem.$type}, propertyName: ${tableItem.propertyName}, location: tableItem.tableStyle.rowStyle.textColor`,
    )
  }

  if (!textColor) {
    return 'inherit'
  }

  if (textColor in grays) {
    return grays[textColor as keyof typeof grays]
  }

  if (textColor in theme.palette) {
    return (theme.palette[textColor as keyof Palette] as PaletteColor)?.main
  }

  if (textColor === 'main') {
    return TEXT_BLACK
  }

  return textColor
}
