import React from 'react'

import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

interface SkeletonProps {
  children?: never
}

export const DtePageSkeleton: React.FC<SkeletonProps> = (props) => {
  return (
    <Box p={3}>
      <Skeleton variant='rect' width='100%' height={90} />
      <Box my={2}>
        <Skeleton variant='rect' width='100%' height={90} />
      </Box>
      <Skeleton variant='rect' width='100%' height={90} />
    </Box>
  )
}
