import React, { useContext } from 'react'

import { createStyles, Link, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { ApiContext } from '../../../../providers'
import { ErrorPage } from '../../../error-page'
import { ItemCard } from '../../../tasks/template-tasks/composite-screen/item-card'
import { InfoPageWrapper } from '../../components/info-page-wrapper'
import { IStoreMenuLocationState } from '../store-menu-page'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'grid',
      gridTemplateColumns: '33fr 67fr',
      columnGap: 10,
      marginBottom: 16,
      '& > *': {
        minWidth: 0,
      },
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
  }),
)

export const ParticipantInfo: React.FC = () => {
  const classes = useStyles()
  const api = useContext(ApiContext)
  const navigate = useNavigate()
  const location = useLocation()
  const { participantCode, posCode } = useParams<{ participantCode: string; posCode: string }>()
  const state = location.state as IStoreMenuLocationState

  const { prevParticipantPath, prevPath } = state

  const onBackHandler = (): void => navigate(prevParticipantPath ?? prevPath, { state })

  const participantOps = useAsync(async () => api.dte.getParticipantProfile(participantCode!))

  if (participantOps.error) {
    return <ErrorPage errorMessage={participantOps.error.message} />
  }

  if (participantOps.loading && !participantOps.value) return <></>

  const participant = participantOps.value!
  const posRelations = participant.poSes.find((pos) => pos.productLocation.code === posCode)
  console.log(participant)
  return (
    <InfoPageWrapper title={`${participant.contact.surname} ${participant.contact.name}`} onBack={onBackHandler}>
      <ItemCard label={'Информация об участнике'}>
        <div className={classes.row}>
          <Typography color='textSecondary'>ФИО</Typography>
          <Typography>
            {participant.contact.surname} {participant.contact.name} {participant.contact.middleName}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography color='textSecondary'>Код</Typography>
          <Typography>{participant.code}</Typography>
        </div>
        <div className={classes.row}>
          <Typography color='textSecondary'>Статус профиля</Typography>
          <Typography>{participant.status}</Typography>
        </div>
        <div className={classes.row}>
          <Typography color='textSecondary'>Статус участника на ТТ </Typography>
          <Typography>{posRelations?.status ?? '-'}</Typography>
        </div>
        <div className={classes.row}>
          <Typography color='textSecondary'>Тип профиля</Typography>
          <Typography>{participant.type}</Typography>
        </div>
        <div className={classes.row}>
          <Typography color='textSecondary'>Тип контакта</Typography>
          {/*eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing*/}
          <Typography>{posRelations?.roles?.map((role) => role.name).join('; ') || '-'}</Typography>
        </div>
        <div className={classes.row}>
          <Typography color='textSecondary'>Номер телефона</Typography>
          <Typography>{participant.contact.phoneNumbers?.[0].number ?? '-'}</Typography>
        </div>
        <div className={classes.row}>
          <Typography color='textSecondary'>Ссылка на профиль в XRM</Typography>
          <Link href={`https://te.pmixrm.com/#/account/profileCode/${participant.code}`}>
            <Typography
              style={{ wordWrap: 'break-word' }}
            >{`https://te.pmixrm.com/#/account/profileCode/${participant.code}`}</Typography>
          </Link>
        </div>
      </ItemCard>
    </InfoPageWrapper>
  )
}
