/* eslint-disable @typescript-eslint/method-signature-style */

import { BlobMetadata, BlobStorageErrorCode } from '../infrastructure/blob-storage/blob-storage-api'
import { InjectionToken } from '../infrastructure/di'
import { BusinessError } from '../model/errors'

export interface IBlobWebApi {
  getObjectUrl(collection: string, path: string): string
  get(collection: string, path: string): Promise<Blob | undefined>
  update(collection: string, path: string, blob: Blob): Promise<OkStrorageResponse>
  add(collection: string, blob: Blob): Promise<BlobMetadata>
}
export const BLOB_WEB_API = new InjectionToken<IBlobWebApi>('blob-web-api')

export interface OkStrorageResponse {
  message: string
}

export interface ErrorStorageResponse {
  error: string
}

export class BlobApiError extends BusinessError<BlobStorageErrorCode> {
  name = 'BlobApiError'
}