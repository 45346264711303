import React from 'react'

import { Box } from '@material-ui/core'

import { EmptyMessage } from '../../../../../components'
import { IStoreManagerServiceCategory } from '../../../../../model/sm-service'
import { getPercentByCategory } from '../utils'
import SMResultItem from './sm-report-item'
import SMSectionTitle from './sm-section-title'

interface SmReportProps {
  reportData: IStoreManagerServiceCategory[] | undefined
}

const SmReport: React.FC<SmReportProps> = (props) => {
  if (!props.reportData?.length) {
    return <EmptyMessage message='Отчет пуст' marginTop={4} />
  }

  return (
    <section>
      {props.reportData
        .sort((a, b) => a.orderNumber - b.orderNumber)
        .map((group) => {
          return (
            <Box marginY={1} key={`${group.name}_${group.orderNumber}`}>
              <SMSectionTitle title={group.name ?? ''} subTitle={getPercentByCategory(group)} />
              <Box paddingX={3}>
                {group.skillMarks
                  ?.sort((a, b) => a.orderNumber - b.orderNumber)
                  .map((groupItem) => (
                    <SMResultItem
                      key={`${group.name}_${group.orderNumber}_${groupItem.orderNumber}`}
                      title={groupItem.questionText ?? '-'}
                      result={groupItem.answerText ?? '-'}
                      comment={groupItem.comment ?? ''}
                      answerMark={groupItem.answerMark}
                    />
                  ))}
              </Box>
            </Box>
          )
        })}
    </section>
  )
}

export default SmReport
