import {
  QuestionnairePOSAssignmentStore, QuestionnairePOSAssignmentStore_questionnaire,
  QuestionnaireStore,
  TaskTemplateQuestionnaireAssignmentStore,
  TaskTemplateStore
} from "../../data/schema"
import {Code, IEntityReference, isReferenceToEntityIgnoreVersion} from "../../model/base"
import {ValidationError, ValidationErrorCode} from "../../model/errors"
import {IQuestionnaire} from "../../model/questionnaire"
import {IQuestionnairePOSAssignment} from "../../model/questionnaire-pos-assignment"
import {ITaskTemplate} from "../../model/task-template"
import {ITaskTemplateQuestionnaireAssignment} from "../../model/task-template-questionnaire-assignment"
import {IQuestionnaireService, TaskTemplateQuestionnaireAssignmentSearchRequest} from "../questionnaire-service-api"
import {LocalStorageBaseService} from "./local-storage-base-service"

export default class LocalStorageQuestionnaireService extends LocalStorageBaseService implements IQuestionnaireService
{
  private static readonly __className = 'LocalStorageQuestionnaireService'

  async getQuestionnaireCodeByTaskType(taskTemplateVersionCode: Code): Promise<Code | null> {
    if (!taskTemplateVersionCode) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing,
        'taskTemplateVersionCode is required parameter',
        ['taskTemplateVersionCode']
      )
    }
    const taskTemplate = await this._storage.getByKey<ITaskTemplate>(TaskTemplateStore, taskTemplateVersionCode)
    return taskTemplate?.questionnaireCode ?? null
  }

  async getQuestionnaire(questionnaireCode: Code): Promise<IQuestionnaire | null> {
    if (!questionnaireCode) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing,
        'questionnaireCode is a required parameter',
        ['questionnaireCode']
      )
    }
    return this._getQuestionnaire(questionnaireCode, this._storage)
  }

  async getQuestionnaireByKey(ref: IEntityReference): Promise<IQuestionnaire | null> {
    if (!ref.code) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing,
        'questionnaireCode is required parameter',
        ['questionnaireCode']
      )
    }
    if (!ref.codeSpace) {
      return await this.getQuestionnaire(ref.code)
    }
    const questionnaireByCode = await this._storage.getWhere<IQuestionnaire>(
      QuestionnaireStore, t => isReferenceToEntityIgnoreVersion(ref, t)
    )
    return questionnaireByCode.at(0) ?? null
  }

  async getQuestionnaires(questionnaireCodes: Code[]): Promise<IQuestionnaire[]> {
    return await this._storage.getByKeys<IQuestionnaire>(QuestionnaireStore, questionnaireCodes)
  }

  async getQuestionnairePOSAssignments(questionnaireCode: Code, posCode?: Code): Promise<IQuestionnairePOSAssignment[]> {
    let qpa = await this._storage.getByIndexRange<IQuestionnairePOSAssignment>(
      QuestionnairePOSAssignmentStore, QuestionnairePOSAssignmentStore_questionnaire, ['=', questionnaireCode]
    )
    qpa = qpa.filter(x => x.isActive)
    if (posCode) {
      qpa = qpa.filter(x => x.productLocation.some(p => p.code === posCode))
    }
    return qpa
  }

  async searchTaskTemplateQuestionnaireAssignments(request: TaskTemplateQuestionnaireAssignmentSearchRequest)
    : Promise<ITaskTemplateQuestionnaireAssignment[]> {
    return await this._storage.getWhere<ITaskTemplateQuestionnaireAssignment>(
      TaskTemplateQuestionnaireAssignmentStore,
      (ta: ITaskTemplateQuestionnaireAssignment) => {
        // у связи указана ТТ из входных параметров
        if (ta.pos.code !== request.posCode) {
          return false
        }
        // связь имеет ссылку на указанный код шаблона
        if (ta.template.code !== request.taskTemplateCode) {
          return false
        }
        // связь является действующей на указанную дату
        if (ta.startDate > request.date || (ta.endDate != null && ta.endDate < request.date)) {
          return false
        }
        return true
      })
  }
}
