import { useContext } from 'react'

import { startOfDay, endOfDay } from 'date-fns'
import { useAsync } from 'react-use'

import { IPointOfSale } from '../../../model/pos'
import { ITaskTemplate } from '../../../model/task-template'
import { IPositionRoleReference } from '../../../model/user'
import { ISelectEmployeeForAuditUserStepScreen } from '../../../model/user-step-screen'
import { ApiContext } from '../../../providers'
import { getContextProperty } from '../script-tasks/propertyName'
import { useScriptTaskContext } from '../script-tasks/script-task-context'
import { VisitAssessment } from './audit-merchandise-service-task'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export function useBackupExtensions() {
  const localContext = useScriptTaskContext()
  const { template } = localContext
  const settings = findSelectEmployeeScreen(template)
  const date = getContextProperty<number>(
    localContext,
    settings?.allowPreviousDay ?? true ? 'task.auditDate' : 'task.startDate',
  )
  const executivePositionRole = getContextProperty<IPositionRoleReference>(
    localContext,
    settings?.selectedPositionRoleReferencePropertyName ?? 'task.auditedFieldPositionRole',
  )
  const api = useContext(ApiContext)
  return useAsync(async () => {
    const vus = await api.audit.searchSupervisedVisits({
      dateFrom: startOfDay(date).valueOf(),
      dateTo: endOfDay(date).valueOf(),
      positionRole: executivePositionRole,
      // status: [],
    })

    const posList = await api.pos.searchPos()

    const backupExtensions = Object.fromEntries(
      vus.map(({ visit, tasks }) => [
        visit.code,
        {
          pos: makePosExtension(posList?.find((pos) => pos.code === visit.pointOfSaleCode)),
          tasks: Object.fromEntries(tasks.map((task) => [task.code, task])),
        },
      ]),
    )
    console.log('backupExtensions', backupExtensions)
    return backupExtensions
  }, [])
}

export function makePosExtension(pos: IPointOfSale | undefined): VisitAssessment['posExtension'] {
  if (!pos) return
  const { code, name, address: { fullAddress, city } = {} as never } = pos
  return {
    code,
    name,
    fullAddress,
    city,
    ownedBy: pos.associatedLegalEntities?.find((e) => e.associationType === 'OwnedBy')?.legalEntity?.name,
  }
}

function findSelectEmployeeScreen(template: ITaskTemplate): ISelectEmployeeForAuditUserStepScreen | undefined {
  for (const screen of Object.values(template.userStepScreens)) {
    if (screen.$type === 'PMI.FACE.BDDM.Extensions.Classes.SelectEmployeeForAuditUserStepScreen') {
      return screen
    }
  }
}