import React, { useState } from 'react'

import { Container, Provider } from '../../infrastructure/di'
import { ServiceContext } from './service-context'

interface Props {
  providers: Array<Provider<unknown>>
  children: React.ReactNode
}

export function ServiceProvider(props: Props): JSX.Element | null {
  const [container] = useState(() => new Container(props.providers))

  return <ServiceContext.Provider value={container}>{props.children}</ServiceContext.Provider>
}

export function Provide(
  providers: Array<Provider<unknown>>,
): <P>(Component: React.ComponentType<P>) => (props: P) => JSX.Element {
  return function <P>(Component: React.ComponentType<P>) {
    const wrapped = function (props: P): JSX.Element {
      return (
        <ServiceProvider providers={providers}>
          <Component {...props} />
        </ServiceProvider>
      )
    }

    ;(wrapped as { displayName?: string }).displayName = `provide(${Component.displayName ?? Component.name})`

    return wrapped
  }
}
