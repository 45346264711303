import React from 'react'

import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emptyWrapper: {
      height: '100%',
      display: 'grid',
      alignContent: 'center',
      justifyContent: 'center',
    },
    emptyText: {
      color: 'rgba(0, 0, 0, 0.38)',
      fontWeight: 500,
      letterSpacing: '0',
    },
  }),
)

export const EmptyText: React.FC = ({ children }) => {
  const classes = useStyles()
  return (
    <Box className={classes.emptyWrapper}>
      <Typography className={classes.emptyText} variant='h6' component='h6'>
        {children}
      </Typography>
    </Box>
  )
}
