import { IStorageSchema } from '../storage-service'

export const RecordStore = 'records'
export const RecordStoreTimestampIndex = 'timestamp'

const schema: IStorageSchema = {
  version: 2,
  stores: {
    [RecordStore]: {
      keyPath: 'id',
      autoIncrement: true,
      index: {
        [RecordStoreTimestampIndex]: {
          keyPath: 'timestamp',
          unique: false
        }
      }
    }
  }
}

export default schema