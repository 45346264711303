import { endOfDay, startOfDay, startOfMonth, startOfWeek, subMonths } from 'date-fns'

import { IStoreManagerServiceCategory } from '../../../../model/sm-service'
import { SMReportViewMode } from './models/sm-report.model'

export function getPeriodByViewMode(viewMode: SMReportViewMode): { start: number; end: number } {
  const today = Date.now()
  switch (viewMode) {
    case 'last-week':
      return {
        start: startOfWeek(today).valueOf(),
        end: endOfDay(today).valueOf()
      }
    case 'last-month':
      return {
        start: startOfMonth(today).valueOf(),
        end: endOfDay(today).valueOf()
      }
    case 'all':
      return {
        start: startOfDay(subMonths(today, 3)).valueOf(),
        end: endOfDay(today).valueOf()
      }
  }
}

// Формула расчета процента набранных баллов FACE-176
function calculatePercentByCategory(category: IStoreManagerServiceCategory): number {
  const answerMarks = category.skillMarks?.reduce(
    (acc, item) => (item.answerMark != null && item.skillWeight != null ? acc + item.answerMark : acc),
    0
  )
  const skillWeights = category.skillMarks?.reduce(
    (acc, item) => (item.answerMark != null && item.skillWeight != null ? acc + item.skillWeight : acc),
    0
  )
  if (!answerMarks || !skillWeights) {
    return 0
  }
  return Math.round((answerMarks / skillWeights) * 100)
}

export function getPercentByCategory(category: IStoreManagerServiceCategory): string | undefined {
  const percent = calculatePercentByCategory(category)
  return !percent ? undefined : `${percent}%`
}
