import React, { useEffect } from 'react'

import { Typography } from '@material-ui/core'

import { usePropertyName } from '../../../nested/usePropertyName'
import { ItemCard } from '../item-card'
import { AstPredicateControl } from './components/ast-predicate-control'
import { ConstPredicateControl } from './components/const-predicate-control'
import { InlinePredicateControl } from './components/inline-predicate-control'
import { ScriptPredicateControl } from './components/script-predicate-control'
import { UnknownPredicateControl } from './components/unknown-predicate-control'
import { IPredicateScreenItem, IPredicateViewScreenItem } from './types'
import { isAstPredicateValue } from './utils'

interface Props {
  item: IPredicateViewScreenItem
}

export const PredicateViewScreenItem: React.FC<Props> = ({ item }) => {
  const value: IPredicateScreenItem = usePropertyName(item.propertyName)
  useEffect(() => {
    console.log('PredicateViewScreenItem mount', item, value)
  }, [])

  const renderEditor = (): JSX.Element => {
    switch (value?.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate':
        return <ConstPredicateControl value={value} readonly />
      case 'PMI.FACE.BDDM.Extensions.Classes.InlineScriptPredicate':
        return <InlinePredicateControl value={value} readonly />
      case 'PMI.FACE.BDDM.Extensions.Classes.ScriptPredicate':
        return <ScriptPredicateControl value={value} readonly />
    }

    if (isAstPredicateValue(value)) {
      return <AstPredicateControl memoBDDMType={[]} value={value} readonly />
    }

    if (value) {
      return <UnknownPredicateControl value={value} readonly />
    }

    return <div>Предикат отсутсвует</div>
  }

  return <ItemCard label={<Typography variant='inherit'>{item.displayName}</Typography>}>{renderEditor()}</ItemCard>
}
