import React, { useContext, useState } from 'react'

import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
} from '@material-ui/core'
import device from 'current-device'
import { useNetworkState } from 'react-use'

import { VisitWithPosIndexed } from '../../../features/visits/activities-list/visits-map-by-day'
import { LogManager } from '../../../infrastructure/logger'
import { ModalContext } from '../../../providers/modal'
import { appToast } from '../../../utils'
import { getURLWithSign } from './utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuOpenButton: {
      width: '32px',
      height: '32px',
      minWidth: '32px',
      padding: '0',
      borderRadius: '8px',
      background: 'rgba(255, 255, 255, 0.5)',
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.5)',
      },
    },
  }),
)

const logger = LogManager.getLogger('RouteMapMenuButton')

interface IUserGeolocation {
  userLatitude: number | null
  userLongitude: number | null
}

interface IRouteMapMenuButton {
  visits: VisitWithPosIndexed[]
  userGeolocation?: IUserGeolocation
}

const RouteMapMenuButton: React.FC<IRouteMapMenuButton> = ({ visits, userGeolocation }) => {
  const classes = useStyles()
  const networkState = useNetworkState()
  const isDevice = !device.desktop()
  const modalContext = useContext(ModalContext)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>()
  const open = !!anchorEl

  const getLinkCoordString = (): string => {
    return visits
      .filter((v) => v.status !== 'Finished')
      .map((visit) => {
        return `${visit.pointOfSale?.coordinates?.latitude},${visit.pointOfSale?.coordinates?.longitude}`
      })
      .join('~')
  }

  const getNavigatorCoordString = (): string => {
    // "yandexnavi://build_route_on_map?lat_from=55.75&lon_from=37.58&lat_to=55.75&lon_to=37.64&lat_via_0=55.75&lon_via_0=37.62"
    return visits
      .map((visit, i) => {
        if (i === visits.length - 1) {
          return `lat_to=${visit.pointOfSale?.coordinates?.latitude}&lon_to=${visit.pointOfSale?.coordinates?.longitude}`
        } else {
          return `lat_via_${i}=${visit.pointOfSale?.coordinates?.latitude}&lon_via_${i}=${visit.pointOfSale?.coordinates?.longitude}`
        }
      })
      .join('&')
  }

  const getUserCoordString = (): string => {
    if (userGeolocation?.userLatitude && userGeolocation.userLongitude) {
      return `${userGeolocation?.userLatitude},${userGeolocation.userLongitude}~`
    }

    return ''
  }

  const navigatorURLOutOfSign = `yandexnavi://build_route_on_map?${getNavigatorCoordString()}`
  const linkToNavigator = getURLWithSign(navigatorURLOutOfSign)
  console.log(linkToNavigator)

  const resultLink = isDevice
    ? `yandexmaps://maps.yandex.ru/?rtext=${getUserCoordString()}${getLinkCoordString()}&rtt=auto`
    : `https://yandex.ru/maps/?rtext=${getUserCoordString()}${getLinkCoordString()}&rtt=auto`

  const resultCopyLink = `https://yandex.ru/maps/?rtext=${getUserCoordString()}${getLinkCoordString()}&rtt=auto`

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLLIElement>): void => {
    setAnchorEl(undefined)
  }

  const onShareHandler = async (): Promise<void> => {
    setAnchorEl(undefined)
    const isOffline = !networkState.online

    if (isOffline) {
      appToast.info('Нет подключения к интернету')
    }

    if (!navigator.share || !navigator.canShare) {
      void navigator.clipboard.writeText(resultCopyLink).then(() => {
        appToast.info('Ссылка скопирована', { style: undefined, autoClose: 1500, hideProgressBar: true })
      })

      return
    }

    try {
      await navigator.share({
        url: resultCopyLink,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        title: `Ссылка на маршрут: ${resultCopyLink}`,
      })
    } catch (e) {
      logger.error('onShareHandler', 'Sharing failed', e)
    }
  }

  const tryOpenUrl = (url: string): void => {
    if (isDevice) {
      setTimeout(() => {
        try {
          document.location = url
        } catch (error) {
          console.log(error)
        }
      }, 5)
      setTimeout(() => {
        if (document.hasFocus()) {
          modalContext.open(
            <Dialog
              disableBackdropClick
              open
              onClose={() => {
                modalContext.close()
              }}
            >
              <DialogTitle id='alert-dialog-title'>
                {'Для построения маршрута необходимо установить приложение "Яндекс Карты".'}
              </DialogTitle>
              <DialogActions>
                <Button
                  autoFocus
                  color='primary'
                  onClick={() => {
                    modalContext.close()
                  }}
                >
                  Закрыть
                </Button>
              </DialogActions>
            </Dialog>,
          )
        }
      }, 100)
    } else {
      window.open(url, '_blank')
    }
  }

  const isDisabled = !visits.find((visit) => visit.pointOfSale?.coordinates)

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={(evt: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLLIElement, MouseEvent>) => {
            tryOpenUrl(resultLink)
            handleClose(evt)
          }}
        >
          Открыть на устройстве
        </MenuItem>
        {/*TODO: Закомментировано до согласования реализации*/}
        {/*<MenuItem*/}
        {/*  component='a'*/}
        {/*  target='_blank'*/}
        {/*  href={linkToNavigator}*/}
        {/*  onClick={(evt: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLLIElement, MouseEvent>) => {*/}
        {/*    handleClose(evt)*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Тест навигатора*/}
        {/*</MenuItem>*/}
        <MenuItem onClick={onShareHandler}>Поделиться ссылкой</MenuItem>
      </Menu>
      <Button className={classes.menuOpenButton} onClick={handleClick} disabled={isDisabled}>
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M13.5279 0C15.043 0 16.428 0.856009 17.1056 2.21115L19.3666 6.73313C19.7831 7.56626 20 8.48494 20 9.41641V18C20 19.1046 19.1046 20 18 20C16.8954 20 16 19.1046 16 18V16H4V18C4 19.1046 3.10457 20 2 20C0.89543 20 0 19.1046 0 18V9.41641C0 8.48494 0.216871 7.56626 0.633437 6.73313L2.89443 2.21115C3.572 0.856009 4.95705 0 6.47214 0H13.5279ZM17 11H15C14.4477 11 14 11.4477 14 12C14 12.5128 14.386 12.9355 14.8834 12.9933L15 13H17C17.5523 13 18 12.5523 18 12C18 11.4872 17.614 11.0645 17.1166 11.0067L17 11ZM5 11H3C2.44772 11 2 11.4477 2 12C2 12.5128 2.38604 12.9355 2.88338 12.9933L3 13H5C5.55228 13 6 12.5523 6 12C6 11.4872 5.61396 11.0645 5.11662 11.0067L5 11ZM13.7639 2H6.23607C5.53263 2 4.88526 2.36904 4.52512 2.96426L4.44721 3.10557L2 8H18L15.5528 3.10557C15.214 2.428 14.5215 2 13.7639 2Z'
            fill='#546066'
          />
        </svg>
      </Button>
    </>
  )
}

export default RouteMapMenuButton
