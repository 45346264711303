import React from 'react'

import { Box, Typography } from '@material-ui/core'
import MonacoEditor from '@monaco-editor/react'

import { IJsonViewerScreenItem } from '../../../../../model/screen-item'
import { getMonacoEditorOptions } from '../../../../admin/_common/monaco-options'
import { ITableRecordEntity } from '../../../../admin/data-manager/models/models'
import { getContextProperty } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { ItemCard } from '../item-card'

interface IProps {
  item: IJsonViewerScreenItem
}

const JsonViewerScreenItem: React.FC<IProps> = ({ item }) => {
  const processContext = useScriptTaskContext()
  const record = getContextProperty<ITableRecordEntity>(processContext, item.propertyName)

  if (!record && item.hideEmpty) {
    return null
  }

  const label = <Typography variant='inherit'>{item.displayName}</Typography>

  if (!record) {
    return (
      <ItemCard label={label}>
        <Typography color='textSecondary'>{item.nullValueCaption}</Typography>
      </ItemCard>
    )
  }

  return (
    <ItemCard label={label} noContentPadding>
      <Box height={item.height <= 0 || !item.height ? 300 : item.height}>
        <MonacoEditor
          language='json'
          value={JSON.stringify(record, null, '\t')}
          options={getMonacoEditorOptions({
            renderLineHighlight: 'none',
            lineNumbers: 'off',
            readOnly: true,
            showFoldingControls: 'always',
            scrollbar: { alwaysConsumeMouseWheel: false },
            scrollBeyondLastLine: false,
          })}
        />
      </Box>
    </ItemCard>
  )
}

export default JsonViewerScreenItem
