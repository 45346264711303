import { useContext } from 'react'

import { useAsync } from 'react-use'
import { AsyncState } from 'react-use/lib/useAsyncFn'

import { IPointOfSale } from '../../../model/pos'
import { ApiContext } from '../../../providers'

const UseGetPos = (searchQuery?: string): AsyncState<IPointOfSale[]> => {
  const api = useContext(ApiContext)

  const posListData = useAsync(async () => {
    const profile = await api.userProfile.getCurrentProfile()
    return await api.pos.searchPos({
      searchQuery: searchQuery ?? '',
      positionRoleCoverage: profile?.fieldPositionRole?.code ?? '',
    })
  }, [searchQuery])

  return posListData
}

export default UseGetPos