import React from 'react'

import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

interface IAnswerResolutionProps {
  resolution: boolean | null | undefined
  text: string
  onlyText?: boolean
}

interface IStylesProps {
  resolution: boolean | null | undefined
}

const SUCCESS_COLOR = '#388E3C'
const DECLINE_COLOR = '#D32F2F'
const NA_COLOR = '#3870B2'

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 10,
      fontWeight: 500,
      color: 'white',
      height: 24,
      // width: 34,
      padding: '0 10px',
      minHeight: 24,
      minWidth: 34,
      borderRadius: 100,
      letterSpacing: '0.1px',
      whiteSpace: 'nowrap',
    },
    booleanResolution: {
      background: ({ resolution }) => (resolution == null ? NA_COLOR : resolution ? SUCCESS_COLOR : DECLINE_COLOR),
    },
    textResolution: {
      background: theme.palette.primary.main,
    },
  }),
)

const AnswerResolution: React.FC<IAnswerResolutionProps> = ({ resolution, text, onlyText }) => {
  const classes = useStyles({ resolution })

  if (onlyText) {
    return <>{text}</>
  }

  return <div className={classNames([classes.root, classes.booleanResolution])}>{text}</div>
}

export default React.memo(AnswerResolution)
