import React from 'react'

import { Box, Button, Container, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import classnames from 'classnames'

import FooterVersion from './footer-version'
import { MaintenanceMarker } from './maintenance-marker'

const PAGE_BACKGROUND_COLOR = 'rgba(0, 61, 165, 0.02)'
const useStyles = makeStyles((theme) => ({
  wrap: {
    position: 'relative',
    height: 'calc(100% + 80px)',
    minHeight: 525,
    background: PAGE_BACKGROUND_COLOR,
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: '#3182CE',
    },
    '&::after': {
      opacity: 0.5
    },
    '&::before': {
      opacity: 0.6,
      backgroundImage: 'url("/mos-city-bg.jpeg")',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 527,
    }
  },
  root: {
    position: 'relative',
    zIndex: 1,
    maxWidth: 540,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 244,
    }
  },
  paper: {
    width: '100%',
    paddingTop: theme.spacing(6),
    // paddingBottom: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 40,
    [theme.breakpoints.down('xs')]: {
      minHeight: 244,
      paddingTop: 30,
    }
  },
  formWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  description: {
    margin: theme.spacing(8, 0, 0),
    display: 'flex',
    alignItems: 'center',
    gap: 5
  },
  submit: {
    height: 40,
    width: 89,
    borderRadius: 28,
    margin: '30px 0 44px',
    [theme.breakpoints.down('xs')]: {
      margin: '20px 0 20px',
    }
  },
  logo: {
    width: 250,
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      width: 170,
    }
  }
}))
export const LoginPageNoNetwork: React.FC = () => {
  const classes = useStyles()
  return (
    <>
      <Box className={classes.wrap}>
        <Container disableGutters fixed className={classes.root} component='main'>
          <Paper
            elevation={3}
            className={classes.paper}
          >
            <Container fixed className={classes.formWrap} component='section' maxWidth='xs'>
              <img className={classnames('Header-logo', classes.logo)} src='/pmi-logo-login-new.png' alt='Philip Morris logo' />
              <Typography className={classes.description} variant='h6' component='h6' align='center'>
                <ReportProblemOutlinedIcon color='action' />
                Вход в систему невозможен.
              </Typography>
              <Typography variant='h6' align='center'>
                Отсутствует подключение к сети Интернет
              </Typography>
              <MaintenanceMarker />
              <Button
                size='large'
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                disabled
                className={classes.submit}
              >
                Войти
              </Button>
            </Container>
          </Paper>
        </Container>
      </Box>
      <FooterVersion />
    </>
  )
}


