import { IBusinessParameters } from '../model/business-parameters'
import { IMediaReceiveSetting } from '../utils/media'
import { BlobKey } from './blob-storage/blob-storage-api'

/**
 * Событие-запрос на загрузку блобов
 */
export const EVENT_NEED_RECEIVE_BLOBS = 'EVENT_NEED_RECEIVE_BLOBS'
export type EventNeedReceiveBlobsArgType = Array<[string, IMediaReceiveSetting]> | Map<string, IMediaReceiveSetting>

/**
 * Событие-запрос на удаление блобов
 */
export const EVENT_NEED_REMOVE_BLOBS = 'EVENT_NEED_REMOVE_BLOBS'
export type EventNeedRemoveBlobsArgType = BlobKey[] | Set<BlobKey>

/**
 * Событие-запрос на отправку блобов
 */
export const EVENT_NEED_SEND_BLOBS = 'EVENT_NEED_SEND_BLOBS'
export type EventNeedSendBlobsArgType = BlobKey[] | Set<BlobKey>

/**
 * Событие загрузки бизнес-параметров
 */
export const EVENT_FETCHED_BUSINESS_PARAMETERS = 'EVENT_FETCHED_BUSINESS_PARAMETERS'
export type EventFetchedBusinessParametersArgType = IBusinessParameters