/* eslint-disable @typescript-eslint/indent */
import React from 'react'

import {Box, createStyles, Grid, Paper, Theme, Tooltip, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import classnames from 'classnames'
import {useTranslation} from 'react-i18next'
import {generatePath, Link} from 'react-router-dom'

import {grays} from '../../../layout/theme'
import {FieldForceVisitSource, VisitStatus} from '../../../model/visit'
import { useFeatureRoute } from '../../custom-app-feature/app-feature-context'
import { getVisitSourceString } from '../utils'
import { visitsRoutes } from '../visits-app-feature/visits-app-feature'

interface IVisitsListWeekItemProps {
  name: string
  address: string
  city: string
  code: string
  posCode: string
  registeredName?: string | null
  status: VisitStatus
  size?: 'small'
  source?: FieldForceVisitSource
}

const useStyles = makeStyles<Theme, IVisitsListWeekItemProps>((theme: Theme) =>
  createStyles({
    link: {
      width: '100%',
    },
    root: {
      cursor: 'pointer',
      position: 'relative',
      fontSize: 16,
      minHeight: 90,
      boxShadow: '0 3px 7px rgba(96, 97, 112, 0.13)',
      borderRadius: 8,
      overflow: 'hidden',
      padding: '10px 12px 10px 20px',
      [theme.breakpoints.down('xs')]: {
        padding: '14px 8px 12px 27px',
        minHeight: 80,
      },
    },
    name: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.1px',
      marginBottom: 1,
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
    cancelled: {
      background: '#F2F3F4',
    },
    finished: {
      background: '#F2F3F4',
    },
    gridContainer: {
      flexWrap: 'wrap',
    },
    nameWrap: {
      width: '100%',
    },
    addressWrap: {
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    statusWrap: {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        maxWidth: '100%',
        alignItems: 'center',
      },
    },
    statusText: {
      minWidth: 65,
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 500,
      color: grays.gray2,
      letterSpacing: '0.1px',
    },
    code: {
      marginBottom: 4,
      color: grays.gray4,
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      fontWeight: 400,
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
        lineHeight: '20px',
        color: grays.gray3,
        marginBottom: 0,
      },
    },
    address: {
      fontWeight: 400,
      fontSize: '11px',
      lineHeight: '16px',
      letterSpacing: '0.1px',
    },
    addressMobile: {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.1px',
      },
    },
    status: {
      position: 'absolute',
      height: '100%',
      marginTop: -10,
      marginLeft: -20,
      width: 8,
      [theme.breakpoints.down('xs')]: {
        marginTop: -14,
        marginLeft: -27,
      },
    },
    statusPlanned: {
      background: theme.palette.info.main,
    },
    statusFinished: {
      background: theme.palette.success.main,
    },
    statusInProgress: {
      background: theme.palette.warning.main,
    },
    statusCanceled: {
      background: theme.palette.grey[600],
    },
    dotStatus: {
      display: 'block',
      borderRadius: '50%',
      flexShrink: 0,
      width: 10,
      height: 10,
      margin: 10,
    },
    codeWrap: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'space-between',
      },
    },
    source: {
      fontWeight: 400,
      fontSize: '11px',
      lineHeight: '16px',
      letterSpacing: '0.1px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.1px',
      },
    },
  }),
)

const VisitsListWeekItem: React.FC<IVisitsListWeekItemProps> = (props) => {
  const { t } = useTranslation('visits')
  const classes = useStyles(props)
  const featureRoute = useFeatureRoute()

  // const gridSize = (size: GridSize): GridSize => (props?.size === 'small' ? 12 : size)

  const pathname = generatePath(visitsRoutes.visitView, { featureRoute, id: props.code })
  return (
    <Link className={classes.link} to={pathname}>
      <Paper
        elevation={3}
        square
        className={classnames(classes.root, {
          [classes.cancelled]: props.status === 'Canceled',
          [classes.finished]: props.status === 'Finished',
        })}
      >
        <Tooltip title={t(props.status, '') as string}>
          <div
            className={classnames(classes.status, {
              [classes.statusPlanned]: props.status === 'Planned',
              [classes.statusFinished]: props.status === 'Finished',
              [classes.statusInProgress]: props.status === 'InProgress',
              [classes.statusCanceled]: props.status === 'Canceled',
            })}
          />
        </Tooltip>
        <Grid container className={classes.gridContainer} alignItems='center' justify='space-between'>
          <Grid item className={classes.nameWrap}>
            <Box>
              <Typography className={classes.name} component='h6' noWrap>
                {props.name}
              </Typography>
              <Typography noWrap className={classes.addressMobile}>
                {props.address}
                <br />
                {props.city}
              </Typography>
              <div className={classes.codeWrap}>
                <Typography className={classes.code} noWrap>
                  {props.posCode}
                  {props.registeredName ? `\u00A0|\u00A0${props.registeredName}` : ''}
                </Typography>
                <div className={classes.statusWrap}>
                  <span
                    className={classnames(classes.dotStatus, {
                      [classes.statusPlanned]: props.status === 'Planned',
                      [classes.statusFinished]: props.status === 'Finished',
                      [classes.statusInProgress]: props.status === 'InProgress',
                      [classes.statusCanceled]: props.status === 'Canceled',
                    })}
                  />
                  <Typography noWrap className={classes.statusText}>
                    {t(props.status)}
                  </Typography>
                </div>
              </div>
            </Box>
          </Grid>
          <Grid item className={classes.addressWrap}>
            <Tooltip title={`${props.address} ${props.city}`}>
              <Typography noWrap className={classes.address}>
                {props.address}
                <br />
                {props.city}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item>
            <Typography noWrap className={classes.source}>
              {getVisitSourceString(props.source)}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Link>
  )
}

export default React.memo(VisitsListWeekItem)
