import { useCallback, useContext } from 'react'

// import { useTranslation } from 'react-i18next'
import { uniq } from 'lodash'

import { LogManager } from '../../../infrastructure/logger'
import { Code } from '../../../model/base'
import { IReportProcessor } from '../../../model/report-processor'
import { IVisit } from '../../../model/visit'
import { isInVisitTask } from '../../../model/visit-task'
import { ApiContext, ConfigContext, ProfileContext } from '../../../providers'
import { isNonNullable } from '../../../utils/isNonNullable'
import { useBusinessSettings } from '../../_common/hooks/useBusinessSettings'
import { useWebUrl } from '../../_common/hooks/useWebUrl'
import { fetchAllTaskSurveys, useLocalContextService } from '../../tasks/nested/local-context'
import { tryFindTaskTemplate } from '../../tasks/nested/tryFindTaskTemplate'
import { IScriptTaskContext } from '../../tasks/script-tasks/script-task-context'
import { generatePdfComposite } from '../../tasks/template-tasks/composite-screen/report/pdf'
import { generatePdfSimple } from '../../tasks/template-tasks/simple-survey/generate-pdf-simple'
import { runResultScreenScript } from '../../tasks/template-tasks/task-result-screen'

const logger = LogManager.getLogger('useGenerateReportCustom')

export function useGenerateReportCustom(taskCode: Code | undefined): () => Promise<Blob | undefined> {
  const api = useContext(ApiContext)
  const profileContext = useContext(ProfileContext)
  const webUrl = useWebUrl()
  const config = useContext(ConfigContext)
  const businessParameters = useBusinessSettings()
  const contextService = useLocalContextService()
  // const { t } = useTranslation('sales-expert-tasks')

  const handler = useCallback(async () => {
    if (!taskCode) return void logger.debug('', 'no taskcode')
    const task = await api.tasks.getTask(taskCode)
    if (!task) {
      throw new Error(`no task ${taskCode} found`)
    }
    let visit: IVisit | null | undefined
    let pos
    if (task && isInVisitTask(task)) {
      visit = await api.visits.getVisit(task!.visitCode)
      if (!visit) logger.debug('', 'no visit')
      pos = await api.pos.getPos(visit!.pointOfSaleCode)
      if (!pos) logger.debug('', 'no pos')
    }
    const taskTemplate = await tryFindTaskTemplate(api, profileContext.value!.profile, task!)

    const surveys = await fetchAllTaskSurveys(task!, taskTemplate!, api)
    const questionnaireCodes = uniq(
      Object.values(surveys)
        .flat()
        .map((survey) => survey.questionnaire.code),
    )
    const questionnaires = await api.questionnaire.getQuestionnaires(questionnaireCodes)

    let propertiesContext: IScriptTaskContext = {
      employee: profileContext.value?.employee,
      profileCode: profileContext.value?.profile?.code,
      fieldPositionRole: profileContext.value?.fieldPositionRole,
      participantProfile: profileContext.value.participantProfile,
      task: task!,
      visit: visit ?? undefined,
      pos: pos ?? undefined,
      template: taskTemplate!,
      taskState: { currentStep: 0, taskCode, subProcesses: {} },
      scope: {},
      fullScope: { task: {} },
      questionnaires: questionnaires.filter(isNonNullable),
      surveys,
      apiUrl: config.config.apiUrl,
      webUrl,
      businessParameters,
      readOnly: true,
    }

    if (taskTemplate?.resultFile) {
      const { resultFileScript } = taskTemplate
      if (resultFileScript) {
        propertiesContext = await runResultScreenScript(resultFileScript, propertiesContext, contextService)
      }
      const { resultFile } = taskTemplate
      switch (resultFile.$type) {
        case 'PMI.FACE.BDDM.Extensions.Classes.CompositeReportProcessor': {
          return generatePdfComposite({ resultFile, propertiesContext, api })
        }
        case 'PMI.FACE.BDDM.Extensions.Classes.CustomReportProcessor': {
          if (resultFile.processorCode === 'SM_PosAudit_v0001') {
            return generatePdfSimple({
              api,
              task: task!,
              propertyName: 'surveys',
              posCode: visit!.pointOfSaleCode,
            })
          } else {
            throw new Error(`unhandled resultFile Custom processor ${resultFile.processorCode}`)
          }
        }
        default:
          throw new Error(`unhandled resultFile type ${(resultFile as IReportProcessor)?.$type}`)
      }
    } else {
      logger.warn('generateReportCustom', 'no resultFile in template', undefined, { taskTemplate })
    }
  }, [taskCode])
  return handler
}
