export function maxOf<T, K>(array: T[], selector: (item: T) => K): K | undefined {
  let max: K | undefined
  if (array?.length) {
    for (const item of array) {
      const prop = selector(item)
      if (max == null || prop > max) {
        max = prop
      }
    }
  }
  return max
}

export function minOf<T, K>(array: T[], selector: (item: T) => K): K | undefined {
  let min: K | undefined
  if (array?.length) {
    for (const item of array) {
      const prop = selector(item)
      if (min == null || prop < min) {
        min = prop
      }
    }
  }
  return min
}

export async function maxOfAsync<T, K>(
  iterator: AsyncIterableIterator<T>,
  selector: (item: T) => K
): Promise<K | undefined> {
  let max: K | undefined
  if (iterator) {
    for await (const item of iterator) {
      const prop = selector(item)
      if (max == null || prop > max) {
        max = prop
      }
    }
  }
  return max
}

export async function minOfAsync<T, K>(
  iterator: AsyncIterableIterator<T>,
  selector: (item: T) => K
): Promise<K | undefined> {
  let min: K | undefined
  if (iterator) {
    for await (const item of iterator) {
      const prop = selector(item)
      if (min == null || prop < min) {
        min = prop
      }
    }
  }
  return min
}
