import { endOfDay, startOfDay } from 'date-fns'

import { POSTaskRegisterExecutionStore, POSTaskRegisterStore } from '../../data/schema'
import { Code } from '../../model/base'
import { ValidationError, ValidationErrorCode } from '../../model/errors'
import { IPOSTaskRegister, IPOSTaskRegisterExecution } from '../../model/pos-task-register'
import IPOSTaskRegisterService, { GetPOSTaskRegisterExecutionsRequest, GetPOSTaskRegistersRequest } from '../pos-task-register-service-api'
import { LocalStorageBaseService } from './local-storage-base-service'


export default class LocalStoragePOSTaskRegisterService extends LocalStorageBaseService implements IPOSTaskRegisterService
{
  private static readonly __className = 'LocalStoragePOSTaskRegisterService'

  async getPOSTaskRegister(registerCode: Code): Promise<IPOSTaskRegister | undefined> {
    return this._storage.getByKey<IPOSTaskRegister>(POSTaskRegisterStore, registerCode)
  }
  
  async searchPOSTaskRegisters(request: GetPOSTaskRegistersRequest): Promise<IPOSTaskRegister[]> {
    if (!request?.pos) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing, 'pos is required', ['pos']
      )
    }
    if (!request?.taskTemplate?.length) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing, 'at least one task template is required', ['taskTemplate']
      )
    }

    return this._storage.getWhere<IPOSTaskRegister>(POSTaskRegisterStore, 
      reg => (
        (
          !reg.pos?.code || reg.pos?.code === request.pos.code 
        )
        &&
        (
          request.taskTemplate.some(tmpl => 
            tmpl.code === reg.taskTemplate.code &&
              tmpl.version?.code === reg.taskTemplate.version?.code
          )
        )
        &&
        (
          !request.date || (reg.startDate <= +endOfDay(request.date) && (!reg.endDate || +startOfDay(request.date) <= reg.endDate!))
        )
        && 
        (
          request.isActive == null || reg.isActive === request.isActive!
        )
      )
    )
  }

  async searchPOSTaskRegisterExecutions(request: GetPOSTaskRegisterExecutionsRequest): Promise<IPOSTaskRegisterExecution[]> {
    if (request?.posTaskRegisterCodes?.length) {
      return this._storage.getByKeys<IPOSTaskRegisterExecution>(POSTaskRegisterExecutionStore, request.posTaskRegisterCodes)
    } else {
      return []
    }
  }
}
