// Этот компонент для тестов и разработки функционала без финального внедрения

import React, { useState } from 'react'

import { Button } from '@material-ui/core'

import { Signature } from '../../signature'

export const SignatureTestItem: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [image, setImage] = useState<string>()

  return (
    <div>
      <Button onClick={() => setIsOpen(true)}>Открыть диалог с ручной подписью</Button>
      <div>
        <Signature
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false)
          }}
          onChange={(value) => {
            if ('image' in value) {
              setImage(value.image)
            }
          }}
        />
      </div>
      {image ? <img src={image} alt='' /> : null}
    </div>
  )
}