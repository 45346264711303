import React, { useContext } from 'react'

import { Box, makeStyles, SvgIcon } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import classnames from 'classnames'
import lodashGet from 'lodash/get'
import { useTranslation } from 'react-i18next'

import { AppRoutes as route } from '../../routes/routes'
import { MenuItem } from '../index'
import { SidebarButton } from '../sidebar-button'
import { grays } from '../theme'
import { ReactComponent as LogoutIcon } from './Logout.svg'
import { MenuContext } from './menu-context'
import { PublicSvgIcon } from './public-svg-icon'
import { SyncMenuItem } from './sync-menu-item'

export const menuBackgroundColor = '--menu-background-color'
export const menuTextColor = '--menu-text-color'
export const menuBackgroundColorActive = '--menu-background-color-active'
export const menuTextColorActive = '--menu-text-color-active'

const useStyles = makeStyles(
  (theme) => ({
    main: {
      [menuBackgroundColor]: theme.palette.primary.main,
      [menuTextColor]: 'white',
      [menuBackgroundColorActive]: 'white',
      [menuTextColorActive]: theme.palette.primary.main,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      // borderRight: '1px solid',
      // borderRightColor: theme.palette.grey.A100,
      zIndex: theme.zIndex.tooltip,
      padding: theme.spacing(0, 2),
      color: `var(${menuTextColor})`,
      background: `var(${menuBackgroundColor})`,
    },
    lightColor: {
      [menuTextColor]: grays.gray2,
      [menuBackgroundColor]: 'white',
      [menuBackgroundColorActive]: 'white',
      [menuTextColorActive]: theme.palette.primary.main,
    },
    onlyIcons: {
      width: lodashGet(theme, 'menu.width', MENU_WIDTH_ONLY_ICONS),
    },
    fixedWrapper: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      width: lodashGet(theme, 'menu.width', MENU_WIDTH_ONLY_ICONS),
      background: theme.palette.primary.main,
      padding: theme.spacing(0, 1),
    },
    divider: {
      backgroundColor: 'white',
      marginLeft: 12,
      marginRight: 12,
    },
    dividerWithMargin: {
      marginTop: 8,
    },
  }),
  { name: 'PmMenu' },
)

const MENU_WIDTH_ONLY_ICONS = 80

interface IMenu {
  onlyIcons?: boolean
  isBottomMenu?: boolean
}

const buildPath = (path: string): string => '/'.concat(path)

const Menu: React.FC<IMenu> = ({ onlyIcons = false, isBottomMenu = false }) => {
  const classes = useStyles()
  const { t } = useTranslation('menu')
  const { permittedMenuItems } = useContext(MenuContext)

  return (
    <div
      className={classnames(classes.main, {
        [classes.onlyIcons]: onlyIcons,
        [classes.lightColor]: isBottomMenu,
      })}
    >
      <div className={classnames({ [classes.fixedWrapper]: onlyIcons })}>
        <Box mt={2.5}>{!isBottomMenu && <SidebarButton />}</Box>
        {permittedMenuItems.map((item) => {
          const feature = item.action.feature
          if (feature.code === 'FACE-APP-FEATURE-SFA-SYNC') {
            return (
              <SyncMenuItem
                key={feature.routePath}
                to={buildPath(feature.routePath)}
                onlyIcon={onlyIcons}
                primaryText={item.displayName ?? ''}
              />
            )
          }

          return (
            <MenuItem
              key={feature.routePath}
              to={buildPath(feature.routePath)}
              onlyIcon={onlyIcons}
              primaryText={item.displayName}
              leftIcon={<PublicSvgIcon path={item.iconPath ?? ''} />}
            />
          )
        })}
        <Divider
          className={classnames(classes.divider, {
            [classes.dividerWithMargin]: !onlyIcons,
          })}
        />
        <MenuItem
          to={buildPath(route.login.path)}
          isExit
          onlyIcon={onlyIcons}
          primaryText={t(route.login.path)}
          leftIcon={<SvgIcon component={LogoutIcon} />}
        />
      </div>
    </div>
  )
}

export default React.memo(Menu)
