import { useContext, useEffect, useRef } from 'react'

import { LogManager } from '../../../infrastructure/logger'
import { Code } from '../../../model/base'
import { IFaceScript } from '../../../model/face-script'
import { IPointOfSale } from '../../../model/pos'
import { ITaskTemplate } from '../../../model/task-template'
import { IVisit } from '../../../model/visit'
import { IVisitTask } from '../../../model/visit-task'
import ApiContext from '../../../providers/api/api-context'
import { appToast } from '../../../utils'

interface ITaskEventHandlerContext {
  visit: IVisit
  pos: IPointOfSale
  task: IVisitTask
  template: ITaskTemplate
  saveTask: () => Promise<void>
}

const logger = LogManager.getLogger('VisitTaskEventHandler')

// eslint-disable-next-line no-eval
const AsyncFunction: FunctionConstructor = eval('Object.getPrototypeOf(async function () {}).constructor')
async function handleScriptEvent(script: IFaceScript, context: ITaskEventHandlerContext): Promise<void> {
  const params = {
    ...context,
    window: 'window',
    self: 'self',
    globalThis: 'globalThis',
  }

  const fn = new AsyncFunction(...Object.keys(params), script.body)
  const res = await fn.call('this', ...Object.values(params))
  console.log('script event result', context.task, res)
  return res
}

export function useAfterTaskCreateEventHandler(
  visitCode: Code,
): (task: IVisitTask, template: ITaskTemplate) => Promise<void> {
  const api = useContext(ApiContext)
  const visitRef = useRef<Promise<IVisit | null>>()
  const posRef = useRef<Promise<IPointOfSale | null>>()

  useEffect(() => {
    visitRef.current = api.visits.getVisit(visitCode)
    posRef.current = visitRef.current!.then(async (visit) => visit && api.pos.getPos(visit.pointOfSaleCode))
  }, [])

  const handler = async (task: IVisitTask, template: ITaskTemplate): Promise<void> => {
    if (template?.afterTaskCreateEvent?.$type !== 'PMI.FACE.BDDM.Extensions.Classes.ScriptEventHandler') return

    const visit = await visitRef.current
    if (!visit) throw new Error('no visit')
    const pos = await posRef.current
    if (!pos) throw new Error('no pos')

    const script = template.scripts[template.afterTaskCreateEvent.script.code]
    if (!script) throw new Error('no script')

    const context: ITaskEventHandlerContext = {
      task,
      template,
      visit,
      pos,
      saveTask: async () => {
        await api.tasks.saveTask(task)
      },
    }

    return handleScriptEvent(script, context)
  }

  return async (task, template) => {
    try {
      await handler(task, template)
    } catch (error) {
      void api.tasks.deleteTask(task.code)
      logger.critical('handler', error.message, error, task)
      appToast.error('Во время выполнения инициализирующего скрипта произошла ошибка, создание задачи невозможно')
    }
  }
}
