import { IAnswer } from './answer'

export type AnswerReplyPredicate = SimpleAnswerReplyPredicate | GroupAnswerReplyPredicate

export interface SimpleAnswerReplyPredicate {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceSimpleAnswerReplyPredicate'
  operationKind: 'EQUALS' | 'NOT_EQUAL' | 'IS_NULL'
  answerReply?: string[]
}

export interface GroupAnswerReplyPredicate {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceGroupAnswerReplyPredicate'
  conjunctionKind: 'AND' | 'OR'
  predicates: AnswerReplyPredicate[]
}

export function checkAnswerReplyPredicate(
  predicate: AnswerReplyPredicate,
  answer: IAnswer
): boolean {
  switch (predicate.$type) {
    case 'PMI.FACE.BDDM.Extensions.Classes.FaceSimpleAnswerReplyPredicate':
      return checkSimpleAnswerReplyPredicate(predicate, answer)
    case 'PMI.FACE.BDDM.Extensions.Classes.FaceGroupAnswerReplyPredicate':
      return checkGroupAnswerReplyPredicate(predicate, answer)
    default:
      console.warn('AnswerReplyPredicate check is invalid: unrecognized predicate type', { predicate, answer })
      return false
  }
}

export function checkSimpleAnswerReplyPredicate(
  predicate: SimpleAnswerReplyPredicate,
  answer: IAnswer
): boolean {
  switch (predicate.operationKind) {
    case 'EQUALS':
    case 'NOT_EQUAL':
      if (predicate.answerReply?.length !== 1) {
        return false
      }
      let predicateValue = predicate.answerReply[0]
      let answerValue: string | null
      switch (answer.$type) {
        case 'PMI.FACE.BDDM.Extensions.Classes.FaceBooleanAnswer':
          answerValue = answer.booleanAnswer?.toString().toLowerCase() ?? null
          predicateValue = predicateValue?.toLowerCase() ?? null
          break
        case 'PMI.FACE.BDDM.Extensions.Classes.FaceTextAnswer':
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          answerValue = answer.reply?.trim().toLocaleLowerCase() || null
          predicateValue = predicateValue?.trim() ?? null
          break
        case 'PMI.FACE.BDDM.Extensions.Classes.FaceSelectProfileAnswer':
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          answerValue = answer.reply?.code || null
          predicateValue = predicateValue ?? null
          break
        default:
          console.warn('SimpleAnswerReplyPredicate check is invalid: unrecognized answer type', { predicate, answer })
          return false
      }
      if (answerValue == null) {
        return false
      }
      return predicate.operationKind === 'EQUALS'
        ? answerValue === predicateValue
        : answerValue !== predicateValue

    case 'IS_NULL':
      let answerIsEmpty: boolean
      switch (answer.$type) {
        case 'PMI.FACE.BDDM.Extensions.Classes.FaceBooleanAnswer':
          answerIsEmpty = answer.booleanAnswer == null
          break
        case 'PMI.FACE.BDDM.Extensions.Classes.FaceTextAnswer':
          answerIsEmpty = !(answer.reply?.trim())
          break
        case 'PMI.FACE.BDDM.Extensions.Classes.FaceSelectProfileAnswer':
          answerIsEmpty = !answer?.reply?.code
          break
        default:
          console.warn('SimpleAnswerReplyPredicate check is invalid: unrecognized answer type', { predicate, answer })
          return false
      }
      return answerIsEmpty

    default:
      console.warn('SimpleAnswerReplyPredicate check is invalid: unrecognized predicate operation kind', { predicate, answer })
      return false
  }
}

export function checkGroupAnswerReplyPredicate(
  predicate: GroupAnswerReplyPredicate,
  answer: IAnswer
): boolean {
  if (!predicate.predicates?.length) {
    return false
  }
  switch (predicate.conjunctionKind) {
    case 'AND':
      return predicate.predicates.every(p => checkAnswerReplyPredicate(p, answer))
    case 'OR':
      return predicate.predicates.some(p => checkAnswerReplyPredicate(p, answer))
    default:
      console.warn('GroupAnswerReplyPredicate check is invalid: unrecognized predicate conjunction kind', { predicate, answer })
      return false
  }
}