import React, { useEffect } from 'react'

import { Box, LinearProgress } from '@material-ui/core'

import { LockedPage } from './locked-page'

interface LockedLoadingScreenProps {
  children: React.ReactNode
}

export const LockedLoadingScreen: React.FC<LockedLoadingScreenProps> = (props) => {
  useEffect(() => {
    console.log('LockedLoadingScreen mount', props)
  }, [])

  return (
    <LockedPage>
      <Box
        //
        width='100%'
        height='100%'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        fontSize='28px'
      >
        {props.children}
        <Box width='180px' marginTop='20px'>
          <LinearProgress variant='indeterminate' />
        </Box>
      </Box>
    </LockedPage>
  )
}
