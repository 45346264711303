import { ScreenItems, IScreenItem } from '../../../model/screen-item'
import { IUserStepScreen } from '../../../model/user-step-screen'
import { ILocalContext, SubProcessDefiningItem } from './local-context'

type PartialContext = Pick<ILocalContext, 'template' | 'processUnit'>

export function* iterAllScreens(localContext: PartialContext): Generator<IUserStepScreen> {
  let screens
  if (localContext.processUnit) {
    screens = localContext.processUnit.screens
  } else {
    screens = localContext.template.userStepScreens
  }
  for (const key in screens) {
    if (screens[key]) {
      yield screens[key]
    }
  }
}

export function* iterAllItems(items: ScreenItems): Generator<IScreenItem> {
  for (const entry of items) {
    if (entry.$type === 'PMI.FACE.BDDM.Extensions.Classes.ScreenItemGroup') {
      for (const item of entry.items) {
        yield item
      }
    } else {
      yield entry
    }
  }
}

export function* iterProcessItems(localContext: PartialContext): Generator<SubProcessDefiningItem> {
  for (const screen of iterAllScreens(localContext)) {
    if (screen.$type === 'PMI.FACE.BDDM.Extensions.Classes.CompositeUserStepScreen') {
      // console.log('looking in screen', screen)
      for (const item of iterAllItems(screen.items)) {
        switch (item.$type) {
          case 'PMI.FACE.BDDM.Extensions.Classes.TableScreenItem':
          case 'PMI.FACE.BDDM.Extensions.Classes.ViewRichTextListScreenItem':
            yield item
        }
      }
    }
  }
}
