import type { IHttpClient } from '../../infrastructure/http-client-factory'
import type { IRichTextService } from '../rich-text-service-api'

export class RichTextService implements IRichTextService {
  constructor(private readonly _http: IHttpClient) {}

  async getBlob(urlImage: string): Promise<Blob | null> {
    if (urlImage.match('undefined')) {
      return null
    }

    const response = await this._http.request<Blob>({
      method: 'GET',
      url: urlImage,
      responseType: 'blob',
    })

    return response.data
  }

  async getImage(urlImage: string): Promise<string> {
    const blob = await this.getBlob(urlImage)

    if (blob === null) {
      return ''
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        resolve(`${reader.result}`)
      }
      reader.onerror = (e) => {
        reject(e)
      }
    })
  }
}
