import React from 'react'

import { endOfDay, startOfDay } from 'date-fns'
import { useMethods, useSearchParam } from 'react-use'

import { VisitsViewMode, VisitsViewDayMode } from '../models'

interface IVisitPageUiContext {
  viewMode: VisitsViewMode
  viewDayMode: VisitsViewDayMode
  startPeriodDate: number
  endPeriodDate: number
}

export const VisitPageUiContext = React.createContext<{
  uiState: IVisitPageUiContext
  uiStateMethods: WrappedMethods
}>(undefined!)

interface IVisitPageUiMethods {
  setViewMode: (viewMode: VisitsViewMode) => IVisitPageUiContext
  setViewDayMode: (viewDayMode: VisitsViewDayMode) => IVisitPageUiContext
  setStartPeriodDate: (startPeriodDate: number) => IVisitPageUiContext
  setEndPeriodDate: (endPeriodDate: number) => IVisitPageUiContext
}

interface WrappedMethods {
  setViewMode: (viewMode: VisitsViewMode) => void
  setViewDayMode: (viewDayMode: VisitsViewDayMode) => void
  setStartPeriodDate: (startPeriodDate: number) => void
  setEndPeriodDate: (endPeriodDate: number) => void
}

const viewModeValues: VisitsViewMode[] = ['day', 'week', 'month']
const viewDayModeValues: VisitsViewDayMode[] = ['list', 'map']

export const VisitPageUiProvider: React.FC = ({ children }) => {
  const viewMode = useSearchParam('viewMode') as 'day' | 'week' | 'month'
  const isViewModeValid = viewModeValues?.includes(viewMode)

  const viewDayMode = useSearchParam('viewDayMode') as 'list' | 'map'
  const isViewDayModeValid = viewDayModeValues?.includes(viewDayMode)

  const startPeriodDate = useSearchParam('startPeriodDate')

  const initialVisitPageUiContext: IVisitPageUiContext = {
    viewMode: isViewModeValid ? viewMode : 'day',
    viewDayMode: isViewDayModeValid ? viewDayMode : 'list',
    startPeriodDate: startOfDay(Number(startPeriodDate) || new Date()).valueOf(),
    endPeriodDate: endOfDay(Number(startPeriodDate) || new Date()).valueOf()
  }

  const [uiState, uiStateMethods] = useMethods<IVisitPageUiMethods, IVisitPageUiContext>(
    visitPageUiMethods,
    initialVisitPageUiContext
  )

  function visitPageUiMethods(state: IVisitPageUiContext): IVisitPageUiMethods {
    return {
      setViewMode: (viewMode) => ({ ...state, viewMode }),
      setViewDayMode: (viewDayMode) => ({ ...state, viewDayMode }),
      setStartPeriodDate: (startPeriodDate) => ({
        ...state,
        startPeriodDate: startOfDay(startPeriodDate).valueOf()
      }),
      setEndPeriodDate: (endPeriodDate) => ({
        ...state,
        endPeriodDate: endOfDay(endPeriodDate).valueOf()
      })
    }
  }
  // console.log(1, new Date(uiState.startPeriodDate))
  // console.log(2, new Date(uiState.endPeriodDate))
  return <VisitPageUiContext.Provider value={{ uiState, uiStateMethods }}>{children}</VisitPageUiContext.Provider>
}
