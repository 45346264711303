import React from 'react'

import { Box, createStyles, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    emptyMessage: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'rgba(0, 0, 0, 0.38)',
      fontWeight: 'normal',
      minHeight: (props: { minHeightDiff?: number, stretch?: boolean }) =>
        props.stretch === false
          ? 'auto'
          : `calc(100vh - ${(props.minHeightDiff ?? 230) + 60}px)`,
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
        minHeight: (props: { minHeightDiff?: number, stretch?: boolean }) =>
          props.stretch === false
            ? 'auto'
            : `calc(100vh - ${props.minHeightDiff ?? 230}px)`,
      },
    },
  }),
)

interface IEmptyMessageProps {
  message: string
  marginTop?: number
  marginBottom?: number
  minHeightDiff?: number
  className?: string
  stretch?: boolean
}

const EmptyMessage: React.FC<IEmptyMessageProps> = ({ message, marginTop, marginBottom, className, minHeightDiff, stretch }) => {
  const classes = useStyles({ minHeightDiff, stretch })
  return (
    <Box mt={marginTop} mb={marginBottom}>
      <Typography className={classnames(classes.emptyMessage, className)} variant='h6' component='h6' align='center'>
        {message}
      </Typography>
    </Box>
  )
}

export default React.memo(EmptyMessage)
