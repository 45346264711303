import { identity } from 'lodash'

import { IStringPropertyScreenItem, SETaskRepresentativeScreenItem } from '../../../../../model/screen-item'

export function getTaskRepresentativeMockItems(
  source: SETaskRepresentativeScreenItem,
  t: (key: string) => string = identity,
): { name: IStringPropertyScreenItem; agency: IStringPropertyScreenItem; email: IStringPropertyScreenItem } {
  const name: IStringPropertyScreenItem = {
    $type: 'PMI.FACE.BDDM.Extensions.Classes.StringPropertyScreenItem',
    actionKind: source.actionKind,
    displayName: t('representativeInputLabel'),
    editSettings: {
      required: true,
      errorHelperText: t('requiredFieldError'),
      placeholder: t('representativeInputPlaceholder'),
    },
    orderNumber: 0,
    propertyName: '',
  }

  const agency: IStringPropertyScreenItem = {
    $type: 'PMI.FACE.BDDM.Extensions.Classes.StringPropertyScreenItem',
    actionKind: source.actionKind,
    displayName: t('thirdPartyInputLabel'),
    editSettings: {
      required: true,
      errorHelperText: t('requiredFieldError'),
      placeholder: t('thirdPartyInputPlaceholder'),
    },
    orderNumber: 1,
    propertyName: '',
  }
  const email: IStringPropertyScreenItem = {
    $type: 'PMI.FACE.BDDM.Extensions.Classes.StringPropertyScreenItem',
    actionKind: source.actionKind,
    displayName: t('emailInputLabel'),
    editSettings: {
      required: true,
      errorHelperText: t('requiredFieldError'),
      placeholder: t('emailInputPlaceholder'),
    },
    orderNumber: 2,
    propertyName: '',
  }

  return {
    name,
    agency,
    email,
  }
}
