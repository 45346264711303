import React, { useContext, useState } from 'react'

import { createStyles, Grid, IconButton, Menu, MenuItem, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MoreVert from '@material-ui/icons/MoreVert'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { YandexMapContext } from '../../../components/yandex-map-custom/yandex-map-provider'
import { Code } from '../../../model/base'
import { ADD_VISITS_ENABLED } from '../../../model/user-profile'
import { useHasPermission } from '../../_common/hooks/useGetPpermission'
import { PosMenuItems } from '../store-menu-page/menu-items'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuWrap: {
      [theme.breakpoints.down('xs')]: {
        order: -1,
      },
    },
  }),
)

interface IStoreMenuButton {
  posCode: Code
}

export const StoreMenuButton: React.FC<IStoreMenuButton> = ({ posCode }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation('stores')
  const yandexMap = useContext(YandexMapContext)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>()
  const isMenuOpen = Boolean(anchorEl)
  const closeMenu = (): void => setAnchorEl(undefined)

  const isAddVisitPermissions = useHasPermission(ADD_VISITS_ENABLED)

  return (
    <Grid className={classes.menuWrap} item onClick={(ev) => ev.stopPropagation()}>
      <IconButton
        size='small'
        id='demo-positioned-button'
        aria-controls={isMenuOpen ? 'demo-positioned-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={isMenuOpen ? 'true' : undefined}
        onClick={(ev) => {
          setAnchorEl(ev.currentTarget)
        }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: 'left',
        }}
      >
        {isAddVisitPermissions && (
          <MenuItem
            onClick={() => {
              navigate('/visits/add', {
                state: {
                  prevPath: location.pathname,
                  isViewMap: !!yandexMap,
                  selectedDate: Date.now(),
                  selectedPos: posCode,
                  lastCoords: !yandexMap
                    ? undefined
                    : { center: yandexMap.instanceMap.getCenter(), zoom: yandexMap.instanceMap.getZoom() },
                },
              })
              closeMenu()
            }}
          >
            {t(`menu.add-visit`)}
          </MenuItem>
        )}
        {Object.values(PosMenuItems).map((item) => (
          <MenuItem
            key={item.path}
            onClick={() => {
              navigate(`store/${posCode}/menu/${item.path}`, {
                state: { prevPath: location.pathname, isViewMap: !!yandexMap },
              })
              closeMenu()
            }}
          >
            {t(`menu.${item.displayName}`)}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  )
}
