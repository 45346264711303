import { makeStyles, Theme, createStyles } from '@material-ui/core'

const SUCCESS_COLOR = '#388E3C'
const DECLINE_COLOR = '#D32F2F'
const NA_COLOR = '#3870B2'
const BUTTON_BORDER_RADIUS = 28
const MIN_WIDTH = 70

export const useBooleanStyles = makeStyles<Theme>((theme) =>
  createStyles({
    label: {
      display: 'flex',
    },
    buttonsWrap: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
    answerWrap: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-start',
    },
    successButton: {
      marginRight: theme.spacing(1),
      borderColor: SUCCESS_COLOR,
      color: SUCCESS_COLOR,
      borderRadius: BUTTON_BORDER_RADIUS,
      whiteSpace: 'nowrap',
      minWidth: MIN_WIDTH,
    },
    declineButton: {
      marginRight: theme.spacing(1),
      borderColor: DECLINE_COLOR,
      color: DECLINE_COLOR,
      borderRadius: BUTTON_BORDER_RADIUS,
      whiteSpace: 'nowrap',
      minWidth: MIN_WIDTH,
    },
    naButton: {
      borderColor: NA_COLOR,
      color: NA_COLOR,
      borderRadius: BUTTON_BORDER_RADIUS,
      minWidth: MIN_WIDTH,
    },
  }),
)
