export function generateArray<T>(count: number, valueProvider?: (i: number) => T): Array<T | undefined> {
  const array = new Array(count)
  for (let i = 0; i < count; i++) {
    array.push(valueProvider ? valueProvider(i) : undefined)
  }
  return array
}

export function mapNotNull<T, V>(array: Array<T | undefined | null>, mapper: (item: T) => V): V[] {
  const result = []
  for (const item of array) {
    if (item != null) {
      result.push(mapper(item))
    }
  }
  return result
}

export function removeWhere<T>(array: T[], filter: (item: T) => boolean): T[] {
  const removed = []
  for (let i = array.length - 1; i >= 0; i--) {
    const item = array[i]
    if (filter(item)) {
      array.splice(i, 1)
      removed.push(item)
    }
  }
  return removed
}

export function unique<T>(array: T[] | Set<T>): T[] {
  if (array instanceof Set) {
    return Array.from(array)
  }
  if (array != null && array.length > 1) {
    return Array.from(new Set(array))
  }
  return array
}
