/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useMemo, useState } from 'react'

import { useLocation } from 'react-router-dom'
import { ObjectManager, ObjectManagerFeature, ObjectManagerFeatures } from 'react-yandex-maps'
import ymaps from 'yandex-maps'

import { MapMenu } from '../../../components/yandex-map-custom/map-menu'
import { YandexMapContext } from '../../../components/yandex-map-custom/yandex-map-provider'
import { IPointOfSale } from '../../../model/pos'
import { appToast } from '../../../utils'
import { useIsSmall } from '../../_common/hooks/useIsSmall'
import { StoreListMap } from './store-list-map'
import StoreMobileCard from './store-mobile-card'
import { scrollToStore } from './utils'

interface IStoresYandexMap {
  storesAll: IPointOfSale[]
  storesView: IPointOfSale[]
  selectStoreCodes: string[]
  onSelect: (code: string[]) => void
}

export const StoresYandexMap: React.FC<IStoresYandexMap> = ({ storesAll, storesView, selectStoreCodes, onSelect }) => {
  const { ymapsAPI, instanceMap, setBounds } = useContext(YandexMapContext)
  const [objectManager, setObjectManager] = useState<ymaps.ObjectManager>()
  const isSmall = useIsSmall()
  const { state } = useLocation()

  const focusOnMap = (storeCode?: string): void => {
    const store = storesAll.find((store) => store.code === storeCode)
    const latitude = store?.coordinates?.latitude
    const longitude = store?.coordinates?.longitude
    if (latitude && longitude) {
      void instanceMap?.setCenter([latitude, longitude], 16)
      return
    }
    if (storeCode) {
      appToast.info('Координаты отсутствуют')
    }
  }

  const geoCoordinates: number[][] = useMemo(() => {
    return storesAll
      .filter((store) => store.coordinates)
      .map((store) => [store.coordinates!.latitude, store.coordinates!.longitude])
  }, [storesAll])

  useEffect(() => {
    if (!objectManager) {
      return
    }
    objectManager?.objects.events.add(['click'], function (e) {
      const objectId = e.get('objectId')
      const object = objectManager.objects.getById(objectId) as unknown as any
      onSelect([object.properties.code])
    })

    objectManager?.clusters.events.add(['click'], function (e) {
      if (instanceMap.getZoom() === instanceMap.zoomRange.getCurrent()?.[1]) {
        const cluster = objectManager.clusters.getById(e.get('objectId'))
        const objects = cluster?.properties.geoObjects
        if (objects) {
          const stores = objects.map((obj) => (obj.properties as unknown as { code: string; isActive: boolean }).code)
          onSelect(stores)
        }
      }
    })

    objectManager?.clusters.events.add(['add', 'zoom'], function (e) {
      const cluster = objectManager.clusters.getById(e.get('objectId'))
      const objects = cluster?.properties.geoObjects
      if (objects) {
        const stores = objects.map((obj) => {
          return obj.properties as unknown as { code: string; isActive: boolean }
        })
        const isActive = !!stores.find((store) => store.isActive)
        objectManager.clusters.setClusterOptions(cluster?.id, {
          clusterIconLayout: createClusterLayout(isActive),
          clusterIconShape: {
            type: 'Circle',
            coordinates: [0, 0],
            radius: 25,
          },
        })
      }
    })
  }, [objectManager])

  useEffect(() => {
    const { lastCoords } = (state ?? {}) as { lastCoords: { center: number[]; zoom: number } }
    setBounds(geoCoordinates, lastCoords)
  }, [])

  useEffect(() => {
    const firstStore = selectStoreCodes?.[0]
    if (firstStore) {
      scrollToStore(firstStore)
    }
  }, [selectStoreCodes])

  const createPlacemarkLayout = (isActive: boolean): ymaps.layout.templateBased.Base => {
    const iconLayout = ymapsAPI?.templateLayoutFactory.createClass(
      `<div class='store-placemark ${isActive ? 'store-placemark--active' : ''}' />`,
      {},
    )
    return iconLayout
  }

  const createClusterLayout = (isActive = false): ymaps.layout.templateBased.Base => {
    const iconLayout = ymapsAPI?.templateLayoutFactory.createClass(
      `<div class='store-placemark-cluster ${
        isActive ? 'store-placemark-cluster--active' : ''
      }'>{{ properties.geoObjects.length }}</div>`,
      {},
    )
    return iconLayout
  }

  const data = useMemo(() => {
    const features: ObjectManagerFeatures = []
    for (const [index, store] of storesAll.entries()) {
      const lat = store.coordinates?.latitude
      const lon = store.coordinates?.longitude

      if (lat && lon) {
        const isActive = !!selectStoreCodes.find((code) => code === store.code)

        const icon = createPlacemarkLayout(isActive)
        const tmpObj: ObjectManagerFeature = {
          type: 'Feature',
          id: `${store.code};isActive-${isActive}`,
          geometry: {
            type: 'Point',
            coordinates: [lat, lon],
          },
          properties: {
            code: store.code,
            isActive,
          },
          options: {
            iconLayout: 'default#imageWithContent',
            iconImageHref: '',
            iconContentLayout: icon,
            iconShape: {
              type: 'Circle',
              coordinates: [0, 0],
              radius: 25,
            },
            zIndex: isActive ? 210 : 110 + index,
          },
        }

        features.push(tmpObj)
      }
    }

    return features
  }, [storesAll, selectStoreCodes])

  return (
    <>
      <ObjectManager
        instanceRef={(ref) => {
          const objectManagerRef = ref as unknown as ymaps.ObjectManager
          setObjectManager(objectManagerRef)
        }}
        features={data}
        options={{
          clusterize: true,
        }}
        clusters={{
          preset: 'islands#redClusterIcons',
        }}
      />
      <MapMenu />
      {!isSmall ? (
        <StoreListMap
          stores={storesView}
          selectStoreCodes={selectStoreCodes}
          onSelect={onSelect}
          setFocus={focusOnMap}
        />
      ) : (
        <StoreMobileCard onClose={() => onSelect([])} stores={storesAll} selectStoreCodes={selectStoreCodes} />
      )}
    </>
  )
}
