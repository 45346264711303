import React, { useContext } from 'react'

import { createStyles, Link, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import _ from 'lodash'
import { useTranslation } from "react-i18next";
import { useAsync } from 'react-use'

import { grays } from '../../layout/theme'
import { TitleBar } from '../../layout/title-bar'
import { IHyperlinkDescription } from '../../model/hyperlink'
import { ApiContext, ProfileContext } from '../../providers'
import { ErrorPage } from '../error-page'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down('xs')]: {
        // paddingTop: 40,
      },
    },
    list: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      padding: '16px 26px',
      backgroundColor: '#ffffff',
      border: `1px solid ${grays.gray5}`,
    },
    link: {
      marginBottom: 16,
      '&:last-child': {
        marginBottom: 0,
      },
    },
  }),
)

const HyperlinksPage: React.FC = () => {
  const { t } = useTranslation('menu')
  const classes = useStyles()
  const profile = useContext(ProfileContext)
  const api = useContext(ApiContext)

  const linksOps = useAsync(async () => await api.dictionary.getHyperlinks(), [])

  if (linksOps.error) {
    return <ErrorPage errorMessage={linksOps.error.message} />
  }

  if (linksOps.loading && !linksOps.value) return <></>

  const links = linksOps
    .value!.filter((link) => !link.relatedType) // Если RelatedType = null (или '', или вообще не заполнен), то это глобальная ссылка.
    .filter((link) => {
      const relatedProfiles = link.relatedProfiles
      if (!relatedProfiles?.length) {
        return true
      }
      return relatedProfiles.find((relatedProfile) => relatedProfile.code === profile.value?.profile.code)
    })

  const sortedLinks = _.orderBy(links, ['linkDisplayName'], ['asc'])

  const renderLink = (hyperlink: IHyperlinkDescription): JSX.Element => {
    const resultLink = hyperlink.linkFormat

    return (
      <Typography color='primary' className={classes.link}>
        <Link href={resultLink} target='_blank' underline='none' variant='body1'>
          {hyperlink.linkDisplayName ?? 'Перейти'}
        </Link>
      </Typography>
    )
  }

  return (
    <div className={classes.root}>
      <TitleBar>{t('hyperlinks')}</TitleBar>
      <div className={classes.list}>
        {sortedLinks.length ? sortedLinks.map(renderLink) : <Typography>Доступных ссылок не найдено</Typography>}
      </div>
    </div>
  )
}

export default HyperlinksPage
