import { createStyles, InputBase, withStyles } from '@material-ui/core'

export const CompactSelect = withStyles(
  (theme) =>
    createStyles({
      root: {
        '& .MuiSelect-icon': {
          width: '0.8em',
          height: '0.8em',
          top: 'calc(50% - 9px)',
          right: 14,
        },
      },
      input: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.palette.common.black,
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: '0.1',
        padding: '8px 16px',
        backgroundColor: '#ffffff',
        borderRadius: '40px',
        minWidth: '100px',

        '&.MuiSelect-select.MuiSelect-select': {
          paddingRight: 0,
        },
        '&.MuiSelect-selectMenu': {
          whiteSpace: 'normal',
        },
        '&:focus': {
          backgroundColor: '#ffffff',
          borderRadius: '40px',
          // textDecoration: 'underline',
        },
      },
    }),
  { name: 'CustomSelect' },
)(InputBase)
