import React from 'react'

import { Box, Typography } from '@material-ui/core'

import { appToast } from '../utils'
import { APP_VERSION, BUILD_INFO } from '../version'

export const AppVersion: React.FC = () => {
  const version = APP_VERSION

  if (!version) {
    return null
  }
  return (
    <>
      <Box px={2} pt={1} pb={3}>
        <Typography
          variant='subtitle2'
          onClick={(ev) => {
            const range = document.createRange()
            range.selectNode(ev.target as Node)
            window.getSelection()?.removeAllRanges()
            window.getSelection()?.addRange(range)
            if (document.execCommand('copy')) {
              appToast.info('Версия скопирована', { style: undefined, autoClose: 1500, hideProgressBar: true })
            }
          }}
        >
          {version}
          <br />
          <span style={{ opacity: 0.5, fontSize: 12 }}>{BUILD_INFO.hash ? BUILD_INFO.hash : ''}</span>
        </Typography>
      </Box>
    </>
  )
}
