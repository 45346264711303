import React, { useCallback, useContext, useMemo, useState } from 'react'

import {
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Grid,
  Paper,
  SvgIcon,
  Box,
  useMediaQuery,
} from '@material-ui/core'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useAsync } from 'react-use'

import { ReactComponent as ChevronRight } from '../../../../assets/icons/ChevronRight.svg'
import { getAppFrameStyle } from '../../../../assets/pm-shared-styles'
import theme, { grays } from '../../../../layout/theme'
import { ApiContext } from '../../../../providers'
import { IVisitTaskSummary3 } from '../../../../services/task-service-api'
import { dateFormat } from '../../../../utils'
import { useAvailableTemplateList } from '../../../_common/hooks/useAvailableTemplateList'
import { getPosAddress } from '../../../_common/pos'
import { getPageLayoutStyles } from '../../../tasks/components/shared-styles'
import SessionPeriodSwitcher from '../../../tasks/sales-expert/tabs/session/components/session-period-switcher'
import SearchInput from '../../../tasks/sales-expert/tabs/session/search-input'
import { findRepresentativeItem } from '../../../tasks/sales-expert/utils'
import { getTaskProperty } from '../../../tasks/script-tasks/propertyName'
import { SessionViewMode, viewModeToPeriod } from '../../../tasks/template-tasks/utils'
import SEReportItemView from './item-view'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appFrame: getAppFrameStyle(theme),
    root: {
      // padding: theme.spacing(4, 3)
    },
    item: {
      cursor: 'pointer',
      display: 'flex',
      minWidth: 320,
      alignItems: 'center',
      padding: '7px 24px',
      marginBottom: theme.spacing(1),
      minHeight: theme.spacing(9),
      borderRadius: 12,
      boxShadow: '0px 3px 7px rgba(96, 97, 112, 0.13)',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
      },
    },
    content: {
      ...getPageLayoutStyles(theme),
      paddingTop: theme.spacing(3),
    },
    selectIcon: {
      width: 36,
      height: 36,
      padding: 0,
      marginLeft: 'auto',
    },
    title: {
      color: 'rgba(0, 0, 0, 0.87)',
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
        padding: theme.spacing(1),
      },
    },
    wrapper: {
      minHeight: 'calc(100vh - 290px)',
      [theme.breakpoints.down('xs')]: {
        minHeight: 'calc(100vh - 230px)',
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
    },
    emptyMessage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'rgba(0, 0, 0, 0.38)',
      fontWeight: 'normal',
      minHeight: 'calc(100vh - 290px)',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
        minHeight: 'calc(100vh - 230px)',
      },
    },
    isInfoOpen: {
      minHeight: 'calc(100vh - 390px)',
      [theme.breakpoints.down('xs')]: {
        minHeight: 'calc(100vh - 340px)',
      },
    },
    searchAndFilters: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: theme.spacing(0, 0, 3),
      [theme.breakpoints.down('xs')]: {
        alignItems: 'center',
        padding: theme.spacing(0, 2),
        margin: theme.spacing(2, 0),
        flexDirection: 'column-reverse',
        '& > *:first-child': {
          width: '100% !important',
          margin: theme.spacing(2, 0, 0),
        },
      },
    },
    search: {
      width: '40%',
      minWidth: 150,
      marginRight: theme.spacing(2),
    },
    periodFilter: {
      // minWidth: 325
    },
    captionLarge: {
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '0.1px',
      fontWeight: 500,
    },
    captionMedium: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.6,
    },
    captionSmall: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '22px',
      letterSpacing: '0.25px',
      color: grays.gray2,
    },
    message: {
      color: theme.palette.text.secondary,
      fontSize: 14,
      lineHeight: '20px',
    },
  }),
)

const SEV3_VERSION = 'ServiceSE_v3'

export const SEReportList: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation(['reports', 'common'])
  // const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'))
  const api = useContext(ApiContext)
  const [selectedItemCode, setSelectedItemCode] = useState<string | null>(null)
  const [viewMode, setViewMode] = useState<SessionViewMode>('all')
  const [searchQuery, setSearchQuery] = useState('')

  const isMobile = useMediaQuery<Theme>(theme.breakpoints.down('xs'))

  const listData = useAsync(async () => {
    const visitTasks = await api.tasks.getTasks3({
      period: viewModeToPeriod(viewMode),
      templateCode: 'ServiceSE',
    })

    return visitTasks
  }, [viewMode])

  const templatesOps = useAvailableTemplateList([])
  const template = templatesOps.value?.find((t) => t.version.code === SEV3_VERSION)
  const representativeItem = template && findRepresentativeItem(template)

  // console.log({ representativeItem })
  const list = useMemo(() => {
    if (!listData.value) return []
    const filtered = listData.value.filter(({ task, pos }) => {
      const fullAddress = pos.address?.fullAddress ?? ''
      const city = pos.address?.city ?? ''
      const searchableAddress = `${fullAddress} ${city} ${fullAddress}`
      const query = searchQuery.toLowerCase()

      function findByRepresentative(): boolean {
        if (task.template.version?.code !== SEV3_VERSION) return false
        if (!representativeItem) return false
        const name = getTaskProperty(task, representativeItem.representativeNamePropertyName, '')
        const email = getTaskProperty(task, representativeItem.represenatativeEmailPropertyName, '')
        console.log({ name, email })
        return name.toLowerCase().includes(query) || email.toLowerCase().includes(query)
      }

      return (
        searchableAddress.toLowerCase().includes(query) ||
        pos.code.toLowerCase().includes(query) ||
        pos.name.toLowerCase().includes(query) ||
        findByRepresentative()
      )
    })
    return filtered.sort((a, b) => b.task.creationTime! - a.task.creationTime!)
  }, [listData.value, searchQuery])

  const onListItemClickHandler = useCallback((itemCode: string) => {
    setSelectedItemCode(itemCode)
  }, [])

  const clearSelectedItemHandler = useCallback(() => {
    setSelectedItemCode(null)
  }, [])

  const handleChangeViewMode = useCallback((newValue: SessionViewMode): void => {
    setSelectedItemCode(null)
    setViewMode(newValue)
  }, [])

  const renderItem = (item: IVisitTaskSummary3): JSX.Element => {
    return (
      <Paper
        className={classes.item}
        key={item.task.code}
        square
        elevation={0}
        onClick={() => {
          onListItemClickHandler(item.task.code)
        }}
      >
        <Grid style={{ height: '100%' }} container alignItems='center' wrap='nowrap' spacing={isMobile ? 2 : 5}>
          <Grid item>
            <Typography className={classes.captionLarge}>{dateFormat(new Date(item.date), 'dd.MM')}</Typography>
          </Grid>
          <Grid item>
            <Box position='relative'>
              <Box
                borderLeft='2px solid currentColor'
                color='#E6E9EF'
                height='48px'
                top='-24px'
                position='absolute'
              ></Box>
            </Box>
          </Grid>
          <Grid item>
            <div className={classes.captionLarge}>
              {item.pos.name} {getPosAddress(item.pos)}
            </div>
            <div className={classes.captionSmall}>{item.pos.code}</div>
          </Grid>
        </Grid>
        <SvgIcon
          className={classes.selectIcon}
          component={ChevronRight}
          viewBox='0 0 36 36'
          color='primary'
          fontSize='large'
        />
      </Paper>
    )
  }

  const renderEmptyMessage = (): string => {
    if (listData.error) {
      throw new Error('Ошибка получения предыдущих задач')
    }

    if (listData.loading) {
      return t('loading', { ns: 'common' })
    } else {
      return searchQuery.trim() ? t('emptySearchMessage') : t('noTasks')
    }
  }

  if (selectedItemCode && listData.value?.length) {
    return (
      <SEReportItemView
        item={listData.value.find((x) => x.task.code === selectedItemCode)!}
        onBack={clearSelectedItemHandler}
        representativeItem={representativeItem}
      />
    )
  }

  return (
    <div className={classes.content}>
      <div className={classes.searchAndFilters}>
        <div className={classes.search}>
          <SearchInput onChange={setSearchQuery} placeholder={t('search', { ns: 'common' })} />
        </div>
        <div className={classes.periodFilter}>
          <SessionPeriodSwitcher currentViewMode={viewMode} onChangeViewMode={handleChangeViewMode} />
        </div>
      </div>
      <div className={classnames(classes.wrapper)}>
        {listData.value?.length ? (
          <div>{list.map((item) => renderItem(item))}</div>
        ) : (
          <Typography className={classnames(classes.emptyMessage)} variant='h6' component='h6' align='center'>
            {renderEmptyMessage()}
          </Typography>
        )}
      </div>
    </div>
  )
}
