import { ContentDocumentStore, TaskTemplateContentStore } from '../../data/schema'
import { IEntityReference } from '../../model/base'
import { IContentDocument } from '../../model/content-document'
import { ITaskTemplateContent } from '../../model/task-template-content'
import { IDocumentService, ITaskTemplateDocumentsRequest } from '../document-service-api'
import { LocalStorageBaseService } from './local-storage-base-service'

export default class LocalStorageDocumentService extends LocalStorageBaseService implements IDocumentService
{
  async getTaskTemplateDocuments(request: ITaskTemplateDocumentsRequest): Promise<IContentDocument[]> {
    const taskTemplateContents = await this._storage.getWhere(
      TaskTemplateContentStore,
      (ttc: ITaskTemplateContent) => {
        return ttc.template?.version?.code === request.taskTemplateVersionCode
          && (!request.posCode || !ttc.productLocation?.code || ttc.productLocation!.code === request.posCode)
          && ttc.status === 'Active'
      })
    const docCodes = taskTemplateContents.map(ttc => ttc.contentDocument?.code).filter(Boolean)
    return docCodes.length ? await this._storage.getByKeys(ContentDocumentStore, docCodes) : []
  }

  async getContentDocuments(refs: IEntityReference[]): Promise<IContentDocument[]> {
    return this._storage.getByKeys<IContentDocument>(ContentDocumentStore, refs.map(r => r.code));
  }
}