import React from 'react'

import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'

import { BlobStorageError } from '../../../../infrastructure/blob-storage/blob-storage-api'
import { IMediaStorageSettings } from '../../../../model/screen-item'
import { ItemCard } from '../../../tasks/template-tasks/composite-screen/item-card'
import { ITempFileItem, usePermissionsAddPhotos } from '../../utils'
import { DocumentThumbnailItem } from '../document-thumbnail-item/document-thumbnail-item'
import { PhotoInput } from '../photo-input/photo-input'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    documentList: {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: '-8px',
      [theme.breakpoints.down('xs')]: {
        marginLeft: '-5px',
      },
    },
  }),
)

interface Props {
  isReadOnly?: boolean
  label: React.ReactNode
  documents: ITempFileItem[]
  onClickItem: (item: ITempFileItem, isChecked: boolean) => void
  onChangeInput?: (file: Blob | undefined) => void
  isSelectMode?: boolean
  checkedList?: string[]
  onLongPressItem?: (evt: MouseEvent | TouchEvent, item: ITempFileItem) => void
  isError?: boolean
  nullValueCaption?: string
  onDownloadError: React.Dispatch<React.SetStateAction<BlobStorageError | undefined>>
  storage: IMediaStorageSettings
}

export const DocumentsScreenItem: React.FC<Props> = ({
  label,
  documents,
  onClickItem,
  onChangeInput,
  isSelectMode,
  checkedList = [],
  onLongPressItem,
  isError,
  isReadOnly = true,
  nullValueCaption,
  onDownloadError,
  storage,
}) => {
  const classes = useStyles()

  const permissionsAddPhoto = usePermissionsAddPhotos()
  // const permissionsAddPhoto = false

  const renderWrapperInput = (): JSX.Element => {
    if (!permissionsAddPhoto && !documents.length) {
      return <Typography>Добавление фотографий возможно только с мобильного устройства</Typography>
    } else {
      return (
        <PhotoInput
          onChange={onChangeInput}
          showCameraOnOpen={false}
          permissionsAddPhoto={permissionsAddPhoto}
          allowAddPhoto={true}
          isContrast={true}
        />
      )
    }
  }

  return (
    <ItemCard label={label} isError={isError}>
      <div className={classes.documentList}>
        {!isReadOnly && renderWrapperInput()}
        {isReadOnly && !documents.length ? <Typography>{nullValueCaption ?? 'N/A'}</Typography> : <></>}
        {documents?.map((document) => {
          const isChecked = !!checkedList.find((id) => id === document.metadata.key)
          return (
            <DocumentThumbnailItem
              key={document.metadata.key}
              isSelectMode={!!isSelectMode}
              isChecked={isChecked}
              item={document}
              onClickItem={() => {
                onClickItem(document, isChecked)
              }}
              onLongPress={(evt) => {
                onLongPressItem?.(evt, document)
              }}
              onDownloadError={onDownloadError}
              storage={storage}
            />
          )
        })}
      </div>
    </ItemCard>
  )
}
