import React, { useMemo } from 'react'

import { Box, createStyles, makeStyles, Theme } from '@material-ui/core'

import { IScreenItem } from '../../../../model/screen-item'
import { IActionScreenItem } from '../../../../model/screen-item-base'
import { IPredicate } from '../../../../model/script-predicate'
import { useIsSmall } from '../../../_common/hooks/useIsSmall'
import { ScreenItem } from './screen-item'

const DEFAULT_MIN_WIDTH = 'auto'
const MOBILE_MIN_WIDTH = '100%'
interface IStylesProps {
  isSmall: boolean
  minWidth?: string
}
const useStyles = makeStyles<Theme, IStylesProps>((theme) =>
  createStyles({
    container: {
      margin: '0px -4px',
    },
    item: {
      padding: '0px 4px',
      minWidth: ({ minWidth }) => minWidth,
    },
  }),
)

export interface IFlexContainerScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.FlexContainerScreenItem'
  items: IScreenItem[]
  visible: IPredicate
  justifyContent: 'Start' | 'Center' | 'End'
}

interface FlexContainerProps {
  item: IFlexContainerScreenItem
}

const FlexContainerScreenItemControl: React.FC<FlexContainerProps> = ({ item }) => {
  const isSmall = useIsSmall()
  const classes = useStyles({ isSmall })

  return (
    <Box
      display='flex'
      alignItems='center'
      flexWrap='wrap'
      justifyContent={item.justifyContent ?? 'center'}
      flexDirection={isSmall ? 'column' : 'row'}
      className={classes.container}
    >
      {item.items.map((it, index) => (
        <FlexItem key={index} item={it}></FlexItem>
      ))}
    </Box>
  )
}

export default FlexContainerScreenItemControl

interface FlexItemProps {
  item: IScreenItem
}
const FlexItem: React.FC<FlexItemProps> = ({ item }) => {
  const isSmall = useIsSmall()
  const { styles } = item
  const { default: main, mobile } = styles ?? {}

  const minWidth = useMemo(() => {
    const def = main?.minWidth ? `${main.minWidth}px` : DEFAULT_MIN_WIDTH
    const mb = mobile?.minWidth ? `${mobile.minWidth}px` : MOBILE_MIN_WIDTH
    return isSmall ? mb : def
  }, [main, mobile, isSmall])

  const classes = useStyles({ isSmall, minWidth })
  return (
    <Box display='flex' alignItems='start' className={classes.item}>
      <ScreenItem
        state={{
          value: '',
          touched: false,
          validate: { isError: false },
        }}
        onBlur={() => null}
        onChange={async () => Promise.resolve()}
        item={item}
      />
    </Box>
  )
}
