import { groupBy, keyBy } from 'lodash'

import { camelCase } from './camelcase'
import { Type, TypeDecl } from './models'

export type TypesTransformer = (types: TypeDecl[]) => void

export function camelCaseFieldNames(): TypesTransformer {
  return function (types) {
    for (const typeDecl of types) {
      if (typeDecl.type.kind === 'Record') {
        for (const fieldDecl of typeDecl.type.fields) {
          fieldDecl.name = camelCase(fieldDecl.name)
        }
      }
    }
  }
}

export function addTypeFieldToRecords(): TypesTransformer {
  return function (types) {
    for (const typeDecl of types) {
      if (typeDecl.type.kind === 'Record' && !typeDecl.type.isAbstract) {
        typeDecl.type.fields.unshift({
          name: '$type',
          type: {
            kind: 'StringLiteral',
            value: typeDecl.name,
          },
        })
      }
    }
  }
}

export function removeField(type: string, field: string): TypesTransformer {
  return function (types) {
    const typeDecl = types.find((x) => x.name === type)
    if (!typeDecl) {
      return
    }
    if (typeDecl.type.kind !== 'Record') {
      return
    }

    typeDecl.type.fields = typeDecl.type.fields.filter((x) => x.name !== field)
  }
}

export function replaceUsagesOfBaseTypesWithUnionOfDerived(options?: {
  exclude?: (decl: TypeDecl) => boolean
}): TypesTransformer {
  const exclude = options?.exclude ?? (() => false)

  return function (types) {
    function getBaseTypeName(typeDecl: TypeDecl) {
      if (typeDecl.type.kind === 'Record' && typeDecl.type.baseType?.kind === 'TypeRef') {
        return typeDecl.type.baseType.name
      } else {
        return undefined
      }
    }

    const byBaseTypeName = groupBy(types, getBaseTypeName)
    const byTypeName = keyBy(types, (typeDecl) => typeDecl.name)

    function replaceType(type: Type): Type {
      switch (type.kind) {
        case 'TypeRef':
          if (type.typeArgs.length > 0) {
            return {
              kind: type.kind,
              name: type.name,
              typeArgs: type.typeArgs.map((typeArg) => replaceType(typeArg)),
            }
          } else {
            const referencedType: TypeDecl | undefined = byTypeName[type.name]
            if (!referencedType) {
              return type
            }
            if (referencedType.type.kind !== 'Record') {
              return type
            }
            if (exclude(referencedType)) {
              return type
            }

            const concreteDerived = findConcreteDerivedTypes(referencedType)

            switch (concreteDerived.length) {
              case 0:
                return type
              case 1:
                return {
                  kind: 'TypeRef',
                  name: concreteDerived[0].name,
                  typeArgs: [],
                }
              default:
                return {
                  kind: 'Union',
                  types: concreteDerived.map((x): Type => ({ kind: 'TypeRef', name: x.name, typeArgs: [] })),
                }
            }
          }

        default:
          return type
      }
    }

    function findConcreteDerivedTypes(typeDecl: TypeDecl) {
      return findAllDerivedTypes(typeDecl).filter((x) => x.type.kind === 'Record' && !x.type.isAbstract)
    }

    function findAllDerivedTypes(typeDecl: TypeDecl): TypeDecl[] {
      const derived = findImmediateDerivedTypes(typeDecl)
      return [typeDecl, ...derived.flatMap((x) => findAllDerivedTypes(x))]
    }

    function findImmediateDerivedTypes(typeDecl: TypeDecl) {
      if (typeDecl.type.kind === 'Record') {
        return byBaseTypeName[typeDecl.name] ?? []
      } else {
        return []
      }
    }

    for (const typeDecl of types) {
      if (typeDecl.type.kind === 'Record') {
        for (const fieldDecl of typeDecl.type.fields) {
          fieldDecl.type = replaceType(fieldDecl.type)
        }
      }
    }
  }
}
