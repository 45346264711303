import React, { useState } from 'react'

import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  OutlinedInput,
  SvgIcon,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import classnames from 'classnames'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'

import { ReactComponent as PencilIcon } from '../../../../../assets/icons/pencil.svg'
import { IQuestionableListScreenItem } from '../../../../../model/questionable-list-screen-item'
import { checkPredicate } from '../../../../../model/script-predicate'
import { useIsSmall } from '../../../../_common/hooks/useIsSmall'
import { OpenableTypography } from '../../../components/openable-typography'
import { useUpdateProperty } from '../../../nested/useUpdateProperty'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { IQuestionableRecord } from './utils'

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      padding: 0,
      margin: 0,
      marginLeft: 24,
      fontSize: 16,
      [theme.breakpoints.down('xs')]: {
        marginLeft: 6,
      },
    },
    error: {
      color: theme.palette.error.main,
    },
    dialogContent: {
      width: 528,
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
      },
    },
    text: {
      minWidth: 0,
      fontSize: 16,
      flexGrow: 1,
      textAlign: 'right',
      [theme.breakpoints.down('xs')]: {
        paddingRight: 10,
        textAlign: 'left',
      },
    },
    wrapText: {
      display: 'flex',
      flexGrow: 1,
      [theme.breakpoints.down('xs')]: {
        // paddingBottom: 4,
      },
    },
  }),
)

interface Props {
  item: IQuestionableListScreenItem
  record: IQuestionableRecord
  index: number
  isModalOpen: boolean
  setModalOpen: (index: number | undefined) => void
}

export const QuestionableTextDialog: React.FC<Props> = ({ item, record, index, isModalOpen, setModalOpen }) => {
  const classes = useStyles()
  const isSmall = useIsSmall()
  const updateProperty = useUpdateProperty()
  const localContext = useScriptTaskContext()
  const { t } = useTranslation('sales-expert-tasks')

  const openModal = (): void => {
    setValue(fieldValue)
    setModalOpen(index)
  }
  const closeModal = (): void => setModalOpen(undefined)

  const fieldValue = get(record, item.itemSettings.commentPropertyName ?? '', undefined) as string

  const [value, setValue] = useState(fieldValue)

  const required = item.itemSettings.commentSettings?.required
  let isRequired = false

  if (required) {
    switch (required.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.ScriptPredicate':
        isRequired = checkPredicate(required, record, localContext)
        break
      case 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate':
        isRequired = required.value
    }
  }

  const isError = isRequired ? !fieldValue : false

  const available = item.itemSettings.commentSettings?.available

  if (available) {
    switch (available.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.ScriptPredicate':
        const isAvailable = checkPredicate(available, record, localContext)
        if (!isAvailable) {
          return <></>
        }
        break
      case 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate':
        if (!available.value) {
          return <></>
        }
    }
  }

  return (
    <div>
      <Dialog open={isModalOpen} onClose={closeModal} fullWidth={isSmall}>
        <DialogTitle>Комментарий</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <FormControl fullWidth variant='outlined' size='small'>
            <InputLabel htmlFor='comment'>{t('commentInputPlaceholder')}</InputLabel>
            <OutlinedInput
              autoFocus
              value={value}
              onChange={(evt) => {
                setValue(evt.target.value)
              }}
              fullWidth
              rows={6}
              multiline
              id='comment'
              label={t('commentInputPlaceholder')}
              inputProps={{ maxLength: item.itemSettings.commentSettings?.maxLength }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            onClick={() => {
              if (isRequired && !fieldValue) {
                // - обнуление поля, отвечающего за комментарий (set null);
                void updateProperty(`${item.propertyName}.${index}.${item.itemSettings.answerPropertyName}`, null)
              }
              closeModal()
            }}
          >
            Отменить
          </Button>
          <Button
            color='primary'
            disabled={isRequired && !value}
            onClick={() => {
              void updateProperty(`${item.propertyName}.${index}.${item.itemSettings.commentPropertyName}`, value)
              closeModal()
            }}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
      {!fieldValue ? (
        <Button
          className={classnames(classes.button, { [classes.error]: isError })}
          color={'primary'}
          startIcon={<AddIcon />}
          onClick={openModal}
        >
          Комментарий
        </Button>
      ) : (
        <>
          <Box className={classes.wrapText}>
            <OpenableTypography className={classes.text}>{fieldValue}</OpenableTypography>
            <IconButton
              onClick={openModal}
              edge='end'
              className={classes.button}
              color='primary'
              aria-label='change-comment'
            >
              <SvgIcon component={PencilIcon} />
            </IconButton>
          </Box>
        </>
      )}
    </div>
  )
}
