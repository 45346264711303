/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from 'react'

import { Box, CircularProgress, createStyles, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Navigate } from 'react-router-dom'
import { useAsync, useSearchParam } from 'react-use'

import { LockedPage } from '../../layout/locked-page'
import { AuthContext } from '../../providers'
import { AppRoutes } from '../../routes'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      lineHeight: '36px',
      textAlign: 'center',
      fontSize: 28,
      fontStyle: 'normal',
      fontWeight: 500,
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
        lineHeight: '28px',
      },
    },
  }),
)
// /login-result?code=....
const LoginResultPage: React.FC = () => {
  const classes = useStyles()
  const authContext = useContext(AuthContext)
  const authorizationCode = useSearchParam('code')

  const authorizing = useAsync(async () => {
    if (!authorizationCode) {
      return
    }

    return await authContext.login({ login: authorizationCode, password: '' })
  }, [authorizationCode])

  // Router 6 changed ordering and any redirect here breaks everything
  // TODO: to find if it's ever needed
  // if (!authorizationCode && !authContext?.authenticated) {
  //   const PATH_TO_LOGIN_PAGE = '/'.concat(AppRoutes?.login.path)
  //   return <Navigate to={PATH_TO_LOGIN_PAGE} />
  // }

  if (authorizing.error) {
    return <h1>LoginResultPage ERROR</h1>
  }
  if (authorizing.loading) {
    return (
      <LockedPage>
        <Box display='flex' flexDirection='column' alignItems='center' textAlign='center'>
          <CircularProgress />
          <Box mt={6} />
          <Typography className={classes.title}>Идет аутентификация пользователя</Typography>
        </Box>
      </LockedPage>
    )
  }
  return <h1>LoginResultPage</h1>
}

export default LoginResultPage
