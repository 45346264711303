import { DependencyList, useContext } from 'react'

import { useAsync } from 'react-use'
import { AsyncState } from 'react-use/lib/useAsyncFn'

import { FaceMimeContentRef, IContent, MimeContentRef, TextMimeContent } from '../../../../../model/content'
import { ApiContext, ConfigContext } from '../../../../../providers'

type TheoryBlockContentRef = MimeContentRef | FaceMimeContentRef | TextMimeContent

export interface IUseTheoryResultItem {
  type: 'text' | 'image'
  content: string
}

export const useTheoryContent = (
  content: IContent | undefined,
  deps: DependencyList = [],
): AsyncState<IUseTheoryResultItem[]> => {
  const api = useContext(ApiContext)
  const { config } = useContext(ConfigContext)

  const baseUrl = config.apiUrl

  return useAsync(async () => {
    const parts = content?.parts.map((part) => part) as TheoryBlockContentRef[] | undefined
    const contents: IUseTheoryResultItem[] = []
    if (!parts?.length) {
      return contents
    }

    for await (const part of parts) {
      if (part.$type === 'PMI.BDDM.Common.TextMimeContent') {
        contents.push({
          type: 'text',
          content: part.text,
        })
      }
      if (part.$type === 'PMI.BDDM.Common.MimeContentRef') {
        if (part.target.includes('orange')) return
        const imageUrl = `${baseUrl}/blobapi/${part.target}`
        const base64Image = await api.richtext.getImage(imageUrl)

        contents.push({
          type: 'image',
          content: base64Image,
        })
      }
    }

    return contents
  }, [...deps])
}
