import React, { useContext, useState } from 'react'

import { Box, createStyles, FormControl, FormControlLabel, Radio, RadioGroup, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import isPast from 'date-fns/isPast'
import { useLocation, useNavigate } from 'react-router-dom'

import { getAppFrameStyle } from '../../../assets/pm-shared-styles'
import { ValidationFab } from '../../../components/validation-fab'
import { LogManager } from '../../../infrastructure/logger'
import { PageContent } from '../../../layout/page-content'
import { TitleBar } from '../../../layout/title-bar'
import { createReferenceToEntity, getEntityKey } from '../../../model/base'
import {ApiContext, ProfileContext} from '../../../providers'
import { useDefaultCodeSpace } from '../../../providers/config/useDefaultCodeSpace'
import { appToast } from '../../../utils'
import { useAvailableTemplateList } from '../../_common/hooks/useAvailableTemplateList'
import { ICreateVisitPageStateFromRoute } from '../../visits'
import VisitCreateDatePicker from '../../visits/visit-create-date-picker/visit-create-date-picker'
import { ItemCard } from '../template-tasks/composite-screen/item-card'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appFrame: { ...getAppFrameStyle(theme) },
    form: {
      width: '100%',
    },
    label: {
      margin: 0,
      padding: '0',
      width: '100%',
      minHeight: 40,
      justifyContent: 'space-between',
      '& .MuiFormControlLabel-label': {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '24px',
      },
    },
  }),
)

const logger = LogManager.getLogger('NonVisitTaskCreatePage')

export const NonVisitTaskCreatePage: React.FC = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation() as { state: ICreateVisitPageStateFromRoute }
  const api = useContext(ApiContext)
  const profile = useContext(ProfileContext)
  const defaultCodeSpace = useDefaultCodeSpace()

  const templatesOps = useAvailableTemplateList(['Assign', 'Proceed'])
  const nonVisitTaskTemplates = templatesOps.value?.filter((template) => template.nonVisitTask)

  const [selectedDate, setSelectedDate] = useState<number>(() => {
    if (location.state?.selectedDate && !isPast(location.state.selectedDate)) {
      return location.state.selectedDate
    }
    return Date.now()
  })
  const [selectedTemplateCode, setSelectedTemplateCode] = useState<string | null>(null)

  const onBackHandler = (): void => navigate(-1)

  const createTask = async (): Promise<void> => {
    console.log('creating task', selectedTemplateCode, 'for date', new Date(selectedDate))
    const template = templatesOps.value?.find((template) => getEntityKey(template) === selectedTemplateCode)
    if (!template) return

    const executivePosition = await api.userProfile.getCurrentFieldPositionRoleReference()

    try {
      const res = await api.tasks.createTask({
        $type: template.taskObjectType,
        name: template.name,
        startDate: selectedDate,
        source: 'SelfAssigned',
        template: createReferenceToEntity(template, defaultCodeSpace, undefined, true),
        executive: profile.value?.employee?.account,
        executivePosition,
        executivePositionRole: executivePosition,
        status: 'Planned',
      })
      onBackHandler()
      console.log('created task', res)
    } catch (err) {
      const message = 'При добавлении вневизитной задачи произошла ошибка'
      logger.error('createTask', message, err, { selectedDate, selectedTemplateCode })
      appToast.error(message)
    }
  }

  return (
    <div className={classes.appFrame}>
      <TitleBar onBack={onBackHandler}>Добавление вневизитной задачи</TitleBar>
      <PageContent>
        <ValidationFab
          aria-label='add'
          isValid={!!selectedTemplateCode}
          onClick={(isValid) => isValid && createTask()} // TODO:  добавить метод создания после имплементации
        >
          Добавить
        </ValidationFab>
        <Box pt={3} px={3}>
          <VisitCreateDatePicker
            selectedDate={selectedDate}
            onChange={(x) => {
              x && setSelectedDate(x.valueOf())
            }}
          />
        </Box>
        <Box px={3} pb={20}>
          <ItemCard //
            label={'Выбор типа задачи'}
            isError={!selectedTemplateCode}
          >
            <FormControl className={classes.form}>
              <RadioGroup aria-labelledby='demo-radio-buttons-group-label' name='radio-buttons-group'>
                {nonVisitTaskTemplates?.map((template) => {
                  const key = getEntityKey(template)
                  return (
                    <FormControlLabel
                      key={key}
                      className={classes.label}
                      value={key}
                      control={<Radio color='primary' />}
                      label={template.name}
                      color='secondary'
                      labelPlacement='start'
                      onClick={() => {
                        setSelectedTemplateCode(key)
                      }}
                    />
                  )
                })}
              </RadioGroup>
            </FormControl>
          </ItemCard>
        </Box>
      </PageContent>
    </div>
  )
}