import React from 'react'

import { createStyles, IconButton, Menu, MenuItem } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { MoreVert } from '@material-ui/icons'
import { isToday } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { DateTime } from '../../../model/common'
import { VisitTaskStatus } from '../../../model/visit-task'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      zIndex: 9,
      top: 5,
      right: 8,
      [theme.breakpoints.down('xs')]: {
        top: 0,
        right: 0,
      },
    },
    buttonToggle: {
      [theme.breakpoints.down('xs')]: {
        width: 44,
        height: 44,
      },
    },
  }),
)

export interface IVisitTaskMenuProps {
  taskStatus: VisitTaskStatus
  plannedDate: DateTime
  onCancel: () => void
  onRestore?: () => void
}

const TaskCardMenu: React.FC<IVisitTaskMenuProps> = ({ taskStatus, onCancel, onRestore, plannedDate }) => {
  const classes = useStyles()

  const { t } = useTranslation('visits')

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement>()
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(undefined)
  }

  const isDisabledRepairButton = (taskStatus: VisitTaskStatus): boolean =>
    taskStatus !== 'Canceled' || !isToday(plannedDate)

  return (
    <div
      className={classes.root}
      onClick={(event) => {
        event.stopPropagation()
      }}
    >
      {taskStatus !== 'Finished' && (
        <IconButton
          className={classes.buttonToggle}
          id='demo-positioned-button'
          aria-controls={open ? 'demo-positioned-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
      )}
      <Menu
        id='demo-positioned-menu'
        aria-labelledby='demo-positioned-button'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: 'left',
        }}
      >
        {taskStatus === 'Canceled' ? (
          <MenuItem disabled={isDisabledRepairButton(taskStatus)} onClick={onRestore}>
            {t('restoreTaskAction')}
          </MenuItem>
        ) : (
          <MenuItem disabled={!(taskStatus === 'InProgress' || taskStatus === 'Planned')} onClick={onCancel}>
            {t('cancelTaskAction')}
          </MenuItem>
        )}
      </Menu>
    </div>
  )
}

export default TaskCardMenu
