import { InjectionToken } from '../infrastructure/di'
import { EntityKey, Code } from '../model/base'
import { IQuestionnaire } from '../model/questionnaire'

export interface IQuestionnaireService {
  getQuestionnaires: () => Promise<IQuestionnaire[]>
  getQuestionnaire: (code: Code) => Promise<IQuestionnaire>
  saveQuestionnaire: (data: IQuestionnaire) => Promise<void>
  copyQuestionnaire: (key: EntityKey) => Promise<EntityKey>
}

export const QUESTIONNAIRE_API = new InjectionToken<IQuestionnaireService>('questionnaire-api')
