import React from 'react'

import { IBusinessParameters } from '../../model/business-parameters'
import { MenuItemDTO } from '../../model/menu-item'
import { IUserGroup } from '../../model/user-group'
import { IUserProfile } from '../../model/user-profile'

export interface IProfileContext {
  value: IUserProfile
  businessParameters: IBusinessParameters
  menuItems: MenuItemDTO[]
  userGroups: IUserGroup[]
  refetch: () => Promise<IUserProfile | undefined>
  refetchUserGroups: () => Promise<void>
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ProfileContext = React.createContext<IProfileContext>({} as IProfileContext)
