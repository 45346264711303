import React from 'react'

import { Box } from '@material-ui/core'
import MonacoEditor from '@monaco-editor/react'

import { getMonacoEditorOptions } from '../../../../../admin/_common/monaco-options'

interface IUnknownPredicateControl {
  value: unknown
  onChange?: (value: unknown) => void
  readonly?: boolean
}

export const UnknownPredicateControl: React.FC<IUnknownPredicateControl> = ({ value, onChange, readonly = false }) => {
  return (
    <Box height='150px'>
      <MonacoEditor
        language='json'
        value={JSON.stringify(value, undefined, 2)}
        onChange={(value) => {
          const entity = value?.trim() ? JSON.parse(value) : undefined
          onChange?.(entity)
        }}
        options={getMonacoEditorOptions({
          readOnly: readonly,
        })}
        beforeMount={(monaco) => {
          monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
            validate: false,
          })
        }}
      />
    </Box>
  )
}
