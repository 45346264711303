import React from 'react'

import { FormHelperText, Typography } from '@material-ui/core'

import { IQuantityInputScreenItem } from '../../../../../model/screen-item'
import { checkPredicate } from '../../../../../model/script-predicate'
import { getContextProperty } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { IQuantity } from '../../types'
import { getMaxValue, getMinValue } from '../integer-input-item/utils'
import { ItemCard } from '../item-card'
import { checkNumericError, NumberEditor } from '../table-item/number-editor'
import { validateQuantityInputScreenItem } from '../validate'
import { IQuantityRecord } from './utils'

interface Props {
  item: IQuantityInputScreenItem
  onChange: (value: IQuantity | null) => void
}

export const QuantityInputItem: React.FC<Props> = ({ item, onChange }) => {
  const propertiesContext = useScriptTaskContext()
  const entryQuantity = getContextProperty(propertiesContext, item.propertyName, undefined) as unknown as
    | IQuantity
    | null
    | undefined

  const minValue = getMinValue(item, propertiesContext)
  const maxValue = getMaxValue(item, propertiesContext)

  let isRequired = false
  if (item.required) {
    switch (item.required.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.ScriptPredicate':
        isRequired = checkPredicate(item.required, {} as IQuantityRecord, propertiesContext)
        break
      case 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate':
        isRequired = item.required.value
    }
  }

  const isError = validateQuantityInputScreenItem(item, propertiesContext).isError
  const isNumericError = checkNumericError(entryQuantity?.value, isRequired, minValue, maxValue)

  const uom = item.defaultUOM

  return (
    <ItemCard
      isError={isError}
      label={(() => item.displayName && <Typography variant='inherit'>{item.displayName}</Typography>)()}
    >
      <NumberEditor
        value={entryQuantity === null ? '' : entryQuantity?.value}
        onChange={(value) => {
          if (typeof value === 'number') {
            const quantity = Object.assign(
              {
                value: value,
                unitOfMeasure: item.defaultUnitOfMeasure ?? 'шт.',
              },
              uom && { uom },
            )
            onChange(quantity)
          } else {
            onChange(null)
          }
        }}
        showIncreaseButtons={item.showIncreaseButtons ?? false}
        minValue={minValue}
        maxValue={maxValue}
        defaultValue={item.defaultValue}
        incrementValue={item.incrementValue}
        isRequired={isRequired}
      />
      {isNumericError && (
        <FormHelperText error>
          {item.errorHelperText ?? 'Не заполнено обязательное поле или не верный диапазон'}
        </FormHelperText>
      )}
    </ItemCard>
  )
}
