import React, { useContext, useEffect, useState } from 'react'

import { Box, Button, IconButton, Paper, Typography } from '@material-ui/core'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { AddLocationOutlined, BorderColor, PhotoLibraryOutlined } from '@material-ui/icons'
import GridOnIcon from '@material-ui/icons/GridOn'
import InfoIcon from '@material-ui/icons/Info'
import MessageIcon from '@material-ui/icons/Message'
import ScreenRotationIcon from '@material-ui/icons/ScreenRotation'
import ShareIcon from '@material-ui/icons/Share'
import { useNetworkState } from 'react-use'
import { Workbox } from 'workbox-window'

import AppConfig from '../../infrastructure/config-service/app-config'
import ConfigService from '../../infrastructure/config-service/config-service'
import { LogManager } from '../../infrastructure/logger'
import { AuthContext, ConfigContext } from '../../providers'
import { appToast } from '../../utils'
import { CompressImage } from './compress-image'
import { Geolocation } from './geolocation'
import { PosTest } from './pos-test'
import PushDebug from './push'
import { ScreenOrientation } from './screen-orientation'
import { SignatureTestItem } from './signature-test-item/signature-test-item'

type DebugTabValue =
  | 'app-info'
  | 'push'
  | 'geolocation'
  | 'autograph'
  | 'compressImage'
  | 'pos-test'
  | 'screenOrientation'

interface IGitInfo {
  hash: string
  date: string
  branch: string
  author: string
}

const logger = LogManager.getLogger('DebugPage')

const Debug: React.FC = () => {
  const auth = useContext(AuthContext)

  const [swVersion, setSwVersion] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const configContext = useContext(ConfigContext)
  // todo: change config interface
  const authService = auth.authService
  const [isSwBgSyncSupported, setIsSwBgSyncSupported] = useState('')
  const networkState = useNetworkState()
  const [selectedTab, setSelectedTab] = useState<DebugTabValue>('app-info')
  const [appConfig, setAppConfig] = useState<AppConfig | null>(null)
  const [versionConfig, setVersionConfig] = useState<{ version: string }>({ version: '' })
  const [gitInfo, setGitInfo] = useState<IGitInfo>()

  useEffect(() => {
    async function fetchMyAPI(): Promise<void> {
      const wb = new Workbox('/service-worker.js')
      void (await wb.register())
      const swVersion = await wb.messageSW({ type: 'GET_VERSION' })
      const swBgSync = await wb.messageSW({ type: 'GET_BG_SYNC_INFO' })
      setSwVersion(swVersion)
      setIsSwBgSyncSupported(swBgSync)
    }

    void fetchMyAPI()
    void getAppVersionConfig().then((v) => setVersionConfig(v))
    void getGitInfo().then((v) => setGitInfo(v))
    void new ConfigService().getAppConfig().then((x) => setAppConfig(x))
  }, [])

  const doSync = async (): Promise<void> => {
    try {
      const versionConfig = await getAppVersionConfig()
      console.log('Version from version.json file:', versionConfig.version)
    } catch (err) {
      logger.error('doSync', 'some error', err)
    }
  }

  const handleChange = (event: React.ChangeEvent<unknown>, newValue: DebugTabValue): void => {
    setSelectedTab(newValue)
  }

  const sharePicture = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    const picture = await fetch('/assets/Octonyan.jpg')
    const pictureBlob = await picture.blob()
    console.log(pictureBlob)
    const shareFile = new File([pictureBlob], 'myFile.jpg', { type: pictureBlob.type })
    console.log(shareFile)
    const shareData: ShareData & { files?: File[] } = {
      files: [shareFile],
      title: 'my sharePicture',
      // url: 'https://face.yarnord.ru/assets/Octonyan.jpg'
    }
    try {
      await navigator.share(shareData as ShareData)
    } catch (e) {
      logger.error('sharePicture', "Your system doesn't support sharing files.", e)
    }
    // if ('canShare' in navigator && window.navigator.share) {
    //   const shareData: ShareData = {
    //     title: 'my shareData'
    //   }
    //   void navigator.share({
    //
    //   })
    // } else {
    //   console.log("Your system doesn't support sharing files.")
    // }
  }

  const sharePdfFromAssets = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    const pdf = await fetch('/assets/results.pdf')
    const pdfBlob = await pdf.blob()
    console.log(pdfBlob)
    const shareFile = new File([pdfBlob], 'myFile.pdf', { type: pdfBlob.type })
    console.log(shareFile)
    const shareData: ShareData & { files?: File[] } = {
      // files: [shareFile],
      title: 'my shareData',
      url: 'https://face.yarnord.ru/assets/results.pdf',
    }
    try {
      await navigator.share(shareData as ShareData)
    } catch (e) {
      logger.error('sharePdfFromAssets', "Your system doesn't support sharing files.", e)
    }
  }

  const shareHandler = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    try {
      // const blob = new Blob([], { type: 'application/pdf' })
      const binary = atob(''.replace(/\s/g, ''))
      const len = binary.length
      const buffer = new ArrayBuffer(len)
      const view = new Uint8Array(buffer)
      for (let i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i)
      }

      // create the blob object with content-type "application/pdf"
      const blob = new Blob([view], { type: 'application/pdf' })

      const file = new File([blob], 'pmPdf.pdf', { type: 'application/pdf' })
      await navigator.share({
        files: [file],
        url: 'https://face.yarnord.ru',
        title: 'Отчеты в pdf',
        text: 'Отчеты по продажам',
      })
    } catch (e) {
      const message = "Your system doesn't support sharing files."
      appToast.error(message)
      logger.error('shareHandler', message, e)
    }

    // if (navigator.canShare?.({ files: filesArray })) {
    //   navigator.share({
    //     files: filesArray,
    //     title: 'Pictures',
    //     text: 'Our Pictures.',
    //   })
    //     .then(() => console.log('Share was successful.'))
    //     .catch((error) => console.log('Sharing failed', error));
    // } else {
    //   console.log(`Your system doesn't support sharing files.`);
    // }
  }

  const shareHandler2 = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    try {
      await navigator.share({
        files: [],
        url: 'http://localhost:8080/pdf',
        title: 'Отчеты в pdf',
        text: 'Отчеты по продажам',
      })
    } catch (e) {
      const message = "Your system doesn't support sharing files."
      appToast.error(message)
      logger.error('shareHandler2', message, e)
    }

    // if (navigator.canShare?.({ files: filesArray })) {
    //   navigator.share({
    //     files: filesArray,
    //     title: 'Pictures',
    //     text: 'Our Pictures.',
    //   })
    //     .then(() => console.log('Share was successful.'))
    //     .catch((error) => console.log('Sharing failed', error));
    // } else {
    //   console.log(`Your system doesn't support sharing files.`);
    // }
  }

  const removeIndexedDbHandler = (): void => {
    void window.indexedDB
      .databases()
      .then((r) => {
        for (let i = 0; i < r.length; i++) window.indexedDB.deleteDatabase(r[i].name!)
      })
      .then(() => {
        appToast.success('All DB cleared')
        window.localStorage.removeItem('__auth.Default')
      })
  }

  function renderAppInfo(): JSX.Element {
    return (
      <Box display='flex' flexDirection='column' flex={1}>
        <Button
          style={{ marginTop: 8, background: 'red', padding: 16 }}
          color='secondary'
          variant='contained'
          onClick={removeIndexedDbHandler}
        >
          Remove IndexedDb
        </Button>
        <Paper style={{ flex: 1, padding: 24 }}>
          <IconButton color='secondary' onClick={shareHandler}>
            <ShareIcon />
          </IconButton>
          <IconButton color='primary' onClick={shareHandler2}>
            <ShareIcon />
          </IconButton>{' '}
          <IconButton color='primary' onClick={sharePicture}>
            <ShareIcon /> Pic
          </IconButton>
          <IconButton color='primary' onClick={sharePdfFromAssets}>
            <ShareIcon /> pdf from assets
          </IconButton>
          <Paper elevation={5} style={{ padding: 8, marginBottom: 24 }}>
            <Typography variant='h6' color='inherit'>
              git-info.json:
            </Typography>
            <pre>
              <code>{JSON.stringify(gitInfo, null, 2)}</code>
            </pre>
          </Paper>
          <Box display='flex' flexDirection='column'>
            <Paper elevation={5} style={{ padding: 8, marginBottom: 24 }}>
              <Typography variant='h5' color='inherit'>
                App info:
              </Typography>
              <Box display='flex' flexDirection='column' m={3}>
                <Paper elevation={2} style={{ padding: 8 }}>
                  <Typography variant='h6' color='inherit'>
                    version.json:
                  </Typography>
                  <pre>
                    <code>{JSON.stringify(versionConfig, null, 2)}</code>
                  </pre>
                </Paper>

                <Paper elevation={2} style={{ padding: 8, marginTop: 8 }}>
                  <Typography variant='h6' color='inherit'>
                    config.json:
                  </Typography>
                  <pre>
                    <code>{JSON.stringify(appConfig, null, 2)}</code>
                  </pre>
                </Paper>

                <Paper elevation={2} style={{ padding: 8, marginTop: 8 }}>
                  <Typography variant='h6' color='inherit'>
                    Auth-info:
                  </Typography>
                  <pre>
                    <code>{JSON.stringify(authService.currentUserName, null, 2)}</code>
                  </pre>
                  {/*<Button variant='contained' onClick={async () => await authService.refresh()} style={{ margin: 24 }}>*/}
                  {/*  refresh*/}
                  {/*</Button>*/}
                  <Button variant='contained' onClick={async () => await authService.logout()} style={{ margin: 24 }}>
                    logout
                  </Button>
                </Paper>
              </Box>
            </Paper>

            <Paper elevation={5} style={{ padding: 8, marginBottom: 24 }}>
              <Typography variant='h6' color='inherit'>
                Service worker state:
              </Typography>
              <Box display='flex' flexDirection='column'>
                <span>installed SW version: {swVersion}</span>
                <span>background sync is natively supported: {isSwBgSyncSupported}</span>
              </Box>

              <Button variant='contained' onClick={doSync} style={{ margin: 24 }}>
                Синхронизация
              </Button>
            </Paper>

            <Paper elevation={5} style={{ padding: 8, marginBottom: 24 }}>
              <Typography variant='h6' color='inherit'>
                Network state:
              </Typography>
              <pre>
                <code>{JSON.stringify(networkState, null, 2)}</code>
              </pre>
            </Paper>
          </Box>
        </Paper>
      </Box>
    )
  }

  function renderTab(value: DebugTabValue): JSX.Element {
    switch (value) {
      case 'app-info':
        return renderAppInfo()
      case 'push':
        return <PushDebug />
      case 'geolocation':
        return <Geolocation />
      case 'autograph': {
        return <SignatureTestItem />
      }
      case 'compressImage': {
        return <CompressImage />
      }
      case 'pos-test': {
        return <PosTest />
      }
      case 'screenOrientation': {
        return <ScreenOrientation />
      }
    }
  }
  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons='on'
        indicatorColor='primary'
        textColor='primary'
        aria-label='visit-tasks icon tabs'
      >
        <Tab value='app-info' icon={<InfoIcon />} aria-label='favorite' />
        <Tab value='push' icon={<MessageIcon />} aria-label='push' />
        <Tab value='geolocation' icon={<AddLocationOutlined />} aria-label='pdf' />
        <Tab value='pos-test' icon={<GridOnIcon />} aria-label='pos-test' />
        <Tab value='autograph' icon={<BorderColor />} aria-label='autograph' />
        <Tab value='compressImage' icon={<PhotoLibraryOutlined />} aria-label='compressImage' />
        <Tab value='screenOrientation' icon={<ScreenRotationIcon />} aria-label='screenOrientation' />
      </Tabs>
      {renderTab(selectedTab)}
    </>
  )
}

export default Debug

/**
 * Система получает с сервера файл version.json
 */
async function getAppVersionConfig(): Promise<{ version: string }> {
  try {
    const res = await fetch('/assets/version.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    console.log(await res)
    const versionConfig: { version: string } = await res.json()

    return versionConfig
  } catch (err) {
    throw new Error('Error during fetching a new version of application')
  }
}

/**
 * Система получает с сервера файл git-info.json
 */
async function getGitInfo(): Promise<IGitInfo> {
  if (process.env.NODE_ENV === 'development') {
    return { author: '', branch: '', date: '', hash: '' }
  }
  try {
    const response = await fetch('/assets/git-info.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    console.log(await response)
    const res: IGitInfo = await response.json()
    return res
  } catch (err) {
    throw new Error('Error during fetching a new version of application')
  }
}
