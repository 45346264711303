import React from 'react'

import { Box, createStyles, makeStyles, Paper } from '@material-ui/core'

const useStyles = makeStyles((theme) =>
  createStyles({
    sheet: {
      maxWidth: '793px',
      // maxHeight: '1122px',
      width: `calc(100% - ${theme.spacing(10)}px)`,
      height: `calc(100% - ${theme.spacing(12)}px)`,
      margin: 'auto',
      [theme.breakpoints.down('xs')]: {
        width: `calc(100% - ${theme.spacing(2)}px)`,
      },
    },
  }),
)

export const PdfSheet: React.FC = ({ children }) => {
  const classes = useStyles()
  return (
    <Box pt={4} pb={12} height='fit-content'>
      <Paper square className={classes.sheet}>
        {children}
      </Paper>
    </Box>
  )
}
