import React, { useContext, useEffect } from 'react'

import { Box, Typography } from '@material-ui/core'
import { sortBy } from 'lodash'
import { useAsync } from 'react-use'

import { EmptyMessage } from '../../../../../components'
import { IEntityReference } from '../../../../../model/base'
import { ISurveysPropertyScreenItem } from '../../../../../model/screen-item'
import { ApiContext } from '../../../../../providers'
import { useFetchQuestionnaire } from '../../../../_common/hooks/useFetchQuestionnaire'
import { getContextProperty } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { AccordionArrow, AccordionCard } from '../accordion-card'
import { SurveyView } from './survey-view'

interface Props {
  item: ISurveysPropertyScreenItem
}

export const SurveyItemView: React.FC<Props> = ({ item }) => {
  useEffect(() => {
    console.log('SurveyItem', item)
  }, [item])
  const propertiesContext = useScriptTaskContext()
  const api = useContext(ApiContext)
  const questionnaireCode = item.questionnaire.code

  const surveysOps = useAsync(async () => {
    const surveyRefs = getContextProperty(propertiesContext, item.propertyName, [])
    // console.log({ surveyRefs })
    const surveys = await api.survey.getSurveys(surveyRefs.map((surveyRef: IEntityReference) => surveyRef.code))
    const fittingSurveys = surveys?.filter((survey) => survey.questionnaire.code === questionnaireCode) ?? []
    return sortBy(fittingSurveys, ['creationTime', 'code']).reverse()
  })

  const questionnaireOps = useFetchQuestionnaire({ questionnaireCode }, [questionnaireCode])

  useEffect(() => {
    if (surveysOps.error) {
      throw surveysOps.error
    }
  }, [surveysOps.error])
  useEffect(() => {
    if (questionnaireOps.error) {
      throw questionnaireOps.error
    }
  }, [questionnaireOps.error])

  if (surveysOps.loading && !surveysOps.value) {
    return <></>
  }
  if (questionnaireOps.loading && !questionnaireOps.value) {
    return <></>
  }

  const surveys = surveysOps.value!
  const questionnaire = questionnaireOps.value!

  return (
    <AccordionCard
      label={
        <>
          <AccordionArrow />
          <Typography style={{ paddingTop: 12, paddingBottom: 12 }} variant='inherit'>
            {item.displayName}
          </Typography>
        </>
      }
    >
      <Box width='100%'>
        {!surveys.length && <EmptyMessage message='Нет опросов' />}
        {surveys.map((survey) => (
          <SurveyView //
            key={survey.code}
            survey={survey}
            questionnaire={questionnaire}
          />
        ))}
      </Box>
    </AccordionCard>
  )
}
