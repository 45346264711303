import {
  IMediaContentListEditorScreenItem,
  IMediaContentListViewerScreenItem,
  IMimeContentEditorScreenItem,
  IMimeContentViewerScreenItem,
  IPhotosPropertyScreenItem,
  IScreenItem,
  isScreenItemGroup,
  ScreenItems,
} from '../../../../model/screen-item'
import { checkPredicate } from '../../../../model/script-predicate'
import { IScriptTaskContext } from '../../script-tasks/script-task-context'

export function findAllItems(items: ScreenItems): IScreenItem[] {
  return items.flatMap((item) => {
    if (isScreenItemGroup(item)) {
      return item.items
    } else {
      return [item]
    }
  })
}

export function sortScreenItems(items: ScreenItems): ScreenItems {
  return items
    .map((item) => {
      if (isScreenItemGroup(item)) {
        const group = item
        const items = group.items.slice().sort(sortByOrderNumber)
        return { ...group, items }
      } else {
        return item
      }
    })
    .sort(sortByOrderNumber)
}

export function sortByOrderNumber<T extends { orderNumber: number }>(a: T, b: T): number {
  if (a.orderNumber == null || b.orderNumber == null) {
    return -1
  }
  return a.orderNumber - b.orderNumber
}

export type MediaEntity =
  | IPhotosPropertyScreenItem
  | IMediaContentListEditorScreenItem
  | IMediaContentListViewerScreenItem
  | IMimeContentViewerScreenItem
  | IMimeContentEditorScreenItem

export const convertMediaContentList = (item: MediaEntity, context: IScriptTaskContext): IPhotosPropertyScreenItem => {
  switch (item.$type) {
    case 'PMI.FACE.BDDM.Extensions.Classes.PhotosPropertyScreenItem':
      return item
    case 'PMI.FACE.BDDM.Extensions.Classes.MediaContentListEditorScreenItem':
      return {
        ...item,
        $type: 'PMI.FACE.BDDM.Extensions.Classes.PhotosPropertyScreenItem',
        editSettings: {
          minPhotoCountRequired: item.minPhotoCountRequired,
          maxPhotoCountAvailable: item.maxPhotoCountAvailable,
          errorHelperText: item.errorHelperText,
        },
      }

    case 'PMI.FACE.BDDM.Extensions.Classes.MediaContentListViewerScreenItem':
    case 'PMI.FACE.BDDM.Extensions.Classes.MimeContentViewerScreenItem':
      return {
        ...item,
        $type: 'PMI.FACE.BDDM.Extensions.Classes.PhotosPropertyScreenItem',
        viewSettings: {
          nullValueCaption: item.nullValueCaption,
          hideEmpty: item.hideEmpty,
        },
      }
    case 'PMI.FACE.BDDM.Extensions.Classes.MimeContentEditorScreenItem':
      const required = item.required && checkPredicate(item.required, {}, context)
      return {
        ...item,
        $type: 'PMI.FACE.BDDM.Extensions.Classes.PhotosPropertyScreenItem',
        editSettings: {
          minPhotoCountRequired: required ? 1 : 0,
          maxPhotoCountAvailable: 1,
          errorHelperText: item.errorHelperText,
        },
      }
  }
}
