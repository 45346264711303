import { IDisposable } from './disposable'

export type EventHandler<T> = (args: T) => void

export interface IEvent<T> {
  add: (handler: EventHandler<T>) => void
  remove: (handler: EventHandler<T>) => void
  readonly hasHandlers: boolean
}

abstract class EventEmitterBase<T> implements IEvent<T>, IDisposable {
  protected _handlers: Set<EventHandler<T>> | undefined

  public add(handler: EventHandler<T>): void {
    if (!this._handlers) {
      this._handlers = new Set<EventHandler<T>>()
    }
    this._handlers.add(handler)
  }

  public remove(handler: EventHandler<T>): void {
    this._handlers?.delete(handler)
  }

  public get hasHandlers(): boolean {
    return this._handlers != null && this._handlers.size > 0
  }

  public dispose(): void {
    if (this._handlers) {
      this._handlers.clear()
      this._handlers = undefined
    }
  }
}

export class EventEmitter<T> extends EventEmitterBase<T> {
  public emit(args: T): void {
    this._handlers?.forEach((handler) => handler(args))
  }
}

export class CustomEventEmitter<T> extends EventEmitter<T> {
  private _unsubscribe: (() => void) | undefined

  public constructor(
    subscribe: (handler: EventHandler<T>) => void,
    unsubscribe: (handler: EventHandler<T>) => void
  ) {
    super()
    const handler = (args: T): void => this._emit(args)
    this._unsubscribe = () => unsubscribe(handler)
    subscribe(handler)
  }

  private _emit(args: T): void {
    this._handlers?.forEach((handler) => handler(args))
  }

  public dispose(): void {
    if (this._unsubscribe) {
      this._unsubscribe()
      this._unsubscribe = undefined
    }
    super.dispose()
  }
}

