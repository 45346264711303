import React, { useEffect } from 'react'

import { createStyles, IconButton, Menu, MenuItem } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { MoreVert } from '@material-ui/icons'
import { isToday } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { DateTime } from '../../../model/common'
import { VisitStatus } from '../../../model/visit'
import { VisitTaskStatus } from '../../../model/visit-task'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      zIndex: 9,
      top: 5,
      right: 8,
      [theme.breakpoints.down('xs')]: {
        top: 0,
        right: 0,
      },
    },
    buttonToggle: {
      [theme.breakpoints.down('xs')]: {
        width: 44,
        height: 44,
      },
    },
  }),
)

export interface IVisitTaskMenuProps {
  visitStatus: VisitStatus
  taskStatus: VisitTaskStatus
  visitPlannedDate: DateTime
  onCancel: () => void
  onRepair: () => void
  onEditCompletedTask?: () => void
}

const VisitTaskMenu: React.FC<IVisitTaskMenuProps> = (props) => {
  const classes = useStyles()

  const { i18n, t, ready } = useTranslation('visits')
  useEffect(() => {
    !ready && i18n.reloadResources(i18n.language, 'visits')
  }, [ready])

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement>()
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(undefined)
  }

  const isDisabledRepairButton = (taskStatus: VisitTaskStatus): boolean =>
    (props.visitStatus !== 'InProgress' && props.visitStatus !== 'Planned') ||
    taskStatus !== 'Canceled' ||
    !isToday(props.visitPlannedDate)

  const isReadOnly = !(props.visitStatus === 'InProgress' || props.visitStatus === 'Planned')
  if (isReadOnly) return <></>

  return (
    <div
      className={classes.root}
      onClick={(event) => {
        event.stopPropagation()
      }}
    >
      <IconButton
        className={classes.buttonToggle}
        id='demo-positioned-button'
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id='demo-positioned-menu'
        aria-labelledby='demo-positioned-button'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: 'left',
        }}
      >
        {props.taskStatus === 'Canceled' && (
          <MenuItem disabled={isDisabledRepairButton(props.taskStatus)} onClick={props.onRepair}>
            {t('restoreTaskAction')}
          </MenuItem>
        )}
        {(props.taskStatus === 'InProgress' || props.taskStatus === 'Planned') && (
          <MenuItem onClick={props.onCancel}>{t('cancelTaskAction')}</MenuItem>
        )}
        {props.taskStatus === 'Finished' && (
          <MenuItem onClick={props.onEditCompletedTask}>{t('editCompletedTaskAction')}</MenuItem>
        )}
      </Menu>
    </div>
  )
}

export default VisitTaskMenu
