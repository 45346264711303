import React from 'react'

import { Box, Link, Typography } from '@material-ui/core'

import { IHyperlinkScreenItem } from '../../../../../model/screen-item'
import { formatTemplateString } from '../../../../../utils/format-template-string'
import { getContextProperty } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { ItemCard } from '../item-card'

interface Props {
  item: IHyperlinkScreenItem
  onChange?: (value: string) => void
}

export const HyperlinkItem: React.FC<Props> = ({ item, onChange }) => {
  const localContext = useScriptTaskContext()

  const resultLink = formatTemplateString(item.linkFormat, (propertyName) =>
    getContextProperty(localContext, propertyName, ''),
  )

  return (
    <ItemCard label={(() => item.displayName && <Typography variant='inherit'>{item.displayName}</Typography>)()}>
      {item.description && (
        <Box mb={2}>
          <Typography variant='body1'>{item.description}</Typography>
        </Box>
      )}
      <Typography color='primary'>
        <Link
          href={resultLink}
          target='_blank'
          onClick={() => {
            onChange?.(resultLink)
          }}
          underline='none'
          variant='body1'
        >
          {/*eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing*/}
          {item.linkDisplayName || 'Перейти'}
        </Link>
      </Typography>
    </ItemCard>
  )
}
