import React, { useContext, useEffect } from 'react'

import { createStyles, IconButton, LinearProgress, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useError } from 'react-use'

import { LogManager } from '../../infrastructure/logger'
import { LockedPage } from '../../layout/locked-page'
import { ReactComponent as LogoutIcon } from '../../layout/menu/Logout.svg'
import { grays } from '../../layout/theme'
import { SyncErrorCode } from '../../model/base'
import { AuthContext, MaintenanceContext, ProfileContext } from '../../providers'
import { handleProfileError } from '../../providers/profile/profile-provider'
import { useVersion } from '../../providers/service-worker/service-worker-provider'
import { SyncError } from '../../services/sync-service-api'
import { useSync } from '../_common/hooks/useSync'
import { contextQueryClient } from '../process/process-context'
import { getDescription, getTitle } from './sync-error-format'
import { ISyncAutoLocationState, ISyncErrorLocationState, IUnauthorizedLocationState } from './sync-location-types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentWrap: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    title: {
      lineHeight: '36px',
      textAlign: 'center',
      fontSize: 28,
      fontStyle: 'normal',
      fontWeight: 500,
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
        lineHeight: '28px',
      },
    },
    progressMessage: {
      textAlign: 'center',
      fontSize: 14,
      lineHeight: '20px',
      color: grays.gray2,
    },
    progressWrap: {
      marginTop: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiLinearProgress-barColorSecondary': {
        backgroundColor: theme.palette.primary.main,
      },
      '& .MuiLinearProgress-colorSecondary': {
        backgroundColor: '#ACC9E3',
      },
    },
    line: {
      width: 165,
      marginRight: 8,
    },
    logoutButton: {
      position: 'fixed',
      top: 10,
      right: 10,
    },
  }),
)

const SyncPageAuto: React.FC<{ onSyncSuccess?: VoidFunction }> = ({ onSyncSuccess }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation() as { state: ISyncAutoLocationState }
  const auth = useContext(AuthContext)
  const version = useVersion()
  const dispatchError = useError()
  const profileContext = useContext(ProfileContext)
  const { progress, finished, error, retrySync } = useSync({ runManually: true })
  const { setMaintenance } = useContext(MaintenanceContext)

  const { t } = useTranslation('sync')

  function handleSyncError(error: SyncError): void {
    if (error.code === SyncErrorCode.Maintenance) {
      setMaintenance(true)
      if (location.state?.doNotLogoff) return
      auth.authService.logoff()
    }
    if (error.code === SyncErrorCode.Unauthorized) {
      auth.authService.logoff()
    }
    // logger.info('syncFn', 'asd', e)
    LogManager.flush()
  }

  useEffect(() => {
    if (error) {
      if (error instanceof SyncError) {
        handleSyncError(error)
      }
      void (async () => {
        if (error instanceof SyncError && error.code === SyncErrorCode.Unauthorized) {
          const state: IUnauthorizedLocationState = {
            userName: auth.currentUserName,
            version,
          }
          navigate('/401', { state })
        } else {
          const state: ISyncErrorLocationState = {
            title: getTitle(error),
            description: getDescription(error),
            userName: auth.currentUserName,
            version,
          }
          navigate('/sync-error', { state })
        }
      })()
    }
  }, [error])

  useEffect(() => {
    Promise.resolve()
      .then(() => {
        if (location.state?.cleanProfile) {
          const state = { ...location.state, cleanProfile: false }
          navigate('/sync-auto', { replace: true, state })
          return profileContext.refetch().then(async (chosenProfile) => {
            if (!chosenProfile) {
              return new Promise(() => {
                // never resolve, selecting profile
              })
            }
          })
        }
      })
      .then(() => retrySync())
      .catch((error) => {
        const handled = handleProfileError(error, auth.currentUserName, navigate, location.state?.doNotLogoff)
        if (!handled) {
          dispatchError(error)
        }
      })
      .finally(() => {
        void contextQueryClient.invalidateQueries()
      })
  }, [])

  useEffect(() => {
    if (finished) {
      onSyncSuccess?.()
      navigate('/')
    }
  }, [finished, onSyncSuccess])

  //console.debug(`sync-page-auto: ${progress}% - ${progressMessage}`)

  return (
    <LockedPage>
      <IconButton
        onClick={() => {
          void auth.logout()
        }}
        color='primary'
        className={classes.logoutButton}
      >
        <LogoutIcon />
      </IconButton>
      <div>
        <Typography className={classes.title}>{t('waiting')}</Typography>
        <Typography className={classes.title}>{t('syncInProgress')}</Typography>
      </div>
      <div className={classes.progressWrap}>
        <LinearProgress className={classes.line} color='secondary' variant='determinate' value={progress} />
        <Typography className={classes.progressMessage}>{`${Math.round(progress)}%`}</Typography>
      </div>
    </LockedPage>
  )
}

export default SyncPageAuto
