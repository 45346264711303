import React, { useEffect } from 'react'

import { Box, LinearProgress } from '@material-ui/core'
import { cloneDeep } from 'lodash'
import { useAsync } from 'react-use'

import { EmptyMessage } from '../../../components'
import { LogManager } from '../../../infrastructure/logger'
import { IReportProcessor } from '../../../model/report-processor'
import { useLocalContextService } from '../nested/local-context'
import { ScriptTaskContext, useScriptTaskContext } from '../script-tasks/script-task-context'
import { CompositeReportPreview } from './composite-screen/report/html'
import { PdfSheet } from './report-stage-screen/pdf-sheet'
import { PreviewPdfSimple } from './simple-survey/preview-pdf-simple'
import { runResultScreenScript } from './task-result-screen'

interface PreviewControlProps {
  children?: never
  /** probably unused override */
  resultFile?: IReportProcessor
}

const logger = LogManager.getLogger('PreviewControl')

export const PreviewControl: React.FC<PreviewControlProps> = (props) => {
  useEffect(() => {
    console.log('PreviewControl mount', props)
  }, [])

  const rootContext = useScriptTaskContext()
  const contextService = useLocalContextService()

  const ops = useAsync(async () => {
    const { resultFileScript } = rootContext.template
    if (!resultFileScript) {
      return rootContext
    }
    const context = cloneDeep(rootContext)
    try {
      return await runResultScreenScript(resultFileScript, context, contextService)
    } catch (err: unknown) {
      logger.error('runResultScreenScript', 'error running script', err, { resultFileScript })
      throw err
    }
  }, [rootContext.task, rootContext.scope])

  if (ops.loading && !ops.value)
    return (
      <Box padding={3} height='60vh' display='flex' flexDirection='column' justifyContent='center'>
        <LinearProgress />
      </Box>
    )

  if (ops.error)
    return (
      <Box padding={3} height='60vh' display='flex' flexDirection='column' justifyContent='center'>
        <EmptyMessage message='Не удалось выполнить инициализирующий скрипт' stretch={false} />
        <EmptyMessage message={`Ошибка: ${ops.error.message}`} stretch={false} />
      </Box>
    )

  const localContext = ops.value!

  const { template, task, pos } = localContext
  const resultFile = props.resultFile ?? template.resultFile
  if (!resultFile) {
    return <EmptyMessage message={'Result file not found in template'} />
  }

  const renderPreview = (): JSX.Element => {
    switch (resultFile.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.CustomReportProcessor':
        if (!pos) {
          return <EmptyMessage message={`Point of sale for ${resultFile.$type} not found`} />
        }
        return (
          <PdfSheet>
            <PreviewPdfSimple task={task} pos={pos} />
          </PdfSheet>
        )
      case 'PMI.FACE.BDDM.Extensions.Classes.CompositeReportProcessor':
        return (
          <PdfSheet>
            <CompositeReportPreview resultFile={resultFile} />
          </PdfSheet>
        )
      default:
        const resultFileType = (resultFile as IReportProcessor).$type
        return <EmptyMessage message={`Result file ${resultFileType} not implemented`} />
    }
  }
  return <ScriptTaskContext.Provider value={localContext}>{renderPreview()}</ScriptTaskContext.Provider>
}
