import React, { useCallback, useEffect, useState } from 'react'

import { Box, createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { endOfDay, isSameMonth, startOfDay, startOfToday, subDays, addDays } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { EmptyMessage } from '../../../components'
import { appToast, capitalizeFirstLetter, dateFormat } from '../../../utils'
import { useSearchVisits } from '../../_common/hooks/useSearchVisits'
import { filterTaskByQuery, sortByTaskStatus } from '../../_common/tasks'
import { filterStoreByQuery, sortByStatus } from '../../_common/visits'
import { ErrorPage } from '../../error-page'
import { Calendar } from '../components'
import CalendarDayListActivities from '../components/calendar-day-list-activities'
import { useSearchNonVisitTasks } from '../task-list/useSearchNonVisitTasks'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      zIndex: 1,
      display: 'flex',
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
    },
    calendar: {
      flex: '0 0 100%',
      maxWidth: '100%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    calendarWithList: {
      flex: 1,
    },
    calendarList: {
      position: 'relative',
      flex: '0 0 40.5%',
      maxWidth: '340px',
      marginLeft: 24,
      maxHeight: '100%',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        flex: '0 0 100%',
        maxWidth: '100%',
      },
    },
    calendarListWrap: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: '100%',
      height: '100%',
      paddingBottom: 160,
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '0',
      },
      [theme.breakpoints.down('sm')]: {
        position: 'static',
        height: 'auto',
      },
    },
    calendarListTitle: {
      marginBottom: 10,
      fontSize: 16,
      lineHeight: '18px',
      color: '#49454F',
      letterSpacing: '0.1px',
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        marginTop: 22,
        marginBottom: 8,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
        lineHeight: '20px',
      },
    },
  }),
)

interface IVisitListProps {
  periodStartDate: number
  periodEndDate: number
  query: string
}

const ActivitiesListByMonth: React.FC<IVisitListProps> = ({ periodStartDate, periodEndDate, query }) => {
  const classes = useStyles()
  // const { uiStateMethods } = useContext(VisitPageUiContext)
  // const api = useContext(ApiContext)
  const { t, i18n } = useTranslation('visits')

  const getSelectDate = (): number => {
    const today = +startOfToday()
    if (!isSameMonth(today, periodStartDate) && today > periodEndDate) {
      return periodEndDate
    }
    if (!isSameMonth(today, periodStartDate) && today < periodStartDate) {
      return periodStartDate
    }

    return today
  }

  const [selectDate, setSelectDate] = useState<number>(getSelectDate())

  // const visitTaskOps = useAsync(
  //   async () =>
  //     await api.visits.countDailyVisitTasks({
  //       fromDate: periodStartDate,
  //       toDate: periodEndDate,
  //       visitStatus: ['Planned', 'Finished', 'InProgress'],
  //       visitTaskStatus: ['Planned', 'Finished', 'InProgress'],
  //       showZero: true
  //     }),
  //   [periodStartDate, periodEndDate]
  // )

  const visitListData = useSearchVisits(
    { periodStartDate: +subDays(periodStartDate, 7), periodEndDate: +addDays(periodEndDate, 7) },
    { withPos: true },
  )

  const taskListData = useSearchNonVisitTasks({
    periodStartDate: +subDays(periodStartDate, 7),
    periodEndDate: +addDays(periodEndDate, 7),
  })

  const onCalendarCellClickHandler = useCallback((date: number) => {
    // uiStateMethods.setViewMode('day')
    // uiStateMethods.setStartPeriodDate(date)
    // uiStateMethods.setEndPeriodDate(date)
    setSelectDate(date)
  }, [])

  useEffect(() => {
    setSelectDate(getSelectDate())
  }, [periodStartDate, periodEndDate])

  if (visitListData.loading || taskListData.loading) {
    return <></>
  }

  if (visitListData.error ?? taskListData.error) {
    const msg = t('errorFetchingCount')
    appToast.error(msg)
    return <ErrorPage errorMessage={msg} />
  }

  if (!visitListData.value || !taskListData.value) {
    if (!visitListData.loading || !taskListData.loading) {
      return <EmptyMessage message={t('errorFetchingCount')} />
    }
  }

  const calendarVisits = visitListData.value ?? []
  const filteredCalendarVisits = calendarVisits.filter((visit) => filterStoreByQuery(visit.pointOfSale, query)).sort(sortByStatus)

  const calendarTasks = taskListData.value ?? []
  const filteredCalendarTasks = calendarTasks.filter((task) => filterTaskByQuery(task, query)).sort(sortByTaskStatus)

  return (
    <Box mt={3} className={classes.root}>
      <div className={classnames(classes.calendar, { [classes.calendarWithList]: selectDate })}>
        <Calendar
          startOfMonth={periodStartDate}
          visits={filteredCalendarVisits}
          tasks={filteredCalendarTasks}
          onCellClick={onCalendarCellClickHandler}
          selectDate={selectDate}
        />
      </div>
      {selectDate ? (
        <div className={classes.calendarList}>
          <div className={classes.calendarListWrap}>
            <div className={classes.calendarListTitle}>
              {capitalizeFirstLetter(dateFormat(selectDate, 'EEEE, dd.MM', i18n.language))}
            </div>
            <CalendarDayListActivities
              periodStartDate={startOfDay(selectDate).valueOf()}
              periodEndDate={endOfDay(selectDate).valueOf()}
              filterQuery={query}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </Box>
  )
}

export default React.memo(ActivitiesListByMonth)
