import { intersectionBy } from 'lodash'

import { IEntityReference } from './base'

export interface IConditionLists<T = IEntityReference> {
  $type?: string
  whiteList?: T[]
  blackList?: T[]
}

export function checkConditionLists(conditionLists: IConditionLists | undefined, target: IEntityReference[]): boolean {
  if (!conditionLists) return true
  const { blackList, whiteList } = conditionLists
  if (!blackList?.length && !whiteList?.length) {
    console.log('hitting empty lists', conditionLists, target)
    return true
  }

  if (intersectionBy(blackList, target, 'code').length) {
    console.log('hitting blacklist', blackList, target)
    return false
  }

  if (whiteList?.length && !intersectionBy(whiteList, target, 'code').length) {
    console.log('missing whiteList', whiteList, target)
    return false
  }

  return true
}
