import React, { useCallback } from 'react'

import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import addMonths from 'date-fns/addMonths'
import ru from 'date-fns/locale/ru'
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'

import { getAppFrameStyle } from '../../../assets/pm-shared-styles'
import { dateFormat } from '../../../utils'
import { ItemCard } from '../../tasks/template-tasks/composite-screen/item-card'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appFrame: { ...getAppFrameStyle(theme) },
    text: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      fontSize: 14,
      fontWeight: 500,
      minWidth: 129,
      height: 40,
      borderRadius: 8,
      color: theme.palette.common.black,
    },
    calendarIcon: {
      width: 16,
      marginRight: 7,
      color: theme.palette.common.black,
    },
    dataPicker: {
      position: 'relative',
      // zIndex: 9,
      color: theme.palette.common.black,
      '& .react-datepicker__tab-loop': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 340,
      },
      '& .react-datepicker-popper': {
        padding: '0 !important',
        width: 'auto',
        inset: '56px 0% auto auto !important',
        transform: 'translateX(0) !important',
      },
      '& .react-datepicker__triangle': {
        display: 'none',
      },
      '& .react-datepicker__current-month': {
        fontSize: 18,
        lineHeight: '21px',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        paddingBottom: 16,
      },
      '& .react-datepicker__header': {
        padding: '22px 0 8px',
        backgroundColor: 'transparent',
        border: 'none',
      },
      '& .react-datepicker__today-button': {
        padding: '22px 0 31px',
        backgroundColor: 'transparent',
        border: 'none',
        color: theme.palette.primary.main,
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: '0.1px',
      },
      '& .react-datepicker': {
        fontFamily: 'Lato',
        border: 'none',
        borderRadius: 12,
        boxShadow: '0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
      },
      '& .react-datepicker__day-name': {
        width: 41,
        padding: '13px 0',
        margin: '-1px 0 0 -1px',
        fontSize: 12,
        fontWeight: 600,
        lineHeight: '14px',
        textTransform: 'capitalize',
      },
      '& .react-datepicker__day': {
        verticalAlign: 'middle',
        margin: '-1px 0 0 -1px',
        padding: '13px 0 12px',
        width: 41,
        fontSize: 12,
        lineHeight: '14px',
        border: '1px solid #C7CDD7',
        '&:hover': {
          borderRadius: 0,
        },
      },
      '& .react-datepicker__day--selected': {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        fontWeight: '600',
      },
      '& .react-datepicker__day--keyboard-selected': {
        borderRadius: 'inherit',
        backgroundColor: 'inherit',
        color: 'inherit',
        fontWeight: 'inherit',
      },
      '& .react-datepicker__month': {
        margin: '0 30px',
      },
      '& .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before':
        {
          borderWidth: '2px 2px 0 0',
          borderColor: '#546066',
        },
      '& .react-datepicker__navigation': {
        top: 18,
        '&--next': {
          right: 24,
        },
        '&--previous': {
          left: 24,
        },
      },
      '& .react-datepicker__day--outside-month': {
        backgroundColor: 'inherit',
        fontWeight: 'inherit',
        color: '#858F97',
      },
      '& .react-datepicker__day--disabled': {
        background: '#FCFCFC',
        color: '#858F97',
      },
      '& .react-datepicker__portal': {
        background: 'rgba(4, 4, 4, 0.15)',
      },
    },
  }),
)

interface IVisitCreateDatePicker {
  onChange: (date: Date) => void
  selectedDate?: number
  title?: string
  isError?: boolean
  minDate?: Date
  maxDate?: Date
}

const VisitCreateDatePicker: React.FC<IVisitCreateDatePicker> = ({
  selectedDate,
  onChange,
  title,
  isError,
  minDate,
  maxDate,
}) => {
  const classes = useStyles()
  const { t } = useTranslation('visits')

  const renderCustomInput = useCallback(
    (): JSX.Element => (
      <div className={classes.text}>
        <svg
          className={classes.calendarIcon}
          width='16'
          height='16'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M20 3H19V2C19 1.45 18.55 1 18 1C17.45 1 17 1.45 17 2V3H7V2C7 1.45 6.55 1 6 1C5.45 1 5 1.45 5 2V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM19 21H5C4.45 21 4 20.55 4 20V8H20V20C20 20.55 19.55 21 19 21Z'
            fill='#333333'
          />
        </svg>
        {selectedDate ? dateFormat(new Date(selectedDate), 'dd.MM.yyyy') : 'Укажите дату'}
      </div>
    ),
    [selectedDate],
  )

  return (
    <ItemCard label={title ?? t('selectDate')} isError={isError}>
      <div className={classes.dataPicker}>
        <DatePicker
          dateFormat='dd.MM.yyyy'
          locale={ru}
          todayButton='Сегодня'
          // customInput={<OutlinedInput  />}
          customInput={renderCustomInput()}
          selected={selectedDate ? new Date(selectedDate) : null}
          minDate={minDate ?? new Date()}
          maxDate={maxDate ?? addMonths(new Date(), 2)}
          onChange={onChange}
        />
      </div>
    </ItemCard>
  )
}

export default VisitCreateDatePicker
