import { ReactComponent as EquipmentIcon } from '../../../assets/icons/equipment.svg'
import { ReactComponent as ExclamationMark } from '../../../assets/icons/exclamation-mark.svg'
import { ReactComponent as ExclamationWarn } from '../../../assets/icons/exclamation-warn.svg'
import { ReactComponent as Link } from '../../../assets/icons/link-icon.svg'
import { ReactComponent as Person } from '../../../assets/icons/person.svg'
import { ReactComponent as Schedule } from '../../../assets/icons/schedule.svg'
import { Hyperlinks } from './hyperlinks/hyperlinks'
import { Information } from './information/information'
import { ParticipantDte } from './participant-dte/participant-dte'
import { Pposm } from './pposm/pposm'
import { ProblemsListPage } from './problems/problems-pos-list'
import { ProgramsDte } from './programs-dte/programs-dte'

export type POSMenuRouteName = 'info' | 'problems' | 'hyperlinks' | 'participantDte' | 'programsDte' | 'pposm'

export interface IPOSMenuRoute {
  path: string
  displayName: string
  icon: React.FC<React.SVGProps<SVGSVGElement>>
  component: React.FC
}

export const PosMenuItems: { [key in POSMenuRouteName]: IPOSMenuRoute } = {
  info: {
    path: 'info',
    displayName: 'info',
    icon: ExclamationMark,
    component: Information,
  },
  problems: {
    path: 'problems',
    displayName: 'problems',
    icon: ExclamationWarn,
    component: ProblemsListPage,
  },
  hyperlinks: {
    path: 'hyperlinks',
    displayName: 'hyperlinks',
    icon: Link,
    component: Hyperlinks,
  },
  participantDte: {
    path: 'participant-dte',
    displayName: 'participant-dte',
    icon: Person,
    component: ParticipantDte,
  },
  programsDte: {
    path: 'programs-dte',
    displayName: 'programs-dte',
    icon: Schedule,
    component: ProgramsDte,
  },
  pposm: {
    path: 'pposm',
    displayName: 'pposm',
    icon: EquipmentIcon,
    component: Pposm,
  },
}
