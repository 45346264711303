import { IEntity } from './base'
import { IMimeContentRef } from './content'
import { IFaceScriptReference } from './face-script'
import { ITaskStageValidator } from './task-stage'
import { IUserStepScreenReference } from './user-step-screen'

export interface ITaskExecutionProcess extends IEntity {
  name?: string
  steps?: IProcessStep[]
  stepperType?: 'Horizontal' | 'Vertical' | 'Unrecognized'
}

export type IProcessStep = IScriptStep | IUserStep | ISwitchStep

export interface IProcessStepBase {
  code: string
  displayName: string
  description?: string
  orderNumber?: number
}

export interface IScriptStep extends IProcessStepBase {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.ScriptStep'
  script: IFaceScriptReference
}

export interface IUserStep extends IProcessStepBase {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.UserStep'
  availableBeforeTaskStart: boolean
  screen: IUserStepScreenReference
  icon?: IMimeContentRef
  nextStepButtonTitle?: string
  validator?: ITaskStageValidator
}

export interface ISwitchStep extends IProcessStepBase {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.SwitchStep'
  cases?: ISwitchStepCase[]
}

export interface ISwitchStepCase {
  userStep: IUserStep
  condition: IStepActivator
  beforeScriptSteps?: IScriptStep[]
  afterScriptSteps?: IScriptStep[]
}

export type IStepActivator = IStepActivatorScript

export interface IStepActivatorScript {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.StepActivatorScript'
  script: IFaceScriptReference
}

export function isScriptStep(step: IProcessStep): step is IScriptStep {
  if (step.$type === 'PMI.FACE.BDDM.Extensions.Classes.ScriptStep') {
    return true
  }
  return false
}

export function isUserStep(step: IProcessStep): step is IUserStep {
  if (step.$type === 'PMI.FACE.BDDM.Extensions.Classes.UserStep') {
    return true
  }
  return false
}

export function isTabStep(step: IProcessStep): step is IUserStep | ISwitchStep {
  return !isScriptStep(step)
}
