import React, { useCallback, useContext } from 'react'

import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core'
import { isToday } from 'date-fns'
import { generatePath, useNavigate } from 'react-router-dom'

import { Code } from '../../../model/base'
import { ApiContext } from '../../../providers'
import { ModalContext } from '../../../providers/modal'
import { appToast, dateFormat } from '../../../utils'
import { useFeatureRoute } from '../../custom-app-feature/app-feature-context'
import { useTaskList } from '../../visits/visit-view-page/useTaskList'
import { visitsRoutes } from '../../visits/visits-app-feature/visits-app-feature'
import { useBusinessSettings } from './useBusinessSettings'

export function useStartVisit(): {
  startVisit: (visitCode: Code) => Promise<boolean>
} {
  const api = useContext(ApiContext)
  const modalContext = useContext(ModalContext)
  const settings = useBusinessSettings()
  const navigate = useNavigate()
  const featureRoute = useFeatureRoute()
  const { getTaskList } = useTaskList()

  const prepareStartVisit = useCallback(async (visitCode: Code) => {
    if (settings?.allowParallelVisitsExecution === 'true') {
      await startVisit(visitCode)
      return true
    }
    const profile = await api.userProfile.getCurrentProfile()
    const visits = await api.visits.searchVisits({
      // executedByUserCode: auth.currentUser.employee.userCode, // FACE-2085 Change visits load logic - UI
      executivePositionCode: profile?.fieldPositionRole?.code,
    })
    const visitInProgress = visits.find((v) => v.status === 'InProgress')
    // console.log('visitInProgress', visitInProgress, visits)
    if (visitInProgress) {
      const date = isToday(visitInProgress.plannedStartDate)
        ? ''
        : dateFormat(visitInProgress.plannedStartDate, ' за dd MMMM')
      modalContext.open(
        <Dialog open={true} onClose={modalContext.close}>
          <DialogContent>
            <DialogContentText>
              Перед началом нового визита необходимо завершить предыдущий визит
              {date} в {visitInProgress.pointOfSaleCode}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color='primary' onClick={modalContext.close}>
              Закрыть
            </Button>
            <Button
              color='primary'
              onClick={() => {
                navigate(generatePath(visitsRoutes.visitView, { featureRoute, id: visitInProgress.code }))
                modalContext.close()
              }}
            >
              К визиту{' '}
            </Button>
            {/* <Button
              color='primary'
              onClick={async () => {
                // TODO: тут не старт
                await startVisit(visitInProgress.code)
                modalContext.close()
              }}
            >
              Завершить
            </Button> */}
          </DialogActions>
        </Dialog>,
      )
      return false
    } else {
      await startVisit(visitCode)
      return true
    }
  }, [])

  const startVisit = async (visitCode: Code): Promise<void> => {
    console.log('finishing unfinished visit', visitCode)

    const { registers } = await getTaskList(visitCode)
    const tasksToIncludeCodes = registers.map((register) => register.code)

    try {
      await api.visits.setVisitStatus({
        visitCode,
        visitStatus: 'InProgress',
        tasksToInclude: tasksToIncludeCodes,
      })
    } catch (e) {
      const msg = 'Ошибка при смене статуса визита'
      appToast.error(msg)
    }
  }

  return {
    startVisit: prepareStartVisit,
  }
}
