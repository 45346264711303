import React, { useState } from 'react'

import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { difference } from 'lodash'

import { YandexMapProvider } from '../../../components/yandex-map-custom/yandex-map-provider'
import { Code } from '../../../model/base'
import { IPointOfSale } from '../../../model/pos'
import { appToast } from '../../../utils'
import { filterStoreByQuery } from '../../_common/visits'
import UseGetPos from '../stores-list/use-get-pos'
import { StoresYandexMap } from './stores-yandex-map'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mapWrapper: {
      position: 'relative',
      height: '100%',
      margin: '20px -24px',
      '& .ymaps-2-1-79-balloon': { display: 'none', boxShadow: 'none !important' },
      '& .ymaps-2-1-79-balloon__content': {
        padding: '0 !important',
        background: 'transparent !important',
        margin: '0 !important',
      },
      '& .ymaps-2-1-79-balloon__layout': {
        background: 'transparent !important',
        border: 'none !important',
      },
      [theme.breakpoints.down('xs')]: {
        margin: '20px -8px',
      },
    },
  }),
)

interface IStoreMapWrapper {
  searchQuery?: string
}

export const StoreMapWrapper: React.FC<IStoreMapWrapper> = ({ searchQuery }) => {
  const classes = useStyles()
  const posListData = UseGetPos()

  const [selectStores, setSelectStores] = useState<Code[]>([])
  const [mouseDownCoordinates, setMouseDownCoordinates] = useState({ clientX: null, clientY: null } as {
    clientX: number | null
    clientY: number | null
  })
  const selectHandler = (codes: string[]): void => {
    setSelectStores((oldCode) => (difference(codes, oldCode).length ? codes : []))
  }

  const handleMouseUp = (clientX: number, clientY: number): void => {
    if (clientX === mouseDownCoordinates.clientX && clientY === mouseDownCoordinates.clientY) {
      setSelectStores([])
    }
  }
  if (posListData.loading) {
    return <></>
  }

  const posList = posListData.value
  const filteredPosList = posList?.filter((store) => filterStoreByQuery(store, searchQuery ?? '')) ?? []

  if (!filteredPosList.length) {
    appToast.info('Ничего не найдено по заданным параметрам')
  }
  if (!filteredPosList.find((store) => store.coordinates)) {
    appToast.info('У торговых точек отсутствуют координаты')
  }

  const getStoreListItems = (): IPointOfSale[] => {
    const isDifferent = posList?.length !== filteredPosList.length
    if (isDifferent || searchQuery) {
      return filteredPosList
    }

    if (selectStores.length) {
      return posList?.filter((store) => selectStores.includes(store.code))
    }

    return []
  }

  return (
    <div
      onMouseDown={(e) => setMouseDownCoordinates({ clientX: e.clientX, clientY: e.clientY })}
      onMouseUp={(e) => {
        const target = e.target as HTMLDivElement
        if (target.nodeName === 'YMAPS') {
          handleMouseUp(e.clientX, e.clientY)
        }
      }}
      className={classes.mapWrapper}
    >
      <YandexMapProvider>
        <StoresYandexMap
          storesAll={filteredPosList}
          storesView={getStoreListItems()}
          selectStoreCodes={selectStores}
          onSelect={selectHandler}
        />
      </YandexMapProvider>
    </div>
  )
}
