import React from 'react'

import { ISelectEmployeeForAuditUserStepScreen } from '../../../../model/user-step-screen'
import { SelectEmployeeForAuditUserStepScreen } from '../../audit/select-employee-for-audit-user-step-screen'

interface Props {
  onReadyChange: (isReady: boolean) => void
}

const screen: ISelectEmployeeForAuditUserStepScreen = {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.SelectEmployeeForAuditUserStepScreen',
  code: '',
  employeeDisplayName: 'Name',
}
export const MerchendizerScreenCustom: React.FC<Props> = ({ onReadyChange }) => {
  return <SelectEmployeeForAuditUserStepScreen onReadyChange={onReadyChange} screen={screen} />
}
