import React, { useMemo, useState } from 'react'

import {
  Button,
  CircularProgress,
  createStyles,
  Fade,
  IconButton,
  makeStyles,
  SvgIconTypeMap,
  Theme,
  Typography,
} from '@material-ui/core'
import { Add, Cancel, Create, Delete, InsertDriveFile, Wallpaper } from '@material-ui/icons'

import { getEntityKey } from '../../model/base'
import { isFaceMimeContentRef, isMimeContentRef } from '../../model/content'
import type { IFaceContentDocument } from '../../model/face-content-document'
import { useBlobToString, useCollectionBlob } from './api'
import { ContentDocumentViewer } from './content-document-viewer'

export function DocumentThumbnail(props: {
  document: IFaceContentDocument | undefined
  loading?: boolean
  showEditButton?: boolean
  showDeleteButton?: boolean
  showAddButton?: boolean
  onClick?: 'openDocumentViewer' | ((key: string | undefined) => void)
  onDelete?: () => void
  showLabel?: boolean
}): JSX.Element {
  const { document, showEditButton, showDeleteButton, showAddButton, onClick, onDelete, showLabel = true } = props
  const styles = useStyles({})
  const [showDocumentViewer, setShowDocumentViewer] = useState(false)
  const documentKey = document ? getEntityKey(document) : undefined
  const documentCode = document?.code
  const documentTitle = document?.name ?? documentCode ?? undefined
  const isLoadable = useMemo((): boolean => {
    const documentContent = document?.content
    return (
      !!documentContent &&
      (isMimeContentRef(documentContent) || isFaceMimeContentRef(documentContent)) &&
      documentContent.type.toUpperCase() !== 'PDF'
    )
  }, [document])
  const iconFontSize: SvgIconTypeMap['props']['fontSize'] = 'default'

  const {
    data: previewImageBlob,
    isLoading: isPreviewImageLoading,
    isError,
  } = useCollectionBlob(document && isLoadable ? documentCode : undefined)
  const previewImageData = useBlobToString(previewImageBlob ?? null)

  const isLoading = !!props.loading || isPreviewImageLoading

  const buttonContent = useMemo(() => {
    function IconWithText({ text, children }: { children: JSX.Element; text?: string }): JSX.Element {
      return (
        <div className={styles.textWithIconWrapper}>
          {children}
          <div className={styles.textUnderIcon}>
            <Typography variant='body1' className={styles.documentTypography}>
              {text}
            </Typography>
          </div>
        </div>
      )
    }

    if (isLoading) {
      return <CircularProgress />
    }

    if (!documentKey) {
      if (showAddButton) {
        return (
          <IconWithText text='Выбрать документ'>
            <div className={styles.circleButton}>
              <Add fontSize={iconFontSize} />
            </div>
          </IconWithText>
        )
      }
      return (
        <IconWithText text='Документ не выбран'>
          <Wallpaper fontSize='large' />
        </IconWithText>
      )
    }

    if (isLoadable) {
      if (isError) {
        return (
          <IconWithText text={documentTitle}>
            <Cancel fontSize='large' />
          </IconWithText>
        )
      }
      return (
        <Fade in={!!previewImageData}>
          <div>
            <img className={styles.thumbnailImage} src={previewImageData} alt={documentTitle} loading='lazy' />
            {showLabel && <div className={styles.thumbnailImageLabel}>{documentTitle}</div>}
          </div>
        </Fade>
      )
    }

    return (
      <IconWithText text={documentTitle}>
        <InsertDriveFile fontSize='large' />
      </IconWithText>
    )
  }, [isLoading, documentKey, showAddButton, isLoadable, isError, previewImageData, documentTitle, showLabel])

  const editButton = useMemo(() => {
    if (showEditButton && documentKey) {
      return (
        <div className={styles.circleButton + ' corner'}>
          <Create fontSize={iconFontSize} />
        </div>
      )
    }
  }, [showEditButton, documentKey])

  const deleteButton = useMemo(() => {
    if (showDeleteButton && documentKey) {
      return (
        <IconButton className={styles.circleButton + ' corner delete'} onClick={() => onDelete?.()}>
          <Delete fontSize={iconFontSize} />
        </IconButton>
      )
    }
  }, [showDeleteButton, documentKey])

  const handleClick = (): void => {
    if (onClick === 'openDocumentViewer') {
      setShowDocumentViewer(true)
      return
    }
    onClick?.(documentKey)
  }

  return (
    <>
      <div className={styles.thumbnailContainer}>
        <Button className={styles.thumbnailButton} disabled={!onClick} onClick={handleClick}>
          {buttonContent}
          {editButton}
        </Button>
        {deleteButton}
      </div>
      {onClick === 'openDocumentViewer' && (
        <ContentDocumentViewer
          open={showDocumentViewer}
          code={documentCode}
          blob={previewImageBlob ?? null}
          documentTitle={documentTitle}
          onClose={() => setShowDocumentViewer(false)}
          onDelete={onDelete && showDeleteButton ? () => onDelete() : undefined}
        />
      )}
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      thumbnailContainer: {
        height: ({ size = 32 }: { size?: number }) => {
          return theme.spacing((size * 3) / 4)
        },
        width: ({ size = 32 }: { size?: number }) => {
          return theme.spacing(size)
        },
        position: 'relative',
        borderRadius: theme.spacing(1),
        // border: `1px ${theme.palette.grey.A200} solid`,
        overflow: 'hidden',
        // '&:hover': {
        //   borderColor: theme.palette.grey.A700,
        // },
      },
      thumbnailButton: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      thumbnailImage: {
        objectFit: 'scale-down',
        height: ({ size = 32 }: { size?: number }) => {
          return theme.spacing((size * 3) / 4 - 2)
        },
        width: ({ size = 32 }: { size?: number }) => {
          return theme.spacing(size - 2)
        },
      },
      thumbnailImageLabel: {
        width: ({ size = 32 }: { size?: number }) => {
          return theme.spacing(size - 2)
        },
        position: 'absolute',
        bottom: '10px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        backgroundColor: 'rgba(213, 213, 213, 0.3)',
        borderRadius: '4px',
      },
      circleButton: {
        width: ({ size = 32 }: { size?: number }) => {
          return theme.spacing((size * 4) / 16)
        },
        height: ({ size = 32 }: { size?: number }) => {
          return theme.spacing((size * 4) / 16)
        },
        borderRadius: ({ size = 32 }: { size?: number }) => {
          return theme.spacing((size * 4) / 2 / 16)
        },
        overflow: 'hidden',
        // border: `1px ${theme.palette.grey.A200} solid`,
        background: theme.palette.grey.A100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.grey.A700,
        opacity: 0.7,
        transition: 'opacity 250ms, background 250ms',
        '.MuiButton-root:hover &, .MuiButton-root:focus &': {
          opacity: 1,
        },
        '&.corner': {
          position: 'absolute',
          top: theme.spacing(1),
          right: theme.spacing(1),
        },
        '&.delete:hover': {
          opacity: 1,
          background: `${theme.palette.grey.A200} !important`,
        },
      },
      textWithIconWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '100%',
      },
      textUnderIcon: {
        marginTop: theme.spacing(2),
        minWidth: 0,
        maxWidth: '100%',
      },
      documentBoxWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
      },
      documentTypography: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '100%',
      },
    }),
  { name: 'DocumentThumbnail' },
)
