import React from 'react'

import { Avatar, Box, createStyles, Grid, Paper, Theme, Typography, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import { dateFormat } from '../../../../../utils'

interface SmListItemProps {
  pointOfSaleName: string
  ownerName: string
  date: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
      display: 'flex',
      minWidth: 320,
      alignItems: 'center',
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1),
      minHeight: theme.spacing(12),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1)
      }
    },
    selectIcon: {
      color: theme.palette.primary.main,
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    ownerName: {
      color: theme.palette.text.secondary
    }
  })
)

const SmListItem: React.FC<SmListItemProps> = (props) => {
  const classes = useStyles()
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'))
  const { pointOfSaleName, date, ownerName } = props

  return (
    <Paper className={classes.root} square elevation={3}>
      <Grid style={{ height: '100%' }} container alignItems='center'>
        <Grid item xs={12} sm={2} md={2}>
          <Typography variant={isSmall ? 'h5' : 'h4'}>{dateFormat(date, 'dd.MM')}</Typography>
        </Grid>
        <Grid item xs={12} sm={5} md={6}>
          <Box display='flex' alignItems='center'>
            <Avatar style={{ marginRight: 16 }} alt={pointOfSaleName} src='/static/images/avatar/1.jpg' />
            <Typography variant={isSmall ? 'subtitle2' : 'h6'}>{pointOfSaleName}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Typography className={classes.ownerName}>{ownerName}</Typography>
        </Grid>
      </Grid>
      <ArrowForwardIosIcon className={classes.selectIcon} />
    </Paper>
  )
}

export default SmListItem
