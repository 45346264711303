import { cloneDeep, last, noop, uniqBy } from 'lodash'

import { generateEntityCode } from '../../../model/base'
import { IFaceScript } from '../../../model/face-script'
import { delay } from '../../../utils/delay'
import { ILocalContextService, makeStateKey } from '../nested/local-context'
import { getCurrentRecord } from '../nested/nested-context'
import { resolvePropertyName } from '../nested/useUpdateProperty'
import { handlePropertyName } from './propertyName'
import { IScriptTaskContext } from './script-task-context'
import { showMessage } from './script-toast'

/** async function is not an async function when compiled  */
// eslint-disable-next-line no-eval
const AsyncFunction: FunctionConstructor = eval('Object.getPrototypeOf(async function () {}).constructor')

export const runScript = async (
  script: IFaceScript,
  localContext: IScriptTaskContext,
  contextService: ILocalContextService,
): Promise<unknown> => {
  const service = {
    delay,
    clone: cloneDeep,
    uniqBy,
  }

  const params = {
    ...localContext,
    task: localContext.task,
    pos: localContext.pos,
    visit: localContext.visit,
    scope: localContext.scope,
    record: localContext.record,
    recordScope: localContext.recordScope,
    saveTask: noop,
    saveScope: noop,
    getProductMatrix: contextService.getProductMatrix,
    getFprUserProfiles: contextService.getFprUserProfiles,
    getSupervisedRoleByCode: contextService.getSupervisedRoleByCode,
    getContractTerms: contextService.getContractTerms,
    createVisitProblem: contextService.createVisitProblem,
    getTaskTemplateStages: contextService.getTaskTemplateStages,
    getTaskTemplateByKey: contextService.getTaskTemplateByKey,
    getTaskTemplateByTaskCode: contextService.getTaskTemplateByTaskCode,
    loadMediaForSupervisedVisit: contextService.loadMediaForSupervisedVisit,
    executeWebMethod: contextService.executeWebMethod,
    getPosExtension: contextService.getPosExtension,
    resolvePropertyName: (propertyName: string) => {
      return resolvePropertyName(propertyName, localContext.subProcessStack)
    },
    getQuestionnaireByKey: contextService.getQuestionnaireByKey,
    getDefaultCodeSpace: contextService.getDefaultCodeSpace,
    generateEntityCode,
    showMessage,
    service,
    context: localContext,
    window: 'window',
    self: 'self',
    globalThis: 'globalThis',
  }

  const fn = new AsyncFunction(...Object.keys(params), script.body)
  console.log('starting with ', getCurrentRecord(localContext), localContext.task)
  const res = await fn.call('this', ...Object.values(params))
  if (localContext.subProcessStack?.length) {
    const stateKey = makeStateKey(last(localContext.subProcessStack)!)
    localContext.fullScope[stateKey] = localContext.recordScope
  }
  // console.log('resulting scope', localContext.fullScope)
  const rootRecordName = contextService.rootRecordName ?? 'task'
  await contextService.saveTask(localContext[handlePropertyName(rootRecordName) as 'task'])
  await contextService.saveScope(localContext.fullScope)
  await contextService.refetch()

  // await delay(10000)
  return res
}

export const runPureScript = async (
  script: IFaceScript,
  localContext: IScriptTaskContext,
  contextService: ILocalContextService,
): Promise<unknown> => {
  const service = {
    delay,
    clone: cloneDeep,
    uniqBy,
  }

  const params = {
    ...localContext,
    task: localContext.task,
    pos: localContext.pos,
    visit: localContext.visit,
    scope: localContext.scope,
    record: localContext.record,
    recordScope: localContext.recordScope,
    saveTask: noop,
    saveScope: noop,
    getProductMatrix: contextService.getProductMatrix,
    getFprUserProfiles: contextService.getFprUserProfiles,
    getSupervisedRoleByCode: contextService.getSupervisedRoleByCode,
    getContractTerms: contextService.getContractTerms,
    createVisitProblem: contextService.createVisitProblem,
    getTaskTemplateStages: contextService.getTaskTemplateStages,
    getTaskTemplateByKey: contextService.getTaskTemplateByKey,
    getTaskTemplateByTaskCode: contextService.getTaskTemplateByTaskCode,
    loadMediaForSupervisedVisit: contextService.loadMediaForSupervisedVisit,
    executeWebMethod: contextService.executeWebMethod,
    getPosExtension: contextService.getPosExtension,
    resolvePropertyName: (propertyName: string) => {
      return resolvePropertyName(propertyName, localContext.subProcessStack)
    },
    getQuestionnaireByKey: contextService.getQuestionnaireByKey,
    getDefaultCodeSpace: contextService.getDefaultCodeSpace,
    generateEntityCode,
    showMessage,
    service,
    context: localContext,
    window: 'window',
    self: 'self',
    globalThis: 'globalThis',
  }

  const fn = new AsyncFunction(...Object.keys(params), script.body)
  const res = await fn.call('this', ...Object.values(params))
  // await delay(10000)
  return res
}
