import React, { useContext, useEffect } from 'react'

import { last } from 'lodash'

import { IScriptTaskContext } from '../script-tasks/script-task-context'
import { makeStateKey, SubProcessStackFrame } from './local-context'

interface ISubProcessContextProps extends SubProcessStackFrame {}

type ISubProcessContext = SubProcessStackFrame[]

interface INestedContext {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

interface INestedContextProps extends INestedContext {}

export const SubProcessContext = React.createContext<ISubProcessContext>([])
export const NestedContext = React.createContext<INestedContext>({})

type ProcessRecord = Record<string, unknown> | undefined
export function getCurrentRecord(localContext: IScriptTaskContext): ProcessRecord {
  const currentFrame = last(localContext.subProcessStack)
  if (!currentFrame) return localContext.record as ProcessRecord
  return localContext[(currentFrame.item.elementVariable ?? 'record') as 'record'] as ProcessRecord
}

export function applyNesting(
  localContext: IScriptTaskContext,
  stack: ISubProcessContext,
  nestedContext: INestedContext,
): IScriptTaskContext {
  const currentFrame = last(stack)
  let subprocessContext = {}
  if (currentFrame) {
    // TODO: apply all frames in case we want records from upper levels
    const stateKey = makeStateKey(currentFrame)
    const { item, record } = currentFrame
    // console.log('taskState', localContext.taskState)

    const recordScope = localContext.fullScope[stateKey] ?? {}
    const recordState = localContext.taskState?.subProcesses[stateKey] ?? {}

    subprocessContext = {
      subProcessStack: stack,
      [item.elementVariable ?? 'record']: record,
      recordScope,
      recordState,
    }
  }

  const override: IScriptTaskContext = {
    ...localContext,
    ...subprocessContext,
    ...nestedContext,
  }
  // console.log('override', override, stateKey, recordScope, recordState)

  return override
}

export const SubProcessContextProvider: React.FC<ISubProcessContextProps> = (props) => {
  useEffect(() => {
    console.log('NestedContext mount', props)
  }, [])
  const { item, record, process, recordIndex, children } = props
  const currentFrame = { item, record, process, recordIndex }
  const rootContext = useContext(SubProcessContext)

  return <SubProcessContext.Provider value={[...rootContext, currentFrame]}>{children}</SubProcessContext.Provider>
}

export const NestedContextProvider: React.FC<INestedContextProps> = (props) => {
  const { children, ...nestedProps } = props
  const parentContextProps = useContext(NestedContext)

  return <NestedContext.Provider value={{...parentContextProps, ...nestedProps}}>{children}</NestedContext.Provider>
}
