import React, { useEffect } from 'react'

import { Typography, Box } from '@material-ui/core'

import { ITableScreenItem } from '../../../../../model/table-screen-item'
import { getContextProperty } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { ITableRecord } from '../table-item/editorUtils'
import { TableItemLandscape } from '../table-item/table-item-landscape'
import { filterTableRows } from '../table-item/utils'

interface TablePreviewProps {
  children?: never
  className?: string
  item: ITableScreenItem
}

export const TablePreview: React.FC<TablePreviewProps> = (props) => {
  useEffect(() => {
    console.log('TablePreview mount', props)
  }, [])

  const { className, item } = props
  const propContext = useScriptTaskContext()
  const rows: ITableRecord[] | null = getContextProperty(propContext, item.propertyName)
  const filteredRows = filterTableRows(rows, item, propContext)
  // TODO cleanup isReport
  if (!filteredRows?.length && item.hideEmpty) {
    return <></>
  }

  return (
    <Typography className={className}>
      <strong>{item.displayName}: </strong>
      <Box mt={3}>
        <TableItemLandscape item={item} isReport={true} isReadOnly={true} />
      </Box>
    </Typography>
  )
}
