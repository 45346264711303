import { FieldForceVisitSource } from '../../model/visit'

export const getVisitSourceString = (source: FieldForceVisitSource | undefined): string => {
  switch (source) {
    case 'RoutePlan':
      return 'RP'
    case 'OutOfRoutePlan':
      return 'OOR'
    default:
      return ''
  }
}