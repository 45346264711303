import { useCallback, useContext } from 'react'

import { LogManager } from '../../../infrastructure/logger'
import { SyncErrorCode } from '../../../model/base'
import { ITask } from '../../../model/task'
import { IVisitTask } from '../../../model/visit-task'
import { SyncContext, ConfigContext, ApiContext } from '../../../providers'
import { SyncError } from '../../../services/sync-service-api'
import { appToast } from '../../../utils'
import { useGenerateReport } from './useGenerateReport'

const logger = LogManager.getLogger('useShareReport')

export function useShareReport(task: ITask | undefined | null, refetch?: () => Promise<unknown>): VoidFunction {
  const { syncService } = useContext(SyncContext)
  const api = useContext(ApiContext)
  const { config } = useContext(ConfigContext)
  const generateReport = useGenerateReport(task)

  const onShareHandler = useCallback(async (): Promise<void> => {
    console.log('onsharehandler', task)
    if (!task) {
      logger.info('onShareHandler', 'no task available', task)
      return
    }
    let shareLink = task.reportLink

    if (!shareLink) {
      try {
        const resultLink = await syncService.uploadPendingTaskReport((task as IVisitTask).visitCode, task.code)
        if (resultLink === null) {
          try {
            shareLink = await generateReport()
          } catch (e) {
            logger.error('onShareHandler', 'error generating report', e, task)
            LogManager.flush()
            appToast.error('При генерации pdf-файла произошла ошибка')
            return
          }
        } else {
          shareLink = resultLink
        }
        shareLink &&
          void api.tasks.updateTaskReportLink(task.code, shareLink).then(() => refetch?.())
      } catch (error) {
        const message = mapError(error)

        // logger.error('onShareHandler', message, error, { visitCode: task.visitCode, taskCode: task.code })

        appToast.error(message)
        return
      }
    }
    if (!shareLink) return logger.debug('onShareHandler', 'no link')
    if (!navigator.share) {
      logger.error('onShareHandler', "Your system doesn't support sharing files.")
      appToast.error('Ваша система не поддерживает функцию отправки файлов')
      return
    }
    try {
      // const taskUpdateDate = dateFormat(task.updateTime, 'dd.MM.yyyy')

      await navigator.share({
        url: `${config.apiUrl}${shareLink}`,
        title: `Отчет по задаче ${task.code}`,
        text: 'Отчет по задаче',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        // title: `Отчет по результатам ${(task as any).representative?.name ?? ''} за ${taskUpdateDate}`,
        // text: 'Итоги проверки навыков'
      })
    } catch (e) {
      logger.error('onShareHandler', 'Sharing failed', e, { task })
      // appToast.error('Ошибка при получении ссылки на отчет')
    }
  }, [task])

  return onShareHandler
}

function mapError(error: unknown): string {
  if (error instanceof SyncError) {
    switch (error.code) {
      case SyncErrorCode.Unauthorized:
        return 'Пользователь не аутентифицирован. Необходимо повторно войти в систему'
      case SyncErrorCode.Maintenance:
        return 'Сервер временно недоступен, ведутся регламентные работы'
      case SyncErrorCode.ServerError:
        return 'Метод сохранения pdf-файла временно недоступен'
      default:
        return `Во время выполнения метода отправки pdf-файла произошла ошибка (${error.httpStatus})`
    }
  }
  return 'Операция временно недоступна'
}
