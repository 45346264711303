/* eslint-disable @typescript-eslint/method-signature-style */

import { InjectionToken } from '../infrastructure/di'
import { ASTPredicate } from '../model/ast-predicate'
import { DataCollectionCode, COLLECTIONS } from '../model/collections'
import { IPropertyListSortingOptions } from '../model/face-data-object'
import { ProblemDetails } from '../utils'

export interface IDataCollectionServiceApi {
  getList<C extends DataCollectionCode>(collection: C, request: GetListRequest): Promise<GetListResult<C>>
  update<C extends DataCollectionCode>(collection: C, data: COLLECTIONS[C]): Promise<COLLECTIONS[C]>
  export<C extends DataCollectionCode>(collection: C, request: GetListRequest, fileType: 'xlsx' | 'json'): Promise<Blob>
  uploadFile<C extends DataCollectionCode>(
    collection: C,
    file: File,
    request: UploadFileRequest,
  ): Promise<UploadFileResponse>
}

export const DATA_COLLECTIONS_API = new InjectionToken<IDataCollectionServiceApi>('data-collections-api')

export interface GetListRequest {
  filter?: ASTPredicate | null | undefined
  pagingOptions?: PagingOptions
  sortingOptions?: IPropertyListSortingOptions | null | undefined
}

export interface GetListResult<C extends DataCollectionCode> {
  $type: 'PMI.FACE.WebApi.Dto.DataCollectionDto'
  items: Array<COLLECTIONS[C]>
  page: PagingOptions
  totalItemsCount: number
}

interface PagingOptions {
  pageNumber: number
  pageSize: number
}

export interface UploadFileRequest {
  profileCode: string
  recordType: string
  action: UploadFileAction
}

export interface UploadFileResponse {
  message: string
  processRecordsCount: number
}

interface UploadFileProblemDetailsExtensions {
  position: { [pos: number]: null }
}

export interface UploadFileProblemDetails extends ProblemDetails<UploadFileProblemDetailsExtensions> {}

export type UploadFileAction = 'Create' | 'Update' | 'Upsert' | 'Merge' | 'Cancel'
