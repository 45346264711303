import { DependencyList, useCallback, useContext } from 'react'

import { Code } from '../../../model/base'
import { ITask } from '../../../model/task'
import { IVisitTask } from '../../../model/visit-task'
import { ApiContext, ProfileContext } from '../../../providers'
import { getAvailableVisitTaskRegister } from '../visit-tasks/utils'

interface IUseTaskListResult {
  visits: IVisitTask[]
  registers: ITask[]
}

export function useTaskList(deps: DependencyList = []): {
  getTaskList: (visitCode: Code) => Promise<IUseTaskListResult>
} {
  const api = useContext(ApiContext)
  const profile = useContext(ProfileContext)

  const _getTaskList = useCallback(
    async (visitCode: Code): Promise<IUseTaskListResult> => {
      const tasks = await api.tasks.searchVisitTasks({ visitCode: visitCode })
      const tasksRegisters = await getAvailableVisitTaskRegister(api, profile.value!.profile, visitCode)

      // FACE-2786 - фильтрация назначений, тех которые уже есть в списке задач
      const entryRegisterList = tasksRegisters.filter(
        (register) => !tasks.find((task) => register.register?.code === task.register?.code),
      )

      return {
        visits: tasks,
        registers: entryRegisterList,
      }
    },
    [...deps],
  )

  return {
    getTaskList: _getTaskList,
  }
}