import { cloneDeep } from 'lodash'
import { set as setFp } from 'lodash/fp'

import { Code, CodeSpace, IEntityReference } from '../../model/base'
import { IContractTerm } from '../../model/contract-term'
import { FieldForceVisitEscalationReason } from '../../model/dictionary-item'
import { IProductMatrix } from '../../model/product-matrix'
import { IQuestionnaire } from '../../model/questionnaire'
import { ISupervisedFieldPositionRole } from '../../model/supervised-field-position-role'
import { ITask } from '../../model/task'
import { ITaskExecutionScope, ITaskExecutionState } from '../../model/task-execution'
import { ITaskStage } from '../../model/task-stage'
import { ITaskTemplate } from '../../model/task-template'
import { IUserProfile } from '../../model/user-profile'
import { IApiContext } from '../../providers/api/api-context'
import { VisitAssessment } from '../tasks/audit/audit-merchandise-service-task'
import { makePosExtension } from '../tasks/audit/use-backup-extensions'
import { useExecutionState } from '../tasks/nested/execution-state'
import { handlePropertyName, uncapitalize } from '../tasks/script-tasks/propertyName'
import { IScriptTaskContext } from '../tasks/script-tasks/script-task-context'
import { executeWebMethod, IWebMethodRequest, IWebMethodResult } from './execute-web-method'
import { IProcessContextService } from './process-context-service'

export class InMemoryProcessContextService implements IProcessContextService {
  inMemory = true
  fetching: Promise<IScriptTaskContext> | undefined

  constructor(
    readonly _refetch: () => Promise<IScriptTaskContext>,
    readonly rootRecordName: string = 'task',
    public state: Record<string, unknown> = {
      [rootRecordName]: {},
    },
    private readonly defaultCodeSpace: CodeSpace,
    readonly api?: IApiContext,
  ) {}

  getPosExtension = async (posCode: string): Promise<VisitAssessment['posExtension'] | undefined> => {
    if (this.api) {
      const pos = await this.api.pos?.getPos(posCode)
      if (pos) {
        return makePosExtension(pos)
      }
    } else {
      throw new Error('not implemented getPos')
    }
  }

  updateProperty = async (propertyName: string, value: unknown): Promise<void> => {
    let properPath
    if (propertyName.startsWith('fullScope.')) {
      const [, processRecordKey, ...rest] = propertyName.split('.')
      properPath = ['fullScope', processRecordKey, ...rest.map(uncapitalize)].join('.')
    } else {
      properPath = handlePropertyName(propertyName)
    }
    this.state = setFp(properPath, value, this.state)
  }

  saveTask = async (rootRecord: ITask): Promise<void> => {
    this.state = setFp(handlePropertyName(this.rootRecordName), rootRecord, this.state)
  }

  saveScope = async (scope: ITaskExecutionScope): Promise<void> => {
    this.state = setFp('fullScope', cloneDeep(scope), this.state)
  }

  saveTaskState = async (taskState: ITaskExecutionState): Promise<void> => {
    this.state = setFp('taskState', taskState, this.state)
  }

  updateStackSteps = async (): Promise<void> => {
    const localTaskState = useExecutionState.getState().taskState
    await this.saveTaskState(localTaskState)
    await this.refetch()
  }

  refetch = async (): Promise<IScriptTaskContext> => {
    this.fetching = this._refetch()
    const res = await this.fetching
    this.fetching = undefined
    return res
  }

  onLoad = async (): Promise<void> => {
    if (this.fetching) {
      console.log('awaiting fetch')
      return this.fetching.then(
        () => void 0,
        () => void 0,
      )
    } else {
      console.log('not fetching')
      return Promise.resolve()
    }
  }

  executeWebMethod = async (req: IWebMethodRequest): Promise<IWebMethodResult> => {
    if (this.api) {
      return executeWebMethod(this.api, req)
    } else {
      throw new Error('not implemented')
    }
  }

  getFprUserProfiles = async (): Promise<IUserProfile[]> => {
    return this.api!.fprUserProfiles.getFprUserProfiles()
  }

  getSupervisedRoleByCode = async (roleCode: Code): Promise<ISupervisedFieldPositionRole | undefined> => {
    return this.api!.supervised.getSupervisedRoleByCode(roleCode)
  }

  getTaskTemplateByKey = async (ref: IEntityReference): Promise<ITaskTemplate | null> => {
    return this.api!.tasks.getTaskTemplate(ref)
  }

  getProductMatrix!: () => Promise<IProductMatrix[]>
  getContractTerms!: () => Promise<IContractTerm[]>
  createVisitProblem!: (
    escalationReason: FieldForceVisitEscalationReason,
    executiveComment: string,
    problemDetails?: string | undefined,
  ) => Promise<string>

  getTaskTemplateStages!: (taskCode: string, searchInSupervisedTasks: boolean) => Promise<ITaskStage[]>
  getTaskTemplateByTaskCode!: (taskCode: string, searchInSupervisedTasks: boolean) => Promise<ITaskTemplate | null>
  loadMediaForSupervisedVisit!: (visitCode: string) => Promise<void>

  getQuestionnaireByKey = async (ref: IEntityReference): Promise<IQuestionnaire | null> => {
    if (this.api) {
      return await this.api.questionnaire.getQuestionnaireByKey(ref)
    } else {
      throw new Error('not implemented')
    }
  }

  getDefaultCodeSpace = (): CodeSpace => this.defaultCodeSpace
}
