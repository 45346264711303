import { IPPOSM } from '../../../../model/pposm'

/**
 * Производитель в общем случае делятся на "наших" и "наших" (определяется по коду производителя). Наши: pmsm, icos.
 * https://wiki.app.pconnect.biz/pages/viewpage.action?spaceKey=FACE&title=POS
 */
interface filteredByManufacturedResult {
  pmi: IPPOSM[]
  other: IPPOSM[]
}
export const filteredByManufactured = (pposmList: IPPOSM[]): filteredByManufacturedResult => {
  return pposmList.reduce<filteredByManufacturedResult>(
    (prev, current) => {
      const manufacturerCode = current.manufacturer?.code.toLocaleLowerCase()

      const isPMI = manufacturerCode === 'pmsm' || manufacturerCode === 'icos'

      return isPMI
        ? {
            pmi: [...prev.pmi, current],
            other: prev.other,
          }
        : {
            pmi: prev.pmi,
            other: [...prev.other, current],
          }
    },
    {
      pmi: [],
      other: [],
    },
  )
}
