import _ from 'lodash'

import {
  POSStore,
  POSTaskRegisterStore,
  QuestionnaireStore,
  SupervisedVisitStore,
  SurveyStore,
  TaskExecutionScopeStore,
  TaskExecutionStateStore,
  TaskStore, TaskStore_updateTime,
  TaskTemplateStore,
  VisitStore,
  VisitTaskTopicStore
} from '../../data/schema'
import {handlePropertyName} from '../../features/tasks/script-tasks/propertyName'
import {
  Code,
  findActiveVersion,
  generateEntityCode,
  getEntityKey,
  IEntityReference,
  isReferenceToEntityIgnoreVersion,
  isVersionActive
} from '../../model/base'
import {BusinessError, BusinessErrorCode, ValidationError, ValidationErrorCode} from '../../model/errors'
import {IPointOfSale} from '../../model/pos'
import {IQuestionnaireProfile} from '../../model/questionnaire-profile'
import {ISupervisedVisitUnit} from '../../model/supervised-visit-unit'
import {ISurvey} from '../../model/survey'
import {ITask} from '../../model/task'
import {ITaskContact} from '../../model/task-contact'
import {ITaskExecutionScope, ITaskExecutionState} from '../../model/task-execution'
import {ITaskTemplate} from '../../model/task-template'
import {ITaskUnit} from "../../model/task-unit";
import {IVisit} from '../../model/visit'
import {IRepresentative, isInVisitTask, IThirdParty, IVisitTask} from '../../model/visit-task'
import {IVisitTaskTopic} from '../../model/visit-task-topic'
import {dateFormatIso} from '../../utils'
import {trace} from '../../utils/trace'
import ITaskService, {
  CreateTaskRequest,
  CreateVisitTasksRequest,
  FinishTaskRequest,
  GetTasks2Request,
  GetTasks3Request,
  IVisitTaskSummary,
  IVisitTaskSummary3,
  ProfileResults, SearchTaskRegistersRequest,
  SearchTasksRequest,
  SearchVisitTasksRequest,
  SetRepresentativeInfoRequest,
  SetRepresentativeNewRequest,
  SetTaskFeedbackRequest,
  SetTaskPropertyPathRequest,
  SetTaskStatusRequest,
  VisitTaskSummaryRequest
} from '../task-service-api'
import {LocalStorageBaseService} from './local-storage-base-service'
import {SyncService} from './sync-service'

// const SALES_EXPERT_V1_VERSION_CODE = 'ServiceSE_v1'
const SALES_EXPERT_TEMPLATE_CODE = 'ServiceSE'

function getValueOrNone(value: string | undefined | null): string | undefined {
  const v = value?.trim()
  // eslint-disable-next-line no-unneeded-ternary
  return v ? v : undefined
}

function getValueOrEmpty(value: string | undefined | null): string {
  const v = value?.trim()
  // eslint-disable-next-line no-unneeded-ternary
  return v ? v : ''
}

function commonTaskSearchFilter(t: ITask, req?: SearchTasksRequest): boolean {
  if (req) {
    if (req.taskTemplateCodes) {
      if (!req.taskTemplateCodes.includes(t.template?.code)) return false
    }
    if (req.registerCode) {
      if (t.register?.code !== req.registerCode) return false
    }
    if (req.status) {
      if (!req.status.includes(t.status)) return false
    }
  }
  return true
}

export default class LocalStorageTaskService extends LocalStorageBaseService implements ITaskService
{
  private static readonly __className = 'LocalStorageTaskService'

  async getTask(taskCode: Code): Promise<ITask | null> {
    if (!taskCode) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing, 'taskCode is required', ['taskCode']
      )
    }
    return await this._getTask(taskCode, this._storage)
  }

  async getVisitTask(taskCode: Code): Promise<IVisitTask | null> {
    if (!taskCode) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing, 'taskCode is required', ['taskCode']
      )
    }
    const task = await this._getTask(taskCode, this._storage)
    const visitTask = task as IVisitTask
    return visitTask?.visitCode ? visitTask : null
  }

  async searchVisitTasks(searchVisitTasksRequest?: SearchVisitTasksRequest): Promise<IVisitTask[]> {
    const tasks = await this._storage.getWhere<IVisitTask>(TaskStore, (t: ITask) => {
      const vt = t as IVisitTask
      // considering only in-visit tasks
      if (vt.visitCode == null) return false
      if (searchVisitTasksRequest?.visitCode && vt.visitCode !== searchVisitTasksRequest.visitCode) return false
      return commonTaskSearchFilter(t, searchVisitTasksRequest)
    })

    if (searchVisitTasksRequest?.pointOfSaleCode == null) {
      return tasks
    }

    // filtering by pos using parent visit
    const result = []
    const visitPosCache = new Map<string, string>()
    for (const task of tasks) {
      let posCode = visitPosCache.get(task.visitCode)
      if (!posCode) {
        const visit = await this._getVisitOrThrow(task.visitCode, this._storage)
        posCode = visit.pointOfSaleCode
        visitPosCache.set(visit.code, posCode)
      }
      if (searchVisitTasksRequest.pointOfSaleCode === posCode) {
        result.push(task)
      }
    }
    return result
  }

  async searchTasks(searchTasksRequest?: SearchTasksRequest): Promise<ITask[]> {
    return await this._storage.getWhere<ITask>(
      TaskStore,(t: ITask) => commonTaskSearchFilter(t, searchTasksRequest)
    )
  }

  async searchNonVisitTasks(searchTasksRequest?: SearchTasksRequest): Promise<ITask[]> {
    return await this._storage.getWhere<ITask>(TaskStore, (t: ITask) => {
      // considering only non-visit tasks
      if (Boolean(t._isVisitTask) || Boolean(t._isTaskRegister) || isInVisitTask(t)) return false
      return commonTaskSearchFilter(t, searchTasksRequest)
    })
  }

  async searchTaskRegisters(request?: SearchTaskRegistersRequest): Promise<ITask[]> {
    return await this._storage.getWhere<ITask>(TaskStore, (t: ITask) => {
      // considering only task-register
      if (Boolean(t._isVisitTask) && Boolean(t._isTaskRegister)) {
        if (request?.pointOfSaleCode) {
          if (t.productLocation?.code !== request.pointOfSaleCode) {
            return false
          }
        }
        if (request?.visitDate) {
          if (t.plannedExecutionPeriod == null
            || (t.plannedExecutionPeriod.startDate > request.visitDate)
            || (t.plannedExecutionPeriod.endDate != null && t.plannedExecutionPeriod.endDate < request.visitDate)) {
            return false
          }
        }
        return true
      }
      return false
    })
  }

  @trace()
  async findLastFurtherFocus(source: IVisitTask): Promise<IVisitTask | undefined> {
    const sourceVisit = await this._storage.getByKey<IVisit>(VisitStore, source.visitCode)

    const tasksByRepresentative = await this._storage.getWhere<IVisitTask>(TaskStore, (t: ITask) => {
      const vt = t as IVisitTask
      if (vt.visitCode == null) {
        return false
      }
      if (vt.template?.code !== source.template?.code) {
        return false
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vtEmail = ((vt as any).representative as IRepresentative)?.eMails?.[0]?.address
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sourceEmail = ((source as any).representative as IRepresentative)?.eMails?.[0]?.address
      if (!vtEmail || vtEmail !== sourceEmail) {
        return false
      }
      if (vt.status !== 'Finished') {
        return false
      }
      return true
    })

    const visits = await this._storage.getWhere(VisitStore, (v: IVisit) => {
      return (
        sourceVisit!.pointOfSaleCode === v.pointOfSaleCode &&
        sourceVisit!.executedBy?.code === v.executedBy?.code &&
        !!tasksByRepresentative.find((vt) => vt.visitCode === v.code)
      )
    })

    visits.sort((a, b) => b.plannedStartDate - a.plannedStartDate)

    const fittingTasks = tasksByRepresentative.filter(
      (vt) => visits[0]?.code === vt.visitCode
      // && source.code !== vt.code
    )
    fittingTasks.sort((a, b) => (b.updateTime ?? 0) - (a.updateTime ?? 0))
    return fittingTasks[0]
  }

  @trace()
  async setTaskStatus(setTaskStatusRequest: SetTaskStatusRequest): Promise<ITask> {
    if (!setTaskStatusRequest.taskStatus || !setTaskStatusRequest.taskCode) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing,
        'taskStatus and taskCode are required in SetTaskStatusRequest',
        ['taskStatus', 'taskCode']
      )
    }
    const userRef = await this._getCurrentUserReference()
    const roleRef = await this._getCurrentRoleReference()

    return await this._storage.execute(
      [VisitStore, TaskStore, SurveyStore, TaskTemplateStore],
      async (tx) => {
        const task = await this._getTaskOrThrow(setTaskStatusRequest.taskCode, tx)
        if (this._setTaskStatus(task, setTaskStatusRequest.taskStatus, setTaskStatusRequest.cancellationReason)) {
          // FACE-141 При отмене задачи должны автоматически отменяться все опросы задачи.
          if (setTaskStatusRequest.taskStatus === 'Canceled') {
            let surveys = await this._getTaskSurveys(task, tx)
            surveys = surveys.filter((survey) => this._setSurveyStatus(survey, 'Canceled'))
            if (surveys.length) {
              await tx.put(SurveyStore, surveys)
            }
          }
          // FACE-1902
          if (setTaskStatusRequest.taskStatus === 'InProgress' || setTaskStatusRequest.taskStatus === 'Canceled') {
            if (setTaskStatusRequest.visitCode && task._isVisitTask && task._isTaskRegister) {
              (task as IVisitTask).visitCode = setTaskStatusRequest.visitCode
              task._isTaskRegister = false
            }
            task.executive = userRef
            task.executivePositionRole = task.executivePosition = roleRef

            const versionedTemplateRef = await this._getTaskVersionedTemplateRef(task.template, tx)
            if (versionedTemplateRef) {
              task.template = versionedTemplateRef
            }
          }
          task._changeTime = Date.now()
          await tx.put(TaskStore, task)
          await this._updateVisitChangeTime((task as IVisitTask).visitCode, tx)
        }
        return task
      })
  }

  @trace()
  async updateTaskReportLink(taskCode: Code, reportLink: string): Promise<ITask> {
    if (!reportLink || !taskCode) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing,
        'reportLink and taskCode are required parameters',
        ['reportLink', 'taskCode']
      )
    }
    return await this._storage.execute(
      [VisitStore, TaskStore],
      async (tx) => {
        const task = await this._getTaskOrThrow(taskCode, tx)
        if (this._setTaskReportLink(task, reportLink)) {
          await tx.put(TaskStore, task)
          await this._updateVisitChangeTime((task as IVisitTask).visitCode, tx);
        }
        return task
      })
  }

  @trace()
  async createVisitTasks({
    visitCode,
    createTaskRequests
  }: CreateVisitTasksRequest): Promise<IVisitTask[]> {
    if (!visitCode) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing,
        'visitCode is required in CreateVisitTasksRequest',
        ['visitCode']
      )
    }
    if (!createTaskRequests.length) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing,
        'createTaskRequests is required in CreateVisitTaskRequest',
        ['createTaskRequests']
      )
    }

    const userRef = await this._getCurrentUserReference()

    return await this._storage.execute(
      [VisitStore, POSStore, TaskStore, TaskTemplateStore, POSTaskRegisterStore],
      async (tx) => {
        const visit = await this._getVisitOrThrow(visitCode, tx)
        const pos = await this._getPos(visit.pointOfSaleCode, tx)

        const now = Date.now()
        const visitTasks: IVisitTask[] = []

        for (const createRequest of createTaskRequests) {
          const visitTask: IVisitTask = {
            $type: createRequest.$type,
            code: generateEntityCode('TAS'),
            codeSpace: this.defaultCodeSpace,
            name: createRequest.name,
            visitCode: visitCode,
            creationTime: now,
            updateTime: now,
            startDate: createRequest.startDate,
            localStartDate: dateFormatIso(visit.plannedStartDate ?? now),
            source: createRequest.source,
            template: createRequest.template,
            executive: createRequest.executive,
            executivePosition: createRequest.executivePosition,
            executivePositionRole: createRequest.executivePositionRole,
            createdBy: userRef,
            status: createRequest.status,
            surveys: [],
          }
          visitTask.type = 'Education' // required for ThirdPartyRepresentativeTask inheritors
          this._updateVisitTaskFromVisit(visitTask, visit, pos)
          visitTasks.push(visitTask)
        }

        await tx.put(TaskStore, visitTasks)

        visit.updateTime = visit._changeTime = now
        await tx.put(VisitStore, visit)

        return visitTasks
      }
    )
  }

  async createTask(createRequest: CreateTaskRequest): Promise<ITask> {
    const userRef = await this._getCurrentUserReference()
    const now = Date.now()
    return await this._storage.execute(
      [TaskStore, TaskTemplateStore, POSTaskRegisterStore],
      async (tx) => {
        const task: ITask = {
          $type: createRequest.$type,
          code: generateEntityCode('TSK'),
          codeSpace: this.defaultCodeSpace,
          name: createRequest.name,
          _changeTime: now,
          creationTime: now,
          updateTime: now,
          startDate: createRequest.startDate,
          localStartDate: dateFormatIso(createRequest.startDate),
          source: createRequest.source,
          template: createRequest.template,
          executive: createRequest.executive,
          executivePosition: createRequest.executivePosition,
          executivePositionRole: createRequest.executivePositionRole,
          createdBy: userRef,
          status: createRequest.status,
          surveys: [],
        }

        await tx.put(TaskStore, task)
        return task
      }
    )
  }

  async getTaskTemplates(): Promise<ITaskTemplate[]> {
    const now = new Date()
    return this._storage.getWhere<ITaskTemplate>(TaskTemplateStore, tt => isVersionActive(tt.version, now))
  }

  async getTaskTemplate(ref: IEntityReference): Promise<ITaskTemplate | null> {
    if (ref.version) {
      const key = getEntityKey(ref)
      return (await this._storage.getByKey<ITaskTemplate>(TaskTemplateStore, key)) ?? null
    }
    const templatesByCode = await this._storage.getWhere<ITaskTemplate>(
      TaskTemplateStore, t => isReferenceToEntityIgnoreVersion(ref, t)
    )
    return findActiveVersion(templatesByCode) ?? null
  }

  @trace()
  async createNewTaskSurvey(taskCode: Code, questionnaireCode: Code, propertyName = 'surveys'): Promise<ISurvey> {
    const profile = await this._getCurrentProfile()
    const $type = SyncService.isDteProfile(profile)
      ? 'PMI.BDDM.Transactionaldata.DTESurvey'
      : 'PMI.FACE.BDDM.Extensions.Classes.FaceFieldForceSurvey'

    return this._storage.execute(
      [VisitStore, TaskStore, SurveyStore],
      async (tx) => {
        const task = await this._getTaskOrThrow(taskCode, tx)
        const now = Date.now()
        const newSurvey: ISurvey = {
          $type,
          code: generateEntityCode('SUR'),
          codeSpace: this.defaultCodeSpace,
          creationTime: now,
          updateTime: now,
          startDate: new Date(2022, 0).getTime(),
          questionnaire: {
            $type: 'PMI.BDDM.Transactionaldata.FieldForceQuestionnaireReference',
            code: questionnaireCode,
            codeSpace: this.defaultCodeSpace,
          },
          _visitCode: (task as IVisitTask).visitCode,
          _taskCode: taskCode,
          status: 'Open',
        }

        const properPath = handlePropertyName(propertyName)

        const surveys: IEntityReference[] = _.get(task, properPath) ?? []
        surveys.push({
          code: newSurvey.code,
          codeSpace: this.defaultCodeSpace,
        })

        await tx.put(SurveyStore, newSurvey)

        _.set(task, properPath, surveys)
        task._changeTime = now

        await tx.put(TaskStore, task)

        await this._updateVisitChangeTime((task as IVisitTask).visitCode, tx)

        return newSurvey
      })
  }

  @trace()
  async removeTaskContact(taskCode: Code, surveyCode: Code): Promise<void> {
    if (!taskCode || !surveyCode) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing,
        'taskCode and surveyCode are required parameters',
        ['taskCode', 'surveyCode']
      )
    }

    await this._storage.execute([VisitStore, TaskStore, SurveyStore], async (tx) => {
      const task = await this._getTaskOrThrow(taskCode, tx)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const faceThirdPartyRepresentativeTask = task as any
      if (!faceThirdPartyRepresentativeTask.contacts) {
        throw new BusinessError(
          BusinessErrorCode.EmptySet,
          `Task ${taskCode} has no contacts`,
          {type: 'task', code: taskCode})
      }
      const contactIndex = faceThirdPartyRepresentativeTask.contacts.findIndex(
        (c: ITaskContact) => c.survey.code === surveyCode
      )
      if (contactIndex < 0) {
        throw new BusinessError(
          BusinessErrorCode.EntityNotFound,
          `Task ${taskCode} contact for survey ${surveyCode} not found`,
          {type: 'task-contact', taskCode: taskCode, surveyCode: surveyCode}
        )
      }

      /* removing contact with survey - DEPRECATED logic */
      //task.contacts.splice(contactIndex, 1)
      //await tx.deleteByKey(SurveyStore, surveyCode)

      /* canceling contact survey */
      const survey = await this._getSurveyOrThrow(surveyCode, tx)
      this._setSurveyStatus(survey, 'Canceled')
      await tx.put(SurveyStore, survey)

      task.updateTime = task._changeTime = Date.now()
      await tx.put(TaskStore, task)

      await this._updateVisitChangeTime((task as IVisitTask).visitCode, tx)
    })
  }

  @trace()
  async setRepresentativeInfo({
    taskCode,
    representativeName,
    representativeEmail,
    thirdPartyName,
    businessTopic,
    theme,
    topicCodes
  }: SetRepresentativeInfoRequest): Promise<ITask> {
    if (!taskCode) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing,
        'taskCode is a required parameter',
        ['taskCode']
      )
    }
    /* if ((theme == null && topicCodes == null) || (theme != null && topicCodes != null)) {
      throw new ValidationError(
        ValidationErrorCode.PreconditionFailed,
        'only theme or topicCodes should be specified',
        ['theme', 'topicCodes']
      )
    } */

    theme = getValueOrNone(theme)
    /* if (!theme && topicCodes && topicCodes.length > 0) {
      theme = (await this._storage.getByKeys<IVisitTaskTopic>(VisitTaskTopicStore, topicCodes))
        .sort((a, b) => a.orderNumber - b.orderNumber)
        .map((t) => t.name)
        .join(', ')
    } */

    return await this._storage.execute([VisitStore, TaskStore, VisitTaskTopicStore], async (tx) => {
      const task = await this._getTaskOrThrow(taskCode, tx)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const faceThirdPartyRepresentativeTask = task as any
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      faceThirdPartyRepresentativeTask.representative = <IRepresentative>{
        name: getValueOrEmpty(representativeName),
        eMails: representativeEmail ? [{ address: getValueOrEmpty(representativeEmail) }] : []
      }
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      faceThirdPartyRepresentativeTask.thirdParty = <IThirdParty>{
        code: '0000',
        codeSpace: this.defaultCodeSpace,
        name: getValueOrNone(thirdPartyName),
      }
      faceThirdPartyRepresentativeTask.businessTopic = businessTopic
      faceThirdPartyRepresentativeTask.theme = theme
      faceThirdPartyRepresentativeTask.topicCodes = topicCodes

      task.updateTime = task._changeTime = Date.now()

      await tx.put(TaskStore, task)

      await this._updateVisitChangeTime((task as IVisitTask).visitCode, tx)

      return task
    })
  }

  @trace()
  async setRepresentativeNewInfo({
    taskCode,
    representativeName,
    representativeEmail,
    thirdPartyName
  }: SetRepresentativeNewRequest): Promise<ITask> {
    if (!taskCode) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing,
        'taskCode is a required parameter',
        ['taskCode']
      )
    }

    return await this._storage.execute([VisitStore, TaskStore, VisitTaskTopicStore], async (tx) => {
      const task = await this._getTaskOrThrow(taskCode, tx)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const faceThirdPartyRepresentativeTask = task as any
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      faceThirdPartyRepresentativeTask.representative = <IRepresentative>{
        name: getValueOrEmpty(representativeName),
        eMails: representativeEmail ? [{ address: getValueOrEmpty(representativeEmail) }] : []
      }
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      faceThirdPartyRepresentativeTask.thirdParty = <IThirdParty>{
        code: '0000',
        codeSpace: this.defaultCodeSpace,
        name: getValueOrNone(thirdPartyName),
      }

      task.updateTime = task._changeTime = Date.now()

      await tx.put(TaskStore, task)

      await this._updateVisitChangeTime((task as IVisitTask).visitCode, tx)

      return task
    })
  }

  @trace()
  async setTaskPropertyPath({ taskCode, propertyName, value }: SetTaskPropertyPathRequest): Promise<void> {
    return this._storage.execute([VisitStore, TaskStore, POSStore, TaskExecutionScopeStore], async (tx) => {
      const task = await this._getTaskOrThrow(taskCode, tx)
      // const targetValue = typeof value === 'string' ? getValueOrNone(value) : value || undefined
      const targetValue = value
      const properPath = handlePropertyName(propertyName)

      if (propertyName.startsWith('task')) {
        if (propertyName.toLowerCase().includes('email')) {
          _.set({ task: task }, properPath, targetValue ?? '')
        } else {
          _.set({ task: task }, properPath, targetValue)
        }
        task.updateTime = task._changeTime = Date.now()
        await tx.put(TaskStore, task)
        return this._updateVisitChangeTime((task as IVisitTask).visitCode, tx)
      }

      if (propertyName.startsWith('scope')) {
        const scope = (await tx.getByKey<ITaskExecutionScope>(TaskExecutionScopeStore, taskCode)) ?? {}
        _.set({ scope }, properPath, targetValue)
        await tx.putByKey(TaskExecutionScopeStore, scope, taskCode)
        return
      }

      console.warn('invalid task property name:', propertyName)
    })
  }

  @trace()
  async setTaskFeedback({
    taskCode,
    recommendations,
    strengths,
    furtherDevelopment
  }: SetTaskFeedbackRequest): Promise<ITask> {
    if (!taskCode) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing,
        'taskCode is required in SetVisitTaskFeedbackRequest',
        ['taskCode']
      )
    }
    return await this._storage.execute([VisitStore, TaskStore], async (tx) => {
      const task = await this._getTaskOrThrow(taskCode, tx)
      const getValue = (value?: string): string | undefined => (!value?.trim() ? undefined : value.trim())

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const faceThirdPartyRepresentativeTask = task as any

      faceThirdPartyRepresentativeTask.recommendations = getValue(recommendations)
      faceThirdPartyRepresentativeTask.strengths = getValue(strengths)
      faceThirdPartyRepresentativeTask.furtherDevelopment = getValue(furtherDevelopment)

      task.updateTime = task._changeTime = Date.now()

      await tx.put(TaskStore, task)

      await this._updateVisitChangeTime((task as IVisitTask).visitCode, tx)

      return task
    })
  }

  async getTaskSurveys(taskCode: Code): Promise<ISurvey[]> {
    if (!taskCode) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing, 'taskCode is a required parameter', ['taskCode']
      )
    }
    return await this._storage.execute(
      [TaskStore, SurveyStore],
      async (tx) => {
        const task = await this._getTaskOrThrow(taskCode, tx)
        return await this._getTaskSurveys(task, tx)
      },
      'r'
    )
  }

  @trace()
  async finishTask({ taskCode, reportLink }: FinishTaskRequest): Promise<ITask> {
    return await this._storage.execute(
      [VisitStore, TaskStore],
      async (tx) => {
        const task = await this._getTaskOrThrow(taskCode, tx)
        const changes = [this._setTaskStatus(task, 'Finished'), this._setTaskReportLink(task, reportLink)]
        if (changes.some(b => b)) {
          await tx.put(TaskStore, task)
          await this._updateVisitChangeTime((task as IVisitTask).visitCode, tx);
        }
        return task
      })
  }

  async getVisitTaskTopics(): Promise<IVisitTaskTopic[]> {
    return await this._storage.getByKeyRange<IVisitTaskTopic>(VisitTaskTopicStore, null)
  }

  async getTasks2(request: GetTasks2Request): Promise<IVisitTask[]> {
    const result: IVisitTask[] = []
    await this._storage.execute(
      [VisitStore, TaskStore, SurveyStore, QuestionnaireStore],
      async (tx) => {
        let visitPosMap: Map<Code, Code> | undefined
        for await (const task of tx.selectByIndexRange<ITask>(TaskStore, TaskStore_updateTime, null, 'desc')) {
          const visitTask = task as IVisitTask
          if (visitTask.visitCode == null) {
            continue
          }
          if (request.templateCode) {
            if (visitTask.template?.code !== request.templateCode) continue
          }
          if (request.templateVersionCode) {
            if (visitTask.template?.version?.code !== request.templateVersionCode) continue
          }
          if (visitTask.status !== 'Finished') continue
          if (request.period && visitTask.creationTime) {
            if (visitTask.creationTime > request.period.to) continue
            if (visitTask.creationTime < request.period.from) break // continue
          }
          if (request.pointOfSaleCode) {
            if (!visitPosMap) {
              visitPosMap = new Map<Code, Code>()
            }
            let posCode = visitPosMap.get(visitTask.visitCode)
            if (!posCode) {
              const visit = await this._getVisitOrThrow(visitTask.visitCode, tx)
              posCode = visit.pointOfSaleCode
              visitPosMap.set(visit.code, posCode)
            }
            if (request.pointOfSaleCode !== posCode) continue
          }
          result.push(visitTask)
        }
      },
      'r'
    )
    return result
  }

  async getTasks3(request: GetTasks3Request): Promise<IVisitTaskSummary3[]> {
    const result: IVisitTaskSummary3[] = []
    await this._storage.execute(
      [VisitStore, TaskStore, POSStore],
      async (tx) => {
        const visitMap = new Map<Code, IVisit>()
        const posMap = new Map<Code, IPointOfSale>()
        for await (const task of tx.selectByIndexRange<ITask>(TaskStore, TaskStore_updateTime, null, 'desc')) {
          const visitTask = task as IVisitTask
          if (visitTask.visitCode == null) {
            continue
          }
          if (request.templateCode) {
            if (visitTask.template?.code !== request.templateCode) continue
          }
          if (request.templateVersionCode) {
            if (visitTask.template?.version?.code !== request.templateVersionCode) continue
          }
          if (visitTask.status !== 'Finished') continue

          const visit = visitMap.get(visitTask.visitCode) ?? (await this._getVisitOrThrow(visitTask.visitCode, tx))
          visitMap.set(visit.code, visit)

          const posCode = visit.pointOfSaleCode

          if (request.pointOfSaleCode) {
            if (request.pointOfSaleCode !== posCode) continue
          }

          const date = visit.plannedStartDate

          if (request.period) {
            if (date > request.period.to) continue
            if (date < request.period.from) continue
          }

          const pos = posMap.get(posCode) ?? (await this._getPos(posCode, tx))!
          posMap.set(posCode, pos)

          const summary: IVisitTaskSummary3 = { task: visitTask, pos, date }
          result.push(summary)
        }
      },
      'r'
    )
    return result
  }

  async getVisitTaskSummary(request: VisitTaskSummaryRequest): Promise<IVisitTaskSummary[]> {
    const result: IVisitTaskSummary[] = []
    const representativeName = request.representativeName?.toLowerCase()
    await this._storage.execute(
      [VisitStore, TaskStore, SurveyStore, QuestionnaireStore],
      async (tx) => {
        let visitPosMap: Map<Code, Code> | undefined
        let profileCache: Map<Code, IQuestionnaireProfile> | undefined
        for await (const task of tx.selectByIndexRange<ITask>(TaskStore, TaskStore_updateTime, null, 'desc')) {
          const visitTask = task as IVisitTask
          if (visitTask.visitCode == null) {
            continue
          }
          if (visitTask.template?.code !== SALES_EXPERT_TEMPLATE_CODE) continue
          if (visitTask.status !== 'Finished') continue
          if (request.period && visitTask.creationTime) {
            if (visitTask.creationTime > request.period.to) continue
            if (visitTask.creationTime < request.period.from) break // continue
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const faceThirdPartyRepresentativeTask = visitTask as any
          if (
            representativeName &&
            faceThirdPartyRepresentativeTask.representative?.name?.toLowerCase().includes(representativeName) !== true
          )
            continue
          if (request.pointOfSaleCode) {
            if (!visitPosMap) {
              visitPosMap = new Map<Code, Code>()
            }
            let posCode = visitPosMap.get(visitTask.visitCode)
            if (!posCode) {
              const visit = await this._getVisitOrThrow(visitTask.visitCode, tx)
              posCode = visit.pointOfSaleCode
              visitPosMap.set(visit.code, posCode)
            }
            if (request.pointOfSaleCode !== posCode) continue
          }

          const surveys = await this._getTaskSurveys(visitTask, tx)

          const scoreSum: ProfileResults = {}
          const maxScoreSum: ProfileResults = {}

          for (const survey of surveys) {
            if (survey.answers && survey.answers.length > 0) {
              // find survey questionnaire profile
              let profile
              if (survey.profileCode) {
                if (!profileCache) {
                  profileCache = new Map<Code, IQuestionnaireProfile>()
                }
                profile = profileCache.get(survey.profileCode)
                if (!profile) {
                  const questionnaire = await this._getQuestionnaireOrThrow(survey.questionnaire.code, tx)
                  if (questionnaire) {
                    questionnaire.profiles?.forEach((p) => profileCache!.set(p.code, p))
                    profile = profileCache.get(survey.profileCode)
                  }
                }
              }
              // process survey answers
              for (const answer of survey.answers) {
                if (answer.score != null && answer.maxScore != null) {
                  scoreSum.ALL = (scoreSum.ALL ?? 0) + answer.score
                  maxScoreSum.ALL = (maxScoreSum.ALL ?? 0) + answer.maxScore
                  if (profile) {
                    scoreSum[profile.shortName] = (scoreSum[profile.shortName] ?? 0) + answer.score
                    maxScoreSum[profile.shortName] = (maxScoreSum[profile.shortName] ?? 0) + answer.maxScore
                  }
                }
              }
            }
          }

          const summary: IVisitTaskSummary = {
            code: visitTask.code,
            date: visitTask.startDate ?? visitTask.updateTime,
            representative: faceThirdPartyRepresentativeTask.representative,
            thirdParty: faceThirdPartyRepresentativeTask.thirdParty,
            results: {},
            templateCode: visitTask.template?.version?.code
          }

          for (const name of Object.keys(scoreSum)) {
            const score = scoreSum[name as keyof ProfileResults]
            const maxScore = maxScoreSum[name as keyof ProfileResults]
            summary.results[name as keyof ProfileResults] =
              score != null && maxScore != null && maxScore > 0 ? Math.round((100 * score) / maxScore) : null
          }

          result.push(summary)
        }
      },
      'r'
    )
    return result
  }

  @trace()
  async restoreCanceledTask(taskCode: Code): Promise<boolean> {
    if (!taskCode) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing, 'taskCode is a required parameter', ['taskCode']
      )
    }
    return await this._storage.execute(
      [VisitStore, TaskStore, SurveyStore],
      async (tx) => {
        const task = await this._getTaskOrThrow(taskCode, tx)
        const visitTask = task as IVisitTask
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const faceTask = visitTask as any

        if (task.status === 'Canceled') {
          const visit = visitTask.visitCode ? await this._getVisitOrThrow(visitTask.visitCode, tx) : null

          if (this._setTaskStatus(task, faceTask.preCanceledStatus ?? 'Planned')) {
            task.startDate = visit?.startDate ?? visit?.plannedStartDate ?? Date.now()
            task.localStartDate = dateFormatIso(task.startDate)

            // restoring task's canceled surveys
            for (const survey of await this._getTaskSurveys(task, tx)) {
              if (survey.status === 'Canceled') {
                if (this._setSurveyStatus(survey, survey.preCanceledStatus ?? 'Open')) {
                  await tx.put(SurveyStore, survey)
                }
              }
            }

            task._changeTime = Date.now()
            await tx.put(TaskStore, task)

            await this._updateVisitChangeTime(visitTask.visitCode, tx)
            return true
          }
        }
        return false
      })
  }

  async saveTask(task: ITask): Promise<void> {
    await this._storage.execute(
      [VisitStore, TaskStore],
      async (tx) => {
        task._changeTime = Date.now()
        await tx.put(TaskStore, task)
        await this._updateVisitChangeTime((task as IVisitTask).visitCode, tx)
      })
  }

  @trace()
  async deleteTask(taskCode: Code): Promise<void> {
    return this._storage.deleteByKey(TaskStore, taskCode)
  }

  @trace()
  async purgeTask(taskCode: Code): Promise<void> {
    await this._storage.execute(
      [TaskStore, SurveyStore, TaskExecutionScopeStore, TaskExecutionStateStore],
      async (tx) => {
        return await this._purgeTask(taskCode, tx)
      },
      'rw'
    )
  }

  async getTaskExecutionScope(taskCode: Code): Promise<ITaskExecutionScope | null> {
    return (await this._storage.getByKey<ITaskExecutionScope>(TaskExecutionScopeStore, taskCode)) ?? null
  }

  async saveTaskExecutionScope(taskCode: Code, scope: ITaskExecutionScope): Promise<void> {
    await this._storage.putByKey(TaskExecutionScopeStore, scope, taskCode)
  }

  async getTaskExecutionState(taskCode: Code): Promise<ITaskExecutionState | null> {
    return (await this._storage.getByKey<ITaskExecutionState>(TaskExecutionStateStore, taskCode)) ?? null
  }

  async saveTaskExecutionState(state: ITaskExecutionState): Promise<void> {
    await this._storage.put(TaskExecutionStateStore, state)
  }

  async getSupervisedTaskUnit(taskCode: Code): Promise<ITaskUnit | null> {
    const vu = await this._storage.getFirstWhere<ISupervisedVisitUnit>(
      SupervisedVisitStore,
      vu => vu.tasks.some(t => t.code === taskCode)
    );

    if (vu) {
      const task = vu.tasks.find(t => t.code === taskCode)
      if (task) {
        return <ITaskUnit>{
          task: task,
          surveys: vu.surveys?.length
            ? task.surveys?.map(sr => vu.surveys?.find(s => s.code === sr.code)).filter(Boolean)
            : null
        }
      }
    }

    return null
  }
}
