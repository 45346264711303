/* eslint-disable @typescript-eslint/no-explicit-any */
import { IDisposable } from './disposable'
import { EventEmitter, EventHandler } from './event'

export class EventBus implements IDisposable {
  private readonly _eventMap = new Map<string, EventEmitter<any>>()
  private readonly _name: string

  constructor(name: string) {
    this._name = name
  }

  public subscribe<T>(eventName: string, handler: EventHandler<T>): IDisposable {
    let emitter = this._eventMap.get(eventName)
    if (!emitter) {
      emitter = new EventEmitter<any>()
      this._eventMap.set(eventName, emitter)
    }

    emitter.add(handler)
    console.debug(`EventBus[${this._name}]: made subscription to event '${eventName}'`)

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const eventBus = this
    return {
      dispose: () => eventBus.unsubscribe(eventName, handler)
    }
  }

  public unsubscribe(eventName: string, handler: EventHandler<any>): void {
    const emitter = this._eventMap.get(eventName)
    if (emitter) {
      emitter.remove(handler)
      console.debug(`EventBus[${this._name}]: made unsubscription from event '${eventName}'`)
      if (!emitter.hasHandlers) {
        this._eventMap.delete(eventName)
      }
    }
  }

  public emit<T>(eventName: string, args?: T): boolean {
    const emitter = this._eventMap.get(eventName)
    if (!emitter) {
      console.debug(`EventBus[${this._name}]: idle emit - no subscribers to event '${eventName}'`)
      return false
    }
    console.debug(`EventBus[${this._name}]: emitting event '${eventName}'`)
    emitter.emit(args)
    return true
  }

  public dispose(): void {
    this._eventMap.forEach((emitter, _) => emitter.dispose())
    this._eventMap.clear()
  }
}
