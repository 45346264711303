import React, { useContext, useRef } from 'react'

import HttpClientFactory from '../../infrastructure/http-client-factory/http-client-factory'
import { SyncService } from '../../services/impl/sync-service'
import { ApiContext } from '../api'
import { AuthContext } from '../auth'
import { ConfigContext } from '../config'
import SyncContext, { ISyncContext } from './sync-context'

const SyncProvider: React.FC = ({ children }) => {
  const cfg = useContext(ConfigContext)
  const auth = useContext(AuthContext)
  const api = useContext(ApiContext)
  const authService = auth.authService
  const storageService = api?.storageService
  const httpClientFactory = new HttpClientFactory(cfg.config.apiUrl, authService)
  const serviceRef = useRef<SyncService>()

  if (!storageService) {
    if (auth.authenticated) {
      console.info('Storage service was not found, skipping one render')
    } else {
      console.info('Storage service will be instantiated after authorizing')
    }
    return <>{children}</>
  }

  if (!serviceRef.current) {
    serviceRef.current = new SyncService(
      cfg.config,
      authService,
      storageService,
      api.visits,
      api.userProfile,
      httpClientFactory,
    )
    console.info('SyncContext initialized')
  }

  const syncContext: ISyncContext = {
    syncService: serviceRef.current,
  }

  return <SyncContext.Provider value={syncContext}>{children}</SyncContext.Provider>
}
export default SyncProvider
