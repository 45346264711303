import type { IHttpClient } from '../infrastructure/http-client-factory'
import type { EntityKey, IEntity, IEntityReference } from '../model/base'
import type { InlineFaceScript } from '../model/face-script'
import type { IReportProcessor } from '../model/report-processor'
import type { ITaskResultScreen } from '../model/task-result-screen'
import type { ITaskTemplate } from '../model/task-template'
import type { ITaskTemplateModel } from '../model/task-template-model'
import type {
  IExecutionProcessSource,
  ITaskTemplatesService,
  NewVersionParams,
  TaskTemplateData,
} from './task-templates-service-api'

export class TaskTemplatesService implements ITaskTemplatesService {
  constructor(private readonly _http: IHttpClient) {}

  async getCurrentTaskTemplateVersions(): Promise<ITaskTemplateModel[]> {
    const response = await this._http.request({
      method: 'GET',
      url: `task-templates/current-versions`,
    })

    return response.data
  }

  async getTaskTemplateVersions(templateKey: EntityKey): Promise<ITaskTemplateModel[]> {
    const response = await this._http.request({
      method: 'GET',
      url: `task-templates/${templateKey}/versions`,
    })

    return response.data
  }

  async getTaskTemplate(templateKey: EntityKey): Promise<ITaskTemplate> {
    const response = await this._http.request({
      method: 'GET',
      url: `task-templates/${templateKey}`,
    })

    return response.data
  }

  async createTaskTemplate(data: TaskTemplateData): Promise<EntityKey> {
    const response = await this._http.request({
      method: 'POST',
      url: `task-templates`,
      data: data,
    })

    return response.data
  }

  async updateTaskTempate(templateKey: EntityKey, data: TaskTemplateData): Promise<void> {
    const response = await this._http.request({
      method: 'PUT',
      url: `task-templates/${templateKey}`,
      data: data,
    })

    return response.data
  }

  async copyTaskTemplate(templateKey: EntityKey): Promise<EntityKey> {
    const response = await this._http.request({
      method: 'POST',
      url: `task-templates/${templateKey}/copy`,
    })

    return response.data
  }

  async exportTaskTemplate(templateKey: EntityKey): Promise<IEntity[]> {
    const response = await this._http.request({
      method: 'GET',
      url: `task-templates/${templateKey}/export`,
    })

    return response.data
  }

  async importTaskTemplate(unit: IEntity[]): Promise<void> {
    const response = await this._http.request({
      method: 'POST',
      url: `task-templates/import`,
      data: unit,
    })

    return response.data
  }

  async createNewVersion(templateKey: EntityKey, params: NewVersionParams): Promise<EntityKey> {
    const response = await this._http.request({
      method: 'POST',
      url: `task-templates/${templateKey}/new-version`,
      data: params,
    })

    return response.data
  }

  async updateExecutionProcess(templateKey: EntityKey, data: IExecutionProcessSource): Promise<IEntityReference> {
    const response = await this._http.request({
      method: 'POST',
      url: `task-templates/${templateKey}/process`,
      data: data,
    })

    return response.data
  }

  async createExecutionSubprocess(templateKey: EntityKey, data: IExecutionProcessSource): Promise<IEntityReference> {
    const response = await this._http.request({
      method: 'POST',
      url: `task-templates/${templateKey}/subprocesses`,
      data: data,
    })

    return response.data
  }

  async updateExecutionSubprocess(
    templateKey: EntityKey,
    processKey: EntityKey,
    data: IExecutionProcessSource,
  ): Promise<void> {
    const response = await this._http.request({
      method: 'PUT',
      url: `task-templates/${templateKey}/subprocesses/${processKey}`,
      data: data,
    })

    return response.data
  }

  async updateTaskResultScreen(templateKey: EntityKey, data: ITaskResultScreen): Promise<IEntityReference> {
    const response = await this._http.request({
      method: 'POST',
      url: `task-templates/${templateKey}/result-screen`,
      data: data,
    })

    return response.data
  }

  async updateTaskResultFile(templateKey: EntityKey, data: IReportProcessor): Promise<IEntityReference> {
    const response = await this._http.request({
      method: 'POST',
      url: `task-templates/${templateKey}/result-file`,
      data: data,
    })

    return response.data
  }

  async updateTaskResultScreenBeforeScript(templateKey: EntityKey, script: InlineFaceScript): Promise<void> {
    const response = await this._http.request({
      method: 'POST',
      url: `task-templates/${templateKey}/result-screen/script`,
      data: {
        ...script,
        // FACE-4417 body is required
        body: script.body ?? '',
      },
    })

    return response.data
  }

  async updateTaskResultFileBeforeScript(templateKey: EntityKey, script: InlineFaceScript): Promise<void> {
    const response = await this._http.request({
      method: 'POST',
      url: `task-templates/${templateKey}/result-file/script`,
      data: {
        ...script,
        // FACE-4417 body is required
        body: script.body ?? '',
      },
    })

    return response.data
  }
}
