import React, { useContext, useState, useEffect, useMemo } from 'react'

import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { getAppFrameStyle } from '../../../../../assets/pm-shared-styles'
import { LogManager } from '../../../../../infrastructure/logger'
import { PageContent } from '../../../../../layout/page-content'
import { grays } from '../../../../../layout/theme'
import { TitleBar } from '../../../../../layout/title-bar'
import { ProblemStatus } from '../../../../../model/problem'
import { IProblemStatusProcessRule, IProblemTemplate } from '../../../../../model/problem-template'
import { IPhotosPropertyScreenItem, IScreenItem, IStringInputScreenItem } from '../../../../../model/screen-item'
import { ApiContext } from '../../../../../providers'
import { appToast } from '../../../../../utils'
import { useTryUpload } from '../../../../_common/hooks/useTryUpload'
import { DocumentsScreenItemContainer } from '../../../../documents/components/documents-screen-items/documents-screen-item-container'
import { ErrorPage } from '../../../../error-page'
import { useUpdateProperty } from '../../../../tasks/nested/useUpdateProperty'
import { useScriptTaskContext } from '../../../../tasks/script-tasks/script-task-context'
import { ItemCard } from '../../../../tasks/template-tasks/composite-screen/item-card'
import { StringInputScreenItem } from '../../../../tasks/template-tasks/composite-screen/string-input-screen-item'
import { ProblemContextProvider } from '.././problem-context'
import ProblemSelectDetailsScreenItem from '../items/problem-select-details-screen-item'
import ProblemValidateButton from '../items/problem-validate-button'
import { getOptionalFields, getRequiredFields } from '../utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appFrame: { ...getAppFrameStyle(theme) },
    contentWrap: {
      padding: 25,
      paddingBottom: theme.spacing(12),
      [theme.breakpoints.down('xs')]: {
        padding: '21px 18px 0',
        paddingBottom: theme.spacing(12),
      },
    },
    item: {
      marginBottom: 10,
    },
    text: {
      color: grays.gray2,
    },
  }),
)

const logger = LogManager.getLogger('problemProcessPage')

interface IState {
  problemId: string
  posCode: string
}

type ActionKind = 'View' | 'Edit'

const ProblemProcessPage: React.FC = () => {
  const MAX_PHOTO_COUNT = 5
  const classes = useStyles()
  const navigate = useNavigate()
  const api = useContext(ApiContext)
  const tryUploadPendingProblem = useTryUpload('problem')
  const { t } = useTranslation('problems')
  const onBackHandler = (): void => navigate(-1)
  const updateProperty = useUpdateProperty()
  const localContext = useScriptTaskContext()
  const { problem } = localContext
  const dictionaryItems = [
    { ruStr: 'Остается в работе', enStr: 'Processed' },
    { ruStr: 'Решена', enStr: 'Resolved' },
    { ruStr: 'Нерелевантна', enStr: 'Canceled' },
  ]
  const itemsList = useMemo(() => dictionaryItems.map((item) => item.ruStr), [])

  const [problemTemplate, setProblemTemplate] = useState<IProblemTemplate | undefined>()
  const [processStatus, setProcessStatus] = useState('')
  const [commentRequired, setCommentRequired] = useState(false)
  const [photoRequired, setPhotoRequired] = useState<ActionKind>()
  const [isValidated, setIsValidated] = useState(false)
  const [creationProcess, setCreationProcess] = useState<IProblemStatusProcessRule>()
  const [errorMessage, setErrorMessage] = useState<string>('Некорректно заполнено обязательное поле')

  const handleChangeInput = async (item: IScreenItem, value: string, oldValue: string): Promise<void> => {
    await updateProperty('problem.revisionComment', value)
  }
  const setTaskPropertyPath = async (value: unknown): Promise<void> => {
    await updateProperty('problem.revisionComment', value)
  }

  const handleChangeDictionary = (value: string): void => {
    if (value) {
      const enStr = dictionaryItems.find((item) => item.ruStr === value)!.enStr as ProblemStatus
      setProcessStatus(enStr)
    }
  }

  const calcMinPhotoAttachments = (): number => {
    return creationProcess?.photoRequired ? 1 : 0
  }

  const saveChanges = async (): Promise<void> => {
    if (!isValidated) {
      appToast.info(errorMessage)
      return
    }

    switch (processStatus) {
      case 'Processed':
        try {
          await api.problem.saveProblem(problem!)
          await tryUploadPendingProblem(problem!.code)
          onBackHandler()
        } catch (error) {
          const message = 'Ошибка при сохранении проблемы'
          logger.error('get', message, error)
          appToast.error(message)
        }
        break
      case 'Resolved':
        try {
          await api.problem.saveProblem({ ...problem!, status: 'Resolved' })
          await tryUploadPendingProblem(problem!.code)
          onBackHandler()
        } catch (error) {
          const message = 'Ошибка при сохранении проблемы'
          logger.error('get', message, error)
          appToast.error(message)
        }
        break
      case 'Canceled':
        try {
          await api.problem.saveProblem({ ...problem!, status: 'Canceled' })
          await tryUploadPendingProblem(problem!.code)
          onBackHandler()
        } catch (error) {
          const message = 'Ошибка при сохранении проблемы'
          logger.error('get', message, error)
          appToast.error(message)
        }
        break
    }
  }

  useEffect(() => {
    if (processStatus) {
      let process = {} as IProblemStatusProcessRule
      if (!problemTemplate) {
        process = {
          $type: 'PMI.BDDM.Transactionaldata.ProblemStatusProcessRule',
          commentAvailable: true,
          commentRequired: false,
          photoAvailable: true,
          photoRequired: false,
          problemStatus: processStatus,
        }
      } else {
        if (processStatus === 'Processed') {
          const resolvedProcess = problemTemplate!.statusProcessRules.find((item) => item.problemStatus === 'Resolved')
          const canceledProcess = problemTemplate!.statusProcessRules.find((item) => item.problemStatus === 'Canceled')
          process = {
            $type: 'PMI.BDDM.Transactionaldata.ProblemStatusProcessRule',
            commentAvailable: resolvedProcess!.commentAvailable && canceledProcess!.commentAvailable,
            commentRequired: false,
            photoAvailable: resolvedProcess!.photoAvailable && canceledProcess!.photoAvailable,
            photoRequired: false,
            problemStatus: 'Processed',
          }
        } else {
          process = problemTemplate!.statusProcessRules.find((item) => item.problemStatus === processStatus)!
        }
      }
      setCreationProcess(process)
    }
  }, [processStatus])

  useEffect(() => {
    if (!creationProcess) return

    const optionalFields = getOptionalFields(creationProcess)
    const requiredFields = getRequiredFields(creationProcess)

    const isOptionalAttachmentsValid =
      !problem!.revisionAttachment! ||
      problem!.revisionAttachment!.parts.length === 0 ||
      problem!.revisionAttachment!.parts.length <= MAX_PHOTO_COUNT

    const isRequiredAttachmentsValid =
      problem!.revisionAttachment! &&
      problem!.revisionAttachment!.parts.length > 0 &&
      problem!.revisionAttachment!.parts.length <= MAX_PHOTO_COUNT

    const optionalFieldsValidation = optionalFields.every((field) => {
      if (field === 'photoAvailable') {
        return isOptionalAttachmentsValid
      }
      if (field === 'commentAvailable') {
        return true
      }
      return false
    })

    const requiredFieldsValidation = requiredFields.every((field) => {
      if (field === 'commentRequired') {
        return Boolean(problem!.revisionComment)
      }
      if (field === 'photoRequired') {
        return isRequiredAttachmentsValid
      }
      return false
    })

    if (optionalFields.includes('photoAvailable') && !isOptionalAttachmentsValid) {
      setErrorMessage('Необходимо добавить от 1 до 5 фото')
    }
    if (requiredFields.includes('photoRequired') && !isRequiredAttachmentsValid) {
      setErrorMessage('Необходимо добавить от 1 до 5 фото')
    }
    if (requiredFields.includes('commentRequired') && !problem!.revisionComment) {
      setErrorMessage('Некорректно заполнено обязательное поле')
    }

    if (processStatus === 'Processed') {
      setCommentRequired(false)
      setPhotoRequired('View')
      setIsValidated(true)
      setIsValidated(optionalFieldsValidation)
      return
    }
    if (processStatus) {
      setCommentRequired(creationProcess!.commentRequired!)
      setPhotoRequired(creationProcess!.photoRequired! ? 'Edit' : 'View')
      setIsValidated(requiredFieldsValidation && optionalFieldsValidation)
    }
  }, [problem!, creationProcess])

  useAsync(async () => {
    try {
      const problemTemplates = await api.problem.getProblemTemplates()
      const problemTemplate = problemTemplates.find(
        (item) => item.escalationReason.code === problem!.escalationReason.code,
      )
      setProblemTemplate(problemTemplate)
    } catch (error) {
      const message = 'Ошибка при получении шаблона'
      logger.error('get', message, error)
      throw new Error(message)
    }
  }, [])

  const inputItem: IStringInputScreenItem = {
    $type: 'PMI.FACE.BDDM.Extensions.Classes.StringInputScreenItem',
    propertyName: 'problem.revisionComment',
    placeholder: 'Введите комментарий исполнителя',
    errorHelperText: 'Некорректно заполнено обязательное поле',
    maxLength: 1000,
    required: {
      $type: 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate',
      value: commentRequired,
    },
    displayName: 'Комментарий исполнителя',
    orderNumber: 1,
  }

  const executiveScreenItem: IPhotosPropertyScreenItem = {
    $type: 'PMI.FACE.BDDM.Extensions.Classes.PhotosPropertyScreenItem',
    propertyName: 'problem.attachment',
    displayName: 'Фото автора',
    orderNumber: 1,
    actionKind: 'View',
    editSettings: {
      minPhotoCountRequired: calcMinPhotoAttachments(),
      maxPhotoCountAvailable: MAX_PHOTO_COUNT,
    },
    viewSettings: {},
  }

  const revisionScreenItem: IPhotosPropertyScreenItem = {
    $type: 'PMI.FACE.BDDM.Extensions.Classes.PhotosPropertyScreenItem',
    propertyName: 'problem.revisionAttachment',
    displayName: 'Фото исполнителя',
    orderNumber: 1,
    actionKind: photoRequired,
    editSettings: {
      minPhotoCountRequired: calcMinPhotoAttachments(),
      maxPhotoCountAvailable: MAX_PHOTO_COUNT,
    },
    viewSettings: {},
  }

  return (
    <div className={classes.appFrame}>
      <TitleBar onBack={onBackHandler}>{t('problemHandling')}</TitleBar>

      <PageContent>
        <div className={classes.contentWrap}>
          <Box className={classes.item}>
            <ItemCard label={t('typeOfProblem')}>
              {
                <Typography className={classes.text}>
                  {
                    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                    problem?.escalationReason.name || problem?.escalationReason.code
                  }
                </Typography>
              }
            </ItemCard>
          </Box>
          {problem?.executiveComment && (
            <Box className={classes.item}>
              <ItemCard label={t('executiveComment')}>
                <Typography className={classes.text}>{problem?.executiveComment}</Typography>
              </ItemCard>
            </Box>
          )}
          {problem?.attachment && (
            <Box className={classes.item}>
              <DocumentsScreenItemContainer entity={executiveScreenItem} isReadOnly={true} />
            </Box>
          )}
          <Box className={classes.item}>
            <ProblemSelectDetailsScreenItem
              itemsList={itemsList}
              displayName={t('status')}
              isMultiSelect={false}
              isRequired={true}
              onChange={handleChangeDictionary}
            />
          </Box>

          {creationProcess?.commentAvailable && (
            <Box className={classes.item}>
              <StringInputScreenItem
                item={inputItem}
                handleChange={handleChangeInput}
                setTaskPropertyPath={setTaskPropertyPath}
              />
            </Box>
          )}

          {creationProcess?.photoAvailable && (
            <Box className={classes.item}>
              <DocumentsScreenItemContainer entity={revisionScreenItem} isReadOnly={false} />
            </Box>
          )}
        </div>

        <ProblemValidateButton handleClick={saveChanges} validationState={isValidated} caption='save' />
      </PageContent>
    </div>
  )
}

// eslint-disable-next-line react/display-name
export default (): JSX.Element => {
  const { posCode, problemId } = useParams() as unknown as IState
  const api = useContext(ApiContext)

  const problem = useAsync(async () => {
    try {
      const problemsOfPos = await api.problem.searchProblems({ posCode })
      return problemsOfPos.find((i) => i.code === problemId)!
    } catch (error) {
      const message = 'Ошибка при поиске проблемы'
      throw new Error(message)
    }
  }, [posCode, problemId])

  if (problem.error) {
    return <ErrorPage errorMessage={problem.error.message} />
  }
  if (problem.loading && !problem.value) return <></>

  return (
    <ProblemContextProvider posCode={posCode} problem={problem.value}>
      <ProblemProcessPage />
    </ProblemContextProvider>
  )
}
