import React from 'react'

import { Box, Button, Container, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { Navigate, useNavigate, useLocation } from 'react-router-dom'

import { LockedPage } from '../../layout/locked-page'
import { ISyncErrorLocationState } from './sync-location-types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 'calc(100% + 140px)',
      position: 'relative',
      padding: theme.spacing(2),
      backgroundImage: 'url("/pmi-logo-sync.png")',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },
    topPanel: {
      background: theme.palette.secondary.main,
      height: theme.spacing(4),
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
    },
    bottomPanel: {
      background: theme.palette.secondary.main,
      height: theme.spacing(4),
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
    },
    errorMsg: {
      lineHeight: '24px',
      textAlign: 'center',
      fontSize: 24,
      fontStyle: 'normal',
      letterSpacing: '0.25px',
      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
        lineHeight: '18px',
      },
    },
    errorIcon: {
      verticalAlign: 'middle',
      marginRight: 8,
      color: theme.palette.error.dark,
    },
    title: {
      lineHeight: '36px',
      textAlign: 'center',
      fontSize: 28,
      fontStyle: 'normal',
      fontWeight: 500,
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
        lineHeight: '28px',
      },
    },
    submit: {
      height: 48,
      paddingLeft: 25,
      paddingRight: 25,
      borderRadius: 28,
    },
  }),
)

export const SyncErrorPage: React.FC = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation() as { state: ISyncErrorLocationState }
  if (!location.state) {
    return <Navigate to='/' />
  }
  const { userName, title, description, version } = location.state

  return (
    <LockedPage>
      <Box display='flex' alignItems='center' justifyContent='center' height='100%'>
        <Container disableGutters fixed component='main' maxWidth='md'>
          {userName && (
            <Box mb={3}>
              <Typography className={classes.title}>
                Пользователь: <b>{userName}</b>
              </Typography>
            </Box>
          )}
          <Typography className={classes.errorMsg} align='center'>
            <span className={classes.errorIcon}>
              <InfoOutlinedIcon />
            </span>
            {title}
          </Typography>
          <Box mt={1}></Box>
          <Typography align='center'>{description}</Typography>
          <Box mt={1}>
            <Typography align='center' color='textSecondary'>
              Версия приложения: {version /*  || '0.1.17' */}
            </Typography>
          </Box>
          <Box mt={3} textAlign='center'>
            <Button className={classes.submit} color='primary' variant='contained' onClick={() => navigate('/')}>
              Закрыть
            </Button>
          </Box>
        </Container>
      </Box>
    </LockedPage>
  )
}
