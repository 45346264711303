import React, { forwardRef, useContext, useImperativeHandle, useRef } from 'react'

import { Box } from '@material-ui/core'
import { noop } from 'lodash'
import { useAsync } from 'react-use'

import { EmptyMessage } from '../../../../../components'
import { IEntityReference } from '../../../../../model/base'
import { IQuestionnaire } from '../../../../../model/questionnaire'
import { IQuestionnairePOSAssignment } from '../../../../../model/questionnaire-pos-assignment'
import { IFillSurveyScreenItem } from '../../../../../model/screen-item'
import { checkPredicate, IPredicate } from '../../../../../model/script-predicate'
import { ApiContext } from '../../../../../providers'
import { useAsyncError } from '../../../../_common/hooks/useAsyncError'
import { useCreateSurvey } from '../../../../_common/hooks/useCreateSurvey'
import ExecuteSurveyControl, { IEscRef } from '../../../../execute-survey-control/execute-survey-control'
import { useSurveyMethods } from '../../../../execute-survey-control/use-survey-methods'
import { getContextProperty } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { ScreenRef } from '../../types'
import { getQuestionnaireByAssignments } from './utils'

interface IProps {
  item: IFillSurveyScreenItem
}

export const FillSurveyScreenItem = forwardRef<ScreenRef, IProps>(function FillSurveyScreenItem({ item }, ref) {
  return (
    <VisibilityHandler item={item} defaultValue={true}>
      <FillSurveyScreenItemControl ref={ref} item={item} />
    </VisibilityHandler>
  )
})

const FillSurveyScreenItemControl = forwardRef<ScreenRef, IProps>(function FillSurveyScreenItemControl({ item }, ref) {
  const localContext = useScriptTaskContext()
  const api = useContext(ApiContext)

  const ops = useAsync(async () => {
    let questionnaireRef: IEntityReference | undefined
    if (item.questionnairePath && !item.questionnaire && !item.questionnaires?.length) {
      questionnaireRef = getContextProperty(localContext, item.questionnairePath)
      // console.log('QuestionnairePath', questionnaireRef, item)
      if (!questionnaireRef) {
        throw new Error('Не указан опросник (пустое значение по пути QuestionnairePath)')
      }
      if (!questionnaireRef.code) {
        throw new Error('Не указан опросник (некорректное значение по пути QuestionnairePath)')
      }
      const questionnaire = await api.questionnaire.getQuestionnaireByKey(questionnaireRef)
      if (!questionnaire) {
        throw new Error('Не найден подходящий опросник (по пути QuestionnairePath)')
      }
      return questionnaire
    } else {
      const questionnairePosAssignments = getContextProperty<IQuestionnairePOSAssignment[] | undefined>(
        localContext,
        'questionnairePosAssignments',
      )
      questionnaireRef = getQuestionnaireByAssignments(item, questionnairePosAssignments)
      const questionnaire = await api.questionnaire.getQuestionnaireByKey(questionnaireRef)
      if (!questionnaire) {
        throw new Error(`Не найден подходящий опросник (code = ${questionnaireRef.code})`)
      }
      return questionnaire
    }
  }, [])

  if (ops.loading) return <></>
  if (ops.error) {
    const message = ops.error instanceof Error ? ops.error.message : 'Неизвестная ошибка'
    return (
      <Box mx={-3} pt={0.5}>
        <EmptyMessage message={message} />
      </Box>
    )
  }

  const questionnaire = ops.value!

  return (
    <Box mx={-3}>
      <SimpleSurvey ref={ref} propertyName={item.propertyName} questionnaire={questionnaire} onReadyChange={noop} />
    </Box>
  )
})

export const VisibilityHandler: React.FC<{ item: { visible?: IPredicate }; defaultValue: boolean }> = ({
  item,
  defaultValue,
  children,
}) => {
  const localContext = useScriptTaskContext()

  const isVisible = checkPredicate(
    item.visible ?? { $type: 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate', value: defaultValue },
    localContext.record,
    localContext,
  )

  if (!isVisible) return <></>
  return <>{children}</>
}

interface SimpleSurveyProps {
  propertyName: string
  questionnaire: IQuestionnaire
  onReadyChange: (isReady: boolean) => void
}

export const SimpleSurvey = forwardRef<ScreenRef, SimpleSurveyProps>(function SimpleSurvey(
  { propertyName, questionnaire, onReadyChange },
  ref,
) {
  const controlRef = useRef<IEscRef>(null)

  useImperativeHandle(
    ref,
    () => ({
      validate: () => {
        controlRef.current?.focusFirstInvalidAnswer()
      },
    }),
    [],
  )

  const surveyOps = useCreateSurvey(questionnaire.code, propertyName)

  const {
    onBooleanAnswer,
    onTextAnswer,
    onRemoveAnswer,
    onSaveComment,
    onPhotosSubmit,
    onHyperlinkAnswer,
    onNumericAnswer,
    onPhotoAnswer,
    onNullAnswer,
  } = useSurveyMethods(surveyOps.value, questionnaire, surveyOps.retry)

  // useAsyncError(questionnaireOps.error)
  useAsyncError(surveyOps.error)
  if (surveyOps.loading && !surveyOps.value) return <></>

  const survey = surveyOps.value!

  return (
    <Box pt={0.5}>
      <ExecuteSurveyControl
        readOnly={false}
        ref={controlRef}
        survey={survey}
        questionnaire={questionnaire}
        onRemoveAnswer={onRemoveAnswer}
        onBooleanAnswer={onBooleanAnswer}
        onHyperlinkAnswer={onHyperlinkAnswer}
        onNumericAnswer={onNumericAnswer}
        onPhotoAnswer={onPhotoAnswer}
        onSaveComment={onSaveComment}
        onTextAnswer={onTextAnswer}
        onPhotosSubmit={onPhotosSubmit}
        onReadyChange={onReadyChange}
        onNullAnswer={onNullAnswer}
      />
    </Box>
  )
})
