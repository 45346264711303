import { IReportProcessor } from '../../../../model/report-processor'
import { IScreenItem, ISignatureImageScreenItem } from '../../../../model/screen-item'
import { IReportUserStepScreen } from '../../../../model/user-step-screen'
import { getContextProperty } from '../../script-tasks/propertyName'
import { IScriptTaskContext } from '../../script-tasks/script-task-context'
import { findAllItems } from '../composite-screen/utils'
import { IValidation } from '../composite-screen/validate'

export function validateSignatureImageItem(
  item: ISignatureImageScreenItem,
  propertiesContext: IScriptTaskContext,
): IValidation {
  if (item.editSettings?.required) {
    const value = getContextProperty(propertiesContext, item.propertyName)
    let isRejection = false

    if (item.signatureRejectionPropertyName) {
      isRejection = getContextProperty(propertiesContext, item.signatureRejectionPropertyName)
    }

    if (!value && !isRejection) {
      return {
        isError: true,
        message: item.editSettings?.errorHelperText,
      }
    }
  }

  return {
    isError: false,
  }
}

function validateReportItem(item: IScreenItem, propertiesContext: IScriptTaskContext): IValidation {
  switch (item.$type) {
    case 'PMI.FACE.BDDM.Extensions.Classes.SignatureImageScreenItem': {
      return validateSignatureImageItem(item, propertiesContext)
    }
  }
  return {
    isError: false,
  }
}

export function validateReportTaskStageScreen(
  screen: IReportUserStepScreen,
  resultFile: IReportProcessor | undefined,
  propertiesContext: IScriptTaskContext,
): { invalidItem?: IScreenItem; message?: string } {
  if (!resultFile) {
    return { invalidItem: { resultFile } as unknown as IScreenItem, message: 'Result File is missing' }
  }

  if (
    screen.actionKind === 'Edit' &&
    resultFile.$type === 'PMI.FACE.BDDM.Extensions.Classes.CompositeReportProcessor'
  ) {
    const screenItems = findAllItems(resultFile.items)
    for (const item of screenItems) {
      const validation = validateReportItem(item, propertiesContext)
      if (validation.isError) {
        return { invalidItem: item, message: validation.message }
      }
    }
  }

  return {}
}
