/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useContext } from 'react'

import { createStyles, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { getAppFrameStyle } from '../../../../assets/pm-shared-styles'
import { EmptyMessage } from '../../../../components'
import { LogManager } from '../../../../infrastructure/logger'
import { PageContent } from '../../../../layout/page-content'
import { TitleBar } from '../../../../layout/title-bar'
import { ApiContext } from '../../../../providers'
import { ErrorPage } from '../../../error-page'
import { ReportTable, SMItemInfo, SmItemShare, SmReport } from './components'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appFrame: getAppFrameStyle(theme),
    table: {
      padding: theme.spacing(0, 2, 2, 2),
    },
    tableTitle: {
      margin: theme.spacing(1, 0, 2, 0),
    },
  }),
)

const logger = LogManager.getLogger('SmItemPage')

const SmItemPage: React.FC = (props) => {
  const { t } = useTranslation('reports')
  const api = useContext(ApiContext)
  const currentRouteParams = useParams<{ id: string }>()
  const classes = useStyles()
  const navigate = useNavigate()
  const onBackHandler = useCallback((): void => navigate(-1), [])
  const smCode = currentRouteParams.id
  const itemDataOps = useAsync(async () => {
    try {
      return api.employee.getStoreManagerService(smCode!)
    } catch (error) {
      const message = 'Ошибка при загрузке отчета'
      logger.error('getStoreManagerService', message, error)
      throw new Error(message)
    }
  }, [smCode])

  if (itemDataOps.error) {
    return <ErrorPage errorMessage={itemDataOps.error.message} />
  }

  if (!itemDataOps.value) {
    if (itemDataOps.loading) {
      return null
    }
    return <EmptyMessage message='Отчет пуст' marginTop={4} />
  }
  const itemData = itemDataOps.value
  const reportData = itemData.categories

  return (
    <div className={classes.appFrame}>
      <TitleBar onBack={onBackHandler} icons={<SmItemShare link={itemData.linkToPdf} />}>
        {t('sm')}
      </TitleBar>

      <PageContent>
        <SMItemInfo
          date={itemData.endDate}
          pointOfSaleName={itemData.pointOfSaleName ?? ''}
          ownerName={itemData.ownerName ?? ''}
        />

        <SmReport reportData={reportData} />

        <div className={classes.table}>
          <Typography align='center' variant='h6' className={classes.tableTitle}>
            Итоги проверки навыков
          </Typography>
          <ReportTable
            strengths={itemData.mainStrengthsInDemo}
            recommendation={itemData.recommendationToAgency}
            feedback={itemData.feedbackFromCustomer}
          />
        </div>
      </PageContent>
    </div>
  )
}

export default SmItemPage
