import React from 'react'

import { Box, Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addButton: {
      fontSize: 16,
      lineHeight: '23px',
      fontWeight: 500,
    },
  }),
)

export interface IHeaderLabel {
  displayName?: string
  addRecordEnabled?: boolean
  onAddRecord?: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>
  buttonDisplayName?: string
}

export const HeaderLabel: React.FC<IHeaderLabel> = ({
  displayName,
  addRecordEnabled,
  onAddRecord,
  buttonDisplayName,
}) => {
  const classes = useStyles()
  if (!displayName && !addRecordEnabled) return null

  return (
    <Box display='flex' alignItems='center'>
      {displayName && <Typography variant='inherit'>{displayName}</Typography>}
      {addRecordEnabled && (
        <Box my={-1} mr={-1} ml='auto' width='fit-content'>
          <Button
            className={classes.addButton}
            variant='text'
            color='primary'
            startIcon={<AddIcon />}
            onClick={onAddRecord}
            disabled={false}
          >
            {buttonDisplayName ?? 'Добавить'}
          </Button>
        </Box>
      )}
    </Box>
  )
}
