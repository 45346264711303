import { IAnswer } from '../../model/answer'
import { checkAnswerReplyPredicate } from '../../model/answer-reply-predicate'
import {
  AnswerReplyCommentRequirements,
  IAnswerReplyRequirements,
  IAttachmentRequirements
} from '../../model/answer-reply-requirements'
import { Code } from '../../model/base'
import { FaceMimeContentRef } from '../../model/content'
import { IQuestion } from '../../model/question'
import { isNullAnswer } from './utils'

export function isCommentDisabled(commentRequirements?: AnswerReplyCommentRequirements): boolean {
  if (commentRequirements) {
    if (commentRequirements === AnswerReplyCommentRequirements.Available) {
      return false
    }
    if (commentRequirements === AnswerReplyCommentRequirements.Required) return false
  }
  return true
}

export function checkAttachmentCount(req: IAttachmentRequirements | undefined, count: number): boolean {
  if (!req) return true
  return req.minOccurs <= count && req.maxOccurs >= count
}

export function findRequiredAttachment(questions: IQuestion[], answers: IAnswer[]): IQuestion | undefined {
  for (const question of questions) {
    const answer = answers.find((answer) => answer.questionCode === question.code)!
    if (isNullAnswer(answer)) continue
    const req = findRequirementByAnswer(question.answerReplyRequirements, answer)
    const ars = req?.attachmentRequirements
    if (!ars) continue
    for (const ar of ars) {
      if (ar.attachmentType !== 'Photo') return
      const count = findPhotos(question.code, answers)?.length ?? 0
      if (!checkAttachmentCount(ar, count)) {
        return question
      }
    }
  }
}

export function findRequirementByAnswer(
  requirements: IAnswerReplyRequirements[] | undefined,
  answer?: IAnswer,
): IAnswerReplyRequirements | undefined {
  if (!requirements) return
  if (answer) {
    const reqByAnswer = requirements.find((req) => {
      if (req.replyCondition) return checkAnswerReplyPredicate(req.replyCondition, answer)
      return false
    })
    // console.log({ reqByAnswer, answer })
    if (reqByAnswer) return reqByAnswer
  }
  const defaultReq = requirements.find((req) => {
    return !req.replyCondition
  })
  // console.log({ defaultReq })
  return defaultReq
}

export const findPhotos = (questionCode: Code, answers: IAnswer[] = []): string[] => {
  const res = answers
    ?.find((answer) => answer.questionCode === questionCode)
    ?.attachment?.parts.filter(
      (part) => part.$type === 'PMI.FACE.BDDM.Extensions.Classes.FaceMimeContentRef' && part.type === 'binary/image',
    )
    .map((part) => (part as FaceMimeContentRef).code)
    .filter(function <T>(a: T): a is NonNullable<T> {
      return !!a
    })
  return res ?? []
}
