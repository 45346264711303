import React, { useContext, useReducer, useState } from 'react'

import { NavigateFunction, useNavigate } from 'react-router-dom'
import { useAsync, useError } from 'react-use'

import { ApiContext, AuthContext } from '..'
import { useAsyncError } from '../../features/_common/hooks/useAsyncError'
import { IUnauthorizedLocationState } from '../../features/sync-page/sync-location-types'
import { LogManager } from '../../infrastructure/logger'
import { UserProfileError, UserProfileErrorCode, UserProfileErrorSource } from '../../infrastructure/user-profile'
import { LockedLoadingScreen } from '../../layout/locked-loading-screen'
import { IBusinessParameters } from '../../model/business-parameters'
import { MenuItemDTO } from '../../model/menu-item'
import { IUserGroup } from '../../model/user-group'
import { IUserProfile } from '../../model/user-profile'
import { promiseAllNamed } from '../../utils'
import { APP_VERSION } from '../../version'
import { IUserContextErrorLocationState } from '../auth/types'
import { ChooseProfileForm } from './choose-profile-form'
import { ProfileContext } from './profile-context'

interface State {
  profile?: IUserProfile
  businessParameters?: IBusinessParameters
  menuItems?: MenuItemDTO[]
  userGroups?: IUserGroup[]
  profileChoices?: IUserProfile[]
}
type Action =
  | { type: 'Init'; payload: State }
  // | { type: 'SelectProfile'; payload: IUserProfile }
  | { type: 'SetProfileChoices'; payload: IUserProfile[] }
  | { type: 'RefetchGroups'; payload: IUserGroup[] }

type Step = 'profile' | 'business parameters' | 'menu items' | 'user groups'

const logger = LogManager.getLogger('ProfileProvider')

export const ProfileProvider: React.FC = ({ children }) => {
  const api = useContext(ApiContext)
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const dispatchError = useError()
  const [secondaryLoadingStep, setLoadingStep] = useState<Step | undefined>()

  const [state, dispatch] = useReducer((state: State, { type, payload }: Action) => {
    switch (type) {
      case 'Init':
        return { ...payload }
      // case 'SelectProfile':
      //   return { ...state, profile: payload }
      case 'SetProfileChoices':
        return { ...state, profileChoices: payload }
      case 'RefetchGroups':
        return { ...state, userGroups: payload }
    }
  }, {})

  async function selectProfile(profile: IUserProfile | undefined): Promise<void> {
    try {
      await api.userProfile.setCurrentProfile(profile ?? null)
      if (profile) {
        // dispatch({ type: 'SelectProfile', payload: profile })
        setLoadingStep('business parameters')
        // await delay(20000)
        await api.userProfile.fetchBusinessParameters()
        setLoadingStep('menu items')
        // await delay(1000)
        await api.userProfile.fetchMenuItems()
        setLoadingStep('user groups')
        // await delay(1000)
        await api.userProfile.fetchUserGroups()
        dispatch({ type: 'Init', payload: await getLocalData() })
      }
    } finally {
      setLoadingStep(undefined)
    }
  }

  const getLocalData = async (): Promise<State> => {
    const { profile, businessParameters, menuItems, userGroups } = await promiseAllNamed({
      profile: api.userProfile.getCurrentProfile(),
      businessParameters: api.userProfile.getBusinessParameters(),
      menuItems: api.userProfile.getMenuItems(),
      userGroups: api.userProfile.getUserGroups(),
    })
    const payload = {
      profile: profile ?? undefined,
      businessParameters,
      menuItems: menuItems ?? undefined,
      userGroups: userGroups ?? undefined,
    }
    return payload
  }

  const forceUpdateProfile = async (): Promise<IUserProfile | undefined> => {
    await selectProfile(undefined)
    const profiles = await api.userProfile.fetchAvailableProfiles()
    checkProfiles(profiles)
    if (profiles.length > 1) {
      dispatch({ type: 'SetProfileChoices', payload: profiles })
      return
    }
    if (profiles.length === 1) {
      const profile = profiles[0]
      await selectProfile(profile)
      return profile
    }
  }

  const refetchUserGroups = async (): Promise<void> => {
    try {
      await api.userProfile.fetchUserGroups()
      const res = await api.userProfile.getUserGroups()
      const userGroups = res ?? []
      dispatch({ type: 'RefetchGroups', payload: userGroups })
    } catch (e) {
      handleProfileError(e, auth.currentUserName, navigate)
    }
  }

  const ops = useAsync(async () => {
    // await delay(1000)
    const { profile, businessParameters, menuItems } = await getLocalData()
    if (profile && businessParameters && menuItems) {
      dispatch({ type: 'Init', payload: { profile, businessParameters, menuItems } })
    } else {
      try {
        await forceUpdateProfile()
        navigate('/sync-auto')
      } catch (e) {
        handleProfileError(e, auth.currentUserName, navigate)
      }
    }
  }, [])

  useAsyncError(ops.error)

  if (ops.loading || secondaryLoadingStep) {
    return <LockedLoadingScreen>Loading {secondaryLoadingStep ?? 'profile'}</LockedLoadingScreen>
  }

  const { profile, businessParameters, menuItems, userGroups, profileChoices } = state
  if (profileChoices?.length) {
    return (
      <ChooseProfileForm
        profileChoices={profileChoices}
        onSubmit={async (profile) => {
          await selectProfile(profile).catch((err) => {
            const handled = handleProfileError(err, auth.currentUserName, navigate)
            if (!handled) {
              dispatchError(err)
            }
            void api.userProfile.setCurrentProfile(null)
            // dispatch({ type: 'SetProfileChoices', payload: [] })
          })
        }}
      />
    )
  }

  return (
    <ProfileContext.Provider
      value={{
        value: profile!,
        refetch: forceUpdateProfile,
        refetchUserGroups,
        businessParameters: businessParameters!,
        menuItems: menuItems!,
        userGroups: userGroups!,
      }}
    >
      {children}
    </ProfileContext.Provider>
  )
}

function mapErrorTitle(source: UserProfileErrorSource): string {
  switch (source) {
    case UserProfileErrorSource.GetAvailableProfiles:
      return 'При получении списка профилей пользователя произошла ошибка'

    case UserProfileErrorSource.GetBusinessSettings:
      return 'При получении списка бизнес-параметров произошла ошибка'

    case UserProfileErrorSource.GetMenuItems:
      return 'При получении карты сайта произошла ошибка'

    case UserProfileErrorSource.GetUserGroups:
      return 'При получении списка групп пользователей произошла ошибка'
  }
}

function mapErrorDescription(error: unknown): string {
  if (error instanceof UserProfileError) {
    switch (error.code) {
      case UserProfileErrorCode.UserUnauthorised:
        return 'Пользователь не аутентифицирован'
      case UserProfileErrorCode.AccessDenied:
        return 'У вас отсутствует подтвержденная роль для доступа в FACE. Обратитесь к своему руководителю и сообщите о необходимости заказать роль для входа в FACE, либо закажите самостоятельно, если у вас есть доступ в IMDL.'
      case UserProfileErrorCode.BusinessError:
        const messageMap: Record<string, string> = {
          EMPLOYEE_NOT_FOUND: 'Не найден сотрудник, связанный с указанным пользователем',
          USER_HAS_MULTIPLE_EMPLOYEES: 'Найдено несколько сотрудников, связанных с указанным пользователем',
          JOB_FUNCTIONS_DUPLICATED: 'У сотрудника обнаружено несколько одинаковых JobFunction',
          FIELD_POSITION_ROLES_LIST_EMPTY:
            'У сотрудника, связанного с указанным пользователем, не найдена ни одна FieldPositionRole',
          FIELD_POSITION_ROLE_HAS_MULTIPLE_POSITIONS:
            'Несколько должностей сотрудника ссылаются на одну и ту же FieldPositionRole',
          INCORRECT_PROFILES_LIST: 'Список профилей настроен некорректно',
        }
        const title = messageMap[error.businessErrorType ?? ''] ?? error.businessErrorTitle
        if (title) return title
        if (error.businessErrorType) return `Произошла бизнес-ошибка с кодом ${error.businessErrorType}`
        return 'Произошла бизнес-ошибка'
      case UserProfileErrorCode.MaintenanceInProgress:
        return 'Сервер временно недоступен, ведутся регламентные работы'
      case UserProfileErrorCode.ServiceError:
        return `Во время выполнения метода произошла ошибка (${error.httpStatus})`
      case UserProfileErrorCode.ServiceUnavailable:
        return 'Сервис недоступен'
    }
  }
  return 'Другая ошибка'
}

class ProfileCountError extends Error {
  public tag = 'ProfileCountError'
}

export function handleProfileError(
  error: unknown,
  userName: string,
  navigate: NavigateFunction,
  doNotLogoff?: boolean,
): boolean {
  const version = APP_VERSION
  if (error instanceof UserProfileError && error.code === UserProfileErrorCode.UserUnauthorised) {
    const state: IUnauthorizedLocationState = {
      userName,
      version,
    }
    navigate('/401', { state })
    return true
  }

  if (error instanceof UserProfileError || error instanceof ProfileCountError) {
    const state: IUserContextErrorLocationState = getUserContextErrorState(userName, version, error)
    navigate('/user-context-error', { state: { ...state, doNotLogoff } })
    return true
  }
  return false
}

function checkProfiles(profiles: IUserProfile[] | undefined | null): void {
  if (!profiles?.length) {
    logger.error('checkProfiles', 'User has no available profiles')
    throw new ProfileCountError(
      'Отсутствует привязка к территории. Обратитесь к своему руководителю для привязки территории к вашей учетной записи.',
    )
  }

  // if (profiles.length > 1) {
  //   logger.error('checkProfiles', 'User has more than 1 available profile', null, profiles)
  //   throw new ProfileCountError('Для пользователя найдено несколько конкурирующих профилей')
  // }
}

function getUserContextErrorState(userName: string, version: string, error: unknown): IUserContextErrorLocationState {
  let title = 'При получении списка профилей пользователя произошла ошибка'
  let message
  if (error instanceof UserProfileError) {
    title = mapErrorTitle(error.source)
    message = mapErrorDescription(error)
  } else if (error instanceof ProfileCountError) {
    title = 'Недостаточно данных для входа'
    message = error.message
  } else {
    message = 'Другая ошибка'
  }

  return {
    title,
    message,
    version,
    userName,
  }
}
