import React from 'react'

import { Box, Container, createStyles, LinearProgress, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import { LockedPage } from '../../../layout/locked-page'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      zIndex: 10001,
      top: 0,
      left: 0,
      overflow: 'scroll',
      backgroundColor: 'white',
    },
    contentWrap: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      lineHeight: '36px',
      textAlign: 'center',
      fontSize: 28,
      fontStyle: 'normal',
      fontWeight: 500,
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
        lineHeight: '28px',
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    progressWrap: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiLinearProgress-barColorSecondary': {
        backgroundColor: theme.palette.primary.main,
      },
      '& .MuiLinearProgress-colorSecondary': {
        backgroundColor: '#ACC9E3',
      },
    },
    line: {
      width: 165,
    },
  }),
)

const PhotoLoad: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation(['common', 'photo'])
  return (
    <div className={classes.root}>
      <LockedPage>
        <Container disableGutters fixed className={classes.contentWrap} component='main' maxWidth='sm'>
          <div className={classes.content}>
            <Box marginBottom={4}>
              <Typography className={classes.title}>{t('waiting')}</Typography>
              <Typography className={classes.title}>{t('savePhoto', { ns: 'photo' })}</Typography>
            </Box>
            <div className={classes.progressWrap}>
              <LinearProgress className={classes.line} color='secondary' />
            </div>
          </div>
        </Container>
      </LockedPage>
    </div>
  )
}

export default PhotoLoad
