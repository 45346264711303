import React from 'react'

import { createStyles, FormControl, makeStyles, MenuItem, Select, Theme } from '@material-ui/core'
import classNames from 'classnames'

import { grays } from '../../layout/theme'

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    selectForm: {
      width: '100%',
      height: 40,
      marginRight: theme.spacing(3),
      backgroundColor: 'white',
      borderRadius: 40,
      overflow: 'hidden',
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
        marginBottom: 0,
        flex: '1 1 auto',
      },
      '& :before': {
        display: 'none',
      },
      '& :after': {
        display: 'none',
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
    selectInput: {
      padding: 0,
      height: '100%',
      backgroundColor: 'white',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.15px',
      [theme.breakpoints.down('xs')]: {
        padding: '2px 0px 1px 0px',
      },
      '& .MuiSelect-select.MuiSelect-select': {
        padding: '4px 16px 3px 16px',
        backgroundColor: 'white',
      },
      '&.MuiSelect-selectMenu': {
        whiteSpace: 'nowrap',
      },
      '&:focus': {
        backgroundColor: 'inherit',
      },
      '& .MuiSelect-icon': {
        marginRight: 15,
        paddingBottom: 2,
        [theme.breakpoints.down('xs')]: {
          marginRight: 14,
        },
      },
    },
    isPlaceholder: {
      '& .MuiSelect-select': {
        color: grays.gray3,
      },
    },
    menuPopup: {
      marginTop: 6,
    },
  }),
)

interface ISelectInput {
  query: string | undefined
  onChange: (queryString: string) => void
  placeholder?: string
  list: string[]
}

export const SelectInput: React.FC<ISelectInput> = ({ query, onChange, placeholder = 'Выбрать', list }) => {
  const classes = useStyles()

  return (
    <FormControl
      className={classNames(classes.selectForm, {
        [classes.isPlaceholder]: !query,
      })}
    >
      <Select
        displayEmpty
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          classes: { paper: classes.menuPopup },
        }}
        className={classes.selectInput}
        onChange={(evt) => {
          onChange(evt.target.value as string)
        }}
        renderValue={(val) => {
          // if (typeof val === 'string') {
          //   return val
          // }

          if (query) {
            return query
          }
          return placeholder
        }}
      >
        {list.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
