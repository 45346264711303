/* eslint-disable react/no-children-prop */
import React, { useEffect } from 'react'

import { Route, Routes } from 'react-router-dom'

import { Layout } from '../../../layout'
import { StoreMenuPage } from '../../stores/store-menu-page/store-menu-page'
import { TaskPage } from '../../tasks/nested/task-page'
import { VisitTaskPage } from '../../tasks/nested/visit-task-page'
import { NonVisitTaskCreatePage } from '../../tasks/nonVisitTask/nonVisitTaskCreatePage'
import TaskRemovePage from '../../tasks/task-remove-page'
import { PreviewPage } from '../../tasks/template-tasks/preview-page'
import VisitCreatePage from '../visit-create-page/visit-create-page'
import VisitEditPage from '../visit-edit-page/visit-edit-page'
import { VisitTaskCreatePage } from '../visit-tasks'
import VisitViewPage from '../visit-view-page/visit-view-page'
import ActivitiesPage from '../visits-page/activities-page'

interface VisitsAppFeatureProps {
  children?: never
}

export const VisitsAppFeature: React.FC<VisitsAppFeatureProps> = (props) => {
  useEffect(() => {
    console.log('VisitsAppFeature mount', props, relativeRoutes)
  }, [])

  return (
    <Routes>
      <Route path={relativeRoutes.root} element={<Layout children={<ActivitiesPage />} />} />
      <Route path={relativeRoutes.visitAdd} element={<VisitCreatePage />} />
      <Route path={relativeRoutes.visitView} element={<Layout children={<VisitViewPage />} />} />
      <Route path={relativeRoutes.visitEdit} element={<VisitEditPage />} />
      <Route path={relativeRoutes.visitTaskAdd} element={<VisitTaskCreatePage />} />
      <Route path={relativeRoutes.visitTaskPreview} element={<PreviewPage />} />
      <Route path={relativeRoutes.visitTaskRemove} element={<TaskRemovePage />} />
      <Route path={relativeRoutes.visitTaskPage} element={<VisitTaskPage />} />
      <Route path={relativeRoutes.nonVisitTaskAdd} element={<NonVisitTaskCreatePage />} />
      <Route path={relativeRoutes.nonVisitTaskRemove} element={<TaskRemovePage />} />
      <Route path={relativeRoutes.nonVisitTaskPreview} element={<PreviewPage />} />
      <Route path={relativeRoutes.nonVisitTaskPage} element={<TaskPage />} />
      <Route path={relativeRoutes.store} element={<Layout children={<StoreMenuPage />} />} />
    </Routes>
  )
}

export const visitsRoutes = {
  root: '/:featureRoute',
  visitAdd: '/:featureRoute/add',
  visitView: '/:featureRoute/:id/view',
  visitEdit: '/:featureRoute/:id/edit',
  visitTaskAdd: '/:featureRoute/:id/task/add',
  visitTaskPreview: '/:featureRoute/:visitCode/task/:taskCode/preview',
  visitTaskRemove: '/:featureRoute/:visitCode/task/:code/remove',
  visitTaskPage: '/:featureRoute/:visitCode/task/:taskCode/*',
  nonVisitTaskAdd: '/:featureRoute/nonVisitTask/add',
  nonVisitTaskRemove: '/:featureRoute/nonVisitTask/:code/remove',
  nonVisitTaskPreview: '/:featureRoute/nonVisitTask/:taskCode/preview',
  nonVisitTaskPage: '/:featureRoute/nonVisitTask/:taskCode/*',
  store: '/:featureRoute/:visitCode/store/:posCode/menu/*'
} as const

const relativeRoutes = Object.fromEntries(
  Object.entries(visitsRoutes).map(([key, value]) => [key, value.replace(/^\/:featureRoute/, '')]),
) as typeof visitsRoutes
