import React, { useContext, useEffect } from 'react'

import { useLatest } from 'react-use'

import { LogManager } from '../../../../../infrastructure/logger'
import { generateEntityCode } from '../../../../../model/base'
import { IFaceScript } from '../../../../../model/face-script'
import { ITableScreenItem } from '../../../../../model/table-screen-item'
import { ModalContext } from '../../../../../providers/modal'
import { appToast } from '../../../../../utils'
import { useIsSmall } from '../../../../_common/hooks/useIsSmall'
import { useUpdateProperty } from '../../../nested/useUpdateProperty'
import { getContextProperty } from '../../../script-tasks/propertyName'
import { runSyncScript } from '../../../script-tasks/run-sync-script'
import { IScriptTaskContext, useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { ItemCard } from '../item-card'
import { ITableRecord } from './editorUtils'
import { HeaderLabel } from './header-label'
import { SubprocessAddItemToListControl } from './subprocess-add-item-to-list-control'
import { TableItemLandscape } from './table-item-landscape'
import { TableItemPortrait } from './table-item-portrait'
import { filterTableRows } from './utils'
import { validateTableScreenItem } from './validate-table'
import { CardHeaderLabel } from './variants/card-header-label'
import { CardVariantLandscape } from './variants/card-variant-landscape'
import { CardVariantPortrait } from './variants/card-variant-portrait'
import { CardVariantWrap } from './variants/card-variant-wrap'

interface Props {
  item: ITableScreenItem
  isReadOnly: boolean
}

type ITableItemContext = ITableScreenItem
export const TableItemContext = React.createContext({} as ITableItemContext)

const logger = LogManager.getLogger('AfterRecordAddedEventHandler')

interface IRecordEventHandlerContext {
  propertiesContext: IScriptTaskContext
  record: ITableRecord
}
// eslint-disable-next-line no-eval
const AsyncFunction: FunctionConstructor = eval('Object.getPrototypeOf(async function () {}).constructor')
async function handleScriptEvent(script: IFaceScript, context: IRecordEventHandlerContext): Promise<void> {
  const params = {
    ...context.propertiesContext,
    generateEntityCode,
    record: context.record,
    window: 'window',
    self: 'self',
    globalThis: 'globalThis',
  }

  const fn = new AsyncFunction(...Object.keys(params), script.body)
  const res = await fn.call('this', ...Object.values(params))
  console.log('script event result', context.record, res)
  return res
}

const handleAfterRecordAddedEvent = async (
  item: ITableScreenItem,
  record: ITableRecord,
  propertiesContext: IScriptTaskContext,
): Promise<void> => {
  const { template } = propertiesContext
  try {
    if (item.afterRecordAddedEvent?.$type !== 'PMI.FACE.BDDM.Extensions.Classes.ScriptEventHandler') return
    const script = template.scripts[item.afterRecordAddedEvent?.script.code]
    if (!script) throw new Error('no script')
    await handleScriptEvent(script, { propertiesContext, record })
  } catch (error) {
    logger.critical('handler', error.message, error, item)
    appToast.error('Во время выполнения инициализирующего скрипта произошла ошибка, создание записи невозможно')
    throw error
  }
}

export const TableItem: React.FC<Props> = ({ item, isReadOnly }) => {
  const propertiesContext = useScriptTaskContext()
  const contextRef = useLatest(propertiesContext)
  const updateProperty = useUpdateProperty()
  const modalContext = useContext(ModalContext)

  useEffect(() => {
    console.log('TableItem mounted', item)
  }, [])
  const isSmall = useIsSmall()

  const addRecordHandler = async (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    evt.stopPropagation()
    const newRecord = {} as ITableRecord

    async function saveNewRecord(newRecord: unknown): Promise<void> {
      const context = contextRef.current
      const records: ITableRecord[] = getContextProperty(context, item.propertyName, [])
      await updateProperty(item.propertyName, [...records, newRecord])
    }
    if (item.addRecordAction) {
      console.log('got addRecordAction', item.addRecordAction)
      switch (item.addRecordAction.$type) {
        case 'PMI.FACE.BDDM.Extensions.Classes.SubprocessAddItemToListAction': {
          const getBack = async (): Promise<void> => {
            modalContext.close()
          }
          return modalContext.open(
            <SubprocessAddItemToListControl
              context={{
                ...propertiesContext,
                [item.addRecordAction.elementVariable ?? 'newItem']: newRecord,
              }}
              onClose={getBack}
              action={item.addRecordAction}
              onSubmit={async (record) => {
                await saveNewRecord(record)
                void getBack()
              }}
            />,
          )
        }
        case 'PMI.FACE.BDDM.Extensions.Classes.InlineScriptAddItemToListAction': {
          const context = {
            ...propertiesContext,
            [item.addRecordAction.argumentName ?? 'newItem']: newRecord,
          }
          // TODO: add full async support
          const res = runSyncScript(item.addRecordAction.body, context)
          return saveNewRecord(res || newRecord)
        }
      }
    } else if (item.afterRecordAddedEvent) {
      await handleAfterRecordAddedEvent(item, newRecord, propertiesContext)
    }
    await saveNewRecord(newRecord)
  }

  const renderLabel = (): JSX.Element | undefined => {
    if (!item.displayName && !item.addRecordEnabled) return

    if (item.tableStyle?.$type === 'PMI.FACE.BDDM.Extensions.Classes.CardsTableStyle') {
      return (
        <CardHeaderLabel
          addRecordEnabled={item.addRecordEnabled}
          displayName={item.displayName}
          buttonDisplayName={item.addRecordButtonDisplayName}
          onAddRecord={addRecordHandler}
          isCollapsible={item.collapsible}
        />
      )
    }

    return (
      <HeaderLabel
        addRecordEnabled={item.addRecordEnabled}
        displayName={item.displayName}
        buttonDisplayName={item.addRecordButtonDisplayName}
        onAddRecord={addRecordHandler}
      />
    )
  }

  const validation = validateTableScreenItem(item, propertiesContext)
  const records: ITableRecord[] = getContextProperty(propertiesContext, item.propertyName, [])
  const filteredRecords = filterTableRows(records, item, propertiesContext)

  if (!filteredRecords?.length) {
    if (item.hideEmpty) {
      return <></>
    }
  }

  if (item.tableStyle?.$type === 'PMI.FACE.BDDM.Extensions.Classes.CardsTableStyle') {
    if (isSmall) {
      return (
        <CardVariantWrap label={renderLabel()} isCollapsible={item.collapsible}>
          <CardVariantPortrait item={item} />
        </CardVariantWrap>
      )
    }
    return (
      <CardVariantWrap label={renderLabel()} isCollapsible={item.collapsible}>
        <CardVariantLandscape item={item} />
      </CardVariantWrap>
    )
  }

  if (isSmall) {
    return (
      <ItemCard
        label={renderLabel()}
        isError={isReadOnly ? false : validation.isError}
        isCollapsible={item.collapsible}
      >
        <TableItemPortrait item={item} isReadOnly={isReadOnly} />
      </ItemCard>
    )
  }

  return (
    <ItemCard label={renderLabel()} isError={isReadOnly ? false : validation.isError} isCollapsible={item.collapsible}>
      <TableItemLandscape item={item} isReadOnly={isReadOnly} />
    </ItemCard>
  )
}
