import greyPalette from '@material-ui/core/colors/grey'
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const breakpoints = createBreakpoints({})

export const TEXT_BLACK = '#333333'
export const grays = {
  gray1: '#546066',
  gray2: '#858F97',
  gray3: '#A7AEB9',
  /** Canceled state */
  gray4: '#C7CDD7',
  gray5: '#E6E9EF',
} as const

const PRIMARY = '#3077B8'

const PM_LIGHT_THEME = createMuiTheme({
  palette: {
    type: 'light',
    common: {
      black: TEXT_BLACK,
      white: '#fff',
    },
    background: {
      paper: '#fff',
      default: '#F4F4F4',
    },
    primary: {
      main: PRIMARY,
      contrastText: '#fff',
    },
    secondary: {
      main: '#BBDEFB',
      contrastText: TEXT_BLACK,
    },
    error: {
      main: '#D32F2F',
      //   light: '#e57373',
      //   dark: '#B80718',
      contrastText: '#fff',
    },
    warning: {
      main: '#FFA827',
      contrastText: '#fff',
    },
    info: {
      main: '#55C2FF',
      contrastText: '#fff',
    },
    success: {
      main: '#8AC858',
      contrastText: '#fff',
    },
    grey: { ...greyPalette, 600: '#C7CDD7' },
    action: {
      selected: 'rgba(48, 119, 184, 0.05)',
    },
    text: {
      primary: TEXT_BLACK,
      secondary: grays.gray2,
      disabled: 'rgba(193,112,112,0.38)',
      hint: 'rgba(73,63,63,0.38)',
    },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: "'Lato', 'Helvetica', 'Arial', sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: 28,
      lineHeight: '36px',
      fontWeight: 500,
    },
    h2: {
      fontSize: 22,
      lineHeight: '28px',
      fontWeight: 500,
    },
    h3: {
      fontSize: 22,
      lineHeight: '28px',
      fontWeight: 400,
    },
    h4: {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 500,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          color: TEXT_BLACK,
        },
      },
    },
    MuiButton: {
      root: {
        // minWidth: 18,
        textTransform: 'unset',
      },
      contained: {
        '&:hover': {
          backgroundColor: 'unset',
        },
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: PRIMARY,
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-disabled': {
          color: 'rgba(0, 0, 0, 0.38)',
        },
      },
    },
    MuiInputBase: {
      root: {
        '&.Mui-disabled': {
          color: 'rgba(0, 0, 0, 0.38)',
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        paddingTop: '14.5px',
        paddingBottom: '14.5px',
      },
      multiline: {
        paddingTop: '14.5px',
        paddingBottom: '14.5px',
      },
    },
    MuiAppBar: {
      root: {
        minHeight: 56,
        '& .MuiToolbar-root': {
          minHeight: 56,
        },
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'unset',
        },
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: 'unset',
        },
      },
    },
    MuiButtonBase: {
      root: {
        '&:hover': {
          backgroundColor: 'unset',
        },
        '&.MuiButton-contained.Mui-disabled': {
          color: '#fff',
        },
        '& .MuiButton-label': {
          // color: '#fff'
        },
      },
    },
    // float action button
    MuiFab: {
      root: {
        '&.MuiFab-primary:hover': {
          backgroundColor: PRIMARY,
        },
        zIndex: 9999,
        textTransform: 'unset',
        '& .MuiFab-label': {
          color: '#fff',
        },
        '&.Mui-disabled': {
          display: 'none',
        },
        '&.MuiFab-extended': {
          height: 56,
          borderRadius: 16,
        },
      },
    },
    // Dialog (modal)
    MuiDialog: {
      root: {
        '& .MuiPaper-rounded': {
          borderRadius: 12,
        },
        '& .MuiDialog-paperFullScreen': {
          borderRadius: 0,
        },
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.15)',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        paddingTop: 24,
        paddingBottom: 18,
        fontSize: 24,
        lineHeight: '32px',
        letterSpacing: '0.4px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '18px 24px 24px',
        '& .MuiButton-label': {
          fontWeight: 500,
          letterSpacing: 0.1,
        },
        '& .MuiButton-root': {
          minWidth: 18,
        },
      },
    },
    MuiDialogContent: {
      root: {
        '&:last-child': {
          paddingBottom: 32,
        },
      },
    },
    MuiDialogContentText: {
      root: {
        marginBottom: 0,
      },
    },
    // Accordion
    MuiAccordion: {
      root: {
        marginBottom: 8,
        // [theme.breakpoints.down('xs')]: {
        //   marginBottom: 4
        // },
        '&.Mui-expanded, &.Mui-expanded:last-child': {
          marginTop: 0,
          marginBottom: 8,
          // [theme.breakpoints.down('xs')]: {
          //   marginBottom: 4
          // }
        },
        '&::before': {
          content: 'none',
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        paddingTop: '4px',
        minHeight: '56px !important',
        '&.Mui-expanded': {
          minHeight: 'unset',
        },
        '&:not(.Mui-expanded)': {
          marginBottom: -1,
        },
        [breakpoints.down('xs')]: {
          minHeight: '52px !important',
        },
      },
      content: {
        margin: '0 !important',
        alignItems: 'center',
      },
    },
  },
})

// // font-size, line-height, weight
// const variants = [
//   28, 36, 500,
//   16, 24, 500,
//   14, 24, 400,
//   14, 20, 500,
//   14, 20, 700,
//   12, 20, 400,
//   11, 16, 400,
//   12, 16, 500,
//   15, 26, 500,
//   22, 28, 500

// ]

console.log({ PM_LIGHT_THEME })
export default PM_LIGHT_THEME
