import React, { useCallback, useEffect, useRef } from 'react'

import { createStyles, Theme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useVirtualizer, VirtualItem } from '@tanstack/react-virtual'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { EmptyMessage } from '../../../components'
import { grays } from '../../../layout/theme'
import { IPointOfSale } from '../../../model/pos'
import { AppRoutes as route } from '../../../routes'
import { getPosAddress } from '../../_common/pos'
import { getRegisteredNameFromPos } from '../store-menu-page/utils'
import StoresListItem from '../stores-list-item/stores-list-item'
import UseGetPos from './use-get-pos'

export type SelectedItemId = string | null

interface IStoresListProps {
  selectable?: boolean
  menuAccess?: boolean
  onSelect?: (selectedId: SelectedItemId) => void
  selectedId?: SelectedItemId
  searchQuery?: string
  currentPosCode?: string
  isButtonNeeded?: boolean
  getScrollOffset?: (val: unknown) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrap: {
      paddingTop: 7,
      paddingBottom: 7,
      backgroundColor: '#ffffff',
      // height: 'calc(100vh - 88px - 40px - 48px - 14px)',
      height: '100%',
      overflowY: 'auto',
    },

    listWrapper: {
      flexGrow: 1,
      height: 0,
      border: `1px solid ${grays.gray5}`,
      marginTop: 24,
      // paddingLeft: 25,
      // paddingRight: 25,
      backgroundColor: '#ffffff',
    },
  }),
)

interface LocationState {
  scrollOffset?: number
}

const StoresList: React.FC<IStoresListProps> = (props) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { state } = useLocation() as { state: LocationState }
  const posListData = UseGetPos(props.searchQuery)

  const posList = posListData.value ?? []
  const containerRef = useRef<HTMLDivElement>(null)
  const restoringScroll = useRef(!!state?.scrollOffset)
  const rowVirtualizer = useVirtualizer({
    count: posList.length,
    getScrollElement: () => containerRef.current,
    estimateSize: () => 122,
    overscan: 15,
    initialOffset: state?.scrollOffset,
    paddingStart: 1,
    paddingEnd: props.isButtonNeeded ? 96 : 1,
    enableSmoothScroll: false,
  })
  useEffect(() => {
    if (rowVirtualizer.scrollElement && restoringScroll.current) {
      rowVirtualizer.scrollToOffset(state!.scrollOffset!)
      restoringScroll.current = false
    }
  }, [rowVirtualizer.scrollElement])

  const { t } = useTranslation('stores')

  const handleItemClick = useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      const posCode = event.currentTarget.dataset.code!
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const scrollOffset = (rowVirtualizer as any).scrollOffset
      if (props.selectable && props.onSelect) {
        const newSelectedId = props.selectedId === posCode ? null : posCode
        props.onSelect(newSelectedId)
        props.getScrollOffset?.(scrollOffset)
      } else {
        if (props.menuAccess) {
          console.log('leaving page', scrollOffset)
          navigate(`/${route.stores.path}/store/${posCode}/menu`, {
            state: {
              prevPath: location.pathname,
              scrollOffset,
              rootPrevPath: location.pathname,
            },
          })
        }
      }
    },
    [props.selectable, props.menuAccess],
  )

  if (posListData.loading) {
    return <EmptyMessage message={'Загрузка'} marginTop={10} marginBottom={10} />
  }

  if (!posListData.value?.length) {
    const msg = props.searchQuery ? t('emptySearchMessage') : t('emptyMessage')
    return <EmptyMessage message={msg} marginTop={10} marginBottom={10} />
  }

  const renderListItem = (virtualItem: VirtualItem<HTMLDivElement>): JSX.Element => {
    const item = posList[virtualItem.index]

    const registeredName = item && getRegisteredNameFromPos(item)
    return (
      <div
        key={virtualItem.key}
        data-code={item.code}
        data-index={virtualItem.index}
        ref={virtualItem.measureElement}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          // height: `${virtualItem.size}px`,
          transform: `translateY(${virtualItem.start}px)`,
        }}
        onClick={handleItemClick}
      >
        <StoresListItem
          selected={props.selectedId === item.code}
          code={item.code}
          name={item.name}
          address={getPosAddress(item)}
          menuAccess={props.menuAccess}
          registerName={registeredName}
        />
      </div>
    )
  }

  const renderList = (): JSX.Element => {
    const filteredAndSortedList = posListData.value?.filter((x) => x.code !== props?.currentPosCode).sort(sortByName)
    if (props.searchQuery?.length && !filteredAndSortedList?.length) {
      return (
        <Box mt={3} width='100%'>
          <Typography align='center'>{t('emptySearchMessage')}</Typography>
        </Box>
      )
    }
    return <>{rowVirtualizer.getVirtualItems().map(renderListItem)}</>
  }

  return (
    <div className={classes.listWrapper}>
      <div className={classes.wrap} ref={containerRef}>
        <div
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {renderList()}
        </div>
      </div>
    </div>
  )
}

export default StoresList

function sortByName(a: IPointOfSale, b: IPointOfSale): number {
  if (!a.name || !b.name) {
    return -1
  }
  return a.name.localeCompare(b.name)
}
