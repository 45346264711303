import React, { useContext } from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core'

import ResetButton from './components/reset-button'
import UserButton from './components/user-button'
import { YandexMapContext } from './yandex-map-provider'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mapButtons: {
      position: 'absolute',
      zIndex: 9999,
      top: '24px',
      right: '24px',
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridRowGap: 10,
      transform: 'translate3d(0px, 0px, 1px)',
    },
  }),
)

export const MapMenu: React.FC = ({ children }) => {
  const classes = useStyles()
  const { setCenterUser, setBounds } = useContext(YandexMapContext)

  return (
    <div className={classes.mapButtons}>
      <UserButton onClick={setCenterUser} />
      <ResetButton onClick={() => setBounds()} />
      {children}
    </div>
  )
}
