import React from 'react'

import {
  Avatar,
  Box,
  createStyles,
  Grid,
  Paper,
  SvgIcon,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { generatePath, Link } from 'react-router-dom'

import { ReactComponent as ChevronRight } from '../../../../../assets/icons/ChevronRight.svg'
import theme, { grays } from '../../../../../layout/theme'
import { Code } from '../../../../../model/base'
import { DateTime } from '../../../../../model/common'
import { dateFormat } from '../../../../../utils'
import { useFeatureRoute } from '../../../../custom-app-feature/app-feature-context'
import { reportsRoutes } from '../../../reports-app-feature/reports-app-feature'

interface IIsaReportListItemProps {
  code: Code
  name: string
  address: string
  posCode: string
  date: DateTime
}

const useStyles = makeStyles<Theme, IIsaReportListItemProps>((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      cursor: 'pointer',
      display: 'flex',
      minWidth: 320,
      alignItems: 'center',
      padding: theme.spacing(2, 3),
      marginBottom: theme.spacing(1),
      minHeight: theme.spacing(9),
      borderRadius: 12,
      boxShadow: '0px 3px 7px rgba(96, 97, 112, 0.13)',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
      },
    },
    gridContainer: {
      minHeight: 88,
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
      },
      [theme.breakpoints.up('xs')]: {
        paddingLeft: theme.spacing(1),
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(2),
      },
    },
    date: {
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '0.1px',
      fontWeight: 500,
    },
    arrowIconWrap: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
      [theme.breakpoints.up('sm')]: {
        padding: '0 !important',
        textAlign: 'end',
      },
    },
    code: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '22px',
      letterSpacing: '0.25px',
      color: grays.gray2,
      // marginTop: theme.spacing(1),
      // [theme.breakpoints.up('xs')]: {
      //   paddingLeft: 50
      // }
    },
    address: {
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '0.1px',
      fontWeight: 500,
      [theme.breakpoints.up('xs')]: {
        // paddingLeft: 50,
        maxWidth: 260,
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: 320,
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: 400,
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 500,
      },
    },
    name: {
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '0.1px',
      fontWeight: 500,
      [theme.breakpoints.up('xs')]: {
        maxWidth: 190,
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: 170,
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: 300,
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 400,
      },
    },
    selectIcon: {
      width: 36,
      height: 36,
      padding: 0,
      marginLeft: 'auto',
    },
  }),
)

const IsaReportListItem: React.FC<IIsaReportListItemProps> = (props) => {
  const classes = useStyles(props)
  const isMobile = useMediaQuery<Theme>(theme.breakpoints.down('xs'))
  const featureRoute = useFeatureRoute()

  const pathname = generatePath(reportsRoutes.isaResultsPage, { featureRoute, id: props.code })
  return (
    <Link to={pathname}>
      <Paper elevation={0} style={{ padding: 8 }} square className={classes.root}>
        <Grid
          // container
          // spacing={3}
          xs={12}
          className={classes.gridContainer}
          // alignItems='center'
          // justify='space-between'
          container
          alignItems='center'
          wrap='nowrap'
          spacing={isMobile ? 2 : 5}
        >
          <Grid item>
            <Typography variant='h4' className={classes.date}>
              {dateFormat(props.date, 'dd.MM')}
            </Typography>
          </Grid>
          {!isMobile ? (
            <Grid item>
              <Box position='relative'>
                <Box
                  borderLeft='2px solid currentColor'
                  color='#E6E9EF'
                  height='48px'
                  top='-24px'
                  position='absolute'
                ></Box>
              </Box>
            </Grid>
          ) : (
            <></>
          )}
          <Grid item md={5} sm={5} xs={5}>
            <Box display='flex' alignItems='center'>
              <Avatar alt={props.name} src='/static/images/avatar/1.jpg' />
              <Box ml={1}>
                <Typography variant='h6' component='h6' className={classes.name} noWrap>
                  {props.name}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={4} sm={4} xs={10} direction='column'>
            <Tooltip title={props.address}>
              <Typography noWrap className={classes.address}>
                {props.address}
              </Typography>
            </Tooltip>
            <Typography className={classes.code}>{props.posCode}</Typography>
          </Grid>
          <SvgIcon
            className={classes.selectIcon}
            component={ChevronRight}
            viewBox='0 0 36 36'
            color='primary'
            fontSize='large'
          />
        </Grid>
      </Paper>
    </Link>
  )
}

export default IsaReportListItem
