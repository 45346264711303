import { DateTime } from '../../model/common'
import { BusinessError } from '../../model/errors'

export enum DumpErrorCode {
  Internal,
  UserUnauthorised,
  AccessDenied,
  BusinessError,
  MaintenanceInProgressError,
  ServiceUnavailableError,
  ServiceError,
  UpgradeRequired,
}

export class DumpError extends BusinessError<DumpErrorCode> {
  readonly httpStatus?: number
  readonly businessErrorType?: string
  readonly businessErrorTitle?: string

  constructor(
    code: DumpErrorCode,
    message: string,
    httpStatus?: number,
    details?: unknown,
    businessErrorType?: string,
    businessErrorTitle?: string
  ) {
    super(code, message, details)
    this.name = 'DumpError'
    this.httpStatus = httpStatus
    this.businessErrorType = businessErrorType
    this.businessErrorTitle = businessErrorTitle
  }
}

export interface IndexedDbDumpInfo {
  /**
   * Наименование базы данных, с которой снят бэкап
   */
  databaseName?: string

  /**
   * Версия IndexedDB
   */
  indexedDbVersion?: number

  /**
   * Дата и время снятия бэкапа на устройстве
   */
  backupDateTime?: DateTime
}

export interface IDbDump {
  data: Record<string, unknown>,
  info: IndexedDbDumpInfo
}

export interface IDumpService {
  /**
   * Загружает на бэкенд бэкап IndexedDB
   * @param {object} dump - бэкап IndexedDB (plain object)
   */
  uploadDump: (dump: IDbDump) => Promise<void>

  dumpAll: () => Promise<IDbDump[]>

  dumpDB: (dbName: string) => Promise<IDbDump | undefined>

  dumpData: (data: Record<string, unknown>) => Promise<IDbDump>
}