import React, { useContext, useMemo, useState } from 'react'

import {
  Container,
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  createStyles
} from '@material-ui/core'
import { sortBy } from 'lodash'

import { ValidationFab } from '../../components/validation-fab'
import { ItemCard } from '../../features/tasks/template-tasks/composite-screen/item-card'
import { grays } from '../../layout/theme'
import { Code } from '../../model/base'
import { IUserProfile } from '../../model/user-profile'
//import { formatFio } from '../../utils/fio'
import { AuthContext } from '../auth'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      overflowY: 'scroll',
      height: '100%',
      paddingBottom: theme.spacing(14),
    },
    info: {
      ...theme.typography.h1,
      fontWeight: theme.typography.fontWeightRegular,
    },
    label: {
      color: grays.gray3,
    },
    login: {
      color: grays.gray1,
    },
    profileChoice: {
      justifyContent: 'space-between',
    },
  }),
)

interface Props {
  profileChoices: IUserProfile[]
  onSubmit: (profile: IUserProfile) => void
}

export const ChooseProfileForm: React.FC<Props> = ({ profileChoices, onSubmit }) => {
  const [formValue, setFormValue] = useState<Code>()
  const auth = useContext(AuthContext)
  const classes = useStyles()

  const choices = useMemo(() => {
    return sortBy(profileChoices, 'profile.name')
  }, [profileChoices])

  const duplicatedProfiles = useMemo(() => {
    const profileIndexes: Record<Code, number> = {}
    const res = new Set<string>()
    for (let i = 0; i < profileChoices.length; i++) {
      const context = profileChoices[i]
      const foundIndex = profileIndexes[context.profile.code]
      if (foundIndex !== undefined && foundIndex !== i) {
        res.add(context.profile.code)
      } else {
        profileIndexes[context.profile.code] = i
      }
    }
    return res
  }, [profileChoices])

  return (
    <div className={classes.container}>
      <Container maxWidth='sm'>
        <Box className={classes.info} marginTop={11} marginBottom={3} textAlign='center'>
          {/*<Typography variant='inherit'>*/}
          {/*  <span className={classes.label}>Пользователь: </span>*/}
          {/*  {formatFio(employee.firstName, employee.surname, employee.middleName)}*/}
          {/*</Typography>*/}
          <Box mt={2} />
          <Typography variant='inherit'>
            <span className={classes.label}>Логин: </span>
            <span className={classes.login}>{auth.currentUserName}</span>
          </Typography>
          <Box mt={4} />
          <Typography variant='h3'>Пользователю соответствует несколько профилей</Typography>
        </Box>
        <ItemCard label={<Typography variant='h3'>Выберите профиль</Typography>}>
          <RadioGroup onChange={(_ev, val) => setFormValue(val)} value={formValue ?? ''}>
            {choices.map((profile) => {
              const code = makeCode(profile)
              return (
                <FormControlLabel
                  control={<Radio color='primary' />}
                  className={classes.profileChoice}
                  labelPlacement='start'
                  label={
                    profile.profile.name +
                    (duplicatedProfiles.has(profile.profile.code) ? ` (${profile.contextDisplayName})` : '')
                  }
                  value={code}
                  key={code}
                />
              )
            })}
          </RadioGroup>
        </ItemCard>
        <ValidationFab
          isValid={!!formValue}
          onClick={async (isValid) => {
            if (!isValid) return
            const profile = profileChoices.find((profile) => {
              const codes = parseCode(formValue ?? '')
              return (
                (profile.fieldPositionRole?.code ?? '') === codes.fieldPositionRole &&
                (profile.position?.code ?? '') === codes.position &&
                profile.profile.code === codes.profile &&
                (profile.participantProfile?.code ?? '') === codes.participantProfile
              )
            })
            if (!profile) return
            onSubmit(profile)
          }}
        >
          Выбрать
        </ValidationFab>
      </Container>
    </div>
  )
}

function makeCode(context: IUserProfile): string {
  return [
    context.fieldPositionRole?.code,
    context.position?.code,
    context.profile.code,
    context.participantProfile?.code,
  ].join(';')
}

function parseCode(code: string): {
  fieldPositionRole: string
  position: string
  profile: string
  participantProfile: string
} {
  const [fieldPositionRole, position, profile, participantProfile] = code.split(';')
  return { fieldPositionRole, position, profile, participantProfile }
}
