import React, { useContext, useState, useEffect } from 'react'

import { Theme, Typography, createStyles, makeStyles } from '@material-ui/core'
import { useAsync } from 'react-use'

import { LogManager } from '../../../../../infrastructure/logger'
import { IDictionaryItem } from '../../../../../model/dictionary-item'
import { IKeyValuePair, ITabSelectScreenItem } from '../../../../../model/screen-item'
import { checkPredicate } from '../../../../../model/script-predicate'
import { ApiContext } from '../../../../../providers'
import { appToast } from '../../../../../utils'
import { getContextProperty, getPropertyAny } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { ItemCard } from '../item-card'
import MultilineTabSelect from './multiline-tab-select'
import ScrollableTabSelect from './scrollable-tab-select'

type Value = IDictionaryItem | string | undefined
interface IProps {
  item: ITabSelectScreenItem
  onChange?: (value: Value) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emptyCaption: {
      color: 'rgba(0, 0, 0, 0.38)',
    },
  }),
)

const logger = LogManager.getLogger('TabSelectScreenItem')

const TabSelectScreenItem: React.FC<IProps> = ({ item, onChange }) => {
  const classes = useStyles()
  const api = useContext(ApiContext)
  const processContext = useScriptTaskContext()
  const alignType = item.style.align ?? 'Left'
  const isVisible = item.visible ? checkPredicate(item.visible, {} as Record<string, unknown>, processContext) : true
  const isRequired = item.required
    ? checkPredicate(item.required, {} as Record<string, unknown>, processContext)
    : false
  const record = getContextProperty<string | IDictionaryItem | undefined>(processContext, item.propertyName, undefined)

  const [selectedlistItem, setSelectedListItem] = useState<string>()

  const isArrayTypeKeyValuePair = (type: IKeyValuePair[] | IDictionaryItem[]): type is IKeyValuePair[] => {
    return type.some((item) => 'key' in item && 'value' in item)
  }

  const data = useAsync(async () => {
    switch (item.dataSource.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.DictionaryReferenceDataSource': {
        const code = item.dataSource.dictionary.version!.code
        const dictionary = await api.dictionary.getDictionaryByVersionCode(code)
        return dictionary?.items
      }
      case 'PMI.FACE.BDDM.Extensions.Classes.KeyValuePairsDataSource': {
        return item.dataSource.items
      }
      case 'PMI.FACE.BDDM.Extensions.Classes.ContextPropertyDataSource': {
        return getPropertyAny<IDictionaryItem[]>(processContext, item.dataSource.propertyName)
      }
      case 'PMI.FACE.BDDM.Extensions.Classes.PropertyKeyValuePairsDataSource': {
        return getPropertyAny<IKeyValuePair[]>(processContext, item.dataSource.propertyName)
      }
    }
  }, [])

  const selectItem = (key: string | undefined): void => {
    setSelectedListItem(key)
    if (isArrayTypeKeyValuePair(data.value!)) {
      onChange?.(key)
    } else {
      const res = data.value!.find((item) => item.code === key)
      onChange?.(res)
    }
  }

  useEffect(() => {
    if (record) {
      onChange?.(record)
      if (typeof record === 'string') {
        setSelectedListItem(record)
      } else {
        setSelectedListItem(record.code)
      }
    }
    if (!record && data.value) {
      if (isArrayTypeKeyValuePair(data.value)) {
        onChange?.(data.value.at(0)?.key)
        setSelectedListItem(data.value.at(0)?.key)
      } else {
        onChange?.(data.value.at(0))
        setSelectedListItem(data.value.at(0)?.code)
      }
    }
  }, [data.value])

  if (!isVisible) {
    return null
  }

  if (data.error) {
    const message = `Ошибка при получении DataSource для Tab select`
    appToast.info(message)
    logger.error('get', message)
    return null
  }

  if (!data.loading && !data.value) {
    appToast.info('Tab select - данных нет')
    return null
  }

  const label = item.compactMode ? undefined : <Typography variant='inherit'>{item.displayName}</Typography>

  return (
    <ItemCard isError={isRequired && !selectedlistItem} label={label}>
      {!data.loading && !data.value?.at(0) && (
        <Typography className={classes.emptyCaption}>Список элементов пуст</Typography>
      )}
      {!data.loading &&
        data.value &&
        item.style.$type === 'PMI.FACE.BDDM.Extensions.Classes.MultilineTabSelectStyle' && (
          <MultilineTabSelect
            listItems={data.value}
            alignType={alignType}
            selectedlistItem={selectedlistItem}
            selectItem={selectItem}
          />
        )}
      {!data.loading &&
        data.value &&
        item.style.$type === 'PMI.FACE.BDDM.Extensions.Classes.ScrollableTabSelectStyle' && (
          <ScrollableTabSelect
            listItems={data.value}
            alignType={alignType}
            selectedlistItem={selectedlistItem}
            selectItem={selectItem}
          />
        )}
    </ItemCard>
  )
}

export default TabSelectScreenItem
