import React, { useEffect, useState } from 'react'

import { Box, Button, IconButton, Menu, MenuItem, Typography } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { useTranslation } from 'react-i18next'

import { IActionScreenItem } from '../../../../../model/screen-item-base'
import { checkPredicate, IPredicate } from '../../../../../model/script-predicate'
import { useUpdateProperty } from '../../../nested/useUpdateProperty'
import { getContextProperty } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import AnswerResolution from '../answer-resolution'
import { ItemCard } from '../item-card'
import { useBooleanStyles } from './styles'
import { BooleanItemValue, mapDisplayValue } from './utils'

export interface IBooleanInputScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.BooleanInputScreenItem'
  required?: IPredicate
  visible?: IPredicate
  errorHelperText?: string
  nullable?: boolean
}

interface BooleanInputScreenItemControlProps {
  item: IBooleanInputScreenItem
  children?: never
}

export const BooleanInputScreenItemControl: React.FC<BooleanInputScreenItemControlProps> = ({ item }) => {
  useEffect(() => {
    console.log('BooleanInputScreenItemControl mount', item)
  }, [])

  const context = useScriptTaskContext()
  const value: boolean = getContextProperty(context, item.propertyName)
  const updateProperty = useUpdateProperty()

  if (item.visible && !checkPredicate(item.visible, undefined, context)) {
    return <></>
  }

  return (
    <BooleanInputScreenItem
      item={item}
      value={value}
      onChange={async (value) => updateProperty(item.propertyName, value)}
    />
  )
}

interface BooleanInputScreenItemProps {
  item: IBooleanInputScreenItem
  onChange: (value: BooleanItemValue) => void
  value: BooleanItemValue
  children?: never
}

const BooleanInputScreenItem: React.FC<BooleanInputScreenItemProps> = ({ value, onChange, item }) => {
  useEffect(() => {
    console.log('BooleanInputScreenItem mount, resolved value:', value, item)
  }, [])
  const classes = useBooleanStyles()
  const { t } = useTranslation('common')
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement>()
  const handleCloseMenu = (): void => setMenuAnchor(undefined)

  const isCompact = false

  const displayValue = mapDisplayValue(value)
  const isAnswer = !!displayValue

  const renderLabel = (): JSX.Element => {
    return (
      <>
        <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={handleCloseMenu}>
          <MenuItem
            onClick={() => {
              handleCloseMenu()
              onChange(undefined)
            }}
          >
            {t('reset')}
          </MenuItem>
        </Menu>
        <Box className={classes.label}>
          <Typography variant='inherit'>{item.displayName}</Typography>
          <Box ml='auto' pl={2}>
            {isAnswer && isCompact && <AnswerResolution resolution={value} onlyText={false} text={displayValue} />}
            {isAnswer && (
              <IconButton edge='end' size='small' onClick={(ev) => setMenuAnchor(ev.currentTarget)}>
                <MoreVertIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      </>
    )
  }

  return (
    <ItemCard label={renderLabel()} isError={!isAnswer}>
      {!isAnswer && (
        <div className={classes.buttonsWrap}>
          <Button
            className={classes.successButton}
            size='large'
            variant='outlined'
            onClick={() => {
              onChange(true)
            }}
          >
            Да
          </Button>
          <Button
            className={classes.declineButton}
            size='large'
            variant='outlined'
            onClick={() => {
              onChange(false)
            }}
          >
            Нет
          </Button>
          {item.nullable && (
            <Button
              className={classes.naButton}
              size='large'
              variant='outlined'
              onClick={() => {
                onChange(null)
              }}
            >
              {'N/A'}
            </Button>
          )}
        </div>
      )}
      {isAnswer && !isCompact && (
        <div className={classes.answerWrap}>
          <AnswerResolution resolution={value} onlyText={false} text={displayValue} />
        </div>
      )}
    </ItemCard>
  )
}
