import React from 'react'

import { IUserStep } from '../../../model/task-execution-process'
import { IUserStepScreen } from '../../../model/user-step-screen'

export interface IStepContext {
  step: IUserStep
  screen: IUserStepScreen
}

export const StepContext = React.createContext({} as IStepContext)
