import React from 'react'

import { createStyles, FormControl, InputLabel, MenuItem, Select, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // height: theme.spacing(6),
      width: 280
    }
  })
)

interface IIsaAnswerSelectControlProps {
  options: string[]
  onSelect: (value: string) => void
}

const IsaAnswerSelectControl: React.FC<IIsaAnswerSelectControlProps> = (props) => {
  const classes = useStyles()

  const { t } = useTranslation('reports')

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode): void => {
    const value = event.target.value as string
    props.onSelect(value)
  }

  return (
    <FormControl variant='outlined'>
      <InputLabel id='isa-answer-select-outlined-label'>{t('selectAnswer')}</InputLabel>
      <Select
        id='isa-answer-select-outlined'
        labelId='isa-answer-select-outlined-label'
        className={classes.root}
        onChange={handleChange}
        label='Выберите ответ'
        MenuProps={{
          // https://github.com/mui-org/material-ui/issues/9595
          disableScrollLock: true
        }}
        // placeholder='Выберите ответ'
        // variant='outlined'
        // defaultValue='ALL'
        // inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem value='ALL'>{t('optionAll')}</MenuItem>
        {props.options.map((option) => (
          <MenuItem key={option} value={option}>
            <span>{!option.trim() ? "''" : option.toUpperCase()}</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default IsaAnswerSelectControl
