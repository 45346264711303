import React, { useContext, useEffect, useRef, useState } from 'react'

import { Box, CircularProgress, Fab, IconButton, makeStyles, Theme, Typography } from '@material-ui/core'
import { createStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import { useVirtualizer } from '@tanstack/react-virtual'
import { flatten } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useLocation, Link } from 'react-router-dom'
import { useAsync } from 'react-use'

import { getFloatActionButtonStyle } from '../../../../assets/pm-shared-styles'
import { LogManager } from '../../../../infrastructure/logger'
import { PageContent } from '../../../../layout/page-content'
import { TitleBar } from '../../../../layout/title-bar'
import { IProblem } from '../../../../model/problem'
import { ApiContext, ProfileContext } from '../../../../providers'
import { useIsSmall } from '../../../_common/hooks/useIsSmall'
import { EmptyProblemMessage } from '../../../visits/visit-tasks/empty-problem-message'
import ProblemFilterScreenItem from './items/problem-filter-screen-item'
import ProblemListPosScreenItem from './items/problem-list-pos-screen-item'
import { ProblemMobileSearchItem } from './items/problem-mobile-search-item'
import ProblemsListVirtual from './problems-list-virtual'
import { getProblems60DaysPeriod } from './utils'

interface LocationState {
  pathname: string
  state: {
    scrollOffset: number
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleBar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    listWrapper: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      height: 'calc(100vh - 208px)',
      padding: '24px 24px 0',
      [theme.breakpoints.down('xs')]: {
        padding: '17px 17px 0',
      },
    },
    progress: {
      margin: '0 auto',
    },
    listWrap: {
      display: 'flex',
      height: `100%`,
      overflow: 'auto',
      paddingBottom: theme.spacing(12),
    },
    captionOfEmptyResult: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'rgba(0, 0, 0, 0.38)',
      whiteSpace: 'nowrap',
    },
    emptyProblemMessage: {
      height: 'calc(100vh - 88px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    buttonWrap: {
      ...getFloatActionButtonStyle(theme),
      textTransform: 'none',
      [theme.breakpoints.down('xs')]: {
        right: '50%',
        transform: 'translateX(50%)',
        margin: '0 auto',
      },
    },
    addButton: {
      paddingRight: 16,
      textTransform: 'none',
    },
    addIcon: {
      marginRight: theme.spacing(1),
    },
  }),
)

const AddButton: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation('problems')
  return (
    <Box className={classes.buttonWrap}>
      <Link to={`/problems/problemPosSelector`}>
        <Fab color='primary' aria-label='add' variant='extended' className={classes.addButton}>
          <AddIcon className={classes.addIcon} />
          {t('problem')}
        </Fab>
      </Link>
    </Box>
  )
}

const logger = LogManager.getLogger('useFetchQuestionnaire')

const ProblemsPage: React.FC = () => {
  const { t } = useTranslation('problems')
  const api = useContext(ApiContext)
  const containerRef = useRef<HTMLDivElement>(null)
  const classes = useStyles()
  const currentUser = useContext(ProfileContext)
  const employee = currentUser.value?.employee
  const currentUserFio = `${employee?.contact.surname} ${employee?.contact.name}`
  const location = useLocation() as LocationState
  const restoringScroll = useRef(!!location.state?.scrollOffset)
  const isSmall = useIsSmall()

  const [isOpenMobileSearch, setIsOpenMobileSearch] = useState(false)
  const [filteredProblems, setFilteredProblems] = useState<IProblem[]>()
  const [problems, setProblems] = useState<IProblem[]>()
  const [invokeFilter, setInvokeFilter] = useState(false)
  const [isNoProblems, setIsNoProblems] = useState<boolean>()
  const [invokeMobileSearch, setInvokeMobileSearch] = useState<boolean>(false)
  let filteredList = filteredProblems ?? []

  const rowVirtualizer = useVirtualizer({
    count: filteredList.length,
    getScrollElement: () => containerRef.current,
    estimateSize: () => 88,
    overscan: 30,
    initialOffset: location.state?.scrollOffset,
    enableSmoothScroll: false,
  })
  useEffect(() => {
    if (rowVirtualizer.options.count > 0 && restoringScroll.current) {
      rowVirtualizer.scrollToOffset(location.state!.scrollOffset!)
      restoringScroll.current = false
    }
  }, [rowVirtualizer.options.count])

  const filteringStatus = (value: string): void => {
    switch (value) {
      case 'all':
        break
      case 'assignedToMe':
        filteredList = filteredList!.filter(
          (problem) =>
            problem.status === 'New' &&
            currentUser.value?.fieldPositionRole?.code === problem.assignedToPositionRole?.code,
        )
        break
      case 'appointedByMe':
        filteredList = filteredList!.filter(
          (problem) =>
            currentUserFio === problem.raisedBy?.name &&
            currentUser.value?.fieldPositionRole?.code === problem.raisedByPositionRole?.code,
        )
        break
      case 'new':
        filteredList = filteredList!.filter(
          (problem) =>
            problem?.status === 'New' &&
            currentUser.value?.fieldPositionRole?.code !== problem.assignedToPositionRole?.code,
        )
        break
      case 'resolve':
        filteredList = filteredList!.filter((problem) => problem?.status === 'Resolved')
        break
      case 'irrelevant':
        filteredList = filteredList!.filter((problem) => problem?.status === 'Canceled')
        break
    }
  }

  const sortingDate = (value: string): void => {
    switch (value) {
      case 'ascending':
        filteredList.sort((a, b) => a.creationTime! - b.creationTime!)
        break
      case 'descending':
        filteredList.sort((a, b) => b.creationTime! - a.creationTime!)
        break
      case '':
        filteredList.sort((a, b) => b.creationTime! - a.creationTime!)
        break
    }
  }

  const searchFilter = (): void => {
    const searchInput = sessionStorage.getItem(`${location.pathname}-searchInput`)!
    filteredList = filteredList!.filter(
      (problem) =>
        problem?.escalationReason.name.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase()) ||
        problem?.executiveComment?.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase()) ||
        problem?.problemDetails?.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase()),
    )
  }

  const onSearchHandler = (queryString: string): void => {
    sessionStorage.setItem(`${location.pathname}-searchInput`, queryString)
    setInvokeMobileSearch((prev) => !prev)
  }

  const renderButtonSearch = (): JSX.Element | undefined => {
    if (isSmall) {
      return (
        <IconButton
          onClick={() => {
            setIsOpenMobileSearch(true)
          }}
        >
          <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='11' cy='11' r='7' stroke='#546066' strokeWidth='2' />
            <path d='M20 20L17 17' stroke='#546066' strokeWidth='2' strokeLinecap='round' />
          </svg>
        </IconButton>
      )
    }
  }

  useAsync(async () => {
    try {
      const profile = await api.userProfile.getCurrentProfile()
      const posList = await api.pos.searchPos({
        positionRoleCoverage: profile?.fieldPositionRole?.code ?? '',
      })
      const posCodesList = posList!.map((pos) => pos.code)
      const problems = await Promise.all(
        posCodesList.map(async (code) => api.problem.searchProblems({ posCode: code })),
      )
      if ([...flatten(problems)].length === 0) setIsNoProblems(true)
      filteredList = getProblems60DaysPeriod([...flatten(problems)])

      if (sessionStorage.getItem(`${location.pathname}-searchInput`)) {
        searchFilter()
        const queryPosList = await api.pos.searchPos({
          positionRoleCoverage: profile?.fieldPositionRole?.code ?? '',
          searchQuery: sessionStorage.getItem(`${location.pathname}-searchInput`)!,
        })
        const posCodesList = queryPosList!.map((pos) => pos.code)
        const problems = await Promise.all(
          posCodesList.map(async (code) => api.problem.searchProblems({ posCode: code })),
        )
        setProblems([...filteredList, ...getProblems60DaysPeriod(flatten(problems))])
      } else {
        setProblems(filteredList)
      }
    } catch (error) {
      const message = 'Ошибка при получении списка проблем'
      logger.error('get', message, error)
      throw new Error(message)
    }
  }, [invokeFilter, invokeMobileSearch])

  useEffect(() => {
    if (problems) {
      filteredList = [...problems!]
      filteringStatus(sessionStorage.getItem(`${location.pathname}-sortStatus`) ?? '')
      sortingDate(sessionStorage.getItem(`${location.pathname}-sortDate`) ?? '')
      setFilteredProblems(filteredList)
    }
  }, [problems, invokeFilter])

  if (!problems) {
    return (
      <>
        <TitleBar>{t('problems')}</TitleBar>
        <Box className={classes.emptyProblemMessage}>
          <CircularProgress />
        </Box>
      </>
    )
  }

  if (isNoProblems) {
    return (
      <>
        <TitleBar>{t('problems')}</TitleBar>
        <Box className={classes.emptyProblemMessage}>
          <EmptyProblemMessage />
        </Box>
      </>
    )
  }

  return (
    <>
      {isSmall && (
        <ProblemMobileSearchItem
          query={sessionStorage.getItem(`${location.pathname}-searchInput`) ?? ''}
          onChange={onSearchHandler}
          isOpen={isOpenMobileSearch}
          onClose={() => {
            sessionStorage.setItem(`${location.pathname}-searchInput`, '')
            setIsOpenMobileSearch(false)
          }}
        />
      )}
      <TitleBar>
        <Box className={classes.titleBar}>
          {t('problems')}
          {renderButtonSearch()}
        </Box>
      </TitleBar>
      <PageContent className={classes.listWrapper}>
        <ProblemFilterScreenItem triggerFilter={setInvokeFilter} />
        {filteredProblems?.length === 0 ? (
          <Typography variant='h6' className={classes.captionOfEmptyResult}>
            {t('searchResultEmpty')}
          </Typography>
        ) : (
          <ProblemsListVirtual ListItem={ProblemListPosScreenItem} items={filteredProblems!} />
        )}
        <AddButton />
      </PageContent>
    </>
  )
}

export default ProblemsPage
