import React, { useMemo } from 'react'

import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { groupBy } from 'lodash'

import { PageContent } from '../../../layout/page-content'
import { IActionScreenItem } from '../../../model/screen-item-base'
import { useAsyncError } from '../../_common/hooks/useAsyncError'
import { usePropertyName } from '../nested/usePropertyName'
import { ItemCard } from '../template-tasks/composite-screen/item-card'
import { TaskAssessment, VisitAssessment } from './audit-merchandise-service-task'
import { formatTaskAssessmentResult } from './task-assessment-result'
import { useBackupExtensions } from './use-backup-extensions'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statisticAccordion: {
      marginBottom: 10,
      '& .MuiPaper-elevation1': {
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
      '& .MuiAccordion-root': {
        marginBottom: 16,
        '&:last-child': {
          marginBottom: 0,
          '& .MuiBox-root': {
            marginBottom: 0,
          },
        },
      },
      '& .MuiAccordion-root:before': {
        display: 'none',
      },
      '& .MuiAccordionSummary-root': {
        justifyContent: 'flex-start',
        height: '24px !important',
        minHeight: '0 !important',
        padding: 0,
      },
      '& .MuiAccordionSummary-content.Mui-expanded, & .MuiAccordion-root.Mui-expanded': {
        margin: 0,
        order: 2,
      },
      '& .MuiAccordionSummary-content': {
        margin: 0,
        flexGrow: 0,
        order: 2,
        marginLeft: '24px !important',
        overflow: 'hidden',
        [theme.breakpoints.down('xs')]: {
          marginLeft: '15px !important',
        },
      },
      '& .MuiAccordionDetails-root': {
        width: '100%',
        padding: 0,
      },

      '& .MuiAccordionSummary-expandIcon': {
        order: 1,
        padding: '0 !important',
        transform: 'rotate(90deg)',
        marginLeft: -10,
      },
      '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
        transform: 'rotate(180deg)',
      },
    },
    emptyResCaption: {
      fontSize: theme.typography.h4.fontSize,
      lineHeight: theme.typography.h4.lineHeight,
      fontWeight: 400,
    },
    taskName: {
      fontSize: theme.typography.h4.fontSize,
      lineHeight: theme.typography.h4.lineHeight,
      fontWeight: 400,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    taskCounter: {
      marginLeft: theme.spacing(2.5),
      fontSize: theme.typography.h4.fontSize,
      lineHeight: theme.typography.h4.lineHeight,
      fontWeight: 400,
    },
    posWrap: {
      width: '100%',
      paddingLeft: 26,
      marginBottom: 15,
      flexGrow: 1,
      '&:last-child': {
        marginBottom: 32,
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 18,
        '&:last-child': {
          marginBottom: 16,
        },
      },
    },
    posItem: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-between',
      marginTop: 16,
      '&:first-child': {
        marginTop: 24,
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: 8,
        '&:first-child': {
          marginTop: 16,
        },
      },
    },
    posTitle: {
      display: 'flex',
      overflow: 'hidden',
    },
    posName: {
      fontSize: theme.typography.h4.fontSize,
      lineHeight: theme.typography.h4.lineHeight,
      fontWeight: 400,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    posCode: {
      fontSize: theme.typography.h4.fontSize,
      lineHeight: theme.typography.h4.lineHeight,
      fontWeight: 400,
      color: 'grey',
      marginLeft: 5,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    posComment: {
      color: 'grey',
      fontSize: 14,
      lineHeight: '24px',
      height: 24,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    posStatus: {
      fontSize: theme.typography.h4.fontSize,
      lineHeight: theme.typography.h4.lineHeight,
      fontWeight: 500,
    },
  }),
)

interface IServiceAuditStatisticsScreenItemProps {
  children?: never
  item: IServiceAuditStatisticsScreenItem
}

export interface IServiceAuditStatisticsScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.ServiceAuditStatisticsScreenItem'
}

interface IPosAssessment {
  posName: string
  visitCode?: string
  posCode: string
  comment: string | undefined
  templateName: string | undefined
  result: TaskAssessment['result']
}

interface ITaskProps {
  taskLabel: string
  posStatistics: IPosAssessment[]
}

interface IPosStatisticProps {
  posName: string
  posCode: string
  comment: string | undefined
  result: TaskAssessment['result']
}

const PosStatistic: React.FC<IPosStatisticProps> = ({ posName, posCode, comment, result }) => {
  const classes = useStyles()
  return (
    <>
      <Box className={classes.posItem}>
        <Box className={classes.posTitle}>
          <Typography className={classes.posName}>{posName}</Typography>
          <Typography className={classes.posCode}>{`(${posCode})`}</Typography>
        </Box>
        <Box>
          <Typography className={classes.posStatus}>{formatTaskAssessmentResult(result)}</Typography>
        </Box>
      </Box>
      {comment && <Typography className={classes.posComment}>{comment}</Typography>}
    </>
  )
}

const Task: React.FC<ITaskProps> = ({ taskLabel, posStatistics }) => {
  const classes = useStyles()
  const tasksComplited = posStatistics.reduce((sum, item) => {
    if (item.result === 'Complied') {
      sum += 1
    }
    return sum
  }, 0)

  return (
    <Accordion defaultExpanded={false}>
      <AccordionSummary expandIcon={<ArrowDropUpIcon />}>
        <Typography className={classes.taskName}>{taskLabel}</Typography>
        <Typography className={classes.taskCounter}>{`${tasksComplited}/${posStatistics.length}`}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box className={classes.posWrap}>
          {posStatistics.map((item) => (
            <PosStatistic
              key={item.posCode}
              posName={item.posName}
              posCode={item.posCode}
              comment={item.comment}
              result={item?.result}
            />
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

const emptyArr: never[] = []

export const ServiceAuditStatisticsScreenItem: React.FC<IServiceAuditStatisticsScreenItemProps> = ({ item }) => {
  const classes = useStyles()
  const value = usePropertyName<VisitAssessment[]>(item.propertyName) ?? emptyArr

  const extensions = useBackupExtensions()
  useAsyncError(extensions.error)

  const grouped = useMemo(() => {
    const stats = value.flatMap((va) => {
      const visitCode = va.visit?.code
      const posExtension =  visitCode ? extensions.value?.[visitCode]?.pos : va.posExtension
      return va.taskAssessmentsData?.map((ta) => {
        const templateName = ta.task?.name && visitCode ? extensions.value?.[visitCode]?.tasks?.[ta.task.code]?.name : ta.taskTemplate?.name
        return {
          posName: posExtension?.name ?? '',
          visitCode,
          posCode: posExtension?.code ?? '',
          comment: ta.comment,
          result: ta.result,
          templateName,
        }
      })
    })
    const validTasks = stats?.filter((item) => item?.result)
    const res = Object.entries(groupBy(validTasks, (item) => item.templateName))
    return res
  }, [value, extensions.value])

  return (
    <PageContent>
      <div className={classes.statisticAccordion}>
        <Box>
          <ItemCard label={item.displayName}>
            {grouped.length === 0 || !grouped ? (
              <Typography className={classes.emptyResCaption}>Список проверенных задач пуст</Typography>
            ) : (
              grouped!.map((item) => <Task key={item[0]} taskLabel={item[0]} posStatistics={item[1]} />)
            )}
          </ItemCard>
        </Box>
      </div>
    </PageContent>
  )
}
