import React from 'react'

import { Avatar, Box, createStyles, Grid, Paper, Theme, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

import { grays } from '../../../layout/theme'
import { useIsSmall } from '../../_common/hooks/useIsSmall'
import { StoreMenuButton } from './store-menu-button'

interface IStoresListItemProps {
  name: string
  address: string
  code: string
  selected?: boolean
  menuAccess?: boolean
  registerName?: string | null
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
      fontSize: 16,
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginBottom: 0,
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    selected: {
      background: 'rgba(48,119,184, 0.05)',
    },
    selectedIcon: {
      marginLeft: 10,
      marginRight: 10,
      color: theme.palette.primary.main,
    },
    gridContainer: {},
    name: {
      marginLeft: 17,
      fontSize: 18,
      lineHeight: '24px',
      fontWeight: 500,
      letterSpacing: '0.15px',
      paddingRight: 8,
    },
    registerName: {
      color: grays.gray2,
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: '0.25px',
      flexShrink: 0,
    },
    code: {
      color: grays.gray2,
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: '0.25px',
      [theme.breakpoints.up('md')]: {
        flexGrow: '0',
        maxWidth: '8.333333%',
        flexBasis: '8.333333%',
      },
      [theme.breakpoints.down('md')]: {
        flexGrow: '0',
        maxWidth: '10%',
        flexBasis: '10%',
      },
      [theme.breakpoints.down('sm')]: {
        flexGrow: '0',
        maxWidth: '90px',
        flexBasis: '90px',
      },

      [theme.breakpoints.down('xs')]: {
        flexGrow: '0',
        maxWidth: '100%',
        flexBasis: '100%',
      },
    },
    address: {
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '0.15px',
      paddingRight: 8,
    },
    avatar: {
      width: 48,
      height: 48,
      textTransform: 'uppercase',
    },
    pointWrap: {
      [theme.breakpoints.down('xs')]: {
        marginBottom: 10,
        order: -1,
      },
    },
  }),
)

const StoresListItem: React.FC<IStoresListItemProps> = (props) => {
  const classes = useStyles()
  const isSmall = useIsSmall()
  const justifyCode = isSmall ? 'flex-start' : 'flex-start'

  return (
    <Paper
      elevation={0}
      className={classnames(classes.root, {
        [classes.selected]: props.selected,
      })}
    >
      <Grid
        container
        className={classes.gridContainer}
        alignItems={isSmall ? 'flex-start' : 'center'}
        justify='space-between'
      >
        <Grid item md={3} sm={4} xs={props.menuAccess ? 9 : 11} className={classes.pointWrap}>
          <Box display='flex' alignItems='center'>
            <Avatar className={classes.avatar} alt={props.name} src='/static/images/avatar/1.jpg' />
            <Typography className={classes.name} noWrap>
              {props.name}
            </Typography>
          </Box>
        </Grid>
        <Grid item md={props.menuAccess ? 3 : 4} sm={props.menuAccess ? 2 : 3} xs={12}>
          <Tooltip title={props.address}>
            <Typography noWrap className={classes.address}>
              {props.address}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item className={classes.code}>
          <Box display='flex' alignItems='center' justifyContent={justifyCode}>
            {props.code}
            {/*{props.selected && <CheckCircleIcon className={classes.selectedIcon} />}*/}
          </Box>
        </Grid>
        <Grid item sm={2} xs={12}>
          <Typography className={classes.registerName} noWrap>
            {props.registerName ?? ''}
          </Typography>
        </Grid>
        {props.menuAccess && <StoreMenuButton posCode={props.code} />}
      </Grid>
    </Paper>
  )
}

export default React.memo(StoresListItem)
