import {Code, SyncErrorCode} from '../model/base'
import {BusinessError} from '../model/errors'
import {IProblem} from "../model/problem"
import {ITask} from "../model/task"
import {ITaskReport} from '../model/task-report'
import {IPositionRoleReference} from '../model/user'
import {IVisit} from "../model/visit"
import {ICancellationToken} from '../utils/cancellation'

export type OperationProgressCallback = (progress: number, message?: string) => void

export enum SyncErrorSource {
  Internal,
  Other,
  UploadReport,
  LoadPos,
  UploadVisit,
  LoadVisitsDelta,
  LoadSupervisedVisitsDelta,
  LoadVisits,
  LoadSupervisedVisits,
  LoadTaskTemplates,
  LoadVisitTaskTopics,
  LoadQuestionnaires,
  LoadAudits,
  LoadSMServices,
  LoadDictionaries,
  LoadHyperlinks,
  LoadPOSTaskRegisters,
  LoadPOSTaskRegisterExecutions,
  LoadTaskTemplateContents,
  LoadContentDocuments,
  LoadProductMatrixAssignments,
  LoadProductMatrices,
  LoadContractTermAssignments,
  LoadContractTerms,
  LoadProblemTemplates,
  LoadProblems,
  UploadProblem,
  UploadTask,
  LoadTasksDelta,
  LoadTaskTemplatesDelta,
  LoadSupervisedFieldPositionRoles,
  LoadFieldPositionRoleUserProfiles
}

export class SyncError extends BusinessError<SyncErrorCode> {
  readonly source: SyncErrorSource
  readonly httpStatus?: number
  readonly businessErrorType?: string
  readonly businessErrorTitle?: string

  constructor(
    errorSource: SyncErrorSource,
    code: SyncErrorCode,
    message: string,
    httpStatus?: number,
    details?: unknown,
    businessErrorType?: string,
    businessErrorTitle?: string
  ) {
    super(code, message, details)
    this.name = 'SyncError'
    this.source = errorSource
    this.httpStatus = httpStatus
    this.businessErrorType = businessErrorType
    this.businessErrorTitle = businessErrorTitle
  }

  toString(): string {
    let msg = `${this.name}: ${this.message} [${this.code}]`
    let info = ''
    if (this.httpStatus) {
      info += `httpStatus=${this.httpStatus}`
    }
    if (this.businessErrorType) {
      info += `, businessError=${this.businessErrorType}:${this.businessErrorTitle}`
    }
    msg += info ? ` (${info})` : ''
    return msg
  }
}

export interface IPendingItemsSummary {
  visitCount: number
  taskCount: number
  problemCount: number
  reportCount: number
}

export interface IPendingItems {
  visits: IVisit[]
  tasks: ITask[]
  problems: IProblem[]
}

export type SyncMode = 'Full' | 'SaveOnly' | 'LoadOnly' | 'Minimal'

export interface ISyncService {
  /**
   * Запускает процесс синхронизации данных
   * @param mode - режим синхронизации (Full - полная, SaveOnly - только сохранение, LoadOnly - только загрузка, Minimal - минимальная)
   * @param onProgress - колбэк прогресса
   * @param cancellation - запрос отмены операции
   */
  sync: (mode: SyncMode, onProgress?: OperationProgressCallback, cancellation?: ICancellationToken) => Promise<void>

  /**
   * Возвращает сводку по измененным объектам, готовым к отправке
   */
  countPendingItems: () => Promise<IPendingItemsSummary>

  /**
   * Возвращает true, если есть измененные данные, готовые к отправке, иначе false
   */
  hasPendingItems: () => Promise<boolean>

  /**
   * Возвращает измененные объекты (новые, неотправленные с ошибкой) данные
   */
  getPendingItems: () => Promise<IPendingItems>

  /**
   * Сохраняет отчет по задаче визита и возвращает ссылку на скачивание файла.
   * При неуспешной загрузке на сервер отчет остаётся сохраненным в локальном хранилище
   * для последующей синхронизации
   * @param report отчет
   */
  saveTaskReport: (report: ITaskReport) => Promise<string>

  /**
   * Загружает на сервер сохраненный отчет
   * и возвращает ссылку на скачивание файла или null, если отчет не найден
   * @param visitCode код визита
   * @param taskCode код задачи
   */
  uploadPendingTaskReport: (visitCode: Code, taskCode: Code) => Promise<string | null>

  /**
   * Загружает визит с указанным кодом на сервер и возвращает true в случае успеха, иначе false
   * false также возвращается, если визит не найден или не требует загрузки (синхронизации)
   */
  tryUploadPendingVisit: (visitCode: Code) => Promise<boolean>

  /**
   * Загружает проблему с указанным кодом на сервер и возвращает true в случае успеха, иначе false
   * false также возвращается, если проблема не найдена или не требует загрузки (синхронизации)
   */
  tryUploadPendingProblem: (problemCode: Code) => Promise<boolean>

  /**
   * Загружает задачу (вневизитную) с указанным кодом на сервер и возвращает true в случае успеха, иначе false
   * false также возвращается, если задача не найдена или не требует загрузки (синхронизации) или не является "вневизиной"
   */
  tryUploadPendingTask: (taskCode: Code) => Promise<boolean>

  /** Обновляет контролируемые визиты для роли исполнителя
   * @return Количество загруженных визитов */
  refreshSupervisedVisits: (executiveRole?: IPositionRoleReference) => Promise<number | undefined>
}
