import {ISAStore, ISAStore_endDate, SupervisedVisitStore} from '../../data/schema'
import { IAudit } from '../../model/audit'
import { Code, isReferenceToEntityIgnoreVersion } from '../../model/base'
import {BusinessError, BusinessErrorCode} from "../../model/errors";
import { IPointOfSale } from '../../model/pos'
import { ISupervisedVisitUnit } from '../../model/supervised-visit-unit'
import {scanMediaRefsTaskSpecialHandling} from "../../utils/media-scan";
import { trace } from '../../utils/trace'
import {
  IAuditInfo,
  IAuditService,
  SearchAuditRequest,
  SearchSupervisedVisitsRequest,
} from '../audit-service-api'
import { LocalStorageBaseService } from './local-storage-base-service'

function buildPosAddress(pos: IPointOfSale): string {
  return !pos.address?.fullAddress || !pos.address?.city ? '' : pos.address.fullAddress + pos.address.city
}

interface IWithMediaRefs {
  _mediaRefs?: string[]
}

export default class LocalStorageAuditService extends LocalStorageBaseService implements IAuditService {
  private static readonly __className = 'LocalStorageAuditService'

  async getAudit(auditCode: Code): Promise<IAudit | null> {
    return (await this._storage.getByKey<IAudit>(ISAStore, auditCode)) ?? null
  }

  @trace()
  async searchAudits(request?: SearchAuditRequest): Promise<IAuditInfo[]> {
    let filterBySearchQuery = (audit: IAudit): boolean => true
    if (request?.searchQuery) {
      const queryString = request.searchQuery.toLowerCase()
      filterBySearchQuery = (audit: IAuditInfo): boolean =>
        audit.pointOfSale.name.toLowerCase().includes(queryString) ||
        audit.pointOfSale.code.toLowerCase().includes(queryString) ||
        buildPosAddress(audit.pointOfSale).toLowerCase().includes(queryString)
    }

    const result: IAuditInfo[] = []
    for await (const audit of this._storage.selectByIndexRange<IAudit>(ISAStore, ISAStore_endDate, null, 'desc')) {
      if (filterBySearchQuery(audit)) {
        result.push({
          code: audit.code,
          endDate: audit.endDate,
          executiveUserName: audit.executiveUserName,
          pointOfSale: audit.pointOfSale,
        })
      }
    }
    return result
  }

  @trace()
  async searchSupervisedVisits(request: SearchSupervisedVisitsRequest): Promise<ISupervisedVisitUnit[]> {
    return await this._storage.getWhere<ISupervisedVisitUnit>(SupervisedVisitStore, (vu) => {
      let accept = vu.visit.plannedStartDate >= request.dateFrom && vu.visit.plannedStartDate <= request.dateTo
      accept &&= vu.visit.status !== 'Canceled'
      if (accept && request.taskTemplateCode) {
        accept = vu.tasks.some((t) => t.template.code === request.taskTemplateCode)
      }
      if (accept && request.status?.length) {
        accept = request.status.includes(vu.visit.status)
      }
      if (accept && request.positionRole) {
        accept =
          vu.visit.executivePositionRole != null
            ? isReferenceToEntityIgnoreVersion(request.positionRole, vu.visit.executivePositionRole)
            : request.positionRole.code === vu.visit.assignee?.positionRoleCode
      }
      return accept
    })
  }

  async getMediaRefsForSupervisedVisit(visitCode: Code): Promise<string[]> {
    const svu = await this._storage.getByKey<ISupervisedVisitUnit>(SupervisedVisitStore, visitCode)
    if (svu == null) {
      throw new BusinessError(BusinessErrorCode.EntityNotFound, `Supervised visit with code ${visitCode} not found`)
    }
    // media references caching (FACE-3200)
    let mediaRefs = (<IWithMediaRefs>svu)._mediaRefs
    if (!mediaRefs) {
      mediaRefs = [...scanMediaRefsTaskSpecialHandling(svu)];
      (<IWithMediaRefs>svu)._mediaRefs = mediaRefs
      await this._storage.put(SupervisedVisitStore, svu)
    }
    return mediaRefs
  }
}
