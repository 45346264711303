import React, { useMemo, useState } from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  createStyles,
  Dialog,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { useSet } from 'react-use'

import { grays } from '../../../../../layout/theme'
import { SETaskDirectionsScreenItem } from '../../../../../model/screen-item'
import { useAsyncError } from '../../../../_common/hooks/useAsyncError'
import { useCreateSurvey } from '../../../../_common/hooks/useCreateSurvey'
import { getPageLayoutStyles } from '../../../components/shared-styles'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { sortAnswers } from '../../utils'
import { DirectionTab, DirectionV3Page } from './direction-v3-page'

interface Props {
  item: SETaskDirectionsScreenItem
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      paddingTop: theme.spacing(6),
    },
    layout: {
      ...getPageLayoutStyles(theme),
      paddingTop: 0,
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(3),
      },
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    accordion: {
      '&:before': {
        display: 'none',
      },
      '& .MuiAccordionDetails-root': {
        padding: 0,
      },
    },
    summary: {
      margin: '0 !important',
      alignItems: 'center',
    },
    chevron: {
      transform: 'rotate(-90deg)',
      transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      marginRight: theme.spacing(1),
      '.Mui-expanded &': {
        transform: 'rotate(0deg)',
      },
    },
    card: {
      border: `1px solid ${grays.gray5}`,
      marginBottom: 8,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 4,
      },
      '&.Mui-expanded, &.Mui-expanded:last-child': {
        marginTop: 0,
        marginBottom: 8,
        [theme.breakpoints.down('xs')]: {
          marginBottom: 4,
        },
      },
      '&::before': {
        content: 'none',
      },
    },
    summaryRoot: {
      paddingLeft: 8,
      height: '56px !important',
      '&.Mui-expanded': {
        minHeight: 'unset',
      },
      borderBottom: `1px solid ${grays.gray5}`,
      '&:not(.Mui-expanded)': {
        marginBottom: -1,
      },
    },
    chip: {
      background: grays.gray4,
      color: theme.palette.common.white,
    },
    requiredIndicator: {
      height: 56,
      width: 2,
      background: '#D32F2F',
    },
    labelText: {
      fontSize: 18,
      lineHeight: '23px',
      fontWeight: 600,
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
      },
    },
  }),
)

interface AccordionSurveyProps {
  expanded: boolean
  onChange: VoidFunction
  showExpandArrow: boolean
  isError: boolean
  displayName: string
  surveyButton: JSX.Element
}
const AccordionSurvey: React.FC<AccordionSurveyProps> = ({
  displayName,
  expanded,
  onChange,
  isError,
  showExpandArrow,
  surveyButton,
  children,
}) => {
  const classes = useStyles()
  return (
    <Accordion expanded={expanded} onChange={onChange} elevation={0} square className={classes.card}>
      <AccordionSummary classes={{ content: classes.summary, root: classes.summaryRoot }}>
        {showExpandArrow ? (
          <IconButton className={classes.chevron}>
            <ArrowDropDownIcon />
          </IconButton>
        ) : (
          <Box mr={3} ml={-1} mt={-0.5}>
            {isError && <div className={classes.requiredIndicator}></div>}
          </Box>
        )}
        <Typography className={classes.labelText}>{displayName}</Typography>
        {/* {!!lasCount && (
            <Box ml={2}>
              <Chip className={classes.chip} size='small' label={String(lasCount)} />
            </Box>
          )} */}
        <Box ml='auto'>{surveyButton}</Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box ml={1.5}>{children}</Box>
      </AccordionDetails>
    </Accordion>
  )
}

const LasLauTaskDirections: React.FC<Props> = ({ item }) => {
  const [showModal, setShowModal] = useState<DirectionTab>()
  const [, { has, toggle }] = useSet<DirectionTab>()
  const localContext = useScriptTaskContext()
  const lasSurveyOps = useCreateSurvey(item.educationDirectionLasSurveyCode)
  const lauSurveyOps = useCreateSurvey(item.educationDirectionLauSurveyCode)

  const lasQuestionnaire = localContext.questionnaires.find((q) => q.code === item.educationDirectionLasSurveyCode)
  const lauQuestionnaire = localContext.questionnaires.find((q) => q.code === item.educationDirectionLauSurveyCode)

  const sortedLasAnswers = useMemo(() => {
    if (!lasQuestionnaire || !lasSurveyOps.value?.answers?.length) {
      return []
    }
    return sortAnswers(lasQuestionnaire, lasSurveyOps.value.answers.slice())
  }, [lasQuestionnaire, lasSurveyOps.value?.answers])

  const sortedLauAnswers = useMemo(() => {
    if (!lauQuestionnaire || !lauSurveyOps.value?.answers?.length) {
      return []
    }
    return sortAnswers(lauQuestionnaire, lauSurveyOps.value.answers.slice())
  }, [lauQuestionnaire, lauSurveyOps.value?.answers])

  useAsyncError(lasSurveyOps.error)
  useAsyncError(lauSurveyOps.error)

  if (lasSurveyOps.loading && !lasSurveyOps.value) return <></>
  if (lauSurveyOps.loading && !lauSurveyOps.value) return <></>
  if (!lasQuestionnaire) return <></>
  if (!lauQuestionnaire) return <></>

  const lasSurvey = lasSurveyOps.value!
  const lauSurvey = lauSurveyOps.value!

  const readOnly = item.actionKind !== 'Edit'

  function renderButton(count: number, tab: DirectionTab): JSX.Element {
    if (readOnly) return <></>

    if (!count) {
      return (
        <Button
          variant='text'
          color='primary'
          onClick={(ev) => {
            ev.stopPropagation()
            return setShowModal(tab)
          }}
        >
          Выбрать
        </Button>
      )
    }

    return (
      <Button
        variant='text'
        color='primary'
        onClick={(ev) => {
          ev.stopPropagation()
          return setShowModal(tab)
        }}
      >
        Изменить
      </Button>
    )
  }

  const lauCount = lauSurvey.answers?.length ?? 0
  const lasCount = lasSurvey.answers?.length ?? 0
  const anyFilled = Boolean(lauCount || lasCount)

  return (
    <>
      <Dialog open={!!showModal} fullScreen>
        <DirectionV3Page
          item={item}
          defaultTab={showModal}
          displayName={item.displayName}
          edLasQuestionnaire={lasQuestionnaire}
          edLauQuestionnaire={lauQuestionnaire}
          edLasSurvey={lasSurvey}
          refetchLas={lasSurveyOps.retry}
          edLauSurvey={lauSurvey}
          refetchLau={lauSurveyOps.retry}
          onBack={() => setShowModal(undefined)}
        />
      </Dialog>

      <AccordionSurvey
        expanded={!!lauCount && has(DirectionTab.LAU)}
        onChange={() => lauCount && toggle(DirectionTab.LAU)}
        showExpandArrow={!!lauCount}
        isError={!anyFilled}
        displayName={`${item.displayName} LAU`}
        surveyButton={renderButton(lauCount, DirectionTab.LAU)}
      >
        {sortedLauAnswers.map((answer) => {
          const question = lauQuestionnaire.questions.find((question) => question.code === answer.questionCode)
          return (
            <Box py={0.5} key={answer.questionCode}>
              <Typography color='textSecondary'>{question?.description}</Typography>
            </Box>
          )
        })}
      </AccordionSurvey>

      <AccordionSurvey
        expanded={!!lasCount && has(DirectionTab.LAS)}
        onChange={() => lasCount && toggle(DirectionTab.LAS)}
        showExpandArrow={!!lasCount}
        isError={!anyFilled}
        displayName={`${item.displayName} LAS`}
        surveyButton={renderButton(lasCount, DirectionTab.LAS)}
      >
        {sortedLasAnswers.map((answer) => {
          const question = lasQuestionnaire.questions.find((question) => question.code === answer.questionCode)
          return (
            <Box py={0.5} key={answer.questionCode}>
              <Typography color='textSecondary'>{question?.description}</Typography>
            </Box>
          )
        })}
      </AccordionSurvey>
    </>
  )
}

export const TaskDirections: React.FC<Props> = ({ item }) => {
  if (item.educationDirectionLasSurveyCode && item.educationDirectionLauSurveyCode) {
    return <LasLauTaskDirections item={item} />
  }
  if (item.educationDirectionLasSurveyCode || item.educationDirectionLauSurveyCode) {
    return (
      <FieldTraining
        item={item}
        questionnaireCode={item.educationDirectionLasSurveyCode || item.educationDirectionLauSurveyCode}
      />
    )
  }
  return <></>
}

type FieldTrainingProps = Props & {
  questionnaireCode: string
}
const FieldTraining: React.FC<FieldTrainingProps> = ({ item, questionnaireCode }) => {
  const [showModal, setShowModal] = useState<DirectionTab>()
  const [, { has, toggle }] = useSet<DirectionTab>()
  const localContext = useScriptTaskContext()
  const lauSurveyOps = useCreateSurvey(questionnaireCode)

  const lauQuestionnaire = localContext.questionnaires.find((q) => q.code === questionnaireCode)

  const sortedLauAnswers = useMemo(() => {
    if (!lauQuestionnaire || !lauSurveyOps.value?.answers?.length) {
      return []
    }
    return sortAnswers(lauQuestionnaire, lauSurveyOps.value.answers.slice())
  }, [lauQuestionnaire, lauSurveyOps.value?.answers])

  useAsyncError(lauSurveyOps.error)

  if (lauSurveyOps.loading && !lauSurveyOps.value) return <></>
  if (!lauQuestionnaire) return <></>

  const lauSurvey = lauSurveyOps.value!

  const readOnly = item.actionKind !== 'Edit'

  function renderButton(count: number, tab: DirectionTab): JSX.Element {
    if (readOnly) return <></>

    if (!count) {
      return (
        <Button
          variant='text'
          color='primary'
          onClick={(ev) => {
            ev.stopPropagation()
            return setShowModal(tab)
          }}
        >
          Выбрать
        </Button>
      )
    }

    return (
      <Button
        variant='text'
        color='primary'
        onClick={(ev) => {
          ev.stopPropagation()
          return setShowModal(tab)
        }}
      >
        Изменить
      </Button>
    )
  }

  const lauCount = lauSurvey.answers?.length ?? 0
  const anyFilled = Boolean(lauCount)

  return (
    <>
      <Dialog open={!!showModal} fullScreen>
        <DirectionV3Page
          item={item}
          defaultTab={showModal}
          displayName={item.displayName}
          edLasQuestionnaire={undefined!}
          edLauQuestionnaire={lauQuestionnaire}
          edLasSurvey={undefined!}
          refetchLas={undefined!}
          edLauSurvey={lauSurvey}
          refetchLau={lauSurveyOps.retry}
          onBack={() => setShowModal(undefined)}
        />
      </Dialog>

      <AccordionSurvey
        expanded={!!lauCount && has(DirectionTab.LAU)}
        onChange={() => lauCount && toggle(DirectionTab.LAU)}
        showExpandArrow={!!lauCount}
        isError={!anyFilled}
        displayName={item.displayName}
        surveyButton={renderButton(lauCount, DirectionTab.LAU)}
      >
        {sortedLauAnswers.map((answer) => {
          const question = lauQuestionnaire.questions.find((question) => question.code === answer.questionCode)
          return (
            <Box py={0.5} key={answer.questionCode}>
              <Typography color='textSecondary'>{question?.description}</Typography>
            </Box>
          )
        })}
      </AccordionSurvey>
    </>
  )
}
