/* eslint-disable @typescript-eslint/no-explicit-any */

type DecoratorFactory = (target: any, key: string, descriptor: PropertyDescriptor) => PropertyDescriptor | undefined

function _trace(): DecoratorFactory {
  return (target: any, key: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value
    descriptor.value = function (...args: any[]) {
      const source: string = target.constructor?.__className ?? target.constructor?.name ?? '?'
      console.groupCollapsed(`${source}::${key}`)
      console.log(args)
      //console.trace()
      console.groupEnd()
      return originalMethod.apply(this, args)
    }
    return descriptor
  }
}

function _dummy(): DecoratorFactory {
  return (target: any, key: string, descriptor: PropertyDescriptor) => undefined
}

const traceEnabled = true

export const trace = traceEnabled ? _trace : _dummy