import { LogEntry, LoggerBase, LogLevel } from './logger-api'

export class ConsoleLogger extends LoggerBase {
  private readonly _name: string
  private readonly _minLogLevel: LogLevel

  constructor(name: string, minLogLevel = LogLevel.debug) {
    super()
    this._name = name
    this._minLogLevel = minLogLevel
  }

  log(entry: LogEntry): void {
    if (entry.level < this._minLogLevel) {
      return
    }
    const { level, tag, message } = entry
    const args = [entry.error, entry.details].filter((a) => a !== undefined)
    let text = `${this._name}`
    if (tag) {
      text += `::${tag}`
    }
    if (message) {
      text += ` - ${message}`
    }
    if (args.length > 0) {
      text += ' | '
    }
    let func = console.info
    if (level === LogLevel.trace) {
      func = console.trace
    } else if (level === LogLevel.debug) {
      func = console.debug
    } else if (level === LogLevel.warn) {
      func = console.warn
    } else if (level === LogLevel.error) {
      func = console.error
    } else if (level === LogLevel.critical) {
      func = console.error
    }
    func(text, ...args)
  }
}
