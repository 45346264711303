import { useCallback, useEffect, useRef, useState } from 'react'

import { Workbox } from 'workbox-window'
import { WorkboxLifecycleWaitingEvent } from 'workbox-window/utils/WorkboxEvent'

interface IUseWorkbox {
  wb: Workbox
  hasNewVersion: boolean
  update: () => void
  version: string
}

export function useWorkbox(): IUseWorkbox {
  const wb = useRef<Workbox>(new Workbox('/service-worker.js'))
  const [hasNewVersion, setNewVersion] = useState<boolean>(false)
  const [version, setVersion] = useState<string>('')

  const hasServiceWorkerNewVersionHandler = useCallback(
    (event: WorkboxLifecycleWaitingEvent) => {
      _getVersion()
        .then((version) => setVersion(version))
        .finally(() => setNewVersion(!hasNewVersion))
    },
    [wb]
  )

  const update = (): void => {
    console.log('activate new sw', wb)
    wb.current.messageSkipWaiting()
  }

  const _getVersion = async (): Promise<string> => {
    return await wb.current.messageSW({ type: 'GET_VERSION' })
  }

  useEffect(() => {
    wb.current.addEventListener('waiting', hasServiceWorkerNewVersionHandler)
    wb.current.addEventListener('controlling', (event) => {
      window.location.reload()
    })

    async function connectWorkbox(): Promise<void> {
      await wb.current.register()
      // await wb.current.update()
    }

    void connectWorkbox()
  }, [])

  return { wb: wb.current, update, version, hasNewVersion }
}
