import React from 'react'

import { Box, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import classnames from 'classnames'
import { eachDayOfInterval, isSameDay } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { EmptyMessage } from '../../../components'
import { grays } from '../../../layout/theme'
import { ITask } from '../../../model/task'
import { appToast, dateFormat } from '../../../utils'
import { hexToRgbA } from '../../../utils/hex-to-rgba'
import { useSearchVisits, VisitWithPos } from '../../_common/hooks/useSearchVisits'
import { filterTaskByQuery, sortByTaskStatus } from '../../_common/tasks'
import { filterStoreByQuery, sortByCreationTime, sortByStatus } from '../../_common/visits'
import { ErrorPage } from '../../error-page'
import { getRegisteredNameFromPos } from '../../stores/store-menu-page/utils'
import { CardTask } from '../task-list/card-task'
import { useSearchNonVisitTasks } from '../task-list/useSearchNonVisitTasks'
import VisitsListWeekItem from '../visit-list-week-item/visit-list-week-item'

interface IVisitListProps {
  periodStartDate: number
  periodEndDate: number
  filterQuery: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    weekListWrap: {
      position: 'relative',
      flexGrow: 1,
      margin: '0 -24px',
      height: '100%',
      overflow: 'hidden',
      [theme.breakpoints.down('xs')]: {
        height: 'auto',
      },
    },
    weekList: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      height: '100%',
      left: 0,
      overflowX: 'scroll',
      overflowY: 'hidden',
      maxWidth: '100%',
      padding: '0 24px',
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100vw',
        minWidth: '320px',
        position: 'static',
        display: 'block',
        overflowX: 'auto',
        overflowY: 'auto',
        height: 'auto',
        paddingBottom: 100,
      },
    },
    weekItem: {
      maxWidth: '178px',
      paddingRight: '8px',
      flex: '0 0 178px',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 'none',
        paddingRight: '0',
      },
    },
    weekItemVisits: {
      paddingTop: 10,
      paddingBottom: 15,
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '0',
        display: 'none',
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: 8,
        paddingBottom: 0,
      },
    },
    dayOfWeekDividerRoot: {
      position: 'relative',
      paddingTop: 5,
      '&::after': {
        content: '""',
        position: 'absolute',
        zIndex: 5,
        left: 0,
        right: 0,
        bottom: -8,
        width: '100%',
        height: 15,
        background: `linear-gradient(180deg, ${hexToRgbA(theme.palette.background.default, '1')} 0%, ${hexToRgbA(
          theme.palette.background.default,
          '1',
        )} 38%, ${hexToRgbA(theme.palette.background.default, '0')} 100%)`,
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: 7,
        '&::after': {
          content: 'none',
        },
      },
    },
    dayOfWeekDividerTitle: {
      paddingBottom: 7,
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '18px',
      [theme.breakpoints.down('xs')]: {
        paddingBottom: 0,
        fontSize: 14,
        lineHeight: '20px',
        color: '#49454F',
        display: 'none',
      },
    },
    dayOfWeekDividerTitleMobile: {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    dayOfWeekDividerTitleDay: {
      display: 'inline-block',
      verticalAlign: 'middle',
      textTransform: 'capitalize',
      color: '#858F97',
      [theme.breakpoints.down('xs')]: {
        color: 'inherit',
      },
    },
    dayOfWeekDividerTitleDate: {
      display: 'inline-block',
      paddingRight: 12,
      verticalAlign: 'middle',
    },
    dayOfWeekContent: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        marginBottom: theme.spacing(1),
      },
    },
    emptyMessage: {
      color: grays.gray4,
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 500,
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        padding: '20px 40px',
      },
    },
  }),
)

const ActivitiesListByWeek: React.FC<IVisitListProps> = ({ periodStartDate, periodEndDate, filterQuery }) => {
  const classes = useStyles()
  const visitListData = useSearchVisits({ periodStartDate, periodEndDate }, { withPos: true })
  const taskListData = useSearchNonVisitTasks({ periodStartDate, periodEndDate })
  const { t } = useTranslation('visits')

  if (visitListData.error ?? taskListData.error) {
    const message = t('errorFetchingList')
    appToast.error(message)
    return <ErrorPage errorMessage={message} />
  }

  if (visitListData.loading || taskListData.loading) {
    return <EmptyMessage message={t('loading')} marginTop={10} />
  }

  const listOfDays = eachDayOfInterval(
    {
      start: periodStartDate,
      end: periodEndDate,
    },
    { step: 1 },
  )

  const sortedAndFilteredVisitList = (dayOfWeek: Date): VisitWithPos[] => {
    if (!visitListData.value || !dayOfWeek) {
      return []
    }
    return (
      visitListData.value
        // .filter(filterCancelledVisitsByToday)
        .filter((x) => isSameDay(x.plannedStartDate, dayOfWeek))
        .sort(sortByCreationTime)
        .sort(sortByStatus)
    )
  }
  const sortedAndFilteredTaskList = (dayOfWeek: Date): ITask[] => {
    if (!taskListData.value || !dayOfWeek) {
      return []
    }
    return taskListData.value.filter((x) => isSameDay(x.startDate, dayOfWeek)).sort(sortByTaskStatus)
  }

  const filterVisitsByQuery = (list: VisitWithPos[]): VisitWithPos[] =>
    list.filter((visit) => filterStoreByQuery(visit.pointOfSale, filterQuery))
  const filterTasksByQuery = (list: ITask[]): ITask[] => list.filter((task) => filterTaskByQuery(task, filterQuery))

  const renderDayOfWeekContent = (dayOfWeek: Date): JSX.Element => {
    const visitList = sortedAndFilteredVisitList(dayOfWeek)
    const taskList = sortedAndFilteredTaskList(dayOfWeek)

    if (!visitList.length && !taskList.length) {
      return <Typography className={classes.emptyMessage}>{t('noVisits')}</Typography>
    }
    const visitListByQuery = filterVisitsByQuery(visitList)
    const taskListByQuery = filterTasksByQuery(taskList)
    if (!visitListByQuery.length && !taskListByQuery.length) {
      return <Typography className={classes.emptyMessage}>{t('noQueryVisits')}</Typography>
    }

    return (
      <div className={classes.dayOfWeekContent}>
        <Box mb={1} width={'100%'}>
          {taskList.map((task) => (
            <CardTask key={task.code} task={task} refetch={async () => taskListData.retry()} variant='week' />
          ))}
        </Box>
        {visitListByQuery.map((item) => (
          <VisitsListWeekItem
            key={item.code}
            size='small'
            name={item.pointOfSale?.name ?? ''}
            address={item.pointOfSale?.address?.fullAddress ? item.pointOfSale.address.fullAddress : ''}
            city={item.pointOfSale?.address?.city ? item.pointOfSale.address.city : ''}
            code={item.code}
            posCode={item.pointOfSale?.code ?? ''}
            registeredName={item.pointOfSale && getRegisteredNameFromPos(item.pointOfSale)}
            status={item.status}
            source={item.source}
          />
        ))}
      </div>
    )
  }

  return (
    <div className={classes.weekListWrap}>
      <div className={classes.weekList} style={{ marginTop: 20 }}>
        {listOfDays.map((dayOfWeek) => (
          <div className={classes.weekItem} key={dayOfWeek.valueOf()}>
            <DayOfWeekDivider dayOfWeek={dayOfWeek} />
            <div className={classes.weekItemVisits}>{renderDayOfWeekContent(dayOfWeek)}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default React.memo(ActivitiesListByWeek)

const DayOfWeekDivider: React.FC<{ dayOfWeek: Date }> = ({ dayOfWeek }) => {
  const classes = useStyles()
  const { i18n } = useTranslation()

  return (
    <div className={classes.dayOfWeekDividerRoot}>
      <Typography variant='h5' className={classes.dayOfWeekDividerTitle}>
        <span className={classes.dayOfWeekDividerTitleDate}>{dateFormat(dayOfWeek, 'd.MM.')}</span>
        <span className={classes.dayOfWeekDividerTitleDay}>{dateFormat(dayOfWeek, 'EEEEEE', i18n.language)}</span>
      </Typography>
      <Typography
        variant='h5'
        className={classnames(classes.dayOfWeekDividerTitle, classes.dayOfWeekDividerTitleMobile)}
      >
        <span className={classes.dayOfWeekDividerTitleDay}>{dateFormat(dayOfWeek, 'EEEEEE, d.MM', i18n.language)}</span>
      </Typography>
    </div>
  )
}
