import React, { useEffect, useRef } from 'react'

import { Card, CardActionArea, createStyles, makeStyles, Theme } from '@material-ui/core'
import { DesktopAccessDisabled } from '@material-ui/icons'
import classnames from 'classnames'

import { appToast } from '../../../../utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      position: 'relative',
      width: 'calc(16% - 16px)',
      paddingBottom: 'calc(16% - 16px)',
      margin: theme.spacing(1),
      borderRadius: '12px !important',
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.14)',
      [theme.breakpoints.down('sm')]: {
        width: 'calc(25% - 16px)',
        paddingBottom: 'calc(25% - 16px)',
      },
      [theme.breakpoints.down('xs')]: {
        margin: 5,
        width: 'calc(33.33% - 10px)',
        paddingBottom: 'calc(33.33% - 10px)',
      },
    },
    isContrast: {
      backgroundColor: 'rgba(48, 119, 184, 0.08)',
    },
    mediaWrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      '&:hover $focusHighlight': {
        opacity: 0,
      },
    },
    input: {
      display: 'none',
    },
    label: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignContent: 'center',
    },
    icon: {
      transform: 'scale(1.1)',
    },
    text: {
      margin: '10px 0',
      padding: '0 5px',
      width: '100%',
      fontWeight: 500,
      textTransform: 'uppercase',
      textAlign: 'center',
    },
    iconPhoto: {
      [theme.breakpoints.down('xs')]: {
        width: 24,
        height: 20,
      },
    },
  }),
)

interface IPhotoInput {
  onChange?: (file: Blob | undefined) => void
  showCameraOnOpen?: boolean
  permissionsAddPhoto: boolean
  allowAddPhoto: boolean
  isContrast?: boolean
}

export const PhotoInput: React.FC<IPhotoInput> = ({
  showCameraOnOpen,
  onChange,
  permissionsAddPhoto,
  allowAddPhoto,
  isContrast,
}) => {
  const classes = useStyles()

  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (!permissionsAddPhoto) {
      return
    }
    if (showCameraOnOpen) {
      inputRef.current?.click()
    }
  }, [])

  return (
    <Card className={classnames(classes.card, { [classes.isContrast]: isContrast })}>
      <CardActionArea className={classes.mediaWrapper}>
        <label className={classes.label}>
          <input
            className={classes.input}
            ref={inputRef}
            type='file'
            accept='image/*'
            capture='environment'
            onClick={(evt) => {
              if (!permissionsAddPhoto) {
                evt.preventDefault()
                appToast.info('Добавление фотографий возможно только с мобильного устройства')
              }

              if (!allowAddPhoto) {
                evt.preventDefault()
                appToast.info('Нет разрешения на добавление фотографий')
              }
            }}
            onChange={(evt) => {
              const file = evt.target.files?.[0]
              if (file) {
                const blob = new Blob([file], { type: file.type })
                if (onChange) {
                  onChange(blob)
                } else {
                  console.warn('Unimplemented method adding a photo')
                }
              }
            }}
          />
          {permissionsAddPhoto && allowAddPhoto ? (
            <svg
              className={classes.iconPhoto}
              width='41'
              height='34'
              viewBox='0 0 41 34'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M29.1006 6.03242L27.9339 3.46576C27.2339 1.83242 25.6005 0.899094 23.7339 0.899094H16.7339C14.8672 0.899094 13.2339 1.83242 12.5339 3.46576L11.3672 6.03242C10.9006 6.73242 10.2006 7.43242 9.26725 7.43242H5.76725C3.20059 7.43242 1.10059 9.53242 1.10059 12.0991V28.4324C1.10059 30.9991 3.20059 33.0991 5.76725 33.0991H26.7673H34.9339C37.5006 33.0991 39.6006 30.9991 39.6006 28.4324V20.2657V12.0991C39.6006 9.53242 37.5006 7.43242 34.9339 7.43242H31.4339C30.2673 7.43242 29.3339 6.96575 29.1006 6.03242Z'
                fill='#C7CDD7'
                stroke='#C7CDD7'
                strokeWidth='1.5'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M19.9677 27.2663C24.6621 27.2663 28.4678 23.4607 28.4678 18.7663C28.4678 14.0719 24.6621 10.2663 19.9677 10.2663C15.2733 10.2663 11.4678 14.0719 11.4678 18.7663C11.4678 23.4607 15.2733 27.2663 19.9677 27.2663Z'
                fill={isContrast ? '#EEF4F9' : 'white'}
                // fill='white'
                stroke='#C7CDD7'
                strokeWidth='1.5'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M19.9677 27.2663C24.6621 27.2663 28.4678 23.4607 28.4678 18.7663C28.4678 14.0719 24.6621 10.2663 19.9677 10.2663C15.2733 10.2663 11.4678 14.0719 11.4678 18.7663C11.4678 23.4607 15.2733 27.2663 19.9677 27.2663Z'
                fill={isContrast ? '#EEF4F9' : 'white'}
                // fill='white'
                stroke='#C7CDD7'
                strokeWidth='1.5'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path d='M19.999 14.5007L19.999 23.5007' stroke='#C7CDD7' strokeWidth='2' strokeLinecap='round' />
              <path d='M24.499 19.0007L15.499 19.0007' stroke='#C7CDD7' strokeWidth='2' strokeLinecap='round' />
            </svg>
          ) : (
            <DesktopAccessDisabled color='primary' className={classes.icon} />
          )}
          {/*<Typography color='primary' className={classes.text}>{permissionsAddPhoto ? 'Сделать фото' : <span>Недоступно на&nbsp;ПК</span>}</Typography>*/}
        </label>
      </CardActionArea>
    </Card>
  )
}
