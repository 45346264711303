/* eslint-disable @typescript-eslint/promise-function-async */
export interface IAwaitable<T = unknown> {
  promise: Promise<T>
}

export function whenAllDone(awaitables: IAwaitable[]): Promise<Array<PromiseSettledResult<unknown>>> {
  return Promise.allSettled(awaitables.map(a => a.promise))
}

export const completedAwaitable = <IAwaitable>{
  promise: Promise.resolve()
}

export class CompositeAwaitable implements IAwaitable<Array<PromiseSettledResult<unknown>>> {
  private readonly _items?: IAwaitable[]
  private _promise?: Promise<Array<PromiseSettledResult<unknown>>>

  constructor(items?: IAwaitable[]) {
    this._items = items?.filter(item => item != null);
  }

  get promise(): Promise<Array<PromiseSettledResult<unknown>>> {
    return this._promise ?? (this._promise = this._items ? whenAllDone(this._items) : Promise.resolve([]))
  }
}
