import { IBooleanPropertyScreenItem } from '../../../../../model/screen-item'
import { checkPredicate } from '../../../../../model/script-predicate'
import { getContextProperty } from '../../../script-tasks/propertyName'
import { IScriptTaskContext } from '../../../script-tasks/script-task-context'
import { IValidation } from '../validate'
import { IBooleanInputScreenItem } from './boolean-input-screen-item'
import { BooleanItemValue, mapDisplayValue } from './utils'

export function validateBooleanProperty(item: IBooleanPropertyScreenItem, context: IScriptTaskContext): IValidation {
  if (item.editSettings?.required) {
    const value = getContextProperty<BooleanItemValue>(context, item.propertyName)
    const isAnswer = value === false || value === true || value === null
    console.log('validating boolean', value, item, context)
    return {
      isError: !isAnswer,
      message: item.editSettings?.errorHelperText,
    }
  }
  return {
    isError: false,
  }
}

export function validateBooleanInput(item: IBooleanInputScreenItem, context: IScriptTaskContext): IValidation {
  const visible = item.visible ?? { $type: 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate', value: true }
  if (!checkPredicate(visible, undefined, context)) {
    return {
      isError: false,
    }
  }

  const required = item.required ?? { $type: 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate', value: false }
  if (!checkPredicate(required, undefined, context)) {
    return {
      isError: false,
    }
  }

  const value = getContextProperty<BooleanItemValue>(context, item.propertyName)
  const isAnswer = !!mapDisplayValue(value)
  return {
    isError: !isAnswer,
    message: item.errorHelperText,
  }
}
