import React from 'react'

import { Box, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { EmptyMessage } from '../../../components'
import { appToast } from '../../../utils'
import { useSearchVisits } from '../../_common/hooks/useSearchVisits'
import { filterTaskByQuery } from '../../_common/tasks'
import { filterStoreByQuery, sortByCreationTime, sortByStatus } from '../../_common/visits'
import { ErrorPage } from '../../error-page'
import { getRegisteredNameFromPos } from '../../stores/store-menu-page/utils'
import { EmptyActivitiesMessage } from '../activities-list/components/empty-activities-message'
import { CardTask } from '../task-list/card-task'
import { useSearchNonVisitTasks } from '../task-list/useSearchNonVisitTasks'
import VisitsListMonthItem from '../visit-list-month-item/visit-list-month-item'

interface ICalendarDayListVisitsProps {
  periodStartDate: number
  periodEndDate: number
  filterQuery: string
}

const CalendarDayListActivities: React.FC<ICalendarDayListVisitsProps> = ({
  periodStartDate,
  periodEndDate,
  filterQuery,
}) => {
  const visitListData = useSearchVisits({ periodStartDate, periodEndDate }, { withPos: true })
  const taskListData = useSearchNonVisitTasks({ periodStartDate, periodEndDate })

  const { t } = useTranslation('visits')

  if (visitListData.error ?? taskListData.error) {
    const message = t('errorFetchingList')
    console.log(message)
    appToast.error(message)
    return <ErrorPage errorMessage={message} />
  }

  if (visitListData.loading || taskListData.loading) {
    return <EmptyMessage message={t('loading')} marginTop={10} />
  }

  if ((!visitListData.value || !visitListData.value.length) && (!taskListData.value || !taskListData.value.length)) {
    return <EmptyActivitiesMessage>{t('noVisits')}</EmptyActivitiesMessage>
  }

  const sortedAndFilteredVisitsList = visitListData
    .value!.filter((visit) => filterStoreByQuery(visit.pointOfSale, filterQuery))
    // .filter(filterCancelledVisitsByToday)
    .sort(sortByCreationTime)
    // .sort(sortByCode)
    // .sort(sortByDate)
    .sort(sortByStatus)

  const sortedAndFilteredTasksList = taskListData.value!.filter((task) => filterTaskByQuery(task, filterQuery))

  if (!sortedAndFilteredVisitsList.length && !sortedAndFilteredTasksList.length) {
    return <EmptyActivitiesMessage>{t('noQueryVisits')}</EmptyActivitiesMessage>
  }

  // const filterListByQuery = sortedAndFilteredList
  // console.log(filterQuery, filterListByQuery)
  return (
    <>
      <Box mt={1}>
        {sortedAndFilteredTasksList.map((task) => (
          <CardTask key={task.code} task={task} refetch={async () => taskListData.retry()} variant='month' />
        ))}
      </Box>
      <Grid container>
        {sortedAndFilteredVisitsList.map((item) => (
          <Grid item xs={12} key={item.code} style={{ marginBottom: 8 }}>
            <VisitsListMonthItem
              name={item.pointOfSale?.name ?? ''}
              address={item.pointOfSale?.address?.fullAddress ? item.pointOfSale.address.fullAddress : ''}
              city={item.pointOfSale?.address?.city ? item.pointOfSale.address.city : ''}
              code={item.code}
              posCode={item.pointOfSale?.code ?? ''}
              registeredName={item.pointOfSale && getRegisteredNameFromPos(item.pointOfSale)}
              status={item.status}
              source={item.source}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default React.memo(CalendarDayListActivities)
