import { useContext, useMemo } from 'react'

import { noop } from 'lodash'

import { LogManager } from '../../infrastructure/logger'
import { IAnswer } from '../../model/answer'
import { checkAnswerPredicate } from '../../model/answer-predicate'
import { Code } from '../../model/base'
import { IQuestionnaire } from '../../model/questionnaire'
import { ISurvey } from '../../model/survey'
import { ApiContext } from '../../providers'
import { appToast } from '../../utils'

const logger = LogManager.getLogger('SurveyMethods')

interface SurveyMethods {
  onBooleanAnswer: (questionCode: Code, resolution: boolean | null) => Promise<void>
  onTextAnswer: (questionCode: Code, answerText: string) => Promise<void>
  onHyperlinkAnswer: (questionCode: Code, hyperlinkAnswer: string) => Promise<void>
  onNumericAnswer: (questionCode: Code, numericAnswer: number) => Promise<void>
  onPhotoAnswer: (questionCode: Code, answerPhotoIds: string[]) => Promise<void>
  onRemoveAnswer: (questionCode: Code) => Promise<void>
  onSaveComment: (questionCode: Code, comment: string) => Promise<void>
  onPhotosSubmit: (questionCode: Code, photoIds: string[]) => Promise<void>
  onNullAnswer: (questionCode: Code) => Promise<void>
}

const defaultValue = {
  onBooleanAnswer: noop,
  onTextAnswer: noop,
  onHyperlinkAnswer: noop,
  onNumericAnswer: noop,
  onPhotoAnswer: noop,
  onRemoveAnswer: noop,
  onSaveComment: noop,
  onPhotosSubmit: noop,
  onNullAnswer: noop,
} as SurveyMethods

export function useSurveyMethods(
  survey: ISurvey | null | undefined,
  questionnaire: IQuestionnaire | null | undefined,
  refetch: () => unknown,
): SurveyMethods {
  const api = useContext(ApiContext)

  const methods = useMemo(() => {
    if (!survey || !questionnaire) {
      return defaultValue
    }

    const surveyCode = survey.code
    const onBooleanAnswer = async (questionCode: Code, resolution: boolean | null): Promise<void> => {
      try {
        await api.survey.giveBooleanAnswer({
          questionCode,
          surveyCode,
          resolution,
        })
        if (survey.status !== 'InProgress') {
          await api.survey.setSurveyStatus({ surveyCode, status: 'InProgress' })
        }
      } catch (e) {
        const msg = 'Ошибка при сохранении ответа на вопрос'
        logger.error('onBooleanAnswerHandler', msg, e, { surveyCode, questionCode, resolution })
        appToast.error(msg)
      }
      await refetch()
    }
    const onTextAnswer = async (questionCode: Code, answerText: string): Promise<void> => {
      try {
        await api.survey.giveTextAnswer({
          questionCode,
          surveyCode,
          answerText,
        })
        if (survey.status !== 'InProgress') {
          await api.survey.setSurveyStatus({ surveyCode, status: 'InProgress' })
        }
      } catch (e) {
        const msg = 'Ошибка при сохранении ответа на вопрос'
        logger.error('onBooleanAnswerHandler', msg, e, { surveyCode, questionCode, answerText })
        appToast.error(msg)
      }
      await refetch()
    }

    const onHyperlinkAnswer = async (questionCode: Code, hyperlinkAnswer: string): Promise<void> => {
      try {
        await api.survey.giveTextAnswer({
          questionCode,
          surveyCode,
          answerText: hyperlinkAnswer,
        })
        if (survey.status !== 'InProgress') {
          await api.survey.setSurveyStatus({ surveyCode, status: 'InProgress' })
        }
      } catch (e) {
        const msg = 'Ошибка при сохранении ответа на вопрос'
        logger.error('onHyperlinkAnswerHandler', msg, e, { surveyCode, questionCode, answerText: hyperlinkAnswer })
        appToast.error(msg)
      }
      await refetch()
    }

    const onNullAnswer = async (questionCode: Code): Promise<void> => {
      // if (loading.get().has(questionCode)) return
      try {
        await api.survey.giveNullAnswer({
          questionCode,
          surveyCode,
        })
        if (survey.status !== 'InProgress') {
          await api.survey.setSurveyStatus({ surveyCode, status: 'InProgress' })
        }
      } catch (e) {
        const msg = 'Ошибка при сохранении ответа на вопрос(Null Answer)'
        logger.error('onNullAnswerHandler', msg, e, { surveyCode, questionCode })
        appToast.error(msg)
      }
      await refetch()
    }

    const onNumericAnswer = async (questionCode: Code, numericAnswer: number): Promise<void> => {
      try {
        await api.survey.giveNumericAnswer({
          questionCode,
          surveyCode,
          answerValue: numericAnswer,
        })
        if (survey.status !== 'InProgress') {
          await api.survey.setSurveyStatus({ surveyCode, status: 'InProgress' })
        }
        // }
      } catch (e) {
        const msg = 'Ошибка при сохранении ответа на вопрос'
        logger.error('onNumericAnswerHandler', msg, e, { surveyCode, questionCode, answerNumeric: numericAnswer })
        appToast.error(msg)
      }
      await refetch()
    }

    const onPhotoAnswer = async (questionCode: Code, answerPhotoIds: string[]): Promise<void> => {
      try {
        if (answerPhotoIds.length) {
          await api.survey.givePhotoAnswer({
            questionCode,
            surveyCode,
            answerPhotoIds,
          })
          if (survey.status !== 'InProgress') {
            await api.survey.setSurveyStatus({ surveyCode, status: 'InProgress' })
          }
        } else {
          await onRemoveAnswer(questionCode)
        }
      } catch (e) {
        const msg = 'Ошибка при сохранении ответа на вопрос'
        logger.error('onPhotoAnswerHandler', msg, e, { surveyCode, questionCode, answerPhotoIds })
        appToast.error(msg)
      }
      await refetch()
    }

    async function removeAnswersRecursively(questionCode: Code, answers: IAnswer[]): Promise<void> {
      await api.survey.removeAnswer({
        questionCode,
        surveyCode,
      })
      const answersLeft = answers.filter((answer) => answer.questionCode !== questionCode)
      const invalidAnswers = answersLeft.filter((answer) => {
        const q = questionnaire?.questions.find((question) => question.code === answer.questionCode)
        if (q?.precondition && !checkAnswerPredicate(q.precondition, answersLeft)) {
          return true
        }
        return false
      })

      console.log('invalid answers after remove', invalidAnswers)
      try {
        await Promise.all(
          invalidAnswers.map(async (answer) => removeAnswersRecursively(answer.questionCode, answersLeft)),
        )
      } catch (e: unknown) {
        if (e instanceof Error && e.message.includes('found')) {
          console.log('ignore duplicate recursive requests', e.message)
        } else {
          throw e
        }
      }
    }

    const onRemoveAnswer = async (questionCode: Code): Promise<void> => {
      try {
        await removeAnswersRecursively(questionCode, survey.answers ?? [])
      } catch (e) {
        const msg = 'Ошибка при удалении ответа на вопрос'
        logger.error('onRemoveAnswerHandler', msg, e, { surveyCode, questionCode })
        appToast.error(msg)
      }
      await refetch()
    }

    const onSaveComment = async (questionCode: Code, comment: string): Promise<void> => {
      try {
        await api.survey.setAnswerComment({
          questionCode,
          surveyCode,
          comment,
        })
        if (survey.status !== 'InProgress') {
          await api.survey.setSurveyStatus({ surveyCode, status: 'InProgress' })
        }
      } catch (e) {
        const msg = 'Ошибка при сохранении комментария к вопросу'
        logger.error('onBooleanAnswerHandler', msg, e, { surveyCode, questionCode, comment })
        appToast.error(msg)
      }
      await refetch()
    }

    const onPhotosSubmit = async (questionCode: Code, photoIds: string[]): Promise<void> => {
      try {
        await api.survey.setAnswerPhotos({ photoIds, questionCode, surveyCode })
      } catch (e) {
        const msg = 'Ошибка при сохранении фотографий в ответе'
        logger.error('onBooleanAnswerHandler', msg, e, { surveyCode, questionCode, photoIds })
        appToast.error(msg)
      }
      await refetch()
    }

    return {
      onBooleanAnswer,
      onTextAnswer,
      onHyperlinkAnswer,
      onNumericAnswer,
      onPhotoAnswer,
      onRemoveAnswer,
      onSaveComment,
      onPhotosSubmit,
      onNullAnswer,
    }
  }, [survey, questionnaire])

  return methods
}
