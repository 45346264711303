import React, { useContext } from 'react'

import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from "react-i18next";
import { generatePath, Link } from 'react-router-dom'
import { useAsync } from 'react-use'

import { EmptyMessage } from '../../../../../components'
import { ApiContext } from '../../../../../providers'
import { useFeatureRoute } from '../../../../custom-app-feature/app-feature-context'
import { ErrorPage } from '../../../../error-page'
import { reportsRoutes } from '../../../reports-app-feature/reports-app-feature'
import SmListItem from './sm-list-item'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  }),
)

interface SmListProps {
  fromDate: number
  toDate: number
}

const SmList: React.FC<SmListProps> = (props) => {
  const { fromDate, toDate } = props
  const { t } = useTranslation('reports')
  const api = useContext(ApiContext)
  const classes = useStyles()
  const listOps = useAsync(
    async () =>
      api.employee.searchStoreManagerServices({
        fromDate,
        toDate,
      }),
    [fromDate, toDate],
  )
  const featureRoute = useFeatureRoute()

  if (listOps.error) {
    console.error('Ошибка при загрузке списка обучений')
    return <ErrorPage errorMessage='Ошибка при загрузке списка обучений' />
  }

  if (!listOps.value || !listOps.value.length) {
    if (listOps.loading) {
      return null
    }
    return <EmptyMessage message={t('trainingsEmpty')} marginTop={4} />
  }

  const listData = listOps.value

  return (
    <div className={classes.root}>
      {listData.map((item) => {
        const pathname = generatePath(reportsRoutes.smItemPage, { featureRoute, id: item.code })
        return (
          <Link key={item.code} to={pathname}>
            <SmListItem
              pointOfSaleName={item.pointOfSaleName ?? ''}
              ownerName={item.ownerName ?? ''}
              date={item.endDate}
            />
          </Link>
        )
      })}
    </div>
  )
}

export default SmList
