import { AxiosRequestConfig } from 'axios'

import { IBaseError } from '../../model/errors'
import { IEvent } from '../../utils/event'

export enum AuthErrorCode {
  Strange = -1,
  Unknown,

  InvalidClient,
  InvalidUserNameOrPassword,

  GetToken401Error,
  GetToken403Error,
  GetTokenBusinessError,
  GetTokenOtherError,
  GetTokenUnavailableError
}

export class AuthError extends Error implements IBaseError {
  readonly code: AuthErrorCode
  readonly status?: unknown
  readonly details: unknown

  constructor(code: AuthErrorCode, message: string, status?: unknown, details?: unknown) {
    super(message)
    this.code = code
    this.status = status
    this.details = details
  }
}

export type UserName = string

export interface ICredentials {
  login: string
  password: string
}

export interface ISessionInfo {
  userName: UserName
  isValid: boolean
  expiryTime?: Date
}

export interface IConfirm {
  confirm: () => void
}

export default interface IAuthService
{
  /**
   * Событие 'пользователь разлогинился'
   */
  readonly onLoggedOut: IEvent<UserName>

  /**
   * Событие 'пользователь залогинился'
   */
  readonly onLoggedIn: IEvent<UserName>

  /**
   * Событие перед автоматическим логаутом
   */
  readonly onBeforeAutoLogout: IEvent<IConfirm>

  /**
   * Имя текущего пользователя, или undefined, если нет текущей сессии аутентификации
   */
  readonly currentUserName: UserName | undefined

  /**
   * Информация о текущей сессии аутентификации, uли undefined, если текущей сессии нет
   */
  readonly currentSession: ISessionInfo | undefined

  /**
   * Возвращает true, если текущий метод авторизации требует предоставить логин-пароль, иначе false
   */
  readonly requireCredentials: boolean

  /**
   * Выполняет вход пользователя по предоставленным данным
   * Возвращает установленную сессию аутентификации
   */
  login: (credentials?: ICredentials) => Promise<ISessionInfo | undefined>

  /**
   * Выполняет выход текущего пользователя (если он есть)
   * Возвращает true - логаут выполнен, false - логаут не выполнен (нет текущей сессии аутентификации)
   * Очищает сессию аутентификации (если есть)
   */
  logout: () => Promise<boolean>

  /**
   * Очищает текущую сессию аутентификации (если есть)
   */
  logoff: () => void

  configureAxiosRequest: (config: AxiosRequestConfig) => void
}
