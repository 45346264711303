import React from 'react'

import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

interface ITextQuestionDiscription {
  description: React.ReactNode
}

const useStyles = makeStyles(() => ({
  description: {
    whiteSpace: 'pre-wrap',
    lineHeight: '1.2em',
  },
}))

const TextQuestionDescription: React.FC<ITextQuestionDiscription> = ({ description }) => {
  const classes = useStyles()
  return (
    <Box letterSpacing='0.25px' mb={2} whiteSpace='pre-wrap'>
      <Typography className={classes.description} variant='body2'>
        {description}
      </Typography>
    </Box>
  )
}

export default TextQuestionDescription
