import React, { useContext, useEffect } from 'react'

import { Box, Button } from '@material-ui/core'

import { POSStore } from '../../data/schema'
import { generateEntityCode } from '../../model/base'
import { IPointOfSale } from '../../model/pos'
import { ApiContext, ProfileContext } from '../../providers'

interface PosTestProps {
  children?: never
}

export const PosTest: React.FC<PosTestProps> = (props) => {
  const profile = useContext(ProfileContext)
  const api = useContext(ApiContext)

  const generate = (): void => {
    if (!api.storageService) return
    const positionRole = profile.value.fieldPositionRole?.code
    const poses = Array.from(new Array(15000), (_, i) => {
      const n = String(i + 1).padStart(5, '0')
      const pos: IPointOfSale = {
        code: generateEntityCode('MOCKPOS'),
        codeSpace: 'mock-pos',
        name: `Mock pos №${n}`,
        updateTime: Date.now(),
        address: {
          city: `mock city`,
          country: `mock country`,
          fullAddress: `mock address  №${n}`,
          zipCode: '',
        },
        associatedLegalEntities: [
          {
            associationType: 'OwnedBy',
            legalEntity: {
              $type: 'PMI.BDDM.Staticdata.LegalEntityReference',
              code: 'mock-entity',
              codeSpace: 'MDM',
              name: 'Copy pasta',
            },
          },
        ],
        assortmentRange: [
          {
            code: 'F0101',
            codeSpace: 'MDM',
            name: 'Cigarettes',
          },
        ],
        coordinates: {
          $type: 'PMI.BDDM.Common.GeoCoordinates',
          latitude: 60,
          longitude: 60,
        },
        status: 'Active',
        positionRoleCoverage: positionRole ? [positionRole] : undefined,
      }
      return pos
    })
    console.log('poses gen', poses)

    void api.storageService.put(POSStore, poses)
  }

  const remove = async (): Promise<void> => {
    if (!api.storageService) return
    const poses: string[] = []
    const posIter = api.storageService.selectWhere<IPointOfSale>(POSStore, (pos) => pos.codeSpace === 'mock-pos')
    for await (const pos of posIter) {
      poses.push(pos.code)
    }
    console.log('deleting mock poses', poses)
    void api.storageService.deleteByKeys(POSStore, poses)
  }

  useEffect(() => {
    console.log('PosTest mount', props)
  }, [])

  return (
    <Box padding={3} paddingTop={5}>
      <Button variant='contained' color='primary' onClick={generate}>
        add 15000 POSes to indexedDB
      </Button>
      <Box mt={2} />
      <Button variant='contained' color='primary' onClick={remove}>
        remove mock POSes
      </Button>
    </Box>
  )
}
