import { IAwaitable } from './awaitable'

export class Deferred<T> implements IAwaitable<T> {
  private _promise?: Promise<T>
  private _resolve?: (arg: T) => void
  private _reject?: (reason?: unknown) => void

  resolve(arg: T): void {
    if (this._resolve) {
      const resolve = this._resolve
      this._resolve = undefined
      this._reject = undefined
      resolve(arg)
    }
  }

  reject(reason?: unknown): void {
    if (this._reject) {
      const reject = this._reject
      this._resolve = undefined
      this._reject = undefined
      reject(reason)
    }
  }

  get promise(): Promise<T> {
    return this._promise ?? (this._promise = new Promise<T>((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject
    }))
  }
}