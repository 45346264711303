import React from 'react'

import { loader } from '@monaco-editor/react'
import ReactDOM from 'react-dom'
import { pdfjs } from 'react-pdf'

import { App } from './features/app'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import './assets/scss/main.scss'

pdfjs.GlobalWorkerOptions.workerSrc = '/assets/libs/pdf.worker_v2.16.105.min.js'

loader.config({
  paths: {
    vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.38.0/min/vs',
  },
})

const errorsToWarn: string[] = ['Warning:', 'Material-UI:']
const oldConsError = console.error
// eslint-disable-next-line @typescript-eslint/no-explicit-any
console.error = function (...args: any) {
  let toWarn = false

  if (typeof args[0] === 'string') {
    errorsToWarn.forEach(function (_s: string) {
      if (args[0].startsWith(_s)) {
        toWarn = true
      }
    })
  }

  toWarn ? console.warn(...args) : oldConsError(...args)
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()
