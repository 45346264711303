import React from 'react'

import { AppBar, Fade, IconButton, LinearProgress, Toolbar, Typography } from '@material-ui/core'
import { Check, Delete } from '@material-ui/icons'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

import { ModalPage } from '../../features/admin/screen-editors/modal-page'
import { DocumentViewer, useDocumentViewerStyles } from '../../features/viewers/document-viewer/document-viewer'
import { PageContent } from '../../layout/page-content'
import { useCollectionBlob } from './api'

export function ContentDocumentViewer(props: {
  open: boolean
  documentTitle?: string
  code?: string | null
  blob?: Blob | null
  elementsCount?: number
  onNext?: () => void
  onPrev?: () => void
  onClose: () => void
  onSelect?: () => void
  onDelete?: () => void
}): JSX.Element | null {
  const { code, open, blob, elementsCount, onNext, onPrev, onClose, onSelect, onDelete, documentTitle } = props
  const classes = useDocumentViewerStyles()
  const { data: blobLoaded, isLoading, error } = useCollectionBlob(!blob && code ? code : undefined)
  const blobCombined = blob ?? blobLoaded ?? undefined
  const showArrows = !!elementsCount && elementsCount > 1 && !!onNext && !!onPrev

  const customButtons: React.ComponentProps<typeof DocumentViewer>['customButtons'] = [
    ...(onSelect
      ? [
          {
            icon: <Check />,
            title: 'Выбрать',
            onClick: () => {
              onSelect()
              onClose()
            },
          },
        ]
      : []),
    ...(onDelete
      ? [
          {
            icon: <Delete />,
            title: 'Удалить',
            onClick: () => {
              onDelete()
              onClose()
            },
          },
        ]
      : []),
  ]

  return (
    <ModalPage open={open} fullscreen>
      {!isLoading && open && (
        <DocumentViewer
          title={`Просмотр документа${documentTitle ? `: ${documentTitle}` : ''}`}
          isReadOnly={false}
          blob={blobCombined}
          error={error as Error}
          onClose={onClose}
          isPrev={showArrows}
          isNext={showArrows}
          onPrevClick={() => onPrev?.()}
          onNextClick={() => onNext?.()}
          customButtons={customButtons}
        />
      )}
      {isLoading && (
        <>
          <AppBar position='relative' className={classes.appBar}>
            <Toolbar disableGutters>
              <IconButton className={classes.backButton} onClick={() => onClose}>
                <ArrowBackIosIcon />
              </IconButton>
              <Typography className={classes.title} variant='h6' id='app-title' noWrap>
                Просмотр документа
              </Typography>
            </Toolbar>
          </AppBar>

          <PageContent>
            <Fade in={true}>
              <LinearProgress variant='query' />
            </Fade>
          </PageContent>
        </>
      )}
    </ModalPage>
  )
}
