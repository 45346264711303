import React, { useEffect, useMemo, useRef, useState } from 'react'

import { Workbox } from 'workbox-window'

import { APP_VERSION } from '../../version'
// import { LogManager } from '../../infrastructure/logger'
import { IServiceWorkerContext, ServiceWorkerContext } from './service-worker-context'

// const logger = LogManager.getLogger('ServiceWorkerProvider')

export const ServiceWorkerProvider: React.FC = ({ children }) => {
  const wb = useRef<Workbox | null>(null)
  const [version, setVersion] = useState('')
  useEffect(() => {
    const instance = new Workbox('/service-worker.js')
    wb.current = instance
    void (async () => {
      await instance.register()
      const version = await instance.messageSW({ type: 'GET_VERSION' })
      setVersion(version)
    })()
  }, [])

  const value: IServiceWorkerContext = useMemo(() => ({ version }), [version])

  return <ServiceWorkerContext.Provider value={value}>{children}</ServiceWorkerContext.Provider>
}

export function useVersion(): string {
  return APP_VERSION
}
