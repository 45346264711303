import React, { FormEvent, useContext, useState } from 'react'

import {
  Paper,
  Container,
  Box,
  useMediaQuery,
  Theme,
  TextField,
  Button,
  Link,
  InputAdornment,
  IconButton
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import classnames from 'classnames'
import { useNetworkState } from 'react-use'

import { AuthContext } from '../../providers'
import { LoginPageNoNetwork } from './login-page-no-network'
import { MaintenanceMarker } from './maintenance-marker'

const PAGE_BACKGROUND_COLOR = 'rgba(0, 61, 165, 0.02)'

const useStyles = makeStyles((theme) => ({
  wrap: {
    height: 'calc(100% + 160px)',
    background: PAGE_BACKGROUND_COLOR
  },
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  paper: {
    width: '100%',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 24
  },
  isSmall: {
    height: '100%',
    borderRadius: 'unset'
  },
  formWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(7),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  submit: {
    height: 48,
    width: 126,
    borderRadius: 28,
    margin: theme.spacing(4, 0, 3)
  },
  forgotPassword: {
    color: theme.palette.primary.main,
    fontSize: 16
  }
}))

const LoginPageByCredentials: React.FC = () => {
  const authContext = useContext(AuthContext)
  const networkState = useNetworkState()
  const isOffline = !networkState.online
  const classes = useStyles()
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'))
  const [loginValue, setLogin] = useState<string>('')
  const [passwordValue, setPassword] = useState<string>('')
  const [showPassword, setShowPassword] = useState(false)

  const submitForm = async (e: FormEvent): Promise<void> => {
    e.preventDefault()

    try {
      await authContext.login({
        login: loginValue,
        password: passwordValue
      })
    } catch (error) {
      setPassword('')
    }
  }

  const handleClickShowPassword = (): void => {
    setShowPassword(!showPassword)
  }

  if (!authContext.currentUserName && isOffline) {
    return <LoginPageNoNetwork />
  }

  return (
    <Box className={classes.wrap}>
      <Container disableGutters fixed className={classes.root} component='main' maxWidth='sm'>
        <Paper
          elevation={10}
          className={classnames(classes.paper, {
            [classes.isSmall]: isSmall
          })}
        >
          <Container fixed className={classes.formWrap} component='section' maxWidth='xs'>
            <img className='Header-logo' src='/pmi-logo-login-new.png' alt='Philip Morris logo' />
            <MaintenanceMarker />

            <form className={classes.form} noValidate onSubmit={submitForm}>
              <TextField
                variant='outlined'
                margin='normal'
                fullWidth
                id='email'
                label='Логин'
                name='email'
                autoComplete='login'
                autoFocus
                value={loginValue}
                onChange={(e) => setLogin(e.target.value)}
              />
              <TextField
                variant='outlined'
                margin='normal'
                fullWidth
                name='password'
                label='Пароль'
                type={showPassword ? 'text' : 'password'}
                id='password'
                autoComplete='current-password'
                value={passwordValue}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Button
                size='large'
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                disabled={!loginValue.length || !passwordValue.length}
                className={classes.submit}
              >
                Войти
              </Button>

              <Link href='forgot-password' className={classes.forgotPassword}>
                Забыли пароль?
              </Link>
            </form>
          </Container>
        </Paper>
      </Container>
    </Box>
  )
}

export default LoginPageByCredentials
