/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/indent */
import { intersectionWith, sortBy } from 'lodash'
import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts'
import { Content, ContentTable, ContentText, Style, TDocumentDefinitions } from 'pdfmake/interfaces'

import { IEntityReference, isReferenceToEntity } from '../../../../../model/base'
import { IDictionaryContent } from '../../../../../model/content'
import { IDictionaryItem } from '../../../../../model/dictionary-item'
import { IRepeaterScreenItem } from '../../../../../model/repeater-screen-item'
import { ICompositeReportProcessor } from '../../../../../model/report-processor'
import {
  IScreenItem,
  isRepeater,
  isScreenItemGroup,
  ISurveysPropertyScreenItem,
  IViewSurveyScreenItem,
  ScreenItemGroup,
} from '../../../../../model/screen-item'
import { ISurvey } from '../../../../../model/survey'
import { HorizontalAlignmentType } from '../../../../../model/table-screen-item'
import { IApiContext } from '../../../../../providers/api/api-context'
import { dateFormat, formatTemplateString, getDictionaryContentText, isNonNullable } from '../../../../../utils'
import { getSurveysByPropertyName } from '../../../../_common/hooks/useCreateSurvey'
import { getContextProperty, getPropertyAny } from '../../../script-tasks/propertyName'
import { IScriptTaskContext as IPropertiesContext } from '../../../script-tasks/script-task-context'
import { surveyPdf, surveyStyles } from '../../survey-pdf'
import { getStringValueBase } from '../get-string-value-base'
import { RepeaterRendererAsync } from '../repeater-screen-item'
import { getQuestionnaireByAssignments } from '../surveys/utils'
import { ITableRecord } from '../table-item/editorUtils'
import { getKeyValuePairValue } from '../table-item/label-cell/data-source'
import { formatLabelCell } from '../table-item/label-cell/utils'
import { groupByIndex } from '../table-item/table-item-portrait'
import { filterTableRows } from '../table-item/utils'
import { formatCustom } from '../text-screen-item'
import { sortScreenItems } from '../utils'
import { TaskDirectionsPdf } from './task-directions'

interface IProps {
  resultFile: ICompositeReportProcessor
  propertiesContext: IPropertiesContext
  api: IApiContext
}

interface IStyle {
  name: string
  style: Style
}

function styleDef<T extends Record<string, IStyle>>(styleDict: T): T {
  return styleDict
}

const styles = styleDef({
  title: {
    name: 'title',
    style: {
      fontSize: 16,
      bold: true,
      margin: [0, 0, 0, 10],
      alignment: 'center',
    },
  },
  group: {
    name: 'group',
    style: {
      bold: true,
      fontSize: 15,
      margin: [0, 10, 0, 10],
    },
  },
  label: {
    name: 'label',
    style: {
      fontSize: 15,
      margin: [0, 2, 0, 6],
    },
  },
  table: {
    name: 'table',
    style: {
      fontSize: 15,
      margin: [0, 0, 0, 8],
    },
  },
  edAnswer: {
    // used in TaskDirections
    name: 'edAnswer',
    style: {
      fontSize: 15,
      margin: [20, 0, 0, 0],
    },
  },
})

async function SurveyListPdf(
  item: ISurveysPropertyScreenItem,
  propertiesContext: IPropertiesContext,
  api: IApiContext,
): Promise<Content> {
  const surveyRefs: IEntityReference[] = getContextProperty(propertiesContext, item.propertyName, [])
  const surveys = await api.survey.getSurveys(surveyRefs.map((ref) => ref.code))
  const questionnaireCode = item.questionnaire.code
  const questionnaire = (await api.questionnaire.getQuestionnaire(questionnaireCode))!

  const fittingSurveys = surveys?.filter((survey) => survey.questionnaire.code === questionnaireCode) ?? []
  return sortBy(fittingSurveys, ['creationTime']).map((survey, i) => {
    const surveyName = `${item.surveyDisplayNamePrefix ?? item.displayName + ' '}${i + 1}`
    console.log({ surveyName, prefix: item.surveyDisplayNamePrefix, i })
    return surveyPdf({ survey, questionnaire, surveyName })
  })
}

async function ViewSurveyPdf(
  item: IViewSurveyScreenItem,
  propertiesContext: IPropertiesContext,
  api: IApiContext,
): Promise<Content> {
  let questionnaireCode: string
  let survey: ISurvey | undefined
  const surveys: ISurvey[] = getSurveysByPropertyName(item.propertyName, propertiesContext)
  try {
    const qref = getQuestionnaireByAssignments(item, [])
    questionnaireCode = qref.code
    survey = surveys.find((survey) => survey.questionnaire.code === questionnaireCode)
  } catch (e: unknown) {
    if (!item.questionnaires?.length) {
      return 'В параметрах настройки на указан опросник'
    }
    const fittingQuestionnaires = intersectionWith(
      item.questionnaires,
      propertiesContext.questionnaires,
      isReferenceToEntity,
    )
    if (!fittingQuestionnaires?.length) {
      return 'В параметрах настройки на указан опросник'
    }
    questionnaireCode = fittingQuestionnaires[0].code
    survey = surveys.find((survey) => survey.questionnaire.code === questionnaireCode)
  }

  const questionnaire = await api.questionnaire.getQuestionnaire(questionnaireCode)

  if (!questionnaire) {
    return `Не найден опросник ${questionnaireCode}`
  }

  if (item.hideEmpty && !survey) {
    return ''
  }

  if (survey) {
    const surveyName = item.displayName
    return surveyPdf({ survey, questionnaire, surveyName })
  } else {
    return item.nullValueCaption ?? 'Нет данных'
  }
}

async function TableGroup(
  group: ScreenItemGroup,
  propertiesContext: IPropertiesContext,
  api: IApiContext,
): Promise<Content> {
  return [
    {
      text: group.displayName ?? '',
      style: styles.group.name,
    },
    {
      style: styles.table.name,
      table: {
        widths: ['auto', '*'],
        body: (
          await Promise.all(
            group.items.map(async (item) => {
              switch (item.$type) {
                case 'PMI.FACE.BDDM.Extensions.Classes.StringPropertyScreenItem': {
                  let value = getContextProperty(propertiesContext, item.propertyName, ' - ')
                  console.log(item.propertyName)
                  if (item.propertyName.toLowerCase().includes('visit.startdate')) {
                    console.log('found date')
                    value = dateFormat(value as unknown as number, 'dd.MM.yyyy')
                  }
                  return [item.displayName, value]
                }
                case 'PMI.FACE.BDDM.Extensions.Classes.TextScreenItem': {
                  let value: string | undefined
                  if (item.format) {
                    const format = await getStringValueBase(item.format, propertiesContext)
                    value = formatTemplateString(format ?? '', (prop) => formatCustom(propertiesContext, prop))
                  } else {
                    value = getContextProperty(propertiesContext, item.propertyName)
                  }
                  if (!value && item.hideEmpty) {
                    return
                  }
                  return [item.displayName, value || (item.nullValueCaption ?? ' - ')]
                }
                case 'PMI.FACE.BDDM.Extensions.Classes.SurveysPropertyScreenItem': {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  return (await SurveyListPdf(item, propertiesContext, api)) as any
                }
                case 'PMI.FACE.BDDM.Extensions.Classes.ViewSurveyScreenItem': {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  return (await ViewSurveyPdf(item, propertiesContext, api)) as any
                }
                default:
                // skip line
              }
            }),
          )
        ).filter(isNonNullable),
      },
    },
  ]
}

async function ListItem(
  item: IScreenItem,
  propertiesContext: IPropertiesContext,
  api: IApiContext,
): Promise<ContentText | ContentTable | undefined> {
  interface IDictionaryItemWithContent extends IDictionaryItem {
    content: IDictionaryContent
  }

  switch (item.$type) {
    case 'PMI.FACE.BDDM.Extensions.Classes.StringPropertyScreenItem':
      const value = getContextProperty(propertiesContext, item.propertyName) as string

      if (item.viewSettings?.hideEmpty && !value) {
        return {
          text: '',
        }
      }

      return {
        text: [{ text: item.displayName + ': ', bold: true }, value || (item.viewSettings?.nullValueCaption ?? '-')],
        style: styles.label.name,
      }
    case 'PMI.FACE.BDDM.Extensions.Classes.TextScreenItem': {
      let value: string | undefined
      if (item.format) {
        const format = await getStringValueBase(item.format, propertiesContext)
        value = formatTemplateString(format ?? '', (prop) => formatCustom(propertiesContext, prop))
      } else {
        value = getContextProperty(propertiesContext, item.propertyName)
      }
      if (!value && item.hideEmpty) {
        return
      }
      return {
        text: [
          item.displayName ? { text: item.displayName + ': ', bold: true } : '',
          value || (item.nullValueCaption ?? '-'),
        ],
        style: styles.label.name,
      }
    }
    case 'PMI.FACE.BDDM.Extensions.Classes.DictionaryItemPropertyScreenItem': {
      const entry: IDictionaryItem | IDictionaryItem[] | undefined = getContextProperty(
        propertiesContext,
        item.propertyName,
      )
      const value = entry instanceof Array ? entry[0] : entry
      return {
        text: [
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          { text: item.displayName + ': ', bold: true },
          value
            ? `${value.name ?? ''}. ${getDictionaryContentText((value as IDictionaryItemWithContent).content)}`
            : ' - ',
        ],
        style: styles.label.name,
      }
    }
    case 'PMI.FACE.BDDM.Extensions.Classes.SalesExpertEducationTaskDirectionsScreenItem':
      return [
        //
        { text: item.displayName ?? '', style: styles.group.name },
        ...TaskDirectionsPdf(item, propertiesContext),
        { text: ' ' },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ] as any
    case 'PMI.FACE.BDDM.Extensions.Classes.SurveysPropertyScreenItem':
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return SurveyListPdf(item, propertiesContext, api) as any
    case 'PMI.FACE.BDDM.Extensions.Classes.ViewSurveyScreenItem': {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return ViewSurveyPdf(item, propertiesContext, api) as any
    }
    case 'PMI.FACE.BDDM.Extensions.Classes.SignatureImageScreenItem':
      const image = getContextProperty(propertiesContext, item.propertyName)

      const isRejection =
        item.signatureRejectionPropertyName !== undefined
          ? (getContextProperty(propertiesContext, item.signatureRejectionPropertyName, false) as boolean)
          : undefined

      if (item.actionKind !== 'Edit' && item.viewSettings?.hideEmpty && !image && !isRejection) {
        return {
          text: '',
        }
      }

      return {
        columns: [
          { text: item.displayName ?? '', style: styles.group.name },
          image && !isRejection
            ? { image, fit: [100, 40], margin: 5 }
            : isRejection
            ? { text: 'От подписи отказался (лась)', margin: [10, 13, 10, 10] }
            : { text: item.viewSettings?.nullValueCaption ?? '', margin: [10, 13, 10, 10] },
        ],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any
    case 'PMI.FACE.BDDM.Extensions.Classes.LabelScreenItem': {
      const value = formatTemplateString(item.text, (prop) => getContextProperty(propertiesContext, prop, ``))
      return {
        text: [{ text: item.displayName ? `${item.displayName}: ` : '', bold: true }, { text: value }],
        style: styles.label.name,
      }
    }
    case 'PMI.FACE.BDDM.Extensions.Classes.TableScreenItem': {
      const rows: ITableRecord[] | null = getContextProperty(propertiesContext, item.propertyName)
      const cells = item.cells

      const filteredRows = filterTableRows(rows, item, propertiesContext)

      if (!filteredRows?.length) {
        if (item.hideEmpty) {
          return
        }
        return {
          text: [
            { text: item.displayName ? `${item.displayName}: ` : '', bold: true },
            { text: item.nullValueCaption ?? '' },
          ],
          style: styles.label.name,
        }
      }

      if (cells) {
        const linesOfRow = groupByIndex(cells, (cell) => cell.landscapeSettings.lineNumber ?? 0).map((line) =>
          line.sort(
            (cell1, cell2) => (cell1.landscapeSettings.columnNumber ?? 0) - (cell2.landscapeSettings.columnNumber ?? 0),
          ),
        )

        const tableLine = linesOfRow[0]

        const headers = tableLine.map((cell) => {
          return {
            displayName: cell.landscapeSettings.displayName ?? '',
            align:
              (cell.landscapeSettings.horizontalAlignment.toLowerCase() as Lowercase<HorizontalAlignmentType>) ??
              'left',
            size: cell.landscapeSettings.columnSize ?? 'auto',
          }
        })

        const headerNames = headers.map((header) => header.displayName)
        return [
          {
            text: item.displayName ?? '',
            style: styles.group.name,
          },
          {
            style: styles.table.name,
            table: {
              headerRows: 1,
              widths: headers.map((header) => {
                switch (typeof header.size) {
                  case 'number':
                    return `${header.size}%`
                  case 'string':
                    return header.size
                  default:
                    return 'auto'
                }
              }),

              body: [
                headerNames.some((n) => n) ? headerNames : undefined,
                ...filteredRows.flatMap((row) => {
                  return linesOfRow.map((line) => {
                    const values = line.map((col) => {
                      const value = getPropertyAny(row, col.propertyName ?? '')
                      switch (col.control.$type) {
                        case 'PMI.FACE.BDDM.Extensions.Classes.LabelCell':
                          if (col.control.format) {
                            return formatLabelCell(col.control, propertiesContext, row) ?? ''
                          }
                          const dataSource = col.control.dataSource
                          if (dataSource?.$type === 'PMI.FACE.BDDM.Extensions.Classes.KeyValuePairsDataSource') {
                            return (
                              getKeyValuePairValue(dataSource, value as string) || col.control.nullValueCaption || ' - '
                            )
                          }
                          return value || col.control.nullValueCaption || ' - '
                        case 'PMI.FACE.BDDM.Extensions.Classes.BooleanIndicatorCell':
                          const has = value as boolean
                          return has
                            ? col.control.trueValueCaption ?? 'Да'
                            : !has
                            ? col.control.falseValueCaption ?? 'Нет'
                            : col.control.nullValueCaption ?? 'N/A'
                      }
                      return 'unknown type cell'
                    })
                    if (line.length === 1 && line.length < headers.length) {
                      console.log('hacking line colspan, headers:', headers, 'values:', values)
                      return values.map((text) => ({ text, colSpan: headers.length }))
                    }
                    return values
                  })
                }),
              ].filter(isNonNullable),
            },
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ] as any
      }

      return { text: '' }
    }
    default:
      return { text: '' }
  }
}

async function ListGroup(
  group: ScreenItemGroup,
  propertiesContext: IPropertiesContext,
  api: IApiContext,
): Promise<Content> {
  return [
    { text: group.displayName ?? '', style: styles.group.name },
    ...(await Promise.all(group.items.map(async (item) => ListItem(item, propertiesContext, api)))).filter(
      isNonNullable,
    ),
  ]
}

async function Repeater(
  item: IRepeaterScreenItem,
  propertiesContext: IPropertiesContext,
  api: IApiContext,
): Promise<Content> {
  const items = await RepeaterRendererAsync({
    item,
    api,
    propertiesContext,
    messageRender: async (props) =>
      Promise.resolve({
        text: `${props.title ? `${props.title}: ` : ''}${props.message}`,
      }),
    itemsRender: async (item, propertiesContext, api) => renderItem(item, propertiesContext, api),
  })
  const itemsFiltered = items.filter((item): item is Exclude<typeof item, undefined> => !!item)
  return itemsFiltered
}

async function renderItem(
  item: ScreenItemGroup | IScreenItem,
  propertiesContext: IPropertiesContext,
  api: IApiContext,
): Promise<Content | undefined> {
  if (isScreenItemGroup(item)) {
    const group = item
    if (group.viewSettings?.viewMode === 'List') {
      return await ListGroup(group, propertiesContext, api)
    }
    return await TableGroup(item, propertiesContext, api)
  } else if (isRepeater(item)) {
    return await Repeater(item, propertiesContext, api)
  } else {
    return await ListItem(item, propertiesContext, api)
  }
}

export async function generatePdfComposite({ resultFile, propertiesContext, api }: IProps): Promise<Blob> {
  console.log('generating pdf composite', resultFile)

  const sorted = sortScreenItems(resultFile.items)
  // sorted.push({
  //   $type: 'Group',
  //   displayName: 'Опрос (группа)',
  //   viewSettings: { viewMode: 'List' },
  //   items: [
  //     {
  //       $type: 'SurveysProperty',
  //       actionKind: 'View',
  //       displayName: 'Опрос',
  //       orderNumber: 0,
  //       propertyName: 'task.components',
  //       questionnaire: {
  //         code: 'AUDIT_POS_TEST_02'
  //       },
  //       surveyDisplayNamePrefix: 'заголовок опроса'
  //     }
  //   ],
  //   orderNumber: 2
  // })

  const title = resultFile.displayName ?? 'CompositeReportPdf' // TODO: remove temp placeholder
  const docDefinition: TDocumentDefinitions = {
    content: [
      {
        text: title,
        style: styles.title.name,
      },
      ...(await Promise.all(sorted.map(async (item) => renderItem(item, propertiesContext, api)))).filter(
        isNonNullable,
      ),
    ],

    styles: {
      [styles.title.name]: styles.title.style,
      [styles.group.name]: styles.group.style,
      [styles.label.name]: styles.label.style,
      [styles.table.name]: styles.table.style,
      [styles.edAnswer.name]: styles.edAnswer.style,
      ...surveyStyles,
    },
    info: {
      title,
      creationDate: new Date(),
      // keywords: task.templateCode
    },
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [35, 55, 35, 55],
    compress: false,
  }

  // stream is the only way to catch errors here
  // https://github.com/bpampuch/pdfmake/issues/2407#issuecomment-1221028738
  return new Promise<Blob>((resolve, reject) => {
    const doc = pdfMake.createPdf(docDefinition, undefined, undefined, pdfFonts.pdfMake.vfs).getStream()
    const chunks: Uint8Array[] = []
    doc.on('data', (data) => chunks.push(data))
    doc.on('end', () => {
      resolve(new Blob(chunks))
    })
    doc.on('error', reject)
    doc.end()
  })
}
