import React from 'react'

import { createStyles, Divider, Grid, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'

import { grays } from '../../../../layout/theme'
import { IDteParticipantProfile } from '../../../../model/dte-participant-profile'
import { useIsSmall } from '../../../_common/hooks/useIsSmall'
import { CardItemWrapper } from '../../components/card-item-wrapper'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalLine: {
      margin: 0,
      backgroundColor: grays.gray5,
      width: 2,
    },
    nameCol: {
      alignItems: 'center',
      maxWidth: '47%',
      flex: '0 0 47%',
      paddingRight: '7%',
    },
    statusCol: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '23%',
      flex: '0 0 23%',
      paddingRight: '7%',
      paddingLeft: '7%',
    },
    typeProfileCol: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '29%',
      flex: '0 0 29%',
      paddingLeft: '8%',
    },
    name: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.1px',
    },
    code: {
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: '0.25px',
      color: grays.gray2,
      [theme.breakpoints.down('xs')]: {
        lineHeight: '22px',
      },
    },
    status: {
      width: '100%',
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: '0.1px',
      [theme.breakpoints.down('xs')]: {
        lineHeight: '20px',
      },
    },
    typeProfile: {
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: '0.1px',
    },
  }),
)

interface IParticipantItem {
  participant: IDteParticipantProfile
  onClick: () => void
}

export const ParticipantItem: React.FC<IParticipantItem> = ({ participant, onClick }) => {
  const classes = useStyles()
  const isSmall = useIsSmall()
  const { posCode } = useParams<{ posCode: string }>()

  const posRelations = participant.poSes.find((pos) => pos.productLocation.code === posCode)

  if (isSmall) {
    return (
      <CardItemWrapper onClick={onClick}>
        <Typography noWrap className={classes.name}>
          {participant.contact.surname} {participant.contact.name}
        </Typography>
        <Typography noWrap className={classes.status}>
          {posRelations?.status} | {participant.type}
        </Typography>
        <Typography noWrap className={classes.code}>
          {participant.code}
        </Typography>
      </CardItemWrapper>
    )
  }

  return (
    <CardItemWrapper onClick={onClick}>
      <Grid container>
        <Grid item className={classes.nameCol}>
          <Typography noWrap className={classes.name}>
            {participant.contact.surname} {participant.contact.name}
          </Typography>
          <Typography noWrap className={classes.code}>
            {participant.code}
          </Typography>
        </Grid>
        {<Divider orientation='vertical' flexItem className={classes.verticalLine} />}
        <Grid item className={classes.statusCol}>
          <Typography noWrap className={classes.status} align='center'>
            {posRelations?.status}
          </Typography>
        </Grid>
        {<Divider orientation='vertical' flexItem className={classes.verticalLine} />}
        <Grid item className={classes.typeProfileCol}>
          <Typography noWrap className={classes.typeProfile}>
            {participant.type}
          </Typography>
        </Grid>
      </Grid>
    </CardItemWrapper>
  )
}

export default ParticipantItem
