import { AnyExpr, PropPath, RelExpr, TokenLocation } from './parser/parse'

export function getChildren(node: AnyExpr): AnyExpr[] {
  switch (node?.kind) {
    case 'rel_expr':
      return [node.lhs, node.rhs]

    case 'logic_expr':
      return [node.lhs, node.rhs]

    case 'prop_path':
      return []

    default:
      return []
  }
}

export function findNodePathAtOffset(root: AnyExpr, offset: number): AnyExpr[] {
  function find(node: AnyExpr, path: AnyExpr[]): AnyExpr[] {
    if (!node) {
      return path
    }

    if ('location' in node) {
      if (containsOffset(node, offset)) {
        return [node, ...path]
      } else {
        return path
      }
    }

    const children = getChildren(node)

    for (const child of children) {
      const result = find(child, [node, ...path])
      if (result.length > path.length + 1) {
        return result
      }
    }

    return path
  }

  return find(root, [])
}

export function containsOffset<T extends { location: TokenLocation }>(token: T | null, offset: number): boolean {
  if (token === null) {
    return false
  }

  return token.location.start <= offset && offset <= token.location.end
}

export function propSegValue(segment: PropPath['path'][number]): string | number | null {
  if (!segment) {
    return null
  }

  switch (segment.kind) {
    case 'identifier':
      return segment.name
    case 'index':
      return segment.value
  }
}

const CONTEXT_REF_IDENT_NAME = '$'

function isContextRef(segment: PropPath['path'][number]): boolean {
  return segment?.kind === 'identifier' && segment.name === CONTEXT_REF_IDENT_NAME
}

function isScopingExpr(expr: AnyExpr): expr is RelExpr {
  const ops = ['EXISTS', 'NOT_EXISTS']
  return expr?.kind === 'rel_expr' && ops.includes(expr.op!) && expr.lhs.kind === 'prop_path'
}

export function resolvePropPath(nodePath: AnyExpr[]): PropPath['path'] | null {
  const [node, ...restNodePath] = nodePath
  if (node?.kind === 'prop_path') {
    const [leadingSegment, ...restSegments] = node.path
    if (isContextRef(leadingSegment)) {
      const scopingExpr = restNodePath
        .slice(1) // skip rel_expr containing current prop_path
        .find(isScopingExpr)

      // unexpected context prop_path without parent scope
      if (!scopingExpr) {
        return null
      }

      const scopePath = resolvePropPath([scopingExpr.lhs, ...restNodePath.slice(restNodePath.indexOf(scopingExpr))])

      if (!scopePath) {
        return null
      }

      return [...scopePath, ...restSegments]
    } else {
      return node.path // non-context prop_path
    }
  } else {
    return null
  }
}
