import { QuestionnaireStore, SurveyStore, TaskStore, VisitStore } from '../../data/schema'
import {
  BooleanAnswer,
  HyperlinkAnswer,
  IAnswer,
  isBooleanAnswer,
  isSelectProfileAnswer,
  NullAnswer,
  NumericAnswer,
  PhotoAnswer,
  SelectProfileAnswer,
  setBooleanAnswerReply,
  TextAnswer
} from '../../model/answer'
import { Code } from '../../model/base'
import { IContent, IMimeContent } from '../../model/content'
import { BusinessError, BusinessErrorCode, ValidationError, ValidationErrorCode } from '../../model/errors'
import { IQuestion } from '../../model/question'
import { IQuestionnaire } from '../../model/questionnaire'
import { IQuestionnaireProfileReference } from '../../model/questionnaire-profile'
import { ISurvey } from '../../model/survey'
import { trace } from '../../utils/trace'
import ISurveyService, {
  GiveBooleanAnswerRequest,
  GiveHyperlinkAnswerRequest,
  GiveNullAnswerRequest,
  GiveNumericAnswerRequest,
  GivePhotoAnswerRequest,
  GiveProfileAnswerRequest,
  GiveTextAnswerRequest,
  RemoveAnswerRequest,
  SetAnswerCommentRequest,
  SetAnswerPhotosRequest,
  SetAnswerReplyRequest,
  SetSurveyStatusRequest,
} from '../survey-service-api'
import { LocalStorageBaseService } from './local-storage-base-service'

function getAnswerScore(question: IQuestion, resolution?: boolean | null): number | undefined {
  if (question.$type === 'PMI.FACE.BDDM.Extensions.Classes.FaceBooleanQuestion') {
    if (resolution == null) {
      return undefined
    }
    // если у вопроса нет MaxScore, то Score не заполняем
    if (question.maxScore == null) {
      return undefined
    }
    // если ответ - true и у вопроса есть maxScore, то в поле answer.Score надо подставлять question.MaxScore
    // если ответ - false и у вопроса есть maxScore, то в поле answer.Score надо подставлять 0
    return resolution ? question.maxScore : 0
  }
}

export default class LocalStorageSurveyService extends LocalStorageBaseService implements ISurveyService
{
  private static readonly __className = 'LocalStorageSurveyService'

  async getQuestionnaire(questionnaireCode: Code): Promise<IQuestionnaire | null> {
    if (!questionnaireCode) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing,
        'questionnaireCode is a required parameter',
        ['questionnaireCode']
      )
    }
    return this._getQuestionnaire(questionnaireCode, this._storage)
  }

  async getQuestionnaires(questionnaireCodes: Code[]): Promise<IQuestionnaire[]> {
    return await this._storage.getByKeys<IQuestionnaire>(QuestionnaireStore, questionnaireCodes)
  }

  async getSurvey(surveyCode: Code): Promise<ISurvey | null> {
    if (!surveyCode) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing, 'surveyCode is a required parameter', ['surveyCode']
      )
    }
    return await this._getSurvey(surveyCode, this._storage)
  }

  /**
   * Получение списка опросов
   */
  async getSurveys(surveyCodes: Code[]): Promise<ISurvey[]> {
    if (!surveyCodes) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing, 'surveyCodes is a required parameter', ['surveyCodes']
      )
    }
    return await this._storage.getByKeys<ISurvey>(SurveyStore, surveyCodes)
  }

  private async _giveAnswer(
    surveyCode: string,
    questionCode: string,
    answerFactory: (question: IQuestion, survey: ISurvey, oldAnswer?: IAnswer) => IAnswer
  ): Promise<IAnswer> {
    if (!surveyCode || !questionCode) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing,
        'surveyCode and questionCode are required parameters',
        ['surveyCode', 'questionCode']
      )
    }

    return await this._storage.execute(
      [VisitStore, SurveyStore, TaskStore, QuestionnaireStore],
      async (tx) => {
        const survey = await this._getSurveyOrThrow(surveyCode, tx)
        const questionnaire = await this._getQuestionnaireOrThrow(survey.questionnaire.code, tx)

        const question = questionnaire.questions?.find((q) => q.code === questionCode)
        if (!question) {
          throw new BusinessError(
            BusinessErrorCode.EntityNotFound,
            `Question ${questionCode} not found in questionnaire ${questionnaire.code}`,
            {type: 'question', code: questionCode}
          )
        }

        if (!survey.answers) {
          survey.answers = []
        }

        let answer: IAnswer

        const oldAnswerIndex = survey.answers.findIndex(a => a.questionCode === questionCode)
        if (oldAnswerIndex > -1) {
          answer = answerFactory(question, survey, survey.answers[oldAnswerIndex]) 
          survey.answers[oldAnswerIndex] = answer
        } else {
          const newAnswer = answerFactory(question, survey)
          survey.answers.push(newAnswer)
          answer = newAnswer
        }

        survey.updateTime = Date.now()

        await tx.put(SurveyStore, survey)

        await this._updateTaskChangeTime(survey._taskCode, tx)
        await this._updateVisitChangeTime(survey._visitCode, tx)

        return answer
      })
  }

  @trace()
  async giveBooleanAnswer({ resolution, surveyCode, questionCode }: GiveBooleanAnswerRequest): Promise<IAnswer> {
    if (resolution === undefined) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing,'resolution is required parameter',['resolution']
      )
    }
    const now = Date.now()
    return this._giveAnswer(
      surveyCode,
      questionCode,
      (question, survey, oldAnswer) => {
        const answer: BooleanAnswer = {
          comment: undefined,
          ...oldAnswer as BooleanAnswer,
          $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceBooleanAnswer',
          questionCode: question.code,
          maxScore: question.maxScore,
          askDate: now,
          replyDate: now,
          score: getAnswerScore(question, resolution),
        }
        setBooleanAnswerReply(answer, resolution)
        return answer
      }
    )
  }

  @trace()
  async giveTextAnswer({ answerText, score, surveyCode, questionCode }: GiveTextAnswerRequest): Promise<IAnswer> {
    if (answerText === undefined) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing,'answerText is required parameters',['answerText']
      )
    }
    const now = Date.now()
    return this._giveAnswer(
      surveyCode,
      questionCode,
      (question, survey, oldAnswer) => <TextAnswer>{
        comment: undefined,
        ...oldAnswer,
        $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceTextAnswer',
        questionCode: question.code,
        reply: answerText == null ? undefined : answerText,
        maxScore: question.maxScore,
        askDate: now,
        replyDate: now,
        score: score
      }
    )
  }

  @trace()
  async giveProfileAnswer({ profileCode, questionCode, surveyCode }: GiveProfileAnswerRequest): Promise<IAnswer> {
    if (!profileCode) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing, 'profileCode is required parameter', ['profileCode']
      )
    }
    const now = Date.now()
    return this._giveAnswer(
      surveyCode,
      questionCode,
      (question, survey, oldAnswer) => {
        const answer: SelectProfileAnswer = {
          comment: undefined,
          ...oldAnswer,
          $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceSelectProfileAnswer',
          questionCode: question.code,
          askDate: now,
          replyDate: now,
          score: getAnswerScore(question),
          maxScore: question.maxScore,
          reply: {
            $type: 'PMI.BDDM.Transactionaldata.FieldForceQuestionnaireFlowReference',
            code: profileCode,
            codeSpace: this.defaultCodeSpace,
          },
        }
        survey.profileCode = profileCode
        return answer
      })
  }

  @trace()
  async giveNumericAnswer({ answerValue, questionCode, surveyCode }: GiveNumericAnswerRequest): Promise<IAnswer> {
    if (answerValue == null) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing, 'answerValue is required parameter', ['answerValue']
      )
    }
    const now = Date.now()
    return this._giveAnswer(
      surveyCode,
      questionCode,
      (question, survey, oldAnswer) => <NumericAnswer>{
        comment: undefined,
        ...oldAnswer,
        $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceNumericAnswer',
        questionCode: question.code,
        askDate: now,
        replyDate: now,
        score: getAnswerScore(question),
        maxScore: question.maxScore,
        reply: answerValue
      }
    )
  }

  @trace()
  async givePhotoAnswer({ answerPhotoIds, questionCode, surveyCode }: GivePhotoAnswerRequest): Promise<IAnswer> {
    if (answerPhotoIds == null || answerPhotoIds.length === 0) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing, 'answerPhotoIds is required parameter', ['answerPhotoIds']
      )
    }
    const now = Date.now()
    return this._giveAnswer(
      surveyCode,
      questionCode,
      (question, survey, oldAnswer) => <PhotoAnswer>{
        comment: undefined,
        ...oldAnswer,
        $type: 'PMI.FACE.BDDM.Extensions.Classes.FacePhotoAnswer',
        questionCode: question.code,
        askDate: now,
        replyDate: now,
        score: getAnswerScore(question),
        maxScore: question.maxScore,
        reply: {
          parts: answerPhotoIds.map(
            (photoId) => <IMimeContent>{
              $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceMimeContentRef',
              type: 'binary/image',
              target: photoId,
              code: photoId
            })
        }
      }
    )
  }

  @trace()
  async giveNullAnswer({ questionCode, surveyCode }: GiveNullAnswerRequest): Promise<IAnswer> {
    const now = Date.now()
    return this._giveAnswer(
      surveyCode,
      questionCode,
      (question) =>
        <NullAnswer>{
          $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceNullAnswer',
          questionCode: question.code,
          askDate: now,
          replyDate: now,
          comment: undefined,
        },
    )
  }

  @trace()
  async giveHyperlinkAnswer({ answerLink, questionCode, surveyCode }: GiveHyperlinkAnswerRequest): Promise<IAnswer> {
    if (answerLink == null) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing, 'answerLink is required parameter', ['answerLink']
      )
    }
    const now = Date.now()
    return this._giveAnswer(
      surveyCode,
      questionCode,
      (question, survey, oldAnswer) => <HyperlinkAnswer>{
        comment: undefined,
        ...oldAnswer,
        $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceHyperlinkAnswer',
        questionCode: question.code,
        askDate: now,
        replyDate: now,
        score: getAnswerScore(question),
        maxScore: question.maxScore,
        reply: answerLink
      }
    )
  }

  @trace()
  async removeAnswer({ surveyCode, questionCode }: RemoveAnswerRequest): Promise<void> {
    if (!surveyCode || !questionCode) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing,
        'questionCode and surveyCode are required parameters',
        ['questionCode', 'surveyCode']
      )
    }

    await this._storage.execute(
      [VisitStore, TaskStore, SurveyStore, QuestionnaireStore],
      async (tx) => {
        const survey = await this._getSurveyOrThrow(surveyCode, tx)
        const questionnaire = await this._getQuestionnaireOrThrow(survey.questionnaire.code, tx)

        const answerIndex = survey.answers?.findIndex((a) => a.questionCode === questionCode)

        if (!survey.answers || answerIndex == null || answerIndex < 0) {
          throw new BusinessError(
            BusinessErrorCode.EntityNotFound,
            `No answer for question ${questionCode} found in survey ${surveyCode}`,
            {type: 'answer', surveyCode: surveyCode, questionCode: questionCode}
          )
        }

        const isProfileAnswer = survey.answers[answerIndex].$type === 'PMI.FACE.BDDM.Extensions.Classes.FaceSelectProfileAnswer'

        survey.answers.splice(answerIndex, 1)

        if (isProfileAnswer) {
          survey.profileCode = undefined
          // removing other answers with specified question profile
          for (let i = survey.answers.length - 1; i >= 0; i--) {
            const answer = survey.answers[i]
            const question = questionnaire.questions.find((q) => q.code === answer.questionCode)
            if (!question) {
              console.warn(
                `Survey ${surveyCode} contains answer on question ${answer.questionCode}` +
                ` which was not found in current questionnaire ${questionnaire.code}`
              )
            } else {
              if (question.profileCodes != null) {
                survey.answers.splice(i, 1)
              }
            }
          }
        }

        // FACE-142 Allow user to change answer on finished surveys
        if (survey.status === 'Finished') {
          this._setSurveyStatus(survey, 'InProgress')
        }

        survey.updateTime = Date.now()
        await tx.put(SurveyStore, survey)

        await this._updateTaskChangeTime(survey._taskCode, tx)
        await this._updateVisitChangeTime(survey._visitCode, tx)
      })
  }

  @trace()
  async setAnswerComment({ comment, surveyCode, questionCode }: SetAnswerCommentRequest): Promise<IAnswer> {
    if (!surveyCode || !questionCode) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing,
        'surveyCode and questionCode are required parameters',
        ['surveyCode', 'questionCode']
      )
    }
    return await this._storage.execute(
      [VisitStore, TaskStore, SurveyStore],
      async (tx) => {
        const survey = await this._getSurveyOrThrow(surveyCode, tx)
        const answer = survey.answers?.find((a) => a.questionCode === questionCode)
        if (!answer) {
          throw new BusinessError(
            BusinessErrorCode.EntityNotFound,
            `No answer for question ${questionCode} found in survey ${surveyCode}`,
            {type: 'answer', surveyCode: surveyCode, questionCode: questionCode}
          )
        }
        answer.comment = comment

        survey.updateTime = Date.now()
        await tx.put(SurveyStore, survey)

        await this._updateTaskChangeTime(survey._taskCode, tx)
        await this._updateVisitChangeTime(survey._visitCode, tx)

        return answer
      })
  }

  @trace()
  async setAnswerReply(request: SetAnswerReplyRequest): Promise<IAnswer> {
    const { surveyCode, questionCode } = request
    if (!surveyCode || !questionCode) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing,
        'surveyCode and questionCode are required parameters',
        ['surveyCode', 'questionCode']
      )
    }
    return await this._storage.execute(
      [VisitStore, TaskStore, SurveyStore],
      async (tx) => {
        const survey = await this._getSurveyOrThrow(surveyCode, tx)
        const answer = survey.answers?.find((a) => a.questionCode === questionCode)
        if (!answer) {
          throw new BusinessError(
            BusinessErrorCode.EntityNotFound,
            `No answer for question ${questionCode} found in survey ${surveyCode}`,
            {type: 'answer', surveyCode: surveyCode, questionCode: questionCode}
          )
        }

        if (isBooleanAnswer(answer)) {
          setBooleanAnswerReply(answer, request.booleanAnswer)
        } 
        else if (isSelectProfileAnswer(answer)) {
          const ref = request.reply as IQuestionnaireProfileReference
          answer.reply = ref
          survey.profileCode = ref.code
        } 
        else {
          switch (answer.$type) {
            case 'PMI.FACE.BDDM.Extensions.Classes.FaceTextAnswer':
            case 'PMI.FACE.BDDM.Extensions.Classes.FaceHyperlinkAnswer':
              answer.reply = request.reply as string
              break
            case 'PMI.FACE.BDDM.Extensions.Classes.FaceNumericAnswer':
              answer.reply = request.reply as number
              break
            case 'PMI.FACE.BDDM.Extensions.Classes.FacePhotoAnswer':
              answer.reply = request.reply as IContent
              break
          }
        }
        answer.replyDate = Date.now()

        survey.updateTime = Date.now()
        await tx.put(SurveyStore, survey)

        await this._updateTaskChangeTime(survey._taskCode, tx)
        await this._updateVisitChangeTime(survey._visitCode, tx)

        return answer
      })
  }

  @trace()
  async setAnswerPhotos({ photoIds, surveyCode, questionCode }: SetAnswerPhotosRequest): Promise<IAnswer> {
    if (!surveyCode || !questionCode) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing,
        'surveyCode and questionCode are required parameters',
        ['surveyCode', 'questionCode']
      )
    }
    return await this._storage.execute(
      [VisitStore, TaskStore, SurveyStore],
      async (tx) => {
        const survey = await this._getSurveyOrThrow(surveyCode, tx)
        const answer = survey.answers?.find((a) => a.questionCode === questionCode)
        if (!answer) {
          throw new BusinessError(
            BusinessErrorCode.EntityNotFound,
            `No answer for question ${questionCode} found in survey ${surveyCode}`,
            {type: 'answer', surveyCode: surveyCode, questionCode: questionCode}
          )
        }
        // TODO: handle more types of attachment
        answer.attachment = {
          parts: photoIds?.map(
              (code): IMimeContent => ({
                $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceMimeContentRef',
                type: 'binary/image',
                target: code,
                code: code
              })) ?? []
        }
        survey.updateTime = Date.now()
        await tx.put(SurveyStore, survey)

        await this._updateTaskChangeTime(survey._taskCode, tx)
        await this._updateVisitChangeTime(survey._visitCode, tx)

        return answer
      })
  }

  @trace()
  async setSurveyStatus({ status, surveyCode }: SetSurveyStatusRequest): Promise<ISurvey> {
    if (!status || !surveyCode) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing,
        'status and surveyCode are required parameters',
        ['status', 'surveyCode']
      )
    }

    return await this._storage.execute(
      [VisitStore, TaskStore, SurveyStore],
      async (tx) => {
        const survey = await this._getSurveyOrThrow(surveyCode, tx)
        if (this._setSurveyStatus(survey, status)) {
          await tx.put(SurveyStore, survey)
          await this._updateTaskChangeTime(survey._taskCode, tx)
          await this._updateVisitChangeTime(survey._visitCode, tx)
        }
        return survey
      })
  }

  async restoreCanceledSurvey(surveyCode: Code): Promise<boolean> {
    if (!surveyCode) {
      throw new ValidationError(
        ValidationErrorCode.RequiredFieldsMissing, 'surveyCode is a required parameter', ['surveyCode']
      )
    }

    return await this._storage.execute(
      [VisitStore, TaskStore, SurveyStore],
      async (tx) => {
        const survey = await this._getSurveyOrThrow(surveyCode, tx)
        if (survey.status === 'Canceled' && survey.preCanceledStatus) {
          if (this._setSurveyStatus(survey, survey.preCanceledStatus)) {
            await tx.put(SurveyStore, survey)
            await this._updateTaskChangeTime(survey._taskCode, tx)
            await this._updateVisitChangeTime(survey._visitCode, tx)
            return true
          }
        }
        return false
      })
  }
}
