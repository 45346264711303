import React, { FC, useCallback, useEffect, useMemo, useState, useContext } from 'react'

import {
  Box,
  Button,
  Dialog,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import classNames from 'classnames'
import { useAsyncFn } from 'react-use'

import { ValidationFab } from '../../../../components/validation-fab'
import { PageContent } from '../../../../layout/page-content'
import { TitleBar } from '../../../../layout/title-bar'
import { IInlineScriptPredicateFunction } from '../../../../model/screen-item'
import { IActionScreenItem } from '../../../../model/screen-item-base'
import { IPredicate, checkPredicate } from '../../../../model/script-predicate'
import { ITaskTemplate } from '../../../../model/task-template'
import { ApiContext } from '../../../../providers'
import { useMenu } from '../../../admin/_common/use-menu'
import { StoresSearch } from '../../../stores/components'
import { useUpdateProperty } from '../../nested/useUpdateProperty'
import { getContextProperty } from '../../script-tasks/propertyName'
import { SyncScriptRunner } from '../../script-tasks/run-sync-script'
import { useScriptTaskContext } from '../../script-tasks/script-task-context'
import { ItemCard } from './item-card'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      zIndex: theme.zIndex.modal,
      height: '100vh',
      backgroundColor: theme.palette.background.default,
      overflow: 'auto',
    },
    container: {
      height: 'inherit',
      overflow: 'auto',
      padding: '8px ',
      border: '1px solid #E6E9EF',
    },
    content: {
      height: `calc(100vh - 194px)`,
      overflow: `hidden`,
      padding: '24px',
      display: 'flex',
      flexDirection: 'column',
    },
    item: {
      cursor: 'pointer',
      padding: '8px 16px',
      '&:hover': {
        backgroundColor: 'rgba(48,119,184, 0.05)',
      },
    },
    selected: {
      backgroundColor: 'rgba(48,119,184, 0.05)',
    },
    pointer: { cursor: 'pointer' },

    emptyMessage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      color: 'rgba(0, 0, 0, 0.38)',
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: '20px',
    },
  }),
)

export interface ISelectTaskTemplateScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.SelectFieldForceTaskTemplateScreenItem'
  condition?: IInlineScriptPredicateFunction
  required?: IPredicate
  errorHelperText?: string
}

interface SelectTaskTemplateScreenItemProps {
  item: ISelectTaskTemplateScreenItem
  children?: never
}

export interface IFieldForceTaskTemplateReference {
  $type: 'PMI.BDDM.Transactionaldata.FieldForceTaskTemplateReference'
  code: string
  codeSpace?: string
}

const SelectTaskTemplateScreenItemControl: FC<SelectTaskTemplateScreenItemProps> = ({ item }) => {
  const classes = useStyles()
  const updateProperty = useUpdateProperty()
  const context = useScriptTaskContext()
  const [isOpen, setIsOpen] = useState(false)
  const api = useContext(ApiContext)

  const [templatesOps, fetchTemplate] = useAsyncFn(
    async () => {
      try {
        const templates = await api.tasks.getTaskTemplates()
        console.log(templates)
        templates.sort((x, y) => x.name.localeCompare(y.name))
        return templates
      } catch (error) {
        throw new Error('Ошибка при получении списка задач.')
      }
    },
    [],
    { loading: true },
  )

  useEffect(() => {
    void fetchTemplate()
  }, [fetchTemplate])

  const value: IFieldForceTaskTemplateReference | undefined = getContextProperty(context, item.propertyName)
  const isError = item.required ? !value && checkPredicate(item.required, undefined, context) : false

  const toggleDialog = useCallback(() => {
    setIsOpen((prev) => !prev)
  }, [])

  const filteredTemplates = useMemo(() => {
    if (item.condition) {
      const scriptRunner = new SyncScriptRunner(item.condition.body.scriptBody, context, item.condition.argumentName)

      return templatesOps.value?.filter((template) => scriptRunner.run(template))
    }
    return templatesOps.value
  }, [templatesOps.value, item.condition])

  const menu = useMenu({
    async reset() {
      await updateProperty(item.propertyName, undefined)
    },
  })

  const renderTemplateName = useMemo(
    () => templatesOps.value?.find((t) => t.code === value?.code)?.name ?? value?.code ?? '',
    [value, templatesOps.value],
  )

  if (templatesOps.loading) {
    return <LinearProgress />
  }

  return (
    <>
      <ItemCard isError={isError} label={<Typography variant='inherit'>Выбор задачи</Typography>}>
        {!value ? (
          <Button color='primary' onClick={toggleDialog}>
            Выберите задачу
          </Button>
        ) : (
          <Box display='flex' alignItems='center' className={classes.pointer}>
            <Box onClick={toggleDialog} flex={1}>
              {renderTemplateName}
            </Box>
            <Box>
              <IconButton edge='end' {...menu.anchor}>
                <MoreVert />
              </IconButton>
              <Menu {...menu.menu}>
                <MenuItem {...menu.action('reset')}>Сбросить</MenuItem>
              </Menu>
            </Box>
          </Box>
        )}
      </ItemCard>
      {isOpen && (
        <SelectTaskTemplateDialog
          isOpen={isOpen}
          toggleDialog={toggleDialog}
          items={filteredTemplates}
          selectTemplate={async (code) => {
            const findTemplate = filteredTemplates?.find((t) => t.code === code)
            if (findTemplate) {
              const newParam: IFieldForceTaskTemplateReference = {
                $type: 'PMI.BDDM.Transactionaldata.FieldForceTaskTemplateReference',
                code: findTemplate.code,
                codeSpace: findTemplate.codeSpace,
              }
              await updateProperty(item.propertyName, newParam)
            }
          }}
          selectedCode={value?.code}
        ></SelectTaskTemplateDialog>
      )}
    </>
  )
}

export default SelectTaskTemplateScreenItemControl

interface SelectTaskTemplateDialogProps {
  isOpen: boolean
  toggleDialog: () => void
  items: ITaskTemplate[] | undefined
  selectTemplate: (code: string | undefined) => void
  selectedCode: string | undefined
}
const SelectTaskTemplateDialog: FC<SelectTaskTemplateDialogProps> = ({
  isOpen,
  toggleDialog,
  items,
  selectTemplate,
  selectedCode,
}) => {
  const classes = useStyles()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [selectedItem, setSelectedItem] = useState<string | undefined>(undefined)
  const filteredItems = useMemo(
    () => items?.filter((it) => it.name.toLocaleLowerCase().includes(searchQuery.trim().toLocaleLowerCase())),

    [searchQuery, items],
  )

  const applyBtn = useCallback(() => {
    if (selectedItem) {
      selectTemplate(selectedItem)
      toggleDialog()
    }
  }, [selectedItem])

  const onClickItem = useCallback(
    (item: ITaskTemplate) => {
      if (selectedItem === item.code) {
        setSelectedItem(undefined)
      } else {
        setSelectedItem(item.code)
      }
    },
    [selectedItem],
  )

  useEffect(() => {
    if (isOpen) {
      setSelectedItem(selectedCode)
    }
  }, [isOpen])

  const renderItem = (item: ITaskTemplate, index: number): JSX.Element => {
    return (
      <Box
        key={index}
        display='flex'
        alignItems='center'
        component='label'
        mb={1}
        onClick={() => onClickItem(item)}
        className={classNames(classes.item, {
          [classes.selected]: selectedItem === item.code,
        })}
      >
        <Typography>{item.name}</Typography>
      </Box>
    )
  }

  return (
    <Dialog fullScreen open={isOpen} onClose={toggleDialog} disableBackdropClick={true} disableEscapeKeyDown={true}>
      <Box className={classes.root}>
        <TitleBar onBack={toggleDialog}>Выбор задачи</TitleBar>
        <PageContent className={classes.content}>
          <Box mb={3}>
            <StoresSearch query={searchQuery} onChange={setSearchQuery} isContrast={false} />
          </Box>
          {!items?.length && (
            <ItemCard>
              <Box>
                <EmptyMessage text='Нет доступных задач' />
              </Box>
            </ItemCard>
          )}
          <Paper elevation={0} square className={classes.container}>
            {filteredItems?.length ? filteredItems.map(renderItem) : <EmptyMessage text='Поиск не дал результатов' />}
          </Paper>

          <ValidationFab isValid={!!selectedItem} onClick={applyBtn}>
            Применить
          </ValidationFab>
        </PageContent>
      </Box>
    </Dialog>
  )
}

interface EmptyMessageProps {
  text: string
}
const EmptyMessage: React.FC<EmptyMessageProps> = ({ text }) => {
  const classes = useStyles()
  return <div className={classes.emptyMessage}>{text}</div>
}
