import React, { useContext, useEffect } from 'react'

import { Box, Button, CircularProgress, createStyles, makeStyles } from '@material-ui/core'
import { endOfDay, startOfDay } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useAsyncFn, useMountedState, useNetworkState } from 'react-use'

import { SyncIcon } from '../../../components/icons/sync'
import { CodeSpace, createReferenceToEntity, generateEntityCode, SyncErrorCode } from '../../../model/base'
import { IActionScreenItem } from '../../../model/screen-item-base'
import { IGenericUserReference, IPositionRoleReference } from '../../../model/user'
import { IPosVisitReference, VisitStatus } from '../../../model/visit'
import { ApiContext, SyncContext } from '../../../providers'
import { useDefaultCodeSpace } from '../../../providers/config/useDefaultCodeSpace'
import { SyncError } from '../../../services/sync-service-api'
import { appToast } from '../../../utils'
import { getTitle, getDescription } from '../../sync-page/sync-error-format'
import { useUpdateProperty } from '../nested/useUpdateProperty'
import { getContextProperty } from '../script-tasks/propertyName'
import { useScriptTaskContext } from '../script-tasks/script-task-context'
import { TaskAssessment, VisitAssessment } from './audit-merchandise-service-task'
import { makePosExtension } from './use-backup-extensions'

export interface IRefreshAuditedVisitsButtonScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.RefreshAuditedVisitsButtonScreenItem'

  /** @defaultValue ['Finished'] */
  availableVisitStatuses?: VisitStatus[]

  /** @defaultValue 'task.auditedFieldPositionRole' */
  selectedPositionRoleReferencePropertyName?: string

  /** @defaultValue 'task.auditDate' */
  visitsDatePropertyName?: string

  loadMediaMaterials?: boolean
}

interface RefreshAuditedVisitsButtonProps {
  item: IRefreshAuditedVisitsButtonScreenItem
  children?: never
}

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      width: 137,
      height: 40,
    },
  }),
)

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function makeVisitAssessment(codeSpace: CodeSpace) {
  const dateTime = Date.now()
  return {
    $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceFieldForceVisitAssessment',
    code: generateEntityCode('VISASM'),
    codeSpace,
    createdTime: dateTime,
    updateTime: dateTime,
    assessmentDate: dateTime,
    status: 'New',
    result: undefined,
  } as const
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function makeTaskAssessment(codeSpace: CodeSpace) {
  const dateTime = Date.now()
  return {
    $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceFieldForceTaskAssessment',
    assessmentDate: dateTime,
    status: 'NotCompleted' as const,
    code: generateEntityCode('TSKASM'),
    codeSpace,
    createdTime: dateTime,
    updateTime: dateTime,
  }
}

export const RefreshAuditedVisitsButton: React.FC<RefreshAuditedVisitsButtonProps> = (props) => {
  const { item } = props
  const classes = useStyles()
  const localContext = useScriptTaskContext()
  const updateProperty = useUpdateProperty()
  const { syncService } = useContext(SyncContext)
  const api = useContext(ApiContext)
  const { t } = useTranslation('audit-merchandise-service-task')
  const networkState = useNetworkState()
  const isMounted = useMountedState()
  const defaultCodeSpace = useDefaultCodeSpace()

  const executivePositionRole = getContextProperty<IPositionRoleReference>(
    localContext,
    item.selectedPositionRoleReferencePropertyName ?? 'task.auditedFieldPositionRole',
  )

  const [mediaOps, loadMedia] = useAsyncFn(
    async (visitAssessmentsData: VisitAssessment[], force: boolean): Promise<void> => {
      await Promise.all(
        visitAssessmentsData.map(async (va) => {
          if (va._isPhotoLoaded) return
          if (va._isPhotoLoaded === false && !force) return

          if (!va.visit) return
          const refs = await api.audit.getMediaRefsForSupervisedVisit(va.visit.code)

          const isPhotoLoaded = await new Promise<boolean>((resolve) => {
            void api.blobStorage
              ?.receive(
                refs.map((ref) => [ref, {}]),
                1,
                true,
              )
              .then(async (obj) => obj.promise)
              .then((state) => {
                // console.log('va media res', va.code, state)
                return state?.every((res) => res.status === 'fulfilled') ?? false
              })
              .catch(() => false)
              .then(resolve)

            setTimeout(resolve, 120_000, false)
          })
          va._isPhotoLoaded = isPhotoLoaded
          console.log('va media', refs, va.code, isPhotoLoaded)
        }),
      )
      if (isMounted()) {
        await updateProperty('task.visitAssessmentsData', visitAssessmentsData)
      }
      // console.log('media state', Object.fromEntries(mediaState))
    },
    [api],
  )

  const [, loadLocalData] = useAsyncFn(async () => {
    const oldList = getContextProperty<VisitAssessment[] | undefined>(localContext, 'task.visitAssessmentsData')
    const filteredOldList = oldList?.filter((va) => !va.visit)

    const date = getContextProperty(localContext, item.visitsDatePropertyName ?? 'task.auditDate', Date.now())
    const res = await api.audit.searchSupervisedVisits({
      dateFrom: startOfDay(date).valueOf(),
      dateTo: endOfDay(date).valueOf(),
      positionRole: executivePositionRole,
      status: item.availableVisitStatuses ?? ['Finished'],
    })

    res.sort((a, b) => (a.visit.startDate ?? 0) - (b.visit.startDate ?? 0))

    console.log(
      'refresh button visit units',
      // res.map((vu) => new Date(vu.visit.startDate!)),
      res,
    )

    const assessedBy: IGenericUserReference = localContext.task.executive!

    const visitAssessmentsData: VisitAssessment[] = res.map(({ visit, pos, tasks }) => {
      const oldVisitAssessment = oldList?.find((ass) => ass.visit?.code === visit.code)
      const taskAssessmentsData = tasks.map<TaskAssessment>((task) => {
        const oldTaskAssessment = oldVisitAssessment?.taskAssessmentsData?.find((ass) => ass.task?.code === task.code)
        return {
          ...(oldTaskAssessment ?? makeTaskAssessment(defaultCodeSpace)),
          task: createReferenceToEntity(
            task,
            defaultCodeSpace,
            'PMI.FACE.BDDM.Extensions.Classes.FaceFieldForceTaskReference',
          ),
          assessedBy,
          taskStatus: task.status,
          cancelationReason: task.cancelationReason,
        }
      })
      return {
        ...(oldVisitAssessment ?? makeVisitAssessment(defaultCodeSpace)),
        visit: createReferenceToEntity<IPosVisitReference>(
          visit,
          defaultCodeSpace,
          'PMI.BDDM.Transactionaldata.FieldForcePOSVisitReference',
        ),
        assessedBy,
        taskAssessmentsData,
        visitExtension: {
          startDate: visit.startDate,
          endDate: visit.endDate,
          distanceVariance: visit.distanceVariance,
          outOfRouteReason: visit.outOfRouteReason,
          source: visit.source,
        },
        posExtension: makePosExtension(pos),
      }
    })

    if (filteredOldList?.length) {
      visitAssessmentsData.push(...filteredOldList)
    }

    await updateProperty('task.visitAssessmentsData', visitAssessmentsData)
    return visitAssessmentsData
  }, [executivePositionRole?.code, localContext])

  const [{ error, loading }, forceUpdate] = useAsyncFn(async () => {
    if (!networkState.online) {
      return
    }
    const count = await syncService.refreshSupervisedVisits(executivePositionRole)
    if (count) {
      appToast.info(t('nVisitsRefreshed', { count }))
    }
    const data = await loadLocalData()
    if (item.loadMediaMaterials) {
      await loadMedia(data, true)
    }
  }, [loadLocalData, networkState.online])

  useEffect(() => {
    console.log('RefreshAuditedVisitsButton mount', props)
    void checkAndRun()

    async function checkAndRun(): Promise<void> {
      const oldList = getContextProperty<VisitAssessment[] | undefined>(localContext, 'task.visitAssessmentsData')
      let data = oldList ?? []
      if (!oldList?.length || !oldList[0].posExtension) {
        data = await loadLocalData()
      }
      if (item.loadMediaMaterials) {
        await loadMedia(data, false)
      }
    }
  }, [])

  useEffect(() => {
    if (error) {
      let message = error.message
      if (error instanceof SyncError) {
        let description = getDescription(error)
        if (error.code === SyncErrorCode.NetworkError) {
          description = 'Нет подключения к сети'
        }
        message = (
          <div>
            <div>{getTitle(error)}</div>
            <div>{description}</div>
          </div>
        ) as unknown as string
      }
      appToast.info(message)
    }
  }, [error])

  const isLoading = loading || mediaOps.loading

  return (
    <Box pb={3}>
      <Button
        disabled={isLoading}
        className={classes.button}
        startIcon={isLoading ? <CircularProgress size={20} /> : <SyncIcon />}
        style={{ borderRadius: 100 }}
        variant='outlined'
        color='primary'
        onClick={forceUpdate}
      >
        Обновить
      </Button>
    </Box>
  )
}
