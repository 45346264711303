import React, { useCallback, useEffect, useMemo } from 'react'

import { Box, CircularProgress, Paper, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'

import { grays } from '../../../../layout/theme'
import { IActionScreenItem } from '../../../../model/screen-item-base'
import { checkPredicate, IPredicate } from '../../../../model/script-predicate'
import { SubprocessSettings } from '../../../../model/subprocess-setting'
import { ITableRowsFilter, ISortingOptions } from '../../../../model/table-screen-item'
import { formatTemplateString } from '../../../../utils/format-template-string'
import { useIsSmall } from '../../../_common/hooks/useIsSmall'
import { useRichTextImg } from '../../../_common/hooks/useRichTextImg'
import { getContextProperty, getPropertyAny } from '../../script-tasks/propertyName'
import { IScriptTaskContext, useScriptTaskContext } from '../../script-tasks/script-task-context'
import { ItemCard } from './item-card'
import { useGotoSubProcess } from './table-item/useGotoSubProcess'
import { filterTableRows } from './table-item/utils'

const defViewSetting: IViewSettings = { direction: 'column', defaultCardWidth: null }
const getNotEmptyValue = (value: string | null | undefined, defValue: string): string => {
  return value === '' || !value ? defValue : value
}

interface IStylesProps {
  borderRadius?: number
  defaultCardWidth?: string | null
  useDefaultPadding?: boolean
}

const useStyles = makeStyles<Theme, IStylesProps>((theme) => ({
  itemContainer: {
    width: ({ defaultCardWidth }) => getNotEmptyValue(defaultCardWidth, '100%'),
    padding: '0px 4px',
    display: 'flex',
    flexDirection: 'column',
  },
  itemContent: {
    borderRadius: ({ borderRadius }) => `${borderRadius}px`,
    padding: ({ useDefaultPadding }) => (useDefaultPadding ? theme.spacing(2, 3) : 0),
    flex: '1',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 56,
    border: `1px solid ${grays.gray5}`,
    marginBottom: 8,
  },

  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hasRoutePath: {
    cursor: 'pointer',
  },
  label: {
    fontSize: 18,
    lineHeight: '23px',
    fontWeight: 600,
    letterSpacing: '0.15px',
    padding: '16px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '14px 16px',
      fontSize: 16,
    },
  },
}))

export interface IViewRichTextListScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.ViewRichTextListScreenItem'
  richTextContent: string
  /** @defaultValue false */
  useDefaultPadding: boolean
  /** @defaultValue 0 */
  borderRadius: number
  /** @defaultValue record */
  elementVariable?: string
  /** @defaultValue code */
  recordKeyPropertyName?: string
  /** @defaultValue false */
  hideEmpty?: boolean
  /** @defaultValue "Список строк пуст" */
  nullValueCaption?: string
  /** @defaultValue  Direction = column; DefaultWidth = null */
  mobileViewSettings?: IViewSettings
  /** @defaultValue  Direction = column; DefaultWidth = null */
  desktopViewSettings?: IViewSettings
  redirectPath?: string
  rowsFilter?: ITableRowsFilter
  sortingOptions?: ISortingOptions
  visible?: IPredicate
  subprocesses?: SubprocessSettings[]
}

export interface IViewSettings {
  /** @defaultValue column */
  direction?: ViewDirection
  /** @defaultValue null */
  defaultCardWidth?: string | null
}
type ViewDirection = 'row' | 'column'

interface ViewRichTextListScreenItemProps {
  value: string
  item: IViewRichTextListScreenItem
  loading: boolean
  children?: never
}

const ViewRichTextListScreenItem: React.FC<ViewRichTextListScreenItemProps> = (props) => {
  const classes = useStyles({})
  const { value, loading } = props
  useEffect(() => {
    console.log('ViewRichTextListScreenItem resolved value', value)
  }, [])

  return (
    <>
      {loading ? (
        <Box className={classes.loader}>
          <CircularProgress />
        </Box>
      ) : (
        <div style={{ flex: 1 }} dangerouslySetInnerHTML={{ __html: value }}></div>
      )}
    </>
  )
}

interface ViewRichTextScreenItemWithImgProps {
  item: IViewRichTextListScreenItem
  row: unknown
  context: IScriptTaskContext
  elementVariable: string
  recordCode: string
  defaultCardWidth?: string | null
}

const ViewRichTextScreenItemWithImg: React.FC<ViewRichTextScreenItemWithImgProps> = ({
  item,
  row,
  context,
  elementVariable,
  recordCode,
  defaultCardWidth,
}) => {
  const classes = useStyles({
    borderRadius: item.borderRadius,
    useDefaultPadding: item.useDefaultPadding,
    defaultCardWidth: defaultCardWidth,
  })
  const navigate = useNavigate()
  const location = useLocation()
  const newContext = { ...context, [elementVariable]: row }
  const val = formatTemplateString(item.richTextContent, (prop) => getContextProperty(newContext, prop, ''))
  const { isLoading, value } = useRichTextImg(val)
  const gotoSubProcess = useGotoSubProcess(item)

  const redirectPath = formatTemplateString(item.redirectPath ?? '', (prop) => getContextProperty(newContext, prop))
  const defaultAction = useMemo(() => {
    return item.subprocesses?.find((settings) => {
      return checkPredicate(
        settings.availabilityCondition ?? {
          $type: 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate',
          value: false,
        },
        row,
        newContext,
      )
    })
  }, [item, row, newContext])

  const cursorPointer = useMemo(() => {
    return (redirectPath && redirectPath !== '') || defaultAction
  }, [redirectPath, defaultAction])

  const onClickItem = useCallback(() => {
    if (redirectPath && redirectPath !== '') {
      navigate(`${redirectPath}`, { state: { returnUrl: location.pathname } })
    } else {
      if (!defaultAction) return
      gotoSubProcess(defaultAction.process.code, recordCode)
    }
  }, [redirectPath, defaultAction])

  return (
    <div
      onClick={onClickItem}
      className={classNames(classes.itemContainer, {
        [classes.hasRoutePath]: cursorPointer,
      })}
    >
      <Paper elevation={0} square className={classes.itemContent}>
        <ViewRichTextListScreenItem item={item} value={value} loading={isLoading} />
      </Paper>
    </div>
  )
}

interface ViewRichTextListScreenItemControlProps {
  item: IViewRichTextListScreenItem
  children?: never
}

export const ViewRichTextListScreenItemControl: React.FC<ViewRichTextListScreenItemControlProps> = (props) => {
  useEffect(() => {
    console.log('ViewRichTextListScreenItemControl mount', props)
  }, [])

  const { item } = props
  const {
    hideEmpty,
    nullValueCaption,
    desktopViewSettings = defViewSetting,
    mobileViewSettings = defViewSetting,
  } = item
  const classes = useStyles({})

  let { recordKeyPropertyName = '', elementVariable = '' } = item
  recordKeyPropertyName = getNotEmptyValue(recordKeyPropertyName, 'code')
  elementVariable = getNotEmptyValue(elementVariable, 'record')

  const context = useScriptTaskContext()

  const isSmall = useIsSmall()
  const { direction, defaultCardWidth } = useMemo(() => (isSmall ? mobileViewSettings : desktopViewSettings), [isSmall])
  const list: unknown[] = getContextProperty(context, item.propertyName) || []
  const filteredList = filterTableRows(list, item, context)

  if (item.visible && !checkPredicate(item.visible, undefined, context)) {
    return <></>
  }

  if (!filteredList.length) {
    return (
      <>
        {hideEmpty ? (
          <></>
        ) : (
          <ItemCard>
            <Typography variant='body1'>
              <strong>{nullValueCaption !== '' ? nullValueCaption : 'Список строк пуст'}</strong>
            </Typography>
          </ItemCard>
        )}
      </>
    )
  }

  return (
    <>
      {item.displayName && (
        <div className={classes.label}>
          <Typography variant='inherit'>{item.displayName}</Typography>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: `${direction ?? 'column'}`,
          margin: '0px -4px',
        }}
      >
        {filteredList.map((row, rowI) => {
          const recordCode = getPropertyAny(row, recordKeyPropertyName, rowI.toString())
          return (
            <ViewRichTextScreenItemWithImg
              key={recordCode}
              recordCode={recordCode}
              context={context}
              item={item}
              row={row}
              elementVariable={elementVariable}
              defaultCardWidth={defaultCardWidth}
            ></ViewRichTextScreenItemWithImg>
          )
        })}
      </div>
    </>
  )
}
