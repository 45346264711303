import React, { useContext } from 'react'

import { createStyles, Link, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { grays } from '../../../../layout/theme'
import { IHyperlinkDescription } from '../../../../model/hyperlink'
import { ApiContext, ProfileContext } from '../../../../providers'
import { formatTemplateString } from '../../../../utils/format-template-string'
import { ErrorPage } from '../../../error-page'
import { getContextProperty } from '../../../tasks/script-tasks/propertyName'
import { IScriptTaskContext } from '../../../tasks/script-tasks/script-task-context'
import { checkAvailability } from './utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 40,
      paddingBottom: theme.spacing(12),
      [theme.breakpoints.down('xs')]: {
        paddingTop: 40,
      },
    },
    list: {
      padding: '16px 26px',
      backgroundColor: '#ffffff',
      border: `1px solid ${grays.gray5}`,
    },
    link: {
      marginBottom: 16,
      '&:last-child': {
        marginBottom: 0,
      },
    },
  }),
)

export const Hyperlinks: React.FC = () => {
  const classes = useStyles()
  const profile = useContext(ProfileContext)
  const api = useContext(ApiContext)

  const { posCode } = useParams<{ posCode: string }>()

  const posOps = useAsync(async () => await api.pos.getPos(posCode!), [posCode])

  const linksOps = useAsync(async () => await api.dictionary.getHyperlinks(), [])

  if (posOps.error) {
    return <ErrorPage errorMessage={posOps.error.message} />
  }

  if (linksOps.error) {
    return <ErrorPage errorMessage={linksOps.error.message} />
  }

  if ((posOps.loading && !posOps.value) || (linksOps.loading && !linksOps.value)) return <></>
  const pos = posOps.value!

  const links = linksOps
    .value!.filter((link) => link.relatedType === 'PMI.BDDM.Staticdata.POS')
    .filter((link) => checkAvailability(link, { pos } as IScriptTaskContext))
    .filter((link) => {
      const relatedProfiles = link.relatedProfiles
      if (!relatedProfiles?.length) {
        return true
      }
      return relatedProfiles.find((relatedProfile) => relatedProfile.code === profile.value?.profile.code)
    })

  const sortedLinks = _.orderBy(links, ['linkDisplayName'], ['asc'])

  const renderLink = (hyperlink: IHyperlinkDescription): JSX.Element => {
    const resultLink = formatTemplateString(hyperlink.linkFormat, (propertyName) =>
      getContextProperty({ pos } as IScriptTaskContext, propertyName, ''),
    )

    return (
      <Typography color='primary' className={classes.link}>
        <Link href={resultLink} target='_blank' underline='none' variant='body1'>
          {hyperlink.linkDisplayName ?? 'Перейти'}
        </Link>
      </Typography>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.list}>
        {sortedLinks.length ? sortedLinks.map(renderLink) : <Typography>Доступных ссылок не найдено</Typography>}
      </div>
    </div>
  )
}
