import React from 'react'

import { Box } from '@material-ui/core'

import { ASTPredicate } from '../../../../../../model/ast-predicate'
import {
  AstPredicateInput,
  formatAstPredicate,
  parseAstPredicate,
} from '../../../../../_common/predicates/ast-predicate'

interface IAstPredicateControl {
  memoBDDMType: string[]
  value: ASTPredicate | null
  onChange?: (value: ASTPredicate | null) => void
  readonly?: boolean
}

export const AstPredicateControl: React.FC<IAstPredicateControl> = ({
  memoBDDMType,
  value,
  onChange,
  readonly = false,
}) => {
  return (
    <Box height='150px'>
      <AstPredicateInput
        types={memoBDDMType}
        value={formatAstPredicate(value)}
        onChange={(value) => {
          void onChange?.(parseAstPredicate(value))
        }}
        disabled={readonly}
      />
    </Box>
  )
}
