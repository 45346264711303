import { Interval, intervalToDuration, formatDuration as formatDurationDateFns } from 'date-fns'

import { getLocal } from './get-local'

type Arg = Interval

export function formatDuration({ start, end }: Arg, locale?: string): string {
  if (end.valueOf() - start.valueOf() > 60_000) {
    return formatDurationDateFns(
      intervalToDuration({
        start,
        end,
      }),
      { format: ['days', 'hours', 'minutes'], locale: getLocal(locale) },
    )
  } else {
    return formatDurationDateFns(
      intervalToDuration({
        start,
        end,
      }),
      { format: ['seconds'], zero: true, locale: getLocal(locale) },
    )
  }
}
