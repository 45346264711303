import { DependencyList, useContext } from 'react'

import device from 'current-device'
import { useAsync } from 'react-use'
import { AsyncState } from 'react-use/lib/useAsyncFn'

import {
  BlobMetadata,
  BlobStorageError,
  BlobStorageErrorCode,
} from '../../infrastructure/blob-storage/blob-storage-api'
import { FaceMimeContentRef, IBddmContent } from '../../model/content'
import { ILocalMediaStorageSettings, IMediaStorageSettings } from '../../model/screen-item'
import { ApiContext } from '../../providers'
import { appToast } from '../../utils'
import { useBusinessSettings } from '../_common/hooks/useBusinessSettings'
import { getContextProperty } from '../tasks/script-tasks/propertyName'
import { IScriptTaskContext } from '../tasks/script-tasks/script-task-context'
import { MediaEntity } from '../tasks/template-tasks/composite-screen/utils'

export async function optimizeBlobImage(
  imageBlob: Blob,
  resolution: number,
  quality = 0.4,
  contentType = 'image/jpeg',
): Promise<Blob> {
  const img = new Image()
  const blobLink = URL.createObjectURL(imageBlob)
  try {
    img.src = blobLink
    await img.decode()
  } finally {
    URL.revokeObjectURL(blobLink)
  }

  let canvas: HTMLCanvasElement | null = document.createElement('canvas')

  const ratioX = resolution / img.naturalWidth
  const ratioY = resolution / img.naturalHeight
  const ratio = Math.min(ratioX, ratioY)

  canvas.width = img.naturalWidth * ratio
  canvas.height = img.naturalHeight * ratio

  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
  ctx.drawImage(img, 0, 0, img.naturalWidth * ratio, img.naturalHeight * ratio)
  const base64Image = canvas.toDataURL(contentType, quality / 100)

  canvas = null
  const base64Response = await fetch(base64Image)
  return base64Response.blob()
}

/** @deprecated Перешел на fetch */
export function base64toBlob(base64Data: string, contentType: string): Blob {
  contentType = contentType || ''
  const sliceSize = 1024
  const byteCharacters = atob(base64Data)
  const bytesLength = byteCharacters.length
  const slicesCount = Math.ceil(bytesLength / sliceSize)
  const byteArrays = new Array(slicesCount)

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize
    const end = Math.min(begin + sliceSize, bytesLength)

    const bytes = new Array(end - begin)
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0)
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes)
  }
  return new Blob(byteArrays, { type: contentType })
}

export interface ITempFileItem {
  blob?: Blob
  metadata: Pick<BlobMetadata, 'key' | 'timestamp' | 'thumbnail'>
}

export const createPhotoItem = (blob: Blob): ITempFileItem => {
  return {
    blob,
    metadata: {
      key: `${Math.floor(Math.random() * 1024 * 1024)}`,
      timestamp: Date.now(),
      // size: blob.size,
      // mimeType: blob.type.split('/')[0]
    },
  }
}

export const useFetchFilesByKeys = (ids?: string[], deps: DependencyList = []): AsyncState<ITempFileItem[]> => {
  const api = useContext(ApiContext)
  const blobStorage = api.blobStorage

  const state = useAsync(async () => {
    if (ids) {
      const loadItems: ITempFileItem[] = []

      for (const id of ids) {
        const metadata = await blobStorage!.getMetadata(id)
        loadItems.push({
          blob: undefined,
          metadata: {
            key: metadata?.key ?? id,
            timestamp: metadata?.timestamp ?? 0,
            thumbnail: metadata?.thumbnail ?? '',
          },
        })
      }

      return loadItems
    }
  }, [...deps, ids])

  return state
}

export const usePermissionsAddPhotos = (): boolean => {
  const settings = useBusinessSettings()

  if (settings?.addPhotoFromDesktopEnabled === 'true') {
    return true
  }
  return !device.desktop() && navigator.maxTouchPoints >= 2
}

export const getErrorToast = (error: BlobStorageError): void => {
  switch (error.code) {
    case BlobStorageErrorCode.OutOfSpace:
      appToast.error('Недостаточно памяти для сохранения фотографии')
      break
    case BlobStorageErrorCode.StorageRestricted:
      appToast.error('Сохранение фото в режиме Инкогнито невозможно')
      break
    default:
      appToast.error('Произошла ошибка при добавлении фотографии')
  }
}

export const DEFAULT_STORAGE: ILocalMediaStorageSettings = {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.LocalMediaStorageSettings',
}

export const getStorage = (entity: MediaEntity): IMediaStorageSettings => {
  if ('storage' in entity) {
    return entity.storage ?? DEFAULT_STORAGE
  }
  return DEFAULT_STORAGE
}

export const getKeysFromContext = (propertiesContext: IScriptTaskContext, propertyName: string): string[] => {
  const value: IBddmContent | FaceMimeContentRef | undefined = getContextProperty(propertiesContext, propertyName)
  return getDocumentsKeysByParts(value)
}

export const getDocumentsKeysByParts = (value: IBddmContent | FaceMimeContentRef | undefined): string[] => {
  if (!value) return []
  if ('parts' in value) {
    return value.parts.map((item) => item.target)
  }

  return [value.target]
}

export const createDocumentsValueByType = (
  keys: string[],
  $type: MediaEntity['$type'],
): IBddmContent | FaceMimeContentRef | undefined => {
  switch ($type) {
    case 'PMI.FACE.BDDM.Extensions.Classes.PhotosPropertyScreenItem':
    case 'PMI.FACE.BDDM.Extensions.Classes.MediaContentListViewerScreenItem':
    case 'PMI.FACE.BDDM.Extensions.Classes.MediaContentListEditorScreenItem':
      return {
        parts: keys.map((key) => {
          return {
            $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceMimeContentRef',
            code: key,
            target: key,
            type: 'JPG',
          }
        }),
      }
    case 'PMI.FACE.BDDM.Extensions.Classes.MimeContentViewerScreenItem':
    case 'PMI.FACE.BDDM.Extensions.Classes.MimeContentEditorScreenItem':
    default:
      if (!keys.length) return
      return {
        $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceMimeContentRef',
        code: keys[0],
        target: keys[0],
        type: 'JPG',
      }
  }
}
