import React from 'react'

import { Route, Routes } from 'react-router-dom'

import DumpLoadPage from '../features/dump-page/dump-load-page'
import { LoginPageError, LoginResultPage, LoginSwitcher } from '../features/login-page'
import { SyncErrorPage } from '../features/sync-page/sync-error-page'
import { UserUnauthorizedPage } from '../features/sync-page/user-unauthorized-page'
import { UserContextErrorPage } from '../providers/auth/user-context-error-page'
import { AppRoutes as route } from './routes'

export const PublicRouteList: React.FC = ({ children }) => {
  return (
    <Routes>
      <Route path={'/'.concat(route.login.path)} element={<LoginSwitcher />} />
      <Route path={'/login-result'} element={<LoginResultPage />} />
      <Route path={'/login-error'} element={<LoginPageError />} />
      <Route path='/sync-error' element={<SyncErrorPage />} />
      <Route path='/401' element={<UserUnauthorizedPage />} />
      <Route path='/user-context-error' element={<UserContextErrorPage />} />
      <Route path='/dump-load' element={<DumpLoadPage />} />
      <Route path='*' element={<>{children}</>} />
    </Routes>
  )
}
