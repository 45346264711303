import React, { useContext, useState } from 'react'

import { makeStyles, Theme, createStyles, Typography, Box, Grid, Checkbox } from '@material-ui/core'

import { getAppFrameStyle, getFloatActionButtonStyle } from '../../../../../assets/pm-shared-styles'
import { ValidationFab } from '../../../../../components/validation-fab'
import { LogManager } from '../../../../../infrastructure/logger'
import { PageContent } from '../../../../../layout/page-content'
import { TitleBar } from '../../../../../layout/title-bar'
import { BooleanAnswer } from '../../../../../model/answer'
import { Code } from '../../../../../model/base'
import { IQuestionnaire } from '../../../../../model/questionnaire'
import { SETaskDirectionsScreenItem } from '../../../../../model/screen-item'
import { ISurvey } from '../../../../../model/survey'
import { ApiContext } from '../../../../../providers'
import { appToast } from '../../../../../utils'
import { useIsSmall } from '../../../../_common/hooks/useIsSmall'
import SearchInput from '../../../sales-expert/tabs/session/search-input'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { useLoadingSetRef } from '../../utils'
import { ItemCard } from '../item-card'
import { validateTaskDirections } from '../validate'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appFrame: {
      ...getAppFrameStyle(theme),
      background: theme.palette.background.default,
      // marginTop: 88,
      // [theme.breakpoints.down('xs')]: {
      //   marginTop: 64
      // }
    },
    finishBtn: {
      ...getFloatActionButtonStyle(theme),
      zIndex: 1700,
    },
    backButton: {
      width: 64,
    },
    tabs: {
      // width: '100%',
      marginBottom: theme.spacing(3),
      height: 32,
      '& .Mui-selected': {
        background: theme.palette.action.selected.concat('!important'),
        color: theme.palette.primary.main,
      },
      '& > button': {
        textTransform: 'unset',
        color: theme.palette.text.primary,
        fontWeight: 'normal',
        fontSize: 14,
        width: 94,
      },
      '& > button:hover': {
        background: 'unset',
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
    appBar: {
      background: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
    content: {
      padding: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2),
      },
    },
  }),
)

const logger = LogManager.getLogger('DirectionV3Page')

export enum DirectionTab {
  LAU = 1,
  LAS,
}

type RefetchSurvey = () => Promise<ISurvey | null>

interface Props {
  defaultTab?: DirectionTab
  item: SETaskDirectionsScreenItem
  displayName: string
  edLasSurvey: ISurvey
  refetchLas: RefetchSurvey
  edLauSurvey: ISurvey
  refetchLau: RefetchSurvey
  edLasQuestionnaire: IQuestionnaire
  edLauQuestionnaire: IQuestionnaire
  onBack: VoidFunction
}

export const DirectionV3Page: React.FC<Props> = ({
  defaultTab,
  item,
  displayName,
  edLasQuestionnaire,
  edLasSurvey,
  edLauQuestionnaire,
  edLauSurvey,
  refetchLas,
  refetchLau,
  onBack,
}) => {
  const classes = useStyles()
  const api = useContext(ApiContext)
  const isSmall = useIsSmall()

  const loading = useLoadingSetRef<Code>()

  const [search, setSearch] = useState('')
  const currentTab = defaultTab ?? DirectionTab.LAU

  function renderTab(survey: ISurvey, questionnaire: IQuestionnaire, refetch: RefetchSurvey): JSX.Element {
    if (!survey) return <></>
    // console.log('current tab', survey)

    const filteredQuestions = questionnaire.questions.filter((question) =>
      question.description?.toLowerCase().includes(search.toLowerCase()),
    )
    const filteredGroups =
      questionnaire.questionGroups?.filter((group) => {
        return filteredQuestions.find((question) => question.questionGroupCode === group.code)
      }) ?? []

    const onBooleanAnswerHandler = async (questionCode: Code, resolution: boolean): Promise<void> => {
      const surveyCode = survey.code
      const loadingCode = surveyCode + questionCode
      if (loading.get().has(loadingCode)) return
      loading.add(loadingCode)
      try {
        if (resolution) {
          await api.survey.giveBooleanAnswer({
            questionCode,
            surveyCode,
            resolution,
          })
        } else {
          await api.survey.removeAnswer({
            questionCode,
            surveyCode,
          })
        }
        if (survey.status !== 'InProgress') {
          await api.survey.setSurveyStatus({ surveyCode, status: 'InProgress' })
        }
      } catch (e) {
        const msg = 'Ошибка при сохранении ответа на вопрос'
        logger.error('onBooleanAnswerHandler', msg, e, { surveyCode, questionCode, resolution })
        appToast.error(msg)
      }
      await refetch()
      loading.remove(loadingCode)
    }
    return (
      <Box letterSpacing='0.15px'>
        {filteredGroups.map((group) => (
          <ItemCard label={<Typography variant='h3'>{group.name}</Typography>} key={group.code}>
            {filteredQuestions
              .filter((question) => question.questionGroupCode === group.code)
              .map((question) => (
                <Box my={1} key={question.code}>
                  <Grid container wrap='nowrap' component='label'>
                    <Box mr={2} mt={-1}>
                      <Checkbox
                        color='primary'
                        onChange={async (ev) => {
                          return onBooleanAnswerHandler(question.code, ev.target.checked)
                        }}
                        checked={
                          (survey.answers?.find((answer) => answer.questionCode === question.code) as BooleanAnswer)
                            ?.booleanAnswer ?? false
                        }
                      />
                    </Box>
                    <Typography>{question.description}</Typography>
                  </Grid>
                </Box>
              ))}
          </ItemCard>
        ))}
        {!filteredQuestions.length && search && (
          <Box display='flex' flexDirection='column' alignItems='center' justifyItems='center'>
            <Typography variant='h6' color='textSecondary'>
              Поиск не дал результатов
            </Typography>
          </Box>
        )}
      </Box>
    )
  }

  return (
    <div className={classes.appFrame}>
      {/* <MuiAppBar elevation={0} className={classes.appBar}>
        <TitleBar onBack={onBack}>{displayName}</TitleBar>
      </MuiAppBar> */}

      <TitleBar onBack={onBack}>{displayName}</TitleBar>
      <PageContent>
        <div className={classes.content}>
          <SearchInput
            placeholder={isSmall ? 'Поиск' : 'Поиск направления развития'}
            onChange={setSearch}
            query={search}
          />
          <Box height={20}></Box>
          {currentTab === DirectionTab.LAU
            ? renderTab(edLauSurvey, edLauQuestionnaire, refetchLau)
            : renderTab(edLasSurvey, edLasQuestionnaire, refetchLas)}
        </div>
        <ValidateButton onBack={onBack} item={item} />
      </PageContent>
    </div>
  )
}

const ValidateButton: React.FC<{ onBack: VoidFunction; item: SETaskDirectionsScreenItem }> = ({ onBack, item }) => {
  const propertiesContext = useScriptTaskContext()

  const isValid = !validateTaskDirections(item, propertiesContext)?.isError
  return (
    <ValidationFab onClick={(isValid) => isValid && onBack()} isValid={isValid}>
      Готово
    </ValidationFab>
  )
}
