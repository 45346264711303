import create from 'zustand'

interface ISearchQueryState {
  query: string
  setQuery: (value: string) => void
}

export const useSearchQuery = create<ISearchQueryState>((setStore) => ({
  query: '',
  setQuery: (value) => {
    setStore({ query: value })
  },
}))
