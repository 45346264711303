import {ISyncEntity} from '../model/base'
import {FaceMimeContentRef, faceMimeContentRefType, MimeContentRef, mimeContentRefType} from '../model/content'
import {IProblem} from '../model/problem'
import {scanObjectTree} from './object'

export function scanMediaRefs(
  obj: unknown,
  bypass?: (parent: unknown, propName: string | number, propValue: unknown) => boolean
): Set<string> {
  return new Set<string>(
    scanObjectTree<MimeContentRef | FaceMimeContentRef>(
      obj,
      ref => ref.$type === faceMimeContentRefType || ref.$type === mimeContentRefType,
      bypass
    ).map(ref => ref.target)
  )
}

export function scanMediaRefsTaskSpecialHandling(unit: unknown) : Set<string> {
  return scanMediaRefs(unit, (_, propName) => propName === 'printedForm')
}

export function checkLoadStartDate<T extends ISyncEntity>(entity: T, loadStartDate: number | undefined): boolean {
  if (loadStartDate === undefined) {
    return true
  }
  return entity.updateTime > loadStartDate
}

export function filterEntityList<T extends ISyncEntity>(entities: T[], loadStartDateScanMedia: number | undefined): T[] {
  if (loadStartDateScanMedia === undefined) {
    return entities
  }

  return entities.filter((entity) => checkLoadStartDate(entity, loadStartDateScanMedia))
}

export function filterProblemsList(problems: IProblem[], loadStartDateScanMedia: number | undefined): IProblem[] {
  if (loadStartDateScanMedia === undefined) {
    return problems
  }

  return problems.filter((problem) => {
    if (problem.status === 'Resolved' || problem.status === 'Canceled') {
      return checkLoadStartDate(problem, loadStartDateScanMedia)
    }
    return true
  })
}