import React, { useContext, useMemo, useState } from 'react'

import { Box, createStyles, Dialog, IconButton, makeStyles, SvgIcon } from '@material-ui/core'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'

import { ReactComponent as ImageBox } from '../../../../../assets/icons/img_box.svg'
import { Code } from '../../../../../model/base'
import { FaceMimeContentRef, IContent } from '../../../../../model/content'
import { checkPredicate } from '../../../../../model/script-predicate'
import { isNonNullable } from '../../../../../utils/isNonNullable'
import { PhotoDialog } from '../../../../documents'
import { usePermissionsAddPhotos } from '../../../../documents/utils'
import { MakePhotoButton } from '../../../../execute-survey-control/photo-button'
import { useUpdateProperty } from '../../../nested/useUpdateProperty'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { CellWrapperProps, EditorItemProps } from './editorUtils'
import { TableItemContext } from './table-item'

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      padding: '0',
    },
  }),
)

const getPhotos = (value: IContent | string[]): string[] => {
  if ('parts' in value) {
    return value.parts?.map((ref) => (ref as FaceMimeContentRef).code).filter(isNonNullable) ?? []
  } else {
    return value
  }
}

const PhotoCellControl: React.FC<EditorItemProps> = ({ col, i, row }) => {
  if (col.control.$type !== 'PMI.FACE.BDDM.Extensions.Classes.PhotosDialogCell') throw new Error('unreachable')

  // FACE-4407 Отказываемся от паттерна readOnly, рассчитываем значение свойства по косвенным
  const { control } = col
  const isReadOnly = !control.allowDeletePhoto && !control.allowAddPhoto

  const { t } = useTranslation('photo')
  const classes = useStyles()
  const updateProperty = useUpdateProperty()
  const localContext = useScriptTaskContext()
  const tableItem = useContext(TableItemContext)
  const isPermissionsAddPhoto = usePermissionsAddPhotos()

  const value = (get(row, col.propertyName ?? '') as string[] | IContent) || []
  const photos = useMemo(() => getPhotos(value), [row, col.propertyName])

  const [photoDialogCode, setPhotoDialogCode] = useState<Code>()

  let isRequired = false

  if (col.control.required) {
    switch (col.control.required.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.ScriptPredicate':
        isRequired = checkPredicate(col.control.required, row, localContext)
        break
      case 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate':
        isRequired = col.control.required.value
    }
  }

  const minOccurs = isRequired ? 1 : 0
  const maxOccurs = col.control.maxPhotoCountAvailable ?? 5

  const renderPhotosButton = (): JSX.Element => (
    <>
      {!isReadOnly ? (
        <MakePhotoButton
          code={col.propertyName}
          onClick={setPhotoDialogCode}
          isPhotoError={!(minOccurs <= photos.length && maxOccurs >= photos.length)}
          isPhotoAdded={!!photos.length}
        />
      ) : photos.length ? (
        <IconButton
          className={classes.button}
          color='primary'
          size='small'
          onClick={() => setPhotoDialogCode(col.propertyName)}
        >
          <SvgIcon component={ImageBox} />
        </IconButton>
      ) : (
        <></>
      )}
    </>
  )

  return (
    <>
      <Dialog open={!!photoDialogCode} fullScreen>
        <PhotoDialog
          title={t('photoDialogTitle')}
          isShow={!!photoDialogCode}
          inboxIds={photos}
          showCameraOnOpen={false}
          minOccurs={minOccurs}
          maxOccurs={maxOccurs}
          onClose={() => setPhotoDialogCode(undefined)}
          onSubmit={(ids) => {
            void updateProperty(`${tableItem.propertyName}.${i}.${col.propertyName}`, {
              parts: ids.map((photoId) => ({
                $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceMimeContentRef',
                type: 'binary/image',
                target: photoId,
                code: photoId,
              })),
            })
          }}
          isReadOnly={isReadOnly}
          allowAddPhoto={!!col.control.allowAddPhoto}
          // allowAddPhoto={true} // DEBUG
          permissionsAddPhoto={isPermissionsAddPhoto}
          nullValueCaption={col.control.nullValueCaption}
          // permissionsAddPhoto={false} // DEBUG
          allowDeletePhoto={col.control.allowDeletePhoto ?? false}
          storage={col.control.storage}
        />
      </Dialog>

      {renderPhotosButton()}
    </>
  )
}

export const PhotosDialogCell: React.FC<EditorItemProps & CellWrapperProps> = ({ variant, ...props }) => {
  const { col, row } = props
  const localContext = useScriptTaskContext()
  if (col.control.$type !== 'PMI.FACE.BDDM.Extensions.Classes.PhotosDialogCell') throw new Error('unreachable')

  if (col.control.visible) {
    switch (col.control.visible.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.ScriptPredicate':
        const isVisible = checkPredicate(col.control.visible, row, localContext)
        if (!isVisible) {
          return <Box></Box>
        }
        break
      case 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate':
        if (!col.control.visible.value) {
          return <Box></Box>
        }
    }
  }

  switch (variant) {
    case 'table':
      return <PhotoCellControl {...props} />
    case 'portrait':
    case 'extended-row':
      return (
        <Box>
          <PhotoCellControl {...props} />
        </Box>
      )
  }
}
