export type IMimeContent = BinaryMimeContent | TextMimeContent | MimeContentRef | FaceMimeContentRef

export const MimeContentTypeArray = ['PNG', 'JPG', 'PDF'/*, 'GIF', 'MP4'*/] as const
export type MimeContentType = typeof MimeContentTypeArray[number]

interface MimeContentBase {
  /**
   * mime-type части
   * Например, text/plain - обычный текст, application/json - json, binary/image растровое изображение.
   */
  type: MimeContentType | string
}

export interface BinaryMimeContent extends MimeContentBase {
  $type: 'PMI.BDDM.Common.BinaryMimeContent'
  data: unknown
}

export interface TextMimeContent extends MimeContentBase {
  $type: 'PMI.BDDM.Common.TextMimeContent'
  text: string
}

export interface IMimeContentRef extends MimeContentBase {
  target: string
}

export const mimeContentRefType = 'PMI.BDDM.Common.MimeContentRef'

export interface MimeContentRef extends IMimeContentRef {
  $type: typeof mimeContentRefType
}

export const faceMimeContentRefType = 'PMI.FACE.BDDM.Extensions.Classes.FaceMimeContentRef'

export interface FaceMimeContentRef extends IMimeContentRef {
  $type: typeof faceMimeContentRefType
  code: string
}

/**
 * Содержимое какой-либо сущности. Состоит из упорядоченного списка частей.
 * Каждая часть может быть либо строкой, либо бинарным массивом, либо ссылкой на web-содержимое
 */
export interface IContent {
  $type?: 'PMI.BDDM.Common.Content'
  parts: IMimeContent[]
}

export interface IBddmContent {
  parts: FaceMimeContentRef[]
}

export interface IDictionaryContent {
  parts: TextMimeContent[]
}

export function isBinaryMimeContent(content: IMimeContent): content is BinaryMimeContent {
  return (content as BinaryMimeContent).$type === 'PMI.BDDM.Common.BinaryMimeContent'
}

export function isTextMimeContent(content: IMimeContent): content is TextMimeContent {
  return (content as TextMimeContent).$type === 'PMI.BDDM.Common.TextMimeContent'
}

export function isMimeContentRef(content: IMimeContent): content is MimeContentRef {
  return (content as MimeContentRef).$type === 'PMI.BDDM.Common.MimeContentRef'
}

export function isFaceMimeContentRef(content: IMimeContent): content is FaceMimeContentRef {
  return (content as FaceMimeContentRef).$type === 'PMI.FACE.BDDM.Extensions.Classes.FaceMimeContentRef'
}
