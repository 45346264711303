import React from 'react'

import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

interface IIsaAnswerResolutionProps {
  resolution?: boolean
  text?: string
}

interface IStylesProps {
  resolution: boolean
  text?: string
}

const SIZE = '40px'

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 14,
      fontWeight: 500,
      color: 'white',
      textTransform: 'uppercase',
      height: SIZE,
      minHeight: SIZE,
      minWidth: SIZE,
      borderRadius: '50%',
      padding: theme.spacing(1)
    },
    booleanResolution: {
      background: ({ resolution }) => (resolution ? '#ADCB57' : '#B80718')
    },
    textResolution: {
      background: theme.palette.primary.main,
      borderRadius: ({ text }) => (text && text?.length > 3 ? '14px' : '50%')
    }
  })
)

const IsaAnswerResolution: React.FC<IIsaAnswerResolutionProps> = ({ resolution = false, text }) => {
  const classes = useStyles({ resolution, text })
  const { t } = useTranslation('reports')

  if (text != null) {
    return <div className={classNames([classes.root, classes.textResolution])}>{text}</div>
  }

  return (
    <div className={classNames([classes.root, classes.booleanResolution])}>
      {resolution ? t('resolutionYes') : t('resolutionNo')}
    </div>
  )
}

export default React.memo(IsaAnswerResolution)
