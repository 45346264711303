import React from 'react'

import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

interface IThirdPartyTableProps {
  strengths: string | undefined
  recommendation: string | undefined
  feedback: string | undefined
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderCollapse: 'collapse',
      width: '100%',
      fontSize: 20,
      '& td': {
        border: `1px solid ${theme.palette.common.black}`,
        padding: 4
      }
    },
    bold: {
      fontWeight: 'bold'
    }
  })
)

const ReportTable: React.FC<IThirdPartyTableProps> = (props) => {
  const classes = useStyles()
  return (
    <table className={classes.root}>
      <tbody>
        <tr>
          <td className={classes.bold}>Основные сильные стороны по демонстрации уровня сервиса</td>
          <td>{props.strengths ?? '-'}</td>
        </tr>
        <tr>
          <td className={classes.bold}>Рекомендация по развитию уровня сервиса</td>
          <td>{props.recommendation ?? '-'}</td>
        </tr>
        <tr>
          <td className={classes.bold}>Обратная связь от клиента эксперту</td>
          <td>{props.feedback ?? '-'}</td>
        </tr>
      </tbody>
    </table>
  )
}

export default React.memo(ReportTable)
