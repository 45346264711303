import { useQueryClient } from '@tanstack/react-query'

import { useService } from '../../../providers/service'
import { BddmTypesApi } from './bddm-types-api'
import { createSchemas } from './create-schemas'
import { transformTypes } from './transform-types'

export function useGetBddmSchema() {
  const api = useService(BddmTypesApi)
  const queryClient = useQueryClient()

  return async function getBddmSchema($type: string) {
    const types = await queryClient.ensureQueryData({
      queryKey: ['bddm-types'],
      queryFn: async () => api.getBddmTypes(),
      structuralSharing: false,
    })

    const schemas = createSchemas(transformTypes(types))

    return schemas.get($type)
  }
}
