import React, { useEffect } from 'react'

import { IActionScreenItem } from '../../../../model/screen-item-base'
import { checkPredicate, IPredicate } from '../../../../model/script-predicate'
import VisitCreateDatePicker from '../../../visits/visit-create-date-picker/visit-create-date-picker'
import { useUpdateProperty } from '../../nested/useUpdateProperty'
import { getContextProperty } from '../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../script-tasks/script-task-context'

export interface IDateInputScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.DateInputScreenItem'
  required?: IPredicate
}

interface DateInputScreenItemProps {
  item: IDateInputScreenItem
  value: number
  onChange: (date: Date) => void
  isError: boolean
}
const DateInputScreenItem: React.FC<DateInputScreenItemProps> = (props) => {
  useEffect(() => {
    console.log('DateInputScreenItem resolved value', value)
  }, [])

  const { item, value, onChange, isError } = props

  return (
    <VisitCreateDatePicker
      title={item.displayName}
      onChange={onChange}
      selectedDate={value}
      isError={isError}
    ></VisitCreateDatePicker>
  )
}
interface DateInputScreenItemControlProps {
  item: IDateInputScreenItem
  children?: never
}
const DateInputScreenItemControl: React.FC<DateInputScreenItemControlProps> = (props) => {
  useEffect(() => {
    console.log('DateInputScreenItemControl mount', item)
  }, [])

  const { item } = props
  const context = useScriptTaskContext()
  const value: number = getContextProperty(context, item.propertyName)
  const updateProperty = useUpdateProperty()

  const required = item.required && checkPredicate(item.required, undefined, context)
  const isError = required && !value

  return (
    <DateInputScreenItem
      item={item}
      value={value}
      onChange={async (value) => updateProperty(item.propertyName, value.valueOf())}
      isError={!!isError}
    ></DateInputScreenItem>
  )
}

export default DateInputScreenItemControl
