import React, { useContext, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { LogManager } from '../../infrastructure/logger'
import { FieldForceTaskCancelationReason } from '../../model/dictionary-item'
import { ApiContext, ProfileContext } from '../../providers'
import { appToast } from '../../utils'
import { useTryUpload } from '../_common/hooks/useTryUpload'
import { ErrorPage } from '../error-page'
import ReasonCancellationList from './reason-cancellation-list'

const logger = LogManager.getLogger('TaskRemovePage')

const TaskRemovePage: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const api = useContext(ApiContext)
  const profile = useContext(ProfileContext)
  const currentRouteParams = useParams<{ code: string; visitCode: string }>()
  const tryUploadVisit = useTryUpload('visit')

  const [value, setValue] = useState<FieldForceTaskCancelationReason>()

  const { t } = useTranslation('visits')

  const { code, visitCode } = currentRouteParams

  const taskOps = useAsync(async () => await api.tasks.getTask(code!), [])

  const templateOps = useAsync(async () => {
    const templateRef = taskOps.value?.template
    if (templateRef) {
      return api.tasks.getTaskTemplate(templateRef)
    }
  }, [taskOps])

  if (taskOps.error) {
    return <ErrorPage errorMessage={taskOps.error.message} />
  }

  if (templateOps.error) {
    return <ErrorPage errorMessage={templateOps.error.message} />
  }

  if (templateOps.loading && !templateOps.value) return <></>

  const availableTaskCancelationReasons = templateOps.value?.availableTaskCancelationReasons

  const reasonsCancelledItems = availableTaskCancelationReasons
    ?.filter((item) => {
      const availableProfiles = item.availableProfiles
      if (!availableProfiles?.length) {
        return true
      }
      return availableProfiles?.some((item) => item.code === profile.value?.profile.code)
    })
    .map((item) => item.reason)

  const onBackHandler = (): void => {
    const state = location.state
    const path = visitCode ? `/visits/${visitCode}/view` : '/visits/'
    navigate(path, { state })
  }

  const onRemoveHandler = async (): Promise<void> => {
    if (!value) {
      appToast.info('Не выбрана причина отмены задачи')
      return
    }

    try {
      await api.tasks.setTaskStatus({
        taskCode: code!,
        taskStatus: 'Canceled',
        cancellationReason: value,
        visitCode,
      })
      visitCode && (await tryUploadVisit(visitCode))
      // appToast.success(t('cancelTaskSuccess'))
      onBackHandler()
    } catch (e) {
      const message = t('cancelTaskError')
      logger.error('onRemoveHandler', message, e, { code })
      appToast.error(message)
    }
  }

  return (
    <ReasonCancellationList
      titleBar='Отмена задачи'
      titleList='Выберите причину отмены задачи'
      title={taskOps.value?.name}
      isError={!value}
      onClickItem={(item) => {
        setValue(item as FieldForceTaskCancelationReason)
      }}
      onRemoveHandler={onRemoveHandler}
      onBackHandler={onBackHandler}
      dictionary={reasonsCancelledItems ?? []}
    />
  )
}

export default TaskRemovePage
