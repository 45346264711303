// TODO: replace with satisfies in typescript 4.9
export type AppRouteName =
  | 'root'
  | 'visits'
  | 'stores'
  | 'reports'
  | 'notifications'
  | 'sync'
  | 'debug'
  | 'login'
  | 'admin'
  | 'hyperlinks'
  | 'problems'
  | 'dteMain'
  | 'dteTasks'
  | 'dteAcademy'
  | 'dteIqosTasks'
  | 'dteTasksChecking'
  | 'dteRewards'
  | 'dteBalance'
  | 'dteRewardsList'

export interface IAppRoute {
  id: number
  path: string
  primaryText: string
}

// TODO: move to config
export const AppRoutes: { [key in AppRouteName]: IAppRoute } = {
  root: {
    id: 0,
    path: '',
    primaryText: '',
  },
  visits: {
    id: 1,
    path: 'visits',
    primaryText: 'Визиты',
  },
  stores: {
    id: 2,
    path: 'stores',
    primaryText: 'Точки',
  },
  reports: {
    id: 3,
    path: 'reports',
    primaryText: 'Отчеты',
  },
  notifications: {
    id: 4,
    path: 'notifications',
    primaryText: 'Уведомления',
  },
  sync: {
    id: 5,
    path: 'sync',
    primaryText: 'Синхронизация',
  },
  login: {
    id: 6,
    path: 'login',
    primaryText: 'Выход',
  },
  debug: {
    id: 7,
    path: 'debug',
    primaryText: 'Debug',
  },
  admin: {
    id: 8,
    path: 'admin',
    primaryText: 'Администрирование',
  },
  hyperlinks: {
    id: 9,
    path: 'hyperlinks',
    primaryText: 'Ссылки',
  },
  problems: {
    id: 10,
    path: 'problems',
    primaryText: 'Проблемы',
  },
  dteMain: {
    id: 11,
    path: 'dte-main',
    primaryText: 'Главная',
  },
  dteTasks: {
    id: 12,
    path: 'dte-tasks',
    primaryText: 'Задания',
  },
  dteAcademy: {
    id: 13,
    path: 'dte-academy',
    primaryText: 'Академия',
  },
  dteIqosTasks: {
    id: 14,
    path: 'dte-iqos-tasks',
    primaryText: 'IQOS',
  },
  dteTasksChecking: {
    id: 15,
    path: 'dte-tasks-checking',
    primaryText: 'Задания',
  },
  dteRewards: {
    id: 16,
    path: 'dte-rewards',
    primaryText: 'Призы',
  },
  dteBalance: {
    id: 17,
    path: 'dte-balance',
    primaryText: 'Личный кабинет',
  },
  dteRewardsList: {
    id: 17,
    path: 'dte-rewards-list',
    primaryText: 'Призы',
  },
}
