import { Code, IEntityReference } from './base'
import { IContent } from './content'
import { ITask, TaskStatus } from './task'

export type VisitTaskStatus = TaskStatus

/**
 * Обучаемый Sales Expert
 */
export interface IRepresentative {
  /** Наименование обучаемого */
  name?: string

  /** Адрес электронной почты обучаемого */
  eMails?: Array<{
    address: string
  }>
}

/**	Агентство, к которому приписан обучаемый sales expert */
export type IThirdParty = IEntityReference

export interface IVisitTask extends ITask {
  type?: string

  /** Код визита, которому принадлежит задача */
  visitCode: Code

  /** Статус задачи */
  status: VisitTaskStatus

  printedForm?: IContent
}

/** привязана ли задача к визиту */
export function isInVisitTask(task: ITask): task is IVisitTask {
  return (task as IVisitTask).visitCode != null
}