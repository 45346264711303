import { intersectionWith } from 'lodash'

import { isReferenceToEntity } from '../../../../../model/base'
import { IQuestionnaireReference } from '../../../../../model/questionnaire'
import { IQuestionnairePOSAssignment } from '../../../../../model/questionnaire-pos-assignment'
import { IFillSurveyScreenItem, IViewSurveyScreenItem } from '../../../../../model/screen-item'

type Item = IFillSurveyScreenItem | IViewSurveyScreenItem

/** @throws */
export function getQuestionnaireByAssignments(
  item: Item,
  posAssignments: IQuestionnairePOSAssignment[] | undefined,
): IQuestionnaireReference {
  if (item.questionnaire) {
    return item.questionnaire
  }

  if (!item.questionnaires?.length) {
    throw new Error('В параметрах настройки не указан опросник')
  }

  if (item.questionnaires?.length === 1) {
    return item.questionnaires[0]
  }

  if (!posAssignments) {
    throw new Error(
      'В параметрах настройки указано несколько опросников. Для вневизитной задачи нельзя указывать несколько опросников',
    )
  }

  // console.log('no direct questionnaire, looking assignments', item.questionnaires, posAssignments)

  const fittingAssignments = intersectionWith(
    item.questionnaires,
    posAssignments.map((qa) => qa.questionnaire),
    isReferenceToEntity,
  )

  // for (const qref of item.questionnaires ?? []) {
  //   if (posAssignments.find((qa) => isReferenceToEntity(qref, qa.questionnaire))) {
  //     return qref
  //   }
  // }
  console.log('all fitting assignments', fittingAssignments)

  switch (fittingAssignments.length) {
    case 0:
      throw new Error('В данной торговой точке нет опросников для заполнения')
    case 1:
      return fittingAssignments[0]
    default:
      throw new Error('Найдено несколько конкурирующих опросников для данной торговой точки')
  }
}
