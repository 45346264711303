import React from 'react'

import { Typography } from '@material-ui/core'

import { ContentDocumentSelector } from '../../../../components/content-document-selector/content-document-selector'
import type { MimeContentType } from '../../../../model/content'
import type { FaceContentDocumentReference } from '../../../../model/face-content-document'
import type { IActionScreenItem } from '../../../../model/screen-item-base'
import { checkPredicate, IPredicate } from '../../../../model/script-predicate'
import { useUpdateProperty } from '../../nested/useUpdateProperty'
import { getContextProperty } from '../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../script-tasks/script-task-context'
import { ItemCard } from './item-card'

export interface IContentDocumentSelectorScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.ContentDocumentSelectorScreenItem'
  required?: IPredicate
  errorHelperText?: string
  accept?: MimeContentType[]
}

interface Props {
  item: IContentDocumentSelectorScreenItem
}
const ContentDocumentSelectorScreenItemControl: React.FC<Props> = ({ item }) => {
  const context = useScriptTaskContext()
  const value: FaceContentDocumentReference = getContextProperty(context, item.propertyName)
  const updateProperty = useUpdateProperty()
  const required = item.required ? checkPredicate(item.required, undefined, context) : false

  return (
    <ItemCard label={item.displayName && <Typography variant='inherit'>{item.displayName}</Typography>}>
      <ContentDocumentSelector
        value={value}
        onChange={async (value) => updateProperty(item.propertyName, value)}
        mimeTypeFilter={item.accept}
        required={required}
      />
    </ItemCard>
  )
}

export default ContentDocumentSelectorScreenItemControl
