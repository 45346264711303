import React, { useEffect, useState } from 'react'

import { Button, MenuItem, Select, TextField } from '@material-ui/core'

import { appToast } from '../../../utils'

type ToastType = 'error' | 'warning' | 'info' | 'success'

export function showMessage(message = '', type: ToastType, closeTimeout = 0): void {
  switch (type) {
    case 'success': {
      const autoClose = closeTimeout || 4000
      appToast.success(message, { autoClose })
      return
    }
    case 'warning': {
      const autoClose = closeTimeout || 7000
      appToast.info(message, { autoClose })
      return
    }
    case 'error': {
      const autoClose = closeTimeout || false
      appToast.error(message, { autoClose })
      return
    }
    default: {
      const autoClose = closeTimeout || 5000
      appToast.info(message, {
        autoClose,
        style: { backgroundColor: '#55C2FF' },
      })
    }
  }
}

interface ScriptToastProps {
  children?: never
}

export const ScriptToast: React.FC<ScriptToastProps> = (props) => {
  useEffect(() => {
    console.log('ScriptToast mount', props)
  }, [])

  const [message, setMessage] = useState('')
  const [type, setType] = useState('')

  return (
    <>
      <Select value={type} onChange={(ev) => setType(ev.target.value as string)}>
        {['error', 'warning', 'info-test', 'success'].map((value) => (
          <MenuItem value={value} key={value}>
            {value}
          </MenuItem>
        ))}
      </Select>

      <TextField value={message} onChange={(ev) => setMessage(ev.target.value)} />
      <Button
        disableElevation
        variant='contained'
        color='primary'
        onClick={() => showMessage(message, type as unknown as ToastType)}
      >
        toast
      </Button>
    </>
  )
}
