import React from 'react'

import { Button, createStyles, SvgIcon, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { PosMenuItems } from './menu-items'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      alignItems: 'flex-start',
      width: 212,
      minHeight: 160,
      padding: '28px 16px',
      background: '#FFFFFF',
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.14)',
      borderRadius: 8,
      marginRight: 16,
      marginBottom: 16,
      '&:hover': {
        background: '#FFFFFF',
      },
      [theme.breakpoints.down('xs')]: {
        alignItems: 'center',
        width: '100%',
        padding: '12px 16px',
        minHeight: 64,
        marginRight: 0,
        marginBottom: 8,
        justifyContent: 'flex-start',
      },
    },
    text: {
      width: '100%',
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: theme.palette.primary.main,
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        fontSize: 16,
        textAlign: 'left',
      },
    },
    iconWrap: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 40,
      height: 40,
      backgroundColor: '#D5EAFD',
      borderRadius: 12,
      marginBottom: 16,
      flexShrink: 0,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 0,
        marginRight: 16,
      },
    },
    icon: {
      width: 24,
      height: 24,
    },
    innerWrap: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      [theme.breakpoints.down('xs')]: {
        alignItems: 'center',
        flexWrap: 'nowrap',
      },
    },
  }),
)

export const StoresMenuList: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation('stores')

  const navigate = useNavigate()
  const { state } = useLocation()
  return (
    <div>
      {Object.values(PosMenuItems).map((item) => (
        <Button
          key={item.path}
          className={classes.button}
          onClick={() => {
            navigate(`${item.path}`, {
              state: {
                ...(state as Record<string, unknown>),
                prevPath: location.pathname,
              },
            })
          }}
        >
          <div className={classes.innerWrap}>
            <div className={classes.iconWrap}>
              <SvgIcon color='primary' className={classes.icon} component={item.icon} />
            </div>
            <Typography className={classes.text}>{t(`menu.${item.displayName}`)}</Typography>
          </div>
        </Button>
      ))}
    </div>
  )
}
