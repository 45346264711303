import React, { useCallback } from 'react'

import { useNavigate } from 'react-router-dom'

import { ICompositeScreenItemLayout } from '../../../../../model/composite-screen-item-layout'
import type { FaceContentDocumentReference } from '../../../../../model/face-content-document'
import { IActionScreenItem } from '../../../../../model/screen-item-base'
import { getContextProperty } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { ItemCard } from '../item-card'
import ContentDocumentView from './content-document-view'

export interface IContentDocumentViewScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.ContentDocumentViewScreenItem'
  staticContent?: FaceContentDocumentReference
  borderRadius: number
  mobileLayout: ICompositeScreenItemLayout
  desktopLayout: ICompositeScreenItemLayout
  redirectPath?: string
  nullValueCaption?: string
}
interface Props {
  item: IContentDocumentViewScreenItem
}

const ContentDocumentViewScreenItemControl: React.FC<Props> = ({ item }) => {
  const navigate = useNavigate()
  const { redirectPath, propertyName, staticContent, nullValueCaption } = item

  const onClickItem = useCallback(() => {
    if (redirectPath && redirectPath !== '') {
      navigate(`${redirectPath}`, { state: { returnUrl: location.pathname } })
    }
  }, [redirectPath])

  const context = useScriptTaskContext()
  const value: FaceContentDocumentReference | undefined = propertyName
    ? getContextProperty(context, item.propertyName)
    : staticContent

  if (!value?.code) {
    return <ItemCard>{!nullValueCaption ? 'Документ не найден' : nullValueCaption}</ItemCard>
  }

  return (
    <ContentDocumentView
      item={item}
      onClick={onClickItem}
      imageCode={value.code}
      cursorPointer={!!redirectPath}
      showDisplayName={!!item.displayName}
    ></ContentDocumentView>
  )
}

export default ContentDocumentViewScreenItemControl
