import { get } from 'lodash'

import { ITask } from '../../../model/task'
import { IScriptTaskContext } from './script-task-context'

interface GetPropertyTyped<Obj> {
  <T>(source: Obj, propertyName: string): T
  <D>(source: Obj, propertyName: string, defaultValue: D): D
}

export const getPropertyAny: GetPropertyTyped<unknown> = <T>(
  source: unknown,
  propertyName: string,
  defaultValue?: T,
): T => {
  const properPath = handlePropertyName(propertyName)

  const value = get(source, properPath, defaultValue)
  return value
}

export const getTaskProperty = getPropertyAny as GetPropertyTyped<ITask>
export const getContextProperty = getPropertyAny as GetPropertyTyped<IScriptTaskContext>

export function handlePropertyName(propertyName: string): string {
  const properPath = propertyName?.split('.').map(uncapitalize).join('.') ?? ''

  return properPath
}

export function uncapitalize(s: string): string {
  if (!s) return s
  return s[0].toLowerCase() + s.slice(1)
}
