/* eslint-disable @typescript-eslint/promise-function-async */
import { openDB } from 'idb/with-async-ittr'

import { Code } from '../../model/base'
import { DateTime } from '../../model/common'
import { gzip } from '../../utils/compression'
import { isAxiosError } from '../../utils/is-axios-error'
import IAuthService from '../auth/auth-service-api'
import { IHttpClientFactory } from '../http-client-factory'
import { LogManager } from '../logger'
import { IUserProfileService } from '../user-profile'
import { DumpError, DumpErrorCode, IDbDump, IDumpService, IndexedDbDumpInfo } from './dump-service-api'

const AUTH = `Basic ${btoa("_log_:923f41bf-ab23-4751-96d8-9f1478c375b0")}`

interface UploadIndexedDbInput {
  /** Наименование базы данных, с которой снят бэкап */
  databaseName?: string
  /** Версия IndexedDB */
  indexedDbVersion?: number
  /** Дата и время снятия бэкапа на устройстве */
  backupDateTime?: DateTime
  /** Логин пользователя, от которого снят бэкап (в формате plikho@pmintl.ru, т.е. логин от ADFS) */
  userLogin?: string
  /** Контекст пользователя */
  userContext?: {
    /** Код сотрудника */
    employeeCode?: Code
    /** Фамилия сотрудника */
    surname?: string
    /** Имя сотрудника */
    name?: string
    /** Отчество сотрудника */
    patronymic?: string
    /** Коды должностей сотрудника */
    positionCodes?: Code[]
  }
}

export class DumpService implements IDumpService
{
  private static readonly __className = 'DumpService'
  private readonly logger = LogManager.getLogger(DumpService.__className)
  private readonly _auth: IAuthService
  private readonly _profile: IUserProfileService
  private readonly _httpClientFactory: IHttpClientFactory

  constructor(
    authService: IAuthService,
    profileService: IUserProfileService,
    httpClientFactory: IHttpClientFactory
  ) {
    this._auth = authService
    this._profile = profileService
    this._httpClientFactory = httpClientFactory
  }

  private static _compress(obj: unknown): Promise<Uint8Array> {
    return gzip(JSON.stringify(obj))
  }

  public async uploadDump(dump: IDbDump): Promise<void> {
    let compressedData
    try {
      compressedData = await DumpService._compress(dump.data)
    } catch (error) {
      this.logger.error('uploadIndexedDb', 'Failed to compress IndexedDB dump', error)
      throw DumpService._makeCompressError(error)
    }

    const dumpInfoFull = await this._makeUploadIndexedDbInput(dump.info)

    try {
      await this._upload(compressedData, dumpInfoFull)
    } catch (error) {
      this.logger.error('uploadIndexedDb', 'Failed to upload IndexedDB dump', error)
      throw DumpService._makeUploadError(error)
    }
  }

  public async dumpAll(): Promise<IDbDump[]> {
    const currentUser = this._auth.currentUserName
    const dbFilter = (db: IDBDatabaseInfo): boolean => {
      if (currentUser) {
        return db.name === currentUser
      } else {
        return db.name !== '_log'
      }
    }

    let databases
    try {
      databases = await window.indexedDB.databases()
    } catch (error) {
      this.logger.error('dumpAll', 'Function "window.indexedDB.databases" not supported in this browser', error)
      throw error
    }

    const dumps: Array<IDbDump | undefined> =
        await Promise.all(databases.filter(dbFilter).map((database) => this.dumpDB(database.name!)))
    return dumps.filter(d => !!d) as IDbDump[]
  }

  public async dumpDB(dbName: string): Promise<IDbDump | undefined> {
    const db = await openDB(dbName)
    const objectStoreNames = db.objectStoreNames
    if (objectStoreNames) {
      const data: Record<string, unknown> = {}
      for (const storeName of Object.values(objectStoreNames)) {
        data[storeName] = await db.getAll(storeName)
      }
      return {
        data: data,
        info: {
          databaseName: db.name,
          indexedDbVersion: db.version,
          backupDateTime: +new Date()
        }
      }
    }
  }

  public async dumpData(data: Record<string, unknown>): Promise<IDbDump> {
    return {
      data: data,
      info: {
        backupDateTime: +new Date()
      }
    }
  }

  private async _makeUploadIndexedDbInput(info: IndexedDbDumpInfo): Promise<UploadIndexedDbInput> {
    const profile = await this._profile?.getCurrentProfile()
    return {
      databaseName: info.databaseName,
      indexedDbVersion: info.indexedDbVersion,
      backupDateTime: info.backupDateTime,
      userLogin: this._auth.currentUserName,
      userContext: profile ? {
        name: profile.employee?.contact.name ?? profile.participantProfile?.contact.name,
        patronymic: profile.employee?.contact.middleName ?? profile.participantProfile?.contact.middleName,
        surname: profile.employee?.contact.surname  ?? profile.participantProfile?.contact.surname,
        employeeCode: profile.employee?.code,
        positionCodes: profile.position ? [profile.position.code] : []
      } : undefined
    }
  }

  private async _upload(compressedDump: Uint8Array, info?: UploadIndexedDbInput): Promise<void> {
    const http = this._httpClientFactory.getHttpClient(undefined, config => {
      config.headers = { ...config.headers, 'Authorization': AUTH }
    })

    const formData = new FormData()
    if (info) {
      formData.append('backupInfo', JSON.stringify(info))
    }
    formData.append('backupData', new Blob([compressedDump], {
      type: 'application/zip'
    }))

    await http.post(
      `/indexed-db/v1/save-backup`, formData,{ headers: { 'Content-Type': 'multipart/form-data' } }
    )
  }

  private static _makeCompressError(error?: unknown): DumpError {
    return new DumpError(DumpErrorCode.Internal, 'Failed to compress IndexedDB dump', undefined, error)
  }

  private static _makeUploadError(error: unknown): DumpError {
    let code = DumpErrorCode.ServiceUnavailableError
    let message = 'Failed to upload compressed IndexedDB dump'
    let businessErrorType
    let businessErrorTitle
    let status
    if (isAxiosError(error) && error.response) {
      status = error.response.status
      message += ` ${status}`
      if (status === 401) {
        code = DumpErrorCode.UserUnauthorised
      } else if (status === 403) {
        code = DumpErrorCode.AccessDenied
      } else if (status === 400) {
        if (error.response.data?.type === 'UPGRADE_REQUIRED') {
          code = DumpErrorCode.UpgradeRequired
        } else {
          code = DumpErrorCode.BusinessError
          if (error.response.data?.type) {
            businessErrorType = error.response.data?.type
          }
          if (error.response.data?.title) {
            businessErrorTitle = error.response.data?.title
          }
        }
      } else {
        code = DumpErrorCode.ServiceError
        if (status === 503 && error.response.data?.type === 'MAINTENANCE_IN_PROGRESS') {
          code = DumpErrorCode.MaintenanceInProgressError
        }
      }
    }
    return new DumpError(code, message, status, error, businessErrorType, businessErrorTitle)
  }
}