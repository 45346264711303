import React, { useEffect } from 'react'

import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom'

import { Layout } from '../../layout'
import { PageContent } from '../../layout/page-content'
import { TitleBar } from '../../layout/title-bar'
import { ITableRecordEntity } from '../admin/data-manager/models/models'
import { InMemoryProcessContext } from '../process/in-memory-process-context'
import { ExecutionProcessControl } from '../tasks/nested/execution-process-control'
import { ContextProcessUnit, useLocalContextService } from '../tasks/nested/local-context'
import { NestedProcessPage } from '../tasks/nested/nested-process'
import { getContextProperty } from '../tasks/script-tasks/propertyName'
import { useScriptTaskContext } from '../tasks/script-tasks/script-task-context'

interface ProcessPageProps {
  children?: never
  elementVariable?: string
  mainTitle: React.ReactNode
  record: Record<string, unknown>
  processUnit: ContextProcessUnit
  useInlineActionButton?: boolean
  onSave?: (record: Record<string, unknown>) => void
  onBack?: VoidFunction
}

export const ProcessPage: React.FC<ProcessPageProps> = (props) => {
  useEffect(() => {
    console.log('ProcessPage mount', props)
  }, [])

  const navigate = useNavigate()
  const toUpperLevel = (): void => navigate('')
  const [searchParams] = useSearchParams()

  const { processUnit, elementVariable, record, onSave, mainTitle, onBack, useInlineActionButton } = props
  const rootRecordName = elementVariable ?? 'record'
  return (
    <InMemoryProcessContext
      processUnit={processUnit}
      rootRecordName={rootRecordName}
      initialState={{
        [rootRecordName]: record,
        fullScope: { task: { surveys: {} } },
        taskState: {
          cases: {},
          currentStep: 0,
          subProcesses: {},
        },
        useInlineActionButton,
        queryParams: { ...Object.fromEntries(searchParams) },
      }}
    >
      <Routes>
        <Route path='' element={<RootProcess onSave={onSave} mainTitle={mainTitle} onBack={onBack} />} />
        <Route path={`process/:processCode/:recordCode/*`} element={<NestedProcessPage onBack={toUpperLevel} />} />
      </Routes>
    </InMemoryProcessContext>
  )
}

const RootProcess: React.FC<Pick<ProcessPageProps, 'onSave' | 'mainTitle' | 'onBack'>> = (props) => {
  const { onSave, mainTitle, onBack } = props
  const processContext = useScriptTaskContext()
  const processContextService = useLocalContextService()
  const { processUnit } = processContext
  const record = getContextProperty<ITableRecordEntity>(processContext, processContextService.rootRecordName!)
  return (
    <Layout>
      <TitleBar onBack={onBack}>{mainTitle}</TitleBar>
      <PageContent>
        <ExecutionProcessControl
          process={processUnit!.process}
          useFinishConfirmation={false}
          onProcessFinish={onSave && (async () => onSave(record))}
        />
      </PageContent>
    </Layout>
  )
}
