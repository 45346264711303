import React from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useTranslation } from 'react-i18next'

interface IQuestionModalProps {
  open: boolean
  onClose: () => void
  onOk?: () => void
  okButtonText?: string
  closeButtonText?: string
  isOkDisabled: boolean
  title: React.ReactNode
  contentClassName?: string
}

export const QuestionModal: React.FC<IQuestionModalProps> = ({
  okButtonText,
  closeButtonText,
  open,
  onClose,
  onOk,
  title,
  isOkDisabled,
  contentClassName,
  children
}) => {
  const { t } = useTranslation()
  return (
    <Dialog
      fullScreen
      disableScrollLock
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent classes={{ root: contentClassName }}>
        {/* <DialogContentText id='alert-dialog-description'>{message}</DialogContentText> */}
        {children}
      </DialogContent>
      <DialogActions>
        <Button fullWidth type='button' onClick={onClose} color='primary' size='large'>
          {closeButtonText ?? t('modalClose')}
        </Button>
        {onOk && (
          <Button fullWidth disabled={isOkDisabled} type='submit' onClick={onOk} autoFocus color='primary' size='large'>
            {okButtonText ?? t('modalOk')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
