import { IHttpClient } from '../infrastructure/http-client-factory'
import { EntityKey } from '../model/base'
import { IBpmnProcessOriginalData } from '../model/bpmn-process-original-data'
import { ExecutionProcessUnit, IExecutionProcessService } from './execution-process-service-api'

export class ExecutionProcessService implements IExecutionProcessService {
  constructor(private readonly _http: IHttpClient) {}

  async getExecutionProcessUnit(processKey: EntityKey): Promise<ExecutionProcessUnit> {
    const response = await this._http.request({
      method: 'GET',
      url: `execution-process/${processKey}`,
    })

    return response.data
  }

  async getBpmnProcessOriginalData(processKey: EntityKey): Promise<IBpmnProcessOriginalData> {
    const response = await this._http.request({
      method: 'GET',
      url: `execution-process/${processKey}/original-data`,
    })

    return response.data
  }
}
