import {
  DteParticipantProfileStore,
  POSParticipantProgramStore,
  POSParticipantProgramStore_pos
} from '../../data/schema'
import { Code } from '../../model/base'
import { IDteParticipantProfile } from '../../model/dte-participant-profile'
import { IPOSParticipantProgram } from '../../model/dte-pos-participant-program'
import { IDteService, ParticipantProfileSearchRequest } from '../dte-service-api'
import { LocalStorageBaseService } from './local-storage-base-service'

export default class LocalStorageDteService extends LocalStorageBaseService implements IDteService {
  private static readonly __className = 'LocalStorageDteService'

  public async getParticipantProfile(code: Code): Promise<IDteParticipantProfile | null> {
    return (await this._storage.getByKey<IDteParticipantProfile>(DteParticipantProfileStore, code)) ?? null
  }

  public async searchParticipantProfiles(request: ParticipantProfileSearchRequest): Promise<IDteParticipantProfile[]> {
    return this._storage.getWhere<IDteParticipantProfile>(DteParticipantProfileStore, (profile) => {
      if (request.profileStatus) {
        if (!request.profileStatus.includes(profile.status)) {
          return false
        }
      }
      const poses = profile.poSes.filter((p) => p.productLocation?.code === request.posCode)
      if (poses?.length) {
        if (request.posStatus) {
          if (!poses.some((p) => request.posStatus!.includes(p.status))) {
            return false
          }
        }
        return true
      }
      return false
    })
  }

  public async getPOSParticipantProgram(posParticipantProgramCode: Code): Promise<IPOSParticipantProgram | undefined> {
    return this._storage.getByKey<IPOSParticipantProgram>(POSParticipantProgramStore, posParticipantProgramCode)
  }

  public async searchPOSParticipantPrograms(request: { posCode: Code }): Promise<IPOSParticipantProgram[]> {
    return this._storage.getByIndexRange<IPOSParticipantProgram>(
      POSParticipantProgramStore, POSParticipantProgramStore_pos, ['=', request.posCode]
    )
  }
}
