import type { IHttpClient } from '../infrastructure/http-client-factory'
import type { EntityKey, Code, IEntityReference } from '../model/base'
import type { IExecutionProcessSource } from '../model/execution-process-source'
import type { IBDDMTypeFormsDefinition, IFaceDataObject } from '../model/face-data-object'
import { cleanObjectOfEmptyStrings } from '../utils'

export class DataObjectsService {
  constructor(private readonly _http: IHttpClient) {}

  async getDataObjects(): Promise<IFaceDataObject[]> {
    const res = await this._http.request({
      method: 'GET',
      url: `data-objects`,
    })

    return res.data
  }

  async getDataObject(code: Code): Promise<IFaceDataObject> {
    const res = await this._http.request({
      method: 'GET',
      url: `data-objects/${code}`,
    })

    return res.data
  }

  async createDataObjectSupportedType(key: EntityKey, data: IBDDMTypeFormsDefinition): Promise<IFaceDataObject> {
    const res = await this._http.request({
      method: 'POST',
      url: `data-objects/${key}/supported-types`,
      data: cleanObjectOfEmptyStrings(data),
    })

    return res.data
  }

  async updateDataObjectSupportedType(
    key: EntityKey,
    bddmType: string,
    data: IBDDMTypeFormsDefinition,
  ): Promise<IFaceDataObject> {
    const res = await this._http.request({
      method: 'PUT',
      url: `data-objects/${key}/supported-types/${bddmType}`,
      data: cleanObjectOfEmptyStrings(data),
    })

    return res.data
  }

  async deleteDataObjectSupportedType(key: EntityKey, bddmType: string): Promise<IFaceDataObject> {
    const res = await this._http.request({
      method: 'DELETE',
      url: `data-objects/${key}/supported-types/${bddmType}`,
    })

    return res.data
  }

  async updateSupportedTypeCreateProcess(
    key: EntityKey,
    bddmType: string,
    data: IExecutionProcessSource,
  ): Promise<IEntityReference> {
    const res = await this._http.request({
      method: 'POST',
      url: `data-objects/${key}/supported-types/${bddmType}/create-process`,
      data,
    })

    return res.data
  }

  async deleteSupportedTypeCreateProcess(key: EntityKey, bddmType: string): Promise<void> {
    const res = await this._http.request({
      method: 'DELETE',
      url: `data-objects/${key}/supported-types/${bddmType}/create-process`,
    })

    return res.data
  }

  async updateSupportedTypeViewProcess(
    key: EntityKey,
    bddmType: string,
    data: IExecutionProcessSource,
  ): Promise<IEntityReference> {
    const res = await this._http.request({
      method: 'POST',
      url: `data-objects/${key}/supported-types/${bddmType}/view-process`,
      data,
    })

    return res.data
  }

  async deleteSupportedTypeViewProcess(key: EntityKey, bddmType: string): Promise<void> {
    const res = await this._http.request({
      method: 'DELETE',
      url: `data-objects/${key}/supported-types/${bddmType}/view-process`,
    })

    return res.data
  }

  async updateSupportedTypeUpdateProcess(
    key: EntityKey,
    bddmType: string,
    data: IExecutionProcessSource,
  ): Promise<IEntityReference> {
    const res = await this._http.request({
      method: 'POST',
      url: `data-objects/${key}/supported-types/${bddmType}/update-process`,
      data,
    })

    return res.data
  }

  async deleteSupportedTypeUpdateProcess(key: EntityKey, bddmType: string): Promise<void> {
    const res = await this._http.request({
      method: 'DELETE',
      url: `data-objects/${key}/supported-types/${bddmType}/update-process`,
    })

    return res.data
  }
}
