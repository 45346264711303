import { useContext } from 'react'

import { useQueries } from '@tanstack/react-query'

import { ApiContext } from '../../../providers'

interface Props {
  isLoading: boolean
  value: string
}
export function useRichTextImg(value: string): Props {
  const templateContent = createTemplateFromString(value)
  const api = useContext(ApiContext)
  const images = Array.from(templateContent.content.querySelectorAll('img') ?? [])
  const imagesUrls: string[] = images.map((element) => element.getAttribute('src') ?? 'undefined')

  const imagesResults = useQueries({
    queries: imagesUrls.map((img) => {
      return {
        queryKey: ['rich-text-images', img],
        queryFn: async () => api.richtext.getImage(img),
        retry: false,
      }
    }),
  })
  const base64List = imagesResults.map((i) => i.data) as string[]
  const isLoading = imagesResults.some((img) => img.isLoading)
  return {
    isLoading,
    value: getTemplateWithImg(templateContent, value, base64List),
  }
}

const getTemplateWithImg = (template: HTMLTemplateElement, value: string, imgList: string[]): string => {
  const images = Array.from(template.content.querySelectorAll('img') ?? [])
  images.forEach((img, index) => {
    img.setAttribute('src', imgList[index] ?? 'undefined')
  })
  return template.innerHTML
}

const createTemplateFromString = (value: string): HTMLTemplateElement => {
  const template = document.createElement('template')
  template.innerHTML = value.trim()
  return template
}
