import React from 'react'

import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'

import { ConstPredicate } from '../../../../../../model/script-predicate'

interface IConstPredicateControl {
  value: ConstPredicate
  onChange?: (value: ConstPredicate) => void
  readonly?: boolean
}

export const createConstPredicate = (value: boolean): ConstPredicate => ({
  $type: 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate',
  value: value,
})

export const ConstPredicateControl: React.FC<IConstPredicateControl> = ({ value, onChange, readonly = false }) => {
  if (readonly) {
    return <Typography>Константа - {String(value.value)}</Typography>
  }
  return (
    <FormControlLabel
      control={
        <Checkbox
          color='primary'
          checked={value.value ?? false}
          onChange={(_, checked) => onChange?.(createConstPredicate(checked))}
        />
      }
      label={' - Константа'}
    />
  )
}
