import React from 'react'

import {
  AppBar as MuiAppBar,
  Box,
  useMediaQuery,
  Theme,
  Toolbar,
  IconButton,
  makeStyles,
  Typography,
  Grid
} from '@material-ui/core'
import { Theme as DefaultTheme } from '@material-ui/core/styles/createMuiTheme'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

import { HideOnScroll } from '../components'
import { LanguageSwitch } from './language-switch'

const useStyles = makeStyles<DefaultTheme, { elevation: boolean }>(
  (theme) => ({
    appBar: (props) => (!props.elevation ? { boxShadow: 'unset', zIndex: theme.zIndex.drawer } : {}),
    backButton: {
      width: BACK_BUTTON_WIDTH
    }
  }),
  { name: 'PmAppBar' }
)

const BACK_BUTTON_WIDTH = 64

interface IAppBarWithoutMenuProps {
  title: string
  onBack: () => void
  elevation?: boolean
  iconAfter?: React.FC | JSX.Element
  iconAfterToRightSide?: boolean
}

const AppBarWithoutMenu: React.FC<IAppBarWithoutMenuProps> = ({
  title,
  onBack,
  elevation = true,
  iconAfter,
  iconAfterToRightSide = false
}) => {
  const styleProps = { elevation }
  const classes = useStyles(styleProps)
  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'))

  return (
    <HideOnScroll>
      <MuiAppBar className={classes.appBar} color='secondary'>
        <Toolbar disableGutters variant={isXSmall ? 'regular' : 'dense'}>
          <IconButton className={classes.backButton} color='inherit' onClick={onBack}>
            <ArrowBackIosIcon />
          </IconButton>

          <Typography variant='h6' color='inherit' id='app-title' noWrap>
            {title}
          </Typography>
          <Grid>
            <LanguageSwitch />
          </Grid>

          {iconAfter && (
            <Box
              display='flex'
              alignItems='center'
              flex={1}
              justifyContent={iconAfterToRightSide ? 'flex-end' : 'flex-start'}
            >
              {iconAfter}
            </Box>
          )}
        </Toolbar>
      </MuiAppBar>
    </HideOnScroll>
  )
}

export default React.memo(AppBarWithoutMenu)
