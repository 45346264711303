import React from 'react'

import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

interface ISmAnswerResolutionProps {
  resolution: boolean | null
}

interface IStylesProps {
  resolution: boolean | null
}

const SIZE = '43px'

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 14,
      fontWeight: 500,
      color: 'white',
      textTransform: 'uppercase',
      height: SIZE,
      minHeight: SIZE,
      minWidth: SIZE,
      borderRadius: '50%',
      padding: theme.spacing(1)
    },
    booleanResolution: {
      background: ({ resolution }) => (resolution === null ? '#98999B' : resolution ? '#ADCB57' : '#B80718')
    }
  })
)

const SmAnswerResolution: React.FC<ISmAnswerResolutionProps> = ({ resolution = false }) => {
  const classes = useStyles({ resolution })
  return (
    <div className={classNames([classes.root, classes.booleanResolution])}>
      {resolution === null ? 'N/A' : resolution ? 'Дa' : 'Нет'}
    </div>
  )
}

export default React.memo(SmAnswerResolution)
