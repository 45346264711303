import React from 'react'

import { createStyles, makeStyles, SvgIcon, Theme, Typography } from '@material-ui/core'
import { useTranslation } from "react-i18next";

import { ReactComponent as ReadyIcon } from '../../../assets/icons/ready.svg'
import { ValidationFab } from '../../../components/validation-fab'
import { grays } from '../../../layout/theme'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '50vh',
      color: grays.gray4,
    },
    icon: {
      width: 160,
      height: 160,
      marginBottom: 12,
      [theme.breakpoints.down('xs')]: {
        height: 76,
        width: 76,
      },
    },
  }),
)

interface TaskStartScreenProps {
  children?: never
  onStart: VoidFunction
}

export const TaskStartScreen: React.FC<TaskStartScreenProps> = ({ onStart }) => {
  const classes = useStyles()
  const { t } = useTranslation('task')

  return (
    <div className={classes.container}>
      <SvgIcon className={classes.icon} viewBox='0 0 163 165' component={ReadyIcon} />
      <Typography>{t('startTasksPreview')}</Typography>
      <ValidationFab isValid={true} onClick={onStart}>
        {t('startTasksButton')}
      </ValidationFab>
    </div>
  )
}
