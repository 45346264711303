import { FieldPositionRoleUserProfileStore } from '../../data/schema'
import { IUserProfile } from '../../model/user-profile'
import IFprUserProfileService from '../fpr-user-profile-service-api'
import { LocalStorageBaseService } from './local-storage-base-service'

export default class LocalStorageFprUserProfileService
  extends LocalStorageBaseService
  implements IFprUserProfileService
{
  private static readonly __className = 'LocalStorageFprUserProfileService'

  async getFprUserProfiles(): Promise<IUserProfile[]> {
    return this._storage.getAll<IUserProfile>(FieldPositionRoleUserProfileStore)
  }
}
