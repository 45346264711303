import React, { useContext, useEffect } from 'react'

import { createStyles, Grid, Theme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAsync } from 'react-use'

import { EmptyMessage } from '../../../components'
import { IPointOfSale } from '../../../model/pos'
import { ApiContext } from '../../../providers'
import { AppRoutes as route } from '../../../routes'
import { getPosAddress } from '../../_common/pos'
import { getRegisteredNameFromPos } from '../store-menu-page/utils'
import StoresListItem from '../stores-list-item/stores-list-item'

export type SelectedItemId = string | null

interface IStoresListProps {
  selectable?: boolean
  menuAccess?: boolean
  onSelect?: (selectedId: SelectedItemId) => void
  selectedId?: SelectedItemId
  searchQuery?: string
  currentPosCode?: string
  showOnlySelected?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrap: {
      paddingTop: 7,
      paddingBottom: 7,
      backgroundColor: '#ffffff',
    },
    listWrapper: {
      flexGrow: 1,
      // height: 0,
      // border: `1px solid ${grays.gray5}`,
      marginTop: 24,
      // paddingLeft: 25,
      // paddingRight: 25,
      backgroundColor: '#ffffff',
    },
    emptyMessage: {
      minHeight: 'calc(100vh - 590px)',
      [theme.breakpoints.down('xs')]: {
        minHeight: 'calc(100vh - 530px)',
      },
    },
  }),
)

const StoresList: React.FC<IStoresListProps> = (props) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { state } = useLocation()

  const api = useContext(ApiContext)
  const posListData = useAsync(async () => {
    const profile = await api.userProfile.getCurrentProfile()
    return await api.pos.searchPos({
      searchQuery: props.searchQuery ?? '',
      positionRoleCoverage: profile?.fieldPositionRole?.code ?? '',
    })
  }, [props.searchQuery])

  const { i18n, t, ready } = useTranslation('stores')
  useEffect(() => {
    !ready && i18n.reloadResources(i18n.language, 'stores')
  }, [ready])

  const handleItemClick =
    (item: IPointOfSale) =>
    (_event: React.MouseEvent<HTMLElement>): void => {
      if (props.selectable && props.onSelect) {
        const newSelectedId = props.selectedId === item.code ? null : item.code
        props.onSelect(newSelectedId)
      } else {
        if (props.menuAccess) {
          navigate(`/${route.stores.path}/store/${item.code}/menu`, {
            state: {
              ...(state as Record<string, unknown>),
              prevPath: location.pathname,
            },
          })
        }
      }
    }

  if (posListData.loading) {
    return <EmptyMessage className={classes.emptyMessage} message={'Загрузка'} marginTop={10} marginBottom={10} />
  }

  if (!posListData.value?.length) {
    const msg = props.searchQuery ? t('emptySearchMessage') : t('emptyMessage')
    return <EmptyMessage className={classes.emptyMessage} message={msg} marginTop={10} marginBottom={10} />
  }

  const renderListItem = (item: IPointOfSale): JSX.Element => {
    return (
      <Grid item xs={12} key={item.code} onClick={handleItemClick(item)}>
        <StoresListItem
          selected={props.selectedId === item.code}
          code={item.code}
          name={item.name}
          address={getPosAddress(item)}
          menuAccess={props.menuAccess}
          registerName={getRegisteredNameFromPos(item)}
        />
      </Grid>
    )
  }

  let filteredAndSortedList = posListData.value?.filter((x) => x.code !== props?.currentPosCode).sort(sortByName)

  if (props.selectedId && props.showOnlySelected) {
    filteredAndSortedList = filteredAndSortedList.filter((item) => item.code === props.selectedId)
  }

  if (props.searchQuery?.length && !filteredAndSortedList.length) {
    return (
      <Box mt={3} width='100%'>
        <Typography align='center'>{t('emptySearchMessage')}</Typography>
      </Box>
    )
  }
  return (
    <div className={classes.listWrapper}>
      <Grid container className={classes.wrap}>
        {filteredAndSortedList.map(renderListItem)}
      </Grid>
    </div>
  )
}

export default StoresList

function sortByName(a: IPointOfSale, b: IPointOfSale): number {
  if (!a.name || !b.name) {
    return -1
  }
  return a.name.localeCompare(b.name)
}
