import React from 'react'

import { Typography } from '@material-ui/core'
import type { Content } from 'pdfmake/interfaces'
import { ErrorBoundary } from 'react-error-boundary'

import type { IRepeaterScreenItem } from '../../../../model/repeater-screen-item'
import type { IScreenItem } from '../../../../model/screen-item'
import type { IApiContext } from '../../../../providers/api/api-context'
import { NestedContextProvider } from '../../nested/nested-context'
import { getContextProperty } from '../../script-tasks/propertyName'
import { IScriptTaskContext, useScriptTaskContext } from '../../script-tasks/script-task-context'
import { ItemCard } from './item-card'
import { ScreenItem } from './screen-item'
import { filterTableRows } from './table-item/utils'

interface IRepeaterRenderItem {
  key: string
  value: unknown
}

export const RepeaterScreenItem: React.FC<{
  item: IRepeaterScreenItem
}> = function RepeaterScreenItem({ item }) {
  return <RepeaterRenderer item={item} messageRender={RepeaterMessageCard} itemsRender={RepeaterRenderItem} />
}

const RepeaterRenderItem: React.FC<{
  item: IScreenItem
}> = function RepeaterRenderItem({ item }) {
  return (
    <ScreenItem
      state={{
        value: '',
        touched: false,
        validate: { isError: false },
      }}
      onBlur={() => null}
      onChange={async () => Promise.resolve()}
      item={item}
    />
  )
}

const RepeaterMessageCard: React.FC<{
  title?: string
  message: string
  isError?: boolean
}> = function RepeaterMessageCard(props) {
  const { isError, message } = props
  const title = props.title ?? (isError ? 'Ошибка' : undefined)
  return (
    <ItemCard label={title && <Typography variant='inherit'>{title}</Typography>} isError={isError}>
      <Typography variant='body1'>
        <strong>{message}</strong>
      </Typography>
    </ItemCard>
  )
}

export const RepeaterRenderer: React.FC<{
  item: IRepeaterScreenItem
  messageRender: React.FC<{ title?: string; message: string; isError?: boolean }>
  itemsRender: React.FC<{
    item: IScreenItem
  }>
}> = function RepeaterRenderer(props) {
  const context = useScriptTaskContext()
  const { item, messageRender: MessageRender, itemsRender: ItemsRender } = props
  const { propertyName, itemRender, hideEmpty } = item
  const recordKeyPropertyName = item.recordKeyPropertyName ?? 'code'
  const propValue = getContextProperty<unknown[] | null>(context, propertyName, null)

  if (!propValue) {
    return hideEmpty ? null : <MessageRender message={`Значение свойства ${propertyName} отсутствует`} />
  }
  if (!Array.isArray(propValue)) {
    const title = `При отрисовке коллекции "${propertyName}" произошла ошибка`
    return <MessageRender isError title={title} message='Значение не является массивом' />
  }
  const filteredList = filterTableRows(propValue, item, context)
  const keyValueList: IRepeaterRenderItem[] = filteredList.map((value, index) => ({
    key: String(value?.[recordKeyPropertyName] ?? index),
    value,
  }))
  if (!keyValueList.length || !itemRender.length) {
    if (hideEmpty) {
      return null
    }
    let emptyMessage = item.nullValueCaption ?? 'Список строк пуст'
    if (keyValueList.length && !itemRender.length) {
      emptyMessage = `Не настроены элементы экрана (itemRender) для отображения элементов коллекции "${propertyName}"`
    }
    return <MessageRender message={emptyMessage} />
  }
  return (
    <>
      {keyValueList.map((renderItem) => {
        const nestedContextData = { [item.elementVariable]: renderItem.value }
        return (
          <ErrorBoundary
            key={renderItem.key}
            fallbackRender={(props) => (
              <MessageRender
                isError
                title={`При отрисовке элемента коллекции с ключом "${renderItem.key}" произошла ошибка`}
                message={props.error.message ?? 'Неизвестная ошибка'}
              />
            )}
          >
            <NestedContextProvider {...nestedContextData}>
              {item.itemRender.map((innerItem, index) => (
                <ItemsRender key={index} item={innerItem} />
              ))}
            </NestedContextProvider>
          </ErrorBoundary>
        )
      })}
    </>
  )
}

export const RepeaterRendererAsync: (props: {
  item: IRepeaterScreenItem
  api: IApiContext
  propertiesContext: IScriptTaskContext
  messageRender: (props: { title?: string; message: string; isError?: boolean }) => Promise<Content>
  itemsRender: (
    item: IScreenItem,
    propertiesContext: IScriptTaskContext,
    api: IApiContext,
  ) => Promise<Content | undefined>
}) => Promise<Array<Content | undefined>> = async function RepeaterRendererAsync(
  props,
): Promise<Array<Content | undefined>> {
  const { item, propertiesContext: context, messageRender, itemsRender, api } = props
  const { propertyName, itemRender, hideEmpty } = item
  const recordKeyPropertyName = item.recordKeyPropertyName ?? 'code'
  const propValue = getContextProperty<unknown[] | null>(context, propertyName, null)

  if (!propValue) {
    return hideEmpty ? [] : [await messageRender({ message: `Значение свойства ${propertyName} отсутствует` })]
  }
  if (!Array.isArray(propValue)) {
    const title = `При отрисовке коллекции "${propertyName}" произошла ошибка`
    return [await messageRender({ message: 'Значение не является массивом', title, isError: true })]
  }
  const filteredList = filterTableRows(propValue, item, context)
  const keyValueList: IRepeaterRenderItem[] = filteredList.map((value, index) => ({
    key: String(value?.[recordKeyPropertyName] ?? index),
    value,
  }))
  if (!keyValueList.length || !itemRender.length) {
    if (hideEmpty) {
      return []
    }
    let emptyMessage = item.nullValueCaption ?? 'Список строк пуст'
    if (keyValueList.length && !itemRender.length) {
      emptyMessage = `Не настроены элементы экрана (itemRender) для отображения элементов коллекции "${propertyName}"`
    }
    return [await messageRender({ message: emptyMessage })]
  }

  const result = await Promise.all(
    keyValueList.map(async (renderItem) => {
      try {
        const nestedContext = {
          ...context,
          [item.elementVariable ?? 'record']: renderItem.value,
        }
        return await Promise.all(item.itemRender.map(async (innerItem) => itemsRender(innerItem, nestedContext, api)))
      } catch (error) {
        return messageRender({
          isError: true,
          title: `При отрисовке элемента коллекции с ключом "${renderItem.key}" произошла ошибка`,
          message: error.message ?? 'Неизвестная ошибка',
        })
      }
    }),
  )
  return result.flat()
}
