function generateKey(): string {
  const seed = Math.floor(Math.random() * 1000000)
  return `${Date.now().toString(36).toUpperCase()}-${seed.toString().padStart(6, '0')}`
}

export function getClientId(): string {
  let cid = window.localStorage.getItem('__clientId')
  if (cid == null) {
    cid = generateKey()
    window.localStorage.setItem('__clientId', cid)
  }
  return cid
}
