/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from 'react'

import { Box, Button, createStyles, Dialog, makeStyles, MenuItem, Portal, Select, Theme } from '@material-ui/core'
import ReactJson from 'react-json-view'
import { useAsync } from 'react-use'

import { VisitStatus } from '../../../model/visit'
import { VisitTaskStatus } from '../../../model/visit-task'
import { ApiContext } from '../../../providers'
import { useBusinessSettings } from '../../_common/hooks/useBusinessSettings'
import { useLocalContextService } from '../nested/local-context'
import { useScriptTaskContext } from '../script-tasks/script-task-context'
import { ScriptToast } from '../script-tasks/script-toast'
import { DebugSteps } from './debug-steps'
import { DeleteReportLink } from './delete-report-link'

interface DebugTreeProps {
  children?: never
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'fixed',
      top: 0,
      right: 0,
      zIndex: theme.zIndex.modal,
    },
  }),
)

export const DebugTree: React.FC<DebugTreeProps> = () => {
  const { processExecutionContextButtonEnabled } = useBusinessSettings() ?? {}
  const USE_DEBUG_TREE = processExecutionContextButtonEnabled === 'true'
  if (!USE_DEBUG_TREE) {
    return <></>
  }
  return <Tree />
}

const Tree: React.FC<DebugTreeProps> = (props) => {
  const ref = useRef<HTMLElement>()
  const api = useContext(ApiContext)
  const contextService = useLocalContextService()

  useEffect(() => {
    ref.current = document.getElementById('debug-root') ?? undefined
    console.log('DebugTree mount', props, ref.current)
  }, [])

  const classes = useStyles()
  const [isOpen, setOpen] = useState(false)
  const localContext = useScriptTaskContext()
  const { visit, task, pos } = localContext
  const matricesOps = useAsync(async () => {
    if (!pos) return
    return api.pos.getProductMatrices(pos.code, new Date())
  }, [pos?.code])
  const matrices = matricesOps.value
  const contractTermsOps = useAsync(async () => {
    if (!pos) return
    return api.pos.getContractTerms(pos.code, new Date())
  }, [pos?.code])
  const contractTerms = contractTermsOps.value

  const [visitStatus, setVisitStatus] = useState<VisitStatus | undefined>(visit?.status)
  const [taskStatus, setTaskStatus] = useState<VisitTaskStatus>(task?.status)

  const refetch: typeof contextService.refetch = async () => {
    return contextService.refetch()
  }

  return (
    <Portal container={ref.current}>
      <div className={classes.container}>
        <Button variant='text' onClick={() => setOpen(true)}>
          Context
        </Button>
      </div>
      <Dialog fullScreen open={isOpen} onClose={() => setOpen(false)} keepMounted>
        <Box display='flex' marginBottom='16px' padding={1} paddingLeft={2} justifyContent='space-around'>
          {/* <ScriptToast /> */}
          <Select value={visitStatus} onChange={(ev) => setVisitStatus(ev.target.value as VisitStatus)}>
            <MenuItem value='Planned'>Planned</MenuItem>
            <MenuItem value='InProgress'>InProgress</MenuItem>
            <MenuItem value='Finished'>Finished</MenuItem>
            <MenuItem value='Canceled'>Canceled</MenuItem>
          </Select>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            onClick={async () => {
              if (visit) {
                await api.visits.setVisitStatus({ visitCode: visit.code, visitStatus: visitStatus! })
                await refetch()
              }
            }}
          >
            Set visit status
          </Button>
          <Box width={25} />
          <Select value={taskStatus} onChange={(ev) => setTaskStatus(ev.target.value as VisitTaskStatus)}>
            <MenuItem value='Planned'>Planned</MenuItem>
            <MenuItem value='InProgress'>InProgress</MenuItem>
            <MenuItem value='Finished'>Finished</MenuItem>
            <MenuItem value='Canceled'>Canceled</MenuItem>
          </Select>
          <Button
            variant='contained'
            disableElevation
            color='primary'
            onClick={async () => {
              if (task) {
                await api.tasks.setTaskStatus({ taskCode: task.code, taskStatus })
                await refetch()
              }
            }}
          >
            Set task status
          </Button>
          <DebugSteps />
          <DeleteReportLink />
          <Box ml='auto'>
            <Button onClick={() => setOpen(false)}>Close</Button>
          </Box>
        </Box>
        <ReactJson
          enableClipboard={(value) => {
            void navigator.clipboard.writeText(JSON.stringify(value.src))
            // return console.log('on copy', value)
          }}
          style={{ fontWeight: 550 }}
          src={{ ...localContext, matrices, contractTerms }}
          collapsed={1}
        />
      </Dialog>
    </Portal>
  )
}
