import { useNavigate } from 'react-router-dom'

import { Code } from '../../../../../model/base'

export function useGotoSubProcess(item: unknown): (processCode: Code, recordCode: Code) => void {
  const navigate = useNavigate()

  return function (processCode: Code, recordCode: Code) {
    console.log('going to subprocess', processCode, recordCode)
    navigate(`process/${processCode}/${recordCode}`, { state: { item } })
  }
}
