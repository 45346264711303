import React, { ChangeEvent, useEffect } from 'react'

import { createStyles, InputBase, makeStyles, Paper, Theme } from '@material-ui/core'
import { useTranslation } from "react-i18next";
import { useDebounce } from 'react-use'

import { grays } from '../../../../layout/theme'

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '24px',
      padding: '3px 20px 3px 20px',
      display: 'flex',
      alignItems: 'center',
      height: 40,
      flex: '0 0 40px',
      borderRadius: '40px',
      boxShadow: 'none'
    },
    input: {
      width: '100%',
      paddingLeft: '10px',
      paddingRight: '10px',
      '& > .MuiInputBase-input': {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 500,
        letterSpacing: '0.1px',
        '&::placeholder': {
          color: grays.gray2,
          opacity: 1,
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 500,
        },
      }
    },
    iconSearch: {
      color: grays.gray2
    }
  })
)

interface IVisitSearchProps {
  query: string
  onChange: (queryString: string) => void
}

export const VisitSearch: React.FC<IVisitSearchProps> = ({ query, onChange }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')

  const [val, setVal] = React.useState(query)
  const [debouncedValue, setDebouncedValue] = React.useState(query)
  useDebounce(
    () => {
      setDebouncedValue(val)
    },
    300,
    [val]
  )

  useEffect(() => onChange(debouncedValue), [debouncedValue])

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setVal(e.target.value)
  }

  return (
    <Paper className={classes.root}>
      <svg className={classes.iconSearch} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="11" cy="11" r="7" stroke="#858F97" strokeWidth="2"/>
        <path d="M20 20L17 17" stroke="#858F97" strokeWidth="2" strokeLinecap="round"/>
      </svg>
      {/*<SearchIcon className={classes.iconSearch} />*/}
      <InputBase
        placeholder={t('search')}
        className={classes.input}
        inputProps={{ 'aria-label': 'visits on map search' }}
        value={val}
        onChange={handleChange}
      />
    </Paper>
  )
}
