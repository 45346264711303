import React, { useMemo } from 'react'

import { makeStyles, Theme, createStyles, Typography } from '@material-ui/core'

import { SETaskDirectionsScreenItem } from '../../../../../model/screen-item'
import { getSurveysByPropertyName } from '../../../../_common/hooks/useCreateSurvey'
import { IScriptTaskContext, useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { sortAnswers } from '../../utils'

interface Props {
  item: SETaskDirectionsScreenItem
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ed: {
      fontSize: 20,
      marginLeft: theme.spacing(2),
    },
  }),
)

export const TaskDirectionsPreview: React.FC<Props> = ({ item }) => {
  const propertiesContext = useScriptTaskContext()
  const classes = useStyles()
  console.log('taskdirectionspreview', item, propertiesContext)

  const surveys = getSurveysByPropertyName(item.propertyName || 'task.surveys', propertiesContext)
  const edLasSurvey = surveys.find((survey) => survey.questionnaire.code === item.educationDirectionLasSurveyCode)
  const edLasQuestionnaire = propertiesContext.questionnaires.find(
    (questionnaire) => questionnaire.code === item.educationDirectionLasSurveyCode,
  )
  const edLauSurvey = surveys.find((survey) => survey.questionnaire.code === item.educationDirectionLauSurveyCode)

  const edLauQuestionnaire = propertiesContext.questionnaires.find(
    (questionnaire) => questionnaire.code === item.educationDirectionLauSurveyCode,
  )

  const sortedLasAnswers = useMemo(() => {
    if (!edLasQuestionnaire || !edLasSurvey?.answers?.length) {
      return []
    }
    return sortAnswers(edLasQuestionnaire, edLasSurvey.answers.slice())
  }, [edLasQuestionnaire, edLasSurvey?.answers])

  const sortedLauAnswers = useMemo(() => {
    if (!edLauQuestionnaire || !edLauSurvey?.answers?.length) {
      return []
    }
    return sortAnswers(edLauQuestionnaire, edLauSurvey.answers.slice())
  }, [edLauQuestionnaire, edLauSurvey?.answers])

  const isFieldTraining = !edLasSurvey || !edLauSurvey

  return (
    <>
      {!isFieldTraining && !!sortedLauAnswers.length && <div>- LAU</div>}
      {sortedLauAnswers.map((answer) => {
        const question = edLauQuestionnaire?.questions.find((question) => question.code === answer.questionCode)
        if (!question) return null

        return (
          <Typography className={classes.ed} key={answer.questionCode}>
            - {question.description}
          </Typography>
        )
      })}
      {!isFieldTraining && !!sortedLasAnswers.length && <div>- LAS</div>}
      {sortedLasAnswers.map((answer) => {
        const question = edLasQuestionnaire?.questions.find((question) => question.code === answer.questionCode)
        if (!question) return null

        return (
          <Typography className={classes.ed} key={answer.questionCode}>
            - {question.description}
          </Typography>
        )
      })}
    </>
  )
}

export function TaskDirectionsPdf(
  item: SETaskDirectionsScreenItem,
  propertiesContext: IScriptTaskContext,
): Array<'' | { text: string; style: string }> {
  console.log({ item, propertiesContext })
  const lasQuestionnaire = propertiesContext.questionnaires.find(
    (questionnaire) => questionnaire.code === item.educationDirectionLasSurveyCode,
  )
  const lauQuestionnaire = propertiesContext.questionnaires.find(
    (questionnaire) => questionnaire.code === item.educationDirectionLauSurveyCode,
  )

  const surveys = getSurveysByPropertyName(item.propertyName || 'task.surveys', propertiesContext)

  const lasSurvey = surveys.find((survey) => survey.questionnaire.code === item.educationDirectionLasSurveyCode)
  const lauSurvey = surveys.find((survey) => survey.questionnaire.code === item.educationDirectionLauSurveyCode)

  const edLasAnswers = lasQuestionnaire
    ? sortAnswers(lasQuestionnaire, lasSurvey?.answers?.slice() ?? [])
        .map((answer) => {
          const question = lasQuestionnaire.questions.find((question) => question.code === answer.questionCode)
          if (!question) return ''
          return { text: ` - ${question?.description}`, style: 'edAnswer' }
        })
        .filter((v) => v)
    : []

  const edLauAnswers = lauQuestionnaire
    ? sortAnswers(lauQuestionnaire, lauSurvey?.answers?.slice() ?? [])
        .map((answer) => {
          const question = lauQuestionnaire.questions.find((question) => question.code === answer.questionCode)
          if (!question) return ''
          return { text: ` - ${question?.description}`, style: 'edAnswer' }
        })
        .filter((v) => v)
    : []

  if (!lasSurvey || !lauSurvey) {
    return [...edLauAnswers, ...edLasAnswers]
  }

  return [
    ...(edLauAnswers.length ? [{ text: '- LAU:', style: 'label' }] : []),
    ...edLauAnswers,
    ...(edLasAnswers.length ? [{ text: '- LAS:', style: 'label' }] : []),
    ...edLasAnswers,
  ]
}
