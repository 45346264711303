import { forwardRef, useEffect, useImperativeHandle } from 'react'

import { useTranslation } from 'react-i18next'

import { IReportProcessor } from '../../../../model/report-processor'
import { IReportUserStepScreen } from '../../../../model/user-step-screen'
import { appToast } from '../../../../utils'
import { useScriptTaskContext } from '../../script-tasks/script-task-context'
import { PreviewControl } from '../preview-control'
import { ScreenRef } from '../types'
import { validateReportTaskStageScreen } from './validate'

interface Props {
  resultFile?: IReportProcessor
  screen: IReportUserStepScreen
  onReadyChange: (isReady: boolean) => void
}

export const ReportTaskStage = forwardRef<ScreenRef, Props>(function ReportTaskStage(
  { resultFile, screen, onReadyChange },
  ref,
) {
  useEffect(() => {
    console.log('ReportTaskStage', resultFile, screen)
  }, [])
  const { t } = useTranslation('sales-expert-tasks')
  const propertiesContext = useScriptTaskContext()
  useImperativeHandle(
    ref,
    () => {
      return {
        validate: () => {
          const { invalidItem, message } = validateReportTaskStageScreen(screen, resultFile, propertiesContext)
          console.log({ invalidItem, message })
          if (message) {
            appToast.info(t(message))
          }
        },
      }
    },
    [propertiesContext, screen],
  )

  useEffect(() => {
    onReadyChange(!validateReportTaskStageScreen(screen, resultFile, propertiesContext).invalidItem)
  }, [propertiesContext])

  return <PreviewControl resultFile={resultFile} />
})
