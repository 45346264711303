import React, { useEffect } from 'react'

import { Badge, Box, createStyles, makeStyles, SvgIcon } from '@material-ui/core'
import { useNetworkState } from 'react-use'

import { ReactComponent as SyncDisabled } from '../../assets/icons/sync-disabled.svg'
import { ReloadIcon } from '../../components/icons/reload'
import { useFetchPendingItems, usePendingItems } from '../../providers/menu-data/pending-items-store'
import MenuItem from './menu-item'

interface SyncMenuItemProps {
  children?: never
  to: string
  onlyIcon: boolean
  primaryText: string
}

const useStyles = makeStyles(() =>
  createStyles({
    badge: {
      '& .MuiBadge-dot': {
        backgroundColor: '#FFCC00',
        height: 12,
        minWidth: 12,
        borderRadius: '50%',
      },
    },
  }),
)

export const SyncMenuItem: React.FC<SyncMenuItemProps> = (props) => {
  // useEffect(() => {
  //   console.log('SyncMenuItem mount', props)
  // }, [])
  const classes = useStyles()
  const { to, onlyIcon, primaryText } = props
  const networkState = useNetworkState()
  const { isPending: areItemsPending, pendingPhotoCount } = usePendingItems()
  const fetchPendingItems = useFetchPendingItems()
  useEffect(() => {
    fetchPendingItems()
  }, [fetchPendingItems])

  const isPending = areItemsPending || !!pendingPhotoCount

  const icon = (
    <>
      <Badge
        variant='dot'
        invisible={!isPending}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className={classes.badge}
      >
        {networkState.online ? (
          <ReloadIcon />
        ) : (
          <Box paddingX='2px' pt='0.5px' height={24} width={24} fontSize={20}>
            <SvgIcon viewBox='0 0 16 16' fontSize='inherit' component={SyncDisabled} />
          </Box>
        )}
      </Badge>
    </>
  )
  return <MenuItem to={to} onlyIcon={onlyIcon} primaryText={primaryText} leftIcon={icon} />
}
