import { ISupervisedFieldPositionRole } from '../../../../../model/supervised-field-position-role'
import { EmployeeDisplayName } from '../../../../../model/user-step-screen'

export function getEmployeeName(
  supervisedFieldPositionRole: ISupervisedFieldPositionRole,
  employeeDisplayName?: EmployeeDisplayName,
): string {
  switch (employeeDisplayName) {
    case 'RoleName':
      return supervisedFieldPositionRole.code || ''
    case 'Name':
    default:
      const employeeName = [
        supervisedFieldPositionRole.currentEmployee?.contact?.surname,
        supervisedFieldPositionRole.currentEmployee?.contact?.name,
      ]
        .filter((v) => v)
        .join(' ')
      return employeeName
  }
}
