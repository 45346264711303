import { Theme } from '@material-ui/core/styles'
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles'

export const getPageLayoutStyles = (theme: Theme): CreateCSSProperties => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  [theme.breakpoints.down('xs')]: {
    paddingTop: theme.spacing(2),
    padding: theme.spacing(0)
  }
})
