import React, { useContext, useState } from 'react'

import {
  Avatar,
  Breadcrumbs,
  createStyles,
  Dialog,
  DialogTitle,
  IconButton,
  SvgIcon,
  Typography,
} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Link, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { BackButton } from '../../../layout/back-button'
import { PageContent } from '../../../layout/page-content'
import { grays } from '../../../layout/theme'
import { TitleBar } from '../../../layout/title-bar'
import TitleView from '../../../layout/title-view'
import { ApiContext } from '../../../providers'
import { ModalContext } from '../../../providers/modal'
import { useIsSmall } from '../../_common/hooks/useIsSmall'
import { ErrorPage } from '../../error-page'
import { PosMenuItems } from './menu-items'
import { ProblemMobileSearchItem } from './problems/items/problem-mobile-search-item'
import { StoresMenuList } from './stores-menu-list'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '100%',
      paddingTop: 147,
      [theme.breakpoints.down('xs')]: {
        paddingTop: 131,
      },
    },
    nameWrap: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: 32,
      [theme.breakpoints.down('xs')]: {
        paddingRight: 16,
      },
    },
    name: {
      fontSize: 28,
      lineHeight: '36px',
      fontWeight: 500,
      letterSpacing: '0.1px',
      [theme.breakpoints.down('xs')]: {
        fontSize: 22,
        fontWeight: 400,
      },
    },
    backButton: {
      marginLeft: -8,
      marginRight: 8,
      [theme.breakpoints.down('xs')]: {
        marginLeft: -4, // looks worse but easier to click
        width: 32,
        padding: 2,
      },
    },
    avatar: {
      width: 36,
      height: 36,
      marginRight: 16,
      [theme.breakpoints.down('xs')]: {
        width: 24,
        height: 24,
        marginRight: 12,
        fontSize: 12,
      },
    },
    contentWrap: {
      minHeight: '100%',
      padding: '24px 24px 0',
      [theme.breakpoints.down('xs')]: {
        padding: '16px 17px 0',
      },
    },
    breadcrumbs: {
      width: '100%',
      marginTop: 16,
      fontSize: 16,
      '& ol': {
        width: '100%',
        flexWrap: 'noWrap',
      },
      '& li:last-child': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
    breadcrumbsLink: {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.main,
      },
      '& .MuiBreadcrumbs-separator': {
        color: theme.palette.text.primary,
      },
    },
    dialogTitle: {
      // padding: theme.spacing(2, 2, 0),
      minWidth: 240,
      position: 'relative',
    },
    closeButton: {
      position: 'absolute',
      top: 4,
      right: 4,
      color: grays.gray4,
    },
    pageContent: {
      minHeight: '100%',
    },
  }),
)

export interface IStoreMenuLocationState {
  prevPath: string
  rootPrevPath: string
  prevParticipantPath?: string
  prevProgramsPath?: string
  prevPPOSMPath?: string
}

interface IProps {
  searchQuery: string
}

export const StoreMenuPage: React.FC = () => {
  console.log('store menu page')
  const { t } = useTranslation('stores')
  const classes = useStyles()
  const modalContext = useContext(ModalContext)
  const api = useContext(ApiContext)
  const navigate = useNavigate()
  const isSmall = useIsSmall()

  const location = useLocation()

  const state = location.state as IStoreMenuLocationState
  const pathname = location.pathname
  const { posCode } = useParams<{ posCode: string }>()
  const prevPath = (state as IStoreMenuLocationState)?.prevPath
  const rootPrevPath = (state as IStoreMenuLocationState)?.rootPrevPath ?? '/stores'
  const isMenu = pathname.split('/').reverse()[0] === 'menu'

  const [isOpenMobileSearch, setIsOpenMobileSearch] = useState(false)
  const [searchQuery, setSearchQuery] = useState<string>('')

  const onSearchHandler = (queryString: string): void => {
    sessionStorage.setItem(`${pathname}-searchInput`, queryString)
    setSearchQuery(queryString)
  }

  const onBackHandler = (): void => navigate(isMenu ? rootPrevPath : prevPath, { state })

  const posOps = useAsync(async () => await api.pos.getPos(posCode!), [posCode])

  if (posOps.error) {
    return <ErrorPage errorMessage={posOps.error.message} />
  }

  if (posOps.loading && !posOps.value) return <></>
  const pos = posOps.value!

  const findSelectMenu = Object.values(PosMenuItems).find((item) => item.path === pathname.split('/').pop())

  const renderToolbar = (): JSX.Element | undefined => {
    return (
      <>
        <TitleView pos={pos} />
        {findSelectMenu && (
          <Breadcrumbs className={classes.breadcrumbs} aria-label='breadcrumb'>
            <Link className={classes.breadcrumbsLink} to='' state={state}>
              {isSmall ? t('listMobile') : t('list')}
            </Link>
            <Typography color='textPrimary'>{t(`menu.${findSelectMenu.path}`)}</Typography>
          </Breadcrumbs>
        )}
      </>
    )
  }

  const renderButtonSearch = (): JSX.Element | undefined => {
    if (isSmall && location.pathname.includes('problems')) {
      return (
        <IconButton
          onClick={() => {
            setIsOpenMobileSearch(true)
          }}
        >
          <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='11' cy='11' r='7' stroke='#546066' strokeWidth='2' />
            <path d='M20 20L17 17' stroke='#546066' strokeWidth='2' strokeLinecap='round' />
          </svg>
        </IconButton>
      )
    }
  }

  return (
    <div className={classes.root}>
      {isSmall && (
        <ProblemMobileSearchItem
          query={sessionStorage.getItem(`${pathname}-searchInput`) ?? ''}
          onChange={onSearchHandler}
          isOpen={isOpenMobileSearch}
          onClose={() => {
            sessionStorage.setItem(`${pathname}-searchInput`, '')
            setSearchQuery('')
            setIsOpenMobileSearch(false)
          }}
        />
      )}
      <TitleBar fixed additionalToolbar={renderToolbar()} icons={renderButtonSearch()}>
        <Box className={classes.nameWrap}>
          <BackButton className={classes.backButton} onBack={onBackHandler} />
          <Avatar className={classes.avatar} alt={pos.name} src='/static/images/avatar/1.jpg' />
          <Typography
            className={classes.name}
            component='h6'
            noWrap={true}
            onClick={() =>
              modalContext.open(
                <Dialog open onClose={modalContext.close}>
                  <DialogTitle disableTypography className={classes.dialogTitle}>
                    {pos.name}
                    <IconButton className={classes.closeButton} onClick={modalContext.close} edge='end' size='small'>
                      <SvgIcon viewBox='0 0 18 18' component={CloseIcon} />
                    </IconButton>
                  </DialogTitle>
                </Dialog>,
              )
            }
          >
            {pos.name}
          </Typography>
        </Box>
      </TitleBar>
      <PageContent className={classes.pageContent}>
        <div className={classes.contentWrap}>
          <Routes>
            <Route path='' element={<StoresMenuList />} />
            {Object.values(PosMenuItems).map((item) => {
              const Component: React.FC<IProps> = item.component
              return <Route key={item.path} path={`${item.path}/*`} element={<Component searchQuery={searchQuery} />} />
            })}
          </Routes>
        </div>
      </PageContent>
    </div>
  )
}
