/* eslint-disable @typescript-eslint/indent */
import React, { useMemo } from 'react'

// eslint-disable-next-line import/order
import { Box, createStyles, makeStyles, Paper, Typography } from '@material-ui/core'

// import { grays } from '../../../../../layout/theme'
import classnames from 'classnames'

import { grays } from '../../../../../layout/theme'
import { checkPredicate } from '../../../../../model/script-predicate'
import { ITableScreenItem } from '../../../../../model/table-screen-item'
import { useUpdateProperty } from '../../../nested/useUpdateProperty'
import { getContextProperty, getPropertyAny } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { BooleanCell } from './boolean-cell'
import { BooleanIndicatorCell } from './boolean-indicator-cell'
import { ITableRecord } from './editorUtils'
import { IntegerInputCell } from './integer-input-cell'
import { LabelCell } from './label-cell/label-cell'
import { PhotosDialogCell } from './photos-dialog-cell'
import { QuantityInputCell } from './quantity-input-cell'
import { RowMenu } from './row-menu'
import { SingleSelectCell } from './single-select-cell'
import { TextDialogCell } from './text-dialog-cell'
import { useGotoSubProcess } from './useGotoSubProcess'
import { filterTableRows, getCssClassCell } from './utils'

const useStyles = makeStyles((theme) =>
  createStyles({
    cell: {
      margin: '0px 0',
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '0.15px',
      boxSizing: 'border-box',
      fontWeight: 400,
      maxWidth: '100%',
      '&.LabelCell': {
        minWidth: 0,
      },
      [theme.breakpoints.down('xs')]: {
        '&.BooleanIndicatorCell': {
          marginTop: 8,
        },
      },
    },
    label: {
      marginLeft: 5,
      marginRight: 5,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.15px',
    },
    wrapper: {
      paddingBottom: 16,
      // padding: '8px 16px',
      '&:last-child': {
        borderBottom: 'none',
        paddingBottom: 0,
      },
    },
    endLine: {
      borderBottom: `1px solid ${grays.gray5}`,
      marginBottom: 16,
    },
    paper: {
      // border: `1px solid ${grays.gray5}`,
      marginBottom: theme.spacing(1),
    },
  }),
)

interface Props {
  item: ITableScreenItem
  isReadOnly: boolean
}

export const TableItemPortrait: React.FC<Props> = ({ item, isReadOnly }) => {
  const classes = useStyles()
  const propContext = useScriptTaskContext()
  const updateProperty = useUpdateProperty()
  const gotoSubProcess = useGotoSubProcess(item)
  const entries: ITableRecord[] | null = getContextProperty(propContext, item.propertyName)

  const filteredEntries = filterTableRows(entries, item, propContext)

  if (!item.cells?.length) {
    throw new Error('Table Item cells is empty')
  }

  const cells = item.cells

  const entryLines = useMemo(
    () =>
      groupByIndex(cells, (cell) => cell.portraitSettings.lineNumber ?? 0).map((line) =>
        line.sort(
          (cell1, cell2) => (cell1.portraitSettings.columnNumber ?? 0) - (cell2.portraitSettings.columnNumber ?? 0),
        ),
      ),
    [item.cells],
  )

  // TODO portrait header displayName
  // const titleCell = item.cells.find(
  //   (cell) => cell.portraitAppearance.displayName && cell.portraitAppearance.lineNumber === 0
  // )

  if (!filteredEntries?.length) {
    return (
      <Paper square elevation={0} className={classes.paper}>
        <Box>
          <Typography>{item.nullValueCaption}</Typography>
        </Box>
      </Paper>
    )
  }

  const isSeparator =
    item.tableStyle?.rowStyle?.showSeparatorLine === undefined ? true : item.tableStyle?.rowStyle?.showSeparatorLine

  return (
    <Paper square elevation={0} className={classes.paper}>
      {filteredEntries.map((row, fallBackIndex) => {
        // const title = get(row, titleCell?.propertyName ?? '')
        return (
          <Box className={classnames(classes.wrapper, { [classes.endLine]: isSeparator })} key={fallBackIndex}>
            {/* {title && (
              <Box gridRow={1} gridColumn={1}>
                <Typography variant='h4'>{title}</Typography>
              </Box>
            )} */}
            {entryLines.map((line, i) => {
              const defaultAction = item.rowSubprocesses?.find((settings) => {
                return checkPredicate(
                  settings.defaultProcessCondition ?? {
                    $type: 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate',
                    value: false,
                  },
                  row,
                  propContext,
                )
              })
              const recordCodePropertyKeyName = item.recordKeyPropertyName ?? 'checkNumber'
              const recordCode = getPropertyAny(row, recordCodePropertyKeyName, fallBackIndex.toString())
              const foundIndex =
                entries?.findIndex((row) => getPropertyAny<unknown>(row, recordCodePropertyKeyName) === recordCode) ??
                -1
              const realIndex = foundIndex > -1 ? foundIndex : undefined
              const rowI = realIndex ?? fallBackIndex
              const onRowClick = (): void => {
                if (!defaultAction) return
                gotoSubProcess(defaultAction.process.code, recordCode)
              }
              return (
                <Box key={i} display='flex' onClick={onRowClick}>
                  {line.map((cell, i) => {
                    const id = `${cell.propertyName};${String(i)};${String(rowI)}`
                    const renderValue = (): JSX.Element => {
                      switch (cell.control.$type) {
                        case 'PMI.FACE.BDDM.Extensions.Classes.CheckBoxCell':
                          return <BooleanCell variant='portrait' col={cell} row={row} i={rowI} id={id} />
                        case 'PMI.FACE.BDDM.Extensions.Classes.SingleSelectCell':
                          return <SingleSelectCell variant='portrait' col={cell} row={row} i={rowI} />
                        case 'PMI.FACE.BDDM.Extensions.Classes.LabelCell':
                          return <LabelCell variant='portrait' col={cell} row={row} i={rowI} />
                        case 'PMI.FACE.BDDM.Extensions.Classes.TextDialogCell':
                          return <TextDialogCell variant='portrait' col={cell} row={row} i={rowI} />
                        case 'PMI.FACE.BDDM.Extensions.Classes.PhotosDialogCell':
                          return (
                            <PhotosDialogCell
                              variant='portrait'
                              col={cell}
                              row={row}
                              i={rowI}
                              isReadOnly={isReadOnly}
                            />
                          )
                        case 'PMI.FACE.BDDM.Extensions.Classes.BooleanIndicatorCell':
                          return <BooleanIndicatorCell variant='portrait' col={cell} row={row} i={rowI} />
                        case 'PMI.FACE.BDDM.Extensions.Classes.QuantityInputCell':
                          return <QuantityInputCell variant='portrait' col={cell} row={row} i={rowI} />
                        case 'PMI.FACE.BDDM.Extensions.Classes.IntegerInputCell':
                          return <IntegerInputCell variant='portrait' col={cell} row={row} i={rowI} />
                        // todo restore
                        // case 'TableNumberEditor':
                        //   return (
                        //     <Box mr={1}>
                        //       <NumberEditor col={cell} row={row} i={rowI} />
                        //     </Box>
                        //   )
                        default:
                          return <Typography>Unknown type cell</Typography>
                      }
                    }
                    const alignment = cell.portraitSettings.horizontalAlignment.toLowerCase()
                    return (
                      <Box
                        className={classnames(classes.cell, getCssClassCell(cell))}
                        key={id}
                        gridColumn={inc(cell.portraitSettings.columnNumber)}
                        flexGrow={1}
                        display='flex'
                        alignItems='center'
                        justifyContent={mapAlignmentToFlex(alignment)}
                        width={`${cell.portraitSettings.columnSize}%`}
                      >
                        {renderValue()}
                        {cell.portraitSettings.displayName && (
                          <Typography className={classes.label} component='label' htmlFor={id}>
                            {cell.portraitSettings.displayName}
                          </Typography>
                        )}
                      </Box>
                    )
                  })}

                  {i === 0 && (
                    <Box marginLeft='auto'>
                      <RowMenu
                        item={item}
                        row={row}
                        onDelete={() => {
                          const keyProp = item.recordKeyPropertyName ?? 'checkNumber'
                          const rowKey = getPropertyAny(row, keyProp)
                          const result = entries?.filter((record) => getPropertyAny(record, keyProp) !== rowKey)
                          console.log({ entries, rowKey, keyProp, result })
                          void updateProperty(item.propertyName, result)
                        }}
                      />
                    </Box>
                  )}
                </Box>
              )
            })}
          </Box>
        )
      })}
    </Paper>
  )
}

function inc(value: number | undefined = 0): number {
  return value + 1
}

export function mapAlignmentToFlex(alignment: string): string {
  switch (alignment) {
    case 'center':
      return 'center'
    case 'right':
      return 'flex-end'
    case 'justify':
      return 'stretch'
    case 'left':
    default:
      return 'flex-start'
  }
}

export function groupByIndex<T>(input: T[], fn: (item: T) => number): T[][] {
  const res: T[][] = []

  for (const item of input) {
    const idx = fn(item)
    if (!res[idx]) {
      res[idx] = []
    }
    res[idx].push(item)
  }
  return res
}
