import React, { useCallback, useContext, useEffect, useState } from 'react'

import { Box, createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import isPast from 'date-fns/isPast'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAsync } from 'react-use'

import { getAppFrameStyle } from '../../../assets/pm-shared-styles'
import { ValidationFab } from '../../../components/validation-fab'
import { LogManager } from '../../../infrastructure/logger'
import { PageContent } from '../../../layout/page-content'
import { TitleBar } from '../../../layout/title-bar'
import { VisitOutOfRouteReason } from '../../../model/dictionary-item'
import { ApiContext } from '../../../providers'
import { AppRoutes } from '../../../routes'
import { appToast } from '../../../utils'
import { useIsSmall } from '../../_common/hooks/useIsSmall'
import { getPosAddress } from '../../_common/pos'
import { StoresSearch } from '../../stores/components'
import { IStoreMenuLocationState } from '../../stores/store-menu-page/store-menu-page'
import { getRegisteredNameFromPos } from '../../stores/store-menu-page/utils'
import StoresListItem from '../../stores/stores-list-item/stores-list-item'
import StoresList from '../../stores/stores-list/stores-list'
import { FormSelectResult } from '../../tasks/sales-expert/tabs-new/components/form-select-result'
import { ItemCard } from '../../tasks/template-tasks/composite-screen/item-card'
import { ICreateVisitPageStateFromRoute } from '../models'
import VisitCreateDatePicker from '../visit-create-date-picker/visit-create-date-picker'
import { VisitPageUiContext } from '../visits-page/visits-page-ui-context'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appFrame: { ...getAppFrameStyle(theme) },
  }),
)

const logger = LogManager.getLogger('VisitCreatePage')

const VisitCreatePage: React.FC = () => {
  const { uiStateMethods } = useContext(VisitPageUiContext)
  const api = useContext(ApiContext)
  const classes = useStyles()
  const { state } = useLocation() as { state: ICreateVisitPageStateFromRoute & Partial<IStoreMenuLocationState> }
  const navigate = useNavigate()
  const isSmall = useIsSmall()
  const [selectedDate, setSelectedDate] = useState<number>(
    isPast(state.selectedDate) ? Date.now : state.selectedDate,
  )
  const [selectedPosCode, setSelectedPosCode] = useState<string | null>(null)
  const [selectedAvailableReasons, setSelectedAvailableReasons] = useState<VisitOutOfRouteReason>()

  const [searchQuery, setSearchQuery] = useState<string>('')

  const userProfile = useAsync(async () => await api.userProfile.getCurrentProfile(), [])
  const availableOutOfRouteVisitReasons = userProfile.value?.profile.availableOutOfRouteVisitReasons

  const posList = useAsync(async () => {
    const profile = await api.userProfile.getCurrentProfile()

    if (state.selectedPos) {
      const pos = await api.pos.getPos(state.selectedPos)
      return pos ? [pos] : []
    }

    return await api.pos.searchPos({ positionRoleCoverage: profile?.fieldPositionRole?.code ?? '' })
  })

  useEffect(() => {
    if (posList.value?.length === 1) {
      setSelectedPosCode(posList.value[0].code)
    }
  }, [posList.loading])

  const onBackHandler = (): void => state.prevPath ? navigate(state.prevPath, { state }) : navigate(-1)

  const addButtonIsValid = (): boolean =>
    !!selectedPosCode && (availableOutOfRouteVisitReasons?.length ? !!selectedAvailableReasons : true)

  const { t } = useTranslation('visits')

  const saveHandler = async (): Promise<void> => {
    if (!selectedPosCode) {
      return
    }
    try {
      const createdVisit = await api.visits.createVisit({
        pointOfSaleCode: selectedPosCode,
        date: selectedDate,
        outOfRouteReason: selectedAvailableReasons,
        source: 'OutOfRoutePlan',
      })
      console.log('createdVisit', createdVisit)
      const path = '/'.concat(AppRoutes.visits.path)
      uiStateMethods.setStartPeriodDate(selectedDate)
      uiStateMethods.setEndPeriodDate(selectedDate)
      if (state.selectedPos) { // если есть selectedPos - считаем, что пришли из контекстного меню ТТ
        navigate(state.prevPath ?? path, {state})
      } else {
        navigate(path)
      }

      // appToast.success(t('visitCreated'))
    } catch (e) {
      const message = t('errorAddingVisit')
      logger.error('saveHandler', message, e)
      appToast.error(message)
    }
  }

  const onSearchHandler = useCallback((queryString: string): void => setSearchQuery(queryString), [])

  const renderSearchPosList = (): JSX.Element | null => {
    if (posList.value?.length === 1) {
      return (
        <Box mx={isSmall ? -2 : -3}>
          <StoresListItem
            name={posList.value[0].name}
            address={getPosAddress(posList.value[0])}
            code={posList.value[0].code}
            registerName={getRegisteredNameFromPos(posList.value[0])}
          />
        </Box>
      )
    }

    return (
      <>
        {!selectedPosCode && <StoresSearch query={searchQuery} onChange={onSearchHandler} isContrast={true} />}
        <Box mx={isSmall ? -2 : -3}>
          <StoresList
            searchQuery={searchQuery}
            selectable
            onSelect={(selectedPosCode) => setSelectedPosCode(selectedPosCode)}
            selectedId={selectedPosCode}
            showOnlySelected={true}
          />
        </Box>
      </>
    )
  }

  if (!posList.value || posList.loading || userProfile.loading || !!userProfile.error || !!posList.error) {
    return null
  }

  if (!posList.value.length) {
    return (
      <div className={classes.appFrame}>
        <TitleBar onBack={onBackHandler}>{t('addingVisitTitle')}</TitleBar>
        <PageContent>
          <Box mt={3} p={1}>
            <Typography variant='h6' component='h6' align='center'>
              {t('noPosMessageTitle')}
              <br />
              {t('noPosMessageDesc')}
            </Typography>
          </Box>
        </PageContent>
      </div>
    )
  }

  return (
    <div className={classes.appFrame}>
      <TitleBar onBack={onBackHandler}>{t('addingVisitTitle')}</TitleBar>
      <PageContent>
        <ValidationFab //
          aria-label='add'
          isValid={addButtonIsValid()}
          onClick={(isValid) => {
            if (isValid) {
              void saveHandler()
              return
            }

            if (availableOutOfRouteVisitReasons?.length ? !selectedAvailableReasons : false) {
              appToast.info('Не выбрана причина добавления визита')
              return
            }

            if (!selectedPosCode) {
              appToast.info('Не выбрана торговая точка')
            }
          }}
        >
          {t('confirmAdd')}
        </ValidationFab>
        <Box p={3} pb={0}>
          <VisitCreateDatePicker
            selectedDate={selectedDate}
            onChange={(x) => {
              x && setSelectedDate(x.valueOf())
            }}
          />
        </Box>

        <Box px={3}>
          <ItemCard //
            label={t('selectPos')}
            isError={!selectedPosCode}
          >
            {renderSearchPosList()}
          </ItemCard>
        </Box>
        {!!availableOutOfRouteVisitReasons?.length && (
          <Box px={3} pb={20}>
            <ItemCard //
              label={t('createVisitReason')}
              isError={!selectedAvailableReasons}
            >
              <FormSelectResult
                // id={item.propertyName}
                // onBlur={onBlur}
                disabled={false}
                value={selectedAvailableReasons?.code ?? ''}
                onChange={(ev) => {
                  const targetDictionary = availableOutOfRouteVisitReasons?.find((el) => el.code === ev.target.value)
                  setSelectedAvailableReasons(targetDictionary)
                }}
                variant='outlined'
                placeholder='Выбор причины добавления визита'
                dictionaries={availableOutOfRouteVisitReasons}
                // helperText={t('requiredFieldError')}
                // error={!selectedAvailableReasons}
                showListButtonCaption={t('choose')}
                isNullable={false}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              />
            </ItemCard>
          </Box>
        )}
      </PageContent>
    </div>
  )
}

export default VisitCreatePage
