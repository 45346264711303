import React, { useContext, useEffect } from 'react'

import { makeStyles, createStyles, Box } from '@material-ui/core'
// import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { useTranslation } from 'react-i18next'
import { useLatest } from 'react-use'

import { EmptyMessage } from '../../components'
import { AlignedFab } from '../../components/validation-fab'
import globalEventBus from '../../infrastructure/global-event-bus'
import { EVENT_NEED_SEND_BLOBS } from '../../infrastructure/global-events'
import { Layout } from '../../layout'
import AppBar, { TitleBarText } from '../../layout/app-bar'
import { BackButton } from '../../layout/back-button'
import { PageContent } from '../../layout/page-content'
import { createReferenceToEntity } from '../../model/base'
import { ApiContext, ProfileContext } from '../../providers'
import { useDefaultCodeSpace } from '../../providers/config/useDefaultCodeSpace'
import { appToast, isAxiosError, scanMediaRefsTaskSpecialHandling } from '../../utils'
import { useIsSmall } from '../_common/hooks/useIsSmall'
import { ExecutionProcessControl } from '../tasks/nested/execution-process-control'
import { useScriptTaskContext } from '../tasks/script-tasks/script-task-context'
import { DTETask } from './types'

const useStyles = makeStyles((theme) =>
  createStyles({
    // appFrame: {
    //   ...getAppFrameStyle(theme),
    //   marginTop: theme.spacing(7)
    // },
    appBar: {
      // boxShadow: 'unset',
      // zIndex: theme.zIndex.drawer
      height: 84,

      [theme.breakpoints.down('xs')]: {
        height: 64,
      },
    },
    content: {
      marginTop: 84,
      paddingTop: 1,
      [theme.breakpoints.down('xs')]: {
        marginTop: 64,
      },
      // height: '100%',
    },
    smallBackButton: {
      width: 32,
      height: 32,
      padding: 2,
      marginRight: 8,
      marginLeft: -8,
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    descriptionIcon: {
      color: theme.palette.primary.main,
      lineHeight: '48px',
    },
  }),
)

interface DteTaskDialogProps {
  children?: never
  onBack: () => void
}

export const DteTaskDialog: React.FC<DteTaskDialogProps> = ({ onBack }) => {
  const classes = useStyles()
  const isSmall = useIsSmall()
  const localContext = useScriptTaskContext()
  const contextRef = useLatest(localContext)
  const profileContext = useContext(ProfileContext)
  const task = localContext.task as unknown as DTETask
  const taskTemplate = localContext.template
  const api = useContext(ApiContext)
  const { t } = useTranslation()
  const defaultCodeSpace = useDefaultCodeSpace()

  useEffect(() => {
    console.log('DteTaskDialog mount', task)
  }, [])

  const isAssigned = task.status === 'Assigned'

  function renderAppBarIcons(): JSX.Element {
    return <></>
  }

  async function finishTask(): Promise<void> {
    const context = contextRef.current
    const participant = profileContext.value.participantProfile!
    const res: DTETask = {
      ...context.task,
      participant: { code: participant.code, codeSpace: participant.codeSpace },
      template: createReferenceToEntity(context.template, defaultCodeSpace),
      status: 'Submitted',
    }
    const surveys = Object.values(context.surveys).flat()
    const unit = { task: res, surveys }
    console.log('finishTask resulting dte-task unit', unit)
    try {
      const client = api._httpClientFactory.getHttpClient()
      await client.post('/dte/tasks/v1.0/save-task', unit)
      const refs = scanMediaRefsTaskSpecialHandling(unit)
      globalEventBus.emit(EVENT_NEED_SEND_BLOBS, Array.from(refs))
      await api.tasks.purgeTask(task.code)
      onBack()
    } catch (e: unknown) {
      if (isAxiosError(e)) {
        console.log('axios error', e)
        if (e.response?.data?.title) {
          appToast.error(e.response.data.title)
        } else {
          appToast.error(`Saving task error: ${e.message}`)
        }
      }
    }
  }

  return (
    <Layout
      appBar={
        <AppBar className={classes.appBar}>
          <BackButton className={isSmall ? classes.smallBackButton : undefined} onBack={onBack} />
          <TitleBarText>{taskTemplate?.name}</TitleBarText>
          <Box>{renderAppBarIcons}</Box>
        </AppBar>
      }
    >
      {/* {stageLoading && <StageLoad stage={stageLoading} />} */}
      <div className={classes.content}>
        {isAssigned ? (
          <ExecutionProcessControl
            process={localContext.template.process!}
            canProcessStart={async () => void 0}
            onProcessStart={undefined}
            useFinishConfirmation={true}
            onBeforeFinish={async () => ''}
            onProcessFinish={finishTask}
          />
        ) : (
          <PageContent>
            <Box paddingTop={3}></Box>
            <EmptyMessage message={`Задача ${task.code} имеет статус, отличный от Assigned`} />
            {/* {!taskTemplate.resultScreen && <Box>no result screen</Box>}
            {taskTemplate.resultScreen?.$type === 'PMI.FACE.BDDM.Extensions.Classes.SimpleSurveyTaskResultScreen' && (
              <SimpleSurveyTaskResult
                title={taskTemplate.name}
                questionnaireCode={taskTemplate.resultScreen.questionnaire.code}
              />
            )}
            {taskTemplate.resultScreen?.$type === 'PMI.FACE.BDDM.Extensions.Classes.CompositeTaskResultScreen' && (
              <CompositeResultScreen screen={taskTemplate.resultScreen} />
            )}
            <Box paddingTop={12}></Box> */}
            {/* <EditCompletedTaskFab /> */}
            <AlignedFab onClick={onBack}>{t('backToTasks', { ns: 'task' })}</AlignedFab>
          </PageContent>
        )}
      </div>
    </Layout>
  )
}
