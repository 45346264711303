import React, {useEffect, useMemo} from 'react'

import { Box, IconButton, Menu, MenuItem, Typography } from '@material-ui/core'
import { MoreHoriz } from '@material-ui/icons'

import { checkPredicate } from '../../../../../model/script-predicate'
import { useDefaultCodeSpace } from '../../../../../providers/config/useDefaultCodeSpace'
import { useMenu } from '../../../../admin/_common/use-menu'
import { usePropertyName } from '../../../nested/usePropertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { useGetStringValueBase } from '../get-string-value-base'
import { ItemCard } from '../item-card'
import { AvailablePredicate } from './available-predicate'
import { AstPredicateControl } from './components/ast-predicate-control'
import { ConstPredicateControl, createConstPredicate } from './components/const-predicate-control'
import { createInlinePredicate, InlinePredicateControl } from './components/inline-predicate-control'
import { createScriptPredicate, ScriptPredicateControl } from './components/script-predicate-control'
import { UnknownPredicateControl } from './components/unknown-predicate-control'
import { IPredicateEditScreenItem, IPredicateScreenItem } from './types'
import { checkPredicateItemValue, isAstPredicateValue } from './utils'

interface Props {
  item: IPredicateEditScreenItem
  onChange: (value: IPredicateScreenItem | unknown) => Promise<void>
}

export const PredicateEditScreenItem: React.FC<Props> = ({ item, onChange }) => {
  const value: IPredicateScreenItem | null | undefined = usePropertyName(item.propertyName)

  const defaultAvailablePredicateTypes = [
    'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate',
    'PMI.FACE.BDDM.Extensions.Classes.InlineScriptPredicate',
    'PMI.FACE.BDDM.Extensions.Classes.ScriptPredicate',
    'PMI.FACE.BDDM.Extensions.Classes.ASTPredicateBase',
  ]

  useEffect(() => {
    console.log('PredicateEditScreenItem mount', item, value)
  }, [])

  const isAst = isAstPredicateValue(value)
  const availablePredicateTypes = item.availablePredicateTypes?.length
    ? [...item.availablePredicateTypes]
    : defaultAvailablePredicateTypes

  if (!availablePredicateTypes.includes(value?.$type ?? '')) {
    if (isAst && !availablePredicateTypes.includes('PMI.FACE.BDDM.Extensions.Classes.ASTPredicateBase')) {
      availablePredicateTypes.push('PMI.FACE.BDDM.Extensions.Classes.ASTPredicateBase')
    } else if (value) {
      availablePredicateTypes.push(value?.$type)
    }
  }

  const context = useScriptTaskContext()
  const defaultCodeSpace = useDefaultCodeSpace()
  const BDDMType = useGetStringValueBase(context, undefined, item.bddmType)
  const memoBDDMType = useMemo(() => (BDDMType ? [BDDMType] : []), [BDDMType])

  const menu = useMenu({
    value() {
      void onChange(createConstPredicate(false))
    },
    inlineScript() {
      void onChange(createInlinePredicate(''))
    },
    scriptRef() {
      void onChange(
        createScriptPredicate({
          $type: 'PMI.FACE.BDDM.Extensions.Classes.FaceScriptReference',
          code: '',
          codeSpace: defaultCodeSpace,
          name: '',
        }),
      )
    },
    ast() {
      void onChange(null)
    },
  })

  const required = item.required && checkPredicate(item.required, undefined, context)
  const isError = required && !checkPredicateItemValue(value)

  const renderEditor = (): JSX.Element => {
    switch (value?.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate':
        return <ConstPredicateControl value={value} onChange={onChange} />
      case 'PMI.FACE.BDDM.Extensions.Classes.InlineScriptPredicate':
        return <InlinePredicateControl value={value} onChange={onChange} />
      case 'PMI.FACE.BDDM.Extensions.Classes.ScriptPredicate':
        return <ScriptPredicateControl value={value} onChange={onChange} />
    }

    if (isAstPredicateValue(value)) {
      return <AstPredicateControl memoBDDMType={memoBDDMType} value={value} onChange={onChange} />
    }

    if (value) {
      return <UnknownPredicateControl value={value} onChange={onChange} />
    }

    return <div>Выберите тип предиката в контекстом меню</div>
  }

  const renderMenu = (): JSX.Element => {
    return (
      <>
        <IconButton edge='end' size='small' {...menu.anchor}>
          <MoreHoriz />
        </IconButton>

        <Menu {...menu.menu}>
          <AvailablePredicate
            $type='PMI.FACE.BDDM.Extensions.Classes.ConstPredicate'
            availableList={availablePredicateTypes}
          >
            <MenuItem
              {...menu.action('value')}
              selected={value?.$type === 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate'}
            >
              ConstPredicate
            </MenuItem>
          </AvailablePredicate>
          <AvailablePredicate
            $type='PMI.FACE.BDDM.Extensions.Classes.InlineScriptPredicate'
            availableList={availablePredicateTypes}
          >
            <MenuItem
              {...menu.action('inlineScript')}
              selected={value?.$type === 'PMI.FACE.BDDM.Extensions.Classes.InlineScriptPredicate'}
            >
              InlineScriptPredicate
            </MenuItem>
          </AvailablePredicate>
          <AvailablePredicate
            $type='PMI.FACE.BDDM.Extensions.Classes.ScriptPredicate'
            availableList={availablePredicateTypes}
          >
            <MenuItem
              {...menu.action('scriptRef')}
              selected={value?.$type === 'PMI.FACE.BDDM.Extensions.Classes.ScriptPredicate'}
            >
              ScriptPredicate
            </MenuItem>
          </AvailablePredicate>
          <AvailablePredicate
            $type='PMI.FACE.BDDM.Extensions.Classes.ASTPredicateBase'
            availableList={availablePredicateTypes}
          >
            <MenuItem {...menu.action('ast')} selected={isAstPredicateValue(value)}>
              AstPredicate
            </MenuItem>
          </AvailablePredicate>
        </Menu>
      </>
    )
  }

  return (
    <ItemCard
      isError={isError}
      label={
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='inherit'>{item.displayName}</Typography>
          {renderMenu()}
        </Box>
      }
    >
      {renderEditor()}
    </ItemCard>
  )
}
