import React, { useContext, useEffect, useRef, useState } from 'react'

import { Box, Typography } from '@material-ui/core'
import MonacoEditor from '@monaco-editor/react'
import { get } from 'lodash'
import { z } from 'zod'
import zodToJsonSchema from 'zod-to-json-schema'

import { LogManager } from '../../../../../infrastructure/logger'
import { IBddmTypeReference } from '../../../../../model/bddm-type-reference'
import { IJsonEditorScreenItem } from '../../../../../model/screen-item'
import { checkPredicate } from '../../../../../model/script-predicate'
import { appToast, isNonNullable } from '../../../../../utils'
import { getMonacoEditorOptions } from '../../../../admin/_common/monaco-options'
import { useGetBddmSchema } from '../../../../admin/bddm-types/use-get-bddm-schema'
import { ITableRecordEntity } from '../../../../admin/data-manager/models/models'
import { useLocalContextService } from '../../../nested/local-context'
import { getContextProperty } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { CompositeScreenContext } from '../composite-screen-stage'
import { ItemCard } from '../item-card'

interface IProps {
  item: IJsonEditorScreenItem
}

const logger = LogManager.getLogger('jsonEditorScreenItem')

const JsonEditorScreenItem: React.FC<IProps> = ({ item }) => {
  const processContext = useScriptTaskContext()
  const processContextService = useLocalContextService()
  const { setCustomValidation } = useContext(CompositeScreenContext)
  const record = getContextProperty<ITableRecordEntity>(processContext, processContextService.rootRecordName!)
  const getBddmSchema = useGetBddmSchema()
  const isVisible = item.visible ? checkPredicate(item.visible, {} as Record<string, unknown>, processContext) : true
  const isRequired = item.required
    ? checkPredicate(item.required, {} as Record<string, unknown>, processContext)
    : false

  const [isObjValid, setIsObjValid] = useState(true)
  const [jsonRecord, setJsonRecord] = useState(JSON.stringify(get(record, item.propertyName), null, '\t'))

  const registerDisposable = useDispose()

  const getError = (): boolean => {
    if (jsonRecord) {
      return !isObjValid
    } else {
      return isRequired
    }
  }
  const isError = getError()

  useEffect(() => {
    setCustomValidation(`${item.$type}-${item.propertyName}`, { isError })
  }, [isError])

  if (!isVisible) {
    return null
  }

  return (
    <ItemCard isError={isError} label={<Typography variant='inherit'>{item.displayName}</Typography>} noContentPadding>
      <Box height={item.height <= 0 || !item.height ? 300 : item.height}>
        <MonacoEditor
          language='json'
          value={jsonRecord}
          onChange={(value) => setJsonRecord(value!)}
          onValidate={(value) => (value.length === 0 ? setIsObjValid(true) : setIsObjValid(false))}
          path={item.propertyName}
          options={getMonacoEditorOptions({
            lineNumbers: 'off',
            showFoldingControls: 'always',
            scrollbar: { alwaysConsumeMouseWheel: false },
            scrollBeyondLastLine: false,
          })}
          beforeMount={async (monaco) => {
            if (!item.bddmType) {
              return
            }

            const schema = await buildSchema(item.bddmType).catch((error) => {
              appToast.error('Не удалось получить схемы валидации')
              logger.error('get', error.message, error)
              throw error
            })

            const { typeNames, isArray, isOptional } = item.bddmType

            const schemaId = `face://${typeNames.join(',')};${isArray};${isOptional}`

            const json = monaco.languages.json.jsonDefaults

            // reset options with schemaValidation=error
            json.setDiagnosticsOptions({
              ...json.diagnosticsOptions,
              validate: true,
              schemaValidation: 'error',
              schemas: json.diagnosticsOptions.schemas ?? [],
            })

            // add schema
            json.diagnosticsOptions.schemas!.push({
              uri: schemaId,
              fileMatch: [item.propertyName],
              schema: zodToJsonSchema(schema),
            })

            // remove schema on unmount
            registerDisposable(() => {
              const schemaIndex = json.diagnosticsOptions.schemas!.findIndex((x) => x.uri === schemaId)
              json.diagnosticsOptions.schemas!.splice(schemaIndex, 1)
            })
          }}
        />
      </Box>
    </ItemCard>
  )

  async function buildSchema(bddmType: IBddmTypeReference): Promise<z.Schema> {
    const schemas$ = bddmType.typeNames.map(async (type) => getBddmSchema(type))
    const schemas = (await Promise.all(schemas$)).filter(isNonNullable)

    let result: z.Schema

    if (schemas.length > 1) {
      result = z.union(schemas as [z.Schema, z.Schema, ...z.Schema[]])
    } else {
      result = schemas[0]
    }

    if (bddmType.isArray) {
      result = z.array(result)
    }

    if (bddmType.isOptional) {
      result = z.optional(result)
    }

    return result
  }
}

export default JsonEditorScreenItem

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function useDispose() {
  const disposers = useRef<Array<() => void>>([])

  useEffect(() => {
    return () => {
      for (const dispose of disposers.current) {
        dispose()
      }
      disposers.current = []
    }
  }, [])

  return function register(dispose: () => void) {
    disposers.current.push(dispose)
  }
}
