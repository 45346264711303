import React, { ChangeEvent, useEffect, useRef } from 'react'

import { createStyles, IconButton, InputBase, makeStyles, Paper, Theme } from '@material-ui/core'
import classnames from 'classnames'
import { useTranslation } from "react-i18next";
import { useDebounce } from 'react-use'

import { grays } from '../../../../../layout/theme'

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    panel: {
      display: 'flex',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      zIndex: 10000,
      backgroundColor: 'white',
      boxShadow: '0px 3px 7px rgba(96, 97, 112, 0.13)',
      height: 52,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(1.5),
      transform: 'translateY(-100%)',
      transition: '0.3s'
    },
    panelOpen: {
      transform: 'translateY(0)'
    },
    root: {
      marginRight: 20,
      padding: '3px 20px 3px 20px',
      display: 'flex',
      alignItems: 'center',
      height: 36,
      flexGrow: 1,
      borderRadius: '40px',
      boxShadow: 'none',
      background: '#F2F3F4'
    },
    input: {
      width: '100%',
      paddingLeft: '10px',
      paddingRight: '10px',
      '& > .MuiInputBase-input': {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 500,
        letterSpacing: '0.1px',
        '&::placeholder': {
          color: grays.gray2,
          opacity: 1,
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 500,
        },
      }
    },
    iconSearch: {
      color: grays.gray2
    }
  })
)

interface IVisitSearchMobileProps {
  query: string
  onChange: (queryString: string) => void
  isOpen: boolean
  onClose: () => void
}

export const ProblemMobileSearchItem: React.FC<IVisitSearchMobileProps> = ({ query, onChange, isOpen, onClose }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const inputRed = useRef<HTMLInputElement>()

  const [val, setVal] = React.useState(query)
  const [debouncedValue, setDebouncedValue] = React.useState(query)

  useEffect(() => {
    if (isOpen) {
      inputRed.current?.focus()
    }
  }, [isOpen])

  useDebounce(
    () => {
      setDebouncedValue(val)
    },
    300,
    [val]
  )

  useEffect(() => onChange(debouncedValue), [debouncedValue])

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setVal(e.target.value)
  }

  return (
    <div className={classnames(classes.panel, isOpen ? classes.panelOpen : '')}>
      <Paper className={classes.root}>
        <svg className={classes.iconSearch} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="11" cy="11" r="7" stroke="#858F97" strokeWidth="2" />
          <path d="M20 20L17 17" stroke="#858F97" strokeWidth="2" strokeLinecap="round" />
        </svg>
        <InputBase
          inputRef={inputRed}
          placeholder={t('search')}
          className={classes.input}
          inputProps={{ 'aria-label': 'visits on map search' }}
          value={val}
          onChange={handleChange}
        />
      </Paper>
      <IconButton onClick={() => {
        onClose()
        setVal('')
      }}>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#DADADA" />
        </svg>
      </IconButton>
    </div>
  )
}
