import { UserProfileStore } from '../../data/schema'
import { CodeSpace, createReferenceToEntity, IEntity } from '../../model/base'
import { IBusinessParameters } from '../../model/business-parameters'
import { MenuItemDTO } from '../../model/menu-item'
import { IPositionRoleReference } from '../../model/user'
import { IUserGroup } from '../../model/user-group'
import { IProfile, IUserProfile } from '../../model/user-profile'
import IAuthService from '../auth/auth-service-api'
import globalEventBus from '../global-event-bus'
import { EVENT_FETCHED_BUSINESS_PARAMETERS, EventFetchedBusinessParametersArgType } from '../global-events'
import { IHttpClientFactory } from '../http-client-factory'
import { LogManager } from '../logger'
import { IStorageService } from '../storage-service'
import {
  IUserProfileService,
  UserProfileError,
  UserProfileErrorCode,
  UserProfileErrorSource,
} from './user-profile-service-api'

type UserProfileKey<T> = string & { __tag: T }

interface UserProfileEntry<T> {
  key: UserProfileKey<T>
  value: T
}

const KnownKeys = {
  Profiles: {
    AvailableList: 'Profiles.AvailableList' as UserProfileKey<IUserProfile[]>,
    Current: 'Profiles.Current' as UserProfileKey<IUserProfile>,
  },
  Sync: {
    LastAttemptTimestamp: 'Sync.LastAttempt.Timestamp' as UserProfileKey<Date>,
    LastSuccessTimestamp: 'Sync.LastSuccess.Timestamp' as UserProfileKey<Date>,
  },
  BusinessParameters: 'BusinessParameters' as UserProfileKey<IBusinessParameters>,
  MenuItems: 'MenuItems' as UserProfileKey<MenuItemDTO[]>,
  UserGroups: 'UserGroups' as UserProfileKey<IUserGroup[]>,
}

interface IBusinessParameterDto {
  code: string
  value: string
}

export default class UserProfileService implements IUserProfileService {
  private readonly logger = LogManager.getLogger('UserProfileService')

  private readonly _storage: IStorageService
  private readonly _httpClientFactory: IHttpClientFactory
  private readonly _auth: IAuthService

  constructor(
    storage: IStorageService,
    auth: IAuthService,
    httpClientFactory: IHttpClientFactory,
    private readonly defaultCodeSpace: CodeSpace,
  ) {
    this._storage = storage
    this._auth = auth
    this._httpClientFactory = httpClientFactory
  }

  private async getItem<T>(key: UserProfileKey<T>): Promise<T | null> {
    const entry = await this._storage.getByKey<UserProfileEntry<T>>(UserProfileStore, key)
    return entry?.value ?? null
  }

  private async setItem<T>(key: UserProfileKey<T>, value: T): Promise<void> {
    const entry: UserProfileEntry<T> = { key, value }
    await this._storage.put(UserProfileStore, entry)
  }

  private async deleteItem<T>(key: UserProfileKey<T>): Promise<void> {
    await this._storage.deleteByKey(UserProfileStore, key)
  }

  private static makeUserProfileError(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any,
    source: UserProfileErrorSource,
    defaultMessage: string,
  ): UserProfileError {
    let code = UserProfileErrorCode.ServiceUnavailable
    let message = defaultMessage
    let businessErrorType
    let businessErrorTitle
    let status
    if (error.response?.status > 0) {
      status = error.response.status
      message += ` ${status}`
      if (status === 401) {
        code = UserProfileErrorCode.UserUnauthorised
      } else if (status === 403) {
        code = UserProfileErrorCode.AccessDenied
      } else if (status === 400) {
        if (error.response.data?.type === 'UPGRADE_REQUIRED') {
          code = UserProfileErrorCode.UpgradeRequired
        } else {
          code = UserProfileErrorCode.BusinessError
          if (error.response.data?.type) {
            businessErrorType = error.response.data?.type
          }
          if (error.response.data?.title) {
            businessErrorTitle = error.response.data?.title
          }
        }
      } else {
        code = UserProfileErrorCode.ServiceError
        if (status === 503 && error.response.data?.type === 'MAINTENANCE_IN_PROGRESS') {
          code = UserProfileErrorCode.MaintenanceInProgress
        }
      }
    }
    return new UserProfileError(source, code, message, status, businessErrorType, businessErrorTitle, error)
  }

  async getAllUserProfiles(): Promise<IProfile[]> {
    const client = this._httpClientFactory.getHttpClient()
    const response = await client.get<IProfile[]>('user/all-profiles')
    return response.data
  }

  async fetchAvailableProfiles(): Promise<IUserProfile[]> {
    let profiles: IUserProfile[]

    this.logger.debug('fetchAvailableProfiles', 'Fetching...')

    const client = this._httpClientFactory.getHttpClient()
    try {
      const response = await client.post<IUserProfile[]>('user/v2/get-available-profiles', {
        login: this._auth.currentUserName,
      })
      profiles = response.data
    } catch (e) {
      this.logger.error(
        'fetchAvailableProfiles',
        'Failed requesting available user profiles',
        e,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e as any)?.response?.data,
      )
      throw UserProfileService.makeUserProfileError(
        e,
        UserProfileErrorSource.GetAvailableProfiles,
        'Failed to fetch available user profiles',
      )
    }

    this.logger.debug('fetchAvailableProfiles', 'Fetched', profiles)

    await this.setItem<IUserProfile[]>(KnownKeys.Profiles.AvailableList, profiles)

    return profiles
  }

  // TODO: implement profile caching
  async getCurrentProfile(): Promise<IUserProfile | null> {
    return this.getItem(KnownKeys.Profiles.Current)
  }

  async setCurrentProfile(profile: IUserProfile | null): Promise<void> {
    return profile == null
      ? this.deleteItem(KnownKeys.Profiles.Current)
      : this.setItem(KnownKeys.Profiles.Current, profile)
  }

  async getLastSyncAttempt(): Promise<Date | null> {
    return this.getItem(KnownKeys.Sync.LastAttemptTimestamp)
  }

  async setLastSyncAttempt(timestamp: Date): Promise<void> {
    return this.setItem(KnownKeys.Sync.LastAttemptTimestamp, timestamp)
  }

  async getLastSyncSuccess(): Promise<Date | null> {
    return this.getItem<Date>(KnownKeys.Sync.LastSuccessTimestamp)
  }

  async setLastSyncSuccess(timestamp: Date): Promise<void> {
    return this.setItem(KnownKeys.Sync.LastSuccessTimestamp, timestamp)
  }

  async fetchBusinessParameters(): Promise<void> {
    let businessParams: IBusinessParameterDto[]

    this.logger.debug('fetchBusinessParameters', 'Fetching...')

    const profile = await this.getCurrentProfile()

    const client = this._httpClientFactory.getHttpClient()
    try {
      const response = await client.get<IBusinessParameterDto[]>('business-settings/v1/current-user-settings', {
        params: { profileCode: profile?.profile?.code },
      })
      businessParams = response.data
    } catch (e) {
      this.logger.error('fetchBusinessParameters', 'Failed requesting business settings', e)
      throw UserProfileService.makeUserProfileError(
        e,
        UserProfileErrorSource.GetBusinessSettings,
        'Failed to fetch business settings',
      )
    }

    const businessParameters = UserProfileService.parseBusinessParameters(businessParams)

    this.logger.debug('fetchBusinessParameters', 'Fetched', businessParameters)

    await this.setItem<IBusinessParameters>(KnownKeys.BusinessParameters, businessParameters)

    globalEventBus.emit<EventFetchedBusinessParametersArgType>(EVENT_FETCHED_BUSINESS_PARAMETERS, businessParameters)
  }

  async getBusinessParameters(): Promise<IBusinessParameters> {
    const res = await this.getItem<IBusinessParameters>(KnownKeys.BusinessParameters)
    // getBusinessParameters вызывается только после того, как
    // fetchBusinessParameters успешно сохранил бизнес-параметры в IndexedDB
    return res!
  }

  private static parseBusinessParameters(dtos: IBusinessParameterDto[]): IBusinessParameters {
    const result: { [code: string]: unknown } = {}
    dtos?.forEach((dto) => {
      let value: unknown = dto.value
      switch (dto.code) {
        case 'dataRetentionDays' as keyof IBusinessParameters:
          value = Number(value) as unknown
          break
      }
      result[dto.code] = value
    })
    return result as unknown as IBusinessParameters
  }

  async getCurrentFieldPositionRoleReference(): Promise<IPositionRoleReference | undefined> {
    const profile = await this.getCurrentProfile()
    return profile?.fieldPositionRole
      ? (createReferenceToEntity(
          profile.fieldPositionRole,
          'PMI.BDDM.Staticdata.FieldPositionRoleReference',
        ) as IPositionRoleReference)
      : undefined
  }

  async fetchMenuItems(): Promise<void> {
    const profile = await this.getCurrentProfile()
    if (!profile) return

    const client = this._httpClientFactory.getHttpClient()
    let menu
    try {
      const body = {
        login: this._auth.currentUserName,
        profile: createReferenceToEntity(profile.profile as unknown as IEntity, this.defaultCodeSpace),
      }
      const res = await client.post<MenuItemDTO[]>('/user/v1/get-menu-items', body)
      menu = res.data
    } catch (e) {
      this.logger.error('fetchMenu', 'Failed requesting menu', e)
      throw UserProfileService.makeUserProfileError(e, UserProfileErrorSource.GetMenuItems, 'Failed to fetch menu')
    }

    this.logger.debug('fetchMenu', 'Fetched', menu)
    await this.setItem(KnownKeys.MenuItems, menu)
  }

  async getMenuItems(): Promise<MenuItemDTO[] | null> {
    return await this.getItem(KnownKeys.MenuItems)
  }

  async fetchUserGroups(): Promise<void> {
    const profile = await this.getCurrentProfile()
    if (!profile) return

    const client = this._httpClientFactory.getHttpClient()
    let userGroups
    try {
      const body: Record<string, unknown> = {
        login: this._auth.currentUserName,
        profile: createReferenceToEntity(profile.profile, this.defaultCodeSpace),
        // profile: {code: profile.profile.code},
      }
      if (profile.participantProfile) {
        const pp = profile.participantProfile
        body.profileContext = {
          // participantProfile: createReferenceToEntity(profile.participantProfile),
          participantProfile: {
            code: pp.code,
            codeSpace: pp.codeSpace,
          },
        }
      }
      const res = await client.post<IUserGroup[]>('/user/v1/get-user-groups', body)
      userGroups = res.data
    } catch (e) {
      this.logger.error('fetchUserGroups', 'Failed requesting userGroups', e)
      throw UserProfileService.makeUserProfileError(
        e,
        UserProfileErrorSource.GetUserGroups,
        'Failed to fetch userGroups',
      )
    }

    this.logger.debug('fetchUserGroups', 'Fetched', userGroups)
    await this.setItem(KnownKeys.UserGroups, userGroups)
  }

  getUserGroups = async (): Promise<IUserGroup[] | null> => {
    return await this.getItem(KnownKeys.UserGroups)
  }
}
