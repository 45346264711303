import React from 'react'

import { createStyles, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { grays } from '../../../../layout/theme'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emptyMessage: {
      flex: 1,
      padding: '68px 0',
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 500,
      color: '#C7CDD7',
      height: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignContent: 'center',

      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
        maxWidth: 265,
        margin: '0 auto',
      },
    },
    addTaskIcon: {
      width: 162,
      fill: 'rgba(199, 205, 215, 0.4)',
      [theme.breakpoints.down('xs')]: {
        width: 80,
      },
    },
    text: {
      width: '100%',
      marginTop: 15,
      fontSize: 16,
      color: grays.gray4,
      fontWeight: 500,
      letterSpacing: '0',
    },
  }),
)

export const EmptyActivitiesMessage: React.FC = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.emptyMessage}>
      <svg className={classes.addTaskIcon} enableBackground='new 0 0 24 24' viewBox='0 0 24 24'>
        <rect fill='none' height='24' width='24' />
        <path d='M22,5.18L10.59,16.6l-4.24-4.24l1.41-1.41l2.83,2.83l10-10L22,5.18z M12,20c-4.41,0-8-3.59-8-8s3.59-8,8-8 c1.57,0,3.04,0.46,4.28,1.25l1.45-1.45C16.1,2.67,14.13,2,12,2C6.48,2,2,6.48,2,12s4.48,10,10,10c1.73,0,3.36-0.44,4.78-1.22 l-1.5-1.5C14.28,19.74,13.17,20,12,20z M19,15h-3v2h3v3h2v-3h3v-2h-3v-3h-2V15z' />
      </svg>
      <Typography className={classes.text}>{children}</Typography>
    </div>
  )
}
