/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'

import { makeStyles, Theme, createStyles, Button } from '@material-ui/core'
import classNames, { Argument as ClassName } from 'classnames'
import { useToggle } from 'react-use'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        position: 'relative',
        width: '100%',
        flexGrow: 1,
      },
      rootWithGradient: {
        background: 'linear-gradient(180deg, #E7E7E7 0%, rgba(243, 243, 243, 0) 67px)',
      },
      gradient: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        height: 90,
        zIndex: -1,
        background: 'linear-gradient(180deg, #E7E7E7 0%, rgba(243, 243, 243, 0) 74.21%)',
      },
      gradientTestButton: {
        position: 'absolute',
        right: 15,
        top: 15,
      },
      noOverflow: {
        overflow: 'hidden',
      },
    }),
  { name: 'PageContent' },
)

interface Props {
  className?: ClassName
  noOverflow?: boolean
}

export const PageContent: React.FC<Props> = ({ className, children, noOverflow }) => {
  const classes = useStyles()
  // const [state, toggle] = useToggle(false)
  return (
    <div
      className={classNames(
        classes.root,
        {
          [classes.rootWithGradient]: true,
          [classes.noOverflow]: noOverflow,
        },
        className,
      )}
    >
      {/* {!state && <div className={classes.gradient}></div>} */}
      {/* <Button className={classes.gradientTestButton} onClick={toggle}>
        Root gradient {String(state)}
      </Button> */}
      {children}
    </div>
  )
}
