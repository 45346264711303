import React, { useCallback, useContext, useState } from 'react'

import { Box, createStyles, IconButton, makeStyles, SvgIcon, Theme } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { isToday } from 'date-fns'
import { noop } from 'lodash'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'

import { ReactComponent as PreviewIcon } from '../../../assets/icons/photo-preview.svg'
import { ShareButton } from '../../../components/share-button'
import { TStageLoad, StageLoad } from '../../../components/stage-load'
import { LogManager } from '../../../infrastructure/logger'
import { AppBar, Layout } from '../../../layout'
import { TitleBarText } from '../../../layout/app-bar'
import { BackButton } from '../../../layout/back-button'
import { grays } from '../../../layout/theme'
import { ApiContext } from '../../../providers'
import { useFetchPendingItems } from '../../../providers/menu-data/pending-items-store'
import { ModalContext } from '../../../providers/modal'
import { appToast } from '../../../utils'
import { getGeolocation } from '../../../utils/get-geolocation'
import { useGenerateReport } from '../../_common/hooks/useGenerateReport'
import { useIsSmall } from '../../_common/hooks/useIsSmall'
import { useSaveVisitCoordinates } from '../../_common/hooks/useSaveVisitCoordinates'
import { useShareReport } from '../../_common/hooks/useShareReport'
import { useStartVisit } from '../../_common/hooks/useStartVisit'
// import { useFinishTask } from '../../execute-survey-control/useFinishTask'
// import { generatePdfNew } from '../../report-page/_utils/generate-pdf-new'
// import { getReportDataNew } from '../../report-page/_utils/useReportDataNew'
import { useFeatureRoute } from '../../custom-app-feature/app-feature-context'
import { contextQueryClient } from '../../process/process-context'
import { VisitModal } from '../../visits/components'
import { visitsRoutes } from '../../visits/visits-app-feature/visits-app-feature'
import { TaskDescriptionDrawerMenu } from '../components'
import { useLocalContextService } from '../nested/local-context'
import { TaskProcessControl } from '../nested/task-process'
import { useScriptTaskContext } from '../script-tasks/script-task-context'
// import { ProceedTaskControl } from './proceed-task-control'
// import { EditCompletedTaskFab } from './edit-completed-task-fab'
import { TaskResultScreen } from './task-result-screen'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // appFrame: {
    //   ...getAppFrameStyle(theme),
    //   marginTop: theme.spacing(7)
    // },
    appBar: {
      // boxShadow: 'unset',
      // zIndex: theme.zIndex.drawer
      height: 84,

      [theme.breakpoints.down('xs')]: {
        height: 64,
      },
    },
    content: {
      marginTop: 84,
      paddingTop: 1,
      [theme.breakpoints.down('xs')]: {
        marginTop: 64,
      },
      // height: '100%',
    },
    smallBackButton: {
      width: 32,
      height: 32,
      padding: 2,
      marginRight: 8,
      marginLeft: -8,
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    descriptionIcon: {
      color: theme.palette.primary.main,
      lineHeight: '48px',
    },
  }),
)

interface IProps {
  onBack: () => void
}

const logger = LogManager.getLogger('TaskDialog')

export const TaskDialog: React.FC<IProps> = ({ onBack }) => {
  const api = useContext(ApiContext)

  const [stageLoading, setStageLoading] = useState<TStageLoad | null>(null)

  const modalContext = useContext(ModalContext)
  const classes = useStyles()
  const propertiesContext = useScriptTaskContext()
  const contextService = useLocalContextService()
  const { task, template: taskTemplate } = propertiesContext
  const visit = propertiesContext.visit!
  const taskCode = task.code
  const visitCode = visit.code

  const isSmall = useIsSmall()
  const { t } = useTranslation(['sales-expert-tasks', 'task'])
  const featureRoute = useFeatureRoute()

  const onShareHandler = useShareReport(task, contextService.refetch)
  const onGenerateReport = useGenerateReport(task)
  // const { finishVisitRequest } = useFinishTask(taskCode)

  const saveVisitCoordinates = useSaveVisitCoordinates()
  const { startVisit } = useStartVisit()
  const fetchPendingItems = useFetchPendingItems()

  const onVisitStartHandler = useCallback(async (): Promise<boolean> => {
    const started = await startVisit(visitCode)
    if (!started) return false

    let geolocation: GeolocationPosition | undefined
    setStageLoading('Geolocation')
    try {
      geolocation = await getGeolocation()
      console.log('Current coordinates: ', geolocation)
    } catch (error) {
      appToast.error(`${error.message}`)
      logger.error('onVisitStartHandler', 'error get geolocation', error, { visitCode })
      LogManager.flush()
    }

    setStageLoading('Visit')
    if (geolocation) await saveVisitCoordinates(visitCode, geolocation)
    await contextQueryClient.invalidateQueries(['visits', visitCode])
    await contextService.refetch()
    // После начала таски уже есть запуск фоновой синхры
    // await tryUploadPendingVisit()
    fetchPendingItems()
    // appToast.success(t('visitStartSuccess'))

    return true
  }, [api.visits, visitCode, saveVisitCoordinates])

  const onBeforeStartTask = (): void => {
    setStageLoading('Task')
  }
  const onAfterStartTask = (): void => {
    setStageLoading(null)
  }

  const checkIsReadyStart = async (): Promise<string | undefined> => {
    return new Promise<string | undefined>((resolve, reject) => {
      const visitStartDateIsToday = isToday(visit.plannedStartDate)
      if (visit.status === 'InProgress' && visitStartDateIsToday && task.status === 'Planned') {
        modalContext.open(
          <VisitModal
            open
            onOk={() => {
              resolve(undefined)
              modalContext.close()
            }}
            onClose={modalContext.close}
            title={t('taskStartModalTitle')}
            message={t('taskStartModalMessage')}
          />,
        )
      }

      if (visit.status === 'Planned' && visitStartDateIsToday && task.status === 'Planned') {
        modalContext.open(
          <VisitModal
            open
            onOk={async () => {
              onVisitStartHandler().then((visitStarted) => {
                if (visitStarted) return resolve(undefined)
                else resolve('Невозможно начать задачу если не начат визит')
              }, reject)
              modalContext.close()
            }}
            onClose={() => {
              resolve('Невозможно начать задачу если не начат визит')
              modalContext.close()
            }}
            title={t('taskStartModalTitle')}
            message={t('taskAndVisitStartMessage')}
          />,
        )
      }

      if (
        (visit.status === 'Planned' || visit.status === 'InProgress') &&
        task.status === 'Planned' &&
        !visitStartDateIsToday
      ) {
        resolve('Невозможно начать задачу, назначенную не на сегодня')
        modalContext.open(
          <VisitModal
            open
            showOkButton={false}
            onOk={noop}
            onClose={modalContext.close}
            title={t('overallWarningModalTitle')}
            message={t('overallWarningWrongDay')}
          />,
        )
      }

      // if (readOnly) {
      //   setWarningMessage('Визит уже завершен')
      // }
    })
  }

  const onAfterFinishTask = async (): Promise<void> => {
    console.log('success')
    // FACE-2015 Remove finished visit - when finish task
    // const tasks = await api.tasks.searchVisitTasks({ visitCode })
    // const tasksRegisters = await getAvailableVisitTaskRegister(api, profile.value!.profile, visitCode)
    // const listTasks = [...tasks, ...tasksRegisters]
    //
    // const canFinishViaTasks =
    //   listTasks.every((x) => x.status !== 'Planned' && x.status !== 'InProgress') &&
    //   !!listTasks.find((x) => x.status === 'Finished')
    //
    // if (canFinishViaTasks) {
    //   console.log('success')
    //   await finishVisitRequest(visitCode)
    // }
  }

  const readOnly = task.status === 'Canceled' || task.status === 'Finished'

  const renderAppBarIcons = (): JSX.Element => {
    return (
      <Box display='flex' flex={1} justifyContent='flex-end' ml={1} mr={2}>
        <span className={classes.descriptionIcon}>
          <TaskDescriptionDrawerMenu
            posCode={visit.pointOfSaleCode}
            taskTemplate={taskTemplate}
            instruction={taskTemplate.helpFileLink?.target}
            description={taskTemplate.description}
            title={t('taskDescriptionDrawerTitle')}
            buttonText={<InfoOutlinedIcon style={{ fontSize: 30 }} />}
          />
        </span>
        <Box //
          display={!readOnly || !taskTemplate.resultFile ? 'none' : 'flex'}
          mr={1}
          hidden={!readOnly || !taskTemplate.resultFile}
          color={grays.gray1}
        >
          <IconButton
            color='inherit'
            component={Link}
            to={generatePath(visitsRoutes.visitTaskPreview, { featureRoute, visitCode, taskCode })}
          >
            <SvgIcon component={PreviewIcon} />
          </IconButton>
          <ShareButton onShare={onShareHandler} />
        </Box>
      </Box>
    )
  }

  return (
    <Layout
      appBar={
        <AppBar className={classes.appBar}>
          <BackButton className={isSmall ? classes.smallBackButton : undefined} onBack={onBack} />
          <TitleBarText>{taskTemplate?.name}</TitleBarText>
          <Box>{renderAppBarIcons}</Box>
        </AppBar>
      }
    >
      {stageLoading && <StageLoad stage={stageLoading} />}
      <div className={classes.content}>
        {!readOnly ? (
          <TaskProcessControl
            canTaskStart={checkIsReadyStart}
            onBeforeFinish={onGenerateReport}
            onTaskFinish={onAfterFinishTask}
            onBeforeStartTask={onBeforeStartTask}
            onAfterStartTask={onAfterStartTask}
          />
        ) : (
          <TaskResultScreen onBack={onBack} />
        )}
      </div>
    </Layout>
  )
}

export default TaskDialog
