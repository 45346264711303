import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useList } from 'react-use'

import { IModalContext, ModalContext } from './modal-context'

export const ModalProvider: React.FC = ({ children }) => {
  const [list, { push, filter }] = useList<React.ReactElement>()
  const [open, setOpen] = useState(true)
  const listRef = useRef(list)
  useEffect(() => {
    listRef.current = list
  }, [list])

  const close = useCallback(() => {
    setOpen(false)
    const list = listRef.current
    const modalToRemove = list[list.length - 1]
    setTimeout(() => {
      // console.log('removing modal', modalToRemove, list)
      filter((modal) => modal !== modalToRemove)
      setOpen(true)
    }, 100)
  }, [])

  const currentModal = list[list.length - 1]
  const modalContext: IModalContext = {
    open: push,
    close
  }
  // console.log('currentModal', currentModal)

  return (
    <ModalContext.Provider value={modalContext}>
      {children}
      {currentModal && React.cloneElement(currentModal, { open })}
    </ModalContext.Provider>
  )
}
