import { InjectionToken } from '../infrastructure/di'
import { EntityKey, Code } from '../model/base'
import { IBpmnProcessOriginalData } from '../model/bpmn-process-original-data'
import { IFaceScript } from '../model/face-script'
import { ITaskExecutionProcess } from '../model/task-execution-process'
import { IUserStepScreen } from '../model/user-step-screen'

export interface IExecutionProcessService {
  getExecutionProcessUnit: (processKey: EntityKey) => Promise<ExecutionProcessUnit>
  getBpmnProcessOriginalData: (processKey: EntityKey) => Promise<IBpmnProcessOriginalData>
}

export const EXECUTION_PROCESS_API = new InjectionToken<IExecutionProcessService>('execution-process-service')

export interface ExecutionProcessUnit {
  process: ITaskExecutionProcess
  screens: Record<Code, IUserStepScreen>
  scripts: Record<Code, IFaceScript>
}
