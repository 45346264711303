import React, { useContext, useEffect, useState } from 'react'

import { Box, CircularProgress, Fab, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { getFloatActionButtonStyle } from '../../../../assets/pm-shared-styles'
import { LogManager } from '../../../../infrastructure/logger'
import { IProblem } from '../../../../model/problem'
import { ApiContext, ProfileContext } from '../../../../providers'
import { EmptyProblemMessage } from '../../../visits/visit-tasks/empty-problem-message'
import ProblemFilterScreenItem from './items/problem-filter-screen-item'
import ProblemListScreenItem from './items/problem-list-screen-item'
import ProblemsListVirtual from './problems-list-virtual'
import { getProblems60DaysPeriod } from './utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      paddingTop: 37,
      [theme.breakpoints.down('xs')]: {
        paddingTop: 39,
      },
    },
    listWrapper: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      height: 'calc(100vh - 208px)',
    },
    filterDate: {
      flex: '1 1 322px',
      [theme.breakpoints.down('xs')]: {
        flex: '1 1 auto',
      },
    },
    captionOfEmptyResult: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'rgba(0, 0, 0, 0.38)',
      whiteSpace: 'nowrap',
    },
    progress: {
      margin: '0 auto',
    },
    buttonWrap: {
      ...getFloatActionButtonStyle(theme),
      textTransform: 'none',
      [theme.breakpoints.down('xs')]: {
        right: '50%',
        transform: 'translateX(50%)',
        margin: '0 auto',
      },
    },
    addButton: {
      paddingRight: 16,
      textTransform: 'none',
    },
    addIcon: {
      marginRight: theme.spacing(1),
    },
  }),
)

interface IAddButtonProps {
  posCode: string
}

interface LocationState {
  scrollOffset?: number
}

interface IPropsListPage {
  searchQuery?: string
}
interface IPropsList {
  posCode?: string
  searchQuery?: string
}

const AddButton: React.FC<IAddButtonProps> = ({ posCode }) => {
  const classes = useStyles()
  const { t } = useTranslation('problems')
  return (
    <Box className={classes.buttonWrap}>
      <Link to={`/stores/${posCode}/addProblem`}>
        <Fab color='primary' aria-label='add' variant='extended' className={classes.addButton}>
          <AddIcon className={classes.addIcon} />
          {t('problem')}
        </Fab>
      </Link>
    </Box>
  )
}

export const ProblemsListPage: React.FC<IPropsListPage> = ({ searchQuery }) => {
  const classes = useStyles()
  const { posCode } = useParams() as unknown as { posCode: string }
  return (
    <Box className={classes.wrapper}>
      <ProblemsPosList posCode={posCode} searchQuery={searchQuery} />
      <AddButton posCode={posCode} />
    </Box>
  )
}

const logger = LogManager.getLogger('fetchProblemsList')

export const ProblemsPosList: React.FC<IPropsList> = ({ posCode, searchQuery }) => {
  const { t } = useTranslation('problems')
  const classes = useStyles()
  const { pathname } = useLocation() as { state: LocationState; pathname: string }
  const api = useContext(ApiContext)
  const currentUser = useContext(ProfileContext)
  const employee = currentUser.value?.employee
  const currentUserFio = `${employee?.contact.surname} ${employee?.contact.name}`
  let filtered = [] as IProblem[]

  const [posProblems, setPosProblems] = useState<IProblem[]>()
  const [filteredProblems, setFilteredProblems] = useState<IProblem[]>()
  const [invokeFilter, setInvokeFilter] = useState(false)

  const handleSearch = (input: string): void => {
    filtered = filtered!.filter(
      (problem) =>
        problem?.escalationReason.name.toLocaleLowerCase().includes(input.toLocaleLowerCase()) ||
        problem?.executiveComment?.toLocaleLowerCase().includes(input.toLocaleLowerCase()) ||
        problem?.problemDetails?.toLocaleLowerCase().includes(input.toLocaleLowerCase()),
    )
  }

  const handleSortStatus = (value: string): void => {
    switch (value) {
      case 'all':
        break
      case 'assignedToMe':
        filtered = filtered!.filter(
          (problem) =>
            problem.status === 'New' &&
            currentUser.value?.fieldPositionRole?.code === problem.assignedToPositionRole?.code,
        )
        break
      case 'appointedByMe':
        filtered = filtered!.filter(
          (problem) =>
            currentUserFio === problem.raisedBy?.name &&
            currentUser.value?.fieldPositionRole?.code === problem.raisedByPositionRole?.code,
        )
        break
      case 'new':
        filtered = filtered!.filter(
          (problem) =>
            problem?.status === 'New' &&
            currentUser.value?.fieldPositionRole?.code !== problem.assignedToPositionRole?.code,
        )
        break
      case 'resolve':
        filtered = filtered!.filter((problem) => problem?.status === 'Resolved')
        break
      case 'irrelevant':
        filtered = filtered!.filter((problem) => problem?.status === 'Canceled')
        break
    }
  }

  const handleSortDate = (value: string): void => {
    switch (value) {
      case 'ascending':
        filtered.sort((a, b) => a.creationTime! - b.creationTime!)
        break
      case 'descending':
        filtered.sort((a, b) => b.creationTime! - a.creationTime!)
        break
      case '':
        filtered.sort((a, b) => b.creationTime! - a.creationTime!)
        break
    }
  }

  useEffect(() => {
    if (posProblems) {
      filtered = [...posProblems]
      handleSearch(sessionStorage.getItem(`${pathname}-searchInput`) ?? '')
      handleSortStatus(sessionStorage.getItem(`${pathname}-sortStatus`) ?? '')
      handleSortDate(sessionStorage.getItem(`${pathname}-sortDate`) ?? '')
      setFilteredProblems(filtered)
    }
  }, [posProblems, invokeFilter, searchQuery])

  useAsync(async () => {
    try {
      const problemsOfPos = await api.problem.searchProblems({ posCode })
      const problemsFor60Days = getProblems60DaysPeriod(problemsOfPos)
      problemsFor60Days.sort((a, b) => b.creationTime! - a.creationTime!)
      setPosProblems(problemsFor60Days)
    } catch (error) {
      const message = 'Ошибка при получении проблем ТТ'
      logger.error('get', message, error)
      throw new Error(message)
    }
  }, [posCode])

  if (!posCode) {
    return <></>
  }

  if (posProblems?.length === 0) {
    return pathname.includes('menu/problems') ? (
      <Box className={classes.listWrapper}>
        <Typography variant='h6' className={classes.captionOfEmptyResult}>
          Cписок проблем пуст
        </Typography>
        <AddButton posCode={posCode} />
      </Box>
    ) : (
      <EmptyProblemMessage />
    )
  }

  return (
    <Box className={classes.listWrapper}>
      <ProblemFilterScreenItem triggerFilter={setInvokeFilter} />
      {filteredProblems?.length === 0 ? (
        <Typography variant='h6' className={classes.captionOfEmptyResult}>
          {t('searchResultEmpty')}
        </Typography>
      ) : !posProblems ? (
        <Box className={classes.progress}>
          <CircularProgress />
        </Box>
      ) : (
        <ProblemsListVirtual ListItem={ProblemListScreenItem} items={filteredProblems!} />
      )}
    </Box>
  )
}
