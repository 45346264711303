import React, { useContext, useEffect } from 'react'

import { IAppFeature } from '../../model/app-feature'

const AppFeatureContext = React.createContext({} as IAppFeature)

interface AppFeatureContextProps {
  // children?: never
  feature: IAppFeature
}

export const AppFeatureContextProvider: React.FC<AppFeatureContextProps> = (props) => {
  useEffect(() => {
    console.log('AppFeatureContextProvider mount', props)
  }, [])

  return <AppFeatureContext.Provider value={props.feature}>{props.children}</AppFeatureContext.Provider>
}

export function useFeatureRoute(): string {
  return useContext(AppFeatureContext).routePath
}
