import React from 'react'

interface Props {
  $type: string
  availableList?: string[]
}

export const AvailablePredicate: React.FC<Props> = ({ $type, availableList, children }) => {
  if (!availableList?.length || availableList.includes($type)) {
    return <>{children}</>
  }

  return <></>
}
