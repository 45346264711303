import React from 'react'

import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { useTranslation } from "react-i18next";

import { SMReportViewMode } from '../models/sm-report.model'

interface ISMReportSwitcherProps {
  currentViewMode: SMReportViewMode
  onChangeViewMode: (newValue: SMReportViewMode) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 32,
      '& .Mui-selected': {
        background: theme.palette.action.selected.concat('!important'),
        color: theme.palette.primary.main
      },
      '& > button': {
        textTransform: 'unset',
        color: theme.palette.text.primary,
        fontWeight: 'normal',
        fontSize: 14
      },
      '& > button:hover': {
        background: 'unset',
        border: `1px solid ${theme.palette.primary.main}`
      }
    }
  })
)

const SMPeriodSwitcher: React.FC<ISMReportSwitcherProps> = ({ currentViewMode, onChangeViewMode }) => {
  const classes = useStyles()
  const { t } = useTranslation('reports')

  const handleChangeViewMode = (event: React.MouseEvent<HTMLElement>, newValue: SMReportViewMode | null): void => {
    if (!newValue) {
      return
    }
    onChangeViewMode(newValue)
  }

  return (
    <ToggleButtonGroup
      className={classes.root}
      size='small'
      value={currentViewMode}
      exclusive
      onChange={handleChangeViewMode}
      aria-label='sm period switcher'
    >
      <ToggleButton value='last-week' aria-label='last-week'>
        {t('lastWeekFilter')}
      </ToggleButton>
      <ToggleButton value='last-month' aria-label='last-month'>
        {t('lastMonthFilter')}
      </ToggleButton>
      <ToggleButton value='all' aria-label='all'>
        {t('allFilter')}
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export default SMPeriodSwitcher
