import React, { useState } from 'react'

import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import { CancelOutlined, CheckBoxOutlineBlankOutlined } from '@material-ui/icons'
import DeleteIcon from '@material-ui/icons/Delete'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import { BlobStorageError } from '../../../../infrastructure/blob-storage/blob-storage-api'
import { LoadErrorIcon } from '../../load-error-icon'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      minHeight: 26,
      display: 'flex',
    },
    selectedControls: {
      marginLeft: 'auto',
      // marginRight: 24,
      color: theme.palette.primary.main,
      [theme.breakpoints.down('xs')]: {
        // marginRight: 12,
      },
    },
    selectButtons: {
      fontWeight: 500,
      padding: 0,
      marginLeft: 15,
      [theme.breakpoints.down('xs')]: {
        marginLeft: 5,
        minWidth: 50,
      },
    },
    iconSelectAll: {
      color: '#8A949D',
      marginRight: 8,
      [theme.breakpoints.down('xs')]: {
        marginRight: 5,
      },
    },
    buttonIcon: {
      width: 22,
      marginRight: 8,
      [theme.breakpoints.down('xs')]: {
        marginRight: 5,
      },
    },
    buttonIconDelete: {
      width: 18,
    },
    buttonText: {
      fontSize: 16,
      lineHeight: '26px',
      textTransform: 'none',
      [theme.breakpoints.down('xs')]: {
        // display: 'none',
      },
    },
  }),
)

interface IDocumentsScreenItemLabel {
  title?: string
  isReadOnly: boolean
  isSelectMode?: boolean
  onSelectAll?: () => void
  onCancel?: () => void
  onDeleteSelected?: () => void
  downloadImageError?: BlobStorageError
}

export const DocumentsScreenItemLabel: React.FC<IDocumentsScreenItemLabel> = ({
  title,
  isReadOnly,
  isSelectMode,
  onCancel,
  onSelectAll,
  onDeleteSelected,
  downloadImageError,
  children,
}) => {
  const classes = useStyles()
  const { t } = useTranslation('photo')
  const [isConfirmation, setIsConfirmation] = useState<boolean>(false)

  return (
    <div className={classes.wrapper}>
      {downloadImageError && <LoadErrorIcon loadImageError={downloadImageError} />}
      {title && (
        <Typography variant='inherit' noWrap>
          {title}
        </Typography>
      )}
      <Box display='flex' className={classes.selectedControls}>
        {!isReadOnly &&
          (!isSelectMode ? (
            <Button color='inherit' className={classes.selectButtons} onClick={onSelectAll}>
              <CheckBoxOutlineBlankOutlined className={classes.iconSelectAll} />
              <span className={classes.buttonText}>{t('selectAll')}</span>
            </Button>
          ) : (
            <>
              <Button color='inherit' className={classes.selectButtons} onClick={onCancel}>
                <CancelOutlined className={classes.buttonIcon} />
                <span className={classes.buttonText}>{t('cancelSelect')}</span>
              </Button>
              <Button
                color='inherit'
                className={classes.selectButtons}
                onClick={() => {
                  setIsConfirmation(true)
                }}
              >
                <DeleteIcon className={classnames(classes.buttonIconDelete, classes.buttonIcon)} />
                <span className={classes.buttonText}>{t('deleteSelect')}</span>
              </Button>
            </>
          ))}
        {children && <Box marginLeft={1}>{children}</Box>}
      </Box>
      <Dialog
        open={isConfirmation}
        disableScrollLock
        onClose={() => {
          setIsConfirmation(false)
        }}
      >
        <DialogTitle id='confirmation'>{t('confirmationModalTitle')}</DialogTitle>
        <DialogContent>{t('confirmationModalDeleteAll')}</DialogContent>
        <DialogActions>
          <Button
            color='primary'
            onClick={() => {
              setIsConfirmation(false)
            }}
          >
            {t('closeModal')}
          </Button>
          <Button
            color='primary'
            onClick={() => {
              setIsConfirmation(false)
              onDeleteSelected?.()
            }}
          >
            {t('deleteFromModal')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
