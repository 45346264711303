import React, { useEffect, useLayoutEffect, useState } from 'react'

import { Card, CardActionArea, CardMedia, createStyles, Icon, makeStyles, Theme, Typography } from '@material-ui/core'
import { CheckRounded, NotInterested, PictureAsPdf } from '@material-ui/icons'
import { useAsyncFn, useLongPress } from 'react-use'

import {
  BlobMetadata,
  BlobStorageError,
  BlobStorageErrorCode,
} from '../../../../infrastructure/blob-storage/blob-storage-api'
import { LogManager } from '../../../../infrastructure/logger'
import { grays } from '../../../../layout/theme'
import { IMediaStorageSettings } from '../../../../model/screen-item'
import { useDocumentMethods } from '../../hooks/use-document-methods'
import { ITempFileItem } from '../../utils'
import { getFileNameByRoute } from './utils'

const DELAY_LONG_PRESS = 1000

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      userSelect: 'none',
      position: 'relative',
      width: 'calc(16% - 16px)',
      paddingBottom: 'calc(16% - 16px)',
      margin: theme.spacing(1),
      borderRadius: '12px !important',
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.14)',
      [theme.breakpoints.down('sm')]: {
        width: 'calc(25% - 16px)',
        paddingBottom: 'calc(25% - 16px)',
      },
      [theme.breakpoints.down('xs')]: {
        margin: 5,
        width: 'calc(33.33% - 10px)',
        paddingBottom: 'calc(33.33% - 10px)',
      },
    },
    mediaWrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      userSelect: 'none',
      '-webkit-touch-callout': 'none',
    },
    imageWrapper: {
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
      userSelect: 'none',
      '-webkit-touch-callout': 'none',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
      pointerEvents: 'none',
    },
    controls: {
      position: 'absolute',
      zIndex: 99,
      left: 0,
      bottom: 0,
      width: '100%',
      height: 40,
      display: 'flex',
      justifyContent: 'center',
      background: 'rgba(0, 0, 0, 0.38)',
    },
    deleteIcon: {
      color: 'white',
    },
    iconChecked: {
      pointerEvents: 'none',
      position: 'absolute',
      zIndex: 1,
      top: 8,
      right: 8,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%',
      [theme.breakpoints.down('xs')]: {
        width: 20,
        height: 20,
        '& svg': {
          display: 'block',
          width: 20,
          height: 20,
        },
      },
    },
    iconUnchecked: {
      pointerEvents: 'none',
      position: 'absolute',
      zIndex: 1,
      top: 8,
      right: 8,
      backgroundColor: 'rgba(0, 0, 0, 0.38)',
      border: '1px solid #ffffff',
      borderRadius: '50%',
      [theme.breakpoints.down('xs')]: {
        width: 20,
        height: 20,
      },
    },
    wrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignContent: 'center',
      padding: 5,
    },
    icon: {
      transform: 'scale(1.1)',
      '& path': {
        fill: grays.gray4,
      },
      [theme.breakpoints.down('xs')]: {
        transform: 'scale(1)',
      },
    },
    text: {
      margin: '10px 0',
      width: '100%',
      fontWeight: 500,
      textAlign: 'center',
      color: grays.gray3,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  }),
)

interface IDocumentThumbnailItemProps {
  item: ITempFileItem
  isChecked: boolean
  isSelectMode: boolean
  onClickItem: (evt: React.SyntheticEvent) => void
  onLongPress?: (evt: MouseEvent | TouchEvent) => void
  onDownloadError: React.Dispatch<React.SetStateAction<BlobStorageError | undefined>>
  storage?: IMediaStorageSettings
}

export const TIMEOUT_ON_LOAD_BLOB = 60_000
const logger = LogManager.getLogger('DocumentThumbnailItem')

export const DocumentThumbnailItem: React.FC<IDocumentThumbnailItemProps> = ({
  isChecked,
  isSelectMode,
  item,
  onClickItem,
  onLongPress,
  onDownloadError,
  storage,
}) => {
  const classes = useStyles()
  const { getMetadataOrBlob } = useDocumentMethods(storage)

  const longPressEvent = useLongPress(
    (evt) => {
      if (onLongPress) {
        return onLongPress(evt)
      }
    },
    { isPreventDefault: false, delay: DELAY_LONG_PRESS },
  )

  const removeNonUserSelect = (): void => {
    document.body.classList.remove('nonUserSelect')
  }

  const addNonUserSelect = (): void => {
    document.body.classList.add('nonUserSelect')
  }

  const [image, setImage] = useState(item.metadata.thumbnail)

  const [imageOps, forceLoadImage] = useAsyncFn(async () => {
    return new Promise<BlobMetadata | Blob | undefined>((resolve, reject) => {
      void getMetadataOrBlob(item.metadata.key).then(resolve, reject)

      setTimeout(() => {
        reject(
          new BlobStorageError(
            BlobStorageErrorCode.Unknown,
            `Not responding on timeout: ${TIMEOUT_ON_LOAD_BLOB} ms. Metadata for '${item.metadata.key}'`,
          ),
        )
      }, TIMEOUT_ON_LOAD_BLOB)
    })
  }, [])

  const imageFromServer = imageOps.value
  useEffect(() => {
    if (imageFromServer instanceof Blob) {
      const url = URL.createObjectURL(imageFromServer)
      setImage(url)
      return () => URL.revokeObjectURL(url)
    }

    if (imageFromServer) {
      setImage(imageFromServer.thumbnail)
    }
  }, [imageFromServer])

  const downloadError = imageOps.error as BlobStorageError | undefined
  useEffect(() => {
    if (downloadError) {
      logger.error('getMetadata', downloadError.message, downloadError)
      onDownloadError((currentError) => {
        if (currentError && downloadError?.code === BlobStorageErrorCode.NotFound) {
          return currentError
        }

        return downloadError
      })
    }
  }, [downloadError])

  useLayoutEffect(() => {
    if (image) return

    if (item.blob) {
      const url = URL.createObjectURL(item.blob)
      setImage(url)

      return () => URL.revokeObjectURL(url)
    } else {
      void forceLoadImage()
    }
  }, [item.blob])

  const renderCheckedMode = (): React.ReactElement => {
    return (
      <Icon className={isChecked ? classes.iconChecked : classes.iconUnchecked}>
        {isChecked ? <CheckRounded style={{ color: '#ffffff' }} /> : <></>}
      </Icon>
    )
  }

  const renderMedia = (): JSX.Element => {
    if (
      storage?.$type === 'PMI.FACE.BDDM.Extensions.Classes.CloudMediaStorageSettings' &&
      (imageFromServer as Blob | undefined)?.type.startsWith('application/pdf')
    ) {
      return (
        <div className={classes.wrapper}>
          <PictureAsPdf color='primary' className={classes.icon} />
          <Typography noWrap color='primary' className={classes.text}>
            {getFileNameByRoute(item.metadata.key)}
          </Typography>
        </div>
      )
    }

    if (image) {
      return (
        <CardMedia data-id={item.metadata.key} className={classes.imageWrapper}>
          <img
            className={classes.image}
            src={image}
            alt=''
            onContextMenuCapture={(evt) => {
              evt.preventDefault()
              return false
            }}
            onContextMenu={(evt) => {
              evt.preventDefault()
              return false
            }}
          />
        </CardMedia>
      )
    }

    if (!image && !imageOps.loading) {
      return (
        <div className={classes.wrapper}>
          <NotInterested color='primary' className={classes.icon} />
          <Typography noWrap color='primary' className={classes.text}>
            Не загружено
          </Typography>
        </div>
      )
    }

    if (imageOps.loading) {
      return (
        <div className={classes.wrapper}>
          <NotInterested color='primary' className={classes.icon} />
          <Typography noWrap color='primary' className={classes.text}>
            Загрузка...
          </Typography>
        </div>
      )
    }

    return <></>
  }

  return (
    <Card
      className={classes.card}
      onTouchStart={addNonUserSelect}
      onTouchEnd={removeNonUserSelect}
      onTouchCancel={removeNonUserSelect}
    >
      {isSelectMode ? renderCheckedMode() : <></>}
      <CardActionArea className={classes.mediaWrapper} onClick={onClickItem} {...longPressEvent}>
        {renderMedia()}
      </CardActionArea>
    </Card>
  )
}
