import { useContext } from 'react'

import { IScriptTaskContext } from '../script-tasks/script-task-context'
import { ILocalContext, makeStateKey, useLocalContextService } from './local-context'
import { SubProcessContext } from './nested-context'

export function resolvePropertyName(
  propertyName: string,
  subProcessStack: ILocalContext['subProcessStack'], // not optional
): string {
  let path = propertyName

  const stack = subProcessStack ?? []
  for (let i = stack.length - 1; i > -1; i -= 1) {
    const frame = stack[i]
    const { item, recordIndex } = frame
    path = path.replace(/^recordScope/, () => {
      // console.log('found recordScope', frame)
      return `fullScope.${makeStateKey(frame)}`
    })
    const elementVar = item.elementVariable ?? 'record'
    if (path.includes(elementVar)) {
      path = path.replace(elementVar, `${item.propertyName}[${recordIndex}]`)
    }
  }

  path = path.replace(/^scope/, 'fullScope.task')

  return path
}

export function useUpdateProperty(): (propertyName: string, value: unknown) => Promise<IScriptTaskContext> {
  const subProcessStack = useContext(SubProcessContext)
  const contextService = useLocalContextService()
  return async (propertyName: string, value: unknown) => {
    const path = resolvePropertyName(propertyName, subProcessStack)

    // const before = getContextProperty(localContext, path)
    await contextService.updateProperty(path, value)

    const t0 = performance.now()
    await contextService.updateStackSteps()
    const updated = await contextService.refetch([[path, value]])
    const t1 = performance.now()
    // const after = getContextProperty(updated, path)
    console.log('update lasted', t1 - t0, 'ms')
    console.log('updating', path, value, updated)
    return updated
  }
}
