import { createContext, useContext } from 'react'

import { set as setFp } from 'lodash/fp'
import { createStore, StoreApi, UseBoundStore, useStore } from 'zustand'

import { ITaskExecutionState } from '../../../model/task-execution'

interface ExecutionStateStore {
  taskState: ITaskExecutionState
  init: (taskState: ITaskExecutionState) => void
  setTaskStep: (currentStep: number) => void
  setSubProcessStep: (stateKey: string, currentStep: number) => void
}
export type ExecutionStateStoreApi = StoreApi<ExecutionStateStore>

export function createExecutionStateStore(initialState?: ITaskExecutionState): ExecutionStateStoreApi {
  const store = createStore<ExecutionStateStore>(
    (setStore: ExecutionStateStoreApi['setState']): ExecutionStateStore => {
      return {
        taskState: initialState!,
        init: (taskState) => {
          setStore((store) => {
            console.log('running init execution state', taskState, store)
            if (store.taskState && store.taskState.taskCode === taskState?.taskCode) return store
            return { taskState }
          })
        },
        setTaskStep: (currentStep) => {
          setStore(setFp(['taskState', 'currentStep'], currentStep))
        },
        setSubProcessStep: (stateKey, currentStep) => {
          setStore(setFp(['taskState', 'subProcesses', stateKey, 'currentStep'], currentStep))
        },
      }
    },
  )
  console.log('createExecutionStateStore', initialState)
  return store
}

const defaultStore = createExecutionStateStore()

export const ExecutionStateStoreContext = createContext(defaultStore)

export const useExecutionState: UseBoundStore<ExecutionStateStoreApi> = ((selector, equals) => {
  const store = useContext(ExecutionStateStoreContext)
  return useStore(store, selector, equals)
}) as UseBoundStore<ExecutionStateStoreApi>

Object.assign(useExecutionState, defaultStore)
