import React, { useState } from 'react'

import { createStyles, Dialog, makeStyles, Theme } from '@material-ui/core'
import { useCounter } from 'react-use'

import { BlobStorageError } from '../../../infrastructure/blob-storage/blob-storage-api'
import { DocumentsScreenItem } from '../../documents/components/documents-screen-items/documents-screen-item'
import { DocumentsScreenItemLabel } from '../../documents/components/documents-screen-items/documents-screen-item-label'
import { DEFAULT_STORAGE, ITempFileItem, useFetchFilesByKeys } from '../../documents/utils'
import { DocumentViewer } from '../../viewers/document-viewer/document-viewer'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 25,
    },
    title: {
      fontSize: 18,
      fontWeight: 600,
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
      },
    },
  }),
)

export interface IDocumentListDrawerMenu {
  title: string
  inboxIds?: string[]
}

// TaskTemplateContentControl
const DocumentListDrawerMenu: React.FC<IDocumentListDrawerMenu> = ({ title, inboxIds }) => {
  const classes = useStyles()

  const { value: files = [] } = useFetchFilesByKeys(inboxIds)
  const [retryCount, { inc: retryInc }] = useCounter(0)
  const retry = (): void => {
    setDownloadError(undefined)
    retryInc()
  }

  const [openedDocument, setOpenedDocument] = useState<ITempFileItem | null>(null)
  const [downloadError, setDownloadError] = useState<BlobStorageError>()

  if (!files.length) {
    return <></>
  }

  return (
    <div className={classes.root}>
      <DocumentsScreenItem
        key={retryCount}
        label={<DocumentsScreenItemLabel title={title} isReadOnly={true} downloadImageError={downloadError} />}
        documents={files}
        onClickItem={(item) => {
          setOpenedDocument(item)
        }}
        onDownloadError={setDownloadError}
        storage={DEFAULT_STORAGE}
      />
      {openedDocument && (
        <Dialog open={!!openedDocument} fullScreen>
          <DocumentViewer
            title={title}
            isReadOnly={true}
            blob={openedDocument.blob}
            idKey={openedDocument.metadata.key}
            onClose={() => {
              retry()
              setOpenedDocument(null)
            }}
            isPrev={files.indexOf(openedDocument) !== 0}
            isNext={files.indexOf(openedDocument) !== files.length - 1}
            onPrevClick={() => {
              setOpenedDocument(files[files.indexOf(openedDocument) - 1])
            }}
            onNextClick={() => {
              setOpenedDocument(files[files.indexOf(openedDocument) + 1])
            }}
          />
        </Dialog>
      )}
    </div>
  )
}

export default DocumentListDrawerMenu
