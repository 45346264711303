import { IPointOfSale } from '../../model/pos'

export function getPosAddress(pos: IPointOfSale | undefined | null): string {
  const DEFAULT_ADDRESS = '\u00A0'
  const fullAddress = pos?.address?.fullAddress
  const city = pos?.address?.city

  switch (`${Boolean(fullAddress)}-${Boolean(city)}`) {
    case 'true-true':
      return `${fullAddress}, ${city}`
    case 'true-false':
      return `${fullAddress}`
    case 'false-true':
      return `${city}`
    default:
      return DEFAULT_ADDRESS
  }
}
