import React, { useCallback, useState } from 'react'

import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { SmList, SMPeriodSwitcher } from './components'
import { SMReportViewMode } from './models/sm-report.model'
import { getPeriodByViewMode } from './utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(4, 3)
    },
    filter: {
      marginBottom: theme.spacing(4)
    }
  })
)

const SMResultPage: React.FC = () => {
  const classes = useStyles()
  const [viewMode, setViewMode] = useState<SMReportViewMode>('all')
  const [startPeriod, setStartPeriod] = useState<number>(getPeriodByViewMode(viewMode).start)
  const [endPeriod, setEndPeriod] = useState<number>(getPeriodByViewMode(viewMode).end)

  const handleChangeViewMode = useCallback((newValue: SMReportViewMode): void => {
    setViewMode(newValue)
    const { start, end } = getPeriodByViewMode(newValue)
    setStartPeriod(start)
    setEndPeriod(end)
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.filter}>
        <SMPeriodSwitcher currentViewMode={viewMode} onChangeViewMode={handleChangeViewMode} />
      </div>
      <SmList fromDate={startPeriod} toDate={endPeriod} />
    </div>
  )
}

export default SMResultPage
