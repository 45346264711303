import React, { useState, useEffect } from 'react'

import { Tab, Tabs, Theme, createStyles, makeStyles } from '@material-ui/core'

import { IDictionaryItem } from '../../../../../model/dictionary-item'
import { IKeyValuePair } from '../../../../../model/screen-item'
import { isTypeKeyValuePair } from './utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabsRoot: {
      minHeight: 24,
      '& .MuiTab-root': {
        padding: 8,
        minWidth: 60,
        minHeight: 24,
        opacity: 1,
      },
      '& .MuiTab-wrapper': {
        fontSize: 16,
      },
      '& .Mui-selected': {
        color: theme.palette.primary.main,
        background: theme.palette.action.selected,
      },
    },
    tabsFlexContainerCenter: {
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'space-between',
      },
    },
    tabsFlexContainerLeft: {
      justifyContent: 'start',
    },
    indicator: {
      opacity: 0,
    },
    scrollButtons: {
      width: 50,
      [theme.breakpoints.down('xs')]: {
        width: 20,
      },
    },
    scrollable: {
      [theme.breakpoints.down('xs')]: {
        margin: '0 10px',
      },
    },
  }),
)

interface IProps {
  listItems: IKeyValuePair[] | IDictionaryItem[]
  alignType: 'Left' | 'Center'
  selectedlistItem: string | undefined
  selectItem: (id: string) => void
}

const ScrollableTabSelect: React.FC<IProps> = ({ listItems, alignType, selectedlistItem, selectItem }) => {
  const classes = useStyles()

  const [activeTab, setActiveTab] = useState(selectedlistItem)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTabClick = (_: any, newValue: string): void => {
    setActiveTab(newValue)
    selectItem(newValue)
  }

  useEffect(() => {
    setActiveTab(selectedlistItem)
  }, [selectedlistItem])

  if (!listItems.at(0)) {
    return null
  }

  return (
    <Tabs
      value={activeTab}
      indicatorColor='primary'
      variant='scrollable'
      selectionFollowsFocus={true}
      scrollButtons={'on'}
      classes={{
        root: classes.tabsRoot,
        flexContainer: alignType === 'Center' ? classes.tabsFlexContainerCenter : classes.tabsFlexContainerLeft,
        indicator: classes.indicator,
        scrollButtons: classes.scrollButtons,
        scrollable: classes.scrollable,
      }}
      TabIndicatorProps={{
        style: { transition: 'none' },
      }}
      onChange={handleTabClick}
    >
      {listItems.map((item) => {
        if (isTypeKeyValuePair(item)) {
          return <Tab style={{ textTransform: 'none' }} key={item.key} value={item.key} label={item.key} />
        } else {
          return <Tab style={{ textTransform: 'none' }} key={item.code} value={item.code} label={item.name} />
        }
      })}
    </Tabs>
  )
}

export default ScrollableTabSelect
