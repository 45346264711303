/* eslint-disable react/no-children-prop */
import React, { useEffect } from 'react'

import { Route, Routes } from 'react-router-dom'

import { Layout } from '../../../layout'
import { ParticipantInfo } from '../store-menu-page/participant-dte/participant-info'
import { PposmInfo } from '../store-menu-page/pposm/pposm-info'
import ProblemCreatePage from '../store-menu-page/problems/problem-create/problem-create-page'
import ProblemProcessPage from '../store-menu-page/problems/problem-process/problem-process-page'
import ProblemViewPage from '../store-menu-page/problems/problem-view/problem-view-page'
import { ProgramDteInfo } from '../store-menu-page/programs-dte/program-dte-info'
import { StoreMenuPage } from '../store-menu-page/store-menu-page'
import StoresPage from '../stores-page/stores-page'

interface StoresAppFeatureProps {
  children?: never
}

export const StoresAppFeature: React.FC<StoresAppFeatureProps> = (props) => {
  useEffect(() => {
    console.log('StoresAppFeature mount', props, relativeRoutes)
  }, [])

  return (
    <Layout>
      <Routes>
        <Route path={relativeRoutes.root} element={<StoresPage />} />
        <Route path=':posCode/addProblem' element={<ProblemCreatePage />} />
        <Route path={`store/:posCode/problems/problem/:problemId/process`} element={<ProblemProcessPage />} />
        <Route path={`store/:posCode/problems/problem/:problemId`} element={<ProblemViewPage />} />
        <Route path={`store/:posCode/programs-dte/:participantProgramCode`} element={<ProgramDteInfo />} />
        <Route path={`store/:posCode/participant-dte/:participantCode`} element={<ParticipantInfo />} />
        <Route path={`store/:posCode/pposm/:pposmCode`} element={<PposmInfo />} />
        <Route path={`store/:posCode/menu/*`} element={<StoreMenuPage />} />
      </Routes>
    </Layout>
  )
}

export const storesRoutes = {
  root: '/:featureRoute',
} as const

const relativeRoutes = Object.fromEntries(
  Object.entries(storesRoutes).map(([key, value]) => [key, value.replace(/^\/:featureRoute/, '')]),
) as typeof storesRoutes
