import React, { useContext, useEffect } from 'react'

import { ApiContext } from '../api'
import { usePendingItems } from './pending-items-store'

interface PhotoCounterProps {
  children?: never
}

export const PhotoCounter: React.FC<PhotoCounterProps> = (props) => {
  const api = useContext(ApiContext)
  const setPhotoCount = usePendingItems((store) => store.setPhotoCount)
  useEffect(() => {
    if (api.blobStorage) {
      function add(list: unknown[]): void {
        setPhotoCount((count = 0) => count + list.length)
      }

      function subtract(): void {
        setPhotoCount((count = 1) => count - 1)
      }

      api.blobStorage.onSending.add(add)
      api.blobStorage.onBlobSent.add(subtract)
      setPhotoCount((count) => {
        //syncStatus.sendingCount is unreliable for some reason, only initialize
        if (count == null) return api.blobStorage!.syncStatus.sendingCount
        else return count
      })

      return () => {
        if (api.blobStorage) {
          api.blobStorage.onSending.remove(add)
          api.blobStorage.onBlobSent.remove(subtract)
        }
      }
    }
  }, [api.blobStorage])

  return <></>
}
