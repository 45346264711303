export async function rotateImageBlob(base64String: string, mimeType: string): Promise<Blob> {
  const rotatedBase64 = await rotateImageString(base64String, mimeType)
  const base64Response = await fetch(rotatedBase64)
  return base64Response.blob()
}

export async function rotateImageString(base64String: string, mimeType: string): Promise<string> {
  const image = new Image()
  image.src = base64String
  await (async () => {
    return new Promise((resolve, reject) => {
      image.onload = function () {
        resolve({})
      }
    })
  })()
  let canvas: HTMLCanvasElement | null = document.createElement('canvas')
  canvas.setAttribute('id', 'rotateImageCanvas')
  canvas.setAttribute('width', `${image.height}`)
  canvas.setAttribute('height', `${image.width}`)

  const angleRotate = (90 * Math.PI) / 180

  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
  ctx.translate(ctx.canvas.width / 2, ctx.canvas.height / 2)
  ctx.rotate(angleRotate)
  ctx.drawImage(image, -image.width / 2, -image.height / 2, image.width, image.height)

  const rotatedBase64 = canvas.toDataURL(mimeType)
  canvas = null

  return rotatedBase64
}
