import React, { forwardRef, PropsWithChildren } from 'react'

import { makeStyles, Theme, createStyles, Tabs, IconButton, TabsProps } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { clamp } from 'lodash'

import { isNonNullable } from '../../../utils/isNonNullable'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      left: 80,
      top: 84,

      [theme.breakpoints.down('xs')]: {
        paddingBottom: theme.spacing(3),
        left: 0,
        top: 64,
      },
      right: 0,
      zIndex: theme.zIndex.drawer,
      background: 'white',
      paddingBottom: theme.spacing(4),
      display: 'flex',
    },
    tabs: {
      flex: 1,
      '& .MuiTabs-indicator': {
        // background: theme.palette.primary.main
        display: 'none',
      },
    },
    arrowButton: {
      opacity: 1,
      transition: theme.transitions.create('opacity'),
      '&.Mui-disabled': {
        opacity: 0,
        color: theme.palette.primary.main,
      },
    },
  }),
)

type Value = string

interface Props {
  value: Value
  onChange: (value: Value) => void
  classes?: TabsProps['classes']
}

export const MobileTabs = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(function MobileTabs(
  { children, value, onChange, classes: muiClasses },
  ref,
) {
  const classes = useStyles()

  const values =
    React.Children.map(children, (tab) => {
      if (!tab || typeof tab !== 'object') return
      if ('props' in tab) {
        if (!tab.props.disabled) return tab.props.value as string
      }
    })?.filter(isNonNullable) ?? []

  const currentIndex = values.indexOf(value) ?? 0
  const maxIndex = values.length - 1

  const goto = (step: number): void => {
    const newValue = values[clamp(currentIndex + step, 0, maxIndex)]
    console.log('step:', step, ', new stepper value', newValue)
    onChange(newValue)
  }
  return (
    <div ref={ref} className={classes.root}>
      <IconButton
        disabled={currentIndex <= 0} //
        className={classes.arrowButton}
        color='primary'
        onClick={() => goto(-1)}
      >
        <ChevronLeftIcon />
      </IconButton>
      <Tabs
        variant='scrollable' // Experimental
        scrollButtons='off'
        value={value}
        onChange={(_ev, value) => onChange(value)}
        className={classes.tabs}
        classes={muiClasses}
      >
        {children}
      </Tabs>
      <IconButton
        disabled={currentIndex === maxIndex}
        className={classes.arrowButton}
        color='primary'
        onClick={() => goto(+1)}
      >
        <ChevronRightIcon />
      </IconButton>
    </div>
  )
})
