import React from 'react'

import { createStyles, Paper, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(3),
      background: 'rgb(235,240,248)',
      minHeight: theme.spacing(7)
    },
    title: {
      // textTransform: 'uppercase'
    },
    subTitle: {
      marginLeft: theme.spacing(4),
      color: theme.palette.secondary.main,
      fontSize: 20,
      fontWeight: 500
    }
  })
)

interface IIsaSectionTitleProps {
  title: string
  subTitle?: string
}

const SMSectionTitle: React.FC<IIsaSectionTitleProps> = (props) => {
  const classes = useStyles()

  return (
    <Paper square elevation={3} className={classes.root}>
      <Typography variant='h5' component='h5'>
        <span className={classes.title}>{props.title}</span>
        {props.subTitle && (
          <Typography variant='caption' className={classes.subTitle}>
            {props.subTitle}
          </Typography>
        )}
      </Typography>
    </Paper>
  )
}

export default SMSectionTitle
