import { IContent, IDictionaryContent, TextMimeContent } from '../model/content'

export function getContentText(content?: IContent): string {
  if (!content || !content.parts?.length) {
    return ''
  }
  const textParts = content.parts.filter((x) => x.type === 'text/plain')
  return textParts.length ? (textParts[0] as TextMimeContent)?.text ?? '' : ''
}

export function getDictionaryContentText(content?: IDictionaryContent): string {
  if (!content || !content.parts?.length) {
    return ''
  }
  const textParts = content.parts.filter((x) => x.type === 'text/plain')
  return textParts.length ? textParts[0]?.text ?? '' : ''
}
