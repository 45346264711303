import * as zlib from 'zlib'

export async function ungzip(body: Uint8Array): Promise<Buffer> {
  return new Promise(function( resolve, reject ) {
    zlib.gunzip(body, (err, buffer) => {
      if (err){
        console.log('Error Unzipping')
        reject(err)
      }
      console.log('Unzipped')
      resolve(buffer)
    })
  })
}

export async function gzip(body: string | ArrayBuffer): Promise<Uint8Array> {
  return new Promise( function( resolve, reject ) {
    zlib.gzip(body, {}, (err, buffer) => {
      if (err){
        console.log('Error Zipping')
        reject(err)
      }
      const sourceSize = (body as ArrayBuffer).byteLength ?? (body as string).length ?? NaN
      const targetSize = buffer.byteLength
      console.log(`zipped ${sourceSize} bytes -> ${targetSize} bytes`)
      // reject(new Error('fail')) // Для дебага ошибок
      resolve(buffer)
    })
  })
}