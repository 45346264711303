/* eslint-disable @typescript-eslint/no-use-before-define, @typescript-eslint/method-signature-style */

import { groupBy } from 'lodash'

import { getEntityKey } from '../../../../../model/base'
import { IKeyValuePair } from '../../../../../model/data-source'
import { EncryptionSettingsReference } from '../../../../../model/encryption-settings'
import { ValidationError, ValidationErrorCode } from '../../../../../model/errors'
import { IIFrameScreenItem } from '../../../../../model/iframe-screen-item'

interface InterpolationEnv {
  interpolate(value: string): string
}

interface EncryptionEnv {
  encrypt(items: IKeyValuePair[], encryption: EncryptionSettingsReference): Promise<IKeyValuePair[]>
}

export interface RequestConfig {
  url: string
  method: string
  headers: Record<string, string>
  body: unknown
}

export function buildRequest(env: InterpolationEnv & EncryptionEnv) {
  return async function (item: IIFrameScreenItem): Promise<RequestConfig> {
    const processPairs_ = processPairs(env)

    async function buildBody(): Promise<unknown> {
      if (!item.requestBody) {
        return undefined
      }

      switch (item.requestBody?.$type) {
        case 'PMI.FACE.BDDM.Extensions.Classes.FormDataWebRequestBody': {
          const result = await processPairs_(item.requestBody.parameters)
          const fd = new FormData()
          for (const param of result) {
            fd.append(param.key, param.value)
          }
          return fd
        }

        default:
          throw new Error(`Unsupported request body type '${item.requestBody.$type}'`)
      }
    }

    if (!item.url.trim()) {
      throw new ValidationError(ValidationErrorCode.RequiredFieldsMissing, 'URL is required')
    }

    try {
      void new URL(item.url)
    } catch (error) {
      throw new ValidationError(ValidationErrorCode.PreconditionFailed, 'Invalid URL', error)
    }

    const [qsPairs, headerPairs, body] = await Promise.all([
      processPairs_((item.requestParameters ?? []).map((pair) => ({ ...pair, key: pair.name }))),
      processPairs_((item.requestHeaders ?? []).map((pair) => ({ ...pair, key: pair.name }))),
      buildBody(),
    ])

    const url = new URL(item.url)

    for (const pair of qsPairs) {
      url.searchParams.append(pair.key, pair.value)
    }

    return {
      url: url.href,
      method: item.requestMethod,
      headers: Object.fromEntries(headerPairs.map((x) => [x.key, x.value])),
      body: body,
    }
  }
}

interface EncryptKeyValuePair {
  key: string
  value: string
  encryption?: EncryptionSettingsReference
}

function processPairs(env: InterpolationEnv & EncryptionEnv) {
  return async function (pairs: EncryptKeyValuePair[]): Promise<IKeyValuePair[]> {
    const interpolated = pairs.map((pair) => ({ ...pair, value: env.interpolate(pair.value) }))
    const dict = groupBy(interpolated, (pair) => (pair.encryption ? getEntityKey(pair.encryption) : ''))
    const groups = Object.values(dict).map((group) => [group[0].encryption, group] as const)
    const encryptTasks = groups.map(async ([enc, pairs]) => (enc ? await env.encrypt(pairs, enc) : pairs))
    const encrypted = await Promise.all(encryptTasks)
    return encrypted.flat(1)
  }
}
