import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react'

import { set } from 'lodash/fp'
import { Route, Routes, useNavigate } from 'react-router-dom'

import { Code, CodeSpace, generateEntityCode, IEntityReference } from '../../../model/base'
import { IContractTerm } from '../../../model/contract-term'
import { IPointOfSale } from '../../../model/pos'
import { IProductMatrix } from '../../../model/product-matrix'
import { IQuestionnaire } from '../../../model/questionnaire'
import { ISupervisedFieldPositionRole } from '../../../model/supervised-field-position-role'
import { ITask } from '../../../model/task'
import { ITaskExecutionScope, ITaskExecutionState } from '../../../model/task-execution'
import { ITaskStage } from '../../../model/task-stage'
import { ITaskTemplate } from '../../../model/task-template'
import { IUserProfile } from '../../../model/user-profile'
import { IVisit } from '../../../model/visit'
import { IVisitTask } from '../../../model/visit-task'
import { ConfigContext, ProfileContext } from '../../../providers'
import { useAsyncError } from '../../_common/hooks/useAsyncError'
import { useAsyncRetryPromise } from '../../_common/hooks/useAsyncRetry'
import { useBusinessSettings } from '../../_common/hooks/useBusinessSettings'
import { useWebUrl } from '../../_common/hooks/useWebUrl'
import { VisitAssessment } from '../audit/audit-merchandise-service-task'
import { DebugTree } from '../debug-tree'
import { IScriptTaskContext, ScriptTaskContext } from '../script-tasks/script-task-context'
import TaskDialog from '../template-tasks/task-dialog'
import { useExecutionState } from './execution-state'
import { ILocalContext, ILocalContextService, LocalContextServiceContext } from './local-context'
import { NestedProcessPage } from './nested-process'
import NonVisitTaskDialog from './non-visit-task-dialog'

interface Props {
  template: ITaskTemplate
  initialData?: Record<string, unknown>
}

class PreviewContextService implements ILocalContextService {
  private task: ITask
  private taskExecutionState: ITaskExecutionState
  private taskExecutionScope: ITaskExecutionScope = { task: {} }

  constructor(template: ITaskTemplate, readonly refetch: ILocalContextService['refetch']) {
    this.task = {
      code: generateEntityCode('PREWIEWTASK'),
      name: '',
      startDate: 0,
      source: 'SelfAssigned',
      status: 'InProgress', // TODO: parametrize api calls in TaskDialogs and Surveys
      surveys: [],
      template,
      updateTime: 0,
      visitCode: '',
      printedForm: {
        parts: [
          {
            type: 'PDF',
            $type: 'PMI.BDDM.Common.MimeContentRef',
            target: 'VIS40763L92JCWTZ-TAS88313L92JIELN-report.pdf',
          },
        ],
      } as never,
    } as IVisitTask

    this.taskExecutionState = {
      taskCode: this.task.code,
      currentStep: 0,
      subProcesses: {},
    }
  }

  getTaskTemplateStages: (taskCode: string, searchInSupervisedTasks: boolean) => Promise<ITaskStage[]> = async () => []

  getTaskTemplateByKey: (ref: IEntityReference) => Promise<ITaskTemplate | null> = async () => null
  getTaskTemplateByTaskCode: (taskCode: string, searchInSupervisedTasks: boolean) => Promise<ITaskTemplate | null> =
    async () => null

  getProductMatrix: () => Promise<IProductMatrix[]> = async () => []
  getFprUserProfiles: () => Promise<IUserProfile[]> = async () => []
  getSupervisedRoleByCode: (roleCode: Code) => Promise<ISupervisedFieldPositionRole | undefined> = async () => undefined
  getContractTerms: () => Promise<IContractTerm[]> = async () => []
  createVisitProblem = async (): Promise<string> => {
    throw new Error('not implemented')
  }

  getTask = async (): Promise<ITask> => {
    return this.task
  }

  getTaskExecutionState = async (): Promise<ITaskExecutionState> => {
    return this.taskExecutionState
  }

  getTaskExecutionScope = async (): Promise<ITaskExecutionScope> => {
    return this.taskExecutionScope
  }

  updateProperty = async (propertyName: string, value: unknown): Promise<void> => {
    if (propertyName.startsWith('fullScope')) {
      const oldScope = await this.getTaskExecutionScope()
      const { fullScope: newScope } = set(propertyName, value, { fullScope: oldScope })
      // console.log('updating scope instead', oldScope, newScope)
      return await this.saveScope(newScope!)
    }
    if (propertyName.startsWith('task')) {
      this.task = set(propertyName.replace(/task./, ''), value, this.task)
    }
  }

  saveTask = async (task: ITask): Promise<void> => {
    this.task = task
  }

  saveScope = async (scope: ITaskExecutionScope): Promise<void> => {
    this.taskExecutionScope = scope
  }

  saveTaskState = async (taskState: ITaskExecutionState): Promise<void> => {
    this.taskExecutionState = taskState
  }

  updateStackSteps = async (): Promise<void> => {
    // TODO: update upwards only
    const localTaskState = useExecutionState.getState().taskState
    await this.saveTaskState(localTaskState)
    await this.refetch()
  }

  onLoad = async (): Promise<void> => {
    return Promise.resolve()
  }

  loadMediaForSupervisedVisit = async (): Promise<void> => {
    return Promise.resolve()
  }

  getPosExtension = async (posCode: string): Promise<VisitAssessment['posExtension'] | undefined> => {
    return Promise.resolve(undefined)
  }

  getQuestionnaireByKey = async (ref: IEntityReference): Promise<IQuestionnaire | null> => {
    return Promise.resolve(null)
  }

  getDefaultCodeSpace = (): CodeSpace => 'TEMPLATE-PREVIEW-TEMP'
}

const visit: IVisit = {
  code: 'Template-preview-visit',
  plannedStartDate: new Date(),
  status: 'InProgress',
} as unknown as IVisit

const pos: IPointOfSale = {
  code: 'TESTPOS01',
  name: 'Тестовая точка',
  address: { city: 'Ярик', fullAddress: 'ул. Пушкина 1' } as never,
} as unknown as IPointOfSale

const TemplatePreviewContextProvider: React.FC<Props> = ({ template, initialData, children }) => {
  const serviceRef = useRef<PreviewContextService>()
  const setTaskStateStore = useExecutionState((store) => store.init)
  const profileContext = useContext(ProfileContext)
  const webUrl = useWebUrl()
  const config = useContext(ConfigContext)
  const businessParameters = useBusinessSettings()

  useLayoutEffect(() => {
    serviceRef.current = new PreviewContextService(template, contextOps.retry as never)
  }, [template])

  const contextOps = useAsyncRetryPromise(async () => {
    const methods = serviceRef.current!
    const task = await methods.getTask()
    if (!task) throw new Error('no task')
    const taskState = await methods.getTaskExecutionState()
    setTaskStateStore(taskState)
    const fullScope = await methods.getTaskExecutionScope()
    const context: ILocalContext = {
      // subProcessStack: [],
      ...initialData,
      fieldPositionRole: profileContext.value?.fieldPositionRole,
      employee: profileContext.value?.employee,
      profileCode: profileContext.value?.profile?.code,
      participantProfile: profileContext.value.participantProfile,
      visit,
      pos,
      task,
      template,
      fullScope,
      taskState,
      scope: fullScope.task,
      questionnaires: [],
      surveys: {},
      apiUrl: config.config.apiUrl,
      webUrl,
      businessParameters,
      readOnly: false,
    }
    return context
  }, [profileContext.value])

  useAsyncError(contextOps.error)
  if (contextOps.loading && !contextOps.value) return <></>
  const context = contextOps.value as unknown as IScriptTaskContext

  return (
    <LocalContextServiceContext.Provider value={serviceRef.current!}>
      <ScriptTaskContext.Provider value={context}>{children}</ScriptTaskContext.Provider>
    </LocalContextServiceContext.Provider>
  )
}

type PreviewProps = Props & {
  onBack: VoidFunction
}

/** mount this to non-exact route */
export const TemplatePreview: React.FC<PreviewProps> = ({ template, initialData, onBack }) => {
  const navigate = useNavigate()

  useEffect(() => {
    console.log('TemplatePreview mount', template)
  }, [])

  const toTopLevel = onBack
  const toUpperLevel = (): void => navigate('')

  return (
    <TemplatePreviewContextProvider template={template} initialData={initialData}>
      <DebugTree />
      <Routes>
        <Route
          path=''
          element={
            template.nonVisitTask ? <NonVisitTaskDialog onBack={toTopLevel} /> : <TaskDialog onBack={toTopLevel} />
          }
        />
        <Route path={`process/:processCode/:recordCode/*`} element={<NestedProcessPage onBack={toUpperLevel} />} />
      </Routes>
    </TemplatePreviewContextProvider>
  )
}
