import React from 'react'

import { Box, createStyles, makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { PageContent } from '../../../../../layout/page-content'
import { TitleBar } from '../../../../../layout/title-bar'
import ProblemPosInfoScreenItem from '../items/problem-pos-info-screen-item'
import { ProblemContextProvider } from '../problem-context'
import ProblemCreateBase from './problem-create-base'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    page: {
      overflowX: 'auto',
      height: 'calc(100vh - 88px)',
      [theme.breakpoints.down('xs')]: {
        height: 'calc(100vh - 64px)',
      },
    },
    wrapper: {
      padding: 25,
      paddingBottom: theme.spacing(12),
      [theme.breakpoints.down('xs')]: {
        padding: '21px 18px 0',
        paddingBottom: theme.spacing(12),
      },
    },
  }),
)

interface IState {
  posCode: string
}

const ProblemCreatePage: React.FC = () => {
  const { posCode } = useParams() as unknown as IState
  const navigate = useNavigate()
  const { t } = useTranslation('problems')
  const classes = useStyles()

  const onBackHandler = (): void => {
    navigate(-1)
  }

  return (
    <ProblemContextProvider posCode={posCode}>
      <TitleBar onBack={onBackHandler}>{t('addingProblem')}</TitleBar>
      <PageContent className={classes.page}>
        <Box className={classes.wrapper}>
          <ProblemPosInfoScreenItem />
          <ProblemCreateBase navigateHandler={onBackHandler} />
        </Box>
      </PageContent>
    </ProblemContextProvider>
  )
}

export default ProblemCreatePage
