import { Code } from '../model/base'
import { IContent } from '../model/content'
import { FieldForceVisitEscalationReason } from '../model/dictionary-item'
import { IPOSReference } from '../model/pos'
import { IProblem, ProblemStatus } from '../model/problem'
import { IProblemTemplate } from '../model/problem-template'
import { IPosVisitReference } from '../model/visit'

export interface ProblemSearchRequest {
  posCode?: Code
  status?: ProblemStatus[]
}

export interface CreateProblemRequest {
  executiveComment: string
  escalationReason: FieldForceVisitEscalationReason
  attachment?: IContent
  visit?: IPosVisitReference
  problemDetails?: string[]
  location?: IPOSReference
}

export enum ProblemErrorCode {
  UnknownRole,
  DuplicateProblem,
  NoProblemTemplateFound,
}

export interface IProblemService {
  /** Возвращает шаблоны проблем */
  getProblemTemplates: () => Promise<IProblemTemplate[]>

  /** Возвращает проблему по коду */
  getProblem: (problemCode: Code) => Promise<IProblem | null>

  /** Возвращает проблемы по фильтру */
  searchProblems: (request: ProblemSearchRequest) => Promise<IProblem[]>

  /** Создает новую проблему для каждого problemDetails */
  createNewProblems: (request: CreateProblemRequest) => Promise<IProblem[]>

  /** Сохраняет проблему */
  saveProblem: (problem: IProblem) => Promise<void>

  /** Удаляет проблему */
  deleteProblem: (problemCode: Code) => Promise<void>
}
