import { InjectionToken } from './injection-token'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Ctor<T> = new (...args: any[]) => T

export type Token<T> = Ctor<T> | InjectionToken<T>

export interface Provider<T> {
  provide: Token<T>
  factory: (container: Container) => T
}

export class Container {
  private readonly _providers = new Map<Token<unknown>, Provider<unknown>>()
  private readonly _instances = new Map<Token<unknown>, unknown>()

  constructor(providers: Array<Provider<unknown>>) {
    for (const provider of providers) {
      this._providers.set(provider.provide, provider)
    }
  }

  register<T>(provider: Provider<T>): void {
    this._providers.set(provider.provide, provider)
  }

  get<T>(token: Token<T>): T {
    if (this._instances.has(token)) {
      return this._instances.get(token) as T
    }

    if (this._providers.has(token)) {
      const provider = this._providers.get(token) as Provider<T>

      const instance = provider.factory(this)

      this._instances.set(provider.provide, instance)

      return instance
    } else {
      throw new Error(`Unknown provider`)
    }
  }
}
