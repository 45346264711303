import React from 'react'

import { Box, Button, Container, Paper, Theme, Typography, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  wrap: {
    height: 'calc(100% + 160px)',
    background: theme.palette.background.default,
  },
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  paper: {
    width: '100%',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 24,
  },
  isSmall: {
    height: '100%',
    borderRadius: 'unset',
  },
  formWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginBottom: 32,
  },
  resetBtn: {
    marginBottom: 16,
  },
}))

interface IErrorPageProps {
  errorMessage?: string
  onBack?: VoidFunction
  title?: string
}

/**
 * Страница плэйсхолдер, когда нужно показать ошибку и дать возможность перейти на главную страницу
 */
const ErrorPage: React.FC<IErrorPageProps> = ({ errorMessage, onBack, title = 'Произошла ошибка' }) => {
  const classes = useStyles()
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'))
  const navigate = useNavigate()
  const toMainPage = (): void => {
    navigate('/')
  }

  return (
    <Box className={classes.wrap}>
      <Container disableGutters fixed className={classes.root} component='main' maxWidth='sm'>
        <Paper
          elevation={10}
          className={classnames(classes.paper, {
            [classes.isSmall]: isSmall,
          })}
        >
          <Container fixed className={classes.formWrap} component='section' maxWidth='xs'>
            {title && (
              <Typography variant='h4' className={classes.title} align='center'>
                {title}
              </Typography>
            )}
            <Typography variant='h5' className={classes.title} align='center'>
              {errorMessage}
            </Typography>

            <Button variant='contained' color='primary' onClick={onBack ?? toMainPage} className={classes.resetBtn}>
              Перейти на главную страницу
            </Button>
          </Container>
        </Paper>
      </Container>
    </Box>
  )
}

export default ErrorPage
