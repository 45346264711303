import { useBusinessSettings } from '../_common/hooks/useBusinessSettings'
import { optimizeBlobImage } from './utils'

const DEFAULT_CONTENT_TYPE = 'image/jpeg'
const DEFAULT_QUALITY = 90
const DEFAULT_RESOLUTION = 2100

export const useOptimizeBlobImage = (): ((blobImage: Blob) => Promise<Blob>) => {
  const businessSettings = useBusinessSettings()

  if (!businessSettings || businessSettings.compressUserMadePhotos === 'false') {
    return async (blobImage: Blob) => blobImage
  }

  const { compressUserMadePhotosMaxLength, compressUserMadePhotosQuality } = businessSettings

  const resolution = Number(compressUserMadePhotosMaxLength)
  const quality = Number(compressUserMadePhotosQuality)

  return async (blobImage: Blob) =>
    optimizeBlobImage(
      blobImage,
      resolution >= 1000 && resolution <= 5000 ? resolution : DEFAULT_RESOLUTION,
      quality >= 1 && quality <= 99 ? quality : DEFAULT_QUALITY,
      DEFAULT_CONTENT_TYPE,
    )
}
