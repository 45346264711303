import React, { useEffect, useState } from 'react'

import { Button, createStyles, Dialog, DialogActions, DialogTitle } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sendDumpButton: {
      position: 'absolute',
      bottom: 37,
      right: -7,
      height: 60,
      width: 28,
      borderRadius: '50%',
      [theme.breakpoints.down('xs')]: {
        right: 2,
        bottom: 30,
      },
    },
    hideButton: {
      opacity: 0,
      pointerEvents: 'none',
    },
  }),
)

export interface SendDumpButtonProps {
  isInitModal?: boolean
  isDisabled?: boolean
  onCloseModal?: React.Dispatch<boolean>
  isHide?: boolean
}

export const SendDumpButton: React.FC<SendDumpButtonProps> = ({
  isInitModal = false,
  isDisabled,
  onCloseModal,
  isHide = false,
}): JSX.Element => {
  const navigate = useNavigate()
  const classes = useStyles()
  const { t } = useTranslation(['sync', 'common'])

  const [isShowModal, setIsShowModal] = useState(false)

  useEffect(() => {
    setIsShowModal(isInitModal)
  }, [isInitModal])

  return (
    <>
      <Button
        type='submit'
        disabled={isDisabled}
        // variant='outlined'
        color='primary'
        className={classNames(classes.sendDumpButton, isHide ? classes.hideButton : '')}
        aria-label='Send dump BD'
        onClick={(evt) => {
          setIsShowModal(true)
        }}
      >
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9.5 11.8775C9.00757 12.2721 8.61029 12.781 8.34839 13.3646L8.34352 13.3754C8.26605 13.548 8.20111 13.6928 8.14829 13.8038C8.10219 13.9008 8.03371 14.0417 7.94687 14.1632C7.82083 14.3396 7.65546 14.5347 7.414 14.691C7.17254 14.8472 6.92683 14.9182 6.71431 14.961C6.51342 15.0014 6.27177 15.0009 6.0642 15.0004H6.06419L6 15.0003C4.89543 15.0003 4 15.8958 4 17.0003C4 18.1049 4.89543 19.0003 6 19.0003H12H18C19.1046 19.0003 20 18.1049 20 17.0003C20 15.8958 19.1046 15.0003 18 15.0003L17.9358 15.0004H17.9358C17.7282 15.0009 17.4866 15.0014 17.2857 14.961C17.0732 14.9182 16.8275 14.8472 16.586 14.691C16.3445 14.5347 16.1792 14.3396 16.0531 14.1632C15.9663 14.0417 15.8978 13.9008 15.8517 13.8038C15.7989 13.6928 15.7339 13.548 15.6565 13.3754L15.6516 13.3646C15.3897 12.781 14.9924 12.2721 14.5 11.8775V9.54443C15.8201 10.1504 16.8818 11.2212 17.4763 12.5457C17.5599 12.732 17.6158 12.8563 17.6579 12.945C17.6683 12.9669 17.6767 12.9841 17.6835 12.9976L17.7032 12.9983C17.7668 13.0002 17.854 13.0003 18 13.0003C20.2091 13.0003 22 14.7912 22 17.0003C22 19.2095 20.2091 21.0003 18 21.0003H12H6C3.79086 21.0003 2 19.2095 2 17.0003C2 14.7912 3.79086 13.0003 6 13.0003C6.14598 13.0003 6.23318 13.0002 6.29677 12.9983L6.31654 12.9976C6.32326 12.9841 6.33167 12.9669 6.34206 12.945C6.38425 12.8563 6.44011 12.732 6.52373 12.5457C7.11818 11.2212 8.17993 10.1504 9.5 9.54443V11.8775ZM6.29756 13.034C6.29755 13.034 6.29761 13.0339 6.29774 13.0337L6.29777 13.0337L6.29756 13.034ZM17.7024 13.034C17.7024 13.034 17.7024 13.0339 17.7022 13.0337L17.7023 13.0337L17.7024 13.034Z'
            fill={isDisabled ? '#0000001f' : '#3077B8'}
          />
          <path
            d='M12 3L11.2929 2.29289L12 1.58579L12.7071 2.29289L12 3ZM13 13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13L13 13ZM7.29289 6.29289L11.2929 2.29289L12.7071 3.70711L8.70711 7.70711L7.29289 6.29289ZM12.7071 2.29289L16.7071 6.29289L15.2929 7.70711L11.2929 3.70711L12.7071 2.29289ZM13 3L13 13L11 13L11 3L13 3Z'
            fill={isDisabled ? '#0000001f' : '#3077B8'}
          />
        </svg>
      </Button>

      {isShowModal ? (
        <Dialog
          open={true}
          disableScrollLock
          // onClose={handleClose}
        >
          <DialogTitle id='alert-dialog-title'>{t('sendDumpTitle')}</DialogTitle>
          <DialogActions>
            <Button
              color='primary'
              onClick={() => {
                navigate('/dump-load')
              }}
            >
              {t('yes', { ns: 'common' })}
            </Button>
            <Button
              autoFocus
              color='primary'
              onClick={(evt) => {
                evt.preventDefault()
                setIsShowModal(false)
                onCloseModal?.(false)
              }}
            >
              {t('no', { ns: 'common' })}
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <></>
      )}
    </>
  )
}
