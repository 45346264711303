import React, { useContext } from 'react'

import { Box, Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { Navigate, useNavigate, useLocation } from 'react-router-dom'

import { LockedPage } from '../../layout/locked-page'
import { AuthContext } from '../../providers'
import { IUnauthorizedLocationState } from './sync-location-types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorMsg: {
      lineHeight: '36px',
      textAlign: 'center',
      fontSize: 28,
      fontStyle: 'normal',
      fontWeight: 500,
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
        lineHeight: '28px',
      },
    },
    errorIcon: {
      verticalAlign: 'middle',
      marginRight: 8,
      color: theme.palette.error.dark,
    },
    submit: {
      height: 48,
      paddingLeft: 25,
      paddingRight: 25,
      borderRadius: 28,
    },
  }),
)

export const UserUnauthorizedPage: React.FC = () => {
  const classes = useStyles()
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const location = useLocation() as { state: IUnauthorizedLocationState }
  if (!location.state) {
    return <Navigate to='/' />
  }
  const { userName, version } = location.state

  const onLogout = (): void => {
    auth.authService.logoff()
    navigate('/')
  }

  return (
    <LockedPage>
      <Typography className={classes.errorMsg} align='center'>
        Для продолжения работы необходимо повторно войти в приложение
      </Typography>
      {userName && (
        <Box mt={1}>
          <Typography align='center'>
            Пользователь: <b>{userName}</b>
          </Typography>
        </Box>
      )}
      <Box>
        <Typography align='center'>Версия приложения: {version /*  || '0.1.17' */}</Typography>
      </Box>
      <Box mt={2} textAlign='center'>
        <Button className={classes.submit} color='primary' variant='contained' onClick={onLogout}>
          Войти повторно
        </Button>
      </Box>
    </LockedPage>
  )
}
