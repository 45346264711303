import React, { useMemo } from 'react'

import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'

import { IDictionaryContent } from '../../../../../model/content'
import { IDictionaryItem } from '../../../../../model/dictionary-item'
import { IRepeaterScreenItem } from '../../../../../model/repeater-screen-item'
import { ICompositeReportProcessor } from '../../../../../model/report-processor'
import { IScreenItem, isRepeater, isScreenItemGroup, ScreenItemGroup } from '../../../../../model/screen-item'
import type { IReportTaskStageScreen } from '../../../../../model/task-stage-screen'
import { dateFormat, formatTemplateString, getDictionaryContentText } from '../../../../../utils'
import { getContextProperty } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { IFrameScreenItem } from '../iframe-item/iframe-screen-item'
import { RepeaterRenderer, RepeaterScreenItem } from '../repeater-screen-item'
import { SignatureImageReportItem } from '../signature-image-item/signature-image-report-item'
import { ITextScreenItem, TextScreenItemControl } from '../text-screen-item'
import { sortScreenItems } from '../utils'
import { SurveyListHtml } from './components/survey-list-html'
import { ViewSurveyHtml } from './components/view-survey-html'
import { TablePreview } from './table-preview'
import { TaskDirectionsPreview } from './task-directions'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: {
      fontStyle: 'italic',
    },
    comment: {
      marginTop: 3,
      letterSpacing: -0.25,
      fontSize: '0.75em',
      color: 'grey',
    },
    groupDivider: {
      backgroundColor: '#f2f2f2',
    },
    title: {
      textAlign: 'center',
      fontSize: '1.5em',
      fontWeight: 'bold',
    },
    group: {
      // fontSize: '1.4em',
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    root: {
      padding: theme.spacing(4),
      fontSize: 20,
      '& table': {
        borderCollapse: 'collapse',
        width: '100%',
      },
      '& table th, & table td': {
        border: '1px solid black',
        padding: 2,
      },
    },
    label: {
      fontSize: 'inherit',
      whiteSpace: 'pre-wrap',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
)

interface IProps {
  resultFile: ICompositeReportProcessor
  actionKind?: IReportTaskStageScreen['actionKind']
}
export const CompositeReportPreview: React.FC<IProps> = ({ resultFile, actionKind = 'View' }) => {
  const classes = useStyles()

  const sorted = useMemo(() => {
    const res = sortScreenItems(resultFile.items)
    return res
  }, [resultFile.items])

  const title = resultFile.displayName ?? 'CompositeReportPreview' // TODO: remove temp placeholder

  return (
    <div className={classes.root}>
      <div className={classes.title}>{title}</div>
      {sorted.map((item) => RenderItem({ item, actionKind }))}
    </div>
  )
}

interface HTMLTextProps {
  value: string
  item: {
    hideEmpty?: boolean
    nullValueCaption?: string
    displayName: string
  }
}
const HTMLText: React.FC<HTMLTextProps> = ({ value, item }) => {
  const classes = useStyles()

  if (item.hideEmpty && !value) {
    return <></>
  }

  return (
    <Typography className={classes.label}>
      {item.displayName && <strong>{item.displayName}: </strong>}
      {value || (item.nullValueCaption ?? ' - ')}
    </Typography>
  )
}

const TableHTMLText: React.FC<HTMLTextProps> = ({ value, item }) => {
  // if (item.hideEmpty && !value) {
  //   return <></>
  // }

  return (
    <tr>
      <td>{item.displayName}</td>
      <td>{(value || item.nullValueCaption) ?? ' - '}</td>
    </tr>
  )
}

interface IRenderItemProps {
  item: IScreenItem | ScreenItemGroup
  actionKind?: IReportTaskStageScreen['actionKind']
}
const RenderItem: React.FC<IRenderItemProps> = (props) => {
  const classes = useStyles()
  const { item: renderItem, actionKind } = props

  if (isScreenItemGroup(renderItem)) {
    const group = renderItem
    if (group.viewSettings?.viewMode === 'List') {
      return (
        <div key={group.displayName}>
          <div className={classes.group}>{group.displayName}</div>
          {group.items.map((item) => RenderListItem({ item, actionKind }))}
        </div>
      )
    }
    return (
      <div key={group.displayName}>
        <div className={classes.group}>{group.displayName}</div>
        <table>
          <tbody>{group.items.map((item) => RenderTableItem({ item }))}</tbody>
        </table>
      </div>
    )
  } else if (isRepeater(renderItem)) {
    return RenderRepeater({ item: renderItem, actionKind })
  } else {
    return RenderListItem({ item: renderItem, actionKind })
  }
}

interface IRenderListItemProps {
  item: IScreenItem
  actionKind?: IReportTaskStageScreen['actionKind']
}
const RenderListItem: React.FC<IRenderListItemProps> = ({ item, actionKind }): JSX.Element => {
  const propertiesContext = useScriptTaskContext()
  const classes = useStyles()

  interface IDictionaryItemWithContent extends IDictionaryItem {
    content: IDictionaryContent
  }

  switch (item.$type) {
    case 'PMI.FACE.BDDM.Extensions.Classes.StringPropertyScreenItem': {
      const textScreenItem = {
        hideEmpty: item.viewSettings?.hideEmpty,
        nullValueCaption: item.viewSettings?.nullValueCaption,
        displayName: item.displayName,
        propertyName: item.propertyName,
      } as ITextScreenItem
      return (
        <TextScreenItemControl
          key={item.propertyName}
          item={textScreenItem}
          element={<HTMLText value='' item={textScreenItem} />}
        />
      )
    }
    case 'PMI.FACE.BDDM.Extensions.Classes.TextScreenItem': {
      return <TextScreenItemControl key={item.displayName} item={item} element={<HTMLText value='' item={item} />} />
    }
    case 'PMI.FACE.BDDM.Extensions.Classes.SurveysPropertyScreenItem': {
      return (
        <div>
          <SurveyListHtml item={item} />
        </div>
      )
    }
    case 'PMI.FACE.BDDM.Extensions.Classes.ViewSurveyScreenItem': {
      return (
        <div>
          <ViewSurveyHtml item={item} />
        </div>
      )
    }
    case 'PMI.FACE.BDDM.Extensions.Classes.SalesExpertEducationTaskDirectionsScreenItem': {
      return (
        <Box marginBottom={3}>
          <div className={classes.group}>{item.displayName}</div>
          <TaskDirectionsPreview item={item} />
        </Box>
      )
    }
    case 'PMI.FACE.BDDM.Extensions.Classes.DictionaryItemPropertyScreenItem': {
      const entry: IDictionaryItem | IDictionaryItem[] | undefined = getContextProperty(
        propertiesContext,
        item.propertyName,
      )
      const value = entry instanceof Array ? entry[0] : entry

      const formattedValue = value
        ? `${value.name}. ${getDictionaryContentText((value as IDictionaryItemWithContent).content)} `
        : ' - '
      return (
        <Typography className={classes.label}>
          <strong>{item.displayName}: </strong>
          {formattedValue}
        </Typography>
      )
    }
    case 'PMI.FACE.BDDM.Extensions.Classes.SignatureImageScreenItem': {
      return <SignatureImageReportItem item={item} isReadOnly={actionKind !== 'Edit' || item.actionKind !== 'Edit'} />
    }
    case 'PMI.FACE.BDDM.Extensions.Classes.LabelScreenItem': {
      const value = formatTemplateString(item.text, (prop) => getContextProperty(propertiesContext, prop, ``))
      return <Typography className={classes.label}>{value}</Typography>
    }
    case 'PMI.FACE.BDDM.Extensions.Classes.TableScreenItem': {
      return <TablePreview className={classes.label} item={item} />
    }
    case 'PMI.FACE.BDDM.Extensions.Classes.IframeScreenItem': {
      return (
        <div>
          <IFrameScreenItem item={item} />
        </div>
      )
    }
    case 'PMI.FACE.BDDM.Extensions.Classes.RepeaterScreenItem': {
      return <RepeaterScreenItem item={item} />
    }
    default:
      return (
        <Typography className={classes.label} key={item.propertyName}>
          unimplemented type {String(item.$type)} for {item.displayName || item.propertyName}
        </Typography>
      )
  }
}

const RenderTableItem = ({ item }: { item: IScreenItem }): JSX.Element => {
  const propertiesContext = useScriptTaskContext()

  switch (item.$type) {
    case 'PMI.FACE.BDDM.Extensions.Classes.StringPropertyScreenItem': {
      let value = getContextProperty(propertiesContext, item.propertyName, ' - ')
      if (item.propertyName.toLowerCase().includes('visit.startdate')) {
        console.log('found date')
        value = dateFormat(value as unknown as number, 'dd.MM.yyyy')
      }
      return <TableHTMLText key={item.propertyName} value={value} item={{ ...item.viewSettings, ...item }} />
    }
    case 'PMI.FACE.BDDM.Extensions.Classes.TextScreenItem': {
      return <TextScreenItemControl item={item} element={<TableHTMLText item={item} value='' />} />
    }
    case 'PMI.FACE.BDDM.Extensions.Classes.SurveysPropertyScreenItem': {
      return (
        <tr>
          <td colSpan={2}>
            <SurveyListHtml item={item} />
          </td>
        </tr>
      )
    }
    default:
      console.log(item)
      return (
        <tr key={item.propertyName}>
          <td colSpan={2}>
            unimplemented type {String(item.$type)} for {item.displayName || item.propertyName}
          </td>
        </tr>
      )
  }
}

interface IRenderRepeaterProps {
  item: IRepeaterScreenItem
  actionKind?: IReportTaskStageScreen['actionKind']
}
const RenderRepeater: React.FC<IRenderRepeaterProps> = ({ item, actionKind }): JSX.Element => {
  return (
    <RepeaterRenderer
      item={item}
      messageRender={(props) => <HTMLText item={{ displayName: props.title ?? '' }} value={props.message} />}
      itemsRender={(props) => <RenderItem item={props.item} actionKind={actionKind} />}
    />
  )
}
