import React from 'react'

import { createStyles, Paper, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { IsaAnswerResolution } from './index'

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(1),
      position: 'relative',
      padding: theme.spacing(2, 8, 2, 3)
      // borderLeft: ({ resolutionColor }) => `4px solid ${resolutionColor}`
    },
    title: {
      fontSize: 14,
      lineHeight: '24px'
    },
    info: {
      marginTop: theme.spacing(2),
      display: 'flex'
    },
    commentWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      borderLeft: '1px solid rgba(33, 33, 33, 0.08)'
    },
    comment: {
      fontSize: 14,
      lineHeight: '24px',
      fontWeight: 500
    },
    imageButton: {
      position: 'absolute',
      top: 8,
      right: 8
    }
  })
)

interface IIsaResultItemProps {
  title: string
  // result: boolean | null
  result: string
  comment?: string
  hasImage?: boolean
}

interface IStylesProps {
  resolutionColor?: string
}

const IsaResultItem: React.FC<IIsaResultItemProps> = (props) => {
  // const resolution = props.result ?? undefined
  // const resolutionText = props.result == null ? 'N/A' : undefined
  // const resolutionColor = resolutionText ? '#98999B' : resolution ? '#538928' : '#B80718'
  // const classes = useStyles({ resolutionColor })

  const classes = useStyles({})

  return (
    <Paper square elevation={3} className={classes.root}>
      {/*{props.hasImage && (*/}
      {/*  <IconButton color='primary' aria-label='image' className={classes.imageButton}>*/}
      {/*    <ImageIcon />*/}
      {/*  </IconButton>*/}
      {/*)}*/}
      <Typography>
        <span className={classes.title}>{props.title}</span>
      </Typography>
      <div className={classes.info}>
        <IsaAnswerResolution text={props.result} />
        {props.comment && (
          <div className={classes.commentWrapper}>
            <Typography className={classes.comment}>{props.comment}</Typography>
          </div>
        )}
      </div>
    </Paper>
  )
}

export default IsaResultItem
