/* eslint-disable  @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from 'axios'
import { addSeconds, differenceInMilliseconds, isBefore } from 'date-fns'
import jwtDecode, { JwtPayload } from 'jwt-decode'

//import { delay } from '../../utils/delay'
import { EventEmitter, IEvent } from '../../utils/event'
import { getClientId } from '../client-id'
import { LogManager } from '../logger'
import IAuthService, {
  AuthError,
  AuthErrorCode,
  IConfirm,
  ICredentials,
  ISessionInfo, UserName
} from './auth-service-api'

interface IAuthSession {
  userName: UserName
  accessToken: string
  idToken?: string
  expiryTime: number
}

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
function generateChallengeCode(length = 44): string {
  let result = ''
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

const LOGIN_RETURN_ROUTE = '/login-result'
const LOGOUT_RETURN_ROUTE = '/login'

const CODE_VERIFIER_KEY = 'auth-code-verifier'

export interface AuthServiceSettings {
  method?: 'Default' | 'Basic' | 'Header'
  /* Базовый URL API */
  apiUrl: string
  /* OIDC authorize endpoint */
  authorizeUrl?: string
  /* OIDC token endpoint */
  tokenUrl?: string
  /* OIDC end session endpoint */
  logoutUrl?: string
  /* Идентификатор клиента */
  clientId?: string
  /* Секрет клиента */
  clientSecret?: string
  /* Scope для запроса кода авторизации */
  scope?: string
  /* Имя claim'а jwt-токена, из которого брать логин пользователя */
  loginClaim?: string
  /* Название заголовка для метода Header */
  header?: string
  /* Разрешить ли автоматический выход при истечении времени жизни токена */
  enableAutoLogout?: boolean
}

export default class AuthService implements IAuthService {
  private readonly logger = LogManager.getLogger('AuthService')

  private static readonly LoginReturnUrl = `${window.location.protocol}//${window.location.host}${LOGIN_RETURN_ROUTE}`
  private static readonly LogoutReturnUrl = `${window.location.protocol}//${window.location.host}${LOGOUT_RETURN_ROUTE}`

  private readonly _settings: AuthServiceSettings
  private readonly _redirector: (url: string) => void
  private readonly _authStateProvider?: () => string
  private readonly _storageKey: string
  private readonly _clientId: string

  private _session: IAuthSession | null = null
  private _timerId: number | null = null
  private _invalidSessionLogged = false

  private readonly _onBeforeAutoLogout = new EventEmitter<IConfirm>()
  public get onBeforeAutoLogout(): IEvent<IConfirm> { return this._onBeforeAutoLogout }

  private readonly _onLoggedOut = new EventEmitter<UserName>()
  public get onLoggedOut(): IEvent<UserName> { return this._onLoggedOut }

  private readonly _onLoggedIn = new EventEmitter<UserName>()
  public get onLoggedIn(): IEvent<UserName> { return this._onLoggedIn }

  readonly requireCredentials: boolean = false

  private readonly _method: 'Code' | 'Password' | 'Basic' | 'Header' = 'Code'

  constructor(
    settings: AuthServiceSettings,
    redirector?: (url: string) => void,
    authStateProvider?: () => string,
  ) {
    this._settings = settings
    this._redirector = redirector ?? window.location.assign
    this._authStateProvider = authStateProvider

    // detect auth method
    switch (settings.method) {
      case 'Header':
        if (!this._settings.header) {
          throw new Error('Header method requires "header" setting specified')
        }
        this._method = 'Header'
        this.requireCredentials = true
        break
      case 'Basic':
        this._method = 'Basic'
        this.requireCredentials = true
        break
      case 'Default':
        if (!this._settings.tokenUrl || !this._settings.clientId) {
          throw new Error('Default method requires "tokenUrl" and "clientId" settings specified')
        }
        if (this._settings.authorizeUrl == null) {
          this._method = 'Password'
          this.requireCredentials = true
        }
        if (this._settings.logoutUrl == null) {
          this.logger.warn('ctor', 'Logout url not specified')
        }
        break
    }

    this._clientId = getClientId()

    this.logger.info('ctor', 'Authorization method: ' + this._method)

    this._storageKey = '__auth.' + this._method

    const auth = this._getCurrentAuthSession()
    this._resetExpiryTimer(auth)
    this._enrichLogContext(auth)
  }

  private _resetExpiryTimer(auth: IAuthSession | null = null): void {
    if (!this._settings.enableAutoLogout) {
      return
    }
    if (this._timerId != null) {
      window.clearTimeout(this._timerId)
      this._timerId = null
    }
    if (auth) {
      const expiryTimeout = differenceInMilliseconds(auth.expiryTime, Date.now())
      if (expiryTimeout >= 0) {
        this._timerId = window.setTimeout(() => this._onExpired(), expiryTimeout)
      }
    }
  }

  private _onExpired(): void {
    if (this._onBeforeAutoLogout.hasHandlers) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this
      this._onBeforeAutoLogout.emit({
        confirm() {
          void self.logout()
        }
      })
    } else {
      void this.logout()
    }
  }

  public get currentUserName(): string | undefined {
    const auth = this._getCurrentAuthSession()
    return auth?.userName
  }

  public get currentSession(): ISessionInfo | undefined {
    const auth = this._getCurrentAuthSession()
    if (auth) {
      return {
        userName: auth.userName,
        expiryTime: new Date(auth.expiryTime),
        isValid: AuthService._isValid(auth)
      }
    }
  }

  public configureAxiosRequest(config: AxiosRequestConfig): void {
    const auth = this._getCurrentAuthSession()
    if (auth) {
      config.headers = {
        ...config.headers,
        ...this._buildAuthorizationHeaders(auth.accessToken)
      }
    }
  }

  private static _isValid(auth: IAuthSession): boolean {
    return isBefore(Date.now(), auth.expiryTime)
  }

  private _enrichLogContext(auth: IAuthSession | null): void {
    LogManager.setContextItem('userName', auth?.userName)
  }

  private _getCurrentAuthSession(): IAuthSession | null {
    let auth = this._session
    if (!auth) {
      const authJson = window.localStorage.getItem(this._storageKey)
      if (authJson) {
        auth = JSON.parse(authJson) as IAuthSession
        this._session = auth
      }
    }
    if (auth) {
      if (AuthService._isValid(auth)) {
        this._invalidSessionLogged = false
        return auth
      }
      if (!this._invalidSessionLogged) {
        this.logger.warn('_getCurrentAuthSession', 'Invalid (expired) authentication session')
        this._invalidSessionLogged = true
      } // else {
      // do not log subsequent messages
      // to prevent logger stuck in loop log-send-log-send-...
      // }
      //TODO: decide what to do when token lifetime expires
      //this._setCurrentAuthSession(null)
      return auth
    }
    this._invalidSessionLogged = false
    return null
  }

  private _setCurrentAuthSession(auth: IAuthSession | null): boolean {
    if (auth) {
      if (AuthService._isValid(auth)) {
        this.logger.info(
          '_setCurrentAuthSession',
          `${this._session ? 'Updating' : 'Setting'} current auth session`,
          auth
        )
        window.localStorage.setItem(this._storageKey, JSON.stringify(auth))
        this._session = auth
        this._resetExpiryTimer(auth)
        this._enrichLogContext(auth)
        this._onLoggedIn.emit(auth.userName)
        return true
      } else {
        this.logger.warn('_setCurrentAuthSession', 'Invalid authentication session')
      }
    }

    this._resetExpiryTimer()
    this._enrichLogContext(null)
    window.localStorage.removeItem(this._storageKey)

    if (this._session) {
      this.logger.info('_setCurrentAuthSession', 'Removing current auth session')
      const userName = this._session.userName
      this._session = null
      this._onLoggedOut.emit(userName)
    }
    return false
  }

  private _buildAuthorizationHeaders(token: string): { [key: string]: string } {
    switch (this._method) {
      case 'Basic':
        return { Authorization: 'Basic ' + token }
      case 'Header':
        return { [this._settings.header!]: token }
      default:
        return { Authorization: 'Bearer ' + token }
    }
  }

  private async _requestToken(credentials: ICredentials): Promise<any> {
    switch (this._method) {
      case 'Basic':
        return {
          access_token: window.btoa(credentials.login + ':' + credentials.password),
          expires_in: 24 * 60 * 60 // seconds
        }
      case 'Header':
        return {
          access_token: credentials.login,
          expires_in: 24 * 60 * 60 // seconds
        }
    }

    const params = new URLSearchParams()
    params.append('client_id', this._settings.clientId!)
    if (this._settings.clientSecret) {
      params.append('client_secret', this._settings.clientSecret)
    }
    if (this._method === 'Password') {
      // oidc password grant type
      params.append('grant_type', 'password')
      params.append('username', credentials.login)
      params.append('password', credentials.password)
    } else if (this._method === 'Code') {
      // oidc authorization_code grant type
      params.append('grant_type', 'authorization_code')
      params.append('redirect_uri', AuthService.LoginReturnUrl)
      params.append('code', credentials.login)
      params.append('code_verifier', credentials.password)
    }
    try {
      const { data } = await axios.post(this._settings.tokenUrl!, params)
      return data
    } catch (error: any) {
      this.logger.error('_requestToken', 'Failed getting token', error, error?.response?.data)
      let code = AuthErrorCode.GetTokenUnavailableError
      const message = 'При запросе токена произошла ошибка'
      let status
      if (error.response) {
        status = error.response.status
        if (status === 401) {
          code = AuthErrorCode.GetToken401Error
        } else if (status === 403) {
          code = AuthErrorCode.GetToken403Error
        } else if (status === 400) {
          code = AuthErrorCode.GetTokenBusinessError
          if (error.response.data?.error === 'invalid_client') {
            code = AuthErrorCode.InvalidClient
          } else if (error.response.data?.error_description === 'invalid_username_or_password') {
            code = AuthErrorCode.InvalidUserNameOrPassword
          }
        } else {
          code = AuthErrorCode.GetTokenOtherError
        }
      }

      throw new AuthError(code, message, status, error)
    }
  }

  private static readonly CodeVerifierStorage = window.localStorage

  private _setupCodeVerifier(): string {
    const challenge = generateChallengeCode(44)
    AuthService.CodeVerifierStorage.setItem(CODE_VERIFIER_KEY, challenge)
    return challenge
  }

  private _getCodeVerifier(): string | null {
    return AuthService.CodeVerifierStorage.getItem(CODE_VERIFIER_KEY)
  }

  private _clearCodeVerifier(): void {
    AuthService.CodeVerifierStorage.removeItem(CODE_VERIFIER_KEY)
  }

  private _requestAuthorizationCode(): void {
    const challenge = this._setupCodeVerifier()
    const url = this._settings.authorizeUrl!;
    let authorizeUrl =
      `${url}` + (url.endsWith('&') ? '' : (url.includes('?') ? '&' : '?')) +
      'response_type=code' +
      `&scope=${this._settings.scope ?? 'openid'}` +
      `&client_id=${this._settings.clientId}` +
      (this._settings.clientSecret ? `&client_secret=${this._settings.clientSecret}` : '') +
      `&redirect_uri=${encodeURIComponent(AuthService.LoginReturnUrl)}` +
      `&code_challenge=${encodeURIComponent(challenge)}` +
      '&code_challenge_method=plain'

    const state = this._authStateProvider?.()
    if (state) {
      authorizeUrl += `&state=${state}`
    }

    this._redirector(authorizeUrl)
  }

  async login(credentials?: ICredentials): Promise<ISessionInfo | undefined> {
    if (credentials == null) {
      if (this._method === 'Code') {
        this.logger.debug('login', 'Requesting authorization code...')
        this._requestAuthorizationCode()
        return
      } else {
        throw new Error('Current authorization method requires user credentials')
      }
    } else {
      if (this._method === 'Code') {
        this.logger.debug('login', `authorization_code=${credentials.login}`)
        if (!credentials.login) {
          throw new Error('Current authorization method requires authorization code')
        }
        const verifier = this._getCodeVerifier()
        if (!verifier) {
          this.logger.error('login', 'Verification code not found')
          throw new AuthError(AuthErrorCode.Strange, 'Verification code not found')
        }
        credentials.password = verifier
        this._clearCodeVerifier()
      }
    }

    const {
      id_token: idToken,
      access_token: accessToken,
      expires_in: expiresIn
    } = await this._requestToken(credentials)

    if (!accessToken) {
      throw new Error('Access token not found')
    }

    let nbf: number | undefined /* not-valid-before */
    let login: string | undefined
    if (!login && accessToken) {
      try {
        const token = jwtDecode<JwtPayload>(accessToken) as any
        if (this._settings.loginClaim)
          login = token[this._settings.loginClaim]
        if (!login)
          login = token.sub
        nbf = token.nbf
      } catch (e) {
        this.logger.warn('login', 'Error decoding access_token', e)
      }
    }
    if (!login && idToken) {
      try {
        const token = jwtDecode<JwtPayload>(idToken) as any
        login = token.upn
      } catch (e) {
        this.logger.warn('login', 'Error decoding id_token', e)
      }
    }

    if (nbf) {
      const interval = nbf * 1000 - Date.now()
      if (interval > 0) {
        console.warn("accessToken.nbf = ", new Date(nbf*1000))
        // awaiting token to become valid (should be handled on server via ClockSkew)
        /*
        if (interval > 1000) {
          this.logger.warn('login', `Waiting ${interval}ms for access token to become valid`)
        }
        await delay(interval)
        */
      }
    }

    const userName = login ?? credentials.login
    /*
    const x = userName.indexOf('@')
    if (x > 0) {
      userName = userName.substring(0, x)
    }
    */
    const newAuthSession: IAuthSession = {
      userName: userName,
      idToken: idToken,
      accessToken: accessToken,
      expiryTime: addSeconds(Date.now(), expiresIn).getTime()
    }

    this._enrichLogContext(newAuthSession)

    if (!this._setCurrentAuthSession(newAuthSession)) {
      throw new AuthError(AuthErrorCode.Strange, 'Authentication invalidated')
    }

    return this.currentSession
  }

  async logout(): Promise<boolean> {
    const auth = this._getCurrentAuthSession()
    if (!auth) {
      this.logger.debug('logout', 'No current auth session')
      return false
    }

    this.logger.info('logout', `Logging out user '${auth.userName}'`)
    const idToken = auth.idToken

    this.logoff()

    if (this._method === 'Code' || this._method === 'Password') {
      if (this._settings.logoutUrl) {
        const logoutLink =
          `${this._settings.logoutUrl}` + (this._settings.logoutUrl.endsWith('&') ? '' : (this._settings.logoutUrl.includes('?') ? '&' : '?'))
          + `post_logout_redirect_uri=${AuthService.LogoutReturnUrl}`
          + (this._settings.clientId ? `&client_id=${this._settings.clientId}` : '')
          + (idToken ? `&id_token_hint=${idToken}` : '')
        this._redirector(logoutLink)
      }
    }
    return true
  }

  logoff(): void {
    this._setCurrentAuthSession(null)
  }

  dispose(): void {
    this._resetExpiryTimer()
    this._enrichLogContext(null)
    this._onLoggedIn.dispose()
    this._onLoggedOut.dispose()
    this._onBeforeAutoLogout.dispose()
    this._session = null
  }
}
