import create from 'zustand'

interface IProgramsFiltersState {
  query: string
  status: string | undefined
  setQuery: (value: string) => void
  setStatus: (status: string) => void
}

export const useProgramsFilters = create<IProgramsFiltersState>((setStore) => ({
  query: '',
  status: 'Действующая',
  setQuery: (value) => {
    setStore({ query: value })
  },
  setStatus: (status) => {
    setStore({ status: status })
  },
}))
