import { InjectionToken } from '../infrastructure/di'
import type { IHttpClient } from '../infrastructure/http-client-factory'
import type { AppMenu } from '../model/menu-item'

export class AppMenusService {
  constructor(private readonly _http: IHttpClient) {}

  async appMenusGet(): Promise<AppMenu> {
    const res = await this._http.request({
      method: 'GET',
      url: 'app-menus/get',
    })

    return res.data
  }

  async appMenusUpdate(data: AppMenu): Promise<void> {
    const res = await this._http.request({
      method: 'POST',
      url: 'app-menus/update',
      data,
    })

    return res.data
  }
}

export interface IAppMenusService extends AppMenusService {}

export const APP_MENUS_SERVICE_API = new InjectionToken<IAppMenusService>('app-menus-service-api')
