/* eslint-disable react/no-children-prop */ // keep outlet as children in a single line
import React, { Suspense, useContext, useEffect } from 'react'

import { Outlet, Route, Routes } from 'react-router-dom'

import { EmptyMessage } from '../components'
import { PageSkeleton } from '../components/page-skeleton'
import { DbUploadPage } from '../features/admin/data-loader/db-upload/db-upload-page'
import { CustomAppFeature } from '../features/custom-app-feature/custom-app-feature'
import { DteTaskPage } from '../features/dte/dte-task-page'
import { ProcessAppFeature } from '../features/process-app-feature/process-app-feature'
import { RequireSync } from '../features/require-sync/require-sync'
import { SyncPageAuto } from '../features/sync-page'
import { Layout } from '../layout'
import { MenuContext } from '../layout/menu/menu-context'
import { AuthRoute } from '../providers'

interface DynamicRouteListProps {
  children?: never
}

export const DynamicRouteList: React.FC<DynamicRouteListProps> = (props) => {
  const { permittedMenuItems = [] } = useContext(MenuContext)

  useEffect(() => {
    console.log('DynamicRouteList items', permittedMenuItems)
  }, [permittedMenuItems])

  return (
    <Suspense fallback={<PageSkeleton />}>
      <AuthRoute>
        <Routes>
          <Route path='/sync-auto' element={<SyncPageAuto />} />
          <Route path='/data-loader/db-upload-tmp' element={<DbUploadPage />} />
          <Route
            // TODO: move RequireSync somewhere, possibly inside of a feature
            element={<RequireSync children={<Outlet />} />}
          >
            <Route path={'/dte-tasks/dte-task/:taskCode/*'} element={<DteTaskPage />} />
            <Route path={'/dte-task/:taskCode/*'} element={<DteTaskPage />} />
            {permittedMenuItems.length === 0 ? (
              // no menu fallback
              <Route path='*' element={<Layout />} />
            ) : (
              <Route path='*' element={<Layout children={<EmptyMessage message='404' />} />} />
            )}
            {permittedMenuItems.map((item) => {
              const feature = item.action.feature
              switch (feature.$type) {
                case 'PMI.FACE.WebApi.Dto.ProcessAppFeatureDTO':
                  return (
                    <Route
                      key={feature.routePath}
                      path={`/${feature.routePath}/*`}
                      element={<ProcessAppFeature feature={feature} />}
                    />
                  )
                case 'PMI.FACE.BDDM.Extensions.Classes.CustomAppFeature':
                  return (
                    <Route
                      key={feature.routePath}
                      path={`/${feature.routePath}/*`}
                      element={<CustomAppFeature feature={feature} />}
                    />
                  )
                default:
                  return <></>
              }
            })}
          </Route>
        </Routes>
      </AuthRoute>
    </Suspense>
  )
}
