import React, { useContext, useEffect } from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  createStyles,
  Typography,
} from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { useTranslation } from 'react-i18next'
import { useAsyncFn } from 'react-use'

import { LogManager } from '../../infrastructure/logger'
import { IProblemUnit } from '../../model/problem-unit'
import { ITaskUnit } from '../../model/task-unit'
import { IVisitUnit } from '../../model/visit-unit'
import { ApiContext, ProfileContext, SyncContext } from '../../providers'
import { appToast } from '../../utils'
import { ItemCard } from '../tasks/template-tasks/composite-screen/item-card'
import { useTaskList } from '../visits/visit-view-page/useTaskList'
import SyncPageDeleteBtn from './sync-page-delete-btn'
import { filterEntityWithSyncError } from './utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    visit: {
      fontSize: theme.typography.h4.fontSize,
      lineHeight: theme.typography.h4.lineHeight,
      fontWeight: 600,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    accordion: {
      '& .MuiPaper-elevation1': {
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
      '& .MuiAccordion-root': {
        marginBottom: 0,
        paddingBottom: 16,
        '&:last-child': {
          marginBottom: 0,
          // paddingBottom: 0,
          '& .MuiBox-root': {
            marginBottom: 0,
          },
        },
      },
      '& .MuiAccordion-root:before': {
        display: 'none',
      },
      '& .MuiAccordionSummary-root': {
        justifyContent: 'flex-start',
        height: '24px !important',
        minHeight: '0 !important',
        padding: 0,
        marginLeft: 10,
      },
      '& .MuiAccordionSummary-content.Mui-expanded, & .MuiAccordion-root.Mui-expanded': {
        margin: 0,
        order: 2,
      },
      '& .MuiAccordionSummary-content': {
        width: '100%',
        margin: 0,
        flexGrow: 0,
        order: 2,
        marginLeft: '24px !important',
        overflow: 'hidden',
        [theme.breakpoints.down('xs')]: {
          marginLeft: '15px !important',
        },
      },
      '& .MuiAccordionDetails-root': {
        width: '100%',
        padding: 0,
      },

      '& .MuiAccordionSummary-expandIcon': {
        order: 1,
        padding: '0 !important',
        transform: 'rotate(90deg)',
        marginLeft: -10,
      },
      '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
        transform: 'rotate(180deg)',
      },
    },
    visitList: {
      width: '100%',
      paddingLeft: 36,
      flexGrow: 1,
      // '&:last-child': {
      //   marginBottom: 16,
      // },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 28,
        // '&:last-child': {
        //   marginBottom: 16,
        // },
      },
    },
    visitWrap: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    visitItem: {
      display: 'flex',
      flexWrap: 'nowrap',
      width: '100%',
      justifyContent: 'space-between',
      marginBottom: '16px !important',
      alignItems: 'flex-start',
      '&:last-child': {
        marginBottom: 0,
      },
      '&:first-child': {
        marginTop: 16,
      },
    },
    errorItem: {
      display: 'flex',
      flexWrap: 'nowrap',
      width: '100%',
      justifyContent: 'space-between',
      marginBottom: 16,
      alignItems: 'flex-start',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    item: {
      fontSize: theme.typography.h4.fontSize,
      lineHeight: theme.typography.h4.lineHeight,
      fontWeight: 400,
      maxWidth: 650,
      marginRight: 20,
    },
    btnWrap: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    loadingWrap: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
)

interface ISyncErrorList {
  retrySyncData: () => void
}

const logger = LogManager.getLogger('SyncErrorList')

export const SyncErrorList: React.FC<ISyncErrorList> = ({ retrySyncData }) => {
  const classes = useStyles()
  const { t } = useTranslation('sync')
  const sync = useContext(SyncContext)
  const api = useContext(ApiContext)
  const profile = useContext(ProfileContext)
  const { getTaskList } = useTaskList([profile])

  const [errorsListOps, runErrorList] = useAsyncFn(
    async () => {
      const { visits, tasks, problems } = await sync.syncService.getPendingItems()

      const visitUnits: IVisitUnit[] = []
      for (const visit of filterEntityWithSyncError(visits)) {
        const { visits: visitTasks } = await getTaskList(visit.code)
        visitUnits.push({ visit, tasks: visitTasks })
      }

      const taskUnits: ITaskUnit[] = filterEntityWithSyncError(tasks).map((task) => ({
        task,
      }))

      const problemUnits: IProblemUnit[] = filterEntityWithSyncError(problems).map((problem) => ({
        problem,
      }))

      return {
        visitUnits,
        taskUnits,
        problemUnits,
      }
    },
    [sync?.syncService, profile],
    { loading: true },
  )

  useEffect(() => {
    if (sync?.syncService && profile) {
      void runErrorList()
    }
  }, [sync?.syncService, profile])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sendDataDump = async (data: Record<string, any>): Promise<void> => {
    try {
      const dump = await api.dumpService.dumpData(data)
      await api.dumpService.uploadDump(dump)
    } catch (e) {
      const message = t('dumpError')
      logger.error('errorsListOps', message, e)
      appToast.error(message)
      throw e
    }
  }

  const deleteVisitHandler = async (visitUnit: IVisitUnit): Promise<void> => {
    visitUnit.surveys = await api.visits.getVisitSurveys(visitUnit.visit.code)

    await sendDataDump(visitUnit)
    console.log('Delete visit: ', visitUnit.visit.code)
    await api.visits.purgeVisit(visitUnit.visit.code)

    void retrySyncData()
    void runErrorList()
  }

  const deleteTaskHandler = async (taskUnit: ITaskUnit): Promise<void> => {
    taskUnit.surveys = await api.tasks.getTaskSurveys(taskUnit.task.code)

    await sendDataDump(taskUnit)
    console.log('Delete task: ', taskUnit.task.code)
    await api.tasks.purgeTask(taskUnit.task.code)
    void retrySyncData()
    void runErrorList()
  }

  const deleteProblemHandler = async (problemUnit: IProblemUnit): Promise<void> => {
    await sendDataDump(problemUnit)
    console.log('Delete problem: ', problemUnit.problem.code)
    await api.problem.deleteProblem(problemUnit.problem.code)
    void retrySyncData()
    void runErrorList()
  }

  const renderList = (): JSX.Element => {
    if (errorsListOps.error) {
      logger.error('errorsListOps', errorsListOps.error.message, errorsListOps.error)
      const message = t('dataReceivedError')
      appToast.error(message)
      return <Typography color='textSecondary'>{message}</Typography>
    }

    if (errorsListOps.loading && !errorsListOps.value) {
      return (
        <div className={classes.loadingWrap}>
          <CircularProgress />
        </div>
      )
    }

    const errorList = errorsListOps.value

    if (!errorList?.visitUnits.length && !errorList?.taskUnits.length && !errorList?.problemUnits.length) {
      return <Typography color='textSecondary'>{t('emptyError')}</Typography>
    }

    return (
      <>
        {errorList.visitUnits.map((visitUnit) => (
          <Box key={visitUnit.visit.code} className={classes.accordion}>
            <Accordion defaultExpanded={false}>
              <AccordionSummary expandIcon={<ArrowDropUpIcon />}>
                <Box className={classes.visitWrap}>
                  <Typography className={classes.visit}>
                    {t('visit')} ({visitUnit.visit.code})
                  </Typography>
                </Box>
                <Box className={classes.btnWrap}>
                  <SyncPageDeleteBtn
                    onOk={async () => {
                      await deleteVisitHandler(visitUnit)
                    }}
                  />
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box className={classes.visitList}>
                  {!visitUnit.tasks.length && (
                    <Box className={classes.visitItem}>
                      <Typography color='textSecondary' className={classes.item}>
                        {t('emptyTasks')}
                      </Typography>
                    </Box>
                  )}
                  {visitUnit.tasks.map((visitTask) => {
                    return (
                      <Box key={visitTask.code} className={classes.visitItem}>
                        <Typography className={classes.item}>
                          “{visitTask.name}” ({visitTask.code})
                        </Typography>
                        <SyncPageDeleteBtn
                          onOk={async () => {
                            const taskUnit = { task: visitTask }
                            await deleteTaskHandler(taskUnit)
                          }}
                        />
                      </Box>
                    )
                  })}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
        <Box>
          {errorList.taskUnits.map((taskUnit) => (
            <Box key={taskUnit.task.code} className={classes.errorItem}>
              <Typography className={classes.item}>
                “{taskUnit.task.name}” ({taskUnit.task.code})
              </Typography>
              <SyncPageDeleteBtn
                onOk={async () => {
                  await deleteTaskHandler(taskUnit)
                }}
              />
            </Box>
          ))}
          {errorList.problemUnits.map((problemUnit) => (
            <Box key={problemUnit.problem.code} className={classes.errorItem}>
              <Typography className={classes.item}>
                “{problemUnit.problem.escalationReason.name}” ({problemUnit.problem.code})
              </Typography>
              <SyncPageDeleteBtn
                onOk={async () => {
                  await deleteProblemHandler(problemUnit)
                }}
              />
            </Box>
          ))}
        </Box>
      </>
    )
  }

  return <ItemCard label={t('errorsTitle')}>{renderList()}</ItemCard>
}
