import React from 'react'

import { IconButton, SvgIcon } from '@material-ui/core'
import { useNetworkState } from 'react-use'

import { ReactComponent as ShareIcon } from '../assets/icons/share.svg'
import { appToast } from '../utils'

export const ShareButton: React.FC<{ onShare: () => void }> = ({ onShare }) => {
  const networkState = useNetworkState()
  const isOffline = !networkState.online
  if (isOffline) {
    return (
      <IconButton aria-label='share' color='inherit'>
        <SvgIcon
          component={ShareIcon}
          color='disabled'
          onClick={() => appToast.info('Отсутствует подключение к сети Интернет')}
        />
      </IconButton>
    )
  }
  return (
    <IconButton aria-label='share' color='inherit' onClick={onShare}>
      <SvgIcon component={ShareIcon} />
    </IconButton>
  )
}
