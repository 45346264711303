import React, { useContext } from 'react'

import { Box, createStyles, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useAsync } from 'react-use'

import { EmptyMessage } from '../../../../../components'
import { ApiContext } from '../../../../../providers'
import { getPosAddress } from '../../../../_common/pos'
import { ErrorPage } from '../../../../error-page'
import IsaReportListItem from './isa-report-list-item'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    emptyMessage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'rgba(0, 0, 0, 0.38)',
      fontWeight: 'normal',
      minHeight: 'calc(100vh - 290px)',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
        minHeight: 'calc(100vh - 230px)',
      },
    },
  }),
)

interface IIsaReportListProps {
  searchQuery: string
}

const IsaReportList: React.FC<IIsaReportListProps> = ({ searchQuery = '' }) => {
  const api = useContext(ApiContext)
  const classes = useStyles()
  const hasSearch = !!searchQuery.trim()
  const auditListOps = useAsync(async () => await api.audit.searchAudits({ searchQuery }), [searchQuery])

  const { t } = useTranslation('reports')

  if (auditListOps.error) {
    return <ErrorPage errorMessage={t('auditListFetchError')} />
  }

  if (!auditListOps.value) {
    if (!auditListOps.loading) {
      return <EmptyMessage message={t('auditListEmpty')} />
    }
    return <></>
  }

  const reports = auditListOps.value

  const renderEmptyMessage = (): JSX.Element => (
    <Typography className={classnames(classes.emptyMessage)} variant='h6' component='h6' align='center'>
      {hasSearch ? t('searchResultEmpty') : t('auditListEmpty')}
    </Typography>
  )

  return (
    <div className={classes.root}>
      <div>
        {!reports.length && renderEmptyMessage()}
        {reports.map((item) => (
          <Box key={item.code} mb={1}>
            <IsaReportListItem
              code={item.code}
              name={item.pointOfSale.name}
              address={getPosAddress(item.pointOfSale)}
              posCode={item.pointOfSale.code}
              date={item.endDate}
            />
          </Box>
        ))}
      </div>
    </div>
  )
}

export default IsaReportList
