export type BooleanItemValue = boolean | null | undefined

export function mapDisplayValue(value: BooleanItemValue): string | undefined {
  switch (value) {
    case true:
      return 'Да'
    case false:
      return 'Нет'
    case null:
      return 'N/A'
  }
}
