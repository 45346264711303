import React, { useContext } from 'react'

import { Box, Button } from '@material-ui/core'
import { noop } from 'lodash'
import { useNavigate } from 'react-router-dom'

import { LogManager } from '../../../../infrastructure/logger'
import { IFaceScript } from '../../../../model/face-script'
import { IScreenItemAction } from '../../../../model/screen-item-action'
import { IActionScreenItem } from '../../../../model/screen-item-base'
import { checkPredicate, IPredicate } from '../../../../model/script-predicate'
import { appToast } from '../../../../utils'
import { useLocalContextService } from '../../nested/local-context'
import { runScript } from '../../script-tasks/run-script'
import { useScriptTaskContext } from '../../script-tasks/script-task-context'
import { CompositeScreenContext } from './composite-screen-stage'

export interface IButtonScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.ButtonScreenItem'
  action: IScreenItemAction
  visible?: IPredicate
  enabled?: IPredicate
}

interface ButtonScreenItemControlProps {
  item: IButtonScreenItem
}
const ButtonScreenItemControl: React.FC<ButtonScreenItemControlProps> = ({ item }) => {
  const logger = LogManager.getLogger('ButtonScreenItemRunScript')
  const navigate = useNavigate()
  const context = useScriptTaskContext()
  const contextService = useLocalContextService()
  const screenContext = useContext(CompositeScreenContext)

  const enabled = item.enabled && !checkPredicate(item.enabled, undefined, context)

  const onClick = (): void | Promise<void> => {
    switch (item.action.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.InternalRedirectAction':
        navigate(`${item.action.redirectPath}`, { state: { returnUrl: location.pathname } })
        return

      case 'PMI.FACE.BDDM.Extensions.Classes.InlineScriptAction':
        const { scriptBody, dialect } = item.action
        screenContext.setLoading(true)
        return runScript({ body: scriptBody, dialect } as IFaceScript, context, contextService)
          .then(() => {
            console.log('runScript ok')
          })
          .catch((error: Error) => {
            const errorMsg = `При нажатии на кнопку ${item.displayName} произошла ошибка`
            appToast.error(errorMsg)
            logger.error('Run script', errorMsg, error)
          })
          .finally(() => {
            screenContext.setLoading(false)
          })

      default:
        return noop()
    }
  }

  if (item.visible && !checkPredicate(item.visible, undefined, context)) {
    return <></>
  }

  return (
    <Box mb={1} display='flex' alignItems='center'>
      <Button disabled={enabled} style={{ borderRadius: 100 }} variant='contained' color='primary' onClick={onClick}>
        {item.displayName}
      </Button>
    </Box>
  )
}

export default ButtonScreenItemControl
