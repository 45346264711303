import { IEntityReference } from '../../../../../model/base'
import { IFillSurveyScreenItem } from '../../../../../model/screen-item'
import { checkPredicate } from '../../../../../model/script-predicate'
import { getSurveysByPropertyName } from '../../../../_common/hooks/useCreateSurvey'
import { validateEsc } from '../../../../execute-survey-control/validate'
import { getAvailableQuestions } from '../../../sales-expert/tabs-new/trainee-skills/predicate-questions'
import { getContextProperty } from '../../../script-tasks/propertyName'
import { IScriptTaskContext } from '../../../script-tasks/script-task-context'
import { IValidation } from '../validate'
import { getQuestionnaireByAssignments } from './utils'

export function validateFillSurveyScreenItem(item: IFillSurveyScreenItem, context: IScriptTaskContext): IValidation {
  const isVisible = checkPredicate(
    item.visible ?? { $type: 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate', value: true },
    context.record,
    context,
  )

  if (!isVisible) {
    return { isError: false }
  }

  const isRequired = checkPredicate(
    item.required ?? { $type: 'PMI.FACE.BDDM.Extensions.Classes.ConstPredicate', value: true },
    context.record,
    context,
  )

  if (!isRequired) {
    return { isError: false }
  }

  const surveys = getSurveysByPropertyName(item.propertyName || 'task.surveys', context)
  try {
    let questionnaireCode: string | undefined
    if (item.questionnairePath && !item.questionnaire && !item.questionnaires?.length) {
      questionnaireCode = getContextProperty<IEntityReference | undefined>(context, item.questionnairePath)?.code
    } else {
      questionnaireCode = getQuestionnaireByAssignments(item, context.questionnairePosAssignments ?? []).code
    }

    const questionnaire = context.questionnaires.find((questionnaire) => questionnaire.code === questionnaireCode)
    if (!questionnaire) {
      return {
        isError: true,
        message: `Не найден опросник ${questionnaireCode}`,
      }
    }

    const survey = surveys.find((survey) => survey.questionnaire.code === questionnaireCode)
    if (!survey) {
      return {
        isError: true,
        message: 'No survey found',
      }
    }

    const answers = survey.answers ?? []

    const availableQuestions = getAvailableQuestions(questionnaire.questions, answers)
    const { message, invalidQuestion } = validateEsc(availableQuestions, answers)

    if (invalidQuestion) {
      return {
        isError: true,
        message,
      }
    }

    return {
      isError: false,
    }
  } catch (e) {
    if (e instanceof Error) {
      return {
        isError: true,
        message: e.message,
      }
    }

    return {
      isError: true,
      message: `Не найден подходящий опросник для торговой точки`,
    }
  }
}
