import React from 'react'

import { createStyles, Theme, makeStyles } from '@material-ui/core'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import CancelIcon from '@material-ui/icons/Cancel'
import DoneIcon from '@material-ui/icons/Done'
import DonutLargeIcon from '@material-ui/icons/DonutLarge'

import { VisitTaskStatus } from '../../../model/visit-task'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusIcon: {
      marginRight: theme.spacing(2)
    }
  })
)

interface ITaskStatusIconProps {
  status: VisitTaskStatus | null
}

const TaskStatusIcon: React.FC<ITaskStatusIconProps> = ({ status }) => {
  const classes = useStyles()

  if (!status) {
    return null
  }

  switch (status) {
    case 'Planned':
      return <CalendarTodayIcon style={{ color: '#7AB2DC' }} className={classes.statusIcon} />
    case 'InProgress':
      return <DonutLargeIcon style={{ color: '#EB690B' }} className={classes.statusIcon} />
    case 'Finished':
      return <DoneIcon style={{ color: '#538928' }} className={classes.statusIcon} />
    case 'Canceled':
      return <CancelIcon style={{ color: 'red' }} className={classes.statusIcon} />
    default:
      return <></>
  }
}
export default React.memo(TaskStatusIcon)
