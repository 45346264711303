import React, { createContext, useContext, useEffect, useState } from 'react'

import { useGeolocation, useOrientation } from 'react-use'
import { GeoLocationSensorState, IGeolocationPositionError } from 'react-use/lib/useGeolocation'
import { Circle, Map, Placemark, YMaps, YMapsApi } from 'react-yandex-maps'
import ymaps from 'yandex-maps'

import { LogManager } from '../../infrastructure/logger'
import { ConfigContext } from '../../providers'
import { appToast } from '../../utils'

const positionOptions: PositionOptions = {
  /**
   * integer (milliseconds) - amount of time before the error callback is invoked, if 0 it will never invoke.
   */
  timeout: 10000,
  /**
   * integer (milliseconds) | infinity - maximum cached position age.
   */
  maximumAge: 60000,
  enableHighAccuracy: true,
}

const logger = LogManager.getLogger('YandexMap')

interface IYandexMapProvider {
  onInit?: () => void
  onError?: () => void
}

export const YandexMapProvider: React.FC<IYandexMapProvider> = ({ onInit, onError, children }) => {
  const { config } = useContext(ConfigContext)
  const orientation = useOrientation()
  const geolocation = useGeolocation(positionOptions)
  const [ymapsAPI, setYmapsAPI] = useState<YMapsApi>()
  const [instanceMap, setInstanceMap] = useState<ymaps.Map>()

  const {
    loading: userGeolocationLoading,
    latitude: userLatitude,
    longitude: userLongitude,
    accuracy: userAccuracy,
    error: userGeolocationError,
  } = geolocation

  if (userLongitude && userLatitude) {
    // geoCoordinates.push([userLatitude, userLongitude])
  }

  useEffect(() => {
    if (userGeolocationError) {
      // https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
      const errorCode = (userGeolocationError as IGeolocationPositionError)?.code || undefined
      if (errorCode != null && errorCode === 1) {
        appToast.info('Не разрешено определение местоположения')
      }
      return
    }
    if (!userGeolocationLoading) {
      if (!(userLatitude && userLongitude)) {
        appToast.info('Не удалось определить местоположение')
      }
    }
  }, [geolocation])

  const setCenterUser = (): void => {
    if (userLatitude && userLongitude) {
      void instanceMap?.setCenter([userLatitude, userLongitude], 16)
    } else {
      appToast.info('Не удалось определить местоположение')
    }
  }

  const setBounds = (geoCoordinates?: number[][], lastCoords?: { center: number[]; zoom: number }): void => {
    if (!instanceMap || !ymapsAPI) {
      appToast.info('Карта еще не загружена')
      return
    }

    // Если в параметрах не передан массив координатов, то взять все объекты с карты
    const entry = geoCoordinates ? [...geoCoordinates] : instanceMap.geoObjects.getBounds() ?? []
    if (userLatitude && userLongitude) {
      entry.push([userLatitude, userLongitude])
    }
    /*
    - lastCoords - если заполнено, то отображаем этот участок карты
    - например после добавления визита, необходимо восстановить center И zoom
    */
    if (lastCoords?.center && lastCoords?.zoom) {
      void instanceMap?.setCenter(lastCoords.center, lastCoords.zoom)
    } else if (entry.length > 1) {
      void instanceMap.setBounds(ymapsAPI.util.bounds.fromPoints(entry), { checkZoomRange: true }).then(() => {
        if (instanceMap.getZoom() > 16) {
          void instanceMap.setZoom(16)
        }
      }) // вписать объекты на карте в область видимости
    } else {
      if (entry[0]) {
        void instanceMap.setCenter(entry[0], 16) // если точно одна - то отцентровать
      }
    }
  }

  const UserOnMap = (): JSX.Element => {
    if (!userGeolocationLoading && userLatitude && userLongitude) {
      return (
        <>
          <Placemark
            geometry={[userLatitude, userLongitude]}
            options={{ preset: 'islands#blueCircleDotIconWithCaption' }}
          />
          {userAccuracy && userAccuracy >= 100 && <Circle geometry={[[userLatitude, userLongitude], userAccuracy]} />}
        </>
      )
    }
    return <></>
  }

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <YMaps
        version={'2.1.79'}
        query={{
          lang: 'ru_RU',
          load: 'util.bounds',
          apikey: config.yandexMapApiKey,
        }}
      >
        {/* <YMaps query={{ apikey: 'ваш ключ' }}> */}
        <Map
          key={orientation.type}
          onLoad={(ymaps) => {
            onInit?.()
            setYmapsAPI(ymaps)
          }}
          onError={(error) => {
            onError?.()
            appToast.error('Ошибка при загрузке Яндекс карты')
            console.error(error)
            logger.error('YandexMap.onload', 'Ошибка при загрузке Яндекс карты', error.name)
            void logger.flush()
          }}
          instanceRef={(instance: React.Ref<ymaps.Map>) => {
            setInstanceMap(instance as unknown as ymaps.Map)
          }}
          defaultState={{ center: [55.75, 37.57], zoom: 13 }}
          modules={['templateLayoutFactory', 'layout.ImageWithContent', 'clusterer.addon.balloon']}
          width='100%'
          height='100%'
        >
          {ymapsAPI && instanceMap ? (
            <YandexMapContext.Provider
              value={{ ymapsAPI, instanceMap, setCenterUser, setBounds, userGeolocation: geolocation }}
            >
              {UserOnMap()}
              {children}
            </YandexMapContext.Provider>
          ) : (
            <div>Инициализация</div>
          )}
        </Map>
      </YMaps>
    </div>
  )
}

export const YandexMapContext = createContext<{
  ymapsAPI: YMapsApi
  instanceMap: ymaps.Map
  setCenterUser: () => void
  setBounds: (geoCoordinates?: number[][], lastCoords?: { center: number[]; zoom: number }) => void
  userGeolocation: GeoLocationSensorState
}>(undefined!)
