import React from 'react'

import { IconButton, SvgIcon } from '@material-ui/core'
import classNames, { Argument as ClassName } from 'classnames'

import { ReactComponent as ImageBox } from '../../assets/icons/img_box.svg'
import { Code } from '../../model/base'
import { ReactComponent as CameraIcon } from './Camera_icon.svg'

interface Props {
  code: Code
  onClick: (code: Code) => void
  isPhotoError?: boolean
  isPhotoAdded?: boolean
  className?: ClassName
}

const SUCCESS_COLOR = '#388E3C'

export const MakePhotoButton: React.FC<Props> = ({
  className,
  code,
  onClick,
  isPhotoError = false,
  isPhotoAdded = false,
}) => {
  return (
    <IconButton className={classNames(className)} size='small' onClick={() => onClick(code)}>
      <SvgIcon
        color={isPhotoError ? 'error' : isPhotoAdded ? undefined : 'primary'}
        htmlColor={isPhotoAdded ? SUCCESS_COLOR : undefined}
        component={CameraIcon}
      />
    </IconButton>
  )
}

export const ViewPhotoButton: React.FC<Props> = ({ className, code, onClick }) => {
  return (
    <IconButton color='primary' className={classNames(className)} size='small' onClick={() => onClick(code)}>
      <SvgIcon component={ImageBox} />
    </IconButton>
  )
}
