import { IBusinessParameters } from '../../model/business-parameters'
import { BusinessError } from '../../model/errors'
import { MenuItemDTO } from '../../model/menu-item'
import { IPositionRoleReference } from '../../model/user'
import { IUserGroup } from '../../model/user-group'
import { IProfile, IUserProfile } from '../../model/user-profile'

export enum UserProfileErrorSource {
  GetAvailableProfiles,
  GetBusinessSettings,
  GetMenuItems,
  GetUserGroups,
}
export enum UserProfileErrorCode {
  UserUnauthorised,
  AccessDenied,
  BusinessError,
  UpgradeRequired,
  MaintenanceInProgress,
  ServiceError,
  ServiceUnavailable,
}

export class UserProfileError extends BusinessError<UserProfileErrorCode> {
  name = 'UserProfileError'

  readonly source: UserProfileErrorSource
  readonly httpStatus?: number
  readonly businessErrorType?: string
  readonly businessErrorTitle?: string

  constructor(
    source: UserProfileErrorSource,
    code: UserProfileErrorCode,
    message: string,
    httpStatus?: number,
    businessErrorType?: string,
    businessErrorTitle?: string,
    details?: unknown,
  ) {
    super(code, message, details)
    this.source = source
    this.httpStatus = httpStatus
    this.businessErrorType = businessErrorType
    this.businessErrorTitle = businessErrorTitle
  }
}

export interface IUserProfileService {
  fetchAvailableProfiles: () => Promise<IUserProfile[]>

  getCurrentProfile: () => Promise<IUserProfile | null>

  setCurrentProfile: (profile: IUserProfile | null) => Promise<void>

  getLastSyncAttempt: () => Promise<Date | null>

  setLastSyncAttempt: (timestamp: Date) => Promise<void>

  getLastSyncSuccess: () => Promise<Date | null>

  setLastSyncSuccess: (timestamp: Date) => Promise<void>

  fetchBusinessParameters: () => Promise<void>

  getBusinessParameters: () => Promise<IBusinessParameters>

  getAllUserProfiles: () => Promise<IProfile[]>

  getCurrentFieldPositionRoleReference: () => Promise<IPositionRoleReference | undefined>

  fetchMenuItems: () => Promise<void>

  getMenuItems: () => Promise<MenuItemDTO[] | null>

  fetchUserGroups: () => Promise<void>

  getUserGroups: () => Promise<IUserGroup[] | null>
}
