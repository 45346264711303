import React, { useContext, useEffect } from 'react'

import { Route, Routes, useNavigate } from 'react-router-dom'

import { ProcessAppFeatureDTO } from '../../model/app-feature'
import { ProfileContext } from '../../providers'
import { ProcessAppPage } from './process-app-page'

interface ProcessAppFeatureProps {
  children?: never
  feature: ProcessAppFeatureDTO
}

export const ProcessAppFeature: React.FC<ProcessAppFeatureProps> = (props) => {
  const { feature } = props
  const profileContext = useContext(ProfileContext)
  useEffect(() => {
    console.log('ProcessAppFeature mount', feature)
    void profileContext.refetchUserGroups()
  }, [feature.routePath])

  const navigate = useNavigate()
  const backToMain = (): void => navigate('')

  return (
    <Routes>
      <Route path='*' element={<ProcessAppPage key={feature.routePath} feature={feature} page={feature.mainPage} />} />
      {feature.pages?.map((appPage) => (
        <Route
          key={appPage.routePath}
          path={`${appPage.routePath}/*`}
          element={<ProcessAppPage key={appPage.routePath} feature={feature} page={appPage} onBack={backToMain} />}
        />
      ))}
    </Routes>
  )
}
