import z from 'zod'

export const Version = z.object({
  $type: z.literal('PMI.BDDM.Common.BusinessEntityVersion').optional(),
  code: z.string().min(1),
  startDate: z.number(),
  endDate: z.number().optional(),
})

export function CreateEntityReference<Type extends string>(type: Type) {
  return z.object({
    $type: z.literal(type),
    code: z.string().min(1),
    codeSpace: z.string().min(1),
    name: z.string().optional(),
  })
}

export function CreateVersionedEntityReference<Type extends string>(type: Type) {
  return CreateEntityReference(type).extend({
    version: Version,
  })
}

const AnyEntityReference = z.object({
  $type: z.string().min(1),
  code: z.string().min(1),
  codeSpace: z.string().min(1),
  name: z.string().optional(),
})

const VersionedAnyEntityReference = AnyEntityReference.extend({
  version: Version,
})

export const FaceScriptReference = CreateEntityReference('PMI.FACE.BDDM.Extensions.Classes.FaceScriptReference')

export const ScriptDialect = z.enum(['JavaScript'])

export const KeyValuePair = z.object({
  $type: z.literal('PMI.FACE.BDDM.Extensions.Classes.KeyValuePair').optional(),
  key: z.string().min(1),
  value: z.string().min(1),
})

export const KeyValuePairsDataSource = z.object({
  $type: z.literal('PMI.FACE.BDDM.Extensions.Classes.KeyValuePairsDataSource'),
  items: z.array(KeyValuePair),
})

export const ContextPropertyDataSource = z.object({
  $type: z.literal('PMI.FACE.BDDM.Extensions.Classes.ContextPropertyDataSource'),
  propertyName: z.string().min(1),
})

export const DictionaryReferenceDataSource = z.object({
  $type: z.literal('PMI.FACE.BDDM.Extensions.Classes.DictionaryReferenceDataSource'),
  dictionary: VersionedAnyEntityReference,
})

export const PropertyKeyValuePairsDataSource = z.object({
  $type: z.literal('PMI.FACE.BDDM.Extensions.Classes.PropertyKeyValuePairsDataSource'),
  propertyName: z.string().min(1),
})

export const DataSource = z.discriminatedUnion('$type', [
  KeyValuePairsDataSource,
  ContextPropertyDataSource,
  DictionaryReferenceDataSource,
  PropertyKeyValuePairsDataSource,
])

export const DictionaryItemsDataSource = z.discriminatedUnion('$type', [
  ContextPropertyDataSource,
  DictionaryReferenceDataSource,
])

export const Predicate = z.discriminatedUnion('$type', [
  z.object({
    $type: z.literal('PMI.FACE.BDDM.Extensions.Classes.ConstPredicate'),
    value: z.boolean(),
  }),
  z.object({
    $type: z.literal('PMI.FACE.BDDM.Extensions.Classes.ScriptPredicate'),
    script: FaceScriptReference,
  }),
  z.object({
    $type: z.literal('PMI.FACE.BDDM.Extensions.Classes.InlineScriptPredicate'),
    dialect: ScriptDialect,
    scriptBody: z.string().min(1),
  }),
])

const ConstStringValue = z.object({
  $type: z.literal('PMI.FACE.BDDM.Extensions.Classes.ConstStringValue'),
  value: z.string(),
})

const ScriptStringValue = z.object({
  $type: z.literal('PMI.FACE.BDDM.Extensions.Classes.ScriptStringValue'),
  script: FaceScriptReference,
})

const InlineScriptStringValue = z.object({
  $type: z.literal('PMI.FACE.BDDM.Extensions.Classes.InlineScriptStringValue'),
  dialect: ScriptDialect,
  scriptBody: z.string().min(1),
})

// const StringValueSwitch = z
//   .object({
//     $type: z.literal('PMI.FACE.BDDM.Extensions.Classes.StringValueSwitch'),
//   })
//   .passthrough()

export const StringValue = z.discriminatedUnion('$type', [
  ConstStringValue,
  ScriptStringValue,
  InlineScriptStringValue,
  // TODO StringValueSwitch
])

// TODO discriminatedUnion
export const EventHandler = z.object({
  $type: z.literal('PMI.FACE.BDDM.Extensions.Classes.ScriptEventHandler'),
  script: FaceScriptReference,
})

export const SubprocessSettings = z.object({
  $type: z.literal('PMI.FACE.BDDM.Extensions.Classes.SubprocessSettings').optional(),
  process: CreateEntityReference('PMI.FACE.BDDM.Extensions.Classes.FieldForceTaskExecutionProcessReference'),
  availabilityCondition: Predicate.optional(),
  defaultProcessCondition: Predicate.optional(),
  menuActionName: z.string(),
  displayNameFormat: z.string().optional(),
  finishActionName: z.string(),
  processStartConfirmationText: z.string().optional(),
})

export const CompositeScreenItemLayout = z.object({
  $type: z.literal('PMI.FACE.BDDM.Extensions.Classes.CompositeScreenItemLayout'),
  width: z.number(),
  horizontalAlignment: z.enum(['Left', 'Center', 'Right']),
})

export const BddmType = z.object({
  typeNames: z.string().array().min(1),
  isArray: z.boolean(),
  isOptional: z.boolean(),
})

export const InlineScriptPredicateFunction = z.object({
  $type: z.literal('PMI.FACE.BDDM.Extensions.Classes.InlineScriptPredicateFunction'),
  argumentName: z.string().min(1),
  body: z.object({
    $type: z.literal('PMI.FACE.BDDM.Extensions.Classes.InlineScriptPredicate'),
    dialect: ScriptDialect,
    scriptBody: z.string().min(1),
  }),
})
