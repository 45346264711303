import { IRefreshAuditedVisitsButtonScreenItem } from '../features/tasks/audit/refresh-audited-visits-button'
import { ServiceAuditResultsScreenItem } from '../features/tasks/audit/service-audit-results-screen-item'
import { IServiceAuditStatisticsScreenItem } from '../features/tasks/audit/service-audit-statistics-screen-item'
import { IBooleanInputScreenItem } from '../features/tasks/template-tasks/composite-screen/boolean/boolean-input-screen-item'
import { IBooleanViewScreenItem } from '../features/tasks/template-tasks/composite-screen/boolean/boolean-view-screen-item'
import { IButtonScreenItem } from '../features/tasks/template-tasks/composite-screen/button-screen-item'
import { IContentDocumentSelectorScreenItem } from '../features/tasks/template-tasks/composite-screen/content-document-selector-screen-item'
import { IContentDocumentPopupViewScreenItem } from '../features/tasks/template-tasks/composite-screen/content-document-view-screen-item/content-document-popup-view-screen-item'
import { IContentDocumentViewScreenItem } from '../features/tasks/template-tasks/composite-screen/content-document-view-screen-item/content-document-view-screen-item'
import { IDateInputScreenItem } from '../features/tasks/template-tasks/composite-screen/date-input-screen-item'
import { IFlexContainerScreenItem } from '../features/tasks/template-tasks/composite-screen/flex-container-screen-item'
import { IPopupRichtextScreenItem } from '../features/tasks/template-tasks/composite-screen/popup-rich-text-screen-item'
import {
  IPredicateEditScreenItem,
  IPredicateViewScreenItem,
} from '../features/tasks/template-tasks/composite-screen/predicate-items/types'
import { IProblemsListScreenItem } from '../features/tasks/template-tasks/composite-screen/problem-list/problems-list-screen-item'
import { IPropertyListScreenItem } from '../features/tasks/template-tasks/composite-screen/property-list-item/property-list-screen-item'
import { IRangeDateInputScreenItem } from '../features/tasks/template-tasks/composite-screen/range-date-item/range-date-input-screen-item'
import { ISelectTaskTemplateScreenItem } from '../features/tasks/template-tasks/composite-screen/select-task-template-screen-item'
import { ITextScreenItem } from '../features/tasks/template-tasks/composite-screen/text-screen-item'
import { IViewRichTextListScreenItem } from '../features/tasks/template-tasks/composite-screen/view-rich-text-list-screen-item'
import { IViewRichTextScreenItem } from '../features/tasks/template-tasks/composite-screen/view-rich-text-screen-item'
import { Code, IEntityReference } from './base'
import { IBddmTypeReference } from './bddm-type-reference'
import { ICheckOutUnitsScreenItem } from './check-out-units-screen-item'
import { IConditionLists } from './condition-lists'
import { IIFrameScreenItem } from './iframe-screen-item'
import { IQuestionableListScreenItem } from './questionable-list-screen-item'
import { IQuestionnaireReference } from './questionnaire'
import { IRepeaterScreenItem } from './repeater-screen-item'
import { IActionScreenItem, IScreenItemBase } from './screen-item-base'
import { InlinePredicate, IPredicate } from './script-predicate'
import { IDictionaryItemsDataSource, ITableScreenItem } from './table-screen-item'
import { EmployeeDisplayName } from './user-step-screen'

export type IScreenItem =
  | ICustomScreenItem
  | ISurveysPropertyScreenItem
  | IStringPropertyScreenItem
  | IStringInputScreenItem
  | IBooleanPropertyScreenItem
  | IDictionaryItemPropertyScreenItem
  | SEv2ScreenItem
  | ISignatureImageScreenItem
  | ILabelScreenItem
  | IPhotosPropertyScreenItem
  | ITableScreenItem
  | IQuestionableListScreenItem
  | ICheckOutUnitsScreenItem
  | IRadioGroupScreenItem
  | ITemplateAttachmentsScreenItem
  | IIntegerInputScreenItem
  | IQuantityInputScreenItem
  | IFillSurveyScreenItem
  | IViewSurveyScreenItem
  | IHyperlinkScreenItem
  | ServiceAuditScreenItem
  | ISingleSelectScreenItem
  | ITextScreenItem
  | IBooleanInputScreenItem
  | IBooleanViewScreenItem
  | IMediaContentListEditorScreenItem
  | IMediaContentListViewerScreenItem
  | IProblemsListScreenItem
  | IViewRichTextScreenItem
  | IViewRichTextListScreenItem
  | IIFrameScreenItem
  | IRefreshAuditedVisitsButtonScreenItem
  | IPropertyListScreenItem
  | IPopupRichtextScreenItem
  | IRepeaterScreenItem
  | IDateInputScreenItem
  | IPredicateViewScreenItem
  | IPredicateEditScreenItem
  | IRangeDateInputScreenItem
  | IButtonScreenItem
  | IFlexContainerScreenItem
  | IMimeContentViewerScreenItem
  | IMimeContentEditorScreenItem
  | IContentDocumentViewScreenItem
  | IContentDocumentPopupViewScreenItem
  | IJsonViewerScreenItem
  | IJsonEditorScreenItem
  | IContentDocumentSelectorScreenItem
  | ISelectTaskTemplateScreenItem
  | ISelectPOSScreenItem
  | ISelectEmployeeForAuditScreenItem
  | ITabSelectScreenItem

type SEv2ScreenItem = SETaskRepresentativeScreenItem | SELastFurtherFocusScreenItem | SETaskDirectionsScreenItem
/** temp hardcoded items for service audit */
type ServiceAuditScreenItem = ServiceAuditResultsScreenItem | IServiceAuditStatisticsScreenItem

export interface SETaskRepresentativeScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.SalesExpertEducationTaskRepresentativeScreenItem'
  agencyNamePropertyName: string
  represenatativeEmailPropertyName: string
  representativeNamePropertyName: string
}

export interface SELastFurtherFocusScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.SalesExpertEducationTaskPrevFutherFocusScreenItem'
  futherFocusPropertyName: string
}

export interface SETaskDirectionsScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.SalesExpertEducationTaskDirectionsScreenItem'
  educationDirectionLasSurveyCode: Code
  educationDirectionLauSurveyCode: Code
}

export type ScreenItems = Array<ScreenItemGroup | IScreenItem>

export interface ScreenItemGroup extends IScreenItemBase {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.ScreenItemGroup'
  items: IScreenItem[]
  viewSettings?: IScreenItemGroupViewSettings
}

export interface IScreenItemGroupViewSettings {
  viewMode: 'List' | 'Table'
}

export function isScreenItemGroup(screen: IScreenItemBase): screen is ScreenItemGroup {
  return screen.$type === 'PMI.FACE.BDDM.Extensions.Classes.ScreenItemGroup'
}

export function isRepeater(screen: IScreenItemBase): screen is IRepeaterScreenItem {
  return screen.$type === 'PMI.FACE.BDDM.Extensions.Classes.RepeaterScreenItem'
}

export interface ICustomScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.CustomScreenItem'
  action: number
  screenItemCode: string
}

export interface ISurveysPropertyScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.SurveysPropertyScreenItem'
  questionnaire: IQuestionnaireReference
  surveyDisplayNamePrefix?: string
  viewSettings?: ISurveysPropertyScreenItemViewSettings
  editSettings?: ISurveysPropertyScreenItemEditSettings
}

export interface IViewSurveyScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.ViewSurveyScreenItem'
  actionKind?: 'View'
  questionnaire?: IQuestionnaireReference
  questionnaires?: IQuestionnaireReference[]
  hideEmpty?: boolean
  nullValueCaption?: string
  compactMode?: boolean
}

export interface IFillSurveyScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.FillSurveyScreenItem'
  actionKind?: 'Edit'
  questionnaire?: IQuestionnaireReference
  questionnaires?: IQuestionnaireReference[]
  questionnairePath?: string
  required?: IPredicate
  visible?: IPredicate
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISurveysPropertyScreenItemViewSettings {}

export interface ISurveysPropertyScreenItemEditSettings {
  minSurveyCountRequired: number
  maxSurveyCountAvailable: number
  defaultSurveyCount: number
  errorHelperText?: string
}

/** @deprecated */
export interface IStringPropertyScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.StringPropertyScreenItem'
  /** @default false */
  compacted?: boolean
  viewSettings?: IStringPropertyScreenItemViewSettings
  editSettings?: IStringPropertyScreenItemEditSettings
}

export interface IStringInputScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.StringInputScreenItem'
  /** @default false */
  compacted?: boolean
  placeholder: string
  required?: IPredicate
  visible?: IPredicate
  errorHelperText: string
  maxLength?: number
  mask?: string
  helperText?: string
  compactMode?: boolean
}

export interface IStringPropertyScreenItemViewSettings {
  nullValueCaption?: string
  hideEmpty?: boolean
  useEllipsis?: boolean
}

export interface IStringPropertyScreenItemEditSettings {
  required: boolean
  placeholder?: string
  maxLength?: number
  errorHelperText?: string
}

/** @deprecated */
export interface IBooleanPropertyScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.BooleanPropertyScreenItem'
  viewSettings?: IBooleanPropertyScreenItemViewSettings
  editSettings?: IBooleanPropertyScreenItemEditSettings
}

export interface IBooleanPropertyScreenItemViewSettings {
  nullValueCaption?: string
  hideEmpty?: boolean
}

export interface IBooleanPropertyScreenItemEditSettings {
  required: boolean
  nullable: boolean
  errorHelperText?: string
}

export interface IDictionaryItemPropertyScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.DictionaryItemPropertyScreenItem'
  dictionary: IEntityReference
  viewSettings: IDictionaryItemPropertyScreenItemViewSettings
  editSettings: IDictionaryItemPropertyScreenItemEditSettings
}

export interface IDictionaryItemPropertyScreenItemViewSettings {
  nullValueCaption?: string
  hideEmpty?: boolean
  /* @deprecated */ collapseByDefaultWhenMultiple: boolean
  /* @deprecated */ showItemContent: boolean
}

export interface IDictionaryItemPropertyScreenItemEditSettings {
  required: boolean
  nullable: boolean
  showListButtonCaption?: string
  nullValueCaption?: string
  errorHelperText?: string
  isArrayValue?: boolean
  /* @deprecated */ collapseByDefaultWhenMultiple: boolean
  /* @deprecated */ maxOccurs: number
  /* @deprecated */ minOccurs: number
  /* @deprecated */ showFilter: boolean
  /* @deprecated */ showItemContentOnMainScreen: boolean
  /* @deprecated */ showItemContentOnSelectScreen: boolean
}

export interface IPhotosPropertyScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.PhotosPropertyScreenItem'
  viewSettings?: IPhotosPropertyScreenItemViewSettings
  editSettings?: IPhotosPropertyScreenItemEditSettings
}

export type IMediaStorageSettings = ILocalMediaStorageSettings | ICloudMediaStorageSettings

export interface ILocalMediaStorageSettings {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.LocalMediaStorageSettings'
}

export interface ICloudMediaStorageSettings {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.CloudMediaStorageSettings'
  routePath?: string
}

export interface IMediaContentListEditorScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.MediaContentListEditorScreenItem'
  minPhotoCountRequired: number
  maxPhotoCountAvailable: number
  errorHelperText?: string
  storage?: IMediaStorageSettings
}

export interface IMediaContentListViewerScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.MediaContentListViewerScreenItem'
  nullValueCaption?: string
  hideEmpty?: boolean
  storage?: IMediaStorageSettings
}

export interface IMimeContentViewerScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.MimeContentViewerScreenItem'
  nullValueCaption?: string
  hideEmpty?: boolean
  storage?: IMediaStorageSettings
}

export interface IMimeContentEditorScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.MimeContentEditorScreenItem'
  required?: IPredicate
  errorHelperText?: string
  storage?: IMediaStorageSettings
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPhotosPropertyScreenItemViewSettings {
  nullValueCaption?: string
  hideEmpty?: boolean
}
export interface IPhotosPropertyScreenItemEditSettings {
  minPhotoCountRequired: number
  maxPhotoCountAvailable: number
  errorHelperText?: string
}

/**
 * @deprecated see ./table-screen-item.ts
 */
export interface ITablePropertyScreenItem extends IActionScreenItem {
  $type: 'TableProperty'
  showColumnsHeader: boolean
  editSettings?: ITablePropertyScreenItemEditSettings
  viewSettings?: ITablePropertyScreenItemViewSettings
  cells: ITableCell[]
}
/**
 * @deprecated
 */
export interface ITablePropertyScreenItemViewSettings {
  /**
   * Показывать или нет контрол, если в таблицу был передан пустой массив.
   * Если параметр не заполнен, используется значение false
   */
  hideEmpty?: boolean
  nullValueCaption?: string
}
/**
 * @deprecated
 */
export interface ITablePropertyScreenItemEditSettings {
  /**
   * Замещающее сообщение, отображаемое в случае, если в таблицу был передан пустой массив.
   * Если параметр не заполнен, испольузется значение "Список строк пуст"
   */
  emptyItemsListCaption?: string
  required: boolean
  /**
   * Можно или нет завершать шаг в случае, если в таблицу был передан пустой массив. Возможные значения
   * true - если в таблицу был передан пустой массив, то можно дальше работать с шагом (значение Required в данном случае игнорируется);
   * false - если в таблицу был передан пустой массив, то завершение шага невозможно
   */
  skipWhenEmpty: boolean
}
/**
 * @deprecated
 */
export type ITableCell = ITableViewCell | ITableEditCell
/**
 * @deprecated
 */
export interface ITableCellBase {
  propertyName?: string
  landscapeAppearance: ITableCellAppearanceSettings
  portraitAppearance: ITableCellAppearanceSettings
}
/**
 * @deprecated
 */
export interface ITableCellAppearanceSettings {
  displayName?: string
  /** @default 0 */
  lineNumber?: number
  /** @default 0 */
  columnNumber?: number
  columnSize?: number
  /**
   * @default "left"
   */
  horizontalAlignment: 'left' | 'right' | 'center' | 'justify'
}
/**
 * @deprecated
 */
export type ITableViewCell =
  | IBooleanCellViewSettings
  | IDictionaryItemCellViewSettings
  | IPhotosCellViewSettings
  | IStringCellViewSettings
/**
 * @deprecated
 */
export interface IBooleanCellViewSettings extends ITableCellBase {
  $type: 'BooleanCellViewSettings'
  nullValueCaption?: string
}
/**
 * @deprecated
 */
export interface IDictionaryItemCellViewSettings extends ITableCellBase {
  $type: 'DictionaryItemCellViewSettings'
  nullValueCaption?: string
}
/**
 * @deprecated
 */
export interface IPhotosCellViewSettings extends ITableCellBase {
  $type: 'PhotosCellViewSettings'
  nullValueCaption?: string
}
/**
 * @deprecated
 */
export interface IStringCellViewSettings extends ITableCellBase {
  $type: 'StringCellViewSettings'
  nullValueCaption?: string
  useEllipsis?: boolean
}
/**
 * @deprecated
 */
export type ITableEditCell =
  | IBooleanCellEditSettings
  | IDictionaryItemCellEditSettings
  | IPhotosCellEditSettings
  | IStringCellEditSettings
/**
 * @deprecated
 */
export interface IBooleanCellEditSettings extends ITableCellBase {
  $type: 'BooleanCellEditSettings'
  required: boolean
  nullable: boolean
  /** @default "Выбрать" */
  showListButtonCaption?: string
  /** @default "Нет" */
  falseValueCaption?: string
  /** @default "Да" */
  trueValueCaption?: string
  /** @default "N/A" */
  nullValueCaption?: string
}
/**
 * @deprecated
 */
export interface IDictionaryItemCellEditSettings extends ITableCellBase {
  $type: 'DictionaryItemCellEditSettings'
  required: boolean
  nullable: boolean
  dictionary: IEntityReference
  /** @default "Выбрать" */
  showListButtonCaption?: string
}
/**
 * @deprecated
 */
export interface IPhotosCellEditSettings extends ITableCellBase {
  $type: 'PhotosCellEditSettings'
  minPhotoCountRequired: number
  maxPhotoCountAvailable: number
}
/**
 * @deprecated
 */
export interface IStringCellEditSettings extends ITableCellBase {
  $type: 'StringCellEditSettings'
  required: boolean
  maxLength?: number
  placeholder?: string
  /** @default "Заполнить" */
  showDialogButtonCaption?: string
}
/**
 * @deprecated
 */
export interface ISignatureImageScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.SignatureImageScreenItem'
  viewSettings?: ISignatureImageScreenItemViewSettings
  editSettings?: ISignatureImageScreenItemEditSettings
  signatureRejectionPropertyName?: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISignatureImageScreenItemViewSettings {
  nullValueCaption?: string
  hideEmpty?: boolean
}
export interface ISignatureImageScreenItemEditSettings {
  required: boolean
  errorHelperText?: string
}
export type IFontWeight = 'bold' | 'normal' | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900
export type IFontStyle = 'italic' | 'normal' | 'oblique' | 'inherit' | 'initial' | 'revert' | 'unset'
export interface IFontSettings {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.FontSettings'
  fontSize?: number
  fontWeight?: IFontWeight
  fontStyle?: IFontStyle
  lineHeight?: number
  letterSpacing?: number
  color?: string
}
export interface ILabelScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.LabelScreenItem'
  text: string
  defaultFont?: IFontSettings
  mobileFont?: IFontSettings
}

export interface IHyperlinkScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.HyperlinkScreenItem'
  description?: string
  linkDisplayName?: string
  linkFormat: string
}

export interface IKeyValuePair {
  key: string
  value: string
}

interface IKeyValuePairsDataSource {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.KeyValuePairsDataSource'
  items: IKeyValuePair[]
}

interface IPropertyKeyValuePairsDataSource {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.PropertyKeyValuePairsDataSource'
  propertyName: string
}

type DataSourceBase = IKeyValuePairsDataSource | IDictionaryItemsDataSource | IPropertyKeyValuePairsDataSource

export interface IRadioGroupScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.RadioGroupScreenItem'
  required: boolean
  errorHelperText?: string
  dataSource: DataSourceBase
  emptyDataSourceCaption?: string
  nullable?: boolean
}

export interface ITemplateAttachmentsScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.TemplateAttachmentsScreenItem'
  nullValueCaption?: string
  hideEmpty?: boolean
}

export interface IIntegerInputScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.IntegerInputScreenItem'
  required?: IPredicate
  showIncreaseButtons?: boolean
  minValue?: number
  minValuePropertyName?: string
  maxValue?: number
  maxValuePropertyName?: string
  defaultValue?: number
  incrementValue?: number
  errorHelperText?: string
}

export interface IQuantityInputScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.QuantityInputScreenItem'
  required?: IPredicate
  showIncreaseButtons?: boolean
  minValue?: number
  minValuePropertyName?: string
  maxValue?: number
  maxValuePropertyName?: string
  defaultValue?: number
  incrementValue?: number
  defaultUnitOfMeasure?: string
  defaultUOM?: string
  errorHelperText?: string
}

export interface ISingleSelectScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.SingleSelectScreenItem'
  required?: boolean
  visible?: IPredicate
  nullable?: boolean
  buttonCaption?: string
  nullValueCaption?: string
  dataSource: DataSourceBase
  isArrayValue?: boolean
  errorHelperText?: string
  compactMode?: boolean
}

export interface IJsonViewerScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.JsonViewerScreenItem'
  height: number
  nullValueCaption?: string
  hideEmpty?: boolean
  bdddmType?: IBddmTypeReference
}

export interface IJsonEditorScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.JsonEditorScreenItem'
  height: number
  visible?: IPredicate
  required?: IPredicate
  bddmType?: IBddmTypeReference
}

export interface IInlineScriptPredicateFunction {
  argumentName: string
  body: InlinePredicate
}

export interface ISelectPOSScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.SelectPOSScreenItem'
  condition?: IInlineScriptPredicateFunction
  required?: IPredicate
  errorHelperText?: string
}

export interface ISelectEmployeeForAuditScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.SelectEmployeeForAuditScreenItem'
  employeeDisplayName?: EmployeeDisplayName
  selectedPositionRoleReferencePropertyName: string
  selectedUserReferencePropertyName: string
  jobFunctions?: IConditionLists
  required?: IPredicate
  visible?: IPredicate
}

interface ITabSelectStyleBase {
  align?: 'Left' | 'Center'
}

interface IMultilineTabSelectStyle extends ITabSelectStyleBase {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.MultilineTabSelectStyle'
}

interface IScrollableTabSelectStyle extends ITabSelectStyleBase {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.ScrollableTabSelectStyle'
}

export interface ITabSelectScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.TabSelectScreenItem'
  dataSource: DataSourceBase
  style: IMultilineTabSelectStyle | IScrollableTabSelectStyle
  required?: IPredicate
  visible?: IPredicate
  compactMode?: boolean
}
