import React, { ChangeEvent, useEffect } from 'react'

import { createStyles, InputBase, makeStyles, Paper, SvgIcon, Theme } from '@material-ui/core'
import { useDebounce } from 'react-use'

import { ReactComponent as SearchIcon } from '../../../../../assets/icons/Search.svg'
import { grays } from '../../../../../layout/theme'

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      padding: '3px 20px 3px 20px',
      display: 'flex',
      alignItems: 'center',
      height: 40,
      borderRadius: '40px !important',
      boxShadow: 'none',
      color: grays.gray2,
    },
    inputRoot: {
      width: '100%',
      paddingLeft: '10px',
      paddingRight: '10px',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 500,
    },
    input: {
      '&::placeholder': {
        color: grays.gray2,
        opacity: 1,
      },
    },
    iconSearch: {
      // color: '#8A949D'
    },
  }),
)

interface SearchInputProps {
  query?: string
  onChange: (queryString: string) => void
  placeholder?: string
}

const SearchInput: React.FC<SearchInputProps> = ({ query = '', onChange, placeholder = 'Поиск' }) => {
  const classes = useStyles()

  const [val, setVal] = React.useState(query)
  const [debouncedValue, setDebouncedValue] = React.useState(query)
  useDebounce(
    () => {
      setDebouncedValue(val)
    },
    300,
    [val],
  )

  useEffect(() => onChange(debouncedValue), [debouncedValue])

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setVal(e.target.value)
  }

  return (
    <Paper className={classes.root}>
      <SvgIcon className={classes.iconSearch} component={SearchIcon} />
      <InputBase
        placeholder={placeholder}
        classes={{ input: classes.input, root: classes.inputRoot }}
        inputProps={{ 'aria-label': 'search representative' }}
        value={val}
        onChange={handleChange}
      />
    </Paper>
  )
}

export default SearchInput
