import React, { useState } from 'react'

import { Box, Link } from '@material-ui/core'

import { getGeolocation } from '../../utils/get-geolocation'

export const Geolocation: React.FC = () => {
  const [isLoadCord, setIsLoadCord] = useState(false)
  const [error, setError] = useState<GeolocationPositionError | null>(null)
  const [position, setPosition] = useState<GeolocationPosition | null>(null)

  if (isLoadCord) {
    return (
      <Box display='flex' justifyContent='center' alignContent='center' alignItems='center' height='100%'>
        <h2>Получение местоположения</h2>
      </Box>
    )
  }

  return (
    <Box display='flex' justifyContent='center' alignContent='center' alignItems='center' height='100%'>
      <div style={{ textAlign: 'center' }}>
        {error && (
          <>
            <h2>Ошибка получения местоположения: {error.message}</h2>
          </>
        )}

        {position && (
          <>
            <h2>
              Ваше местоположение: {position.coords.longitude}, {position.coords.latitude}
            </h2>
          </>
        )}

        {navigator.geolocation ? (
          <>
            <Box mb={3}>
              <button
                onClick={async () => {
                  setIsLoadCord(true)

                  let geolocation: GeolocationPosition

                  try {
                    geolocation = await getGeolocation()
                    setPosition(geolocation)
                    setError(null)
                  } catch (error) {
                    setError(error ?? null) // Ошибка может не ввернуться в PWA, если нет разрешения от iOS
                    setPosition(null)
                  }

                  setIsLoadCord(false)
                }}
              >
                Получить координаты
              </button>
            </Box>
            <Box mb={3}>
              <Link
                href={`yandexnavi://build_route_on_map?lat_from=55.75&lon_from=37.58&lat_to=55.75&lon_to=37.64&lat_via_0=55.75&lon_via_0=37.62`}
              >
                Построить маршрут в Навигаторе
              </Link>
            </Box>
            <Box mb={3}>
              <Link target='_blank' href={`https://yandex.ru/maps/?rtext=55.75,37.58~55.75,37.64~55.75,37.62&rtt=auto`}>
                Построить маршрут в Картах (Web)
              </Link>
            </Box>
            <Box mb={3}>
              <Link href={`yandexmaps://maps.yandex.ru/?rtext=55.75,37.58~55.75,37.64~55.75,37.62&rtt=auto`}>
                Построить маршрут в Картах (Native)
              </Link>
            </Box>
          </>
        ) : (
          <h3>Служба геолокации не поддерживается</h3>
        )}
      </div>
    </Box>
  )
}
