import React from 'react'

import { createStyles, makeStyles, SvgIcon, SvgIconProps } from '@material-ui/core'
import classNames from 'classnames'

import { ReactComponent } from '../../assets/icons/sync-disabled.svg'

const useStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      padding: theme.spacing('0.5px', '2px', 0),
      // TODO: test more and fix the differences with other icons
      // width: 24,
      // height: 24,
      // fontSize: 20,
    },
  }),
)

export const SyncDisabledIcon: React.FC<SvgIconProps> = ({ className, ...props }) => {
  const classes = useStyles()
  return (
    <SvgIcon
      viewBox='0 0 16 16'
      fontSize='inherit'
      component={ReactComponent}
      {...props}
      className={classNames(classes.icon, className)}
    />
  )
}
