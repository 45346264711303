import { useContext } from 'react'

import { useAsync } from 'react-use'
import { AsyncState } from 'react-use/lib/useAsyncFn'

import { IAvailableTaskCancelationReason } from '../../../model/task-template'
import { ApiContext, ProfileContext } from '../../../providers'

interface IUseTaskCancelationReasons {
  taskCode: string
}

export function useTaskCancelationReasons({
  taskCode,
}: IUseTaskCancelationReasons): AsyncState<IAvailableTaskCancelationReason[]> {
  const api = useContext(ApiContext)
  const profile = useContext(ProfileContext)

  const taskCancelationReasonsOps = useAsync(async () => {
    const task = await api.tasks.getTask(taskCode)
    const templateRef = task?.template

    if (templateRef) {
      const template = await api.tasks.getTaskTemplate(templateRef)
      return template?.availableTaskCancelationReasons?.filter((item) => {
        const availableProfiles = item.availableProfiles
        if (!availableProfiles?.length) {
          return true
        }
        return availableProfiles?.some((item) => item.code === profile.value?.profile.code)
      })
    }
  })

  return taskCancelationReasonsOps
}
