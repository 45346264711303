import React, { useEffect } from 'react'

import { ErrorBoundary } from 'react-error-boundary'
import { generatePath, Route, Routes, useNavigate, useParams } from 'react-router-dom'

import { ProtectedRoute } from '../../../components/protected-route/protected-route'
import { Code } from '../../../model/base'
import { useFeatureRoute } from '../../custom-app-feature/app-feature-context'
import { ProcessContextProvider } from '../../process/process-context'
import { visitsRoutes } from '../../visits/visits-app-feature/visits-app-feature'
import { useScriptTaskContext } from '../script-tasks/script-task-context'
import TaskDialog from '../template-tasks/task-dialog'
import { BusinessErrorBoundary } from './business-error-boundary'
// import { LocalContextProvider } from './local-context'
import { NestedProcessPage } from './nested-process'

interface VisitTaskPageProps {
  taskCode: Code
  visitCode: Code
}

export const VisitTaskPage: React.FC = () => {
  const params = useParams() as unknown as VisitTaskPageProps
  const navigate = useNavigate()
  const featureRoute = useFeatureRoute()

  useEffect(() => {
    console.log('VisitTaskPage mount', params)
  }, [])

  const toVisit = (): void => navigate(generatePath(visitsRoutes.visitView, { featureRoute, id: params.visitCode }))
  const toUpperLevel = (): void => navigate('', { replace: true })

  return (
    <ErrorBoundary FallbackComponent={BusinessErrorBoundary} onReset={toVisit}>
      <ProcessContextProvider {...params}>
        <Routes>
          <Route path='' element={<TaskDialog onBack={toVisit} />} />
          <Route
            path={`process/:processCode/:recordCode/*`}
            element={<VisitTaskNestedProcess onBack={toUpperLevel} />}
          />
        </Routes>
      </ProcessContextProvider>
    </ErrorBoundary>
  )
}

const VisitTaskNestedProcess: React.FC<{ onBack?: VoidFunction }> = ({ onBack }) => {
  const params = useParams() as unknown as VisitTaskPageProps
  const propertiesContext = useScriptTaskContext()

  const task = propertiesContext.task
  const isAllowed = task.status !== 'Finished'

  return (
    <ProtectedRoute redirectPath={`/visits/${params.visitCode}/task/${params.taskCode}`} isAllowed={isAllowed}>
      <NestedProcessPage onBack={onBack} />
    </ProtectedRoute>
  )
}