import Locale from 'date-fns'
import en from 'date-fns/locale/en-GB'
import ru from 'date-fns/locale/ru'

export const getLocal = (local?: string): Locale => {
  switch (local) {
    case 'en':
      return en
    case 'ru':
    default:
      return ru
  }
}