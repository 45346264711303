import React from 'react'

import { Navigate, Outlet } from 'react-router-dom'

interface IProtectedRoute {
  isAllowed: boolean
  redirectPath?: string
}

export const ProtectedRoute: React.FC<IProtectedRoute> = ({ isAllowed, redirectPath = '/', children }) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />
  }

  return (
    <>
      {children} ?? <Outlet />
    </>
  )
}
