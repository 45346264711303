import React, { useContext } from 'react'

import { createStyles, Link, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { ApiContext } from '../../../../providers'
import { dateFormat } from '../../../../utils'
import { ErrorPage } from '../../../error-page'
import { ItemCard } from '../../../tasks/template-tasks/composite-screen/item-card'
import { InfoPageWrapper } from '../../components/info-page-wrapper'
import { IStoreMenuLocationState } from '../store-menu-page'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'grid',
      gridTemplateColumns: '33fr 67fr',
      columnGap: 10,
      marginBottom: 16,
      '& > *': {
        minWidth: 0,
      },
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
  }),
)

export const PposmInfo: React.FC = () => {
  const classes = useStyles()
  const api = useContext(ApiContext)
  const navigate = useNavigate()
  const location = useLocation()
  const { pposmCode } = useParams<{ pposmCode: string; posCode: string }>()
  const state = location.state as IStoreMenuLocationState

  const { prevPPOSMPath, prevPath } = state

  const onBackHandler = (): void => navigate(prevPPOSMPath ?? prevPath, { state })

  const pposmOps = useAsync(async () => api.pos.getPPOSM(pposmCode!))

  if (pposmOps.error) {
    return <ErrorPage errorMessage={pposmOps.error.message} />
  }

  if (pposmOps.loading && !pposmOps.value) return <></>

  const pposm = pposmOps.value!

  const installedDate = pposm.state.installed
  const serialText = pposm.serial ?? pposm.serialNumber ?? null

  return (
    <InfoPageWrapper title={pposm.name} onBack={onBackHandler}>
      <ItemCard label={'Информация об оборудовании'}>
        <div className={classes.row}>
          <Typography color='textSecondary'>Наименование</Typography>
          <Typography>{pposm.name}</Typography>
        </div>
        <div className={classes.row}>
          <Typography color='textSecondary'>Производитель</Typography>
          <Typography>{pposm.manufacturer?.name ?? '-'}</Typography>
        </div>
        <div className={classes.row}>
          <Typography color='textSecondary'>Код производителя</Typography>
          <Typography>{pposm.manufacturer?.code ?? '-'}</Typography>
        </div>
        <div className={classes.row}>
          <Typography color='textSecondary'>Тип </Typography>
          <Typography>{pposm.type?.name ?? '-'}</Typography>
        </div>
        <div className={classes.row}>
          <Typography color='textSecondary'>Серийный номер</Typography>
          <Typography> {serialText ?? '-'}</Typography>
        </div>
        <div className={classes.row}>
          <Typography color='textSecondary'>Код PPOSM</Typography>
          <Typography>{pposm.code}</Typography>
        </div>
        <div className={classes.row}>
          <Typography color='textSecondary'>Дата установки</Typography>
          <Typography>{installedDate ? dateFormat(new Date(installedDate), 'dd.MM.yyyy') : '-'}</Typography>
        </div>
        <div className={classes.row}>
          <Typography color='textSecondary'>Статус</Typography>
          <Typography>{pposm.state.status}</Typography>
        </div>
        <div className={classes.row}>
          <Typography color='textSecondary'>Ссылка на PION</Typography>
          <Link href={`https://pmirussc.service-now.com/sp?id=scan_equipment&touchpoint=${pposm.code}`} target='_blank'>
            <Typography
              style={{ wordWrap: 'break-word' }}
            >{`https://pmirussc.service-now.com/sp?id=scan_equipment&touchpoint=${pposm.code}`}</Typography>
          </Link>
        </div>
      </ItemCard>
    </InfoPageWrapper>
  )
}
