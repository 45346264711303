import React, { useCallback, useState, useRef } from 'react'

import { createStyles, Dialog, DialogContent, IconButton, makeStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'

import { ICompositeScreenItemLayout } from '../../../../../model/composite-screen-item-layout'
import { FaceContentDocumentReference } from '../../../../../model/face-content-document'
import { IActionScreenItem } from '../../../../../model/screen-item-base'
import { CloseIcon } from '../../../../admin/data-manager/icons'
import { ItemCard } from '../item-card'
import ContentDocumentView from './content-document-view'

export interface IContentDocumentPopupViewScreenItem extends IActionScreenItem {
  $type: 'PMI.FACE.BDDM.Extensions.Classes.ContentDocumentPopupViewScreenItem'
  popupCaption: string
  previewStaticContent?: FaceContentDocumentReference
  popupStaticContent?: FaceContentDocumentReference
  borderRadius: number
  mobileLayout: ICompositeScreenItemLayout
  desktopLayout: ICompositeScreenItemLayout
}
interface Props {
  item: IContentDocumentPopupViewScreenItem
}

const ContentDocumentPopupViewScreenItemControl: React.FC<Props> = ({ item }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const toggleDialog = useCallback(() => {
    setOpenDialog((prev) => !prev)
  }, [])

  const { previewStaticContent } = item

  if (!previewStaticContent?.code) {
    return <ItemCard>Документ не найден</ItemCard>
  }

  return (
    <>
      <ContentDocumentView
        item={item}
        onClick={toggleDialog}
        imageCode={previewStaticContent.code}
      ></ContentDocumentView>
      <ContentDocumentDialog isOpen={openDialog} toggleDialog={toggleDialog} item={item}></ContentDocumentDialog>
    </>
  )
}

export default ContentDocumentPopupViewScreenItemControl

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogHeader: {
      position: 'fixed',
      top: '0px',
      right: '16px',
      left: '0px',
      display: 'flex',
      alignItems: 'center',
      padding: '16px 8px 16px 24px;',
      zIndex: 100,
      background: '#fff',
      transition: '0.1s top',

      fontSize: 18,
      lineHeight: '23px',
      fontWeight: 600,
      letterSpacing: '0.15px',
    },
    hideHeader: {
      top: '-64px',
    },
    dialogContent: { marginTop: '48px' },
  }),
)
interface ContentDocumentDialogProps {
  item: IContentDocumentPopupViewScreenItem
  isOpen: boolean
  toggleDialog: () => void
}
const ContentDocumentDialog: React.FC<ContentDocumentDialogProps> = ({ isOpen, toggleDialog, item }) => {
  const classes = useStyles()
  const contentRef = useRef<HTMLDivElement>(null)
  const [scroll, setScroll] = useState<number>(-1)
  const [hideHeader, setHideHeader] = useState(false)

  const scrollAction = (): void => {
    if (contentRef.current) {
      if (contentRef.current.scrollTop <= 0) {
        setHideHeader(false)
      } else if (scroll > contentRef.current.scrollTop) {
        setHideHeader(false)
      } else if (scroll < contentRef.current.scrollTop) {
        setHideHeader(true)
      }

      setScroll(contentRef.current.scrollTop)
    }
  }

  return (
    <Dialog fullScreen open={isOpen} onClose={toggleDialog} disableBackdropClick={true} disableEscapeKeyDown={true}>
      <DialogContent onScroll={scrollAction} ref={contentRef}>
        <div
          className={classNames(classes.dialogHeader, {
            [classes.hideHeader]: hideHeader,
          })}
        >
          <Typography style={{ flex: 1 }} variant='inherit'>
            {item.popupCaption}
          </Typography>
          <IconButton edge='end' size='small' onClick={toggleDialog} aria-label='close'>
            <CloseIcon />
          </IconButton>
        </div>
        {!item.popupStaticContent?.code && <>Документ не найден</>}
        {item.popupStaticContent?.code && (
          <ContentDocumentView
            item={item}
            onClick={toggleDialog}
            imageCode={item.popupStaticContent.code}
            isModal={true}
          ></ContentDocumentView>
        )}
      </DialogContent>
    </Dialog>
  )
}
