import React from 'react';

import { createStyles, Fab, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { getFloatActionButtonStyle } from '../../../../../assets/pm-shared-styles';
import { ValidationFabIcon } from '../../../../../components/validation-fab';

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    addButton: {
      ...getFloatActionButtonStyle(theme),
      zIndex: 100,
      paddingRight: 16,
      textTransform: 'none',
      [theme.breakpoints.down('xs')]: {
        right: '50%',
        transform: 'translateX(50%)',
        margin: '0 auto',
      },
    },
  })
)

interface IProps {
  handleClick: () => void
  validationState: boolean
  caption: string
}

const ProblemValidateButton: React.FC<IProps> = ({handleClick, validationState, caption}) => {
  const classes = useStyles()
  const { t } = useTranslation('problems')

  return (
    <Fab
      color='primary'
      aria-label='add'
      variant='extended'
      className={classes.addButton}
      onClick={handleClick}
    >
      <ValidationFabIcon isValid={validationState} />
      {t(caption)}
    </Fab>
  );
};

export default ProblemValidateButton