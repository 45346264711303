import React, { useEffect, useState } from 'react'

import { Dialog } from '@material-ui/core'
import { useCounter } from 'react-use'

import { BlobStorageError } from '../../../../infrastructure/blob-storage/blob-storage-api'
import { LogManager } from '../../../../infrastructure/logger'
import { appToast } from '../../../../utils'
import { useUpdateProperty } from '../../../tasks/nested/useUpdateProperty'
import { useScriptTaskContext } from '../../../tasks/script-tasks/script-task-context'
import { convertMediaContentList, MediaEntity } from '../../../tasks/template-tasks/composite-screen/utils'
import { DocumentViewer } from '../../../viewers/document-viewer/document-viewer'
import { useOptimizeBlobImage } from '../../hooks'
import { useDocumentMethods } from '../../hooks/use-document-methods'
import PhotoLoad from '../../photo-dialog/photo-load'
import {
  createDocumentsValueByType,
  getDocumentsKeysByParts,
  getErrorToast,
  getKeysFromContext,
  getStorage,
  ITempFileItem,
  useFetchFilesByKeys,
} from '../../utils'
import { ZipDocumentsMenu } from '../zip-button/zip-documents-menu'
import { DocumentsScreenItem } from './documents-screen-item'
import { DocumentsScreenItemLabel } from './documents-screen-item-label'

interface IDocumentsScreenItemContainer {
  entity: MediaEntity
  isReadOnly: boolean
}

const logger = LogManager.getLogger('DocumentsScreenItemContainer')

export const DocumentsScreenItemContainer: React.FC<IDocumentsScreenItemContainer> = ({ entity, isReadOnly }) => {
  const propertiesContext = useScriptTaskContext()
  const updateProperty = useUpdateProperty()
  const optimizeBlobImage = useOptimizeBlobImage()
  const storage = getStorage(entity)
  const { addBlob, deleteBlob } = useDocumentMethods(storage)

  // Конвертер для обратной совместимости с PhotosPropertyScreenItem
  const item = convertMediaContentList(entity, propertiesContext)

  useEffect(() => {
    console.log('DocumentsScreenItemContainer mount', entity)
  }, [])

  const idsEntry = getKeysFromContext(propertiesContext, entity.propertyName)
  const [ids, setIds] = useState(idsEntry)
  const [openedDocument, setOpenedDocument] = useState<ITempFileItem | null>(null)
  const [isSelectMode, setIsSelectMode] = useState<boolean>(false)
  const [checkedList, setCheckedList] = useState<string[]>([])
  const [isLoadingToDB, setIsLoadingToDB] = useState<boolean>(false)
  const [downloadError, setDownloadError] = useState<BlobStorageError>()
  const [updatedItemKey, setUpdatedItemKey] = useState<string | undefined>(undefined)

  const { value: files = [] } = useFetchFilesByKeys(ids)
  const [retryCount, { inc: retryInc }] = useCounter(0)
  const retry = (): void => {
    setDownloadError(undefined)
    retryInc()
  }

  const setDocumentsPropertyPath = async (keys: string[]): Promise<void> => {
    // const routePath = getRoutePath(storage)
    const value = createDocumentsValueByType(keys, entity.$type)
    await updateProperty(entity.propertyName, value)
    const newKeys = getDocumentsKeysByParts(value)
    setIds(newKeys)
  }

  const onChangeInput = async (file: Blob | undefined): Promise<void> => {
    if (file) {
      setIsLoadingToDB(true)
      try {
        const optimizedImage = await optimizeBlobImage(file)
        const metadata = await addBlob(optimizedImage)
        await setDocumentsPropertyPath([metadata.key, ...ids])
      } catch (err) {
        logger.error('onChangeInput', 'Error adding photos', err)
        getErrorToast(err as BlobStorageError)
      }
      setIsLoadingToDB(false)
    }
  }

  const onDeleteHandler = async (): Promise<void> => {
    const key = openedDocument?.metadata.key
    if (!key) return
    const result = ids.filter((id) => id !== key)
    setIsLoadingToDB(true)
    try {
      await setDocumentsPropertyPath(result)
      await deleteBlob(key)
    } catch (err) {
      logger.error('onDeleteHandler', 'Error removed photo', err)
      appToast.error('Произошла ошибка при удалении фотографии')
    }
    setIsLoadingToDB(false)
    setOpenedDocument(null)
  }

  const onUpdateHandler = async (file: Blob): Promise<void> => {
    const oldKey = openedDocument?.metadata.key
    if (!oldKey) return
    setIsLoadingToDB(true)
    const removeIndex = ids.findIndex((id) => id === oldKey)

    try {
      // добавить новый
      const optimizedImage = await optimizeBlobImage(file)
      const { key } = await addBlob(optimizedImage)
      ids.splice(removeIndex, 1, key)
      await setDocumentsPropertyPath([...ids])
      // сохранить id, для автоматического открытия модалки после обновления
      setUpdatedItemKey(key)
      setOpenedDocument(null)

      // удалить старый
      await deleteBlob(oldKey)
    } catch (err) {
      logger.error('onUpdateHandler', 'Error update photo', err)
      appToast.error('Произошла ошибка при обновлении фотографии')
    }

    setIsLoadingToDB(false)
  }

  useEffect(() => {
    if (files.length) {
      const item = files.find((f) => f.metadata.key === (updatedItemKey ?? openedDocument?.metadata.key))
      /*
        после обновления нужно автоматически открыть картинку
      */
      if (item && (updatedItemKey ?? (openedDocument && !files.includes(openedDocument)))) {
        setOpenedDocument(item)
        setUpdatedItemKey(undefined)
      }
    }
  }, [files, updatedItemKey, openedDocument])

  const onCancel = (): void => {
    setIsSelectMode(false)
    setCheckedList([])
  }

  const onSelectAll = (): void => {
    setIsSelectMode(true)
    setCheckedList(files.map((file) => file.metadata.key))
  }

  const onDeleteSelected = async (): Promise<void> => {
    const difference: string[] = []
    setIsLoadingToDB(true)
    try {
      for (const id of ids) {
        if (!checkedList.includes(id)) {
          difference.push(id)
        } else {
          await deleteBlob(id)
        }
      }
      await setDocumentsPropertyPath(difference)
    } catch (err) {
      logger.error('onDeleteSelected', 'Error removed selected photos', err)
      appToast.error('Произошла ошибка при удалении выбранных фотографий')
    }
    setIsSelectMode(false)
    setIsLoadingToDB(false)
  }

  const onClickItem = (item: ITempFileItem, isChecked: boolean): void => {
    if (!isSelectMode) {
      setOpenedDocument(item)
      return
    }
    if (!isChecked) {
      setCheckedList([...checkedList, item.metadata.key])
    } else {
      setCheckedList(checkedList.filter((id) => id !== item.metadata.key))
    }
  }

  const onLongPressItem = (evt: MouseEvent | TouchEvent, item: ITempFileItem): void => {
    if (isReadOnly) {
      return
    }
    if (!isSelectMode) {
      setIsSelectMode(true)
    }
    if (evt.type === 'touchstart') {
      setCheckedList([...checkedList, item.metadata.key])
    }
  }

  let isError = false

  if (item.editSettings) {
    const { minPhotoCountRequired, maxPhotoCountAvailable } = item.editSettings

    if (files.length < minPhotoCountRequired || files.length > maxPhotoCountAvailable) {
      isError = true
    }
  }

  if (item.viewSettings) {
    if (!files.length && item.viewSettings.hideEmpty) {
      return <></>
    }
  }

  return (
    <>
      <DocumentsScreenItem
        key={retryCount}
        isReadOnly={isReadOnly}
        label={
          <DocumentsScreenItemLabel
            title={entity.displayName}
            isReadOnly={!files.length || isReadOnly}
            isSelectMode={isSelectMode}
            onCancel={onCancel}
            onDeleteSelected={onDeleteSelected}
            onSelectAll={onSelectAll}
            downloadImageError={downloadError}
          >
            {isReadOnly && <ZipDocumentsMenu ids={ids} storage={storage} />}
          </DocumentsScreenItemLabel>
        }
        documents={files}
        onChangeInput={onChangeInput}
        onClickItem={onClickItem}
        onLongPressItem={onLongPressItem}
        isSelectMode={isSelectMode}
        checkedList={checkedList}
        isError={isError}
        nullValueCaption={item.viewSettings?.nullValueCaption}
        onDownloadError={setDownloadError}
        storage={storage}
      />
      {openedDocument && (
        <Dialog open={!!openedDocument} fullScreen>
          <DocumentViewer
            title={entity.displayName}
            isReadOnly={isReadOnly}
            blob={openedDocument.blob}
            idKey={openedDocument.metadata.key}
            onClose={() => {
              retry()
              setOpenedDocument(null)
            }}
            isPrev={files.indexOf(openedDocument) !== 0}
            isNext={files.indexOf(openedDocument) !== files.length - 1}
            onPrevClick={() => {
              setOpenedDocument(files[files.indexOf(openedDocument) - 1])
            }}
            onNextClick={() => {
              setOpenedDocument(files[files.indexOf(openedDocument) + 1])
            }}
            onDelete={onDeleteHandler}
            storage={storage}
            onUpdate={onUpdateHandler}
          />
        </Dialog>
      )}
      {isLoadingToDB ? <PhotoLoad /> : ''}
    </>
  )
}
