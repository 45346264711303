import React, { useEffect } from 'react'

import i18n from 'i18next'
import HttpApi from 'i18next-http-backend'
import { throttle } from 'lodash'
import { initReactI18next, useTranslation } from 'react-i18next'

import { useBusinessSettings } from '../../features/_common/hooks/useBusinessSettings'
import resources from './resources.json'

const namespaces = Object.keys(resources)

void i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    ns: namespaces,
    saveMissing: true,
    missingKeyHandler(lngs, ns, key, fallbackValue, updateMissing, options) {
      // enables onMissingKey event together with saveMissing: true
      // console.log('missing keys', { lngs, ns, key, fallbackValue, updateMissing, options })
    },
    // parseMissingKeyHandler(key) {
    //   console.log('parse missing key', key)
    // },
    // appendNamespaceToMissingKey: true,
    defaultNS: 'common',
    debug: false,
    fallbackLng: false,
    lng: 'ru',
    resources,
    partialBundledLanguages: true,
    react: {
      bindI18nStore: 'added',
      useSuspense: false,
    },
  })

export const LanguageReader: React.FC = () => {
  const businessSettings = useBusinessSettings()
  const { i18n } = useTranslation()

  useEffect(() => {
    if (businessSettings) {
      const locale = businessSettings.localLanguage
      const onLanguageChange = (locale: string): void => void i18n.reloadResources(locale)
      i18n.on('languageChanged', onLanguageChange)
      void i18n.changeLanguage(locale)

      const missingKey = throttle((...args: unknown[]): void => {
        console.log('missing key', args)
        void i18n.reloadResources()
      }, 10_000)
      i18n.on('missingKey', missingKey)

      return () => {
        i18n.off('languageChanged', onLanguageChange)
        i18n.off('missingKey', missingKey)
      }
    }
  }, [businessSettings])

  return null
}
