import React from 'react'

import { ICredentials } from '../../infrastructure/auth'
import IAuthService from '../../infrastructure/auth/auth-service-api'
import AppConfig from '../../infrastructure/config-service/app-config'

export type AuthOption = NonNullable<AppConfig['authOptions']>[0] & {
  displayName?: string
}

export interface IAuthContext {
  authenticated: boolean
  login: (credentials?: ICredentials) => Promise<void>
  logout: () => Promise<boolean>
  currentUserName: string
  authService: IAuthService
  authOptions: AuthOption[]
  selectOption: (key: string) => void
}

const AuthContext = React.createContext<IAuthContext>(undefined!)
export default AuthContext
