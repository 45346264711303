import React, { useState } from 'react'

import { Button, createStyles, Link, makeStyles, Paper, Theme } from '@material-ui/core'

import { grays } from '../../../../../layout/theme'
import { ISignatureImageScreenItem } from '../../../../../model/screen-item'
import { Signature } from '../../../../signature'
import { useBatchUpdate } from '../../../nested/useBatchUpdate'
import { useUpdateProperty } from '../../../nested/useUpdateProperty'
import { getContextProperty } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `1px solid ${grays.gray5}`,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginBottom: theme.spacing(1),
      minHeight: theme.spacing(7),
    },
    wrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      fontSize: 16,
      minHeight: theme.spacing(7),
      // fontWeight: 700
    },
    name: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    noImageText: {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: '0.1px',
    },
    rejectionText: {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: '0.1px',
    },
    link: {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: '0.1px',
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
    },
    imageSignature: {
      height: 56,
      width: 128,
      objectFit: 'contain',
    },
    signature: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 2,
    },
  }),
)

interface Props {
  item: ISignatureImageScreenItem
  isReadOnly?: boolean
}

export const SignatureImageStageItem: React.FC<Props> = ({ item, isReadOnly }) => {
  const classes = useStyles()
  const propertiesContext = useScriptTaskContext()
  const updateProperty = useUpdateProperty()
  const batchUpdate = useBatchUpdate()

  const [isOpen, setIsOpen] = useState(false)

  const image = getContextProperty(propertiesContext, item.propertyName, '')

  const isRejection =
    item.signatureRejectionPropertyName !== undefined
      ? (getContextProperty(propertiesContext, item.signatureRejectionPropertyName, false) as boolean)
      : undefined

  if (isReadOnly && item.viewSettings?.hideEmpty && !image && !isRejection) {
    return <></>
  }

  return (
    <Paper className={classes.root} elevation={0} square>
      <div className={classes.wrapper}>
        <span className={classes.name}>{item.displayName}</span>
        <div className={classes.signature}>
          {image && !isRejection ? <img className={classes.imageSignature} src={image} alt='' /> : ''}
          {isRejection ? <span className={classes.rejectionText}>От подписи отказался (лась)</span> : ''}
          {isReadOnly && !image && !isRejection ? (
            <span className={classes.noImageText}>{item.viewSettings?.nullValueCaption ?? ''}</span>
          ) : (
            ''
          )}
          {!isReadOnly && (
            <Button
              className={classes.link}
              component={Link}
              onClick={() => {
                setIsOpen(true)
              }}
            >
              {image || isRejection === true ? 'Изменить' : 'Поставить подпись'}
            </Button>
          )}
        </div>
        <Signature
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false)
          }}
          onChange={(value) => {
            if (item.actionKind !== 'Edit') return
            if ('image' in value) {
              if (item.signatureRejectionPropertyName) {
                void batchUpdate({
                  [item.propertyName]: value.image,
                  [item.signatureRejectionPropertyName]: false,
                })
              } else {
                void updateProperty(item.propertyName, value.image)
              }
            } else if ('rejection' in value) {
              if (item.signatureRejectionPropertyName) {
                void batchUpdate({
                  [item.propertyName]: '',
                  [item.signatureRejectionPropertyName]: value.rejection,
                })
              }
            }
          }}
          isRejection={isRejection}
        />
      </div>
    </Paper>
  )
}
