import React from 'react'

import {
  makeStyles,
  Theme,
  createStyles,
  Tabs,
  Tab,
  withStyles,
  styled,
  useMediaQuery,
  TabsProps,
} from '@material-ui/core'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'

import { grays } from '../../../layout/theme'
import { MobileTabs } from './proceed-tabs-mobile'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      position: 'fixed',
      padding: theme.spacing(0, 3),
      left: 80,
      top: 84,
      right: 0,
      // [theme.breakpoints.down('xs')]: {
      //   top: 64
      // },
      // width: '100%',
      // color: 'white',
      zIndex: theme.zIndex.drawer,
      background: 'white',
      paddingBottom: theme.spacing(4),
      // boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%)'
      '& .MuiTabs-indicator': {
        // background: theme.palette.primary.main
        display: 'none',
      },
    },
  }),
)

type Value = string

interface Props {
  value: Value
  onChange: (value: Value) => void
  classes?: TabsProps['classes']
}

const middleBreakpoint: Breakpoint = 'sm'

export const ProceedTabs: React.FC<Props> = ({ classes: muiClasses, value, onChange, children }) => {
  const classes = useStyles()
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down(middleBreakpoint))
  return isSmall ? (
    <MobileTabs classes={muiClasses} value={value} onChange={onChange}>
      {children}
    </MobileTabs>
  ) : (
    <Tabs
      className={classes.tabs}
      classes={muiClasses}
      value={value}
      onChange={(_ev, val) => onChange(val)}
      variant='fullWidth'
      // selectionFollowsFocus
      // scrollButtons='on'
      aria-label='visit-tasks icon tabs'
    >
      {children}
    </Tabs>
  )
}

export const ProceedTab = withStyles(
  (theme: Theme) =>
    createStyles({
      labelIcon: {
        minHeight: 'unset',
      },
      root: {
        height: 53,
        minWidth: 'auto',
        textTransform: 'none',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '16px',
        borderBottom: `3px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        maxWidth: 'unset',
        paddingLeft: theme.spacing(2),
        textAlign: 'left',
        [theme.breakpoints.down(middleBreakpoint)]: {
          minWidth: 180,
          flexGrow: 1,
        },
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        opacity: 0.9,
      },
      disabled: {
        color: grays.gray3,
        borderBottom: '3px solid currentColor',
        opacity: '0.8 !important;',
      },
      selected: {
        background: theme.palette.secondary.main,
        '&:hover': {
          background: theme.palette.secondary.main,
        },
      },
      wrapper: {
        flexWrap: 'nowrap',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('xs')]: {
          justifyContent: 'center',
        },
      },
    }),
  { name: 'ProceedTab' },
)(Tab)

export const TabIcon = styled('div')(({ theme }) => ({
  paddingRight: 1,
  background: theme.palette.primary.main,
  color: 'white',
  borderRadius: '50%',
  width: 20,
  flexShrink: 0,
  height: 20,
  marginRight: 10,
  marginBottom: '0px !important',
  lineHeight: '20px',
  fontSize: 12,
  textAlign: 'center',
  '.Mui-disabled &': {
    background: '#A7AEB9',
  },
}))
