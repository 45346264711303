import React from 'react'

import { Box, createStyles, makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { PageContent } from '../../../../../layout/page-content'
import { TitleBar } from '../../../../../layout/title-bar'
import ProblemPosInfoScreenItem from '../items/problem-pos-info-screen-item'
import { ProblemContextProvider } from '../problem-context'
import ProblemCreateBase from './problem-create-base'

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    wrapper: {
      padding: 25,
      paddingBottom: theme.spacing(12),
      [theme.breakpoints.down('xs')]: {
        padding: '21px 18px 0',
        paddingBottom: theme.spacing(12),
      },
    },
  })
)

interface IState {
  posCode: string
}

interface LocationState {
  scrollOffset?: number
}

const ProblemCreateSelectPage: React.FC = () => {
  const { posCode } = useParams() as unknown as IState
  const navigate = useNavigate()
  const { t } = useTranslation('problems')
  const { state } = useLocation() as { state: LocationState }
  const classes = useStyles()

  const goToProblems = (): void => {
    navigate('/problems')
  }
  const goToPosSelector = (): void => {
    navigate('/problems/problemPosSelector', { state })
  }

  return (
    <ProblemContextProvider posCode={posCode}>
      <TitleBar onBack={goToProblems}>{t('addingProblem')}</TitleBar>
      <PageContent>
        <Box className={classes.wrapper}>
          <ProblemPosInfoScreenItem
            isChangeOption={true}
            handleClick={goToPosSelector}
          />
          <ProblemCreateBase
            navigateHandler={goToProblems}
          />
        </Box>
      </PageContent>
    </ProblemContextProvider>
  )
}

export default ProblemCreateSelectPage
