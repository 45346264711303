import React, { useState, useContext, useImperativeHandle, forwardRef } from 'react'

import { Box, createStyles, makeStyles, Theme } from '@material-ui/core'
import { useAsync } from 'react-use'

import { LogManager } from '../../../infrastructure/logger'
import { IPointOfSale, IPOSReference } from '../../../model/pos'
import { checkPredicate } from '../../../model/script-predicate'
import { ISelectPOSScreen } from '../../../model/user-step-screen'
import { ApiContext } from '../../../providers'
import { appToast } from '../../../utils'
import { useBatchUpdate } from '../nested/useBatchUpdate'
import { getContextProperty } from '../script-tasks/propertyName'
import { useScriptTaskContext } from '../script-tasks/script-task-context'
import PosSelectorList from './composite-screen/pos-selector/pos-selector-list'
import { ScreenRef } from './types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listWrapper: {
      height: 'calc(100vh - 380px)',
      margin: '0 24px 0',
    },
  }),
)

interface IProps {
  screen: ISelectPOSScreen
  onReadyChange: (isReady: boolean) => void
}

const logger = LogManager.getLogger('SelectPOSStepScreen')

const SelectPOSStepScreen = forwardRef<ScreenRef, IProps>(({ screen, onReadyChange }, ref) => {
  SelectPOSStepScreen.displayName = 'SelectPOSStepScreen'
  const localContext = useScriptTaskContext()
  const isRequired = screen.required
    ? checkPredicate(screen.required, {} as Record<string, unknown>, localContext)
    : false
  const classes = useStyles()
  const batchUpdate = useBatchUpdate()
  const record = getContextProperty<IPOSReference | undefined>(localContext, screen.propertyName, undefined)
  const api = useContext(ApiContext)

  const [selectedItem, setSelectedItem] = useState<IPointOfSale | null>()
  const [isLoading, setisLoading] = useState(true)

  const selectPos = (val: IPointOfSale | null): void => {
    if (!val) {
      onReadyChange(false)
      return
    }
    setSelectedItem(val)
    onReadyChange(true)
    void batchUpdate({
      [screen.propertyName]: {
        $type: 'PMI.BDDM.Staticdata.POSReference',
        code: val.code,
        codeSpace: val.codeSpace,
      },
    })
  }

  useAsync(async () => {
    if (record?.$type.includes('POSReference')) {
      try {
        const res = await api.pos.getPos(record.code)
        selectPos(res)
        setisLoading(false)
        return
      } catch (err) {
        setisLoading(false)
        const message = `Ошибка ${err.message}`
        appToast.info(message)
        logger.error('get', message, err)
        throw new Error(message)
      }
    } else {
      setisLoading(false)
      if (isRequired) {
        onReadyChange(false)
      }
    }
  }, [])

  useImperativeHandle(
    ref,
    () => ({
      validate: () => {
        if (isRequired && !selectedItem) {
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          const message = screen.errorHelperText || 'Не выбрана торговая точка'
          appToast.info(message)
        }
      },
    }),
    [],
  )

  return (
    <>
      {!isLoading && (
        <Box className={classes.listWrapper}>
          <PosSelectorList condition={screen.condition} selectedItem={selectedItem} selectPos={selectPos} />
        </Box>
      )}
    </>
  )
})

export default SelectPOSStepScreen
