import React from 'react'

import { Box } from '@material-ui/core'
import MonacoEditor from '@monaco-editor/react'

import { InlinePredicate } from '../../../../../../model/script-predicate'
import { getMonacoEditorOptions } from '../../../../../admin/_common/monaco-options'

interface IInlinePredicateControl {
  value: InlinePredicate
  onChange?: (value: InlinePredicate) => void
  readonly?: boolean
}

export const createInlinePredicate = (value?: string): InlinePredicate => ({
  $type: 'PMI.FACE.BDDM.Extensions.Classes.InlineScriptPredicate',
  scriptBody: value ?? '',
  dialect: 'JavaScript',
})

export const InlinePredicateControl: React.FC<IInlinePredicateControl> = ({ value, onChange, readonly = false }) => {
  return (
    <Box height='150px'>
      <MonacoEditor
        language='javascript'
        value={value.scriptBody ?? ''}
        onChange={(value) => {
          onChange?.(createInlinePredicate(value))
        }}
        options={getMonacoEditorOptions({
          readOnly: readonly,
        })}
      />
    </Box>
  )
}
