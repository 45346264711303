import React from 'react'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { APP_VERSION } from '../../version'

const useStyles = makeStyles((theme) => ({
  footer: {
    width: '100%',
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    paddingBottom: 9
  },
  text: {
    fontSize: 14,
    color: theme.palette.primary.main,
    fontWeight: 500,
    letterSpacing: '0.1px'
  }
}))

const FooterVersion: React.FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.footer}>
      <Typography className={classes.text}>
        Версия приложения {APP_VERSION}
      </Typography>
    </div>
  )
}

export default FooterVersion
