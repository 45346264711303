import React, { ChangeEvent, useEffect } from 'react'

import { Button, createStyles, InputBase, Paper, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'react-use'

import { grays } from '../../../../layout/theme'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // maxWidth: 416,
      padding: '3px 20px 3px 20px',
      display: 'flex',
      alignItems: 'center',
      height: 40,
      flex: '0 0 40px',
      borderRadius: '40px',
      boxShadow: 'none',
    },
    input: {
      marginLeft: 10,
      flex: 1,
      '& > .MuiInputBase-input': {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 500,
        letterSpacing: '0.1px',
        '&::placeholder': {
          color: grays.gray2,
          opacity: 1,
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 500,
        },
      },
    },
    clearSearchBtn: {
      padding: '6px 6px',
      minWidth: 24,
      borderRadius: '50%',
    },
    iconSearch: {
      color: grays.gray2,
    },
    isContrast: {
      backgroundColor: '#F2F3F4',
      // border: `1px solid ${grays.gray5}`
    },
  }),
)

interface IStoresSearchProps {
  query?: string
  onChange?: (queryString: string) => void
  isContrast?: boolean
  isCrearBtnEnable?: boolean
}

const StoresSearch: React.FC<IStoresSearchProps> = ({ onChange, query = '', isContrast, isCrearBtnEnable }) => {
  const classes = useStyles()
  const [val, setVal] = React.useState(query)
  const [debouncedValue, setDebouncedValue] = React.useState(query)
  useDebounce(
    () => {
      setDebouncedValue(val)
    },
    400,
    [val],
  )

  const clear = (): void => {
    setVal('')
  }

  useEffect(() => onChange?.(debouncedValue), [debouncedValue])

  const { i18n, t, ready } = useTranslation('stores')
  useEffect(() => {
    !ready && i18n.reloadResources(i18n.language, 'stores')
  }, [ready])

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setVal(e.target.value)
  }

  return (
    <Paper
      elevation={0}
      className={classnames(classes.root, {
        [classes.isContrast]: isContrast,
      })}
    >
      <svg
        className={classes.iconSearch}
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='11' cy='11' r='7' stroke='#858F97' strokeWidth='2' />
        <path d='M20 20L17 17' stroke='#858F97' strokeWidth='2' strokeLinecap='round' />
      </svg>
      <InputBase
        placeholder={t('searchBarPlaceholder')}
        className={classes.input}
        inputProps={{ 'aria-label': 'points of sale search' }}
        value={val}
        onChange={handleChange}
      />
      {isCrearBtnEnable && (
        <Button className={classes.clearSearchBtn} onClick={clear}>
          <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M18 6L6 18' stroke='#858F97' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M6 6L18 18' stroke='#858F97' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          </svg>
        </Button>
      )}
    </Paper>
  )
}

export default React.memo(StoresSearch)
