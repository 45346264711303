import React, { useCallback, useContext } from 'react'

import { createStyles, FormControl, FormControlLabel, Radio, RadioGroup, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useAsync } from 'react-use'

import { IDictionaryItem } from '../../../../../model/dictionary-item'
import { IKeyValuePair, IRadioGroupScreenItem } from '../../../../../model/screen-item'
import { ApiContext } from '../../../../../providers'
import { getContextProperty } from '../../../script-tasks/propertyName'
import { useScriptTaskContext } from '../../../script-tasks/script-task-context'
import { ItemCard } from '../item-card'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      margin: 0,
      width: '100%',
      // minHeight: 40,
      marginBottom: 16,
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      '& .MuiFormControlLabel-label': {
        // paddingTop: 8,
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '24px',
      },
      '&:last-child': {
        marginBottom: 0,
      },
    },
    form: {
      width: '100%',
    },
    radio: {
      padding: 0,
      marginLeft: 10,
    },
  }),
)

interface Props {
  item: IRadioGroupScreenItem
  onChange: (value: string | IDictionaryItem) => void
}

export const RadioGroupItem: React.FC<Props> = ({ item, onChange }) => {
  const classes = useStyles()
  const api = useContext(ApiContext)
  const propertiesContext = useScriptTaskContext()

  const isRequired = item.required

  let items
  const value = getContextProperty(propertiesContext, item.propertyName, undefined) as unknown as string

  const renderFormControl = useCallback((): JSX.Element => {
    switch (item.dataSource.$type) {
      case 'PMI.FACE.BDDM.Extensions.Classes.KeyValuePairsDataSource':
        items = item.dataSource.items as IKeyValuePair[]
        const valueKeyValue = getContextProperty(propertiesContext, item.propertyName, undefined) as unknown as
          | string
          | undefined

        if (!items?.length) {
          return renderEmptyList()
        }
        return (
          <FormControl className={classes.form}>
            <RadioGroup
              value={valueKeyValue}
              aria-labelledby='demo-radio-buttons-group-label'
              name={`radio-buttons-group-${item.displayName}`}
            >
              {sortEmptyKeyValuePairArray(items).map((item) => (
                <FormControlLabel
                  key={item.key}
                  className={classes.label}
                  value={item.key}
                  control={<Radio className={classes.radio} color='primary' />}
                  label={item.value ? item.value : item.key}
                  color='secondary'
                  labelPlacement='start'
                  onClick={() => {
                    onChange(item.key)
                  }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )
      case 'PMI.FACE.BDDM.Extensions.Classes.DictionaryReferenceDataSource':
        const valueDictionary = getContextProperty(propertiesContext, item.propertyName, undefined) as unknown as
          | IDictionaryItem
          | undefined
        const dictionaryVersion = item.dataSource.dictionary.version!.code
        const dictionaryOps = useAsync(async () => {
          if (dictionaryVersion) {
            return await api.dictionary.getDictionaryByVersionCode(dictionaryVersion)
          }
        }, [dictionaryVersion])

        items = dictionaryOps.value?.items as IDictionaryItem[]
        // Если связанное свойство уже было заполнено и значение свойства отсутствует в источнике данных,
        // то необходимо добавлять еще один вариант выбора
        // https://jira.app.pconnect.biz/projects/FACE/issues/FACE-1449
        const isFindValue = items?.find((el) => el.code === valueDictionary?.code)
        if (!isFindValue && valueDictionary) {
          items?.push(valueDictionary)
        }

        if (!items?.length) {
          return renderEmptyList()
        }
        return (
          <FormControl className={classes.form}>
            <RadioGroup
              value={valueDictionary?.code}
              aria-labelledby='demo-radio-buttons-group-label'
              name={`radio-buttons-group-${item.displayName}`}
            >
              {items?.map((item) => (
                <FormControlLabel
                  key={item.code}
                  className={classes.label}
                  value={item.code}
                  control={<Radio className={classes.radio} color='primary' />}
                  label={item.name ? item.name : item.code}
                  color='secondary'
                  labelPlacement='start'
                  onClick={() => {
                    onChange(item)
                  }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )
      default:
        return (
          <>
            unimplemented data source type {String(item.$type)} for {item.displayName || item.propertyName}
          </>
        )
    }
  }, [item, value])

  return (
    <ItemCard
      isError={Boolean(isRequired && !value)}
      label={(() => item.displayName && <Typography variant='inherit'>{item.displayName}</Typography>)()}
    >
      {renderFormControl()}
    </ItemCard>
  )
}

const renderEmptyList = (text?: string): JSX.Element => {
  return (
    <Typography variant='body1' color='textSecondary'>
      {text ?? 'Список элементов для выбора пуст'}
    </Typography>
  )
}

function sortEmptyKeyValuePairArray(array: IKeyValuePair[]): IKeyValuePair[] {
  const arrDefined = array.filter((el) => el.value)
  const arrUndefined = array.filter((el) => !el.value)
  return [...arrDefined, ...arrUndefined]
}
