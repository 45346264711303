import React from 'react'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'

export interface AttentionModalProps {
  isOpen: boolean
  onClose: () => void,
  message: boolean | string
}

export const AttentionModal: React.FC<AttentionModalProps> = ({
  isOpen,
  onClose,
  message
}): JSX.Element => {

  return (
    <Dialog
      open={isOpen}
      disableScrollLock
      onClose={onClose}
    >
      <DialogTitle id='alert-dialog-title'>Внимание!</DialogTitle>
      <DialogContent>
        {message}
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          onClick={onClose}
        >
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>)
}